import { SignatureConfiguration } from '@augusthealth/models/com/august/protos/signable_form'
import classNames from 'classnames'
import { ReactNode, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { listPossibleSigners, SignatureInfoWithSkip } from '@shared/api/task'
import ButtonLink from '@shared/components/ButtonLink'
import { SmallDotDivider } from '@shared/components/DotDivider'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { Person } from '@shared/types/person'
import { Signer, SignerRole } from '@shared/types/snapshot'
import { SignatureInfo, SignatureStatus, Task } from '@shared/types/task'
import { getFullName } from '@shared/utils/humanName'
import { Loading } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import styles from './styles.module.css'
import { SignerRow } from '../Components'
import { changeSignerCta } from './helpers'

export default function ChangeSigners({
  currentSigner,
  setSigner,
  person,
  task,
  currentSigners,
  subTitle,
  signerIndex,
  disableChangeSigner,
  templateSigner,
  skipSignerComponent,
}: {
  currentSigner: SignatureInfoWithSkip
  currentSigners: SignatureInfoWithSkip[]
  setSigner: (s: SignatureInfoWithSkip) => void
  person: Person
  task: Task
  signerIndex: number
  subTitle?: ReactNode
  disableChangeSigner: boolean
  templateSigner?: SignatureConfiguration
  skipSignerComponent?: () => React.ReactNode
}) {
  const [showUserDropdown, setShowUserDropdown] = useState(false)
  const signerLabelClasses = classNames({
    ['visually-hidden']: showUserDropdown,
  })
  const dropdownClasses = classNames({
    ['visually-hidden']: !showUserDropdown,
    [styles.dropdown]: showUserDropdown,
  })
  const backgroundClasses = classNames({
    ['visually-hidden']: !showUserDropdown,
    [styles.background]: showUserDropdown,
  })

  const hasNotSelectedSigner = currentSigner.signer?.name === undefined
  const showInlineSignerDescription =
    templateSigner &&
    hasNotSelectedSigner &&
    (templateSigner.description || skipSignerComponent)
  const originalConfiguredSignerRole = currentSigners[signerIndex]?.signer?.role

  return (
    <div>
      <div
        onClick={() => setShowUserDropdown(false)}
        className={backgroundClasses}
      />
      <SignerRow
        id={`signer-${signerIndex}`}
        title={
          <>
            <div className={signerLabelClasses}>
              {getFullName(currentSigner.signer!.name!)}
              {!hasNotSelectedSigner && <SmallDotDivider />}
              <ButtonLink
                className={styles.signatureFlowLink}
                onClick={() => setShowUserDropdown(true)}
                disabled={disableChangeSigner}
              >
                {changeSignerCta({ hasNotSelectedSigner })}
              </ButtonLink>
              {showInlineSignerDescription && templateSigner.description && (
                <>
                  <SmallDotDivider />
                  <SignerDescription signer={templateSigner} />
                </>
              )}
            </div>
            <SkipSigner
              templateSigner={templateSigner}
              hasNotSelectedSigner={hasNotSelectedSigner}
              skipSignerComponent={skipSignerComponent}
              showUserDropdown={showUserDropdown}
              signerLabelClasses={signerLabelClasses}
            />
            <div className={dropdownClasses}>
              <UserDropdown
                currentSignerRole={originalConfiguredSignerRole}
                currentSigners={currentSigners}
                person={person}
                task={task}
                setCurrentSigner={setSigner}
                setShowUserDropdown={setShowUserDropdown}
              />
            </div>
          </>
        }
        subTitle={<div className={signerLabelClasses}>{subTitle}</div>}
        state={'Waiting for signature'}
      />
    </div>
  )
}

/**
 * TODO: This logic on whe/how to show the Skip CTA is a mess. We need to clean this up.
 */
function SkipSigner({
  templateSigner,
  hasNotSelectedSigner,
  skipSignerComponent,
  showUserDropdown,
  signerLabelClasses,
}: {
  templateSigner?: SignatureConfiguration
  hasNotSelectedSigner: boolean
  skipSignerComponent?: () => ReactNode
  showUserDropdown: boolean
  signerLabelClasses: string
}) {
  const hasSelectedSigner = !hasNotSelectedSigner
  const showInlineSignerDescription =
    templateSigner &&
    hasNotSelectedSigner &&
    (templateSigner.description || skipSignerComponent)
  const newlineSignerDescription =
    hasSelectedSigner && templateSigner && templateSigner.description

  if (
    (showInlineSignerDescription || skipSignerComponent) &&
    !newlineSignerDescription &&
    skipSignerComponent &&
    !showUserDropdown &&
    skipSignerComponent
  ) {
    return skipSignerComponent()
  }

  if (newlineSignerDescription) {
    return (
      <div className={signerLabelClasses}>
        <SignerDescription signer={templateSigner} />
        {skipSignerComponent && (
          <>
            <SmallDotDivider />
            {skipSignerComponent()}
          </>
        )}
      </div>
    )
  }

  return null
}

function UserDropdown({
  person,
  task,
  setCurrentSigner,
  currentSigners,
  setShowUserDropdown,
  currentSignerRole,
}: {
  person: Person
  task: Task
  setCurrentSigner: React.Dispatch<React.SetStateAction<SignatureInfo>>
  setShowUserDropdown: React.Dispatch<React.SetStateAction<boolean>>
  currentSigners: SignatureInfo[]
  currentSignerRole: SignerRole | undefined
}) {
  const [facilitySigners, setFacilitySigners] = useState<Loading<Signer[]>>({
    tag: 'Loading',
  })

  const [selectedUser, setSelectedUser] = useState<OptionTypeBase<Signer>>()

  useEffect(() => {
    void listPossibleSigners({
      personIds: person,
      taskId: task.id!,
      includesRp: true,
    }).then((res) => {
      if (currentSignerRole) {
        const possibleSigners = res.data[currentSignerRole] || []
        setFacilitySigners({ tag: 'Complete', value: possibleSigners })
      }
    })
  }, [currentSigners])

  if (facilitySigners.tag === 'Loading') {
    return <Skeleton />
  }

  const userOptions =
    facilitySigners.tag === 'Complete'
      ? facilitySigners.value.map((u) => ({
          value: u,
          label: getFullName(u!.name!),
        }))
      : []

  return (
    <>
      <label className="visually-hidden" htmlFor="signer">
        Signer
      </label>
      <StyledSelect
        inputId="signer"
        name="signer"
        options={userOptions}
        onChange={(e: OptionTypeBase<Signer>) => {
          setSelectedUser(e)
          setCurrentSigner({
            status:
              task?.signatures?.find(
                (s) => s.signer?.userAccountId === e.value.userAccountId
              )?.status ?? SignatureStatus.SIGNATURE_STATUS_NOT_SIGNED,
            signer: {
              role: e.value.role,
              email: e.value.email,
              name: e.value.name,
              userAccountId: e.value.userAccountId,
            },
          })
          setShowUserDropdown(false)
        }}
        onBlur={() => setShowUserDropdown(false)}
        placeholder="Select a signer..."
        styles={{
          control: (provided) => ({
            ...provided,
            borderColor: 'var(--form-input-border)',
            height: 'var(--form-input-height)',
            cursor: 'pointer',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '16px',
          }),
        }}
        value={selectedUser}
      />
    </>
  )
}

function SignerDescription({ signer }: { signer: SignatureConfiguration }) {
  return (
    <span className={tw`font-medium text-secondary-07`}>
      {signer.description}
    </span>
  )
}
