import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { DocumentViewerUploaderFooter } from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { LightboxSidebarTitle } from '@shared/components/AnimatedPopup/Lightbox/Lightbox'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { snapshotFileUrl } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { DataType, Snapshot } from '@shared/types/snapshot'
import { getLastName } from '@shared/utils/humanName'
import { getSnapshotName } from '@shared/utils/snapshot'
import useBlobData from '@app/hooks/useBlobData'
import PolstRadioGroup from '@app/pages/Documents/sharedComponents/PolstRadioGroup'
import styles from './styles.module.css'
import { PolstData, spaceFree, updateCPRCodeStatus } from './helpers'
import Viewer from './index'
import useDeletable from './useDeletable'

interface Props {
  person: Person
  onComplete: (updated: boolean) => Promise<void>
  currentPOLST: Snapshot
}

export default function POLSTViewer({
  currentPOLST,
  onComplete,
  person,
}: Props) {
  const { user } = useUserContext()
  const { setError } = useContext(GlobalContext)
  const { deletable } = useDeletable({
    document: currentPOLST,
    onComplete,
    person,
  })

  const useFormReturn = useForm<PolstData>({
    mode: 'onChange',
    defaultValues: {
      codeStatus: currentPOLST.data?.uploadInfo?.polst?.cprCode,
    },
  })
  const { handleSubmit, formState } = useFormReturn

  const onSubmit = async (data: PolstData) => {
    try {
      await updateCPRCodeStatus(data, person, currentPOLST)
      await onComplete(true)
    } catch (e) {
      setError(e)
    }
  }

  const pdfPath = snapshotFileUrl({
    personId: person.id!,
    orgId: person.orgId!,
    dataType: DataType.DATA_TYPE_POLST,
    snapshotId: currentPOLST.id!,
  })

  const snapshotName = getSnapshotName(currentPOLST, '')
  const hasFile = Boolean(currentPOLST.fileMetaData)
  const blobData = useBlobData(hasFile ? pdfPath : null) // Send null when no file to prevent unnecessary API call
  const filename = spaceFree(`${getLastName(person.name)} ${snapshotName}`)

  const canUpdate = hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_SNAPSHOT_UPDATE],
  })
  // If Full Code and no file uploaded, disable the form,
  // since switching to DNR without file is not allowed.
  const disabled = !canUpdate || !hasFile

  // Outer <Form /> doesn't go well with MUI Modal, need to put handleSubmit on Save button
  return (
    <form>
      <Viewer
        dataType={currentPOLST.dataType!}
        deletable={hasFile ? deletable : false}
        blobData={hasFile ? blobData : null} // When no file, pass null to display No Attachment message
        filename={filename}
        popUpActions={
          <DocumentViewerUploaderFooter
            yesBtn={{
              label: 'Save',
              props: {
                disabled: disabled || formState.isSubmitting,
                onClick: handleSubmit(onSubmit),
              },
            }}
            noBtn={{
              action: () => void onComplete(false),
              label: canUpdate ? 'Cancel' : 'Close',
            }}
            formState={formState}
          />
        }
        onClose={() => onComplete(false)}
        notFoundMessage="No attachment"
      >
        <LightboxSidebarTitle>{snapshotName}</LightboxSidebarTitle>
        <div className={styles.formContainer}>
          <PolstRadioGroup polst={currentPOLST} useFormReturn={useFormReturn} />
        </div>
      </Viewer>
    </form>
  )
}
