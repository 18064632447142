import { ReportAssignment } from '@augusthealth/models/com/august/protos/report'
import { ChangeEvent } from 'react'
import { BasicCheckbox } from '@shared/components/BasicInput/BasicInput'
import { updateReportAssignment } from '@app/api/reports'

export default function AllowLimitedReadCheckbox({
  assignment,
  reload,
}: {
  assignment: ReportAssignment
  reload: () => void
}) {
  return (
    <BasicCheckbox
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        assignment.allowLimitedRead = e.target.checked
        void updateReportAssignment(assignment).finally(reload)
      }}
      checked={assignment.allowLimitedRead}
    >
      <i className={'fa-solid fa-lock-open mr-[4px]'} />
      Allow Limited Read
    </BasicCheckbox>
  )
}
