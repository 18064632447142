import { Person } from '@shared/types/person'
import { DeepNull } from '@shared/types/utilities'

export function filterOutEmptyAllergies(patch: DeepNull<Person>) {
  const updatedPatch = { ...patch }
  let allergies =
    updatedPatch.allergiesAndIntolerances &&
    updatedPatch.allergiesAndIntolerances.allergies
  if (Array.isArray(allergies)) {
    allergies = allergies.filter((a) => a.summary?.trim())
    if (updatedPatch.allergiesAndIntolerances) {
      updatedPatch.allergiesAndIntolerances.allergies = allergies
    }
  }

  return updatedPatch
}
