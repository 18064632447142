import { AugustFieldFormConfigurationMap } from '@shared/constants/custom_field/type'
import { Coverage_CoverageType as CoverageType } from '@shared/types/coverage'
import { AugustFieldType } from '@shared/types/custom_field'

const medicalPath = `coverage[type=${CoverageType.COVERAGE_TYPE_MEDICAL}]`
const dentalPath = `coverage[type=${CoverageType.COVERAGE_TYPE_DENTAL}]`
const pharmacyPath = `coverage[type=${CoverageType.COVERAGE_TYPE_PHARMACY}]`
const primaryPath = `${medicalPath}[0]`
const primaryNamePath = `${primaryPath}.name`
const primaryMemberIdPath = `${primaryPath}.subscriberId`
const primaryGroupNumberPath = `${primaryPath}.plan.value`
const secondaryPath = `${medicalPath}[1]`
const secondaryNamePath = `${secondaryPath}.name`
const secondaryMemberIdPath = `${secondaryPath}.subscriberId`
const secondaryGroupNumberPath = `${secondaryPath}.plan.value`
const dentalNamePath = `${dentalPath}.name`
const dentalMemberIdPath = `${dentalPath}.subscriberId`
const dentalGroupNumberPath = `${dentalPath}.plan.value`
const pharmacyNamePath = `${pharmacyPath}.name`
const pharmacyMemberIdPath = `${pharmacyPath}.subscriberId`
const pharmacyGroupNumberPath = `${pharmacyPath}.plan.value`
const pharmacyBinPath = `${pharmacyPath}.rxbin.value`
const pharmacyPcnPath = `${pharmacyPath}.rxpcn.value`

export const INSURANCE_INFO: AugustFieldFormConfigurationMap = {
  [AugustFieldType.AUGUST_FIELD_TYPE_MED_INS_1_COMPANY]: {
    fieldLists: ['coverage'],
    requiredFields: [
      primaryNamePath,
      primaryMemberIdPath,
      primaryGroupNumberPath,
    ],
    fields: [
      {
        type: 'label',
        label: 'PRIMARY MEDICAL INSURANCE',
      },
      {
        title: 'INSURANCE COMPANY',
        name: primaryNamePath,
        placeholder: 'e.g. Blue Shield of California, Aetna, Kaiser',
      },
      {
        title: 'INSURANCE MEMBER ID',
        name: primaryMemberIdPath,
      },
      {
        title: 'INSURANCE GROUP #',
        name: primaryGroupNumberPath,
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_MED_INS_2_COMPANY]: {
    fieldLists: ['coverage'],
    requiredFields: [secondaryNamePath],
    fields: [
      {
        type: 'label',
        label: 'SECONDARY MEDICAL INSURANCE',
      },
      {
        title: 'INSURANCE COMPANY',
        name: secondaryNamePath,
        placeholder: 'e.g. Blue Shield of California, Aetna, Kaiser',
        relation: {
          trigger: primaryPath,
          condition: 'notExists',
          relationType: 'disability',
        },
      },
      {
        title: 'INSURANCE MEMBER ID',
        name: secondaryMemberIdPath,
        relation: {
          trigger: primaryPath,
          condition: 'notExists',
          relationType: 'disability',
        },
      },
      {
        title: 'INSURANCE GROUP #',
        name: secondaryGroupNumberPath,
        relation: {
          trigger: primaryPath,
          condition: 'notExists',
          relationType: 'disability',
        },
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_DENTAL_INSURANCE_COMPANY]: {
    fieldLists: ['coverage'],
    requiredFields: [dentalNamePath, dentalMemberIdPath, dentalGroupNumberPath],
    fields: [
      {
        type: 'label',
        label: 'DENTAL INSURANCE',
      },
      {
        title: 'INSURANCE COMPANY',
        name: dentalNamePath,
        placeholder: 'e.g. Delta Dental, Aetna',
      },
      {
        title: 'INSURANCE MEMBER ID',
        name: dentalMemberIdPath,
      },
      {
        title: 'INSURANCE GROUP #',
        name: dentalGroupNumberPath,
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_PHARMACY_INSURANCE_1_COMPANY]: {
    fieldLists: ['coverage'],
    requiredFields: [
      pharmacyNamePath,
      pharmacyMemberIdPath,
      pharmacyGroupNumberPath,
      pharmacyBinPath,
      pharmacyPcnPath,
    ],
    fields: [
      {
        type: 'label',
        label: 'PHARMACY INSURANCE',
      },
      {
        title: 'INSURANCE COMPANY',
        name: pharmacyNamePath,
      },
      {
        title: 'INSURANCE MEMBER ID',
        name: pharmacyMemberIdPath,
      },
      {
        title: 'INSURANCE GROUP #',
        name: pharmacyGroupNumberPath,
      },
      {
        title: 'BIN',
        name: pharmacyBinPath,
      },
      {
        title: 'PCN',
        name: pharmacyPcnPath,
      },
    ],
  },
}
