import { SignatureConfiguration } from '@augusthealth/models/com/august/protos/signable_form'
import { SignatureInfoWithSkip } from '@shared/api/task'
import ButtonLink from '@shared/components/ButtonLink'
import { SmallDotDivider } from '@shared/components/DotDivider'
import { toggleSkip } from '@shared/utils/signatureInfo'
import { twx } from '@shared/utils/tailwind'
import styles from '@app/components/SignatureFlow/styles.module.css'

/**
 * Renders a CTA that allows users to skip a signer
 * Has two render modes: inline and block
 * @param props
 * @constructor
 */
export const SkipSignerInput = (props: {
  inline?: boolean
  templateSigner?: SignatureConfiguration
  index: number
  setCurrentSigners: React.Dispatch<
    React.SetStateAction<SignatureInfoWithSkip[]>
  >
  disabled: boolean
}) => {
  const {
    inline = false,
    templateSigner,
    index,
    setCurrentSigners,
    disabled,
  } = props
  const updateCurrentSigners = (ix: number) => {
    setCurrentSigners((x) =>
      x.map((x, index) => (ix === index ? toggleSkip(x) : x))
    )
  }

  if (templateSigner?.canSkip !== true) {
    return null
  }

  if (inline) {
    return (
      <ButtonLink
        data-testid={`btn-skip-${index}`}
        onClick={() => {
          updateCurrentSigners(index)
        }}
        name={`skip-${index}`}
        disabled={disabled}
      >
        Skip signer
      </ButtonLink>
    )
  } else {
    return (
      <div className={'flex justify-start'}>
        <ButtonLink
          data-testid={`btn-skip-${index}`}
          onClick={() => {
            updateCurrentSigners(index)
          }}
          name={`skip-${index}`}
          disabled={disabled}
        >
          Skip signer
        </ButtonLink>
      </div>
    )
  }
}

export function SkippedSignerRow({
  setCurrentSigners,
  index,
  disabled,
}: {
  index: number
  setCurrentSigners: React.Dispatch<
    React.SetStateAction<SignatureInfoWithSkip[]>
  >
  disabled: boolean
}) {
  const updateCurrentSigners = (ix: number) => {
    setCurrentSigners((x) =>
      x.map((x, index) => (ix === index ? toggleSkip(x) : x))
    )
  }

  return (
    <div className={styles.signatureFlowHolder}>
      <i
        className={twx(
          styles.signatureFlowIcon,
          `fa-arrow-right-long-to-line fa-fw fa-light text-secondary-08`
        )}
      />
      <div>
        <div className={styles.signatureFlowTitle}>
          Skipped
          <SmallDotDivider />
          <ButtonLink
            onClick={() => updateCurrentSigners(index)}
            disabled={disabled}
          >
            Add signer
          </ButtonLink>
        </div>
      </div>
    </div>
  )
}
