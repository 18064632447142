import {
  DayOfWeek,
  UnitOfTime,
} from '@augusthealth/models/com/august/protos/timing'
import classNames from 'classnames'
import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { LabelAboveInput, requiredWhenError } from '@shared/components/Labels'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import { ButtonGroup } from '@app/components/ButtonGroup'
import { abbreviatedDayOfWeekOptions } from '@app/pages/Routines/constants'
import { RoutineOrderFormData } from './RoutineOrderForm'

// Adapted from @app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/helpers
// Since we are using UNIT_OF_TIME_DAY now, we don't need to validate the frequency === number of days of week selected.
const validateRequiredNumberSelection = (
  value: any[],
  frequencyCount: number
) => {
  if (value.length === 0 || isNaN(frequencyCount) || frequencyCount === 0) {
    return 'Required'
  }
  return true
}

type Props = {
  index: number
  readOnly: boolean
}
export const RoutineDaysOfWeek = ({ index, readOnly }: Props) => {
  const { register, setValue, unregister, control, watch, getValues } =
    useFormContext<RoutineOrderFormData>()

  const frequencyCount = watch(`schedules.${index}.frequency`)
  const numberOfDays = watch(`schedules.${index}.dayOfWeek`)?.length || 0

  useEffect(() => {
    register(`schedules.${index}.period`)
    setValue(`schedules.${index}.period`, 1)
    register(`schedules.${index}.periodUnit`)
    setValue(`schedules.${index}.periodUnit`, UnitOfTime.UNIT_OF_TIME_DAY)
    return () => {
      if (getValues(`schedules.${index}.periodChoice`) !== 'DaysOfWeek') {
        unregister(`schedules.${index}.dayOfWeek`)
      }
    }
  }, [])

  useEffect(() => {
    if (numberOfDays === 0) {
      setValue(`schedules.${index}.frequency`, 0)
    } else if (frequencyCount === 0) {
      setValue(`schedules.${index}.frequency`, 1)
    }
  }, [numberOfDays])

  return (
    <Controller
      control={control}
      name={`schedules.${index}.dayOfWeek`}
      rules={{
        validate: (value: DayOfWeek[]) =>
          validateRequiredNumberSelection(value, frequencyCount),
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const labelClasses = classNames('ml-auto w-fit', {
          'mb-[4px]': error,
          'mb-0': !error,
        })
        return (
          <>
            <LabelAboveInput
              className={labelClasses}
              subLabel={requiredWhenError(!!error)}
              errorMessage={error?.message}
              htmlFor={`schedules.${index}.dayOfWeek`}
            />
            <ButtonGroup
              prependTestId={'days-of-week'}
              onChange={(e: OptionTypeBase<DayOfWeek>[]) =>
                onChange(e.map((opt) => opt.value))
              }
              options={abbreviatedDayOfWeekOptions}
              chosen={abbreviatedDayOfWeekOptions.filter((day) =>
                value?.includes(day.value)
              )}
              disabled={readOnly}
            />
          </>
        )
      }}
    />
  )
}
