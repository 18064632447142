/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { BillingSettings } from '@augusthealth/models/com/august/protos/settings/billing_settings'
import { requestAnything, requestJson } from '@shared/api/request'
import { apiUrl, facilityUrl, personUrl } from '@shared/api/urls'
import { apiOrganizationUrl } from '@shared/legacy_routes'
import {
  AccountingPeriodClose,
  AddChargeRequest,
  BillingCategory,
  BillingCategoryData,
  BillingEvent,
  BillingFee,
  BillingFeeData,
  BillingFeesWithParentCategory,
  BillingResidentSummary,
  DetailedStatement,
  DraftStatementsRequest,
  LedgerExport,
  PayerBillingEvent,
  PayerDetailedStatement,
  PayerSettingsData,
  PayerWithScheduledPayment,
  RecurringCharge,
  ResidentEventImpactingBilling,
  ResidentListEntry,
  ReturnPaymentRequest,
  ReversePaymentRequest,
  StatementResponse,
} from '@shared/types/billing'
import { Facility, FacilityIds } from '@shared/types/facility'
import { RequiredPersonIds } from '@shared/types/person'

export async function getLedgerExports(facility: Facility) {
  const response = await requestJson({
    url: apiUrl(
      facilityUrl(facility.orgId, facility.id),
      '/billing/journalEntries',
      {}
    ),
  })

  return response.data as LedgerExport[]
}

export async function getOrgBillingCategories({
  orgId,
}: {
  orgId: string
}): Promise<BillingCategory[]> {
  const response = await requestJson({
    url: apiUrl(apiOrganizationUrl(orgId), '/billing/categories', {}),
  })
  return response.data as BillingCategory[]
}

export async function getFacilityBillingCategories({
  orgId,
  facilityId,
}: {
  orgId: string
  facilityId: string
}): Promise<BillingCategory[]> {
  const response = await requestJson({
    url: apiUrl(facilityUrl(orgId, facilityId), '/billing/categories', {}),
  })
  return response.data as BillingCategory[]
}

export async function createBillingCategory({
  orgId,
  category,
}: {
  orgId: string
  category: BillingCategoryData
}): Promise<BillingCategory> {
  const response = await requestJson({
    method: 'POST',
    url: apiUrl(apiOrganizationUrl(orgId), '/billing/categories', {}),
    body: JSON.stringify(category),
  })
  return response.data as BillingCategory
}

export async function updateBillingCategory({
  orgId,
  categoryId,
  category,
}: {
  orgId: string
  categoryId: string
  category: BillingCategoryData
}): Promise<BillingCategory> {
  const response = await requestJson({
    method: 'PUT',
    url: apiUrl(
      apiOrganizationUrl(orgId),
      `/billing/categories/${categoryId}`,
      {}
    ),
    body: JSON.stringify(category),
  })
  return response.data as BillingCategory
}

export async function createBillingFee({
  orgId,
  item,
}: {
  orgId: string
  item: BillingFeeData
}): Promise<BillingFee> {
  const response = await requestJson({
    method: 'POST',
    url: apiUrl(apiOrganizationUrl(orgId), '/billing/items', {}),
    body: JSON.stringify(item),
  })
  return response.data as BillingFee
}

export async function updateBillingFee({
  orgId,
  itemId,
  item,
}: {
  orgId: string
  itemId: string
  item: Omit<BillingFeeData, 'facilityId' | 'orgId'>
}): Promise<BillingFee> {
  const response = await requestJson({
    method: 'PUT',
    url: apiUrl(apiOrganizationUrl(orgId), `/billing/items/${itemId}`, {}),
    body: JSON.stringify(item),
  })
  return response.data as BillingFee
}

export async function upsertBillingFee({
  billingFeeData,
  itemId,
  orgId,
}: {
  billingFeeData: BillingFeeData
  itemId: string
  orgId: string
}) {
  const coreProps = {
    orgId,
    item: billingFeeData,
  }

  if (itemId) {
    return await updateBillingFee({
      itemId,
      ...coreProps,
    })
  }

  return await createBillingFee({ ...coreProps })
}

export async function getBillingStatementPreview({
  orgId,
  facilityId,
  invoiceId,
  personId,
}: {
  orgId: string
  facilityId: string
  invoiceId: string
  personId: string
}): Promise<DetailedStatement> {
  const response = await requestJson({
    url: apiUrl(
      personUrl(orgId, facilityId, personId),
      `/invoices/${invoiceId}`,
      {}
    ),
  })

  return response.data as DetailedStatement
}

export async function getFacilityBillingFeesWithParentCategories({
  orgId,
  facilityId,
}: {
  orgId: string
  facilityId: string
}) {
  const response = await requestJson({
    url: apiUrl(facilityUrl(orgId, facilityId), '/billing/itemsByCategory', {}),
  })

  return response.data as BillingFeesWithParentCategory[]
}
export async function getOrgBillingFees({
  orgId,
}: {
  orgId: string
}): Promise<BillingFee[]> {
  const response = await requestJson({
    url: apiUrl(apiOrganizationUrl(orgId), '/billing/items', {}),
  })
  return response.data as BillingFee[]
}

/**
 * Charges or credits a `BillingFee` to a resident.
 * The amount is editable on a per charge basis.
 * The frequency is from the `BillingFee`.
 *
 * Creating a one time charge will only create a `Transaction` which also appears as a `BillingEvent`.
 * Creating a recurring charge will create `Transaction`s for relevant time periods
 * and will create a `RecurringCharge` if the `endDate` is unset or in the future.
 */
export async function createChargeOrTransaction({
  orgId,
  facilityId,
  billingChargesDataList,
}: {
  orgId: string
  facilityId: string
  billingChargesDataList: AddChargeRequest[]
}): Promise<void> {
  await requestJson({
    method: 'POST',
    url: apiUrl(facilityUrl(orgId, facilityId), '/billing/charges', {}),
    body: JSON.stringify(billingChargesDataList),
  })

  return undefined
}

/**
 * TODO update swagger documentation
 */
export async function createStatements({
  orgId,
  facilityId,
  data,
}: {
  orgId: string
  facilityId: string
  data: DraftStatementsRequest
}) {
  const response = await requestJson({
    method: 'POST',
    url: apiUrl(facilityUrl(orgId, facilityId), '/invoices', {}),
    body: JSON.stringify(data),
  })

  return response.data as StatementResponse[]
}

function getRecurringChargesUrl(props: RequiredPersonIds) {
  const { orgId, facilityId, id: personId } = props

  return `${personUrl(orgId, facilityId, personId)}/billing/charges`
}

export async function getRecurringCharges({
  orgId,
  facilityId,
  personId,
}: {
  orgId: string
  facilityId: string
  personId: string
}): Promise<RecurringCharge[]> {
  const response = await requestJson({
    url: getRecurringChargesUrl({ orgId, facilityId, id: personId }),
  })
  return response.data as RecurringCharge[]
}

export async function deleteRecurringCharge({
  personIds,
  chargeId,
}: {
  personIds: RequiredPersonIds
  chargeId: string
}) {
  return (await requestJson({
    url: `${getRecurringChargesUrl(personIds)}/${chargeId}`,
    method: 'DELETE',
  })) as Promise<{ meta: { hello: 'Deleted' } }>
}

export async function updateRecurringCharge({
  personIds,
  chargeData,
  chargeId,
}: {
  personIds: RequiredPersonIds
  chargeData: AddChargeRequest
  chargeId: string
}) {
  return (await requestJson({
    url: `${getRecurringChargesUrl(personIds)}/${chargeId}`,
    method: 'POST',
    body: JSON.stringify(chargeData),
  })) as Promise<{ data: RecurringCharge }>
}

export async function endRecurringCharge({
  personIds,
  chargeId,
  endDate,
}: {
  personIds: RequiredPersonIds
  chargeId: string
  endDate: string
}) {
  return (await requestJson({
    url: `${getRecurringChargesUrl(personIds)}/${chargeId}/endDate`,
    method: 'POST',
    body: `"${endDate}"`,
  })) as Promise<{ data: RecurringCharge }>
}

export async function getResidentBillingSummary({
  orgId,
  facilityId,
  personId,
}: {
  orgId: string
  facilityId: string
  personId: string
}) {
  const response = await requestJson({
    url: apiUrl(personUrl(orgId, facilityId, personId), '/billingSummary', {}),
  })

  return response.data as BillingResidentSummary
}

export async function getResidentList({
  orgId,
  facilityId,
}: {
  orgId: string
  facilityId: string
}) {
  const response = await requestJson({
    url: apiUrl(facilityUrl(orgId, facilityId), '/billing/people', {}),
  })

  return response.data as ResidentListEntry[]
}

export async function issueStatementsForResidents({
  orgId,
  facilityId,
  statementIds,
}: {
  orgId: string
  facilityId: string
  statementIds: string[]
}) {
  const response = await requestJson({
    method: 'POST',
    url: apiUrl(
      facilityUrl(orgId, facilityId),
      '/billing/statements/issue',
      {}
    ),
    body: JSON.stringify({ invoiceIds: statementIds }),
  })

  return response.data as StatementResponse[]
}

/**
 * TODO update swagger documentation
 */
export async function issueStatementForResident({
  personIds: { orgId, facilityId, id: personId },
  statementId,
}: {
  personIds: RequiredPersonIds
  statementId: string
}) {
  const response = await requestJson({
    method: 'POST',
    url: `${personUrl(orgId, facilityId, personId)}/billing/statements/${statementId}/issue`,
  })

  return response.data as StatementResponse[]
}

function getResidentEventsImpactingBillingUrl(personIds: RequiredPersonIds) {
  const { orgId, facilityId, id: personId } = personIds
  return `${personUrl(orgId, facilityId, personId)}/billing/events`
}

export async function fetchResidentEventsImpactingBilling(
  personIds: RequiredPersonIds
) {
  const response = await requestJson({
    url: getResidentEventsImpactingBillingUrl(personIds),
  })

  return response.data as ResidentEventImpactingBilling[]
}

export async function completeResidentEventImpactingBilling(
  personIds: RequiredPersonIds,
  eventId: string
) {
  const response = await requestJson({
    url: `${getResidentEventsImpactingBillingUrl(personIds)}/${eventId}/status/completed`,
    method: 'POST',
  })

  return response.data as { meta: { id: string } }
}

export async function fetchResidentBillingEvents(personIds: RequiredPersonIds) {
  const { orgId, facilityId, id: personId } = personIds
  const response = await requestJson({
    url: `${personUrl(orgId, facilityId, personId)}/billing/transactions`,
  })

  return response.data as BillingEvent[]
}

type GetTransaction = {
  personIds: RequiredPersonIds
  invoiceItemId: string
}

function getTransactionUrl({ personIds, invoiceItemId }: GetTransaction) {
  const { orgId, facilityId, id: personId } = personIds
  return `${personUrl(orgId, facilityId, personId)}/billing/invoiceItems/${invoiceItemId}`
}

export async function deleteTransaction(props: GetTransaction) {
  return (await requestJson({
    url: getTransactionUrl(props),
    method: 'DELETE',
  })) as Promise<void>
}

export async function regenerateStatements(personIds: RequiredPersonIds) {
  const { orgId, facilityId, id: personId } = personIds

  return (await requestJson({
    url: `${personUrl(orgId, facilityId, personId)}/statements/regenerate`,
    method: 'POST',
  })) as Promise<void>
}

function getFacilityBillingUrl({ orgId, id }: FacilityIds) {
  return `${facilityUrl(orgId, id)}/billing`
}

/**
 * To download Facility Billing Statements PDF
 */
export function getFacilityBillingStatementsUrl(facilityIds: FacilityIds) {
  return `${getFacilityBillingUrl(facilityIds)}/statements.pdf`
}

export async function fetchClosedBillingAccounting(facilityIds: FacilityIds) {
  // Use requestAnything since API may return 204 No Content (vs JSON)
  return await requestAnything({
    url: `${getFacilityBillingUrl(facilityIds)}/accountingPeriodClose`,
  })
    .then((res) => {
      if (res.status === 204) {
        return { data: undefined }
      }

      return res.json()
    })
    .then((res) => res.data as AccountingPeriodClose)
}

export async function closeBillingAccountingPeriod({
  facilityIds,
  isoStr,
}: {
  facilityIds: FacilityIds
  isoStr: string
}) {
  const response = await requestJson({
    url: `${getFacilityBillingUrl(facilityIds)}/closeAccountingPeriod`,
    method: 'POST',
    body: `"${isoStr}"`,
  })

  return response.data as AccountingPeriodClose
}

export function getLedgerExportUrl({
  facility,
  id,
}: {
  facility: Facility
  id: string
}) {
  return `${facilityUrl(facility.orgId, facility.id)}/billing/journalEntries/${id}`
}

export async function getBillingSettings({
  orgId,
  facilityId,
}: {
  orgId: string
  facilityId: string
}) {
  const response = await requestJson({
    url: `${facilityUrl(orgId, facilityId)}/billing/settings`,
  })

  return response.data as BillingSettings
}

export async function updateBillingSettings({
  orgId,
  facilityId,
  settings,
}: {
  orgId: string
  facilityId: string
  settings: BillingSettings
}) {
  const response = await requestJson({
    url: `${facilityUrl(orgId, facilityId)}/billing/settings`,
    method: 'POST',
    body: JSON.stringify(settings),
  })

  return response.data as Promise<{ meta: { hello: 'Updated' } }>
}

export async function getPayerSettings({
  orgId,
  facilityId,
  personId,
  contactId,
}: {
  orgId: string
  facilityId: string
  personId: string
  contactId: string
}) {
  const response = await requestJson({
    url: `${personUrl(orgId, facilityId, personId)}/contacts/${contactId}/payerSettings`,
  })
  return response.data as PayerSettingsData
}

export async function setPayerSettings({
  orgId,
  facilityId,
  personId,
  contactId,
  updatedSettings,
}: {
  orgId: string
  facilityId: string
  personId: string
  contactId: string
  updatedSettings: PayerSettingsData
}) {
  const response = await requestJson({
    url: `${personUrl(orgId, facilityId, personId)}/contacts/${contactId}/payerSettings`,
    method: 'PUT',
    body: JSON.stringify(updatedSettings),
  })

  return response.data as Promise<{ meta: { hello: 'Updated' } }>
}

export async function getPayerUserSettings({
  orgId,
  facilityId,
  personId,
}: {
  orgId: string
  facilityId: string
  personId: string
}) {
  const response = await requestJson({
    url: `${personUrl(orgId, facilityId, personId)}/payers/userSettings`,
  })

  return response.data as PayerWithScheduledPayment
}

export async function setPayerUserSettings({
  orgId,
  facilityId,
  personId,
  updatedSettings,
}: {
  orgId: string
  facilityId: string
  personId: string
  updatedSettings: PayerSettingsData
}) {
  const response = await requestJson({
    url: `${personUrl(orgId, facilityId, personId)}/payers/userSettings`,
    method: 'PUT',
    body: JSON.stringify(updatedSettings),
  })

  return response.data as Promise<{ meta: { hello: 'Updated' } }>
}

export async function getPayerUserStatements({
  orgId,
  facilityId,
  personId,
}: {
  orgId: string
  facilityId: string
  personId: string
}) {
  const response = await requestJson({
    url: `${personUrl(orgId, facilityId, personId)}/payers/statements/issued/latest`,
  })

  return response.data as PayerDetailedStatement
}

export async function createPayerUserOneTimePayment({
  orgId,
  facilityId,
  personId,
  amountCents,
}: {
  orgId: string
  facilityId: string
  personId: string
  amountCents: number
}) {
  const response = await requestJson({
    url: `${personUrl(orgId, facilityId, personId)}/payments`,
    method: 'POST',
    body: JSON.stringify({ amountCents }),
  })

  return response.data as { meta: { hello: 'Updated' } }
}

export async function getPayerUserTransactions({
  orgId,
  facilityId,
  personId,
}: {
  orgId: string
  facilityId: string
  personId: string
}) {
  const response = await requestJson({
    url: `${personUrl(orgId, facilityId, personId)}/payers/transactions`,
  })

  return response.data as PayerBillingEvent[]
}

export async function returnPayment(props: ReturnPaymentRequest) {
  const [orgId, facilityId, residentId] = props.residentIds
  const { addFee, returnDate, notes, transactionId } = props

  const response = await requestJson({
    url: `${personUrl(orgId, facilityId, residentId)}/payments/${transactionId}/return`,
    method: 'POST',
    body: JSON.stringify({
      returnDate,
      addFee,
      notes,
    }),
  })

  return response.data as { meta: { hello: 'Updated' } }
}

export async function reversePayment(props: ReversePaymentRequest) {
  const [orgId, facilityId, residentId] = props.residentIds
  const { notes, transactionId } = props

  const response = await requestJson({
    url: `${personUrl(orgId, facilityId, residentId)}/payments/${transactionId}/enteredInError`,
    method: 'POST',
    body: JSON.stringify({
      notes,
    }),
  })

  return response.data as { meta: { hello: 'Updated' } }
}
