import { SignatureInfoWithSkip } from '@shared/api/task'
import { getFirstAndLastName } from '@shared/utils/humanName'

export function addMissingEmailToSignerInList({
  currentSigner,
  allSigners,
  newEmail,
}: {
  currentSigner: SignatureInfoWithSkip
  allSigners: SignatureInfoWithSkip[]
  newEmail: string
}) {
  const { name: curName } = currentSigner.signer || {}

  return allSigners.map((sig) => {
    const { name, email } = sig.signer || {}
    // To-Do: will not work if different signers have the same name
    if (
      !email &&
      name &&
      curName &&
      getFirstAndLastName(name) === getFirstAndLastName(curName)
    ) {
      return {
        ...sig,
        signer: {
          ...sig.signer,
          email: newEmail,
        },
      }
    }

    return sig
  })
}
