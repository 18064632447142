import { useState } from 'react'
import {
  AsyncIconButton,
  ButtonSize,
  ButtonStyle,
} from '@shared/components/AsyncButton'
import Icon from '@shared/components/Icon'
import SearchBox from '@shared/components/SearchBox'
import { tw, twx } from '@shared/utils/tailwind'
import styles from './styles.module.css'

export type MenuItem = {
  id: string
  onClick: () => Promise<void> | void
  isDisabled: boolean
  isVisible: boolean
  title: string
  dataTestId?: string
  iconName?: string
}

export type DownloadMenuButtonProps = {
  id?: string
  dataCy?: string
  dataTestId?: string
  buttonStyle?: ButtonStyle
  buttonSize?: ButtonSize
  classNames?: string
  cta?: string
  initialIcon?: string
}

type MenuProps = {
  backgroundProps?: {
    dataCy?: string
  }
  buttonProps?: DownloadMenuButtonProps
  defaultIconName?: string
}

export function DownloadMenu({
  menuItems,
  menuProps = {},
  includeSearchBar = false,
  hideCaret = false,
}: {
  menuItems: Array<MenuItem>
  menuProps: MenuProps
  includeSearchBar?: boolean
  hideCaret?: boolean
}) {
  const [showMenu, setShowMenu] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [searchText, setSearchText] = useState<string>('')

  const { backgroundProps, buttonProps, defaultIconName } = menuProps
  const buttonClasses = twx(styles.dropdownButton, buttonProps?.classNames)
  const filteredMenuItems = menuItems.filter(
    (i) =>
      i.isVisible &&
      (!searchText || i.title.toLowerCase().includes(searchText.toLowerCase()))
  )
  const btnLabel = buttonProps?.cta === undefined ? 'Download' : buttonProps.cta

  return (
    <div className={styles.menuHolder}>
      {showMenu && (
        <div
          className={styles.menuBackground}
          data-cy={backgroundProps?.dataCy}
          onClick={() => setShowMenu(false)}
        />
      )}
      <AsyncIconButton
        buttonStyle={buttonProps?.buttonStyle ?? 'secondary-outline'}
        buttonSize={buttonProps?.buttonSize}
        isLoading={isLoading}
        disabled={isLoading}
        className={buttonClasses}
        id={buttonProps?.id}
        data-cy={buttonProps?.dataCy}
        initialIcon={buttonProps?.initialIcon ?? 'fa-download'}
        data-testid={buttonProps?.dataTestId}
        onClick={() => {
          setShowMenu(!showMenu)
        }}
      >
        {btnLabel}
        {!hideCaret && (
          <i
            className={twx('fa', {
              'fa-caret-up': showMenu,
              'fa-caret-down': !showMenu,
              'ml-[8px]': btnLabel,
            })}
          />
        )}
      </AsyncIconButton>
      {showMenu && (
        <menu className={styles.menu}>
          {includeSearchBar && (
            <li className={tw`p-[16px]`}>
              <SearchBox onChange={setSearchText} value={searchText} />
            </li>
          )}
          {includeSearchBar && searchText && filteredMenuItems.length === 0 && (
            <li className={tw`text-center`}>No match found</li>
          )}
          {filteredMenuItems.map((item) => {
            const icon = item.iconName || defaultIconName
            return (
              <li key={item.id} className={styles.menuItem}>
                <button
                  className={styles.menuItemButton}
                  disabled={item.isDisabled}
                  id={item.id}
                  data-testid={item?.dataTestId}
                  onClick={async () => {
                    try {
                      setIsLoading(true)
                      setShowMenu(false)
                      await item.onClick()
                      setIsLoading(false)
                    } finally {
                      setIsLoading(false)
                    }
                  }}
                >
                  {icon && (
                    <Icon name={icon} className={tw`mr-[8px] text-gray-08`} />
                  )}
                  {item.title}
                </button>
              </li>
            )
          })}
        </menu>
      )}
    </div>
  )
}
