import { BasicSpinner } from '@shared/components/BasicSpinner'
import { tw } from '@shared/utils/tailwind'
import { useCustomFormContext } from '@app/components/Prospects/Forms/CustomForm/CustomFormContext'
import useFormImage from '@app/hooks/useFormImage'

type Props = {
  name: string
  description: string
}

export default function FormImage(props: Props) {
  const { name, description } = props
  const signableForm = useCustomFormContext()

  const { imageData } = useFormImage({
    signableFormId: signableForm!.id!,
    key: name.split('.')[1],
  })
  if (imageData.tag === 'Loading') {
    return (
      <div className={tw`mx-auto mb-[32px] max-w-[200px]`}>
        <BasicSpinner />
      </div>
    )
  } else if (imageData.tag === 'Error') {
    if (imageData.value.status === 404) {
      return (
        <div className={tw`mx-auto mb-[32px] text-tags-red`}>
          No image configured for form.
        </div>
      )
    } else {
      return (
        <div className={tw`mx-auto mb-[32px] text-tags-red`}>
          Image failed to load.
        </div>
      )
    }
  } else {
    return (
      <img
        className={tw`mx-auto mb-[32px] max-w-[200px]`}
        src={imageData.value}
        alt={description}
      />
    )
  }
}
