import { useState } from 'react'
import Preview from './Preview'
import Setup from './Setup'
import { PopupProps, SettingsData } from './types'

export default function BulkRoomsPopup(props: PopupProps) {
  const [previewData, setPreviewData] = useState<SettingsData | undefined>()
  const onSettingsPreview = (settings: SettingsData) => {
    setPreviewData(settings)
  }

  if (previewData) {
    return (
      <Preview
        {...props}
        data={previewData}
        onClose={() => setPreviewData(undefined)}
      />
    )
  }

  return <Setup {...props} onSave={onSettingsPreview} />
}
