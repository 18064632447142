import { Array, pipe, String } from 'effect'
import { ClassNameValue } from 'tailwind-merge'
import { HiddenLabelCheckbox } from '@shared/components/BasicInput/BasicInput'
import {
  SummarySectionList,
  SummarySectionListItem,
} from '@shared/components/LinkCard/SummarySection'
import { NEWLINE } from '@shared/constants/parsing'
import {
  RoutineAdministration,
  RoutineAdministrationProgressType,
} from '@shared/types/careapp'
import { getLabels } from '@shared/utils/routineAdministration'
import {
  getIconNameAndClassByStatus,
  getSummaryTitle,
} from '@shared/utils/routineAdministrationProgressType'
import { tw, twx } from '@shared/utils/tailwind'

export type RoutineListItemProps = {
  administration: RoutineAdministration
  facilityTimeZone: string
  progressType: RoutineAdministrationProgressType
  itemsCount: number
  idx: number
  liClass?: ClassNameValue
  onAdministrationClick?: (administration: RoutineAdministration) => void
  leftSlot?: JSX.Element
  hideVerticalBar?: boolean
  innerVerticalPaddingClassName?: string
}
export function RoutineAdministrationListItem({
  administration,
  facilityTimeZone,
  progressType,
  liClass,
  onAdministrationClick,
  leftSlot,
  hideVerticalBar,
  itemsCount,
  idx,
  innerVerticalPaddingClassName,
}: RoutineListItemProps) {
  const { barColorClass, iconName, iconClass } =
    getIconNameAndClassByStatus(progressType)
  const { label, subLabel, note } = getLabels({
    routineAdministration: administration,
    type: progressType,
    facilityTimeZone,
  })

  return (
    <SummarySectionListItem
      key={`summary-item-${progressType}-${administration.id}`}
      iconName={iconName}
      iconClass={iconClass}
      liClass={twx('font-medium text-gray-07', liClass)}
      onLiClick={
        onAdministrationClick
          ? () => onAdministrationClick(administration)
          : undefined
      }
      barColor={hideVerticalBar ? undefined : barColorClass}
      leftSlot={leftSlot}
      itemsCount={itemsCount}
      idx={idx}
      innerVerticalPaddingClassName={innerVerticalPaddingClassName}
    >
      <span className={tw`font-semibold text-secondary-04`}>{label}</span>
      {(subLabel || note) && (
        <div className={tw`flex flex-col flex-wrap gap-layout-gap-xxs`}>
          {subLabel && <span>{subLabel}</span>}
          {note &&
            pipe(
              note,
              String.split(NEWLINE),
              Array.map((line, i) => <span key={i}>{line}</span>)
            )}
        </div>
      )}
    </SummarySectionListItem>
  )
}

export function CheckableRoutineAdministrationListItem({
  progressType,
  administration,
  facilityTimeZone,
  onAdministrationClick,
  liClass,
  itemsCount,
  idx,
  selectedProgressTypes,
  selectedAdministrations,
  toggleAdministration,
}: RoutineListItemProps & {
  selectedProgressTypes: RoutineAdministrationProgressType[]
  selectedAdministrations: RoutineAdministration[]
  toggleAdministration: (administration: RoutineAdministration) => void
}) {
  const buildListItemLeftSlot = () => {
    const showCheckbox =
      selectedProgressTypes?.length === 1 &&
      selectedProgressTypes[0] === progressType

    if (showCheckbox) {
      const handleOnChangeChecked = () => {
        toggleAdministration(administration)
      }

      const checked = Boolean(
        selectedAdministrations?.find(({ id }) => id === administration.id)
      )

      return (
        <HiddenLabelCheckbox checked={checked} onChange={handleOnChangeChecked}>
          Select for bulk action
        </HiddenLabelCheckbox>
      )
    } else {
      return <></>
    }
  }

  return (
    <RoutineAdministrationListItem
      administration={administration}
      onAdministrationClick={onAdministrationClick}
      facilityTimeZone={facilityTimeZone}
      progressType={progressType}
      liClass={liClass}
      leftSlot={buildListItemLeftSlot()}
      itemsCount={itemsCount}
      idx={idx}
    />
  )
}

export default function RoutineAdministrationsStatusSummary({
  onAdministrationClick,
  routineAdministrationMap,
  hideTitle,
  liClass,
  showBgColor,
  facilityTimeZone,
  ListItem,
}: {
  routineAdministrationMap: Partial<
    Record<RoutineAdministrationProgressType, RoutineAdministration[]>
  >
  onAdministrationClick?: (admin: RoutineAdministration) => void
  hideTitle?: boolean
  liClass?: string
  showBgColor?: boolean
  facilityTimeZone: string
  ListItem: (props: RoutineListItemProps) => JSX.Element
}) {
  return (
    <div className={tw`flex w-full flex-col gap-layout-gap`}>
      {Object.entries(routineAdministrationMap).map(
        ([key, administrations]) => {
          const progressType = key as RoutineAdministrationProgressType
          const { bgColor } = getIconNameAndClassByStatus(progressType)
          const title =
            `${getSummaryTitle(progressType)} (${administrations.length})`.toUpperCase()

          if (administrations.length) {
            return (
              <SummarySectionList
                key={title}
                title={hideTitle ? '' : title}
                bgColor={showBgColor ? bgColor : undefined}
              >
                {administrations.map((administration, idx) => {
                  return (
                    <ListItem
                      key={administration.id}
                      progressType={progressType}
                      administration={administration}
                      facilityTimeZone={facilityTimeZone}
                      liClass={liClass}
                      onAdministrationClick={onAdministrationClick}
                      itemsCount={administrations.length}
                      idx={idx}
                    />
                  )
                })}
              </SummarySectionList>
            )
          } else return null
        }
      )}
    </div>
  )
}
