import { useState } from 'react'
import { EmptyCard } from '@shared/components/EmptyCard'
import { Person } from '@shared/types/person'
import BulletedSection from '@app/components/BulletedSection'
import styles from './styles.module.css'
import ScheduleRateChange from '../AddCharge/SchedulRateChange'
import CancelScheduledRateChange from './CancelScheduledRateChange'
import { DeleteCharge } from './DeleteCharge'
import { useCharges } from './hooks'
import { OneTimeCharge } from './OneTimeCharge'
import OneTimeChargeRow from './OneTimeChargeRow'
import { RecurringCharge } from './RecurringCharge'
import RecurringChargeCard from './RecurringChargeCard'

interface Props {
  person: Person
  newChargeAdded?: string
  addNewCharge: () => void
}

export default function List({ person, addNewCharge, newChargeAdded }: Props) {
  const [chargeToConfirmDelete, setChargeToConfirmDelete] = useState<
    RecurringCharge | OneTimeCharge | undefined
  >()
  const [chargeToChangeRate, setChargeToChangeRate] = useState<
    RecurringCharge | undefined
  >()
  const [chargeToCancelRateChange, setChargeToCancelRateChange] = useState<
    { charge: RecurringCharge; rateIndex: number } | undefined
  >()
  const [forceUpdate, setForceUpdate] = useState(0)

  const { onetimeCharges, recurringCharges, getOriginalCharge } = useCharges(
    person,
    [newChargeAdded, forceUpdate]
  )

  if (onetimeCharges === undefined || recurringCharges === undefined) {
    return null
  }

  if (onetimeCharges.length === 0 && recurringCharges.length === 0) {
    return <EmptyCharges addNewCharge={addNewCharge} />
  }

  return (
    <>
      {chargeToChangeRate && (
        <ScheduleRateChange
          person={person}
          charge={getOriginalCharge(chargeToChangeRate)!}
          done={() => {
            setChargeToChangeRate(undefined)
            setForceUpdate(forceUpdate + 1)
          }}
        />
      )}

      {chargeToConfirmDelete && (
        <DeleteCharge
          chargeToDelete={chargeToConfirmDelete}
          person={person}
          done={(deleted) => {
            setChargeToConfirmDelete(undefined)
            if (deleted) {
              setForceUpdate(forceUpdate + 1)
            }
          }}
        />
      )}

      {chargeToCancelRateChange && (
        <CancelScheduledRateChange
          charge={getOriginalCharge(chargeToCancelRateChange.charge)!}
          rateIndex={chargeToCancelRateChange.rateIndex}
          person={person}
          done={(cancelled) => {
            setChargeToCancelRateChange(undefined)
            if (cancelled) {
              setForceUpdate(forceUpdate + 1)
            }
          }}
        />
      )}

      <BulletedSection containerClasses="mb-[24px]">
        Recurring Charges
      </BulletedSection>

      {recurringCharges.length === 0 && (
        <EmptyCharges addNewCharge={addNewCharge} />
      )}

      {recurringCharges.length > 0 &&
        recurringCharges.map((recurringCharge) => {
          return (
            <RecurringChargeCard
              onChangeRate={setChargeToChangeRate}
              onCancelScheduledRate={(charge, rateIndex) => {
                setChargeToCancelRateChange({ charge, rateIndex })
              }}
              onDelete={setChargeToConfirmDelete}
              key={recurringCharge.id}
              charge={recurringCharge}
            />
          )
        })}

      <BulletedSection containerClasses="mb-[24px] mt-[32px]">
        One-Time Charges
      </BulletedSection>

      {onetimeCharges.length === 0 && (
        <EmptyCharges addNewCharge={addNewCharge} />
      )}

      {onetimeCharges.length > 0 &&
        onetimeChargesSection(onetimeCharges, setChargeToConfirmDelete)}
    </>
  )
}

const EmptyCharges = ({ addNewCharge }: { addNewCharge: () => void }) => (
  <div>
    <EmptyCard
      version={{
        tag: 'GetStarted',
        cta: 'add a charge',
        ctaFn: () => addNewCharge(),
      }}
      title="No charges yet."
    />
  </div>
)

function onetimeChargesSection(
  onetimeCharges: OneTimeCharge[],
  onDelete: (charge: OneTimeCharge) => void
) {
  return (
    <div className={styles.container}>
      <table id="oneTimeCharges" className={styles.table}>
        <thead>
          <tr className={styles.headingRow}>
            <th className={styles.columnHeading}>Date</th>
            <th className={styles.columnHeading}>Description</th>
            <th className={styles.columnHeading}>Payer</th>
            <th className={styles.columnHeading}>Amount</th>
            <th className={styles.columnHeading}></th>
          </tr>
        </thead>
        <tbody>
          {onetimeCharges.map((onetimeCharge) => (
            <OneTimeChargeRow
              charge={onetimeCharge}
              onDelete={onDelete}
              key={onetimeCharge.id}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}
