import { download } from '@shared/api/legacy'
import { Person } from '@shared/types/person'
import { fetchMedListPdf } from '@app/api/medications'

export async function downloadMedListPDF(
  person: Person,
  physicianReview?: boolean
) {
  const fileUrl = await fetchMedListPdf({
    orgId: `${person.orgId}`,
    facilityId: `${person.facilityId}`,
    personId: `${person.id}`,
    physicianReview,
  })

  await download({
    fileUrl,
    target: '_blank',
  })
}
