import { ErrorCode } from '@augusthealth/models/com/august/protos/api/error_response'
import { MedicationStatus } from '@augusthealth/models/com/august/protos/medication_statement'
import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import useCurrentPage, { extractIds } from '@shared/hooks/useCurrentPage'
import { medicationsPathForPerson } from '@shared/legacy_routes'
import {
  DosageDeviation,
  DosageTransitionStrategy,
} from '@shared/types/dosage_deviation'
import {
  MedicationOrder,
  MedOrderFormData,
} from '@shared/types/medication_order'
import { Person } from '@shared/types/person'
import { DeepNull } from '@shared/types/utilities'
import { fromDateToDateMessage } from '@shared/utils/date'
import { AugustError } from '@shared/utils/error'
import { getOrElse } from '@shared/utils/loading'
import { isDiscontinued } from '@shared/utils/medicationStatement'
import {
  reactivateMedicationOrder,
  updateMedicationOrder,
} from '@app/api/medicationOrders'
import ConfirmModal from '@app/components/ConfirmModal'
import {
  noBtnDefaults,
  yesBtnDefaults,
} from '@app/components/FullpageFormFooter'
import { DosageDeviationModal } from '@app/components/Residents/Medications/EditMedication/DosageDeviationModal'
import { getMedicationBreadCrumbText } from '@app/components/Residents/Medications/Orders/helpers'
import { MedicationOrderLoading } from '@app/components/Residents/Medications/Orders/MedicationOrderDetails/layout'
import {
  mapFormMedToMedOrder,
  mapMedOrderToFormData,
} from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'
import ReviewMedicationOrderContents from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewMedicationOrderContents'
import styles from '@app/components/Residents/Medications/Orders/styles.module.css'
import { useMedicationOrder } from '@app/hooks/useMedications'
import useMedPasses from '@app/hooks/useMedPasses'
import { usePersonInContext } from '@app/hooks/usePerson'

export const EditMedication = () => {
  const page = useCurrentPage()
  const { orgId, facilityId, personId, medicationId } = extractIds(page)
  const { person } = usePersonInContext({
    initialData: {
      orgId,
      facilityId,
      id: personId,
    },
  })
  const { medicationOrder } = useMedicationOrder(medicationId!)
  const history = useHistory()
  const methods = useForm<MedOrderFormData>({
    mode: 'onSubmit',
  })

  const { medPasses } = useMedPasses({
    facility: { id: facilityId!, orgId: orgId! },
  })

  const [showDiscontinueConfirmation, setShowDiscontinueConfirmation] =
    useState(false)
  const [showReactivateConfirmation, setShowReactivateConfirmation] =
    useState(false)
  const [dosageDeviation, setDosageDeviation] =
    useState<DosageDeviation | null>(null)
  const [dosageTransitionStrategy, setDosageTransitionStrategy] =
    useState<DosageTransitionStrategy | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { setError } = useContext(GlobalContext)
  const { user } = useUserContext()
  const allowDosageDeviation = true

  const returnToMedicationsList = () => {
    history.push(medicationsPathForPerson(person as Required<Person>))
  }

  useEffect(() => {
    if (medicationOrder.tag === 'Complete') {
      if (medicationOrder.value === null) {
        return void returnToMedicationsList()
      }
      methods.reset(mapMedOrderToFormData(medicationOrder.value))
    }
  }, [medicationOrder.tag])

  if (!person || medicationOrder.tag === 'Loading') {
    return <MedicationOrderLoading title={'Loading Order'} />
  }

  if (!medicationOrder.value) {
    return null
  }

  const canReactivate = hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_MEDICATION_REACTIVATE],
  })
  const canEdit = hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_MEDICATION_UPDATE],
  })
  const maybeApplyDosageTransition = (medOrder: DeepNull<MedicationOrder>) => {
    if (
      dosageDeviation &&
      dosageTransitionStrategy !==
        DosageTransitionStrategy.DOSAGE_TRANSITION_STRATEGY_UNSPECIFIED
    ) {
      return {
        ...medOrder,
        dosageTransition: {
          strategy: dosageTransitionStrategy,
          deviation: dosageDeviation,
        },
      }
    } else {
      return medOrder
    }
  }

  const medOrder = medicationOrder.value

  async function discontinueMedication() {
    setIsSubmitting(true)
    try {
      const newMedOrder: MedicationOrder = {
        ...medOrder,
        medicationStatement: {
          ...medOrder.medicationStatement,
          statusEnum: MedicationStatus.MEDICATION_STATUS_STOPPED,
          effectivePeriod: {
            ...medOrder?.medicationStatement?.effectivePeriod,
            endDate: fromDateToDateMessage(new Date()),
          },
        },
      }

      await updateMedicationOrder(person!, medicationId!, newMedOrder, {
        allowDosageDeviation,
      })
      returnToMedicationsList()
    } catch (e) {
      setError(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  const updateOrder = async () => {
    const formData = methods.getValues()

    try {
      const updatedWithFormData = mapFormMedToMedOrder({ formData, medOrder })

      const response = await updateMedicationOrder(
        person,
        medicationId!,
        maybeApplyDosageTransition(updatedWithFormData),
        { allowDosageDeviation }
      )

      if (response.id !== medicationId!) {
        history.push(medicationsPathForPerson(person), {
          highlightId: response.id,
          statusChange: 'Saved',
        })
      } else {
        history.push(medicationsPathForPerson(person), {
          statusChange: 'Saved',
        })
      }
    } catch (error) {
      methods.reset(formData, {
        keepDirtyValues: true,
        keepTouched: true,
        keepDirty: true,
      })

      const { code, data } =
        (error as AugustError | undefined)?.json?.errors?.[0] || {}

      if (code === ErrorCode.ERROR_CODE_DOSAGE_TRANSITION_REQUIRED) {
        setDosageDeviation(data.deviation as DosageDeviation)
      } else {
        setDosageDeviation(null)
        setError(error)
      }
    }
  }

  async function handleReactivate() {
    setIsSubmitting(true)
    try {
      const response = await reactivateMedicationOrder({
        orgId: orgId!,
        facilityId: facilityId!,
        personId: personId!,
        orderGroupId: medOrder.orderGroupId!,
      })

      history.push(medicationsPathForPerson(person as Required<Person>), {
        highlightId: response.id,
        statusChange: 'Saved',
      })
    } catch (e) {
      setError(e)
    } finally {
      setIsSubmitting(false)
      setShowReactivateConfirmation(false)
    }
  }

  return (
    <>
      {showDiscontinueConfirmation && (
        <ConfirmModal
          testId={'discontinue-med-modal'}
          title={'Discontinue this medication order?'}
          confirmButtonConfig={{
            children: 'Discontinue',
            onClick: discontinueMedication,
          }}
          denyButtonConfig={{
            onClick: () => setShowDiscontinueConfirmation(false),
          }}
        />
      )}
      {showReactivateConfirmation && (
        <ConfirmModal
          testId={'reactivate-med-modal'}
          title={'Reactivate this medication order?'}
          confirmButtonConfig={{
            children: 'Confirm reactivation',
            onClick: handleReactivate,
          }}
          denyButtonConfig={{
            onClick: () => setShowReactivateConfirmation(false),
          }}
        />
      )}
      {dosageDeviation && (
        <DosageDeviationModal
          originalMedicationOrder={medOrder}
          updatedMedicationOrder={
            mapFormMedToMedOrder({
              formData: methods.getValues(),
              medOrder,
            }) as MedicationOrder
          }
          person={person}
          dosageDeviation={dosageDeviation}
          setDosageTransitionStrategy={setDosageTransitionStrategy}
          dosageTransitionStrategy={dosageTransitionStrategy}
          closeModal={() => {
            setDosageTransitionStrategy(null)
            setDosageDeviation(null)
          }}
          onConfirm={updateOrder}
          medPasses={getOrElse(medPasses, [])}
        />
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(updateOrder)}>
          <ReviewMedicationOrderContents
            medicationOrder={medOrder}
            pageTitle={getMedicationBreadCrumbText(medOrder, true)}
            mode={'edit'}
            canEdit={canEdit}
            user={user}
          />

          <footer className={styles.footer}>
            <div className={styles.footerContent}>
              {!isDiscontinued(medOrder.medicationStatement!) ? (
                <AsyncIconButton
                  buttonStyle={'danger-outline'}
                  width={'109px'}
                  type="button"
                  onClick={() => setShowDiscontinueConfirmation(true)}
                  disabled={
                    !canEdit || isSubmitting || methods.formState.isSubmitting
                  }
                >
                  Discontinue
                </AsyncIconButton>
              ) : (
                <AsyncIconButton
                  buttonStyle={'primary-light-fill'}
                  width={'109px'}
                  type="button"
                  onClick={() => setShowReactivateConfirmation(true)}
                  disabled={
                    isSubmitting ||
                    methods.formState.isSubmitting ||
                    !canReactivate
                  }
                >
                  Reactivate
                </AsyncIconButton>
              )}
              <div className="flex w-[320px] justify-between">
                <AsyncIconButton
                  onClick={returnToMedicationsList}
                  {...{
                    ...noBtnDefaults,
                    width: '152px',
                  }}
                  disabled={isSubmitting || methods.formState.isSubmitting}
                >
                  Cancel
                </AsyncIconButton>
                <AsyncIconButton
                  disabled={
                    !canEdit ||
                    isSubmitting ||
                    isDiscontinued(medOrder.medicationStatement!) ||
                    methods.formState.isSubmitting ||
                    !methods.formState.isDirty
                  }
                  {...{
                    ...yesBtnDefaults,
                    width: '152px',
                  }}
                >
                  Save
                </AsyncIconButton>
              </div>
            </div>
          </footer>
        </form>
      </FormProvider>
    </>
  )
}
