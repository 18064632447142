import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useEffect, useState } from 'react'
import { download } from '@shared/api/legacy'
import { hasPermissionForFacility } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import {
  apiOneTimeChargesCsvReportUrl,
  getReportUrl,
} from '@shared/legacy_routes'
import { FacilityIds } from '@shared/types/facility'
import { UserAccount } from '@shared/types/user'
import { convertEnumValueToLabel } from '@shared/utils/common'
import { printFileFromIFrame } from '@shared/utils/iframePrint'
import { fetchReportNamesByFacility, getReportCsvUrl } from '../api/reports'
import { MenuItem } from '../components/DownloadMenu'
import FacilitiesContext from '../contexts/FacilitiesContext'
import { formatReportName } from '../pages/Facilities/Reports/helpers'
import PreviewLightbox from '../pages/Facilities/Reports/PreviewLightbox'

export const ONE_TIME_CHARGES_REPORT_NAME = 'one_time_charges'

export default function useReportMenuAndPreview({
  facility,
}: {
  facility: FacilityIds
}) {
  const { orgId, id: facilityId } = facility
  const { setError } = useContext(GlobalContext)
  const { user } = useUserContext()

  // props.facility only has id and orgId, need to get Facility Name from FacilitiesContext
  const { facilities } = useContext(FacilitiesContext)
  const currentFacility = facilities?.find((f) => f.id === facilityId)

  const [reportNames, setReportNames] = useState<string[]>([])
  const [reportToView, setReportToView] = useState<string>()
  const [printIFrameIsLoading, setPrintIFrameIsLoading] =
    useState<boolean>(false)
  const hasReportPermission = canAccessReports(user, facility)
  const limitedRead = !hasFullReadPermission(user, facility)
  const includeOneTimeCharges = hasFullReadPermission(user, facility)

  useEffect(() => {
    if (hasReportPermission) {
      fetchReportNamesByFacility(orgId, facilityId, { limitedRead })
        .then((res) =>
          setReportNames(
            [
              ...res.data,
              ...(includeOneTimeCharges ? [ONE_TIME_CHARGES_REPORT_NAME] : []),
            ].sort()
          )
        )
        .catch(setError)
    }
  }, [orgId, facilityId])

  const reportMenuItems: MenuItem[] = reportNames.map(
    reportNameToMenuItem(setReportToView)
  )

  const isOneTimeChargesReport = reportToView === ONE_TIME_CHARGES_REPORT_NAME

  const previewLightbox = reportToView ? (
    <PreviewLightbox
      name={reportToView}
      orgId={orgId}
      facilityId={facilityId}
      download={() => {
        const fileUrl = isOneTimeChargesReport
          ? apiOneTimeChargesCsvReportUrl({ orgId, facilityId })
          : getReportCsvUrl(orgId, facilityId, reportToView)
        const reportName = convertEnumValueToLabel(reportToView)
        const facilityName = convertEnumValueToLabel(
          currentFacility?.name || ''
        )
        download({
          fileUrl,
          fileName: `${reportName} - ${facilityName}.csv`,
        }).catch(setError)
      }}
      print={() => {
        const reportUrl = getReportUrl(orgId, facilityId, reportToView)
        printFileFromIFrame(reportUrl, {
          eventName: 'reportLoaded',
          onIframeAppended: () => setPrintIFrameIsLoading(true),
          onPrint: () => setPrintIFrameIsLoading(false),
        })
      }}
      printIFrameIsLoading={printIFrameIsLoading}
      onClose={() => setReportToView(undefined)}
    />
  ) : null

  return {
    reportMenuItems,
    previewLightbox,
  }
}

function canAccessReports(user: UserAccount, facility: FacilityIds) {
  return (
    hasPermissionForFacility({
      user,
      facility,
      permissions: [GroupPermission.GROUP_PERMISSION_REPORT_LIMITED_READ],
    }) ||
    hasPermissionForFacility({
      user,
      facility,
      permissions: [GroupPermission.GROUP_PERMISSION_REPORT_READ],
    })
  )
}

/**
 * It should run after canAccessReports(...).
 * Don't need to check if at least GROUP_PERMISSION_REPORT_LIMITED_READ exists.
 */
function hasFullReadPermission(user: UserAccount, facility: FacilityIds) {
  // If GROUP_PERMISSION_REPORT_READ found, you can read MORE than LimitedRead
  return hasPermissionForFacility({
    user,
    facility,
    permissions: [GroupPermission.GROUP_PERMISSION_REPORT_READ],
  })
}

function reportNameToMenuItem(
  setReportToView: (n: string) => void
): (name: string) => MenuItem {
  return (name) => {
    return {
      isDisabled: false,
      isVisible: true,
      id: name,
      onClick: async () => {
        setReportToView(name)
        await Promise.resolve()
      },
      dataTestId: `view_${name}`,
      title: formatReportName(name),
      iconName: 'table',
    }
  }
}
