import { fetchAnything } from '@shared/api/request'
import { unsubscribeUrl } from '@shared/api/urls'
import { Result } from '@shared/utils/loading'

interface UnsubscribeRequest {
  code: string
}

export async function unsubscribe({
  code,
}: UnsubscribeRequest): Promise<Result<string, unknown>> {
  try {
    const response = await fetchAnything(unsubscribeUrl(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code }),
    })

    if (response.ok) {
      return { tag: 'Complete', value: response.statusText }
    } else {
      return { tag: 'Error', value: response.statusText }
    }
  } catch (e) {
    return { tag: 'Error', value: e }
  }
}
