import { IncidentAction } from '@augusthealth/models/com/august/protos/incidents'
import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { download } from '@shared/api/legacy'
import { incidentActionPdfUrl } from '@shared/api/urls'
import { BasicSpinner } from '@shared/components/BasicSpinner'
import ButtonLink from '@shared/components/ButtonLink'
import GlobalContext from '@shared/contexts/GlobalContext'
import { getStateIncidentReportUrl } from '@shared/legacy_routes'
import { Incident } from '@shared/types/incidents'
import { Person } from '@shared/types/person'
import { DataType } from '@shared/types/snapshot'
import { incompleteTask } from '@shared/utils/task'
import PersonContext from '@app/contexts/PersonContext'
import { recreateTaskAndReturnUrl } from '@app/pages/Documents/helpers'

export function Download624Link({
  incident,
  person,
}: {
  incident: Incident
  person: Person
}) {
  const { setError } = useContext(GlobalContext)
  const [downloading, setDownloading] = useState(false)

  const onClickDownload = async () => {
    setDownloading(true)
    try {
      await download({
        fileUrl: getStateIncidentReportUrl(
          person as Required<Person>,
          incident as Required<Incident>
        ),
        target: '_blank',
      })
    } catch (e) {
      setError(e)
    } finally {
      setDownloading(false)
    }
  }

  return (
    <ButtonLink data-testid="download-624-link" onClick={onClickDownload}>
      Download
      {downloading && (
        <BasicSpinner
          className="ml-[8px] text-primary-light"
          data-testid="spinner-624"
        />
      )}
    </ButtonLink>
  )
}

export function ServicePlanLink({ person }: { person: Person }) {
  const history = useHistory()
  const { setError } = useContext(GlobalContext)
  const { tasks } = useContext(PersonContext)
  const [loading, setLoading] = useState(false)

  const task = incompleteTask(tasks, DataType.DATA_TYPE_SERVICE_PLAN, undefined)

  return (
    <ButtonLink
      data-testid="service-plan-link"
      onClick={async () => {
        try {
          setLoading(true)
          const url = await recreateTaskAndReturnUrl(
            person,
            task,
            DataType.DATA_TYPE_SERVICE_PLAN
          )
          setLoading(false)
          history.push(url)
        } catch (e) {
          setError(e)
          setLoading(false)
        }
      }}
    >
      Get Started
      {loading && (
        <BasicSpinner
          className="ml-[8px] text-primary-light"
          data-testid="spinner-service-plan"
        />
      )}
    </ButtonLink>
  )
}

export function DownloadCustomActionPDF({
  incident,
  person,
  action,
}: {
  incident: Incident
  person: Person
  action: IncidentAction
}) {
  const { setError } = useContext(GlobalContext)
  const [downloading, setDownloading] = useState(false)

  const onClickDownload = async () => {
    setDownloading(true)
    try {
      await download({
        fileUrl: incidentActionPdfUrl({
          orgId: person.orgId,
          facilityId: person.facilityId,
          personId: person.id,
          incidentId: incident.id!,
          actionId: action.id!,
        }),
        target: '_blank',
      })
    } catch (e) {
      setError(e)
    } finally {
      setDownloading(false)
    }
  }

  return (
    <ButtonLink data-testid="download-624-link" onClick={onClickDownload}>
      Download
      {downloading && (
        <BasicSpinner
          className="ml-[8px] text-primary-light"
          data-testid="spinner-624"
        />
      )}
    </ButtonLink>
  )
}
