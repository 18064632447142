import { Contact } from '@augusthealth/models/com/august/protos/contact'
import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { Person } from '@shared/types/person'
import {
  isPrimaryCareProvider,
  isResponsiblePerson,
} from '@shared/utils/contact'
import notEmpty from '@shared/utils/notEmpty'
import {
  isPrimaryCareProviderEligible,
  isResponsiblePersonEligible,
} from '@shared/utils/relationship'
import {
  deleteContact,
  updateContactGeneralPractitioner,
  updateContactResponsibleParty,
} from '@app/api/contacts'

interface ContactActionType {
  adminAction: (contact: Contact, person: Person) => void
  name: string
  iconClass: string
  label: string
  permissions: GroupPermission[]
}

export function allContactActions({
  onRemove,
  onMakeContactRP,
  onMakeContactPrimaryCare,
  contact,
}: {
  contact: Contact
  onRemove: (contact: Contact) => void
  onMakeContactRP: (contact: Contact) => void
  onMakeContactPrimaryCare: (contact: Contact) => void
}): ContactActionType[] {
  const isMainRole =
    isResponsiblePerson(contact) || isPrimaryCareProvider(contact)
  const { relationship } = contact

  return [
    {
      adminAction: onRemove,
      name: `remove-${contact.id}`,
      iconClass: isMainRole ? 'fas fa-lock' : 'fa fa-trash-can',
      label: 'Remove',
      permissions: [GroupPermission.GROUP_PERMISSION_PERSON_CONTACT_DELETE],
    },
    relationship && !isMainRole && isResponsiblePersonEligible(relationship)
      ? {
          adminAction: onMakeContactRP,
          name: `make-rp-${contact.id}`,
          iconClass: 'fas fa-user',
          label: 'Make Responsible Person',
          permissions: [GroupPermission.GROUP_PERMISSION_PERSON_CONTACT_SET_RP],
        }
      : undefined,
    relationship && !isMainRole && isPrimaryCareProviderEligible(relationship)
      ? {
          adminAction: onMakeContactPrimaryCare,
          name: `make-pcp-${contact.id}`,
          iconClass: 'fas fa-user',
          label: 'Make Primary Physician',
          permissions: [GroupPermission.GROUP_PERMISSION_PERSON_CONTACT_UPDATE],
        }
      : undefined,
  ].filter(notEmpty)
}

export const removeContact = (deletedContact: Contact, person: Person) =>
  deleteContact({
    contactId: +deletedContact.id!,
    orgId: person.orgId!,
    facilityId: person.facilityId!,
    personId: person.id!,
  })
export const makeContactRP = (contact: Contact, person: Person) =>
  updateContactResponsibleParty({
    contactId: +contact.id!,
    orgId: person.orgId!,
    facilityId: person.facilityId!,
    personId: person.id!,
  })
export const makeContactPrimaryCare = (contact: Contact, person: Person) =>
  updateContactGeneralPractitioner({
    contactId: +contact.id!,
    orgId: person.orgId!,
    facilityId: person.facilityId!,
    personId: person.id!,
  })
