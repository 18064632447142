import { startOfToday } from 'date-fns'
import { History } from 'history'
import { useContext } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Badge from '@shared/components/Badge'
import GlobalContext from '@shared/contexts/GlobalContext'
import { carePathForPerson } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { fromDateToDateMessage } from '@shared/utils/date'
import { AsyncResult, getOrElse, mapAsyncResult } from '@shared/utils/loading'
import { createRoutineOrder } from '@app/api/routineOrders'
import PersonContext from '@app/contexts/PersonContext'
import useAppraisalSettingsCategories from '@app/hooks/useAppraisalSettingsCategories'
import { toRoutineSchedule } from './helpers'
import RoutineOrderForm, {
  CategoryOption,
  RoutineOrderFormData,
  toCategoryOptions,
} from './RoutineOrderForm'

export default function ScheduleCustomRoutineWrapper() {
  const { setError } = useContext(GlobalContext)
  const { person } = useContext(PersonContext)
  const history = useHistory()
  const { categories } = useAppraisalSettingsCategories({
    person,
  })
  const categoryOptions = mapAsyncResult(categories, toCategoryOptions)
  const methods = useForm<RoutineOrderFormData>({
    mode: 'onSubmit',
    defaultValues: {
      effectivePeriod: { startDate: fromDateToDateMessage(startOfToday()) },
    },
  })

  if (person === undefined) {
    return null
  }

  if (categoryOptions.tag === 'Error') {
    setError(categoryOptions.value)

    return (
      <Badge color="darkOrange" className="w-full p-4">
        <i className="fas fa-info-circle mr-2" />
        Error: Could not load assessment categories for routines!
      </Badge>
    )
  }

  return (
    <ScheduleCustomRoutine
      person={person}
      categoryOptions={categoryOptions}
      methods={methods}
      setError={setError}
      history={history}
    />
  )
}

export function ScheduleCustomRoutine({
  person,
  categoryOptions,
  methods,
  history,
  setError,
}: {
  person: Person
  categoryOptions: AsyncResult<CategoryOption[], unknown>
  setError: (error: unknown) => void
  history: History<unknown>
  methods: UseFormReturn<RoutineOrderFormData>
}) {
  const onSubmit = async (data: RoutineOrderFormData) => {
    if (person === undefined) {
      return
    }

    const { name, instructions, effectivePeriod } = data
    const response = await createRoutineOrder(person, {
      name,
      instructions,
      effectivePeriod,
      categoryKey: data.category.value.categoryKey,
      customKey: data.category.value.customKey,
      routineType: { custom: {} },
      schedules: data.schedules.map(toRoutineSchedule),
    })

    if (response.tag === 'Error') {
      setError(response.value)
      methods.reset(data, { keepDirtyValues: true, keepTouched: true })
    } else {
      history.push(carePathForPerson(person))
    }
  }

  return (
    <RoutineOrderForm
      title="Add Routine"
      disabled={false}
      history={history}
      methods={methods}
      onSubmit={onSubmit}
      person={person}
      categoryOptions={getOrElse(categoryOptions, [])}
    />
  )
}
