import { useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import CollapsibleCard from '@shared/components/CollapsibleCard'
import { MedOrderFormData } from '@shared/types/medication_order'
import { MedOrderCardTitle } from '@app/components/Residents/Medications/Orders/MedicationOrderDetails/MedOrderCardTitle'
import { isFormVitalOrder } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'
import styles from '../styles.module.css'
import { ReviewOrderBadge } from './ReviewOrderBadges'

type Props = {
  readOnly?: boolean
}
export default function ReviewOrderTreatmentCard(props: Props) {
  const { readOnly = false } = props
  const [expanded, setExpanded] = useState(false)
  const { control, register } = useFormContext<MedOrderFormData>()
  const formValues = useWatch({ control }) as MedOrderFormData

  const condition = formValues.condition
  const isVital = isFormVitalOrder(formValues)

  const descriptor = isVital ? 'vital' : 'medication'

  return (
    <CollapsibleCard
      data-testid={'treatment-card'}
      defaultExpanded={false}
      enableAutoScrollOnExpand={true}
      collapsedIcon={readOnly ? 'fa-lock text-secondary-07' : undefined}
      cardTitle={
        <div className={styles.treatmentContainer}>
          {!expanded && !!condition ? (
            <>
              <MedOrderCardTitle value={`This ${descriptor} treats`} />
              <ReviewOrderBadge>{condition}</ReviewOrderBadge>
            </>
          ) : (
            <>
              <MedOrderCardTitle
                value={`What condition does this ${descriptor} treat?`}
              />
            </>
          )}
        </div>
      }
      onExpandClick={setExpanded}
    >
      <BasicTextarea
        {...register('condition')}
        className={'min-h-[48px]'}
        disabled={readOnly}
        placeholder={'E.g. Pain'}
        ignoreMaxWidth={true}
      />
    </CollapsibleCard>
  )
}
