import { useContext } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import Card from '@shared/components/Card'
import { EmptyCard } from '@shared/components/EmptyCard'
import { Person } from '@shared/types/person'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { VITALS_INPUT_PROPS_MAP } from '@shared/utils/vitals'
import { printSelectedElements } from '@app/components/Printable'
import VitalsContext from '@app/contexts/VitalsContext'
import { getVitalsForTab } from '@app/pages/Notes/HistoricVitalsModal/helpers'
import LatestVitalsCard from '@app/pages/Notes/Vitals/LatestVitalsCard'
import VitalsTable from '../VitalsTable'
import Chart from './Chart'

export default function VitalsContent({
  person,
  setPrintHistoricVitals,
}: {
  person: Person
  setPrintHistoricVitals: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { activeTab, allVitals } = useContext(VitalsContext)
  const vitalsForTab = getVitalsForTab({ allVitals, activeTab })
  const label = VITALS_INPUT_PROPS_MAP(activeTab!).label
  const title = `${label} for ${getFirstAndLastName(person.name)}`

  return (
    <>
      <h4 className="mb-[24px] flex">
        <div className="flex-1">{title}</div>
        {vitalsForTab.length > 0 && (
          <ButtonLink
            data-testid="print-vitals"
            className="print-hide"
            onClick={() => {
              printSelectedElements(setPrintHistoricVitals)
            }}
          >
            <i className="fas fa-print cursor-pointer text-secondary-06" />
          </ButtonLink>
        )}
      </h4>
      <Card className="mb-[16px] p-[8px]">
        <Chart activeTab={activeTab} vitalsForTab={vitalsForTab} />
      </Card>
      <LatestVitalsCard showDateOfLastEdits={false} />
      {vitalsForTab.length === 0 && (
        <EmptyCard
          version={{
            tag: 'Children',
            children: (
              <div className="mt-[34px]">
                Add a vital to see historical data.
              </div>
            ),
          }}
          title="No vitals here yet."
          data-testid="no-vitals-message"
        />
      )}
      {vitalsForTab.length > 0 && (
        <VitalsTable label={label!} vitalsForTab={vitalsForTab} />
      )}
    </>
  )
}
