import { formatISO } from 'date-fns'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { returnPayment } from '@shared/api/billing'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import {
  BasicCheckbox,
  BasicTextarea,
} from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput } from '@shared/components/Labels'
import GlobalContext from '@shared/contexts/GlobalContext'
import { BillingEvent, ReturnPaymentRequest } from '@shared/types/billing'
import { tw } from '@shared/utils/tailwind'
import { DatePickerWithIsoDateStr } from '@app/components/reactHookForm/RhfDateTimePicker'
import BillingModal from '@app/pages/Facilities/Billing/Modals/components/BillingModal'
import { ModalProps } from '../helpers'

type Props = Pick<ModalProps, 'doneFn' | 'closeFn' | 'residentIds'> & {
  transaction: BillingEvent | undefined
}

type MarkPaymentAsNSFFormData = Pick<
  ReturnPaymentRequest,
  'returnDate' | 'addFee' | 'notes'
>

export default function MarkPaymentAsNSFModal(props: Props) {
  const { setError } = useContext(GlobalContext)
  const { residentIds, transaction, doneFn, closeFn } = props
  const {
    handleSubmit,
    control,
    register,
    formState,
    formState: { disabled },
  } = useForm({
    defaultValues: {
      returnDate: formatISO(new Date(), { representation: 'date' }),
      addFee: false,
      notes: '',
    },
  })

  async function onSubmit(formData: MarkPaymentAsNSFFormData) {
    try {
      await returnPayment({
        residentIds,
        transactionId: transaction!.id,
        returnDate: formData.returnDate,
        addFee: formData.addFee,
        notes: formData.notes,
      })
      await doneFn()
      closeFn()
    } catch (e) {
      setError(e)
    }
  }

  return (
    <BillingModal
      id="mark-payment-as-nsf-modal"
      title="Mark payment as NSF"
      onClose={closeFn}
    >
      <form className={tw`mt-[32px]`} onSubmit={handleSubmit(onSubmit)}>
        <div className={tw`mt-[24px]`}>
          <DatePickerWithIsoDateStr
            control={control}
            labelProps={{
              children: 'Date',
              required: true,
              uppercase: false,
            }}
            name="returnDate"
          />
        </div>

        <div className={tw`mt-[24px]`}>
          <BasicCheckbox {...register('addFee')}>
            <LabelAboveInput uppercase={false}>Add NSF Fee?</LabelAboveInput>
          </BasicCheckbox>
        </div>

        <div className={tw`mt-[24px]`}>
          <LabelAboveInput htmlFor="notes" uppercase={false}>
            Notes
          </LabelAboveInput>
          <BasicTextarea {...register('notes')} />
        </div>

        <div className={tw`mt-[32px]`}>
          <AnimatedPopupFormFooter
            yesBtn={{ props: { disabled } }}
            noBtn={{ action: closeFn }}
            formState={formState}
          />
        </div>
      </form>
    </BillingModal>
  )
}
