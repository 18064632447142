import React from 'react'
import { Page } from '@shared/hooks/useCurrentPage'
import { Facility } from '@shared/types/facility'

export interface SidebarContentsContext {
  facilities: Facility[]
  currentFacility: Facility | undefined
  currentPage: Page
  orgId: string | undefined
}

export default React.createContext<SidebarContentsContext>({
  facilities: [],
  currentFacility: undefined,
  orgId: undefined,
  currentPage: { tag: 'Unknown' },
})
