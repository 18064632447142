import { MedOrderFormDose } from '@shared/types/medication_order'
import {
  VitalEnumToVitalsType,
  VITALS_INPUT_PROPS_MAP,
} from '@shared/utils/vitals'

export const VitalCheck = ({
  dose,
  index,
}: {
  dose: MedOrderFormDose
  index: number
}) => {
  if (dose?.requiredVitals) {
    return (
      <>
        {dose.requiredVitals.map((vi) => {
          const matchingVitalType = vi && VitalEnumToVitalsType(vi)

          const matching = matchingVitalType
            ? VITALS_INPUT_PROPS_MAP(matchingVitalType)
            : null
          if (matching) {
            return (
              <div
                key={`${vi}-dose-${index}`}
                className={
                  'mb-[16px] text-[14px] font-semibold capitalize text-gray-01'
                }
              >
                <i
                  className={`${matching.iconClassName} mr-[8px] text-primary-light`}
                />
                Check {matching.label}
              </div>
            )
          }

          return null
        })}
      </>
    )
  }

  return null
}
