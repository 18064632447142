import { Option } from 'effect'
import { head } from 'lodash'
import {
  fetchOrgSettingsByKey,
  upsertSettings,
} from '@shared/api/orgFacilitySettings'
import { SettingsType } from '@shared/types/org_facility_settings'
import {
  CreateOrgSecurityPolicySettings,
  SecurityPolicySettings,
} from '@shared/types/security_policy_settings'

export async function setSecurityPolicySettings(
  settings: Omit<CreateOrgSecurityPolicySettings, 'settingsType'>
) {
  await upsertSettings({
    settingsType: SettingsType.SETTINGS_TYPE_SECURITY_POLICY,
    ...settings,
  })
}

export async function getSecurityPolicySettings(
  orgId: string
): Promise<Option.Option<SecurityPolicySettings>> {
  const settings = await fetchOrgSettingsByKey<SecurityPolicySettings>(
    orgId,
    SettingsType.SETTINGS_TYPE_SECURITY_POLICY
  )

  return Option.fromNullable(head(settings))
}
