export const EMAIL_REGX = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+[.][A-Za-z]{2,}$/

// https://github.com/sindresorhus/escape-string-regexp
// The package ships ESM only, which fights with Jest
// So I copy-pasted the relevant code here.
export function escapeStringRegexp(input: string) {
  return input.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d')
}

/**
 * will match any time matching the expected time formats
 * examples: 09:00, 9:00, 9:15, 21:00, 9PM or 9 PM
 * **/
export const FLEXIBLE_TIME_REGEX = /(\d{1,2}:\d{2})|(\d{1,2}(\s?)(AM|PM))/

export function isAnEmailString(text: string) {
  return EMAIL_REGX.test(text)
}
