import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useRef, useState } from 'react'
import { UrlAndContentType } from '@shared/api/request'
import {
  ActionButtonHolder,
  DeleteButton,
  DownloadButton,
  PrintButton,
} from '@shared/components/AnimatedPopup/Lightbox/ActionButtons/ActionButtons'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import { useUserContext } from '@shared/contexts/UserContext'
import ErrorMonitoring from '@shared/ErrorMonitoring'
import { AsyncResult } from '@shared/utils/loading'
import PersonContext from '@app/contexts/PersonContext'
import { Deletable, SoftDeletable } from '../index'

interface Props {
  blobData: AsyncResult<UrlAndContentType, any> | null
  deletable: Deletable
  filename: string
  showPrintDownload?: boolean
  softDeletable?: SoftDeletable
}

export default function DocumentPreviewActions({
  blobData,
  deletable,
  filename,
  softDeletable,
  showPrintDownload = true,
}: Props) {
  const { user } = useUserContext()
  const { person } = useContext(PersonContext)
  const iframeRef = useRef<HTMLIFrameElement>(null)

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const deleting = deletable && deletable.deleting
  const onClickDelete =
    (deletable && deletable.onClickDelete) || Promise.resolve
  const confirmView = deletable && deletable.confirmView

  if (person === undefined || blobData?.tag === 'Loading' || deleting) {
    return null
  }

  const canDelete =
    deletable &&
    hasPermissionForPerson({
      user,
      person,
      permissions: [GroupPermission.GROUP_PERMISSION_SNAPSHOT_DELETE],
    })

  let fileUrl = ''
  if (blobData?.tag === 'Complete') {
    fileUrl = blobData.value.url
  } else if (blobData?.tag === 'Error') {
    // We expect this component to handle Snapshots with a file attachment only
    if (blobData.value && blobData.value.status === 404) {
      ErrorMonitoring.capture({
        error: 'Unexpected 404 when viewing document',
        level: 'error',
      })
    }
  }

  return (
    <>
      {showDeleteConfirmation &&
        confirmView &&
        confirmView(onClickDelete, () => {
          setShowDeleteConfirmation(false)
        })}
      {blobData && (
        <iframe
          ref={iframeRef}
          style={{ visibility: 'hidden', display: 'none' }}
          src={fileUrl}
        />
      )}
      {showPrintDownload && blobData && !deleting && (
        <ActionButtonHolder>
          <PrintButton
            onClick={() => iframeRef.current?.contentWindow?.print()}
          />
          <DownloadButton href={fileUrl} download={filename} />
          {canDelete && (
            <DeleteButton
              data-cy="delete-document"
              onClick={() => {
                if (softDeletable) {
                  softDeletable?.callbackFn()
                }
                setShowDeleteConfirmation(true)
              }}
            />
          )}
        </ActionButtonHolder>
      )}
    </>
  )
}
