import {
  OrgFacilitySettings,
  SettingsType,
} from '@augusthealth/models/com/august/protos/org_facility_settings'
import { listFacilities } from '@shared/api/facility'
import { getFacilitySettings } from '@shared/api/facility_settings'

/**
 * Fetches the security settings for the first facility in the org.
 * @param {string} orgId - The org ID.
 * @returns {Promise<OrgFacilitySettings | undefined>} The security settings for the first facility in the org.
 */
export async function fetchOrgFacilitySecuritySettings(
  orgId: string | undefined
): Promise<OrgFacilitySettings | undefined> {
  if (!orgId) return

  const firstFacility = await listFacilities(orgId, 1, true)
  const facilityId = firstFacility[0]?.id

  if (!facilityId) return

  const orgFacilitySettings = await getFacilitySettings(
    orgId,
    facilityId,
    SettingsType.SETTINGS_TYPE_SECURITY_POLICY
  )

  return orgFacilitySettings
}
