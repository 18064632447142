import { UserSessionLogoutReason } from '@augusthealth/models/com/august/protos/user_session'
import { CSSProperties, useState } from 'react'
import { Link, NavLink, NavLinkProps } from 'react-router-dom'
import { logout } from '@shared/components/Auth/Auth'
import ButtonLink from '@shared/components/ButtonLink'
import Icon from '@shared/components/Icon'
import { useUserContext } from '@shared/contexts/UserContext'
import { GroupPermission } from '@shared/types/permission'
import { hasPermissions } from '@shared/utils/permisson'
import { tw, twx } from '@shared/utils/tailwind'
import * as ToolsPaths from '@app/pages/Tools/path'
import sidebarStyles from '@app/pages/Tools/sidebar.module.css'

const activeStyle: CSSProperties = {
  background: 'rgba(0, 0, 0, 0.3)',
  fontWeight: '500',
  textDecoration: 'none',
  transition: 'background-color 200ms ease-in',
}

export default function Sidebar() {
  return (
    <div
      className={
        'z-[3] h-screen w-screen bg-sidebar text-[12px] font-medium leading-4 text-white transition-all xs:h-auto xs:w-[248px] print:hidden'
      }
    >
      <div className="horizontal">
        <Link to={ToolsPaths.TOOLS_PATH} className="flex-grow">
          <h3
            className={tw`m-0 flex flex-grow items-center bg-[#f7f5f2] p-2 font-semibold text-primary`}
          >
            <img
              src="/svg/logo.svg"
              alt="August Health logo symbol"
              className={tw`mr-[10px] h-[32px] w-[32px]`}
            />
            <span>Tools</span>
          </h3>
        </Link>
      </div>
      <section data-note="Hide on small screen">
        <ul className={sidebarStyles.linksList}>
          <SidebarMenu
            linkText={'Template Tools'}
            icon={'fa-paste'}
            menuItems={[
              {
                to: ToolsPaths.TOOLS_RECENT_TEMPLATES_PATH,
                linkText: 'Recent Templates',
                icon: 'fa-clock',
              },
              {
                to: ToolsPaths.TOOLS_ALL_TEMPLATES_PATH,
                linkText: 'All Templates',
                icon: 'fa-hourglass-start',
              },
              {
                to: ToolsPaths.TOOLS_CREATE_TEMPLATE_PATH,
                linkText: 'Create Template',
                icon: 'fa-file-plus',
              },
              {
                to: ToolsPaths.TOOLS_ASSIGN_TEMPLATES_PATH,
                linkText: 'Assign Templates',
                icon: 'fa-file-signature',
              },
              {
                to: ToolsPaths.TOOLS_STATE_INCIDENT_TEMPLATES_PATH,
                linkText: 'State Incident Templates',
                icon: 'fa-exclamation-triangle',
              },
            ]}
            isActive={(match, location) =>
              location.pathname.includes('pdfTemplate')
            }
          />
          <SidebarLink
            to={ToolsPaths.TOOLS_FACILITY_LIST_PATH}
            linkText="Org & Facility Management"
            icon={'fa-chart-bar'}
            isActive={(match, location) => {
              if (match) {
                return true
              }

              return location.pathname.endsWith('/metrics')
            }}
          />
          <SidebarLink
            to={ToolsPaths.TOOLS_ADD_USER_PATH}
            linkText={'Internal User Management'}
            icon={'fa-user-plus'}
          />
          <SidebarMenu
            isActive={(match, location) =>
              location.pathname.includes('reportGenerator')
            }
            linkText={'Report Tools'}
            icon={'fa-chart-mixed'}
            menuItems={[
              {
                to: `${ToolsPaths.TOOLS_REPORT_GENERATOR}/list`,
                linkText: 'Reports',
                icon: 'fa-chart-mixed',
              },
              {
                to: `${ToolsPaths.TOOLS_REPORT_GENERATOR}/assign`,
                linkText: 'Assign Reports',
                icon: 'fa-file-signature',
              },
              {
                to: `${ToolsPaths.TOOLS_REPORT_GENERATOR}/create`,
                linkText: 'Create Report',
                icon: 'fa-file-plus',
              },
            ]}
          />
          <SidebarLink
            to={ToolsPaths.TOOLS_RESIDENT_TRANSFER_PATH}
            linkText={'Resident Copy/Transfer'}
            icon={'fa-person-walking-dashed-line-arrow-right'}
            permissions={[GroupPermission.GROUP_PERMISSION_TOOL_GENERAL]}
          />
          <SidebarLink
            to={ToolsPaths.TOOLS_RESIDENT_IMPORT_PATH}
            linkText={'Resident / User Import, etc.'}
            icon={'fa-file-import'}
          />
          <SidebarLink
            to={ToolsPaths.TOOLS_LOC_SETTINGS}
            linkText={'Level of Care Settings'}
            icon={'fa-file-heart'}
          />
          <SidebarLink
            to={ToolsPaths.TOOLS_RESIDENT_DOCUMENT_UPLOADER}
            linkText={'Document Uploader'}
            icon={'fa-files'}
            permissions={[GroupPermission.GROUP_PERMISSION_TOOL_GENERAL]}
          />
          <SidebarLink
            to={ToolsPaths.TOOLS_EMAIL_INFO_PATH}
            linkText={'Email Lookup'}
            icon={'fa-at'}
          />
          <SidebarLink
            to={ToolsPaths.TOOLS_MEDPASS_CONFIG_PATH}
            linkText={'Medpass Configuration'}
            icon={'fa-pills'}
          />
          <SidebarLink
            to={ToolsPaths.TOOLS_ASSESSMENT_CONFIG}
            linkText={'Assessment Configuration'}
            icon={'fa-file-user'}
          />
          <SidebarLink
            to={ToolsPaths.TOOLS_DIETS_PATH}
            icon={'fa-burger-soda'}
            linkText={'Diets'}
          />
          <SidebarLink
            to={ToolsPaths.TOOLS_AMBULATORY_PATH}
            linkText={'Ambulatory'}
            icon={'fa-person-walking-with-cane'}
          />
          <SidebarLink
            to={ToolsPaths.TOOLS_SCHEDULED_TASKS_PATH}
            linkText={'Scheduled Tasks'}
            icon={'fa-clock'}
          />
          <SidebarLink
            to={ToolsPaths.TOOLS_SECURITY_SETTINGS_PATH}
            linkText={'Security Settings'}
            icon={'fa-house-turret'}
          />
          <SidebarMenu
            linkText={'Pharmacy Tools'}
            icon={'fa-prescription-bottle'}
            menuItems={[
              {
                to: ToolsPaths.TOOLS_REPROCESS_PHARMACY_MESSAGES_PATH,
                linkText: 'Reprocess Messages',
                icon: 'fa-prescription-bottle-pill',
              },
              {
                to: ToolsPaths.TOOLS_UNASSIGN_PHARMACY_RESIDENT,
                linkText: 'Unassign Resident',
                icon: 'fa-person-circle-xmark',
              },
              {
                to: ToolsPaths.TOOLS_PHARMACY_INTEGRATIONS,
                linkText: 'Pharmacy Integrations',
                icon: 'fa-prescription',
              },
            ]}
            isActive={(match, location) => {
              return location.pathname.includes('pharmacyTools')
            }}
          />
          <SidebarLink
            to={ToolsPaths.TOOLS_SHIFTS_PATH}
            linkText="Shifts"
            icon="fa-list-timeline"
          />
          <SidebarMenu
            linkText={'PDF View Settings'}
            icon={'fa-file-pdf'}
            menuItems={[
              {
                to: ToolsPaths.TOOLS_SERVICE_PLAN_PDF_PATH,
                linkText: 'Service Plan',
                icon: 'fa-bell-concierge',
              },
              {
                to: ToolsPaths.TOOLS_MED_LIST_PDF_PATH,
                linkText: 'Med List',
                icon: 'fa-prescription',
              },
            ]}
          />
        </ul>
      </section>
      <footer className="p-[24px]">
        <ButtonLink
          className="hover:text-underline text-white hover:text-white"
          onClick={() =>
            logout(UserSessionLogoutReason.USER_SESSION_LOGOUT_REASON_EXPLICIT)
          }
        >
          <i className="far fa-fw fa-sign-out mr-[8px]" />
          Log Out
        </ButtonLink>
      </footer>
    </div>
  )
}

type SidebarLinkProps = NavLinkProps & {
  linkText: string
  icon: string
  permissions?: GroupPermission[]
  menuTrigger?: boolean
}

export function SidebarLink({
  to,
  linkText,
  icon,
  isActive,
  permissions = [],
  menuTrigger = false,
}: SidebarLinkProps) {
  const { user } = useUserContext()

  if (!hasPermissions({ user, permissions })) {
    return null
  }

  return (
    <li className={sidebarStyles.link}>
      <NavLink activeStyle={activeStyle} isActive={isActive} to={to}>
        <span className="flex w-full flex-row items-center justify-between">
          <span className={tw`flex flex-row items-center whitespace-nowrap`}>
            <i
              className={`fa-fw fa-solid ${icon} mr-[8px] hover:no-underline`}
            />
            {linkText}
          </span>
          {menuTrigger && <Icon name={'chevron-right'} />}
        </span>
      </NavLink>
    </li>
  )
}

type SidebarMenuProps = Omit<SidebarLinkProps, 'to'> & {
  menuItems: SidebarLinkProps[]
}
export const SidebarMenu = ({
  linkText,
  icon,
  menuItems,
  isActive,
}: SidebarMenuProps) => {
  const [showMenu, setShowMenu] = useState(false)

  return (
    <div
      className={twx('relative')}
      onMouseEnter={() => setShowMenu(true)}
      onMouseLeave={() => setShowMenu(false)}
    >
      <SidebarLink
        to={'#'}
        linkText={linkText}
        icon={icon}
        isActive={isActive}
        menuTrigger={true}
      />
      {showMenu && (
        <div
          className={tw`absolute left-[100%] top-0 w-full bg-link opacity-100 shadow-popup`}
        >
          {menuItems.map((item, index) => {
            return (
              <SidebarLink
                key={index}
                to={item.to}
                linkText={item.linkText}
                icon={item.icon}
                isActive={item.isActive}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}
