import {
  AmbulatoryStatus_AmbulatoryCode,
  AmbulatoryStatus_AmbulatoryResult,
} from '@augusthealth/models/com/august/protos/ambulatory_status'
import { Condition } from '@augusthealth/models/com/august/protos/condition'
import { cloneDeep } from 'lodash'
import { typedMergePatchPerson } from '@shared/api/person'
import { CareGroupDetails, MedGroupDetails, Person } from '@shared/types/person'
import { nameForAmbulatoryCode } from '@shared/utils/ambulatoryStatus'
import {
  DEMENTIA_CODE,
  DEMENTIA_CODEABLE_CONCEPT,
} from '@shared/utils/diagnosis'

interface DementiaFormData {
  dementia: {
    label: string
    value: boolean
  }
}

export type PersonAndOnClose = {
  person: Person
  onClose: (updated: boolean) => Promise<void>
}

export async function updateCareGroup(
  person: Person,
  careGroupDetails: CareGroupDetails | null
) {
  return await typedMergePatchPerson({
    fId: person.facilityId!,
    pId: person.id!,
    orgId: person.orgId!,
    fields: ['careGroupDetails'],
    patch: {
      careGroupDetails,
    },
  })
}

export async function updateMedGroup(
  person: Person,
  medGroupDetails: MedGroupDetails | null
) {
  return await typedMergePatchPerson({
    fId: person.facilityId!,
    pId: person.id!,
    orgId: person.orgId!,
    fields: ['medGroupDetails'],
    patch: {
      medGroupDetails,
    },
  })
}

export async function updateDementiaStatus(
  person: Person,
  data: DementiaFormData
) {
  const conditions: Condition[] = cloneDeep(person.conditions || [])

  if (data.dementia.value) {
    conditions.push(DEMENTIA_CODEABLE_CONCEPT)
  } else {
    const dementiaIndex = conditions.findIndex((condition) =>
      condition.code?.coding?.some((coding) => coding.code === DEMENTIA_CODE)
    )
    conditions.splice(dementiaIndex, 1)
  }

  await typedMergePatchPerson({
    fId: person.facilityId!,
    pId: person.id!,
    orgId: person.orgId!,
    fields: ['conditions'],
    patch: {
      conditions,
    },
  })
}

export async function updateAmbulatoryStatus(
  person: Person,
  data: FormData
): Promise<Pick<Person, 'ambulatoryStatus'>> {
  const response = await typedMergePatchPerson({
    fId: person.facilityId!,
    pId: person.id!,
    orgId: person.orgId!,
    fields: ['ambulatoryStatus'],
    patch: {
      ambulatoryStatus: {
        code: data.ambulatoryCode?.value,
        evacuation: {
          ableToEvacuateReasonablyQuickly: data.evacuatesQuickly?.value,
          ableToFollowInstructions: data.followsInstructions?.value,
        },
      },
    },
  })

  return response.data
}

// Need to update .ambulatoryStatus.ambulatoryImport instead
// Since .ambulatoryStatus.ambulatoryResult is derived
export async function updateAmbulatoryResult(
  person: Person,
  ambulatoryResult: AmbulatoryStatus_AmbulatoryResult
): Promise<Pick<Person, 'ambulatoryStatus'>> {
  const response = await typedMergePatchPerson({
    fId: person.facilityId!,
    pId: person.id!,
    orgId: person.orgId!,
    fields: ['ambulatoryStatus'],
    patch: {
      ambulatoryStatus: {
        ambulatoryResult,
      },
    },
  })

  return response.data
}

export function defaultValues(person: Person) {
  const ambulatoryCode = person.ambulatoryStatus?.code
  const initialCode =
    ambulatoryCode &&
    ambulatoryCodeOptions.find((co) => co.value === ambulatoryCode)

  const ableToFollowInstructions =
    person.ambulatoryStatus?.evacuation?.ableToFollowInstructions
  const initialFollowsInstructions = yesNoOptions.find(
    (o) => o.value === ableToFollowInstructions
  )

  const ableToEvacuate =
    person.ambulatoryStatus?.evacuation?.ableToEvacuateReasonablyQuickly
  const initialAbleToEvacuate = yesNoOptions.find(
    (o) => o.value === ableToEvacuate
  )

  return {
    ambulatoryCode: initialCode ?? null,
    followsInstructions: initialFollowsInstructions ?? null,
    evacuatesQuickly: initialAbleToEvacuate ?? null,
  }
}

// This is duplicated on the backend to derive the Person.AmbulatoryStatus.AmbulatoryResult
// https://github.com/augusthealth/august-backend/blob/dea50d68d272937b3654b2717dbf028a3737ce35/loquat/modules/core/src/main/scala/com/august/model/ProtoExtensions.scala#L185
export function localAmbulatoryStatus(
  code: AmbulatoryStatus_AmbulatoryCode | undefined,
  followsInstructions: boolean | undefined,
  ableToEvacuate: boolean | undefined
) {
  if (
    code &&
    followsInstructions !== undefined &&
    ableToEvacuate !== undefined
  ) {
    const walks = [
      'AMBULATORY_CODE_WALKS_WITHOUT_SUPPORT',
      'AMBULATORY_CODE_WALKS_WITH_CANE',
    ].includes(code)

    if (walks && followsInstructions && ableToEvacuate) {
      return AmbulatoryStatus_AmbulatoryResult.AMBULATORY_RESULT_AMBULATORY
    } else if (code === 'AMBULATORY_CODE_BEDRIDDEN_UNABLE_TO_TURN') {
      return AmbulatoryStatus_AmbulatoryResult.AMBULATORY_RESULT_BEDRIDDEN
    } else {
      return AmbulatoryStatus_AmbulatoryResult.AMBULATORY_RESULT_NON_AMBULATORY
    }
  }

  return undefined
}

export const ambulatoryCodeOptions = [
  AmbulatoryStatus_AmbulatoryCode.AMBULATORY_CODE_WALKS_WITHOUT_SUPPORT,
  AmbulatoryStatus_AmbulatoryCode.AMBULATORY_CODE_WALKS_WITH_CANE,
  AmbulatoryStatus_AmbulatoryCode.AMBULATORY_CODE_WALKS_WITH_WALKER_OR_OTHER_SUPPORT,
  AmbulatoryStatus_AmbulatoryCode.AMBULATORY_CODE_USES_WHEELCHAIR,
  AmbulatoryStatus_AmbulatoryCode.AMBULATORY_CODE_BEDRIDDEN_ABLE_TO_TURN,
  AmbulatoryStatus_AmbulatoryCode.AMBULATORY_CODE_BEDRIDDEN_UNABLE_TO_TURN,
].map((c) => ({
  label: nameForAmbulatoryCode(c)!,
  value: c,
}))

export const yesNoOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

export interface FormData {
  ambulatoryCode: {
    label: string
    value: AmbulatoryStatus_AmbulatoryCode
  } | null
  followsInstructions: {
    label: string
    value: boolean
  } | null
  evacuatesQuickly: {
    label: string
    value: boolean
  } | null
}
