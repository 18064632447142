import { BasicRadioButton } from '@shared/components/BasicInput/BasicInput'
import Card from '@shared/components/Card'
import { MedicationOrder } from '@shared/types/medication_order'
import {
  getMedicationOrderInstructions,
  getMedicationTitleAndSubTitle,
} from '@shared/utils/medicationOrder'
import { tw, twx } from '@shared/utils/tailwind'
import { DiscontinuedStatusMedPill } from '@app/components/Residents/Medications/Orders/OrderStatusPill'
import styles from '@app/components/Residents/Medications/Orders/styles.module.css'
import { WeakMatchOrderFooter } from '@app/components/Residents/Medications/Orders/WeakMatch/WeakMatchOrderFooter'

type Props = {
  order: MedicationOrder
  selected: boolean
  onClick: () => void
}

export const PossibleMatchCard = ({ order, selected, onClick }: Props) => {
  const instructions = getMedicationOrderInstructions(order)
  const { title, subTitle } = getMedicationTitleAndSubTitle(order)

  return (
    <>
      <Card
        className={tw`flex flex-row items-center justify-start border border-transparent bg-gray-13 px-0 py-0 hover:border-primary-light`}
        onClick={onClick}
      >
        <div className={tw`rounded-l-[8px] p-4`}>
          <BasicRadioButton
            checked={selected}
            readOnly
            holderClassName={tw`m-0 items-center p-0`}
            className={tw`m-0 p-0`}
          >
            <span className={'hidden'}>{order.orderGroupId}</span>
          </BasicRadioButton>
        </div>
        <div
          className={
            'flex w-full flex-col rounded-r-[8px] border-l-[1px] border-gray-11 bg-white p-4'
          }
        >
          <div
            className={twx(
              `flex flex-wrap items-center gap-2 text-[14px] font-semibold leading-5 text-gray-04 [&>*]:whitespace-nowrap`
            )}
          >
            <span>
              {title} {subTitle}
            </span>
            {selected && <DiscontinuedStatusMedPill />}
          </div>
          <div className={styles.medInstructions}>
            {instructions.map((inst, index) => (
              <span
                key={`${order.id}-instruction-${index}`}
                className={tw`block`}
              >
                {inst}
              </span>
            ))}
          </div>
          <WeakMatchOrderFooter order={order} renderMode={'modal'} />
        </div>
      </Card>
    </>
  )
}
