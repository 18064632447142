import { useFormContext, Validate } from 'react-hook-form'
import { BadgeInput } from '@shared/components/BadgeInputs/BadgeInput'
import {
  MedOrderFormData,
  SlidingScaleEntryWithId,
} from '@shared/types/medication_order'
import {
  validateAtLeastOneSlidingQuantity,
  validatePositiveNumber,
} from '@shared/utils/formValidationFunctions'
import { validNumberOrNull } from '@shared/utils/parsing'
import pluralize from '@shared/utils/pluralize'
import { SlidingScaleDoseEntry } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/helpers'
import styles from '../styles.module.css'
import { RowActions } from './RowActions'
import { ScaleEntryBoundsInput } from './ScaleEntryBoundsInput'

type Props = {
  scaleEntry: SlidingScaleEntryWithId
  readOnly: boolean
  index: number
  doseIndex: number
  addScaleEntry: () => void
  removeScaleEntry: () => void
  showRangeError: boolean
  showUnitError: boolean
  scaleUnit: string
}
export const SlidingScaleEntryRow = ({
  scaleEntry,
  readOnly,
  index,
  addScaleEntry,
  removeScaleEntry,
  showRangeError,
  showUnitError,
  doseIndex,
  scaleUnit,
}: Props) => {
  const methods = useFormContext<MedOrderFormData>()
  const { getValues, register, formState, trigger, watch } = methods

  const scalePrefix: SlidingScaleDoseEntry = `doses.${doseIndex}.slidingScale.entries.${index}`
  const unitsValue =
    validNumberOrNull(watch(`${scalePrefix}.doseQuantity.value`)) ?? 0
  const scaleEntries =
    getValues(`doses.${doseIndex}`)?.slidingScale?.entries ?? []
  const unitsLabel = pluralize(scaleUnit, unitsValue, false)
  const isFinalEntry = index + 1 === scaleEntries.length
  const isFirstEntry = index === 0
  const shouldBePositive =
    isFirstEntry || isFinalEntry ? undefined : validatePositiveNumber
  const shouldHaveAtLeastOne = isFirstEntry
    ? (_, formData: MedOrderFormData) =>
        validateAtLeastOneSlidingQuantity({ formData, unit: unitsLabel })
    : undefined
  let validate:
    | Record<string, Validate<number | undefined, MedOrderFormData>>
    | undefined
  if (shouldBePositive && shouldHaveAtLeastOne) {
    validate = { shouldBePositive, shouldHaveAtLeastOne }
  } else if (shouldBePositive) {
    validate = { shouldBePositive }
  } else if (shouldHaveAtLeastOne) {
    validate = { shouldHaveAtLeastOne }
  }

  return (
    <div
      className={
        'justify-content-start mb-[8px] mt-[8px] flex w-fit flex-row items-center'
      }
    >
      <ScaleEntryBoundsInput
        scaleEntry={scaleEntry}
        scalePrefix={scalePrefix}
        entryIndex={index}
        doseIndex={doseIndex}
        isFinalEntry={isFinalEntry}
        isFirstEntry={isFirstEntry}
        readOnly={readOnly}
        showRangeError={showRangeError}
      />
      <BadgeInput
        badgeLabel={unitsLabel}
        type={'number'}
        min={0}
        {...register(`${scalePrefix}.doseQuantity.value`, {
          valueAsNumber: true,
          validate,
          onBlur: () => {
            if (formState.isSubmitted) {
              void trigger(scalePrefix)
            } else {
              void trigger(`${scalePrefix}.doseQuantity.value`)
            }
          },
        })}
        placeholder={'#'}
        step={'any'}
        disabled={readOnly}
        className={styles.unitInput}
        showErrorBorder={
          !!formState.errors.doses?.[doseIndex]?.slidingScale?.entries?.[index]
            ?.doseQuantity?.value
        }
        tooltip={
          showUnitError
            ? formState.errors.doses?.[doseIndex]?.slidingScale?.entries?.[
                index
              ]?.doseQuantity?.value?.message
            : undefined
        }
      />
      <RowActions
        scalePrefix={scalePrefix}
        readOnly={readOnly}
        isFinalEntry={isFinalEntry}
        isFirstEntry={isFirstEntry}
        addScaleEntry={addScaleEntry}
        removeScaleEntry={removeScaleEntry}
      />
    </div>
  )
}
