import { LatestVital } from '@augusthealth/models/com/august/protos/api/latest_vital'
import React from 'react'
import { Vital } from '@shared/types/vital'
import { Loading } from '@shared/utils/loading'
import { VitalsType } from '@shared/utils/vitals'

export type VitalsContextProps = {
  activeTab: VitalsType | undefined
  allVitals: Loading<Vital[]>
  latestVitals: Loading<LatestVital>
  setActiveTab: (val: VitalsType | undefined) => void
  refreshLatestVitals: () => Promise<void>
  refreshAllVitals: (markAsLoadingDuringRefresh?: boolean) => Promise<void>
}

export const defaultProps: VitalsContextProps = {
  activeTab: undefined,
  allVitals: { tag: 'Loading' },
  latestVitals: { tag: 'Loading' },
  setActiveTab: () => {},
  refreshLatestVitals: () => Promise.resolve(),
  refreshAllVitals: () => Promise.resolve(),
}

export default React.createContext(defaultProps)
