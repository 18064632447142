import { IncomingFile } from '@augusthealth/models/com/august/protos/incoming_file'
import { sortBy } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { UrlAndContentType } from '@shared/api/request'
import { fetchTasks } from '@shared/api/task'
import Lightbox, {
  LightboxFooterButton,
  LightboxSidebarFooter,
  LightboxSidebarTitle,
} from '@shared/components/AnimatedPopup/Lightbox/Lightbox'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { DataType } from '@shared/types/snapshot'
import { Task } from '@shared/types/task'
import { getFullName } from '@shared/utils/humanName'
import { getOrElse, Loading } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import { getIncomingFile } from '@app/api/incoming_files'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import { useImmunizationsByPerson } from '@app/hooks/useImmunizations'
import useUploadableDocuments from '@app/hooks/useUploadableDocuments'
import DocumentPreview from '@app/pages/Documents/Viewer/DocumentPreview/DocumentPreview'
import DocumentPreviewFloatingActions from '@app/pages/Documents/Viewer/DocumentPreview/DocumentPreviewFloatingActions'
import {
  assignIncomingFileToResident,
  ignoreIncomingFile,
} from '@app/pages/Facilities/Inbox/IncomingFiles/helpers'
import styles from '@app/pages/Facilities/Inbox/IncomingFiles/styles.module.css'
import FormProducer from '@app/pages/Tools/ResidentDocumentUploader/FormProducer'
import { validateForm } from '@app/pages/Tools/ResidentDocumentUploader/helpers'

type Props = {
  file: IncomingFile
  closeModal: () => void
  afterSubmission: () => Promise<void>
}
export const ReviewPendingFileModal = ({
  file,
  closeModal,
  afterSubmission,
}: Props) => {
  const { setError } = useContext(GlobalContext)
  const [tasks, setTasks] = useState<Loading<Task[]>>({ tag: 'Loading' })
  const { currentFacility, residents } = useCurrentFacility()

  const [selectedResidentId, setSelectedResidentId] = useState<string>()
  const [selectedDataType, setSelectedDataType] = useState<DataType>()
  const [methods, setMethods] = useState<UseFormReturn<any>>()

  const [blobData, setBlobData] = useState<UrlAndContentType>()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const orgId =
    currentFacility?.tag === 'Complete'
      ? currentFacility.value.orgId
      : undefined
  const facilityId =
    currentFacility?.tag === 'Complete' ? currentFacility.value.id : undefined

  useEffect(() => {
    if (orgId && facilityId) {
      const fetchFile = async () => {
        const response = await getIncomingFile({
          orgId,
          facilityId,
          fileId: file.id!,
        })
        setBlobData(response)
      }

      void fetchFile()
    }
  }, [orgId, facilityId])

  const { uploadableDocuments } = useUploadableDocuments({
    tasks: getOrElse(tasks, []),
    overrideIds: {
      orgId: orgId,
      facilityId: facilityId,
      personId: selectedResidentId,
    },
  })

  const { immunizations } = useImmunizationsByPerson({
    person: {
      orgId: orgId,
      facilityId: facilityId,
      id: selectedResidentId,
    },
  })

  useEffect(() => {
    if (selectedResidentId && orgId) {
      setTasks({ tag: 'Loading' })
      fetchTasks({ pId: selectedResidentId, orgId: orgId })
        .then((response) => setTasks({ tag: 'Complete', value: response.data }))
        .catch(setError)
    }
  }, [selectedResidentId, orgId])
  const assignToResident = async () => {
    const hasErrors = validateForm({
      formValues: methods!.getValues(),
      dataType: selectedDataType,
      setFormError: methods!.setError,
      setAugustError: setError,
    })

    if (hasErrors) {
      return
    }

    setIsSubmitting(true)
    const formValues = methods?.getValues() ?? {}

    await assignIncomingFileToResident({
      formData: formValues,
      person: {
        id: selectedResidentId!,
        orgId: orgId!,
        facilityId: facilityId!,
      },
      dataType: selectedDataType!,
      fileId: file.id!,
      originalImmunizations:
        immunizations.tag === 'Complete' ? immunizations.value : [],
    })

    await afterSubmission()

    setIsSubmitting(false)
  }

  const ignore = async () => {
    setIsSubmitting(true)

    await ignoreIncomingFile({
      fileId: file.id!,
      orgId: orgId!,
      facilityId: facilityId!,
    })

    await afterSubmission()

    setIsSubmitting(false)
  }

  const residentOptions: OptionTypeBase[] =
    residents.tag === 'Complete'
      ? residents.value.map((res) => ({
          label: getFullName(res.person?.name),
          value: res.person!.id!,
        }))
      : []

  const documentOptions: OptionTypeBase<DataType>[] =
    uploadableDocuments.tag === 'Complete'
      ? [
          ...sortBy(
            uploadableDocuments.value.map((doc) => ({
              label: doc.displayName!,
              value: doc.dataType!,
            })),
            (o) => o.label
          ),
          {
            label: 'Other Documents',
            value: DataType.DATA_TYPE_OTHER_UPLOAD,
          },
        ]
      : []

  const formSidebarContent = (
    <div className={styles.sidebar}>
      <div className={styles.sidebarContent}>
        <LightboxSidebarTitle>Review & Assign</LightboxSidebarTitle>
        <div className={styles.scrollableContents}>
          <div className={'mt-6'}>
            <LabelAboveInput htmlFor="personId" subLabel={requiredLabel(false)}>
              Assign to resident
            </LabelAboveInput>
            <StyledSelect
              placeholder={'Assign to resident...'}
              value={
                residentOptions.find(
                  (res) => res.value === selectedResidentId
                ) ?? null
              }
              options={residentOptions}
              onChange={(selected: OptionTypeBase) =>
                setSelectedResidentId(selected.value)
              }
            />
          </div>

          <div className={'mt-6'}>
            <LabelAboveInput htmlFor="dataType" subLabel={requiredLabel(false)}>
              Document Type
            </LabelAboveInput>
            <StyledSelect
              placeholder={'Select the file type...'}
              value={
                documentOptions.find((res) => res.value === selectedDataType) ??
                null
              }
              options={documentOptions}
              onChange={(selected: OptionTypeBase<DataType>) => {
                setSelectedDataType(selected.value)
              }}
              isDisabled={
                uploadableDocuments.tag === 'Loading' || !selectedResidentId
              }
            />
          </div>

          {selectedDataType && (
            <div className={tw`mt-2 flex flex-col gap-2`}>
              <FormProducer
                currentTaskTemplate={{ dataType: selectedDataType }}
                setMethods={setMethods}
                immunizations={immunizations}
              />
            </div>
          )}
        </div>
      </div>
      <LightboxSidebarFooter className={'flex-col gap-2'}>
        <LightboxFooterButton
          onClick={assignToResident}
          disabled={!selectedResidentId || !selectedDataType || isSubmitting}
          buttonStyle={'primary-fill'}
        >
          Save
        </LightboxFooterButton>
        <div className={'flex flex-row gap-2'}>
          <LightboxFooterButton
            onClick={closeModal}
            disabled={isSubmitting}
            buttonStyle={'secondary-outline'}
          >
            Cancel
          </LightboxFooterButton>
          <LightboxFooterButton
            onClick={ignore}
            disabled={isSubmitting}
            buttonStyle={'danger-fill'}
          >
            Ignore
          </LightboxFooterButton>
        </div>
      </LightboxSidebarFooter>
    </div>
  )

  const filePreview = (
    <>
      {blobData ? (
        <>
          <DocumentPreview blobData={{ tag: 'Complete', value: blobData }} />
          <DocumentPreviewFloatingActions
            blobData={{ tag: 'Complete', value: blobData }}
            softDeletable={false}
            showSmallPrintButton
            showSmallRotateButton
          />
        </>
      ) : (
        <SimpleSpinner />
      )}
    </>
  )

  return (
    <Lightbox
      mainContent={filePreview}
      sidebarContent={formSidebarContent}
      mainContentBackgroundColor="dark"
      onClose={closeModal}
    />
  )
}
