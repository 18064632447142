import ButtonLink from '@shared/components/ButtonLink'
import { SlidingScaleDoseEntry } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/helpers'
import styles from '../styles.module.css'
import { SlidingScaleNote } from './SlidingScaleNote'

type Props = {
  scalePrefix: SlidingScaleDoseEntry
  readOnly: boolean
  isFinalEntry: boolean
  isFirstEntry: boolean
  addScaleEntry: () => void
  removeScaleEntry: () => void
}
export const RowActions = ({
  scalePrefix,
  readOnly,
  isFinalEntry,
  isFirstEntry,
  addScaleEntry,
  removeScaleEntry,
}: Props) => {
  const actions = [
    <SlidingScaleNote
      key={`${scalePrefix}-ss-note`}
      noteName={`${scalePrefix}.note`}
      readOnly={readOnly}
    />,
  ]

  if (!isFinalEntry && !readOnly) {
    actions.push(
      <ButtonLink
        key={`${scalePrefix}-ss-add-range`}
        icon={'fa-regular fa-fw fa-circle-plus'}
        title={'Add sliding scale range'}
        iconClasses={styles.slidingScaleIcon}
        onClick={addScaleEntry}
      />
    )
  }

  if (!isFirstEntry && !isFinalEntry && !readOnly) {
    actions.push(
      <ButtonLink
        key={`${scalePrefix}-ss-remove-range`}
        icon={'fa-regular fa-fw fa-circle-minus'}
        title={'Remove sliding scale range'}
        iconClasses={styles.slidingScaleIcon}
        onClick={removeScaleEntry}
        disabled={isFinalEntry}
      />
    )
  }

  return <div className={styles.iconContainer}>{actions}</div>
}
