import { Contact } from '@shared/types/contact'
import { RequiredPersonIds } from '@shared/types/person'
import { BaseMissingEmailOverlays } from '../BaseMissingEmailOverlays'
import { setRPEmail } from './helpers'

export function RpMissingEmailOverlays({
  responsiblePerson,
  personIds,
  onContinue,
  continueWithEmailButtonText,
}: {
  personIds: RequiredPersonIds
  responsiblePerson: Contact
  continueWithEmailButtonText: string
  onContinue: () => Promise<void>
}) {
  return (
    <BaseMissingEmailOverlays
      data-testid={'missingRpEmail'}
      contact={responsiblePerson}
      onContinue={async (newEmail: string) => {
        if (newEmail) {
          await setRPEmail({
            responsiblePerson,
            personIds,
            newRpEmail: newEmail,
          })
        }
        await onContinue()
      }}
      continueWithEmailButtonText={continueWithEmailButtonText}
    />
  )
}
