import {
  MedicationApprovalStatus,
  MedicationOrder,
  MedOrderFormData,
  RxMedicationOrder,
  VitalMedicationOrder,
} from '@shared/types/medication_order'
import { DosageType } from '@shared/types/medication_statement'
import { VitalType } from '@shared/types/vital'
import { mapFormMedToMedOrder } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'

export const mapNewFormMedToMedOrder = ({
  formData,
  personId,
}: {
  formData: MedOrderFormData
  personId: string
}): MedicationOrder => {
  const medOrder = formData.vitalType
    ? getNewVitalOrder({ personId, vitalType: formData.vitalType })
    : getNewRxOrder({
        personId,
        dosageType: formData.doses[0]!.userSetDosageType,
      })

  return mapFormMedToMedOrder({
    formData,
    medOrder,
  }) as MedicationOrder
}

export const getNewVitalOrder = ({
  personId,
  vitalType,
}: {
  personId: string
  vitalType: VitalType
}): Partial<VitalMedicationOrder> => ({
  personId,
  medicationStatement: {
    dosageType: DosageType.DOSAGE_TYPE_VITAL,
    selfAdministered: false,
    isTreatment: false,
    vitalType,
    vitalInstructions: [],
    medication: null,
    dosageInstruction: null,
  },
  approval: {
    status: MedicationApprovalStatus.MEDICATION_APPROVAL_STATUS_APPROVED,
  },
})

export const getNewRxOrder = ({
  personId,
  dosageType,
}: {
  personId: string
  dosageType: DosageType
}): Partial<RxMedicationOrder> => ({
  personId,
  medicationStatement: {
    dosageType,
    medication: {},
    dosageInstruction: [],
    isTreatment: false,
    selfAdministered: false,
    vitalType: null,
    vitalInstructions: null,
  },
  approval: {
    status: MedicationApprovalStatus.MEDICATION_APPROVAL_STATUS_APPROVED,
  },
})
