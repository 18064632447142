import { format, formatDistance, parseISO } from 'date-fns'
import { match } from 'ts-pattern'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import { Time } from '@shared/types/date'
import { EventTiming } from '@shared/types/timing'

const TWENTY_FOUR_HOURS = Array(24)
  .fill(0)
  .map((_, hour) => hour)

const minutesByInterval = (interval: number): number[] => {
  const times: number[] = []
  for (let minute = 0; minute < 60; minute += interval) {
    times.push(minute)
  }
  return times
}

export const buildTimesByInterval = (interval: number): Time[] => {
  return TWENTY_FOUR_HOURS.flatMap((hour) =>
    minutesByInterval(interval).map((minute) => ({ hour, minute }))
  )
}

export const medPassEventTimings: OptionTypeBase<EventTiming>[] = [
  {
    label: 'AM',
    value: EventTiming.EVENT_TIMING_MORN,
  },
  {
    label: 'NN',
    value: EventTiming.EVENT_TIMING_AFT,
  },
  {
    label: 'PM',
    value: EventTiming.EVENT_TIMING_EVE,
  },
  {
    label: 'HS',
    value: EventTiming.EVENT_TIMING_NIGHT,
  },
]

const hourNameMapping: Record<number, string> = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
  10: 'ten',
  11: 'eleven',
  12: 'twelve',
  13: 'one',
  14: 'two',
  15: 'three',
  16: 'four',
  17: 'five',
  18: 'six',
  19: 'seven',
  20: 'eight',
  21: 'nine',
  22: 'ten',
  23: 'eleven',
  24: 'twelve',
} as const

export const iconNameForTime = (time: Time | undefined): string => {
  if (!time || !time.hour) {
    return ''
  }

  const hour: string = hourNameMapping[time.hour]

  if (time.minute === 30) {
    return `clock-${hour}-thirty`
  }

  return `clock-${hour}`
}

export const iconNameForIsoDate = (isoDate: string): string => {
  const parsedDate = new Date(parseISO(isoDate))
  const hour = hourNameMapping[parsedDate.getHours()]
  const minute = parsedDate.getMinutes()

  if (minute > 15 && minute < 45) {
    return `clock-${hour}-thirty`
  } else {
    return `clock-${hour}`
  }
}

export function fromTimeStringToTime(timeString: string): Time {
  const [time, suffix] = timeString.split(/(?=[A-Z]{2})/)
  const [hour, minute] = time.split(':')

  switch (suffix) {
    case 'AM':
      return {
        hour: parseInt(hour) % 12,
        minute: minute ? parseInt(minute) : 0,
      }
    case 'PM': {
      const convertedHour = parseInt(hour) === 12 ? 12 : parseInt(hour) + 12
      return {
        hour: convertedHour,
        minute: minute ? parseInt(minute) : 0,
      }
    }
  }

  return {
    hour: parseInt(hour),
    minute: minute ? parseInt(minute) : 0,
  }
}

export function fromTimetoTimeString(
  time: Time | undefined,
  long: boolean = false
) {
  if (!time) {
    return ''
  }

  return `${hour(time)}${minute(time, long)}${long ? ' ' : ''}${suffix(time)}`
}

function hour(time: Time) {
  if (time.hour !== undefined) {
    if (time.hour > 12) {
      return `${time.hour - 12}`
    }

    if (time.hour === 0) {
      return 12
    }

    return time.hour
  }

  return ''
}

function minute(time: Time, long: boolean = false) {
  if (time.minute !== undefined) {
    if (time.minute.toString().length === 2) {
      return `:${time.minute.toString()}`
    }

    if (time.minute.toString() === '0') {
      return long ? ':00' : ''
    }
  }

  return ''
}

function suffix(time: Time) {
  if (time.hour) {
    if (time.hour >= 12) {
      return `PM`
    }
  }

  return 'AM'
}

export const fromDateToTimeString = (
  date: Date,
  use24HourTime: boolean,
  long: boolean = false
): string => {
  const formatString = use24HourTime ? 'HH:mm' : long ? 'hh:mm a' : 'h:mm a'

  return format(date, formatString)
}

export const formatDistanceToNowFromIsoString = (isoString: string) => {
  return formatDistance(parseISO(isoString), new Date(), {
    addSuffix: true,
  })
}

/**
 * Compare Time objects for sorting.
 */
export function compareTimeAsc(a: Time, b: Time): -1 | 0 | 1 {
  const aHour = a.hour || 0
  const aMinute = a.minute || 0
  const bHour = b.hour || 0
  const bMinute = b.minute || 0

  const result = aHour === bHour ? aMinute - bMinute : aHour - bHour

  return match(result)
    .with(0, () => 0 as const)
    .when(
      (n) => n > 0,
      () => 1 as const
    )
    .when(
      (n) => n < 0,
      () => -1 as const
    )
    .otherwise(() => 0)
}

export function isOnOrAfter(a: Time, b: Time): boolean {
  return compareTimeAsc(a, b) >= 0
}

export function isBefore(a: Time, b: Time): boolean {
  return compareTimeAsc(a, b) < 0
}
