import {
  Contact,
  Contact_ContactRelationship as CR,
} from '@augusthealth/models/com/august/protos/contact'
import { useEffect, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ActionMeta } from 'react-select'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { Person } from '@shared/types/person'
import {
  formatInlineAddressWithoutUnit,
  getAddressFromGeocoder,
} from '@shared/utils/address'
import { TITLE_OPTIONS } from '@shared/utils/person'
import { tw } from '@shared/utils/tailwind'
import {
  optionsForCategory,
  SECONDARY_RELATIONSHIP_OPTIONS_FOR_GP,
} from '@app/components/AddPersonPopup/RPVerifyInformation/helpers'
import usePlacesAutocomplete from '@app/hooks/usePlacesAutocomplete'
import { contactFromFormValues } from './helpers'
import RelationshipLabels from './RelationshipLabels'
import TelecomSection from './TelecomSection'
import { ContactFormData, OnClose } from './types'

type Props = {
  contact?: Contact
  onClose: OnClose
  setContactToEdit: (contact: Contact) => void
  setShowContactRoles: (isEditing: boolean) => void
  person: Person
}
export default function DetailsTab({
  contact,
  onClose,
  setContactToEdit,
  setShowContactRoles,
  person,
}: Props) {
  const methods = useFormContext<ContactFormData>()
  const {
    control,
    formState,
    formState: { errors },
    getValues,
    register,
    setValue,
    trigger,
    watch,
  } = methods

  const [relationshipSelectOptions, setRelationshipSelectOptions] =
    useState<ContactFormData['secondaryRelationship'][]>()
  const primaryRelationship: ContactFormData['primaryRelationship'] = watch(
    'primaryRelationship'
  )
  const addressRef = useRef<HTMLInputElement | null>(null)
  const { ref, ...rest } = register('address')
  usePlacesAutocomplete({
    inputRef: addressRef,
    callback: (place) => {
      if (place.address_components) {
        const addressTyped = getAddressFromGeocoder(place.address_components)
        setValue('addressTyped', addressTyped)
        setValue('address', formatInlineAddressWithoutUnit(addressTyped))
        if (addressTyped?.line?.[1]) {
          setValue('addressTyped.line.1', addressTyped.line[1])
        }
      }
      // If place.address_components not found, address text will be set below in <BasicInput name="address" />'s onChange
    },
  })

  const addressTyped: ContactFormData['addressTyped'] = watch('addressTyped')

  useEffect(() => {
    if (addressTyped?.text === '') {
      setValue('addressTyped.line.1', '')
    }
  }, [addressTyped?.text])

  useEffect(() => {
    if (primaryRelationship !== CR.CONTACT_RELATIONSHIP_CLINICIAN) {
      // @ts-ignore
      setValue('clinicalSpecialty', null)
    }
    if (
      // Reset Secondary Relationship on Primary Relationship change
      !optionsForCategory(primaryRelationship).some(
        (o) => o.value === getValues('secondaryRelationship')?.value
      )
    ) {
      // @ts-ignore
      setValue('secondaryRelationship', null)
    }
    // Personal relationship doesn't have associated Org.
    if (primaryRelationship === CR.CONTACT_RELATIONSHIP_PERSONAL) {
      // @ts-ignore
      setValue('organization', null)
    }

    const roles = getValues('roles') || []
    const isPrimaryPractitioner = roles.find(
      (r) => r === CR.CONTACT_RELATIONSHIP_GENERAL_PRACTITIONER
    )
    setRelationshipSelectOptions(
      isPrimaryPractitioner
        ? SECONDARY_RELATIONSHIP_OPTIONS_FOR_GP
        : optionsForCategory(primaryRelationship)
    )
  }, [primaryRelationship])

  const createContactFromData = () => {
    setContactToEdit(contactFromFormValues(getValues() as ContactFormData))
    setShowContactRoles(true)
  }

  const handleOnChangeTitle =
    (onChange: (...event: any[]) => void) =>
    (e: OptionTypeBase, actionMeta: ActionMeta<unknown>) => {
      switch (actionMeta.action) {
        case 'clear':
          onChange(null)
          break
        default:
          onChange(e?.value)
      }
    }

  return (
    <>
      <RelationshipLabels register={register} />
      <div className={tw`mt-[24px]`}>
        <LabelAboveInput
          htmlFor="firstName"
          subLabel={requiredLabel(!!errors.firstName)}
          uppercase={false}
        >
          First Name
        </LabelAboveInput>
        <div className={tw`flex`}>
          <div className={tw`mr-[8px] flex-1`}>
            <BasicInput
              id="firstName"
              {...register('firstName', { required: true })}
            />
          </div>
          <Controller
            control={control}
            name="title"
            render={({ field: { onChange, value } }) => (
              <div className={tw`w-[136px]`}>
                <StyledSelect
                  name="title"
                  id="title"
                  options={TITLE_OPTIONS}
                  value={TITLE_OPTIONS.find((opt) => opt.value === value)}
                  onChange={handleOnChangeTitle(onChange)}
                  placeholder="Title..."
                  aria-labelledby="title"
                  isClearable
                />
              </div>
            )}
          />
        </div>
      </div>
      <div className={tw`mt-[24px]`}>
        <LabelAboveInput
          htmlFor="lastName"
          subLabel={requiredLabel(!!errors.lastName)}
          uppercase={false}
        >
          Last Name
        </LabelAboveInput>
        <BasicInput
          id="lastName"
          {...register('lastName', { required: true })}
        />
      </div>
      <Controller
        control={control}
        name="secondaryRelationship"
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { invalid } }) => (
          <div className={tw`mt-[24px]`}>
            <LabelAboveInput
              subLabel={requiredLabel(invalid)}
              htmlFor="secondaryRelationship"
              uppercase={false}
            >
              {primaryRelationship === CR.CONTACT_RELATIONSHIP_PERSONAL
                ? 'Relationship to Resident'
                : 'Profession'}
            </LabelAboveInput>
            <StyledSelect
              inputId="secondaryRelationship"
              name="secondaryRelationship"
              options={relationshipSelectOptions}
              value={value}
              onChange={onChange}
            />
          </div>
        )}
      />
      {primaryRelationship === CR.CONTACT_RELATIONSHIP_CLINICIAN && (
        <BasicInput
          className={tw`mt-[8px]`}
          id="clinicalSpecialty"
          placeholder="Clinical specialty or practice (optional)"
          {...register('clinicalSpecialty')}
        />
      )}
      {(primaryRelationship === CR.CONTACT_RELATIONSHIP_CLINICIAN ||
        primaryRelationship === CR.CONTACT_RELATIONSHIP_PROFESSIONAL) && (
        <BasicInput
          className={tw`mt-[8px]`}
          id="organization"
          placeholder="Organization, e.g. Smiles Dental Care (optional)"
          {...register('organization')}
        />
      )}
      <div className={tw`mt-[32px]`}>
        <LabelAboveInput htmlFor="address" uppercase={false}>
          Address
        </LabelAboveInput>
        <div className={tw`flex`}>
          <div className={tw`mr-[8px] flex-1`}>
            <BasicInput
              {...rest}
              name="address"
              ref={(e) => {
                ref(e)
                addressRef.current = e
              }}
              onChange={(e) => {
                const value = e.currentTarget.value
                setValue('addressTyped.text', value)
                setValue('address', value)
              }}
            />
          </div>
          <div className={tw`w-[136px]`}>
            <BasicInput
              {...register('addressTyped.line.1')}
              placeholder="Apt / Unit #"
            />
          </div>
        </div>
      </div>
      {/*Primary Phone, Secondary Phone, Email, and Fax inputs*/}
      <TelecomSection contact={contact} methods={methods} person={person} />
      <div className={tw`mt-[32px]`}>
        <AnimatedPopupFormFooter
          formState={formState}
          noBtn={{ action: () => void onClose(false) }}
          yesBtn={{
            label: 'Continue',
            props: {
              onClick: () => {
                void trigger().then((valid) => {
                  if (valid) {
                    createContactFromData()
                    setShowContactRoles(true)
                  }
                })
              },
            },
          }}
        />
      </div>
    </>
  )
}
