import React from 'react'
import { tw, twx } from '@shared/utils/tailwind'
import styles from '@app/components/Breadcrumbs/styles.module.css'

export const BreadcrumbContainer = ({
  className,
  children,
}: {
  className?: string
  children: React.ReactNode
}) => {
  const dateNow = new Date()

  return (
    <header
      className={twx(
        `w-full pb-[24px]`,
        `text-[14px] leading-[16px]`,
        `[&_a]:inline-block [&_a]:p-[8px]`,
        `[&_a:last-of-type]:font-semibold [&_a]:font-medium`,
        `[&_a:hover]:text-gray-03 [&_a:last-of-type]:text-gray-03 [&_a]:text-gray-07`,
        `[&_a:hover]:no-underline [&_a:last-of-type]:no-underline`,
        className
      )}
    >
      <div className={tw`flex`}>
        <div className={tw`grow`}>{children}</div>
        <div className={tw`hidden print:block`}>{dateNow.toString()}</div>
      </div>
    </header>
  )
}

export const BreadcrumbLinksContainer = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return <div className={styles.linksHolder}>{children}</div>
}
