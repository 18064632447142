import { useState } from 'react'
import { BasicCheckbox } from '@shared/components/BasicInput/BasicInput'
import { RecurringCharge } from '@shared/types/billing'
import { BillingModalType } from '@shared/utils/billing'
import { Loading } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import { Header } from '@app/components/Table/TableHeader'
import WithTable from '@app/pages/Facilities/Billing/Components/WithTable'
import { ChargesColumnName } from '../../helpers'
import ChargeRow from './ChargeRow'
import {
  getFilteredCharges,
  getSearchedBillingCharges,
  getSortedCharges,
  hasInactiveCharges,
} from './helpers'

type Props = {
  billingCharges: Loading<RecurringCharge[]>
  openChargeModal: (
    charge: RecurringCharge,
    modalType: BillingModalType
  ) => void
}

const headers: Header[] = [
  {
    label: ChargesColumnName.DESCRIPTION,
    className: 'w-auto',
  },
  {
    label: ChargesColumnName.AMOUNT,
    className: 'w-auto',
  },
  {
    label: ChargesColumnName.START_DATE,
    className: 'w-auto',
  },
  {
    label: ChargesColumnName.END_DATE,
    className: 'w-auto',
  },
  {
    label: '',
    className: 'w-[272px]',
  }, // Column for Buttons, no label
]

type FilterOpts = {
  showInactive: boolean
}

export default function RecurringChargesTable(props: Props) {
  const { billingCharges, openChargeModal } = props
  const [filterOpts, setFilterOpts] = useState<FilterOpts>({
    showInactive: false,
  })

  return (
    <div className={tw`pt-8`}>
      <WithTable
        title="Manage Recurring Charges"
        rows={getFilteredCharges(billingCharges, filterOpts)}
        headers={headers}
        defaultSelectedColumn={ChargesColumnName.DESCRIPTION}
        sortingFn={getSortedCharges}
        searchBoxProps={{
          searchPlaceholder: 'Search charges...',
          searchFn: getSearchedBillingCharges,
        }}
        titleActionChildren={
          hasInactiveCharges(billingCharges) && (
            <div className={tw`text-gray-600 text-sm`}>
              <BasicCheckbox
                name="showInactive"
                labelClassName="mb-0"
                onChange={() => {
                  setFilterOpts({
                    ...filterOpts,
                    showInactive: !filterOpts.showInactive,
                  })
                }}
                checked={filterOpts.showInactive}
              >
                Show Inactive
              </BasicCheckbox>
            </div>
          )
        }
      >
        {(sortedRows: RecurringCharge[]) => (
          <>
            {sortedRows.map((charge) => {
              return (
                <ChargeRow
                  key={`charge-row-${charge.meta.id}`}
                  billingCharge={charge}
                  openChargeModal={openChargeModal}
                />
              )
            })}
          </>
        )}
      </WithTable>
    </div>
  )
}
