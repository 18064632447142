import { useContext, useEffect, useState } from 'react'
import { Props as ReactSelectProps } from 'react-select'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import CurrentFacilityContext from '@shared/contexts/CurrentFacilityContext'
import ElementHolder, {
  Props as ElementHolderProps,
} from '@app/components/formElements/ElementHolder'
import DietSelect from '../index'

type Option = {
  label: string
  value: string
}

type Props = ReactSelectProps &
  Omit<ElementHolderProps, 'value' | 'children'> & {
    onUpdate: (value?: string, name?: string) => void
    value: string
  }

function getSelectedOption(dietType: string, options: OptionTypeBase[]) {
  if (dietType) {
    const selectedOption = options.find((opt) => opt.value === dietType)
    // Also allow to display option not in original options
    return selectedOption
      ? selectedOption
      : { label: dietType, value: dietType }
  }

  return undefined
}

export default function DietSelectInGenerator(props: Props) {
  const {
    settings: { dietSettingsOptions },
  } = useContext(CurrentFacilityContext)
  const { name, onUpdate, value } = props
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(
    getSelectedOption(value, dietSettingsOptions)
  )
  const [visible, setVisible] = useState<boolean>(true)

  useEffect(() => {
    setSelectedOption(getSelectedOption(value, dietSettingsOptions))
  }, [value])

  // Hide dropdown after clicking X icon, do not wait for API result being returned
  // So user will not be able to click X icon twice
  if (!visible) {
    return false
  }

  return (
    <ElementHolder {...props}>
      <DietSelect
        {...props}
        onChange={(option: { label: string; value: string }) => {
          if (option) {
            if (option.value !== value) {
              onUpdate(option.value, name)
            }
          } else {
            onUpdate(undefined, name)
            setVisible(false)
          }
        }}
        value={selectedOption}
      />
    </ElementHolder>
  )
}
