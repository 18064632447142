import { Occupancy_Room as OccupancyRoom } from '@augusthealth/models/com/august/protos/api/occupancy'
import { useContext } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { updateRoomCapacity } from '@app/api/rooms'
import ConfirmModal from '@app/components/ConfirmModal'

type Props = {
  occupancyRoom: OccupancyRoom
  onComplete: (didUpdate: boolean) => Promise<void>
}

export default function DeleteRoomPopup({ occupancyRoom, onComplete }: Props) {
  const { setError } = useContext(GlobalContext)
  const { beds, room } = occupancyRoom
  if (!room) {
    return null
  }

  const { orgId, facilityId, id, name } = room
  const onConfirm = async () => {
    if (orgId && facilityId && id) {
      try {
        await updateRoomCapacity(room, {
          capacity: 0,
        })
        await onComplete(true)
      } catch (e) {
        setError(e)
      }
    }
  }
  const roomLabel = name && parseInt(name, 10) ? `Room #${name}` : name

  if (beds?.some(({ occupants }) => occupants?.length)) {
    return (
      <ConfirmModal
        title="This room is still occupied!"
        content="Please move the resident first."
        confirmButtonConfig={{
          buttonStyle: 'primary-fill',
          onClick: () => onComplete(false),
        }}
        denyButtonConfig={{
          onClick: () => onComplete(false),
          hidden: true,
        }}
      />
    )
  }
  return (
    <ConfirmModal
      title="Delete this room?"
      content={roomLabel}
      confirmButtonConfig={{
        children: 'Delete Room',
        onClick: onConfirm,
      }}
      denyButtonConfig={{
        onClick: () => onComplete(false),
      }}
    />
  )
}
