import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import {
  BasicCheckbox,
  BasicTextarea,
} from '@shared/components/BasicInput/BasicInput'
import { SmallDotDivider } from '@shared/components/DotDivider'
import { LabelAboveInput } from '@shared/components/Labels'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { firstLastName, primaryEmail } from '@shared/utils/contact'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { getResponsiblePerson } from '@shared/utils/person'
import { getSortedTasks } from '@shared/utils/task'
import { shareTasks } from '@app/api/tasks'
import PersonContext from '@app/contexts/PersonContext'
import styles from './styles.module.css'
import {
  defaultPersonalMessage,
  shareableTasks,
  TaskCheckboxItem,
} from './helpers'

interface Props {
  onComplete: (shared: boolean) => void
}

interface FormData {
  tasksToShare: string[]
  personalMessage: string
}

export default function ShareWithRP({ onComplete }: Props) {
  const { setError } = useContext(GlobalContext)
  const { user } = useUserContext()
  const { person, tasks } = useContext(PersonContext)
  const { register, handleSubmit, watch } = useForm<FormData>({
    defaultValues: { tasksToShare: [] },
    mode: 'onChange',
  })
  const [isLoading, setIsLoading] = useState(false)

  if (!person || !tasks) {
    return null
  }

  const responsiblePerson = getResponsiblePerson(person)

  if (!responsiblePerson) {
    return null
  }

  const rpName = firstLastName(responsiblePerson)
  const rpEmail = primaryEmail(responsiblePerson)

  const shareable = shareableTasks(getSortedTasks(tasks))
  const hasTaskToShare = !!shareable.length

  const onSubmit = async (data: FormData) => {
    setIsLoading(true)

    const taskIds = data.tasksToShare.filter((taskToShareId) => {
      const isAlreadyShared = tasks.find((t) => t.id === taskToShareId)?.shared

      return !isAlreadyShared
    })

    try {
      await shareTasks({
        person,
        taskIds,
        message: data.personalMessage,
      })

      onComplete(true)
    } catch (e) {
      setError(e)
      setIsLoading(false)
    }
  }

  return (
    <AnimatedPopup
      title="Share with Responsible Person"
      onClickBackground={() => !isLoading && onComplete(false)}
    >
      {hasTaskToShare && (
        <div>
          Invite{' '}
          <span className="font-semibold">
            {rpName} {rpEmail && `(${rpEmail})`}
          </span>{' '}
          to help complete the following documents:
        </div>
      )}
      <form className="mt-[32px]" onSubmit={handleSubmit(onSubmit)}>
        {hasTaskToShare &&
          shareable.map((task, ix) => {
            return (
              <div className="mt-[12px] font-medium" key={`task-${ix}`}>
                <BasicCheckbox
                  className="mr-[8px]"
                  {...register('tasksToShare')}
                  defaultChecked={task.shared}
                  value={task.taskId}
                  disabled={task.disabled}
                  id={`task-${ix}`}
                  data-testid={`task-${ix}`}
                >
                  {' '}
                  {task.title}{' '}
                  {(task.subtitle || task.shared) && <Subtitle task={task} />}
                </BasicCheckbox>
              </div>
            )
          })}
        {!hasTaskToShare && 'Nothing to share.'}
        {hasTaskToShare && (
          <div className="mt-[32px]">
            <LabelAboveInput htmlFor="personalMessage">
              Include a Personal Message
            </LabelAboveInput>
            <BasicTextarea
              style={{ minHeight: '114px' }}
              {...register('personalMessage')}
              defaultValue={defaultPersonalMessage(
                rpName,
                getFirstAndLastName(person.name),
                user.email!
              )}
            />
          </div>
        )}
        <div className={`mt-[32px] pt-[32px] ${styles.buttonContainer}`}>
          <Button
            buttonStyle="secondary-outline"
            className="mr-[16px] w-[110px]"
            onClick={() => onComplete(false)}
            type="button"
          >
            {hasTaskToShare ? 'Cancel' : 'Close'}
          </Button>
          {hasTaskToShare && (
            <Button
              buttonStyle="primary-fill"
              className="w-[110px]"
              disabled={watch('tasksToShare').length === 0 || isLoading}
              isLoading={isLoading}
              data-testid="share-tasks"
              type="submit"
            >
              Share
            </Button>
          )}
        </div>
      </form>
    </AnimatedPopup>
  )
}

function Subtitle({ task }: { task: TaskCheckboxItem }) {
  const { subtitle, shared, disabled } = task
  const color = disabled ? 'text-gray-07' : 'text-gray-06'

  if (subtitle && shared) {
    return (
      <span
        className={`text-[12px] font-medium ${color} uppercase`}
        style={{ marginLeft: '4px' }}
      >
        {subtitle} <SmallDotDivider /> Shared
      </span>
    )
  }

  if (subtitle) {
    return (
      <span
        className={`text-[12px] font-medium ${color} uppercase`}
        style={{ marginLeft: '4px' }}
      >
        {subtitle}
      </span>
    )
  }

  if (shared) {
    return (
      <span
        className={`text-[12px] font-medium ${color} uppercase`}
        style={{ marginLeft: '4px' }}
      >
        Shared
      </span>
    )
  }

  return null
}
