import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import GlobalContext from '@shared/contexts/GlobalContext'
import {
  hasDuplicateGroupName,
  hasEmptyGroupName,
} from '@shared/utils/careGroup'
import { AugustError } from '@shared/utils/error'
import {
  createOrUpdateMultipleCareGroups,
  deleteCareGroup,
} from '@app/api/careGroup'
import {
  createOrUpdateMultipleMedGroups,
  deleteMedGroup,
} from '@app/api/medGroup'
import styles from './styles.module.css'
import GroupInput from './GroupInput'
import {
  FacilityGroupsPopupProps,
  FieldName,
  FormData,
  OnDeleteError,
} from './helpers'

type FacilityGroupsFormProps = FacilityGroupsPopupProps & {
  onDeleteError: (props: OnDeleteError) => void
}

export default function FacilityGroupsForm({
  careGroups: originalCareGroups,
  medGroups: originalMedGroups,
  facility,
  onClose,
  onDeleteError,
}: FacilityGroupsFormProps) {
  const { setError } = useContext(GlobalContext)
  const [deletingGroupId, setDeletingGroupId] = useState<string>()
  const { formState, handleSubmit, register, setValue, watch } =
    useForm<FormData>({
      defaultValues: {
        careGroups: originalCareGroups,
        medGroups: originalMedGroups,
      },
      mode: 'onSubmit',
    })
  const careGroups = watch('careGroups')
  const medGroups = watch('medGroups')
  const hasDuplicate =
    hasDuplicateGroupName(careGroups) || hasDuplicateGroupName(medGroups)
  const hasEmptyName =
    hasEmptyGroupName(careGroups) || hasEmptyGroupName(medGroups)
  const save = async ({
    careGroups: updatedCareGroups,
    medGroups: updatedMedGroups,
  }: FormData) => {
    try {
      await Promise.all([
        createOrUpdateMultipleCareGroups({
          facility,
          originalCareGroups,
          updatedCareGroups: updatedCareGroups.filter((c) => c.name),
        }),
        createOrUpdateMultipleMedGroups({
          facility,
          originalMedGroups,
          updatedMedGroups: updatedMedGroups.filter((c) => c.name),
        }),
      ])
      await onClose()
    } catch (e) {
      setError(e)
    }
  }

  // Function to create deleteGroup function based on fieldName: care or med groups
  const getHandleDeleteGroupFunc =
    (fieldName: FieldName) => async (deleteIndex: number) => {
      const isMedGroups = fieldName === 'medGroups'
      const groups = isMedGroups ? medGroups : careGroups
      const groupToDelete = groups.find((_v, i) => i === deleteIndex)
      const groupsToStay = groups.filter((_d, index) => index !== deleteIndex)
      if (groupToDelete?.id) {
        const deleteFunc = isMedGroups ? deleteMedGroup : deleteCareGroup
        try {
          setDeletingGroupId(groupToDelete.id)
          await deleteFunc({
            group: groupToDelete,
            facility,
          })

          setValue(fieldName, groupsToStay)
        } catch (error) {
          onDeleteError({
            callback: (updated: boolean) => {
              if (updated) {
                setValue(fieldName, groupsToStay)
              }
            },
            fieldName,
            group: groupToDelete,
            error: error as AugustError,
          })
        } finally {
          setDeletingGroupId(undefined)
        }
      } else {
        // Remove a new care group which is not saved yet
        setValue(fieldName, groupsToStay)
      }
    }

  const lastCareGroup = careGroups[careGroups.length - 1]
  const lastMedGroup = medGroups[medGroups.length - 1]
  useEffect(() => {
    // Add new empty care group input when need
    if (!lastCareGroup || lastCareGroup?.name) {
      setValue('careGroups', [...careGroups, {}])
    }
    if (!lastMedGroup || lastMedGroup?.name) {
      setValue('medGroups', [...medGroups, {}])
    }
  }, [lastCareGroup?.name, lastMedGroup?.name])

  return (
    <section>
      <form className={styles.form} onSubmit={handleSubmit(save)}>
        <h2 className={styles.title}>{facility.name}</h2>
        <h3 className={styles.subTitle}>Care groups</h3>
        {careGroups.map((g, i, list) => {
          return (
            <GroupInput
              group={g}
              deletingGroupId={deletingGroupId}
              list={list}
              i={i}
              ignoreValidation={i === list.length - 1}
              facility={facility}
              fieldName="careGroups"
              key={`care-group-${i}`}
              onClickDelete={async () =>
                await getHandleDeleteGroupFunc('careGroups')(i)
              }
              register={register}
            />
          )
        })}
        <h3 className={styles.subTitle}>Med groups</h3>
        {medGroups.map((g, i, list) => {
          return (
            <GroupInput
              group={g}
              deletingGroupId={deletingGroupId}
              list={list}
              i={i}
              ignoreValidation={i === list.length - 1}
              facility={facility}
              fieldName="medGroups"
              key={`med-group-${i}`}
              onClickDelete={async () =>
                await getHandleDeleteGroupFunc('medGroups')(i)
              }
              register={register}
            />
          )
        })}
        <div className={'mt-[32px]'}>
          <AnimatedPopupFormFooter
            yesBtn={{
              props: {
                disabled:
                  hasEmptyName || hasDuplicate || deletingGroupId !== undefined,
              },
            }}
            noBtn={{ action: () => void onClose() }}
            formState={formState}
          />
        </div>
      </form>
    </section>
  )
}
