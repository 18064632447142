import {
  Contact,
  Contact_ContactRelationship as CR,
} from '@augusthealth/models/com/august/protos/contact'
import {
  ContactPoint_ContactPointSystem as Sys,
  ContactPoint_ContactPointUse as Use,
} from '@augusthealth/models/com/august/protos/contact_point'
import { intersectionWith, isEmpty } from 'lodash'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import { ROLE_RELATIONSHIP_MAP } from '@shared/constants/contactRelationship'
import { formatInlineAddressWithoutUnit } from '@shared/utils/address'
import {
  primaryEmail,
  primaryPhone,
  secondaryPhone,
} from '@shared/utils/contact'
import { getFax } from '@shared/utils/contactPoint'
import { getFirstName, getLastName, getTitle } from '@shared/utils/humanName'
import { isPrimaryCareProviderEligible } from '@shared/utils/relationship'
import {
  optionsForCategory,
  RelationshipCategory,
} from '@app/components/AddPersonPopup/RPVerifyInformation/helpers'
import { ContactFormData } from './types'

export const defaultValuesFromContact = (contact: Contact): ContactFormData => {
  const primaryRelationship = contact.relationship!.find((r) =>
    [
      CR.CONTACT_RELATIONSHIP_PERSONAL,
      CR.CONTACT_RELATIONSHIP_CLINICIAN,
      CR.CONTACT_RELATIONSHIP_PROFESSIONAL,
    ].includes(r)
  )! as RelationshipCategory
  return {
    firstName: getFirstName(contact.name),
    lastName: getLastName(contact.name),
    title: getTitle(contact.name) || undefined,
    primaryRelationship,
    secondaryRelationship: getSecondaryRelationship(
      contact.relationship!,
      primaryRelationship
    ),
    clinicalSpecialty: contact.clinicalSpecialty,
    primaryPhoneNumber: primaryPhone(contact)?.value,
    primaryPhoneType: primaryPhone(contact)?.use
      ? {
          label: getPhoneTypeLabel(primaryPhone(contact)!.use!),
          value: primaryPhone(contact)!.use!,
        }
      : undefined,
    secondaryPhoneNumber: secondaryPhone(contact)?.value,
    secondaryPhoneType: secondaryPhone(contact)?.use
      ? {
          label: getPhoneTypeLabel(secondaryPhone(contact)!.use!),
          value: secondaryPhone(contact)!.use!,
        }
      : undefined,
    emailAddress: primaryEmail(contact),
    faxNumber: getFax(contact.telecom)?.value,
    address: contact.address
      ? formatInlineAddressWithoutUnit(contact.address)
      : undefined,
    addressTyped: contact.address,
    notes: contact.note,
    roles: contact.relationship!.filter((r) =>
      Object.keys(ROLE_RELATIONSHIP_MAP).includes(r)
    ),
    organization: contact.organization?.name,
  }
}
export const contactFromFormValues = (formData: ContactFormData) => {
  const contact: Contact = {
    name: {
      family: formData.lastName,
      given: [formData.firstName],
      prefix: formData.title,
    },
    relationship: [
      formData.primaryRelationship,
      formData.secondaryRelationship.value,
      ...(formData.roles || []).map((r) => r),
    ],
    telecom: [],
    note: formData.notes,
    // @ts-expect-error
    address: getUpdatedAddress(formData),
    clinicalSpecialty: formData.clinicalSpecialty,
    // @ts-expect-error
    organization: null,
  }

  if (formData.primaryPhoneNumber?.trim()) {
    contact.telecom!.push({
      value: formData.primaryPhoneNumber,
      system: Sys.CONTACT_POINT_SYSTEM_PHONE,
      use: formData.primaryPhoneType?.value,
    })
  }

  if (formData.secondaryPhoneNumber?.trim()) {
    contact.telecom!.push({
      value: formData.secondaryPhoneNumber,
      system: Sys.CONTACT_POINT_SYSTEM_PHONE,
      use: formData.secondaryPhoneType?.value,
    })
  }

  if (formData.emailAddress) {
    contact.telecom!.push({
      value: formData.emailAddress,
      system: Sys.CONTACT_POINT_SYSTEM_EMAIL,
    })
  }

  if (formData.faxNumber?.trim()) {
    contact.telecom!.push({
      value: formData.faxNumber,
      system: Sys.CONTACT_POINT_SYSTEM_FAX,
    })
  }

  if (formData.organization) {
    contact.organization = {
      name: formData.organization,
    }
  }

  return contact
}

export const getPhoneTypeLabel = (contactPoint: Use) => {
  switch (contactPoint) {
    case Use.CONTACT_POINT_USE_HOME:
      return 'Home'
    case Use.CONTACT_POINT_USE_MOBILE:
      return 'Mobile'
    case Use.CONTACT_POINT_USE_WORK:
      return 'Work'
    case Use.CONTACT_POINT_USE_PAGER:
      return 'Pager'
    case Use.CONTACT_POINT_USE_EMERGENCY:
      return 'Emergency'
    default:
      return 'Home'
  }
}

export const phoneTypeOptions = () => {
  const types = [
    Use.CONTACT_POINT_USE_HOME,
    Use.CONTACT_POINT_USE_MOBILE,
    Use.CONTACT_POINT_USE_WORK,
    Use.CONTACT_POINT_USE_PAGER,
    Use.CONTACT_POINT_USE_EMERGENCY,
  ]
  return types.map((t) => ({ label: getPhoneTypeLabel(t), value: t }))
}
export const rolesForCategory = (
  category: CR,
  relationship?: CR
): OptionTypeBase<CR>[] => {
  let roles: CR[] = []
  switch (category) {
    case CR.CONTACT_RELATIONSHIP_PERSONAL:
    case CR.CONTACT_RELATIONSHIP_PROFESSIONAL:
      roles = [
        CR.CONTACT_RELATIONSHIP_FINANCIAL_PAYMENT_OF_CARE,
        CR.CONTACT_RELATIONSHIP_FINANCIAL_POWER_OF_ATTORNEY,
        CR.CONTACT_RELATIONSHIP_MEDICAL_POWER_OF_ATTORNEY,
        CR.CONTACT_RELATIONSHIP_C,
      ]
      break
    case CR.CONTACT_RELATIONSHIP_CLINICIAN:
      roles = [
        CR.CONTACT_RELATIONSHIP_MENTAL_HEALTH_PROVIDER,
        CR.CONTACT_RELATIONSHIP_HOSPICE_PROVIDER,
      ]
      break
  }
  if (relationship && isPrimaryCareProviderEligible([relationship])) {
    roles.unshift(CR.CONTACT_RELATIONSHIP_GENERAL_PRACTITIONER)
  }
  // @ts-ignore
  return roles.map((r) => ({ label: ROLE_RELATIONSHIP_MAP[r], value: r }))
}

export const hasPrimaryCareRoleChanged = (
  formData: ContactFormData,
  contact: Contact
) => {
  const isNewPCP =
    formData.roles?.some(
      (r) => r === CR.CONTACT_RELATIONSHIP_GENERAL_PRACTITIONER
    ) &&
    !contact.relationship!.some(
      (r) => r === CR.CONTACT_RELATIONSHIP_GENERAL_PRACTITIONER
    )
  const isNoLongerPCP =
    contact.relationship!.some(
      (r) => r === CR.CONTACT_RELATIONSHIP_GENERAL_PRACTITIONER
    ) &&
    !formData.roles?.some(
      (r) => r === CR.CONTACT_RELATIONSHIP_GENERAL_PRACTITIONER
    )

  return isNewPCP || isNoLongerPCP
}

const getSecondaryRelationship = (
  relationships: CR[],
  primaryRelationship: RelationshipCategory
): OptionTypeBase<Exclude<CR, CR>> => {
  const secondaryOptions = optionsForCategory(primaryRelationship)

  // @ts-ignore
  return intersectionWith(
    secondaryOptions,
    relationships,
    (s, r) => s.value === r
  )[0]
}

export const getUpdatedAddress = (formData: ContactFormData) => {
  if (formData.addressTyped) {
    if (formData.addressTyped?.text) {
      return {
        text: formData.addressTyped.text,
        line: null,
        city: null,
        state: null,
        postalCode: null,
      }
    }

    const { line, ...rest } = formData.addressTyped
    // react-hook-form may add a [null | empty, ""] when original .line is undefined due to .line.1 text will always return a string
    let updatedLine = Array.isArray(line) ? [...line] : null
    if (updatedLine) {
      updatedLine = updatedLine.map((l) => (l ? l : '')) // covert null to empty string if applicable
      if (updatedLine.every(isEmpty)) {
        updatedLine = null // Convert to null if it is a all-falsy array
      }
    } else {
      updatedLine = null
    }

    return {
      ...rest,
      line: updatedLine,
      text: null,
    }
  }

  return null
}
