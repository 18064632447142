/* eslint react-hooks/exhaustive-deps: 2 */

import { ReactNode, useEffect, useState } from 'react'
import { createCtx } from '@shared/contexts/createCtx'
import { RoutineAdministration } from '@shared/types/careapp'

interface SelectedRoutineAdministrationsContextProps {
  selected: RoutineAdministration[]
  toggleAdministration: (administration: RoutineAdministration) => void
  clearSelected: () => void
  setSelected: (administrations: RoutineAdministration[]) => void
}

export const [
  useSelectedRoutineAdministrationsContext,
  SelectedRoutineAdministrationsContextProvider,
] = createCtx<SelectedRoutineAdministrationsContextProps>(
  'SelectedRoutineAdministrationsContext'
)

export default function SelectedRoutineAdministrationsContext({
  children,
  filteredAdministrations,
}: {
  children: ReactNode
  filteredAdministrations: RoutineAdministration[]
}) {
  const [selected, setSelected] = useState<RoutineAdministration[]>([])

  useEffect(() => {
    setSelected([])
  }, [filteredAdministrations])

  const toggleAdministration = (administration: RoutineAdministration) => {
    setSelected((prev) => {
      if (prev.find(({ id }) => id === administration.id)) {
        return prev.filter(({ id }) => id !== administration.id)
      } else {
        return [...prev, administration]
      }
    })
  }

  const clearSelected = () => setSelected([])

  return (
    <SelectedRoutineAdministrationsContextProvider
      value={{
        selected,
        toggleAdministration,
        clearSelected,
        setSelected,
      }}
    >
      {children}
    </SelectedRoutineAdministrationsContextProvider>
  )
}
