import { isNil, omitBy } from 'lodash'
import { getETag } from '@shared/api/etags'
import { getUrl, queued } from '@shared/api/legacy'
import { SNAPSHOT_URL } from '@shared/api/person'
import { fetchJson } from '@shared/api/request'
import { mergePatchTask } from '@shared/api/task'
import { fetchUser } from '@shared/api/user'
import { SnapshotStatus } from '@shared/types/snapshot'
import { isResponsiblePerson } from '@shared/utils/user'

const queueByDataType = {}

export function patchSnapshot(
  params: Record<string, unknown>
): Promise<unknown> {
  let fn: CallableFunction | undefined = queueByDataType[
    params.dataType as string
  ] as CallableFunction | undefined
  if (!fn) {
    fn = queueByDataType[params.dataType as string] = queued(rawPatchSnapshot)
  }

  return fn(params) as Promise<unknown>
}

async function rawPatchSnapshot({
  pId,
  facilityId,
  orgId,
  taskId,
  snapshotId,
  dataType,
  customType,
  patch,
  submit,
  params,
}: {
  pId: string
  facilityId: string
  orgId: string
  taskId: string
  snapshotId: string
  dataType: string
  customType: string
  patch: unknown
  submit: unknown
  params: Record<string, string>
}): Promise<unknown> {
  // 'Send' them in parallel
  const props = omitBy(
    {
      pId,
      orgId,
      snapshotId,
      patch,
      dataType,
      customType,
      params,
      status: submit ? 'SNAPSHOT_STATUS_COMPLETE' : undefined,
    },
    isNil
  )
  const rspFut = mergePatchSnapshot(props as never)
  const userFut = fetchUser()

  // Once both are complete, patch the task
  const rsp = (await rspFut) as never
  const user = await userFut

  let task: Record<string, unknown> | undefined
  if (!submit) {
    task = {}
  } else {
    task = {
      status: isResponsiblePerson({ user, orgId, facilityId, personId: pId })
        ? 'TASK_STATUS_COMPLETE_BUT_NEW'
        : 'TASK_STATUS_COMPLETE',
      shared: true,
    }
  }

  await mergePatchTask({ pId, orgId, taskId, task })

  return rsp
}

export function mergePatchSnapshot({
  pId,
  orgId,
  snapshotId,
  patch,
  dataType,
  customType = undefined,
  status,
  params = {},
}: {
  pId: string
  orgId: string
  snapshotId: string
  patch: unknown
  dataType: string
  customType?: string
  status?: SnapshotStatus
  params?: Record<string, unknown>
}) {
  if (customType) {
    params.customType = customType
  }
  const url = getUrl({
    pId,
    orgId,
    snapshotId,
    dataType,
    baseUrl: SNAPSHOT_URL,
    params,
  })
  return fetchJson(url, {
    method: 'PATCH',
    body: JSON.stringify({ data: patch, status }),
    headers: {
      'If-Match': getETag(url) || '*',
      'Content-Type': 'application/merge-patch+json',
    },
  })
}
