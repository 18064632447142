import { RouteComponentProps } from 'react-router-dom'

export const ORGANIZATION_DASHBOARD_PATH =
  '/orgs/:orgId/facilities/:facilityId/insights'
export const ORGANIZATION_DASHBOARD_PAGE_PATH = `${ORGANIZATION_DASHBOARD_PATH}/:tab`

type Params = {
  orgId: string
  facilityId: string
  tab?: string
}

export type DashboardProps = RouteComponentProps<Params>
