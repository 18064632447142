import { getUrl } from '@shared/api/legacy'
import { SNAPSHOT_BY_ID_URL, SNAPSHOT_URL } from '@shared/api/person'
import { requestJson } from '@shared/api/request'
import environment from '@shared/environment'
import { DataType, Snapshot, SnapshotStatus } from '@shared/types/snapshot'

const { baseUrl } = environment

type ListSnapshotsUrlArgs = {
  orgId: string
  personId: string
  status: SnapshotStatus
  dataType?: DataType
  customType?: string
}

export function getSnapshotsUrl({
  orgId,
  personId,
  ...params
}: ListSnapshotsUrlArgs) {
  const queryParams = new URLSearchParams()
  Object.keys(params).forEach((param) => {
    if (params[param]) {
      queryParams.append(param, params[param])
    }
  })

  return `${baseUrl}/organizations/${orgId}/people/${personId}/snapshots?${queryParams}`
}

export function fetchSnapshots(props: ListSnapshotsUrlArgs) {
  const url = getSnapshotsUrl(props)
  return requestJson({ url })
}

interface FetchSnapshot {
  personId: string
  orgId: string
  snapshotId?: string
  dataType: DataType
  customType?: string
  create?: boolean
  fields?: string
}

interface FetchSnapshotURLParams {
  fields?: string
  create?: boolean
  customType?: string
}

export async function fetchSnapshotById({
  personId,
  orgId,
  snapshotId,
}: {
  personId: string
  orgId: string
  snapshotId: string
}) {
  const url: string = getUrl({
    pId: personId,
    orgId,
    snapshotId,
    baseUrl: SNAPSHOT_BY_ID_URL,
  })

  const response = await requestJson({ url })
  return response.data
}

export async function fetchSnapshot({
  personId,
  orgId,
  snapshotId = 'latest',
  dataType,
  customType,
  create = false,
  fields = '',
}: FetchSnapshot): Promise<Snapshot> {
  const params: FetchSnapshotURLParams = {}

  if (fields) params.fields = fields
  if (create) params.create = true
  if (customType) params.customType = customType

  const url: string = getUrl({
    pId: personId,
    orgId,
    snapshotId,
    dataType,
    baseUrl: SNAPSHOT_URL,
    params: params as unknown as Record<string, unknown>,
  })

  const response = await requestJson({ url })
  return response.data
}
