import { useContext, useState } from 'react'
import Datepicker from 'react-datepicker'
import { Controller, useFormContext } from 'react-hook-form'
import {
  BasicCheckbox,
  BasicTextarea,
} from '@shared/components/BasicInput/BasicInput'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect from '@shared/components/Selects/StyledSelect'
import { useUserContext } from '@shared/contexts/UserContext'
import FacilitiesContext from '@app/contexts/FacilitiesContext'
import styles from './styles.module.css'
import { facilityOptions } from './helpers'

type Props = {
  orgId: string
  facilityId: string
}

export default function AdmissionStep({ facilityId }: Props) {
  const { control, register, setValue } = useFormContext()
  const { facilities = [] } = useContext(FacilitiesContext)
  const { user } = useUserContext()
  const [disableDatepicker, setDisableDatepicker] = useState(false)
  const options = facilityOptions(facilities, user)

  const asapCheckbox = (
    <div className={styles.asapCheckbox}>
      <BasicCheckbox
        {...register('admissionASAP')}
        onChange={() => {
          // admission date & ASAP are mutually exclusive
          setValue('admissionDate', undefined)
          setDisableDatepicker(!disableDatepicker)
        }}
      >
        As soon as possible
      </BasicCheckbox>
    </div>
  )
  const summaryNotes = (
    <div className="mt-[32px]">
      <LabelAboveInput htmlFor="admissionNotes">Summary Notes</LabelAboveInput>
      <BasicTextarea
        {...register('admissionNotes')}
        placeholder="e.g. wants a room with a view"
      />
    </div>
  )

  return (
    <>
      <Controller
        control={control}
        defaultValue={options.find((f) => f.value.id === facilityId)}
        name="admissionFacility"
        render={({ field: { onChange, value } }) => (
          <div className="mt-[32px]">
            <LabelAboveInput htmlFor="admissionFacility">
              Desired Community
            </LabelAboveInput>
            <StyledSelect
              name="admissionFacility"
              value={value}
              onChange={onChange}
              options={options}
            />
          </div>
        )}
      />
      <div className={`mt-[32px] ${styles.dateContainer}`}>
        <Controller
          control={control}
          name="admissionDate"
          rules={{ required: false }}
          render={({ field: { onChange, value } }) => (
            <div className="mr-[16px]">
              <LabelAboveInput htmlFor="admissionDate">
                Desired Move-In Date
              </LabelAboveInput>
              <Datepicker
                disabled={disableDatepicker}
                id="admissionDate"
                onChange={(value, context) => {
                  onChange(value, context)
                  // admission date & ASAP are mutually exclusive
                  setValue('admissionASAP', undefined)
                }}
                customInput={
                  <CalendarInput data-testid="calendar-text-input" />
                }
                selected={value}
              />
            </div>
          )}
        />
        {asapCheckbox}
      </div>
      {summaryNotes}
    </>
  )
}
