import { pipe } from 'effect'
import au from './json/au.json'
import ca from './json/ca.json'
import cn from './json/cn.json'
import de from './json/de.json'
import fr from './json/fr.json'
import gb from './json/gb.json'
import india from './json/in.json' // "in" is a reserved word, use "india" instead
import jp from './json/jp.json'
import us from './json/us.json'

const SUPPORTED_COUNTRIES_TZ_HASH: Record<string, string[]> = {
  au,
  Australia: au,
  ca,
  Canada: ca,
  de,
  Germany: de,
  gb,
  ['United Kingdom']: gb,
  us,
  ['United States']: us,
  cn,
  China: cn,
  jp,
  Japan: jp,
  fr,
  France: fr,
  ['in']: india,
  India: india,
}

const SUPPORTED_TIMEZONES_BY_COUNTRY_CODE_HASH = Object.entries(
  SUPPORTED_COUNTRIES_TZ_HASH
).reduce((hash: Record<string, string>, [countryCode, timezones]) => {
  if (countryCode.length === 2) {
    // Only map 2 digit country code, ignore full Country Name
    timezones.forEach((tz) => (hash[tz] = countryCode))
  }

  return hash
}, {})

const TIMEZONE_MAP = {
  'America/Los_Angeles': 'America/Los Angeles (PST, PDT)',
  'America/Denver': 'America/Denver (MST, MDT)',
  'America/Phoenix': 'America/Phoenix (MST)',
  'America/Chicago': 'America/Chicago (CST, CDT)',
  'America/New_York': 'America/New York (EST, EDT)',
}

export const UsTimeZoneOptions = Object.keys(TIMEZONE_MAP).map((key) => ({
  label: TIMEZONE_MAP[key as keyof typeof TIMEZONE_MAP],
  value: key,
}))

// Get Supported Country Code
export function getCountryCode(tz?: string) {
  const timezone = tz || Intl.DateTimeFormat().resolvedOptions().timeZone
  return SUPPORTED_TIMEZONES_BY_COUNTRY_CODE_HASH[timezone] || 'us'
}

export function getCountryName(tz?: string, lang?: string) {
  const countryCode = getCountryCode(tz)
  return (
    new Intl.DisplayNames([lang || 'us'], { type: 'region' }).of(
      countryCode.toUpperCase()
    ) || ''
  )
}

export function getTimeZoneOptionsByCountryCode(code: string) {
  const timezones = SUPPORTED_COUNTRIES_TZ_HASH[code] as string[] | undefined
  return timezones?.map((t) => ({ label: t, value: t })) || []
}

/**
 * Get Timezone Options by Country or 2 digit country code
 * @param countryOrCode
 * @returns
 */
export function getTimeZoneOptions(countryOrCode?: string) {
  const code = countryOrCode || getCountryCode()
  if (code === 'us' || code === 'United States') {
    return UsTimeZoneOptions
  }

  return code ? getTimeZoneOptionsByCountryCode(code) : []
}

/**
 * Accepts timezone name from IANA Time Zone Database. e.g. UTC,
 * America/New_York, etc.
 */
export function getTimezoneDisplay(
  timeZone: string,
  zoneFormat: Intl.DateTimeFormatOptions['timeZoneName']
) {
  try {
    return pipe(
      new Intl.DateTimeFormat('en-US', {
        timeZone,
        timeZoneName: zoneFormat,
      }),
      (format) => format.formatToParts(new Date()),
      (formatParts) => formatParts.find(({ type }) => type === 'timeZoneName'),
      (zoneNamePart) => zoneNamePart?.value ?? timeZone
    )
  } catch {
    return timeZone
  }
}
