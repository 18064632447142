import { OrgFacilitySettings } from '@augusthealth/models/com/august/protos/org_facility_settings'
import { useEffect, useState } from 'react'
import {
  fetchTaskFacilitySettings,
  OrgFacilitySettingsHash,
} from '@shared/api/orgFacilitySettings'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { Loading } from '@shared/utils/loading'
import { getOrganization } from '@app/api/organizations'

export interface UseTaskFacilitySettingsProps {
  organization?: Organization
  facilities: Facility[]
  orgTaskDefinitions: Loading<OrgFacilitySettings[]>
  orgStateTaskDefinitions: Loading<OrgFacilitySettingsHash>
  facilityTaskDefinitions: Loading<OrgFacilitySettingsHash>
  orgScheduledTasks: Loading<OrgFacilitySettings[]>
  orgStateScheduledTasks: Loading<OrgFacilitySettingsHash>
  facilityScheduledTasks: Loading<OrgFacilitySettingsHash>
  reload: () => Promise<void>
}

/**
 * Loads task definitions for a given orgId. This includes:
 * - Org task definitions: Org level task definitions ONLY (no inheritance/hierarchy)
 * - Org state task definitions: Org-state level task definitions ONLY (no inheritance/hierarchy)
 * - Facility task definitions: All task definitions relevant for the org's facilities (with inheritance)
 * @param orgId
 */
export default function useTaskFacilitySettings({
  orgId,
}: {
  orgId: string
}): UseTaskFacilitySettingsProps {
  const [facilities, setFacilities] = useState<Facility[]>([])
  const [organization, setOrganization] = useState<Organization>()
  const [orgTaskDefinitions, setOrgTaskDefinitions] = useState<
    Loading<OrgFacilitySettings[]>
  >({ tag: 'Loading' })
  const [orgStateTaskDefinitions, setOrgStateTaskDefinitions] = useState<
    Loading<OrgFacilitySettingsHash>
  >({ tag: 'Loading' })
  const [facilityTaskDefinitions, setFacilityTaskDefinitions] = useState<
    Loading<OrgFacilitySettingsHash>
  >({ tag: 'Loading' })
  const [orgScheduledTasks, setOrgScheduledTasks] = useState<
    Loading<OrgFacilitySettings[]>
  >({ tag: 'Loading' })
  const [orgStateScheduledTasks, setOrgStateScheduledTasks] = useState<
    Loading<OrgFacilitySettingsHash>
  >({ tag: 'Loading' })
  const [facilityScheduledTasks, setFacilityScheduledTasks] = useState<
    Loading<OrgFacilitySettingsHash>
  >({ tag: 'Loading' })

  function reloadTaskFacilitySettings(orgIdParam: string) {
    return fetchTaskFacilitySettings(orgIdParam).then((res) => {
      const { activeFacilities, scheduledTaskDefinitions, taskDefinitions } =
        res
      setFacilities(activeFacilities)
      setOrgTaskDefinitions({
        tag: 'Complete',
        value: taskDefinitions.orgSettings,
      })
      setOrgStateTaskDefinitions({
        tag: 'Complete',
        value: taskDefinitions.orgStateSettings,
      })
      setFacilityTaskDefinitions({
        tag: 'Complete',
        value: taskDefinitions.inheritedFacilitySettings,
      })
      setOrgScheduledTasks({
        tag: 'Complete',
        value: scheduledTaskDefinitions.orgSettings,
      })
      setOrgStateScheduledTasks({
        tag: 'Complete',
        value: scheduledTaskDefinitions.orgStateSettings,
      })
      setFacilityScheduledTasks({
        tag: 'Complete',
        value: scheduledTaskDefinitions.facilitySettings,
      })
    })
  }

  /* Initial load of org, facilities, org task definitions */
  useEffect(() => {
    void getOrganization(orgId).then(setOrganization)
    void reloadTaskFacilitySettings(orgId)
  }, [orgId])

  return {
    organization,
    facilities,
    orgTaskDefinitions,
    orgStateTaskDefinitions,
    facilityTaskDefinitions,
    orgScheduledTasks,
    orgStateScheduledTasks,
    facilityScheduledTasks,
    reload: async () => reloadTaskFacilitySettings(orgId),
  }
}
