import { useContext, useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { match } from 'ts-pattern'
import { login } from '@shared/api/login'
import {
  loginWithMagicLink,
  userHasAccessToOrg,
} from '@shared/components/Auth/helpers'
import { LoaderSvg } from '@shared/components/LoadingPopup'
import GlobalContext from '@shared/contexts/GlobalContext'
import useQuery from '@shared/hooks/useQuery'
import { loginPath } from '@shared/routes'
import { ApiError } from '@shared/types/api/error_response'
import { isMagicLinksNotAllowedError } from '@shared/utils/error'
import { tw } from '@shared/utils/tailwind'

type MagicLinkLoginStep =
  | 'LOGGING_IN'
  | 'EXPIRED_LINK'
  | 'EMAIL_SUBMITTED'
  | 'MAGIC_LINKS_NOT_ALLOWED'

function Login({ applicationBasePath }: { applicationBasePath: string }) {
  const { setError } = useContext(GlobalContext)
  const [email, setEmail] = useState('')
  const [loginStep, setLoginStep] = useState<MagicLinkLoginStep | undefined>(
    undefined
  )
  const { e: loginEmail, c: loginCode } = useParams<{
    e: string | undefined
    c: string | undefined
  }>()
  const queryString = useQuery()
  const redirectPath = queryString.get('path') || applicationBasePath

  useEffect(() => {
    if (loginEmail && loginCode) {
      setLoginStep('LOGGING_IN')
      handleMagicLogin({
        redirectPath,
        email: loginEmail,
        code: loginCode,
        applicationBasePath,
      }).catch((e) => {
        if (isMagicLinksNotAllowedError(e)) {
          setLoginStep('MAGIC_LINKS_NOT_ALLOWED')
        } else if (e instanceof ApiError && e.status === 401) {
          setLoginStep('EXPIRED_LINK')
          setEmail(loginEmail)
        } else {
          setError(e)
        }
      })
    }
  }, [loginCode, loginEmail, redirectPath])

  const onSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
    path: string
  ) => {
    e.preventDefault()
    setLoginStep('EMAIL_SUBMITTED')
    try {
      await login(email, path)
    } catch (e) {
      if (isMagicLinksNotAllowedError(e)) {
        setLoginStep('MAGIC_LINKS_NOT_ALLOWED')
      } else {
        setError(e)
      }
    }
  }

  if (loginStep === 'MAGIC_LINKS_NOT_ALLOWED') {
    return (
      <Redirect to={`${loginPath(applicationBasePath)}?credentials=true`} />
    )
  }

  return (
    <div
      className={tw`flex h-screen flex-col items-center justify-center text-secondary-03 `}
      slot="sign-in"
    >
      <div
        className={tw`flex flex-col items-center rounded-xl bg-white px-[60px] pb-[16px] pt-[60px]`}
      >
        <img src="/svg/rebrand_logotype.svg" alt={'August Health wordmark'} />

        {match(loginStep)
          .with('EXPIRED_LINK', () => (
            <Expired onSubmit={(e) => onSubmit(e, redirectPath)} />
          ))
          .with('LOGGING_IN', () => <LoggingYouIn />)
          .with('EMAIL_SUBMITTED', () => <CheckEmail email={email} />)
          .with(undefined, () => <LoggingYouIn />)
          .exhaustive()}
        <Footer />
      </div>
    </div>
  )
}

const LoggingYouIn = () => (
  <>
    <div className={tw`mt-[40px]`}>
      <LoaderSvg />
    </div>
    <div
      className={tw`mt-[28px] font-inter text-[20px] font-semibold not-italic leading-[24px]`}
    >
      Logging you in
    </div>
  </>
)

function Expired({
  onSubmit,
}: {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}) {
  return (
    <div className={tw`flex w-[360px] flex-col items-center`}>
      <div
        className={tw`mt-[40px] font-inter text-[20px] font-semibold not-italic leading-[24px]`}
      >
        Your login link has expired
      </div>
      <form onSubmit={onSubmit} className={tw`w-full`}>
        <input
          className={tw`no-italic disabled:cursor-initial mt-[16px] h-[48px] w-full cursor-pointer rounded-[4px] border-0 bg-rebrand-primary text-center font-inter text-[14px] font-semibold uppercase leading-[16px] text-white hover:brightness-90 disabled:opacity-50`}
          type="submit"
          value="Send me a new link"
        />
      </form>
    </div>
  )
}

function CheckEmail({ email }: { email: string }) {
  return (
    <>
      <div
        className={tw`mt-[40px] font-inter text-[20px] font-semibold not-italic leading-[24px]`}
      >
        Check your email
      </div>
      <div className={tw`mt-[16px]`}>
        <div className={tw`text-center`}>
          We've sent a temporary login link to{' '}
          <span className={tw`font-bold`}>{email}</span>
        </div>
      </div>
    </>
  )
}

export function Footer() {
  return (
    <div
      className={tw`mt-[40px] flex flex-col items-center text-[12px] font-medium leading-[16px] text-secondary-07`}
    >
      <div>
        Or,{' '}
        <a
          href="mailto:support@augusthealth.com"
          className={tw`font-semibold text-rebrand-primary-light hover:underline`}
        >
          contact support
        </a>{' '}
        if you need help to log in.
      </div>
      <div className={tw`mt-[4px]`}>
        By logging in, you accept our{' '}
        <a
          href="/terms.html"
          className={tw`font-semibold text-rebrand-primary-light hover:underline`}
        >
          Terms
        </a>{' '}
        and{' '}
        <a
          href="/privacy.html"
          className={tw`font-semibold text-rebrand-primary-light hover:underline`}
        >
          Privacy Policy
        </a>
      </div>
    </div>
  )
}

async function handleMagicLogin({
  redirectPath,
  email,
  code,
  applicationBasePath,
}: {
  redirectPath: string
  email: string
  code: string
  applicationBasePath: string
}) {
  await loginWithMagicLink(email, code)

  try {
    const isValidRedirect = await userHasAccessToOrg(redirectPath)

    if (isValidRedirect) {
      window.location.href = redirectPath
    } else {
      window.location.href = applicationBasePath
    }
  } catch (e) {
    window.location.href = applicationBasePath
  }
}

export default Login
