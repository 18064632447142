import { sortBy } from 'lodash'
import { TimeZoneConvertFunc } from '@shared/contexts/CurrentFacilityContext'
import { Signer, Snapshot, SnapshotStatus } from '@shared/types/snapshot'
import { formatDate } from '@shared/utils/date'
import { getFullName } from '@shared/utils/humanName'

function isComplete(value: Snapshot) {
  return value.status === SnapshotStatus.SNAPSHOT_STATUS_COMPLETE
}

export function nameOfUploader(snapshot: Pick<Snapshot, 'lastModification'>) {
  const uploader = snapshot.lastModification?.modifiedByUserName
  return uploader && getFullName(uploader)
}

export function sortByCreation(snapshots: Snapshot[]) {
  return sortBy(snapshots, [
    (snapshot) => new Date(snapshot.createdAt || ''),
  ]).reverse()
}

function hasPendingSigner(signers: Signer[]) {
  return signers.some((s) => !s.signedAt)
}

export function wasCompletedWithoutAllSignatures(snapshot: Snapshot) {
  const { signatureDetails } = snapshot
  const { signers = [] } = signatureDetails || {}

  // When a snapshot is complete
  // But has at least one Pending Signer (without .signedAt)
  // Will assume that last .lastModification.modifiedByUserName is Mark-As-Done user
  return isComplete(snapshot) && hasPendingSigner(signers)
}

export function getFormattedCompletionDate(
  document: Snapshot,
  timezoneConverter?: TimeZoneConvertFunc
) {
  const completionDate = getRelevantDate(document)
  return completionDate
    ? formatDate(
        timezoneConverter ? timezoneConverter(completionDate) : completionDate
      )
    : 'Unknown'
}

export function getRelevantDate(snapshot: Snapshot) {
  if (snapshot.completedAt) {
    return snapshot.completedAt
  } else if (snapshot.lastModification?.modificationTime) {
    return snapshot.lastModification.modificationTime
  }

  return ''
}

export function getSnapshotName(snapshot: Snapshot, dafaultName?: string) {
  return snapshot.snapshotDetail?.displayName || dafaultName
}
