import { useContext, useEffect, useState } from 'react'
import {
  fetchFacilityCompletionStats,
  fetchFacilityTaskTemplates,
} from '@shared/api/facility'
import GlobalContext from '@shared/contexts/GlobalContext'
import {
  CompletionType,
  FacilityCompletionStats,
} from '@shared/types/api/facility_stats'
import {
  CompletionTypeToTemplateHash,
  getCompletionTypeListAndTemplateHash,
} from '@shared/utils/facilityStats'
import { Loading } from '@shared/utils/loading'

export default function useCompletionStats(
  {
    orgId,
    facilityId,
  }: {
    orgId: string
    facilityId: string
  },
  deps: React.DependencyList = []
) {
  const [completionStats, setCompletionStats] = useState<
    Loading<FacilityCompletionStats>
  >({
    tag: 'Loading',
  })
  const [availableCompletionTasks, setAvailableCompletionTasks] = useState<
    Loading<CompletionType[]>
  >({ tag: 'Loading' })
  const [templateHash, setTemplateHash] =
    useState<CompletionTypeToTemplateHash>()
  const { setError } = useContext(GlobalContext)
  const refreshStats = () =>
    fetchFacilityCompletionStats({ orgId, facilityId })
      .then((pts) => setCompletionStats({ tag: 'Complete', value: pts }))
      .catch(setError)

  useEffect(() => {
    void refreshStats()
    fetchFacilityTaskTemplates(orgId, facilityId)
      .then((response) => {
        const taskTemplateInfoList = response.data
        const { completionTypeList, templateHash } =
          getCompletionTypeListAndTemplateHash(taskTemplateInfoList)

        setTemplateHash(templateHash)
        setAvailableCompletionTasks({
          tag: 'Complete',
          value: completionTypeList,
        })
      })
      .catch((e) => {
        setError(e)
        setAvailableCompletionTasks({ tag: 'Complete', value: [] })
      })
  }, [orgId, facilityId, ...deps])

  return {
    completionStats,
    completionTypeToTemplateHash: templateHash,
    availableCompletionTasks,
    refreshStats,
  }
}
