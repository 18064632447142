import { useEffect } from 'react'

interface Arguments {
  inputRef: React.RefObject<HTMLInputElement>
  configureListener?: (
    maps: typeof google.maps
  ) => google.maps.MapsEventListener
  callback: (place: google.maps.places.PlaceResult) => void
}

export default function usePlacesAutocomplete({
  inputRef,
  configureListener,
  callback,
}: Arguments) {
  useEffect(() => {
    const googleMaps = window.google && window.google.maps

    if (inputRef.current && googleMaps) {
      const autocomplete = new googleMaps.places.Autocomplete(inputRef.current)

      const placeChangedListener = autocomplete.addListener(
        'place_changed',
        () => {
          const place = autocomplete.getPlace()
          callback(place)
        }
      )

      const otherListener = configureListener && configureListener(googleMaps)

      return () => {
        placeChangedListener.remove()
        otherListener && otherListener.remove()
      }
    }

    return () => null
  }, [])
}
