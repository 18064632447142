import { format, parseISO } from 'date-fns'
import { useImperativeHandle, useRef } from 'react'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { Control, Controller } from 'react-hook-form'
import {
  BadgedCalendarInput,
  CalendarInput,
} from '@shared/components/CalendarInput/CalendarInput'
import {
  LabelAboveInput,
  LabelAboveInputProps,
} from '@shared/components/Labels'
import { DateMessage } from '@shared/types/date'
import { tw } from '@shared/utils/tailwind'

type DateRelatedValue = Date | DateMessage | string | null | undefined
type ConvertToDateFunc = (d: DateRelatedValue) => Date | null | undefined
type ConvertToSaveValue = (d: Date | null) => DateRelatedValue
export type DatePickerProps = Omit<
  ReactDatePickerProps,
  'onChange' | 'selected'
> & {
  convertToDate: ConvertToDateFunc
  convertToSaveValue: ConvertToSaveValue
}

type Props = {
  control: Control<any>
  datePickerProps?: DatePickerProps
  labelProps?: LabelAboveInputProps
  holderDivProps?: React.HTMLProps<HTMLDivElement>
  calendarWidth?: string
  name: string
  required?: boolean
  style?: { tag: 'Calendar Icon' } | { tag: 'Badge Text'; value: string }
  showErrorBorder?: boolean
}

export default function RhfDatePicker({
  control,
  datePickerProps,
  labelProps,
  holderDivProps,
  name,
  required = false,
  calendarWidth,
  showErrorBorder,
  style = { tag: 'Calendar Icon' },
}: Props) {
  const {
    convertToDate = (d) => d,
    convertToSaveValue = (d: Date | null) => d,
    ...restDatePickerProps
  } = datePickerProps || {}
  const innerRef = useRef<DatePicker>(null)

  return (
    <div {...holderDivProps}>
      <LabelAboveInput htmlFor={name} {...labelProps} />
      <div className={tw`flex flex-grow items-center`}>
        <Controller
          control={control}
          name={name}
          rules={{ required }}
          render={({ field: { ref, onChange, value } }) => {
            useImperativeHandle(ref, () => ({
              focus: () => {
                const domNode = document.getElementById(name)
                domNode?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'nearest',
                })
                innerRef.current?.setFocus()
              },
            }))
            return (
              <DatePicker
                id={name}
                name={name}
                customInput={
                  style?.tag === 'Badge Text' ? (
                    <BadgedCalendarInput
                      badgeLabel={style.value}
                      showErrorBorder={showErrorBorder}
                      className={tw`scroll-mb-1`}
                    />
                  ) : (
                    <CalendarInput
                      width={calendarWidth}
                      showErrorBorder={showErrorBorder}
                      className={tw`scroll-mb-1`}
                    />
                  )
                }
                onChange={(d: Date | null) => onChange(convertToSaveValue(d))}
                selected={convertToDate(value)}
                ref={innerRef}
                {...restDatePickerProps}
              />
            )
          }}
        />
      </div>
    </div>
  )
}

export type PredefiendDatePickerProps = Omit<
  DatePickerProps,
  'convertToDate' | 'convertToSaveValue'
>
type PredefinedProps = Omit<Props, 'datePickerProps'> & {
  datePickerProps?: PredefiendDatePickerProps
}

export function DatePickerWithIsoDateStr(props: PredefinedProps) {
  const { datePickerProps, ...restProps } = props

  return (
    <RhfDatePicker
      datePickerProps={{
        ...datePickerProps,
        convertToDate: (isoDateStr) =>
          typeof isoDateStr === 'string' ? parseISO(isoDateStr) : undefined,
        convertToSaveValue: (d: Date | null) =>
          d ? format(d, 'yyyy-MM-dd') : null,
      }}
      {...restProps}
    />
  )
}
