import StyledSelect, {
  StyledSelectProps,
} from '@shared/components/Selects/StyledSelect'

export const CAPACITY_OPTIONS = [
  {
    label: 'Single',
    value: 1,
  },
  {
    label: 'Double',
    value: 2,
  },
]

export default function RoomCapacitySelect(
  props: Omit<StyledSelectProps, 'options'>
) {
  return (
    <StyledSelect
      placeholder="Select a room type..."
      options={CAPACITY_OPTIONS}
      {...props}
    />
  )
}
