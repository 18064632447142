import { AllergyIntolerance } from '@augusthealth/models/com/august/protos/allergy_intolerance'
import { Condition } from '@augusthealth/models/com/august/protos/condition'
import { ChangeEvent, KeyboardEvent, useState } from 'react'
import { SwitchSize } from '@app/components/Switch'
import ElementHolder, {
  Props as ElementHolderProps,
  ValueProps,
} from '../ElementHolder'
import DeletableInput from './index'

export type Props = Omit<ElementHolderProps, 'value' | 'children'> & {
  name: string
  onUpdate: (value?: ValueProps, name?: string) => void
  tabIndex?: number
  value?: string
  placeholder?: string
  hashValue?: AllergyIntolerance & Condition
  disabled?: boolean
  switchChecked?: boolean
  switchLabel?: string
  onSwitchChange?: () => void
}

export default function DeletableInputInGenerator(props: Props) {
  const {
    disabled,
    hashValue,
    name,
    onSwitchChange,
    onUpdate,
    switchChecked = false,
    switchLabel = '',
    value,
  } = props
  const [inputValue, setInputValue] = useState<string>(value || '')
  const update = (val: string) => {
    if (val) {
      onUpdate(val, name)
    } else {
      onUpdate(undefined, name)
    }
  }
  const change = (val: string) => {
    setInputValue(val)
  }
  const inputProps = { ...props }
  delete inputProps.onSwitchChange
  delete inputProps.switchChecked
  delete inputProps.switchLabel

  const switchProps =
    hashValue && onSwitchChange
      ? {
          checked: switchChecked,
          name: `${name}-toggler`,
          onChange: onSwitchChange,
          label: switchLabel,
          size: SwitchSize.small,
        }
      : undefined
  // If hashValueis undefined or empty string, it should be the input to add new entry
  const trashIconProps = hashValue
    ? {
        disabled,
        onClick: () => onUpdate(undefined, name),
      }
    : undefined

  return (
    <ElementHolder {...props}>
      <DeletableInput
        inputProps={{
          ...inputProps,
          onBlur: (ev: ChangeEvent<HTMLInputElement>) =>
            update(ev.target.value),
          onKeyUp: (ev: KeyboardEvent<HTMLInputElement>) => {
            if (ev.key === 'Enter') {
              const target = ev.target as HTMLInputElement
              update(target.value)
            }
          },
          onChange: (ev: ChangeEvent<HTMLInputElement>) =>
            change(ev.target.value),
          value: inputValue,
        }}
        switchProps={switchProps}
        trashIconProps={trashIconProps}
      />
    </ElementHolder>
  )
}
