import IMask from 'imask'
import { useContext, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { configureMfa } from '@shared/api/user'
import Button from '@shared/components/Auth/LoginWithUsernameOrEmail/Button'
import Footer from '@shared/components/Auth/LoginWithUsernameOrEmail/Footer'
import Badge from '@shared/components/Badge'
import { BasicInputProps } from '@shared/components/BasicInput/BasicInput'
import LogoHeader from '@shared/components/LogoHeader'
import GlobalContext from '@shared/contexts/GlobalContext'
import { ApiError, ErrorCode } from '@shared/types/api/error_response'
import { MfaChallenge, MfaCredentials, MfaMethod } from '@shared/types/auth'
import { buildAugustError } from '@shared/utils/error'
import { tw, twx } from '@shared/utils/tailwind'

interface PhoneInputProps {
  inputProps: BasicInputProps
  showErrorBorder: boolean
}

function MaskedPhoneInput({ inputProps, showErrorBorder }: PhoneInputProps) {
  const masked = new IMask.MaskedDynamic({
    mask: [{ mask: '(000) 000-0000' }],
  })
  const [textValue, setTextValue] = useState(inputProps.value ?? '')
  const { onChange } = inputProps

  return (
    <>
      <input
        {...inputProps}
        autoFocus
        type="text"
        className={twx(
          'h-[48px] w-[360px] rounded-[6px] border pl-[40px] font-medium hover:outline hover:outline-1  focus:outline focus:outline-1 ',
          {
            'border-gray-10 hover:outline-rebrand-primary-light focus:outline-rebrand-primary-light':
              !showErrorBorder,
            'border-tags-red hover:outline-tags-red focus:outline-tags-red':
              showErrorBorder,
          }
        )}
        placeholder="(555) 555-5555"
        onChange={(e) => {
          if (onChange) {
            onChange(e)
          }
          setTextValue(masked.resolve(e.currentTarget.value))
        }}
        value={textValue}
      />
      <i
        className={tw`fa fas fa-phone absolute left-[16px] top-[17px] text-rebrand-primary opacity-75`}
      />
    </>
  )
}

export interface Props {
  postRegistration: (challenge: MfaChallenge) => void
  credentials: MfaCredentials
}

export default function ConfigureMfa({ credentials, postRegistration }: Props) {
  const { setError } = useContext(GlobalContext)
  const methods = useForm({
    defaultValues: { phoneNumber: '' },
  })
  const {
    formState: { isSubmitting, isValid },
    register,
    handleSubmit,
  } = methods
  const [invalidPhoneNumberMsg, setInvalidPhoneNumberMsg] = useState('')

  async function onSubmit({ phoneNumber }: { phoneNumber: string }) {
    setInvalidPhoneNumberMsg('')
    try {
      const challenge = await configureMfa({
        value: phoneNumber,
        credentials: credentials,
        method: MfaMethod.SMS,
      })
      postRegistration(challenge)
    } catch (e) {
      const isApiError = e instanceof ApiError
      if (isApiError && e.status === 400) {
        const error = e.json?.errors?.find(
          (e) => e.code === ErrorCode.ERROR_CODE_DISPLAY_TO_CLIENT
        )
        setInvalidPhoneNumberMsg(
          error?.message || 'Please enter a valid phone number'
        )
      } else if (isApiError && e.status === 401) {
        setError(
          buildAugustError({
            name: 'Your session has expired',
            message: 'Your session has expired. Please login again.',
          })
        )
      } else {
        setError(
          buildAugustError({
            name: 'Something went wrong!',
            message:
              'Unable to verify your phone number. Please reach out to August Health support for assistance',
          })
        )
      }
    }
  }

  return (
    <div className={tw`flex flex-col items-center`}>
      <LogoHeader title="Secure your account" />
      <div
        className={tw`mt-[24px] w-[425px] text-nowrap text-center text-secondary-07`}
      >
        Your organization requires added security for all users.
        <br />
        Enter your mobile number to receive a verification code.
      </div>
      {invalidPhoneNumberMsg && (
        <div className={tw`mt-[24px]`}>
          <Badge color="red">{invalidPhoneNumberMsg}</Badge>
        </div>
      )}

      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={tw`mt-[40px] text-center`}
        >
          <div className={tw`relative mb-[16px]`}>
            <MaskedPhoneInput
              inputProps={{
                ...register('phoneNumber', {
                  required: true,
                  pattern: /^\(\d{3}\)\s\d{3}-\d{4}$/,
                }),
              }}
              showErrorBorder={Boolean(invalidPhoneNumberMsg)}
            />
            <div
              className={tw`mt-[16px] flex flex-col items-center text-[12px] font-medium leading-[16px] text-secondary-07`}
            >
              <div className={tw`mt-[4px]`}>
                Message and data rates may apply.
              </div>
              <div className={tw`mt-[4px]`}>
                You'll receive one message per login attempt.
              </div>
            </div>
          </div>
          <Button
            disabled={isSubmitting || !isValid}
            isLoading={isSubmitting}
            state={'engaged'}
            text={'Send verification code'}
          />
        </form>
      </FormProvider>
      <div className={tw`mt-[40px] text-[12px]`}>
        <a href="/" className={tw`text-secondary-07 underline`}>
          Back to log in
        </a>
      </div>
      <Footer />
    </div>
  )
}
