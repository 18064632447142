import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'
import { BasicInputProps } from '@shared/components/BasicInput/BasicInput'
import {
  EmailInput,
  FaxInput,
  isValidEmail,
  phoneMask,
} from '@shared/components/TextInputWithIcon/TextInputWithIcon'
import {
  ContactPoint,
  ContactPoint_ContactPointSystem as ContactPointSystem,
  ContactPoint_ContactPointUse as ContactPointUse,
} from '@shared/types/contact_point'
import { upsertTelecom } from '@shared/utils/contactPoint'
import ElementHolder, {
  Props as ElementHolderProps,
} from '@app/components/formElements/ElementHolder'
import { CONTACT_POINT_USE_OPTIONS, Option } from '../ContactPointUseDropdown'
import PhoneInput from '../PhoneInput'

type Props = Omit<ElementHolderProps, 'children'> &
  Omit<BasicInputProps, 'onChange' | 'value'> & {
    onChange?: (value: any) => void
    onUpdate: (value: ContactPoint | null, name: string) => void
    value?: ContactPoint
    showUseDropdown?: boolean
    contactPointSystem: ContactPointSystem
  }

function TelecomInputInGenerator(props: Props) {
  const {
    name = '',
    value: originalContactPoint,
    disabled = false,
    onChange,
    onUpdate,
    showUseDropdown = false,
    contactPointSystem,
  } = props
  const isPhone =
    contactPointSystem === ContactPointSystem.CONTACT_POINT_SYSTEM_PHONE
  const isFax =
    contactPointSystem === ContactPointSystem.CONTACT_POINT_SYSTEM_FAX
  const isEmail =
    contactPointSystem === ContactPointSystem.CONTACT_POINT_SYSTEM_EMAIL
  const applyNumberMask = isPhone || isFax
  const inputName = `${name}.value`
  const convertValueToTelecom = (value: string): ContactPoint | null => {
    return upsertTelecom({
      contactPointSystem,
      originalTelecom: originalContactPoint,
      value,
    })
  }
  const convertUseToTelecom = (
    contactPointUse: ContactPointUse
  ): ContactPoint | null => {
    return upsertTelecom({
      contactPointSystem,
      originalTelecom: originalContactPoint,
      contactPointUse,
      value: originalContactPoint?.value,
    })
  }

  const contactPointUse = originalContactPoint?.use
  const dropdownName = `${name}.use`
  const dropdownProps = showUseDropdown
    ? {
        name: dropdownName,
        value: CONTACT_POINT_USE_OPTIONS.find(
          (opt) => opt.value === contactPointUse
        ),
        onChange: (opt?: Option) => {
          onUpdate(convertUseToTelecom(opt?.value as ContactPointUse), name)
        },
        isDisabled: disabled,
      }
    : undefined
  const [inputValue, setInputValue] = useState(
    originalContactPoint?.value || ''
  )
  useEffect(() => {
    setInputValue(originalContactPoint?.value || '')
  }, [originalContactPoint])

  const [alertMessage, setAlertMessage] = useState('')
  const update = (text: string) => {
    if (isEmail && text && !isValidEmail(text)) {
      setAlertMessage('Invalid email format')
    } else {
      onUpdate(convertValueToTelecom(text), name)
      setAlertMessage('')
    }
  }
  const inputProps = {
    ...props,
    name: inputName,
    value: inputValue,
    onBlur: (ev: ChangeEvent<HTMLInputElement>) => {
      update(ev.target.value)
    },
    onKeyUp: (ev: KeyboardEvent<HTMLInputElement>) => {
      if (ev.key === 'Enter') {
        update((ev.target as HTMLInputElement).value)
      }
    },
    onChange: (ev: ChangeEvent<HTMLInputElement>) => {
      const text = ev.target.value
      setInputValue(applyNumberMask ? phoneMask.resolve(text) : text)
      onChange?.(text)
    },
  }

  let content
  if (isPhone) {
    content = (
      <PhoneInput inputProps={inputProps} dropdownProps={dropdownProps} />
    )
  } else if (isFax) {
    content = <FaxInput inputProps={inputProps} />
  } else if (isEmail) {
    content = <EmailInput inputProps={inputProps} />
  }

  return (
    <ElementHolder {...props} alertMessage={alertMessage}>
      {content}
    </ElementHolder>
  )
}

export function TelecomPhoneInputInGenerator(
  props: Omit<Props, 'contactPointSystem'>
) {
  return (
    <TelecomInputInGenerator
      contactPointSystem={ContactPointSystem.CONTACT_POINT_SYSTEM_PHONE}
      {...props}
    />
  )
}

export function TelecomEmailInputInGenerator(
  props: Omit<Props, 'contactPointSystem'>
) {
  return (
    <TelecomInputInGenerator
      contactPointSystem={ContactPointSystem.CONTACT_POINT_SYSTEM_EMAIL}
      {...props}
    />
  )
}

export function TelecomFaxInputInGenerator(
  props: Omit<Props, 'contactPointSystem'>
) {
  return (
    <TelecomInputInGenerator
      contactPointSystem={ContactPointSystem.CONTACT_POINT_SYSTEM_FAX}
      {...props}
    />
  )
}
