import { ResidentStatus } from '@shared/types/person'

export function isProspect(status: ResidentStatus) {
  return status === ResidentStatus.RESIDENT_STATUS_PROSPECT
}

export function isMoveIn(status: ResidentStatus) {
  return isProspect(status)
}

export function isCurrentResident(status: ResidentStatus) {
  return status === ResidentStatus.RESIDENT_STATUS_CURRENT_RESIDENT
}

export function isDischarged(status: ResidentStatus) {
  return status === ResidentStatus.RESIDENT_STATUS_DISCHARGED
}

export function isClosed(status: ResidentStatus) {
  return status === ResidentStatus.RESIDENT_STATUS_CLOSED
}
