import { formatISO } from 'date-fns'
import { identity } from 'lodash'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { download } from '@shared/api/legacy'
import { apiUrl, facilityUrl } from '@shared/api/urls'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Facility } from '@shared/types/facility'
import { tw } from '@shared/utils/tailwind'
import RhfDatePicker from '@app/components/reactHookForm/RhfDateTimePicker'
import BillingModal from '../../Modals/components/BillingModal'

type LedgerExportFormData = {
  endDate: Date
}

type Props = {
  closeFn: (downloaded: boolean) => Promise<void>
  facility: Facility
}

export function GenerateLedgerExportModal({ closeFn, facility }: Props) {
  const { setError } = useContext(GlobalContext)
  const {
    control,
    handleSubmit,
    formState,
    formState: { disabled },
  } = useForm<LedgerExportFormData>({
    defaultValues: {
      endDate: new Date(),
    },
  })

  async function onSubmit(formData: LedgerExportFormData) {
    const endDateISO = formatISO(formData.endDate, { representation: 'date' })
    const url = apiUrl(
      facilityUrl(facility.orgId, facility.id),
      '/billing/journalEntries',
      {}
    )

    try {
      await download({
        fileUrl: url,
        target: '_blank',
        method: 'POST',
        body: JSON.stringify({ onOrBefore: endDateISO }),
        contentType: 'application/json',
      })

      await closeFn(true)
    } catch (e) {
      setError(e)
      await closeFn(false)
    }
  }

  return (
    <BillingModal
      onClose={() => closeFn(false)}
      id="generate-ledger-export-modal"
      title="General Ledger"
      contentClassName="overflow-visible"
    >
      <form onSubmit={handleSubmit(onSubmit)} data-testid="form">
        <RhfDatePicker
          labelProps={{
            children: 'Export all charges until',
            required: true,
          }}
          control={control}
          name="endDate"
          datePickerProps={{
            convertToDate: identity,
            convertToSaveValue: identity,
          }}
        />
        <div className={tw`mt-[32px]`}>
          <AnimatedPopupFormFooter
            yesBtn={{ label: 'Download', props: { disabled } }}
            noBtn={{ action: () => void closeFn(false) }}
            formState={formState}
          />
        </div>
      </form>
    </BillingModal>
  )
}
