import { Person } from '@shared/types/person'
import { DeepNull } from '@shared/types/utilities'
import { isEmptyObject } from '@shared/utils/common'

export function filterOutEmptyConditions(patch: DeepNull<Person>) {
  const updatedPatch = { ...patch }
  let { conditions } = updatedPatch
  if (Array.isArray(conditions)) {
    conditions = conditions.filter(
      (a) =>
        a.code?.text?.trim() ||
        (a.code?.coding && !isEmptyObject(a.code.coding))
    )
    updatedPatch.conditions = conditions
  }

  return updatedPatch
}
