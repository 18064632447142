import { StateMachineType, TaskTemplateInfo } from '@shared/types/task'

const SIGNABLE_MACHINE_TYPES = [
  StateMachineType.STATE_MACHINE_TYPE_SHAREABLE_AND_SIGNABLE,
  StateMachineType.STATE_MACHINE_TYPE_SHAREABLE_AND_SIGNABLE_NO_REVIEW,
  StateMachineType.STATE_MACHINE_TYPE_SIGNABLE_NOT_SHAREABLE,
]

const FILLABLE_MACHINE_TYPES = [
  StateMachineType.STATE_MACHINE_TYPE_FILLABLE_PDF,
  StateMachineType.STATE_MACHINE_TYPE_SHAREABLE_FILLABLE_PDF,
]

export function isSignable(template: TaskTemplateInfo) {
  const { stateMachineType } = template

  return stateMachineType && SIGNABLE_MACHINE_TYPES.includes(stateMachineType)
}

export function isFillable(template: TaskTemplateInfo) {
  const { stateMachineType } = template

  return stateMachineType && FILLABLE_MACHINE_TYPES.includes(stateMachineType)
}
