import { Occupancy_Room as OccupancyRoom } from '@augusthealth/models/com/august/protos/api/occupancy'
import { ReactNode, useContext } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import { Person, ResidentStatus } from '@shared/types/person'
import { displayResidentStatus } from '@shared/utils/person'
import { tw, twx } from '@shared/utils/tailwind'
import { NameTd, TrWithHoverEffect } from '@app/components/Table'
import OccupancyContext from '@app/pages/Facilities/Occupancy/OccupancyContext'
import styles from './styles.module.css'
import BedRow from './BedRow'
import RoomBedName from './RoomBedName'

type Props = {
  occupancyRoom: OccupancyRoom
}

export default function TableRow({ occupancyRoom }: Props) {
  const { setDeleteRoomPopupData, setEditRoomPopupData } =
    useContext(OccupancyContext)
  const { beds, room } = occupancyRoom
  if (!room || !beds) {
    return null
  }

  const { id, name } = room
  const bedRows = beds.map((b) => {
    const { id: bedId } = b.bed || {}
    const key = `${id}-${bedId}-row`

    return <BedRow key={key} occupancyBed={b} occupancyRoom={occupancyRoom} />
  })
  const roomBedNames = beds.reduce((list: ReactNode[], b, ci) => {
    const { bed = {}, occupants = [] } = b
    if (occupants.length) {
      occupants.forEach((_o, i) => {
        list.push(
          <RoomBedName room={room} bed={bed} index={i} key={`rbn-${ci}-${i}`} />
        )
      })
    } else {
      list.push(<RoomBedName room={room} bed={bed} key={`rbnu-${ci}`} />)
    }

    return list
  }, [])
  const residentStatus = beds.reduce((list: ReactNode[], b, ci) => {
    const { occupants = [] } = b
    if (occupants.length) {
      occupants.forEach((o) => {
        const { personId, residentStatus } = o
        const statusLabel = displayResidentStatus(o as Person)
        list.push(
          <div
            key={`resident-status-${personId}`}
            className={twx(styles.rowInRow, 'text-gray-07', {
              hidden:
                residentStatus !== ResidentStatus.RESIDENT_STATUS_PROSPECT,
            })}
          >
            {statusLabel}
          </div>
        )
      })
    } else {
      list.push(
        <div className={twx(styles.rowInRow, 'hidden')} key={`rs-d-${ci}`}>
          No Occupant
        </div>
      )
    }

    return list
  }, [])
  const trKey = `roomRow${name}`

  return (
    <TrWithHoverEffect
      className={styles.row}
      onClick={() => setEditRoomPopupData({ occupancyRoom })}
    >
      <NameTd data-cy={trKey} className={tw`whitespace-nowrap`}>
        {roomBedNames}
      </NameTd>
      <td>{bedRows}</td>
      <td>{residentStatus}</td>
      <td className={tw`text-left`}>
        <ButtonLink
          data-cy={`deleteRoom${name}`}
          onClick={(ev) => {
            ev.stopPropagation()
            setDeleteRoomPopupData({ occupancyRoom })
          }}
        >
          Delete
        </ButtonLink>
      </td>
    </TrWithHoverEffect>
  )
}
