import { AugustInitialAppraisal } from '@augusthealth/models/com/august/protos/august_initial_appraisal'
import { AppraisalSettings_AssessmentType } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { Option, pipe } from 'effect'
import { useContext, useState } from 'react'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { assessmentTypeToDisplay } from '@shared/utils/assessmentConfiguration'
import styles from './styles.module.css'
import { AssessmentChange } from './AssessmentPage/types'

export default function AssessmentType({
  disabled: initialDisabledValue,
  assessment,
  saveAppraisal,
}: {
  disabled: boolean
  assessment: AugustInitialAppraisal
  saveAppraisal: (appraisal: AssessmentChange) => Promise<void>
}) {
  const { setError } = useContext(GlobalContext)
  const assessmentTypes: AppraisalSettings_AssessmentType[] =
    assessment.settings?.assessmentTypes ?? []

  const getInitialAssessmentType =
    (): OptionTypeBase<AppraisalSettings_AssessmentType> | null => {
      if (assessment?.levelOfCare?.assessmentType) {
        return {
          label: pipe(
            assessmentTypeToDisplay(assessment!.levelOfCare!.assessmentType),
            Option.getOrElse(() => '')
          ),
          value: assessment!.levelOfCare!.assessmentType,
        }
      } else {
        return {
          label: '',
          value: AppraisalSettings_AssessmentType.ASSESSMENT_TYPE_UNSPECIFIED,
        }
      }
    }

  const [assessmentType, setAssessmentType] =
    useState<OptionTypeBase<AppraisalSettings_AssessmentType> | null>(
      getInitialAssessmentType
    )
  const [disabled, setDisabled] = useState(initialDisabledValue)

  if (assessmentTypes.length === 0) {
    return null
  }

  const onChange = async (
    newValue: OptionTypeBase<AppraisalSettings_AssessmentType>
  ) => {
    try {
      setDisabled(true)
      setAssessmentType(newValue)
      await saveAppraisal({
        tag: 'AssessmentTypeChange',
        assessmentType: newValue.value,
      })
    } catch (e) {
      setError(e)
    } finally {
      setDisabled(initialDisabledValue)
    }
  }

  const options = assessmentTypes
    .map((ar) => ({
      label: pipe(
        assessmentTypeToDisplay(ar!),
        Option.getOrElse(() => '')
      ),
      value: ar!,
    }))
    .concat({
      label: '',
      value: AppraisalSettings_AssessmentType.ASSESSMENT_TYPE_UNSPECIFIED,
    })

  return (
    <div className={'mb-[40px]'}>
      <h2 className={[styles.h2, 'mb-[16px]'].join(' ')}>
        <i className={`fas fa-square ${styles.square}`} />
        Assessment Type
      </h2>
      <StyledSelect
        styles={{
          menuList: (provided) => ({
            ...provided,
            height: '135px',
          }),
        }}
        isDisabled={disabled}
        options={options}
        onChange={onChange}
        value={assessmentType}
      />
    </div>
  )
}
