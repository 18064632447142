import { apiUrl, personUrl } from '@shared/api/urls'
import useBlobData from './useBlobData'

type Props = {
  invoiceId: string
  orgId: string
  facilityId: string
  personId: string
}

export default function useBillingPayerUserStatementPreview(props: Props) {
  const { orgId, facilityId, invoiceId, personId } = props
  const fileUrl = apiUrl(
    personUrl(orgId, facilityId, personId),
    `/payers/statements/:invoiceId.pdf`,
    { invoiceId }
  )
  const statementBlob = useBlobData(fileUrl)

  return {
    statementBlob,
  }
}
