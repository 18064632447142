import { LatestVital } from '@augusthealth/models/com/august/protos/api/latest_vital'
import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import ButtonLink from '@shared/components/ButtonLink'
import Icon from '@shared/components/Icon'
import {
  hasPermissionForPerson,
  PersonPermissionGate,
} from '@shared/components/PermissionGates/PermissionGates'
import CurrentFacilityContext from '@shared/contexts/CurrentFacilityContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { Person } from '@shared/types/person'
import { Vital } from '@shared/types/vital'
import { Loading } from '@shared/utils/loading'
import { tw, twx } from '@shared/utils/tailwind'
import { VitalsFormData, VitalsType } from '@shared/utils/vitals'
import {
  NameTd,
  TdWithExtraPadding,
  TrWithHoverEffect,
} from '@app/components/Table'
import { formatVital } from '@app/pages/Notes/HistoricVitalsModal/helpers'
import { DeleteVitalsRow, EditVitalsRow } from './ModifyVitalsRow'
import { IsModifying } from './type'

export default function Tr({
  activeTab,
  isModifying,
  methods,
  person,
  vital,
  isSubmitting,
  latestVitals,
  setIsModifying,
  onDeleteVital,
  onEditVital,
}: {
  activeTab: VitalsType
  isModifying: IsModifying | undefined
  methods: UseFormReturn<VitalsFormData>
  person: Person
  vital: Vital
  isSubmitting: boolean
  latestVitals: Loading<LatestVital>
  setIsModifying: (isModifying: IsModifying | undefined) => void
  onDeleteVital: (vitalId: string) => Promise<void>
  onEditVital: (data: VitalsFormData) => Promise<void>
}) {
  const { convertToFacilityTimezone } = useContext(CurrentFacilityContext)
  const { user } = useUserContext()
  const [hightlightPenIcon, setHightlightPenIcon] = useState(false)
  const { id, date, value, unitsLabel, optionalLabel, modifiedBy } =
    formatVital({
      vital,
      activeTab,
      timezoneCoverter: convertToFacilityTimezone,
    })
  const onMouseEnter = () => setHightlightPenIcon(true)
  const onMouseLeave = () => setHightlightPenIcon(false)
  const showEditVitalUI = () => setIsModifying({ tag: 'Editing', vitalId: id! })
  const showDeleteVitalUI = () =>
    setIsModifying({ tag: 'Deleting', vitalId: id! })
  const canEdit = hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_VITAL_UPDATE],
  })
  const canDelete = hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_VITAL_DELETE],
  })
  const editTdProps = {
    onClick: () => {
      if (canEdit) {
        showEditVitalUI()
        onMouseLeave()
      }
    },
    onMouseEnter,
    onMouseLeave,
  }
  const deleteTdProps = {
    onClick: () => {
      if (canDelete) {
        showDeleteVitalUI()
        onMouseLeave()
      }
    },
  }

  return (
    <TrWithHoverEffect key={`vtb-${id}`}>
      {isModifying?.vitalId !== id && (
        <>
          <NameTd
            data-testid={`historic-${activeTab}-value`}
            className={tw`font-semibold text-gray-01`}
            {...editTdProps}
          >
            {value} {unitsLabel}
            {optionalLabel && (
              <span
                className={tw`ml-[8px] inline-block font-medium text-gray-07`}
              >
                ({optionalLabel})
              </span>
            )}
          </NameTd>
          <TdWithExtraPadding
            className={tw`text-gray-07`}
            data-testid={`historic-${activeTab}-date`}
            {...editTdProps}
          >
            {date}
          </TdWithExtraPadding>
          <TdWithExtraPadding
            className={tw`flex items-center justify-between text-gray-07`}
            data-testid={`historic-${activeTab}-insertion`}
            {...editTdProps}
          >
            {modifiedBy}
          </TdWithExtraPadding>
          <TdWithExtraPadding {...editTdProps}>
            <PersonPermissionGate
              person={person}
              permissions={[GroupPermission.GROUP_PERMISSION_VITAL_UPDATE]}
            >
              <ButtonLink
                data-testid="edit-vital"
                className={twx('text-gray-07 print:hidden', {
                  'text-primary-light': hightlightPenIcon,
                })}
              >
                <Icon name="pen" />
              </ButtonLink>
            </PersonPermissionGate>
          </TdWithExtraPadding>
          <TdWithExtraPadding {...deleteTdProps}>
            <PersonPermissionGate
              person={person}
              permissions={[GroupPermission.GROUP_PERMISSION_VITAL_DELETE]}
            >
              <ButtonLink
                data-testid="delete-vital"
                className={tw`text-gray-07 print:hidden`}
              >
                <Icon name="trash" />
              </ButtonLink>
            </PersonPermissionGate>
          </TdWithExtraPadding>
        </>
      )}
      {isModifying?.tag === 'Editing' && isModifying.vitalId === id && (
        <EditVitalsRow
          methods={methods}
          onClickSave={(data) => onEditVital(data)}
          activeTab={activeTab}
          latestVitals={latestVitals}
          disabled={isSubmitting}
          onClickCancel={() => {
            methods.reset()
            setIsModifying(undefined)
          }}
        />
      )}
      {isModifying?.tag === 'Deleting' && isModifying.vitalId === id && (
        <DeleteVitalsRow
          value={value}
          unitsLabel={unitsLabel}
          date={date}
          disabled={isSubmitting}
          onClickDelete={() => onDeleteVital(id)}
          onClickCancel={() => setIsModifying(undefined)}
        />
      )}
    </TrWithHoverEffect>
  )
}
