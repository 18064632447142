import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'

export const DaysOfMonthOptions: OptionTypeBase<number>[] = Array(28)
  .fill(null)
  .map((_, index) => ({
    label: `${index + 1}`,
    value: index + 1,
  }))

export const ISO_FORMAT_STRING = "yyyy-MM-dd'T'HH:mm:ssXX"

export const DATE_WITH_TIMEZONE_FORMAT_STRING = 'PP p z'

export const TIME_INPUT_FORMAT_STRING = 'HH:mm'
