import {
  Contact,
  Contact_BillingStatementMethod,
} from '@augusthealth/models/com/august/protos/contact'
import { Controller, useForm } from 'react-hook-form'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import {
  BasicInput,
  BasicTextarea,
} from '@shared/components/BasicInput/BasicInput'
import BaseCard from '@shared/components/ContactCard/BaseCard/BaseCard'
import { LabelAboveInput } from '@shared/components/Labels'
import {
  IconStyledSelect,
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { PayerSettingsData } from '@shared/types/billing'
import { buildAddressLabel } from '@shared/utils/address'
import { primaryEmail } from '@shared/utils/contact'
import { getFullName } from '@shared/utils/humanName'
import { tw } from '@shared/utils/tailwind'
import { determineStatementDeliveryDefault } from '@app/pages/Facilities/Billing/Residents/helpers'

interface Props {
  contact: Contact
  payerSettings: PayerSettingsData
  onClose: () => void
  submitFn: (data: PayerSettingsData) => Promise<boolean>
  isSubmitting: boolean
}

type StatementDeliveryFormData = {
  deliveryMethod: Contact_BillingStatementMethod
}

export default function StatementDeliveryCard({
  contact,
  payerSettings,
  onClose,
  submitFn,
  isSubmitting,
}: Props) {
  const displayName = getFullName(contact.name)
  const email = primaryEmail(contact)
  const address = buildAddressLabel(contact.address)
  const statementMethod = payerSettings.statementDelivery
  const deliveryDefault = determineStatementDeliveryDefault({
    method: statementMethod,
  })

  const defaultFormData = { deliveryMethod: deliveryDefault }

  const { formState, control, watch, handleSubmit } =
    useForm<StatementDeliveryFormData>({
      defaultValues: defaultFormData,
    })

  const deliveryOptions: OptionTypeBase<
    Contact_BillingStatementMethod,
    string
  >[] = [
    {
      value: Contact_BillingStatementMethod.BILLING_STATEMENT_METHOD_EMAIL,
      label: 'Email',
      data: 'fa-solid fa-envelope',
    },
    {
      value: Contact_BillingStatementMethod.BILLING_STATEMENT_METHOD_MAIL,
      label: 'Mail',
      data: 'fa-solid fa-mailbox',
    },
    {
      value: Contact_BillingStatementMethod.BILLING_STATEMENT_METHOD_PRINT,
      label: 'In Room',
      data: 'fa-solid fa-house-heart',
    },
  ]

  const method = watch('deliveryMethod')

  async function onSubmit(formData: StatementDeliveryFormData) {
    const updatedSettings: PayerSettingsData = {
      ...payerSettings,
      statementDelivery: formData.deliveryMethod,
    }
    const success = await submitFn(updatedSettings)
    if (success) {
      onClose()
    }
  }

  return (
    <BaseCard
      linkable={false}
      header={displayName}
      subheader={'STATEMENT DELIVERY'}
      testid={'statement-delivery-card-content'}
      label="Edit Statement Delivery"
      showDivider={false}
      footer={
        <div className={tw`flex justify-center`}>
          <AnimatedPopupFormFooter
            yesBtn={{
              props: {
                onClick: handleSubmit(onSubmit),
                disabled: formState.disabled,
                isLoading: isSubmitting,
              },
            }}
            noBtn={{ action: onClose }}
            formState={formState}
          />
        </div>
      }
    >
      <div className={tw`min-h-[200px]`}>
        <form data-testid="form" onSubmit={handleSubmit(onSubmit)}>
          <div className={tw`mb-6`}>
            <LabelAboveInput uppercase={false} htmlFor="deliveryMethod">
              Delivery method
            </LabelAboveInput>
            <Controller
              control={control}
              name="deliveryMethod"
              render={({ field: { onChange, value } }) => {
                return (
                  <IconStyledSelect
                    aria-label="deliveryMethod"
                    name="deliveryMethod"
                    iconName={
                      deliveryOptions.find((o) => o.value === value)!.data!
                    }
                    isClearable={false}
                    value={deliveryOptions.find((o) => o.value === value)}
                    onChange={(option: OptionTypeBase | null) => {
                      if (!option) {
                        onChange(defaultFormData.deliveryMethod)
                      } else {
                        onChange(option.value)
                      }
                    }}
                    options={deliveryOptions}
                  />
                )
              }}
            />
          </div>
          {method ===
            Contact_BillingStatementMethod.BILLING_STATEMENT_METHOD_MAIL &&
            (address ? (
              <>
                <LabelAboveInput htmlFor="address" uppercase={false}>
                  Address
                </LabelAboveInput>
                <BasicTextarea disabled value={address} />
              </>
            ) : (
              <div
                className={tw`text-formInput font-medium text-form-input-placeholder-color`}
              >
                No address listed. To add one, please update from the Contacts
                tab.
              </div>
            ))}
          {method ===
            Contact_BillingStatementMethod.BILLING_STATEMENT_METHOD_EMAIL &&
            (email ? (
              <>
                <LabelAboveInput htmlFor="email" uppercase={false}>
                  Email
                </LabelAboveInput>
                <BasicInput
                  disabled
                  value={email}
                  placeholder="No email listed. To add one, please update from the Contacts tab."
                />
              </>
            ) : (
              <div
                className={tw`text-formInput font-medium text-form-input-placeholder-color`}
              >
                No email listed. To add one, please update from the Contacts
                tab.
              </div>
            ))}
        </form>
      </div>
    </BaseCard>
  )
}
