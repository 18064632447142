import ElementHolder, {
  Props as ElementHolderProps,
} from '@app/components/formElements/ElementHolder'
import LanguageDropdown from './LanguageDropdownWithPersonContext'

type Props = ElementHolderProps & {
  name: string
  onUpdate: (value: string, name: string) => void
  value?: string
  placeholder?: string
}

export default function LanguageDropdownIngenerator(props: Props) {
  const { name, onUpdate, placeholder, value, ...holderProps } = props

  return (
    <ElementHolder {...holderProps}>
      <LanguageDropdown
        name={name}
        countryCode="USA"
        onChange={(opt: { value: string }) => onUpdate(opt?.value, name)}
        value={value}
        placeholder={placeholder}
      />
    </ElementHolder>
  )
}
