export const RESIDENT_DETAILS_FIELDS = [
  'name',
  'gender',
  'birthDate',
  'telecom',
  'address',
  'religiousPreference',
  'ssn',
].join(',')

export const EMERGENCY_INFO_FIELDS = ['hospital', 'coverage'].join(',')

export const AMBULATORY_STATUS_FIELDS = [
  'ambulatoryStatus/code',
  'ambulatoryStatus/evacuation',
  'ambulatoryStatus/safeToLeaveWithoutSupervision',
].join(',')

export const HEALTH_HISTORY_PART_1_FIELDS = [
  'allergiesAndIntolerances/allergies',
  'ambulatoryStatus/bedStatus',
  'conditions',
  'diagnosticResults/diagnosticReports',
  'familyMemberHistory/condition',
  'healthQuestionnaire/hospitalizations',
  'healthQuestionnaire/medications',
  'healthQuestionnaire/overallConditionCode',
  'healthQuestionnaire/overallConditionSummary',
  'socialHistory/diet',
  'socialHistory/recentTbExposure',
].join(',')

export const HEALTH_HISTORY_PART_2_FIELDS = [
  'healthQuestionnaire/physicalDisabilities',
  'healthQuestionnaire/mentalHealth',
  'socialHistory/interestsAndActivitiesSummary',
  'socialHistory/dislikesAndHardshipsSummary',
  'socialHistory/educationSummary',
  'socialHistory/occupationSummary',
  'socialHistory/tobaccoUse',
  'socialHistory/alcoholUse',
  'maritalStatus',
  'communication',
  'socialHistory/other',
].join(',')

export const FUNCTIONAL_CAPABILITIES_FIELDS = [
  'functionalStatus/capabilities',
].join(',')

export const OTHER_SERVICES_FIELDS = [
  'functionalStatus/capabilities',
  'healthQuestionnaire/servicesNeeded',
  'healthQuestionnaire/admissionSuitabilitySummary',
].join(',')

export const ADMISSIONS_INFORMATION = [
  'admissionsInformation/admissionType',
  'admissionsInformation/startDate',
  'admissionsInformation/financialStartDate',
  'admissionsInformation/startRange', // Need to remove if startDate is specified
  'admissionsInformation/bedId',
  'admissionsInformation/assignedRoomType',
  'admissionsInformation/initialIntendedRate',
].join(',')

export const CONSENT_FORMS_FIELDS = [
  'contact',
  'allergiesAndIntolerances/allergies',
].join(',')

export const PROSPECT_STATS_FIELDS = [
  'person/id',
  'person/orgId',
  'person/facilityId',
  'person/admissionsInformation/startDate',
  'person/admissionsInformation/startRange',
  'person/gender', // for default profile svg
  'person/name',
  'person/residentStatus',
  'lastUpdate/userName',
  'lastUpdate/updatedAt',
  'taskStats',
  'profilePhotoUrl',
  'person/birthDate',
]

export const RESIDENT_STATS_FIELDS = [
  'person/id',
  'person/orgId',
  'person/facilityId',
  'person/admissionsInformation/admissionType',
  'person/admissionsInformation/bedDetails/name',
  'person/admissionsInformation/room/name',
  'person/admissionsInformation/roomDetails/name',
  'person/admissionsInformation/startDate',
  'person/admissionsInformation/startRange',
  'person/gender', // for default profile svg
  'person/name',
  'person/levelOfCare',
  'person/residentStatus',
  'person/isOutOfFacility',
  'person/careGroupDetails',
  'alerts',
  'lastUpdate/userName',
  'lastUpdate/updatedAt',
  'taskStats',
  'profilePhotoUrl',
  'person/dischargeInformation',
  'person/birthDate',
]
