import { ChargeType } from '@augusthealth/models/com/august/protos/charge'
import { useContext, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Controller, useForm } from 'react-hook-form'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import {
  Label,
  LabelAboveInput,
  requiredLabel,
  requiredWhenError,
} from '@shared/components/Labels'
import StyledSelect from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import MoneyInReactHookForm from '@app/components/MoneyInput/MoneyInReactHookForm'
import { Switch } from '@app/components/Switch'
import { useContacts } from '@app/hooks/useContacts'
import useDelayUnmount from '@app/hooks/useDelayUnmount'
import styles from './styles.module.css'
import {
  addCharge,
  ChargeTypeSelection,
  chargeTypeToSelect,
  contactsToSelect,
  dailyFrequency,
  FormData,
  frequencies,
  isMonthly,
  monthlyFrequency,
  recurringChargeTypes,
} from './helpers'

export default function AddCharge({
  person,
  doneFn,
}: {
  person: Person
  doneFn: (newChargeId?: string) => void
}) {
  const { setError } = useContext(GlobalContext)
  const {
    setError: setFormError,
    setValue,
    watch,
    register,
    handleSubmit,
    control,

    formState: { errors },
  } = useForm<FormData>()

  register('recurring')
  register('frequency')

  const [showFrequency, setShowFrequency] = useState(true)
  const chargeType = watch('chargeType') as
    | ChargeTypeSelection
    | null
    | undefined

  const forceRecurring = chargeType
    ? recurringChargeTypes.includes(chargeType.value)
    : false
  const recurringStyles = forceRecurring
    ? `${styles.switchContainer} ${styles.disabled}`
    : styles.switchContainer

  useEffect(() => {
    const isRecurring = chargeType
      ? recurringChargeTypes.includes(chargeType.value)
      : false

    if (isRecurring && chargeType) {
      // The charge type is definitely recurring, so
      // don't show the frequency input, and disable the recurring switch
      setShowFrequency(false)

      if (isMonthly(chargeType)) {
        setTimeout(() => setValue('frequency', monthlyFrequency))
      } else {
        setTimeout(() => setValue('frequency', dailyFrequency))
      }

      setValue('recurring', true)
      setIsMounted(true)
    } else {
      // The charge type isn't necessarily recurring
      setValue('frequency', undefined)
      setValue('recurring', false)
      setIsMounted(false)
      // This is to give time for the animation to finish, otherwise
      // this causes some UI flicker
      setTimeout(() => {
        setShowFrequency(true)
      }, 350)
    }
  }, [chargeType])

  const { contacts } = useContacts()

  const [isMounted, setIsMounted] = useState(false)
  const shouldRenderChild = useDelayUnmount(isMounted, 300)
  const mountedStyle = styles.inAnimation
  const unmountedStyle = styles.outAnimation

  const onSubmit = async (formData: FormData) => {
    const { recurringStartDate, recurringEndDate } = formData
    if (
      recurringStartDate &&
      recurringEndDate &&
      recurringEndDate < recurringStartDate
    ) {
      setFormError('recurringEndDate', {
        type: 'validate',
        message: 'Need to be after Start Date',
      })

      return Promise.resolve()
    }

    try {
      const resp = await addCharge(person, formData)
      doneFn(resp)
    } catch (err) {
      setError(err)
    }
  }

  return (
    <AnimatedPopup title="Add Charge">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          defaultValue={undefined}
          name="chargeType"
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { invalid } }) => {
            return (
              <div>
                <LabelAboveInput
                  subLabel={requiredLabel(invalid)}
                  htmlFor="chargeType"
                >
                  Type
                </LabelAboveInput>
                <StyledSelect
                  id="chargeType"
                  instanceId="type"
                  name="chargeType"
                  options={chargeTypeToSelect()}
                  value={value}
                  onChange={onChange}
                />
              </div>
            )
          }}
        />
        <Controller
          control={control}
          defaultValue={undefined}
          name="payer"
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { invalid } }) => {
            return (
              <div className="mt-[32px]">
                <LabelAboveInput
                  subLabel={requiredLabel(invalid)}
                  htmlFor="payer"
                >
                  Payer
                </LabelAboveInput>
                <StyledSelect
                  name="payer"
                  id="payer"
                  instanceId="payer"
                  value={value}
                  onChange={onChange}
                  isDisabled={contacts.tag === 'Loading'}
                  options={
                    contacts.tag === 'Complete'
                      ? contactsToSelect(contacts.value)
                      : []
                  }
                />
              </div>
            )
          }}
        />
        <div className="mt-[32px] flex">
          <MoneyInReactHookForm
            uppercaseTitle={true}
            name="amount"
            id="amount"
            control={control}
            subLabel={requiredLabel(!!errors.amount)}
            inputProps={{
              className: 'w-[184px]',
            }}
            required
            title={amountLabel(chargeType)}
            allowNegativeNumbers={true}
          />
          <div className={recurringStyles}>
            <Switch
              name="recurring"
              checked={watch('recurring', false)}
              disabled={forceRecurring}
              onChange={(e) => {
                setIsMounted(!isMounted)
                setValue('recurring', e.target.checked)
              }}
            />
            <Label htmlFor="recurring">Recurring</Label>
          </div>
        </div>
        {shouldRenderChild && (
          <div className={isMounted ? mountedStyle : unmountedStyle}>
            {showFrequency && (
              <Controller
                control={control}
                defaultValue={undefined}
                name="frequency"
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid },
                }) => {
                  return (
                    <div className="mt-[32px]">
                      <LabelAboveInput
                        htmlFor="frequency"
                        subLabel={requiredLabel(invalid)}
                      >
                        Frequency
                      </LabelAboveInput>
                      <StyledSelect
                        onChange={onChange}
                        value={value}
                        name="frequency"
                        options={frequencies()}
                      />
                    </div>
                  )
                }}
              />
            )}
            <div className={`${styles.dateContainer} mt-[32px]`}>
              <Controller
                control={control}
                rules={{ required: true }}
                name="recurringStartDate"
                defaultValue={new Date()}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid },
                }) => (
                  <div>
                    <LabelAboveInput
                      htmlFor="recurringStartDate"
                      subLabel={requiredLabel(invalid)}
                    >
                      Start Date
                    </LabelAboveInput>
                    <DatePicker
                      selected={value}
                      onChange={onChange}
                      customInput={<CalendarInput />}
                    />
                  </div>
                )}
              />
              <div className="ml-[16px]">
                <LabelAboveInput
                  htmlFor="recurringEndDate"
                  subLabel={requiredWhenError(!!errors.recurringEndDate)}
                  errorMessage={errors.recurringEndDate?.message}
                >
                  End Date
                </LabelAboveInput>
                <Controller
                  control={control}
                  name="recurringEndDate"
                  defaultValue={null}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      selected={value}
                      onChange={onChange}
                      customInput={<CalendarInput />}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        )}
        {!shouldRenderChild && (
          <div className={!isMounted ? styles.fadeIn : unmountedStyle}>
            <div className="mt-[32px]">
              <LabelAboveInput htmlFor="serviceDate">
                Service Date
              </LabelAboveInput>
              <Controller
                control={control}
                name="serviceDate"
                defaultValue={new Date()}
                render={({ field: { onChange, value } }) => (
                  <div className={styles.dateContainer}>
                    <DatePicker
                      selected={value}
                      onChange={onChange}
                      customInput={<CalendarInput />}
                    />
                  </div>
                )}
              />
            </div>
          </div>
        )}
        <div className="mt-[32px]">
          <LabelAboveInput htmlFor="notes">Notes</LabelAboveInput>
          <BasicTextarea
            {...register('notes')}
            placeholder="To help categorize the charge"
          />
        </div>
        <div className="mt-[32px]">
          <button
            className={`rounded-[6px] border border-button-tertiary-color bg-button-default-bg-color px-[12px] py-[11px] text-[11px] font-bold leading-[14px] text-button-tertiary-color hover:bg-button-tertiary-color hover:text-button-fill-font-color ${styles.btn}`}
            type="button"
            onClick={() => doneFn()}
          >
            CANCEL
          </button>
          <button
            id="saveCharge"
            className={`rounded-[6px] bg-button-primary-color px-[12px] py-[11px] text-[11px] font-bold leading-[14px] text-button-fill-font-color hover:brightness-125  ${styles.btn}`}
          >
            SAVE
          </button>
        </div>
      </form>
    </AnimatedPopup>
  )
}

function amountLabel(chargeType: ChargeTypeSelection | null | undefined) {
  switch (chargeType?.value) {
    case ChargeType.CHARGE_TYPE_MONTHLY_RATE:
      return 'Monthly Rate'
    case ChargeType.CHARGE_TYPE_DAILY_RATE:
      return 'Daily Rate'
    default:
      return 'Amount'
  }
}
