import { DragEvent } from 'react'

type DraggableProps = {
  indexName: string
  index: number
  list: any[]
  onDrop: (list: any[]) => void
}

function isOverTopPart(ev: DragEvent<HTMLElement>) {
  const { clientY, currentTarget } = ev
  const { top, bottom } = currentTarget.getBoundingClientRect()
  const middleX = top + (bottom - top) / 2 // Get the middle X of Dropped Item
  return clientY < middleX
}

function removeDraggableClass(ev: DragEvent<HTMLElement>) {
  const { classList } = ev.currentTarget
  classList.remove('draggable-top')
  classList.remove('draggable-bottom')
}

/**
 * Save the index of dragging item, and style dragging item
 */
function getOnDragStartFunc({
  index,
  indexName,
}: Pick<DraggableProps, 'index' | 'indexName'>) {
  return (ev: DragEvent<HTMLElement>) => {
    ev.currentTarget.classList.add('dragging-item')
    ev.dataTransfer.setData(indexName, index.toString())
  }
}

/**
 * Check if dragging item is on top or bottom part of dropped-overlapped item
 * And insert dragging item in front or rear of overlapped item
 */
function getOnDropFunc({ index, indexName, list, onDrop }: DraggableProps) {
  return (ev: DragEvent<HTMLElement>) => {
    const draggingIndex = Number(ev.dataTransfer.getData(indexName))
    removeDraggableClass(ev)
    ev.dataTransfer.clearData(indexName)

    if (!isNaN(draggingIndex)) {
      const insertIndex = isOverTopPart(ev) ? index : index + 1 // Insert before or after
      const selectedItem = list[draggingIndex]
      const newList = [...list] // Only reorder, shallow clone should be enough
      newList.splice(insertIndex, 0, selectedItem) // insert
      const moveForward = insertIndex < draggingIndex
      newList[moveForward ? draggingIndex + 1 : draggingIndex] = null // remove out dragging item
      onDrop(newList.filter((p) => p))
    }
  }
}

/**
 * Set className draggable-top or draggable-bottom for dragging guide line
 */
function getOnDragOverFunc({ indexName }: Pick<DraggableProps, 'indexName'>) {
  return (ev: DragEvent<HTMLElement>) => {
    ev.preventDefault() // So onDrop will always trigger
    const draggingIndex = Number(ev.dataTransfer.getData(indexName))
    if (!isNaN(draggingIndex)) {
      const { classList } = ev.currentTarget
      if (isOverTopPart(ev)) {
        classList.add('draggable-top')
        classList.remove('draggable-bottom')
      } else {
        classList.add('draggable-bottom')
        classList.remove('draggable-top')
      }
    }
  }
}

/**
 * Add style for top or bottom guide line
 */
function getOnDragLeaveFunc({ indexName }: Pick<DraggableProps, 'indexName'>) {
  return (ev: DragEvent<HTMLElement>) => {
    const draggingIndex = Number(ev.dataTransfer.getData(indexName))
    if (!isNaN(draggingIndex)) {
      removeDraggableClass(ev)
    }
  }
}

/**
 * Remove dragging item style
 */
function getOnDragEndFunc({ indexName }: Pick<DraggableProps, 'indexName'>) {
  return (ev: DragEvent<HTMLElement>) => {
    const draggingIndex = Number(ev.dataTransfer.getData(indexName))
    if (!isNaN(draggingIndex)) {
      ev.currentTarget.classList.remove('dragging-item')
    }
  }
}

/**
 * Set attributes: draggable, onDragStart and onDrop
 */
export function getDraggableElementProps(props: DraggableProps) {
  return {
    draggable: true,
    onDragLeave: getOnDragLeaveFunc(props),
    onDragOver: getOnDragOverFunc(props),
    onDragStart: getOnDragStartFunc(props),
    onDragEnd: getOnDragEndFunc(props),
    onDrop: getOnDropFunc(props),
  }
}
