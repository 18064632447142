import { Address } from '@augusthealth/models/com/august/protos/address'
import { DeepNull } from '@shared/types/utilities'

/*
 * Merge Address from Google AutoComplete to .address
 * Special handle .text (when enter text manually),
 * and Unit Number (.line[1]) (since it is not returned by Google AutoComplete)
 */
export function mergeAddresses(prevAddress: Address, newAddress: Address) {
  let updatedAddress: DeepNull<Address>
  if (typeof newAddress.text === 'string') {
    // Enter Address manually, set text and ignore other attr. in Address
    if (newAddress.text) {
      updatedAddress = {
        text: newAddress.text,
        line: null,
        city: null,
        state: null,
        postalCode: null,
      }
    } else {
      // expect empty text when trying to delete an address
      updatedAddress = {}
    }
  } else if (
    // Update Unit Number only when receive .line: ['', string]
    prevAddress.line &&
    newAddress.line &&
    newAddress.line[0] === '' &&
    newAddress.line[1] !== prevAddress.line[1]
  ) {
    // Update Unit Number
    updatedAddress = {
      ...prevAddress,
      line: [prevAddress.line[0], newAddress.line[1]].filter((l) => l),
    }
  } else if (
    // Add Unit # back, since Google AutoComplete comes without it
    newAddress.line &&
    newAddress.line[0] &&
    prevAddress.line &&
    prevAddress.line[1]
  ) {
    updatedAddress = {
      ...newAddress,
      line: [newAddress.line[0], prevAddress.line[1]],
    }
  } else {
    updatedAddress = newAddress
  }

  return updatedAddress
}
