import { useState } from 'react'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { RequiredActionModal } from '@shared/components/baseMui/Modal/RequiredActionModal'
import Card from '@shared/components/Card'
import Icon from '@shared/components/Icon'
import { PaymentMethod, ResidentListEntry } from '@shared/types/billing'
import { tw } from '@shared/utils/tailwind'
import RecordPayments from '@app/pages/Facilities/Billing/Modals/RecordPayments/index'

export default function PaymentMethodChooser({
  onClose,
  residents,
  onFinishPayment,
}: {
  residents: ResidentListEntry[]
  onFinishPayment: () => Promise<void>
  onClose: () => void
}) {
  const [paymentMethod, setPaymentMethod] = useState<'Picker' | PaymentMethod>(
    'Picker'
  )

  return (
    <RequiredActionModal
      open={true}
      id="record-payments"
      contentClassName={tw`flex min-h-[90vh] w-full overflow-y-hidden`}
    >
      {paymentMethod === 'Picker' && (
        <section
          className={tw`mx-auto flex flex-col items-center justify-center`}
        >
          <h2 className={tw`m-0 text-xl font-semibold leading-6`}>
            What type of payment do you want to record?
          </h2>
          <div className={tw`mt-12`}>
            <div className={tw`flex flex-wrap justify-center gap-8`}>
              <Card
                className={tw`w-40 cursor-pointer flex-col items-center p-8`}
                onClick={() => setPaymentMethod(PaymentMethod.CHECK)}
              >
                <Icon
                  name={'money-check-dollar'}
                  className={tw`text-6xl text-primary`}
                />
                <div className={tw`mt-6 font-medium text-gray-07`}>Check</div>
              </Card>
              <Card
                className={tw`w-40 cursor-pointer flex-col items-center p-8`}
                onClick={() => setPaymentMethod(PaymentMethod.ACH)}
              >
                <Icon
                  name={'money-bill-transfer'}
                  className={tw`text-6xl text-primary`}
                />
                <div className={tw`mt-6 font-medium text-gray-07`}>ACH</div>
              </Card>
              <Card
                className={tw`w-40 cursor-pointer flex-col items-center p-8`}
                onClick={() => setPaymentMethod(PaymentMethod.WIRE)}
              >
                <Icon
                  name={'building-columns'}
                  className={tw`text-6xl text-primary`}
                />
                <div className={tw`mt-6 font-medium text-gray-07`}>Wire</div>
              </Card>
              <Card
                className={tw`w-40 cursor-pointer flex-col items-center p-8`}
                onClick={() => setPaymentMethod(PaymentMethod.CASH)}
              >
                <Icon
                  name={'money-bill-1'}
                  className={tw`text-6xl text-primary`}
                />
                <div className={tw`mt-6 font-medium text-gray-07`}>Cash</div>
              </Card>
              <Card
                className={tw`w-40 cursor-pointer flex-col items-center p-8`}
                onClick={() => setPaymentMethod(PaymentMethod.CREDIT_CARD)}
              >
                <Icon
                  name={'credit-card'}
                  className={tw`text-6xl text-primary`}
                />
                <div className={tw`mt-6 font-medium text-gray-07`}>
                  Credit Card
                </div>
              </Card>
              <Card
                className={tw`w-40 cursor-pointer flex-col items-center p-8`}
                onClick={() => setPaymentMethod(PaymentMethod.DEBIT_CARD)}
              >
                <Icon
                  name={'credit-card-front'}
                  className={tw`text-6xl text-primary`}
                />
                <div className={tw`mt-6 font-medium text-gray-07`}>
                  Debit Card
                </div>
              </Card>
            </div>
            <AsyncIconButton
              onClick={onClose}
              width={'100%'}
              buttonStyle={'secondary-outline'}
              className={tw`mt-12`}
            >
              Cancel
            </AsyncIconButton>
          </div>
        </section>
      )}
      {paymentMethod !== 'Picker' && (
        <RecordPayments
          paymentMethod={paymentMethod}
          residents={residents}
          onFinishPayment={onFinishPayment}
          onClose={onClose}
        />
      )}
    </RequiredActionModal>
  )
}
