import { Signer, SignerRole } from '@shared/types/snapshot'

export const isAdminSigner = (signer: Signer) =>
  signer.role === SignerRole.SIGNER_ROLE_ADMIN

export const isExternalSigner = (signer: Signer) =>
  signer.role === SignerRole.SIGNER_ROLE_EXTERNAL

export const isRpSigner = (signer: Signer) =>
  signer.role === SignerRole.SIGNER_ROLE_RESPONSIBLE_PARTY

export const isResidentSigner = (signer: Signer) =>
  signer.role === SignerRole.SIGNER_ROLE_RESIDENT
