import { compareAsc, differenceInDays, startOfToday } from 'date-fns'
import { PersonTasks } from '@shared/types/api/person_tasks'
import { Task, TaskStatus } from '@shared/types/task'
import {
  fromDateMessageToDate,
  isDatePast,
  isDateWithinRange,
} from '@shared/utils/date'
import { isIncomplete } from '@shared/utils/task'

export function isTodoDate(d: Date) {
  return isDateWithinRange(d, 7) || isDatePast(d)
}

/**
 * A task is considered todo if:
 * - It has a due date, is incomplete, has a due date in the past or in the next 7 days
 * - It's incomplete and is pending signatures or awaiting review
 * - It's incomplete, is started, has been modified, and it's within 7 days of the modification time
 */
export function getTodoTasks(pt: PersonTasks) {
  const tasks = pt.tasks ?? []

  const tasksWithRecentDueDate = tasks.filter(
    (t) =>
      t.dueDate &&
      isIncomplete(t) &&
      isTodoDate(fromDateMessageToDate(t.dueDate)!)
  )
  const reviewOrSignTasks = tasks.filter(
    (t) =>
      (isIncomplete(t) &&
        t.status === TaskStatus.TASK_STATUS_PENDING_SIGNATURES) ||
      t.status === TaskStatus.TASK_STATUS_AWAITING_REVIEW
  )
  const recentlyUpdatedTasks = tasks.filter(
    (t) =>
      isIncomplete(t) &&
      t.status !== TaskStatus.TASK_STATUS_NOT_STARTED &&
      t.lastModification?.modificationTime &&
      !isDateWithinRange(new Date(t.lastModification?.modificationTime), 7)
  )

  return [
    ...new Set([
      ...tasksWithRecentDueDate,
      ...reviewOrSignTasks,
      ...recentlyUpdatedTasks,
    ]),
  ]
}

export function getUpcomingTasks(pt: PersonTasks) {
  return (pt.tasks ?? []).filter(
    (t) =>
      t.dueDate &&
      isIncomplete(t) &&
      !isTodoDate(fromDateMessageToDate(t.dueDate)!)
  )
}

export function getOverflowTasks(pt: PersonTasks) {
  const todoTasks = getTodoTasks(pt)
  const upcomingTasks = getUpcomingTasks(pt)

  return (pt.tasks ?? [])
    .filter((t) => isIncomplete(t))
    .filter((t) => todoTasks.find((tt) => tt.id === t.id) === undefined)
    .filter((t) => upcomingTasks.find((ut) => ut.id === t.id) === undefined)
}

export const dueDateLabelText = (date: Date) => {
  const daysDiff = differenceInDays(date, startOfToday())
  if (daysDiff > 0) {
    if (daysDiff === 1) {
      return 'Due in 1 day'
    } else if (daysDiff === 7) {
      return 'Due in 1 week'
    } else {
      return `Due in ${daysDiff} days`
    }
  } else {
    if (daysDiff < -30) {
      return '>30 days overdue'
    } else if (daysDiff < -1) {
      return `${Math.abs(daysDiff)} days overdue`
    } else if (daysDiff === -1) {
      return '1 day overdue'
    }
  }

  return 'Due today'
}

export const sortTasksByDueDate = (tasksA: Task[], tasksB: Task[]) => {
  // both person a and b have task lists with dueDates
  if (tasksA.length && tasksB.length) {
    // arrays are sorted with oldest dates first
    tasksA.sort((a, b) =>
      compareAsc(
        fromDateMessageToDate(a.dueDate)!,
        fromDateMessageToDate(b.dueDate)!
      )
    )
    tasksB.sort((a, b) =>
      compareAsc(
        fromDateMessageToDate(a.dueDate)!,
        fromDateMessageToDate(b.dueDate)!
      )
    )
    // compare lists by oldest dueDates
    const sorted = compareAsc(
      fromDateMessageToDate(tasksA[0].dueDate)!,
      fromDateMessageToDate(tasksB[0].dueDate)!
    )

    // when dueDates are the same
    if (sorted === 0) {
      // use lengths as a tiebreaker
      if (tasksA.length > tasksB.length) {
        return -1
      } else if (tasksB.length > tasksA.length) {
        return 1
      } else {
        return 0
      }
    } else {
      return sorted
    }
    // only a has tasks
  } else if (tasksA.length) {
    return -1
    // only b has tasks
  } else if (tasksB.length) {
    return 1
  }

  return 0
}

export const sortPeopleByTodoTasks = (personTasks: PersonTasks[]) =>
  personTasks.sort((a, b) => {
    const aTodoTasks = getTodoTasks(a)
    const bTodoTasks = getTodoTasks(b)

    // both person a and b have to do tasks
    if (aTodoTasks.length && bTodoTasks.length) {
      const aDueDateTasks = aTodoTasks.filter((t) => t.dueDate)
      const bDueDateTasks = bTodoTasks.filter((t) => t.dueDate)
      return sortTasksByDueDate(aDueDateTasks, bDueDateTasks)
      // only a has to do tasks
    } else if (aTodoTasks.length) {
      return -1
      // only b has to do tasks
    } else if (bTodoTasks.length) {
      return 1
    }

    return 0
  })

export const sortPeopleByUpcomingTasks = (personTasks: PersonTasks[]) =>
  personTasks.sort((a, b) => {
    const aUpcomingTasks = getUpcomingTasks(a)
    const bUpcomingTasks = getUpcomingTasks(b)

    return sortTasksByDueDate(aUpcomingTasks, bUpcomingTasks)
  })
