import { Array, Option, pipe } from 'effect'
import { ClassNameValue } from 'tailwind-merge'
import { Shift } from '@shared/types/shift'
import { twx } from '@shared/utils/tailwind'
import { IconStyledSelect, OptionTypeBase } from './StyledSelect'

function toOption(shift: Shift): OptionTypeBase {
  return {
    label: shift.name,
    value: shift.id,
  }
}

function selectedShift(
  id: Shift['id'],
  shifts: Shift[]
): OptionTypeBase | null {
  return pipe(
    shifts,
    Array.findFirst((shift) => id === shift.id),
    Option.map(toOption),
    Option.getOrNull
  )
}

export default function ShiftSelect({
  selectedShiftId,
  shifts,
  onChange,
  className,
}: {
  selectedShiftId: Shift['id']
  shifts: Shift[]
  onChange: (next: Shift['id']) => void
  className?: ClassNameValue
}) {
  const shiftOptions: OptionTypeBase[] = shifts.map(toOption)

  return (
    <IconStyledSelect
      inputId="shiftSelect"
      name="shiftSelect"
      iconName="fa-solid fa-clock"
      className={twx(className)}
      options={shiftOptions}
      value={selectedShift(selectedShiftId, shifts)}
      onChange={(shift: OptionTypeBase) => onChange(shift.value)}
      styles={{
        menu: (provided) => ({ ...provided, minWidth: 'min-content' }),
      }}
    />
  )
}
