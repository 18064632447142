import {
  RoutineAdministrationEventAdministered as RoutineAdministrationEventAdministeredProto,
  RoutineAdministrationEventCreated as RoutineAdministrationEventCreatedProto,
  RoutineAdministrationEventDeactivated as RoutineAdministrationEventDeactivatedProto,
  RoutineAdministrationEventException as RoutineAdministrationEventExceptionProto,
  RoutineAdministrationEventUndoAdministered as RoutineAdministrationEventUndoAdministeredProto,
  RoutineAdministrationEventUndoException as RoutineAdministrationEventUndoExceptionProto,
  RoutineAdministrationExceptionReason,
  RoutineAdministrationStatus,
} from '@augusthealth/models/com/august/protos/routine_administration'
import { Brand } from 'effect'
import { P } from 'ts-pattern'
import { Tagged } from 'type-fest'
import { ResidentStatus } from '@shared/types/person'
import { Shift } from '@shared/types/shift'
import { UTCIsoDateTime } from '@shared/utils/date'
import { AdmissionsInformation } from './admissions'
import { AmbulatoryStatus } from './ambulatory_status'
import { UserReference } from './annotation'
import { CareGroup } from './care_group'
import { Condition } from './condition'
import { DateMessage, TimePeriod } from './date'
import { Facility } from './facility'
import { Gender } from './gender'
import { HospiceStatus } from './hospice'
import { HumanName } from './human_name'
import { Incident } from './incidents'
import { Organization } from './organization'
import { OutOfFacilityDetail, Person_AllergiesAndIntolerances } from './person'
import { RespiteStatus } from './respite'
import { RoutineOrder } from './routine_order'
import { AmbulatorySettings } from './settings/ambulatory_settings'
import { AppraisalSettings } from './settings/appraisal_settings'
import { MarSettings } from './settings/mar_settings'
import { Polst_CPR } from './snapshot'

export {
  RoutineAdministrationExceptionReason,
  RoutineAdministrationStatus,
} from '@augusthealth/models/com/august/protos/routine_administration'

/**
 * Example: ba9ec5d4-241e-49a7-9e78-3d1a21521c32
 */
export type UUID = `${string}-${string}-${string}-${string}-${string}`

/**
 * iso8601 date
 * Example: "2024-08-09"
 */
export type IsoDate = `${number}-${number}-${number}`

/**
 * A date without a time-zone in the ISO-8601 calendar system.
 * Mimics Java's LocalDate
 *
 * Example: "2018-04-01"
 */
export type LocalDate = Brand.Brand<'LocalDate'> & IsoDate
export const LocalDate = Brand.nominal<LocalDate>()

/**
 * iso8601 date and time without zone
 * Example: "2018-04-01T15:20:15"
 */
export type IsoDateTime =
  `${number}-${number}-${number}T${number}:${number}:${number}`

/**
 * A date-time without a time-zone in the ISO-8601 calendar system.
 * Mimics Java's LocalDateTime
 *
 * Example: "2018-04-01T15:20:15"
 */
export type LocalDateTime = Brand.Brand<'LocalDateTime'> & IsoDateTime
export const LocalDateTime = Brand.nominal<LocalDateTime>()

/**
 * Represents a Date that internally holds a UTC timestamp
 */
export type Instant = Brand.Brand<'Instant'> & Date
export const Instant = Brand.nominal<Instant>()

/**
 * Serialized java Long
 */
type Id = string
type ShiftId = Id

/**
 * Example: "2024-08-09|15"
 */
export type ShiftOccurrenceRef = Tagged<
  `${IsoDate}|${ShiftId}`,
  'ShiftOccurrenceRef'
>

export type ShiftOccurrence = {
  shift: CareAppShift
  date: LocalDate
}

interface RoutineAdministrationEventBase {
  id: UUID
  occurredAt: UTCIsoDateTime
  performer?: UserReference
  parentId?: UUID
}

export interface RoutineAdministrationEventCreated
  extends RoutineAdministrationEventBase {
  performer?: UserReference
  created: RoutineAdministrationEventCreatedProto
}

export const isCreatedEvent = {
  id: P.string,
  created: {
    initialStatus: P.optional(
      P.string as unknown as RoutineAdministrationStatus
    ),
  },
}

export interface RoutineAdministrationEventDeactivated
  extends RoutineAdministrationEventBase {
  performer?: UserReference
  deactivated: RoutineAdministrationEventDeactivatedProto
}

export const isDeactivatedEvent = {
  deactivated: P._,
}

export interface RoutineAdministrationEventAdministered
  extends RoutineAdministrationEventBase {
  note?: string
  administered: RoutineAdministrationEventAdministeredProto
}

export const isAdministeredEvent = {
  administered: {
    effortLevel: P.optional(P.number),
  },
}

export interface RoutineAdministrationEventUndoAdministered
  extends RoutineAdministrationEventBase {
  performer: UserReference
  undoAdministered: RoutineAdministrationEventUndoAdministeredProto
}

export const isUndoAdministeredEvent = {
  undoAdministered: P._,
}

export interface RoutineAdministrationEventException
  extends RoutineAdministrationEventBase {
  note?: string
  exception: RoutineAdministrationEventExceptionProto
}

export const isExceptionEvent = {
  exception: {
    reason: P.optional(
      P.string as unknown as RoutineAdministrationExceptionReason
    ),
  },
}

export interface RoutineAdministrationEventUndoException
  extends RoutineAdministrationEventBase {
  performer: UserReference
  undoException: RoutineAdministrationEventUndoExceptionProto
}

export const isUndoExceptionEvent = {
  undoException: P._,
}

export type RoutineAdministrationEvent =
  | RoutineAdministrationEventCreated
  | RoutineAdministrationEventDeactivated
  | RoutineAdministrationEventAdministered
  | RoutineAdministrationEventUndoAdministered
  | RoutineAdministrationEventException
  | RoutineAdministrationEventUndoException

export interface RoutineAdministration {
  id: UUID
  routineOrderId: Id
  personId: Id
  shiftId: ShiftId
  routineOrderGroupId: UUID
  scheduleId: UUID
  status: RoutineAdministrationStatus
  beginScheduledTime: LocalDateTime
  endScheduledTime: LocalDateTime
  beginAdministrationWindow: LocalDateTime
  endAdministrationWindow: LocalDateTime
  isActive: boolean
  shiftOccurrence: ShiftOccurrenceRef
  events: RoutineAdministrationEvent[]
  orderName: string
  note?: string
  performer?: UserReference
  reasonForException?: RoutineAdministrationExceptionReason
  effortLevel?: number
}

export interface CareAppPerson {
  id: Id
  orgId: Id
  facilityId: Id
  name: HumanName
  gender: Gender
  dateOfBirth: DateMessage
  careDetails?: string
  profilePhotoUrl: string
  cprCode: Polst_CPR
  outOfFacilityDetail?: OutOfFacilityDetail
  ambulatoryStatus: AmbulatoryStatus
  allergiesAndIntolerances: Person_AllergiesAndIntolerances
  conditions: Condition[]
  onAlertIncidents: Incident[]
  admissionsInformation: AdmissionsInformation
  respiteStatus: RespiteStatus
  hospiceStatus: HospiceStatus
  careGroupId: Id
  residentStatus: ResidentStatus
}

export type CareAppShift = Shift & {
  signOffPeriod: Required<TimePeriod>
}

export interface RoutineAdministrationsResponse {
  data: {
    routineOrders: RoutineOrder[]
    routineAdministrations: RoutineAdministration[]
    people: CareAppPerson[]
    shifts: CareAppShift[]
    careGroups: CareGroup[]
    signOffs: ShiftOccurrenceSignOff[]
  }
}

export interface FacilityWithSettings {
  facility: Facility
  ambulatorySettings: AmbulatorySettings
  appraisalSettings: AppraisalSettings
  marSettings: MarSettings
}

export interface OrganizationWithFacilities {
  organization: Organization
  facilities: FacilityWithSettings[]
}

export interface CareAppSync {
  routineAdministrationIds: RoutineAdministration['id'][]
}

export interface FacilityProgressRoutines {
  careGroups: CareGroup[]
  people: CareAppPerson[]
  shifts: CareAppShift[]
  date?: IsoDate
  lastRequested?: IsoDateTime
  routineAdministrations: RoutineAdministration[]
  selectedCareGroupId?: string
  selectedShiftId?: string
  missedAdministrationsHistory?: {
    count: number
    date: IsoDate
  }[]
}

export type RoutineAdministrationProgressType =
  | 'Administered'
  | 'Exception'
  | 'Overdue'
  | 'Unadministered'

export type PersonWithRoutineAdministrations = {
  person: CareAppPerson
  administrations: RoutineAdministration[]
}

export interface RoutineAdministrationSnapshot {
  id: UUID
  status: RoutineAdministrationStatus
  events: UUID[]
}

export interface ShiftOccurrenceSignOff {
  id: UUID
  signedOffById: Id
  shiftOccurrenceRef: ShiftOccurrenceRef
  // A copy of the routine administrations as the frontend saw them at time of sign off.
  routineAdministrationsHistory: RoutineAdministrationSnapshot[]
  signedOffAt: UTCIsoDateTime
}
