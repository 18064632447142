import styles from './styles.module.css'
import DocumentUploadZone from './DocumentUploadZone/DocumentUploadZone'

interface Props {
  onFileChange: (files: File[]) => void
  uploadInProgress: boolean
  children: React.ReactNode
  defaultButtonText: string
  popUpActions?: React.ReactNode
}

export default function Uploader({
  onFileChange,
  uploadInProgress,
  children,
  defaultButtonText,
  popUpActions,
}: Props) {
  return (
    <div className={styles.background}>
      <div className={styles.modal}>
        <div className={styles.uploadZoneContainer}>
          <DocumentUploadZone
            onFileChange={onFileChange}
            uploadInProgress={uploadInProgress}
            defaultButtonText={defaultButtonText}
          />
        </div>
        <div className={styles.sidebarContainer}>
          <div
            className={styles.sidebarContent}
            data-testid={'uploader-sidebar'}
          >
            {children}
            <div className={styles.mobileUploadZoneContainer}>
              <DocumentUploadZone
                onFileChange={onFileChange}
                uploadInProgress={uploadInProgress}
                defaultButtonText={defaultButtonText}
              />
            </div>
          </div>
          {popUpActions && (
            <div className={styles.actionsContainer}>{popUpActions}</div>
          )}
        </div>
      </div>
    </div>
  )
}
