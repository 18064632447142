import { OccupancyStats } from '@augusthealth/models/com/august/protos/api/occupancy'
import { BasicSpinner } from '@shared/components/BasicSpinner'
import Card from '@shared/components/Card'
import { fromDateMessageToDate } from '@shared/utils/date'
import { getMonthlyPercentage } from '@shared/utils/occupancy'
import useOccupancyStats from '@app/hooks/useOccupancyStats'
import BarChart from './BarChart'
import { BarData } from './types'

type Props = {
  orgId: string
  facilityId: string
}

function convertMonthlyStatsToBarChartData(stats: OccupancyStats) {
  const { monthlyStats = [] } = stats

  return monthlyStats.reduce((list: BarData[], ms, index) => {
    const { bedDays, period, residentDays } = ms

    if (
      bedDays !== undefined &&
      period !== undefined &&
      residentDays !== undefined
    ) {
      const { startDate } = period
      const percentage = getMonthlyPercentage(ms)

      if (startDate) {
        const dateObj = fromDateMessageToDate(startDate)
        const y = percentage ? percentage * 100 : 0.1

        if (dateObj) {
          list.push({
            date: dateObj,
            x: index + 1,
            y,
            percentage,
          })
        }
      }
    }

    return list
  }, [])
}

export default function Stats({ orgId, facilityId }: Props) {
  const { occupancyStats } = useOccupancyStats({ orgId, facilityId })

  let content = (
    <div className="text-align flex-center w-full">
      <BasicSpinner className="fa-3x" />
    </div>
  )

  const cardClassNameList = ['mb-[24px]']
  if (occupancyStats.tag === 'Complete' && occupancyStats.value) {
    const barChartData = convertMonthlyStatsToBarChartData(occupancyStats.value)
    content = <BarChart data={barChartData} />
    cardClassNameList.push('pb-0')
  }

  return <Card className={cardClassNameList.join(' ')}>{content}</Card>
}
