import { DosageType } from '@augusthealth/models/com/august/protos/medication_statement'
import { DosageV2 } from '@shared/types/dosage'
import pluralize from '@shared/utils/pluralize'

type MaxDoseDescriptorOptions = {
  excludeMaximumDescriptor?: boolean
}
export const maximumDosesPerDayDescriptor = (
  dosage: DosageV2,
  options: MaxDoseDescriptorOptions = {}
): string | undefined => {
  if (
    dosage.doseAndRate === undefined ||
    dosage.doseAndRate.maxDosePerDay === undefined ||
    dosage.doseAndRate.maxDosePerDay.value === undefined
  ) {
    return undefined
  }

  const value = dosage.doseAndRate.maxDosePerDay.value
  const unit = dosage.doseAndRate.maxDosePerDay.unit
    ? dosage.doseAndRate.maxDosePerDay.unit
        .replace(/^UNIT_OF_TIME_/, '')
        .toLowerCase()
    : 'dose'

  return `${options.excludeMaximumDescriptor ? '' : 'Maximum '}${pluralize(
    unit,
    value,
    true
  )} in 24 hours`
}

export function minimumTimeBetweenDosesDescriptor(
  dosage: DosageV2
): string | undefined {
  if (
    dosage.doseAndRate === undefined ||
    dosage.doseAndRate.minTimeBetweenDose === undefined ||
    dosage.doseAndRate.minTimeBetweenDose.value === undefined
  ) {
    return undefined
  }

  const value = dosage.doseAndRate.minTimeBetweenDose.value
  const unit = dosage.doseAndRate.minTimeBetweenDose.unit
    ? dosage.doseAndRate.minTimeBetweenDose.unit
        .replace(/^UNIT_OF_TIME_/, '')
        .toLowerCase()
    : 'hour'

  return `Minimum ${value} ${pluralize(unit, value)} between doses`
}

const isDosageType = (
  valueToCompare: DosageType,
  expectedDosageType: DosageType
): boolean => {
  return valueToCompare === expectedDosageType
}

export const isVitalDosageType = (value?: DosageType): boolean => {
  return !!value && isDosageType(value, DosageType.DOSAGE_TYPE_VITAL)
}

export const isRoutineDosageType = (value?: DosageType): boolean => {
  return !!value && isDosageType(value, DosageType.DOSAGE_TYPE_ROUTINE)
}

export const isPrnDosageType = (value?: DosageType): boolean => {
  return !!value && isDosageType(value, DosageType.DOSAGE_TYPE_PRN)
}

export const isSlidingScaleDosageType = (value?: DosageType): boolean => {
  return !!value && isDosageType(value, DosageType.DOSAGE_TYPE_SLIDING_SCALE)
}

export const isTaperDosageType = (value?: DosageType): boolean => {
  return !!value && isDosageType(value, DosageType.DOSAGE_TYPE_TAPER)
}

export const isSplitDosageType = (value?: DosageType): boolean => {
  return !!value && isDosageType(value, DosageType.DOSAGE_TYPE_SPLIT)
}
