import { useState } from 'react'
import Card from '@shared/components/Card'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { tw } from '@shared/utils/tailwind'
import Content from '@app/components/generic/Content'
import HUD from '@app/components/HUD'
import PersonPageTitle from '@app/components/PersonPageTitle'
import useShifts from '@app/hooks/useShifts'
import { OrgFacilitySelects } from '@app/pages/Tools/SharedSelects/OrgFacilitySelects'
import ShiftsForm from './ShiftsForm'

export default function ShiftConfiguration() {
  const [selectedOrganization, setSelectedOrganization] =
    useState<OptionTypeBase<Organization> | null>(null)
  const [selectedFacility, setSelectedFacility] =
    useState<OptionTypeBase<Facility> | null>(null)
  const { shifts, loadShifts } = useShifts({
    facility: selectedFacility?.value || undefined,
  })
  const [hudText, setHudText] = useState<string>('')

  return (
    <Content className={tw`mt-[36px]`}>
      <PersonPageTitle title="Shifts" />
      <div className={tw`grid grid-cols-3 gap-4`}>
        <div>
          <Card className={tw`flex flex-col`}>
            <OrgFacilitySelects
              onOrgSelect={setSelectedOrganization}
              onFacilitySelect={setSelectedFacility}
              orgLabel="I want to view the shifts for..."
              selectedOrganization={selectedOrganization}
              selectedFacility={selectedFacility}
            />
          </Card>
        </div>
        <div className={tw`col-span-2`}>
          {selectedFacility && shifts.tag === 'Loading' && (
            <Card>
              <SimpleSpinner />
            </Card>
          )}
          {selectedFacility && shifts.tag === 'Complete' && (
            <ShiftsForm
              facility={selectedFacility.value}
              onSave={() => {
                setHudText('Shifts updated!')
                loadShifts()
              }}
              shifts={shifts.value}
            />
          )}
        </div>
      </div>
      {hudText && <HUD onExpire={() => setHudText('')}>{hudText}</HUD>}
    </Content>
  )
}
