import {
  BillingResidentSummary,
  PayerDetailedStatement,
  PayerSettingsData,
  PayerWithScheduledPayment,
} from '@shared/types/billing'
import { tw, twx } from '@shared/utils/tailwind'
import AutoPayForm from './AutoPayCard/AutoPayForm'
import BalanceCard from './BalanceCard'

export type CardProps = {
  billingSummary: BillingResidentSummary
  payer: PayerWithScheduledPayment
  billingPayerUserStatements: PayerDetailedStatement
  saveSettings: (updatedSettings: PayerSettingsData) => Promise<void>
}

export default function Cards(props: CardProps) {
  const { billingSummary, payer, billingPayerUserStatements, saveSettings } =
    props

  return (
    <div className={tw`flex w-full gap-4`} data-testid="overview-cards">
      <div className={twx('w-[40%]')}>
        <BalanceCard statement={billingPayerUserStatements} />
      </div>

      <div className={twx('w-[60%]')}>
        <AutoPayForm
          billingSummary={billingSummary}
          payer={payer}
          onSubmit={saveSettings}
        />
      </div>
    </div>
  )
}
