import { AppraisalSettings_AssessmentType } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { cloneDeep } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'
import { upsertSettings } from '@shared/api/orgFacilitySettings'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import Card from '@shared/components/Card'
import { LabelAboveInput } from '@shared/components/Labels'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import {
  CreatableStyledSelect,
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { FrontendDetail } from '@shared/types/assessment'
import {
  AssessmentCategory,
  AssessmentCategoryOptions,
  AssessmentConfig,
  AssessmentLevel,
  AssessmentReason,
  CategoryKeyIdentifier,
  UpdateDetailByPageMap,
} from '@shared/types/assessment_configuration'
import notEmpty from '@shared/utils/notEmpty'
import { buildCategoryKeyIdentifier } from '@shared/utils/residentAssessment'
import { tw } from '@shared/utils/tailwind'
import HUD from '@app/components/HUD'
import { haveAssessmentTypesChanged } from '@app/pages/Tools/AssessmentConfiguration/AssessmentTypes/helpers'
import styles from './styles.module.css'
import { ConfigurationLevelSelects } from '../ConfigurationLevelSelects/ConfigurationLevelSelects'
import { OrgFacilitySelects } from '../SharedSelects/OrgFacilitySelects'
import { getSettingsLevel } from '../TaskDefinitions/helpers'
import { AssessmentReasons } from './AssessmentReasons/AssessmentReasons'
import {
  buildAssessmentReasonsForEditing,
  haveAssessmentReasonsChanged,
} from './AssessmentReasons/helpers'
import { AssessmentTypes } from './AssessmentTypes/AssessmentTypes'
import { CategoryOptions } from './CategoryOptions'
import {
  CreateCustomCategoryModal,
  CustomCategoryForm,
} from './CreateCustomCategoryModal'
import { AddDetailGroupModal } from './DetailGroups/AddDetailGroupModal'
import { DetailGroups } from './DetailGroups/DetailGroups'
import {
  buildAssessmentConfig,
  buildOrgFacilitySettingsFromAssessmentConfig,
  getAssessmentCategoryOptions,
  getPendingUpdates,
  hasRequiredInformationToSaveAssessment,
  reorderExistingCategoriesWithNewCategory,
} from './helpers'
import { Levels } from './Levels/Levels'
import { PendingChanges } from './PendingChanges'
import useAssessmentConfiguration from './useAssessmentConfiguration'
import ViewDeleteCurrentSettings from './ViewDeleteCurrentSettings'

export const AssessmentConfiguration = () => {
  const { setError } = useContext(GlobalContext)
  const [showSaveNotice, setShowSaveNotice] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState<AssessmentCategory>()

  const [updatedCategoryOptions, setUpdatedCategoryOptions] =
    useState<AssessmentCategoryOptions>({})
  const [updatedLevels, setUpdatedLevels] = useState<
    AssessmentLevel[] | undefined
  >([])
  const [updatedDetails, setUpdatedDetails] = useState<
    Map<string, FrontendDetail[]> | undefined
  >()
  const [pendingUpdates, setPendingUpdates] = useState<UpdateDetailByPageMap>(
    {}
  )
  const [updatedReasons, setUpdatedReasons] = useState<AssessmentReason[]>([])

  const [updatedTypes, setUpdatedTypes] = useState<
    AppraisalSettings_AssessmentType[]
  >([])

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [proposedCategoryName, setProposedCategoryName] = useState<
    string | null
  >(null)

  const hasPendingChanges = Object.keys(pendingUpdates).some((key) =>
    Object.keys(pendingUpdates[key]).some(
      (k) => pendingUpdates[key][k].length > 0
    )
  )

  const resetAssessmentValues = () => {
    setSelectedCategory(undefined)
    setUpdatedLevels(undefined)
    setUpdatedDetails(undefined)
    setPendingUpdates({})
    setUpdatedCategoryOptions({})
  }

  const {
    settingsConfig,
    setSettingsConfig,
    assessmentConfig,
    setAssessmentConfig,
    config,
    setConfig,
    categoryDropdownOptions,
    setCategoryDropdownOptions,
    selectedFacility,
    setSelectedFacility,
    selectedOrganization,
    setSelectedOrganization,
    reloadConfig,
    reloadCategoryDropdownOptions,
  } = useAssessmentConfiguration(resetAssessmentValues)

  const hasAssessmentReasonChanges = haveAssessmentReasonsChanged(
    updatedReasons,
    config?.settings?.appraisalSettings?.assessmentReasons
  )

  const hasAssessmentTypeChanges = haveAssessmentTypesChanged(
    updatedTypes,
    config?.settings?.appraisalSettings?.assessmentTypes
  )

  useEffect(() => {
    setUpdatedReasons(
      buildAssessmentReasonsForEditing(
        config?.settings?.appraisalSettings?.assessmentReasons ?? []
      )
    )
    setUpdatedTypes(config?.settings?.appraisalSettings?.assessmentTypes ?? [])
  }, [config])

  useEffect(() => {
    if (selectedCategory) {
      setUpdatedLevels(
        cloneDeep(
          assessmentConfig![selectedCategory.categoryKey!]!.levels ?? []
        )
      )
      setUpdatedDetails(
        cloneDeep(
          assessmentConfig![selectedCategory.categoryKey!]!.details ?? []
        )
      )
      setUpdatedCategoryOptions(
        assessmentConfig![selectedCategory.categoryKey!]!.categoryOptions
      )
    } else if (!selectedCategory && !config) {
      setCategoryDropdownOptions(undefined)
      setUpdatedLevels(undefined)
      setUpdatedDetails(undefined)
      setUpdatedCategoryOptions({})
      setUpdatedReasons([])
    }
  }, [selectedCategory])

  useEffect(() => {
    if (updatedDetails && updatedLevels && selectedCategory) {
      const updates = getPendingUpdates({
        selectedCategory: selectedCategory!,
        updatedLevels,
        updatedDetails,
        updatedCategoryOptions,
        assessmentConfig,
      })

      setPendingUpdates({
        ...pendingUpdates,
        [selectedCategory!.categoryKey!]: updates,
      })
    }
  }, [
    updatedDetails,
    updatedLevels,
    updatedCategoryOptions,
    selectedCategory,
    assessmentConfig,
  ])

  const saveAssessment = async () => {
    try {
      setIsSubmitting(true)
      const newAssessment = buildOrgFacilitySettingsFromAssessmentConfig(
        assessmentConfig,
        settingsConfig,
        updatedReasons,
        updatedTypes
      )

      await upsertSettings(newAssessment)
      setShowSaveNotice(true)

      resetAssessmentValues()
      setConfig(newAssessment)
      setAssessmentConfig(
        buildAssessmentConfig(
          newAssessment.settings!.appraisalSettings!.categories!
        )
      )
      setCategoryDropdownOptions(
        getAssessmentCategoryOptions(
          newAssessment.settings!.appraisalSettings!.categories!
        )
      )
      setIsSubmitting(false)
    } catch (e) {
      setError(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  const getSelectedCategoryValue =
    (): OptionTypeBase<AssessmentCategory> | null => {
      const matchingLabel = categoryDropdownOptions?.find(
        (opt) =>
          opt &&
          opt.options.find(
            (opt) => opt.value.categoryKey === selectedCategory?.categoryKey
          )
      )
      const matchingOptions = matchingLabel?.options.find((opt) => {
        return opt.value.categoryKey === selectedCategory?.categoryKey
      })

      return matchingOptions ?? null
    }

  const savingToNewLevel =
    settingsConfig.orgId !== selectedOrganization?.value.id ||
    settingsConfig.facilityId !== selectedFacility?.value.id ||
    (config ? getSettingsLevel(config) !== settingsConfig.settingsLevel : false)

  const hasAnyChanges =
    hasPendingChanges ||
    hasAssessmentReasonChanges ||
    hasAssessmentTypeChanges ||
    savingToNewLevel

  const createNewCategory = (formData: CustomCategoryForm) => {
    const { title, page, displayOrder, includeOnServicePlan } = formData
    const newCategoryKey: CategoryKeyIdentifier = buildCategoryKeyIdentifier({
      customKey: title,
    })
    const categoryOptions = {
      title,
      page,
      displayOrder,
      question: undefined,
    }
    const newCategory: AssessmentCategory = {
      categoryKey: newCategoryKey,
      customKey: title,
      levels: [],
      details: [],
      categoryOptions,
      isExcludedFromServicePlan: !includeOnServicePlan,
    }
    const updatedAssessmentConfig = reorderExistingCategoriesWithNewCategory({
      newCategory,
      currentAssessmentConfig: cloneDeep(assessmentConfig),
    })

    setAssessmentConfig(updatedAssessmentConfig)
    reloadCategoryDropdownOptions(
      updatedAssessmentConfig,
      updatedReasons,
      updatedTypes
    )
    setSelectedCategory(newCategory)
    setProposedCategoryName(null)
  }

  const categoryIsMarkedForDelete = selectedCategory
    ? !!assessmentConfig[selectedCategory.categoryKey!].deleteOnSave
    : false

  const currentCategoryNames =
    categoryDropdownOptions
      ?.map((page) =>
        page.options.map((cat) => {
          if (cat.value.customKey) {
            return cat.value.customKey
          }

          return cat.label
        })
      )
      .flat()
      .filter(notEmpty) ?? []

  const canSaveAssessment =
    hasRequiredInformationToSaveAssessment(settingsConfig)

  return (
    <div className="content-holder">
      {proposedCategoryName && (
        <CreateCustomCategoryModal
          proposedName={proposedCategoryName}
          closeModal={() => setProposedCategoryName(null)}
          createNewCategory={createNewCategory}
          categoryDropdownOptions={categoryDropdownOptions!}
          currentCategoryNames={currentCategoryNames}
        />
      )}
      <h4 className="page-title mt-[24px]">Assessment Configuration</h4>
      <div className={styles.container}>
        <div className={styles.left}>
          <Card className={styles.card}>
            <OrgFacilitySelects
              onOrgSelect={setSelectedOrganization}
              onFacilitySelect={setSelectedFacility}
              orgLabel={'I want to view the assessment for...'}
              selectedOrganization={selectedOrganization}
              selectedFacility={selectedFacility}
            />
            <ViewDeleteCurrentSettings
              config={config}
              reloadConfig={reloadConfig}
              clearConfig={() => setConfig(undefined)}
            />
          </Card>
          {selectedOrganization && selectedFacility && !config && (
            <Card className={styles.card}>
              <SimpleSpinner />
            </Card>
          )}

          {config && categoryDropdownOptions && (
            <>
              <Card className={styles.card} data-testid={'config-selects'}>
                <ConfigurationLevelSelects
                  onSelectionChange={setSettingsConfig}
                  defaultOrg={selectedOrganization!}
                  defaultFacility={selectedFacility}
                  labelText="Save Config at..."
                />

                <AsyncIconButton
                  buttonStyle={'primary-fill'}
                  onClick={saveAssessment}
                  disabled={
                    !hasAnyChanges || isSubmitting || !canSaveAssessment
                  }
                  isLoading={isSubmitting}
                >
                  Save Assessment Configuration
                </AsyncIconButton>
              </Card>
              <Card className={styles.card} data-testid={'category-selection'}>
                <LabelAboveInput htmlFor={'category'} id={'label-category'}>
                  Edit Assessment Category
                </LabelAboveInput>
                <CreatableStyledSelect
                  name={'category'}
                  aria-labelledby="label-category"
                  instanceId={'category'}
                  aria-label={'category'}
                  placeholder="Select Category..."
                  options={categoryDropdownOptions}
                  onChange={(o: OptionTypeBase<AssessmentCategory>) => {
                    setSelectedCategory(o?.value)
                    setUpdatedLevels(undefined)
                    setUpdatedDetails(undefined)
                    setUpdatedCategoryOptions({})
                  }}
                  onCreateOption={(opt) => {
                    setProposedCategoryName(opt)
                  }}
                  value={getSelectedCategoryValue()}
                  isValidNewOption={(opt) =>
                    !!(
                      opt.trim() &&
                      !currentCategoryNames
                        .map((opt) => opt.toLowerCase())
                        .includes(opt.toLowerCase())
                    )
                  }
                  isClearable={true}
                  formatCreateLabel={(val: string) =>
                    `Create Category "${val}"`
                  }
                  formatOptionLabel={(
                    opt: OptionTypeBase<AssessmentCategory>
                  ) => {
                    if (opt.value.categoryKey) {
                      const customKey = opt.value.categoryKey.split('-')[1]

                      if (customKey) {
                        return (
                          <div className={'flex flex-row items-baseline'}>
                            {opt.label}
                            <span
                              className={
                                'ml-[8px] text-[13px] leading-[16px] text-gray-08'
                              }
                            >
                              {customKey}
                            </span>
                          </div>
                        )
                      } else if (
                        assessmentConfig[opt.value.categoryKey]?.deleteOnSave
                      ) {
                        return (
                          <div
                            data-testid={`${opt.label}-TBD`}
                            className={tw`text-gray-07 line-through`}
                          >
                            {opt.label}
                          </div>
                        )
                      } else {
                        return <div>{opt.label}</div>
                      }
                    }

                    return <div>{opt.label}</div>
                  }}
                  filterOption={(
                    opt: FilterOptionOption<OptionTypeBase<AssessmentCategory>>,
                    inputValue: string
                  ) => {
                    const searchTerm = inputValue.toLowerCase()
                    return !!(
                      opt.label.toLowerCase().includes(searchTerm) ||
                      opt.data.value.customKey
                        ?.toLowerCase()
                        .includes(searchTerm)
                    )
                  }}
                />
              </Card>
              <PendingChanges
                hasPendingChanges={hasPendingChanges}
                pendingUpdates={pendingUpdates}
              />
            </>
          )}
        </div>
        <div className={styles.right}>
          {selectedCategory ? (
            <>
              <CategoryOptions
                selectedCategory={selectedCategory}
                updatedCategoryOptions={updatedCategoryOptions}
                setUpdatedCategoryOptions={setUpdatedCategoryOptions}
                setAssessmentConfig={setAssessmentConfig}
                currentAssessmentConfig={assessmentConfig}
                categoryIsMarkedForDelete={categoryIsMarkedForDelete}
                reloadCategoryDropdownOptions={(
                  updatedConfig: AssessmentConfig
                ) =>
                  reloadCategoryDropdownOptions(
                    updatedConfig,
                    updatedReasons,
                    updatedTypes
                  )
                }
              />
              {updatedLevels && (
                <Levels
                  selectedCategory={selectedCategory}
                  updatedCategoryOptions={updatedCategoryOptions}
                  setUpdatedCategoryOptions={setUpdatedCategoryOptions}
                  updatedLevels={updatedLevels}
                  setUpdatedLevels={setUpdatedLevels}
                  setAssessmentConfig={setAssessmentConfig}
                  currentAssessmentConfig={assessmentConfig}
                  categoryIsMarkedForDelete={categoryIsMarkedForDelete}
                />
              )}
              {updatedDetails && (
                <>
                  <DetailGroups
                    updatedDetails={updatedDetails}
                    selectedCategory={selectedCategory}
                    setUpdatedDetails={setUpdatedDetails}
                    setAssessmentConfig={setAssessmentConfig}
                    currentAssessmentConfig={assessmentConfig}
                    categoryIsMarkedForDelete={categoryIsMarkedForDelete}
                  />
                  <AddDetailGroupModal
                    updatedDetails={updatedDetails}
                    selectedCategory={selectedCategory}
                    setUpdatedDetails={setUpdatedDetails}
                    setAssessmentConfig={setAssessmentConfig}
                    currentAssessmentConfig={assessmentConfig}
                    categoryIsMarkedForDelete={categoryIsMarkedForDelete}
                  />
                </>
              )}
            </>
          ) : (
            config && (
              <>
                <AssessmentReasons
                  assessmentReasons={updatedReasons}
                  setAssessmentReasons={setUpdatedReasons}
                />
                <AssessmentTypes
                  assessmentTypes={updatedTypes}
                  setAssessmentTypes={setUpdatedTypes}
                />
              </>
            )
          )}
        </div>
      </div>
      {showSaveNotice && (
        <HUD
          onExpire={() => setShowSaveNotice(false)}
          className="animate-fade-in"
        >
          Assessment Configuration Saved
        </HUD>
      )}
    </div>
  )
}
