import { Immunization } from '@augusthealth/models/com/august/protos/immunization'
import { first } from 'lodash'
import { Person, PersonIds } from '@shared/types/person'
import { sortByDateOfOccurrence } from '@shared/utils/immunizations'
import {
  attachImmunization,
  createImmunization,
  deleteImmunization,
} from '@app/api/immunizations'

function mapId(immunization: Immunization) {
  return immunization.id
}

export async function attachFilesToMostRecentImmunization({
  files,
  person,
  immunizations,
}: {
  files: File[]
  person: Person
  immunizations: Immunization[]
}): Promise<Immunization | undefined> {
  const mostRecentImmunizationId: string | undefined = first(
    sortByDateOfOccurrence(immunizations)
  )?.id

  if (mostRecentImmunizationId) {
    return await attachImmunization(files, mostRecentImmunizationId, person)
  }

  return Promise.resolve(undefined)
}

export async function saveImmunizations({
  originalImmunizations,
  updatedImmunizations,
  person,
}: {
  originalImmunizations: Immunization[]
  updatedImmunizations: Immunization[]
  person: Required<PersonIds>
}): Promise<Immunization[]> {
  // Don't need to handle updated immunizations
  const updatedIds = updatedImmunizations.filter(mapId).map(mapId)
  const immunizationsToCreate = updatedImmunizations.filter((immu) => !immu.id)
  const immunizationsToKeep = updatedImmunizations.filter((immu) => immu.id)
  const immunizationsToDelete = originalImmunizations.filter(
    (immu) => !updatedIds.includes(immu.id)
  )

  const promisesToCreate = immunizationsToCreate.map((immu) =>
    createImmunization(person, immu)
  )
  const promisesToDelete = immunizationsToDelete.map((immu) =>
    deleteImmunization(person, immu)
  )
  const records = await Promise.all([...promisesToCreate, ...promisesToDelete])
  const immunizationsToCreateWithId = immunizationsToCreate.map((immu, i) => ({
    id: records[i].id.toString(),
    ...immu,
  }))
  return [...immunizationsToKeep, ...immunizationsToCreateWithId]
}
