import { AllergyIntolerance_AllergyIntoleranceCategory } from '@augusthealth/models/com/august/protos/allergy_intolerance'
import {
  ChangeEvent,
  KeyboardEvent,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import { fetchPerson } from '@shared/api/person'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { LabelAboveInput } from '@shared/components/Labels'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import {
  DiagnosesOrAllergiesFields,
  updateDiagnosesAndAllergies,
} from '@shared/utils/diagnosis'
import { allergyList, diagnosesList } from '@shared/utils/person'
import { twx } from '@shared/utils/tailwind'
import DeletableInput from '@app/components/formElements/DeletableInput'
import { SwitchSize } from '@app/components/Switch'
import Allergies from './Allergies'

type DisplayOption = 'all' | 'allergies_only' | 'diagnoses_only'

type Options = {
  displayOption: DisplayOption
  submitOnSaveBtn?: boolean // set to true if handling form inside of another form
}

function getModalTitle(displayOption: DisplayOption) {
  switch (displayOption) {
    case 'all':
      return 'Diagnoses & Medical Allergies'
    case 'allergies_only':
      return 'Medical Allergies'
    case 'diagnoses_only':
      return 'Diagnoses'
  }
}

function getApiFields(
  displayOption: DisplayOption
): DiagnosesOrAllergiesFields[] {
  switch (displayOption) {
    case 'all':
      return ['conditions', 'allergiesAndIntolerances/allergies']
    case 'allergies_only':
      return ['allergiesAndIntolerances/allergies']
    case 'diagnoses_only':
      return ['conditions']
  }
}

export type Props = {
  person: Person
  onClose: (updated: boolean) => Promise<void>
  options?: Options
}

export default function EditDiagnoses({
  person,
  onClose,
  options = {
    displayOption: 'all',
    submitOnSaveBtn: false,
  },
}: Props) {
  const { displayOption, submitOnSaveBtn } = options
  const { setError } = useContext(GlobalContext)
  const { handleSubmit, formState } = useForm()
  const [updatedPerson, setUpdatedPerson] = useState(person)
  const medCategory =
    AllergyIntolerance_AllergyIntoleranceCategory.ALLERGY_INTOLERANCE_CATEGORY_MEDICATION
  const [diagnoses, setDiagnoses] = useState([
    ...diagnosesList(updatedPerson),
    { code: { text: '' }, isPrimary: false },
  ])
  const [allergies, setAllergies] = useState([
    ...allergyList(updatedPerson).filter(
      (allergy) => allergy.category === medCategory
    ),
    { summary: '', category: medCategory },
  ])
  const fields = getApiFields(displayOption)

  useEffect(() => {
    fetchPerson({
      facilityId: person.facilityId,
      personId: person.id,
      orgId: person.orgId,
      fields,
    })
      .then(setUpdatedPerson)
      .catch(setError)
  }, [])

  const onSubmit = async () => {
    const fullAllergyList = [
      ...allergies.filter((a) => a.summary?.trim()),
      ...allergyList(updatedPerson).filter(
        (allergy) => allergy.category !== medCategory
      ),
    ]

    try {
      await updateDiagnosesAndAllergies({
        person,
        diagnoses: diagnoses.filter((d) => d.code?.text?.trim()),
        allergies: fullAllergyList,
        fields,
      })
      await onClose(true)
    } catch (e) {
      setError(e)
    }
  }

  const onDiagnosisChange = (newValue: string, index: number) =>
    setDiagnoses([
      ...diagnoses.slice(0, index),
      {
        ...diagnoses[index],
        code: { text: newValue },
      },
      ...diagnoses.slice(index + 1),
      // once the last item is filled out, add new blank item at the end
      ...(index === diagnoses.length - 1 && newValue.trim()
        ? [{ code: { text: '' }, isPrimary: false }]
        : []),
    ])
  const onPrimaryDiagnosis = (isPrimary: boolean, index: number) =>
    setDiagnoses([
      ...diagnoses.slice(0, index),
      { ...diagnoses[index], isPrimary },
      ...diagnoses.slice(index + 1),
    ])
  const onTrashDiagnosis = (index: number) =>
    setDiagnoses(diagnoses.filter((_d, i) => i !== index))
  const diagnoseInputs = diagnoses.map((diagnosis, index) => {
    const hasCoding = !!diagnosis.code!.coding
    const text = diagnosis.code!.text
    const inputProps = {
      disabled: hasCoding,
      name: `diagnoses[${index}]`,
      value: text,
      onKeyDown: (ev: KeyboardEvent<HTMLInputElement>) => {
        if (ev.key === 'Enter') {
          ev.preventDefault()
        }
      },
      onChange: (ev) => onDiagnosisChange(ev.target.value, index),
      placeholder: 'Enter one per line...',
    }
    const switchProps = text
      ? {
          label: 'PRIMARY',
          checked: diagnosis.isPrimary || false,
          name: `isPrimary[${index}]`,
          size: SwitchSize.small,
          onChange: (ev: ChangeEvent<HTMLInputElement>) =>
            onPrimaryDiagnosis(ev.target.checked, index),
        }
      : undefined
    const trashIconProps =
      text && !hasCoding
        ? {
            name: `delete-diagnosis[${index}]`,
            onClick: () => onTrashDiagnosis(index),
            type: 'button',
          }
        : undefined

    return (
      <DeletableInput
        key={`diagnosis-${index}`}
        inputProps={inputProps}
        switchProps={switchProps}
        trashIconProps={trashIconProps}
      />
    )
  })

  const saveAction = handleSubmit(onSubmit)
  const formContent = (
    <form onSubmit={submitOnSaveBtn ? undefined : saveAction}>
      {displayOption !== 'allergies_only' && (
        <>
          <LabelAboveInput htmlFor="diagnoses">Diagnoses</LabelAboveInput>
          {diagnoseInputs}
        </>
      )}
      {displayOption !== 'diagnoses_only' && (
        <>
          <LabelAboveInput
            className={twx({ 'mt-[32px]': displayOption === 'all' })}
            htmlFor="allergies"
          >
            Medical Allergies
          </LabelAboveInput>
          <Allergies
            allergies={allergies}
            setAllergies={setAllergies}
            category={medCategory}
          />
        </>
      )}
      <div className="mt-[32px]">
        <AnimatedPopupFormFooter
          noBtn={{ action: () => void onClose(false) }}
          yesBtn={{
            props: {
              id: 'saveDiagnosesAllergies',
              onClick: submitOnSaveBtn ? saveAction : undefined,
              type: submitOnSaveBtn ? 'button' : 'submit',
            },
          }}
          formState={formState}
        />
      </div>
    </form>
  )

  return (
    <AnimatedPopup title={getModalTitle(displayOption)}>
      {formContent}
    </AnimatedPopup>
  )
}
