import React from 'react'
import { AugustError } from '@shared/utils/error'

export type GlobalContextProps = {
  error: AugustError | TypeError | undefined
  setError: (error: unknown) => void
  setLoading: (loading: boolean) => void
}

export default React.createContext<GlobalContextProps>({
  error: undefined,
  setError: () => {},
  setLoading: () => {},
})
