import { useEffect, useRef, useState } from 'react'
import { UrlAndContentType } from '@shared/api/request'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import { AsyncResult } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import PDFReader from '@app/components/generic/PDFReader'
import styles from './styles.module.css'

interface Props {
  blobData: AsyncResult<UrlAndContentType, any> | null
  pdfScale?: number
  notFoundMessage?: string
}

export default function DocumentPreview({
  blobData,
  pdfScale = 1,
  notFoundMessage = 'Document not found',
}: Props) {
  const pdfContainerRef = useRef<HTMLDivElement>(null)

  const [containerWidth, setContainerWidth] = useState<number>()

  useEffect(() => {
    if (blobData?.tag !== 'Loading' && pdfContainerRef) {
      const calculatedWidth = pdfContainerRef.current?.offsetWidth
      if (calculatedWidth) {
        const maxWidth = 888
        if (calculatedWidth > maxWidth) {
          setContainerWidth(maxWidth)
        } else {
          // subtracting 32px due to margin/padding
          setContainerWidth(calculatedWidth - 32)
        }
      }
    }
  }, [blobData, pdfContainerRef])

  if (blobData?.tag === 'Loading') {
    return <SimpleSpinner />
  }

  const isPDF =
    blobData?.tag === 'Complete' &&
    blobData.value.contentType === 'application/pdf'

  let fileUrl = ''
  let fileName
  if (blobData?.tag === 'Complete') {
    fileUrl = blobData.value.url
    fileName = blobData.value.filename
  }

  return (
    <div className={styles.previewContainer} ref={pdfContainerRef}>
      {(blobData === null || blobData.tag === 'Error') && (
        <div className={tw`h-100 flex items-center justify-center`}>
          <div>{notFoundMessage}</div>
        </div>
      )}
      {isPDF && (
        <PDFReader
          pdfPath={fileUrl}
          scale={pdfScale}
          width={containerWidth}
          documentProps={{
            error: (
              <img
                className={styles.imageReview}
                src={fileUrl}
                alt="Image Preview"
              />
            ),
          }}
        />
      )}
      {!isPDF && (
        <img className={styles.imageReview} src={fileUrl} alt={fileName} />
      )}
    </div>
  )
}
