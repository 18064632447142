import { LoaderSvg } from '@shared/components/LoadingPopup'
import LogoHeader from '@shared/components/LogoHeader'
import { tw } from '@shared/utils/tailwind'
import Footer from './Footer'

export default function Loader({ title }: { title: string }) {
  return (
    <div
      className={tw`flex flex-col items-center rounded-xl bg-white px-[60px] pb-[16px] pt-[40px]`}
    >
      <LogoHeader title={''} />

      <div className={tw`mt-[24px]`}>
        <LoaderSvg />
      </div>

      <div className={tw`mt-[28px] text-xl font-medium`}>{title}</div>

      <Footer />
    </div>
  )
}
