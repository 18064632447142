import { useContext, useEffect } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { UserAccount } from '@shared/types/user'
import { getFullName } from '@shared/utils/humanName'
import { isDirector as getIsDirector } from '@shared/utils/user'
import { removeGroupsFromOrganizationUser } from '@app/api/organizations'
import { removeAllGroupsFromFacilityUser } from '@app/api/users'
import ConfirmModal from '@app/components/ConfirmModal'
import { getUserLabelAndType } from './helpers'

type Props = {
  orgId: string
  user: UserAccount
  onClose: () => void
  setShowNotice: React.Dispatch<React.SetStateAction<boolean>>
}

export default function DeleteUserModal({
  orgId,
  user,
  onClose,
  setShowNotice,
}: Props) {
  const { user: loginUser } = useUserContext()
  const isDirector = getIsDirector({ user: loginUser, orgId })
  // Make sure that Show Notice HUD will be reset
  useEffect(() => setShowNotice(false), [])

  const { setError } = useContext(GlobalContext)
  const { id, name: n, email } = user
  const name = n ? getFullName(n) : ''
  const onConfirm = async () => {
    if (id) {
      try {
        if (isDirector) {
          await removeAllGroupsFromFacilityUser({
            loginUser,
            orgId,
            updatedUser: user,
          })
        } else {
          await removeGroupsFromOrganizationUser(orgId, user)
        }

        setShowNotice(true)
        onClose()
      } catch (e) {
        onClose()
        setError(e)
      }
    }
  }

  const { label = '' } = getUserLabelAndType(user)
  const content = (
    <div>
      <span className="font-semibold">
        {name} ({label})
      </span>
      <br />
      {email}
    </div>
  )

  return (
    <ConfirmModal
      title="Do you want to delete this user?"
      content={content}
      confirmButtonConfig={{
        children: 'Delete User',
        onClick: onConfirm,
      }}
      denyButtonConfig={{
        onClick: onClose,
      }}
    />
  )
}
