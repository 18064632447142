import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getPersonCompletionStats } from '@shared/api/person'
import Card from '@shared/components/Card'
import {
  CompletionType,
  ResidentCompletionStats,
} from '@shared/types/api/facility_stats'
import { Person } from '@shared/types/person'
import { Task } from '@shared/types/task'
import { CompletionTypeToTemplateHash } from '@shared/utils/facilityStats'
import { Loading } from '@shared/utils/loading'
import notEmpty from '@shared/utils/notEmpty'
import HUD from '@app/components/HUD'
import useCompletionStats from '@app/pages/Compliance/useCompletionStats'
import styles from './styles.module.css'
import { getTaskForItem, PossibleTasks } from './helpers'
import ResidentModalProducer, { ModalType } from './ModalProducer'
import PieChartIcon, { strokeColorForPercent } from './PieChartIcon'
import { TaskRow } from './TaskRow'

export default function CompletionStats({
  person,
  tasks,
  refreshTasks,
}: {
  person: Person
  tasks: Task[]
  refreshTasks: () => Promise<void>
}) {
  const history = useHistory()
  const {
    availableCompletionTasks,
    completionTypeToTemplateHash = {} as CompletionTypeToTemplateHash,
  } = useCompletionStats({
    orgId: person.orgId!,
    facilityId: person.facilityId!,
  })

  const [personStats, setPersonStats] =
    useState<Loading<ResidentCompletionStats>>()
  const [cardClassName, setCardClassName] = useState(styles.card)
  const [showDetails, setShowDetails] = useState(false)
  const [showReminderNotice, setShowReminderNotice] = useState(false)

  const [modalType, setModalType] = useState<ModalType>()

  useEffect(() => {
    setPersonStats({ tag: 'Loading' })
    void getPersonCompletionStats(person as Required<Person>).then((stats) => {
      setPersonStats({ tag: 'Complete', value: stats })
    })
  }, [person])

  if (!personStats || !tasks) {
    return null
  }

  if (
    personStats.tag === 'Loading' ||
    availableCompletionTasks.tag === 'Loading'
  ) {
    return (
      <Card className={styles.card}>
        <div className={styles.cardTitle}>
          <div className="flex items-center text-[16px] font-medium leading-[24px]">
            <PieChartIcon percent={0} size={20} />
            <span className="ml-[8px]">Resident Completion</span>
            <span
              style={{ color: strokeColorForPercent(0) }}
              className="ml-[8px]"
            />
          </div>
          <h4 className="mb-0 flex items-center text-[14px] font-medium uppercase text-gray-07">
            <span className="text-[13px]">0 of 0 done</span>
            <i className={`far fa-angle-down ${styles.expandIcon}`} />
          </h4>
        </div>
      </Card>
    )
  }

  const totalItems = [
    ...availableCompletionTasks.value,
    CompletionType.COMPLETION_TYPE_HAS_PROFILE_PHOTO,
  ]
  const completeItems = Object.values(
    personStats.value.completionStatus!
  ).filter((v) => v)
  const percentComplete = (
    (completeItems.length / totalItems.length) *
    100
  ).toPrecision(3)

  const residentTasks = totalItems
    .map((stat: PossibleTasks) =>
      getTaskForItem({
        stat,
        completionTypeToTemplateHash,
        personStats: personStats.value,
        personTasks: { tasks },
        history,
        setShowProfile: () => setModalType('profile'),
        setShowDietModal: () => setModalType('diet'),
        setShowPolstUploader: () => setModalType('polst'),
        setShow602Uploader: () => setModalType('602'),
        setShowEditAmbulatory: () => setModalType('ambulatory'),
        setShowReminder: () => setModalType('rp-reminder'),
      })
    )
    .filter(notEmpty)

  const closeModals = () => {
    setModalType(undefined)
  }
  const reloadCompletionStats = async () => {
    setPersonStats({ tag: 'Loading' })
    const updatedStats = await getPersonCompletionStats(
      person as Required<Person>
    )
    setPersonStats({ tag: 'Complete', value: updatedStats })
    await refreshTasks()
  }

  const modalConfiguration =
    modalType === 'rp-reminder'
      ? { modalType, setShowReminderNotice }
      : { modalType }

  return (
    <>
      <Card className={cardClassName}>
        <div
          className={styles.cardTitle}
          onMouseEnter={() => {
            setCardClassName(`${styles.cardHover} ${styles.card}`)
          }}
          onMouseLeave={() => {
            setCardClassName(styles.card)
          }}
          onClick={() => {
            setShowDetails(!showDetails)
          }}
        >
          <div className="flex items-center text-[16px] font-medium leading-[24px]">
            <PieChartIcon percent={+percentComplete} size={20} />
            <span className="ml-[12px]">Resident Completion</span>
            <span
              data-cy="completion-percent"
              style={{ color: strokeColorForPercent(+percentComplete) }}
              className="ml-[8px]"
            >
              {percentComplete}%
            </span>
          </div>
          <div
            data-cy="completion-count"
            className="mb-0 flex items-center text-[14px] font-medium uppercase text-gray-07"
          >
            <span className="text-[13px]">{`${completeItems.length} of ${totalItems.length} done`}</span>
            <i
              className={`far fa-angle-${showDetails ? 'up' : 'down'} ${
                styles.expandIcon
              }`}
            />
          </div>
        </div>
        <div
          className={
            showDetails ? styles.tasksContainer : styles.tasksContainerHidden
          }
        >
          {residentTasks.map((task, index) => (
            <TaskRow
              key={`completion-task-${index}`}
              task={task!}
              person={person}
            />
          ))}
        </div>
      </Card>
      <ResidentModalProducer
        person={person}
        onUpdate={reloadCompletionStats}
        onClose={closeModals}
        modalConfiguration={modalConfiguration}
        tasks={tasks}
      />
      {showReminderNotice && (
        <HUD onExpire={() => setShowReminderNotice(false)}>
          Reminder email sent
        </HUD>
      )}
    </>
  )
}
