import { snapshotFileUrl } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { Snapshot } from '@shared/types/snapshot'
import { Task } from '@shared/types/task'

export type AssessmentViewerMode =
  | {
      tag: 'review'
      onSubmit: () => void
      onMakeChanges: () => void
      assessmentTask: Task
    }
  | {
      tag: 'view'
      onClose: () => void
    }

export type AssessmentType =
  | { tag: 'Internal' }
  | { tag: 'External'; fileUrl?: string }

export function getAssessmentType(
  appraisal: Snapshot,
  person: Person
): AssessmentType {
  const isExternal =
    appraisal.data?.augustInitialAppraisal?.isExternalAppraisal ?? false
  const fileUrl =
    appraisal.fileMetaData &&
    snapshotFileUrl({
      personId: person.id!,
      orgId: person.orgId!,
      dataType: appraisal.dataType!,
      snapshotId: appraisal.id!,
    })

  return {
    tag: isExternal ? 'External' : 'Internal',
    fileUrl,
  }
}
