import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { useUserContext } from '@shared/contexts/UserContext'
import { Facility } from '@shared/types/facility'
import { tw } from '@shared/utils/tailwind'
import { isSuperUser } from '@shared/utils/user'
import { DashboardSession } from '@app/api/payments'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import useRainforestDashboardSession from '@app/hooks/useRainforestDashboardSession'
import GeneralLedger from './GeneralLedger'
import { hasRainforestMerchantId } from './helpers'

export default function Reports() {
  const { currentFacility } = useCurrentFacility()

  const [dashboardType, setDashboardType] =
    useState<DashboardType>('General Ledger')
  const options = [
    ...(hasRainforestMerchantId(currentFacility)
      ? [
          { label: 'ACH Payments', value: 'Payments' },
          { label: 'Deposits', value: 'Deposits' },
        ]
      : []),
    { label: 'General Ledger', value: 'General Ledger' },
  ]

  if (!currentFacility) return null

  if (currentFacility.tag === 'Loading') {
    return (
      <section>
        <div className="mx-[12px] mt-[24px] w-[225px]">
          <StyledSelect
            isDisabled
            aria-label="billing-dashboards"
            options={[]}
          />
        </div>
        <Skeleton height={500} className={tw`mt-8`} />
      </section>
    )
  }

  return (
    <section>
      <div className="mx-[12px] mt-[24px] w-[225px]">
        <StyledSelect
          aria-label="billing-dashboards"
          value={{ label: dashboardType, value: dashboardType }}
          onChange={(value: OptionTypeBase<DashboardType>) =>
            setDashboardType(value.value as DashboardType)
          }
          options={options}
        />
      </div>
      {hasRainforestMerchantId(currentFacility) && (
        <RainforestDashboards
          dashboardType={dashboardType}
          facility={currentFacility.value}
        />
      )}
      {dashboardType === 'General Ledger' && (
        <GeneralLedger facility={currentFacility.value} />
      )}
    </section>
  )
}

function RainforestDashboards({
  facility,
  dashboardType,
}: {
  dashboardType: DashboardType
  facility: Facility
}) {
  const { dashboardSession } = useRainforestDashboardSession(facility)

  if (dashboardSession.tag === 'Loading') {
    return <Skeleton height={500} className={tw`mt-8`} />
  }

  return (
    <>
      {dashboardType === 'Payments' && dashboardSession && (
        <PaymentDashboard
          dashboardSession={dashboardSession.value}
          facilityId={facility.id}
        />
      )}
      {dashboardType === 'Deposits' && dashboardSession && (
        <DepositDashboard
          dashboardSession={dashboardSession.value}
          facilityId={facility.id}
        />
      )}
    </>
  )
}

type DashboardType = 'Payments' | 'Deposits' | 'General Ledger'

function PaymentDashboard({
  dashboardSession,
  facilityId,
}: {
  dashboardSession: DashboardSession
  facilityId: string
}) {
  const { user } = useUserContext()

  return React.createElement(
    'rainforest-payment-report',
    dashboardConfiguration({
      dashboardSession,
      facilityId,
      showMetadata: isSuperUser(user),
    })
  )
}

function DepositDashboard({
  dashboardSession,
  facilityId,
}: {
  dashboardSession: DashboardSession
  facilityId: string
}) {
  return React.createElement(
    'rainforest-deposit-report',
    dashboardConfiguration({ dashboardSession, facilityId })
  )
}

function dashboardConfiguration({
  dashboardSession,
  facilityId,
  showMetadata,
}: {
  dashboardSession: DashboardSession
  facilityId: string
  showMetadata?: boolean
}) {
  return {
    'session-key': dashboardSession.sessionKey,
    'data-filters': `{"merchant_id": "${dashboardSession.merchantId}", "metadata[$.resident.facilityId]": "${facilityId}"}`,
    'details-view': 'fullscreen_overlay',
    'show-export-button': true,
    'hide-metadata-raw-json': true,
    'hide-metadata': !showMetadata || undefined,
    'display-header': undefined,
    'style-button-color': '#007580',
    'style-font-family': 'Inter',
    'style-font-size': '14px',
    'style-border-radius': '8px',
  }
}
