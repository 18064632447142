import { MedGroup } from '@augusthealth/models/com/august/protos/api/med_group'
import { CareGroup } from '@augusthealth/models/com/august/protos/care_group'
import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Facility } from '@shared/types/facility'
import { FacilityGroupType } from '@shared/utils/careGroup'
import { Loading } from '@shared/utils/loading'
import { fetchCareGroups } from '../api/careGroup'
import { fetchMedGroups } from '../api/medGroup'

export default function useGroupsByFacility({
  facility,
  facilityGroupType,
}: {
  facility: Facility
  facilityGroupType: FacilityGroupType
}) {
  const { id, orgId } = facility
  const { setError } = useContext(GlobalContext)
  const [groups, setGroups] = useState<Loading<CareGroup[] | MedGroup[]>>({
    tag: 'Loading',
  })

  const reloadGroups = () => {
    if (id && orgId) {
      const func =
        facilityGroupType === 'med' ? fetchMedGroups : fetchCareGroups
      func({ facility: { id, orgId } })
        .then((res) =>
          setGroups({
            tag: 'Complete',
            value: res,
          })
        )
        .catch(setError)
    }
  }

  useEffect(() => {
    reloadGroups()
  }, [id, orgId, facilityGroupType])

  return {
    groups,
    reloadGroups,
  }
}
