import { CareGroup } from '@augusthealth/models/com/august/protos/care_group'
import { requestJson } from '@shared/api/request'
import { apiFacilityUrl } from '@shared/legacy_routes'
import { Facility, FacilityIds } from '@shared/types/facility'
import { getUpdatedGroup } from '@shared/utils/careGroup'

function apiCareGroupUrl(facility: FacilityIds) {
  const { id: facilityId, orgId } = facility
  return `${apiFacilityUrl(orgId, facilityId)}/careGroups`
}

export async function fetchCareGroups({
  facility,
}: {
  facility: FacilityIds
}): Promise<CareGroup[]> {
  const response = await requestJson({
    url: apiCareGroupUrl(facility),
    method: 'GET',
  })

  return response.data
}

type FacilityId = string
export type CareGroupsMap = Record<FacilityId, CareGroup[]>

export async function fetchAllCareGroups({
  facilities,
}: {
  facilities: FacilityIds[]
}): Promise<CareGroupsMap> {
  const facilityIds = facilities.map((f) => f.id)
  const promises = facilities.map((f) => fetchCareGroups({ facility: f }))

  return Promise.all(promises).then((listInList: CareGroup[][]) => {
    return listInList.reduce((hash, groups, i) => {
      hash[facilityIds[i]] = groups
      return hash
    }, {})
  })
}

async function createCareGroup({
  careGroup,
  facility,
}: {
  careGroup: CareGroup
  facility: FacilityIds
}): Promise<{ id: string }> {
  const response = await requestJson({
    url: apiCareGroupUrl(facility),
    method: 'POST',
    body: JSON.stringify(careGroup),
  })

  return response.data
}

async function updateCareGroup({
  careGroup,
  facility,
}: {
  careGroup: CareGroup
  facility: FacilityIds
}): Promise<{ id: string }> {
  const { name, id } = careGroup
  const response = await requestJson({
    contentType: 'application/merge-patch+json',
    url: `${apiCareGroupUrl(facility)}/${id}`,
    method: 'PATCH',
    body: JSON.stringify({ name }),
  })

  return response.data
}

export async function deleteCareGroup({
  group,
  facility,
  force = false,
}: {
  group: CareGroup
  facility: FacilityIds
  force?: boolean
}): Promise<{ id: string }> {
  const response = await requestJson({
    url: `${apiCareGroupUrl(facility)}/${group.id}?force=${force}`,
    method: 'DELETE',
  })

  return response.data
}

export async function createOrUpdateMultipleCareGroups({
  facility,
  originalCareGroups,
  updatedCareGroups,
}: {
  facility: Facility
  originalCareGroups: CareGroup[]
  updatedCareGroups: CareGroup[]
}): Promise<any> {
  const careGroupsToCreate = updatedCareGroups.filter((c) => !c.id && c.name)
  const promisesToCreate = careGroupsToCreate.map(async (g) => {
    await createCareGroup({
      facility: facility as FacilityIds,
      careGroup: g,
    })
  })

  const careGroupsToUpdate = getUpdatedGroup(
    updatedCareGroups,
    originalCareGroups
  )
  const promisesToUpdate = careGroupsToUpdate
    .filter((cg) => cg.name)
    .map(async (g) => {
      await updateCareGroup({
        facility: facility as FacilityIds,
        careGroup: g,
      })
    })

  return Promise.all([...promisesToCreate, ...promisesToUpdate])
}
