import { useContext, useState } from 'react'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { Organization } from '@shared/types/organization'
import { removeEmptyPaymentCustomer } from '@shared/utils/payment'
import { EMAIL_REGX } from '@shared/utils/regex'
import { isSuperUser as getIsSuperUser } from '@shared/utils/user'
import { createOrganization, updateOrganization } from '@app/api/organizations'
import AdvancedFormGenerator from '@app/components/generators/AdvancedFormGenerator'
import getFormConfig from './getFormConfig'

type Props = {
  organization?: Organization
  onClose: () => void
  onSave: () => void
}

export default function OrgDetailsForm(props: Props) {
  const { setError } = useContext(GlobalContext)
  const { user } = useUserContext()
  const isSuperUser = getIsSuperUser(user)
  const { organization: defaultOrg, onClose, onSave } = props
  const [organization, setOrganization] = useState<Organization | undefined>(
    defaultOrg
  )
  const update = (_value: any, _name: string, data: Organization) => {
    setOrganization(removeEmptyPaymentCustomer(data))
  }
  const isEditMode = Boolean(defaultOrg)
  const save = () => {
    const updatedOrg = organization
    if (updatedOrg && updatedOrg.paymentCustomer) {
      // Clone Org. Address or Telecom to Payment Customer when need
      const { address, telecom } = updatedOrg
      const { address: pcAddress, telecom: pcTelecom } =
        updatedOrg.paymentCustomer
      if (address && !pcAddress) {
        updatedOrg.paymentCustomer.address = address
      }
      if (telecom && !pcTelecom) {
        updatedOrg.paymentCustomer.telecom = telecom
      }
    }

    let func
    if (isEditMode) {
      func = updateOrganization
    } else {
      func = createOrganization
    }
    func(organization).then(onSave).catch(setError)
  }
  const title = isEditMode ? 'Edit Organization' : 'Add Organization'
  const formConfig = getFormConfig({ organization, isSuperUser })
  const requiredFields = [
    'name',
    {
      path: 'telecom[system=CONTACT_POINT_SYSTEM_EMAIL].value',
      isInvalidFunc: (email?: string) => {
        if (email?.trim()) {
          if (!EMAIL_REGX.test(email)) {
            return '* Invalid email format'
          }
        } else {
          return '* Required'
        }

        return false
      },
    },
  ]
  if (isSuperUser && organization?.paymentCustomer) {
    requiredFields.push(
      'paymentCustomer.billingStrategy',
      'paymentCustomer.paymentFrequency'
    )
  }

  return (
    <AnimatedPopup title={title}>
      <AdvancedFormGenerator
        data={organization}
        configuration={formConfig}
        saveButtonClassName="hover:brightness-125 bg-button-primary-color text-button-fill-font-color min-w-[100px]"
        saveButtonLabel="SAVE"
        saveButtonClick={save}
        cancelButtonClassName="bg-white border border-button-inner-border-color text-button-default-font-color hover:brightness-90 min-w-[100px]"
        cancelButtonLabel="CLOSE"
        cancelButtonClick={onClose}
        onUpdate={update}
        requiredFields={requiredFields}
      />
    </AnimatedPopup>
  )
}
