import { useContext } from 'react'
import PersonContext from '@app/contexts/PersonContext'
import LanguageDropdown, { Props as LanguageDropdownProps } from './index'

type Props = Omit<LanguageDropdownProps, 'person'>

export default function LanguageDropdownWithPersonContext(props: Props) {
  const { person } = useContext(PersonContext)
  if (person) {
    return <LanguageDropdown person={person} {...props} />
  }

  return null
}
