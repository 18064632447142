import {
  IncomingFile,
  ProcessingStatus,
} from '@augusthealth/models/com/august/protos/incoming_file'
import { useEffect, useState } from 'react'
import { FacilityIds } from '@shared/types/facility'
import { Loading } from '@shared/utils/loading'
import { getIncomingFilesInbox } from '@app/api/incoming_files'

export const useIncomingFilesInbox = (facility: FacilityIds) => {
  const [pendingFiles, setPendingFiles] = useState<Loading<IncomingFile[]>>({
    tag: 'Loading',
  })

  const [assignedFiles, setAssignedFiles] = useState<IncomingFile[]>([])
  const [ignoredFiles, setIgnoredFiles] = useState<IncomingFile[]>([])

  const { orgId, id: facilityId } = facility
  const refreshFiles = async () => {
    if (orgId && facilityId) {
      try {
        setPendingFiles({ tag: 'Loading' })
        const files = await getIncomingFilesInbox({
          orgId,
          id: facilityId,
        })

        const incomingFiles = files ?? []
        const pending = incomingFiles.filter(
          (file) =>
            file.processingStatus === ProcessingStatus.PROCESSING_STATUS_INBOX
        )
        const filed = incomingFiles.filter(
          (file) =>
            file.processingStatus === ProcessingStatus.PROCESSING_STATUS_FILED
        )
        const ignored = incomingFiles.filter(
          (file) =>
            file.processingStatus === ProcessingStatus.PROCESSING_STATUS_IGNORED
        )

        setAssignedFiles(filed)
        setIgnoredFiles(ignored)
        setPendingFiles({ tag: 'Complete', value: pending })
      } catch (e) {
        setPendingFiles({ tag: 'Complete', value: [] })
      }
    }
  }

  useEffect(() => {
    void refreshFiles()
  }, [orgId, facilityId])

  return {
    pendingFiles,
    assignedFiles: pendingFiles?.tag === 'Complete' ? assignedFiles : [],
    ignoredFiles: pendingFiles?.tag === 'Complete' ? ignoredFiles : [],
    refreshFiles,
  }
}
