import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import styles from './styles.module.css'

type Props = {
  options: OptionTypeBase<any>[]
  onChange: (newValue: OptionTypeBase<any>[]) => void
  type?: 'Single' | 'Multi'
  chosen?: any[]
  disabled?: boolean
  prependTestId?: string
  containerClassName?: string
  buttonClassName?: string
}

export function ButtonGroup({
  options,
  onChange,
  type = 'Multi',
  chosen = [],
  disabled = false,
  prependTestId,
  containerClassName,
  buttonClassName,
}: Props) {
  const [selected, setSelected] = useState<OptionTypeBase<any>[]>(chosen)
  useEffect(() => {
    onChange(selected)
  }, [selected])

  const containerClasses = classNames(
    styles.dayPicker_container,
    containerClassName
  )

  return (
    <div className={containerClasses}>
      {options.map((o, i) => {
        const buttonClasses = classNames(
          styles.dayPicker_btn,
          {
            [styles.dayPicker_btn_selected]: selectedIncludesOption(
              selected,
              o
            ),
          },
          buttonClassName
        )

        const testId = prependTestId
          ? `${prependTestId}-pp-${o.value}`
          : `pp-${o.value}`

        const isDisabled = o.isDisabled || disabled

        return (
          <button
            disabled={isDisabled}
            data-testid={testId}
            key={`${o.label}-${i}`}
            className={buttonClasses}
            type={'button'}
            onClick={() => {
              if (selectedIncludesOption(selected, o)) {
                if (type === 'Single') {
                  setSelected([])
                } else {
                  setSelected((vs) => vs.filter((v) => v.value !== o.value))
                }
              } else {
                if (type === 'Single') {
                  setSelected([o])
                } else {
                  setSelected((vs) => [...vs, o])
                }
              }
            }}
          >
            {o.label}
          </button>
        )
      })}
    </div>
  )
}

function selectedIncludesOption(
  selected: OptionTypeBase<any>[],
  option: OptionTypeBase<any>
) {
  return selected.some((s) => s.value === option.value)
}
