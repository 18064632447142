import { apiUrl, facilityUrl, personUrl } from '@shared/api/urls'
import { FacilityIds } from '@shared/types/facility'
import { DataType } from '@shared/types/snapshot'

export const getIncomingFilesInboxUrl = (ids: FacilityIds) => {
  return apiUrl(facilityUrl(ids.orgId!, ids.id!), `/incomingFiles`, {})
}

export const getIncomingFileSnapshotUrl = (ids: {
  orgId: string
  facilityId: string
  fileId: string
}) => {
  return apiUrl(
    getIncomingFilesInboxUrl({ orgId: ids.orgId, id: ids.facilityId }),
    `/:incomingFileId/file`,
    {
      incomingFileId: ids.fileId,
    }
  )
}

export const getAssignFileToResidentUrl = (ids: {
  personId: string
  orgId: string
  facilityId: string
  fileId: string
  dataType: DataType
}) => {
  return apiUrl(
    personUrl(ids.orgId, ids.facilityId, ids.personId),
    '/incomingFiles/:incomingFileId/snapshots/:snapshotType',
    {
      incomingFileId: ids.fileId,
      snapshotType: ids.dataType,
    }
  )
}

export const getIncomingFileUrl = (ids: {
  orgId: string
  facilityId: string
  fileId: string
}) => {
  return apiUrl(
    getIncomingFilesInboxUrl({ orgId: ids.orgId, id: ids.facilityId }),
    `/:incomingFileId`,
    {
      incomingFileId: ids.fileId,
    }
  )
}
