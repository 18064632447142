import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { download } from '@shared/api/legacy'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { FeatureFlagNames } from '@shared/constants/feature_flags'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { Person } from '@shared/types/person'
import { tw } from '@shared/utils/tailwind'
import { isFeatureAllowed } from '@shared/utils/user'
import { careSummaryPdfUrl } from '@app/api/routines'
import { DownloadMenu } from '@app/components/DownloadMenu'
import PersonPageTitle from '@app/components/PersonPageTitle'
import { DownloadIndividualCareLogModal } from './DownloadCareLogModal'

export const routineButtonText = 'Add Routine'

export default function RoutinePageTitle({ person }: { person: Person }) {
  const { setError } = useContext(GlobalContext)

  const history = useHistory()
  const [isLoadingPDF, setIsLoadingPDF] = useState(false)
  const { user } = useUserContext()
  const [showCareLogModal, setShowCareLogModal] = useState<boolean>(false)
  const allowCareLogDownload = isFeatureAllowed(user, FeatureFlagNames.CARE_LOG)

  const routineMenuItems = [
    {
      isDisabled: false,
      isVisible: true,
      id: 'careSummary',
      title: 'Care Summary',
      onClick: async () => {
        const fileUrl = careSummaryPdfUrl(person)
        await download({
          fileUrl,
          target: '_blank',
        })
      },
    },
    {
      isDisabled: false,
      isVisible: allowCareLogDownload,
      id: 'careLog',
      title: 'Care Log',
      onClick: async () => {
        setShowCareLogModal(true)
      },
    },
  ]

  return (
    <PersonPageTitle title={'Routines'} withBorder={false}>
      {showCareLogModal && (
        <DownloadIndividualCareLogModal
          user={user}
          person={person}
          onClose={() => setShowCareLogModal(false)}
        />
      )}
      <PersonPermissionGate
        person={person}
        permissions={[GroupPermission.GROUP_PERMISSION_ROUTINE_CREATE]}
      >
        <AsyncIconButton
          className={tw`mr-[12px]`}
          initialIcon={'fa-list-check'}
          buttonStyle={'secondary-fill'}
          onClick={() => history.push('routines/new')}
        >
          {routineButtonText}
        </AsyncIconButton>
      </PersonPermissionGate>
      <PersonPermissionGate
        person={person}
        permissions={[GroupPermission.GROUP_PERMISSION_ROUTINE_READ]}
      >
        {allowCareLogDownload ? (
          <DownloadMenu
            menuProps={{
              backgroundProps: {
                dataCy: 'med-menu-background',
              },
              buttonProps: {
                id: 'medDownloadMenu',
                classNames: 'ml-[12px]',
              },
            }}
            menuItems={routineMenuItems}
          />
        ) : (
          <AsyncIconButton
            initialIcon={'fa-download'}
            buttonStyle={'secondary-fill'}
            isLoading={isLoadingPDF}
            disabled={isLoadingPDF}
            onClick={async () => {
              try {
                setIsLoadingPDF(true)
                const fileUrl = careSummaryPdfUrl(person)
                await download({
                  fileUrl,
                  target: '_blank',
                })
              } catch (e) {
                setError(e)
              } finally {
                setIsLoadingPDF(false)
              }
            }}
          >
            Care Summary
          </AsyncIconButton>
        )}
      </PersonPermissionGate>
    </PersonPageTitle>
  )
}
