// https://app.hellosign.com/api/embeddedRequestingWalkthrough
// https://github.com/hellosign/hellosign-embedded/wiki/API-Documentation-(v2)#events
import { EmbeddedUrlResponse } from '@augusthealth/models/com/august/protos/api/esign'
import HelloSignEmbedded, {
  HelloSign as HelloSignType,
} from 'hellosign-embedded'
import { RefObject, useEffect, useRef } from 'react'
import './style.css'
import ErrorMonitoring from '@shared/ErrorMonitoring'
import { twx } from '@shared/utils/tailwind'

export type HelloSignClient = HelloSignEmbedded & {
  _baseEl: HTMLElement
}

/**
 * Click X icon without HelloSign loading error will trigger onCancel and onClose
 * Click X icon with HelloSign loading error will trigger onClose only
 */
type HelloSignProps = EmbeddedUrlResponse & {
  fullscreen?: boolean
  onCancel: () => void
  onCreateTemplate?: () => void
  onErrorClose?: () => void
  onFinish?: () => void
  onSend?: () => void
  onSign?: () => void
  init?: (client: HelloSignEmbedded) => void
}

const DEFAULT_HELLO_SIGN_OPTIONS: HelloSignType.Options = {
  allowCancel: true,
  hideHeader: false,
  testMode: true,
  debug: true,
  skipDomainVerification: true,
  timeout: 180000,
}

export default function HelloSign({
  clientId,
  url,
  fullscreen = false,
  onCancel,
  onCreateTemplate,
  onErrorClose,
  onFinish,
  onSend,
  onSign,
  init,
}: HelloSignProps) {
  const helloSignRef = useRef<HTMLElement>(null)

  const classNames = twx({
    'w-[80vw] min-w-[512px] h-[90vh] m-auto': fullscreen,
  })

  useEffect(() => {
    let client: HelloSignEmbedded
    if (helloSignRef && clientId && url) {
      const options = DEFAULT_HELLO_SIGN_OPTIONS
      if (fullscreen && helloSignRef.current) {
        options.container = helloSignRef.current
      }

      client = new HelloSignEmbedded({ clientId })
      client.open(url, options)
      client.on('cancel', onCancel)
      if (onCreateTemplate) {
        client.on('createTemplate', () => {
          client.close()
          onCreateTemplate()
        })
      }
      if (onFinish) {
        client.on('finish', () => {
          onFinish()
        })
      }
      if (onSend) {
        client.on('send', () => {
          client.close()
          onSend()
        })
      }
      if (onSign) {
        client.on('sign', () => {
          client.close()
          onSign()
        })
      }
      client.on('error', (errorData) => {
        ErrorMonitoring.capture({
          error: 'HelloSign client error',
          extras: errorData
            ? {
                signatureId: errorData.signatureId,
                code: errorData.code,
              }
            : undefined,
        })

        if (onErrorClose) {
          client.on('close', onErrorClose)
        }
      })

      if (init) {
        // Useful to set CSS class in order to make specific HelloSign form wider, etc.
        init(client)
      }
    }
    return () => client && client.close()
  }, [clientId, url, helloSignRef])

  return (
    <div
      className={classNames}
      ref={helloSignRef as RefObject<HTMLDivElement>}
    />
  )
}
