import {
  DoseNumber,
  Immunization,
} from '@augusthealth/models/com/august/protos/immunization'
import { getTargetDiseaseName } from '@shared/utils/immunizations'

export const doseLabels = {
  [DoseNumber.DOSE_NUMBER_1]: '1st Dose',
  [DoseNumber.DOSE_NUMBER_2]: '2nd Dose',
  [DoseNumber.DOSE_NUMBER_BOOSTER]: 'Booster',
  [DoseNumber.DOSE_NUMBER_BOOSTER_2]: 'Booster',
  [DoseNumber.DOSE_NUMBER_BOOSTER_3]: 'Booster',
  [DoseNumber.DOSE_NUMBER_BOOSTER_4]: 'Booster',
}

export function getImmunizationLabel(immunization: Immunization) {
  const text = getTargetDiseaseName(immunization) || ''

  return `${text} Immunization`
}
