import { isEmpty } from 'lodash'
import {
  PharmacyMessagePayload,
  ProcessPharmacyMessagePayload,
} from '@shared/types/api/pharmacy_message'
import { medicationOrderDescriptor } from '@shared/utils/medicationOrder'

export function createQueueMessage(
  pharmacyMessage: PharmacyMessagePayload
): ProcessPharmacyMessagePayload {
  return {
    processPharmacyMessage: {
      residentIdentifier: pharmacyMessage.messageId.personId,
      external_message_id: pharmacyMessage.messageId.messageId,
      message_id: pharmacyMessage.id,
    },
  }
}

export function pharmacyMessageToLabel(
  message: PharmacyMessagePayload
): string {
  if (!isEmpty(message.pharmacyMessage)) {
    return medicationOrderDescriptor({
      ...message.pharmacyMessage!.medicationOrder!,
      medicationStatement:
        message.pharmacyMessage.medicationOrder!.medicationStatement!,
    })
  } else {
    return message.messageId.messageId
  }
}
