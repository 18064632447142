import { Array, Option, pipe } from 'effect'
import {
  CareAppPerson,
  PersonWithRoutineAdministrations,
  RoutineAdministrationProgressType,
} from '@shared/types/careapp'
import {
  personNameMatchesSearch,
  personRoomMatchesSearch,
} from '@shared/utils/person'
import { administrationIsOneOfSelectedProgressTypes } from '@shared/utils/routineAdministrationProgressType'

export const personMatchesSearchText =
  (searchText: string) => (careAppPerson: CareAppPerson) => {
    const person = {
      name: [careAppPerson.name],
      admissionsInformation: careAppPerson.admissionsInformation,
    }

    return (
      searchText === '' ||
      Boolean(personNameMatchesSearch(person, searchText)) ||
      Boolean(personRoomMatchesSearch(person, searchText))
    )
  }

export function filter({
  peopleWithRoutineAdministrations,
  searchText,
  routineAdministrationProgressTypes,
  facilityTimeZone,
}: {
  peopleWithRoutineAdministrations: PersonWithRoutineAdministrations[]
  searchText: string
  routineAdministrationProgressTypes: RoutineAdministrationProgressType[]
  facilityTimeZone: string
}): PersonWithRoutineAdministrations[] {
  return pipe(
    peopleWithRoutineAdministrations,
    Array.filterMap(({ person, administrations }) => {
      const administrationsMatchingProgressTypes =
        routineAdministrationProgressTypes.length > 0
          ? administrations.filter(
              administrationIsOneOfSelectedProgressTypes(
                routineAdministrationProgressTypes,
                facilityTimeZone
              )
            )
          : administrations

      if (
        personMatchesSearchText(searchText)(person) &&
        administrationsMatchingProgressTypes.length > 0
      ) {
        return Option.some({
          person,
          administrations: administrationsMatchingProgressTypes,
        })
      } else {
        return Option.none()
      }
    })
  )
}
