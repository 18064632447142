import { TaskCreationEvent } from '@shared/types/task'

export function eventName(e: TaskCreationEvent) {
  switch (e) {
    case TaskCreationEvent.TASK_CREATION_EVENT_PROSPECT_CREATED:
      return 'On Prospect Create'
    case TaskCreationEvent.TASK_CREATION_EVENT_RESIDENT_CREATED:
      return 'On Resident Create'
    case TaskCreationEvent.TASK_CREATION_EVENT_UNSPECIFIED:
      return 'Unspecified (??)'
    case TaskCreationEvent.UNRECOGNIZED:
      return 'Unrecognized (??)'
  }
}
