import { HealthcareService_ServiceCategory } from '@augusthealth/models/com/august/protos/healthcare_service'
import { typedMergePatchPerson } from '@shared/api/person'
import { Person } from '@shared/types/person'
import { getAddressFromGeocoder } from '@shared/utils/address'
import { makeTelecom } from '@shared/utils/contactPoint'

interface FormData {
  googlePlace?: google.maps.places.PlaceResult
  hospitalName: string
  hospitalPhone: string
  hospitalFax: string
}

export async function updatePreferredHospital(
  person: Person,
  formData: FormData
) {
  return await typedMergePatchPerson({
    fId: person.facilityId!,
    pId: person.id!,
    orgId: person.orgId!,
    patch: makePatch(person, formData),
    fields: ['hospital'],
  })
}

function makePatch(
  person: Person,
  formData: FormData
): Pick<Person, 'hospital'> {
  const { googlePlace, hospitalName, hospitalPhone, hospitalFax } = formData

  if (googlePlace === undefined) {
    const currentLocations = person.hospital?.location || []
    return {
      hospital: {
        category: [HealthcareService_ServiceCategory.SERVICE_CATEGORY_HOSPITAL],
        name: hospitalName,
        location: currentLocations,
        telecom: makeTelecom(
          person.hospital?.telecom || [],
          hospitalPhone,
          hospitalFax
        ),
      },
    }
  }

  return {
    hospital: {
      category: [HealthcareService_ServiceCategory.SERVICE_CATEGORY_HOSPITAL],
      name: hospitalName,
      location: [
        {
          address: {
            ...getAddressFromGeocoder(googlePlace.address_components || []),
            text: googlePlace.formatted_address,
          },
        },
      ],
      telecom: makeTelecom([], hospitalPhone, hospitalFax),
    },
  }
}
