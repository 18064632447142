import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { fetchPeople } from '@shared/api/person'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { FacilityPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { Person } from '@shared/types/person'
import { tw } from '@shared/utils/tailwind'
import { isSuperUser } from '@shared/utils/user'
import Content from '@app/components/generic/Content'
import PersonPageTitle from '@app/components/PersonPageTitle'
import useOccupancyRooms from '@app/hooks/useOccupancyRooms'
import AddRoomPopup from './AddRoomPopup'
import AssignRoomPopup from './AssignRoomPopup'
import BulkRoomsPopup from './BulkRoomsPopup'
import DeleteRoomPopup from './DeleteRoomPopup'
import EditRoomPopup from './EditRoomPopup'
import OccupancyContext, {
  AssignRoomPopupData,
  DeleteRoomPopupData,
  EditRoomPopupData,
} from './OccupancyContext'
import OccupancyStats from './OccupancyStats'
import RoomTable from './RoomTable'

type FacilityParams = {
  facilityId: string
  orgId: string
}

export type FacilityPageProps = RouteComponentProps<FacilityParams>

export default function Occupancy({ match }: FacilityPageProps) {
  const { user } = useUserContext()
  const { params } = match
  const { orgId, facilityId } = params
  const { setError } = useContext(GlobalContext)
  const [assignRoomPopupData, setAssignRoomPopupData] =
    useState<AssignRoomPopupData>()
  const [deleteRoomPopupData, setDeleteRoomPopupData] =
    useState<DeleteRoomPopupData>()
  const [editRoomPopupData, setEditRoomPopupData] =
    useState<EditRoomPopupData>()
  const [openBulkPopup, setOpenBulkPopup] = useState(false)
  const [openAddPopup, setOpenAddPopup] = useState(false)
  const [people, setPeople] = useState<Person[]>([])
  const { occupancyRooms, refreshOccupancyRooms } = useOccupancyRooms({
    orgId,
    facilityId,
  })

  const loadPeople = () => {
    const fields = [
      'id',
      'orgId',
      'facilityId',
      'name',
      'gender',
      'admissionsInformation',
      'residentStatus',
    ]
    return fetchPeople({ orgId, facilityId, fields })
      .then(setPeople)
      .catch(setError)
  }

  useEffect(() => {
    if (orgId && facilityId) {
      void loadPeople() // For Assign Room dropdown
    }
  }, [orgId, facilityId])

  const addRoomPopup = openAddPopup && (
    <AddRoomPopup
      orgId={orgId}
      facilityId={facilityId}
      onClose={() => setOpenAddPopup(false)}
      onSave={async () => {
        await refreshOccupancyRooms()
        setOpenAddPopup(false)
      }}
    />
  )

  const bulkRoomsPopup = openBulkPopup && (
    <BulkRoomsPopup
      orgId={orgId}
      facilityId={facilityId}
      onClose={() => setOpenBulkPopup(false)}
      onSave={async () => {
        await refreshOccupancyRooms()
        setOpenBulkPopup(false)
      }}
    />
  )

  // Assign, Delete and Edit popups are called in this level
  // to avoid being cut by Sidebar
  const assignRoomPopup = assignRoomPopupData && (
    <AssignRoomPopup
      {...assignRoomPopupData}
      people={people}
      onComplete={async (didUpdate: boolean) => {
        if (didUpdate) {
          await loadPeople()
          await refreshOccupancyRooms()
        }
        setAssignRoomPopupData(undefined)
      }}
    />
  )

  const deleteRoomPopup = deleteRoomPopupData && (
    <DeleteRoomPopup
      {...deleteRoomPopupData}
      onComplete={async (didUpdate: boolean) => {
        if (didUpdate) {
          await refreshOccupancyRooms()
        }
        setDeleteRoomPopupData(undefined)
      }}
    />
  )

  const editRoomPopup = editRoomPopupData && (
    <EditRoomPopup
      {...editRoomPopupData}
      onComplete={async (didUpdate: boolean) => {
        if (didUpdate) {
          await refreshOccupancyRooms()
        }
        setEditRoomPopupData(undefined)
      }}
    />
  )

  return (
    <OccupancyContext.Provider
      value={{
        setAssignRoomPopupData,
        setDeleteRoomPopupData,
        setEditRoomPopupData,
      }}
    >
      <Content className={tw`mt-[32px]`}>
        <PersonPageTitle title="Occupancy" withBorder={false}>
          <FacilityPermissionGate
            facility={{ id: facilityId, orgId }}
            permissions={[GroupPermission.GROUP_PERMISSION_OCCUPANCY_CREATE]}
          >
            <AsyncIconButton
              initialIcon="fa-plus"
              data-cy="addRoomButton"
              buttonStyle="primary-fill"
              onClick={() => setOpenAddPopup(true)}
            >
              Add room
            </AsyncIconButton>
            {isSuperUser(user) && (
              <AsyncIconButton
                className="ml-[8px]"
                initialIcon="fa-rocket-launch"
                buttonStyle="secondary-outline"
                data-cy="bulkAddButton"
                onClick={() => setOpenBulkPopup(true)}
              >
                Bulk add
              </AsyncIconButton>
            )}
          </FacilityPermissionGate>
        </PersonPageTitle>
        <OccupancyStats orgId={orgId} facilityId={facilityId} />
        {occupancyRooms.tag === 'Complete' && (
          <RoomTable
            occupancyRooms={occupancyRooms.value}
            onAdd={() => setOpenAddPopup(true)}
          />
        )}
        {addRoomPopup}
        {assignRoomPopup}
        {bulkRoomsPopup}
        {deleteRoomPopup}
        {editRoomPopup}
      </Content>
    </OccupancyContext.Provider>
  )
}
