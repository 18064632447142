import { UnitOfTime } from '@augusthealth/models/com/august/protos/timing'
import { UseFormReturn } from 'react-hook-form'
import { GroupBase, StylesConfig } from 'react-select'
import {
  baseSelectControlStyles,
  selectStyles,
} from '@shared/components/Selects/utils'
import {
  MedOrderFormData,
  MedOrderFormDose,
} from '@shared/types/medication_order'
import {
  isPrnDosageType,
  isRoutineDosageType,
  isSlidingScaleDosageType,
  isTaperDosageType,
} from '@shared/utils/dosage'
import { CustomPeriodUnits } from '@shared/utils/medicationStatement'

export type SlidingScaleDoseEntry =
  `doses.${number}.slidingScale.entries.${number}`
export const PrimaryDropdownStyleConfig: StylesConfig<
  unknown,
  boolean,
  GroupBase<unknown>
> = {
  ...selectStyles(),
  control: (provided, state) => {
    const disabledStyles = {
      borderColor: 'var(--form-input-border)',
      backgroundColor: 'var(--form-input-disabled-background-color)',
      color: 'var(--form-input-disabled-text-color)',
    }
    const enabledStyles = {
      borderColor: 'var(--primary-border)',
      backgroundColor: 'var(--primary-light)',
      color: 'white',
    }

    const styles = state.isDisabled ? disabledStyles : enabledStyles

    return {
      ...provided,
      ...baseSelectControlStyles('medium', state),
      width: 'fit-content',
      ...styles,
    }
  },
  menu: (provided) => ({
    ...provided,
    width: 'fit-content',
  }),
  input: (provided) => ({
    ...provided,
    color: 'white',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? 'var(--form-input-disabled-text-color)' : 'white',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--form-input-placeholder-color)',
  }),
}

export const getNumberOfTimeInputsToShow = ({
  frequencyCount,
  frequencyType,
  variablePeriodUnit,
  period,
}: {
  frequencyCount: number
  frequencyType?: UnitOfTime | CustomPeriodUnits
  variablePeriodUnit?: UnitOfTime
  period?: number
}) => {
  if (
    variablePeriodUnit === UnitOfTime.UNIT_OF_TIME_HOUR &&
    frequencyType === CustomPeriodUnits.EVERY &&
    period &&
    !isNaN(period) &&
    period > 0 // Prevent division by zero
  ) {
    // calculate inputs to show for 24 hour period, rounding up
    return Math.ceil(24 / period)
  }

  // frequencyCount coming from input can be NaN
  if (isNaN(frequencyCount) || frequencyCount < 0) {
    return 0
  }

  if (frequencyType === undefined && variablePeriodUnit === undefined) {
    return Math.floor(frequencyCount)
  }

  return frequencyType === UnitOfTime.UNIT_OF_TIME_DAY ||
    frequencyType === CustomPeriodUnits.EVERY ||
    frequencyType === CustomPeriodUnits.SPECIFIC_DAYS ||
    variablePeriodUnit === UnitOfTime.UNIT_OF_TIME_DAY
    ? Math.floor(frequencyCount)
    : 1
}

export const updateDosesToMatchDoseUnit = ({
  newUnit,
  methods,
}: {
  newUnit: string
  methods: UseFormReturn<MedOrderFormData>
}): void => {
  const hasCustomPrnMaxDosage = methods.getValues('hasCustomPrnMaxDosage')

  const updatedDoses = [...methods.getValues('doses')]
  updatedDoses.forEach((dose: MedOrderFormDose) => {
    if (dose) {
      if (
        isRoutineDosageType(dose.userSetDosageType) ||
        isTaperDosageType(dose.userSetDosageType)
      ) {
        dose.quantity.unit = newUnit
      } else if (isPrnDosageType(dose.userSetDosageType)) {
        dose.quantity.unit = newUnit
        if (!hasCustomPrnMaxDosage) {
          dose.maxDosage!.unit = newUnit
        }
      } else if (isSlidingScaleDosageType(dose.userSetDosageType)) {
        dose.quantity.unit = newUnit
        dose.slidingScale!.entries = (dose.slidingScale?.entries ?? []).map(
          (entry) => ({
            ...entry,
            doseQuantity: {
              ...entry.doseQuantity,
              unit: newUnit,
            },
          })
        )
      }
    }
  })

  methods.setValue('doses', updatedDoses, { shouldDirty: true })
  return
}

export const updateSlidingScaleDosesToMatchDoseUnit = ({
  newUnit,
  methods,
  doseIndex,
}: {
  newUnit: string
  methods: UseFormReturn<MedOrderFormData>
  doseIndex: number
}): void => {
  const updatedDoses = [...methods.getValues('doses')]
  updatedDoses.forEach((dose: MedOrderFormDose, index: number) => {
    if (dose) {
      if (
        isSlidingScaleDosageType(dose.userSetDosageType) &&
        index === doseIndex
      ) {
        dose.quantity.unit = newUnit
        dose.slidingScale!.entries = (dose.slidingScale?.entries ?? []).map(
          (entry) => ({
            ...entry,
            doseQuantity: {
              ...entry.doseQuantity,
              unit: newUnit,
            },
          })
        )
      }
    }
  })

  methods.setValue('doses', updatedDoses, { shouldDirty: true })
  return
}
