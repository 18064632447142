import { MedPass } from '@augusthealth/models/com/august/protos/api/med_pass'
import { useContext, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { DocumentViewerUploaderFooter } from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import ExpectedErrorModal, {
  Props as PopupUIProps,
} from '@shared/components/ErrorModal/ExpectedErrorModal'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Facility } from '@shared/types/facility'
import { AugustError } from '@shared/utils/error'
import { getErrorMessage, getErrorTitle } from '@shared/utils/medPass'
import { tw } from '@shared/utils/tailwind'
import { deleteMedPasses, updateMedPasses } from '@app/api/med_pass'
import HUD from '@app/components/HUD'
import MedPassItem, { FormData } from './MedPassItem'

export default function MedPassForm({
  facility,
  loadMedPasses,
  medPasses: defaultMedPasses,
  setMedPasses,
}: {
  facility: Facility
  loadMedPasses: () => void
  medPasses: MedPass[]
  setMedPasses: (medPasses: MedPass[]) => void
}) {
  const { setError } = useContext(GlobalContext)
  const [expectedErrorData, setExpectedErrorData] = useState<
    Omit<PopupUIProps, 'buttonProps' | 'id'> | undefined
  >(undefined)
  const [showSaveNotice, setShowSaveNotice] = useState(false)
  const { orgId, id: facilityId, name: facilityName } = facility
  const useFormReturn = useForm<FormData>()
  const { control, formState, handleSubmit, reset, setValue } = useFormReturn
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'medPasses',
  })
  const { isDirty } = formState
  const save = async (formData: FormData) => {
    const { medPasses: updatedMedPasses } = formData
    try {
      if (updatedMedPasses.length) {
        const newMesPasses = await updateMedPasses({
          orgId,
          facilityId,
          medPasses: updatedMedPasses.map((pass) => {
            const clonedPass = { ...pass }
            if (clonedPass.id === '') {
              delete clonedPass.id
            }

            return clonedPass
          }) as MedPass[],
        })
        setMedPasses(newMesPasses)
        reset({ medPasses: newMesPasses })
      } else {
        await deleteMedPasses(facility)
        loadMedPasses()
        reset({ medPasses: [] })
      }

      setShowSaveNotice(true)
    } catch (err) {
      const { status, json } = err as AugustError
      // Temporary display MedPasses error to client
      if (status === 400 && json?.errors && json.errors.length) {
        const { message } = json.errors[0]
        if (message) {
          const expectedErrorTitle = getErrorTitle(message)
          if (expectedErrorTitle) {
            setExpectedErrorData({
              children: getErrorMessage(message),
              title: expectedErrorTitle,
            })
            return
          }
        }
      }

      setError(err)
    }
  }

  useEffect(() => {
    setValue('medPasses', defaultMedPasses)
  }, [defaultMedPasses])

  return (
    <section className={tw`pl-10`}>
      <h2 className={tw`mb-[16px] flex-1 text-[16px] leading-[18px]`}>
        Med Passes for {facilityName}
      </h2>
      <hr className={tw`mb-[16px]`} />
      {fields.length === 0 && <div>Record not found</div>}
      <form onSubmit={handleSubmit(save)}>
        <ol className="list-decimal">
          {fields.map((f, i) => {
            return (
              <li key={`medPass-li-${f.id}`} className={tw`mb-[16px]`}>
                <MedPassItem
                  index={i}
                  useFormReturn={useFormReturn}
                  onDelete={() => remove(i)}
                />
              </li>
            )
          })}
        </ol>
        <div className={tw`mb-[16px] text-right`}>
          <Button
            buttonStyle="primary-fill"
            buttonSize="xsmall"
            onClick={() => append({ facilityId })}
          >
            <i className={tw`fa fa-plus mr-[8px]`} />
            Add New Med Pass
          </Button>
        </div>
        <hr />
        <div className={tw`mt-[16px] flex`}>
          <DocumentViewerUploaderFooter
            yesBtn={{
              props: { disabled: !isDirty },
            }}
            noBtn={{
              label: 'Reset',
              action: () => setValue('medPasses', defaultMedPasses),
            }}
            formState={formState}
          />
        </div>
      </form>
      {showSaveNotice && (
        <HUD
          onExpire={() => setShowSaveNotice(false)}
          className={tw`animate-fade-in`}
        >
          Medpass Configuration Saved
        </HUD>
      )}
      {expectedErrorData && (
        <ExpectedErrorModal
          id={'med-pass-config-error'}
          buttonProps={{
            children: 'Close',
            onClick: () => setExpectedErrorData(undefined),
          }}
          {...expectedErrorData}
        />
      )}
    </section>
  )
}
