import {
  SignableFormAssignment,
  SignableFormMetaData,
} from '@augusthealth/models/com/august/protos/signable_form'
import { useState } from 'react'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { ToolPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { Facility } from '@shared/types/facility'
import { OrgFacilitySettings } from '@shared/types/org_facility_settings'
import { Organization } from '@shared/types/organization'
import { GroupPermission } from '@shared/types/permission'
import {
  findScheduledTaskForTaskDefinition,
  sortTaskDefinitions,
} from '@shared/utils/orgFacilitySettings'
import TaskDefinitionModal from '@app/pages/Tools/TaskDefinitions/TaskDefinitionModal'
import TaskCard from '../TaskCard'
import { SuppressedTaskCard } from '../TaskCard/SuppressedTaskCard'

type Props = {
  allAssignments: SignableFormAssignment[]
  formList: SignableFormMetaData[]
  facility: Facility
  loadAssignments: () => Promise<any>
  onTaskDefinitionChange: () => Promise<void>
  org: Organization
  taskDefinitions: OrgFacilitySettings[]
  scheduledTaskSettings: OrgFacilitySettings[]
}

export default function FacilityTasks({
  allAssignments,
  formList,
  facility,
  loadAssignments,
  onTaskDefinitionChange,
  org,
  taskDefinitions,
  scheduledTaskSettings,
}: Props) {
  const [showAssignTask, setShowAssignTask] = useState(false)
  const { id: facilityId, orgId, name, address } = facility

  return (
    <div>
      <div className="mb-[16px] mt-[48px] flex items-center justify-between">
        <span className="group-title mb-0">
          {name} ({address?.state})
        </span>
        <ToolPermissionGate
          permissions={[GroupPermission.GROUP_PERMISSION_TOOL_SETTINGS_CREATE]}
        >
          <AsyncIconButton
            initialIcon="fa-plus"
            buttonStyle="primary-fill"
            buttonSize="small"
            onClick={() => setShowAssignTask(true)}
          >
            Add Facility Task
          </AsyncIconButton>
        </ToolPermissionGate>
      </div>
      {sortTaskDefinitions(taskDefinitions).map((ts) => {
        const task = ts.settings!.taskDefinition!

        if (!task.template) {
          return (
            <SuppressedTaskCard
              key={`facility-${ts.key}`}
              taskSetting={ts}
              reloadTaskSettings={async () => {
                await onTaskDefinitionChange()
              }}
            />
          )
        }
        const { taskType, customType } = task.template!

        return (
          <TaskCard
            facility={facility}
            org={org}
            cardLevel={{
              tag: 'Facility',
              orgId: orgId!,
              facilityId: facilityId!,
            }}
            allAssignments={allAssignments}
            key={`${taskType}${customType || ''}-${facilityId}-task`}
            formList={formList}
            name={facility.name!}
            taskSettings={ts}
            scheduledTask={findScheduledTaskForTaskDefinition({
              cardLevel: {
                tag: 'Facility',
                facilityId: facilityId!,
                orgId: orgId!,
              },
              scheduledTasks: scheduledTaskSettings,
              taskDefinition: ts,
            })}
            loadAssignments={loadAssignments}
            onTaskDefinitionChange={async () => {
              await onTaskDefinitionChange()
            }}
            onScheduledTaskChange={async () => {
              await onTaskDefinitionChange()
            }}
          />
        )
      })}
      {showAssignTask && (
        <TaskDefinitionModal
          name={name!}
          cardLevel={{
            tag: 'Facility',
            orgId: orgId!,
            facilityId: facilityId!,
          }}
          onClose={async (updated) => {
            if (updated) {
              await onTaskDefinitionChange()
            }
            setShowAssignTask(false)
          }}
        />
      )}
    </div>
  )
}
