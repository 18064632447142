import { SignatureConfiguration } from '@augusthealth/models/com/august/protos/signable_form'
import { isEqual } from 'lodash'
import { useContext } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import { SmallDotDivider } from '@shared/components/DotDivider'
import CurrentFacilityContext, {
  TimeZoneConvertFunc,
} from '@shared/contexts/CurrentFacilityContext'
import { Person } from '@shared/types/person'
import { Signer, SignerRole } from '@shared/types/snapshot'
import { SignatureInfo, SignatureStatus, Task } from '@shared/types/task'
import { UserAccount } from '@shared/types/user'
import { getFullName } from '@shared/utils/humanName'
import {
  extractTemplateSigners,
  getTemplateSigner,
} from '@shared/utils/signableForm'
import { signaturesAreProcessing } from '@shared/utils/signature'
import { nextSigner } from '@shared/utils/task'
import { isAdmin } from '@shared/utils/user'
import { getSignatureLabel } from '@app/components/Prospects/Forms/sharable/PdfPopup/helpers'
import { replaceSignerPreservingRoleIndex } from '@app/components/SignatureFlow/helpers'
import PersonContext from '@app/contexts/PersonContext'
import TaskTemplateContext from '@app/contexts/TaskTemplateContext'
import ChangeSigners from '../ChangeSigners'
import { SignerRow } from '../Components'

export function Signatures({
  user,
  currentSigners,
  setCurrentSigners,
  task,
  allowToShowSignInPerson,
  disableChangeSigner,
  onSignInPersonComplete,
}: {
  user: UserAccount
  currentSigners: SignatureInfo[]
  setCurrentSigners: React.Dispatch<React.SetStateAction<SignatureInfo[]>>
  task: Task
  allowToShowSignInPerson: boolean
  disableChangeSigner: boolean
  onSignInPersonComplete: () => void
}) {
  const { convertToFacilityTimezone } = useContext(CurrentFacilityContext)
  const { person, setSignInPersonPopupTask } = useContext(PersonContext)
  const { facilityId, orgId } = person || {}
  const userIsAdmin = isAdmin(user, { organizationId: orgId, facilityId })

  const canChangeSigners = (sigInfo: SignatureInfo) =>
    userIsAdmin &&
    sigInfo.status === SignatureStatus.SIGNATURE_STATUS_NOT_SIGNED &&
    sigInfo.signer?.role === SignerRole.SIGNER_ROLE_ADMIN
  const nextSignerIsRpOrResident = (signer: Signer | undefined) =>
    !signer?.signedAt &&
    (signer?.role === SignerRole.SIGNER_ROLE_RESPONSIBLE_PARTY ||
      signer?.role === SignerRole.SIGNER_ROLE_RESIDENT) &&
    isEqual(nextSigner(task), signer)

  function nextSignerIsExternalBlock(signer: Signer | undefined): boolean {
    return (
      !signer?.signedAt &&
      signer?.role === SignerRole.SIGNER_ROLE_EXTERNAL &&
      nextSigner(task)?.role === SignerRole.SIGNER_ROLE_EXTERNAL
    )
  }

  const showSignInPersonLink = (signer: Signer | undefined) =>
    allowToShowSignInPerson &&
    (nextSignerIsRpOrResident(signer) || nextSignerIsExternalBlock(signer))

  const { signableForm } = useContext(TaskTemplateContext)
  const templateSigners = extractTemplateSigners({ template: signableForm })

  return (
    <>
      {(currentSigners ?? []).map((sig, ix) => {
        const { signer } = sig

        const icon =
          sig.status === SignatureStatus.SIGNATURE_STATUS_SIGNED
            ? 'Signed'
            : 'Waiting for signature'

        const templateSigner: SignatureConfiguration | undefined =
          getTemplateSigner(templateSigners, signer!)

        if (canChangeSigners(sig)) {
          return (
            <div key={`s-${ix}`} className={'mr-[32px] flex items-center'}>
              <ChangeSigners
                disableChangeSigner={disableChangeSigner}
                signerIndex={ix}
                person={person as Required<Person>}
                task={task}
                currentSigner={sig}
                setSigner={(newSigner) =>
                  setCurrentSigners((previous) =>
                    replaceSignerPreservingRoleIndex({
                      previous,
                      newSigner,
                      ix,
                    })
                  )
                }
                currentSigners={currentSigners}
                subTitle={
                  <SignerDescriptionAndLabel
                    templateSigner={templateSigner}
                    signer={sig.signer!}
                  />
                }
              />
            </div>
          )
        }

        return (
          <SignerRow
            key={`s-${ix}`}
            id={`signer-${ix}`}
            title={
              <>
                {getFullName(signer!.name!)}
                {showSignInPersonLink(signer) && (
                  <>
                    <SmallDotDivider />
                    <ButtonLink
                      onClick={() =>
                        setSignInPersonPopupTask({
                          task,
                          onComplete: onSignInPersonComplete,
                          email: signer!.email!,
                        })
                      }
                      disabled={
                        disableChangeSigner || signaturesAreProcessing(task)
                      }
                    >
                      Sign in-person now
                    </ButtonLink>
                  </>
                )}
              </>
            }
            subTitle={
              <SignerDescriptionAndLabel
                templateSigner={templateSigner}
                signer={sig.signer!}
                convertToFacilityTimezone={convertToFacilityTimezone}
              />
            }
            state={icon}
          />
        )
      })}
    </>
  )
}

function SignerDescriptionAndLabel({
  templateSigner,
  signer,
  convertToFacilityTimezone,
}: {
  templateSigner: SignatureConfiguration | undefined
  signer: Signer
  convertToFacilityTimezone?: TimeZoneConvertFunc
}) {
  return (
    <>
      {templateSigner?.description && (
        <>
          <span
            data-testid={`signer-description-${templateSigner.signerRole}-${templateSigner.roleIndex}`}
          >
            {templateSigner.description}
          </span>
          <SmallDotDivider />
        </>
      )}
      {getSignatureLabel(signer, convertToFacilityTimezone)}
    </>
  )
}
