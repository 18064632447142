import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import LoadingPopup from '@shared/components/LoadingPopup'
import SignatureFlow from '@app/components/SignatureFlow'
import PersonContext from '@app/contexts/PersonContext'
import TaskTemplateContext from '@app/contexts/TaskTemplateContext'
import useTemplate from '@app/hooks/useTemplate'
import { FormPageProps } from '../FormLayout/type'

export default function OnlySignForm(props: FormPageProps) {
  const history = useHistory()
  const { taskId } = props.match.params
  const { person, tasks = [] } = useContext(PersonContext)
  const task = tasks.find((t) => t.id === taskId)

  if (!task || !person) {
    return <LoadingPopup loading />
  }

  const template = useTemplate({
    dataType: task.taskTemplateInfo!.dataType!,
    person,
    customType: task.taskTemplateInfo!.customType,
  })

  return (
    <TaskTemplateContext.Provider value={{ signableForm: template }}>
      <SignatureFlow task={task!} person={person!} onClose={history.goBack} />
    </TaskTemplateContext.Provider>
  )
}
