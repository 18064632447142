import { AppraisalStatus } from '@augusthealth/models/com/august/protos/august_initial_appraisal'
import { ServicePlan_PlanCategoryKey } from '@augusthealth/models/com/august/protos/service_plan'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { CategoryKeyIdentifier } from '@shared/types/assessment_configuration'
import { Person } from '@shared/types/person'
import {
  getCategoryTitle,
  getMatchingAssessmentCategory,
} from '@shared/utils/residentAssessment'
import { patchResidentAssessment } from '@app/api/resident_assessment'
import CheckboxSections from '../Tasks/ResidentAssessment/AssessmentPage/CheckboxSections'
import Needs from '../Tasks/ResidentAssessment/AssessmentPage/Needs'
import NeedsHeader from '../Tasks/ResidentAssessment/AssessmentPage/NeedsHeader'
import Notes from '../Tasks/ResidentAssessment/AssessmentPage/Notes'
import { AssessmentChange } from '../Tasks/ResidentAssessment/AssessmentPage/types'
import { buildPatchFromChanges } from '../Tasks/ResidentAssessment/helpers'

export function EditAssessmentCategory({
  categoryKey,
  person,
  onComplete,
  assessment,
}: {
  categoryKey: ServicePlan_PlanCategoryKey | CategoryKeyIdentifier // TODO: make routines use categoryKeyIdentifier and update this type
  person: Person
  onComplete: (updated: boolean) => Promise<void>
  assessment: AppraisalStatus
}) {
  const [changes, setChanges] = useState<AssessmentChange[]>([])
  const { handleSubmit, formState } = useForm()

  const category = getMatchingAssessmentCategory({
    categoryKey,
    categories: assessment.appraisal?.settings?.categories,
  })

  if (category === undefined) {
    return null
  }

  const categoryTitle = getCategoryTitle(category)

  const onSubmit = async () => {
    const updatedAppraisal = buildPatchFromChanges({
      changes,
      initialAssessment: assessment.appraisal ?? {},
    })
    await patchResidentAssessment({ person, patch: updatedAppraisal })
    await onComplete(true)
  }

  return (
    <AnimatedPopup title={categoryTitle}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <NeedsHeader
          category={category}
          mode={{ tag: 'view' }}
          person={person}
        />
        <Needs
          category={category}
          disabled={false}
          onChange={async (change) => {
            setChanges((previous) => [...previous, change])
          }}
          assessment={assessment.appraisal}
        />
        <CheckboxSections
          person={person}
          mode={'edit-sans-august-fields'}
          category={category}
          disabled={false}
          onChange={(change) => {
            setChanges((previous) => [...previous, change])
          }}
          assessment={assessment.appraisal}
        />
        <Notes
          mode={'edit'}
          assessment={assessment.appraisal}
          category={category}
          disabled={false}
          onChange={(change) => {
            setChanges((previous) => [...previous, change])
          }}
        />
        <div>
          <AnimatedPopupFormFooter
            yesBtn={{}}
            noBtn={{ action: () => void onComplete(false) }}
            formState={formState}
          />
        </div>
      </form>
    </AnimatedPopup>
  )
}
