import classNames from 'classnames'
import styles from '@app/components/Residents/Medications/MedicationSpecialtyBadges/styles.module.css'

type Props = {
  text: string
  onClick: (e: React.FormEvent<HTMLInputElement>) => void
  selected: boolean
  readOnly?: boolean
}
export const SpecialtyBadge = ({
  text,
  onClick,
  selected,
  readOnly = false,
}: Props) => {
  const classes = classNames(styles.specialtyBadge, {
    [styles.selected]: selected,
    [styles.readOnly]: readOnly,
  })

  return (
    <label className={classes} htmlFor={text}>
      {text}
      <input
        onChange={(e) => {
          if (!readOnly) {
            onClick(e)
          }
        }}
        readOnly={readOnly}
        type="checkbox"
        name={text}
        id={text}
        checked={selected ?? false}
      />
    </label>
  )
}
