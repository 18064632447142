import { useState } from 'react'
import { UserFormData } from '@shared/components/Auth/LoginWithUsernameOrEmail/PasswordField/types'
import { BasicInputProps } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput } from '@shared/components/Labels'
import TextInputWithIcon from '@shared/components/TextInputWithIcon/TextInputWithIcon'

/**
 * Always disabled but can be reset via cta
 * Use props.onReset to set cta visibility
 */

export default function MultiFactorAuthenticationInput(props: {
  onReset: () => void
  disabled?: boolean
  user: UserFormData
  inputProps: BasicInputProps
  phoneNumber?: string | null
}) {
  const [isResetting, setIsResetting] = useState<boolean>(false)
  const { onReset, user, disabled = false, inputProps } = props
  if (!(user.mfaEnabled && user.phoneNumber)) {
    return null
  }

  return (
    <div className="mb-[32px]">
      <div className="flex">
        <LabelAboveInput
          htmlFor={'resetMfa'}
          ctaProps={
            !disabled
              ? {
                  text: 'Reset Phone Number',
                  onClick: () => {
                    setIsResetting(true)
                    onReset()
                  },
                }
              : undefined
          }
        >
          Multi-Factor Authentication
        </LabelAboveInput>
      </div>
      <TextInputWithIcon
        inputProps={{
          ...inputProps,
          placeholder: '(555) 555-5555',
          disabled: true,
          value: isResetting ? '[Reset]' : user.phoneNumber,
        }}
        iconClassName="fas fa-phone"
      />
    </div>
  )
}
