import { DropdownIndicatorProps } from 'react-select'
import { twx } from '@shared/utils/tailwind'

export function XLDropdownIndicator(props: DropdownIndicatorProps) {
  const classes = twx('fas fa-solid fa-caret-down text-[16px] leading-[24px]', {
    'text-secondary-07': props.isDisabled,
    'text-gray-03': !props.isDisabled,
    'visually-hidden': props.options.length === 0 || props.hasValue,
    'mr-[10px]': !props.hasValue,
  })

  return <i className={twx(classes)} />
}
