import {
  SignableForm,
  SignableFormWithAssignments,
} from '@augusthealth/models/com/august/protos/signable_form'
import { useContext, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { publishForm } from '@app/api/form'
import ConfirmModal from '@app/components/ConfirmModal'
import HUD from '@app/components/HUD'

type Props = {
  form: SignableForm
  onClose: () => void
  afterPublish: (updatedForm: SignableFormWithAssignments) => void
}

export default function PublishConfirmPopup({
  form,
  onClose,
  afterPublish,
}: Props) {
  const [updatedForm, setUpdatedForm] = useState<
    SignableFormWithAssignments | undefined
  >(undefined)
  const { setError } = useContext(GlobalContext)
  const { id, name } = form
  const onConfirm = async () => {
    try {
      if (id) {
        const updatedF = await publishForm({ id })
        setUpdatedForm(updatedF)
      }
    } catch (e) {
      setError(e)
    }
  }
  const onExpire = () => {
    onClose()
    if (updatedForm) {
      afterPublish(updatedForm)
    }
  }

  if (updatedForm) {
    return <HUD onExpire={onExpire}>Template {name} published</HUD>
  }

  return (
    <ConfirmModal
      title={`Are you sure that you want to publish ${name}?`}
      confirmButtonConfig={{
        children: 'Publish',
        buttonStyle: 'primary-fill',
        onClick: onConfirm,
      }}
      denyButtonConfig={{
        onClick: onClose,
      }}
    />
  )
}
