import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { UserSessionLogoutReason } from '@augusthealth/models/com/august/protos/user_session'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { logout } from '@shared/components/Auth/Auth'
import ButtonLink from '@shared/components/ButtonLink'
import { INTERCOM_LAUNCHER_ID } from '@shared/components/Intercom'
import { FeatureFlagNames } from '@shared/constants/feature_flags'
import { extractIds } from '@shared/hooks/useCurrentPage'
import useLocalStorage from '@shared/hooks/useLocalStorage'
import {
  getAccountPreferencesUrl,
  getOrgListUrl,
  getOrgSettingsUrl,
} from '@shared/legacy_routes'
import {
  careFacilityAdministrationsPath,
  emarOrgFacilityPassmedsPath,
} from '@shared/routes'
import { UserAccount } from '@shared/types/user'
import { hasAccessToCareApp } from '@shared/utils/careapp'
import { hasAccessToEmar, resetEmar } from '@shared/utils/emar'
import { getFullName } from '@shared/utils/humanName'
import { getOrElse } from '@shared/utils/loading'
import {
  belongsToMultipleOrgs,
  hasEmail,
  isDirector,
  isFacilityUserManager,
  isFeatureAllowed,
  isOrgAdmin,
  isOrgUserManager,
  isSuperUser,
} from '@shared/utils/user'
import styles from './user-settings.module.css'
import SidebarContentsContext from '../SidebarContentsContext'

export default function UserSettings({
  closeAllMenus,
  showUserSettings,
  setShowUserSettings,
  setShowPermissionsModal,
  user,
}: {
  closeAllMenus: () => void
  showUserSettings: boolean
  setShowUserSettings: React.Dispatch<React.SetStateAction<boolean>>
  setShowPermissionsModal: (show: boolean) => void
  user: UserAccount
}) {
  const { currentFacility, currentPage } = useContext(SidebarContentsContext)
  const { orgId } = extractIds(currentPage)
  const showOrgSettings =
    isSuperUser(user) ||
    (orgId && isOrgAdmin({ user, orgId })) ||
    (orgId && isDirector({ user, orgId })) ||
    (orgId && isOrgUserManager({ user, orgId })) ||
    (orgId && isFacilityUserManager({ user, orgId }))
  const showOrgsLink = belongsToMultipleOrgs(user) || isSuperUser(user)
  const canSeeEmarLink =
    currentFacility && hasAccessToEmar({ user, facility: currentFacility })
  const canSeeCareTrackLink =
    currentFacility && hasAccessToCareApp({ user, facility: currentFacility })
  const canResetEmar =
    isFeatureAllowed(user, FeatureFlagNames.RESET_EMAR) && canSeeEmarLink

  const [storedSuppressedPermissions] = useLocalStorage<GroupPermission[]>(
    'suppressedPermissions',
    []
  )
  const suppressedPermissions = getOrElse(storedSuppressedPermissions, [])

  const showAccountPreferences =
    currentFacility?.orgId &&
    currentFacility.id &&
    hasEmail(user) &&
    !isOrgUserManager({ user, orgId: orgId! }) &&
    !isFacilityUserManager({ user, orgId: orgId! })

  return (
    <div className={styles.settingsContainer}>
      <div
        id={INTERCOM_LAUNCHER_ID}
        className={`${styles.userSettings} ${styles.supportSettings}`}
      >
        <img src="/svg/support.svg" alt="" className={styles.userImage} />
        Help &amp; Support
      </div>
      {canSeeCareTrackLink &&
        orgId &&
        currentFacility &&
        currentFacility.id && (
          <a
            className={styles.emarLink}
            href={careFacilityAdministrationsPath({
              orgId,
              facilityId: currentFacility.id,
            })}
            data-testid={'link-to-care-track'}
          >
            <div className={styles.careTrackIcon}>
              <i className="far fa-solid fa-clipboard" />
            </div>
            Care Track
          </a>
        )}
      {canSeeEmarLink && orgId && currentFacility && currentFacility.id && (
        <a
          className={styles.emarLink}
          href={emarOrgFacilityPassmedsPath(orgId, currentFacility.id)}
          data-testid={'link-to-emar'}
        >
          <div className={styles.emarIcon}>
            <i className="far fa-solid fa-heart" />
          </div>
          eMAR
        </a>
      )}
      <div
        className={
          showUserSettings
            ? `${styles.userSettingsActive} ${styles.userSettings}`
            : styles.userSettings
        }
        onClick={() => {
          closeAllMenus()
          setShowUserSettings(!showUserSettings)
        }}
      >
        <img src="/svg/avatar--user.svg" alt="" className={styles.userImage} />
        {user?.name ? getFullName(user.name) : 'August Health User'}
        {showUserSettings && (
          <ul className={styles.userSettingsMenu}>
            <li className={styles.userSettingsLink}>
              <button
                className={styles.linkBorder}
                onClick={() =>
                  logout(
                    UserSessionLogoutReason.USER_SESSION_LOGOUT_REASON_EXPLICIT
                  )
                }
              >
                <i className="far fa-fw fa-sign-out text-[#bfbfbf]" />
                Log Out
              </button>
            </li>
            {showOrgsLink && (
              <li className={styles.userSettingsLink}>
                <Link className={styles.linkBorder} to={getOrgListUrl()}>
                  <i className="far fa-fw fa-sitemap" />
                  View Organizations
                </Link>
              </li>
            )}
            {showOrgSettings && orgId && (
              <li className={styles.userSettingsLink}>
                <Link
                  className={styles.linkBorder}
                  to={getOrgSettingsUrl(orgId)}
                >
                  <i className="far fa-fw fa-buildings" />
                  Org Settings
                </Link>
              </li>
            )}
            {showAccountPreferences && (
              <li className={styles.userSettingsLink}>
                <Link
                  data-testid="account-preferences"
                  className={styles.linkBorder}
                  to={getAccountPreferencesUrl(
                    currentFacility.orgId,
                    currentFacility.id
                  )}
                >
                  <i className="far fa-fw fa-id-card-clip" />
                  Account Preferences
                </Link>
              </li>
            )}
            {canResetEmar && (
              <li className={styles.userSettingsLink}>
                <button
                  data-testid={'reset-emar'}
                  className={styles.linkBorder}
                  onClick={() => resetEmar()}
                >
                  <i className="far fa-fw fa-warning text-[#bfbfbf]" />
                  Reset eMAR
                </button>
              </li>
            )}
          </ul>
        )}
      </div>
      {suppressedPermissions?.length > 0 && (
        <div className={`${styles.userSettings} ${styles.supportSettings}`}>
          <div className="flex flex-col">
            <span>
              <img
                src="/svg/guy-fawkes-color.svg"
                className={styles.userImage}
              />
              Suppressed Permissions
            </span>
            <p className="mt-[8px]">
              Press "Ctrl+/" or{' '}
              <ButtonLink
                className="text-alert"
                onClick={() => setShowPermissionsModal(true)}
              >
                click here
              </ButtonLink>{' '}
              to edit
            </p>
          </div>
        </div>
      )}
    </div>
  )
}
