const cache: Record<string, string> = {}

function key(urlStr: string): string {
  // The key is the path plus the value of the 'fields' url parameter
  // We don't key on the entire literal url string, because there's a case
  // where we need to ignore a url parameter.
  // Specifically, we can GET snapshots with '?fields=foo,bar&create=true' but PATCH with just '?fields=foo,bar'.
  // That is, we want to ignore the 'create=true' in the query
  const url = new URL(urlStr)
  const query = Object.fromEntries(
    url.search
      .replace('?', '') // remove leading '?'
      .split('&')
      .map((x) => x.split('='))
  ) as Record<string, string>

  return `${url.pathname}?${query.fields || ''}&${query.customType || ''}`
}

export function cacheETag(url: string, eTag: string): void {
  const k = key(url)
  cache[k] = eTag
}

export function getETag(url: string): string {
  const eTag = cache[key(url)]
  if (!eTag) {
    console.warn(
      `Not protected against concurrent edits! No ETag found for ${url}`
    )
  }
  return eTag
}
