import { CompletionType } from '@shared/types/api/facility_stats'
import { DataType } from '@shared/types/snapshot'
import { TaskTemplateInfo } from '@shared/types/task'

const BASE_COMPLETION_TYPE_LIST = [
  CompletionType.COMPLETION_TYPE_HAS_PRIMARY_DR,
  CompletionType.COMPLETION_TYPE_HAS_MEDICATIONS,
  CompletionType.COMPLETION_TYPE_HAS_AMBULATORY_STATUS,
  CompletionType.COMPLETION_TYPE_HAS_RESPONSIBLE_PERSON,
  CompletionType.COMPLETION_TYPE_HAS_DIET,
  CompletionType.COMPLETION_TYPE_HAS_BILLING,
]

const DATA_TYPE_TO_COMPLETION_TYPE_MAP = Object.freeze({
  [DataType.DATA_TYPE_CA_FORM_602]: CompletionType.COMPLETION_TYPE_HAS_602,
  [DataType.DATA_TYPE_POLST]: CompletionType.COMPLETION_TYPE_HAS_POLST,
  [DataType.DATA_TYPE_AUGUST_INITIAL_APPRAISAL]:
    CompletionType.COMPLETION_TYPE_HAS_APPRAISAL,
  [DataType.DATA_TYPE_SERVICE_PLAN]:
    CompletionType.COMPLETION_TYPE_HAS_SERVICE_PLAN,
})

type CompletionTypeWithCorrespondingDataType = Partial<
  keyof typeof DATA_TYPE_TO_COMPLETION_TYPE_MAP
>

export type CompletionTypeToTemplateHash = Record<
  CompletionTypeWithCorrespondingDataType,
  TaskTemplateInfo
>

/**
 * Filter-out some CompletionType when no corresponding Template found
 */
export function getCompletionTypeListAndTemplateHash(
  taskTemplateInfoList: TaskTemplateInfo[]
) {
  const completionTypeList = [...BASE_COMPLETION_TYPE_LIST]
  const templateHash = {} as CompletionTypeToTemplateHash
  taskTemplateInfoList.forEach((t) => {
    const completionType =
      t.dataType &&
      (DATA_TYPE_TO_COMPLETION_TYPE_MAP[t.dataType] as CompletionType)
    if (completionType) {
      completionTypeList.push(completionType)
      templateHash[completionType] = t
    }
  })

  return {
    completionTypeList,
    templateHash,
  }
}

export function getLabelForCompletionType({
  completionType,
  completionTypeToTemplateHash,
  withMoreDetails = false,
}: {
  completionType: CompletionType
  completionTypeToTemplateHash: CompletionTypeToTemplateHash
  withMoreDetails?: boolean
}): string {
  const template = completionTypeToTemplateHash[completionType] as
    | TaskTemplateInfo
    | undefined
  if (template?.displayName) {
    let displayName = template.displayName
    if (template.shortName && withMoreDetails) {
      displayName += ` (${template.shortName})`
    }
    return displayName
  }

  switch (completionType) {
    case CompletionType.COMPLETION_TYPE_HAS_PRIMARY_DR:
      return withMoreDetails ? 'Primary Care Physician' : 'Primary Physician'
    case CompletionType.COMPLETION_TYPE_HAS_MEDICATIONS:
      return 'Medications'
    case CompletionType.COMPLETION_TYPE_HAS_AMBULATORY_STATUS:
      return 'Ambulatory Status'
    case CompletionType.COMPLETION_TYPE_HAS_RESPONSIBLE_PERSON:
      return 'Responsible Person'
    case CompletionType.COMPLETION_TYPE_HAS_DIET:
      return 'Diet'
    case CompletionType.COMPLETION_TYPE_HAS_BILLING:
      return 'Billing'
    case CompletionType.COMPLETION_TYPE_HAS_PROFILE_PHOTO:
      return 'Resident Picture'
    default:
      return 'Unknown'
  }
}
