import { useRef, useState } from 'react'
import { UrlAndContentType } from '@shared/api/request'
import Icon from '@shared/components/Icon'
import { AsyncResult } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import readerStyles from '@app/components/generic/PDFReader/styles.module.css'
import styles from './styles.module.css'
import { SoftDeletable } from '../index'

interface Props {
  blobData: AsyncResult<UrlAndContentType, any> | null
  softDeletable?: SoftDeletable
  showSmallPrintButton?: boolean
  showSmallDownloadButton?: boolean
  showSmallRotateButton?: boolean
}

export default function DocumentPreviewFloatingActions({
  blobData,
  softDeletable,
  showSmallPrintButton = false,
  showSmallDownloadButton = false,
  showSmallRotateButton = false,
}: Props) {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [rotation, setRotation] = useState(90)
  const fileLoaded = blobData?.tag === 'Complete'
  const fileUrl = fileLoaded ? blobData.value.url : ''

  return (
    <>
      <div className={styles.previewButtonsContainer}>
        {fileLoaded && showSmallRotateButton && (
          <RotateButton rotation={rotation} setRotation={setRotation} />
        )}
        {!!softDeletable && (
          <RemoveButton handleOnClick={softDeletable.callbackFn} />
        )}
        {showSmallPrintButton && (
          <SmallPrintButton
            handleOnClick={() => {
              iframeRef.current?.contentWindow?.print()
            }}
          />
        )}
        {showSmallDownloadButton && fileUrl && (
          <SmallDownloadButton
            href={fileUrl}
            fileName={(fileLoaded && blobData.value.filename) || ''}
          />
        )}
      </div>
      {blobData && showSmallPrintButton && (
        <iframe
          ref={iframeRef}
          style={{ visibility: 'hidden', display: 'none' }}
          src={fileUrl}
        />
      )}
    </>
  )
}

function RotateButton({
  rotation,
  setRotation,
}: {
  rotation: number
  setRotation: React.Dispatch<React.SetStateAction<number>>
}) {
  return (
    <button
      className={styles.smallButton}
      data-cy="rotate-document"
      onClick={() => {
        const items = document.querySelectorAll(
          `.${readerStyles.pdfPage}, .${styles.imageReview}`
        )

        for (let i = 0; i < items.length; i++) {
          items[i].setAttribute('style', `transform: rotate(${rotation}deg)`)
          if (rotation <= 270) {
            setRotation(rotation + 90)
          } else {
            setRotation(90)
          }
        }
      }}
      type="button"
    >
      <Icon name="redo" className={tw`mr-[8px]`} />
      Rotate
    </button>
  )
}

function RemoveButton({ handleOnClick }: { handleOnClick: () => void }) {
  return (
    <button
      className={styles.smallButton}
      data-cy="remove-document"
      onClick={handleOnClick}
      type="button"
    >
      <Icon name="trash-can" className={tw`mr-[8px]`} />
      Remove
    </button>
  )
}

function SmallPrintButton({ handleOnClick }: { handleOnClick: () => void }) {
  return (
    <button
      className={styles.smallButton}
      data-cy="print-document"
      onClick={handleOnClick}
      type="button"
    >
      <Icon name="print" className={tw`mr-[8px]`} />
      Print
    </button>
  )
}

function SmallDownloadButton({
  fileName,
  href,
}: {
  fileName: string
  href: string
}) {
  return (
    <a
      className={styles.smallButton}
      download={fileName}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      <Icon name="download" className={tw`mr-[8px]`} />
      Download
    </a>
  )
}
