import { match } from 'ts-pattern'
import { hasPermissionForFacility } from '@shared/components/PermissionGates/PermissionGates'
import {
  CareAppPerson,
  Instant,
  RoutineAdministration,
  RoutineAdministrationExceptionReason,
  RoutineAdministrationStatus,
} from '@shared/types/careapp'
import { Facility } from '@shared/types/facility'
import { GroupPermission } from '@shared/types/permission'
import { UserAccount } from '@shared/types/user'
import { convertEnumValueToLabel } from '@shared/utils/common'
import { isCareAppActive } from '@shared/utils/facilities'
import { isAfterInZone, isBeforeInZone } from './localDateTime'

export function routineAdminIsAdministered(
  routineAdministration: RoutineAdministration
) {
  return (
    routineAdministration.status ===
    RoutineAdministrationStatus.ROUTINE_ADMINISTRATION_STATUS_ADMINISTERED
  )
}

export function routineAdminIsException(
  routineAdministration: RoutineAdministration
) {
  return (
    routineAdministration.status ===
    RoutineAdministrationStatus.ROUTINE_ADMINISTRATION_STATUS_EXCEPTION
  )
}

export function routineAdminIsOverDue(
  routineAdministration: RoutineAdministration,
  facilityTimeZone: string
) {
  const { endAdministrationWindow, status } = routineAdministration
  const now = Instant(new Date())

  return (
    status ===
      RoutineAdministrationStatus.ROUTINE_ADMINISTRATION_STATUS_UNSPECIFIED &&
    isAfterInZone(now, endAdministrationWindow, facilityTimeZone)
  )
}

export function routineAdminIsUnadministered(
  routineAdministration: RoutineAdministration,
  timeZone: string
) {
  const { endAdministrationWindow, status } = routineAdministration
  const now = Instant(new Date())

  return (
    status ===
      RoutineAdministrationStatus.ROUTINE_ADMINISTRATION_STATUS_UNSPECIFIED &&
    isBeforeInZone(now, endAdministrationWindow, timeZone)
  )
}

export function routineAdminIsUnspecified({ status }: RoutineAdministration) {
  return (
    status ===
    RoutineAdministrationStatus.ROUTINE_ADMINISTRATION_STATUS_UNSPECIFIED
  )
}

export function getAdministrationsForPerson({
  person,
  routineAdministrations,
}: {
  person: Pick<CareAppPerson, 'id'>
  routineAdministrations: RoutineAdministration[]
}) {
  return routineAdministrations.filter(
    (administration) => administration.personId === person.id
  )
}

export function isProgressRoutinesPageEnable({
  facility,
  user,
}: {
  facility: Facility
  user: UserAccount
}) {
  return (
    isCareAppActive(facility) &&
    hasPermissionForFacility({
      user,
      facility,
      permissions: [
        GroupPermission.GROUP_PERMISSION_ROUTINE_RETROACTIVE_ADMINISTER,
      ],
    })
  )
}

export function getReasonForExceptionDisplay(
  reasonForException: RoutineAdministrationExceptionReason
) {
  return convertEnumValueToLabel(
    reasonForException.replace('ROUTINE_ADMINISTRATION_EXCEPTION_REASON_', ''),
    { capitalizeFirstWordOnly: true }
  )
}

export function getEffortLevelForDisplay(effortLevel: number): string {
  return match(effortLevel)
    .when(
      (v) => v < 0,
      () => 'Less'
    )
    .when(
      (v) => v === 0,
      () => 'Same'
    )
    .when(
      (v) => v > 0,
      () => 'More'
    )
    .run()
}

export const hasAccessToCareApp = ({
  user,
  facility,
}: {
  user: UserAccount
  facility: Facility
}): boolean => {
  return (
    isCareAppActive(facility) &&
    hasPermissionForFacility({
      user,
      facility: facility,
      permissions: [GroupPermission.GROUP_PERMISSION_ROUTINE_ADMINISTER],
    }) //  && TODO: is this necessary?
    // !isFrontDeskUser({
    //   user,
    //   orgId: facility.orgId,
    //   facilityId: facility.id,
    // })
  )
}
