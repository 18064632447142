import {
  ServicePlan_EvaluationOption,
  ServicePlan_Frequency,
  ServicePlan_LocationOption,
  ServicePlan_PersonResponsible,
} from '@augusthealth/models/com/august/protos/service_plan'
import {
  EvaluationOption,
  Frequency,
  LocationOption,
  ResponsiblePerson,
} from './types'

export const frequencies: Frequency[] = [
  {
    value: ServicePlan_Frequency.FREQUENCY_AS_SCHEDULED,
    label: 'As Scheduled',
  },
  {
    value: ServicePlan_Frequency.FREQUENCY_DAILY,
    label: 'Daily',
  },
  {
    value: ServicePlan_Frequency.FREQUENCY_SEMI_WEEKLY,
    label: 'Semi-Weekly',
  },
  {
    value: ServicePlan_Frequency.FREQUENCY_WEEKLY,
    label: 'Weekly',
  },
  {
    value: ServicePlan_Frequency.FREQUENCY_MONTHLY,
    label: 'Monthly',
  },
  {
    value: ServicePlan_Frequency.FREQUENCY_ANNUALLY,
    label: 'Annually',
  },
  {
    value: ServicePlan_Frequency.FREQUENCY_AS_NEEDED,
    label: 'As Needed',
  },
]

export const responsiblePeople: ResponsiblePerson[] = [
  {
    value:
      ServicePlan_PersonResponsible.PERSON_RESPONSIBLE_ACTIVITIES_COORDINATOR,
    label: 'Activities Coordinator/Director',
  },
  {
    value: ServicePlan_PersonResponsible.PERSON_RESPONSIBLE_ADMINISTRATOR,
    label: 'Administrator/Executive Director',
  },
  {
    value: ServicePlan_PersonResponsible.PERSON_RESPONSIBLE_CAREGIVER,
    label: 'Caregiver',
  },
  {
    value: ServicePlan_PersonResponsible.PERSON_RESPONSIBLE_CARE_COORDINATOR,
    label: 'Resident Care Coordinator/Director',
  },
  {
    value: ServicePlan_PersonResponsible.PERSON_RESPONSIBLE_DINING_STAFF,
    label: 'Dining Staff',
  },
  {
    value: ServicePlan_PersonResponsible.PERSON_RESPONSIBLE_DRIVER,
    label: 'Driver',
  },
  {
    value: ServicePlan_PersonResponsible.PERSON_RESPONSIBLE_FAMILY,
    label: 'Family',
  },
  {
    value: ServicePlan_PersonResponsible.PERSON_RESPONSIBLE_FRONT_DESK,
    label: 'Front Desk',
  },
  {
    value: ServicePlan_PersonResponsible.PERSON_RESPONSIBLE_HOSPICE,
    label: 'Hospice',
  },
  {
    value: ServicePlan_PersonResponsible.PERSON_RESPONSIBLE_HOUSEKEEPING,
    label: 'Housekeeping',
  },
  {
    value: ServicePlan_PersonResponsible.PERSON_RESPONSIBLE_MAINTENANCE,
    label: 'Maintenance Director',
  },
  {
    value: ServicePlan_PersonResponsible.PERSON_RESPONSIBLE_MED_TECH,
    label: 'Med Tech',
  },
  {
    value:
      ServicePlan_PersonResponsible.PERSON_RESPONSIBLE_MEMORY_SUPPORT_COORDINATOR,
    label: 'Memory Support Coordinator/Director',
  },
  {
    value: ServicePlan_PersonResponsible.PERSON_RESPONSIBLE_NURSE,
    label: 'Nurse',
  },
]

export const evaluationMethods: EvaluationOption[] = [
  {
    value:
      ServicePlan_EvaluationOption.EVALUATION_OPTION_OBSERVATION_REAPPRAISAL,
    label: 'Observation and Reappraisal',
  },
  {
    value: ServicePlan_EvaluationOption.EVALUATION_OPTION_CARE_CONFERENCE,
    label: 'Care Conference',
  },
  {
    value: ServicePlan_EvaluationOption.EVALUATION_OPTION_MEASURE_GOALS,
    label: 'Measure Objective/Plan Goals',
  },
  {
    value:
      ServicePlan_EvaluationOption.EVALUATION_OPTION_UPDATE_PHYSICIANS_REPORT,
    label: "Update Physician's Report",
  },

  {
    value: ServicePlan_EvaluationOption.EVALUATION_OPTION_OTHER,
    label: 'Other',
  },
]

export const locationOptions: LocationOption[] = [
  {
    value: ServicePlan_LocationOption.LOCATION_OPTION_RESIDENT_ROOM,
    label: 'Resident Room',
  },
  {
    value: ServicePlan_LocationOption.LOCATION_OPTION_DINING_AREA,
    label: 'Dining Area',
  },
  {
    value: ServicePlan_LocationOption.LOCATION_OPTION_OUTSIDE_OF_COMMUNITY,
    label: 'Outside of Community',
  },
  {
    value: ServicePlan_LocationOption.LOCATION_OPTION_BATHROOM,
    label: 'Bathroom',
  },
  {
    value: ServicePlan_LocationOption.LOCATION_OPTION_SALON,
    label: 'Salon',
  },
  {
    value: ServicePlan_LocationOption.LOCATION_OPTION_OTHER,
    label: 'Other',
  },
]
