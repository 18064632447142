import { Option } from 'effect'
import { ClassName } from 'react-pdf/dist/cjs/shared/types'
import { StylesConfig } from 'react-select'
import {
  IconStyledSelect,
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { CareGroup } from '@shared/types/care_group'
import { twx } from '@shared/utils/tailwind'

const ALL_CARE_GROUPS_OPTION = {
  value: '',
  label: 'All care groups',
}

function careGroupOptions(careGroups: CareGroup[]): OptionTypeBase[] {
  return [
    ALL_CARE_GROUPS_OPTION,
    ...careGroups.map((careGroup) => ({
      value: careGroup.id!,
      label: careGroup.name!,
    })),
  ]
}

export function CareGroupsSelect({
  className,
  selectedCareGroup,
  careGroups,
  onChangeSelectedCareGroup,
  disabled = false,
  styles,
}: {
  selectedCareGroup: Option.Option<CareGroup>
  careGroups: CareGroup[]
  onChangeSelectedCareGroup: (careGroup: Option.Option<CareGroup>) => void
  disabled?: boolean
  className?: ClassName
  styles?: StylesConfig
}) {
  const value: OptionTypeBase = Option.match(selectedCareGroup, {
    onNone: () => ALL_CARE_GROUPS_OPTION,
    onSome: ({ id, name }) => ({
      value: id!,
      label: name!,
    }),
  })

  const handleOnChange = ({ value }: { value: string }) => {
    if (value) {
      const careGroup = careGroups.find(({ id }) => id === value)
      onChangeSelectedCareGroup(Option.some(careGroup!))
    } else {
      onChangeSelectedCareGroup(Option.none())
    }
  }

  return (
    <IconStyledSelect
      inputId="careGroupSelector"
      name="careGroupSelector"
      iconName="fa-solid fa-people-group"
      className={twx(className?.toString())}
      isDisabled={disabled}
      isSearchable={false}
      options={careGroupOptions(careGroups)}
      value={value}
      onChange={handleOnChange}
      styles={{
        menu: (provided) => ({ ...provided, minWidth: 'min-content' }),
        ...styles,
      }}
    />
  )
}
