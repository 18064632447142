import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import CollapsibleCard from '@shared/components/CollapsibleCard'
import {
  MedicationOrder,
  MedOrderFormData,
} from '@shared/types/medication_order'
import {
  DosageType,
  MedicationStatement,
} from '@shared/types/medication_statement'
import {
  dosageTypeToDisplay,
  isUnknownDosageType,
  KnownDosageType,
  UnknownDosageType,
} from '@shared/utils/medicationStatement'
import { tw } from '@shared/utils/tailwind'
import { vitalEnumToLabel } from '@shared/utils/vitals'
import {
  getNewRxOrder,
  getNewVitalOrder,
} from '@app/components/Residents/Medications/AddMedication/helpers'
import { MedOrderCardTitle } from '@app/components/Residents/Medications/Orders/MedicationOrderDetails/MedOrderCardTitle'
import {
  getMedOrderDosageType,
  mapFormMedToMedOrder,
} from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'
import { MedicationOrderDoseSchedules } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/MedicationOrderDoseSchedules'
import { ScheduleBadges } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/ScheduleBadges'
import { VitalOrderSchedule } from '@app/components/Residents/Medications/Orders/VitalOrderSchedule'

type Props = {
  use24HourTime: boolean
  personId: string
}

export default function NewOrderScheduleCard(props: Props) {
  const { use24HourTime, personId } = props
  const [expanded, setExpanded] = useState(false)
  const methods = useFormContext<MedOrderFormData>()

  const { formState, watch } = methods

  const medOrderFormData = watch()
  const userSetDosageType = medOrderFormData.doses![0]!.userSetDosageType!
  const isVitalOrder = !!medOrderFormData.vitalType

  const newOrder: Partial<MedicationOrder> = isVitalOrder
    ? getNewVitalOrder({ personId, vitalType: medOrderFormData.vitalType! })
    : getNewRxOrder({ personId, dosageType: userSetDosageType })

  const wholeOrderDoseType = getMedOrderDosageType(
    mapFormMedToMedOrder({
      formData: medOrderFormData,
      medOrder: newOrder,
    }).medicationStatement as MedicationStatement
  )
  const firstDoseType: Exclude<DosageType, UnknownDosageType> =
    isUnknownDosageType(userSetDosageType)
      ? DosageType.DOSAGE_TYPE_ROUTINE
      : userSetDosageType

  const formHasErrors = Object.keys(formState.errors).length > 0

  return (
    <CollapsibleCard
      defaultExpanded={true}
      enableAutoScrollOnExpand={formState.isSubmitted}
      expanded={formHasErrors}
      expandTrigger={formState.isSubmitting}
      contentTestId={'diy-schedule-card'}
      cardTitle={
        <div className={tw`w-full`}>
          {isVitalOrder ? (
            <>
              <MedOrderCardTitle
                value={`Record ${vitalEnumToLabel(medOrderFormData.vitalType)}`}
              />
              {!expanded && (
                <ScheduleBadges
                  order={newOrder}
                  doseType={firstDoseType}
                  use24HourTime={use24HourTime}
                />
              )}
            </>
          ) : (
            <>
              {expanded ? (
                <MedOrderCardTitle value={'Schedule and Dosage...'} />
              ) : (
                <>
                  <MedOrderCardTitle
                    value={
                      formState.dirtyFields?.doses
                        ? `${dosageTypeToDisplay(wholeOrderDoseType as KnownDosageType)} Schedule...`
                        : 'Schedule and Dosage'
                    }
                  />
                  <ScheduleBadges
                    order={newOrder}
                    doseType={firstDoseType}
                    use24HourTime={use24HourTime}
                  />
                </>
              )}
            </>
          )}
        </div>
      }
      onExpandClick={setExpanded}
      role={'list'}
    >
      {isVitalOrder ? (
        <VitalOrderSchedule order={newOrder} index={0} mode={'create'} />
      ) : (
        <MedicationOrderDoseSchedules
          canAddOrRemoveDoses={true}
          readOnly={false}
          order={newOrder}
          mode={'create'}
        />
      )}
    </CollapsibleCard>
  )
}
