import { Vital } from '@shared/types/vital'
import notEmpty from '@shared/utils/notEmpty'
import { VitalsType } from '@shared/utils/vitals'
import { Props as AreaChartProps } from '@app/libraries/Victory/AreaChart'
import {
  ChartData,
  convertDateObjToString,
  getDomainYWithMargin,
} from '@app/libraries/Victory/helpers'

/**
 * Sort Vital by recordedAt in ASC order
 * @param Vital v1
 * @param Vital v2
 * @returns [-1, 1]
 */
export function sortVitalsByRecordedAt(v1: Vital, v2: Vital) {
  const at1 = v1.recordedAt
  const at2 = v2.recordedAt
  if (!at1) {
    return 1
  } else if (!at2) {
    return -1
  } else if (at1 < at2) {
    return -1
  }

  return 1
}

type AttrKey = 'diastolic' | 'value' | 'systolic'

function convertVitalToChartData({
  vital,
  vitalsType,
  attrKey,
  index,
}: {
  vital: Vital
  vitalsType: VitalsType
  attrKey: AttrKey
  index: number
}) {
  const { panel, recordedAt } = vital
  if (panel?.[vitalsType]?.[attrKey] && recordedAt) {
    const value = panel![vitalsType]![attrKey]!
    const y = attrKey === 'value' ? value : value['value']
    return {
      x: index + 1, // Index starts at 0
      y,
      date: new Date(recordedAt!),
    }
  }

  return null
}

function filterOutVitalWithDuplicateDate(vitals: Vital[]) {
  const dataHash = vitals.reduce<Record<string, Vital>>((hash, v) => {
    const { recordedAt } = v
    if (recordedAt) {
      const d = new Date(recordedAt)
      if (!isNaN(d.getTime())) {
        const key = `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`
        hash[key] = v // Entry with same date will be overwritten
      }
    }
    return hash
  }, {})

  return Object.values(dataHash)
}

export function convertVitalsToChartDataList({
  vitals,
  vitalsType,
}: {
  vitals: Vital[]
  vitalsType: VitalsType
}): ChartData[][] {
  const attrKeys =
    vitalsType === VitalsType.BLOOD_PRESSURE
      ? ['systolic', 'diastolic']
      : ['value']
  const vitalsWithoutDuplicateDate = filterOutVitalWithDuplicateDate(vitals)
  return attrKeys.map((aKey: AttrKey) => {
    return vitalsWithoutDuplicateDate
      .map((v, i) => {
        return convertVitalToChartData({
          vital: v,
          vitalsType,
          index: i,
          attrKey: aKey,
        })
      })
      .filter(notEmpty)
  })
}

export type CategoriesDataDomain = Pick<
  AreaChartProps,
  'categories' | 'data' | 'domain'
>

export function getAreaChartProps({
  vitals,
  vitalsType,
}: {
  vitals: Vital[]
  vitalsType: VitalsType
}): CategoriesDataDomain {
  const sortedVitals = [...vitals]
  sortedVitals.sort(sortVitalsByRecordedAt)

  let data: ChartData[][] = [[]]
  let categories: string[] = []
  let maxY = 1
  let minY = 0

  if (sortedVitals.length && vitalsType) {
    data = convertVitalsToChartDataList({
      vitals: sortedVitals,
      vitalsType,
    })
    categories = data[0].map((d) => convertDateObjToString(d.date))

    const extent = data.reduce(
      (ext, list) => {
        const allY = list.map((l) => l.y)
        return {
          min: Math.min(...allY, ext.min),
          max: Math.max(...allY, ext.max),
        }
      },
      {
        min: Number.MAX_SAFE_INTEGER,
        max: Number.MIN_SAFE_INTEGER,
      }
    )
    minY = extent.min
    maxY = extent.max
  }

  return {
    categories,
    data,
    domain: { y: getDomainYWithMargin([minY, maxY]) },
  }
}
