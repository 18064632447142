import Skeleton from 'react-loading-skeleton'
import Card from '@shared/components/Card'
import styles from './styles.module.css'
import 'react-loading-skeleton/dist/skeleton.css'

export function ContactCardSkeleton() {
  return (
    <Card
      style={{ height: '336px', width: '336px' }}
      className={styles.disabledCard}
      data-testid={'contact-card-skeleton'}
    >
      <div className="flex flex-col">
        <h4 className={styles.role}>
          <Skeleton width="120px" />
        </h4>
        <h3 className={styles.name}>
          <Skeleton width="160px" />
        </h3>
        <div className={styles.infoText}>
          <Skeleton width="200px" />
        </div>
        <div className={styles.infoText}>
          <Skeleton width="256px" />
        </div>
        <div className={`${styles.primaryLabel} mt-[24px]`}>
          <Skeleton width="160px" />
        </div>
        <div className="mt-[24px] pt-[24px]">
          <Skeleton width="64px" />
        </div>
      </div>
    </Card>
  )
}
