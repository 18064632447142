import { useContext, useEffect, useState } from 'react'
import {
  LightboxFooterButton,
  LightboxSidebarTitle,
} from '@shared/components/AnimatedPopup/Lightbox/Lightbox'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { snapshotFileUrl } from '@shared/legacy_routes'
import { Immunization } from '@shared/types/immunization'
import { GroupPermission } from '@shared/types/permission'
import { Person } from '@shared/types/person'
import { DataType, Snapshot } from '@shared/types/snapshot'
import { getFullName } from '@shared/utils/humanName'
import {
  getDiseaseLabel,
  getImmunizationsByDiseaseName,
} from '@shared/utils/immunizations'
import { getSnapshotName } from '@shared/utils/snapshot'
import { tw } from '@shared/utils/tailwind'
import { incompleteTask } from '@shared/utils/task'
import {
  attachImmunization,
  removeSnapshotIdFromImmunization,
} from '@app/api/immunizations'
import { fetchSnapshot } from '@app/api/snapshot'
import { markTaskAsComplete } from '@app/api/tasks'
import Warning from '@app/components/Warning'
import useBlobData from '@app/hooks/useBlobData'
import { useTasks } from '@app/hooks/useTasks'
import Uploader from '@app/pages/Documents/Uploader/Uploader'
import Viewer from '@app/pages/Documents/Viewer'
import {
  attachFilesToMostRecentImmunization,
  saveImmunizations,
} from '@app/pages/Documents/Viewer/VaccineViewer/helpers'
import FormContent from './FormContent'
import { ErrorType } from './FormContent/AddImmunization'

export type Props = {
  diseaseName: string
  person: Person
  onClose: () => void
  onUpdate: () => Promise<void>
  immunizations?: Immunization[]
  document?: Snapshot
  displayUploaderByDefault?: boolean
}

export const WARNING_MESSAGE_HASH: Record<ErrorType, string> = {
  dateNotAdd: "Please click the 'Add' button to save your update.",
  needAtLeastOneImmunization:
    'To upload a document, please add an immunization record.',
}

export default function VaccineViewer({
  diseaseName,
  immunizations: defaultImmunizations = [],
  onClose,
  onUpdate,
  person,
  document: defaultDocument,
  displayUploaderByDefault,
}: Props) {
  const { user } = useUserContext()
  const immunizations = getImmunizationsByDiseaseName({
    diseaseName,
    immunizations: defaultImmunizations,
  })
  const { setError } = useContext(GlobalContext)
  const [files, setFiles] = useState<File[]>()
  const [showUploadInProgress, setShowUploadInProgress] = useState(false)
  const hasAttachment = immunizations.some((immu) => immu.attachmentSnapshotId)
  const [attachment, setAttachment] = useState<Snapshot | undefined>(
    defaultDocument
  )
  const [newOccurrenceDate, setNewOccurrenceDate] = useState<Date | null>(null)
  const [uploaded, setUploaded] = useState<boolean>(false)
  const [errorType, setErrorType] = useState<ErrorType | undefined>(undefined)
  const [uploaderOnly, setUploaderOnly] = useState(displayUploaderByDefault)

  useEffect(() => {
    setAttachment(defaultDocument)
  }, [defaultDocument?.id])

  useEffect(() => {
    if (!newOccurrenceDate && errorType === 'dateNotAdd') {
      setErrorType(undefined)
    }
    if (
      errorType === 'needAtLeastOneImmunization' &&
      (newOccurrenceDate || !uploaded)
    ) {
      setErrorType(undefined)
    }
  }, [newOccurrenceDate, uploaded])

  const { tasks, refreshTasks } = useTasks(false)
  const { id: personId, name, orgId } = person
  const reloadAttachment = (attachmentSnapshotId?: string) => {
    if (personId && orgId) {
      if (attachmentSnapshotId) {
        fetchSnapshot({
          personId,
          orgId,
          snapshotId: attachmentSnapshotId,
          dataType: DataType.DATA_TYPE_IMMUNIZATION_RECORD,
        })
          .then((snapshot) => {
            setAttachment(snapshot)
            setUploaderOnly(false)
          })
          .catch(() => setAttachment(undefined))
      } else {
        setAttachment(undefined)
      }
    }
  }

  async function makeTaskComplete() {
    const task = incompleteTask(tasks, DataType.DATA_TYPE_IMMUNIZATION_RECORD)
    if (task) {
      await markTaskAsComplete(person, task)
      await refreshTasks()
    }
  }

  async function onSubmit(updatedImmunizations: Immunization[]) {
    try {
      setShowUploadInProgress(true)
      const returnedImmunizations = await saveImmunizations({
        originalImmunizations: immunizations,
        updatedImmunizations,
        person,
      })

      if (files?.length) {
        const immuWithAttachment = await attachFilesToMostRecentImmunization({
          person,
          immunizations: returnedImmunizations,
          files,
        })

        setFiles([])
        if (immuWithAttachment?.attachmentSnapshotId) {
          reloadAttachment(immuWithAttachment.attachmentSnapshotId)
        }
      }

      await makeTaskComplete()
      await onUpdate()
    } catch (e) {
      setError(e)
    } finally {
      setShowUploadInProgress(false)
    }
  }

  async function onRemoveSnapshotId(immunizationToUpdate: Immunization) {
    return removeSnapshotIdFromImmunization(person, immunizationToUpdate)
      .then(() => onUpdate())
      .catch(setError)
  }

  const title =
    (diseaseName && getDiseaseLabel(diseaseName)) ||
    (attachment && getSnapshotName(attachment))
  const formContent = (
    <section>
      <LightboxSidebarTitle>{title}</LightboxSidebarTitle>
      {errorType && WARNING_MESSAGE_HASH[errorType] && (
        <Warning className={tw`mb-[8px]`}>
          {WARNING_MESSAGE_HASH[errorType]}
        </Warning>
      )}
      <FormContent
        diseaseName={diseaseName}
        immunizations={immunizations}
        onAdd={async (immu: Immunization) => onSubmit([immu, ...immunizations])}
        onDelete={async (immuToDelete: Immunization) =>
          onSubmit(immunizations.filter((immu) => immu.id !== immuToDelete.id))
        }
        onRemoveSnapshotId={(immu) => {
          onRemoveSnapshotId(immu)
            .then(() => {
              if (
                attachment?.id &&
                attachment.id === immu.attachmentSnapshotId
              ) {
                // Clean attachment in PDF viewer if it was associated to current immunization
                setUploaderOnly(true)
              }
            })
            .catch(setError)
        }}
        onAddAttachment={(files: FileList, immunization: Immunization) => {
          if (immunization.id) {
            attachImmunization(Array.from(files), immunization.id, person)
              .then((immu) => {
                reloadAttachment(immu.attachmentSnapshotId)
                void onUpdate()
              })
              .catch(setError)
          }
        }}
        person={person}
        onDateChange={(d: Date | null) => setNewOccurrenceDate(d)}
        errorType={errorType}
        attachment={attachment}
        switchAttachment={reloadAttachment}
      />
    </section>
  )
  const ActionButtons = () => (
    <LightboxFooterButton
      aria-label="closePopupBtn"
      onClick={() => {
        setErrorType(undefined)

        // Set dateNotAdd if newOccurenceDate is picked but not added
        if (newOccurrenceDate) {
          // Set a timeout for better animated effect
          setErrorType('dateNotAdd')
        } else if (uploaded && !immunizations.length) {
          setErrorType('needAtLeastOneImmunization')
        } else {
          onClose()
        }
      }}
    />
  )
  const blobData = useBlobData(
    attachment?.id // Only call Snapshot when attachedId exists, we don't want to have extra API call for latest snapshot
      ? snapshotFileUrl({
          personId: personId!,
          orgId: orgId!,
          dataType: DataType.DATA_TYPE_IMMUNIZATION_RECORD,
          snapshotId: attachment.id,
        })
      : '' // pass empty string to put fetching of blobData on hold
  )
  const filename = `${name?.length ? getFullName(name[0]) : ''}_Immunization`
  const allowToDelete = hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_SNAPSHOT_DELETE],
  })

  if (hasAttachment && !uploaderOnly) {
    return (
      <Viewer
        dataType={DataType.DATA_TYPE_IMMUNIZATION_RECORD}
        deletable={false}
        softDeletable={
          allowToDelete
            ? {
                callbackFn: () => {
                  if (attachment) {
                    const immunWithAttachment = immunizations.find(
                      (immu) =>
                        immu.attachmentSnapshotId !== undefined &&
                        immu.attachmentSnapshotId === attachment.id
                    )
                    if (immunWithAttachment) {
                      void onRemoveSnapshotId(immunWithAttachment)
                    }
                  }
                },
              }
            : false
        }
        blobData={blobData}
        filename={filename}
        showPrintDownload={false}
        showSmallPrintButton
        popUpActions={<ActionButtons />}
        onClose={onClose}
      >
        {formContent}
      </Viewer>
    )
  } else {
    return (
      <Uploader
        onFileChange={async (files: File[]) => {
          if (files?.length) {
            setUploaded(true)
            // Attach files if any immunizations found
            if (immunizations?.length) {
              const immuWithAttachment =
                await attachFilesToMostRecentImmunization({
                  person,
                  immunizations,
                  files,
                })
              if (immuWithAttachment?.attachmentSnapshotId) {
                reloadAttachment(immuWithAttachment.attachmentSnapshotId)
              }
              await makeTaskComplete()
              await onUpdate()
              return
            } else {
              // Otherwise save file to be attached once immunization record is added in onSubmit
              setFiles(files)
            }
          } else {
            setUploaded(false)
          }
        }}
        uploadInProgress={showUploadInProgress}
        defaultButtonText="Upload Document"
        popUpActions={<ActionButtons />}
      >
        {formContent}
      </Uploader>
    )
  }
}
