import { NoteType } from '@augusthealth/models/com/august/protos/incidents'
import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { startOfYear, sub } from 'date-fns'
import { identity, intersection, orderBy, partition } from 'lodash'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import { Facility } from '@shared/types/facility'
import {
  Incident,
  IncidentActionType,
  IncidentType,
} from '@shared/types/incidents'
import { Person } from '@shared/types/person'
import { UserAccount } from '@shared/types/user'
import {
  fromDateMessageToDate,
  fromDateToDateMessage,
  fromTimeStringToTime,
} from '@shared/utils/date'
import { getFullName } from '@shared/utils/humanName'
import {
  followUpActions,
  hasActiveAlert,
  tags as incidentTags,
  inputWithIconGroups,
  noteTypes,
} from '@shared/utils/incident'
import notEmpty from '@shared/utils/notEmpty'
import { isSuperUser } from '@shared/utils/user'
import { createIncident, updateIncident } from '@app/api/incidents'
import {
  FilterIncidentsFormData,
  InputWithIcon,
  NoteFormData,
} from '@app/pages/Notes/types'

export function canEditIncidentActions({
  user,
  person,
}: {
  user: UserAccount
  person: Person
}) {
  return hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_INCIDENT_ACTION_UPDATE],
  })
}

export function canEditIncident({
  user,
  incident,
  person,
}: {
  user: UserAccount
  incident: Incident
  person: Person
}) {
  const _isSuperUser = isSuperUser(user)
  const isCreator = incident.createdBy?.modifiedByUserId === user.id
  const hasIncidentUpdatePermission = hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_INCIDENT_UPDATE],
  })

  const hasUpdateAllIncidentsPermission = hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_INCIDENT_UPDATE_ALL],
  })

  return (
    _isSuperUser ||
    (isCreator && hasIncidentUpdatePermission) ||
    hasUpdateAllIncidentsPermission
  )
}

export function canChangeIncidentStatus({
  user,
  person,
}: {
  user: UserAccount
  person: Person
}) {
  return hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_INCIDENT_STATUS_UPDATE],
  })
}

export function canCreateIncident({
  user,
  person,
}: {
  user: UserAccount
  person: Person
}) {
  return hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_INCIDENT_CREATE],
  })
}

export function canDeleteIncident({
  user,
  person,
}: {
  user: UserAccount
  person: Person
}) {
  return hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_INCIDENT_DELETE],
  })
}

export async function onNoteSubmit({
  formData,
  person,
  originalNote,
}: {
  formData: NoteFormData
  person: Person
  originalNote?: Incident
}): Promise<Incident> {
  const date = formData.serviceDate ?? new Date()
  const time = formData.time ? fromTimeStringToTime(formData.time) : null

  const incident: Incident = {
    detail: {
      noteDetail: { note: formData.noteText, types: [...formData.noteTypes] },
    },
    occurredAt: {
      date: fromDateToDateMessage(date),
      // @ts-ignore - We send nulls to mark the key as 'deletable'
      time: time ?? null,
    },
  }

  if (originalNote) {
    await updateIncident({
      person,
      incident: { ...incident, id: originalNote.id },
    })
    return { ...originalNote, ...incident, id: originalNote.id }
  } else {
    await createIncident({ person, incident })
    return { ...incident }
  }
}

export function filterIncidents({
  incidents,
  filters,
}: {
  incidents: Incident[]
  filters: FilterIncidentsFormData
}) {
  return incidents
    .filter((i) => matchesTime(i, filters))
    .filter((i) => matchesModifiedByUser(i, filters))
    .filter((i) => matchesTypesAndStatus(i, filters))
    .filter((i) => matchesOnAlert(i, filters))
}

function sortByModificationTime(incidents: Incident[]) {
  return orderBy(
    incidents,
    (i) => {
      const comments = i.comments ?? []
      const allUpdateTimes = [
        i.updatedBy?.modificationTime,
        ...comments.map((c) => c.updatedBy?.modificationTime),
      ]
        .filter(notEmpty)
        .map((t) => new Date(t))

      return orderBy(allUpdateTimes, identity, ['desc'])[0]
    },
    ['desc']
  )
}

export function sortIncidents(incidents: Incident[]) {
  const [incidentsOnAlert, incidentsNotOnAlert] = partition(
    incidents,
    hasActiveAlert
  )

  return [
    ...sortByModificationTime(incidentsOnAlert),
    ...sortByModificationTime(incidentsNotOnAlert),
  ]
}

function matchesTypesAndStatus(i: Incident, f: FilterIncidentsFormData) {
  const hasStatusFilter = !!f.incidentStatus
  const hasIncidentsFilters = f.incidentTypes.length > 0
  const hasNotesFilters = f.noteTypes.length > 0

  if (i.detail?.noteDetail && hasNotesFilters) {
    const noteTags = i.detail.noteDetail.types ?? []
    const matchesNoTagsFilter =
      noteTags.length === 0 &&
      f.noteTypes.includes(NoteType.NOTE_TYPE_UNSPECIFIED)

    return (
      f.noteTypes.length === noteTypes.length ||
      intersection(noteTags, f.noteTypes).length > 0 ||
      matchesNoTagsFilter
    )
  }

  if (i.detail?.incidentDetail && hasIncidentsFilters) {
    const incidentTypes = i.detail.incidentDetail.types ?? []
    const matchesNoTagsFilter =
      incidentTypes.length === 0 &&
      f.incidentTypes.includes(IncidentType.INCIDENT_TYPE_UNSPECIFIED)
    const hasMatchingTag =
      f.incidentTypes.length === incidentTags.length ||
      intersection(incidentTypes, f.incidentTypes).length > 0 ||
      matchesNoTagsFilter

    if (hasStatusFilter) {
      return hasMatchingTag && matchesStatus(i, f)
    } else {
      return hasMatchingTag
    }
  }

  if (i.detail?.noteDetail && hasIncidentsFilters) {
    // Here we know we have a note that doesn't match any note tags
    // When there are incident tags, exclude the note
    return false
  }

  if (i.detail?.incidentDetail && hasNotesFilters && !hasStatusFilter) {
    // Here we know we have an incident that doesn't match any incident tags
    // When there are note tags and no status filter, exclude the incident
    return false
  }

  if (hasStatusFilter) {
    return matchesStatus(i, f)
  }

  return true
}

function matchesOnAlert(i: Incident, f: FilterIncidentsFormData) {
  return Boolean(!f.onAlert || (f.onAlert && hasActiveAlert(i)))
}

function matchesTime(i: Incident, f: FilterIncidentsFormData) {
  if (f.time && f.time.length > 0) {
    if (i.occurredAt?.date === undefined) {
      return false
    }

    const now = new Date()
    const occurredAt = fromDateMessageToDate(i.occurredAt.date)!

    switch (f.time) {
      case 'lastTwentyFourHours':
        return occurredAt >= sub(now, { days: 1 })
      case 'lastSevenDays':
        return occurredAt >= sub(now, { days: 7 })
      case 'lastThirtyDays':
        return occurredAt >= sub(now, { days: 30 })
      case 'lastSixtyDays':
        return occurredAt >= sub(now, { days: 60 })
      case 'lastHundredAndTwentyDays':
        return occurredAt >= sub(now, { days: 120 })
      case 'lastTwelveMonths':
        return occurredAt >= sub(now, { months: 12 })
      case 'yearToDate':
        return occurredAt >= startOfYear(now)
    }
  }

  return true
}

function matchesStatus(i: Incident, f: FilterIncidentsFormData) {
  if (i.detail?.noteDetail) {
    return false
  }

  if (f.incidentStatus !== null) {
    if (
      f.incidentStatus ===
      IncidentActionType.INCIDENT_ACTION_TYPE_NOTIFY_CA_LIC_624
    ) {
      if (
        i?.detail?.incidentDetail?.incidentActions?.some(
          (at) =>
            at?.type ===
            IncidentActionType.INCIDENT_ACTION_TYPE_NOTIFY_CA_LIC_624
        )
      ) {
        return true
      }
    }
    if (i.detail?.incidentDetail?.status === undefined) {
      return false
    }

    return i.detail.incidentDetail.status === f.incidentStatus
  }

  return true
}

function matchesModifiedByUser(i: Incident, f: FilterIncidentsFormData) {
  if (f.users.length > 0) {
    if (i.createdBy?.modifiedByUserId === undefined) {
      return false
    }

    return intersection([i.createdBy.modifiedByUserId], f.users).length > 0
  }

  return true
}

export function getUsers(incidents: Incident[]) {
  return Array.from(
    new Set(incidents.map((v) => v.createdBy?.modifiedByUserId))
  ).map((value) => {
    const humanName = incidents.find(
      (v) => v.createdBy?.modifiedByUserId === value
    )?.createdBy?.modifiedByUserName
    const fullName =
      humanName !== undefined ? getFullName(humanName) : `user-${value}`
    return {
      value: value!,
      icon: 'users',
      label: fullName,
      name: fullName,
    }
  })
}

export function getNewFilterValue(
  group: string | string[] | null,
  valueToRemove: string | string[]
) {
  const newFilterValue = Array.isArray(group)
    ? group.filter((v: string) => v !== valueToRemove)
    : null

  return newFilterValue
}

function findAndAppendToAcc({
  value,
  inputGroup,
  acc,
  name,
}: {
  value: string
  inputGroup: InputWithIcon[]
  acc: InputWithIcon[]
  name: string
}) {
  if (value !== undefined && inputGroup !== undefined) {
    const inputWithIcon = inputGroup!.find(
      (i: InputWithIcon) => i.value === value
    )

    if (inputWithIcon) {
      acc.push({ ...inputWithIcon, groupName: name })
    }
  }
}

export function getBadges({
  filters,
  users,
}: {
  filters: FilterIncidentsFormData
  users: any
}): InputWithIcon[] {
  return Object.entries(filters).reduce(
    (
      acc: InputWithIcon[],
      [name, selection]: [name: string, selection: string[]]
    ) => {
      const inputGroup =
        name === 'users' && users !== undefined
          ? users
          : inputWithIconGroups[name]

      if (Array.isArray(selection)) {
        selection.forEach((v) =>
          findAndAppendToAcc({
            value: v,
            inputGroup,
            acc,
            name,
          })
        )
      }

      if (!Array.isArray(selection)) {
        findAndAppendToAcc({
          value: selection,
          inputGroup,
          acc,
          name,
        })
      }

      return acc
    },
    []
  )
}

function incidentStatusesForFacility(facility: Facility): InputWithIcon[] {
  const { incidentStatus } = inputWithIconGroups

  if (hasConfiguredStateReportInfo(facility)) {
    return incidentStatus
  }

  return incidentStatus.filter(
    (s) => s.value !== IncidentActionType.INCIDENT_ACTION_TYPE_NOTIFY_CA_LIC_624
  )
}

export function getInputWithIconGroups({ facility }: { facility: Facility }) {
  const { time, incidentTypes, noteTypes, onAlert } = inputWithIconGroups

  return {
    incidentStatus: incidentStatusesForFacility(facility),
    time,
    noteTypes,
    incidentTypes,
    onAlert,
  }
}

export function followUpActionsForFacility(facility: Facility) {
  return followUpActions.filter((action) => {
    if (
      action.value === IncidentActionType.INCIDENT_ACTION_TYPE_NOTIFY_CA_LIC_624
    ) {
      return hasConfiguredStateReportInfo(facility)
    } else {
      return true
    }
  })
}

function hasConfiguredStateReportInfo(facility: Facility) {
  return !!(
    facility.stateIncidentReportInfo?.infoUrl ||
    facility.stateIncidentReportInfo?.isProjectable
  )
}

function isUnspecifiedIncidentOrNoteType(type: NoteType | IncidentType) {
  return (
    type === IncidentType.INCIDENT_TYPE_UNSPECIFIED ||
    type === NoteType.NOTE_TYPE_UNSPECIFIED
  )
}

export function updateIncidentOrNoteTypes({
  originalTypes,
  selectedType,
}: {
  originalTypes: (NoteType | IncidentType)[]
  selectedType: NoteType | IncidentType
}) {
  if (originalTypes.includes(selectedType)) {
    // Unchecked when exists
    return originalTypes.filter((t) => t !== selectedType)
  } else if (isUnspecifiedIncidentOrNoteType(selectedType)) {
    // No Tags clicked
    return [selectedType] // Keep No Tags only, other types will be removed
  } else {
    // Other Tags clicked
    return [
      ...originalTypes.filter((t) => !isUnspecifiedIncidentOrNoteType(t)), // Filter-out No Tags
      selectedType,
    ]
  }
}
