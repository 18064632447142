import {
  Contact,
  Contact_ContactRelationship,
} from '@augusthealth/models/com/august/protos/contact'
import {
  ContactPoint,
  ContactPoint_ContactPointSystem,
} from '@augusthealth/models/com/august/protos/contact_point'
import { cloneDeep, intersection } from 'lodash'
import { fetchPerson } from '@shared/api/person'
import {
  CLINICIAN_RELATIONSHIP_LIST,
  PERSONAL_RELATIONSHIP_LIST,
  PRIMARY_CARE_ROLES,
  PROFESSIONAL_RELATIONSHIP_LIST,
} from '@shared/constants/contactRelationship'
import { Person } from '@shared/types/person'
import { getAddressFromGeocoder } from '@shared/utils/address'
import { convertRelationshipToOption } from '@shared/utils/contact'
import { updateContact } from '@app/api/contacts'

export type RelationshipCategory =
  | Contact_ContactRelationship.CONTACT_RELATIONSHIP_PERSONAL
  | Contact_ContactRelationship.CONTACT_RELATIONSHIP_PROFESSIONAL
  | Contact_ContactRelationship.CONTACT_RELATIONSHIP_CLINICIAN

export function relationship(
  category: Contact_ContactRelationship | undefined,
  relationships: Contact_ContactRelationship[]
) {
  if (category === undefined) {
    return undefined
  }

  let matches: Contact_ContactRelationship[] = []

  switch (category) {
    case Contact_ContactRelationship.CONTACT_RELATIONSHIP_PERSONAL:
      matches = intersection(relationships, PERSONAL_RELATIONSHIP_LIST).filter(
        isSpecific
      )
      return matches.length > 0 ? matches[matches.length - 1] : undefined
    case Contact_ContactRelationship.CONTACT_RELATIONSHIP_CLINICIAN:
      matches = intersection(relationships, CLINICIAN_RELATIONSHIP_LIST).filter(
        isSpecific
      )
      return matches.length > 0 ? matches[matches.length - 1] : undefined
    case Contact_ContactRelationship.CONTACT_RELATIONSHIP_PROFESSIONAL:
      matches = intersection(
        relationships,
        PROFESSIONAL_RELATIONSHIP_LIST
      ).filter(isSpecific)
      return matches.length > 0 ? matches[matches.length - 1] : undefined
    default:
      return undefined
  }
}

export function optionsForCategory(category: RelationshipCategory) {
  if (category === undefined) {
    return []
  }

  switch (category) {
    case Contact_ContactRelationship.CONTACT_RELATIONSHIP_PERSONAL:
      return PERSONAL_RELATIONSHIP_LIST.filter(isSpecific).map(
        convertRelationshipToOption
      )
    case Contact_ContactRelationship.CONTACT_RELATIONSHIP_PROFESSIONAL:
      return PROFESSIONAL_RELATIONSHIP_LIST.filter(isSpecific).map(
        convertRelationshipToOption
      )
    case Contact_ContactRelationship.CONTACT_RELATIONSHIP_CLINICIAN:
      return CLINICIAN_RELATIONSHIP_LIST.filter(isSpecific).map(
        convertRelationshipToOption
      )
    default:
      return []
  }
}

export interface FormData {
  firstName: string
  lastName: string
  phone: string
  relationshipCategory: {
    label: string
    value: RelationshipCategory
  }
  specificRelationship: { label: string; value: Contact_ContactRelationship }
  address: google.maps.places.PlaceResult | string | undefined
}

function isSpecific(relationship: Contact_ContactRelationship) {
  return ![
    Contact_ContactRelationship.CONTACT_RELATIONSHIP_PERSONAL,
    Contact_ContactRelationship.CONTACT_RELATIONSHIP_FAMMEMB,
    Contact_ContactRelationship.CONTACT_RELATIONSHIP_CLINICIAN,
    Contact_ContactRelationship.CONTACT_RELATIONSHIP_PROFESSIONAL,
  ].includes(relationship)
}

export const categoryOptions = [
  convertRelationshipToOption(
    Contact_ContactRelationship.CONTACT_RELATIONSHIP_PERSONAL
  ),
  convertRelationshipToOption(
    Contact_ContactRelationship.CONTACT_RELATIONSHIP_CLINICIAN
  ),
  convertRelationshipToOption(
    Contact_ContactRelationship.CONTACT_RELATIONSHIP_PROFESSIONAL
  ),
]

export const SECONDARY_RELATIONSHIP_OPTIONS_FOR_GP = PRIMARY_CARE_ROLES.map(
  convertRelationshipToOption
)

export async function updateResponsiblePerson({
  data,
  rp,
  initialRelationship,
  initialRelationshipCategory,
  initialPhoneNumber,
  person,
}: {
  data: FormData
  rp: Contact
  initialRelationshipCategory: RelationshipCategory | undefined
  initialRelationship: Contact_ContactRelationship | undefined
  initialPhoneNumber: string | undefined
  person: Person
}) {
  const newContact = cloneDeep(rp)

  // Build new name, preserving prefix/suffix
  newContact.name = {
    ...newContact.name,
    given: [data.firstName],
    family: data.lastName,
  }

  // Build new telecom, replacing phone with data from form
  newContact.telecom = makeTelecom({
    contact: rp,
    initialPhoneNumber,
    newPhoneNumber: data.phone,
  })

  // Build new relationships
  // 1. Take out initially selected values, generic & specific
  newContact.relationship = (newContact.relationship || []).filter((r) => {
    if (initialRelationshipCategory === 'CONTACT_RELATIONSHIP_PERSONAL') {
      return ![
        initialRelationshipCategory,
        initialRelationship,
        Contact_ContactRelationship.CONTACT_RELATIONSHIP_FAMMEMB,
      ].includes(r)
    } else {
      return ![initialRelationshipCategory, initialRelationship].includes(r)
    }
  })

  // 2. Add in new generic and specific relationships
  newContact.relationship = [
    ...newContact.relationship,
    data.relationshipCategory.value,
    data.specificRelationship.value,
  ]

  if (
    data.relationshipCategory.value ===
    Contact_ContactRelationship.CONTACT_RELATIONSHIP_PERSONAL
  ) {
    if (
      data.specificRelationship.value !==
      Contact_ContactRelationship.CONTACT_RELATIONSHIP_OTHER
    ) {
      newContact.relationship.push(
        Contact_ContactRelationship.CONTACT_RELATIONSHIP_FAMMEMB
      )
    }
  }

  if (typeof data.address === 'object') {
    if (data.address.address_components) {
      newContact.address = getAddressFromGeocoder(
        data.address.address_components
      )
    }
  }

  await updateContact({
    orgId: person.orgId!,
    facilityId: person.facilityId!,
    personId: person.id!,
    contact: newContact,
  })

  return await fetchPerson({
    orgId: person.orgId!,
    facilityId: person.facilityId!,
    personId: person.id!,
  })
}

function makeTelecom({
  contact,
  initialPhoneNumber,
  newPhoneNumber,
}: {
  contact: Contact
  initialPhoneNumber: string | undefined
  newPhoneNumber: string
}): ContactPoint[] {
  if (contact.telecom) {
    return contact.telecom.map((t) => {
      if (t.value === initialPhoneNumber) {
        return {
          ...t,
          value: newPhoneNumber,
        }
      }

      return t
    })
  } else {
    return [
      {
        value: newPhoneNumber,
        system: ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_PHONE,
      },
    ]
  }
}
