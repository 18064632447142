import { useContext, useState } from 'react'
import { issueStatementsForResidents } from '@shared/api/billing'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Statement } from '@shared/types/billing'
import pluralize from '@shared/utils/pluralize'
import { tw } from '@shared/utils/tailwind'
import ConfirmModal from '@app/components/ConfirmModal'

type Props = {
  closeFn: () => void
  doneFn: () => Promise<void>
  facilityId: string
  orgId: string
  // TODO: Is this really 'issuable invoices'? Should we filter elsewhere or here?
  issuableStatements: Statement[]
  billingUpdates: number
}

export default function IssueStatementsModal(props: Props) {
  const {
    orgId,
    facilityId,
    doneFn,
    closeFn,
    issuableStatements,
    billingUpdates,
  } = props
  const { setError } = useContext(GlobalContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const len = issuableStatements.length

  async function onSubmit() {
    setIsSubmitting(true)
    try {
      await issueStatementsForResidents({
        orgId,
        facilityId,
        statementIds: issuableStatements.map((i) => i.meta.id),
      })
      await doneFn()
      closeFn()
    } catch (e) {
      setError(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <ConfirmModal
      title="Issue Statements?"
      denyButtonConfig={{
        onClick: closeFn,
        disabled: isSubmitting,
        isLoading: isSubmitting,
        children: 'Cancel',
      }}
      confirmButtonConfig={{
        onClick: onSubmit,
        disabled: isSubmitting || len === 0,
        isLoading: isSubmitting,
        buttonStyle: 'primary-fill',
        type: 'submit',
      }}
      content={
        <form onSubmit={onSubmit}>
          <div
            className={tw`mb-2 text-[14px] font-medium`}
          >{`${len} ${pluralize('statement', len)} will be issued.`}</div>
          {billingUpdates > 0 && (
            <div
              className={tw`text-[14px] font-medium`}
            >{`${billingUpdates} ${pluralize('statement', billingUpdates)} 
              ${pluralize('is', billingUpdates)} 
              for ${billingUpdates === 1 ? 'a resident' : 'residents'} 
              with open billing updates. Are you sure you would like to proceed?`}</div>
          )}
        </form>
      }
    />
  )
}
