import { useContext, useEffect, useState } from 'react'
import { listFacilities } from '@shared/api/facility'
import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { Loading } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import {
  isDirector as getIsDirector,
  isSuperUser as getIsSuperUser,
  getRelevantFacilities,
} from '@shared/utils/user'
import { getOrganization } from '@app/api/organizations'
import Content from '@app/components/generic/Content'
import HUD from '@app/components/HUD'
import FacilitiesContext from '@app/contexts/FacilitiesContext'
import useCareGroups from '@app/hooks/useCareGroups'
import useMedGroups from '@app/hooks/useMedGroups'
import SectionTitle from '@app/pages/Organizations/Settings/SectionTitle'
import { Props } from '../type'
import VersionInfo from '../VersionInfo'
import FacilityFormPopup from './FacilityFormPopup'
import FacilityGroupPopup from './FacilityGroupPopup'
import { FacilityGroupsPopupStateProps } from './FacilityGroupPopup/helpers'
import CareGroupTable from './FacilityGroupTable'
import FacilityTable from './FacilityTable'

export default function CommunitiesPage(props: Props) {
  const { facilities: allFacilities, setFacilities } =
    useContext(FacilitiesContext)
  const { setError } = useContext(GlobalContext)
  const { user } = useUserContext()
  const facilities = getRelevantFacilities({
    facilities: allFacilities,
    user,
  })
  const { match } = props
  const { orgId } = match.params
  const isDirector = getIsDirector({ user, orgId })
  const isSuperUser = getIsSuperUser(user)
  const [notification, setNotification] = useState<string>()
  const [organization, setOrganization] = useState<Loading<Organization>>({
    tag: 'Loading',
  })
  const [careGroupPopupData, setFacilityGroupPopupData] = useState<
    FacilityGroupsPopupStateProps | undefined
  >()
  const [facilityPopupData, setFacilityPopupData] = useState<
    { tag: 'Edit'; facility: Facility } | { tag: 'New' }
  >()
  const { careGroupsMap, reloadCareGroups } = useCareGroups({ facilities })
  const { medGroupsMap, reloadMedGroups } = useMedGroups({ facilities })
  const reloadFacilities = () => {
    return listFacilities(orgId)
      .then((res) => setFacilities(res))
      .catch(setError)
  }
  const reloadOrganization = () => {
    return getOrganization(orgId)
      .then((value) => setOrganization({ tag: 'Complete', value }))
      .catch(setError)
  }
  useEffect(() => {
    document.title = 'Communities - Org Settings'
  }, [])

  useEffect(() => {
    if (orgId) {
      if (!isDirector) {
        void reloadOrganization()
      }
      void reloadFacilities()
    }
  }, [orgId])

  const showFacilityForm = (facility?: Facility) => {
    setFacilityPopupData(facility ? { tag: 'Edit', facility } : { tag: 'New' })
  }

  return (
    <Content className={tw`px-0`}>
      <SectionTitle title="Communities" className={tw`mb-[8px] mt-[32px]`}>
        {isSuperUser && (
          <Button
            buttonStyle="primary-fill"
            buttonSize="xsmall"
            id="addFacilityButton"
            className="whitespace-nowrap"
            onClick={() => showFacilityForm()}
          >
            <i className="fas fa-home-lg-alt mr-[8px]" />
            Add New
          </Button>
        )}
      </SectionTitle>
      <FacilityTable
        facilities={facilities || []}
        showFacilityForm={showFacilityForm}
      />
      <SectionTitle
        title="Care & Med Groups"
        className={tw`mb-[8px] mt-[32px]`}
      />
      <CareGroupTable
        careGroupsMap={
          (careGroupsMap.tag === 'Complete' && careGroupsMap.value) || {}
        }
        medGroupsMap={
          (medGroupsMap.tag === 'Complete' && medGroupsMap.value) || {}
        }
        facilities={facilities || []}
        setCareGroupPopupData={setFacilityGroupPopupData}
      />
      <VersionInfo />
      {facilityPopupData && organization.tag === 'Complete' && (
        <FacilityFormPopup
          facility={
            facilityPopupData.tag === 'Edit'
              ? facilityPopupData.facility
              : undefined
          }
          organization={organization.value}
          onClose={() => setFacilityPopupData(undefined)}
          onSave={async () => {
            await reloadFacilities()
            setFacilityPopupData(undefined)
          }}
        />
      )}
      {careGroupPopupData && (
        <FacilityGroupPopup
          {...careGroupPopupData}
          onClose={async () => {
            await reloadCareGroups()
            await reloadMedGroups()
            setFacilityGroupPopupData(undefined)
          }}
        />
      )}
      {notification && (
        <HUD
          onExpire={() => setNotification(undefined)}
          className="animate-fade-in"
        >
          {notification}
        </HUD>
      )}
    </Content>
  )
}
