import { ReactNode } from 'react'

// CSS in src/styles/layout.css

export default function Content({
  className,
  children,
  title,
}: {
  className?: string
  children: ReactNode
  title?: string
}) {
  const classNameList = ['content-holder']
  if (className) {
    classNameList.push(className)
  }

  const pageTitle = title ? <h2 className="page-title">{title}</h2> : undefined

  return (
    <section className={classNameList.join(' ')}>
      {pageTitle}
      {children}
    </section>
  )
}
