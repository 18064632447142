import { AugustInitialAppraisal } from '@augusthealth/models/com/august/protos/august_initial_appraisal'
import {
  ServicePlan_PlanCategoryKey as CategoryKey,
  ServicePlan_PlanCategoryKey,
} from '@augusthealth/models/com/august/protos/service_plan'
import {
  AppraisalSettings_AppraisalCategory,
  AppraisalSettings_Level as Level,
} from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { intersection, isEqual, uniq } from 'lodash'
import { useEffect, useState } from 'react'
import environment from '@shared/environment'
import useCurrentPage, { extractIds } from '@shared/hooks/useCurrentPage'
import { FrontendDetail } from '@shared/types/assessment'
import { Person } from '@shared/types/person'
import { Snapshot } from '@shared/types/snapshot'
import notEmpty from '@shared/utils/notEmpty'
import {
  getLevelPath,
  makeCheckboxSections,
} from '@shared/utils/residentAssessment'
import { AssessmentChange } from './AssessmentPage/types'

export const CLAIBORNE_ORG_ID = environment.modifiedAssessmentOrgId

export interface BehaviorCustomization {
  disabledGroups?: DisabledGroup[]
  disabledDetails?: DisabledDetail[]
}

interface DisabledDetail {
  key: CategoryKey
  customKey: string | undefined
  details: FrontendDetail[]
}

interface DisabledGroup {
  key: ServicePlan_PlanCategoryKey
  matchers: RegExp[]
}

/**
 * A hook that manages the enabling/disabling of various sections/details based on the appraisal
 * Built for a customer of ours, Claiborne, that has a very specific set of rules for their appraisals
 * @param initialAppraisal
 * @param categories
 */
export default function useAssessmentModification({
  initialAssessment,
  categories,
}: {
  initialAssessment?: AugustInitialAppraisal
  categories: AppraisalSettings_AppraisalCategory[]
}) {
  const [disabledGroups, setDisabledGroups] = useState<DisabledGroup[]>([])
  const [disabledDetails, setDisabledDetails] = useState<DisabledDetail[]>([])

  const page = useCurrentPage()
  const { orgId } = extractIds(page)

  // This effect manages the enabling/disabling of various sections/details based on the appraisal
  useEffect(() => {
    if (orgId !== CLAIBORNE_ORG_ID) {
      return
    }

    const bathingCategory = categories.find(isBathing)
    const checkboxSections = bathingCategory
      ? makeCheckboxSections(bathingCategory)
      : new Map()

    // If bathing needs are none or minimal, disable the entire 'enhanced needs' section
    if (
      bathingNeedIs(initialAssessment, [Level.LEVEL_MINIMAL, Level.LEVEL_NONE])
    ) {
      disableEnhancedNeedsSection({ setDisabledGroups })
    }

    // If bathing needs are moderate, ensure the maximum needs are disabled
    if (bathingNeedIs(initialAssessment, [Level.LEVEL_MODERATE])) {
      enableEnhancedNeedsSection({ setDisabledGroups })

      disableEnhancedNeedsForMatchers({
        setDisabledDetails,
        checkboxSections,
        matchers: [/Maximum/i],
      })
    }

    // If the bathing needs are max, ensure moderate needs are disabled
    if (bathingNeedIs(initialAssessment, [Level.LEVEL_MAX])) {
      enableEnhancedNeedsSection({ setDisabledGroups })

      disableEnhancedNeedsForMatchers({
        setDisabledDetails,
        checkboxSections,
        matchers: [/Moderate/i],
      })
    }

    // Other categories
    categories
      .filter(
        (c) =>
          c.categoryKey !==
          ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_BATHING
      )
      .forEach((category) => {
        // Enable/disable details based on needs
        if (categoryNeedIs(category, initialAssessment, [Level.LEVEL_NONE])) {
          const checkboxSections = category
            ? makeCheckboxSections(category)
            : new Map()

          disableDetailsWithPoints({
            setDisabledDetails,
            checkboxSections,
            category,
          })
        }
      })
  }, [initialAssessment])

  /**
   * A function that returns a list of changes to be applied to the appraisal, based on the user's change.
   * Example: User checks 'Moderate' for bathing, we automatically add the moderate enhanced needs
   * @param detailChange
   */
  function assessmentChangeModifier(detailChange: AssessmentChange) {
    if (orgId !== CLAIBORNE_ORG_ID) {
      return []
    }

    const additionalChanges: AssessmentChange[] = []
    // Bathing rules:
    // 1. If none or minimal is selected, no enhanced needs can be added
    // 2. If moderate is selected, check the moderate assistance detail dropdown
    //    & disable the maximum assistance detail dropdown
    // 3. If maximum is selected, disable the moderate assistance detail dropdown

    if (detailChange.tag === 'NeedChange') {
      const checkboxSections = makeCheckboxSections(detailChange.category)

      const isNoneOrMinimal =
        detailChange.level === Level.LEVEL_NONE ||
        detailChange.level === Level.LEVEL_MINIMAL

      if (isBathing(detailChange.category) && isNoneOrMinimal) {
        // Remove all enhanced needs if they were checked
        additionalChanges.push(
          ...additionalAssessmentChanges({
            checkboxSections,
            detailChange,
            matchers: [/Moderate/i, /Maximum/i],
            change: 'Remove',
          })
        )

        // Mark enhanced needs as disabled
        disableEnhancedNeedsSection({ setDisabledGroups })

        // Remove disabled details, since the whole section is disabled
        setDisabledDetails((prevState) => [
          ...prevState.filter(
            (dg) => dg.key !== CategoryKey.PLAN_CATEGORY_KEY_BATHING
          ),
        ])
      } else if (
        isBathing(detailChange.category) &&
        detailChange.level === Level.LEVEL_MODERATE
      ) {
        // Add moderate enhanced needs once the moderate need is checked
        additionalChanges.push(
          ...additionalAssessmentChanges({
            checkboxSections,
            detailChange,
            matchers: [/Moderate/i],
            change: 'Add',
          }),
          ...additionalAssessmentChanges({
            checkboxSections,
            detailChange,
            matchers: [/Maximum/i],
            change: 'Remove',
          })
        )

        // Disable maximum details
        disableEnhancedNeedsForMatchers({
          setDisabledDetails,
          checkboxSections,
          matchers: [/Maximum/i],
        })

        // Remove disabled groups
        enableEnhancedNeedsSection({ setDisabledGroups })
      } else if (
        isBathing(detailChange.category) &&
        detailChange.level === Level.LEVEL_MAX
      ) {
        // Remove moderate enhanced needs if they were checked
        additionalChanges.push(
          ...additionalAssessmentChanges({
            checkboxSections,
            detailChange,
            matchers: [/Moderate/i],
            change: 'Remove',
          })
        )

        // Disable moderate details
        disableEnhancedNeedsForMatchers({
          setDisabledDetails,
          checkboxSections,
          matchers: [/Moderate/i],
        })
      } else {
        // Non-bathing categories
        const checkboxSections = makeCheckboxSections(detailChange.category)
        const isNone = detailChange.level === Level.LEVEL_NONE

        // If the category is none, disable all details with points
        // Also, remove them if they're selected
        if (isNone) {
          const disabledDetails = [...checkboxSections.values()]
            .flat()
            .filter((d) => {
              switch (d.tag) {
                case 'DetailWithCheckbox': {
                  return (d.value.score ?? 0) > 0
                }
                case 'DetailWithDropdown': {
                  return d.value.options.some((o) => (o.score ?? 0) > 0)
                }
                default:
                  return false
              }
            })

          setDisabledDetails((prevState) => [
            ...prevState.filter((dg) => {
              return (
                dg.key !== detailChange.category.categoryKey ||
                (dg.key ===
                  ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_CUSTOM &&
                  dg.customKey !== detailChange.category.customKey)
              )
            }),
            {
              key: detailChange.category.categoryKey!,
              customKey: detailChange.category.customKey,
              details: disabledDetails,
            },
          ])

          additionalChanges.push(
            ...additionalAssessmentChanges({
              checkboxSections,
              detailChange,
              matcherFn: (d) => {
                switch (d.tag) {
                  case 'DetailWithCheckbox': {
                    return (d.value.score ?? 0) > 0
                  }
                  case 'DetailWithDropdown': {
                    return d.value.options.some((o) => (o.score ?? 0) > 0)
                  }
                  default:
                    return false
                }
              },
              change: 'Remove',
            })
          )
        } else {
          setDisabledDetails((prevState) => [
            ...prevState.filter((dg) => {
              return (
                dg.key !== detailChange.category.categoryKey ||
                (dg.key ===
                  ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_CUSTOM &&
                  dg.customKey !== detailChange.category.customKey)
              )
            }),
            {
              key: detailChange.category.categoryKey!,
              customKey: detailChange.category.customKey,
              details: [],
            },
          ])
        }
      }
    }

    return additionalChanges
  }

  return {
    assessmentChangeModifier,
    disabledGroups,
    disabledDetails,
  }
}

function disableDetailsWithPoints({
  setDisabledDetails,
  checkboxSections,
  category,
}: {
  setDisabledDetails: React.SetStateAction<any>
  checkboxSections: Map<string, FrontendDetail[]>
  category: AppraisalSettings_AppraisalCategory
}) {
  const disabledDetails = [...checkboxSections.values()].flat().filter((d) => {
    switch (d.tag) {
      case 'DetailWithCheckbox': {
        return (d.value.score ?? 0) > 0
      }
      case 'DetailWithDropdown': {
        return d.value.options.some((o) => (o.score ?? 0) > 0)
      }
      default:
        return false
    }
  })

  setDisabledDetails((prevState: DisabledDetail[]) => [
    ...prevState.filter((dg) => {
      return (
        dg.key !== category.categoryKey ||
        (dg.key === CategoryKey.PLAN_CATEGORY_KEY_CUSTOM &&
          dg.customKey !== category.customKey)
      )
    }),
    {
      key: category.categoryKey!,
      details: disabledDetails,
      customKey: category.customKey,
    },
  ])
}

/**
 * Disables dropdown details that match the passed regular expressions
 * Example usage: disable 'maximum' enhanced needs when 'moderate' bathing needs are selected
 * @param setDisabledDetails
 * @param checkboxSections
 * @param matchers
 */
function disableEnhancedNeedsForMatchers({
  setDisabledDetails,
  checkboxSections,
  matchers,
}: {
  setDisabledDetails: React.SetStateAction<any>
  checkboxSections: Map<string, FrontendDetail[]>
  matchers: RegExp[]
}) {
  checkboxSections.forEach((value, key) => {
    if (/Enhanced Needs/i.test(key)) {
      const maximumDetails = value.filter((v) => {
        if (v.tag === 'DetailWithDropdown') {
          return matchers
            .map((r) => r.test(v.value.detailMetadata.description ?? ''))
            .some(Boolean)
        }

        return false
      })

      setDisabledDetails((prevState: DisabledDetail[]) => [
        ...prevState.filter(
          (dg) => dg.key !== CategoryKey.PLAN_CATEGORY_KEY_BATHING
        ),
        {
          key: CategoryKey.PLAN_CATEGORY_KEY_BATHING,
          details: maximumDetails,
        },
      ])
    }
  })
}

/**
 * Disables the entire 'enhanced needs' section
 * Example usage: When 'none' or 'minimal' bathing needs are selected
 * @param setDisabledGroups
 */
function disableEnhancedNeedsSection({
  setDisabledGroups,
}: {
  setDisabledGroups: React.SetStateAction<any>
}) {
  const enhancedNeedsGroupMatcher = /Enhanced Needs/i

  setDisabledGroups((prevState: DisabledGroup[]) => [
    ...prevState.filter(
      (dg) => dg.key !== CategoryKey.PLAN_CATEGORY_KEY_BATHING
    ),
    {
      key: CategoryKey.PLAN_CATEGORY_KEY_BATHING,
      matchers: [enhancedNeedsGroupMatcher],
    },
  ])
}

/**
 * Enables the entire 'enhanced needs' section
 * Example usage: When 'moderate' or 'maximum' bathing needs are selected
 * @param setDisabledGroups
 */
function enableEnhancedNeedsSection({
  setDisabledGroups,
}: {
  setDisabledGroups: React.SetStateAction<any>
}) {
  setDisabledGroups((prevState: DisabledGroup[]) => [
    ...prevState.filter(
      (dg) => dg.key !== CategoryKey.PLAN_CATEGORY_KEY_BATHING
    ),
  ])
}

/**
 * Builds a list of AppraisalChange objects depending on the passed regular expressions and verb
 * Example usage: Remove all 'moderate' enhanced needs when 'none' or 'minimal' bathing needs are selected
 * @param checkboxSections
 * @param detailChange
 * @param matchers
 * @param matcherFn
 * @param change
 */
function additionalAssessmentChanges({
  checkboxSections,
  detailChange,
  matchers = [],
  matcherFn,
  change,
}: {
  checkboxSections: Map<string, FrontendDetail[]>
  matchers?: RegExp[]
  matcherFn?: (d: FrontendDetail) => boolean
  detailChange: AssessmentChange
  change: 'Add' | 'Remove'
}) {
  const additionalChanges: AssessmentChange[] = []

  checkboxSections.forEach((value) => {
    value
      .filter((v) => {
        if (v.tag === 'DetailWithDropdown') {
          if (matcherFn) {
            return matcherFn(v)
          }

          return matchers
            .map((r) => r.test(v.value.detailMetadata.description ?? ''))
            .some(Boolean)
        } else if (v.tag === 'DetailWithCheckbox') {
          if (matcherFn) {
            return matcherFn(v)
          }
        }

        return false
      })
      .map((d) => {
        // TODO: Work with all detail types?
        if (
          d.tag === 'DetailWithDropdown' &&
          detailChange.tag === 'NeedChange'
        ) {
          if (change === 'Remove') {
            additionalChanges.push({
              tag: 'RemoveUniversal',
              category: detailChange.category,
              detail: d,
            })
          } else {
            additionalChanges.push({
              tag: 'AddUniversal',
              category: detailChange.category,
              detail: d,
              chosen: d.value.options[0],
            })
          }
        } else if (
          d.tag === 'DetailWithCheckbox' &&
          detailChange.tag === 'NeedChange'
        ) {
          if (change === 'Remove') {
            additionalChanges.push({
              tag: 'RemoveUniversal',
              category: detailChange.category,
              detail: d,
            })
          } else {
            additionalChanges.push({
              tag: 'AddUniversal',
              category: detailChange.category,
              detail: d,
            })
          }
        }
      })
  })

  return additionalChanges
}

export function hasAllAdditionalServicesDropdownDetails(
  assessment: Snapshot,
  person: Person
) {
  if (person.orgId !== CLAIBORNE_ORG_ID) {
    return true
  }

  const allCustomDetails =
    assessment?.data?.augustInitialAppraisal?.customDetails ?? []

  const servicesSettings =
    assessment.data?.augustInitialAppraisal?.settings?.categories?.find(
      (c) => c.categoryKey === CategoryKey.PLAN_CATEGORY_KEY_ADDITIONAL_SERVICES
    )

  const numberOfDropdownDetails = uniq(
    (servicesSettings?.customDetails ?? []).map((cd) => cd.answerGroup)
  ).length

  const answers = allCustomDetails
    .filter(
      (cd) =>
        cd.categoryKey === CategoryKey.PLAN_CATEGORY_KEY_ADDITIONAL_SERVICES
    )
    .map((cd) => (cd.fields ?? []).map((f) => f.id))
    .flat()
    .filter(notEmpty)

  return answers.length === numberOfDropdownDetails
}

/**
 * Returns true if the bathing need is moderate or max and the enhanced needs section is empty.
 * Only applicable for Claiborne.
 * @param assessment
 * @param person
 */
export function isMissingRequiredEnhancedNeed(
  assessment: Snapshot,
  person: Person
) {
  if (person.orgId !== CLAIBORNE_ORG_ID) {
    return false
  }

  const allCustomDetails =
    assessment?.data?.augustInitialAppraisal?.customDetails ?? []

  const hasFilledOutEnhancedNeed = allCustomDetails.some((wrapper) => {
    const bathingSettings =
      assessment.data?.augustInitialAppraisal?.settings?.categories?.find(
        (c) => c.categoryKey === CategoryKey.PLAN_CATEGORY_KEY_BATHING
      )
    const bathingDropdownCustomDetailIDs = (
      bathingSettings?.customDetails ?? []
    )
      .filter((cd) => cd.answerGroup)
      .map((cd) => cd.id)
      .filter(notEmpty)

    return (
      isBathing(wrapper) &&
      intersection(
        bathingDropdownCustomDetailIDs,
        wrapper.fields?.map((f) => f.id)
      ).length > 0
    )
  })

  return hasBathingNeed(assessment) && !hasFilledOutEnhancedNeed
}

export function hasNeedForEachCategory(
  assessment: Snapshot,
  categories: AppraisalSettings_AppraisalCategory[],
  person: Person
) {
  if (person.orgId !== CLAIBORNE_ORG_ID) {
    return true
  }

  const relevantCategories = categories.filter(
    (c) => (c.levels?.length ?? 0) > 0
  )

  const levelAnswers = relevantCategories
    .map((c) => assessment.data?.augustInitialAppraisal?.[getLevelPath(c)])
    .filter(notEmpty)
  const customCategoryLevelAnswers = (
    assessment?.data?.augustInitialAppraisal?.customDetails ?? []
  )
    .filter((c) => c.level)
    .filter(notEmpty)

  return (
    levelAnswers.length + customCategoryLevelAnswers.length ===
    relevantCategories.length
  )
}

/**
 * Controls the 'required' label next to sections for Claiborne
 * @param behaviorCustomization
 * @param categoryKey
 * @param sectionName
 * @param details
 */
export function checkboxSectionModifiers({
  person,
  behaviorCustomization,
  category,
  sectionName,
  details,
}: {
  person: Person
  details: FrontendDetail[]
  sectionName: string
  category: AppraisalSettings_AppraisalCategory
  behaviorCustomization: BehaviorCustomization
}) {
  if (person.orgId !== CLAIBORNE_ORG_ID) {
    return {
      showRequiredLabel: false,
      disabledDetailsForCategory: [],
      nameMatches: false,
    }
  }

  const { categoryKey, customKey } = category
  const { disabledGroups = [], disabledDetails = [] } = behaviorCustomization
  const matchers =
    disabledGroups.find((g) => g.key === categoryKey)?.matchers ?? []

  const nameMatches = matchers.reduce((accum, el) => {
    return accum || el.test(sectionName)
  }, false)

  const disabledDetailsForCategory =
    disabledDetails.find(
      (g) => g.key === categoryKey && g.customKey === customKey
    )?.details ?? []
  const hasDisabledDetail = details.some((detailData) =>
    disabledDetailsForCategory.some((d) => isEqual(d, detailData))
  )

  let showRequiredLabel: boolean = false
  switch (categoryKey) {
    case CategoryKey.PLAN_CATEGORY_KEY_BATHING: {
      showRequiredLabel = hasDisabledDetail && disabledDetails.length > 0
      break
    }
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_ADDITIONAL_SERVICES: {
      showRequiredLabel = /Details/i.test(sectionName)
    }
  }

  return {
    showRequiredLabel,
    disabledDetailsForCategory,
    nameMatches,
  }
}

function hasBathingNeed(assessment: Snapshot) {
  return (
    assessment.data?.augustInitialAppraisal?.bathing !== undefined &&
    [Level.LEVEL_MODERATE, Level.LEVEL_MAX].includes(
      assessment?.data?.augustInitialAppraisal?.bathing
    )
  )
}

function bathingNeedIs(
  assessment: AugustInitialAppraisal | undefined,
  levels: Level[]
) {
  return (
    assessment?.bathing !== undefined && levels.includes(assessment.bathing)
  )
}

function categoryNeedIs(
  category: AppraisalSettings_AppraisalCategory,
  assessment: AugustInitialAppraisal | undefined,
  levels: Level[]
) {
  if (
    category.categoryKey ===
    ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_CUSTOM
  ) {
    const levelValue = assessment?.customDetails?.find(
      (cd) =>
        cd.categoryKey ===
          ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_CUSTOM &&
        cd.customKey === category.customKey
    )?.level

    return levelValue && levels.includes(levelValue)
  }

  const levelPath = getLevelPath(category)
  return (
    assessment?.[levelPath] !== undefined &&
    levels.includes(assessment?.[levelPath])
  )
}

function isBathing(value: { categoryKey?: CategoryKey }) {
  return value.categoryKey === CategoryKey.PLAN_CATEGORY_KEY_BATHING
}
