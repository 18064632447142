import { useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { ModalTitle } from '@shared/components/baseMui/Modal/Layout'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { EmbossedCard } from '@shared/components/EmbossedCard'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Incident } from '@shared/types/incidents'
import { Person } from '@shared/types/person'
import { tw } from '@shared/utils/tailwind'
import { getIncident } from '@app/api/incidents'
import IncidentAttachment from '../IncidentAttachments'
import {
  createAttachments,
  getAttachmentError,
  IncidentFormData,
  selectNewOrEditedAttachments,
} from '../IncidentForm/incidentFormHelpers'

export default function AddDocumentsModal({
  incident,
  closeModal,
  person,
}: {
  incident: Incident
  closeModal: (updated?: Incident) => Promise<void>
  person: Person
}) {
  const methods = useForm<IncidentFormData>({
    mode: 'onChange',
    defaultValues: { attachments: [] },
  })
  const { formState, handleSubmit } = methods
  const { setError } = useContext(GlobalContext)

  async function onSubmit(formData: IncidentFormData) {
    try {
      if (formData.attachments) {
        const { id: incidentId } = incident

        const newOrEditedAttachments = selectNewOrEditedAttachments({
          incidentAttachments: incident?.attachments,
          newAttachments: formData.attachments,
        })

        const result = await createAttachments({
          incidentId: incidentId!,
          attachments: newOrEditedAttachments,
          person,
        })

        const failedAttachments: any = result.filter((r) => r.tag === 'Error')

        if (failedAttachments.length) {
          const attachmentError = getAttachmentError(failedAttachments)
          setError(attachmentError)
          return closeModal()
        }

        const updatedIncident = await getIncident({ incident, person })

        await closeModal(updatedIncident)
      }
    } catch (e) {
      setError(e)
    }
  }

  return (
    <Modal
      open={true}
      id={'add-edit-note'}
      onClose={() => closeModal()}
      contentClassName={tw`w-[600px] min-w-[600px] max-w-[600px]`}
    >
      <ModalTitle>Add Documents</ModalTitle>
      <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={formState.isSubmitting}>
          <EmbossedCard>
            <FormProvider {...methods}>
              <IncidentAttachment incident={incident} person={person} />
            </FormProvider>
          </EmbossedCard>
        </fieldset>
        <footer className="flex-start mt-[32px]">
          <AnimatedPopupFormFooter
            yesBtn={{
              label: 'Upload',
              props: {
                disabled:
                  !formState.isValid ||
                  !formState.isDirty ||
                  formState.isSubmitting,
                isLoading: formState.isSubmitting,
                ['data-testid']: 'add-attachments',
              },
            }}
            noBtn={{
              action: () => void closeModal(),
              label: 'Cancel',
            }}
            formState={formState}
          />
        </footer>
      </form>
    </Modal>
  )
}
