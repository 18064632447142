import { match } from 'ts-pattern'
import { VerticalBarColors } from '@shared/components/VerticalRoundedBar'
import { CHART_COLORS } from '@shared/constants/victory'
import {
  RoutineAdministration,
  RoutineAdministrationProgressType,
} from '@shared/types/careapp'
import {
  routineAdminIsAdministered,
  routineAdminIsException,
  routineAdminIsOverDue,
  routineAdminIsUnadministered,
} from './careapp'

export function getIconNameAndClassByStatus(
  type: RoutineAdministrationProgressType
): {
  barColorClass: VerticalBarColors
  bgColor: string
  iconName: string
  iconClass: string
} {
  const { BAR_FILL } = CHART_COLORS

  switch (type) {
    case 'Administered':
      return {
        barColorClass: 'tags-happy',
        bgColor: BAR_FILL.SUCCESS,
        iconName: 'circle-check',
        iconClass: 'text-tags-happy',
      }
    case 'Overdue':
      return {
        barColorClass: 'tags-red',
        bgColor: BAR_FILL.DANGER,
        iconName: 'siren-on',
        iconClass: 'text-tags-red',
      }
    case 'Exception':
      return {
        barColorClass: 'pill-warning',
        bgColor: BAR_FILL.WARNING,
        iconName: 'circle-xmark',
        iconClass: 'text-pill-warning',
      }
    case 'Unadministered':
      return {
        barColorClass: 'mute',
        bgColor: BAR_FILL.MUTED,
        iconName: 'hourglass-start',
        iconClass: 'mute-text',
      }
  }
}

type SummaryTitle = 'Completed' | 'Exceptions' | 'Overdue'

export function getSummaryTitle(
  progressType: RoutineAdministrationProgressType
): SummaryTitle | undefined {
  switch (progressType) {
    case 'Administered':
      return 'Completed'
    case 'Exception':
      return 'Exceptions'
    case 'Overdue':
      return 'Overdue'
    case 'Unadministered':
      return undefined
  }
}

export function getProgressTypeLabel(
  progressType: RoutineAdministrationProgressType
) {
  return match(progressType)
    .with('Exception', () => 'Exceptions')
    .with('Overdue', () => 'Overdue Routines')
    .with('Administered', () => 'Completed Routines')
    .with('Unadministered', () => 'Not Started Routines')
    .exhaustive()
}

export const administrationIsOneOfSelectedProgressTypes =
  (
    selectedAdministrationProgressTypes: RoutineAdministrationProgressType[],
    facilityTimeZone: string
  ) =>
  (administration: RoutineAdministration) => {
    return selectedAdministrationProgressTypes.some((progressType) => {
      switch (progressType) {
        case 'Exception':
          return routineAdminIsException(administration)
        case 'Overdue':
          return routineAdminIsOverDue(administration, facilityTimeZone)
        case 'Unadministered':
          return routineAdminIsUnadministered(administration, facilityTimeZone)
        case 'Administered':
          return routineAdminIsAdministered(administration)
      }
    })
  }
