import { useState } from 'react'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { tw } from '@shared/utils/tailwind'
import {
  ModalOverlays,
  ModalOverlaysParagraph,
} from '@app/components/ModalOverlays'

export function ConfirmMakeChangesOverlays({
  onConfirm,
  onCancel,
}: {
  onConfirm: () => Promise<void>
  onCancel: () => void
}) {
  const [isConfirming, setIsConfirming] = useState(false)

  return (
    <ModalOverlays
      data-testid={'confirmMakeChanges'}
      title="Are you sure you want to make changes?"
    >
      <ModalOverlaysParagraph className={tw`w-[500px]`}>
        This will cancel existing signatures and re-open the admission task with
        your last edits.
      </ModalOverlaysParagraph>
      <div className={'mt-[32px]'}>
        <AsyncIconButton
          onClick={onCancel}
          buttonStyle={'secondary-outline'}
          width={'170px'}
          className={tw`mr-[16px]`}
        >
          Cancel
        </AsyncIconButton>
        <AsyncIconButton
          isLoading={isConfirming}
          disabled={isConfirming}
          buttonStyle={'danger-fill'}
          width={'170px'}
          data-testid={'makeChangesImSure'}
          onClick={async () => {
            setIsConfirming(true)
            await onConfirm()
          }}
        >
          Make Changes
        </AsyncIconButton>
      </div>
    </ModalOverlays>
  )
}
