import { format } from 'date-fns'
import React from 'react'
import { detailsPathForPerson } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { getFirstAndLastName } from '@shared/utils/humanName'
import notEmpty from '@shared/utils/notEmpty'
import {
  displayGender,
  displayResidentStatus,
  getBirthDate,
  isDischargedResident,
  isProspect,
  isProspectClosed,
  isResident,
} from '@shared/utils/person'
import pluralize from '@shared/utils/pluralize'
import { tw } from '@shared/utils/tailwind'
import Warning from '@app/components/Warning'

export default function SamePeopleWarning({ people }: { people: Person[] }) {
  const peopleList = people.map((p, i) => {
    const gender = displayGender(p)
    const dob = getBirthDate(p)
    const lastIndex = people.length - 1
    // no commas prefix for first person, use ' and ' before last person, use ', ' inbetween
    const prefix = i === 0 ? '' : i === lastIndex ? ' and ' : ', '
    const fullName = getFirstAndLastName(p.name)
    const domList: React.ReactNode[] = [
      <span key={`same-name-prefix-${i}`}>{prefix}</span>,
      <a
        key={`same-name-fullname-${i}`}
        title={displayResidentStatus(p)}
        style={{ cursor: 'help' }}
        target="_blank"
        rel="noopener noreferrer"
        href={detailsPathForPerson(p as Required<Person>)}
        className={tw`cursor-help font-bold`}
      >
        {fullName}
      </a>,
    ]
    const dobGenderLabel = [
      gender && gender.toLowerCase(),
      dob && format(dob, 'M/d/yyyy'),
    ]
      .filter(notEmpty)
      .join(', ')
    if (dobGenderLabel) {
      domList.push(
        <span key={`same-name-dobGender-${i}`}>{` (${dobGenderLabel})`}</span>
      )
    }
    return domList
  })
  const hasProspect = people.some((p) => isProspect(p) || isProspectClosed(p))
  const hasResident = people.some(
    (p) => isResident(p) || isDischargedResident(p)
  )
  const len = peopleList.length
  const moveInResidentLabel = [
    hasProspect ? 'move-in' : undefined,
    hasResident ? 'resident' : undefined,
  ]
    .filter(notEmpty)
    .join('/')

  return (
    <Warning className="mt-[12px]">
      It appears that you have {pluralize(moveInResidentLabel, len, true)}{' '}
      called {peopleList}. Continue?
    </Warning>
  )
}
