import { useSearchParams } from 'react-router-dom-v5-compat'

const SEARCH_TEXT_KEY = 'searchText'

export default function useSearchTextParam() {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchText = searchParams.get(SEARCH_TEXT_KEY) ?? ''

  const setSearchText = (nextSearchText: string) => {
    setSearchParams((prev) => {
      if (nextSearchText === '') {
        prev.delete(SEARCH_TEXT_KEY)
      } else {
        prev.set(SEARCH_TEXT_KEY, nextSearchText)
      }

      return prev
    })
  }

  return [searchText, setSearchText] as const
}
