import { AugustInitialAppraisal } from '@augusthealth/models/com/august/protos/august_initial_appraisal'
import { AppraisalSettings_AssessmentReasonWrapper } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { useContext, useState } from 'react'
import { RequiredLabel } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { assessmentReasonToDisplay } from '@shared/utils/assessmentConfiguration'
import styles from './styles.module.css'
import { AssessmentChange } from './AssessmentPage/types'

export default function AssessmentReason({
  disabled: initialDisabledValue,
  assessment,
  saveAppraisal,
  showRequiredError,
}: {
  disabled: boolean
  assessment: AugustInitialAppraisal
  saveAppraisal: (appraisal: AssessmentChange) => Promise<void>
  showRequiredError: boolean
}) {
  const { setError } = useContext(GlobalContext)
  const assessmentReasons: AppraisalSettings_AssessmentReasonWrapper[] =
    assessment.settings?.assessmentReasons ?? []

  const getInitialAssessmentReason =
    (): OptionTypeBase<AppraisalSettings_AssessmentReasonWrapper> | null => {
      if (assessment?.assessmentReason) {
        return {
          label: assessmentReasonToDisplay(assessment.assessmentReason) ?? '',
          value: assessment.assessmentReason,
        }
      } else {
        return null
      }
    }

  const [assessmentReason, setAssessmentReason] =
    useState<OptionTypeBase<AppraisalSettings_AssessmentReasonWrapper> | null>(
      getInitialAssessmentReason
    )
  const [disabled, setDisabled] = useState(initialDisabledValue)

  if (assessmentReasons.length === 0) {
    return null
  }

  const onChange = async (
    newValue: OptionTypeBase<AppraisalSettings_AssessmentReasonWrapper>
  ) => {
    try {
      setDisabled(true)
      setAssessmentReason(newValue)
      await saveAppraisal({
        tag: 'AssessmentReasonChange',
        assessmentReason: newValue.value,
      })
    } catch (e) {
      setError(e)
    } finally {
      setDisabled(initialDisabledValue)
    }
  }

  const options = assessmentReasons.map((ar) => ({
    label: assessmentReasonToDisplay(ar),
    value: ar,
  }))

  return (
    <div className={'mb-[40px]'}>
      <h2 className={[styles.h2, 'mb-[16px]'].join(' ')}>
        <i className={`fas fa-square ${styles.square}`} />
        Assessment Reason
        <RequiredLabel showError={showRequiredError} />
      </h2>
      <StyledSelect
        isDisabled={disabled}
        options={options}
        onChange={onChange}
        value={assessmentReason}
      />
    </div>
  )
}
