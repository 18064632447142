import { useEffect, useState } from 'react'
import { MedicationOrder } from '@shared/types/medication_order'
import { Person } from '@shared/types/person'
import notEmpty from '@shared/utils/notEmpty'
import BreadcrumbEntry from '@app/components/Breadcrumbs/BreadcrumbEntry'
import {
  Breadcrumb,
  buildPersonCrumb,
  facilityCrumb,
} from '@app/components/Breadcrumbs/helpers'
import {
  BreadcrumbContainer,
  BreadcrumbLinksContainer,
} from '@app/components/Breadcrumbs/layout'
import { getMedicationBreadCrumbText } from '@app/components/Residents/Medications/Orders/helpers'
import { usePersonTabTitle } from '@app/hooks/usePersonTabTitle'

type Props = {
  person: Person
  medicationOrder?: MedicationOrder
  useOrderTerminology?: boolean
}

/**
 * Used on the Edit Medication (v1 and v2) and Pending Orders screens
 * Needed to accurately reflect the pending status of meds (discontinue, update, etc)
 * @param person
 * @param medicationOrder
 * @param useOrderTerminology - true if crumbs should read 'Order', false if crumbs should read 'Medications' for meds 1.0
 * @constructor
 */
export const MedicationBreadcrumbs = ({
  person,
  medicationOrder,
  useOrderTerminology = true,
}: Props) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([])

  usePersonTabTitle(breadcrumbs)

  useEffect(() => {
    if (person) {
      const facilityBreadcrumb = facilityCrumb({
        person,
        orgId: person.orgId,
        facilityId: person.facilityId,
      })
      const personCrumb = buildPersonCrumb({
        page: {
          tag: 'Person',
          tab: {
            tag: 'Medications',
          },
          orgId: person.orgId,
          facilityId: person.facilityId,
          personId: person.id,
        },
        person,
      })

      const medicationCrumb = medicationOrder
        ? {
            text: getMedicationBreadCrumbText(
              medicationOrder,
              useOrderTerminology
            ),
            url: '#',
          }
        : undefined

      setBreadcrumbs(
        [facilityBreadcrumb, personCrumb, medicationCrumb].filter(notEmpty)
      )
    }
  }, [person, medicationOrder])

  return (
    <BreadcrumbContainer>
      <BreadcrumbLinksContainer>
        {breadcrumbs.map((breadcrumb, index) => (
          <BreadcrumbEntry
            key={breadcrumb.text + index}
            breadcrumb={breadcrumb}
            index={index}
          />
        ))}
      </BreadcrumbLinksContainer>
    </BreadcrumbContainer>
  )
}
