import { jwtDecode } from 'jwt-decode'
import { isEmpty } from 'lodash'
import { useContext, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { match, P } from 'ts-pattern'
import { unsubscribe } from '@shared/api/unsubscribe'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import LogoHeader from '@shared/components/LogoHeader'
import GlobalContext from '@shared/contexts/GlobalContext'
import useQuery from '@shared/hooks/useQuery'
import {
  JwtContents,
  subscribed,
  Subscription,
  unsubcribed,
  UnsubscribeJwt,
} from '@shared/pages/Unsubscribe/types'
import { AsyncResult, LOADING } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'

export default function Unsubscribe() {
  const { setError } = useContext(GlobalContext)
  const [subscription, setSubscription] =
    useState<AsyncResult<Subscription, unknown>>(subscribed)
  const params = useQuery()
  const token = params.get('code')

  if (isEmpty(token) || token === null) {
    return <Redirect to={'/'} />
  }

  const onUnsubscribeClick = async () => {
    setSubscription(LOADING)
    const result = await unsubscribe({ code: token })

    if (result.tag === 'Complete') {
      setSubscription(unsubcribed)
    } else {
      setError(result.value)
      setSubscription(subscribed)
    }
  }

  if (
    subscription.tag === 'Complete' &&
    subscription.value === 'Unsubscribed'
  ) {
    return (
      <div className={tw`flex max-w-[400px] flex-col`}>
        <LogoHeader title={"You've been unsubscribed."} />
        <AsyncIconButton
          buttonStyle={'secondary-outline'}
          className={tw`mt-4`}
          onClick={() => {
            window.location.href = '/'
          }}
        >
          Back to August Health
        </AsyncIconButton>
      </div>
    )
  }

  return (
    <div className={tw`flex max-w-[400px] flex-col`}>
      <LogoHeader title={'Unsubscribe from emails?'} />
      <p
        className={tw`mt-6  text-justify text-sm font-medium text-secondary-06`}
      >
        August Health is a platform used by assisted living communities to
        communicate with residents and their families. By unsubscribing, you may
        miss important information about your loved one.
      </p>
      <p className={tw`mt-4 text-sm font-medium text-secondary-06`}>
        You may also{' '}
        <a href="mailto:support@augusthealth.com">contact support</a> if you'd
        like to speak with someone at August Health about this message.
      </p>
      <UnsubscribeCopy />
      <AsyncIconButton
        buttonStyle={'secondary-outline'}
        className={tw`mt-6`}
        onClick={() => {
          window.location.href = '/'
        }}
      >
        Back to August Health
      </AsyncIconButton>
      <AsyncIconButton
        isLoading={subscription.tag === 'Loading'}
        disabled={subscription.tag === 'Loading'}
        buttonStyle={'primary-fill'}
        className={tw`mt-2`}
        onClick={() => onUnsubscribeClick()}
      >
        Unsubscribe
      </AsyncIconButton>
    </div>
  )
}

function UnsubscribeCopy() {
  const params = useQuery()
  const token = params.get('code')

  if (isEmpty(token) || token === null) {
    return <Redirect to={'/'} />
  }

  const decoded = jwtDecode<UnsubscribeJwt>(token)
  const maybeFacility = decoded.facilityName
  const maybeEmail = decoded.sub
  const decodedValues: JwtContents = [maybeEmail, maybeFacility]

  const body = match(decodedValues)
    .with(
      [P.string.includes('@'), P.string.minLength(1)],
      ([email, facility]) => {
        return (
          <>
            This email was sent to {emphasize(email)} on behalf of{' '}
            {emphasize(facility)}.
          </>
        )
      }
    )
    .with([P.string.includes('@'), P._], ([email]) => {
      return <>This email was sent to {emphasize(email)}.</>
    })
    .with([P._, P.string.minLength(1)], ([_, facility]) => {
      return <>This email was sent on behalf of {emphasize(facility)}.</>
    })
    .otherwise(() => null)

  if (body === null) {
    return null
  }

  return (
    <p className={tw`mt-4 text-sm font-medium text-secondary-06`}>{body}</p>
  )
}

const emphasize = (text: string) => (
  <span className={tw`font-bold text-secondary-01`}>{text}</span>
)
