import { Redirect, Switch } from 'react-router-dom'
import { CARE_APP_ROUTE_BASE_PATH } from '@shared/routes'
import { UserAccount } from '@shared/types/user'
import { ExternalRedirect } from '@shared/utils/routing'
import {
  belongsToMultipleOrgs,
  hasBillingRole,
  hasCaregiverPlusEhrRole,
  hasCaregiverRole,
  hasDirectorRole,
  hasExternalRole,
  hasFacilitySalesAndMarketingRole,
  hasFacilityStaffRole,
  hasFacilityUserManagementRole,
  hasFrontDeskRole,
  hasMedTechRole,
  hasMedTechSupervisorRole,
  hasNurseRole,
  hasOrgAdminRole,
  hasOrganizationUserManagementRole,
  hasPayerRole,
  hasPharmacistRole,
  hasResponsiblePersonRole,
  hasSalesAndMarketingPlusBillingRole,
  hasSocialWorkerRole,
  isAnalyst,
  isSuperUser,
  isToolsUser,
} from '@shared/utils/user'
import {
  admissionTasksRouteForPayer,
  externalSignerRoute,
  facilitiesRoute,
  facilityRoute,
  orgSettingsUsersRoute,
  personDetailsRoute,
  prospectRoute,
} from './groupBasedRouting'

export default function RootPathRouter({ user }: { user: UserAccount }) {
  if (hasCaregiverRole(user)) {
    return <ExternalRedirect to={CARE_APP_ROUTE_BASE_PATH} />
  }

  return (
    <Switch>
      {(isToolsUser(user) || isAnalyst(user)) && <Redirect to="/tools" />}
      {(isSuperUser(user) || belongsToMultipleOrgs(user)) && (
        <Redirect to={'/orgs'} />
      )}
      {hasOrgAdminRole(user) && <Redirect to={facilitiesRoute(user)} />}
      {hasDirectorRole(user) && <Redirect to={facilityRoute(user)} />}
      {hasFacilitySalesAndMarketingRole(user) && (
        <Redirect to={facilityRoute(user)} />
      )}
      {hasSalesAndMarketingPlusBillingRole(user) && (
        <Redirect to={facilityRoute(user)} />
      )}
      {hasFacilityStaffRole(user) && <Redirect to={facilityRoute(user)} />}
      {hasSocialWorkerRole(user) && <Redirect to={personDetailsRoute(user)} />}
      {hasResponsiblePersonRole(user) && <Redirect to={prospectRoute(user)} />}
      {hasBillingRole(user) && <Redirect to={facilityRoute(user)} />}
      {hasPharmacistRole(user) && <Redirect to={facilityRoute(user)} />}
      {hasNurseRole(user) && <Redirect to={facilityRoute(user)} />}
      {hasMedTechSupervisorRole(user) && <Redirect to={facilityRoute(user)} />}
      {hasMedTechRole(user) && <Redirect to={facilityRoute(user)} />}
      {hasPayerRole(user) && (
        <Redirect to={admissionTasksRouteForPayer(user)} />
      )}
      {hasFrontDeskRole(user) && <Redirect to={facilityRoute(user)} />}
      {hasExternalRole(user) && <Redirect to={externalSignerRoute(user)} />}
      {hasCaregiverPlusEhrRole(user) && <Redirect to={facilityRoute(user)} />}
      {hasOrganizationUserManagementRole(user) && (
        <Redirect to={orgSettingsUsersRoute(user)} />
      )}
      {hasFacilityUserManagementRole(user) && (
        <Redirect to={orgSettingsUsersRoute(user)} />
      )}
    </Switch>
  )
}
