import { SettingsType } from '@augusthealth/models/com/august/protos/org_facility_settings'
import { useState } from 'react'
import Card from '@shared/components/Card'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { ScheduledTaskSettings } from '@shared/types/scheduled_task_settings'
import { TaskDefinitionSettings } from '@shared/types/task_definition_settings'
import useSettingsByKey from '@app/hooks/useSettingsByKey'
import { ScheduledTaskCard } from '@app/pages/Tools/ScheduledTasks/ScheduledTaskCard'
import { OrgFacilitySelects } from '../SharedSelects/OrgFacilitySelects'

export default function ScheduledTasks() {
  const [selectedOrganization, setSelectedOrganization] =
    useState<OptionTypeBase<Organization> | null>(null)
  const [selectedFacility, setSelectedFacility] =
    useState<OptionTypeBase<Facility> | null>(null)

  const { settings: scheduledTaskSettings } =
    useSettingsByKey<ScheduledTaskSettings>({
      facility: selectedFacility?.value,
      settingsType: SettingsType.SETTINGS_TYPE_SCHEDULED_TASK,
    })

  /**
   * Fetch task settings, so we can match scheduled tasks with their task definitions
   * Used to get the display name for the scheduled tasks
   */
  const { settings: taskSettings } = useSettingsByKey<TaskDefinitionSettings>({
    facility: selectedFacility?.value,
    settingsType: SettingsType.SETTINGS_TYPE_TASK_DEFINITION,
  })

  const isLoading =
    scheduledTaskSettings.tag === 'Loading' || taskSettings.tag === 'Loading'

  return (
    <div className="content-holder">
      <h4 className="page-title mt-[24px]">Scheduled Tasks</h4>
      <div className={'flex justify-between'}>
        <div>
          <Card className="mb-[16px] flex flex-col">
            <OrgFacilitySelects
              orgLabel={'I want to view scheduled task settings for...'}
              onOrgSelect={setSelectedOrganization}
              onFacilitySelect={setSelectedFacility}
              selectedOrganization={selectedOrganization}
              selectedFacility={selectedFacility}
            />
          </Card>
        </div>
        <div className={'ml-[32px] grow'}>
          {!isLoading &&
            scheduledTaskSettings.value.map((setting) => (
              <ScheduledTaskCard
                taskSettings={taskSettings.value}
                settings={setting}
                key={setting.key}
              />
            ))}
          {scheduledTaskSettings.tag === 'Complete' &&
            scheduledTaskSettings.value.length === 0 && (
              <Card>No scheduled tasks found</Card>
            )}
          {isLoading && selectedFacility && <Card>Loading...</Card>}
        </div>
      </div>
    </div>
  )
}
