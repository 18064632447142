import { ReactNode, useContext, useState } from 'react'
import { fetchTasks } from '@shared/api/task'
import Lightbox, {
  LightboxSidebarContent,
  LightboxSidebarFooter,
} from '@shared/components/AnimatedPopup/Lightbox/Lightbox'
import GlobalContext from '@shared/contexts/GlobalContext'
import { GettingToKnowYouForm } from '@app/components/Prospects/Forms/GettingToKnowYou'
import {
  calculateLevel,
  calculateTotalScore,
  getLevelOfCarePills,
  LevelOfCareForm,
} from '@app/components/Prospects/Forms/LevelOfCare'
import PersonContext from '@app/contexts/PersonContext'
import { MatchParams } from '../../FormLayout/type'
import ConfirmLightbox, {
  Props as ConfirmationLightboxProps,
} from './ConfirmLightbox'
import LightboxHeader from './LightboxHeader'
import SubmitButton from './SubmitButton'

type Props = {
  params: MatchParams
  onClose: () => void
}

/**
 * Task Review Lightbox for GettingToKnowYou and (Old) LevelofCare
 */

export default function PdfPopup(props: Props) {
  const [confirmLightboxData, setConfirmLightboxData] =
    useState<ConfirmationLightboxProps | null>(null)
  const [titleDecorator, setTitleDecorator] = useState<ReactNode>()
  const { params, onClose } = props
  const { orgId, id: pId, taskId } = params
  const { setError } = useContext(GlobalContext)
  const { tasks = [], person, setTasks } = useContext(PersonContext)
  const task = tasks.find((t) => t.id === taskId)
  if (!task || !person) {
    return null
  }

  const updateTasks = () => {
    fetchTasks({ orgId, pId })
      .then((res) => setTasks(res.data))
      .catch(setError)
  }
  let content
  const taskType = task.taskTemplateInfo?.taskType
  const matchProps = { params: { ...params, taskId: task.id } }
  if (taskType === 'TASK_TYPE_GETTING_TO_KNOW_YOU') {
    content = (
      <GettingToKnowYouForm
        onDataChange={updateTasks}
        match={matchProps}
        readOnly
      />
    )
  } else if (taskType === 'TASK_TYPE_LEVEL_OF_CARE') {
    const admissionType = person.admissionsInformation?.admissionType
    const onDataChange = ({ data }) => {
      const totalScore = calculateTotalScore({ data })
      const level = calculateLevel({ totalScore, admissionType })
      setTitleDecorator(
        <div className="level-of-care-flex">
          {getLevelOfCarePills({ totalScore, level })}
        </div>
      )
      updateTasks()
    }
    content = (
      <LevelOfCareForm
        match={matchProps}
        onDataChange={onDataChange}
        readOnly
      />
    )
  }

  if (confirmLightboxData) {
    return (
      <ConfirmLightbox
        {...confirmLightboxData}
        onClose={() => setConfirmLightboxData(null)}
      />
    )
  } else {
    return (
      <Lightbox
        mainContent={content}
        sidebarContent={
          <>
            <LightboxSidebarContent>
              <LightboxHeader params={params} titleDecorator={titleDecorator} />
            </LightboxSidebarContent>
            <LightboxSidebarFooter className="flex-col">
              <SubmitButton
                params={params}
                setConfirmLightboxData={setConfirmLightboxData}
                onClose={onClose}
              />
            </LightboxSidebarFooter>
          </>
        }
        mainContentBackgroundColor="white"
        onClose={onClose}
      />
    )
  }
}
