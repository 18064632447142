import { Contact } from '@augusthealth/models/com/august/protos/contact'
import { Option } from 'effect'
import React, { useEffect } from 'react'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { PayerSettingsData } from '@shared/types/billing'
import { RequiredPersonIds } from '@shared/types/person'
import useRainforestPaymentMethodConfig from '@app/hooks/useRainforestPaymentMethodConfig'

/**
    TODO:
    1. Can we pass Person & Contact instead of IDs?
*/
export type Params = {
  orgId: string
  facilityId: string
  personId: string
  contact: Contact
  closeFn: () => void
  reloadBillingSummary: () => void
}

type SavedPaymentMethodEvent = {
  data: SavedPaymentMethod
}

type SavedPaymentMethod = {
  payment_method_id: string
  method_type: string
  ach: ACH
}

type ACH = {
  account_number_last_4?: string
  routing_number?: string
  bank_name?: string
  account_type?: string
  account_holder_type?: string
}

interface Props {
  contactId: Option.Option<string>
  personIds: RequiredPersonIds
  payerSettings: PayerSettingsData
  onClose: () => void
  submitFn: (data: PayerSettingsData) => Promise<boolean>
  isOpen: boolean
}

export function PaymentDetailsModal({
  isOpen,
  contactId,
  personIds,
  payerSettings,
  onClose,
  submitFn,
}: Props) {
  const { orgId, facilityId, id: personId } = personIds
  const { paymentMethodConfig } = useRainforestPaymentMethodConfig({
    orgId,
    facilityId,
    personId,
    contactId,
  })

  async function savePaymentMethod(
    data: CustomEvent<Array<SavedPaymentMethodEvent>>
  ) {
    const paymentData = data.detail[0].data
    const updatedSettings: PayerSettingsData = {
      ...payerSettings,
      paymentMethod: {
        paymentMethodId: paymentData.payment_method_id,
        methodType: paymentData.method_type,
        ach: {
          accountNumberLast4: paymentData.ach.account_number_last_4,
          routingNumber: paymentData.ach.routing_number,
          bankName: paymentData.ach.bank_name,
          accountType: paymentData.ach.account_type,
          accountHolderType: paymentData.ach.account_holder_type,
        },
      },
    }
    const success = await submitFn(updatedSettings)
    if (success) {
      onClose()
    }
  }
  return (
    <Modal id="payment-details-modal" onClose={onClose} open={isOpen}>
      {paymentMethodConfig.tag === 'Loading' ? (
        <div>Loading...</div>
      ) : (
        <RainforestPayment
          sessionKey={paymentMethodConfig.value.sessionKey}
          paymentMethodConfigId={
            paymentMethodConfig.value.paymentMethodConfigId
          }
          savePaymentMethod={savePaymentMethod}
        />
      )}
    </Modal>
  )
}

function RainforestPayment({
  sessionKey,
  paymentMethodConfigId,
  savePaymentMethod,
}: {
  sessionKey: string
  paymentMethodConfigId: string
  savePaymentMethod: (data: CustomEvent<Array<SavedPaymentMethodEvent>>) => void
}) {
  useEffect(() => {
    const embeddedComponent = document.querySelector('rainforest-payment')
    if (embeddedComponent) {
      embeddedComponent.addEventListener('approved', savePaymentMethod)
    }

    return () => {
      if (embeddedComponent) {
        embeddedComponent.removeEventListener('approved', savePaymentMethod)
      }
    }
  }, [paymentMethodConfigId])

  return React.createElement('rainforest-payment', {
    'session-key': sessionKey,
    'payment-method-config-id': paymentMethodConfigId,
    'allowed-methods': 'ACH',
    'style-button-color': '#007580',
    'style-font-family': 'Inter',
  })
}
