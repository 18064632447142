import ButtonLink from '@shared/components/ButtonLink'
import styles from '../list.module.css'

type PlaceholderProps = {
  text: string
  action?: (open: boolean) => void
  showImage: boolean
  isResident?: boolean
}
export const Placeholder = ({
  text,
  action,
  showImage,
  isResident = false,
}: PlaceholderProps) => (
  <div className={styles.emptyResidents}>
    {showImage && (
      <img src="/svg/people.svg" alt="Smiling people illustration" />
    )}
    <div className={styles.emptyCopy}>{text}</div>
    {action && (
      <div className={`${styles.emptyCopy} mt-[4px]`}>
        To get started,{' '}
        <ButtonLink onClick={() => action(true)}>
          add a new {isResident ? 'resident' : 'move-in'}.
        </ButtonLink>
      </div>
    )}
  </div>
)
