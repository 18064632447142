import styles from './styles.module.css'

export default function PieChartIcon({
  percent,
  size = 16,
}: {
  percent: number
  size?: number
}) {
  const pieStyle = {
    '--percent': `${percent}%`,
    '--pie-bg-color': bgColorForPercent(percent),
    '--pie-wedge-color': strokeColorForPercent(percent),
    '--pie-size': `${size}px`,
  } as React.CSSProperties

  return (
    <div
      data-cy="completion-pie-chart"
      className={styles.pieChart}
      style={pieStyle}
    />
  )
}

export const bgColorForPercent = (pct: number) => {
  if (pct < 50) {
    return 'rgba(204, 102, 0, 0.2)'
  } else if (pct < 75) {
    return 'rgba(82, 82, 204, 0.2)'
  } else {
    return 'rgba(61, 143, 75, 0.2)'
  }
}

export const strokeColorForPercent = (pct: number) => {
  if (pct < 50) {
    return 'rgba(204, 102, 0)'
  } else if (pct < 75) {
    return 'rgba(82, 82, 204)'
  } else {
    return 'rgba(61, 143, 75)'
  }
}
