import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  BasicCheckbox,
  BasicInput,
} from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput } from '@shared/components/Labels'
import { DetailModalForm } from '@app/pages/Tools/AssessmentConfiguration/DetailGroups/AddEditDetailModal/AddEditDetailModal'
import styles from '@app/pages/Tools/AssessmentConfiguration/styles.module.css'

export const TextInputDetail = () => {
  const { register, unregister } = useFormContext<DetailModalForm>()

  useEffect(() => {
    return () => {
      unregister(['description', 'helpText', 'isRequired'])
    }
  }, [])
  return (
    <>
      <LabelAboveInput htmlFor={'description'}>Description</LabelAboveInput>
      <BasicInput
        type={'text'}
        placeholder={'Description'}
        {...register('description')}
      />

      <LabelAboveInput htmlFor={'helpText'}>Help Text</LabelAboveInput>
      <BasicInput
        type={'text'}
        placeholder={'Help text'}
        {...register('helpText')}
      />
      <div className={styles.requiredCheckbox}>
        <BasicCheckbox {...register('isRequired')}>Required</BasicCheckbox>
      </div>
    </>
  )
}
