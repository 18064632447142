import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import COMMON_LANGUAGES_BY_COUNTRY from '@shared/constants/commonLanguagesByCountry.json'
import LANGUAGES from '@shared/constants/languages.json'
import { Communication } from '@shared/types/communication'
import notEmpty from '@shared/utils/notEmpty'

type Lang = {
  abbr?: string
  value: string
}

export function convertLangToOption(lang: Lang) {
  const { value } = lang
  return {
    label: value,
    value,
  }
}

export function getLanguageOptions({
  allLanguages = LANGUAGES,
  communication = [],
  countryCode,
}: {
  allLanguages?: Lang[]
  communication?: Communication[]
  countryCode?: string
}) {
  let options: Array<{ label: string; value: string }>
  const commonLanguages = ((countryCode &&
    COMMON_LANGUAGES_BY_COUNTRY[countryCode]) ||
    []) as Lang[] | undefined
  const existingLangStrList = communication
    .map((c) => c.languageStr)
    .filter(notEmpty)
  if (commonLanguages) {
    const list = [...commonLanguages]

    // Remove duplicate languages from commonLanguages
    list.push(
      ...allLanguages.filter(
        (lang) => !commonLanguages.some((l) => lang.value === l.value)
      )
    )

    options = list.map(convertLangToOption)
  } else {
    options = allLanguages.map(convertLangToOption)
  }

  return options.filter(
    (opt: OptionTypeBase) => !existingLangStrList.includes(opt.value)
  )
}
