import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import classNames from 'classnames'
import { useContext, useState } from 'react'
import { updatePersonToProspect } from '@shared/api/person'
import { useHasAtLeastOnePermission } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import {
  displayResidentStatus,
  isProspect as getIsProspect,
  isResident as getIsResident,
  isOutOfFacility,
  isProspectClosed,
} from '@shared/utils/person'
import { downloadFaceSheetPdf } from '@app/api/facesheet'
import { usePersonInContext } from '@app/hooks/usePerson'
import styles from './styles.module.css'
import { ActionMenuItem } from './ActionMenuItem'
import { ActionMenuItemType } from './types'

interface Props {
  onEditProfileClick: () => void
  onEditAdmissionClick: () => void
  onMoveInResidentClick: () => void
  onCloseProspectClick: () => void
  onMoveOutResidentClick: () => void
  onMarkAsAwayClick: () => void
  onUndoMoveInClick: () => void
  person: Person
}

const updatePersonPermission = GroupPermission.GROUP_PERMISSION_PERSON_UPDATE
const readOccupancyPermission = GroupPermission.GROUP_PERMISSION_OCCUPANCY_READ
const facesheetReadPermission = GroupPermission.GROUP_PERMISSION_FACESHEET_READ
const changeStatusPermission =
  GroupPermission.GROUP_PERMISSION_PERSON_CHANGE_STATUS
const superUserPermission = GroupPermission.GROUP_PERMISSION_SUPER_USER

export function ActionMenu({
  onEditAdmissionClick,
  onEditProfileClick,
  onMoveInResidentClick,
  onCloseProspectClick,
  onMoveOutResidentClick,
  onMarkAsAwayClick,
  onUndoMoveInClick,
  person,
}: Props) {
  const { setError } = useContext(GlobalContext)
  const { refreshPerson } = usePersonInContext({
    initialData: person,
    skipFirstFetch: true,
  })
  const canOpenDropdown = useHasAtLeastOnePermission({
    person,
    permissions: [
      updatePersonPermission,
      readOccupancyPermission,
      facesheetReadPermission,
    ],
  })

  const [showDropdown, setShowDropdown] = useState(false)
  const [loading, setLoading] = useState<'FaceSheet' | 'Re-Open'>()

  const placeholder = displayResidentStatus(person)

  const icon = showDropdown ? 'fas fa-caret-up' : 'fas fa-caret-down'
  const isProspect = getIsProspect(person)
  const isClosedProspect = isProspectClosed(person)
  const isResident = getIsResident(person)

  const pillStyles = classNames(styles.pill, {
    [styles.clickablePill]: canOpenDropdown,
  })

  return (
    <div className={styles.actionMenu}>
      {showDropdown && (
        <div
          className={styles.dropdownBackground}
          onClick={() => setShowDropdown(false)}
        ></div>
      )}
      <button
        id="personActionMenu"
        className={pillStyles}
        onClick={() => {
          canOpenDropdown && setShowDropdown(!showDropdown)
        }}
      >
        {placeholder}
        {canOpenDropdown && (
          <span className={styles.caretContainer}>
            <i className={icon} />
          </span>
        )}
      </button>
      {showDropdown && (
        <div className={styles.dropdown}>
          <ul className={styles.dropdownList}>
            <ActionMenuItem
              person={person}
              permissions={[updatePersonPermission]}
              onClick={onEditProfileClick}
              setShowDropdown={setShowDropdown}
              item={ActionMenuItemType.EDIT_PROFILE}
              icon="fas fa-user-edit"
            />
            <ActionMenuItem
              person={person}
              permissions={[updatePersonPermission, readOccupancyPermission]}
              onClick={onEditAdmissionClick}
              setShowDropdown={setShowDropdown}
              item={ActionMenuItemType.EDIT_ADMISSION_DETAILS}
              icon="fas fa-info-square"
            />
            {isProspect && (
              <ActionMenuItem
                person={person}
                permissions={[updatePersonPermission, changeStatusPermission]}
                onClick={onMoveInResidentClick}
                setShowDropdown={setShowDropdown}
                item={ActionMenuItemType.SCHEDULE_MOVE_IN}
                icon="fa-solid fa-house-person-return"
              />
            )}
            {isProspect && (
              <ActionMenuItem
                person={person}
                permissions={[updatePersonPermission, changeStatusPermission]}
                onClick={onCloseProspectClick}
                setShowDropdown={setShowDropdown}
                item={ActionMenuItemType.CLOSE_PROSPECT}
                icon="fas fa-user-slash"
              />
            )}
            {isClosedProspect && (
              <ActionMenuItem
                person={person}
                permissions={[updatePersonPermission, changeStatusPermission]}
                onClick={async () => {
                  setLoading('Re-Open')

                  try {
                    await updatePersonToProspect({ person })
                    await refreshPerson()
                  } catch (e) {
                    setError(e)
                  } finally {
                    setLoading(undefined)
                    setShowDropdown(false)
                  }
                }}
                setShowDropdown={() => null}
                item={ActionMenuItemType.REOPEN}
                icon={
                  loading === 'Re-Open'
                    ? 'fa-solid fa-spinner fa-spin'
                    : 'fas fa-door-open'
                }
              />
            )}
            {isResident && !isOutOfFacility(person) && (
              <ActionMenuItem
                onClick={onMarkAsAwayClick}
                setShowDropdown={setShowDropdown}
                item={ActionMenuItemType.MARK_AS_AWAY}
                person={person}
                permissions={[updatePersonPermission]}
                icon="fa-regular fa-person-walking-luggage"
              />
            )}
            {isResident && (
              <ActionMenuItem
                onClick={onMoveOutResidentClick}
                setShowDropdown={setShowDropdown}
                item={ActionMenuItemType.MOVE_OUT_RESIDENT}
                person={person}
                permissions={[updatePersonPermission, changeStatusPermission]}
                icon="fa-solid fa-house-person-leave"
              />
            )}
            {isResident && (
              <ActionMenuItem
                item={ActionMenuItemType.UNDO_MOVE_IN}
                onClick={onUndoMoveInClick}
                setShowDropdown={setShowDropdown}
                icon={'fa-solid fa-undo'}
                permissions={[superUserPermission]}
                person={person}
              />
            )}
            <ActionMenuItem
              onClick={async () => {
                setLoading('FaceSheet')

                try {
                  await downloadFaceSheetPdf(person)
                } catch (e) {
                  setError(e)
                } finally {
                  setLoading(undefined)
                  setShowDropdown(false)
                }
              }}
              setShowDropdown={() => null}
              person={person}
              permissions={[facesheetReadPermission]}
              disabled={loading === 'FaceSheet'}
              item={ActionMenuItemType.PRINT_FACE_SHEET}
              icon={
                loading === 'FaceSheet'
                  ? 'fa-solid fa-spinner fa-spin'
                  : 'fas fa-print'
              }
            />
          </ul>
        </div>
      )}
    </div>
  )
}
