import { UserSessionLogoutReason } from '@augusthealth/models/com/august/protos/user_session'
import localforage from 'localforage'
import { useEffect } from 'react'
import LoadingPopup from '@shared/components/LoadingPopup'
import { logout } from './Auth'

export default function Logout() {
  async function resetAndRedirect() {
    try {
      await localforage.clear()
      await logout(
        UserSessionLogoutReason.USER_SESSION_LOGOUT_REASON_EXPLICIT,
        '/'
      )
    } catch (e) {
      /**
        If "nice" logout fails, we'll clear localStorage
        and redirect to the login page.
      */

      // eslint-disable-next-line
      localStorage.clear()
      window.location.href = '/'
    }
  }

  useEffect(() => {
    void resetAndRedirect()
  }, [])

  return <LoadingPopup loading />
}
