import { PasswordPolicy } from '@augusthealth/models/com/august/protos/settings/security_policy'
import { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Skeleton from 'react-loading-skeleton'
import { fetchInheritedSettings } from '@shared/api/orgFacilitySettings'
import { setSecurityPolicySettings } from '@shared/api/security_policy_settings'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import Card from '@shared/components/Card'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { SettingsType } from '@shared/types/org_facility_settings'
import { Organization } from '@shared/types/organization'
import {
  CreateOrgSecurityPolicySettings,
  SecurityPolicySettings,
} from '@shared/types/security_policy_settings'
import { TwoFactorLevel } from '@shared/types/settings/security_policy'
import { getOrElse } from '@shared/utils/loading'
import { RHFSwitch } from '@app/components/Switch'
import useOrganizations from '@app/hooks/useOrganizations'
import styles from '../AssessmentConfiguration/styles.module.css'
import { passwordPolicyOptions } from './constants'

export default function SecuritySettings() {
  const { setError } = useContext(GlobalContext)
  const { organizations } = useOrganizations([])
  const [isLoading, setIsLoading] = useState(false)

  const { reset, watch, formState, setValue, control, handleSubmit } =
    useForm<CreateOrgSecurityPolicySettings>()

  const selectedOrgId = watch('orgId')
  const selectedOrg = getOrElse(organizations, []).find(
    (o) => o.id === selectedOrgId
  )

  async function fetchCurrentSecuritySettings(orgId: string) {
    try {
      setIsLoading(true)

      const currentSettings =
        await fetchInheritedSettings<SecurityPolicySettings>({
          orgId,
          settingsType: SettingsType.SETTINGS_TYPE_SECURITY_POLICY,
        })

      reset({
        orgId,
        settings: currentSettings.settings,
      })
    } catch (e) {
      setError(e)
    } finally {
      setIsLoading(false)
    }
  }

  async function onSubmit(formData: CreateOrgSecurityPolicySettings) {
    try {
      setIsLoading(true)
      await setSecurityPolicySettings({
        orgId: formData.orgId,
        settings: {
          securityPolicy: {
            passwordPolicy: formData.settings.securityPolicy.passwordPolicy,
            twoFactorLevel: formData.settings.securityPolicy.twoFactorLevel,
          },
        },
      })

      reset({
        orgId: formData.orgId,
        settings: formData.settings,
      })
    } catch (e) {
      setError(e)
    } finally {
      setIsLoading(false)
    }
  }

  if (organizations.tag === 'Loading' || isLoading) {
    return (
      <div className="content-holder">
        <h4 className="page-title mt-[24px]">Security Settings</h4>
        <div className={styles.container}>
          <div className={styles.left}>
            <Skeleton height={30} count={3} />
          </div>
          <div className={styles.right}>
            <Skeleton height={30} count={3} />
          </div>
        </div>
      </div>
    )
  }

  const orgOptions = organizations.value.map((o: Organization) => {
    return { label: o.name!, value: o }
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="content-holder">
        <h4 className="page-title mt-[24px]">Security Settings</h4>
        <div className={styles.container}>
          <div className={styles.left}>
            <Card className={styles.card}>
              <LabelAboveInput htmlFor="organizations">
                I want to set security settings for...
              </LabelAboveInput>
              <Controller
                control={control}
                name="orgId"
                render={({ field: { onChange, value } }) => {
                  return (
                    <StyledSelect
                      options={orgOptions}
                      onChange={(opt: OptionTypeBase<Organization>) => {
                        void fetchCurrentSecuritySettings(opt.value.id)
                        onChange(opt.value.id)
                      }}
                      value={orgOptions.find((opt) => opt.value.id === value)}
                    />
                  )
                }}
              />
            </Card>
          </div>
          <div className={styles.right}>
            {selectedOrg && (
              <Card className={styles.card}>
                <LabelAboveInput htmlFor="settings.securityPolicy.passwordPolicy">
                  Current security settings for {selectedOrg.name}
                </LabelAboveInput>
                <Controller
                  control={control}
                  name="settings.securityPolicy.passwordPolicy"
                  render={({ field: { onChange, value } }) => {
                    if (value === PasswordPolicy.PASSWORD_POLICY_UNSPECIFIED)
                      return <></>
                    const defaultValue = passwordPolicyOptions.find(
                      (o) => o.value === value
                    )
                    return (
                      <StyledSelect
                        inputId="passwordPolicy"
                        defaultValue={defaultValue}
                        className="mb-[16px]"
                        placeholder="Select password policy..."
                        options={passwordPolicyOptions}
                        onChange={(e: OptionTypeBase<PasswordPolicy>) => {
                          onChange(e.value)
                          setValue(
                            'settings.securityPolicy.passwordPolicy',
                            e.value
                          )
                        }}
                        value={passwordPolicyOptions.find(
                          (o) => o.value === value
                        )}
                      />
                    )
                  }}
                />
                <div className="print-hide mb-[16px] mt-[16px] flex items-center">
                  <Controller
                    control={control}
                    name="settings.securityPolicy.twoFactorLevel"
                    defaultValue={TwoFactorLevel.TWO_FACTOR_LEVEL_UNSPECIFIED}
                    render={({ field: { onChange, value } }) => {
                      const mfaEnabled =
                        value === TwoFactorLevel.TWO_FACTOR_LEVEL_ALL_USERS

                      return (
                        <section>
                          <LabelAboveInput htmlFor="settings.securityPolicy.twoFactorLevel">
                            Multi-Factor Authentication
                          </LabelAboveInput>
                          <RHFSwitch
                            id="mfa-enabled-toggle"
                            checked={mfaEnabled}
                            onChange={(event) => {
                              const newValue = event.currentTarget.checked
                                ? TwoFactorLevel.TWO_FACTOR_LEVEL_ALL_USERS
                                : TwoFactorLevel.TWO_FACTOR_LEVEL_DISABLED
                              onChange(newValue)
                            }}
                            value={value}
                            label={mfaEnabled ? 'On' : 'Off'}
                            switchSize={'small'}
                          />
                        </section>
                      )
                    }}
                  />
                </div>

                <AsyncIconButton
                  type="submit"
                  buttonStyle="primary-fill"
                  disabled={isLoading || !formState.isDirty}
                  isLoading={isLoading}
                >
                  Save Security Settings
                </AsyncIconButton>
              </Card>
            )}
          </div>
        </div>
      </div>
    </form>
  )
}
