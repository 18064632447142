import { useContext, useEffect, useState } from 'react'
import {
  createBillingCategory,
  getOrgBillingCategories,
  updateBillingCategory,
} from '@shared/api/billing'
import GlobalContext from '@shared/contexts/GlobalContext'
import { BillingCategory, BillingCategoryData } from '@shared/types/billing'
import { loaded, LOADING, Loading } from '@shared/utils/loading'

export default function useBillingCategories({ orgId }: { orgId: string }) {
  const { setError } = useContext(GlobalContext)
  const [billingCategories, setBillingCategories] =
    useState<Loading<BillingCategory[]>>(LOADING)

  async function reloadBillingCategories() {
    setBillingCategories(LOADING)
    try {
      const newCategories = await getOrgBillingCategories({ orgId })
      setBillingCategories(loaded(newCategories))
    } catch (e) {
      setError(e)
      setBillingCategories(loaded([]))
    }
  }

  async function upsertBillingCategoryData({
    billingCategoryData,
    categoryId,
  }: {
    billingCategoryData: BillingCategoryData
    categoryId: string
  }) {
    const { name } = billingCategoryData

    const coreProps = {
      orgId,
      category: {
        orgId,
        name,
      },
    }

    const isEditing = !!categoryId

    const fn = isEditing
      ? () =>
          updateBillingCategory({
            categoryId,
            ...coreProps,
          })
      : () => createBillingCategory(coreProps)

    return await fn()
  }

  useEffect(() => {
    void reloadBillingCategories()
  }, [orgId])

  return {
    billingCategories,
    reloadBillingCategories,
    upsertBillingCategoryData,
  }
}
