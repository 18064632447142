import { useContext, useEffect, useState } from 'react'
import {
  createBillingFee,
  getFacilityBillingFeesWithParentCategories,
  getOrgBillingFees,
  updateBillingFee,
} from '@shared/api/billing'
import GlobalContext from '@shared/contexts/GlobalContext'
import {
  BillingFee,
  BillingFeeData,
  BillingFeesWithParentCategory,
} from '@shared/types/billing'
import { loaded, LOADING, Loading } from '@shared/utils/loading'

export default function useBillingFees({
  orgId,
  facilityId,
}: {
  orgId: string
  facilityId?: string
}) {
  const { setError } = useContext(GlobalContext)
  const [billingFees, setBillingFees] = useState<Loading<BillingFee[]>>(LOADING)
  const [billingFeesWithParentCategories, setBillingFeesWithParentCategories] =
    useState<Loading<BillingFeesWithParentCategory[]>>(LOADING)

  async function reloadBillingFees() {
    setBillingFees(LOADING)
    try {
      if (facilityId) {
        const res = await getFacilityBillingFeesWithParentCategories({
          orgId: orgId!,
          facilityId: facilityId!,
        })
        const newBillingFees = res
          .map((obj) => {
            const category = obj.category
            const items = obj.items
            return items.map((item) => {
              return {
                ...item,
                category,
              }
            })
          })
          .flat()
        const newBillingFeesWithParentCategories = res

        setBillingFees(loaded(newBillingFees))
        setBillingFeesWithParentCategories(
          loaded(newBillingFeesWithParentCategories)
        )
      } else {
        const newBillingFees = await getOrgBillingFees({ orgId })

        setBillingFees(loaded(newBillingFees))
      }
    } catch (e) {
      setError(e)
      setBillingFees(loaded([]))
    }
  }

  async function upsertBillingFeeData({
    billingFeeData,
    itemId,
  }: {
    billingFeeData: BillingFeeData
    itemId: string
  }) {
    const coreProps = {
      orgId,
      item: billingFeeData,
    }
    const isEditing = !!itemId

    const fn = isEditing
      ? () =>
          updateBillingFee({
            itemId,
            ...coreProps,
          })
      : () =>
          createBillingFee({
            ...coreProps,
          })

    try {
      await fn()
    } catch (error) {
      setError(error)
    }
  }

  useEffect(() => {
    void reloadBillingFees()
  }, [])

  return {
    billingFees,
    billingFeesWithParentCategories,
    reloadBillingFees,
    upsertBillingFeeData,
  }
}
