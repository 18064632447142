import styles from './styles.module.css'

type Props = {
  onClick: () => void
  icon: string
  text: string
}
export default function FilterBadge(props: Props) {
  const { onClick, icon, text } = props
  return (
    <button
      onClick={onClick}
      className={`mb-[8px] mr-[8px] rounded-[4px] border-0 bg-primary-light p-[8px] text-[14px] font-medium leading-[16px] text-white ${styles.filterBadge}`}
    >
      <i className={`fa-solid fa-${icon} mr-[8px]`} />
      {text}
      <i className="fa-thin fa-xmark ml-[8px] font-normal opacity-50" />
    </button>
  )
}
