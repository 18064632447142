import { useFormContext } from 'react-hook-form'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import { DataType } from '@shared/types/snapshot'

export function NamedUpload({
  dataType,
  label,
}: {
  dataType: DataType | undefined
  label: string | undefined
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <>
      <input type={'hidden'} value={'NamedUpload'} {...register('tag')} />
      <input type={'hidden'} value={dataType} {...register('dataType')} />
      <div className="mt-[32px]">
        <LabelAboveInput htmlFor="name" subLabel={requiredLabel(!!errors.name)}>
          Document Title
        </LabelAboveInput>
        <BasicInput
          data-testid="title"
          id="name"
          {...register('name', { value: label, required: true })}
        />
      </div>
    </>
  )
}
