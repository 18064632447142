import { Controller, useForm } from 'react-hook-form'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import StyledSelect from '@shared/components/Selects/StyledSelect'
import { Person } from '@shared/types/person'
import { hasDementia } from '@shared/utils/person'
import usePerson from '@app/hooks/usePerson'
import { updateDementiaStatus, yesNoOptions } from './helpers'

interface FormData {
  dementia: {
    label: string
    value: boolean
  }
}

export default function EditDementia({
  person,
  onClose,
}: {
  person: Person
  onClose: (updated: boolean) => Promise<void>
}) {
  const dementia = hasDementia(person)
  const { person: etagPerson } = usePerson({
    initialData: person,
    fields: ['conditions'],
  })

  const { control, handleSubmit, formState } = useForm<FormData>({
    defaultValues: {
      dementia: dementia
        ? { label: 'Yes', value: true }
        : { label: 'No', value: false },
    },
  })

  const onSubmit = async (formData: FormData) => {
    if (formData.dementia.value && dementia) {
      await onClose(false)
    } else {
      await updateDementiaStatus(person, formData)
      await onClose(true)
    }
  }

  return (
    <AnimatedPopup title={'Dementia'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="dementia"
          render={({ field: { onChange, value } }) => {
            return (
              <StyledSelect
                options={yesNoOptions}
                name="dementia"
                value={value}
                onChange={onChange}
                isDisabled={etagPerson.tag === 'Loading'}
              />
            )
          }}
        />
        <div className="mt-[32px]">
          <AnimatedPopupFormFooter
            yesBtn={{ props: { disabled: etagPerson.tag === 'Loading' } }}
            noBtn={{ action: () => void onClose(false) }}
            formState={formState}
          />
        </div>
      </form>
    </AnimatedPopup>
  )
}
