import { Link } from 'react-router-dom'
import styles from './styles.module.css'
import { Breadcrumb } from './helpers'

export default function BreadcrumbEntry({
  breadcrumb,
  index,
}: {
  breadcrumb: Breadcrumb
  index: number
}) {
  return (
    <>
      <Link to={breadcrumb.url}>
        {index > 0 && <i className={`far fa-angle-right ${styles.icon}`} />}
        {breadcrumb.text}
      </Link>
      {breadcrumb.children}
    </>
  )
}
