import { ReactNode } from 'react'
import { VALIDATION_MAP } from './config'

// CSS in src/styles/form.css

enum ValidationCondition {
  EQUAL = 'equal',
  NOT_EQUAL = 'not_equal',
}

type ShowElementHolderProps = {
  title?: string
  subTitle?: string
  description?: string
}

export type ValueProps = boolean | number | string | object | null | undefined

type ValidationProps = {
  regex: string
  message: ReactNode
  condition: ValidationCondition
}

export type Props = ShowElementHolderProps & {
  holderClassName?: string
  labelClassName?: string
  children: ReactNode
  readOnly?: boolean
  value?: ValueProps | ValueProps[]
  itemComponent?: (p: {
    data: boolean | number | string | object | null | undefined | ValueProps[]
  }) => void
  // display whole UI component in read-only, rather than string value
  childrenInReadOnlyHolder?: boolean
  validation?: string | ValidationProps
  alertMessage?: ReactNode
  noAlert?: boolean
  childrenOnly?: boolean
  visibility?: boolean
  warning?: ReactNode
  htmlFor?: string
  name?: string
}

export function showInsideHolder({
  title,
  subTitle,
  description,
}: ShowElementHolderProps) {
  return title || subTitle || description
}

export default function ElementHolder(props: Props) {
  const {
    title,
    subTitle,
    description,
    holderClassName = 'vertical',
    labelClassName,
    children,
    readOnly = false,
    value,
    itemComponent,
    childrenInReadOnlyHolder = false,
    validation,
    alertMessage,
    noAlert = false,
    childrenOnly = false,
    visibility = true,
    warning,
    htmlFor,
    name,
  } = props

  if (visibility === false) {
    return false
  }

  const holderClassNameList = ['form-group']
  if (holderClassName) {
    holderClassNameList.push(holderClassName)
  }

  const labelClassNameList = ['form-control-label']
  if (labelClassName) {
    labelClassNameList.push(labelClassName)
  }

  const titleLabel =
    title || subTitle ? (
      <div className="form-element-title">
        {title}
        {subTitle && <span className="form-element-subtitle">{subTitle}</span>}
      </div>
    ) : undefined

  const descriptionLabel = description ? (
    <div className="form-element-description">{description}</div>
  ) : undefined

  const displayedValue =
    typeof itemComponent === 'function' ? itemComponent({ data: value }) : value
  let content
  if (childrenInReadOnlyHolder) {
    content = <div className="read-only-holder">{children}</div>
  } else if (readOnly) {
    // @ts-ignore
    content = <div className="read-only-holder">{displayedValue}</div>
  } else {
    content = children
  }

  let validationMessage = alertMessage
  if (validation && value) {
    let validationData = validation
    if (validation === 'email' && VALIDATION_MAP.email) {
      validationData = VALIDATION_MAP.email as ValidationProps
    }
    const { regex, message, condition } = validationData as ValidationProps
    if (regex) {
      const reg = new RegExp(regex)
      if (typeof value === 'string') {
        if (reg.test(value)) {
          if (condition === ValidationCondition.EQUAL) {
            validationMessage = message
          }
        } else if (condition === ValidationCondition.NOT_EQUAL) {
          validationMessage = message
        }
      }
    }
  }

  const validationElement =
    !noAlert && validationMessage ? (
      <div className="validation-holder">{validationMessage}</div>
    ) : undefined

  if (childrenOnly) {
    return content
  } else if (showInsideHolder(props)) {
    return (
      <div className={holderClassNameList.join(' ')}>
        <label
          className={labelClassNameList.join(' ')}
          htmlFor={htmlFor || name}
        >
          {titleLabel}
          {descriptionLabel}
        </label>
        {content}
        {validationElement}
        {warning}
      </div>
    )
  } else if (validationElement) {
    return (
      <div className={holderClassNameList.join(' ')}>
        {content}
        {validationElement}
        {warning}
      </div>
    )
  } else {
    return content
  }
}
