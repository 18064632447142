import {
  DraftStatus,
  SignableForm,
  SignableFormWithAssignments,
} from '@augusthealth/models/com/august/protos/signable_form'
import { useState } from 'react'
import { LightboxFooterButton } from '@shared/components/AnimatedPopup/Lightbox/Lightbox'
import PublishConfirmPopup from './PublishConfirmPopup'

type LightboxFooterProps = SignableForm & {
  reload: (updateForm: SignableFormWithAssignments) => void
}

export default function PublishButton(props: LightboxFooterProps) {
  const { draftStatus, name, reload } = props
  const [openPublishPopup, setOpenPublishPopup] = useState<boolean>(false)
  const [canPublish, setCanPublish] = useState<boolean>(
    draftStatus === DraftStatus.DRAFT_STATUS_READY
  )
  if (openPublishPopup) {
    return (
      <PublishConfirmPopup
        form={props}
        onClose={() => setOpenPublishPopup(false)}
        afterPublish={(updatedForm: SignableFormWithAssignments) => {
          reload(updatedForm)
          setCanPublish(false)
        }}
      />
    )
  }

  let publishButtonTitle
  if (canPublish) {
    publishButtonTitle = `Publish Template ${name}`
  } else if (!draftStatus) {
    publishButtonTitle = `Template ${name} has been published already`
  } else {
    publishButtonTitle = `Unable to publish ${name} due to draft status equals ${draftStatus}`
  }

  return (
    <LightboxFooterButton
      buttonStyle="primary-fill"
      onClick={() => setOpenPublishPopup(true)}
      disabled={!canPublish}
      title={publishButtonTitle}
    >
      PUBLISH
    </LightboxFooterButton>
  )
}
