import { DocumentViewerUploaderFooter } from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { Person } from '@shared/types/person'
import { Snapshot } from '@shared/types/snapshot'
import { getLastName } from '@shared/utils/humanName'
import useBlobData from '@app/hooks/useBlobData'
import { spaceFree } from '../helpers'
import Viewer from '../index'
import { AssessmentViewerMode } from './helpers'
import { Sidebar } from './Sidebar'

export function UploadedAssessmentViewer({
  assessment,
  person,
  mode,
  pdfPath,
  onClose,
}: {
  assessment: Snapshot
  person: Person
  mode: AssessmentViewerMode
  pdfPath: string
  onClose: () => void
}) {
  const blobData = useBlobData(pdfPath)
  const filename = spaceFree(`${getLastName(person.name)}-uploaded-appraisal`)

  return (
    <Viewer
      onClose={onClose}
      blobData={blobData}
      deletable={false}
      filename={filename}
      dataType={assessment.dataType!}
      showPrintDownload={true}
      popUpActions={
        mode.tag === 'view' && (
          <DocumentViewerUploaderFooter
            yesBtn={{
              props: {},
              hideButton: true,
            }}
            noBtn={{
              action: mode.onClose,
              label: 'Close',
            }}
            formState={{ isSubmitting: false }}
          />
        )
      }
    >
      <Sidebar
        setShowServicePlanOverlay={() => {}}
        assessment={assessment}
        mode={mode}
        person={person}
      />
    </Viewer>
  )
}
