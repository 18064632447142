import { UseFormReturn } from 'react-hook-form'
import { requiredWhenError } from '@shared/components/Labels'
import { BillingFrequency } from '@shared/types/billing'
import { tw } from '@shared/utils/tailwind'
import {
  DatePickerWithIsoDateStr,
  PredefiendDatePickerProps,
} from '@app/components/reactHookForm/RhfDateTimePicker'
import { BillingChargeFormData } from '../helpers'

export default function StartEndDates({
  frequency,
  startDateLabel = 'Start Date',
  useFormReturn,
  disabled,
  startDatePickerProps,
}: {
  frequency?: BillingFrequency
  startDateLabel?: string
  useFormReturn: UseFormReturn<BillingChargeFormData>
  disabled?: boolean
  startDatePickerProps?: PredefiendDatePickerProps
}) {
  const { control, formState } = useFormReturn
  const { errors } = formState

  return (
    <div className={tw`flex gap-[16px]`}>
      <DatePickerWithIsoDateStr
        control={control}
        name="startDate"
        labelProps={{
          uppercase: false,
          children: startDateLabel,
          subLabel: requiredWhenError(Boolean(errors.startDate)),
        }}
        datePickerProps={{ disabled, ...startDatePickerProps }}
        required
      />
      {frequency && frequency !== BillingFrequency.ONE_TIME && (
        <DatePickerWithIsoDateStr
          control={control}
          name="endDate"
          labelProps={{
            uppercase: false,
            children: 'End Date',
          }}
          datePickerProps={{ disabled }}
        />
      )}
    </div>
  )
}
