import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { DataType, Snapshot, SnapshotStatus } from '@shared/types/snapshot'
import { Loading } from '@shared/utils/loading'
import { fetchSnapshots } from '../api/snapshot'
import PersonContext from '../contexts/PersonContext'

type Props = {
  dataType?: DataType
  customType?: string
  status: SnapshotStatus
}
export default function useSnapshots(
  { dataType, customType, status }: Props,
  deps: React.DependencyList = []
): {
  snapshots: Loading<Snapshot[]>
  refreshSnapshots: () => Promise<void>
} {
  const { setError } = useContext(GlobalContext)
  const { person } = useContext(PersonContext)

  const [snapshots, setSnapshots] = useState<Loading<Snapshot[]>>({
    tag: 'Loading',
  })

  const refreshSnapshots = async () => {
    try {
      if (person === undefined) {
        return
      }

      const response = await fetchSnapshots({
        personId: person.id!,
        orgId: person.orgId!,
        status,
        dataType,
        customType,
      })

      setSnapshots({ tag: 'Complete', value: response.data })
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    setSnapshots({ tag: 'Loading' })
    void refreshSnapshots()
  }, [person?.id, ...deps])

  return {
    snapshots: snapshots,
    refreshSnapshots,
  }
}
