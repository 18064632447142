import { formatDistanceToNowStrict } from 'date-fns'
import React from 'react'
import Badge from '@shared/components/Badge'
import { medicationsPathForPerson } from '@shared/legacy_routes'
import { FacilityIds } from '@shared/types/facility'
import { ResidentOrders } from '@shared/types/pharmacy_integration'
import { getFullName } from '@shared/utils/humanName'
import { formatRoomNumber, getProfileSvgPath } from '@shared/utils/person'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import ReviewUnassignedOrderModal from '@app/pages/Facilities/Inbox/ReviewUnassignedOrders/ReviewUnassignedOrderModal'
import styles from './inbox.module.css'

export const InboxPendingOrder = ({
  order,
  history,
  facility,
  refreshOrders,
}: {
  order: ResidentOrders
  history: any
  facility: FacilityIds
  refreshOrders: () => Promise<void>
}) => {
  const { orgId, id: facilityId } = facility
  const [showAssignModal, setShowAssignModal] = React.useState(false)
  const { residents } = useCurrentFacility()
  const facilityResident = order.matchDetails?.matchedResident
  const pharmacyResident = order.matchDetails?.matchCriteria

  if (pharmacyResident) {
    return (
      <>
        {showAssignModal && (
          <ReviewUnassignedOrderModal
            order={order}
            orgId={orgId!}
            facilityId={facilityId!}
            closeModal={() => setShowAssignModal(false)}
            refreshOrders={refreshOrders}
          />
        )}
        <PendingOrderShell
          name={getFullName(pharmacyResident.name!).toLowerCase()}
          timestamp={order.latestReceived}
          orderCount={order.medicationOrders?.length ?? 0}
          photoUrl={getProfileSvgPath(pharmacyResident)}
          unassigned={true}
          onClick={() => setShowAssignModal(true)}
        />
      </>
    )
  }

  if (residents.tag === 'Loading') {
    return null
  }

  if (facilityResident) {
    const matchingResident = residents.value.find(
      (r) => r.person?.id === facilityResident.id
    )
    return (
      <PendingOrderShell
        name={getFullName(facilityResident.name!)}
        timestamp={order.latestReceived}
        orderCount={order.medicationOrders?.length ?? 0}
        onClick={() =>
          history.push(
            medicationsPathForPerson({
              id: facilityResident.id || '',
              orgId: orgId || '',
              facilityId: facilityId || '',
            })
          )
        }
        photoUrl={
          matchingResident?.profilePhotoUrl ??
          getProfileSvgPath(matchingResident?.person)
        }
        roomDescriptor={formatRoomNumber(
          facilityResident.room?.name,
          facilityResident.bed?.name
        )}
      />
    )
  }

  return null
}

const PendingOrderShell = ({
  onClick,
  name,
  roomDescriptor,
  timestamp,
  orderCount,
  photoUrl,
  unassigned = false,
}: {
  onClick?: () => void
  name: string
  roomDescriptor?: string
  timestamp?: string
  orderCount: number
  photoUrl: string
  unassigned?: boolean
}) => {
  return (
    <div className={styles.orderContainer} onClick={onClick}>
      <div className={styles.orderDetailsContainer}>
        <i className={'fa-regular fa-prescription text-gray-07'} />
        <div className={styles.orderDetails}>
          <span className={styles.bold}>
            {orderCount} pending {orderCount === 1 ? 'order' : 'orders'}
          </span>
          <span>for</span>
          <img className={styles.profilePic} src={photoUrl} />
          <span className={`${styles.bold} capitalize`}>{name}</span>
          {roomDescriptor && (
            <span>
              <i className="fas fa-fw fa-bed ml-2 mr-1" />
              {roomDescriptor}
            </span>
          )}
          {unassigned && <Badge color={'orange'}>Unassigned</Badge>}
        </div>
      </div>
      <div className={styles.orderTimeContainer}>
        {timestamp ? (
          <div className={styles.orderTime}>
            {formatDistanceToNowStrict(new Date(timestamp), {
              addSuffix: true,
              roundingMethod: 'floor',
            })}
          </div>
        ) : (
          <div />
        )}
        <i className={'fa-regular fa-chevron-right fa-sm text-gray-07'} />
      </div>
    </div>
  )
}
