import {
  OrgFacilitySettings,
  Settings,
  SettingsType,
} from '@augusthealth/models/com/august/protos/org_facility_settings'
import { PdfViewSettings_DocumentType } from '@augusthealth/models/com/august/protos/settings/pdf_view_settings'
import { useForm } from 'react-hook-form'
import {
  getFacilityMedListPdfViewSettings,
  upsertSettings,
} from '@shared/api/orgFacilitySettings'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput } from '@shared/components/Labels'
import { tw } from '@shared/utils/tailwind'
import OrgFacilityLoaderSaver, {
  OnSubmit,
} from '../../subComponents/OrgFacilityLoaderSaver'

export default function MedListPdfViewSettings() {
  const useFormReturn = useForm<Settings>()
  const { register } = useFormReturn

  return (
    <OrgFacilityLoaderSaver
      pageTitle="Med List PDF View Settings"
      loaderTitle="I want to view Med List PDF view settings for..."
      saverTitle="Save Med List PDF View Settings at..."
      childrenTitle="Med List PDF View Settings"
      saveBtnLabel="Save Med List PDF View Settings"
      hudText="Med List PDF View Settings Updated"
      useFormReturn={useFormReturn}
      path={'pdfViewSettings'}
      loaderFunc={getFacilityMedListPdfViewSettings}
      loaderFuncMap={(res: OrgFacilitySettings) =>
        res.settings?.pdfViewSettings
      }
      onSubmit={({ ids, data }: OnSubmit) => {
        return upsertSettings({
          ...ids,
          settings: data,
          settingsType: SettingsType.SETTINGS_TYPE_PDF_VIEW_SETTINGS,
          key: PdfViewSettings_DocumentType.DOCUMENT_TYPE_MED_LIST,
        })
      }}
    >
      <div className={tw`mb-[16px] flex flex-col`}>
        <LabelAboveInput>Physician Authorization Blurb</LabelAboveInput>
        <BasicTextarea
          {...register(
            'pdfViewSettings.medListView.physicianAuthorizationBlurb'
          )}
          placeholder="Physician Authorization Blurb"
        />
      </div>
    </OrgFacilityLoaderSaver>
  )
}
