import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { ModalTitle } from '@shared/components/baseMui/Modal/Layout'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput } from '@shared/components/Labels'
import GlobalContext from '@shared/contexts/GlobalContext'
import {
  ApprovalData,
  MedicationApprovalStatus,
  MedicationOrder,
  RejectionReason,
} from '@shared/types/medication_order'
import { Person } from '@shared/types/person'
import { validStringOrUndefined } from '@shared/utils/parsing'
import { tw } from '@shared/utils/tailwind'
import { rejectPharmacyMessage } from '@app/api/medicationOrders'
import { ActionCard } from '@app/components/Residents/Medications/Orders/WeakMatch/WeakMatchModal/ActionCard'
import { ReviewPossibleMatches } from '@app/components/Residents/Medications/Orders/WeakMatch/WeakMatchModal/PossibleMatches/ReviewPossibleMatches'
import WeakMatchOrder from '@app/components/Residents/Medications/Orders/WeakMatch/WeakMatchOrder'

type Props = {
  order: MedicationOrder
  closeModal: () => void
  allOrders: MedicationOrder[]
  onOrderReject: () => Promise<void>
  person: Person
}
export const WeakMatchModal = ({
  order,
  closeModal,
  allOrders,
  onOrderReject,
  person,
}: Props) => {
  const { setError } = useContext(GlobalContext)
  const history = useHistory()
  const [modalState, setModalState] = useState<
    'actions' | 'replace' | 'reject'
  >('actions')

  const [loading, setLoading] = useState(false)
  const [rejectionNote, setRejectionNote] = useState('')

  const addNewOrder = () => {
    history.push(`pharmacy-messages/${order.sourceData?.messageId}/review`)
  }

  const confirmReject = async (rejectionNote: string) => {
    try {
      setLoading(true)
      await rejectPharmacyMessage(
        person as Person,
        order.sourceData!.messageId!,
        {
          status: MedicationApprovalStatus.MEDICATION_APPROVAL_STATUS_REJECTED,
          rejectionReason: RejectionReason.REJECTION_REASON_UNSPECIFIED,
          rejectionNote: validStringOrUndefined(rejectionNote),
        } as ApprovalData
      )
      await onOrderReject()
      setLoading(false)
      closeModal()
    } catch (e) {
      setError(e)
    }
  }

  return (
    <Modal
      open={true}
      id={'weak-match'}
      onClose={closeModal}
      contentClassName={tw`w-[600px] min-w-[600px] max-w-[600px]`}
    >
      <ModalTitle>Match Orders</ModalTitle>
      <div
        className={tw`mb-6 text-[14px] font-medium leading-[20px] text-secondary-06`}
      >
        You have a new order similar to an existing order.
      </div>

      <WeakMatchOrder order={order} mode={{ tag: 'modal' }} />

      {modalState === 'actions' && (
        <>
          <div
            className={tw`mb-4 mt-6 text-[14px] font-semibold leading-[20px] text-secondary-04`}
          >
            How should we handle this order?
          </div>

          <div className={tw`flex flex-col gap-2`}>
            <ActionCard
              iconName={'circle-check'}
              iconColor={'text-primary-light'}
              text={`Add as a new order`}
              onClick={addNewOrder}
            />
            <ActionCard
              iconName={'circle-xmark'}
              iconColor={'text-danger'}
              text={`Reject order`}
              onClick={() => setModalState('reject')}
            />
            <ActionCard
              iconName={'swap'}
              iconColor={'text-tags-purple'}
              text={`Replace an existing order (${
                order.sourceData!.possibleMatches!.length
              })`}
              onClick={() => setModalState('replace')}
            />
          </div>
        </>
      )}

      {modalState === 'reject' && (
        <>
          <div
            className={tw`mb-4 mt-6 text-[14px] font-semibold leading-[20px] text-secondary-04`}
          >
            Are you sure you want to reject this order?
          </div>
          <LabelAboveInput
            htmlFor="rejection-reason"
            className={tw`mt-4`}
            a11yOnly={true}
          >
            Rejection reason
          </LabelAboveInput>
          <BasicTextarea
            name="rejection-reason"
            value={rejectionNote}
            placeholder="Optional rejection reason..."
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setRejectionNote(e.currentTarget.value)
            }
            className={tw`mb-4`}
          ></BasicTextarea>
          <div className={`mx-auto flex flex-row justify-between gap-2`}>
            <AsyncIconButton
              buttonStyle={'secondary-outline'}
              width={'fit-content'}
              onClick={() => setModalState('actions')}
              initialIcon={'fa-fw fa-chevron-left'}
              disabled={loading}
            >
              Back
            </AsyncIconButton>
            <AsyncIconButton
              buttonStyle={'danger-fill'}
              onClick={() => confirmReject(rejectionNote)}
              width={'100%'}
              isLoading={loading}
            >
              Reject Order
            </AsyncIconButton>
          </div>
        </>
      )}

      {modalState === 'replace' && (
        <ReviewPossibleMatches
          order={order}
          goBack={() => setModalState('actions')}
          allOrders={allOrders}
        />
      )}
    </Modal>
  )
}
