import {
  AllergyIntolerance,
  AllergyIntolerance_AllergyIntoleranceCategory,
} from '@augusthealth/models/com/august/protos/allergy_intolerance'
import { BasicInputProps } from '@shared/components/BasicInput/BasicInput'
import DeletableInput from '@app/components/formElements/DeletableInput'

type Props = {
  allergies: AllergyIntolerance[]
  setAllergies: React.Dispatch<React.SetStateAction<AllergyIntolerance[]>>
  category: AllergyIntolerance_AllergyIntoleranceCategory
  inputProps?: BasicInputProps
  onRemoveAllergy?: (allergies: AllergyIntolerance[]) => void
  readOnly?: boolean
}

const Allergies = ({
  allergies,
  setAllergies,
  category,
  inputProps,
  onRemoveAllergy,
  readOnly = false,
}: Props) => {
  const onAllergyChange = (newValue: string, index: number) =>
    setAllergies([
      ...allergies.slice(0, index),
      { summary: newValue, category },
      ...allergies.slice(index + 1),
      // once the last item is filled out, add new blank item at the end
      ...(index === allergies.length - 1 && newValue.trim()
        ? [{ summary: '', category }]
        : []),
    ])
  const onTrashAllergy = (index: number) => {
    const updatedAllergies = allergies.filter((_a, i) => i !== index)
    setAllergies(updatedAllergies)
    if (onRemoveAllergy) {
      onRemoveAllergy(updatedAllergies)
    }
  }

  const inputs = allergies.map((allergy, index) => {
    const allInputProps = {
      name: `allergies[${index}]`,
      value: allergy.summary,
      onKeyDown: (e) => {
        if (e.key === 'Enter') {
          e.preventDefault()
        }
      },
      onChange: (ev) => onAllergyChange(ev.target.value, index),
      placeholder: 'Enter one per line...',
      disabled: readOnly,
      ...inputProps,
    }
    const trashIconProps =
      allergy.summary && !readOnly
        ? {
            name: `delete-allergy[${index}]`,
            onClick: () => onTrashAllergy(index),
            type: 'button',
          }
        : undefined

    return (
      <DeletableInput
        key={`allergy-input-${index}`}
        inputProps={allInputProps}
        trashIconProps={trashIconProps}
      />
    )
  })

  return <>{inputs}</>
}

export default Allergies
