import { Shift } from '@augusthealth/models/com/august/protos/shift'
import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { FacilityIds } from '@shared/types/facility'
import { Loading } from '@shared/utils/loading'
import { fetchShifts } from '@app/api/shift'

export default function useShifts({ facility }: { facility?: FacilityIds }) {
  const { setError } = useContext(GlobalContext)
  const [shifts, setShifts] = useState<Loading<Shift[]>>({
    tag: 'Loading',
  })

  const loadShifts = () => {
    if (facility) {
      setShifts({ tag: 'Loading' })
      fetchShifts(facility)
        .then((data) =>
          setShifts({
            tag: 'Complete',
            value: data,
          })
        )
        .catch(setError)
    }
  }

  useEffect(() => {
    loadShifts()
  }, [facility?.id, facility?.orgId])

  return {
    shifts,
    loadShifts,
    setShifts,
  }
}
