import { cloneDeep, compact } from 'lodash'
import { HumanName } from '@shared/types/human_name'
import { Person } from '@shared/types/person'
import { DeepNull } from '@shared/types/utilities'

export function getNameComponent(
  component: 'family' | 'title' | 'given' | 'middle' | 'suffix',
  names?: HumanName | Array<HumanName>,
  defaultValue: string = ''
): string {
  const name = Array.isArray(names) ? names[0] : names

  if (name === undefined) {
    return defaultValue
  }

  switch (component) {
    case 'family':
      return name.family ?? defaultValue
    case 'title':
      return name.prefix ?? defaultValue
    case 'given':
      return name.given?.[0] ?? defaultValue
    case 'middle':
      return name.given?.[1] ?? defaultValue
    case 'suffix':
      return name.suffix ?? defaultValue
  }
}

interface GetFullNameOptions {
  title: boolean
  given: boolean
  middle: boolean
  family: boolean
  suffix: boolean
}

export function getFullName(
  names: HumanName | Array<HumanName> | undefined,
  { title, given, middle, family, suffix }: GetFullNameOptions = {
    title: true,
    given: true,
    middle: true,
    family: true,
    suffix: true,
  }
): string {
  return compact([
    title ? getNameComponent('title', names) : undefined,
    given ? getNameComponent('given', names) : undefined,
    middle ? getNameComponent('middle', names) : undefined,
    family ? getNameComponent('family', names) : undefined,
    suffix ? getNameComponent('suffix', names) : undefined,
  ]).join(' ')
}

export const getFirstAndLastName = (
  names: HumanName | Array<HumanName> | undefined
): string =>
  getFullName(names, {
    title: false,
    given: true,
    middle: false,
    family: true,
    suffix: false,
  })

export const getFirstName = (
  names: HumanName | Array<HumanName> | undefined
): string => getNameComponent('given', names)

export const getMiddleName = (
  names: HumanName | Array<HumanName> | undefined
): string => getNameComponent('middle', names)

export const getLastName = (
  names: HumanName | Array<HumanName> | undefined
): string => getNameComponent('family', names)

export const getTitle = (
  names: HumanName | Array<HumanName> | undefined
): string => getNameComponent('title', names)

/**
 * Remove empty input such as undefined, null, '', etc. in .perspn.name
 */
export function filterOutEmptyGivenName(patch: DeepNull<Person>) {
  const updatedPatch = cloneDeep(patch)
  if (updatedPatch.name?.length) {
    updatedPatch.name = updatedPatch.name.map((n) => {
      return {
        ...n,
        given: n.given?.length ? compact(n.given) : n.given,
      }
    })
  }

  return updatedPatch
}
