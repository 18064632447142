import { BigDotDivider } from '@shared/components/DotDivider'
import Icon from '@shared/components/Icon'
import { Contact } from '@shared/types/contact'
import { getFullName } from '@shared/utils/humanName'
import { tw, twx } from '@shared/utils/tailwind'
import { getRelationshipLabel } from './helpers'

interface ReadOnlyAndDeletableOptionProps {
  name?: string
  data?: Contact
  onRemove?: (contact: Contact) => void
}

/**
 * Selected Item component in list with read-only or deletable
 */

export default function ReadOnlyAndDeletableOption(
  props: ReadOnlyAndDeletableOptionProps
) {
  const { name: dropdownName, data, onRemove } = props

  let orgNameHolder
  let relationshipHolder
  let label
  let removeBtn
  const { name, organization: org, relationship } = data || {}

  if (relationship) {
    relationshipHolder = (
      <span className={tw`mute-text ml-[8px]`}>
        {getRelationshipLabel(relationship)}
      </span>
    )
  }

  if (org && org.name) {
    orgNameHolder = (
      <span className={tw`mute-text ml-0`}>
        <BigDotDivider />
        {org.name}
      </span>
    )
  }

  if (name && getFullName(name)) {
    label = (
      <span data-cy={`${dropdownName}-fullName-label`}>
        {getFullName(name)}
      </span>
    )
  }

  if (onRemove) {
    removeBtn = (
      <div>
        <button
          name={`${dropdownName}-close-icon`}
          type="button"
          onClick={() => data && onRemove(data)}
        >
          <Icon name="close" className={tw`text-secondary-06`} />
        </button>
      </div>
    )
  }

  return (
    <div
      className={twx(
        'flex w-full',
        'text-[13px] font-semibold leading-[14px]',
        'rounded-input border border-button-inner-border-color bg-duotone-level6',
        'min-h-[40px] min-w-[100px] px-[12px] py-[11px]'
      )}
    >
      <div className={tw`grow text-secondary-06`}>
        {label}
        {relationshipHolder}
        {orgNameHolder}
      </div>
      {removeBtn}
    </div>
  )
}
