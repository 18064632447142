import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import {
  MedicationOrder,
  MedOrderFormData,
} from '@shared/types/medication_order'
import {
  FormattedRxTerm,
  StrengthAndForm,
} from '@app/components/Residents/Medications/MedicationSearch/helpers'

type MedicationOrderSearchOption =
  | { tag: 'Medication'; value: FormattedRxTerm }
  | { tag: 'Vital Order' }

export default function useMedicationSearch({
  order,
}: {
  order?: MedicationOrder
}) {
  const { reset, formState, register, control, setValue, watch } =
    useFormContext<MedOrderFormData>()
  const [rxTermResponse, setRxTermResponse] = useState<
    OptionTypeBase<FormattedRxTerm>[]
  >([])
  const [strengthAndFormOptions, setStrengthAndFormOptions] = useState<
    OptionTypeBase<StrengthAndForm>[]
  >([])
  const selectedMedication = watch('displayName')
  const selectedVital = watch('vitalType')
  const medicationOrderType = selectedVital ? 'vital' : 'rx'

  const medicationOptions: OptionTypeBase<MedicationOrderSearchOption>[] =
    rxTermResponse.map((rxTerm) => ({
      label: rxTerm.label,
      value: {
        tag: 'Medication',
        value: rxTerm.value,
      },
    }))

  if (
    selectedMedication &&
    !rxTermResponse.find((re) => re.value.displayName === selectedMedication)
  ) {
    medicationOptions.push({
      label: selectedMedication,
      value: {
        tag: 'Medication',
        value: {
          displayName: selectedMedication,
          codeName: selectedMedication,
          strengthsAndForms: [],
        },
      },
    })
  }

  useEffect(() => {
    if (order?.medicationStatement.medication?.strengthAndForm) {
      setStrengthAndFormOptions([
        {
          label:
            order.medicationStatement.medication.strengthAndForm ?? 'Unknown',
          value: {
            strengthAndForm:
              order.medicationStatement.medication.strengthAndForm,
            rxCui: order.medicationStatement.medication.code?.coding?.[0].code,
          },
        },
      ])
    }
  }, [order?.id ?? ''])

  return {
    control,
    medicationOptions,
    register,
    reset,
    setRxTermResponse,
    setStrengthAndFormOptions,
    setValue,
    strengthAndFormOptions,
    selectedMedication,
    selectedVital,
    medicationOrderType,
    formState,
  }
}
