import { ServicePlanObjectivesGetResponse_Objective } from '@augusthealth/models/com/august/protos/api/service_plan_objectives'
import { useContext, useEffect, useReducer, useRef, useState } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import useCurrentPage, { extractIds } from '@shared/hooks/useCurrentPage'
import { getTasksUrl, getTaskUrl } from '@shared/legacy_routes'
import { CategoryKeyIdentifier } from '@shared/types/assessment_configuration'
import { Person } from '@shared/types/person'
import { DataType } from '@shared/types/snapshot'
import { getOrElse, mapLoading } from '@shared/utils/loading'
import notEmpty from '@shared/utils/notEmpty'
import { buildCategoryKeyIdentifier } from '@shared/utils/residentAssessment'
import { fetchObjectiveOptions } from '@app/api/servicePlan'
import SignatureFlow from '@app/components/SignatureFlow'
import PersonContext from '@app/contexts/PersonContext'
import TaskTemplateContext from '@app/contexts/TaskTemplateContext'
import useAssessment from '@app/hooks/useAssessment'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import useSnapshot from '@app/hooks/useSnapshot'
import useTemplate from '@app/hooks/useTemplate'
import {
  getOrderedCategories,
  residentAssessmentOrServicePlanPath,
} from '../ResidentAssessment/helpers'
import { ServicePlanSteps } from '../TaskPageNavigation/step-definitions'
import TaskPageNavigation from '../TaskPageNavigation/TaskPageNavigation'
import { countIncompleteSections } from './helpers'
import MissingSectionsBanner from './MissingSectionsBanner'
import ServicePlanContext from './ServicePlanContext'
import ServicePlanHeader from './ServicePlanHeader'
import ServicePlanPage from './ServicePlanPage'
import { initialState, reducer } from './stateManagement'

export default function ServicePlan() {
  const history = useHistory()

  const { person, tasks = [] } = useContext(PersonContext)
  const [state, dispatch] = useReducer(reducer, initialState)
  const promiseRef = useRef(Promise.resolve())
  const currentPage = useCurrentPage()
  const { facilityId, orgId, personId, stepNumber, taskId } =
    extractIds(currentPage)
  const { currentFacility } = useCurrentFacility()

  const task = tasks.find((task) => task.id === taskId)

  const { assessment, refreshAssessment } = useAssessment({
    person: person ?? ({} as Person),
    withOriginalSettings: true,
  })
  const { snapshot: servicePlan } = useSnapshot({
    dataType: DataType.DATA_TYPE_SERVICE_PLAN,
  })

  const [objectiveOptions, setObjectiveOptions] = useState<
    ServicePlanObjectivesGetResponse_Objective[]
  >([])
  const [reviewPdfIsLoaded, setReviewPdfIsLoaded] = useState(false)

  const taskTemplate = useTemplate({
    dataType: DataType.DATA_TYPE_SERVICE_PLAN,
    person: person!,
  })

  useEffect(() => {
    if (orgId && facilityId && personId) {
      void fetchObjectiveOptions({ orgId, facilityId, personId }).then(
        (response) => setObjectiveOptions(response.objectiveOptions || [])
      )
    }
  }, [])

  useEffect(() => {
    if (reviewPdfIsLoaded) {
      setReviewPdfIsLoaded(false)
    }
  }, [stepNumber])

  useEffect(() => {
    if (servicePlan.tag === 'Complete') {
      dispatch({
        actionType: 'loadData',
        payload: {
          backendServicePlan: servicePlan.value.data?.servicePlan || {},
        },
      })
    }
  }, [servicePlan.tag])

  if (
    person === undefined ||
    task === undefined ||
    taskTemplate === undefined ||
    orgId === undefined ||
    personId === undefined ||
    servicePlan.tag !== 'Complete'
  ) {
    return null
  }

  const currentStepNumber = parseInt(stepNumber || '1')
  const categoriesForStep = getOrElse(
    mapLoading(assessment, (loaded) => {
      // Categories loaded from appraisal settings
      const categories = getOrderedCategories({
        categories: loaded.appraisal?.settings?.categories ?? [],
      })

      return categories
        .filter(
          (c) =>
            c.categoryOptions?.page === currentStepNumber &&
            !c.isExcludedFromServicePlan
        )
        .map((c) => buildCategoryKeyIdentifier(c))
        .filter(notEmpty)
    }),
    []
  ) as CategoryKeyIdentifier[]

  const taskPath = getTaskUrl({
    orgId,
    facilityId: facilityId || '',
    personId,
    id: taskId,
  })

  return (
    <ServicePlanContext.Provider
      value={{
        assessment,
        refreshAssessment,
        state,
        dispatch,
        person,
        servicePlan,
        objectiveOptions,
      }}
    >
      <TaskTemplateContext.Provider value={{ signableForm: taskTemplate }}>
        <div className="flex flex-col">
          <ServicePlanHeader task={task} />
          <div className="mt-[64px] flex">
            <TaskPageNavigation
              stepLinks={ServicePlanSteps}
              currentStepNumber={currentStepNumber}
              promiseQueue={Promise.resolve()}
              promiseRef={promiseRef}
              person={person}
              task={task}
            />
            <Switch>
              <Route exact path={taskPath + '/7'}>
                {servicePlan.tag === 'Complete' &&
                  taskTemplate.tag === 'Complete' && (
                    <SignatureFlow
                      task={task}
                      person={person}
                      onClose={history.goBack}
                      subHeaderComponent={
                        <MissingSectionsBanner
                          incompleteCount={countIncompleteSections({
                            assessment:
                              (assessment.tag === 'Complete' &&
                                assessment.value.appraisal) ||
                              undefined,
                            state,
                            facility:
                              currentFacility?.tag === 'Complete'
                                ? currentFacility.value
                                : undefined,
                          })}
                        />
                      }
                      setIsLoaded={setReviewPdfIsLoaded}
                      forceHideVerticalOverflow={!reviewPdfIsLoaded}
                    />
                  )}
              </Route>
              <Route exact path={residentAssessmentOrServicePlanPath}>
                <ServicePlanPage
                  categories={categoriesForStep}
                  onFinishLater={() => {
                    history.push(
                      getTasksUrl({
                        orgId,
                        facilityId: facilityId || '',
                        personId,
                      })
                    )
                  }}
                  onContinue={() => {
                    const nextStepNumber = currentStepNumber + 1
                    history.push(`${taskPath}/${nextStepNumber}`)
                  }}
                  promiseRef={promiseRef}
                />
              </Route>
            </Switch>
          </div>
        </div>
      </TaskTemplateContext.Provider>
    </ServicePlanContext.Provider>
  )
}
