import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useState } from 'react'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Incident } from '@shared/types/incidents'
import { Person } from '@shared/types/person'
import { deleteIncidentAttachment, getIncident } from '@app/api/incidents'
import ConfirmModal from '@app/components/ConfirmModal'
import styles from '../styles.module.css'
import AttachmentLink from '../IncidentAttachments/AttachmentLink'
import DeleteAttachmentLink from '../IncidentAttachments/DeleteAttachmentLink'

export default function IncidentDocuments({
  incident,
  person,
  onUpdate,
}: {
  incident: Incident
  person: Person
  onUpdate: (newIncident: Incident) => Promise<void>
}) {
  const [loadingAttachmentId, setLoadingAttachmentId] = useState<
    string | undefined
  >(undefined)

  const [idOfAttachmentToBeDeleted, setShowAttachmentDeletionConfirmation] =
    useState<string | undefined>(undefined)

  const { setError } = useContext(GlobalContext)

  const { attachments } = incident

  if (!attachments) {
    return null
  }

  const onConfirm = async () => {
    try {
      if (idOfAttachmentToBeDeleted) {
        setLoadingAttachmentId(idOfAttachmentToBeDeleted)

        await deleteIncidentAttachment({
          person,
          incidentId: incident.id!,
          snapshotId: idOfAttachmentToBeDeleted,
        })

        const updatedIncident = await getIncident({
          incident,
          person,
        })

        await onUpdate(updatedIncident)
      }
    } catch (e) {
      setError(e)
    } finally {
      setLoadingAttachmentId(undefined)
      setShowAttachmentDeletionConfirmation(undefined)
    }
  }

  return (
    <>
      <div className="w-[144px] text-[12px] font-semibold uppercase leading-[16px] text-gray-06">
        Documents
      </div>
      <div className="mt-[12px] flex grow flex-col pr-[16px]">
        {attachments.map((attachment) => {
          return (
            <div
              key={attachment.id}
              data-testid="attachments-container"
              className={styles.followUpActionRow}
            >
              <AttachmentLink
                isLoading={loadingAttachmentId === attachment.id}
                setIsLoading={setLoadingAttachmentId}
                attachment={attachment}
              />
              <PersonPermissionGate
                person={person}
                permissions={[
                  GroupPermission.GROUP_PERMISSION_INCIDENT_ATTACHMENT_DELETE,
                ]}
              >
                <div data-testid="delete-attachment">
                  <DeleteAttachmentLink
                    attachment={attachment}
                    setShowAttachmentDeletionConfirmation={
                      setShowAttachmentDeletionConfirmation
                    }
                  />
                </div>
              </PersonPermissionGate>
            </div>
          )
        })}
      </div>

      {idOfAttachmentToBeDeleted && (
        <ConfirmModal
          title="Are you sure?"
          content={
            <div className="content-text">
              Clicking "confirm" will result in deletion of this attachment
            </div>
          }
          confirmButtonConfig={{
            onClick: onConfirm,
            children: 'Confirm',
          }}
          denyButtonConfig={{
            onClick: () => setShowAttachmentDeletionConfirmation(undefined),
          }}
        />
      )}
    </>
  )
}
