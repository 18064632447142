import {
  AppraisalSettings_AppraisalCategoryLevel,
  AppraisalSettings_Level,
} from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { cloneDeep } from 'lodash'
import {
  AssessmentLevel,
  LevelModalMode,
  UpdatedDetailMap,
} from '@shared/types/assessment_configuration'
import notEmpty from '@shared/utils/notEmpty'
import { ADDED, NEEDS, REMOVED } from '../helpers'

export const SUPPORTED_LEVELS = [
  AppraisalSettings_Level.LEVEL_NONE,
  AppraisalSettings_Level.LEVEL_MINIMAL,
  AppraisalSettings_Level.LEVEL_MODERATE,
  AppraisalSettings_Level.LEVEL_MAX,
]

export const getAddEditLevelModalDefaultValues = ({
  mode,
}: {
  mode?: LevelModalMode
}) => {
  if (mode?.tag === 'edit') {
    return {
      level: mode.currentLevel.level,
      description: mode.currentLevel.description,
      score: mode.currentLevel.score,
    }
  }

  return null
}

/**
 * Used to add a new level in the Assessment Config Tool via the modal
 * Will return the levels in the order of SUPPORTED_LEVELS to ensure we always go from min to max
 * @param currentLevels the current levels
 * @param newLevel the new level to add
 */
export const addLevel = ({
  currentLevels,
  newLevel,
}: {
  currentLevels: AssessmentLevel[]
  newLevel: AssessmentLevel
}): AssessmentLevel[] => {
  const orderedLevels: AssessmentLevel[] = []

  SUPPORTED_LEVELS.forEach((level) => {
    if (newLevel.level! === level) {
      const matching = currentLevels.find((l) => l?.level === level)
      if (matching && matching.tempId === REMOVED) {
        orderedLevels.push({
          ...newLevel,
          tempId: level,
        })
      } else {
        orderedLevels.push(newLevel)
      }
    } else {
      const matching = currentLevels.find((l) => l?.level === level)
      if (matching) {
        orderedLevels.push(matching)
      }
    }
  })

  return orderedLevels
}

/**
 * Used to update a level via the modal in the Assessment Config Tool
 * @param index index of the level to update
 * @param updatedLevel the updated level to replace the existing level
 * @param currentLevels the current levels
 */
export const replaceExistingLevel = ({
  index,
  updatedLevel,
  currentLevels,
}: {
  index: number
  updatedLevel: AssessmentLevel
  currentLevels: AssessmentLevel[]
}) => {
  const newLevels = Array.from(currentLevels.slice())
  newLevels[index] = updatedLevel

  return newLevels
}

/**
 * Used to update a level in-line in the Assessment Config Tool
 * @param value the updated value
 * @param index index of the field to update
 * @param toUpdate the field to update
 * @param currentLevels the current levels
 */
export const generateNewLevels = ({
  value,
  index,
  toUpdate,
  currentLevels,
}: {
  value: string | number
  index: number
  toUpdate: 'description' | 'score'
  currentLevels: AssessmentLevel[]
}): AssessmentLevel[] => {
  const newValue: string = value as string

  const newLevels = Array.from(currentLevels.slice())
  if (toUpdate === 'description') {
    newLevels[index].description = newValue
  } else if (toUpdate === 'score') {
    const parsedValue = parseInt(newValue)
    newLevels[index].score = isNaN(parsedValue) ? 0 : parsedValue
  }

  return newLevels
}

/**
 * Used to remove a level in the Assessment Config Tool
 * @param index index of the level to remove
 * @param currentLevels the current levels
 */
export const removeLevel = ({
  index,
  currentLevels,
}: {
  index: number
  currentLevels: AssessmentLevel[]
}): AssessmentLevel[] => {
  const newLevels = Array.from(currentLevels.slice())

  if (newLevels[index].tempId === ADDED) {
    delete newLevels[index]
  } else {
    newLevels[index] = {
      ...cloneDeep(newLevels[index]),
      tempId: REMOVED,
    }
  }
  return newLevels.filter(notEmpty)
}

/**
 * Used to get the list of updated levels (pending changes) in the Assessment Config Tool
 * @param updated The updated levels - should match what you see in the UI
 * @param original The original levels - what the assessment was first loaded with
 */
export const getListOfUpdatedLevels = (
  updated: AssessmentLevel[],
  original: AssessmentLevel[]
): UpdatedDetailMap => {
  const updates: UpdatedDetailMap = {}

  updated.forEach((newLevel) => {
    const levelIdentifier = newLevel.level!
    const originalLevel = original.find((l) => l.level === newLevel.level) ?? {}

    updates[levelIdentifier] = []

    if (newLevel.tempId === REMOVED) {
      updates[levelIdentifier].push({
        type: 'removeLevel',
        valueChanged: 'removed level',
        level: newLevel.level!,
        group: NEEDS,
      })
      return
    } else if (newLevel.tempId === ADDED && !originalLevel.level) {
      updates[levelIdentifier].push({
        type: 'newLevel',
        valueChanged: 'added level',
        description: newLevel.description!,
        score: newLevel.score!,
        level: newLevel.level!,
        group: NEEDS,
      })
      return
    } else {
      if (newLevel.description !== originalLevel.description) {
        updates[levelIdentifier].push({
          type: 'level',
          valueChanged: 'description',
          original: originalLevel.description!,
          updated: newLevel.description!,
          level: newLevel.level!,
          group: NEEDS,
        })
      }

      if (newLevel.score !== originalLevel.score) {
        updates[levelIdentifier].push({
          type: 'level',
          valueChanged: 'score',
          original: originalLevel.score!,
          updated: newLevel.score!,
          level: newLevel.level!,
          group: NEEDS,
        })
      }
    }
  })

  return updates
}

/**
 * Used to attach a tempId to the levels to help with tracking changes
 * @param levels
 */
export const buildAssessmentLevels = (
  levels: AppraisalSettings_AppraisalCategoryLevel[]
): AssessmentLevel[] => {
  return levels.map((level) => {
    return {
      ...cloneDeep(level),
      tempId: `${level.level}`,
    }
  })
}

/**
 * Returns an array of the levels that are currently implemented in the assessment
 * @param levels
 */
export const getCurrentlyImplementedLevels = (
  levels: AssessmentLevel[]
): AppraisalSettings_Level[] => {
  const currentLevels: AppraisalSettings_Level[] = []

  levels.forEach((level) => {
    if (level.tempId !== 'REMOVED') {
      currentLevels.push(level.level!)
    }
  })
  return currentLevels
}

/**
 * Returns boolean indicating if all 4 levels are represented in the assessment
 * @param levels
 */
export const allLevelsAreRepresented = (levels: AssessmentLevel[]): boolean => {
  const currentLevels = getCurrentlyImplementedLevels(levels)
  return SUPPORTED_LEVELS.every((level) => currentLevels.includes(level))
}
