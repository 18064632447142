import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { Label } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { ErrorDropdownStyleConfig } from '@shared/components/Selects/utils'
import { UnitOfTime } from '@shared/types/timing'
import {
  validatePositiveNumber,
  validateRequired,
} from '@shared/utils/formValidationFunctions'
import { EVERY_BLANK_PERIOD_UNIT_OPTIONS } from '@shared/utils/medicationStatement'
import styles from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/styles.module.css'
import { RoutineOrderFormData } from './RoutineOrderForm'

type Props = {
  index: number
  readOnly: boolean
}
export const RoutineEveryBlankPeriod = ({ index, readOnly }: Props) => {
  const { register, unregister, formState, control, getValues, setValue } =
    useFormContext<RoutineOrderFormData>()

  useEffect(() => {
    return () => {
      if (getValues(`schedules.${index}.periodChoice`) !== 'Every') {
        unregister(`schedules.${index}.period`)
      }
    }
  }, [])

  return (
    <>
      <Label
        visuallyHidden
        htmlFor={`schedules.${index}.period`}
        id={`label-schedules.${index}.period`}
      >
        Period
      </Label>
      <BasicInput
        type={'number'}
        {...register(`schedules.${index}.period`, {
          valueAsNumber: true,
          validate: validatePositiveNumber,
        })}
        className={styles.period}
        showErrorBorder={!!formState.errors.schedules?.[index]?.period?.message}
        placeholder={'# of...'}
        disabled={readOnly}
      />
      <Controller
        control={control}
        name={`schedules.${index}.periodUnit`}
        rules={{
          validate: validateRequired,
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <>
              <Label
                visuallyHidden
                htmlFor={`schedules.${index}.variablePeriodUnit`}
                id={`label-schedules.${index}.variablePeriodUnit`}
              >
                Variable Period Unit
              </Label>
              <StyledSelect
                aria-labelledby={`label-schedules.${index}.variablePeriodUnit`}
                name={`schedules.${index}.variablePeriodUnit`}
                id={`schedules.${index}.variablePeriodUnit`}
                options={EVERY_BLANK_PERIOD_UNIT_OPTIONS}
                placeholder="Select..."
                onChange={(e: OptionTypeBase) => {
                  onChange(e.value)
                  if (e.value === UnitOfTime.UNIT_OF_TIME_HOUR) {
                    setValue(`schedules.${index}.frequency`, 1)
                  }
                }}
                value={EVERY_BLANK_PERIOD_UNIT_OPTIONS.find(
                  (opt) => opt.value === value
                )}
                className={styles.periodUnit}
                styles={ErrorDropdownStyleConfig({ error: !!error })}
                isDisabled={readOnly}
              />
            </>
          )
        }}
      />
    </>
  )
}
