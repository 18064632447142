import { useEffect, useState } from 'react'
import { AsyncResult, LOADING } from '@shared/utils/loading'

/**
 * Use a Promise<T> as a AsyncResult<T>
 */
export default function useAsyncResult<T>(promiseFn: () => Promise<T>) {
  const [result, setResult] = useState<AsyncResult<T, unknown>>(LOADING)

  useEffect(() => {
    setResult(LOADING)
    promiseFn()
      .then((result) => setResult({ value: result, tag: 'Complete' }))
      .catch((error) => setResult({ value: error, tag: 'Error' }))
  }, [])

  return result
}
