import { Controller, useForm } from 'react-hook-form'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import { Label, LabelAboveInput } from '@shared/components/Labels'
import StyledSelect from '@shared/components/Selects/StyledSelect'
import { Incident } from '@shared/types/incidents'
import { Person } from '@shared/types/person'
import { DeepNull } from '@shared/types/utilities'
import { hasActiveAlert, remainingAlertTime } from '@shared/utils/incident'
import { tw } from '@shared/utils/tailwind'
import { setIncidentAlert } from '@app/api/incidents'
import {
  checkEveryOptions,
  constructDefaultValues,
  durationOptions,
  toIncidentAlert,
} from './helpers'

export type AlertData = {
  durationDays: { label: string; value: number } | null
  checkEveryHours: { label: string; value: number | boolean }
  instructions: string
}

export default function OnAlertModal({
  incident,
  person,
  onClose,
}: {
  incident: Incident
  person: Person
  onClose: (newIncident?: Incident) => Promise<void>
}) {
  const alertHasExpired = !hasActiveAlert(incident)
  const { formState, control, register, handleSubmit } = useForm<AlertData>({
    defaultValues: constructDefaultValues(alertHasExpired ? {} : incident),
  })

  const onSubmit = async (data) => {
    const alert = toIncidentAlert(data, incident)
    const newIncident: DeepNull<Incident> = {
      id: incident.id,
      alert,
    }
    await setIncidentAlert({ person, incident: newIncident })
    await onClose({ ...incident, ...(newIncident as Incident) })
  }

  const isEditingAlert = hasActiveAlert(incident)

  return (
    <Modal
      open={true}
      onClose={() => onClose()}
      id={'add-edit-alert'}
      contentClassName={tw`w-[464px] min-w-[464px] max-w-[464px]`}
    >
      <form style={{ width: '400px' }} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="durationDays"
          render={({ field: { onChange, value } }) => (
            <div>
              <LabelAboveInput htmlFor="durationDays">
                Alert Duration
              </LabelAboveInput>
              <StyledSelect
                placeholder={`For another ${remainingAlertTime(incident)}...`}
                options={durationOptions}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
        <div className={'mt-[32px]'}>
          <LabelAboveInput htmlFor={'instructions'}>
            Alert Instructions
          </LabelAboveInput>
          <BasicTextarea
            data-cy="alert-instructions"
            placeholder="E.g. check for elevated fever"
            {...register('instructions')}
          />
          <div className="flex items-center justify-between">
            <Label visuallyHidden htmlFor="checkEveryHours">
              Check how often?
            </Label>
            <Controller
              control={control}
              name="checkEveryHours"
              render={({ field: { onChange, value } }) => (
                <StyledSelect
                  className="grow"
                  menuPlacement="auto"
                  inputId="checkEveryHours"
                  options={checkEveryOptions}
                  styles={{
                    menuList: (provided) => ({
                      ...provided,
                      height: '192px',
                    }),
                  }}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>
        <AsyncIconButton
          buttonStyle="primary-fill"
          initialIcon="fa-bell"
          className="mt-[24px] w-full"
          isLoading={formState.isSubmitting}
          data-cy="submit-alert"
          data-testid="submit-alert"
          disabled={
            formState.isSubmitting || (isEditingAlert && !formState.isDirty)
          }
          type={'submit'}
        >
          {isEditingAlert ? 'Update' : 'Start'} Alert
        </AsyncIconButton>
        {isEditingAlert && (
          <AsyncIconButton
            type="button"
            onClick={async () => {
              await onClose()
            }}
            width="100%"
            className="mt-[8px]"
            isLoading={false}
            buttonStyle="secondary-outline"
          >
            Cancel
          </AsyncIconButton>
        )}
      </form>
    </Modal>
  )
}
