import { Popup } from '@augusthealth/august-frontend-form-elements'
import { useContext, useState } from 'react'
import { fetchJson } from '@shared/api/request'
import { fetchTask } from '@shared/api/task'
import { queueJobUrl } from '@shared/api/urls'
import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { Task } from '@shared/types/task'
import {
  oneOfSignaturesIsSigned,
  signaturesAreProcessing,
} from '@shared/utils/signature'
import { tw } from '@shared/utils/tailwind'
import { isSuperUser } from '@shared/utils/user'
import HUD from '@app/components/HUD'

type Props = {
  onClose: () => void
  task: Task
}

type TaskStatus = 'IS_RESET' | 'IS_PROCESSED' | 'IS_SIGNED'

export default function ProcessingPopup({ onClose, task }: Props) {
  const [hudText, setHudText] = useState('')
  const { setError } = useContext(GlobalContext)
  const { user } = useUserContext()
  const canReset =
    signaturesAreProcessing(task) &&
    !oneOfSignaturesIsSigned(task) &&
    isSuperUser(user)
  const { orgId, personId, id: taskId } = task
  const resetSignaturePacket = () => {
    if (orgId && personId && taskId) {
      fetchTask({ orgId, taskId, pId: personId })
        .then(({ data: latestTask }) => {
          // Check one more time before reset
          if (oneOfSignaturesIsSigned(latestTask)) {
            return Promise.resolve('IS_SIGNED')
          } else if (signaturesAreProcessing(latestTask)) {
            return fetchJson(queueJobUrl(), {
              method: 'POST',
              body: JSON.stringify({
                signaturePacketReset: {
                  orgId,
                  personId,
                  taskId,
                },
              }),
            }).then(() => Promise.resolve('IS_RESET'))
          }

          return Promise.resolve('IS_PROCESSED')
        })
        .then((res: TaskStatus) => {
          if (res === 'IS_RESET') {
            setHudText('Signature Package has been reset!')
          } else if (res === 'IS_PROCESSED') {
            setHudText('Signature Package is processed already, please reload!')
          } else if (res === 'IS_SIGNED') {
            setHudText(
              'You cannot reset a signed Package, please reach out the technical support!'
            )
          }
        })
        .catch(setError)
    }
  }

  if (hudText) {
    return <HUD onExpire={onClose}>{hudText}</HUD>
  }

  return (
    <Popup
      holderClassName="center-modal confirm-modal"
      modalClassName="grass"
      contentClassName="no-separator"
      defaultOpen
    >
      <div key="processing-popup-header" className={tw`mx-auto w-fit`}>
        <img alt="HelloSign Logo" src="/svg/logo--HelloSign.svg" />
      </div>
      <div key="processing-popup-body">
        <div>Your signature is still being processed.</div>
        <div>Please try again in a moment.</div>
      </div>
      <div key="processing-popup-footer">
        <Button
          buttonStyle="primary-fill"
          className={tw`w-[200px]`}
          onClick={onClose}
        >
          OKAY
        </Button>
        {canReset && (
          <Button
            buttonStyle="secondary-outline"
            className={tw`ml-[16px] w-[200px]`}
            onClick={resetSignaturePacket}
          >
            Reset Signature Package
          </Button>
        )}
      </div>
    </Popup>
  )
}
