import { Immunization } from '@augusthealth/models/com/august/protos/immunization'
import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext } from 'react'
import { fetchBlobUrlAndContentType } from '@shared/api/request'
import ButtonLink from '@shared/components/ButtonLink'
import {
  ClickableEmbossedCard,
  EmbossedCard,
} from '@shared/components/EmbossedCard'
import Icon from '@shared/components/Icon'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { snapshotFileUrl } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { DataType } from '@shared/types/snapshot'
import { formatDate, fromDateMessageToDate } from '@shared/utils/date'
import { tw, twx } from '@shared/utils/tailwind'

export default function ImmunizationInfo({
  person,
  immunization,
  onDelete,
  onRemoveSnapshotId,
  onAddAttachment,
  selected = false,
  switchAttachment,
}: {
  person: Person
  immunization: Immunization
  onDelete: (immu: Immunization) => void
  onRemoveSnapshotId?: (immu: Immunization) => void
  onAddAttachment?: (files: FileList, immu: Immunization) => void
  selected?: boolean
  switchAttachment?: (snapshotId?: string) => void
}) {
  const { setError } = useContext(GlobalContext)
  const {
    attachmentSnapshotId,
    id,
    lotNumber,
    occurrenceDate,
    manufacturer,
    performer,
    protocolApplied,
    route,
    site,
  } = immunization
  const { id: personId, orgId } = person
  const dateObj = fromDateMessageToDate(occurrenceDate)
  const formattedDate = dateObj
    ? formatDate(dateObj, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    : undefined
  const content = (
    <>
      <div className={tw`flex-1 text-[14px] font-medium leading-[16px]`}>
        <div className={tw`font-semibold`}>
          {protocolApplied?.targetDisease?.text}
          {attachmentSnapshotId && (
            <PersonPermissionGate
              person={person}
              permissions={[GroupPermission.GROUP_PERMISSION_SNAPSHOT_READ]}
            >
              <ButtonLink
                className={tw`text-gray-07`}
                onClick={(ev) => {
                  ev.stopPropagation()
                  if (personId && orgId) {
                    const url = snapshotFileUrl({
                      personId,
                      orgId,
                      dataType: DataType.DATA_TYPE_IMMUNIZATION_RECORD,
                      snapshotId: attachmentSnapshotId,
                    })
                    fetchBlobUrlAndContentType({ url })
                      .then((blobData) => window.open(blobData.url, '_blank'))
                      .catch(setError)
                  }
                }}
              >
                <Icon
                  className={tw`ml-[8px]`}
                  name="file-magnifying-glass"
                  variant="light"
                  title="View attachment"
                />
              </ButtonLink>
            </PersonPermissionGate>
          )}
          {attachmentSnapshotId && onRemoveSnapshotId && (
            <PersonPermissionGate
              person={person}
              permissions={[GroupPermission.GROUP_PERMISSION_SNAPSHOT_DELETE]}
            >
              <ButtonLink
                className={tw`text-gray-07`}
                onClick={(ev) => {
                  ev.stopPropagation()
                  onRemoveSnapshotId(immunization)
                }}
              >
                <Icon
                  className={tw`ml-[8px]`}
                  name="file-minus"
                  variant="light"
                  title="Remove attachment"
                />
              </ButtonLink>
            </PersonPermissionGate>
          )}
          {!attachmentSnapshotId && onAddAttachment && (
            <PersonPermissionGate
              person={person}
              permissions={[GroupPermission.GROUP_PERMISSION_SNAPSHOT_UPDATE]}
            >
              <label>
                <input
                  className={tw`hidden`}
                  onChange={(ev) => {
                    if (ev.target.files) {
                      onAddAttachment(ev.target.files, immunization)
                    }
                  }}
                  type="file"
                  accept="image/png, image/jpeg, .pdf"
                />
                <Icon
                  className={tw`ml-[8px] cursor-pointer text-gray-07 hover:text-primary-light`}
                  name="file-plus"
                  variant="light"
                  title="Add attachment"
                />
              </label>
            </PersonPermissionGate>
          )}
        </div>
        {formattedDate && <InfoRow>{formattedDate}</InfoRow>}
        {route && (
          <InfoRow>
            <span className={'capitalize'}> {route?.text}</span>
          </InfoRow>
        )}
        {performer && <InfoRow>Administrated by: {performer}</InfoRow>}
        {site && <InfoRow>Site: {site.text}</InfoRow>}
        {lotNumber && <InfoRow>LOT: {lotNumber}</InfoRow>}
        {manufacturer && <InfoRow>Manufacturer: {manufacturer.name}</InfoRow>}
      </div>
      <div className={tw`flex-0`}>
        <PersonPermissionGate
          person={person}
          permissions={[GroupPermission.GROUP_PERMISSION_SNAPSHOT_DELETE]}
        >
          <ButtonLink
            data-testid={`remove-immunization-btn-${id}`}
            className={tw`text-gray-07`}
            onClick={() => onDelete(immunization)}
          >
            <Icon
              className={tw`fa-fw text-[14px]`}
              name="trash"
              variant={'solid'}
            />
          </ButtonLink>
        </PersonPermissionGate>
      </div>
    </>
  )
  const className = tw`m-[1px] mb-[16px] flex p-[16px]`

  if (attachmentSnapshotId && !selected && switchAttachment) {
    return (
      <ClickableEmbossedCard
        cardProps={{ className }}
        buttonProps={{
          onClick: () => switchAttachment(attachmentSnapshotId),
        }}
      >
        {content}
      </ClickableEmbossedCard>
    )
  }

  return (
    <EmbossedCard className={twx(className, { 'card-outline': selected })}>
      {content}
    </EmbossedCard>
  )
}

function InfoRow({ children }: { children: React.ReactNode }) {
  return <div className={tw`mt-[4px] text-secondary-07`}>{children}</div>
}
