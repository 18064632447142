import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import Content from '@app/components/generic/Content'
import RouterTabs from '@app/components/RouterTabs'
import useSignableForms from '@app/hooks/useSignableForms'
import OrgFacilityTasks from '@app/pages/Tools/PdfTemplate/AssignTemplates/OrgFacilityTasks'
import StateTasks from '@app/pages/Tools/PdfTemplate/AssignTemplates/StateTasks'
import DisplayForm from '@app/pages/Tools/PdfTemplate/TemplateList/DisplayForm'
import {
  TOOLS_ALL_TEMPLATES_PATH,
  TOOLS_ASSIGN_TEMPLATES_PATH,
  TOOLS_CREATE_TEMPLATE_PATH,
  TOOLS_PDF_TEMPLATE_DISPLAY_FORM_PATH,
  TOOLS_PDF_TEMPLATE_ORG_PATH,
  TOOLS_PDF_TEMPLATE_STATE_PATH,
  TOOLS_RECENT_TEMPLATES_PATH,
  TOOLS_STATE_INCIDENT_TEMPLATES_PATH,
} from '../path'
import AssignTemplates from './AssignTemplates'
import CreatePdfTemplate from './CreatePdfTemplate'
import { PageTab } from './helpers'
import NotifyState from './NotifyState'
import SignableFormContext from './SignableFormContext'
import TemplateList from './TemplateList'

type MatchParams = {
  tab?: PageTab
}

type Props = RouteComponentProps<MatchParams>

export default function PdfTemplate(props: Props) {
  const { tab } = props.match.params
  const activeTab = () => {
    switch (tab) {
      case PageTab.RECENT_TEMPLATES:
        return 'Recent Templates'
      case PageTab.CREATE_PDF_TEMPLATE:
        return 'Create Template'
      case PageTab.ASSIGN_TEMPLATE:
      case PageTab.STATE_ASSIGNMENT:
        return 'Assign Templates'
      case PageTab.NOTIFY_STATE:
        return 'State Incident Templates'
      case PageTab.PDF_TEMPLATE_LIST:
        return 'All Templates'
      default:
        return 'Recent Templates'
    }
  }
  const { formList: recentFormList, reloadFormList: reloadRecentFormList } =
    useSignableForms('Recent Templates')
  const { formList, reloadFormList } = useSignableForms('All Templates')

  return (
    <SignableFormContext.Provider
      value={{
        signableFormsWithAssignments: formList,
        signableForms:
          formList.tag === 'Complete' ||
          formList.tag === 'LoadingWithPriorValue'
            ? {
                tag: formList.tag,
                value: formList.value.map((f) => f.signableForm),
              }
            : formList,
        reloadSignableForms: reloadFormList,
        recentSignableFormsWithAssignments: recentFormList,
        recentSignableForms:
          recentFormList.tag === 'Complete' ||
          recentFormList.tag === 'LoadingWithPriorValue'
            ? {
                tag: recentFormList.tag,
                value: recentFormList.value.map((f) => f.signableForm),
              }
            : recentFormList,
        reloadRecentSignableForms: reloadRecentFormList,
      }}
    >
      <Content>
        <RouterTabs
          activeTab={activeTab()}
          tabList={[
            {
              label: 'Recent Templates',
              url: TOOLS_RECENT_TEMPLATES_PATH,
            },
            {
              label: 'All Templates',
              url: TOOLS_ALL_TEMPLATES_PATH,
            },
            {
              label: 'Create Template',
              url: TOOLS_CREATE_TEMPLATE_PATH,
            },
            {
              label: 'Assign Templates',
              url: TOOLS_ASSIGN_TEMPLATES_PATH,
            },
            {
              label: 'State Incident Templates',
              url: TOOLS_STATE_INCIDENT_TEMPLATES_PATH,
            },
          ]}
        />
        <Switch>
          <Route exact path={TOOLS_RECENT_TEMPLATES_PATH}>
            <TemplateList key={'recent'} show={'Recent Templates'} />
          </Route>
          <Route exact path={TOOLS_ALL_TEMPLATES_PATH}>
            <TemplateList key={'all'} show={'All Templates'} />
          </Route>
          <Route
            exact
            path={TOOLS_PDF_TEMPLATE_DISPLAY_FORM_PATH}
            render={() => <DisplayForm />}
          />
          <Route
            exact
            path={TOOLS_CREATE_TEMPLATE_PATH}
            component={CreatePdfTemplate}
          />
          <Route
            exact
            path={TOOLS_ASSIGN_TEMPLATES_PATH}
            component={AssignTemplates}
          />
          <Route
            exact
            path={TOOLS_STATE_INCIDENT_TEMPLATES_PATH}
            component={NotifyState}
          />
          <Route
            exact
            path={TOOLS_PDF_TEMPLATE_STATE_PATH}
            component={StateTasks}
          />
          <Route
            exact
            path={TOOLS_PDF_TEMPLATE_ORG_PATH}
            component={OrgFacilityTasks}
          />
        </Switch>
      </Content>
    </SignableFormContext.Provider>
  )
}
