import { useContext, useEffect, useState } from 'react'
import { getPayerUserSettings } from '@shared/api/billing'
import GlobalContext from '@shared/contexts/GlobalContext'
import { PayerWithScheduledPayment } from '@shared/types/billing'
import { loaded, LOADING, Loading } from '@shared/utils/loading'

type Props = {
  orgId: string
  facilityId: string
  personId: string
}

export default function useBillingPayerUserSettings(props: Props) {
  const [billingPayerUserSettings, setBillingPayerUserSettings] =
    useState<Loading<PayerWithScheduledPayment>>(LOADING)
  const { setError } = useContext(GlobalContext)

  async function reloadBillingPayerUserSettings() {
    setBillingPayerUserSettings(LOADING)
    try {
      const result = await getPayerUserSettings(props)
      setBillingPayerUserSettings(loaded(result))
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    void reloadBillingPayerUserSettings()
  }, [])

  return {
    billingPayerUserSettings,
    reloadBillingPayerUserSettings,
  }
}
