import { cloneDeep } from 'lodash'
import { LastUpdate } from '@shared/types/api/last_update'
import { Person } from '@shared/types/person'
import { PersonStats, TaskStats } from '@shared/types/person_stats'
import { PickPartial } from '@shared/types/utilities'
import { getLastName } from '@shared/utils/humanName'
import {
  getCareGroup,
  isResident as getIsCurrentResident,
  isDischargedResident as getIsDischargedResident,
  isProspect as getIsProspect,
  isProspectClosed as getIsProspectClosed,
  getResidentMoveInDate,
  getResidentMoveOutDate,
  getResidentMoveOutReason,
  getRoomNumber,
  levelOfCareLabel,
} from '@shared/utils/person'
import { alphaNumericSort, Order, SortableValue } from '@shared/utils/sorting'

enum ItemType {
  PERSON = 'person',
  TASK_STATS = 'taskStats',
  LAST_UPDATE = 'lastUpdate',
}

function getPersonOrTaskStats(value: PersonStats, itemType?: ItemType) {
  if (itemType === ItemType.TASK_STATS) {
    return value.taskStats
  } else if (itemType === ItemType.LAST_UPDATE) {
    return value.lastUpdate
  }

  return value.person
}

export const isCurrentResident = (p: PersonStats) =>
  getIsCurrentResident(p.person)

export const isDischargedResident = (p: PersonStats) =>
  getIsDischargedResident(p.person)

export const isProspect = (p: PersonStats) => getIsProspect(p.person)

export const isProspectClosed = (p: PersonStats) =>
  getIsProspectClosed(p.person)

export function sortPeople(
  residents: PersonStats[],
  getterFunc: (
    p:
      | PickPartial<
          Person,
          'name' | 'admissionsInformation' | 'careGroupDetails' | 'levelOfCare'
        >
      | TaskStats
      | LastUpdate
      | undefined
  ) => SortableValue,
  order?: Order,
  itemType?: ItemType
): PersonStats[] {
  return cloneDeep(residents).sort((resident1, resident2) => {
    const data1 = getPersonOrTaskStats(resident1, itemType)
    const data2 = getPersonOrTaskStats(resident2, itemType)
    const val1 = data1 && getterFunc(data1)
    const val2 = data2 && getterFunc(data2)

    return alphaNumericSort(val1, val2, order)
  })
}

export function sortPeopleByLastName(
  residents: PersonStats[],
  order?: Order
): PersonStats[] {
  return sortPeople(
    residents,
    (resident: Person) => getLastName(resident.name),
    order
  )
}

export function sortPeopleByMoveInDate(
  residents: PersonStats[],
  order: Order
): PersonStats[] {
  // Reverse order, so by default, newest date first
  return sortPeople(residents, getResidentMoveInDate, -order)
}

export function sortPeopleByMoveOutDate(
  residents: PersonStats[],
  order: Order
): PersonStats[] {
  // Reverse order, so by default, newest date first
  return sortPeople(residents, getResidentMoveOutDate, -order)
}

export function sortPeopleByMoveOutReason(
  residents: PersonStats[],
  order: Order
): PersonStats[] {
  return sortPeople(residents, getResidentMoveOutReason, order)
}

export function sortPeopleByRoomNumber(
  residents: PersonStats[],
  order: Order
): PersonStats[] {
  return sortPeople(residents, getRoomNumber, order)
}

export function sortPeopleByCareGroup(residents: PersonStats[], order: Order) {
  return sortPeople(residents, getCareGroup, order)
}

export function sortPeopleByLevelOfCare(
  residents: PersonStats[],
  order: Order
): PersonStats[] {
  return sortPeople(residents, levelOfCareLabel, order)
}

function getLastUpdateAt(lastUpdate: LastUpdate) {
  return lastUpdate.updatedAt
}

export function sortPeopleByLastModification(
  residents: PersonStats[],
  order: Order
): PersonStats[] {
  // Reverse order, so by default, newest date first
  return sortPeople(residents, getLastUpdateAt, -order, ItemType.LAST_UPDATE)
}

export function getCompletedTaskCounter(taskStats: TaskStats) {
  return taskStats.complete
}

export function sortPeopleByCompletedTaskCounter(
  residents: PersonStats[],
  order: Order
): PersonStats[] {
  // Reverse order, so by default, newest date first
  return sortPeople(
    residents,
    getCompletedTaskCounter,
    order,
    ItemType.TASK_STATS
  )
}
