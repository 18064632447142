import { Person } from '@shared/types/person'
import { getFullName } from '@shared/utils/humanName'
import HUD from '@app/components/HUD'
import styles from '../styles.module.css'

export default function ConfirmHUD({
  iconClassName,
  onExpire,
  person,
  title,
}: {
  iconClassName: string
  onExpire: () => void
  person: Person
  title: string
}) {
  return (
    <HUD className={styles.hudContainer} onExpire={onExpire}>
      <div className={styles.moveOutConfirmedContent}>
        <div>
          <i className={iconClassName} />
        </div>
        <div className="mt-[24px] text-[20px] font-semibold leading-[24px]">
          {title}
        </div>
        {person && person.name && (
          <div className="mt-[8px] text-[16px] leading-[24px]">
            {getFullName(person.name[0])}
          </div>
        )}
      </div>
    </HUD>
  )
}
