// https://app.hellosign.com/api/embeddedRequestingWalkthrough
// https://github.com/hellosign/hellosign-embedded/wiki/API-Documentation-(v2)#events
import { EmbeddedUrlResponse } from '@augusthealth/models/com/august/protos/api/esign'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getSignatureUrl, getSignInPersonUrl } from '@shared/api/signature'
import { fetchTasks, processSignature } from '@shared/api/task'
import LoadingPopup from '@shared/components/LoadingPopup'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { admissionTasksPathForPerson } from '@shared/legacy_routes'
import { isAdmin } from '@shared/utils/user'
import HelloSign from '@app/components/generic/HelloSign'
import { DEFAULT_HELLO_SIGN_DATA } from '@app/components/generic/HelloSign/defaults'
import PersonContext from '@app/contexts/PersonContext'
import ProspectsContext from '@app/contexts/ProspectsContext'
import { FormPageProps } from '../Forms/FormLayout/type'

export interface SignProps extends FormPageProps {
  fullscreen?: boolean
  isSignInPerson?: boolean
  taskId?: string
  onCloseBehavior?:
    | { tag: 'Redirect' }
    | { tag: 'Close HelloSign UI'; onSign: () => void; onClose: () => void }
  email?: string
}

type OnSignFinishProps = {
  orgId: string
  facilityId: string
  pId: string
  history: {
    push: (path: string) => void
  }
}

export function onSignFinish({
  orgId,
  facilityId,
  pId,
  history,
}: OnSignFinishProps) {
  if (window.name === 'signInPersonWindow' && window.opener) {
    // If Sign in a child window (for example, Sign-in-person in a separate browser tab)
    // Focus on the main window before closing Sign-in-person child window
    if (window.opener?.name) {
      // opener.name may not be supported in all Browsers
      window.opener.name = 'signInPersonParentWindow'
      const mainWin = window.open('', window.opener.name)
      mainWin?.focus()
    }
    window.close()
  } else {
    // Otherwise, redirect to Admission Tasks page
    history.push(admissionTasksPathForPerson({ facilityId, id: pId, orgId }))
  }
}

export default function Sign({
  onCloseBehavior = { tag: 'Redirect' },
  ...props
}: SignProps) {
  const [loading, setLoading] = useState<boolean>(false)
  const [helloSignData, setHelloSignData] = useState<EmbeddedUrlResponse>(
    DEFAULT_HELLO_SIGN_DATA
  )

  const { clientId, url, email } = helloSignData
  const history = useHistory()
  const { setError } = useContext(GlobalContext)
  const { person, tasks = [], setTasks } = useContext(PersonContext)
  const { setOpenCongrats } = useContext(ProspectsContext)
  const { user } = useUserContext()
  const {
    email: signerEmail,
    match,
    fullscreen = false,
    isSignInPerson = false,
  } = props
  const { params } = match
  const { facilityId, id: pId, orgId, taskId } = params
  //props.taskId is a fallback to control what task is being signed when taskId is not present in the url
  const taskIdBeingUsed = taskId ?? props.taskId
  const task = taskIdBeingUsed
    ? tasks.find(
        (t) =>
          t.id === taskIdBeingUsed && pId === t.personId && orgId === t.orgId
      )
    : undefined

  const { dataType, customType } = (task && task.taskTemplateInfo) || {}

  useEffect(() => {
    if (pId && orgId && dataType) {
      const coreParams = {
        pId,
        orgId,
        dataType,
        customType,
      }
      const func =
        isSignInPerson && signerEmail
          ? () =>
              getSignInPersonUrl({
                ...coreParams,
                email: signerEmail,
              })
          : () => getSignatureUrl(coreParams)
      setLoading(true)
      func()
        .then((res) => setHelloSignData(res.data))
        .catch(setError)
        .finally(() => setLoading(false))
    }
  }, [pId, orgId, dataType, isSignInPerson, customType])

  if (!task) {
    return null
  }

  const updateTasks = () => {
    return fetchTasks({ pId, orgId })
      .then((res) => setTasks(res && res.data))
      .catch(setError)
  }

  async function updateStatus() {
    if (!person || !task) return

    try {
      await processSignature({
        orgId: person.orgId!,
        facilityId: person.facilityId!,
        personId: person.id,
        taskId: task.id!,
        email: email!,
      })
    } catch (err) {
      if (err instanceof Error && 'status' in err && err.status !== 409) {
        setError(err)
      }
    }
  }

  const onCancel = async () => {
    if (onCloseBehavior?.tag === 'Redirect') {
      await updateTasks()
      onSignFinish({ orgId, facilityId, pId, history })
    } else if (onCloseBehavior?.tag === 'Close HelloSign UI') {
      onCloseBehavior.onClose()
    }
  }

  const onSign = async () => {
    await updateStatus()
    if (onCloseBehavior?.tag === 'Close HelloSign UI') {
      onCloseBehavior.onSign()
    } else if (onCloseBehavior?.tag === 'Redirect') {
      await updateTasks()
      if (
        isAdmin(user, { organizationId: orgId, facilityId }) ||
        isSignInPerson
      ) {
        setOpenCongrats('COMPLETED_TASK_CONTINUE')
      } else {
        // Sign and login as RP
        setOpenCongrats('COMPLETED_TASK_VISIT_DOCUMENTS')
      }
    }
  }

  if (clientId && url) {
    return (
      <HelloSign
        clientId={clientId}
        url={url}
        onCancel={onCancel}
        onErrorClose={onCancel}
        onSign={onSign}
        fullscreen={fullscreen}
      />
    )
  }

  return <LoadingPopup loading={loading} />
}
