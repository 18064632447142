import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { match } from 'ts-pattern'
import { login as requestLoginWithMagicLink } from '@shared/api/login'
import { resendMfaCode } from '@shared/api/user'
import { login } from '@shared/components/Auth/helpers'
import ConfigureMfa from '@shared/components/Auth/LoginWithUsernameOrEmail/Mfa/ConfigureMfa'
import MfaCode from '@shared/components/Auth/LoginWithUsernameOrEmail/Mfa/MfaCode'
import Badge from '@shared/components/Badge'
import LogoHeader from '@shared/components/LogoHeader'
import GlobalContext from '@shared/contexts/GlobalContext'
import ErrorMonitoring from '@shared/ErrorMonitoring'
import useQuery from '@shared/hooks/useQuery'
import {
  MfaChallenge,
  MfaCredentials,
  RequiredUserActionType,
} from '@shared/types/auth'
import {
  buildAugustError,
  isConnectivityError,
  isMagicLinksNotAllowedError,
} from '@shared/utils/error'
import { tw } from '@shared/utils/tailwind'
import EmailLoginForm from './EmailLoginForm'
import Footer from './Footer'
import ResetPassword from './ForgotPassword/ResetPassword'
import Loader from './Loader'
import UsernameLoginForm from './UsernameLoginForm'

const LoginStepUsername = { step: 'username' }
const LoginStepEmail = { step: 'email' }

type LoginStepForgotPassword = {
  step: 'forgotPassword'
  username: string
  password: string
}

type LoginStepConfigureMfa = {
  step: 'configureMfa'
  credentials: MfaCredentials
}

type LoginStepMfaCode = {
  step: 'mfaCode'
  credentials: MfaCredentials
  challenge: MfaChallenge
}

type LoginStep =
  | typeof LoginStepUsername
  | typeof LoginStepEmail
  | LoginStepForgotPassword
  | LoginStepConfigureMfa
  | LoginStepMfaCode

function isConfigureMfaStep(
  step: undefined | LoginStep
): step is LoginStepConfigureMfa {
  return step?.step === 'configureMfa'
}

function isMfaCodeStep(step: undefined | LoginStep): step is LoginStepMfaCode {
  return step?.step === 'mfaCode'
}

function isForgotPasswordStep(
  step: undefined | LoginStep
): step is LoginStepForgotPassword {
  return step?.step === 'forgotPassword'
}

export type BtnState = 'engaged' | 'disengaged' | 'default'

function EmailLoginOnlyError() {
  return (
    <div className={tw`mb-[40px] mt-0 flex flex-col items-center text-center`}>
      <Badge color={'darkOrange'} className={tw`mb-4`}>
        Email & password required
      </Badge>
      <p className={tw`text-sm font-medium`}>
        Your organization requires added security for all users.
        <br />
        Please login with your email and password.
      </p>
    </div>
  )
}

export const DEFAULT_LOGIN_TITLE = ''
export default function LoginWithUsernameOrEmail({
  title = DEFAULT_LOGIN_TITLE,
  applicationBasePath,
}: {
  title?: string
  applicationBasePath: string
}) {
  const history = useHistory()
  const params = useQuery()
  const credentialsOnly = params.get('credentials') === 'true'
  const [allowMagicLinkLogin, setAllowMagicLinkLogin] =
    useState(!credentialsOnly)
  const [loginStep, setLoginStep] = useState<LoginStep>(LoginStepUsername)
  const [email, setEmail] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { setError } = useContext(GlobalContext)

  if (isLoading) {
    return <Loader title="Logging you in" />
  }

  function getBtnState(type: LoginStep) {
    if (loginStep === type) {
      return 'engaged'
    }

    return 'disengaged'
  }

  async function onEmailSubmit({ email }: { email: string }) {
    if (loginStep.step !== 'email') {
      return setLoginStep(LoginStepEmail)
    }
    setSubmitted(true)
    setEmail(email)
    try {
      await requestLoginWithMagicLink(email, history.location.pathname)
    } catch (e) {
      setSubmitted(false)
      if (isMagicLinksNotAllowedError(e)) {
        setAllowMagicLinkLogin(false)
        setLoginStep(LoginStepUsername)
        setEmail('')
      } else {
        setError(e)
      }
    }
  }

  async function onUsernameSubmit({
    preferredUsername,
    password,
  }: {
    preferredUsername: string
    password: string
  }) {
    if (loginStep.step !== 'username' && loginStep.step !== 'forgotPassword') {
      return setLoginStep(LoginStepUsername)
    }

    setIsLoading(true)
    try {
      const { action, username, mfaChallenge } = await login(
        preferredUsername,
        password
      )
      match(action)
        .with(RequiredUserActionType.NO_ACTION, () => {
          window.location.pathname = history.location.pathname
        })
        .with(RequiredUserActionType.NEW_PASSWORD_REQUIRED, () => {
          setLoginStep({
            step: 'forgotPassword',
            username: username || preferredUsername,
            password,
          })
          setIsLoading(false)
        })
        .with(RequiredUserActionType.ACCOUNT_LOCKED_TRY_AGAIN_LATER, () => {
          setError(
            buildAugustError({
              name: 'Account Locked',
              message:
                "You've attempted to login too many times. Try again later",
            })
          )
          setIsLoading(false)
        })
        .with(RequiredUserActionType.SETUP_MFA, () => {
          setIsLoading(false)
          setLoginStep({
            step: 'configureMfa',
            credentials: {
              username: username!,
              password: password,
            },
          })
        })
        .with(RequiredUserActionType.SMS_MFA, () => {
          setIsLoading(false)
          setLoginStep({
            step: 'mfaCode',
            challenge: mfaChallenge,
            credentials: {
              username: username!,
              password: password,
            },
          })
        })
        .with(RequiredUserActionType.EMAIL_OTP, () => {
          setIsLoading(false)
          setLoginStep({
            step: 'mfaCode',
            challenge: mfaChallenge,
            credentials: {
              username: username!,
              password: password,
            },
          })
        })
        .otherwise((action) => {
          ErrorMonitoring.capture({
            error: 'Unrecognized required action',
            level: 'warning',
            extras: {
              action: action as string,
            },
          })
          setIsLoading(false)
          setError(
            buildAugustError({
              name: 'Unrecognized required action',
              message:
                'Unable to login. Please reach out to August Health support for assistance',
            })
          )
        })
    } catch (e) {
      const error = isConnectivityError(e)
        ? e
        : buildAugustError({
            name: 'Something went wrong!',
            message:
              'Unable to login. Please reach out to August Health support for assistance',
          })

      setError(error)
      setIsLoading(false)
    }
  }

  if (submitted) {
    return (
      <div className={tw`flex flex-col items-center`} slot="sign-in">
        <LogoHeader title={`We've sent a temporary login link to ${email}`} />
        <Footer />
      </div>
    )
  }

  if (isConfigureMfaStep(loginStep)) {
    const { credentials } = loginStep
    return (
      <ConfigureMfa
        credentials={credentials}
        postRegistration={(challenge) => {
          setLoginStep({
            step: 'mfaCode',
            challenge,
            credentials,
          })
        }}
      />
    )
  }

  if (isMfaCodeStep(loginStep)) {
    const { credentials, challenge } = loginStep
    return (
      <MfaCode
        onSuccess={() => {
          window.location.pathname = history.location.pathname
        }}
        requestNewCode={async () => {
          const newChallenge = await resendMfaCode(
            credentials.username,
            credentials.password
          )
          setLoginStep({
            step: 'mfaCode',
            credentials,
            challenge: newChallenge,
          })
        }}
        mfaChallenge={challenge}
      />
    )
  }

  if (isForgotPasswordStep(loginStep)) {
    return (
      <ResetPassword
        userAlias={loginStep.username}
        currentPassword={loginStep.password}
        afterReset={({ preferredUsername, password }) => {
          void onUsernameSubmit({
            preferredUsername,
            password,
          })
        }}
      />
    )
  }

  return (
    <div
      className={tw`flex flex-col items-center overflow-hidden rounded-xl bg-white px-[60px] pb-[16px] pt-[60px]`}
    >
      <LogoHeader title={title} />

      {!allowMagicLinkLogin && <EmailLoginOnlyError />}

      {allowMagicLinkLogin && (
        <EmailLoginForm
          switchToUsername={() => setLoginStep(LoginStepUsername)}
          handleLogin={onEmailSubmit}
          state={getBtnState(LoginStepEmail)}
        />
      )}

      <UsernameLoginForm
        allowMagicLinkLogin={allowMagicLinkLogin}
        switchToEmail={() => setLoginStep(LoginStepEmail)}
        handleLogin={onUsernameSubmit}
        state={getBtnState(LoginStepUsername)}
        applicationBasePath={applicationBasePath}
      />

      <Footer />
    </div>
  )
}
