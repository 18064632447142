import { OccupancyStats } from '@augusthealth/models/com/august/protos/api/occupancy'
import {
  convertPercentageToString,
  getCurrentMonthStats,
  getMonthlyPercentage,
} from '@shared/utils/occupancy'

export const facilityDropdownClasses = ({
  facilityDropdownShowing,
  oneOrZeroFacilities,
}: {
  facilityDropdownShowing: boolean
  oneOrZeroFacilities: boolean
}) => {
  const classes =
    'flex items-center border-b border-border mb-[24px] text-[14px] justify-between relative'

  if (facilityDropdownShowing) {
    return classes + ' ' + 'bg-scrim shadow-md cursor-pointer transition-all'
  }

  if (oneOrZeroFacilities) {
    return (
      classes + ' ' + 'hover:bg-scrim border-b border-border cursor-default'
    )
  }

  return classes
}

export function getOccupancyPercentageLabel(stats?: OccupancyStats) {
  if (stats?.monthlyStats) {
    const currentMonthlyStats = getCurrentMonthStats(stats.monthlyStats)
    if (currentMonthlyStats) {
      const percentage = getMonthlyPercentage(currentMonthlyStats)
      if (percentage > 0) {
        return convertPercentageToString(percentage)
      }
    }
  }

  return ''
}
