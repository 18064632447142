import { useContext, useEffect, useState } from 'react'
import {
  fetchBlobUrlAndContentType,
  UrlAndContentType,
} from '@shared/api/request'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import GlobalContext from '@shared/contexts/GlobalContext'
import PDFReader from '@app/components/generic/PDFReader'
import styles from '../PDFReader/styles.module.css'

type BlobFileReaderProps = {
  filePath: string
  initialBlobUrl?: string
  documentScale?: number
  onBlobFileLoaded?: (blobData: UrlAndContentType) => unknown
  onBlobLoadingError?: (err: unknown) => unknown
  onPdfLoaded?: () => unknown // Triggered only Pdf file is loaded in PdfReader
  onPdfLoadingError?: () => unknown
}

export default function BlobFileReader({
  filePath,
  initialBlobUrl,
  documentScale = 1.75,
  onBlobFileLoaded = (blobData) => console.log(blobData),
  onBlobLoadingError = (err) => console.warn(err),
  onPdfLoadingError = () => console.warn('Has Pdf loading error'),
}: BlobFileReaderProps) {
  const [hasBlobLoadingError, setHasBlobLoadingError] = useState<boolean>(false)
  const [blobUrl, setBlobUrl] = useState<string | undefined>(initialBlobUrl)
  const { setError } = useContext(GlobalContext)

  useEffect(() => {
    setBlobUrl(undefined)
    setHasBlobLoadingError(false)
    fetchBlobUrlAndContentType({ url: filePath })
      .then((blobData: UrlAndContentType) => {
        setBlobUrl(blobData.url)
        onBlobFileLoaded(blobData)
      })
      .catch((err) => {
        setError(err)
        setHasBlobLoadingError(true)
        onBlobLoadingError(err)
      })
  }, [filePath, setError])

  if (hasBlobLoadingError) {
    return (
      <div className="text-danger">
        <i className="fas fa-exclamation-circle" />
        &nbsp; File not found, please delete and upload again
      </div>
    )
  } else if (blobUrl) {
    const imageCallback = (
      <img className={styles.imageReview} src={blobUrl} alt="Image Preview" />
    )

    return (
      <PDFReader
        key={`pdf-reader-${filePath}`}
        pdfPath={blobUrl}
        scale={documentScale}
        documentProps={{
          loading: '',
          noData: '',
          error: imageCallback,
          onLoadError: onPdfLoadingError,
        }}
      />
    )
  }

  return <SimpleSpinner />
}
