import { Charge } from '@augusthealth/models/com/august/protos/charge'
import { useContext } from 'react'
import DatePicker from 'react-datepicker'
import { Controller, useForm } from 'react-hook-form'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import { LabelAboveInput, requiredWhenError } from '@shared/components/Labels'
import StyledSelect from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import MoneyInReactHookForm from '@app/components/MoneyInput/MoneyInReactHookForm'
import styles from './styles.module.css'
import {
  nextSchedulableDate,
  RateChangeFormData,
  rateChangeReasons,
  scheduleRateChange,
} from './helpers'

interface Props {
  person: Person
  done: () => void
  charge: Charge
}

export default function ScheduleRateChange({ person, charge, done }: Props) {
  const { setError } = useContext(GlobalContext)
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<RateChangeFormData>()

  const onSubmit = async (data: RateChangeFormData) => {
    try {
      await scheduleRateChange(charge, data, person)
      done()
    } catch (e) {
      setError(e)
    }
  }

  const minDate = nextSchedulableDate(charge)

  return (
    <AnimatedPopup title="Schedule Rate Change">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          defaultValue={undefined}
          name="reason"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => {
            return (
              <div>
                <LabelAboveInput
                  htmlFor="reason"
                  subLabel={requiredWhenError(!!errors.reason)}
                >
                  Reason
                </LabelAboveInput>
                <StyledSelect
                  name="reason"
                  id="reason"
                  instanceId="reason"
                  options={rateChangeReasons()}
                  value={value}
                  onChange={onChange}
                />
              </div>
            )
          }}
        />
        <MoneyInReactHookForm
          uppercaseTitle={true}
          name="newAmount"
          id="newAmount"
          control={control}
          subLabel={requiredWhenError(!!errors.newAmount)}
          inputProps={{
            className: 'w-[184px]',
          }}
          holderClassName="mt-[32px]"
          required
          title="Amount"
        />
        <div className="mt-[32px]">
          <LabelAboveInput
            htmlFor="effectiveDate"
            subLabel={requiredWhenError(!!errors.effectiveDate)}
          >
            Effective Date
          </LabelAboveInput>
          <Controller
            control={control}
            defaultValue={undefined}
            name="effectiveDate"
            render={({ field: { onChange, value } }) => (
              <DatePicker
                minDate={minDate}
                id="effectiveDate"
                name="effectiveDate"
                selected={value}
                onChange={onChange}
                customInput={<CalendarInput />}
                portalId={'root'}
              />
            )}
            rules={{ required: true }}
          />
        </div>
        <div className="mt-[32px]">
          <button
            className={`rounded-[6px] border border-button-tertiary-color bg-button-default-bg-color px-[12px] py-[11px] text-[11px] font-bold leading-[14px] text-button-tertiary-color hover:bg-button-tertiary-color hover:text-button-fill-font-color ${styles.btn}`}
            onClick={(e) => {
              e.preventDefault()
              done()
            }}
            type="button"
          >
            CANCEL
          </button>
          <button
            id="scheduleChange"
            className={`rounded-[6px] bg-button-primary-color px-[12px] py-[11px] text-[11px] font-bold leading-[14px] text-button-fill-font-color hover:brightness-125  ${styles.btn}`}
          >
            SAVE
          </button>
        </div>
      </form>
    </AnimatedPopup>
  )
}
