import { useContext } from 'react'
import PersonContext from '@app/contexts/PersonContext'
import useIncident from '@app/hooks/useIncident'
import styles from '../styles.module.css'
import IncidentForm from './IncidentForm'

type Props = {
  match: {
    params: {
      facilityId: string
      orgId: string
      id: string
      incidentId: string
    }
  }
}

export default function EditIncident({ match }: Props) {
  const { orgId, facilityId, id, incidentId } = match.params
  const incident = useIncident({ orgId, facilityId, id }, incidentId)
  const { person } = useContext(PersonContext)

  if (person === undefined) {
    return null
  }

  return (
    <div className={styles.incidentFormContainer}>
      <div className={styles.page}>
        <h2 className="page-title horizontal mb-[40px]">Edit Incident</h2>
        {incident.tag === 'Loading' && (
          <IncidentForm person={person} isLoading />
        )}
        {incident.tag === 'Complete' && (
          <IncidentForm person={person} incident={incident.value} />
        )}
      </div>
    </div>
  )
}
