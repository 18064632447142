import { Person } from '@shared/types/person'
import { isIncomplete } from '@shared/utils/task'
import { useTasks } from '@app/hooks/useTasks'
import { RemoveTasksModal } from './RemoveTasksModal'

export default function ModalLauncher({
  closeModal,
  person,
}: {
  closeModal: () => void
  person: Person
}) {
  const { tasks, refreshTasks } = useTasks()
  const incompleteTasks = tasks.filter(isIncomplete)

  return (
    <RemoveTasksModal
      incompleteTasks={incompleteTasks}
      done={async (didCloseTasks) => {
        if (didCloseTasks) {
          await refreshTasks()
        }

        closeModal()
      }}
      person={person}
    />
  )
}
