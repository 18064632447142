import { apiUrl, facilityUrl, personUrl } from '@shared/api/urls'
import { FacilityIds } from '@shared/types/facility'
import { Person } from '@shared/types/person'
import { PickPartial } from '@shared/types/utilities'

export const getMedicationOrdersUrl = (
  person: Required<PickPartial<Person, 'id' | 'orgId' | 'facilityId'>>
) =>
  apiUrl(
    personUrl(person.orgId, person.facilityId, person.id),
    '/medicationOrders',
    {}
  )
export const getSingleMedicationOrderUrl = (
  person: Required<PickPartial<Person, 'id' | 'orgId' | 'facilityId'>>,
  medicationId: string
) =>
  apiUrl(getMedicationOrdersUrl(person), `/:medicationId`, {
    medicationId,
  })

export const getMedicationOrdersInboxUrl = (ids: FacilityIds) =>
  apiUrl(facilityUrl(ids.orgId, ids.id), `/medicationOrders/inbox`, {})
export const getBulkRejectMedicationOrdersUrl = ({
  orgId,
  facilityId,
  pharmacyPartner,
  externalFacilityId,
  externalResidentId,
}: {
  orgId: string
  facilityId: string
  pharmacyPartner: string
  externalFacilityId: string
  externalResidentId: string
}) => {
  return apiUrl(
    facilityUrl(orgId, facilityId),
    `/partners/:pharmacyPartner/pharmacyFacilities/:externalFacilityId/pharmacyResident/:externalResidentId/medicationOrders/rejectAll`,
    { pharmacyPartner, externalFacilityId, externalResidentId }
  )
}
export const getAssignExternalIdentifierToResidentUrl = ({
  orgId,
  facilityId,
  personId,
}: {
  orgId: string
  facilityId: string
  personId: string
}) => {
  return apiUrl(
    personUrl(orgId, facilityId, personId),
    '/pharmacyIntegration/assign',
    {}
  )
}

export const getMedicationHistoryUrl = ({
  orgId,
  facilityId,
  personId,
  orderGroupId,
}: {
  orgId: string
  facilityId: string
  personId: string
  orderGroupId: string
}) => {
  return `${getMedicationOrdersUrl({
    id: personId,
    orgId,
    facilityId,
  })}/orderGroups/${orderGroupId}/history`
}

export function reactivateMedicationOrderUrl({
  orgId,
  facilityId,
  personId,
  orderGroupId,
}: {
  orgId: string
  facilityId: string
  personId: string
  orderGroupId: string
}) {
  return `${getMedicationOrdersUrl({
    id: personId,
    orgId,
    facilityId,
  })}/orderGroups/${orderGroupId}/reactivate`
}
