import {
  EmailNotification,
  EmailNotificationEnumOption,
} from '@augusthealth/models/com/august/protos/notification_subscriptions'
import { Switch } from '@app/components/Switch'

export default function NotificationRow({
  notificationOption,
  currentSubscriptions,
  onChange,
}: {
  notificationOption: EmailNotificationEnumOption
  currentSubscriptions: EmailNotification[]
  onChange: (change: {
    action: 'Subscribe' | 'Unsubscribe'
    value: EmailNotification
  }) => unknown
}) {
  return (
    <li
      className={
        'flex items-center justify-between border-b border-transparent-gray px-[8px] py-[16px]'
      }
    >
      <div className={'flex flex-col'}>
        <span className={'text-gray-04'}>
          {notificationOption.description!.shortName}
        </span>
        <span>{notificationOption.description!.displayName}</span>
      </div>
      <div>
        <Switch
          name={notificationOption.notification!}
          checked={currentSubscriptions.includes(
            notificationOption.notification!
          )}
          onChange={(e) => {
            onChange({
              action: e.target.checked ? 'Subscribe' : 'Unsubscribe',
              value: notificationOption.notification!,
            })
          }}
        />
      </div>
    </li>
  )
}
