import { Communication } from '@augusthealth/models/com/august/protos/communication'
import { useContext } from 'react'
import { Person } from '@shared/types/person'
import { getLanguageOptions } from '@shared/utils/communication'
import ElementHolder, {
  Props as ElementHolderProps,
} from '@app/components/formElements/ElementHolder'
import PersonContext from '@app/contexts/PersonContext'
import LanguageDropdown from '../Dropdown'

type Props = ElementHolderProps & {
  countryCode?: string
  name: string
  onUpdate: (value: string | Communication[], name: string) => void
  value?: Communication[]
  itemNamePostfix?: string
}

function getOtherLanguagesWithEmptyDropdown(list?: Communication[]) {
  const otherLanguages = list?.filter((item) => !item.preferred)
  return otherLanguages ? [...otherLanguages, {}] : [{}]
}

export default function LanguageDropdownList(props: Props) {
  const {
    countryCode = 'USA',
    name = 'communication',
    onUpdate,
    itemNamePostfix,
    ...restProps
  } = props

  const { person = {} as Person } = useContext(PersonContext)
  const { communication = [] } = person
  const options = getLanguageOptions({ communication, countryCode })
  const list = getOtherLanguagesWithEmptyDropdown(communication)

  return (
    <ElementHolder {...restProps}>
      <div className="form-element-list">
        <ul className="list-group no-style">
          {list.map((v, i) => {
            const { languageStr } = v
            const key = `lang-row-${languageStr}-${i}`
            let dropdownName
            if (name.includes('[*]')) {
              dropdownName = name.replace(/\[\*\]/, `[${i}]`)
            } else {
              dropdownName = `${name}[${i}]`
            }
            if (itemNamePostfix) {
              dropdownName = `${dropdownName}.${itemNamePostfix}`
            }

            return (
              <li className="list-group-item" key={key}>
                <LanguageDropdown
                  name={dropdownName}
                  person={person}
                  options={options}
                  onChange={(opt: { value: string } | null) => {
                    if (opt?.value) {
                      onUpdate(opt.value, dropdownName)
                    } else {
                      const newList = communication.filter(
                        (item) => item.languageStr !== v.languageStr
                      )
                      onUpdate(newList, 'communication')
                    }
                  }}
                  value={v.languageStr}
                  placeholder="Other languages of moderate fluency"
                  isClearable
                />
              </li>
            )
          })}
        </ul>
      </div>
    </ElementHolder>
  )
}
