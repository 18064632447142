import { useContext, useEffect, useState } from 'react'
import { listFacilities } from '@shared/api/facility'
import CurrentFacilityContext from '@shared/contexts/CurrentFacilityContext'
import GlobalContext from '@shared/contexts/GlobalContext'
import useCurrentPage, { extractIds } from '@shared/hooks/useCurrentPage'
import { Facility } from '@shared/types/facility'
import { Loading } from '@shared/utils/loading'
import FacilitiesContext from '../contexts/FacilitiesContext'

export function useFacilities(
  { orgId }: { orgId?: string },
  deps: React.DependencyList = []
): { facilities: Loading<Facility[]>; refreshFacilities: () => Promise<any> } {
  const { setError } = useContext(GlobalContext)
  const [facilities, setFacilities] = useState<Loading<Facility[]>>({
    tag: 'Loading',
  })

  async function refreshFacilities() {
    if (orgId === undefined) {
      return setFacilities({ tag: 'Complete', value: [] })
    }
    try {
      setFacilities({ tag: 'Loading' })
      await listFacilities(orgId, 1000, true).then((value) =>
        setFacilities({ tag: 'Complete', value })
      )
    } catch (e) {
      setError(e)
      setFacilities({ tag: 'Complete', value: [] })
    }
  }

  useEffect(() => {
    void refreshFacilities()
  }, [...deps, orgId])

  return { facilities, refreshFacilities }
}

export const useCurrentFacility = () => {
  const { facilities } = useContext(FacilitiesContext)
  const {
    allPeople,
    refreshAllPeople,
    currentFacility,
    setCurrentFacility,
    residents,
    movedOutResidents,
    prospects,
    closedProspects,
    refreshProspects,
    refreshResidents,
    settings,
    convertToFacilityTimezone,
  } = useContext(CurrentFacilityContext)
  const currentPage = useCurrentPage()
  const { orgId, facilityId } = extractIds(currentPage)

  useEffect(() => {
    if (facilities && facilities.length > 0 && facilityId && orgId) {
      if (
        (currentFacility?.tag === 'Complete' &&
          currentFacility.value &&
          currentFacility.value.id === facilityId &&
          currentFacility.value.orgId === orgId) ||
        currentFacility?.tag === 'Loading'
      ) {
        return
      }

      const matchingFacility = facilities.find(
        (fa) => fa.id === facilityId && fa.orgId === orgId
      )

      if (matchingFacility) {
        setCurrentFacility(matchingFacility)
      } else {
        setCurrentFacility(undefined)
      }
    } else {
      setCurrentFacility(undefined)
    }
  }, [facilities?.length, facilityId, orgId])

  return {
    allPeople,
    refreshAllPeople,
    currentFacility,
    residents,
    movedOutResidents,
    prospects,
    closedProspects,
    refreshProspects,
    refreshResidents,
    settings,
    convertToFacilityTimezone,
  }
}
