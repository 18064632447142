import { useEffect, useState } from 'react'
import { RoutineOrder } from '@shared/types/routine_order'
import { AugustError } from '@shared/utils/error'
import { AsyncResult } from '@shared/utils/loading'
import { getRoutineOrder } from '@app/api/routineOrders'

export default function useRoutineOrder({
  orgId,
  facilityId,
  personId,
  routineId,
  setError,
}: {
  orgId: string
  facilityId: string
  personId: string
  routineId: string
  setError: (error: unknown) => void
}) {
  const [routineOrder, setRoutineOrder] = useState<
    AsyncResult<RoutineOrder, AugustError>
  >({
    tag: 'Loading',
  })

  useEffect(() => {
    setRoutineOrder({ tag: 'Loading' })
    void getRoutineOrder({ orgId, facilityId, id: personId }, routineId).then(
      (response) => {
        if (response.tag === 'Error') {
          setError(response.value)
        }
        setRoutineOrder(response)
      }
    )
  }, [personId, facilityId, orgId, routineId])

  return { routineOrder }
}
