import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import Card from '@shared/components/Card'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { Person } from '@shared/types/person'
import { twx } from '@shared/utils/tailwind'
import styles from './styles.module.css'

interface PlainProps {
  icon: string
  title: string
  children: React.ReactNode
  id?: string
  titleUnderline?: boolean
}

export function PlainDetailsCard(props: PlainProps) {
  return <DetailsCard {...props} />
}

export interface EditPermissions {
  person: Person
  permissions: GroupPermission[]
}

interface EditableProps {
  buttonIcon?: string
  buttonTitle?: string
  icon: string
  title: string
  children: React.ReactNode
  onEditClick: () => void
  editButtonId: string
  id?: string
  editPermissions: EditPermissions
  className?: string
  titleUnderline?: boolean
}

export function EditableDetailsCard(props: EditableProps) {
  return (
    <DetailsCard
      buttonIcon={props.buttonIcon}
      buttonTitle={props.buttonTitle}
      icon={props.icon}
      title={props.title}
      onEditClick={props.onEditClick}
      editButtonId={props.editButtonId}
      editPermissions={{
        person: props.editPermissions.person,
        permissions: props.editPermissions.permissions.concat([
          GroupPermission.GROUP_PERMISSION_UI_PERSON_DETAILS_EDIT,
        ]),
      }}
      id={props.id}
      className={props.className}
      titleUnderline={props.titleUnderline}
    >
      {props.children}
    </DetailsCard>
  )
}

interface Props {
  buttonIcon?: string
  buttonTitle?: string
  icon: string
  title: string
  children: React.ReactNode
  onEditClick?: () => void
  id?: string
  editButtonId?: string
  editPermissions?: EditPermissions
  className?: string
  titleUnderline?: boolean
}

function DetailsCard({
  buttonIcon,
  buttonTitle,
  icon,
  title,
  children,
  onEditClick,
  editButtonId,
  id,
  editPermissions,
  className,
  titleUnderline = true,
}: Props) {
  const titleStyle = twx('mb-[24px]', {
    'flex justify-between': onEditClick,
    'border-b border-secondary-10 pb-[24px]': titleUnderline,
  })
  const cardClassNames = twx(`mb-[24px] flex-col`, className)

  return (
    <Card id={id} className={cardClassNames}>
      <div className={titleStyle}>
        <div className={styles.titleTextWrapper}>
          <i className={icon} />
          <span className={styles.titleText}>{title}</span>
        </div>
        {editPermissions && (
          <PersonPermissionGate
            person={editPermissions.person}
            permissions={editPermissions.permissions}
          >
            <div>
              <AsyncIconButton
                buttonStyle="tertiary-outline"
                onClick={onEditClick}
                initialIcon={buttonIcon || 'fa-pen'}
                buttonSize="xsmall"
                id={editButtonId}
              >
                {buttonTitle ? buttonTitle : 'Edit'}
              </AsyncIconButton>
            </div>
          </PersonPermissionGate>
        )}
      </div>
      {children}
    </Card>
  )
}
