import { AmbulatorySettings } from '@augusthealth/models/com/august/protos/settings/ambulatory_settings'
import { Controller, UseFormReturn } from 'react-hook-form'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import {
  BasicInput,
  BasicTextarea,
} from '@shared/components/BasicInput/BasicInput'
import Card from '@shared/components/Card'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { AMBULATORY_STATUS_OPTIONS } from '@shared/utils/ambulatoryStatus'

const PATH = 'ambulatoryStatusSettings'

export default function AmbulatoryStatusSettings({
  index,
  useFormReturn,
}: {
  index: number
  useFormReturn: UseFormReturn<AmbulatorySettings>
}) {
  const { control, formState, register, setValue, watch } = useFormReturn
  const { errors } = formState
  const ambulatoryStatusSettings = watch(PATH)
  const existingStatus =
    ambulatoryStatusSettings?.map((a) => a.ambulatoryStatus) || []
  const filteredOptions = AMBULATORY_STATUS_OPTIONS.filter(
    (opt) => opt?.value && !existingStatus.includes(opt.value)
  )
  const hasStatusError = Boolean(
    errors?.ambulatoryStatusSettings?.[index]?.ambulatoryStatus
  )
  const hasTitleError = Boolean(
    errors?.ambulatoryStatusSettings?.[index]?.title
  )
  const hasDescriptionError = Boolean(
    errors?.ambulatoryStatusSettings?.[index]?.description
  )
  const keyPath = `${PATH}-item-${index}`

  return (
    <Card className="mb-[16px]" key={keyPath}>
      <div className="flex-1" data-testid={keyPath}>
        <Controller
          control={control}
          rules={{ required: true }}
          name={`${PATH}.${index}.ambulatoryStatus`}
          render={({ field: { onChange, value } }) => {
            return (
              <div>
                <LabelAboveInput subLabel={requiredLabel(hasStatusError)}>
                  Ambulatory Status
                </LabelAboveInput>
                <StyledSelect
                  name={`${PATH}.${index}.ambulatoryStatus`}
                  onChange={(opt?: OptionTypeBase) =>
                    onChange(opt?.value || null)
                  }
                  options={filteredOptions}
                  value={AMBULATORY_STATUS_OPTIONS.find(
                    (opt: OptionTypeBase) => opt?.value === value
                  )}
                  placeholder="Select an Ambulatory Status..."
                />
              </div>
            )
          }}
        />
        <div className={'mt-[16px]'}>
          <LabelAboveInput subLabel={requiredLabel(hasTitleError)}>
            Title
          </LabelAboveInput>
          <BasicInput
            {...register(`${PATH}.${index}.title`, {
              required: true,
            })}
            placeholder="Title"
          />
        </div>
        <div className={'mt-[16px]'}>
          <LabelAboveInput subLabel={requiredLabel(hasDescriptionError)}>
            Description
          </LabelAboveInput>
          <BasicTextarea
            {...register(`${PATH}.${index}.description`, {
              required: true,
            })}
            placeholder="Description"
          />
        </div>
        <AsyncIconButton
          type={'button'}
          buttonStyle={'danger-fill'}
          width={'100%'}
          className={'mt-[16px]'}
          data-testid={`trash-icon-ambulatoryStatus-${index}`}
          onClick={() => {
            const newList = ambulatoryStatusSettings?.filter(
              (_a, i) => i !== index
            )
            setValue(PATH, newList || [])
          }}
        >
          Remove
        </AsyncIconButton>
      </div>
    </Card>
  )
}
