import { faker } from '@faker-js/faker'
import { sub } from 'date-fns'
import { Factory } from 'fishery'
import { DateMessage } from '@shared/types/date'

class DateMessageFactory extends Factory<DateMessage> {
  recent() {
    const date = faker.date.recent()

    return this.params({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    })
  }
  past() {
    const lastWeek = sub(new Date(), { days: 7 })
    const date = faker.date.past({ refDate: lastWeek })

    return this.params({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    })
  }

  future() {
    const date = faker.date.future()

    return this.params({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    })
  }

  today() {
    const date = new Date()

    return this.params({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    })
  }

  afterDate(date: Date) {
    const afterDate = faker.date.future({ refDate: date })

    return this.params({
      year: afterDate.getFullYear(),
      month: afterDate.getMonth() + 1,
      day: afterDate.getDate(),
    })
  }
}

export const dateMessageFactory = DateMessageFactory.define(
  (): DateMessage => ({
    year: faker.date.recent().getFullYear(),
    month: faker.date.recent().getMonth() + 1,
    day: faker.date.recent().getDate(),
  })
)
