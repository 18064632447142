import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import { getOrElse } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import HUD from '@app/components/HUD'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import useOrganizations from '@app/hooks/useOrganizations'
import BillingCategoriesTable from './BillingCategoriesTable'
import FeesTable from './FeesTable'
import SettingsTable from './SettingsTable'

export default function Settings() {
  const { orgId, facilityId } = useParams<{
    facilityId: string
    orgId: string
  }>()
  const { organizations } = useOrganizations()
  const organization = getOrElse(organizations, []).find(
    (org) => org.id === orgId
  )
  const { currentFacility } = useCurrentFacility()
  const [hudText, setHudText] = useState('')

  if (!organization || !currentFacility || currentFacility.tag === 'Loading') {
    return <SimpleSpinner />
  }

  return (
    <>
      <div className={tw`mt-8`}>
        <SettingsTable
          orgId={orgId}
          facilityId={facilityId}
          setHudText={setHudText}
        />
      </div>
      <div className={tw`mt-8`}>
        <FeesTable
          facility={currentFacility.value}
          organization={organization}
          onUpdate={setHudText}
        />
      </div>
      <div className={tw`mt-8`}>
        <BillingCategoriesTable
          facility={currentFacility.value}
          organization={organization}
          onUpdate={setHudText}
        />
      </div>
      {hudText && <HUD onExpire={() => setHudText('')}>{hudText}</HUD>}
    </>
  )
}
