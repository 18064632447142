import { DateMessage } from '@augusthealth/models/com/august/protos/date'

export interface DateOfBirthProps {
  allowEmpty?: boolean
  value?: string | DateMessage
  onUpdate: (date?: DateMessage | Date) => void
  setError?: (a?: object) => void
  title?: string
  name?: string
  acceptDateObject?: boolean
  sendUpdateOnError?: boolean
  updateOnEachKeyUp?: boolean
  menuPlacement?: 'top' | 'bottom'
}

export interface SelectOption {
  value: number
  label: string
  abbr: string
}

export interface DateError {
  year?: string
  month?: string
  day?: string
  misc?: string
  isEmpty?: boolean
}

export const MONTH_OPTIONS: SelectOption[] = [
  { value: 1, label: 'January', abbr: 'Jan' },
  { value: 2, label: 'February', abbr: 'Feb' },
  { value: 3, label: 'March', abbr: 'Mar' },
  { value: 4, label: 'April', abbr: 'Apr' },
  { value: 5, label: 'May', abbr: 'May' },
  { value: 6, label: 'June', abbr: 'Jun' },
  { value: 7, label: 'July', abbr: 'Jul' },
  { value: 8, label: 'August', abbr: 'Aug' },
  { value: 9, label: 'September', abbr: 'Sep' },
  { value: 10, label: 'October', abbr: 'Oct' },
  { value: 11, label: 'November', abbr: 'Nov' },
  { value: 12, label: 'December', abbr: 'Dec' },
]
