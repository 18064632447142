import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Incident } from '@shared/types/incidents'
import { Person } from '@shared/types/person'
import { Loading } from '@shared/utils/loading'
import { fetchIncidents } from '../api/incidents'

export default function useIncidents(person: Person | undefined) {
  const { setError } = useContext(GlobalContext)
  const [incidents, setIncidents] = useState<Loading<Incident[]>>({
    tag: 'Loading',
  })
  const { id } = person || {}

  const refreshIncidents = async () => {
    if (person === undefined) {
      return Promise.resolve()
    }

    return fetchIncidents({ person })
      .then((value) => setIncidents({ tag: 'Complete', value }))
      .catch(setError)
  }

  useEffect(() => {
    void refreshIncidents()
  }, [id])

  return { incidents, refreshIncidents }
}
