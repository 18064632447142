import { utcToZonedTime } from 'date-fns-tz'
import React from 'react'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import { AmbulatoryStatus_AmbulatoryResult as AmbulatoryStatus } from '@shared/types/ambulatory_status'
import { Facility } from '@shared/types/facility'
import { PersonStats } from '@shared/types/person_stats'
import { Shift } from '@shared/types/shift'
import { TaskTemplateInfo } from '@shared/types/task'
import { LOADING, Loading } from '@shared/utils/loading'

export type FacilitySettings = {
  ambulatorySettingsOptions: OptionTypeBase<AmbulatoryStatus>[]
  dietSettingsOptions: OptionTypeBase[]
  use24HourClock: boolean
}

type ValidDateParam = Date | number | string
export type TimeZoneConvertFunc = (date: ValidDateParam) => Date

export type CurrentFacilityContextProps = {
  configuredTasks: Loading<TaskTemplateInfo[]> | undefined
  setConfiguredTasks: (tasks: Loading<TaskTemplateInfo[]> | undefined) => void
  currentFacility: Loading<Facility> | undefined
  setCurrentFacility: (facility: Facility | undefined) => void
  shifts: Loading<Shift[]>
  allPeople: Loading<PersonStats[]>
  setAllPeople: (val: Loading<PersonStats[]>) => void
  /**
   * Residents, Prospects, and Closed Prospects are only used in the Resident/Move-In lists,
   * but they are saved at the App level to enable quick switching between tabs
   */
  prospects: Loading<PersonStats[]>
  setProspects: (val: Loading<PersonStats[]>) => void
  closedProspects: PersonStats[]
  residents: Loading<PersonStats[]>
  setResidents: (val: Loading<PersonStats[]>) => void
  movedOutResidents: PersonStats[]
  /**
   * Refresh functions are called in the background of their respective lists
   * to ensure the data stays up to date
   */
  refreshAllPeople: (orgId: string, facilityId: string) => Promise<void>
  refreshProspects: (orgId: string, facilityId: string) => Promise<void>
  refreshResidents: (orgId: string, facilityId: string) => Promise<void>
  /**
   * Settings
   */
  settings: FacilitySettings
  convertToFacilityTimezone: TimeZoneConvertFunc
}

export const defaultProps: CurrentFacilityContextProps = {
  configuredTasks: LOADING,
  setConfiguredTasks: () => {},
  currentFacility: LOADING,
  setCurrentFacility: () => {},
  shifts: LOADING,
  allPeople: LOADING,
  setAllPeople: () => {},
  prospects: LOADING,
  setProspects: () => {},
  closedProspects: [],
  residents: LOADING,
  setResidents: () => {},
  movedOutResidents: [],
  refreshAllPeople: () => Promise.resolve(),
  refreshProspects: () => Promise.resolve(),
  refreshResidents: () => Promise.resolve(),
  settings: {
    ambulatorySettingsOptions: [],
    dietSettingsOptions: [],
    use24HourClock: false,
  },
  convertToFacilityTimezone: (d: ValidDateParam) => new Date(d),
}

export default React.createContext(defaultProps)

export function getConvertToFacilityTimezoneFunc(facilityTimeZone?: string) {
  return facilityTimeZone
    ? (d: ValidDateParam) => utcToZonedTime(d, facilityTimeZone)
    : (d: ValidDateParam) => new Date(d)
}
