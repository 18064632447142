import { get, set } from '@augusthealth/august-frontend-form-elements'
import { FunctionComponent, useEffect, useState } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import ElementHolder, {
  Props as ElementHolderProps,
  ValueProps,
} from '../ElementHolder'
import './style.css'
import DeletableInputInGenerator from '../DeletableInput/DeletableInputIngenerator'

type ComponentProps = {
  tabIndex?: number
  name?: string
  onUpdate: (v: ValueProps, n: string) => void
  value?: ValueProps
  readOnly?: boolean
  placeholder?: string
  hashValue?: object
}

export type Props = Omit<ElementHolderProps, 'value'> & {
  title?: string
  className?: string
  defaultItem?: string | number | object
  Component?: FunctionComponent<ComponentProps>
  itemNamePostfix?: string
  name: string
  onUpdate: (value: ValueProps, name: string) => void
  tabIndex?: number
  value?: ValueProps[]
  showRowNumber?: boolean
  showDeleteIcon?: boolean
  placeholder?: string
}

/** Also all props of **ElementHolder** */
export default function HashList(props: Props) {
  const {
    className,
    value,
    Component = DeletableInputInGenerator,
    name,
    defaultItem = '',
    onUpdate,
    tabIndex = 1,
    itemNamePostfix,
    readOnly = false,
    showRowNumber = false,
    showDeleteIcon = false,
    placeholder = '',
  } = props
  const [listValue, setListValue] = useState<any[]>(value || [])

  useEffect(() => {
    if (value && value.length) {
      setListValue(value)
    } else {
      setListValue([])
    }
  }, [value])

  const update = (val, itemName) => {
    let valTrimmed
    if (typeof val === 'string') {
      valTrimmed = (val && val.trim()) || ''
    } else {
      valTrimmed = val
    }
    const newList = [...listValue]

    set(newList, itemName, valTrimmed)
    setListValue(newList)
    onUpdate(valTrimmed, itemName)
  }

  const options = [...listValue]
  if (!readOnly && defaultItem !== undefined) {
    options.push(defaultItem)
  }
  const items = options.map((d, i) => {
    let itemName
    if (name.includes('[*]')) {
      itemName = name.replace(/\[\*\]/, `[${i}]`)
    } else {
      itemName = `${name}[${i}]`
    }

    let itemValue = d
    if (itemNamePostfix) {
      itemName = `${itemName}.${itemNamePostfix}`
      itemValue = get(d, itemNamePostfix)
    }

    const key = `${itemName}-${itemValue}-${i}`
    const rowNumber = showRowNumber && (
      <div className="list-item-row-number">{i + 1}.</div>
    )
    const deleteIcon = d && showDeleteIcon && (
      <ButtonLink
        className="ml-[24px]"
        title={`Remove ${JSON.stringify(d)}`}
        onClick={() => {
          const newValue = listValue.filter((_v, index) => i !== index)
          setListValue(newValue)
          onUpdate(newValue, name)
        }}
      >
        <i className="fas fa-fw fa-trash-can" />
      </ButtonLink>
    )
    return (
      <li key={key} className="list-group-item">
        {rowNumber}
        <div className="list-item-holder">
          <Component
            tabIndex={tabIndex}
            name={itemName}
            onUpdate={(v, n) => update(v, n)}
            value={itemValue}
            readOnly={readOnly}
            placeholder={placeholder}
            hashValue={d}
          />
        </div>
        {deleteIcon}
      </li>
    )
  })

  const classNameList = ['list-group']
  if (className) {
    classNameList.push(className)
  }

  const elementHolderProps = { ...props }
  elementHolderProps.readOnly = false

  return (
    <ElementHolder {...elementHolderProps} childrenInReadOnlyHolder={readOnly}>
      <div className="form-element-list">
        <ul className={classNameList.join(' ')}>{items}</ul>
      </div>
    </ElementHolder>
  )
}
