import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { DotDivider } from '@shared/components/DotDivider'
import Icon from '@shared/components/Icon'
import { LinkCard } from '@shared/components/LinkCard'
import CurrentFacilityContext from '@shared/contexts/CurrentFacilityContext'
import { documentsUrl, documentUrl } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import {
  getFormattedCompletionDate,
  nameOfUploader,
} from '@shared/utils/snapshot'
import styles from './styles.module.css'
import { RoutableDocument } from '../helpers'

interface DocumentCardProps {
  animationStyle: React.CSSProperties
  document: RoutableDocument
  icon:
    | 'fa-file-image'
    | 'fa-file-pdf'
    | 'fa-file-alt'
    | 'fa-file-contract'
    | 'fa-syringe'
  title: React.ReactNode
  completionText: 'Uploaded' | 'Completed'
  viewer: (closeFn: () => void) => React.ReactNode
  person: Person
  cypressData?: string
}

export default function DocumentCard({
  animationStyle,
  document,
  icon,
  title,
  completionText,
  viewer,
  person,
  cypressData,
}: DocumentCardProps) {
  const { convertToFacilityTimezone } = useContext(CurrentFacilityContext)
  const history = useHistory()

  const completer = nameOfUploader(document) || 'Unknown'
  const completionTime = getFormattedCompletionDate(
    document,
    convertToFacilityTimezone
  )

  return (
    <>
      <LinkCard
        style={animationStyle}
        data-cy={cypressData}
        className={`${styles.card} ${styles.slideIn}`}
        href={documentUrl({
          person: person as Required<Person>,
          documentId: document.id!,
        })}
      >
        <div className={styles.iconContainer}>
          <Icon variant={'light'} name={icon} />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.title}>{title}</div>
          <div className={styles.updatedBy}>
            {completionText} by {completer}
            <DotDivider />
            {completionTime}
          </div>
        </div>
      </LinkCard>
      {document.routeDirectlyToDocument &&
        viewer(() =>
          history.replace(documentsUrl({ person: person as Required<Person> }))
        )}
    </>
  )
}
