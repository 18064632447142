import { Contact } from '@augusthealth/models/com/august/protos/contact'
import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useState } from 'react'
import { Person } from '@shared/types/person'
import { formatSingleLineAddress } from '@shared/utils/address'
import { organizationName, primaryPhone } from '@shared/utils/contact'
import { getFullName } from '@shared/utils/humanName'
import { EditContactModal } from '@app/pages/Contacts/ContactModal/ContactModal'
import styles from './styles.module.css'
import { EditableDetailsCard } from '../DetailsCard'

export default function PrimaryCarePhysician({
  physician,
  person,
  updatePerson,
}: {
  physician: Contact
  person: Person
  updatePerson: () => void
}) {
  const [physicianToEdit, setPhysicianToEdit] = useState<Contact>()

  const hospital = organizationName(physician)
  const address = formatSingleLineAddress(physician.address)
  const phoneNumber = primaryPhone(physician)?.value

  return (
    <EditableDetailsCard
      title="Primary Care Physician"
      icon="fas fa-user-md fa-fw"
      editPermissions={{
        person,
        permissions: [GroupPermission.GROUP_PERMISSION_PERSON_UPDATE],
      }}
      editButtonId={'editPrimaryPhysician'}
      onEditClick={() => {
        setPhysicianToEdit(physician)
      }}
    >
      <span className={styles.name}>{getFullName(physician.name!)}</span>
      <div className={styles.info}>
        {hospital && (
          <div className={styles.infoRow}>
            <i className="fas fa-building fa-fw" /> {hospital}
          </div>
        )}
        {address && (
          <div className={styles.infoRow}>
            <i className="fas fa-map-marker-alt fa-fw" /> {address}
          </div>
        )}
        {phoneNumber && (
          <div className={styles.infoRow}>
            <i className="fas fa-phone fa-fw" /> {phoneNumber}
          </div>
        )}
      </div>
      {!!physicianToEdit && (
        <EditContactModal
          contact={physicianToEdit}
          person={person}
          onClose={async (updated) => {
            if (updated) {
              updatePerson()
            }
            setPhysicianToEdit(undefined)
          }}
        />
      )}
    </EditableDetailsCard>
  )
}
