import { HTMLProps } from 'react'
import { tw, twx } from '@shared/utils/tailwind'

export default function SectionTitle(props: HTMLProps<HTMLElement>) {
  const { children, className, title, ...restProps } = props

  return (
    <section
      className={twx(
        'flex items-center text-[14px] font-bold uppercase leading-[24px] text-gray-06',
        className
      )}
      {...restProps}
    >
      <div className={tw`grow`}>{title}</div>
      <div>{children}</div>
    </section>
  )
}
