import { Report } from '@augusthealth/models/com/august/protos/report'
import { useEffect, useState } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import { fetchReports } from '@app/api/reports'
import Content from '@app/components/generic/Content'
import RouterTabs from '@app/components/RouterTabs'
import { TOOLS_REPORT_GENERATOR } from '@app/pages/Tools/path'
import { PageTab } from './models'
import ReportAssignments from './ReportAssignments'
import CreateReport from './ReportForm'
import ReportList from './ReportList'

type MatchParams = {
  tab?: PageTab
}

type Props = RouteComponentProps<MatchParams>

export default function PdfTemplate(props: Props) {
  const { tab } = props.match.params
  const [reports, setReports] = useState<Report[]>([])
  const reloadReports = () => {
    void fetchReports().then(({ data }) => {
      setReports(data)
    })
  }

  const activeTab = () => {
    switch (tab) {
      case PageTab.REPORT_LIST:
        return 'Report List'
      case PageTab.REPORT_ASSIGNMENTS:
        return 'Assign Reports'
      case PageTab.CREATE_REPORT:
        return 'Create Report'
      default:
        return 'Create Report'
    }
  }

  useEffect(reloadReports, [])

  return (
    <Content>
      <RouterTabs
        activeTab={activeTab()}
        tabList={[
          {
            label: 'Report List',
            url: `${TOOLS_REPORT_GENERATOR}/${PageTab.REPORT_LIST}`,
          },
          {
            label: 'Assign Reports',
            url: `${TOOLS_REPORT_GENERATOR}/${PageTab.REPORT_ASSIGNMENTS}`,
          },
          {
            label: 'Create Report',
            url: `${TOOLS_REPORT_GENERATOR}/${PageTab.CREATE_REPORT}`,
          },
        ]}
      />
      <Switch>
        <Route
          exact
          path={'/tools/reportGenerator/list'}
          component={() => (
            <ReportList reports={reports} reload={reloadReports} />
          )}
        />
        <Route
          exact
          path={[
            '/tools/reportGenerator/assign',
            '/tools/reportGenerator/assign/:reportId',
          ]}
          component={() => <ReportAssignments reports={reports} />}
        />
        <Route
          exact
          path={'/tools/reportGenerator/create'}
          component={() => <CreateReport afterCreate={reloadReports} />}
        />
      </Switch>
    </Content>
  )
}
