import { AllergyIntolerance } from '@augusthealth/models/com/august/protos/allergy_intolerance'
import { typedMergePatchPerson } from '@shared/api/person'
import { Person } from '@shared/types/person'
import { validStringOrNull } from '@shared/utils/parsing'

type Fields = {
  specialDiets: string[]
  allergies: AllergyIntolerance[]
  notes: string
}

export const updateDietAndAllergies = async (
  person: Person,
  fields: Fields
) => {
  const trimmedAllergies = fields.allergies.map((a) => ({
    ...a,
    summary: a.summary?.trim(),
  }))
  const notes = fields.notes.trim()
  const dietSummary = validStringOrNull(notes)

  await typedMergePatchPerson({
    fId: person.facilityId!,
    pId: person.id!,
    orgId: person.orgId!,
    fields: ['socialHistory/diet', 'allergiesAndIntolerances/allergies'],
    patch: {
      socialHistory: {
        diet: {
          dietTypeValue: fields.specialDiets,
          summary: dietSummary,
        },
      },
      allergiesAndIntolerances: { allergies: trimmedAllergies },
    },
  })
}
