import {
  Incident,
  IncidentAction,
  IncidentActionStatus,
  IncidentActionType,
} from '@shared/types/incidents'
import { Person } from '@shared/types/person'
import { customIncidentActionIdentifier } from '@shared/utils/incident'
import notEmpty from '@shared/utils/notEmpty'
import {
  createIncidentAction,
  deleteIncidentAction,
  updateIncidentAction,
} from '@app/api/incidents'
import { IncidentFormData } from '../IncidentForm/incidentFormHelpers'

/**
 * Returns current incident action types, but are not included in form
 * submission data
 * @function
 * @param {IncidentFormData} formData
 * @param {Incident} incident
 * @returns {IncidentAction[]}
 */
export function getActionsToBeDeleted(
  formData: IncidentFormData,
  incident: Incident
): IncidentAction[] {
  const incidentActions = incident.detail?.incidentDetail?.incidentActions

  if (!incidentActions?.length) {
    return []
  }

  return incidentActions?.filter((incidentAction) => {
    const customActionIdentifier =
      customIncidentActionIdentifier(incidentAction)

    if (customActionIdentifier) {
      return !Object.values(formData.customFollowUpActions).includes(
        customActionIdentifier
      )
    }

    return !formData.followUpActions.includes(incidentAction.type!)
  })
}

/**
 * Returns actions that do not currently exist in incident
 * @function
 * @param {IncidentFormData} formData
 * @param {Incident} incident
 * @returns {IncidentAction[]} actionsToBeCreated
 */
export function getActionsToBeCreated(
  formData: IncidentFormData,
  incident: Incident,
  specialActions: IncidentAction[]
): IncidentAction[] {
  const incidentActions = incident.detail?.incidentDetail?.incidentActions ?? []

  const actionTypesToBeCreated = formData.followUpActions.filter(
    (actionType) => {
      return !incidentActions.map((ia) => ia.type).includes(actionType)
    }
  )

  const specialActionsToBeCreated = Object.values(
    formData.customFollowUpActions
  )
    .map((customType) => {
      if (customType === false) {
        return null
      }

      if (
        incidentActions
          .map((ia) => customIncidentActionIdentifier(ia))
          .includes(customType)
      ) {
        return null
      }

      return specialActions.find(
        (action) => customIncidentActionIdentifier(action) === customType
      )
    })
    .filter(notEmpty)

  return [
    ...actionTypesToBeCreated.map((actionType) => {
      const basicActionProps = {
        incidentId: incident.id!,
        type: actionType,
        status: IncidentActionStatus.INCIDENT_ACTION_STATUS_OPEN,
      }

      if (actionType === IncidentActionType.INCIDENT_ACTION_TYPE_OTHER) {
        return {
          ...basicActionProps,
          otherType: formData.followUpOther ?? undefined,
        }
      }

      return basicActionProps
    }),
    ...specialActionsToBeCreated.map((a) => ({
      ...a,
      incidentId: incident.id!,
    })),
  ]
}

/**
 * Returns a list of OTHER type actions that need to be updated
 * @function
 * @param {IncidentFormData} formData
 * @param {Incident} incident
 * @returns {IncidentAction[]} actionsToBeUpdated
 */
export function getActionTypeOtherToBeUpdated({
  formData,
  incident,
}: {
  formData: IncidentFormData
  incident: Incident
}): IncidentAction | null {
  const incidentOtherAction =
    incident.detail?.incidentDetail?.incidentActions?.find(
      (action) => action.type === IncidentActionType.INCIDENT_ACTION_TYPE_OTHER
    )

  if (!incidentOtherAction) {
    return null
  }

  const formDataOtherActionType = formData.followUpActions.find(
    (action) => action === IncidentActionType.INCIDENT_ACTION_TYPE_OTHER
  )

  if (!formDataOtherActionType) {
    return null
  }

  if (incidentOtherAction.otherType === formData.followUpOther) {
    return null
  }

  return {
    ...incidentOtherAction,
    otherType: formData.followUpOther ?? undefined,
  }
}

/*
 * Sends requests to delete or create follow up actions
 * @function
 * @param {IncidentAction[]} actionsToBeDeleted
 * @param {IncidentAction[]} actionsToBeCreated
 * @param {IncidentAction[]} actionsToBeUpdated
 * @param {Incident} incident
 * @param {Person} person
 * @returns {Promise<void>}
 */
export async function updateIncidentActions({
  incident,
  person,
  actionsToBeDeleted,
  actionsToBeCreated,
  actionsToBeUpdated,
}: {
  incident: Incident
  person: Person
  actionsToBeDeleted: IncidentAction[]
  actionsToBeCreated: IncidentAction[]
  actionsToBeUpdated: IncidentAction[]
}): Promise<void> {
  const promises: (() => Promise<any>)[] = []

  actionsToBeDeleted.forEach((action) => {
    promises.push(() =>
      deleteIncidentAction({
        actionId: action.id!,
        incidentId: incident.id!,
        person,
      })
    )
  })

  actionsToBeCreated.forEach((action) => {
    promises.push(() =>
      createIncidentAction({
        incidentId: incident.id!,
        action,
        person,
      })
    )
  })

  actionsToBeUpdated?.forEach((action: IncidentAction) => {
    promises.push(() =>
      updateIncidentAction({
        incident,
        incidentAction: {
          id: action.id,
          type: action.type,
          status: action.status,
          incidentId: action.incidentId,
          ...(action.otherType && { otherType: action.otherType }),
        },
        person,
      })
    )
  })

  async function fulfillSequentially(promises: (() => Promise<any>)[]) {
    for (const promise of promises) {
      await promise()
    }
  }

  await fulfillSequentially(promises)
}
