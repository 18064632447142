import { useState } from 'react'
import { FacilityPageProps } from '@shared/types/facility'
import { getOrElse } from '@shared/utils/loading'
import HUD from '@app/components/HUD'
import useBillingResidentList from '@app/hooks/useBillingResidentList'
import ChargeModal from '@app/pages/Facilities/Billing/Modals/TransactionModals/ChargeModal'
import CreditModal from '@app/pages/Facilities/Billing/Modals/TransactionModals/CreditModal'
import ResidentsTable from './ResidentsTable'

export default function Residents({
  match,
  showChargeModal,
  closeChargeModal,
}: FacilityPageProps & {
  closeChargeModal: () => void
  showChargeModal?: 'ADD_CHARGE' | 'RECORD_CREDIT'
}) {
  const { orgId, facilityId } = match.params
  const { residents, reloadResidentList: reload } = useBillingResidentList({
    orgId,
    facilityId,
  })
  const [hudText, setHudText] = useState<string>('')
  const reloadResidentList = async (hudMsg?: string) => {
    if (hudMsg) {
      setHudText(hudMsg)
    }
    return await reload()
  }
  const billingResidents = getOrElse(residents, [])
  const billingResidentIds = billingResidents.map((r) => r.personId)

  return (
    <>
      <ResidentsTable
        facilityIds={{ orgId, id: facilityId }}
        residents={residents}
        reloadResidentList={reloadResidentList}
      />
      {showChargeModal === 'ADD_CHARGE' && (
        <ChargeModal
          title="Add One-Time Charge"
          closeFn={closeChargeModal}
          doneFn={reloadResidentList}
          residents={billingResidents}
          residentIds={billingResidentIds}
          oneTimeChargeOnly
        />
      )}
      {showChargeModal === 'RECORD_CREDIT' && (
        <CreditModal
          title="Add One-Time Credit"
          closeFn={closeChargeModal}
          doneFn={reloadResidentList}
          residents={billingResidents}
          residentIds={billingResidentIds}
          oneTimeChargeOnly
        />
      )}
      {hudText && <HUD onExpire={() => setHudText('')}>{hudText}</HUD>}
    </>
  )
}
