import { Occupancy_Occupant as OccupancyOccupant } from '@augusthealth/models/com/august/protos/api/occupancy'
import { Bed } from '@augusthealth/models/com/august/protos/bed'
import { Room } from '@augusthealth/models/com/august/protos/room'
import { ReactNode } from 'react'
import { Person } from '@shared/types/person'
import { getFullName } from '@shared/utils/humanName'
import {
  displayResidentStatus,
  formatRoomNumber,
  getGenderLetter,
} from '@shared/utils/person'
import styles from './styles.module.css'

export type OptionProps = {
  label: ReactNode
  value?: string
  searchText: string
  isOccupied: boolean
}

type Props = {
  bed: Bed
  occupants?: OccupancyOccupant[]
  room: Room
}

export function getOption({ bed, room, occupants }: Props): OptionProps {
  const { name: roomName = '' } = room
  const { id, name: bedName } = bed
  const isOccupied = Boolean(occupants && occupants.length)
  const roomBedName = formatRoomNumber(roomName, bedName, {
    bedNameInParentheses: true,
  })
  let subLabel: ReactNode = 'Vacant'
  if (isOccupied) {
    subLabel = occupants!.map((o, i) => {
      const text = [
        o.name && getFullName(o.name),
        getGenderLetter(o as Person),
        displayResidentStatus(o as Person),
      ]
        .filter((l) => l)
        .join(' - ')
      return <div key={`${text}-${i}`}>{text}</div>
    })
  }

  return {
    label: (
      <div className={styles.row}>
        <div>{roomBedName}</div>
        <div className={styles.subTitle}>{subLabel}</div>
      </div>
    ),
    value: id,
    searchText: roomBedName,
    isOccupied,
  }
}
