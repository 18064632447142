import React from 'react'
import { SignableForm } from '@shared/types/signable_form'
import { AsyncResult } from '@shared/utils/loading'

export type TaskTemplateContextProps = {
  signableForm: AsyncResult<SignableForm, 'Not Found'>
}

export const defaultProps = {
  signableForm: { tag: 'Loading' } as const,
}

export default React.createContext<TaskTemplateContextProps>(defaultProps)
