import { useContext, useState } from 'react'
import { AsyncIconButton as AsyncButton } from '@shared/components/AsyncButton'
import { NestedModalConfirmation } from '@shared/components/baseMui/Modal/Layout'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { DataType } from '@shared/types/snapshot'
import { recreateTask } from '@app/api/tasks'

export default function ServicePlanOverlay({
  person,
  doneFn,
  setShowServicePlanOverlay,
}: {
  person: Person
  doneFn: () => void
  setShowServicePlanOverlay: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { setError } = useContext(GlobalContext)
  const [isLoading, setIsLoading] = useState(false)
  const onSubmit = async () => {
    setIsLoading(true)
    try {
      await recreateTask({ person, dataType: DataType.DATA_TYPE_SERVICE_PLAN })
    } catch (e) {
      setError(e)
    }
    setIsLoading(false)
    doneFn()
    setShowServicePlanOverlay(false)
  }

  const onCancel = () => {
    doneFn()
    setShowServicePlanOverlay(false)
  }

  return (
    <NestedModalConfirmation>
      <div className="mb-[8px] text-[20px] font-semibold leading-[28px]">
        Does this resident need an updated service plan?
      </div>
      <div className="text-[16px] leading-[24px] text-secondary-font-color">
        This will create a new task to update the service plan.
      </div>
      <div className="mt-[24px]">
        <AsyncButton
          disabled={isLoading}
          className="mr-[16px]"
          data-testid="cancel-service-plan-prompt"
          buttonStyle="secondary-outline"
          onClick={onCancel}
          width="144px"
        >
          Not Now
        </AsyncButton>
        <AsyncButton
          disabled={isLoading}
          isLoading={isLoading}
          data-testid="submit-service-plan-prompt"
          buttonStyle="primary-fill"
          type="submit"
          onClick={onSubmit}
          width="144px"
        >
          Yes, create task
        </AsyncButton>
      </div>
    </NestedModalConfirmation>
  )
}
