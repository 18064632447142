import { cloneDeep } from 'lodash'
import { AdmissionsInformation_AdmissionType as AdmissionType } from '@shared/types/admissions'
import { ValidDate } from '@shared/types/date'
import { Person } from '@shared/types/person'
import { DeepNull } from '@shared/types/utilities'

export function removeStartRange(patch: DeepNull<Person>): DeepNull<Person> {
  const updatedPatch = cloneDeep(patch)
  if (updatedPatch.admissionsInformation) {
    const { startDate, startRange } = updatedPatch.admissionsInformation
    if (startDate && startRange) {
      updatedPatch.admissionsInformation.startRange = null
    }
  }

  return updatedPatch
}

export function cloneMoveInDateToFinancialStartDate(
  patch: DeepNull<Person>
): DeepNull<Person> {
  const updatedPatch = cloneDeep(patch)
  if (updatedPatch.admissionsInformation) {
    const { startDate, financialStartDate } = updatedPatch.admissionsInformation
    if (
      startDate &&
      startDate.year &&
      startDate.month &&
      startDate.day &&
      !financialStartDate
    ) {
      updatedPatch.admissionsInformation.financialStartDate =
        startDate as ValidDate
    }
  }

  return updatedPatch
}

export function getAdmissionTypeLabel(admissionType: AdmissionType) {
  switch (admissionType) {
    case AdmissionType.ADMISSION_TYPE_ASSISTED_LIVING:
      return 'Assisted Living'
    case AdmissionType.ADMISSION_TYPE_MEMORY_CARE:
      return 'Memory Care'
    case AdmissionType.ADMISSION_TYPE_INDEPENDENT_LIVING:
      return 'Independent Living'
    default:
      return ''
  }
}
