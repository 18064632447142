import { twx } from '@shared/utils/tailwind'
import styles from './styles.module.css'

type Props = React.HTMLProps<HTMLDivElement>

export default function Warning({ className, children, ...rest }: Props) {
  return (
    <div className={twx(styles.box, className)} {...rest}>
      <div>
        <i className="fas fa-exclamation-square mr-[8px]" />
      </div>
      <div>{children}</div>
    </div>
  )
}

type RpEmailWarning = Omit<Props, 'children'> & {
  name?: string
}

export function RpEmailWarning({
  name = 'This person',
  ...rest
}: RpEmailWarning) {
  return (
    <Warning {...rest}>
      {name} is the responsible person. Are you sure you want to change their
      email address? Previously shared tasks and any documents awaiting
      signature from the responsible person will be canceled.
    </Warning>
  )
}
