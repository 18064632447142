import { useEffect, useState } from 'react'
import { AsyncResult } from '@shared/utils/loading'
import { fetchFormImageKeys, getFormImage } from '@app/api/form'

export default function useFormImage({
  signableFormId,
  key,
}: {
  signableFormId: string
  key: string
}) {
  const [imageData, setImageData] = useState<AsyncResult<string, any>>({
    tag: 'Loading',
  })

  useEffect(() => {
    getFormImage({
      signableFormId,
      key,
    })
      .then((image) => {
        setImageData({ tag: 'Complete', value: image })
      })
      .catch((error) => {
        setImageData({ tag: 'Error', value: error })
      })
  }, [signableFormId, key])

  return { imageData }
}

export function useFormImageKeys({
  signableFormId,
}: {
  signableFormId: string
}) {
  const [formImageKeys, setFormImageKeys] = useState<
    AsyncResult<string[], any>
  >({
    tag: 'Loading',
  })

  const loadFormImageKeys = () => {
    return fetchFormImageKeys({
      signableFormId,
    })
      .then((image) => {
        setFormImageKeys({ tag: 'Complete', value: image })
      })
      .catch((error) => {
        setFormImageKeys({ tag: 'Error', value: error })
      })
  }

  useEffect(() => {
    void loadFormImageKeys()
  }, [signableFormId])

  return { formImageKeys, loadFormImageKeys }
}
