import { Array, Option, pipe } from 'effect'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { bulkUpdateRoutineAdministrations } from '@shared/api/careapp'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import Card from '@shared/components/Card'
import Icon from '@shared/components/Icon'
import {
  RescheduleExceptionRadioButton,
  ResidentRefusedExceptionRadioButton,
  ResidentUnavailableExceptionRadioButton,
} from '@shared/components/RoutineAdministrationButtons/ExceptionRadioButtons'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { UserReference } from '@shared/types/annotation'
import {
  RoutineAdministration,
  RoutineAdministrationEvent,
  RoutineAdministrationExceptionReason,
} from '@shared/types/careapp'
import { Facility } from '@shared/types/facility'
import {
  buildExceptionEvent,
  getMostRecentEventId,
  getProgressType,
  getUndoEvent,
} from '@shared/utils/routineAdministration'
import { getProgressTypeLabel } from '@shared/utils/routineAdministrationProgressType'
import { tw, twx } from '@shared/utils/tailwind'
import BulkUpdateBanner from './BulkUpdateBanner'

type ExceptionFormData = {
  exceptionReason: RoutineAdministrationExceptionReason | null
  note: string | null
}

export default function BulkMarkAsExceptionModal({
  peopleCount,
  routineAdministrations,
  facility,
  onSubmitSuccess,
  onClose,
}: {
  peopleCount: number
  routineAdministrations: RoutineAdministration[]
  facility: Facility
  onSubmitSuccess: () => void
  onClose: () => void
}) {
  const { timeZone: facilityTimeZone, orgId, id: facilityId } = facility

  const { setError } = useContext(GlobalContext)
  const { user } = useUserContext()
  const { formState, register, handleSubmit } = useForm<ExceptionFormData>({
    defaultValues: { exceptionReason: null, note: null },
    resolver: (values) => {
      const { exceptionReason, note } = values
      const errors =
        exceptionReason !== null || (note !== null && note.trim().length > 0)
          ? {}
          : {
              exceptionReason: {
                type: 'required',
                message: 'Please select a reason and/or provide a note',
              },
            }
      return {
        values,
        errors,
      }
    },
  })

  const { errors } = formState
  const exceptionReasonProps = register('exceptionReason')

  const onSave = async ({ exceptionReason, note }: ExceptionFormData) => {
    try {
      const performer: UserReference = {
        userId: user.id,
        userName: user.name,
      }

      const withExceptions: RoutineAdministration[] = pipe(
        routineAdministrations,
        Array.map((administration) => ({
          ...administration,
          reasonForException: exceptionReason ?? undefined,
          events: buildNextEvents(
            performer,
            exceptionReason,
            note,
            administration
          ),
        }))
      )

      await bulkUpdateRoutineAdministrations({
        orgId,
        facilityId,
        routineAdministrations: withExceptions,
      })

      onSubmitSuccess()
      onClose()
    } catch (err) {
      setError(err)
    }
  }

  const progressType = getProgressType(
    routineAdministrations[0],
    facilityTimeZone
  )

  const label = getProgressTypeLabel(progressType)

  const routineAdministrationsCount = routineAdministrations.length

  return (
    <Modal
      id="bulk-mark-exception-modal"
      contentClassName={twx('w-[565px] min-w-[565px]')}
      open
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSave)} className="flex flex-col gap-6">
        <Card className={tw`flex-col`}>
          <BulkUpdateBanner
            label={label}
            routineAdministrationsCount={routineAdministrationsCount}
            peopleCount={peopleCount}
            progressType={progressType}
          />
          <div className={tw`mt-[16px] flex-col px-[8px]`}>
            <div
              className={tw`mb-[16px] text-[14px] font-semibold text-gray-04`}
            >
              <Icon name="circle-x" variant="regular" />
              <span className={tw`ml-[8px]`}>Reason for exception?</span>
            </div>

            <fieldset className="flex flex-col gap-3">
              <legend className="hidden">Choose reason for exception</legend>
              <p
                className={twx(
                  {
                    hidden: !errors.exceptionReason && !errors.root,
                  },
                  'rounded-lg bg-alert-fill-color p-2 text-center text-alert'
                )}
              >
                {errors.exceptionReason?.message}
                {errors.root?.message}
              </p>

              <ResidentUnavailableExceptionRadioButton
                inputProps={exceptionReasonProps}
              />
              <RescheduleExceptionRadioButton
                inputProps={exceptionReasonProps}
              />
              <ResidentRefusedExceptionRadioButton
                inputProps={exceptionReasonProps}
              />
            </fieldset>

            <BasicTextarea
              {...register('note')}
              placeholder="Add optional note..."
              className="mt-[12px] h-[5.5rem] min-h-[5.5rem]"
            />
          </div>
        </Card>

        <AsyncIconButton
          type="submit"
          disabled={formState.isSubmitting}
          isLoading={formState.isSubmitting}
          buttonStyle="primary-fill"
          buttonSize="medium"
        >
          Mark {routineAdministrationsCount} {label} as Exceptions
        </AsyncIconButton>
      </form>
    </Modal>
  )
}

const buildNextEvents = (
  performer: UserReference,
  exceptionReason: RoutineAdministrationExceptionReason | null,
  note: string | null,
  routineAdministration: RoutineAdministration
): RoutineAdministrationEvent[] => {
  const optionUndoEvent = getUndoEvent({
    performer,
    administration: routineAdministration,
  })

  const exceptionEvent = buildExceptionEvent({
    performer,
    exceptionReason,
    note,
    parentId: getMostRecentEventId(routineAdministration.events),
  })

  const nextEvents = Option.match(optionUndoEvent, {
    onNone: () => [exceptionEvent],
    onSome: (undoEvent) => [undoEvent, exceptionEvent],
  })

  return nextEvents
}
