import { useContext, useState } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import PersonContext from '@app/contexts/PersonContext'
import ModalLauncher from './ModalLauncher'

export default function CloseTasks() {
  const [showModal, setShowModal] = useState(false)
  const { person } = useContext(PersonContext)

  if (person === undefined) {
    return null
  }

  return (
    <div className="mb-[24px] mt-[24px]">
      <ButtonLink
        id="close-tasks"
        className="ml-[8px] text-[12px] font-medium leading-[16px] text-secondary"
        onClick={() => setShowModal(true)}
      >
        <i className={'fas fa-file-times mr-[6px]'} />
        Remove tasks
      </ButtonLink>

      {showModal && (
        <ModalLauncher closeModal={() => setShowModal(false)} person={person} />
      )}
    </div>
  )
}
