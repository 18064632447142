/* eslint react-hooks/exhaustive-deps: 2 */

import { Facility } from '@shared/types/facility'
import PageLoaded from './PageLoaded'
import ProgressContext, { useProgressContext } from './ProgressContext'
import SelectedRoutineAdministrationsContext from './SelectedRoutineAdministrationsContext'

export default function Routines({ facility }: { facility: Facility }) {
  return (
    <ProgressContext facility={facility}>
      <Wrapper facility={facility} />
    </ProgressContext>
  )
}

function Wrapper({ facility }: { facility: Facility }) {
  const {
    facilityCareProgress,
    reloadFacilityCareProgress,
    filteredPeopleWithAdministrations,
  } = useProgressContext()

  const filteredAdministrations = filteredPeopleWithAdministrations.flatMap(
    ({ administrations }) => administrations
  )

  return (
    <SelectedRoutineAdministrationsContext
      filteredAdministrations={filteredAdministrations}
    >
      <PageLoaded
        facility={facility}
        facilityCareProgress={facilityCareProgress}
        reloadFacilityCareProgress={reloadFacilityCareProgress}
      />
    </SelectedRoutineAdministrationsContext>
  )
}
