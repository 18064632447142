import { useState } from 'react'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { NestedModalConfirmation } from '@shared/components/baseMui/Modal/Layout'
import pluralize from '@shared/utils/pluralize'
import { twx } from '@shared/utils/tailwind'
import { FacilityGroupsAndError, FieldName } from './helpers'

// TODO: use NestedModalConfirmation here
export function DeleteOverlay({
  fieldName,
  onDelete,
  onCancel,
  error,
}: {
  fieldName: FieldName
  onCancel: () => void
  onDelete: () => Promise<void>
  error: FacilityGroupsAndError
}) {
  const [isDeleting, setIsDeleting] = useState(false)
  const deleteCount = error.error.residents_affected
  const groupType = fieldName === 'medGroups' ? 'med' : 'care'

  const classes = twx('text-[16px] leading-[24px] text-gray-06 m-0 p-0')

  return (
    <NestedModalConfirmation className={twx('opacity-95')}>
      <h3 className={twx('weight-semibold mb-2 text-[20px] leading-[28px]')}>
        Delete {error.group.name}
      </h3>
      <p className={twx(classes)}>
        {pluralize('resident', deleteCount, true)}{' '}
        {pluralize('is', deleteCount, false)} in this {groupType} group.
      </p>
      <p className={twx(classes)}>
        {pluralize('Resident', deleteCount, false)} will get unassigned if you
        delete it.
      </p>
      <p className={twx(classes)}>Are you sure you want to delete it?</p>
      <section className={'mt-[24px]'}>
        <AsyncIconButton
          buttonStyle={'secondary-outline'}
          className={'mr-[8px]'}
          onClick={onCancel}
        >
          Cancel
        </AsyncIconButton>
        <AsyncIconButton
          buttonStyle={'danger-fill'}
          className={'ml-[8px]'}
          isLoading={isDeleting}
          disabled={isDeleting}
          onClick={async () => {
            setIsDeleting(true)
            await onDelete()
          }}
        >
          Delete Group
        </AsyncIconButton>
      </section>
    </NestedModalConfirmation>
  )
}
