import {
  CreateRoomRequest,
  Occupancy_Room as OccupancyRoom,
  OccupancyStats,
  UpdateCapacityRequest,
} from '@augusthealth/models/com/august/protos/api/occupancy'
import { Room } from '@augusthealth/models/com/august/protos/room'
import { requestJson } from '@shared/api/request'
import { apiRoomUrl } from '@shared/legacy_routes'
import { FacilityIds } from '@shared/types/facility'

export async function fetchRooms({
  orgId,
  id,
}: FacilityIds): Promise<OccupancyRoom[]> {
  const url = apiRoomUrl(orgId, id)
  const responseJson = await requestJson({
    url,
    method: 'GET',
  })

  return responseJson.data.rooms || []
}

export async function fetchOccupancyStats({
  orgId,
  id,
}: FacilityIds): Promise<OccupancyStats> {
  const url = `${apiRoomUrl(orgId, id)}/occupancyStats`
  const responseJson = await requestJson({
    url,
    method: 'GET',
  })

  return responseJson.data
}

export async function createRoom(
  { orgId, id }: FacilityIds,
  room: Room
): Promise<{ id: number }> {
  const url = apiRoomUrl(orgId, id)
  const responseJson = await requestJson({
    url,
    method: 'POST',
    body: JSON.stringify(room),
  })

  return responseJson.data
}

export async function bulkCreateRooms(
  { orgId, id }: FacilityIds,
  rooms: CreateRoomRequest[]
): Promise<{ hello: string }> {
  const url = `${apiRoomUrl(orgId, id)}/bulkCreate`
  const responseJson = await requestJson({
    url,
    method: 'POST',
    body: JSON.stringify({ rooms }),
  })

  return responseJson.data
}

export async function updateRoom(room: Room): Promise<Room> {
  const { orgId, facilityId, id } = room
  if (!orgId || !facilityId || !id) {
    throw new Error('updateRoom: orgId, facilityId or id is missing')
  }

  const url = `${apiRoomUrl(orgId, facilityId)}/${id}`
  const responseJson = await requestJson({
    url,
    method: 'PATCH',
    contentType: 'application/merge-patch+json',
    body: JSON.stringify(room),
  })

  return responseJson.data
}
export async function updateRoomCapacity(
  { orgId, facilityId, id }: Room,
  updateCapacity: UpdateCapacityRequest
): Promise<OccupancyRoom> {
  if (!orgId || !facilityId || !id) {
    throw new Error('updateRoomCapacity: orgId, facilityId or id is missing')
  }

  const url = `${apiRoomUrl(orgId, facilityId)}/${id}/capacity`
  const responseJson = await requestJson({
    url,
    method: 'POST',
    body: JSON.stringify(updateCapacity),
  })

  return responseJson.data
}
