import StyledSelect, {
  OptionTypeBase,
  StyledSelectProps,
} from '@shared/components/Selects/StyledSelect'
import ElementHolder, {
  Props as ElementHolderProps,
} from '@app/components/formElements/ElementHolder'

type OptionValue = string | number | undefined

type Props = Omit<StyledSelectProps, 'onChange' | 'value'> &
  Omit<ElementHolderProps, 'children' | 'value'> & {
    onUpdate: (value: OptionValue, name?: string, data?: any) => void
    value: OptionValue
  }

type Option = OptionTypeBase<OptionValue, any>

export default function SelectInGenerator(props: Props) {
  const { onUpdate, value, ...selectProps } = props
  const selectedOption = props?.options?.find(
    (opt?: Option) => opt?.value === value
  )
  const inputId = `${selectProps.name}-styled-select-input`

  return (
    <ElementHolder htmlFor={inputId} {...props}>
      <StyledSelect
        inputId={inputId}
        {...(selectProps as StyledSelectProps)}
        onChange={(opt?: Option) => onUpdate(opt?.value, props.name, opt?.data)}
        value={selectedOption || null}
      />
    </ElementHolder>
  )
}
