import { SignableFormWithAssignments } from '@augusthealth/models/com/august/protos/signable_form'
import { capitalize } from 'lodash'
import states from '@shared/constants/states'

export function filterBySearchTerm({
  search,
  form,
}: {
  search: string
  form: SignableFormWithAssignments
}) {
  if (search === '') {
    return true
  }

  const nameMatches = includes(search, form.signableForm?.name)
  const descriptionMatches = includes(search, form.signableForm?.description)
  const facilityMatches = form.facilities?.some((f) => includes(search, f.name))
  const orgMatches = form.organizations?.some((o) => includes(search, o.name))
  const stateAbbrev = states[capitalize(search)]
  const stateMatches = form.states?.some(
    (s) => includes(search, s) || (stateAbbrev && includes(stateAbbrev, s))
  )

  return (
    nameMatches ||
    descriptionMatches ||
    facilityMatches ||
    orgMatches ||
    stateMatches
  )
}

function includes(search: string, value: string | undefined) {
  return value?.toLowerCase().includes(search.toLowerCase()) ?? false
}
