import Datepicker from 'react-datepicker'
import { Controller, useFormContext } from 'react-hook-form'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import { DataType } from '@shared/types/snapshot'

export function SignedDocument({
  dataType,
}: {
  dataType: DataType | undefined
}) {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <>
      <input type={'hidden'} value={'SignedDocument'} {...register('tag')} />
      <input type={'hidden'} value={dataType} {...register('dataType')} />
      <div className="mt-[32px]">
        <LabelAboveInput
          htmlFor="dateSigned"
          subLabel={requiredLabel(!!errors.dateSigned)}
        >
          Date Signed
        </LabelAboveInput>
        <Controller
          defaultValue={null}
          control={control}
          name="dateSigned"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => {
            return (
              <Datepicker
                id="dateSigned"
                name="dateSigned"
                maxDate={new Date()}
                onChange={onChange}
                selected={value}
                customInput={<CalendarInput />}
              />
            )
          }}
        />
      </div>
    </>
  )
}
