import { Report } from '@augusthealth/models/com/august/protos/report'
import { useContext, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { deleteReport } from '@app/api/reports'
import ConfirmModal from '@app/components/ConfirmModal'
import HUD from '@app/components/HUD'

export default function DeleteConfirmPopup({
  report,
  onClose,
  afterDelete,
}: {
  report: Report
  onClose: () => void
  afterDelete: () => void
}) {
  const [showNotice, setShowNotice] = useState<boolean>(false)
  const { setError } = useContext(GlobalContext)
  const { id, description } = report
  const onConfirm = async () => {
    if (id) {
      try {
        await deleteReport(id)
        setShowNotice(true)
        afterDelete()
      } catch (e) {
        onClose()
        setError(e)
      }
    }
  }

  if (showNotice) {
    return <HUD onExpire={onClose}>Report {description} deleted</HUD>
  }

  return (
    <ConfirmModal
      title="Do you want to delete this report?"
      content={description}
      confirmButtonConfig={{
        children: 'Delete',
        onClick: onConfirm,
      }}
      denyButtonConfig={{
        onClick: onClose,
      }}
    />
  )
}
