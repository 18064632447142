import classnames from 'classnames'
import { Link } from 'react-router-dom'
import styles from './styles.module.css'

export type TabData = {
  url: string
  label: string
  active: boolean
  target?: string
}

export default function TabLink({ url, label, active, target }: TabData) {
  const labelClasses = classnames(styles.tabWrapper, {
    [styles.active]: active,
  })
  const tabId = `${label.toLowerCase()}-tab`

  return (
    <li className={labelClasses}>
      <div className={styles.tab}>
        {target ? (
          <a data-testid={tabId} id={tabId} href={url} target={target}>
            {label}
          </a>
        ) : (
          <Link data-testid={tabId} id={tabId} to={url}>
            {label}
          </Link>
        )}
      </div>
    </li>
  )
}
