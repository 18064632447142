import {
  Report,
  ReportAssignment,
} from '@augusthealth/models/com/august/protos/report'
import { Facility } from '@shared/types/facility'
import AllowLimitedReadCheckbox from './AllowLimitedReadCheckbox'
import AssignCheckbox from './AssignCheckbox'
import AssignmentName from './AssignmentName'
import PreviewIcon from './PreviewIcon'

export default function FacilityAssignmentTd({
  facility,
  report,
  assignments,
  reload,
}: {
  facility: Facility
  report: Report
  assignments: ReportAssignment[]
  reload: ({
    facilityId,
    orgId,
  }: {
    facilityId?: string
    orgId?: string
  }) => void
}) {
  const { id: facilityId, orgId } = facility
  const { id: reportId } = report
  const assignment = assignments.find(
    (a) =>
      a.facilityId === facilityId &&
      a.orgId === orgId &&
      a.reportId === reportId
  )
  const previewableAssignment = assignments.find(
    (a) =>
      (!a.facilityId || a.facilityId === facilityId) &&
      (!a.orgId || a.orgId === orgId) &&
      a.reportId === reportId
  )
  const previewLink = previewableAssignment && report && (
    <PreviewIcon
      facility={facility}
      assignment={previewableAssignment}
      report={report}
    />
  )

  return (
    <td className="report-cell">
      {previewLink}
      &nbsp;&nbsp;
      {report && (
        <AssignCheckbox
          assignment={assignment}
          facilityId={facilityId}
          orgId={orgId}
          report={report}
          reload={() => reload({ facilityId, orgId })}
        />
      )}
      {assignment && (
        <AllowLimitedReadCheckbox
          assignment={assignment}
          reload={() => reload({ facilityId, orgId })}
        />
      )}
      {assignment && (
        <AssignmentName
          assignment={assignment}
          reload={() => reload({ facilityId, orgId })}
        />
      )}
    </td>
  )
}
