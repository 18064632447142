import { isEmpty } from '@augusthealth/august-frontend-form-elements'
import { DateMessage } from '@augusthealth/models/com/august/protos/date'
import { KeyboardEvent, ReactNode } from 'react'
import { DateError } from './interfaces'

export function getDefaultBirthday(value?: string | Date | DateMessage) {
  if (typeof value === 'string' || value instanceof Date) {
    let dateObj
    if (typeof value === 'string') {
      dateObj = new Date(value)
    } else {
      dateObj = value
    }
    return {
      year: dateObj.getFullYear(),
      month: dateObj.getMonth() + 1,
      day: dateObj.getDate(),
    }
  } else if (value) {
    return value
  }

  return { year: undefined, month: undefined, day: undefined }
}

export const getInvalidBirthdayError = (
  birthday?: DateMessage,
  allowEmpty?: boolean
) => {
  const returnHash: DateError = {}
  const { year, month, day } = birthday || {}
  if (year === undefined && month === undefined && day === undefined) {
    if (allowEmpty) {
      return
    } else {
      return { isEmpty: true, misc: 'Date cannot be empty' }
    }
  }

  // JS will convert any number to *some* date, but if March 32 is entered
  // we do not want to save it as April 1, so we should flag it
  // arbitrary limit on how far back dates can go
  if (!allowEmpty && year === undefined) {
    returnHash.year = 'Please enter the year'
  } else if (year !== undefined && year < 1900) {
    returnHash.year = 'Year must be greater than 1900'
  }
  if (!allowEmpty && month === undefined) {
    returnHash.month = 'Please enter the month'
  } else if (month !== undefined && (month < 1 || month > 12)) {
    // Should not happen, since we are using Dropdonw for Month
    // always have a valid month is filled
    returnHash.month = 'Please enter a valid month'
  }

  if (!allowEmpty && day === undefined) {
    returnHash.day = 'Please enter the day'
  } else {
    let lastDayOfMonth: number | undefined
    if (year !== undefined && month !== undefined) {
      // Don't do (month + 1) here, I want to get the 0 day of previous month
      // which equal to the last day of current month
      lastDayOfMonth = new Date(year, month, 0).getDate()
    }
    if (
      day !== undefined &&
      (day < 1 || (lastDayOfMonth && day > lastDayOfMonth))
    ) {
      returnHash.day = `Please enter a valid day (1 ~ ${lastDayOfMonth})`
    }
  }

  if (year && month && day) {
    const birthDate = new Date(year, month - 1, day)
    const currentDate = new Date()

    if (currentDate < birthDate) {
      returnHash.misc = 'Date cannot be in the future'
    }
  }

  if (isEmpty(returnHash)) {
    return
  }

  return returnHash
}

export function displayDateError(error?: DateError): ReactNode | void {
  if (error) {
    const messages = Object.values(error).filter(
      (msg) => typeof msg === 'string'
    )

    if (!isEmpty(messages)) {
      return messages.map((msg) => <div key={msg}>{msg}</div>)
    }
  }
}

export function allowNumberInputOnly(event: KeyboardEvent<HTMLInputElement>) {
  // key.length > 1 is specail key such as Backspace, Enter, Alt, Shift, ArrowUp, etc.
  // Allow metaKey so CMD + A will work for .select()
  if (!event.metaKey && event.key.length === 1 && isNaN(+event.key)) {
    event.preventDefault()
  }
}

function isNaNOrUndefined(value: any) {
  return value === undefined || isNaN(value)
}

export function isCompleteDateMessage(date: DateMessage) {
  const { year, month, day } = date
  if (
    isNaNOrUndefined(year) ||
    isNaNOrUndefined(month) ||
    isNaNOrUndefined(day)
  ) {
    return false
  }

  return true
}

export function isCompleteAndValidBirthday(bDay: DateMessage) {
  if (getInvalidBirthdayError(bDay, true)) {
    return false
  }

  return !bDay || isCompleteDateMessage(bDay)
}
