import { useState } from 'react'
import { Incident, IncidentStatus } from '@shared/types/incidents'
import { Person } from '@shared/types/person'
import { UserAccount } from '@shared/types/user'
import { getIncidentSatus, hasIncompleteAction } from '@shared/utils/incident'
import { updateIncidentStatus } from '@app/api/incidents'
import ConfirmModal from '@app/components/ConfirmModal'
import styles from './styles.module.css'
import { canChangeIncidentStatus } from './helpers'

export default function IncidentStatusButton({
  incident,
  person,
  onChange,
  user,
}: {
  incident: Incident
  person: Person
  user: UserAccount
  onChange: (updated: Incident) => Promise<void>
}) {
  const [isUpdating, setIsUpdating] = useState(false)
  const [showIncompleteConfirmPopup, setShowIncompleteConfirmPopup] =
    useState(false)
  const { icon, color, text } = visualDataForIncident(incident, isUpdating)
  const status = getIncidentSatus(incident)
  const isOpen = status === IncidentStatus.INCIDENT_STATUS_OPEN
  const canEdit = canChangeIncidentStatus({ user, person })

  const onClick = async () => {
    if (isOpen && hasIncompleteAction(incident)) {
      setShowIncompleteConfirmPopup(true)
      return
    }

    return toggleStatus()
  }

  const toggleStatus = async () => {
    setIsUpdating(true)
    const newStatus =
      status === IncidentStatus.INCIDENT_STATUS_COMPLETED
        ? IncidentStatus.INCIDENT_STATUS_OPEN
        : IncidentStatus.INCIDENT_STATUS_COMPLETED
    const updatedIncident = await updateIncidentStatus({
      person,
      incident,
      incidentStatus: newStatus,
    })

    await onChange(updatedIncident)
    setIsUpdating(false)
  }

  return (
    <>
      <button
        data-testid="incident-toggle-status-btn"
        disabled={isUpdating || !canEdit}
        className={styles.incidentStatusBtn}
        onClick={async () => await onClick()}
        title={
          canEdit
            ? 'Toggle Incident Status'
            : "You don't have the permission to update Incident Status"
        }
      >
        <i className={`fa ${icon} ${color} mr-[8px]`} /> Incident Status:{' '}
        <span className={color}>{text}</span>
      </button>
      {showIncompleteConfirmPopup && (
        <ConfirmModal
          title={
            <div>
              <div>You have open follow-up actions.</div>
              <div>Are you sure you want to close this incident?</div>
            </div>
          }
          confirmButtonConfig={{
            onClick: async () => {
              await toggleStatus()
              setShowIncompleteConfirmPopup(false)
            },
          }}
          denyButtonConfig={{
            onClick: () => setShowIncompleteConfirmPopup(false),
          }}
        />
      )}
    </>
  )
}

function visualDataForIncident(incident: Incident, isUpdating: boolean) {
  if (incident.detail?.incidentDetail?.status === undefined) {
    return {
      icon: 'fa-circle-question',
      color: 'text-secondary-07',
      text: 'Unknown',
    }
  }

  let result: { icon: string; color: string; text: string }
  switch (incident.detail.incidentDetail.status) {
    case IncidentStatus.INCIDENT_STATUS_OPEN:
      result = {
        icon: 'fa-circle-dashed',
        color: 'text-tags-alert',
        text: 'Open',
      }
      break
    case IncidentStatus.INCIDENT_STATUS_COMPLETED:
      result = {
        icon: 'fa-circle-check',
        color: 'text-tags-happy',
        text: 'Closed',
      }
      break
    case IncidentStatus.INCIDENT_STATUS_UNSPECIFIED:
      result = {
        icon: 'fa-circle-question',
        color: 'text-secondary-07',
        text: 'Unknown',
      }
      break
    case IncidentStatus.UNRECOGNIZED:
      result = {
        icon: 'fa-circle-question',
        color: 'text-secondary-07',
        text: 'Unknown',
      }
  }

  if (isUpdating) {
    return { ...result, icon: 'fa-solid fa-spinner fa-spin' }
  }

  return result
}
