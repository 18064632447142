import { useFormContext } from 'react-hook-form'
import { tw } from '@shared/utils/tailwind'
import PolstRadioGroup from '@app/pages/Documents/sharedComponents/PolstRadioGroup'
import { PolstData } from '@app/pages/Documents/Viewer/helpers'

export function POLST({
  setAllowToSubmitWithoutFiles,
}: {
  setAllowToSubmitWithoutFiles: (allowed: boolean) => void
}) {
  const useFormReturn = useFormContext<PolstData>()

  return (
    <div className={tw`mt-[32px]`}>
      <PolstRadioGroup
        useFormReturn={useFormReturn}
        onRequiredAttachmentChange={(required: boolean) =>
          setAllowToSubmitWithoutFiles(!required)
        }
      />
    </div>
  )
}
