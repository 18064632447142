import { useContext, useEffect, useState } from 'react'
import { DataType, Snapshot } from '@shared/types/snapshot'
import { TaskType } from '@shared/types/task'
import { Loading } from '@shared/utils/loading'
import { fetchSnapshot } from '../api/snapshot'
import PersonContext from '../contexts/PersonContext'

export default function useCurrentPOLST(deps: React.DependencyList = []) {
  const { person, tasks = [] } = useContext(PersonContext)
  const [polstSnapshot, setPolstSnapshot] = useState<
    Loading<Snapshot | undefined>
  >({
    tag: 'Loading',
  })
  const polstTask = tasks.find(
    (t) => t.taskTemplateInfo?.taskType === TaskType.TASK_TYPE_POLST
  )

  useEffect(() => {
    if (person) {
      setPolstSnapshot({ tag: 'Loading' })
      void getLatestCompletePOLST({
        personId: person.id!,
        orgId: person.orgId!,
      }).then((snapshot) =>
        setPolstSnapshot({ tag: 'Complete', value: snapshot })
      )
    }
  }, [polstTask?.id, polstTask?.status, person?.id, person?.cprCode, ...deps])

  return polstSnapshot
}

const getLatestCompletePOLST = async ({
  personId,
  orgId,
}: {
  personId: string
  orgId: string
}): Promise<Snapshot | undefined> => {
  try {
    const polstResponseData = await fetchSnapshot({
      personId,
      orgId: orgId,
      dataType: DataType.DATA_TYPE_POLST,
    })

    if (polstResponseData.status === 'SNAPSHOT_STATUS_COMPLETE') {
      return polstResponseData
    }

    return undefined
  } catch (e) {
    return undefined
  }
}
