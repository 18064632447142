import { compact, isEqual } from 'lodash'
import { SignatureInfo } from '@shared/types/task'

export function signersHaveChanged(a: SignatureInfo[], b: SignatureInfo[]) {
  const aIds = compact(a.map((s: SignatureInfo) => s?.signer?.userAccountId))

  const bIds = compact(b.map((s: SignatureInfo) => s?.signer?.userAccountId))

  return !isEqual(aIds, bIds)
}

export function getProposedSigners({
  originalSigners,
  proposedSigners,
}: {
  originalSigners: SignatureInfo[]
  proposedSigners: SignatureInfo[]
}) {
  const originalSignerIds = compact(
    originalSigners.map((s) => s.signer?.userAccountId)
  )

  return proposedSigners.filter((s: SignatureInfo) => {
    if (!s.signer?.userAccountId) return false
    return !originalSignerIds.includes(s.signer?.userAccountId)
  })
}
