import { faker } from '@faker-js/faker'
import { Factory } from 'fishery'
import {
  CLINICIAN_RELATIONSHIP_LIST,
  PERSONAL_RELATIONSHIP_LIST,
  PRIMARY_CARE_ROLES,
  PROFESSIONAL_RELATIONSHIP_LIST,
} from '@shared/constants/contactRelationship'
import {
  Contact,
  Contact_ContactRelationship as Relationship,
} from '@shared/types/contact'
import {
  contactPointFactory,
  emailContactPointFactory,
} from './contactPointFactory'
import { humanNameFactory } from './humanNameFactory'

class ContactFactory extends Factory<Contact> {
  generalPractitioner() {
    return this.params({
      relationship: [
        Relationship.CONTACT_RELATIONSHIP_CLINICIAN,
        faker.helpers.arrayElement(PRIMARY_CARE_ROLES),
      ],
    })
  }

  personal() {
    return this.params({
      relationship: [
        Relationship.CONTACT_RELATIONSHIP_PERSONAL,
        faker.helpers.arrayElement(PERSONAL_RELATIONSHIP_LIST),
      ],
    })
  }

  clinician() {
    return this.params({
      relationship: [
        Relationship.CONTACT_RELATIONSHIP_CLINICIAN,
        faker.helpers.arrayElement(CLINICIAN_RELATIONSHIP_LIST),
      ],
    })
  }

  professional() {
    return this.params({
      relationship: [
        Relationship.CONTACT_RELATIONSHIP_PROFESSIONAL,
        faker.helpers.arrayElement(PROFESSIONAL_RELATIONSHIP_LIST),
      ],
    })
  }

  rp() {
    return this.params({
      relationship: [Relationship.CONTACT_RELATIONSHIP_RESPONSIBLE_PERSON],
      telecom: [emailContactPointFactory.build()],
    })
  }

  noTelecom() {
    return this.params({ telecom: [] })
  }

  payer() {
    return this.params({
      relationship: [
        Relationship.CONTACT_RELATIONSHIP_FINANCIAL_PAYMENT_OF_CARE,
      ],
    })
  }
}

export const contactFactory = ContactFactory.define((): Contact => {
  return {
    id: faker.string.uuid(),
    name: humanNameFactory.build(),
    telecom: [contactPointFactory.build()],
  }
})
