import ButtonLink from '@shared/components/ButtonLink'
import { EmptyCard } from '@shared/components/EmptyCard'
import { Person } from '@shared/types/person'
import { UserAccount } from '@shared/types/user'
import { canCreateIncident } from '../helpers'

type Props = {
  user: UserAccount
  person: Person
  isFiltered: boolean
  onAddNote: () => void
  onAddIncident: () => void
}

export default function EmptyIncidentCard(props: Props) {
  const { user, person, isFiltered, onAddNote, onAddIncident } = props

  const title = isFiltered
    ? 'No notes found matching your filters.'
    : 'No notes yet.'

  const canCreate = canCreateIncident({ user, person })

  const children = (
    <div className="mt-[34px]">
      {isFiltered ? (
        <>Try removing some filters and try again.</>
      ) : (
        canCreate && (
          <>
            To get started,{' '}
            <ButtonLink onClick={onAddNote} className="font-medium">
              add a note
            </ButtonLink>{' '}
            or{' '}
            <ButtonLink onClick={onAddIncident} className="font-medium">
              incident
            </ButtonLink>
            .
          </>
        )
      )}
    </div>
  )

  return (
    <div>
      <EmptyCard
        version={{
          tag: 'Children',
          children,
        }}
        title={title}
        data-testid="empty-notes"
      />
    </div>
  )
}
