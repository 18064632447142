export const TOOLS_PATH = '/tools'
export const TOOLS_PDF_TEMPLATE_PATH = `${TOOLS_PATH}/pdfTemplate`
export const TOOLS_RECENT_TEMPLATES_PATH = `${TOOLS_PATH}/pdfTemplate/recentTemplates`
export const TOOLS_ALL_TEMPLATES_PATH = `${TOOLS_PATH}/pdfTemplate/pdfTemplateList`
export const TOOLS_CREATE_TEMPLATE_PATH = `${TOOLS_PATH}/pdfTemplate/createPdfTemplate`
export const TOOLS_ASSIGN_TEMPLATES_PATH = `${TOOLS_PATH}/pdfTemplate/assignTemplates`
export const TOOLS_STATE_INCIDENT_TEMPLATES_PATH = `${TOOLS_PATH}/pdfTemplate/notifyState`
export const TOOLS_PDF_TEMPLATE_TAB_PATH = `${TOOLS_PDF_TEMPLATE_PATH}/:tab`
export const TOOLS_PDF_TEMPLATE_DISPLAY_FORM_PATH = `${TOOLS_PDF_TEMPLATE_PATH}/display/:formId`
export const TOOLS_PDF_TEMPLATE_ORG_PATH = `${TOOLS_PDF_TEMPLATE_TAB_PATH}/orgs/:orgId`
export const TOOLS_PDF_TEMPLATE_STATE_PATH = `${TOOLS_PDF_TEMPLATE_TAB_PATH}/states/:state`
export const TOOLS_PDF_TEMPLATE_LIST_PATHS = [
  TOOLS_PDF_TEMPLATE_PATH,
  TOOLS_PDF_TEMPLATE_TAB_PATH,
  TOOLS_PDF_TEMPLATE_DISPLAY_FORM_PATH,
  TOOLS_PDF_TEMPLATE_ORG_PATH,
  TOOLS_PDF_TEMPLATE_STATE_PATH,
]
export const TOOLS_PDF_TEMPLATE_HELLO_SIGN_PATH = `${TOOLS_PDF_TEMPLATE_PATH}/helloSignTemplate/:id`
export const TOOLS_FACILITY_MANAGEMENT_PATH = `${TOOLS_PATH}/facilities/new`
export const TOOLS_FACILITY_LIST_PATH = `${TOOLS_PATH}/facilities`
export const TOOLS_FACILITY_METRICS_PATH = `${TOOLS_PATH}/orgs/:orgId/facilities/:facilityId/metrics`
export const TOOLS_ADD_USER_PATH = `${TOOLS_PATH}/addUser`
export const TOOLS_MY_FEATURE_FLAGS_PATH = `${TOOLS_ADD_USER_PATH}/myFeatureFlags`
export const TOOLS_INTERNAL_USERS_PATH = `${TOOLS_ADD_USER_PATH}/internalUsers`
export const TOOLS_REPORT_GENERATOR = `${TOOLS_PATH}/reportGenerator`
export const TOOLS_REPORT_TAB_PATH = `${TOOLS_REPORT_GENERATOR}/:tab`
export const TOOLS_REPORT_ASSIGN_PATH = `${TOOLS_REPORT_GENERATOR}/:tab/:reportId`
export const TOOLS_REPORTS_PATHS = [
  TOOLS_REPORT_GENERATOR,
  TOOLS_REPORT_TAB_PATH,
  TOOLS_REPORT_ASSIGN_PATH,
]
export const TOOLS_RESIDENT_TRANSFER_PATH = `${TOOLS_PATH}/residentTransfer`
export const TOOLS_RESIDENT_IMPORT_PATH = `${TOOLS_PATH}/residentImport`
export const TOOLS_LOC_SETTINGS = `${TOOLS_PATH}/levelOfCare`
export const TOOLS_RESIDENT_DOCUMENT_UPLOADER = `${TOOLS_PATH}/residentDocumentUploader`
export const TOOLS_EMAIL_INFO_PATH = `${TOOLS_PATH}/emailInfo`
export const TOOLS_MEDPASS_CONFIG_PATH = `${TOOLS_PATH}/medpassConfiguration`
export const TOOLS_ASSESSMENT_CONFIG = `${TOOLS_PATH}/assessmentConfiguration`
export const TOOLS_DIETS_PATH = `${TOOLS_PATH}/diets`
export const TOOLS_AMBULATORY_PATH = `${TOOLS_PATH}/ambulatory`
export const TOOLS_SCHEDULED_TASKS_PATH = `${TOOLS_PATH}/scheduledTasks`
export const TOOLS_SECURITY_SETTINGS_PATH = `${TOOLS_PATH}/securitySettings`
export const TOOLS_FACILITY_FIELDS_PATH = `${TOOLS_PATH}/templates/:templateId/facilityFields`
export const TOOLS_REPROCESS_PHARMACY_MESSAGES_PATH = `${TOOLS_PATH}/pharmacyTools/reprocessPharmacyMessages`
export const TOOLS_UNASSIGN_PHARMACY_RESIDENT = `${TOOLS_PATH}/pharmacyTools/unassignPharmacyResident`
export const TOOLS_PHARMACY_INTEGRATIONS = `${TOOLS_PATH}/pharmacyTools/pharmacyIntegrations`
export const TOOLS_SHIFTS_PATH = `${TOOLS_PATH}/shifts`
export const TOOLS_SERVICE_PLAN_PDF_PATH = `${TOOLS_PATH}/pdfViews/servicePlan`
export const TOOLS_MED_LIST_PDF_PATH = `${TOOLS_PATH}/pdfViews/medList`

export const TOOLS_PATHS = [
  TOOLS_PATH,
  ...TOOLS_PDF_TEMPLATE_LIST_PATHS,
  TOOLS_PDF_TEMPLATE_HELLO_SIGN_PATH,
  TOOLS_FACILITY_MANAGEMENT_PATH,
  TOOLS_FACILITY_LIST_PATH,
  TOOLS_ADD_USER_PATH,
  TOOLS_MY_FEATURE_FLAGS_PATH,
  TOOLS_INTERNAL_USERS_PATH,
  TOOLS_FACILITY_METRICS_PATH,
  ...TOOLS_REPORTS_PATHS,
  TOOLS_RESIDENT_TRANSFER_PATH,
  TOOLS_RESIDENT_IMPORT_PATH,
  TOOLS_LOC_SETTINGS,
  TOOLS_EMAIL_INFO_PATH,
  TOOLS_RESIDENT_DOCUMENT_UPLOADER,
  TOOLS_MEDPASS_CONFIG_PATH,
  TOOLS_ASSESSMENT_CONFIG,
  TOOLS_DIETS_PATH,
  TOOLS_AMBULATORY_PATH,
  TOOLS_SCHEDULED_TASKS_PATH,
  TOOLS_SECURITY_SETTINGS_PATH,
  TOOLS_FACILITY_FIELDS_PATH,
  TOOLS_REPROCESS_PHARMACY_MESSAGES_PATH,
  TOOLS_UNASSIGN_PHARMACY_RESIDENT,
  TOOLS_PHARMACY_INTEGRATIONS,
  TOOLS_SHIFTS_PATH,
  TOOLS_SERVICE_PLAN_PDF_PATH,
  TOOLS_MED_LIST_PDF_PATH,
]

export function getAssignedReportPath(id: string) {
  return TOOLS_REPORT_ASSIGN_PATH.replace(/:tab/, 'assign').replace(
    /:reportId/,
    id
  )
}

export function getStateTasksUrl(state: string) {
  return TOOLS_PDF_TEMPLATE_STATE_PATH.replace(/:tab/, 'assign').replace(
    /:state/,
    state
  )
}
