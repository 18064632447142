import { useEffect, useState } from 'react'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import Card from '@shared/components/Card'
import { Organization } from '@shared/types/organization'
import { updateOrganization } from '@app/api/organizations'
import { Switch } from '@app/components/Switch'

export default function OrgSettings({
  org,
  setShowHUD,
  onClose,
  onUpdate,
}: {
  org: Organization
  setShowHUD: React.Dispatch<React.SetStateAction<boolean>>
  onClose?: () => void
  onUpdate?: () => void
}) {
  const [isActive, setIsActive] = useState(org.isActive)

  useEffect(() => {
    setIsActive(org.isActive)
  }, [org])

  const updateOrgSettings = async () => {
    const updatedOrg = { ...org, isActive }
    await updateOrganization(updatedOrg)

    setShowHUD(true)
    onClose?.()
    onUpdate?.()
  }

  return (
    <Card className="w-min flex-col">
      <div className="mb-[32px] text-[20px] font-semibold leading-[20px]">
        Organization Settings
      </div>

      <div className="flex items-center justify-between">
        <label className="mb-0" htmlFor="inactive">
          Mark as inactive
        </label>
        <Switch
          name="inactive"
          onChange={() => setIsActive(!isActive)}
          checked={!isActive}
        />
      </div>

      <div className="mt-[24px] flex justify-between gap-x-4">
        <AsyncIconButton
          buttonStyle="secondary-outline"
          buttonSize="small"
          onClick={onClose}
          width="120px"
        >
          Cancel
        </AsyncIconButton>

        <AsyncIconButton
          buttonStyle="primary-fill"
          buttonSize="small"
          onClick={async () => {
            await updateOrgSettings()
          }}
          width="120px"
        >
          Save
        </AsyncIconButton>
      </div>
    </Card>
  )
}
