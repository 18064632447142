import {
  Controller,
  Message,
  UseFormReturn,
  ValidationRule,
} from 'react-hook-form'
import {
  LabelAboveInput,
  LabelAboveInputProps,
} from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
  StyledSelectProps,
} from '@shared/components/Selects/StyledSelect'

type Rules = Partial<{
  required: Message | ValidationRule<boolean>
  min: ValidationRule<number | string>
  max: ValidationRule<number | string>
  valueAsNumber: boolean
  valueAsDate: boolean
}>

type Props = {
  labelProps?: LabelAboveInputProps
  rhfProps: {
    rules?: Rules
    useFormReturn: UseFormReturn<any>
  }
  selectProps: StyledSelectProps
}

export default function RHFSelect(props: Props) {
  const { labelProps, rhfProps, selectProps } = props
  const { rules, useFormReturn } = rhfProps
  const { control } = useFormReturn
  const { name, options = [] } = selectProps

  return (
    <Controller
      control={control}
      rules={rules}
      name={name || 'unknown-select'}
      render={({ field: { onChange, value } }) => (
        <div className="mt-[32px]">
          {labelProps && <LabelAboveInput {...labelProps} />}
          <StyledSelect
            {...selectProps}
            onChange={(opt?: OptionTypeBase) => onChange(opt?.value || null)}
            value={options.find((opt: OptionTypeBase) => opt?.value === value)}
          />
        </div>
      )}
    />
  )
}
