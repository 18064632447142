import DatePicker from 'react-datepicker'
import { Controller, useFormContext } from 'react-hook-form'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import { LabelAboveInput } from '@shared/components/Labels'
import {
  fromDateMessageToDate,
  fromDateToDateMessage,
} from '@shared/utils/date'
import { tw } from '@shared/utils/tailwind'
import {
  RoutineOrderFormData,
  RoutineOrderPeriodChoice,
} from './RoutineOrderForm'

export function RoutineDateInput(props: {
  dateLabel: 'startDate' | 'endDate'
  index: number
  disabled?: boolean
}) {
  const { dateLabel, index, disabled = false } = props
  const { getValues, setValue, control, watch } =
    useFormContext<RoutineOrderFormData>()
  const htmlName = `effectivePeriod.${dateLabel}` as const

  const clearInput = () => {
    setValue(htmlName, undefined)
  }

  const periodChoice = watch(`schedules.${index}.periodChoice`)

  const isStartDate = dateLabel === 'startDate'

  return (
    <Controller
      control={control}
      name={`effectivePeriod.${dateLabel}`}
      rules={{ required: isStartDate }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div className={tw`mt-input flex flex-col`}>
          <LabelAboveInput
            htmlFor={htmlName}
            subLabel={
              isStartDate
                ? { tag: 'Required', hasError: !!error }
                : { tag: 'Optional' }
            }
          >
            {dateLabel === 'startDate' ? 'Start Date' : 'End Date'}
          </LabelAboveInput>
          <DatePicker
            name={htmlName}
            customInput={
              <CalendarInput showErrorBorder={!!error} data-testid={htmlName} />
            }
            disabled={disabled}
            readOnly={periodChoice === RoutineOrderPeriodChoice.OnThisDay}
            onChange={(newDate: Date | null) => {
              if (newDate) {
                const newDateMessage = fromDateToDateMessage(newDate)
                setValue(htmlName, newDateMessage)
                onChange(newDateMessage)
              } else {
                clearInput()
              }
            }}
            selected={fromDateMessageToDate(value ?? getValues(htmlName))}
          />
        </div>
      )}
    />
  )
}
