import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ButtonLink from '@shared/components/ButtonLink'
import Card from '@shared/components/Card'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { admissionTasksPathForPerson } from '@shared/legacy_routes'
import { PersonTasks } from '@shared/types/api/person_tasks'
import { Person } from '@shared/types/person'
import { Task } from '@shared/types/task'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { getProfileSvgPath } from '@shared/utils/person'
import styles from '@app/pages/Compliance/styles.module.css'
import { getOverflowTasks, getTodoTasks, getUpcomingTasks } from './helpers'
import { TaskListItem } from './TaskListItem'

export default function PersonCard({
  personTask,
  setShowCloseTasks,
  setShowUploader,
  showOnlyTodoTasks,
}: {
  personTask: PersonTasks
  setShowCloseTasks: (val: { tasks: Task[]; person: Person }) => void
  setShowUploader: (val: { task: Task; person: Person }) => void
  showOnlyTodoTasks: boolean
}) {
  const upcomingTasks = getUpcomingTasks(personTask)
  const todoTasks = getTodoTasks(personTask)
  const overflowTasks = getOverflowTasks(personTask)

  const [showAllTasks, setShowAllTasks] = useState(false)
  const [filteredTasks, setFilteredTasks] = useState(todoTasks)

  useEffect(() => {
    if (showAllTasks) {
      setFilteredTasks([...todoTasks, ...overflowTasks])
    } else if (showOnlyTodoTasks) {
      setFilteredTasks(todoTasks)
    } else {
      setFilteredTasks(upcomingTasks)
    }
  }, [showAllTasks, personTask])

  useEffect(() => {
    setShowAllTasks(false)

    if (showOnlyTodoTasks) {
      setFilteredTasks(todoTasks)
    } else {
      setFilteredTasks(upcomingTasks)
    }
  }, [showOnlyTodoTasks])

  const overflowTaskCount = overflowTasks.length
  const personId = personTask.person?.id

  if (filteredTasks.length === 0 && overflowTaskCount === 0) {
    return null
  }
  const showOverflowLink =
    showOnlyTodoTasks && !showAllTasks && overflowTaskCount > 0

  return (
    <Card className="mb-[16px] flex-col" id={`person-card-${personId}`}>
      <div className={styles.personHeader}>
        <h3 className="m-0 block text-[16px] leading-[24px]">
          <Link
            to={admissionTasksPathForPerson(personTask.person)}
            className={styles.personName}
          >
            {personTask.profilePhotoUrl && (
              <img
                alt=""
                className={styles.tinyPic}
                src={personTask.profilePhotoUrl}
              />
            )}
            {!personTask.profilePhotoUrl && (
              <img
                alt=""
                className={styles.tinyPic}
                src={getProfileSvgPath(personTask.person)}
              />
            )}
            {getFirstAndLastName(personTask.person?.name)}
            <i className="far fa-angle-right ml-[8px]" />
          </Link>
        </h3>
        <PersonPermissionGate
          person={personTask.person!}
          permissions={[GroupPermission.GROUP_PERMISSION_TASK_UPDATE]}
        >
          <button
            id={`close-task-${personId}`}
            className={`uppercase ${styles.closeTasks}`}
            onClick={() => {
              setShowCloseTasks({
                tasks: filteredTasks.length > 0 ? filteredTasks : overflowTasks,
                person: personTask.person!,
              })
            }}
          >
            <i className="fas fa-clipboard-check mr-[8px]" />
            Remove Tasks
          </button>
        </PersonPermissionGate>
      </div>
      {filteredTasks.length > 0 && (
        <>
          <ul className="m-0 animate-fade-in list-none p-0">
            {filteredTasks.map((task, ix) => {
              return (
                <TaskListItem
                  id={`task-${personId}-${task.taskTemplateInfo!.taskType!}`}
                  key={`pt-${ix}`}
                  task={task}
                  person={personTask.person!}
                  onClickUpload={(task, person) => {
                    setShowUploader({ task, person })
                  }}
                />
              )
            })}
          </ul>
          {showOverflowLink && (
            <ButtonLink
              data-cy={`overflow-${personId}`}
              className={styles.overflowLink}
              onClick={() => setShowAllTasks(true)}
            >
              <span className={styles.fancyUnderline}>
                {overflowTaskCount === 1
                  ? '1 more task...'
                  : `${overflowTaskCount} more tasks...`}
              </span>
            </ButtonLink>
          )}
        </>
      )}
      {filteredTasks.length === 0 && overflowTaskCount > 0 && (
        <ul className="m-0 animate-fade-in list-none p-0">
          {overflowTasks.map((task, ix) => {
            return (
              <TaskListItem
                id={`task-${personId}-${task.taskTemplateInfo!.taskType!}`}
                key={`pt-${ix}`}
                task={task}
                person={personTask.person!}
                onClickUpload={(task, person) => {
                  setShowUploader({ task, person })
                }}
              />
            )
          })}
        </ul>
      )}
    </Card>
  )
}
