import { useFormContext } from 'react-hook-form'
import { MedOrderFormData } from '@shared/types/medication_order'
import { tw, twx } from '@shared/utils/tailwind'

type Props = {
  doseIndex: number
  readOnly: boolean
}
export const DoseLevelInstructions = ({ doseIndex, readOnly }: Props) => {
  const methods = useFormContext<MedOrderFormData>()
  const { watch } = methods

  const numberOfDoses = watch('doses')?.length ?? 0

  const dose = watch(`doses.${doseIndex}`)
  if (!dose?.text || numberOfDoses === 1) {
    return null
  }

  return (
    <div
      className={tw`mb-[24px] flex items-center text-[14px] font-medium leading-[20px] text-gray-07`}
    >
      <div
        className={twx(
          `min-h-[28px] w-[2px] self-stretch rounded-sm border-l-2 border-l-primary-light`,
          {
            'saturate-0': readOnly,
          }
        )}
      />
      <span className={tw`ml-3`}>{dose.text}</span>
    </div>
  )
}
