import styles from './styles.module.css'

interface Props {
  children: React.ReactNode
  containerClasses?: string
}
export default function BulletedSection({
  children,
  containerClasses = '',
}: Props) {
  return (
    <div className={`${containerClasses}`}>
      <i className={`${styles.bullet} fas fa-square fa-fw`} />
      <span className={styles.title}>{children}</span>
    </div>
  )
}
