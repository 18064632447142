import { AdmissionsInformation_AdmissionType } from '@augusthealth/models/com/august/protos/admissions'
import { LevelOfCare } from '@augusthealth/models/com/august/protos/level_of_care'
import { format, parseISO } from 'date-fns'
import Skeleton from 'react-loading-skeleton'
import { BillingResidentSummary } from '@shared/types/billing'
import { formatCurrencyForBilling } from '@shared/utils/billing'
import { Loading } from '@shared/utils/loading'
import { admissionTypeOptions } from '@shared/utils/orgFacilitySettings'
import pluralize from '@shared/utils/pluralize'
import { tw } from '@shared/utils/tailwind'

type Props = {
  billingSummary: Loading<BillingResidentSummary>
}

const UNKNOWN = 'n/a'

export default function Table(props: Props) {
  const { billingSummary } = props

  if (billingSummary.tag === 'Loading') {
    return <LoadingState />
  }

  const {
    admissionType,
    levelOfCare,
    moveInDate,
    moveOutDate,
    financialStartDate,
    financialEndDate,
    statementBalanceCents,
    totalBalanceCents,
  } = billingSummary.value

  return (
    <>
      <div className={tw`flex justify-between gap-[56px]`}>
        <div>
          <AdmissionTypeRow admissionType={admissionType} />
          <LevelOfCareRow levelOfCare={levelOfCare} />
        </div>
        <div>
          <DateRangeRow
            title="Physically present"
            startDate={moveInDate}
            endDate={moveOutDate}
          />
          <DateRangeRow
            title="Financially present"
            startDate={financialStartDate}
            endDate={financialEndDate}
          />
        </div>
        <div>
          <MoneyRow title="Current balance" amountCents={totalBalanceCents} />
          <MoneyRow
            title="Statement balance"
            amountCents={statementBalanceCents}
          />
        </div>
      </div>
    </>
  )
}

function LevelOfCareRow({
  levelOfCare,
}: {
  levelOfCare: LevelOfCare | undefined
}) {
  if (!levelOfCare) return <Row title="Care needs" text={UNKNOWN} />

  const { score, value } = levelOfCare

  let text = ''
  if (!value && !score) text = UNKNOWN
  if (!value && !!score) text = `${score} ${pluralize('point', score)}`
  if (!!value && !score) text = `Level ${value}`
  if (!!score && !!value) {
    text = `Level ${value} (${score} ${pluralize('point', score)})`
  }

  return <Row title="Care level" text={text} />
}

function AdmissionTypeRow({
  admissionType,
}: {
  admissionType: AdmissionsInformation_AdmissionType
}) {
  const humanReadableAdmissionType = admissionTypeOptions.find(
    (option) => option.value === admissionType
  )?.label

  const text = !humanReadableAdmissionType
    ? UNKNOWN
    : humanReadableAdmissionType

  return <Row title="Admission type" text={text} />
}

function Row({ title, text }: { title: string; text: React.ReactNode }) {
  return (
    <dl className={tw`grid grid-cols-2 items-baseline`}>
      <dt
        className={tw`min-w-[120px] text-[10px] font-semibold uppercase text-gray-07`}
      >
        {title}
      </dt>
      <dd className={tw`text-[14px] font-medium leading-[20px] text-gray-03`}>
        {text}
      </dd>
    </dl>
  )
}

function DateRangeRow({
  title,
  startDate,
  endDate,
}: {
  title: string
  startDate: string | undefined
  endDate: string | undefined
}) {
  if (!startDate && !endDate) {
    return <Row title={title} text={UNKNOWN} />
  }
  const formattedStartDate = startDate
    ? format(parseISO(startDate), 'MMMM d, yyyy')
    : UNKNOWN
  const formattedEndDate = endDate
    ? format(parseISO(endDate), 'MMMM d, yyyy')
    : 'present'
  return (
    <Row title={title} text={`${formattedStartDate} - ${formattedEndDate}`} />
  )
}

function MoneyRow({
  title,
  amountCents,
}: {
  title: string
  amountCents: number
}) {
  return <Row title={title} text={formatCurrencyForBilling(amountCents)} />
}

function LoadingState() {
  function SkeletonRow() {
    return <Skeleton height={20} width={250} />
  }

  function SkeletonRows() {
    return (
      <div>
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
      </div>
    )
  }

  return (
    <div className={tw`flex justify-between `}>
      <div>
        <SkeletonRows />
      </div>
      <div>
        <SkeletonRows />
      </div>
      <div>
        <SkeletonRows />
      </div>
    </div>
  )
}
