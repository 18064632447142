import { DatePicker, get } from '@augusthealth/august-frontend-form-elements'
import { ReactNode } from 'react'
import ElementHolder, {
  Props as ElementHolderProps,
  ValueProps,
} from '@app/components/formElements/ElementHolder'
import { uiComponentsMap } from '@app/components/generators/utils'

const CompnentsMap = {
  DatePicker,
  ...uiComponentsMap,
}

type Props = ElementHolderProps & {
  className?: string
  elementPropsList: {
    name: string
    elementName: string
  }[]
  data: ValueProps
}

/*
Ported from @august-frontend-form-elements
Only designed to use in src/app/components/Prospects/Forms/603/json/healthHistory.json
May be removed or improved in future
*/

export function ElementGroup(props: Props) {
  const { className, data, elementPropsList, ...restProps } = props
  const elementProps = { ...props }
  // Remove ElementHolder attributes from elementProps
  delete elementProps.title
  delete elementProps.subTitle
  delete elementProps.description

  const elements = elementPropsList.reduce((list, el) => {
    const { elementName, name } = el
    const Comp = CompnentsMap[elementName]
    const value = get(data, name)
    if (Comp) {
      const key = `element-group-${name}`
      list.push(<Comp key={key} {...elementProps} {...el} value={value} />)
    }

    return list
  }, [] as ReactNode[])

  return (
    <ElementHolder {...restProps}>
      <div className={className}>{elements}</div>
    </ElementHolder>
  )
}
