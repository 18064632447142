import { Person } from '@shared/types/person'
import { RoutableDocument } from '../helpers'
import LevelOfCareViewer from '../Viewer/LevelOfCare'
import DocumentCard from './DocumentCard'

export default function LevelOfCareCard({
  animationStyle,
  current,
  person,
  title,
}: {
  animationStyle: React.CSSProperties
  current: RoutableDocument
  person: Person
  title: string
}) {
  return (
    <DocumentCard
      animationStyle={animationStyle}
      person={person}
      document={current}
      icon="fa-file-alt"
      title={title}
      completionText="Completed"
      viewer={(closeFn) => (
        <LevelOfCareViewer
          levelOfCare={current}
          person={person}
          onComplete={closeFn}
        />
      )}
    />
  )
}
