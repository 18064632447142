import Datepicker from 'react-datepicker'
import { Controller, useFormContext } from 'react-hook-form'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import { DataType } from '@shared/types/snapshot'

export function CustomSignableForm({
  dataType,
  customType,
  isSignable,
}: {
  dataType: DataType | undefined
  customType: string | undefined
  isSignable: boolean
}) {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <>
      <input type="hidden" value="CustomSignableForm" {...register('tag')} />
      <input type="hidden" value={dataType} {...register('dataType')} />
      <input type="hidden" value={customType} {...register('customType')} />
      <div className="mt-[32px]">
        <LabelAboveInput
          htmlFor="dateSigned"
          subLabel={
            isSignable ? requiredLabel(Boolean(errors.dateSigned)) : undefined
          }
        >
          Date Signed
        </LabelAboveInput>
        <Controller
          defaultValue={null}
          control={control}
          name="dateSigned"
          rules={{ required: isSignable }}
          render={({ field: { onChange, value } }) => {
            return (
              <Datepicker
                id="dateSigned"
                name="dateSigned"
                maxDate={new Date()}
                onChange={onChange}
                selected={value}
                customInput={<CalendarInput />}
              />
            )
          }}
        />
      </div>
    </>
  )
}
