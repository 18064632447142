import { formatInTimeZone } from 'date-fns-tz'
import { ISO_FORMAT_STRING } from '@shared/constants/timing'
import { Facility } from '@shared/types/facility'

const notifyStates = ['CA', 'California', 'VA', 'Virginia']
const facilityAddressStateMatcher = (facility: Facility, states: string[]) =>
  states.some(
    (s) => s === facility.address?.state || facility.address?.text?.includes(s)
  )

export const isCaliforniaFacility = (facility: Facility | undefined | null) =>
  facility
    ? facilityAddressStateMatcher(facility, notifyStates.slice(0, 2))
    : false

export function isVirginiaFacility(facility: Facility) {
  return facilityAddressStateMatcher(facility, notifyStates.slice(2, 4))
}

interface FacilityHash {
  [key: string]: Facility
}

export function getFacilityHash(facilities: Facility[]): FacilityHash {
  return facilities.reduce<FacilityHash>((hash, f) => {
    const { id } = f
    if (id) {
      hash[id] = f
    }

    return hash
  }, {})
}

export function isEmarActive(facility: Facility) {
  return Boolean(facility.emarSettings?.isActive)
}

export function isCareAppActive(facility: Facility) {
  return Boolean(facility.careAppSettings?.isActive)
}

export function hasActivePharmacyIntegration(facility: Facility) {
  return Boolean(facility.hasActivePharmacyIntegration)
}

export enum BillingFeature {
  CommunityWideBilling = 'Community-wide Billing',
  ResidentLevelBilling = 'Resident-level Billing',
}

export type BillingFeatureType =
  | BillingFeature.CommunityWideBilling
  | BillingFeature.ResidentLevelBilling

export function billingFeatureForFacility(
  facility: Facility
): BillingFeatureType {
  return facility.billingSettings?.isActive
    ? BillingFeature.CommunityWideBilling
    : BillingFeature.ResidentLevelBilling
}

/**
 * returns the current time at the facility, zoned to the browsers timezone
 */
export const getCurrentZonedTimeAtFacility = (
  facilityTimeZone: string
): Date => {
  const milliseconds = formatInTimeZone(
    new Date(Date.now()),
    facilityTimeZone,
    ISO_FORMAT_STRING
  )
  return new Date(milliseconds)
}
