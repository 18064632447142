import { AugustFieldOption } from '@augusthealth/models/com/august/protos/api/json_report'
import {
  CustomDisplayField,
  DisplayField,
  DisplayPage,
  FormDisplay,
} from '@augusthealth/models/com/august/protos/signable_form'
import { UseFormReturn } from 'react-hook-form'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import ButtonLink from '@shared/components/ButtonLink'
import { LabelAboveInput, requiredWhenError } from '@shared/components/Labels'
import { isEmptyObject } from '@shared/utils/common'
import { tw } from '@shared/utils/tailwind'
import DraggableList from '@app/libraries/DragAndDrop/DraggableList'
import AugustField from './AugustField'
import CustomField from './CustomField'
import { getPageName } from './helpers'

export default function Page({
  augustValues,
  pageIndex,
  pages,
  useFormReturn,
}: {
  augustValues: AugustFieldOption[]
  pageIndex: number
  pages: DisplayPage[]
  useFormReturn: UseFormReturn<FormDisplay>
}) {
  const { formState, register, setValue, watch } = useFormReturn
  const { errors } = formState
  const pageTitleName = getPageName(pageIndex, 'pageTitle')
  const menuTitleName = getPageName(pageIndex, 'menuTitle')
  const augustFieldsName = getPageName(pageIndex, 'fields')
  const customFieldsName = getPageName(pageIndex, 'customFields')
  const augustFields = watch(augustFieldsName) as DisplayField[] | undefined
  const customFields = watch(customFieldsName) as
    | CustomDisplayField[]
    | undefined
  const disableNewAugustFieldBtn = Boolean(
    augustFields?.length && isEmptyObject(augustFields[augustFields.length - 1])
  )
  const disableNewCustomFieldBtn = Boolean(
    customFields?.length && isEmptyObject(customFields[customFields.length - 1])
  )
  const hasAugustFields = Boolean(augustFields?.length)
  const hasCustomFields = Boolean(customFields?.length)

  let hasMenuTitleError = false
  if (Array.isArray(errors.pages) && errors.pages[pageIndex]) {
    hasMenuTitleError = Boolean(errors.pages[pageIndex].menuTitle)
  }

  return (
    <section className={tw`relative`}>
      <div className={tw`mb-[24px] flex justify-evenly`}>
        <div className={tw`mr-[12px] flex-1`}>
          <LabelAboveInput htmlFor={pageTitleName}>Page Title</LabelAboveInput>
          <BasicInput {...register(pageTitleName)} />
        </div>
        <div className={tw`ml-[12px] flex-1`}>
          <LabelAboveInput
            htmlFor={menuTitleName}
            subLabel={requiredWhenError(hasMenuTitleError)}
          >
            Menu Title
          </LabelAboveInput>
          <BasicInput {...register(menuTitleName, { required: true })} />
        </div>
      </div>
      {!hasCustomFields && (
        <section className={tw`relative`}>
          <LabelAboveInput htmlFor={augustFieldsName}>
            August Fields
          </LabelAboveInput>
          <DraggableList
            path={augustFieldsName}
            useFormReturn={useFormReturn}
            addBtn={{
              buttonStyle: 'primary-fill',
              buttonSize: 'xsmall',
              children: 'Add new August field',
              disabled: disableNewAugustFieldBtn,
            }}
            ListItem={AugustField}
            itemProps={{ augustValues, pageIndex }}
            placeholder="No August fields found"
          />
          {augustFields?.length === 1 && (
            <ButtonLink
              className={tw`absolute right-[8px] top-0`}
              onClick={() => {
                // Need to set value to an empty array ([]) first to trigger shouldDirty then reset to undefined
                // Otherwise shouldDirty: true with an undefined field will generate a JS error
                setValue(augustFieldsName, [], { shouldDirty: true })
                setValue(augustFieldsName, undefined)
              }}
            >
              <i className={tw`fa-solid fa-times`} />
            </ButtonLink>
          )}
        </section>
      )}
      {!hasAugustFields && (
        <section className={tw`relative`}>
          <LabelAboveInput htmlFor={customFieldsName}>
            Custom Fields
          </LabelAboveInput>
          <DraggableList
            path={customFieldsName}
            useFormReturn={useFormReturn}
            addBtn={{
              buttonStyle: 'primary-fill',
              buttonSize: 'xsmall',
              children: 'Add new Custom field',
              disabled: disableNewCustomFieldBtn,
            }}
            ListItem={CustomField}
            itemProps={{ pageIndex }}
            placeholder="No Custom fields found"
            defaultValueToBeAdded={{
              formFieldConfiguration: {
                type: 'HashTextInput',
              },
            }}
          />
        </section>
      )}
      <i
        className={tw`fa-solid fa-times mute-text  absolute right-[-8px] top-[-12px] cursor-pointer`}
        onClick={() =>
          setValue(
            'pages',
            pages.filter((_p, i) => i !== pageIndex),
            { shouldDirty: true }
          )
        }
      />
    </section>
  )
}
