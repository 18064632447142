import { Person } from '@shared/types/person'
import { getAdmissionTypeLabel } from '@shared/utils/admissions'
import { formatDateMessage } from '@shared/utils/date'
import { getAdmissionType, getOnHospice, isRespite } from '@shared/utils/person'

export function moveIn(person: Person) {
  if (person.admissionsInformation?.startDate) {
    return formatDateMessage(person.admissionsInformation.startDate)
  } else if (person.admissionsInformation?.startRange) {
    return 'As soon as possible'
  }

  return ''
}

export function getFinancialStartDateLabel(person: Person) {
  if (person.admissionsInformation?.financialStartDate) {
    return formatDateMessage(person.admissionsInformation.financialStartDate)
  }

  return ''
}

export function admissionType(person: Person) {
  const admissionType = getAdmissionType(person)
  return admissionType ? getAdmissionTypeLabel(admissionType) : ''
}

export function hospice(person: Person) {
  if (getOnHospice(person)) {
    return 'Yes'
  }

  return 'No'
}

export function respite(person: Person) {
  if (isRespite(person)) {
    return 'Yes'
  }

  return 'No'
}
