import {
  SignableFormAssignment,
  SignableFormMetaData,
} from '@augusthealth/models/com/august/protos/signable_form'
import { useContext } from 'react'
import Badge from '@shared/components/Badge'
import ButtonLink from '@shared/components/ButtonLink'
import Card from '@shared/components/Card'
import GlobalContext from '@shared/contexts/GlobalContext'
import { getTitleFromDataType } from '@shared/utils/task'
import { deleteFormAssignment, FormAssignmentApiUrl } from '@app/api/form'
import taskStyles from '@app/components/Prospects/Tasks/styles.module.css'
import { getSettingsLevel } from '@app/pages/Tools/TaskDefinitions/helpers'

export default function AssignmentCard({
  assignment,
  formList,
  includeDeleteBtn = false,
  reloadAssignments,
}: {
  assignment: SignableFormAssignment
  formList: SignableFormMetaData[]
  includeDeleteBtn?: boolean
  reloadAssignments?: () => Promise<void>
}) {
  const { setError } = useContext(GlobalContext)
  const { id, signableFormId, dataType, state } = assignment
  const assignmentLevel = getSettingsLevel(assignment)

  if (signableFormId && dataType) {
    const taskTitle = getTitleFromDataType(dataType) || 'Task'
    const associatedForm = formList.find((f) => f.id === signableFormId)
    if (associatedForm) {
      const title = `${signableFormId}. ${associatedForm.name}`
      const status = `${assignmentLevel} assignment for ${taskTitle}`
      return (
        <Card className={taskStyles.card} key={`sfa-${id}`}>
          <div
            style={{ width: '60%', justifyContent: 'flex-start' }}
            className={taskStyles.titleRow}
          >
            <i className={`fa-file fal ${taskStyles.icon}`} />
            <div className="flex-col">
              <h6 className="mb-0">{title}</h6>
              <div className={taskStyles.subtitle}>{status}</div>
            </div>
          </div>
          {(includeDeleteBtn || state) && (
            <div>
              {state && (
                <Badge className="mr-[16px]" color="green">
                  {state}
                </Badge>
              )}
              {includeDeleteBtn && (
                <ButtonLink
                  onClick={() => {
                    deleteFormAssignment(assignment as FormAssignmentApiUrl)
                      .then(reloadAssignments)
                      .catch(setError)
                  }}
                >
                  <i className="fa fa-trash-can" />
                </ButtonLink>
              )}
            </div>
          )}
        </Card>
      )
    }
  }

  return null
}
