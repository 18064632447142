import { FormState } from 'react-hook-form'
import {
  Props as AsyncButtonProps,
  AsyncIconButton,
} from '@shared/components/AsyncButton'
import { tw } from '@shared/utils/tailwind'

type Props = {
  yesBtn: {
    label?: string
    props?: Partial<AsyncButtonProps>
  }
  noBtn: {
    action: () => void
    label?: string
    props?: Partial<AsyncButtonProps>
  }
  formState: FormState<never> | { isSubmitting: boolean }
}

type UploaderProps = Props & {
  yesBtn: {
    hideButton?: boolean
  }
}

type FooterProps = UploaderProps & {
  yesWidth: string
  noWidth: string
}

function FormFooter({
  yesBtn,
  noBtn,
  formState,
  yesWidth,
  noWidth,
}: FooterProps) {
  const { disabled, id, isLoading, onClick, type } = yesBtn.props || {}

  return (
    <>
      <AsyncIconButton
        buttonStyle="secondary-outline"
        onClick={noBtn.action}
        width={noWidth}
        data-cy={noBtn.props?.['data-cy']}
        type="button"
      >
        {noBtn.label || 'Cancel'}
      </AsyncIconButton>
      {!yesBtn.hideButton && (
        <AsyncIconButton
          disabled={formState.isSubmitting || disabled || isLoading}
          isLoading={formState.isSubmitting || isLoading}
          onClick={onClick}
          className={tw`ml-[16px]`}
          buttonStyle={yesBtn.props?.buttonStyle ?? 'primary-fill'}
          buttonSize="medium"
          width={yesWidth}
          id={id}
          data-testid={yesBtn.props?.['data-testid']}
          data-cy={yesBtn.props?.['data-cy']}
          type={type ? type : 'submit'}
        >
          {yesBtn.label || 'Save'}
        </AsyncIconButton>
      )}
    </>
  )
}

export function DocumentViewerUploaderFooter({
  yesBtn,
  noBtn,
  formState,
}: UploaderProps) {
  return (
    <FormFooter
      yesBtn={yesBtn}
      noBtn={noBtn}
      formState={formState}
      yesWidth={yesBtn.hideButton ? '0' : '50%'}
      noWidth={yesBtn.hideButton ? '100%' : '50%'}
    />
  )
}

export default function AnimatedPopupFormFooter({
  yesBtn,
  noBtn,
  formState,
}: UploaderProps) {
  return (
    <FormFooter
      yesBtn={yesBtn}
      noBtn={noBtn}
      formState={formState}
      yesWidth={yesBtn.props?.width || '138px'}
      noWidth={noBtn.props?.width || '138px'}
    />
  )
}
