import { useHistory, useParams } from 'react-router-dom'
import Icon from '@shared/components/Icon'
import LinkButton from '@shared/components/LinkButton'
import { billingPayerStatementPreviewRoute } from '@shared/routes'
import { BillingEventType, PayerBillingEvent } from '@shared/types/billing'
import { formatCurrencyForBilling } from '@shared/utils/billing'
import { convertISODateStringToLabel } from '@shared/utils/date'
import { tw, twx } from '@shared/utils/tailwind'
import { TdWithExtraPadding, TrWithHoverEffect } from '@app/components/Table'

type Props = {
  transaction: PayerBillingEvent
}

export default function TransactionRow(props: Props) {
  const history = useHistory()

  const { id, facilityId, orgId } = useParams<{
    id: string
    facilityId: string
    orgId: string
  }>()

  return (
    <TrWithHoverEffect
      data-testid="transaction-row"
      onClick={() => {
        if (props.transaction.transactionType === BillingEventType.STATEMENT) {
          return history.push(
            billingPayerStatementPreviewRoute(
              orgId,
              facilityId,
              id,
              props.transaction.id
            )
          )
        }
      }}
      className={tw`text-gray-04`}
    >
      <TdWithExtraPadding className={tw`flex items-center`}>
        <Icon
          name="file-invoice-dollar"
          className={tw`mr-4 text-xl text-primary`}
          variant="solid"
        />
        {convertISODateStringToLabel(props.transaction.serviceStartDate)}
      </TdWithExtraPadding>

      <TdWithExtraPadding data-testid="transaction-description">
        {props.transaction.transactionType === BillingEventType.STATEMENT && (
          <>{props.transaction.description}</>
        )}
        {props.transaction.transactionType === BillingEventType.PAYMENT && (
          <span className={tw`text-sm font-medium text-primary`}>
            <>{props.transaction.description}</>
          </span>
        )}
      </TdWithExtraPadding>
      <TdWithExtraPadding>
        <span
          data-testid="transaction-balance"
          className={twx('text-sm font-medium', {
            'text-primary':
              props.transaction.transactionType === BillingEventType.PAYMENT,
          })}
        >
          {formatCurrencyForBilling(props.transaction.balanceCents || 0)}
        </span>
      </TdWithExtraPadding>

      <TdWithExtraPadding>
        {props.transaction.transactionType === BillingEventType.STATEMENT && (
          <LinkButton
            data-testid="view-statement"
            href=""
            buttonStyle="link"
            className={tw`h-6 text-sm font-medium lowercase text-gray-07 underline`}
          >
            view
          </LinkButton>
        )}
      </TdWithExtraPadding>
    </TrWithHoverEffect>
  )
}
