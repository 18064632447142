import {
  EmailNotification,
  EmailNotificationEnumOption,
  NotificationSubscriptions,
} from '@augusthealth/models/com/august/protos/notification_subscriptions'
import { groupBy } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { UserAccount } from '@shared/types/user'
import { AsyncResult } from '@shared/utils/loading'
import {
  getEmailNotificationOptions,
  getNotificationSubscriptions,
  subscribeToNotification,
  unsubscribeToNotification,
} from '@app/api/email_notifications'
import Content from '@app/components/generic/Content'
import { PlainDetailsCard } from '@app/components/Prospects/Details/DetailsCard'
import NotificationRow from './NotificationRow'

/*
The route for this page is a little strange. Currently, notification
preferences are NOT relative to an org or facility. They are global for the
user. Ideally, this would be reflected in a route like /account_preferences.
However, the sidebar currently depends on the FacilitiesContext, which expects
an org/facility ID in the URL. I nested this page under the org/facility route
to avoid a large refactor of the sidebar.
 */
export const ACCOUNT_PREFERENCES_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/account_preferences',
]

function hasPermissionForNotificationOption(
  user: UserAccount,
  option: EmailNotificationEnumOption
) {
  const optionPermission = option.description?.permission
  if (optionPermission === undefined) {
    return true
  }

  const allUserPermissions = (user.groups ?? []).reduce((accum, el) => {
    return [...accum, ...(el.groupPermissions ?? [])]
  }, [])

  return allUserPermissions.includes(optionPermission)
}

export default function AccountPreferences() {
  const { user } = useUserContext()
  const { setError } = useContext(GlobalContext)
  const notificationOptions = useNotificationOptions()
  const initialSubscriptions = useNotificationSubscriptions()
  const [currentSubscriptions, setCurrentSubscriptions] = useState<
    EmailNotification[]
  >([])

  useEffect(() => {
    if (initialSubscriptions.tag === 'Complete') {
      setCurrentSubscriptions(initialSubscriptions.value.notifications ?? [])
    }
  }, [initialSubscriptions])

  if (
    notificationOptions.tag !== 'Complete' ||
    initialSubscriptions.tag !== 'Complete'
  ) {
    return null
  }

  const relevantOptions = notificationOptions.value.filter((no) =>
    hasPermissionForNotificationOption(user, no)
  )

  const groupedOptions = groupBy(
    relevantOptions,
    (no) => no.description!.notificationGroup
  )
  const groups = Object.keys(groupedOptions)

  return (
    <Content
      className={
        'mt-[32px] text-[14px] font-medium leading-[20px] text-gray-07'
      }
      title={'Account Preferences'}
    >
      <PlainDetailsCard icon={'fa fa-bell-on'} title={'Email Notifications'}>
        <span className={'mb-[16px]'}>
          Your email for notifications is{' '}
          <strong className={'font-semibold text-gray-04'}>{user.email}</strong>
          .
        </span>
        {groups.map((group, i) => {
          const options = groupedOptions[group]

          if (options.length === 0) {
            return null
          }

          return (
            <section
              className={'mt-[32px] overflow-x-clip'}
              key={`ngroup-${i}`}
            >
              <h2
                className={
                  'm-0 border-b border-transparent-gray pb-[12px] text-[14px] font-bold uppercase leading-[18px] text-gray-04'
                }
              >
                {group}
              </h2>
              <ul className={'m-0 list-none p-0'}>
                {options.map((no, i) => (
                  <NotificationRow
                    key={`prefitem-${i}`}
                    notificationOption={no}
                    currentSubscriptions={currentSubscriptions}
                    onChange={async (change) => {
                      if (change.action === 'Subscribe') {
                        try {
                          setCurrentSubscriptions((prevState) => [
                            ...prevState,
                            change.value,
                          ])
                          await subscribeToNotification(change.value!)
                        } catch (e) {
                          setError(e)
                        }
                      } else if (change.action === 'Unsubscribe') {
                        try {
                          setCurrentSubscriptions((prevState) =>
                            prevState.filter((en) => en !== change.value)
                          )
                          await unsubscribeToNotification(change.value!)
                        } catch (e) {
                          setError(e)
                        }
                      }
                    }}
                  />
                ))}
              </ul>
            </section>
          )
        })}
      </PlainDetailsCard>
    </Content>
  )
}

function useNotificationOptions() {
  const [notificationOptions, setNotificationOptions] = useState<
    AsyncResult<EmailNotificationEnumOption[], Error>
  >({ tag: 'Loading' })

  useEffect(() => {
    getEmailNotificationOptions()
      .then((value) => setNotificationOptions({ tag: 'Complete', value }))
      .catch((value) => setNotificationOptions({ tag: 'Error', value }))
  }, [])

  return notificationOptions
}

function useNotificationSubscriptions() {
  const [notificationSubscriptions, setNotificationSubscriptions] = useState<
    AsyncResult<NotificationSubscriptions, Error>
  >({ tag: 'Loading' })

  useEffect(() => {
    getNotificationSubscriptions()
      .then((value) => setNotificationSubscriptions({ tag: 'Complete', value }))
      .catch((value) => setNotificationSubscriptions({ tag: 'Error', value }))
  }, [])

  return notificationSubscriptions
}
