import { DoseNumber } from '@augusthealth/models/com/august/protos/immunization'
import { request } from '@shared/api/request'
import { mergePatchSnapshot } from '@shared/api/snapshot'
import { apiUploadUrl } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import {
  Polst_CPR,
  SignatureDetails_SignatureProvider,
  Snapshot,
  Snapshot_Data,
} from '@shared/types/snapshot'
import { doseLabels } from '@app/components/Prospects/Details/Vaccinations/helpers'

export interface PolstData {
  tag: 'POLST'
  codeStatus: Polst_CPR
  documentNotRequired?: boolean
}

export async function deleteDocument(person: Person, document: Snapshot) {
  const url = apiUploadUrl({
    documentId: document.id,
    orgId: person.orgId!,
    personId: person.id!,
    dataType: document.dataType!,
    customType: document.customType,
  })

  return await request({
    url: `${url}`,
    method: 'DELETE',
  })
}

export async function updateDocument(
  person: Person,
  document: Snapshot,
  newDocumentName: string
) {
  const url = apiUploadUrl({
    orgId: person.orgId!,
    personId: person.id!,
    dataType: document.dataType!,
    customType: document.customType,
  })

  const body: Snapshot_Data = {
    uploadInfo: {
      name: newDocumentName,
    },
  }

  await request({
    contentType: 'application/merge-patch+json',
    url: `${url}/${document.id}`,
    method: 'PATCH',
    body: JSON.stringify({
      data: body,
    }),
  })
}

export async function updateCPRCodeStatus(
  data: PolstData,
  person: Person,
  currentPOLST: Snapshot
) {
  await mergePatchSnapshot({
    pId: person.id!,
    orgId: person.orgId!,
    snapshotId: currentPOLST.id!,
    patch: {
      uploadInfo: {
        polst: {
          cprCode: data.codeStatus,
        },
      },
    },
    dataType: 'DATA_TYPE_POLST',
    status: currentPOLST.status,
  })
}

export function spaceFree(input: string) {
  return input.replace(/ /g, '_')
}

export function getDoseLabel(doseNumber: DoseNumber | undefined) {
  const label = Object.keys(doseLabels).find((k) => k === doseNumber)

  if (label) {
    return doseLabels[label]
  }

  return undefined
}

export function isDeletable(document: Snapshot) {
  // If it is hello-signed, it is not deletable
  if (
    document.signatureDetails?.provider ===
    SignatureDetails_SignatureProvider.SIGNATURE_PROVIDER_HELLO_SIGN
  ) {
    return false
  }

  return true
}
