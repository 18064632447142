import { useState } from 'react'
import Card from '@shared/components/Card'
import {
  MarkAsAdministeredCardRadioButton,
  MarkAsExceptionCardRadioButton,
} from '@shared/components/RoutineAdministrationButtons/FollowUpActionButtons'
import { CareAppPerson, RoutineAdministration } from '@shared/types/careapp'
import { tw } from '@shared/utils/tailwind'
import MarkAsAdministeredModal from '@app/pages/Facilities/Progress/Routines/Modals/MarkAsAdministeredModal'
import MarkAsExceptionModal from '@app/pages/Facilities/Progress/Routines/Modals/MarkAsExceptionModal'
import RoutineAdministrationBanner from './RoutineAdministrationBanner'
import RoutineModal from './RoutineModal'

export default function ModalToHandleOverdue(props: {
  careAppPerson: CareAppPerson
  onClose: () => void
  routineAdministration: RoutineAdministration
  facilityTimeZone: string
  reload: () => void
}) {
  const { careAppPerson, onClose, routineAdministration, facilityTimeZone } =
    props
  const [choice, setChoice] = useState<
    'markAsAdministered' | 'markAsException'
  >()

  if (choice === 'markAsAdministered') {
    return <MarkAsAdministeredModal {...props} />
  }

  if (choice === 'markAsException') {
    return <MarkAsExceptionModal {...props} />
  }

  return (
    <RoutineModal
      id="progress-routines-overdue-modal"
      careAppPerson={careAppPerson}
      onClose={onClose}
    >
      <Card className={tw`flex-col`}>
        <RoutineAdministrationBanner
          routineAdministration={routineAdministration}
          facilityTimeZone={facilityTimeZone}
        />
        <div className={tw`mt-[16px] flex-col px-[8px]`}>
          <fieldset className="flex flex-col gap-3">
            <MarkAsAdministeredCardRadioButton
              onClick={() => setChoice('markAsAdministered')}
              radioInputClassName={tw`hidden`}
            />
            <MarkAsExceptionCardRadioButton
              onClick={() => setChoice('markAsException')}
              radioInputClassName={tw`hidden`}
            />
          </fieldset>
        </div>
      </Card>
    </RoutineModal>
  )
}
