import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import Card from '@shared/components/Card'
import {
  CompletionType,
  FacilityCompletionStats,
} from '@shared/types/api/facility_stats'
import {
  CompletionTypeToTemplateHash,
  getLabelForCompletionType,
} from '@shared/utils/facilityStats'
import { Loading } from '@shared/utils/loading'
import PieChartIcon, {
  strokeColorForPercent,
} from '@app/components/Residents/CompletionStats/PieChartIcon'
import styles from './styles.module.css'
import 'react-loading-skeleton/dist/skeleton.css'
import { getResidentCompletionUrl } from '@shared/legacy_routes'

interface Props {
  availableCompletionTasks: Loading<CompletionType[]>
  stats: Loading<FacilityCompletionStats>
  facilityId: string
  orgId: string
  completionTypeToTemplateHash?: CompletionTypeToTemplateHash
}

export default function ResidentCompletionBanner({
  availableCompletionTasks,
  stats,
  facilityId,
  orgId,
  completionTypeToTemplateHash = {} as CompletionTypeToTemplateHash,
  ...rest
}: Props) {
  const values =
    stats.tag === 'Complete' && availableCompletionTasks.tag === 'Complete'
      ? percentageDataFromStats(stats.value, availableCompletionTasks.value)
      : []

  return (
    <Card {...rest} className={styles.completionContainer}>
      <Link
        data-cy="resident-completion-link"
        to={getResidentCompletionUrl(orgId, facilityId)}
        className={`${styles.completionHeader} flex items-center`}
      >
        Resident Completion
        <i className={'far fa-angle-right ml-[8px]'} />
      </Link>
      <div className={styles.statsContainer}>
        {(stats.tag === 'Loading' ||
          availableCompletionTasks.tag === 'Loading') && <LoadingSkeleton />}
        {values.map((data, ix) => (
          <div
            data-cy="facility-completion-stat"
            key={`res-pc-${ix}`}
            className={styles.pieContainer}
          >
            <PieChartIcon percent={data.percent} />
            <span className="ml-[8px]">
              {getLabelForCompletionType({
                completionType: data.completionType,
                completionTypeToTemplateHash,
              })}{' '}
              <span style={{ color: strokeColorForPercent(data.percent) }}>
                {data.percent}%
              </span>
            </span>
          </div>
        ))}
      </div>
    </Card>
  )
}

export function LoadingSkeleton() {
  return (
    <>
      {Array(8)
        .fill(undefined)
        .map((_, ix) => (
          <Skeleton key={`ls-${ix}`} />
        ))}
    </>
  )
}

export function percentageDataFromStats(
  stats: FacilityCompletionStats,
  baseTasks: CompletionType[]
): { percent: number; completionType: CompletionType }[] {
  return baseTasks.map((completionType) => {
    const numerator = parseInt(stats.completionCount?.[completionType] || '0')
    const denominator = parseInt(stats.personCount!)
    const decimal = denominator > 0 ? numerator / denominator : 0
    const percent = parseInt((decimal * 100).toFixed(2))

    return { percent, completionType }
  })
}
