import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { ModalTitle } from '@shared/components/baseMui/Modal/Layout'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import { DaysOfMonthOptions } from '@shared/constants/timing'
import { tw } from '@shared/utils/tailwind'
import { ButtonGroup } from '@app/components/ButtonGroup'

type Props = {
  isOpen: boolean
  onClose: () => void
  paymentDayOfMonth: number | null
  statementDueDay: number
}

const MIN_PAYMENT_DAY = 26

export default function DayForAutoPayModal(props: Props) {
  const [selectedDay, setSelectedDay] = useState<number | null>(
    props.paymentDayOfMonth
  )
  const {
    setValue,
    formState,
    formState: { disabled, isLoading },
  } = useFormContext()

  const daysOfMonthOptionsWithDisabledDays = DaysOfMonthOptions.map((day) => {
    return {
      ...day,
      isDisabled:
        day.value < MIN_PAYMENT_DAY || day.value > props.statementDueDay,
    }
  })

  return (
    <Modal
      contentClassName={tw`min-w-[380px]`}
      id="day-for-auto-pay-modal"
      open={props.isOpen}
      onClose={props.onClose}
    >
      <ModalTitle
        className={tw`leading-0 border-0 pb-0 text-2xl font-medium text-secondary-02`}
      >
        Day for Auto Pay
      </ModalTitle>
      <ButtonGroup
        prependTestId="paymentDayOfMonth"
        onChange={(e) => setSelectedDay(e[0].value)}
        type="Single"
        options={daysOfMonthOptionsWithDisabledDays}
        buttonClassName={tw`flex h-[40px] w-[40px] items-center justify-center`}
        containerClassName={tw`grid grid-cols-[repeat(7,40px)] grid-rows-4 gap-1.5`}
        chosen={
          daysOfMonthOptionsWithDisabledDays.filter(
            (dayOfMonth) => props.paymentDayOfMonth === dayOfMonth.value
          ) as OptionTypeBase<number>[]
        }
      />
      <div className={tw`mt-8`}>
        <AsyncIconButton
          buttonStyle="secondary-outline"
          onClick={props.onClose}
          type="button"
        >
          Cancel
        </AsyncIconButton>

        <AsyncIconButton
          data-testid="save-day-for-auto-pay"
          disabled={formState.isSubmitting || disabled || isLoading}
          isLoading={formState.isSubmitting || isLoading}
          onClick={async () => {
            setValue('paymentDayOfMonth', selectedDay)
            props.onClose()
          }}
          className={tw`ml-[16px]`}
          buttonStyle="primary-fill"
          buttonSize="medium"
          type="submit"
        >
          Save
        </AsyncIconButton>
      </div>
    </Modal>
  )
}
