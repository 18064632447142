import { useState } from 'react'
import {
  ActionButtonHolder,
  PrintButton,
} from '@shared/components/AnimatedPopup/Lightbox/ActionButtons/ActionButtons'
import Lightbox, {
  LightboxFooterButton,
  LightboxSidebarContent,
  LightboxSidebarFooter,
} from '@shared/components/AnimatedPopup/Lightbox/Lightbox'
import Card from '@shared/components/Card'
import { Person } from '@shared/types/person'
import { Snapshot } from '@shared/types/snapshot'
import { getFirstAndLastName } from '@shared/utils/humanName'
import Print, { printSelectedElements } from '@app/components/Printable'
import gettingToKnowYouConf from '@app/components/Prospects/Forms/GettingToKnowYou/json/gettingToKnowYou.json'
import styles from './styles.module.css'

interface Props {
  person: Person
  gettingToKnowYou: Snapshot
  onComplete: (updated: boolean) => void
  title: string
}

export default function GettingToKnowYouViewer({
  person,
  onComplete,
  gettingToKnowYou,
  title,
}: Props) {
  const [printGTKU, setPrintGTKU] = useState(false)
  const gtkuQandA = (
    <ol>
      {gettingToKnowYouConf.map((question, ix) => {
        const answer = gettingToKnowYou.data?.gettingToKnowYou?.[question.name]
        return (
          <li key={`gtku-${ix}`} className="pb-[16px]">
            <div>{question.description}</div>
            <div className={styles.answer}>{answer || 'No Answer'}</div>
          </li>
        )
      })}
    </ol>
  )

  return (
    <>
      <Lightbox
        mainContent={
          <Card style={{ color: 'black' }} className="overflow-y-auto">
            <ol>
              {gettingToKnowYouConf.map((question, ix) => {
                const answer =
                  gettingToKnowYou.data?.gettingToKnowYou?.[question.name]
                return (
                  <li key={`gtku-${ix}`} className="pb-[16px]">
                    <div>{question.description}</div>
                    <div className={styles.answer}>{answer || 'No Answer'}</div>
                  </li>
                )
              })}
            </ol>
          </Card>
        }
        sidebarContent={
          <>
            <LightboxSidebarContent title={title}>
              <ActionButtonHolder>
                <PrintButton
                  onClick={() => printSelectedElements(setPrintGTKU)}
                />
              </ActionButtonHolder>
            </LightboxSidebarContent>
            <LightboxSidebarFooter>
              <LightboxFooterButton onClick={() => onComplete(false)} />
            </LightboxSidebarFooter>
          </>
        }
        mainContentBackgroundColor="white"
        onClose={() => onComplete(false)}
      />
      {printGTKU && (
        <Print>
          <div style={{ margin: '100px' }}>
            <h1 style={{ marginBottom: '50px' }}>
              Getting to Know You for {getFirstAndLastName(person.name)}
            </h1>
            {gtkuQandA}
          </div>
        </Print>
      )}
    </>
  )
}
