import React from 'react'
import { tw, twx } from '@shared/utils/tailwind'

interface Props {
  header: string
  subheader: string
  children: React.ReactNode
  subcontent?: React.ReactNode // hidden on mobile
  footer?: React.ReactNode // hidden on mobile
  topRightContent?: React.ReactNode
  gridIndex?: number // supports cascading animation when used
  linkable?: boolean
  containerClassName?: string
  onClick?: () => void
  showDivider?: boolean
  testid?: string
  label?: string
}

export default function BaseCard({
  children,
  gridIndex,
  containerClassName,
  linkable = true,
  onClick,
  header,
  subheader,
  subcontent,
  footer,
  topRightContent,
  showDivider = true,
  testid,
  label,
}: Props) {
  // this sets a variable delay for cascading animation
  // see shared/utils/tailwind.ts for supported delays
  const ANIMATION_DELAY_BASE = 50
  const animationDelay = gridIndex
    ? `motion-safe:animation-delay-[${gridIndex * ANIMATION_DELAY_BASE}ms]`
    : ''

  const ParentElement = linkable ? 'a' : 'div'

  return (
    <ParentElement
      data-testid={testid}
      className={twx(
        'rounded-md border border-transparent',
        {
          'hover:border-primary-light focus:border-primary-light': linkable,
          'motion-safe:animate-animate-in': gridIndex,
        },
        animationDelay
      )}
      onClick={onClick}
    >
      <div className={twx(containerClassName ?? 'size-full')}>
        <label className={tw`visually-hidden`}>{label}</label>
        <div
          className={twx(
            'flex-column h-100 flex min-w-[336px] justify-between rounded-md bg-white p-6 shadow-card transition duration-100 ease-in-out',
            { 'cursor-pointer hover:border-primary-light': linkable }
          )}
          tabIndex={0}
        >
          <section className={tw`relative z-20`}>
            <div className={tw`mb-6 flex justify-between`}>
              <div>
                <h4
                  className={tw`animate-fade-in text-sm font-semibold uppercase leading-4 text-gray-07`}
                >
                  {subheader}
                </h4>
                <h3
                  className={tw`mb-0 text-xl font-semibold leading-6 text-black`}
                >
                  {header}
                </h3>
              </div>
              <div className={tw`content-center`}>{topRightContent}</div>
            </div>
            <div className={tw`animate-fade-in`}>{children}</div>
          </section>
          <section className={tw`relative z-10 animate-fade-in mobile:hidden`}>
            {subcontent}
            {footer && (
              <div
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
                className={twx('mt-6', {
                  'border-t border-gray-11': showDivider,
                })}
              >
                {footer}
              </div>
            )}
          </section>
        </div>
      </div>
    </ParentElement>
  )
}
