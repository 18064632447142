import { Person } from '@shared/types/person'
import { PickPartial } from '@shared/types/utilities'
import {
  AmbulatoryBadgeColor,
  getAmbulatoryBadgeColor,
  getAmbulatoryStatus,
} from '@shared/utils/ambulatoryStatus'
import { useCurrentFacility } from '@app/hooks/useFacilities'

/**
 * A hook to get the ambulatory status of the person.
 * Depends on PersonContext and CurrentFacilityContext.
 * Ambulatory status is configurable (via Tools) everywhere except California.
 */
export default function useAmbulatoryStatus(
  person: PickPartial<Person, 'ambulatoryStatus'>
): {
  ambulatoryStatus: string
  ambulatoryBadgeColor: AmbulatoryBadgeColor
} {
  const { currentFacility, settings } = useCurrentFacility()
  const ambulatoryBadgeColor = getAmbulatoryBadgeColor(person)

  if (currentFacility === undefined || currentFacility.tag === 'Loading') {
    return { ambulatoryStatus: 'Loading', ambulatoryBadgeColor }
  }

  return {
    ambulatoryStatus: getAmbulatoryStatus({
      person,
      facility: currentFacility.value,
      ambulatorySettings: {
        ambulatoryStatusSettings: settings.ambulatorySettingsOptions.map(
          (option) => ({
            title: option.label,
            ambulatoryStatus: option.value,
          })
        ),
      },
    }),
    ambulatoryBadgeColor,
  }
}
