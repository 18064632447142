import { ApiParams, fetchJson } from '@shared/api/request'
import { facilitySettingsByTypeUrl } from '@shared/api/urls'
import {
  OrgFacilitySettings,
  SettingsType,
} from '@shared/types/org_facility_settings'

export interface GetFacilitySettingsParams extends ApiParams {}

export async function getFacilitySettings(
  orgId: string,
  facilityId: string,
  settingsType: SettingsType,
  params: GetFacilitySettingsParams = {}
): Promise<OrgFacilitySettings> {
  return fetchJson<OrgFacilitySettings>(
    facilitySettingsByTypeUrl(orgId, facilityId, settingsType, params)
    // Remove casts once fetchJson is fully typed
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  ).then((response) => response.data) as Promise<OrgFacilitySettings>
}

export const getFacilityMarSettings = async (
  orgId: string,
  facilityId: string
): Promise<OrgFacilitySettings> => {
  return getFacilitySettings(
    orgId,
    facilityId,
    SettingsType.SETTINGS_TYPE_MAR_SETTINGS
  )
}
export const getFacilityAmbulatorySettings = async (
  orgId: string,
  facilityId: string
): Promise<OrgFacilitySettings> => {
  return getFacilitySettings(
    orgId,
    facilityId,
    SettingsType.SETTINGS_TYPE_AMBULATORY_SETTINGS
  )
}

export const getFacilityLoCSettings = async ({
  orgId,
  facilityId,
}: {
  orgId: string
  facilityId: string
}): Promise<OrgFacilitySettings> => {
  return getFacilitySettings(
    orgId,
    facilityId,
    SettingsType.SETTINGS_TYPE_LEVEL_OF_CARE_SETTINGS
  )
}

export const getFacilityDietSettings = async (
  orgId: string,
  facilityId: string
): Promise<OrgFacilitySettings> => {
  return getFacilitySettings(
    orgId,
    facilityId,
    SettingsType.SETTINGS_TYPE_DIET_SETTINGS
  )
}

export async function getFacilityAssessmentConfiguration(
  orgId: string,
  facilityId: string
): Promise<OrgFacilitySettings> {
  return getFacilitySettings(
    orgId,
    facilityId,
    SettingsType.SETTINGS_TYPE_APPRAISAL_SETTINGS
  )
}
