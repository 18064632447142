import { CategoryTasks, Routine, RoutineStatus } from '@shared/types/routine'
import { ServicePlan_PlanCategoryKey } from '@shared/types/service_plan'

export function getCategoryTasks(r: Routine) {
  return r.tasks ?? []
}

export function isDraftRoutine(r: Routine) {
  return r.status === RoutineStatus.ROUTINE_STATUS_DRAFT
}

export function isActiveRoutine(r: Routine) {
  return r.status === RoutineStatus.ROUTINE_STATUS_ACTIVE
}

export function isDiscardedRoutine(r: Routine) {
  return r.status === RoutineStatus.ROUTINE_STATUS_DISCARDED
}

export function isMorningOrBedtime(r: Routine) {
  return ['Morning', 'Bedtime'].includes(r.name ?? '')
}

export function isNotMorningOrBedtime(r: Routine) {
  return !isMorningOrBedtime(r)
}

// Omit routines that only have 'Objective Text' without any appraisal tasks, service plan tasks
export function routineHasTasks(r: Routine) {
  const categoryTasks = getCategoryTasks(r)

  return !categoryTasks.every(
    (t) =>
      t.appraisalTasks === undefined &&
      t.servicePlanTasks?.servicePlanOptions === undefined
  )
}

export function getCategoryKey(categoryTasks: CategoryTasks) {
  return (
    categoryTasks.category ??
    ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_UNSPECIFIED
  )
}
