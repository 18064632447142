import { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { Label } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { ErrorDropdownStyleConfig } from '@shared/components/Selects/utils'
import { GenericTooltip } from '@shared/components/Tooltips/GenericTooltip'
import { MedOrderFormData } from '@shared/types/medication_order'
import { UnitOfTime } from '@shared/types/timing'
import {
  validateEveryXPeriod,
  validateRequired,
} from '@shared/utils/formValidationFunctions'
import {
  CustomPeriodUnits,
  EVERY_BLANK_PERIOD_UNIT_OPTIONS,
} from '@shared/utils/medicationStatement'
import { tw } from '@shared/utils/tailwind'
import styles from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/styles.module.css'

type Props = {
  index: number
  readOnly: boolean
}
export const EveryBlankPeriod = ({ index, readOnly }: Props) => {
  const {
    register,
    unregister,
    formState,
    control,
    getValues,
    setValue,
    trigger,
  } = useFormContext<MedOrderFormData>()
  const [periodTooltip, setPeriodTooltip] = useState<string | null>(null)

  const variablePeriodUnitName = `doses.${index}.variablePeriodUnit` as const
  const periodName = `doses.${index}.period` as const

  useEffect(() => {
    return () => {
      if (getValues(`doses.${index}.periodUnit`) !== CustomPeriodUnits.EVERY) {
        unregister(`doses.${index}.period`)
        unregister(`doses.${index}.variablePeriodUnit`)
      }
    }
  }, [])

  useEffect(() => {
    if (
      formState.errors.doses?.[index]?.period?.message?.startsWith('Must be')
    ) {
      setPeriodTooltip(formState.errors.doses[index].period.message)
    } else {
      setPeriodTooltip(null)
    }
  }, [formState.errors.doses?.[index]?.period?.message ?? ''])

  return (
    <>
      <div className={tw`relative h-full max-h-full`}>
        {periodTooltip && (
          <GenericTooltip
            className={tw`absolute bottom-input w-fit whitespace-nowrap hover:opacity-80`}
            data-testid={'period-tooltip'}
          >
            {periodTooltip}
          </GenericTooltip>
        )}
        <Label visuallyHidden htmlFor={periodName} id={`label-${periodName}`}>
          Period
        </Label>
        <BasicInput
          type={'number'}
          aria-labelledby={`label-${periodName}`}
          {...register(periodName, {
            valueAsNumber: true,
            validate: (value, formValues) =>
              validateEveryXPeriod({
                value,
                periodUnit: formValues.doses?.[index]?.variablePeriodUnit,
              }),
          })}
          className={styles.period}
          showErrorBorder={!!formState.errors.doses?.[index]?.period?.message}
          placeholder={'# of...'}
          disabled={readOnly}
        />
      </div>
      <Controller
        control={control}
        name={variablePeriodUnitName}
        rules={{
          validate: validateRequired,
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <>
              <Label
                visuallyHidden
                htmlFor={variablePeriodUnitName}
                id={`label-${variablePeriodUnitName}`}
              >
                Variable Period Unit
              </Label>
              <StyledSelect
                aria-labelledby={`label-${variablePeriodUnitName}`}
                name={variablePeriodUnitName}
                id={variablePeriodUnitName}
                options={EVERY_BLANK_PERIOD_UNIT_OPTIONS}
                placeholder="Select..."
                onChange={(e: OptionTypeBase) => {
                  onChange(e.value)
                  if (e.value === UnitOfTime.UNIT_OF_TIME_HOUR) {
                    setValue(`doses.${index}.frequency`, 1)
                  }
                  if (formState.isSubmitted) {
                    void trigger(periodName)
                  }
                }}
                value={EVERY_BLANK_PERIOD_UNIT_OPTIONS.find(
                  (opt) => opt.value === value
                )}
                className={styles.periodUnit}
                styles={ErrorDropdownStyleConfig({ error: !!error })}
                isDisabled={readOnly}
              />
            </>
          )
        }}
      />
    </>
  )
}
