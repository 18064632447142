import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { useState } from 'react'
import { ClassNameValue } from 'tailwind-merge'
import { BasicCheckbox } from '@shared/components/BasicInput/BasicInput'
import SearchBox from '@shared/components/SearchBox'
import {
  PersonWithRoutineAdministrations,
  RoutineAdministrationProgressType,
} from '@shared/types/careapp'
import { Facility } from '@shared/types/facility'
import { tw, twx } from '@shared/utils/tailwind'
import BulkMarkAsAdministeredModal from '../Modals/BulkMarkAsAdministeredModal'
import BulkMarkAsExceptionModal from '../Modals/BulkMarkAsExceptionModal'
import { useProgressContext } from '../ProgressContext'
import { useSelectedRoutineAdministrationsContext } from '../SelectedRoutineAdministrationsContext'

function RoutineFilterButton({
  buttonText,
  count,
  active,
  primaryBgClassName,
  onClick,
}: {
  buttonText: string
  count: number
  active: boolean
  primaryBgClassName: ClassNameValue
  onClick: () => void
}) {
  return (
    <button
      onClick={onClick}
      disabled={count === 0 && !active}
      className={twx(
        'flex flex-row items-center justify-center gap-layout-gap-xxs',
        'rounded-[1.25rem] px-2 py-1',
        'text-sm font-medium text-gray-700',
        'transition-colors duration-200',
        active ? primaryBgClassName : 'bg-none'
      )}
    >
      {buttonText}
      <span
        className={twx(
          'bg-white/60 px-2 py-[0.125rem]',
          'text-xs font-bold text-[#0C2836]',
          'rounded-full',
          'transition-colors duration-200',
          active ? 'bg-white/60' : primaryBgClassName
        )}
      >
        {count}
      </span>
    </button>
  )
}

export default function RoutineFilterAndSearch({
  filteredPeopleWithAdministrations,
  facility,
  exceptionsCount,
  overdueCount,
  administeredCount,
  searchText,
  setSearchText,
}: {
  filteredPeopleWithAdministrations: PersonWithRoutineAdministrations[]
  facility: Facility
  exceptionsCount: number
  overdueCount: number
  administeredCount: number
  searchText: string
  setSearchText: (text: string) => void
}) {
  const { id: facilityId, orgId, timeZone } = facility

  const {
    selectedProgressTypes,
    toggleProgressType,
    reloadFacilityCareProgress,
  } = useProgressContext()
  const {
    selected: selectedAdministrations,
    clearSelected,
    setSelected,
  } = useSelectedRoutineAdministrationsContext()

  const exceptionsSelected = selectedProgressTypes.includes('Exception')
  const overdueSelected = selectedProgressTypes.includes('Overdue')
  const administeredSelected = selectedProgressTypes.includes('Administered')

  const [isBulkMarkExceptionModalOpen, setIsBulkmarkExceptionModalOpen] =
    useState<boolean>(false)
  const [isBulkMarkAdministeredModalOpen, setIsBulkMarkAdministeredModalOpen] =
    useState<boolean>(false)

  const handleOnClickFilter = (
    progressType: RoutineAdministrationProgressType
  ) => {
    toggleProgressType(progressType)
    clearSelected()
  }

  const peopleWithSelectedAdministrationsCount = new Set(
    selectedAdministrations.map(({ personId }) => personId)
  ).size

  const availableToSelect = filteredPeopleWithAdministrations.flatMap(
    ({ administrations }) => administrations
  )

  const allSelected =
    selectedAdministrations.length === availableToSelect.length &&
    availableToSelect.length > 0

  const toggleAllSelected = () =>
    allSelected ? clearSelected() : setSelected(availableToSelect)

  const handleOnChangeSelectAll = () => {
    toggleAllSelected()
  }

  const handleOnClickMarkAsAdministered = async () => {
    setIsBulkMarkAdministeredModalOpen(true)
  }

  const handleOnClickMarkAsException = async () => {
    setIsBulkmarkExceptionModalOpen(true)
  }

  const handleOnMarkAsExceptionSuccess = async () => {
    await reloadFacilityCareProgress()
  }

  const handleOnMarkAsAdministeredSuccess = async () => {
    await reloadFacilityCareProgress()
  }

  const noneSelected = selectedAdministrations.length === 0

  const showSelectAll = selectedProgressTypes.length === 1

  const dropdownOptions = buildOptions(selectedProgressTypes, {
    onClickMarkAsAdministered: handleOnClickMarkAsAdministered,
    onClickMarkAsException: handleOnClickMarkAsException,
  })
  const isDropdownDisabled = selectedAdministrations.length === 0

  const ariaChecked = allSelected ? 'true' : noneSelected ? 'false' : 'mixed'

  return (
    <div
      className={tw`mb-[24px] flex flex-row items-center justify-between rounded-lg bg-white p-3`}
    >
      <div className={tw`flex flex-row items-center gap-[12px]`}>
        {showSelectAll && (
          <BasicCheckbox
            labelClassName="mb-0 text-sm"
            onChange={handleOnChangeSelectAll}
            checked={allSelected}
            aria-label="Select all routine administrations"
            aria-checked={ariaChecked}
          >
            Select All
          </BasicCheckbox>
        )}

        <SimpleDropdown
          buttonText="Bulk Actions"
          options={dropdownOptions}
          disabled={isDropdownDisabled}
        />

        {isBulkMarkExceptionModalOpen && (
          <BulkMarkAsExceptionModal
            peopleCount={peopleWithSelectedAdministrationsCount}
            routineAdministrations={selectedAdministrations}
            onSubmitSuccess={handleOnMarkAsExceptionSuccess}
            onClose={() => setIsBulkmarkExceptionModalOpen(false)}
            facility={facility}
          />
        )}
        {isBulkMarkAdministeredModalOpen && (
          <BulkMarkAsAdministeredModal
            orgId={orgId}
            facilityId={facilityId}
            peopleCount={peopleWithSelectedAdministrationsCount}
            routineAdministrations={selectedAdministrations}
            onSubmitSuccess={handleOnMarkAsAdministeredSuccess}
            onClose={() => setIsBulkMarkAdministeredModalOpen(false)}
            facilityTimeZone={timeZone}
          />
        )}

        <RoutineFilterButton
          count={exceptionsCount}
          active={exceptionsSelected}
          onClick={() => handleOnClickFilter('Exception')}
          primaryBgClassName="bg-yellow-12"
          buttonText="Exceptions"
        />
        <RoutineFilterButton
          count={overdueCount}
          active={overdueSelected}
          onClick={() => handleOnClickFilter('Overdue')}
          primaryBgClassName="bg-tags-error-fill"
          buttonText="Overdue"
        />
        <RoutineFilterButton
          count={administeredCount}
          active={administeredSelected}
          onClick={() => handleOnClickFilter('Administered')}
          primaryBgClassName="bg-tags-happy-fill"
          buttonText="Completed"
        />
      </div>

      <SearchBox
        onChange={setSearchText}
        value={searchText}
        placeholder="Name or room..."
        holderClassName={tw`grow-0`}
      />
    </div>
  )
}

const buildOptions = (
  selectedProgressTypes: RoutineAdministrationProgressType[],
  {
    onClickMarkAsAdministered,
    onClickMarkAsException,
  }: {
    onClickMarkAsAdministered: () => Promise<void>
    onClickMarkAsException: () => Promise<void>
  }
) => {
  if (selectedProgressTypes.length === 1) {
    const progressType = selectedProgressTypes[0]

    switch (progressType) {
      case 'Administered':
        return [
          {
            label: 'Mark as Exception',
            onClick: onClickMarkAsException,
          },
        ]
      case 'Overdue':
        return [
          {
            label: 'Mark as Exception',
            onClick: onClickMarkAsException,
          },
          {
            label: 'Mark as Completed',
            onClick: onClickMarkAsAdministered,
          },
        ]
      case 'Exception':
        return [
          {
            label: 'Mark as Completed',
            onClick: onClickMarkAsAdministered,
          },
        ]
      case 'Unadministered':
        return []
    }
  } else {
    return []
  }
}

type SimpleDropdownOption = {
  label: string
  onClick: () => void
}

function SimpleDropdown({
  buttonText,
  options,
  disabled,
}: {
  buttonText: string
  options: SimpleDropdownOption[]
  disabled?: boolean
}) {
  const menuButtonClassName = twx(
    'rounded-md border border-gray-10 p-2.5 text-sm leading-none',
    { 'opacity-50': disabled }
  )

  return (
    <Menu>
      <MenuButton className={menuButtonClassName} disabled={disabled}>
        {buttonText}
        <span className={twx('fas fa-solid fa-caret-down ml-3 text-xs')} />
      </MenuButton>

      <MenuItems
        anchor={{
          to: 'bottom start',
          gap: '0.25rem',
        }}
        className={tw`rounded-lg border border-gray-10 bg-white p-1 shadow-popup`}
      >
        {options.map((option, idx) => {
          return (
            <MenuItem key={idx}>
              <button
                className={tw`block w-full rounded-lg px-4 py-2 text-left text-sm font-medium data-[focus]:bg-secondary-11`}
                onClick={option.onClick}
              >
                {option.label}
              </button>
            </MenuItem>
          )
        })}
      </MenuItems>
    </Menu>
  )
}
