import { uniq } from 'lodash'
import { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { fetchResidentCompletionStats } from '@shared/api/facility'
import { BasicCheckbox } from '@shared/components/BasicInput/BasicInput'
import ButtonLink from '@shared/components/ButtonLink'
import Card from '@shared/components/Card'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import { PersonSearchBox } from '@shared/components/PersonSearchBox'
import useLocalStorage from '@shared/hooks/useLocalStorage'
import {
  getComplianceUrl,
  getResidentCompletionUrl,
} from '@shared/legacy_routes'
import {
  CompletionType,
  ResidentCompletionStats,
} from '@shared/types/api/facility_stats'
import {
  CompletionTypeToTemplateHash,
  getLabelForCompletionType,
} from '@shared/utils/facilityStats'
import { getOrElse, Loading } from '@shared/utils/loading'
import { personNameMatchesSearch } from '@shared/utils/person'
import BreadcrumbEntry from '@app/components/Breadcrumbs/BreadcrumbEntry'
import breadcrumbStyles from '@app/components/Breadcrumbs/styles.module.css'
import Content from '@app/components/generic/Content'
import PieChartIcon, {
  strokeColorForPercent,
} from '@app/components/Residents/CompletionStats/PieChartIcon'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import usePersonTasks from '@app/hooks/usePersonTasks'
import styles from './styles.module.css'
import { percentageDataFromStats } from '../ResidentCompletionBanner'
import useCompletionStats from '../useCompletionStats'
import ResidentCompletionItems from './Items'

export default function ResidentCompletionList({
  match,
}: RouteComponentProps<{ orgId: string; facilityId: string }>) {
  const { currentFacility } = useCurrentFacility()
  const { orgId, facilityId } = match.params
  const {
    availableCompletionTasks,
    completionStats,
    completionTypeToTemplateHash = {} as CompletionTypeToTemplateHash,
    refreshStats,
  } = useCompletionStats({ orgId, facilityId })
  const { personTasks } = usePersonTasks(
    {
      orgId,
      facilityId,
    },
    [orgId, facilityId]
  )

  const [nameFilter, setNameFilter] = useState('')
  const [residentStats, setResidentStats] = useState<
    Loading<ResidentCompletionStats[]>
  >({ tag: 'Loading' })

  const [storedVisibleTasks, setVisibleTasks] = useLocalStorage<
    CompletionType[]
  >('visibleTasks', [])
  const visibleTasks = getOrElse(storedVisibleTasks, [])

  const [someTasksSelected, setSomeTasksSelected] = useState<boolean>(
    visibleTasks.length > 0
  )

  function toggleSelectedTasks() {
    if (someTasksSelected) {
      void setVisibleTasks([])
      setSomeTasksSelected(false)
    } else {
      if (availableCompletionTasks.tag === 'Complete') {
        void setVisibleTasks(availableCompletionTasks.value)
      }
      setSomeTasksSelected(true)
    }
  }

  useEffect(() => {
    setSomeTasksSelected(visibleTasks.length > 0)
  }, [visibleTasks])

  useEffect(() => {
    if (orgId && facilityId) {
      setResidentStats({ tag: 'Loading' })
      void fetchResidentCompletionStats({ facilityId, orgId }).then(
        (response) => setResidentStats({ tag: 'Complete', value: response })
      )
    }
  }, [orgId, facilityId])

  if (
    residentStats.tag === 'Loading' ||
    personTasks.tag === 'Loading' ||
    completionStats.tag === 'Loading' ||
    availableCompletionTasks.tag === 'Loading' ||
    currentFacility?.tag === 'Loading' ||
    currentFacility === undefined
  ) {
    return <SimpleSpinner />
  }

  const percentsForStats = percentageDataFromStats(
    completionStats.value,
    availableCompletionTasks.value
  )
  const avgPercent = averageCompletionPercent(percentsForStats)
  const filteredResidentStats = residentStats.value.filter((r) =>
    personNameMatchesSearch(r.person!, nameFilter)
  )

  return (
    <Content>
      <div className={styles.cardList}>
        <header
          className={`${breadcrumbStyles.breadcrumbsContainer} ${styles.breadcrumbHeader}`}
          style={{ margin: 0, paddingLeft: 0, paddingBottom: 0 }}
        >
          <div className={breadcrumbStyles.linksHolder}>
            <BreadcrumbEntry
              breadcrumb={{
                text: 'Compliance Dashboard',
                url: getComplianceUrl(orgId, facilityId),
              }}
              index={0}
            />
            <BreadcrumbEntry
              breadcrumb={{
                text: 'Resident Completion',
                url: getResidentCompletionUrl(orgId, facilityId),
              }}
              index={1}
            />
          </div>
        </header>
        <div className="ml-[8px] mr-[8px] flex items-center justify-between">
          <h1 className="text-[24px]">Resident Completion</h1>
          <PersonSearchBox onChange={setNameFilter} value={nameFilter} />
        </div>
        <Card className="flex-col">
          <div className="mb-[16px] flex items-center">
            <PieChartIcon percent={avgPercent} />
            <div className="mb-0 ml-[8px] text-[16px] font-semibold leading-[24px]">
              {currentFacility.value.name} is&nbsp;
              <span
                data-cy="facility-percent"
                style={{ color: strokeColorForPercent(avgPercent) }}
              >
                {avgPercent.toPrecision(3)}%&nbsp;
              </span>
              complete
            </div>
          </div>
          <div className={styles.checkboxGrid}>
            {availableCompletionTasks.value.map((task, index) => {
              const percentForTask = percentsForStats.find(
                (p) => p.completionType === task
              )!.percent
              return (
                <BasicCheckbox
                  data-cy={`task-checkbox-${index}`}
                  checked={visibleTasks.includes(task)}
                  key={`${task}-checkbox`}
                  labelClassName="mb-0 flex items-center"
                  onChange={(event) => {
                    void setVisibleTasks(
                      uniq(
                        event.currentTarget.checked
                          ? [...visibleTasks, task]
                          : [...visibleTasks.filter((t) => t !== task)]
                      )
                    )
                  }}
                >
                  <div className="text-[14px]">
                    {getLabelForCompletionType({
                      completionType: task,
                      completionTypeToTemplateHash,
                    })}
                    <span
                      className="ml-[8px]"
                      style={{
                        color: strokeColorForPercent(percentForTask),
                      }}
                    >
                      {percentForTask}%
                    </span>
                  </div>
                </BasicCheckbox>
              )
            })}
          </div>

          <div className={styles.checkboxToggler}>
            <ButtonLink
              onClick={toggleSelectedTasks}
              className={'text-[14px] font-medium leading-[16px]'}
            >
              Select {someTasksSelected ? 'none' : 'all'}
            </ButtonLink>
          </div>
        </Card>
        <ResidentCompletionItems
          filteredResidentStats={filteredResidentStats}
          visibleTasks={visibleTasks}
          personTasks={personTasks.value}
          availableCompletionTasks={availableCompletionTasks.value}
          refreshStats={refreshStats}
          completionTypeToTemplateHash={completionTypeToTemplateHash}
        />
      </div>
    </Content>
  )
}

const averageCompletionPercent = (
  facilityStats: { percent: number; completionType: CompletionType }[]
) => {
  const total = facilityStats.reduce(
    (
      totalCompletionPercent: number,
      stat: { percent: number; completionType: CompletionType }
    ) => {
      return totalCompletionPercent + stat.percent
    },
    0
  )
  return total / facilityStats.length
}
