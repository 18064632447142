import { AugustFieldType } from '@augusthealth/models/com/august/protos/custom_field'
import {
  ReportOnlyField,
  StringFilterOperation,
} from '@augusthealth/models/com/august/protos/report'
import { useState } from 'react'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import StyledSelect from '@shared/components/Selects/StyledSelect'
import styles from '../styles.module.css'
import { stringFilterOptions } from '../helpers'
import { ReportFields } from '../models'

export default function FieldFilter({
  filterField,
  setReport,
}: {
  filterField: AugustFieldType | ReportOnlyField | undefined
  setReport: React.Dispatch<React.SetStateAction<ReportFields>>
}) {
  const [filterOperator, setFilterOperator] = useState<StringFilterOperation>()

  const isNotAKeywordSearch =
    filterOperator ===
      StringFilterOperation.STRING_FILTER_OPERATION_NON_EMPTY ||
    filterOperator === StringFilterOperation.STRING_FILTER_OPERATION_IS_EMPTY

  const addFilterField = (newFilter) => {
    setReport((report) => ({
      fields: report.fields,
      orderByField: report.orderByField,
      fieldFilter: {
        ...report.fieldFilter,
        ...newFilter,
      },
    }))
  }

  return (
    <div className="flex">
      <div className="grow">
        <StyledSelect
          isClearable
          isDisabled={!filterField}
          placeholder="Filter by..."
          options={stringFilterOptions}
          onChange={(e: { value: StringFilterOperation }) => {
            setFilterOperator(e?.value)
            addFilterField({ operator: e?.value })
          }}
        />
      </div>
      <div className="ml-[12px]">
        <BasicInput
          className={styles.filterText}
          disabled={!filterField || !filterOperator || isNotAKeywordSearch}
          placeholder="Filter text..."
          onChange={(e) => addFilterField({ arg: e?.currentTarget?.value })}
        />
      </div>
    </div>
  )
}
