import { ServicePlanObjectivesGetResponse_Objective } from '@augusthealth/models/com/august/protos/api/service_plan_objectives'
import { AppraisalStatus } from '@augusthealth/models/com/august/protos/august_initial_appraisal'
import React from 'react'
import { Person } from '@shared/types/person'
import { Snapshot } from '@shared/types/snapshot'
import { Loading } from '@shared/utils/loading'
import { Action, initialState, State } from './stateManagement'

interface ServicePlanContextData {
  assessment: Loading<AppraisalStatus>
  refreshAssessment: () => Promise<void>
  servicePlan: Loading<Snapshot>
  state: State
  dispatch: React.Dispatch<Action>
  person: Person
  objectiveOptions: ServicePlanObjectivesGetResponse_Objective[]
}

export default React.createContext<ServicePlanContextData>({
  assessment: { tag: 'Loading' },
  refreshAssessment: () => Promise.resolve(),
  servicePlan: { tag: 'Loading' },
  state: initialState,
  dispatch: () => null,
  person: {} as Person,
  objectiveOptions: [],
})
