import { MedGroup } from '@augusthealth/models/com/august/protos/api/med_group'
import { CareGroup } from '@augusthealth/models/com/august/protos/care_group'
import classNames from 'classnames'
import { UseFormRegister } from 'react-hook-form'
import TextInputWithIcon from '@shared/components/TextInputWithIcon/TextInputWithIcon'
import { Facility } from '@shared/types/facility'
import { isDuplicateGroupName } from '@shared/utils/careGroup'
import styles from './styles.module.css'
import { FieldName, FormData } from './helpers'

export const PLACEHOLDER = 'Enter one per line...'

export default function GroupInput({
  deletingGroupId,
  facility,
  fieldName,
  group,
  list,
  i,
  ignoreValidation,
  onClickDelete,
  register,
}: {
  deletingGroupId: string | undefined
  facility: Facility
  fieldName: FieldName
  group: CareGroup | MedGroup
  list: CareGroup[] | MedGroup[]
  i: number
  ignoreValidation: boolean
  onClickDelete: () => Promise<void>
  register: UseFormRegister<FormData>
}) {
  const { name = '' } = group
  const facilityId = facility.id!

  const isDuplicate = isDuplicateGroupName({
    groups: list,
    index: i,
    name,
  })
  const deleteBtnClasses = classNames('fa-solid cursor-pointer', {
    'fa-spinner fa-spin':
      deletingGroupId !== undefined && deletingGroupId === group.id,
    'fa-trash': deletingGroupId === undefined || deletingGroupId !== group.id,
  })
  let warning
  if (!ignoreValidation) {
    if (!name?.trim()) {
      warning = <span className={styles.warning}>name is required</span>
    } else if (isDuplicate) {
      warning = <span className={styles.warning}>name is already taken</span>
    }
  }

  return (
    <div key={`${fieldName}-${i}`}>
      <input
        {...register(`${fieldName}.${i}.facilityId`)}
        type="hidden"
        value={facilityId}
      />
      <TextInputWithIcon
        key={`${fieldName}.${i}.name-input`}
        holderClassName="mb-[8px]"
        iconClassName="fa-solid fa-people-group"
        inputProps={{
          ...register(`${fieldName}.${i}.name`, {
            required: !ignoreValidation,
          }),
          placeholder: PLACEHOLDER,
          value: name,
        }}
      >
        <div className={styles.iconHolder}>
          {warning}
          <i
            data-testid={`delete-${fieldName}-${i}`}
            className={deleteBtnClasses}
            onClick={onClickDelete}
          />
        </div>
      </TextInputWithIcon>
    </div>
  )
}
