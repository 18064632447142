import { RegisterOptions } from 'react-hook-form'
import { Label } from '@shared/components/Labels'
import { twx } from '@shared/utils/tailwind'

export enum SwitchSize {
  small = 'small',
  medium = 'medium',
}

export type SwitchProps = {
  name: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  checked?: boolean
  disabled?: boolean
  label?: string
  labelPosition?: 'left' | 'right'
  /** Useful to add specific CSS, for example, inside an input */
  holderClassName?: string
  labelClassName?: string
  color?: 'primary' | 'alert'
  size?: SwitchSize
  inputRef?: any
  value?: any
  id?: any
}

export function Switch({
  name,
  checked = false,
  onChange,
  disabled,
  label,
  labelPosition = 'right',
  holderClassName,
  labelClassName,
  color = 'primary',
  size = SwitchSize.medium,
  inputRef,
}: SwitchProps) {
  const labelClasses = twx(
    'cursor-pointer text-gray-03',
    {
      ['font-semibold mr-[16px]']: size === SwitchSize.medium,
      ['text-[14px] leading-[16px] font-medium ml-[12px]']:
        size === SwitchSize.small,
    },
    labelClassName
  )
  const inputClasses = twx(
    'appearance-none cursor-pointer block text-transparent mb-0 select-none',
    'disabled:before:opacity-50 disabled:after:opacity-50',
    'before:content-[" "] before:block before:absolute before:cursor-pointer before:w-full before:h-full before:bg-[#ccc9c7] before:rounded-[9999em] before:transition-[background-color] before:duration-[0.25s]',
    'after:content-[" "] after:block after:absolute after:cursor-pointer after:rounded-[50%] after:bg-white after:shadow-[0,0,2px,rgba(0,0,0,0.45)] after:transition-[left] after:duration-[0.25s]',
    {
      ['before:checked:bg-button-primary-color']: color === 'primary',
      ['before:checked:bg-alert']: color === 'alert',
      ['w-[48px] h-[24px] after:top-[4px] after:left-[4px] after:w-[16px] after:h-[16px] after:checked:left-[28px]']:
        size === SwitchSize.medium,
      ['w-[32px] h-[16px] after:top-[2px] after:left-[2px] after:w-[12px] after:h-[12px] after:checked:left-[18px]']:
        size === SwitchSize.small,
    }
  )

  const component = (
    <div className={twx('relative inline-block h-fit')}>
      <input
        id={name}
        onChange={onChange}
        checked={checked}
        ref={inputRef}
        disabled={disabled}
        name={name}
        type="checkbox"
        className={inputClasses}
        data-cy={`switch-${name}`}
      />
      {!label && (
        <Label htmlFor={name} visuallyHidden>
          {name}
        </Label>
      )}
    </div>
  )

  if (label) {
    return (
      <div
        className={twx(
          'flex flex-row',
          {
            'flex-row-reverse justify-end': labelPosition === 'right',
          },
          holderClassName
        )}
      >
        {label && (
          <label className={labelClasses} htmlFor={name}>
            {label}
          </label>
        )}
        {component}
      </div>
    )
  }

  return component
}

interface RHFSwitchProps extends React.HTMLProps<HTMLInputElement> {
  /** Useful to add specific CSS, for example, inside an input */
  holderClassName?: string
  color?: 'primary' | 'alert'
  switchSize?: 'small' | 'medium'
  inputRef?: any
  /** RHF v7 register **/
  register?: any
  labelPosition?: 'left' | 'right'
  registerOptions?: RegisterOptions
}

/* Our 'Switch' component, but specialized to work with react-hook-form */
/* TODO: Unify this with the above <Switch /> - I ran into issues, so deferring for now */

export function RHFSwitch({
  name,
  label,
  holderClassName = '',
  color = 'primary',
  switchSize = 'medium',
  inputRef,
  id,
  labelPosition = 'right',
  registerOptions = {},
  ...rest
}: RHFSwitchProps) {
  const labelClasses = twx('cursor-pointer text-gray-03 mb-0', {
    ['font-semibold mr-[16px]']: switchSize === 'medium',
    ['text-[14px] leading-[16px] font-medium ml-[12px]']:
      switchSize === 'small',
  })
  const inputClasses = twx(
    'appearance-none cursor-pointer block text-transparent mb-0 select-none',
    'disabled:before:opacity-50 disabled:after:opacity-50',
    'before:content-[" "] before:block before:absolute before:cursor-pointer before:w-full before:h-full before:bg-[#ccc9c7] before:rounded-[9999em] before:transition-[background-color] before:duration-[0.25s]',
    'after:content-[" "] after:block after:absolute after:cursor-pointer after:rounded-[50%] after:bg-white after:shadow-[0,0,2px,rgba(0,0,0,0.45)] after:transition-[left] after:duration-[0.25s]',
    {
      ['before:checked:bg-button-primary-color']: color === 'primary',
      ['before:checked:bg-alert']: color === 'alert',
      ['w-[48px] h-[24px] after:top-[4px] after:left-[4px] after:w-[16px] after:h-[16px] after:checked:left-[28px]']:
        switchSize === 'medium',
      ['w-[32px] h-[16px] after:top-[2px] after:left-[2px] after:w-[12px] after:h-[12px] after:checked:left-[18px]']:
        switchSize === 'small',
    }
  )
  const holderClasses = twx(
    'flex flex-row items-center',
    {
      'flex-row-reverse justify-end': labelPosition === 'right',
    },
    holderClassName
  )

  const inputProps = Object.assign({
    ...rest,
    id,
    ['data-testid']: id,
    type: 'checkbox',
    className: inputClasses,
    ['aria-labelledby']: label ? `${id}-label` : undefined,
    // register is an invalid input prop and should not be included
    register: undefined,
    ...(!rest.register && { name }),
    ...(!!rest.register && { ...rest.register(name, registerOptions) }),
  })

  return (
    <div className={holderClasses}>
      {label && (
        <label className={labelClasses} id={`${id}-label`} htmlFor={id}>
          {label}
        </label>
      )}
      <div className={twx('relative inline-block h-fit')}>
        <input {...inputProps} />
      </div>
    </div>
  )
}
