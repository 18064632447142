import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { PayerWithScheduledPayment } from '@shared/types/billing'
import { Contact_BillingStatementMethod } from '@shared/types/contact'
import { tw } from '@shared/utils/tailwind'

type Props = {
  settings: PayerWithScheduledPayment
  submitFn: (data: PayerWithScheduledPayment) => Promise<void>
}

export default function StatementDeliveryPreference({
  settings,
  submitFn,
}: Props) {
  const { handleSubmit, control, watch } = useForm<PayerWithScheduledPayment>({
    defaultValues: settings,
  })

  useEffect(() => {
    const subscription = watch(() => void handleSubmit(submitFn)())
    return () => subscription.unsubscribe()
  }, [handleSubmit, watch])

  const deliveryOptions: OptionTypeBase<
    Contact_BillingStatementMethod,
    string
  >[] = [
    {
      value: Contact_BillingStatementMethod.BILLING_STATEMENT_METHOD_EMAIL,
      label: 'Email',
    },
    {
      value: Contact_BillingStatementMethod.BILLING_STATEMENT_METHOD_MAIL,
      label: 'Mail',
    },
    {
      value: Contact_BillingStatementMethod.BILLING_STATEMENT_METHOD_PRINT,
      label: 'In Room',
    },
  ]

  return (
    <div className={tw`flex w-full items-center justify-between p-4`}>
      <span className={tw`text-sm font-semibold text-gray-04`}>
        Statement delivery preference
      </span>
      <form
        data-testid="statement-delivery-form"
        onSubmit={handleSubmit(submitFn)}
      >
        <Controller
          control={control}
          name="paymentSettings.statementDelivery"
          render={({ field: { onChange, value } }) => {
            return (
              <StyledSelect
                defaultValue={deliveryOptions.find((o) => o.value === value)}
                placeholder="Select a delivery method"
                aria-label="statementDelivery"
                name="statementDelivery"
                isClearable={false}
                value={deliveryOptions.find((o) => o.value === value)}
                onChange={(option: OptionTypeBase | null) => {
                  onChange(option?.value)
                }}
                options={deliveryOptions}
                className={tw`w-52`}
              />
            )
          }}
        />
      </form>
    </div>
  )
}
