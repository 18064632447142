import {
  ChargeType,
  Recurring_Rate_RateChangeReason,
} from '@shared/types/charge'

export function nameForChargeType(chargeType: ChargeType) {
  switch (chargeType) {
    case ChargeType.CHARGE_TYPE_ADJUSTMENT_ACCIDENTAL_CHARGE:
      return 'Accidental charge'
    case ChargeType.CHARGE_TYPE_ADJUSTMENT_APPOINTMENT_CANCELLED:
      return 'Appointment got cancelled'
    case ChargeType.CHARGE_TYPE_APPLICATION_FEE:
      return 'Application Fee/Community Fee'
    case ChargeType.CHARGE_TYPE_DAILY_RATE:
      return 'Daily Rate'
    case ChargeType.CHARGE_TYPE_DEPOSIT:
      return 'Deposit'
    case ChargeType.CHARGE_TYPE_MASSAGE_WELLNESS:
      return 'Massage/Wellness'
    case ChargeType.CHARGE_TYPE_FOOD:
      return 'Food'
    case ChargeType.CHARGE_TYPE_HAIRCUT_GROOMING:
      return 'Haircut/Grooming'
    case ChargeType.CHARGE_TYPE_MONTHLY_RATE:
      return 'Monthly Rate'
    case ChargeType.CHARGE_TYPE_HOSPITAL_STAY:
      return 'Hospital stay'
    case ChargeType.CHARGE_TYPE_OTHER:
      return 'Other'
    case ChargeType.CHARGE_TYPE_CARE_RATE:
      return 'Care Rate'
    case ChargeType.CHARGE_TYPE_UNSPECIFIED:
      return 'Unspecified'
    case ChargeType.UNRECOGNIZED:
      return 'Unrecognized'
  }
}

export function iconForChargeType(chargeType: ChargeType) {
  switch (chargeType) {
    case ChargeType.CHARGE_TYPE_APPLICATION_FEE:
      return 'fa-file-invoice'
    case ChargeType.CHARGE_TYPE_DAILY_RATE:
      return 'fa-bed-alt'
    case ChargeType.CHARGE_TYPE_MONTHLY_RATE:
      return 'fa-bed-alt'
    case ChargeType.CHARGE_TYPE_DEPOSIT:
      return 'fa-money-check'
    case ChargeType.CHARGE_TYPE_FOOD:
      return 'fa-utensils-alt'
    case ChargeType.CHARGE_TYPE_HAIRCUT_GROOMING:
      return 'fa-cut'
    case ChargeType.CHARGE_TYPE_MASSAGE_WELLNESS:
      return 'fa-spa'
    case ChargeType.CHARGE_TYPE_OTHER:
      return 'fa-sticky-note'
    default:
      return 'fa-exclamation-circle'
  }
}

export function centsToMoney(amountInCents: number) {
  return (amountInCents / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
}

export function nameForRateChange(reason: Recurring_Rate_RateChangeReason) {
  switch (reason) {
    case Recurring_Rate_RateChangeReason.RATE_CHANGE_REASON_ANNUAL_INCREASE:
      return 'Annual increase'
    case Recurring_Rate_RateChangeReason.RATE_CHANGE_REASON_INITIAL_RATE:
      return 'Initial rate'
    case Recurring_Rate_RateChangeReason.RATE_CHANGE_REASON_LEVEL_OF_CARE_ADJUSTMENT:
      return 'Level of care adjustment'
    case Recurring_Rate_RateChangeReason.RATE_CHANGE_REASON_OTHER:
      return 'Other'
    case Recurring_Rate_RateChangeReason.RATE_CHANGE_REASON_UNSPECIFIED:
      return 'Unspecified'
    case Recurring_Rate_RateChangeReason.UNRECOGNIZED:
      return 'Unrecognized'
  }
}
