import { useContext, useEffect, useState } from 'react'
import {
  getPharmacyMessages,
  GetPharmacyParameters,
  processPharmacyMessage,
} from '@shared/api/pharmacy_message'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import Card from '@shared/components/Card'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { PharmacyMessagePayload } from '@shared/types/api/pharmacy_message'
import { getOrElse, Loading } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import HUD from '@app/components/HUD'
import {
  createQueueMessage,
  pharmacyMessageToLabel,
} from '@app/pages/Tools/PharmacyTools/ReprocessMessage/helpers'
import styles from '@app/pages/Tools/ResidentDocumentUploader/styles.module.css'
import { OrgFacilityPersonSelects } from '@app/pages/Tools/SharedSelects/OrgFacilityPersonSelects'
import useOrgFacilityPersonSelects from '@app/pages/Tools/SharedSelects/useOrgFacilityPersonSelects'

const ReprocessMessage = () => {
  const { setError } = useContext(GlobalContext)

  const {
    selectedOrganization,
    selectedFacility,
    selectedPerson,
    onOrgSelect,
    onFacilitySelect,
    onPersonSelect,
  } = useOrgFacilityPersonSelects()

  const orgId = selectedOrganization?.value.id
  const facilityId = selectedFacility?.value.id
  const personId = selectedPerson?.value.id

  const [pharmacyMessages, setPharmacyMessages] = useState<
    Loading<PharmacyMessagePayload[]>
  >({ tag: 'Loading' })

  const [selectedPharmacyMessage, setSelectedPharmacyMessage] =
    useState<OptionTypeBase<PharmacyMessagePayload> | null>(null)

  const [processedMessages, setProcessedMessages] = useState<
    PharmacyMessagePayload[]
  >([])

  const [messageProcessed, setMessageProcessed] = useState<boolean>(false)

  const fetchMessagesAndAddToState = async (props: GetPharmacyParameters) => {
    try {
      const messages = await getPharmacyMessages(props)
      setPharmacyMessages({ tag: 'Complete', value: messages })
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    setPharmacyMessages({ tag: 'Loading' })
    if (orgId && facilityId) {
      void fetchMessagesAndAddToState({ orgId, facilityId, personId })
    }
    setProcessedMessages([])
  }, [orgId, facilityId, personId])

  const onClick = async () => {
    if (selectedPharmacyMessage) {
      try {
        const message = createQueueMessage(selectedPharmacyMessage.value)
        await processPharmacyMessage(message)
        setMessageProcessed(true)
        setSelectedPharmacyMessage(null)
        setProcessedMessages([
          ...processedMessages,
          selectedPharmacyMessage.value,
        ])
      } catch (error) {
        setError(error)
      }
    }
  }

  const pharmacyMessageOptions = getOrElse(pharmacyMessages, []).map((f) => ({
    label: pharmacyMessageToLabel(f),
    value: f,
  }))

  return (
    <div className="content-holder">
      <h4 className="page-title mt-[24px]">Reprocess Pharmacy Messages</h4>
      <div className={tw`flex flex-row gap-4`}>
        <Card className="mb-[16px] flex flex-1 flex-col gap-4">
          <OrgFacilityPersonSelects
            onOrgSelect={onOrgSelect}
            onFacilitySelect={onFacilitySelect}
            onPersonSelect={onPersonSelect}
            selectedOrganization={selectedOrganization}
            selectedFacility={selectedFacility}
            selectedPerson={selectedPerson}
            orgLabel={'I want to reprocess a pharmacy message for...'}
          />

          <LabelAboveInput
            className={tw`pt-input`}
            htmlFor={'messageToReprocess'}
          >
            Message to reprocess
          </LabelAboveInput>
          <StyledSelect
            placeholder={'Select message to reprocess'}
            value={selectedPharmacyMessage}
            options={pharmacyMessageOptions}
            name="messageToReprocess"
            id="messageToReprocess"
            instanceId="messageToReprocess"
            onChange={(o: OptionTypeBase<PharmacyMessagePayload>) =>
              setSelectedPharmacyMessage(o)
            }
            isDisabled={pharmacyMessages.tag === 'Loading'}
          />

          <AsyncIconButton
            buttonStyle={'primary-fill'}
            onClick={onClick}
            type={'button'}
            isLoading={false}
            className={styles.button}
            disabled={!selectedPharmacyMessage}
          >
            Reprocess
          </AsyncIconButton>
          {messageProcessed && (
            <HUD
              onExpire={() => setMessageProcessed(false)}
              milliseconds={1000}
            >
              Pharmacy message added to queue for reprocessing
            </HUD>
          )}
        </Card>
        <div className={tw`flex-1`}>
          {processedMessages.length > 0 && (
            <Card className={tw`mb-[16px] flex flex-col gap-4`}>
              <div
                className={tw`text-[14px] font-semibold uppercase leading-[18px] text-heading-font-color`}
              >
                Processed Messages
              </div>
              {processedMessages.map((message, index) => {
                return (
                  <div
                    key={`${message.id}-${index}`}
                    className={tw`text-[14px] text-gray-04`}
                  >
                    {pharmacyMessageToLabel(message)}
                  </div>
                )
              })}
            </Card>
          )}
        </div>
      </div>
    </div>
  )
}

export default ReprocessMessage
