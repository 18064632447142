import { UseFormRegisterReturn } from 'react-hook-form'
import Icon from '@shared/components/Icon'
import { RoutineAdministrationExceptionReason } from '@shared/types/careapp'
import { getReasonForExceptionDisplay } from '@shared/utils/careapp'
import { tw, twx } from '@shared/utils/tailwind'
import { CardButton, CardRadioButton, IconAndLabel } from './BaseButtons'

type Props = {
  inputProps: UseFormRegisterReturn<'exceptionReason'>
  radioInputClassName?: string
}

function ExceptionReasonRadioButton({
  reason,
  iconName,
  inputProps,
  radioInputClassName,
}: Props & {
  reason: RoutineAdministrationExceptionReason
  iconName: string
}) {
  const label = getReasonForExceptionDisplay(reason)

  return (
    <CardRadioButton
      value={reason}
      className={twx(radioInputClassName)}
      iconName={iconName}
      label={label}
      {...inputProps}
    />
  )
}

export function ResidentUnavailableExceptionRadioButton({
  inputProps,
  radioInputClassName,
}: Props) {
  return (
    <ExceptionReasonRadioButton
      inputProps={inputProps}
      reason={
        RoutineAdministrationExceptionReason.ROUTINE_ADMINISTRATION_EXCEPTION_REASON_RESIDENT_UNAVAILABLE
      }
      iconName="location-question"
      radioInputClassName={radioInputClassName}
    />
  )
}

export function RescheduleExceptionRadioButton({
  inputProps,
  radioInputClassName,
}: Props) {
  return (
    <ExceptionReasonRadioButton
      inputProps={inputProps}
      reason={
        RoutineAdministrationExceptionReason.ROUTINE_ADMINISTRATION_EXCEPTION_REASON_RESCHEDULE_REQUESTED
      }
      iconName="refresh"
      radioInputClassName={radioInputClassName}
    />
  )
}

export function RescheduleForDateAndTimeButton({
  onClick,
}: {
  onClick: () => void
}) {
  return (
    <CardButton
      onClick={onClick}
      className={twx('flex items-center justify-between')}
    >
      Select Date + Time
      <Icon
        name="chevron-right"
        className={tw`font-normal text-secondary-07`}
      />
    </CardButton>
  )
}

export function RequestedRescheduleExceptionButton({
  onClick,
}: {
  onClick: () => void
}) {
  return (
    <CardButton
      onClick={onClick}
      className={twx('flex items-center justify-between')}
    >
      <IconAndLabel iconName={'refresh'} label={'Reschedule requested'} />
      <Icon
        name="chevron-right"
        className={tw`font-normal text-secondary-07`}
      />
    </CardButton>
  )
}

export function ResidentRefusedExceptionRadioButton({
  inputProps,
  radioInputClassName,
}: Props) {
  return (
    <ExceptionReasonRadioButton
      inputProps={inputProps}
      reason={
        RoutineAdministrationExceptionReason.ROUTINE_ADMINISTRATION_EXCEPTION_REASON_RESIDENT_REFUSED
      }
      iconName="ban"
      radioInputClassName={radioInputClassName}
    />
  )
}
