import classNames from 'classnames'
import { MedicationOrder } from '@shared/types/medication_order'
import { formatDateTime } from '@shared/utils/date'
import { isCommunityManaged } from '@shared/utils/medicationStatement'
import { tw } from '@shared/utils/tailwind'
import MedMetadata from '@app/components/Residents/Medications/Orders/MedicationOrderDetails/MedMetadata'
import styles from '@app/components/Residents/Medications/Orders/styles.module.css'
import { useCurrentFacility } from '@app/hooks/useFacilities'

type Props = {
  order: MedicationOrder
  className?: string
  renderMode: 'list' | 'modal'
}
export const WeakMatchOrderFooter = ({
  order,
  className,
  renderMode,
}: Props) => {
  const {
    settings: { use24HourClock },
    convertToFacilityTimezone,
  } = useCurrentFacility()
  const containerClasses = classNames(styles.orderFooter, className)
  const medStatement = order.medicationStatement

  const shownInList = renderMode === 'list'
  const fallbackValue = shownInList ? undefined : 'Not Specified'

  const profiled = order.sourceData?.profileOnly ?? false
  const communityManaged = isCommunityManaged(order)

  return (
    <div className={containerClasses}>
      {!shownInList && (
        <div className={styles.footerMetadata}>
          <div className={styles.footerMedInfo}>
            <MedMetadata
              value={medStatement.rxNumber ?? fallbackValue}
              iconClasses={'fa-prescription'}
              testId={'rxNumber'}
              underline={true}
            />
            <MedMetadata
              value={medStatement.pharmacy ?? fallbackValue}
              iconClasses={'fa-house-medical'}
              testId={'pharmacy'}
              underline={true}
              valueSuffix={profiled ? '(profiled)' : undefined}
              suffixClassName={tw`lowercase`}
            />
            <MedMetadata
              value={medStatement.prescriber?.toLowerCase() ?? fallbackValue}
              iconClasses={'fa-user-doctor-hair-long'}
              testId={'prescriber'}
              underline={true}
            />
            {communityManaged && (
              <MedMetadata
                value={'Community managed'}
                iconClasses={'fa-hands-holding-diamond'}
                testId={'community-managed'}
              />
            )}
          </div>
        </div>
      )}

      {shownInList && order.sourceData?.receivedAt && (
        <div>
          Received on{' '}
          {formatDateTime(
            convertToFacilityTimezone(order.sourceData.receivedAt),
            {
              includeDate: true,
              use24HourClock,
            }
          )}
        </div>
      )}
    </div>
  )
}
