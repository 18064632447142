import {
  Group,
  GroupType,
} from '@augusthealth/models/com/august/protos/permission'
import {
  admissionTasksPathForPerson,
  detailsPathForPerson,
} from '@shared/legacy_routes'
import { CARE_APP_ROUTE_BASE_PATH } from '@shared/routes'
import { ResidentStatus } from '@shared/types/person'
import { UserAccount } from '@shared/types/user'
import { isSuperUser } from '@shared/utils/user'

const facilityGroups = [
  GroupType.GROUP_TYPE_NURSE,
  GroupType.GROUP_TYPE_FACILITY_DIRECTOR,
  GroupType.GROUP_TYPE_MED_TECH,
  GroupType.GROUP_TYPE_MED_TECH_SUPERVISOR,
  GroupType.GROUP_TYPE_BILLING,
  GroupType.GROUP_TYPE_PHARMACIST,
  GroupType.GROUP_TYPE_FACILITY_STAFF,
  GroupType.GROUP_TYPE_FACILITY_SALES_AND_MARKETING,
  GroupType.GROUP_TYPE_SALES_AND_MARKETING_PLUS_BILLING,
  GroupType.GROUP_TYPE_FRONT_DESK,
  GroupType.GROUP_TYPE_CAREGIVER_PLUS_EHR,
]

const prospectGroups = [
  GroupType.GROUP_TYPE_RESPONSIBLE_PARTY,
  GroupType.GROUP_TYPE_PAYER,
]

const userManagementGroups = [
  GroupType.GROUP_TYPE_ORG_USER_MANAGEMENT,
  GroupType.GROUP_TYPE_FACILITY_USER_MANAGEMENT,
]

/**
 * Determines where a user should be routed to given an orgId
 * Used for users with multiple groups, who have access to /orgs
 * @param user
 * @param orgId
 */
export default function rootRouteForUser(user: UserAccount, orgId: string) {
  if (isSuperUser(user)) {
    return facilitiesRoute(user, orgId)
  }

  const groups = user.groups ?? []
  const relevantGroup = groups.find(
    (group) =>
      group.personMatcher?.organizationId === orgId &&
      group.groupType !== GroupType.GROUP_TYPE_DEMO_USER
  )

  if (relevantGroup === undefined) {
    throw new Error(`User ${user.id} is not a member of org ${orgId}`)
  }

  if (facilityGroups.includes(relevantGroup.groupType!)) {
    return facilityRoute(user, orgId)
  }

  if (relevantGroup.groupType === GroupType.GROUP_TYPE_SOCIAL_WORKER) {
    return personDetailsRoute(user, orgId)
  }

  if (prospectGroups.includes(relevantGroup.groupType!)) {
    return prospectRoute(user, orgId)
  }

  if (relevantGroup.groupType === GroupType.GROUP_TYPE_ORGANIZATION_ADMIN) {
    return facilitiesRoute(user, orgId)
  }

  if (userManagementGroups.includes(relevantGroup.groupType!)) {
    return orgSettingsUsersRoute(user, orgId)
  }

  if (relevantGroup.groupType === GroupType.GROUP_TYPE_CAREGIVER) {
    return CARE_APP_ROUTE_BASE_PATH
  }

  if (relevantGroup.groupType === GroupType.GROUP_TYPE_CUSTOM_GROUP) {
    // TODO: Figure out custom group routing
    return '/'
  }

  // If none of the conditions matched, throw an error
  throw new Error(`No matching groupType for ${relevantGroup.groupType}`)
}

const determineFirstMatchingGroupForUser = ({
  groupTypes,
  user,
  orgId,
}: {
  groupTypes: GroupType[]
  user: UserAccount
  orgId?: string
}): Group | undefined => {
  return user.groups?.find(isMatchingGroup({ groupTypes, orgId }))
}

const isMatchingGroup =
  ({ groupTypes, orgId }: { groupTypes: GroupType[]; orgId?: string }) =>
  (group: Group): boolean => {
    const matchesOrgId = orgId
      ? group.personMatcher!.organizationId === orgId
      : true

    const matchesGroupType = groupTypes.some((g) => g === group.groupType)

    return matchesOrgId && matchesGroupType
  }

export function personDetailsRoute(user: UserAccount, orgId?: string) {
  const group = determineFirstMatchingGroupForUser({
    groupTypes: [GroupType.GROUP_TYPE_SOCIAL_WORKER],
    orgId,
    user,
  })

  if (group) {
    return detailsPathForPerson({
      id: group.personMatcher?.personId || '',
      facilityId: group.personMatcher?.facilityId || '',
      orgId: group.personMatcher?.organizationId || '',
      residentStatus: ResidentStatus.RESIDENT_STATUS_PROSPECT,
    })
  }

  throw new Error('No route matches for user!')
}

export function facilityRoute(user: UserAccount, orgId?: string) {
  const group = determineFirstMatchingGroupForUser({
    groupTypes: facilityGroups,
    user,
    orgId,
  })

  if (group) {
    return `/orgs/${group.personMatcher!.organizationId}/facilities/${
      group.personMatcher!.facilityId
    }`
  }

  throw new Error('No route matches for user!')
}

export function prospectRoute(user: UserAccount, orgId?: string) {
  const group = determineFirstMatchingGroupForUser({
    groupTypes: prospectGroups,
    user,
    orgId,
  })

  if (group) {
    return `/orgs/${group.personMatcher!.organizationId}/facilities/${
      group.personMatcher!.facilityId
    }/prospects/${group.personMatcher!.personId}`
  }

  throw new Error('No route matches for user!')
}

/**
 * Redirect user for first login or from login email after creation
 * @param user
 * @param orgId
 * @returns string
 */
export function externalSignerRoute(user: UserAccount, orgId?: string) {
  const group = determineFirstMatchingGroupForUser({
    groupTypes: [GroupType.GROUP_TYPE_EXTERNAL_CONTACT],
    user,
    orgId,
  })

  if (group) {
    return `/orgs/${group.personMatcher!.organizationId}/facilities/${
      group.personMatcher!.facilityId
    }/person/${group.personMatcher!.personId}/signDocuments`
  }

  throw new Error('No route matches for user!')
}

export function admissionTasksRouteForPayer(user: UserAccount) {
  const group = determineFirstMatchingGroupForUser({
    groupTypes: [GroupType.GROUP_TYPE_PAYER],
    user,
  })

  if (group) {
    return admissionTasksPathForPerson({
      id: group.personMatcher?.personId ?? '',
      facilityId: group.personMatcher?.facilityId ?? '',
      orgId: group.personMatcher?.organizationId ?? '',
    })
  }

  throw new Error('No route matches for user!')
}

export function facilitiesRoute(user: UserAccount, orgId?: string) {
  let orgIdToUse: string | undefined
  const isSuper = isSuperUser(user)

  if (isSuper) {
    orgIdToUse = orgId
  } else {
    const group = determineFirstMatchingGroupForUser({
      groupTypes: [GroupType.GROUP_TYPE_ORGANIZATION_ADMIN],
      user,
      orgId,
    })
    orgIdToUse = group?.personMatcher?.organizationId
  }

  if (orgIdToUse) {
    return `/orgs/${orgIdToUse}/facilities`
  }

  throw new Error('No route matches for user!')
}

export const orgSettingsUsersRoute = (
  user: UserAccount,
  orgId?: string
): string => {
  const group = determineFirstMatchingGroupForUser({
    groupTypes: userManagementGroups,
    user,
    orgId,
  })

  if (group) {
    return `/orgs/${group.personMatcher!.organizationId}/settings/users`
  }

  throw new Error('No route matches for user!')
}
