import { OccupancyStats } from '@augusthealth/models/com/august/protos/api/occupancy'
import React from 'react'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'

export type FacilitiesContextProps = {
  occupancyStats: OccupancyStats | undefined
  setOccupancyStats: (stats: OccupancyStats | undefined) => void
  organizations: Organization[] | undefined
  setOrganizations: (org: Organization[] | undefined) => void
  facilities: Facility[] | undefined
  setFacilities: (facilities: Facility[] | undefined) => void
}

export const defaultProps: FacilitiesContextProps = {
  occupancyStats: undefined,
  setOccupancyStats: () => {},
  organizations: [],
  setOrganizations: () => {},
  facilities: [],
  setFacilities: () => {},
}

export default React.createContext(defaultProps)
