import {
  Contact,
  Contact_ContactRelationship as CR,
} from '@shared/types/contact'
import { ContactPoint_ContactPointSystem } from '@shared/types/contact_point'
import { getFullName } from '@shared/utils/humanName'

export function paymentMethod(contact: Contact) {
  return contact.externalFields?.rainforestPaymentMethod?.methodType
}

export function isResponsiblePerson(contact: Contact) {
  return (
    contact.relationship &&
    contact.relationship.includes(CR.CONTACT_RELATIONSHIP_RESPONSIBLE_PERSON)
  )
}

export function isPrimaryCareProvider(contact: Contact) {
  return (contact.relationship || []).includes(
    CR.CONTACT_RELATIONSHIP_GENERAL_PRACTITIONER
  )
}

export function firstLastName(contact: Contact) {
  return contact.name ? getFullName(contact.name) : ''
}

export function primaryPhone(contact: Contact) {
  const phones =
    contact.telecom?.filter((telecom) => {
      return (
        telecom.system ===
        ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_PHONE
      )
    }) || []

  if (phones.length === 0) {
    return undefined
  }

  return phones[0]
}

export function secondaryPhone(contact: Contact) {
  const phones =
    contact.telecom?.filter((telecom) => {
      return (
        telecom.system ===
        ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_PHONE
      )
    }) || []

  if (phones.length < 2) {
    return undefined
  }

  return phones[1]
}

export function primaryEmail(contact: Contact) {
  const emails =
    contact.telecom?.filter(
      (telecom) =>
        telecom.system ===
        ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_EMAIL
    ) || []

  if (emails.length === 0) {
    return undefined
  }

  return emails[0].value
}

export function organizationName(contact: Contact) {
  return contact.organization?.name
}

export function relationshipName(relationship: CR) {
  switch (relationship) {
    case CR.CONTACT_RELATIONSHIP_UNSPECIFIED:
      return 'Unspecified'
    case CR.CONTACT_RELATIONSHIP_PERSONAL:
      return 'Personal'
    case CR.CONTACT_RELATIONSHIP_FAMMEMB:
      return 'Family Member'
    case CR.CONTACT_RELATIONSHIP_CHILD:
      return 'Daughter / Son'
    case CR.CONTACT_RELATIONSHIP_CHILD_INLAW:
      return 'Daughter-in-law / Son-in-law'
    case CR.CONTACT_RELATIONSHIP_GRNDCHILD:
      return 'Grandchild'
    case CR.CONTACT_RELATIONSHIP_SPS:
      return 'Spouse'
    case CR.CONTACT_RELATIONSHIP_SIB:
      return 'Sibling'
    case CR.CONTACT_RELATIONSHIP_NIENEPH:
      return 'Niece/Nephew'
    case CR.CONTACT_RELATIONSHIP_GRNIENEPH:
      return 'Grand Niece/Nephew'
    case CR.CONTACT_RELATIONSHIP_COUSN:
      return 'Cousin'
    case CR.CONTACT_RELATIONSHIP_FRND:
      return 'Friend'
    case CR.CONTACT_RELATIONSHIP_CLINICIAN:
      return 'Clinician'
    case CR.CONTACT_RELATIONSHIP_PHYSICIAN:
      return 'Physician'
    case CR.CONTACT_RELATIONSHIP_NURSE_PRACTITIONER:
      return 'Nurse Practitioner'
    case CR.CONTACT_RELATIONSHIP_PHYSICIAN_ASSISTANT:
      return 'Physician Assistant'
    case CR.CONTACT_RELATIONSHIP_PHARMACIST:
      return 'Pharmacist'
    case CR.CONTACT_RELATIONSHIP_NURSE:
      return 'Nurse'
    case CR.CONTACT_RELATIONSHIP_SKILLED_THERAPIST:
      return 'Skilled Therapist (PT, OT, Speech)'
    case CR.CONTACT_RELATIONSHIP_PSYCHOLOGIST:
      return 'Psychologist'
    case CR.CONTACT_RELATIONSHIP_DIETICIAN:
      return 'Dietician'
    case CR.CONTACT_RELATIONSHIP_SOCIAL_WORKER:
      return 'Social Worker'
    case CR.CONTACT_RELATIONSHIP_DENTIST:
      return 'Dentist'
    case CR.CONTACT_RELATIONSHIP_PODIATRIST:
      return 'Podiatrist'
    case CR.CONTACT_RELATIONSHIP_PROFESSIONAL:
      return 'Professional'
    case CR.CONTACT_RELATIONSHIP_REFERRAL_AGENT:
      return 'Referral Agent'
    case CR.CONTACT_RELATIONSHIP_PLACEMENT_AGENT:
      return 'Placement Agent'
    case CR.CONTACT_RELATIONSHIP_CONSERVATOR:
      return 'Conservator'
    case CR.CONTACT_RELATIONSHIP_LEGALLY_RESPONSIBLE:
      return 'Guardian'
    case CR.CONTACT_RELATIONSHIP_CASE_MANAGER:
      return 'Case Manager'
    case CR.CONTACT_RELATIONSHIP_RELIGIOUS_ADVISOR:
      return 'Religious Advisor'
    case CR.CONTACT_RELATIONSHIP_THIRD_PARTY_PAYER:
      return 'Third Party Payer'
    case CR.CONTACT_RELATIONSHIP_FIDUCIARY:
      return 'Fiduciary'
    case CR.CONTACT_RELATIONSHIP_FUNERAL_HOME:
      return 'Funeral Home'
    case CR.CONTACT_RELATIONSHIP_GENERAL_PRACTITIONER:
      return 'General Practitioner'
    case CR.CONTACT_RELATIONSHIP_MENTAL_HEALTH_PROVIDER:
      return 'Mental Health Provider'
    case CR.CONTACT_RELATIONSHIP_HOSPICE_PROVIDER:
      return 'Hospice Provider'
    case CR.CONTACT_RELATIONSHIP_RESPONSIBLE_PERSON:
      return 'Responsible Person'
    case CR.CONTACT_RELATIONSHIP_FINANCIAL_PAYMENT_OF_CARE:
      return 'Payment of Care'
    case CR.CONTACT_RELATIONSHIP_FINANCIAL_POWER_OF_ATTORNEY:
      return 'Financial Power of Attorney'
    case CR.CONTACT_RELATIONSHIP_MEDICAL_POWER_OF_ATTORNEY:
      return 'Medical Power of Attorney'
    case CR.CONTACT_RELATIONSHIP_C:
      return 'Emergency Contact'
    case CR.CONTACT_RELATIONSHIP_RELEASE_OF_INFORMATION:
      return 'Release of Information Manager'
    case CR.CONTACT_RELATIONSHIP_PERSONAL_PROPERTY:
      return 'Personal Property Manager'
    case CR.CONTACT_RELATIONSHIP_OTHER:
      return 'Other'
    case CR.CONTACT_RELATIONSHIP_SELF:
      return 'Self'
    case CR.CONTACT_RELATIONSHIP_N:
      return 'Next-of-Kin' // Unused
    case CR.UNRECOGNIZED:
    default:
      return 'Unrecognized'
  }
}

export function convertRelationshipToOption(r: CR) {
  return {
    label: relationshipName(r),
    value: r,
  }
}
