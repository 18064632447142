import { cloneDeep } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import CollapsibleCard from '@shared/components/CollapsibleCard'
import { AssessmentReason as AssessmentReasonType } from '@shared/types/assessment_configuration'
import notEmpty from '@shared/utils/notEmpty'
import { AssessmentReason } from './AssessmentReason'

type Props = {
  assessmentReasons: AssessmentReasonType[]
  setAssessmentReasons: (reasons: AssessmentReasonType[]) => void
}
export const AssessmentReasons = ({
  assessmentReasons,
  setAssessmentReasons,
}: Props) => {
  const saveAssessmentReason = (reason: AssessmentReasonType) => {
    const updatedReasons = cloneDeep(assessmentReasons).map((ar) => {
      if (ar.tempId === reason.tempId) {
        return {
          ...ar,
          ...reason,
        }
      }
      return ar
    })

    setAssessmentReasons(updatedReasons)
  }
  const deleteAssessmentReason = (tempId: string) => {
    const afterDelete = assessmentReasons.filter((ar) => ar.tempId !== tempId)
    setAssessmentReasons(afterDelete)
  }

  const currentReasonEnums = Array.from(
    new Set(assessmentReasons.map((ar) => ar.assessmentReason))
  ).filter(notEmpty)

  const createEmptyAssessmentReason = () => {
    const tempId = uuidv4()
    setAssessmentReasons([
      ...assessmentReasons,
      {
        assessmentReason: undefined,
        tempId,
      },
    ])
  }

  return (
    <CollapsibleCard cardTitle={'RECURRING ASSESSMENT REASONS'}>
      {assessmentReasons.length === 0 && (
        <div className={'mb-[8px] text-center text-[14px]'}>
          No assessment reasons have been specified.
        </div>
      )}
      {assessmentReasons.map((ar) => (
        <AssessmentReason
          reason={ar}
          key={ar.tempId}
          deleteReason={deleteAssessmentReason}
          saveReason={saveAssessmentReason}
          currentEnums={currentReasonEnums}
        />
      ))}
      <AsyncIconButton
        buttonStyle={'tertiary-fill'}
        buttonSize={'small'}
        onClick={createEmptyAssessmentReason}
        initialIcon={'fa-solid fa-plus'}
        className={'mt-[16px]'}
      >
        Add Reason
      </AsyncIconButton>
    </CollapsibleCard>
  )
}
