import { EmbeddedUrlResponse } from '@augusthealth/models/com/august/protos/api/esign'
import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import LoadingPopup from '@shared/components/LoadingPopup'
import GlobalContext from '@shared/contexts/GlobalContext'
import { getHelloSignInfo } from '@app/api/form'
import HelloSign, { HelloSignClient } from '@app/components/generic/HelloSign'
import { DEFAULT_HELLO_SIGN_DATA } from '@app/components/generic/HelloSign/defaults'

type UrlProps = {
  id: string
}

export function setHelloSignFullScreen(client: HelloSignClient) {
  client._baseEl.classList.add('full-screen')
}

export default function HelloSignTemplate() {
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()
  const { id = '' } = useParams<UrlProps>()
  const [helloSignData, setHelloSignData] = useState<EmbeddedUrlResponse>(
    DEFAULT_HELLO_SIGN_DATA
  )
  const { clientId, url } = helloSignData
  const { setError } = useContext(GlobalContext)
  const goBack = () => history.push('/tools/pdfTemplate')

  useEffect(() => {
    setLoading(true)
    getHelloSignInfo({ id })
      .then((res) => setHelloSignData(res.data))
      .catch((err) => {
        setError(err)
        goBack() // Go back to Template list instead stay in Blank HelloSign holder
      })
      .finally(() => setLoading(false))
  }, [id])

  if (clientId && url) {
    return (
      <HelloSign
        clientId={clientId}
        url={url}
        onCancel={goBack}
        onFinish={goBack}
        onErrorClose={goBack}
        init={setHelloSignFullScreen}
      />
    )
  }

  return <LoadingPopup loading={loading} />
}
