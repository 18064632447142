import {
  DateMessage,
  ValidDate,
} from '@augusthealth/models/com/august/protos/date'
import { DosageType } from '@augusthealth/models/com/august/protos/medication_statement'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  BasicInput,
  BasicTextarea,
} from '@shared/components/BasicInput/BasicInput'
import { ControlledLabelledCalendarInput } from '@shared/components/CalendarInput/ControlledLabelledCalendarInput'
import { LabelAboveInput } from '@shared/components/Labels'
import { MedOrderFormData } from '@shared/types/medication_order'
import {
  fromDateMessageToDate,
  fromDateToDateMessage,
} from '@shared/utils/date'
import { tw, twx } from '@shared/utils/tailwind'
import { ConditionalParameters } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/ScheduleFooter/ConditionalParameters'
import {
  FooterMode,
  isPartialFooter,
  updateEndDate,
} from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/ScheduleFooter/helpers'
import { isIndefiniteEndTaperDuration } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/TaperSchedule/helpers'
import { Switch, SwitchSize } from '@app/components/Switch'
import { DateDisplay, FooterRowDisplay } from './FooterRowDisplays'

type Props = {
  readOnly: boolean
  mode: FooterMode
}

export const ScheduleFooter = ({ readOnly, mode }: Props) => {
  const {
    register,
    getValues,
    setValue,
    control,
    watch,
    setFocus,
    unregister,
  } = useFormContext<MedOrderFormData>()

  const isPartialTaper = mode.tag === `partial-${DosageType.DOSAGE_TYPE_TAPER}`
  const isPartial = isPartialFooter(mode)
  const isFullVital = mode.tag === `full-DOSAGE_TYPE_ROUTINE-Vital`
  const isFull = mode.tag.startsWith('full-')
  const isTaper = mode.tag.endsWith(`${DosageType.DOSAGE_TYPE_TAPER}`)

  const noteName =
    isPartial || isFullVital ? mode.noteName : 'additionalInstructions'
  const requiredDocumentationName = isPartial
    ? mode.requiredDocumentationName
    : null
  const requiredDocumentationError = isPartial
    ? mode.requiredDocumentationError
    : null
  const noteValue = noteName ? watch(noteName) : null
  const requiredInjectionOrApplicationSitePromptValue =
    requiredDocumentationName ? watch(requiredDocumentationName) : null

  const medOrder = getValues()
  const numberOfDoses = medOrder.doses?.length ?? 1
  const lastDoseIndex = numberOfDoses - 1
  const finalDoseDuration = watch(`doses.${lastDoseIndex}.duration`)
  const isTaperWithIndefiniteEnd =
    isTaper && isIndefiniteEndTaperDuration(finalDoseDuration)
  const finalDoseStart = watch(`doses.${lastDoseIndex}.boundsPeriod.startDate`)

  const startDateName = isPartialTaper
    ? mode.startDateName
    : 'effectivePeriod.startDate'
  const endDateName = isPartialTaper
    ? mode.endDateName
    : 'effectivePeriod.endDate'

  const startDate = watch(startDateName) as ValidDate
  const endDate = watch(endDateName) as ValidDate

  const [hasTouchedInstructions, setHasTouchedInstructions] =
    useState(!!noteValue)
  const [
    hasTouchedAdditionalDocumentation,
    setHasTouchedAdditionalDocumentation,
  ] = useState(!!requiredInjectionOrApplicationSitePromptValue)

  const noteContainerClass = twx({
    'mt-[24px]': !hasTouchedInstructions,
    'mt-[8px] mb-[16px]': hasTouchedInstructions,
  })

  useEffect(() => {
    if (hasTouchedInstructions && noteName) {
      setFocus(noteName)
    }
  }, [hasTouchedInstructions, noteName])

  useEffect(() => {
    if (noteValue) {
      setHasTouchedInstructions(true)
    }
  }, [noteValue])

  return (
    <div data-testid={'schedule-footer'}>
      {!readOnly && requiredDocumentationName && (
        <>
          <Switch
            name={'additionalDocumentation'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setHasTouchedAdditionalDocumentation(event.target.checked)
              if (!event.target.checked) {
                setValue(requiredDocumentationName, '', {
                  shouldDirty: true,
                })
                unregister(requiredDocumentationName)
              }
            }}
            label={'Require documentation'}
            labelClassName={'text-august-primary'}
            size={SwitchSize.small}
            checked={hasTouchedAdditionalDocumentation}
            holderClassName={tw`mt-6`}
          />
          {hasTouchedAdditionalDocumentation && (
            <>
              <LabelAboveInput
                uppercase={false}
                className={tw`mt-2`}
                htmlFor={requiredDocumentationName}
              >
                What should be documented every time this medication is passed?
              </LabelAboveInput>
              <BasicInput
                {...register(requiredDocumentationName, {
                  required: true,
                })}
                showErrorBorder={!!requiredDocumentationError}
                disabled={readOnly}
                placeholder={'e.g. Injection site'}
                width={'100%'}
                containerClassName={tw`mb-6 mt-2`}
              />
            </>
          )}
        </>
      )}
      {!readOnly && isPartial && (
        <ConditionalParameters doseIndex={mode.doseIndex} />
      )}
      {isFull && (
        <div className={twx('my-4 flex flex-row items-center gap-4')}>
          <ControlledLabelledCalendarInput<MedOrderFormData, ValidDate>
            control={control}
            name={startDateName}
            rules={{ required: true }}
            convertToSavedValue={fromDateToDateMessage}
            convertToSelectedValue={fromDateMessageToDate}
            inputProps={{
              label: 'Start Date',
              allowErrorBorder: true,
              onChange: (updatedValue: Date | null) => {
                if (mode.tag === 'full-DOSAGE_TYPE_TAPER') {
                  mode.onStartDateChange({
                    date: updatedValue
                      ? fromDateToDateMessage(updatedValue)
                      : null,
                    oldDate: startDate,
                  })
                  return
                }

                if (!isTaper && updatedValue) {
                  updateEndDate({
                    originalDate: fromDateMessageToDate(startDate),
                    newDate: updatedValue,
                    endDateName,
                    getValues,
                    setValue,
                  })
                }
              },
              className: twx('mr-[16px]'),
              disabled: readOnly,
              maxDate: isTaper ? null : fromDateMessageToDate(endDate),
            }}
          />
          <ControlledLabelledCalendarInput<MedOrderFormData, ValidDate>
            control={control}
            name={endDateName}
            convertToSavedValue={fromDateToDateMessage}
            convertToSelectedValue={fromDateMessageToDate}
            inputProps={{
              isClearable: !readOnly,
              label: 'End Date',
              allowErrorBorder: true,
              onChange: (updatedValue: Date | null) => {
                const date = updatedValue
                  ? fromDateToDateMessage(updatedValue)
                  : null

                if (isTaperWithIndefiniteEnd) {
                  setValue(
                    `doses.${lastDoseIndex}.boundsPeriod.endDate`,
                    // @ts-ignore
                    date
                  )
                }
              },
              disabled: readOnly || (isTaper && !isTaperWithIndefiniteEnd),
              minDate: isTaperWithIndefiniteEnd
                ? fromDateMessageToDate(finalDoseStart as DateMessage)
                : fromDateMessageToDate(startDate),
            }}
          />
        </div>
      )}
      {hasTouchedInstructions && noteName && (
        <div className={noteContainerClass}>
          <BasicTextarea
            {...register(noteName)}
            className={'min-h-[48px]'}
            disabled={readOnly}
            placeholder={'Additional Instructions: e.g. take after meal'}
            ignoreMaxWidth={true}
          />
        </div>
      )}
      {isPartialTaper && (
        <>
          <DateDisplay
            label={'First Dose:'}
            date={startDate}
            readOnly={readOnly}
            isPartialTaper={true}
          />
          <DateDisplay
            label={'Last Dose:'}
            date={endDate}
            readOnly={readOnly}
            isPartialTaper={true}
          />
        </>
      )}
      {!hasTouchedInstructions && !readOnly && noteName && (
        <FooterRowDisplay
          onClick={() => {
            setHasTouchedInstructions(true)
          }}
          label={'Add additional instructions'}
          icon={'fa-note-sticky'}
        />
      )}
    </div>
  )
}
