import { AppraisalSettings_AssessmentType } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { Option } from 'effect'
import { useState } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { assessmentTypeToDisplay } from '@shared/utils/assessmentConfiguration'
import notEmpty from '@shared/utils/notEmpty'
import { ASSESSMENT_TYPE_OPTIONS } from './helpers'

type Props = {
  assessType: AppraisalSettings_AssessmentType
  deleteType: (assessmentType: AppraisalSettings_AssessmentType) => void
  saveType: (assessmentType: AppraisalSettings_AssessmentType) => void
  currentEnums: AppraisalSettings_AssessmentType[]
}
export const AssessmentType = ({
  assessType,
  deleteType,
  saveType,
  currentEnums,
}: Props) => {
  const labelString = assessmentTypeToDisplay(assessType!)

  const [selectedOption, setSelectedOption] =
    useState<OptionTypeBase<AppraisalSettings_AssessmentType> | null>(
      Option.isSome(labelString)
        ? {
            label: labelString.value,
            value: assessType!,
          }
        : null
    )

  const options = ASSESSMENT_TYPE_OPTIONS.filter(notEmpty).map(
    (opt: OptionTypeBase<AppraisalSettings_AssessmentType>) => ({
      ...opt,
      isDisabled: currentEnums.includes(opt.value),
    })
  )

  const saveAssessmentType = (
    selectedValue: OptionTypeBase<AppraisalSettings_AssessmentType>
  ) => {
    saveType(selectedValue!.value!)
  }

  return (
    <div className={'mb-[8px] flex flex-row items-center justify-between'}>
      <StyledSelect
        id={'assessment-type'}
        name={'assessment-type'}
        instanceId={'assessment-type'}
        onChange={(val: OptionTypeBase<AppraisalSettings_AssessmentType>) => {
          setSelectedOption(val)
          saveAssessmentType(val)
        }}
        value={selectedOption?.value ? selectedOption : null}
        options={options}
        placeholder={'Select assessment type...'}
        className={'w-full'}
      />
      <ButtonLink
        title={'Delete Item'}
        data-testid={`delete-type-${assessType}`}
        className={'ml-[8px]'}
        onClick={() => {
          deleteType(assessType)
        }}
      >
        <i className={`fa fa-fw fa-trash`} />
      </ButtonLink>
    </div>
  )
}
