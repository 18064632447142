/* eslint react-hooks/exhaustive-deps: 2 */

import useSearchTextParam from '@careapp/hooks/useSearchTextParam'
import { parseISO } from 'date-fns'
import { Array, Option, pipe } from 'effect'
import { useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import MissedAdministrationsBanner from '@shared/components/MissedAdministrationsBanner'
import { CareGroupsSelect } from '@shared/components/Selects/CareGroupsSelect'
import ShiftSelect from '@shared/components/Selects/ShiftSelect'
import { FacilityProgressRoutines } from '@shared/types/careapp'
import { Facility } from '@shared/types/facility'
import {
  routineAdminIsAdministered,
  routineAdminIsException,
  routineAdminIsOverDue,
} from '@shared/utils/careapp'
import { tw } from '@shared/utils/tailwind'
import Content from '@app/components/generic/Content'
import PersonPageTitle from '@app/components/PersonPageTitle'
import { getProgressRoutinesPageUrl } from '../paths'
import { personMatchesSearchText } from './filter'
import { useProgressContext } from './ProgressContext'
import ResidentRoutines from './ResidentRoutines'
import RoutineCharts from './RoutineCharts'
import RoutineFilterAndSearch from './RoutineFilterAndSearch'

export default function PageLoaded({
  facility,
  facilityCareProgress,
  reloadFacilityCareProgress,
}: {
  facility: Facility
  facilityCareProgress: FacilityProgressRoutines
  reloadFacilityCareProgress: () => Promise<void>
}) {
  const { timeZone, orgId, id: facilityId } = facility

  const [searchText, setSearchText] = useSearchTextParam()

  const {
    isoDate,
    careGroupId,
    shiftId,
    setIsoDate,
    setShiftId,
    setCareGroupId,
    filteredPeopleWithAdministrations,
    peopleWithRoutineAdministrations,
  } = useProgressContext()
  const {
    careGroups,
    shifts,
    routineAdministrations: routineAdministrationsForDate,
    missedAdministrationsHistory = [],
  } = facilityCareProgress

  const dateInLocalTZ = parseISO(isoDate)

  const filteredRoutineAdministrations = pipe(
    peopleWithRoutineAdministrations,
    Array.filter(({ person }) => {
      return personMatchesSearchText(searchText)(person)
    }),
    Array.flatMap(({ administrations }) => administrations)
  )

  const selectedCareGroup = careGroups.find(({ id }) => id === careGroupId)

  const overdueRoutinesCount = filteredRoutineAdministrations.filter(
    (administration) => routineAdminIsOverDue(administration, timeZone)
  ).length
  const exceptionRoutinesCount = filteredRoutineAdministrations.filter(
    routineAdminIsException
  ).length
  const administeredRoutinesCount = filteredRoutineAdministrations.filter(
    routineAdminIsAdministered
  ).length

  useEffect(() => {
    const { shifts } = facilityCareProgress

    // Default to first shift if shiftId not found or not in current shifts
    if (!shiftId || !shifts.some((s) => s.id === shiftId)) {
      const firstShift = shifts[0]!
      setShiftId(firstShift.id)
    }
  }, [facilityCareProgress, setShiftId, shiftId])

  if (!shiftId) {
    return <SimpleSpinner />
  }

  return (
    <Content className={tw`mt-[32px]`}>
      <PersonPageTitle title="Care Progress" withBorder={false}>
        <div className={tw`flex gap-3`}>
          <DatePicker
            wrapperClassName={tw`w-[174px]`}
            selected={dateInLocalTZ}
            onChange={setIsoDate}
            customInput={<CalendarInput className={tw`w-[174px]`} />}
            maxDate={new Date()}
          />
          <ShiftSelect
            className={tw`min-w-[174px]`}
            selectedShiftId={shiftId}
            shifts={shifts}
            onChange={setShiftId}
          />
          <CareGroupsSelect
            className={tw`min-w-[174px]`}
            selectedCareGroup={Option.fromNullable(selectedCareGroup)}
            careGroups={careGroups}
            onChangeSelectedCareGroup={setCareGroupId}
          />
        </div>
      </PersonPageTitle>

      {missedAdministrationsHistory.length > 0 && (
        <MissedAdministrationsBanner
          title="You have missed routines"
          orgId={orgId}
          facilityId={facilityId}
          activeDate={dateInLocalTZ}
          missedAdministrationsHistory={missedAdministrationsHistory}
          className={tw`mb-[16px]`}
          linkFunc={(oId, fId, { date }) =>
            getProgressRoutinesPageUrl(oId, fId, {
              date,
              careGroupId,
              shiftId,
            })
          }
        />
      )}

      <RoutineCharts
        allRoutineAdministrations={routineAdministrationsForDate}
        shiftId={shiftId}
        shifts={shifts}
        setShiftId={setShiftId}
        facilityTimeZone={timeZone}
      />

      <RoutineFilterAndSearch
        filteredPeopleWithAdministrations={filteredPeopleWithAdministrations}
        facility={facility}
        administeredCount={administeredRoutinesCount}
        exceptionsCount={exceptionRoutinesCount}
        overdueCount={overdueRoutinesCount}
        searchText={searchText}
        setSearchText={setSearchText}
      />

      <ResidentRoutines
        filteredPeopleWithAdministration={filteredPeopleWithAdministrations}
        facilityTimeZone={timeZone}
        searchText={searchText}
        reload={reloadFacilityCareProgress}
      />
    </Content>
  )
}
