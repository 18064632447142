import {
  AllergyIntolerance,
  AllergyIntolerance_AllergyIntoleranceCategory,
} from '@augusthealth/models/com/august/protos/allergy_intolerance'
import { ChangeEvent, useState } from 'react'
import { LabelAboveInput } from '@shared/components/Labels'
import { Person } from '@shared/types/person'
import { Snapshot, SnapshotStatus } from '@shared/types/snapshot'
import {
  GtkyFields,
  GtkyPersonPatch,
  GtkySnapshotPatch,
} from '@shared/utils/gtky'
import Allergies from '@app/components/Prospects/Details/Diagnoses/Allergies'
import LongformAnswer from '@app/pages/Tasks/GettingToKnowYouV2/components/LongformAnswer'
import { getFoodAllergiesForDisplay } from './helpers'

type Props = {
  updateSnapshot: ({
    patch,
    status,
  }: {
    patch: GtkySnapshotPatch | null
    status: SnapshotStatus
  }) => Promise<void>
  updatePerson: ({
    patch,
    fields,
  }: {
    patch: GtkyPersonPatch
    fields: string
  }) => Promise<void>
  snapshot: Snapshot
  person: Person
  isEditing?: boolean
}

export default function MealsPage({
  updateSnapshot,
  updatePerson,
  person,
  snapshot,
  isEditing = false,
}: Props) {
  const foodCategory =
    AllergyIntolerance_AllergyIntoleranceCategory.ALLERGY_INTOLERANCE_CATEGORY_FOOD
  const [fields, setFields] = useState<GtkySnapshotPatch>({})
  const [foodAllergies, setFoodAllergies] = useState<AllergyIntolerance[]>(
    getFoodAllergiesForDisplay({ isEditing, person })
  )

  const disabled = !isEditing

  const updateFoodAllergies = async (updatedAllergies = foodAllergies) => {
    const filteredAllergies = updatedAllergies.filter((f) => f.summary?.trim())
    await savePerson(
      {
        allergiesAndIntolerances: {
          allergies: filteredAllergies,
        },
      },
      'allergiesAndIntolerances/allergies'
    )
  }

  const saveSnapshot = async (formValues = fields) =>
    await updateSnapshot({ patch: formValues, status: snapshot.status! })

  const savePerson = async (formData: GtkyPersonPatch, fieldsToMatch: string) =>
    await updatePerson({ patch: formData, fields: fieldsToMatch })

  const formFields = [
    {
      field: GtkyFields.BREAKFAST_TIME,
      question: 'When do you prefer to eat breakfast?',
    },
    {
      field: GtkyFields.LUNCH_TIME,
      question: 'When do you prefer to eat lunch?',
    },
    {
      field: GtkyFields.DINNER_TIME,
      question: 'When do you prefer to eat dinner?',
    },
    {
      field: GtkyFields.FAVORITE_FOODS,
      question: 'What are your favorite foods?',
    },
    {
      field: GtkyFields.DISLIKED_FOODS,
      question: 'What foods do you dislike?',
    },
    {
      field: GtkyFields.SNACK_FOODS,
      question: 'What are your favorite snacks?',
    },
  ]

  return (
    <>
      {formFields.map((field) => {
        return (
          <div key={field.field}>
            <LabelAboveInput htmlFor={field.field} uppercase={false}>
              {field.question}
            </LabelAboveInput>
            <LongformAnswer
              mode={isEditing ? 'edit' : 'view'}
              disabled={disabled}
              onBlur={async () => await saveSnapshot()}
              id={field.field}
              name={field.field}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                setFields({
                  ...fields,
                  [field.field]: e.target.value,
                })
              }}
              defaultValue={
                snapshot!.data?.formData?.fields?.[field.field] || ''
              }
            />
          </div>
        )
      })}

      <div>
        <LabelAboveInput htmlFor={GtkyFields.FOOD_ALLERGIES} uppercase={false}>
          List any food allergies and describe your reactions to them
        </LabelAboveInput>
        <Allergies
          allergies={foodAllergies}
          setAllergies={setFoodAllergies}
          category={foodCategory}
          inputProps={{
            onBlur: () => void updateFoodAllergies(),
          }}
          onRemoveAllergy={updateFoodAllergies}
          readOnly={disabled}
        />
      </div>
    </>
  )
}
