import classNames from 'classnames'
import { ChangeEvent, useState } from 'react'
import { FieldPath, useFormContext } from 'react-hook-form'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { ModalTitle } from '@shared/components/baseMui/Modal/Layout'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import {
  BasicInput,
  BasicTextarea,
} from '@shared/components/BasicInput/BasicInput'
import ButtonLink from '@shared/components/ButtonLink'
import { MedOrderFormData } from '@shared/types/medication_order'
import { tw } from '@shared/utils/tailwind'
import styles from '../styles.module.css'

type Props = {
  noteName: FieldPath<MedOrderFormData>
  readOnly: boolean
}
export const SlidingScaleNote = ({ noteName, readOnly }: Props) => {
  const methods = useFormContext<MedOrderFormData>()
  const { register, formState, getValues, setValue } = methods
  const savedNote = getValues(noteName) as string

  const [showNoteModal, setShowNoteModal] = useState(false)
  const [noteValue, setNoteValue] = useState(savedNote ?? '')
  const iconClasses = classNames(styles.slidingScaleIcon, {
    hidden: readOnly,
  })

  const noteInputPreviewClasses = classNames(styles.noteInput, {
    [styles.readOnlyNoteInput]: readOnly,
  })

  const saveNote = () => {
    setValue(noteName, noteValue.trim(), { shouldDirty: true })
    setShowNoteModal(false)
  }

  return (
    <>
      <Modal
        open={showNoteModal}
        id={'sliding-scale-note'}
        onClose={() => setShowNoteModal(false)}
        contentClassName={tw`w-defaultModal min-w-defaultModal max-w-defaultModal`}
      >
        <ModalTitle>Add Note</ModalTitle>
        <BasicTextarea
          placeholder={'Optional note or additional instructions...'}
          className={'mb-[24px]'}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            setNoteValue(e.target.value)
          }
          disabled={readOnly}
          value={noteValue}
        />
        <AnimatedPopupFormFooter
          yesBtn={{
            label: 'Save',
            props: {
              ['data-cy']: 'save-sliding-scale-note',
              ['data-testid']: 'save-sliding-scale-note',
              onClick: saveNote,
              type: 'button',
              disabled: readOnly,
            },
          }}
          noBtn={{ action: () => setShowNoteModal(false) }}
          formState={formState}
        />
      </Modal>
      {savedNote ? (
        <BasicInput
          readOnly={true}
          {...register(noteName)}
          onClick={() => setShowNoteModal(true)}
          className={noteInputPreviewClasses}
        />
      ) : (
        <ButtonLink
          icon={'fa-regular fa-fw fa-note-sticky'}
          title={'Add note to range'}
          iconClasses={iconClasses}
          className={'ml-[16px]'}
          onClick={() => setShowNoteModal(true)}
        />
      )}
    </>
  )
}
