import { Popup } from '@augusthealth/august-frontend-form-elements'
import Confetti from 'confetti-js'
import { useEffect } from 'react'
import styles from './styles.module.css'

type Props = {
  footer: React.ReactNode
  content?: React.ReactNode
}

export default function CongratsModal(props: Props) {
  const {
    content = (
      <>
        <h4>Nice work!</h4>
        <p>You are done with this task.</p>
      </>
    ),
    footer,
  } = props

  useEffect(() => {
    const confettiSettings = {
      target: 'modal-header-canvas',
      width: 500,
      height: 140,
      max: 75,
      props: ['square', 'triangle'],
    }
    const confetti = new Confetti(confettiSettings)
    confetti.render()

    return () => confetti.clear()
  }, [])

  return (
    <Popup title="Congratulations!" returnChildrenDirectly defaultOpen>
      <div className="modal-dialog" role="document">
        <div className="modal-content no-separator">
          <div className={`modal-header ${styles.congratsHeader}`}>
            <canvas id="modal-header-canvas" className={styles.confetti} />
            <img
              className={styles.congratsImage}
              src="/svg/illustration--couple.svg"
              alt="Two smiling people with confetti in the air"
            />
          </div>
          <div className="modal-body">
            <section key="congrats-modal-body" className="text-center">
              {content}
            </section>
          </div>
          <div className={`modal-footer ${styles.congratsFooter}`}>
            {footer}
          </div>
        </div>
      </div>
    </Popup>
  )
}
