import { useContext } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Incident } from '@shared/types/incidents'
import { Person } from '@shared/types/person'
import { deleteIncidentAttachment } from '@app/api/incidents'
import Uploader from '@app/components/Uploader'
import TypeSelector from './TypeSelector'

export type IncidentAttachmentType = {
  file: File
  attachmentType: { label: string | undefined; value: string | undefined }
}

type FieldWithIncidentAttachmentData = IncidentAttachmentType &
  Record<'id', string> & {
    attachmentType: { label: string; value: string }
    attachmentId: string
  }

type Props = {
  incident: Incident | undefined
  person: Person
}

export default function IncidentAttachment(props: Props) {
  const { setError } = useContext(GlobalContext)
  const { person, incident } = props
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name: 'attachments',
    control,
  })

  async function handleRemoveAttachment({
    index,
    attachmentId,
  }: {
    index: number
    attachmentId: string | undefined
  }) {
    try {
      if (attachmentId) {
        await deleteIncidentAttachment({
          person,
          incidentId: incident!.id!,
          snapshotId: attachmentId,
        })
      }

      remove(index)
    } catch (err) {
      setError(err)
    }
  }

  return (
    <>
      <Uploader
        handleChange={(file: File) => {
          append({ file })
        }}
      />
      {fields?.map((f: FieldWithIncidentAttachmentData, index) => (
        <div key={f.id} data-testid="attachment">
          <TypeSelector
            control={control}
            index={index}
            attachmentType={f.attachmentType}
            isDisabled={!!f.attachmentId}
            file={f.file}
            removeAttachment={async () => {
              await handleRemoveAttachment({
                index,
                attachmentId: f.attachmentId,
              })
            }}
            isSubmitting={isSubmitting}
            person={person}
          />
        </div>
      ))}
    </>
  )
}
