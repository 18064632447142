import { useContext, useEffect, useState } from 'react'
import {
  completeResidentEventImpactingBilling,
  fetchResidentEventsImpactingBilling,
} from '@shared/api/billing'
import GlobalContext from '@shared/contexts/GlobalContext'
import { ResidentEventImpactingBilling } from '@shared/types/billing'
import { RequiredPersonIds } from '@shared/types/person'
import { loaded, LOADING, Loading } from '@shared/utils/loading'

export default function useBillingRelatedResidentEvents(
  props: RequiredPersonIds
) {
  const { orgId, facilityId, id: personId } = props
  const { setError } = useContext(GlobalContext)
  const [residentEvents, setResidentEvents] =
    useState<Loading<ResidentEventImpactingBilling[]>>(LOADING)

  async function reloadResidentEvents() {
    setResidentEvents(LOADING)
    try {
      const newResidentEvents = await fetchResidentEventsImpactingBilling(props)
      setResidentEvents(loaded(newResidentEvents))
    } catch (e) {
      setError(e)
    }
  }

  async function completeAndReloadResidentEvents(eventId: string) {
    try {
      await completeResidentEventImpactingBilling(props, eventId)
      await reloadResidentEvents()
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    void reloadResidentEvents()
  }, [orgId, facilityId, personId])

  return {
    residentEvents,
    completeAndReloadResidentEvents,
    reloadResidentEvents,
  }
}
