import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { prospectsList } from '@shared/legacy_routes'
import CloseProspect from '@app/components/Prospects/Close'
import UndoMoveIn from '@app/components/Prospects/UndoMoveIn'
import VerifyAdmissionDetails from '@app/components/Prospects/VerifyAdmissionDetails/VerifyAdmissionDetails'
import MoveOutDetails from '@app/components/Residents/MoveOutDetails'
import MarkAsAwayPopup from '@app/components/Residents/MoveOutDetails/MarkAsAwayPopup'
import PersonContext from '@app/contexts/PersonContext'
import { ActionMenu } from './ActionMenu'

export default function ActionMenuContainer({
  setShowProfile,
}: {
  setShowProfile: (show: boolean) => void
}) {
  const history = useHistory()
  const [showAdmissionDetails, setShowAdmissionDetails] = useState({
    showModal: false,
    convertToCurrentResident: false,
  })
  const [showMoveOutResident, setShowMoveOutResident] = useState(false)
  const [showCloseProspect, setShowCloseProspect] = useState(false)
  const [showUndoMoveIn, setShowUndoMoveIn] = useState(false)
  const [showMarkAsAway, setShowMarkAsAway] = useState(false)
  const { person } = useContext(PersonContext)

  if (!person) {
    return null
  }

  return (
    <>
      <ActionMenu
        person={person}
        onEditProfileClick={() => setShowProfile(true)}
        onEditAdmissionClick={() => {
          setShowAdmissionDetails({
            showModal: true,
            convertToCurrentResident: false,
          })
        }}
        onMoveInResidentClick={() => {
          setShowAdmissionDetails({
            showModal: true,
            convertToCurrentResident: true,
          })
        }}
        onMoveOutResidentClick={() => setShowMoveOutResident(true)}
        onCloseProspectClick={() => setShowCloseProspect(true)}
        onMarkAsAwayClick={() => setShowMarkAsAway(true)}
        onUndoMoveInClick={() => {
          setShowUndoMoveIn(true)
        }}
      />
      {showAdmissionDetails.showModal && (
        <VerifyAdmissionDetails
          convertToCurrentResident={
            showAdmissionDetails.convertToCurrentResident
          }
          person={person}
          doneFn={() =>
            setShowAdmissionDetails({
              showModal: false,
              convertToCurrentResident: false,
            })
          }
        />
      )}
      {showCloseProspect && (
        <CloseProspect
          onYes={() => {
            history.replace(
              prospectsList(person.orgId!, person.facilityId!, {
                closed: person.id!,
              })
            )
          }}
          onNotNow={() => {
            setShowCloseProspect(false)
          }}
          person={person}
        />
      )}
      {showUndoMoveIn && (
        <UndoMoveIn
          onYes={() => {
            history.replace(
              prospectsList(person.orgId!, person.facilityId!, {})
            )
          }}
          onNotNow={() => {
            setShowUndoMoveIn(false)
          }}
          person={person}
        />
      )}
      {showMoveOutResident && (
        <MoveOutDetails
          person={person}
          doneFn={() => setShowMoveOutResident(false)}
        />
      )}
      {showMarkAsAway && (
        <MarkAsAwayPopup
          setShowPopup={setShowMarkAsAway}
          person={person}
          doneFn={() => setShowMarkAsAway(false)}
        />
      )}
    </>
  )
}
