import { Controller, useForm } from 'react-hook-form'
import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import { ModalTitle } from '@shared/components/baseMui/Modal/Layout'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { LabelAboveInput } from '@shared/components/Labels'
import { tw } from '@shared/utils/tailwind'
import { RHFSwitch as Switch } from '@app/components/Switch'
import { PrintableAssessmentProps } from '@app/pages/Documents/Viewer/AssessmentViewer/PrintableAssessment'

export type FormData = Pick<
  PrintableAssessmentProps,
  'printBlank' | 'printWithoutName'
>

export default function PrintModal({
  onClose,
  onPrint,
}: {
  onClose: (printing: boolean) => void
  onPrint: (formData: FormData) => void
}) {
  const { control, formState, handleSubmit } = useForm<FormData>({
    defaultValues: {
      printBlank: false,
      printWithoutName: false,
    },
    mode: 'onChange',
  })

  const save = async (formData: FormData) => {
    onPrint(formData)
    onClose(true)
  }

  return (
    <Modal
      open={true}
      id={'print-resident-assessment'}
      contentClassName={tw`min-w-[364px] max-w-[464px] text-center`}
      onClose={() => onClose(false)}
    >
      <ModalTitle>Print Resident Assessment</ModalTitle>
      <form onSubmit={handleSubmit(save)}>
        <Controller
          control={control}
          name="printBlank"
          render={({ field: { onChange, value } }) => (
            <div className="flex justify-between">
              <LabelAboveInput htmlFor="printBlank" uppercase={false}>
                Blank version
              </LabelAboveInput>
              <Switch
                holderClassName="mb-[12px] pr-[2px]"
                checked={value}
                id="printBlank"
                onChange={() => onChange(!value)}
                switchSize="small"
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="printWithoutName"
          render={({ field: { onChange, value } }) => (
            <div className="flex justify-between">
              <LabelAboveInput htmlFor="printWithoutName" uppercase={false}>
                Hide the resident name
              </LabelAboveInput>
              <Switch
                holderClassName="mb-[12px] pr-[2px]"
                checked={value}
                id="printWithoutName"
                onChange={() => onChange(!value)}
                switchSize="small"
              />
            </div>
          )}
        />
        <Button
          buttonStyle="primary-fill"
          className="mt-[24px]"
          data-testid="mark-as-done-btn"
          id="mark-as-done-btn"
          width="304px"
          disabled={!formState.isValid || formState.isSubmitting}
          isLoading={formState.isSubmitting}
          type={'submit'}
        >
          Continue
        </Button>
      </form>
    </Modal>
  )
}
