import EditDiagnoses, {
  Props,
} from '@app/components/Prospects/Details/Diagnoses/EditDiagnoses'
import { PortalModal } from '../components/PortalModal'

export default function EditDiagnosesModel(props: Props) {
  return (
    <PortalModal>
      <EditDiagnoses
        options={{
          displayOption: 'diagnoses_only',
          submitOnSaveBtn: true,
        }}
        {...props}
      />
    </PortalModal>
  )
}
