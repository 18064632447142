import { Facility } from '@shared/types/facility'
import { UserAccount } from '@shared/types/user'
import { getRelevantFacilities, isOrgAdmin } from '@shared/utils/user'

type Props = {
  facilities: Facility[]
  user: UserAccount
  orgId: string
}

export default function FacilityList(props: Props) {
  const { facilities, user, orgId } = props
  if (isOrgAdmin({ user, orgId })) {
    return (
      <div data-testid={`user-id-${user.id}-community-access`}>
        <i className="fas fa-house-building mr-[8px] text-gray-07" />
        All Communities
      </div>
    )
  }

  const availableFacilities = getRelevantFacilities({ facilities, user })

  const list = availableFacilities.map((f) => {
    const { name } = f
    return (
      <div key={`${name}-facility-row`} className="ml-0">
        <i className="fas fa-house mr-[8px] text-gray-07" />
        {name}
      </div>
    )
  })

  return <div className="vertical">{list}</div>
}
