import { AugustFieldType } from '@augusthealth/models/com/august/protos/custom_field'
import { ReportOnlyField } from '@augusthealth/models/com/august/protos/report'
import { useState } from 'react'
import StyledSelect from '@shared/components/Selects/StyledSelect'
import { dateFilterFields, getAccessor, isDateTypeFilter } from '../helpers'
import { CategoryGroup, FieldOption, ReportFields } from '../models'
import { FieldOptionComponent, SingleValue } from '../SelectComponents'
import { DateFilter } from './DateFilter'
import FieldFilter from './FieldFilter'

type Props = {
  fieldOptions: CategoryGroup[]
  setReport: React.Dispatch<React.SetStateAction<ReportFields>>
}
export default function FilterBy({ fieldOptions, setReport }: Props) {
  const [filterField, setFilterField] = useState<
    AugustFieldType | ReportOnlyField
  >()

  const addField = (newField: AugustFieldType | ReportOnlyField) => {
    setReport((prev) => ({
      fields: prev.fields,
      orderByField: prev.orderByField,
      fieldFilter: {
        ...prev.fieldFilter,
        accessor: getAccessor(newField),
      },
    }))
  }
  const addDateField = (newField) => {
    setReport((prev) => ({
      fields: prev.fields,
      orderByField: prev.orderByField,
      dateFilter: {
        ...prev.dateFilter,
        field: newField,
      },
    }))
  }
  return (
    <div className="flex items-baseline">
      <div className="mr-[8px] w-[64px] font-semibold">Filter By</div>
      <div className="grow flex-col">
        <div className="mb-[12px] grow">
          <StyledSelect
            isClearable
            placeholder="Select a field..."
            components={{
              Option: FieldOptionComponent,
              SingleValue,
            }}
            options={fieldOptions}
            onChange={(e: FieldOption) => {
              setFilterField(e?.value)
              if (isDateTypeFilter(e?.value)) {
                addDateField(dateFilterFields[e.value])
              } else {
                addField(e?.value)
              }
            }}
          />
        </div>
        {isDateTypeFilter(filterField) && (
          <DateFilter
            filterField={filterField as AugustFieldType}
            setReport={setReport}
          />
        )}
        {!isDateTypeFilter(filterField) && (
          <FieldFilter filterField={filterField} setReport={setReport} />
        )}
      </div>
    </div>
  )
}
