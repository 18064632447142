import { createPortal } from 'react-dom'

export default function Print({ children }) {
  const appRoot = document.getElementById('root')

  return createPortal(children, appRoot!)
}

export const printSelectedElements = (
  togglePrintElements: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const elements = document.querySelectorAll('#root > *')
  elements.forEach((e) => e.classList.add('print-hide'))
  togglePrintElements(true)

  const hidePrintElements = () => {
    window.removeEventListener('afterprint', hidePrintElements, true)
    elements.forEach((e) => e.classList.remove('print-hide'))
    togglePrintElements(false)
  }
  window.addEventListener('afterprint', hidePrintElements, true)
  setTimeout(() => window.print())
}
