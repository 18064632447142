import { Person } from '@shared/types/person'
import { RoutableDocument } from '../helpers'
import NamedViewer from '../Viewer/NamedViewer'
import DocumentCard from './DocumentCard'

export default function NamedCard({
  animationStyle,
  document,
  person,
  onUpdate,
  title,
}: {
  animationStyle: React.CSSProperties
  document: RoutableDocument
  person: Person
  onUpdate: () => Promise<void>
  title?: string
}) {
  const icon = (document.fileMetaData?.contentType || '').match('image')
    ? 'fa-file-image'
    : 'fa-file-pdf'

  return (
    <DocumentCard
      person={person}
      document={document}
      icon={icon}
      title={document.data?.uploadInfo?.name || title}
      completionText="Uploaded"
      viewer={(closeFn) => (
        <NamedViewer
          person={person}
          document={document}
          onComplete={async (updated) => {
            if (updated) {
              await onUpdate()
            }
            closeFn()
          }}
        />
      )}
      cypressData="namedDocumentCard"
      animationStyle={animationStyle}
    />
  )
}
