import { Person } from '@shared/types/person'
import { RoutableDocument } from '../helpers'
import GettingToKnowYouViewerV2 from '../Viewer/GettingToKnowYouViewerV2'
import DocumentCard from './DocumentCard'

export default function GettingToKnowYouV2Card({
  animationStyle,
  current,
  person,
  title,
}: {
  animationStyle: React.CSSProperties
  current: RoutableDocument
  person: Person
  title: string
}) {
  return (
    <DocumentCard
      animationStyle={animationStyle}
      person={person}
      document={current}
      icon="fa-file-alt"
      title={title}
      completionText="Completed"
      viewer={(closeFn) => (
        <GettingToKnowYouViewerV2
          title={title}
          mode={{
            tag: 'view',
            onClose: closeFn,
          }}
          gettingToKnowYou={current}
          person={person}
        />
      )}
    />
  )
}
