import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import GlobalContext from '@shared/contexts/GlobalContext'
import { BillingFrequency, ResidentListEntry } from '@shared/types/billing'
import { tw } from '@shared/utils/tailwind'
import MoneyInReactHookForm from '@app/components/MoneyInput/MoneyInReactHookForm'
import useBillingFees from '@app/hooks/useBillingFees'
import BillingModal from '../components/BillingModal'
import FeeDropdown from '../components/FeeDropdown'
import QuantityInput from '../components/QuantityInput'
import ResidentsDropdown from '../components/ResidentsDropdown'
import StartEndDates from '../components/StartEndDates'
import {
  BillingChargeFormData,
  createCharge,
  getDefaultValues,
  ModalProps,
} from '../helpers'

export type ChargeModalProps = ModalProps & {
  oneTimeChargeOnly?: boolean
  residents?: ResidentListEntry[]
}

export default function ChargeModal({
  closeFn,
  doneFn,
  residentIds,
  title = 'Add Charge',
  defaultValues,
  disabled: defaultDisabled,
  oneTimeChargeOnly = false,
  residents,
}: ChargeModalProps) {
  const { orgId, facilityId } = useParams<{
    orgId: string
    facilityId: string
  }>()
  const { setError } = useContext(GlobalContext)
  const { billingFees, billingFeesWithParentCategories } = useBillingFees({
    facilityId,
    orgId,
  })
  const disabled =
    billingFeesWithParentCategories.tag === 'Loading' || defaultDisabled
  const defaultFormData = getDefaultValues({
    defaultFormData: defaultValues,
    personIds: residentIds,
  })
  const useFormReturn = useForm<BillingChargeFormData>({
    defaultValues: defaultFormData,
  })
  const { formState, register, handleSubmit, control } = useFormReturn
  const { errors } = formState
  const [frequency, setFrequency] = useState<BillingFrequency>()
  const isNotRecurring = !frequency || frequency === BillingFrequency.ONE_TIME

  useEffect(() => {
    if (billingFees.tag === 'Complete' && defaultFormData.itemId) {
      const item = billingFees.value.find(
        (data) => data.meta.id === defaultFormData.itemId
      )
      if (item) {
        setFrequency(item.data.frequency)
      }
    }
  }, [billingFees.tag])

  async function onSubmit(formData: BillingChargeFormData) {
    try {
      await createCharge({
        orgId,
        facilityId,
        formData,
      })

      await doneFn()
      closeFn()
    } catch (e) {
      setError(e)
    }
  }

  return (
    <BillingModal id="add-charge-modal" onClose={closeFn} title={title}>
      <form className={tw`mt-[32px]`} onSubmit={handleSubmit(onSubmit)}>
        {residents && (
          <div>
            <ResidentsDropdown
              residents={residents}
              useFormReturn={useFormReturn}
              disabled={disabled}
            />
          </div>
        )}
        <div className={tw`mt-[24px]`}>
          <FeeDropdown
            billingFeesWithParentCategories={billingFeesWithParentCategories}
            useFormReturn={useFormReturn}
            setFrequency={setFrequency}
            disabled={disabled}
            oneTimeChargeOnly={oneTimeChargeOnly}
          />
        </div>
        <div className={tw`mt-[24px] flex gap-4`}>
          <MoneyInReactHookForm
            uppercaseTitle={false}
            subLabel={requiredLabel(Boolean(errors.amountCents))}
            control={control}
            inputProps={{ disabled }}
            name="amountCents"
            id="amountCents"
            required
            title={isNotRecurring ? 'Amount' : 'Rate'}
          />
        </div>
        <div className={tw`mt-[24px]`}>
          <StartEndDates
            frequency={frequency}
            useFormReturn={useFormReturn}
            disabled={disabled}
            startDateLabel={isNotRecurring ? 'Service Date' : 'Start Date'}
          />
        </div>
        {isNotRecurring && (
          <div className={tw`mt-[24px]`}>
            <QuantityInput useFormReturn={useFormReturn} disabled={disabled} />
          </div>
        )}
        <div className={tw`mt-[24px]`}>
          <LabelAboveInput uppercase={false} htmlFor="note">
            Additional Description
          </LabelAboveInput>
          <BasicTextarea {...register('note')} disabled={disabled} />
        </div>
        <div className={tw`mt-[32px]`}>
          <AnimatedPopupFormFooter
            yesBtn={{ props: { disabled } }}
            noBtn={{ action: closeFn }}
            formState={formState}
          />
        </div>
      </form>
    </BillingModal>
  )
}
