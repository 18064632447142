import { DataType } from '@augusthealth/models/com/august/protos/snapshot'
import { ScheduledTaskSettings } from '@shared/types/scheduled_task_settings'

export function getIdentifyingKey(
  settings: ScheduledTaskSettings
): [DataType, string | undefined] {
  return [
    settings.settings.scheduledTask.dataType,
    settings.settings.scheduledTask.customType,
  ]
}

export function getFilters(settings: ScheduledTaskSettings) {
  return settings.settings.scheduledTask.parameters.filters ?? []
}

export function getDaysToExpire(settings: ScheduledTaskSettings) {
  return settings.settings.scheduledTask.parameters.daysToExpire
}

export function getDaysInAdvance(settings: ScheduledTaskSettings) {
  return settings.settings.scheduledTask.parameters.daysInAdvance
}

export function getTasksToCreate(settings: ScheduledTaskSettings) {
  return settings.settings.scheduledTask.parameters.tasksToCreate
}
