import localForage from 'localforage'
import { useEffect } from 'react'
import SearchBox from '@shared/components/SearchBox'
import { tw } from '@shared/utils/tailwind'
import SortByDropdown from '@app/components/SortByDropdown'
import {
  Option,
  SNAPSHOT_SORT_OPTIONS,
  SnapshotSortOrder,
} from '@app/components/SortByDropdown/helpers'
import { useDocumentsContext } from '@app/contexts/DocumentsContext'

const SORT_SNAPSHOTS_STORAGE_NAME = 'sortSnapshotsOrder' as const

export default function SearchAndSortByControls() {
  const {
    documentFilter,
    setDocumentFilter,
    snapshotSortOrder,
    setSnapshotSortOrder,
  } = useDocumentsContext()

  useEffect(() => {
    void localForage.getItem(SORT_SNAPSHOTS_STORAGE_NAME).then((sortOrder) => {
      if (sortOrder) {
        setSnapshotSortOrder(sortOrder as SnapshotSortOrder)
      }
    })
  }, [])

  return (
    <div className={tw`flex`}>
      <div className={tw`flex w-[305px] items-center justify-end`}>
        <SearchBox
          onChange={setDocumentFilter}
          placeholder="Document name or keyword..."
          value={documentFilter}
        />
      </div>
      <div className={tw`ml-[8px]`}>
        <SortByDropdown
          options={SNAPSHOT_SORT_OPTIONS}
          value={
            SNAPSHOT_SORT_OPTIONS.find(
              (opt) => opt.value === snapshotSortOrder
            ) || null
          }
          onChange={(opt: Option | null) => {
            const selectedValue = opt?.value
              ? opt.value
              : SnapshotSortOrder.SNAPSHOT_SORT_BY_NAME_ASC
            setSnapshotSortOrder(selectedValue as SnapshotSortOrder)
            void localForage.setItem(SORT_SNAPSHOTS_STORAGE_NAME, selectedValue)
          }}
        />
      </div>
    </div>
  )
}
