import { HTMLAttributes, useRef } from 'react'
import {
  BasicInput,
  BasicInputProps,
} from '@shared/components/BasicInput/BasicInput'
import { twx } from '@shared/utils/tailwind'
import { Switch, SwitchProps } from '@app/components/Switch'
import styles from './styles.module.css'

type Props = {
  inputProps: BasicInputProps
  switchProps?: SwitchProps
  trashIconProps?: HTMLAttributes<HTMLButtonElement>
  holderClassName?: string
}

export default function DeletableInput(props: Props) {
  const inputRef = useRef(null)
  const {
    holderClassName: defaultHolderClassName,
    inputProps,
    switchProps,
    trashIconProps,
  } = props
  const switchHolder = switchProps ? (
    <Switch holderClassName="floating-switch" {...switchProps} />
  ) : undefined
  const trashIcon = trashIconProps ? (
    <button
      data-testid={`trash-icon-${inputProps.name}`}
      className={styles.iconContainer}
      type="button"
      {...trashIconProps}
    >
      <i className="fas fa-fw fa-trash-can" />
    </button>
  ) : undefined
  const { className, ...restInputProps } = inputProps
  const inputClasses = twx(className, {
    'pr-[200px]': !!switchProps,
    'pr-[48px]': !switchProps && trashIconProps,
  })

  return (
    <div className={twx('relative mb-[8px]', defaultHolderClassName)}>
      <BasicInput ref={inputRef} {...restInputProps} className={inputClasses} />
      {switchHolder}
      {trashIcon}
    </div>
  )
}
