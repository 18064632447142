import {
  AggregateResults,
  MetaData,
} from '@augusthealth/models/com/august/protos/api/json_report'
import {
  Report,
  Report_Aggregate as ReportAggregate,
} from '@augusthealth/models/com/august/protos/report'
import { Struct } from '@augusthealth/models/google/protobuf/struct'
import { ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import LoadingPopup from '@shared/components/LoadingPopup'
import GlobalContext from '@shared/contexts/GlobalContext'
import { triggerEventAfterImageLoaded } from '@shared/utils/common'
import { tw } from '@shared/utils/tailwind'
import { fetchReportJSON, previewReportJson } from '@app/api/reports'

type Props = {
  orgId: string
  facilityId: string
  name: string
  previewReport?: Report
}

export default function ReportTable(props: Props) {
  const { setError } = useContext(GlobalContext)
  const { orgId, facilityId, name, previewReport } = props
  const [aggregates, setAggregates] = useState<AggregateResults[]>()
  const [headers, setHeaders] = useState<string[]>()
  const [metadata, setMetadata] = useState<MetaData[]>()
  const [rows, setRows] = useState<Struct[]>()
  // domRef is used to verify that Report Table is loaded before printing
  const domRef = useCallback((node) => {
    if (node !== null) {
      triggerEventAfterImageLoaded('reportLoaded')
    }
  }, [])

  useEffect(() => {
    if (orgId && facilityId && name) {
      let jsonReport
      if (previewReport) {
        jsonReport = previewReportJson(orgId, facilityId, previewReport)
      } else {
        jsonReport = fetchReportJSON(orgId, facilityId, name)
      }
      jsonReport
        .then((res) => {
          const { aggregates: a, headers: h, metadata: m, rows: r } = res.data
          setAggregates(a)
          setHeaders(h)
          setMetadata(m || [])
          setRows(r || [])
        })
        .catch(setError)
    }
  }, [orgId, facilityId, name])

  if (rows && headers) {
    const ths = headers.map((n) => {
      const key = `th-${n}`
      return <th key={key}>{n}</th>
    })

    // Add an extra empty TH as first TH for Profile picture
    ths.unshift(<th key="profile-picture-th"></th>)

    const colSpan = headers.length + 1
    let tFoot
    let trs
    if (rows.length) {
      trs = rows.reduce((list: ReactNode[], d, trIndex) => {
        const tds = headers.map((key, tdIndex) => {
          const value = d[key]
          if (typeof value === 'string' && value.includes('\n')) {
            return (
              <td key={`td-${trIndex}-${tdIndex}`}>
                {value.split('\n').map((s, dIndex) => (
                  <div key={`d-${dIndex}`}>{s}</div>
                ))}
              </td>
            )
          }
          return <td key={`td-${trIndex}-${tdIndex}`}>{value}</td>
        })

        // Add an extra TD as first TD for Profile picture
        tds.unshift(
          <td key={`td-profile-picture-${trIndex}`} className={tw`align-top`}>
            {metadata && !!metadata[trIndex]?.profilePhotoUrl && (
              <img
                className={tw`m-auto w-[36px] min-w-[36px]`}
                src={metadata[trIndex].profilePhotoUrl}
                data-testid={`avatar-${trIndex}`}
              />
            )}
          </td>
        )

        list.push(<tr key={`tr-${trIndex}`}>{tds}</tr>)

        return list
      }, [])

      // Add Totals row if aggregates exist
      if (aggregates?.length) {
        const aggTds = headers.map((h, i) => {
          const key = `td-aggregate-${h}-${i}`
          const agg = aggregates.find((a) => a.header === h)
          if (agg) {
            const { aggregate, value } = agg
            if (
              aggregate === ReportAggregate.AGGREGATE_SUM ||
              aggregate === ReportAggregate.AGGREGATE_COUNT
            ) {
              return (
                <td key={key} className="font-bold">
                  {value}
                </td>
              )
            }
          }

          return <td key={key} />
        })

        tFoot = (
          <tfoot>
            <tr key="tr-total-row">
              <th className="uppercase">Totals</th>
              <th colSpan={colSpan} />
            </tr>
            <tr key="tr-aggregates-row">{aggTds}</tr>
          </tfoot>
        )
      }
    } else {
      trs = [
        <tr key={`tr-empty-0`}>
          <td colSpan={colSpan}>
            <i className="far fa-ellipsis-v"></i>
          </td>
        </tr>,
      ]
    }

    return (
      <div className="details-table-holder animate-fade-in">
        <table className="table-cell-border details-table table" ref={domRef}>
          <thead>
            <tr>{ths}</tr>
          </thead>
          <tbody>{trs}</tbody>
          {tFoot}
        </table>
      </div>
    )
  }

  return <LoadingPopup className="bg-transparent" loading />
}
