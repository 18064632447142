import { Link } from 'react-router-dom'
import { notesPathForPerson } from '@shared/legacy_routes'
import { Incident } from '@shared/types/incidents'
import { Person } from '@shared/types/person'
import { TaskTemplateInfo } from '@shared/types/task'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { displayResidentStatus } from '@shared/utils/person'
import styles from '../styles.module.css'
import FollowUpActions from './FollowUpActions/FollowUpActions'
import { FrontendIncident } from './helpers'
import { useIncidentCardContext } from './IncidentCardContext'
import IncidentDocuments from './IncidentDocuments'

export default function IncidentBody({
  data,
  onUpdate,
  creatableTasks,
  includePersonLink = false,
  onClickEditFollowUpActions,
}: {
  data: FrontendIncident
  onUpdate: (newIncident: Incident) => Promise<void>
  creatableTasks: TaskTemplateInfo[]
  includePersonLink?: boolean
  onClickEditFollowUpActions: () => void
}) {
  const { person } = useIncidentCardContext()
  const { attachments, note, injuries, date, location } = data

  return (
    <>
      <div className={styles.detailsContainer}>
        {includePersonLink && (
          <div className={styles.details}>
            <div className="w-[144px] font-semibold uppercase text-gray-06">
              {displayResidentStatus(person)}
            </div>
            <div className="font-medium text-gray-04">
              <Link to={notesPathForPerson(person as Required<Person>)}>
                {getFirstAndLastName(person.name)}
              </Link>
            </div>
          </div>
        )}
        <div className={styles.details}>
          <div className="w-[144px] font-semibold uppercase text-gray-06">
            Notes
          </div>
          <div
            style={{ maxWidth: '65%' }}
            className="whitespace-pre-wrap font-medium text-gray-04"
          >
            {note}
          </div>
        </div>
        <div className={styles.details}>
          <div className="w-[144px] font-semibold uppercase text-gray-06">
            Date
          </div>
          <div className="font-medium text-gray-04">{date}</div>
        </div>
        {location && (
          <div className={styles.details}>
            <div className="w-[144px] font-semibold uppercase text-gray-06">
              Location
            </div>
            <div className="font-medium text-gray-04">{location}</div>
          </div>
        )}
        {injuries && (
          <div className={styles.details}>
            <div className="w-[144px] font-semibold uppercase text-gray-06">
              Injuries
            </div>
            <div className="font-medium text-gray-04">{injuries}</div>
          </div>
        )}
      </div>
      <div className="mt-[32px]">
        <FollowUpActions
          onClickEdit={onClickEditFollowUpActions}
          onUpdate={onUpdate}
          creatableTasks={creatableTasks}
        />
      </div>
      {!!attachments && attachments.length > 0 && (
        <div className="mt-[32px]">
          <IncidentDocuments
            person={person}
            incident={data as Incident}
            onUpdate={onUpdate}
          />
        </div>
      )}
    </>
  )
}
