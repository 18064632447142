import { sortBy } from 'lodash'
import { DataType } from '@shared/types/snapshot'

export const taskViaUploadDataTypes = [
  DataType.DATA_TYPE_CA_FORM_601,
  DataType.DATA_TYPE_CA_FORM_603,
  DataType.DATA_TYPE_CA_FORM_603A,
]

// Use in src/app/pages/Documents/Viewer/NamedViewer.tsx only
// POLST has its own src/app/pages/Documents/Viewer/POLSTViewer.tsx
export const generalUploadOptions = sortBy(
  [
    {
      label: 'Advanced Directives and Power of Attorney',
      value: DataType.DATA_TYPE_DIRECTIVE,
    },
    {
      label: 'Insurance Card',
      value: DataType.DATA_TYPE_INSURANCE_CARD,
    },
    {
      label: 'Medical Record',
      value: DataType.DATA_TYPE_MEDICAL_RECORD,
    },
    {
      label: 'Identification and Emergency Information',
      value: DataType.DATA_TYPE_CA_FORM_601,
    },
    {
      label: 'Pre-Placement Appraisal',
      value: DataType.DATA_TYPE_CA_FORM_603,
    },
    {
      label: 'Resident Appraisal',
      value: DataType.DATA_TYPE_CA_FORM_603A,
    },
    {
      label: "Physician's Report",
      value: DataType.DATA_TYPE_CA_FORM_602,
    },
    {
      label: 'PRN Authorization',
      value: DataType.DATA_TYPE_PRN_AUTHORIZATION,
    },
    {
      label: 'Nurse Delegation',
      value: DataType.DATA_TYPE_NURSE_DELEGATION,
    },
  ],
  'label'
).concat({ label: 'Other', value: DataType.DATA_TYPE_OTHER_UPLOAD })
