import { Address } from '@augusthealth/models/com/august/protos/address'
import { Link } from 'react-router-dom'
import { contactsPathForPerson } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { getResponsiblePerson } from '@shared/utils/person'
import AddressAutocomplete from '../AddressAutocomplete'
import ElementHolder from '../ElementHolder'

type Props = {
  name: string
  onUpdate: (value: Address, name: string) => void
  person: Person
}

export default function RpAddressAutoComplete(props: Props) {
  const { person, ...restProps } = props
  const rp = getResponsiblePerson(person)

  if (rp) {
    return <AddressAutocomplete {...restProps} />
  }

  return (
    <ElementHolder {...restProps}>
      <div>
        Responsible person not found,&nbsp;
        <Link to={contactsPathForPerson(person as Required<Person>)}>
          please create a responsible person first!
        </Link>
      </div>
    </ElementHolder>
  )
}
