import { useEffect } from 'react'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { Organization } from '@shared/types/organization'
import useOrganizations from '@app/hooks/useOrganizations'

type Props = {
  prepopulateWithDefaults?: boolean
  onOrgSelect: (org: OptionTypeBase<Organization> | null) => void
  orgLabel?: string
  selectClassName?: string
  selectedOrganization: OptionTypeBase<Organization> | null
  showInactive?: boolean
}

const DEFAULT_ORG_ID = '2' // August Org
export const BaseOrgSelect = ({
  prepopulateWithDefaults = false,
  selectClassName,
  onOrgSelect,
  orgLabel,
  selectedOrganization,
  showInactive,
}: Props) => {
  const { organizations: activeOrganizations, allOrganizations } =
    useOrganizations([])

  const organizations = showInactive ? allOrganizations : activeOrganizations

  useEffect(() => {
    if (organizations.tag === 'Complete' && prepopulateWithDefaults) {
      const matchingOrg =
        organizations.value.find((org) => org.id === DEFAULT_ORG_ID) ||
        organizations.value[0]

      const orgToSave = matchingOrg
        ? { label: matchingOrg.name!, value: matchingOrg }
        : undefined

      if (orgToSave) {
        onOrgSelect(orgToSave)
      }
    }
  }, [organizations, prepopulateWithDefaults])

  return (
    <>
      {orgLabel && (
        <LabelAboveInput htmlFor={'organizations'}>{orgLabel}</LabelAboveInput>
      )}
      <StyledSelect
        isClearable
        placeholder="Select Organization..."
        options={
          organizations?.tag === 'Complete'
            ? organizations.value.map((o) => {
                return {
                  label: o.name!,
                  value: o,
                  subLabel: !o.isActive ? 'Inactive' : undefined,
                }
              })
            : []
        }
        className={selectClassName}
        name={'organizations'}
        id={'organizations'}
        instanceId={'organizations'}
        onChange={(o: OptionTypeBase<Organization>) => {
          onOrgSelect(o)
        }}
        value={selectedOrganization}
      />
    </>
  )
}
