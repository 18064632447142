import { AugustInitialAppraisal } from '@augusthealth/models/com/august/protos/august_initial_appraisal'
import { useEffect, useState } from 'react'
import {
  ActionButtonHolder,
  PrintButton,
} from '@shared/components/AnimatedPopup/Lightbox/ActionButtons/ActionButtons'
import Lightbox, {
  LightboxFooterButton,
  LightboxSidebarContent,
  LightboxSidebarFooter,
} from '@shared/components/AnimatedPopup/Lightbox/Lightbox'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { Person } from '@shared/types/person'
import { Snapshot } from '@shared/types/snapshot'
import { GtkyViewerMode } from '@shared/utils/gtky'
import { getFirstAndLastName } from '@shared/utils/humanName'
import Print, { printSelectedElements } from '@app/components/Printable'
import useAssessment from '@app/hooks/useAssessment'
import GettingToKnowYouPageProducer from '@app/pages/Tasks/GettingToKnowYouV2/GettingToKnowYouPageProducer'
import ResidentTaskHeader from '@app/pages/Tasks/ResidentTaskHeader/ResidentTaskHeader'
import { GettingToKnowYouSteps } from '@app/pages/Tasks/TaskPageNavigation/step-definitions'

interface Props {
  person: Person
  gettingToKnowYou: Snapshot
  title: string
  mode: GtkyViewerMode
}

export default function GettingToKnowYouViewerV2({
  person,
  gettingToKnowYou,
  title,
  mode,
}: Props) {
  const [printGTKU, setPrintGTKU] = useState(false)
  const [currentAssessment, setCurrentAssessment] =
    useState<AugustInitialAppraisal>()
  const { assessment } = useAssessment({ person })

  useEffect(() => {
    if (assessment.tag === 'Complete') {
      setCurrentAssessment(assessment.value.appraisal)
    }
  }, [assessment.tag])

  return (
    <>
      <Lightbox
        dataCy="gtky-viewer"
        mainContent={
          <GettingToKnowYouPages snapshot={gettingToKnowYou} person={person} />
        }
        sidebarContent={
          <>
            <LightboxSidebarContent
              title={
                <>
                  {title}
                  <div className="text-[16px] leading-[24px]">
                    {getFirstAndLastName(person.name)}
                  </div>
                </>
              }
              message={
                mode.tag === 'review' && (
                  <>
                    <div>Please review the document</div>
                    <div>
                      If everything looks correct, finalize the document.
                    </div>
                  </>
                )
              }
            >
              <ActionButtonHolder showTopHR={mode.tag === 'review'}>
                <PrintButton
                  onClick={() => printSelectedElements(setPrintGTKU)}
                />
              </ActionButtonHolder>
            </LightboxSidebarContent>
            <LightboxSidebarFooter className="flex-col">
              {mode.tag === 'view' && (
                <LightboxFooterButton onClick={mode.onClose} />
              )}
              {mode.tag === 'review' && (
                <>
                  <AsyncIconButton
                    className={'mb-[12px] grow'}
                    buttonStyle={'primary-fill'}
                    onClick={mode.onComplete}
                  >
                    Finalize
                  </AsyncIconButton>
                  <LightboxFooterButton onClick={mode.onMakeChanges}>
                    Make Changes
                  </LightboxFooterButton>
                </>
              )}
            </LightboxSidebarFooter>
          </>
        }
        onClose={mode.tag === 'view' ? mode.onClose : mode.onMakeChanges}
        mainContentBackgroundColor="white"
      />
      {printGTKU && (
        <Print>
          <div
            style={{
              margin: '50px 75px',
            }}
          >
            <ResidentTaskHeader
              person={person}
              assessment={currentAssessment}
            />
            <GettingToKnowYouPages
              snapshot={gettingToKnowYou}
              person={person}
            />
          </div>
        </Print>
      )}
    </>
  )
}

const GettingToKnowYouPages = ({
  snapshot,
  person,
}: {
  snapshot: Snapshot
  person: Person
}) => {
  return (
    <div className={'h-fit flex-col'}>
      {GettingToKnowYouSteps.slice(0, -1).map((step, index) => {
        return (
          <GettingToKnowYouPageProducer
            key={`${step}-${index}`}
            mode={{ tag: 'view' }}
            snapshot={snapshot}
            person={person}
            currentStepNumber={index + 1}
          />
        )
      })}
    </div>
  )
}
