import { Contact_ContactRelationship } from '@augusthealth/models/com/august/protos/contact'
import { fetchConsentForms, mergePatchConsentForms } from '@shared/api/person'
import { filterOutEmptyAllergies } from '@shared/utils/allergyIntolerance'
import { contactUpdater } from '../contactHelpers'
import FormLayout from '../FormLayout'
import {
  FormPageProps,
  PageGeneratorItem,
  UpdaterProps,
} from '../FormLayout/type'
import consentFormsConf from './json/consentForms.json'

export default function Consent(props: FormPageProps) {
  const formList: PageGeneratorItem[] = [
    {
      menuTitle: '1. PREPARE CONSENT FORMS',
      pageTitle: 'PREPARE CONSENT FORMS',
      json: consentFormsConf,
      loader: fetchConsentForms,
      updater: (params: UpdaterProps) => {
        const { name } = params
        if (
          name.includes(
            Contact_ContactRelationship.CONTACT_RELATIONSHIP_GENERAL_PRACTITIONER
          )
        ) {
          return contactUpdater(params).then(() => fetchConsentForms(params))
        } else {
          const updatedParams = { ...params }
          updatedParams.patch = filterOutEmptyAllergies(updatedParams.patch)
          return mergePatchConsentForms(updatedParams)
        }
      },
    },
    {
      menuTitle: '2. REVIEW AND SIGN',
      pageTitle: 'REVIEW AND SIGN',
      content: 'Review',
    },
  ]

  return (
    <FormLayout
      {...props}
      configuration={formList}
      returnUpdatedAttributeOnly
    />
  )
}
