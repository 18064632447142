import { SignerRole } from '@shared/types/snapshot'

export function appendExternalRoles({
  roles,
  numberOfExternalSigners,
}: {
  roles: SignerRole[]
  numberOfExternalSigners: number | undefined
}) {
  const numberOfExternalSignersInt = numberOfExternalSigners || 0

  return roles.flatMap((r) => {
    if (r === SignerRole.SIGNER_ROLE_EXTERNAL) {
      return Array.from(
        { length: +numberOfExternalSignersInt },
        () => SignerRole.SIGNER_ROLE_EXTERNAL
      )
    } else {
      return [r]
    }
  })
}
