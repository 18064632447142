import { SignableFormMetaData } from '@augusthealth/models/com/august/protos/signable_form'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import Card from '@shared/components/Card'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import SearchBox from '@shared/components/SearchBox'
import { tw } from '@shared/utils/tailwind'
import PersonPageTitle from '@app/components/PersonPageTitle'
import { TemplateLimit } from '@app/pages/Tools/PdfTemplate/helpers'
import { filterBySearchTerm } from '@app/pages/Tools/PdfTemplate/TemplateList/helpers'
import { useSignableFormContext } from '../SignableFormContext'
import PreviewLightbox from './PreviewLightbox'
import Item from './TemplateItem'

export default function TemplateList({ show }: { show: TemplateLimit }) {
  const [search, setSearch] = useState<string>('')
  const {
    signableFormsWithAssignments: formsWithAssignments,
    reloadSignableForms: reload,
  } = useSignableFormContext(show)
  const [selectedForm, setSelectedForm] = useState<
    SignableFormMetaData | undefined
  >()

  useEffect(() => {
    if (formsWithAssignments.tag !== 'Loading') {
      void reload()
    }
  }, [])

  if (formsWithAssignments.tag === 'Loading') {
    return <SimpleSpinner />
  }

  const closeLightbox = () => {
    setSelectedForm(undefined)
  }

  const iconClassList = classNames(
    'fas fa-sync-alt cursor-pointer text-[20px]',
    {
      'fa-spin': formsWithAssignments.tag === 'LoadingWithPriorValue',
    }
  )

  const filteredForms = formsWithAssignments.value.filter((form) =>
    filterBySearchTerm({ search, form })
  )

  return (
    <div className="mt-[24px]">
      <PersonPageTitle subtitle title={show}>
        <div className={tw`flex`}>
          <SearchBox
            holderClassName={tw`mr-[8px]`}
            onChange={setSearch}
            value={search}
          />
          <ButtonLink
            onClick={async () => {
              await reload()
            }}
          >
            <i className={iconClassList} />
          </ButtonLink>
        </div>
      </PersonPageTitle>
      {filteredForms.map((fwa) => (
        <Item
          key={`template-${fwa.signableForm.id}`}
          reload={reload}
          setSelectedTemplate={setSelectedForm}
          formWithAssignments={fwa}
          copiedFromForm={
            formsWithAssignments.value.find(
              (f) =>
                f.signableForm.id === fwa.signableForm.copiedFromSignableFormId
            )?.signableForm
          }
        />
      ))}
      {filteredForms.length === 0 && (
        <Card>
          No template matches for <code className={tw`ml-[4px]`}>{search}</code>
          .
        </Card>
      )}
      {selectedForm && (
        <PreviewLightbox
          {...selectedForm}
          onClose={closeLightbox}
          reload={reload}
        />
      )}
    </div>
  )
}
