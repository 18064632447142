import { DateMessage, ValidDate } from '@shared/types/date'

export const validStringOrUndefined = (
  value: string | undefined | null
): string | undefined => {
  if (!value || value.trim().length === 0) {
    return undefined
  }
  return value.trim()
}

export const validStringOrNull = (
  value: string | undefined | null
): string | null => {
  return hasValidStringValue(value) ? (value as string) : null
}

export const hasValidStringValue = (
  value: string | undefined | null
): boolean => {
  return value ? value.trim().length > 0 : false
}

export const validNumberOrNull = (
  value: string | number | undefined | null
): number | null => {
  return hasValidNumberValue(value) ? (value as number) : null
}

export const hasValidNumberValue = (
  value: string | number | undefined | null
): boolean => {
  return !(value === undefined || value === null || isNaN(value as number))
}

export const validDateMessageOrNull = (
  dateMessage?: DateMessage
): ValidDate | null => {
  if (dateMessage && dateMessage.day && dateMessage.year && dateMessage.month) {
    return dateMessage as ValidDate
  }

  return null
}
