import { Report } from '@augusthealth/models/com/august/protos/report'
import { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ButtonLink from '@shared/components/ButtonLink'
import SearchBox from '@shared/components/SearchBox'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Organization } from '@shared/types/organization'
import { listOrganizations } from '@app/api/organizations'
import useAllFacilities from '@app/hooks/useAllFacilities'
import useAllReportAssignments from '@app/hooks/useAllReportAssignments'
import ReportTable from './ReportTable'

type Props = {
  reports: Report[] | undefined
}

export default function ReportAssignments({ reports = [] }: Props) {
  const history = useHistory()
  const { reportId } = useParams<{ reportId?: string }>()
  const { setError } = useContext(GlobalContext)
  const [orgNameSearch, setOrgNameSearch] = useState('')
  const [orgs, setOrgs] = useState<Organization[]>([])

  useEffect(() => {
    listOrganizations().then(setOrgs).catch(setError)
  }, [])

  const allFacilitiesData = useAllFacilities({ orgs })
  const allFacilities =
    (allFacilitiesData.tag === 'Complete' && allFacilitiesData.value) || []
  const { assignments, loadAssignments } = useAllReportAssignments({
    facilities: allFacilities,
  })

  let orgsToShow = orgs
  if (orgNameSearch) {
    orgsToShow = orgs.filter((o) =>
      o.name?.toLowerCase().includes(orgNameSearch.toLocaleLowerCase())
    )
  }

  return (
    <>
      <h4 className="page-title horizontal mt-[24px]">
        <div>Report Assignment</div>
        {reportId && (
          <ButtonLink onClick={() => history.goBack()}>Back</ButtonLink>
        )}
      </h4>
      <div className="mb-[16px]">
        <SearchBox
          onChange={setOrgNameSearch}
          placeholder="Filter by Organization name"
          value={orgNameSearch}
        />
      </div>
      <ReportTable
        assignments={
          (assignments.tag === 'Complete' && assignments.value) || []
        }
        facilities={allFacilities}
        organizations={orgsToShow}
        reports={reportId ? reports.filter((r) => r.id === reportId) : reports}
        reload={loadAssignments}
      />
    </>
  )
}
