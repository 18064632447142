import { Charge } from '@augusthealth/models/com/august/protos/charge'
import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { getOnetimeCharges, getRecurringCharges } from '@app/api/charges'
import { isRecurring } from './helpers'
import { OneTimeCharge, toOneTimeCharge } from './OneTimeCharge'
import { RecurringCharge, toRecurringCharge } from './RecurringCharge'

export function useCharges(person: Person, deps: React.DependencyList = []) {
  const [oneTimeCharges, setOneTimeCharges] = useState<Charge[]>()
  const [recurringCharges, setRecurringCharges] = useState<Charge[]>()
  const { setError } = useContext(GlobalContext)

  useEffect(() => {
    getRecurringCharges(person)
      .then(setRecurringCharges)
      .catch((e) => {
        setError(e)
        setRecurringCharges([])
      })

    getOnetimeCharges(person)
      .then(setOneTimeCharges)
      .catch((e) => {
        setError(e)
        setOneTimeCharges([])
      })
  }, [person, ...deps])

  const getOriginalCharge = (
    charge: OneTimeCharge | RecurringCharge
  ): Charge | undefined => {
    if (isRecurring(charge)) {
      return recurringCharges?.find(
        (recurringCharge) => recurringCharge.id === charge.id
      )
    } else {
      return oneTimeCharges?.find(
        (oneTimeCharge) => oneTimeCharge.id === charge.id
      )
    }
  }

  return {
    onetimeCharges: oneTimeCharges?.map(toOneTimeCharge),
    recurringCharges: recurringCharges?.map(toRecurringCharge),
    getOriginalCharge,
  }
}
