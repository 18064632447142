import { useEffect, useState } from 'react'
import { SignableFormWithAssignments } from '@shared/types/signable_form'
import { LoadingWithPriorValue } from '@shared/utils/loading'
import { getFormWithAssignments } from '@app/api/form'
import { TemplateLimit } from '@app/pages/Tools/PdfTemplate/helpers'

const RECENT_TEMPLATES_LIMIT = 250
const ALL_TEMPLATES_LIMIT = 9999

export default function useSignableForms(templates: TemplateLimit) {
  const [formList, setFormList] = useState<
    LoadingWithPriorValue<SignableFormWithAssignments[]>
  >({
    tag: 'Loading',
  })

  const reloadFormList = (updatedForm?: SignableFormWithAssignments) => {
    // Don't reload if updateForm is received for better performance
    if (updatedForm && formList.tag === 'Complete') {
      setFormList({
        tag: 'Complete',
        value: formList.value.map((f) => {
          if (f.signableForm.id === updatedForm.signableForm.id) {
            return updatedForm
          }

          return f
        }),
      })

      return Promise.resolve()
    }

    setFormList((previous) => {
      if (previous.tag === 'Complete') {
        return {
          tag: 'LoadingWithPriorValue',
          value: previous.value,
        }
      }

      return {
        tag: 'Loading',
      }
    })

    const limit =
      templates === 'All Templates'
        ? ALL_TEMPLATES_LIMIT
        : RECENT_TEMPLATES_LIMIT

    return getFormWithAssignments(limit).then((value) =>
      setFormList({ tag: 'Complete', value })
    )
  }

  useEffect(() => {
    void reloadFormList()
  }, [])

  return {
    formList,
    reloadFormList,
  }
}
