import { useContext, useEffect, useState } from 'react'
import { getPayerUserStatements } from '@shared/api/billing'
import GlobalContext from '@shared/contexts/GlobalContext'
import { PayerDetailedStatement } from '@shared/types/billing'
import { loaded, LOADING, Loading } from '@shared/utils/loading'

type Props = {
  orgId: string
  facilityId: string
  personId: string
}

export default function useBillingPayerUserStatements(props: Props) {
  const [billingPayerUserStatements, setBillingPayerUserStatements] =
    useState<Loading<PayerDetailedStatement>>(LOADING)
  const { setError } = useContext(GlobalContext)

  async function reloadBillingPayerUserStatements() {
    try {
      const result = await getPayerUserStatements(props)
      setBillingPayerUserStatements(loaded(result))
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    void reloadBillingPayerUserStatements()
  }, [])

  return {
    billingPayerUserStatements,
    reloadBillingPayerUserStatements,
  }
}
