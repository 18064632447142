import { tw } from '@shared/utils/tailwind'

export default function CareRow({
  label,
  children,
}: {
  label: string
  children: React.ReactNode
}) {
  return (
    <div className={tw`mt-[4px] flex text-[14px] leading-[20px]`}>
      <div className={tw`w-[130px] font-semibold`}>{label}</div>
      <div className={tw`font-medium`}>{children}</div>
    </div>
  )
}
