import { Link } from 'react-router-dom'
import { contactsPathForPerson } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { getFullName } from '@shared/utils/humanName'
import { tw } from '@shared/utils/tailwind'
import {
  ModalOverlays,
  ModalOverlaysParagraph,
} from '@app/components/ModalOverlays'

export function MissingRpOverlays({ person }: { person: Person }) {
  return (
    <ModalOverlays
      data-testid={'missingRP'}
      title={`Missing Responsible Person for ${getFullName(person.name)}`}
    >
      <ModalOverlaysParagraph>
        Please{' '}
        <Link
          className={tw`font-semibold hover:no-underline`}
          to={contactsPathForPerson(person as Required<Person>)}
        >
          add a responsible person
        </Link>{' '}
        before requesting a signature.
      </ModalOverlaysParagraph>
    </ModalOverlays>
  )
}
