import { FieldErrors } from 'react-hook-form'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import { UserAccount } from '@shared/types/user'

export default function FullNameInputs({
  disabled = false,
  errors,
  name = 'name',
  register,
}: {
  disabled?: boolean
  errors: FieldErrors<UserAccount>
  name?: string
  register: any
}) {
  const firstName = `${name}.given[0]`
  const lastName = `${name}.family`

  return (
    <div className="mb-[32px]">
      <div className="mb-[32px]">
        <LabelAboveInput
          htmlFor={firstName}
          subLabel={requiredLabel(errors[name]?.given)}
        >
          First Name
        </LabelAboveInput>
        <BasicInput
          disabled={disabled}
          id={firstName}
          {...register(firstName, { required: true })}
        />
      </div>
      <div>
        <LabelAboveInput
          htmlFor={lastName}
          subLabel={requiredLabel(errors[name]?.family)}
        >
          Last Name
        </LabelAboveInput>
        <BasicInput
          disabled={disabled}
          id={lastName}
          {...register(lastName, { required: true })}
        />
      </div>
    </div>
  )
}
