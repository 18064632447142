import { ClassNameValue } from 'tailwind-merge'
import { twx } from '@shared/utils/tailwind'

export function DotDivider({ className }: { className?: ClassNameValue }) {
  return <span className={twx('mx-2', className)}>·</span>
}

export function SmallDotDivider({ className }: { className?: ClassNameValue }) {
  return <span className={twx('mx-1', className)}>·</span>
}

export function BigDotDivider({ className }: { className?: ClassNameValue }) {
  return <span className={twx('mx-2', className)}>•</span>
}
