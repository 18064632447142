import { Charge } from '@augusthealth/models/com/august/protos/charge'
import { centsToMoney, nameForChargeType } from '@shared/utils/charge'
import { firstLastName } from '@shared/utils/contact'
import { monthDayYear } from '@shared/utils/date'

export interface OneTimeCharge {
  id: string
  date: string
  title: string
  subtitle?: string
  payer: string
  amount: string
}

export function toOneTimeCharge(onetimeCharge: Charge): OneTimeCharge {
  if (onetimeCharge.oneTime === undefined) {
    throw new Error('Cannot convert recurring charge to OnetimeRow')
  }

  return {
    id: onetimeCharge.id!,
    date: monthDayYear(onetimeCharge.oneTime.date!),
    title: nameForChargeType(onetimeCharge.type!),
    subtitle: onetimeCharge.notes,
    payer: onetimeCharge.payer ? firstLastName(onetimeCharge.payer) : 'Unknown',
    amount: centsToMoney(onetimeCharge.oneTime.amount!),
  }
}
