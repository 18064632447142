import { PayerBillingEvent } from '@shared/types/billing'
import { Loading } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import { Table, TableContainer } from '@app/components/Table'
import TableHeader from '@app/components/Table/TableHeader'
import TransactionRow from './TransactionRow'

type Props = {
  billingPayerUserTransactions: Loading<PayerBillingEvent[]>
  reload: () => Promise<void>
}

export default function TransactionsTable(props: Props) {
  const { billingPayerUserTransactions } = props

  return (
    <div data-testid="transactions-table">
      <h2 className={tw`mb-0 text-2xl font-medium leading-9 text-secondary-02`}>
        Transactions
      </h2>
      {billingPayerUserTransactions.tag === 'Loading' ? (
        <div className={tw`mt-4 text-center text-gray-04`}>Loading...</div>
      ) : (
        <TableContainer>
          <Table className="table-auto">
            <TableHeader
              headers={[
                { label: 'Date', className: tw`w-[15%]` },
                { label: 'Description', className: tw`w-[50%]` },
                { label: 'Balance', className: tw`w-[10%]` },
                { label: '', className: tw`w-[10%]` },
              ]}
            />
            <tbody>
              {billingPayerUserTransactions.value.map((transaction, index) => (
                <TransactionRow key={index} transaction={transaction} />
              ))}
            </tbody>
          </Table>
        </TableContainer>
      )}
    </div>
  )
}
