import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Incident } from '@shared/types/incidents'
import { PersonIds } from '@shared/types/person'
import { Loading } from '@shared/utils/loading'
import { getIncident } from '../api/incidents'

export default function useIncident(
  person: Required<PersonIds>,
  incidentId: string
) {
  const { setError } = useContext(GlobalContext)
  const [incident, setIncident] = useState<Loading<Incident>>({
    tag: 'Loading',
  })

  useEffect(() => {
    getIncident({ person, incident: { id: incidentId } })
      .then((value) => setIncident({ tag: 'Complete', value }))
      .catch(setError)
  }, [incidentId])

  return incident
}
