import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { frequencyText, remainingAlertTime } from '@shared/utils/incident'
import { useIncidentCardContext } from '@app/pages/Notes/IncidentCard/IncidentCardContext'
import styles from '@app/pages/Notes/styles.module.css'

export default function AlertBanner({
  onEditAlert,
}: {
  onEditAlert: () => void
}) {
  const { incident, person } = useIncidentCardContext()
  const frequencyString = frequencyText(incident)

  return (
    <div className={styles.alertBanner}>
      <div className={'flex flex-col'}>
        <p
          className={'m-0 text-[14px] font-semibold leading-[16px] text-alert'}
        >
          On alert for another
          <i className={'fa fa-clock ml-[6px] mr-[6px]'} />
          {remainingAlertTime(incident)}
        </p>
        <p className={'mb-0 mt-[8px] text-[14px] font-medium leading-[20px]'}>
          {incident.alert?.note} &ndash; {frequencyString}
        </p>
      </div>
      <PersonPermissionGate
        person={person}
        permissions={[GroupPermission.GROUP_PERMISSION_INCIDENT_ON_ALERT]}
      >
        <div>
          <AsyncIconButton
            isLoading={false}
            buttonStyle={'tertiary-outline'}
            buttonSize={'xsmall'}
            initialIcon={'fa-pencil'}
            data-cy="edit-alert"
            onClick={onEditAlert}
          >
            Edit Alert
          </AsyncIconButton>
        </div>
      </PersonPermissionGate>
    </div>
  )
}
