import { useEffect, useState } from 'react'
import { Document, DocumentProps, Page } from 'react-pdf'
import ErrorMonitoring from '@shared/ErrorMonitoring'
import styles from './styles.module.css'

type Props = {
  pdfPath: string
  scale: number
  width?: number
  documentProps: Partial<DocumentProps>
  setIsLoaded?: (loaded?: boolean) => void
  subHeaderComponent?: React.ReactNode
}

const documentOptions = {
  standardFontDataUrl: `/standard_fonts/`,
}

export default function PDFReader({
  pdfPath,
  width = undefined,
  scale = 1,
  documentProps,
  setIsLoaded,
  subHeaderComponent,
}: Props) {
  const [numberOfPages, setNumberOfPages] = useState(1)
  const pages: React.ReactNode[] = []
  for (let i = 0; i < numberOfPages; i++) {
    pages.push(
      <Page
        key={`pdf-${i}`}
        className={styles.pdfPage}
        pageIndex={i}
        scale={scale}
        width={width}
        renderTextLayer={false}
        renderAnnotationLayer={false}
      />
    )
  }

  useEffect(() => {
    if (setIsLoaded) {
      setIsLoaded(false)
    }
  }, [pdfPath])

  // loading={<span />} (removing default) vs loading={spinner} to prevent Spinner jump up and down
  // Spinner should be set in the Parent component
  return (
    <ErrorMonitoring.Boundary fallback={<span>Unable to load document</span>}>
      <Document
        file={pdfPath}
        onLoadSuccess={(pdf) => {
          setNumberOfPages(pdf.numPages)
          if (setIsLoaded) {
            setIsLoaded(true)
          }
        }}
        loading={<span />}
        noData="Document not found"
        className={styles.pdfDocument}
        options={documentOptions}
        {...documentProps}
      >
        {subHeaderComponent}
        {pages}
      </Document>
    </ErrorMonitoring.Boundary>
  )
}
