import { DoseAndRate } from '@augusthealth/models/com/august/protos/dosage'
import { isAfter } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { DosageV2, SlidingScaleEntry } from '@shared/types/dosage'
import {
  MedicationOrder,
  VitalMedicationOrder,
} from '@shared/types/medication_order'
import { AdditionalInstructions } from '@shared/types/medication_statement'
import { PharmacyPartner } from '@shared/types/pharmacy_partner'
import { VitalInstruction } from '@shared/types/vital'
import { fromDateTimeToDate, isWithinInterval } from '@shared/utils/date'
import { getMedicationName } from '@shared/utils/medications'
import {
  isExternal,
  isVitalOrder,
  statementHasPrn,
} from '@shared/utils/medicationStatement'
import notEmpty from '@shared/utils/notEmpty'
import pluralize from '@shared/utils/pluralize'
import { escapeStringRegexp } from '@shared/utils/regex'
import { vitalEnumToLabel } from '@shared/utils/vitals'
import { MedicationOrderRow } from '@emar/types/db'

/**
 * Returns the full name of the medication (drug name and alternate name if present) or readable vital type
 */
export const getMedicationOrderName = (order: MedicationOrder): string => {
  if (isVitalOrder(order)) {
    return vitalEnumToLabel(order.medicationStatement.vitalType)
  }

  return getMedicationName(order.medicationStatement.medication)
}
/**
 * Returns a title and subtitle for a medication order
 * Title is the full name of the order
 * subtitle is the strength and form when not present in the full medication name
 * @param order
 */
export const getMedicationTitleAndSubTitle = (
  order: MedicationOrder
): { title: string; subTitle: string } => {
  if (isVitalOrder(order)) {
    return {
      title: `Record ${getMedicationOrderName(order)}`,
      subTitle: '',
    }
  }

  const strengthAndForm = order.medicationStatement.medication?.strengthAndForm

  const isSuiteRx =
    order.sourceData?.integrationSource ===
    PharmacyPartner.PHARMACY_PARTNER_SUITE_RX

  const containsStrengthAndFormInTitle = isExternal(order) && !isSuiteRx

  return {
    title: getMedicationOrderName(order),
    subTitle:
      !containsStrengthAndFormInTitle && strengthAndForm ? strengthAndForm : '',
  }
}

export const medicationOrderDescriptor = (
  order: Pick<MedicationOrder, 'medicationStatement'>
): string => {
  const { title, subTitle } = getMedicationTitleAndSubTitle(
    order as MedicationOrder
  )

  return [title, subTitle].filter(notEmpty).join(' ').trim()
}

export const orderHasPrn = (order: MedicationOrder): boolean =>
  statementHasPrn(order.medicationStatement)

export const orderIsCurrentlyOnHoldAtFacility = (
  order: MedicationOrder | MedicationOrderRow,
  facilityTimeZone: string
): boolean => {
  const currentHoldDetail = order.medicationStatement.onHold?.onHoldDetail

  if (currentHoldDetail) {
    const nowAtFacility = utcToZonedTime(new Date(), facilityTimeZone)

    const holdStart = currentHoldDetail.start
      ? fromDateTimeToDate(currentHoldDetail.start)
      : undefined
    const holdEnd = currentHoldDetail.end
      ? fromDateTimeToDate(currentHoldDetail.end)
      : undefined

    if (holdStart && holdEnd) {
      return isWithinInterval(nowAtFacility, {
        start: holdStart,
        end: holdEnd,
      })
    } else if (holdStart) {
      return isAfter(nowAtFacility, holdStart)
    }

    return false
  }

  return false
}

export const orderIsMealDependent = (order: MedicationOrder): boolean => {
  return orderSpecifiesEmptyStomach(order) || orderSpecifiesTakeWithFood(order)
}

export const orderSpecifiesEmptyStomach = (order: MedicationOrder): boolean => {
  return Boolean(
    order.medicationStatement.additionalInstructions?.includes(
      AdditionalInstructions.ADDITIONAL_INSTRUCTIONS_BEFORE_MEALS
    )
  )
}

export const orderSpecifiesTakeWithFood = (order: MedicationOrder): boolean => {
  return Boolean(
    order.medicationStatement.additionalInstructions?.includes(
      AdditionalInstructions.ADDITIONAL_INSTRUCTIONS_AFTER_MEALS
    )
  )
}

export const nameOrInstructionMatchesString = (medFilter: string) => {
  return (order: MedicationOrder): boolean => {
    const name = getMedicationOrderName(order)
    const instructions = getMedicationOrderInstructions(order).some((i) =>
      i.match(new RegExp(escapeStringRegexp(medFilter), 'i'))
    )

    return !!(
      instructions || name.match(new RegExp(escapeStringRegexp(medFilter), 'i'))
    )
  }
}

/**
 * Returns the "sig" of the medication order
 * Falls back to the dosage-level instructions if the providerDosageInstructions are not specified
 */
export const getMedicationOrderInstructions = (
  order: MedicationOrder
): string[] => {
  if (order.medicationStatement.providerDosageInstructions) {
    return [order.medicationStatement.providerDosageInstructions]
  }

  return (order.medicationStatement.dosageInstruction ?? [])
    .map((dose) => dose.text)
    .filter(notEmpty)
}

export const getAllAdditionalInstructions = (
  order: MedicationOrder
): string[] => {
  return [
    order.medicationStatement.note,
    ...(order.medicationStatement.dosageInstruction ?? []).map((i) => i.note),
  ].filter(notEmpty)
}

export const getDosageInstructions = (order: MedicationOrder): DosageV2[] => {
  return order.medicationStatement.dosageInstruction ?? []
}

export const getVitalInstructions = (
  order: VitalMedicationOrder
): VitalInstruction[] => {
  return order.medicationStatement.vitalInstructions
}

export const getSlidingScaleEntriesFromDose = (dose?: DosageV2) => {
  return dose?.doseAndRate?.slidingScale?.entries ?? []
}

export const getDoseAndRate = (
  order: MedicationOrder,
  index: number = 0
): DoseAndRate | undefined => {
  return order.medicationStatement.dosageInstruction?.[index]?.doseAndRate
}

export const getSlidingScaleUnit = (entries: SlidingScaleEntry[]): string => {
  return (
    entries.find((entry) => entry.doseQuantity?.unit)?.doseQuantity?.unit ??
    'unit'
  )
}

export const getScaleBoundsValue = ({
  entry,
  boundsType,
}: {
  entry?: SlidingScaleEntry
  boundsType: 'high' | 'low'
}): number | null => {
  return entry?.bounds?.[boundsType]?.value ?? null
}

export const getScaleBoundsUnit = ({
  entry,
  boundsType,
}: {
  entry?: SlidingScaleEntry
  boundsType: 'high' | 'low'
}): string | null => {
  return entry?.bounds?.[boundsType]?.unit ?? null
}

export const getStructuredSlidingScaleDisplayData = (
  scaleEntries?: SlidingScaleEntry[]
): {
  rangeText: string
  administerDose: string
  isValidRange: boolean
  note: string | undefined
}[] => {
  if (!scaleEntries || scaleEntries.length === 0) {
    return []
  }

  const finalEntryIndex = scaleEntries.length - 1
  const unitLabel = getSlidingScaleUnit(scaleEntries)

  return scaleEntries.map((en, index) => {
    const isFinalEntry = index === finalEntryIndex
    let lowRange: string | number | null = getScaleBoundsValue({
      entry: en,
      boundsType: 'low',
    })
    let highRange: string | number | null = getScaleBoundsValue({
      entry: en,
      boundsType: 'high',
    })

    let isValidRange = true

    if (lowRange === null) {
      isValidRange = false
      lowRange = 'N/A'
    }

    if (!isFinalEntry && highRange === null) {
      isValidRange = false
      highRange = 'N/A'
    }

    if (isValidRange && highRange && lowRange >= highRange) {
      isValidRange = false
    }

    const rangeUnit = getScaleBoundsUnit({ entry: en, boundsType: 'low' })

    const rangeText = isFinalEntry
      ? `> ${lowRange} ${rangeUnit}`
      : `${lowRange} - ${highRange} ${rangeUnit}`

    const administerDose = pluralize(
      unitLabel,
      en.doseQuantity?.value ?? 0,
      true
    )

    return {
      rangeText,
      administerDose,
      isValidRange,
      note: en.note,
    }
  })
}

export const getDoseQuantityValue = (dose: DosageV2): number => {
  return dose.doseAndRate?.doseQuantity?.value ?? 0
}

export const getDoseQuantityUnit = (dose: DosageV2): string => {
  return dose.doseAndRate?.doseQuantity?.unit ?? 'unit'
}
