import {
  Report,
  ReportAssignment,
} from '@augusthealth/models/com/august/protos/report'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { getAssignedReportPath } from '@app/pages/Tools/path'
import AllowLimitedReadCheckbox from './AllowLimitedReadCheckbox'
import AssignCheckbox from './AssignCheckbox'
import AssignmentName from './AssignmentName'

export default function THead({
  reports,
  assignments,
  reload,
}: {
  reports: Report[]
  assignments: ReportAssignment[]
  reload: () => void
}) {
  const hasMultipleReports = reports.length > 1
  const reportThs = reports.map((r) => {
    const { id: rId, description } = r
    const reportTitle = `${rId}. ${description}`
    const assignment = assignments.find(
      (a) => !a.facilityId && !a.orgId && a.reportId === rId
    )
    return (
      <th key={reportTitle} className="report-title">
        <div className="whitespace-nowrap">
          {reportTitle}
          {hasMultipleReports && rId && (
            <Link to={getAssignedReportPath(rId)}>
              <i className="fa-solid fa-ballot-check ml-[8px]" />
            </Link>
          )}
        </div>
        <AssignCheckbox assignment={assignment} report={r} reload={reload} />
        {assignment && (
          <Fragment>
            <AllowLimitedReadCheckbox assignment={assignment} reload={reload} />
            <AssignmentName assignment={assignment} reload={reload} />
          </Fragment>
        )}
      </th>
    )
  })

  return (
    <thead className="thead-light">
      <tr>
        <th>&nbsp;</th>
        {reportThs}
      </tr>
    </thead>
  )
}
