import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { DetailWithTextbox, FrontendDetail } from '@shared/types/assessment'
import { DetailModalMode } from '@shared/types/assessment_configuration'
import styles from '../styles.module.css'
import { EditRemoveButtons } from '../EditRemoveButtons'

export type Props = {
  question: DetailWithTextbox
  updateDetail: (eventDetails: {
    value: string | number
    index: number
    key: string
    optIndex?: number
    toUpdate: 'description' | 'score' | 'helpText'
  }) => void
  index: number
  detailGroupKey: string
  setShowAddEditModal: (mode: DetailModalMode | undefined) => void
  editDetailFromModal: ({
    updatedDetail,
    index,
    optIndex,
  }: {
    updatedDetail: Partial<FrontendDetail>
    index: number
    optIndex?: number
  }) => void
  removeDetail: ({
    index,
    optIndex,
    key,
  }: {
    index: number
    optIndex?: number
    key: string
  }) => void
  disabled: boolean
}
export const EditTextbox = ({
  question,
  updateDetail,
  index,
  detailGroupKey,
  setShowAddEditModal,
  editDetailFromModal,
  removeDetail,
  disabled,
}: Props) => {
  const onEdit = () => {
    setShowAddEditModal({
      tag: 'edit',
      currentDetail: question,
      editDetail: editDetailFromModal,
      index,
    })
  }

  const onRemove = () => {
    removeDetail({ index, key: detailGroupKey })
  }

  return (
    <>
      <EditRemoveButtons
        onEdit={onEdit}
        onRemove={onRemove}
        disabled={disabled}
      />
      <div>
        <BasicInput
          type={'text'}
          value={question.value.description}
          className={'mb-[8px]'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateDetail({
              value: e.target.value,
              index,
              key: detailGroupKey,
              toUpdate: 'description',
            })
          }
          data-testid={`${question.value.group}-${index}-description`}
          disabled={disabled}
        />
        <BasicInput
          type={'text'}
          value={question.value.helpText}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateDetail({
              value: e.target.value,
              index,
              key: detailGroupKey,
              toUpdate: 'helpText',
            })
          }
          placeholder={'Help text for this question'}
          data-testid={`${question.value.group}-${index}-helptext`}
          disabled={disabled}
        />
      </div>
      <BasicInput
        type={'text'}
        disabled
        value={'N/A'}
        className={styles.scoreInput}
        data-testid={`${question.value.group}-${index}-score`}
      />
    </>
  )
}
