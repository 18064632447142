import { Button, RadioGroup } from '@augusthealth/august-frontend-form-elements'
import PropTypes from 'prop-types'
import './style.css'

const OPTIONS = [
  /* {
    label: 'Unspecified',
    value: 'AMBULATORY_CODE_UNSPECIFIED',
    iconClassName: 'fa fa-chevron-up'
  }, */
  {
    label: 'Walks without device or support',
    value: 'AMBULATORY_CODE_WALKS_WITHOUT_SUPPORT',
    iconPath: '/svg/walk.svg',
  },
  {
    label: 'Walks with cane',
    value: 'AMBULATORY_CODE_WALKS_WITH_CANE',
    iconPath: '/svg/walk--cane.svg',
  },
  {
    label: 'Walks with walker or other support',
    value: 'AMBULATORY_CODE_WALKS_WITH_WALKER_OR_OTHER_SUPPORT',
    iconPath: '/svg/walk--walker.svg',
  },
  {
    label: 'Uses wheelchair',
    value: 'AMBULATORY_CODE_USES_WHEELCHAIR',
    iconPath: '/svg/wheelchair.svg',
  },
  {
    label: 'Mostly in bed, able to turn self',
    value: 'AMBULATORY_CODE_BEDRIDDEN_ABLE_TO_TURN',
    iconPath: '/svg/bedridden--can_turn.svg',
  },
  {
    label: 'Bedridden, unable to turn self',
    value: 'AMBULATORY_CODE_BEDRIDDEN_UNABLE_TO_TURN',
    iconPath: '/svg/bedridden--can_not_turn.svg',
  },
]

const ACTIVE_BTN_CLASS_NAME =
  'hover:brightness-125 bg-button-secondary-color text-button-fill-font-color'
const INACTIVE_BTN_CLASS_NAME =
  'border-button-inner-border-color text-button-default-font-color hover:brightness-90'

function IconButton(props) {
  const { label, iconPath, className, onClick } = props
  const classNameList = ['ambulatory-icon']
  const iconClassName =
    className === ACTIVE_BTN_CLASS_NAME ? 'icon-white' : 'icon'
  if (className) {
    classNameList.push(className)
  }

  return (
    <Button className={classNameList.join(' ')} onClick={onClick}>
      <div>
        <img className={iconClassName} src={iconPath} alt={label} />
      </div>
      <div className="icon-label">{label}</div>
    </Button>
  )
}

IconButton.propTypes = {
  label: PropTypes.string,
  iconPath: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

function AmbulatoryRadioGroup(props) {
  return (
    <RadioGroup
      options={OPTIONS}
      itemComponent={IconButton}
      className="ambulatory-icons-holder horizontal"
      activeBtnClassName={ACTIVE_BTN_CLASS_NAME}
      inactiveBtnClassName={INACTIVE_BTN_CLASS_NAME}
      {...props}
    />
  )
}

AmbulatoryRadioGroup.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  iconPath: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default AmbulatoryRadioGroup
