import { ReactNode } from 'react'
import { ClassNameValue } from 'tailwind-merge'
import { twx } from '@shared/utils/tailwind'

export default function Disclosure({
  summary,
  children,
  classes,
  summaryClasses,
  open,
}: {
  classes?: ClassNameValue
  summaryClasses?: ClassNameValue
  summary: ReactNode
  children: ReactNode
  open?: boolean
}) {
  return (
    <details className={twx(classes)} open={open}>
      <summary className={twx('mb-4', summaryClasses)}>{summary}</summary>
      {children}
    </details>
  )
}

export function SectionDisclosure({
  summary,
  children,
  className,
  summaryClassName,
  open,
}: {
  summary: ReactNode
  children: ReactNode
  open?: boolean
  className?: ClassNameValue
  summaryClassName?: ClassNameValue
}) {
  return (
    <Disclosure
      summary={summary}
      summaryClasses={twx(
        'text-sm font-medium text-gray-07 hover:text-gray-06 focus:text-primary-light',
        summaryClassName
      )}
      open={open}
      classes={className}
    >
      {children}
    </Disclosure>
  )
}
