import { useForm } from 'react-hook-form'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { ModalTitle } from '@shared/components/baseMui/Modal/Layout'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { requiredLabel } from '@shared/components/Labels'
import { CreatePayerUserOneTimePaymentRequest } from '@shared/types/billing'
import { tw } from '@shared/utils/tailwind'
import MoneyInReactHookForm from '@app/components/MoneyInput/MoneyInReactHookForm'
import useBillingPayerUserSettings from '@app/hooks/useBillingPayerUserSettings'
import BankInfo from './BankInfo'

export type OneTimePaymentModalProps = {
  isOpen: boolean
  onClose: () => void
  billingPayerUserSettings: ReturnType<
    typeof useBillingPayerUserSettings
  >['billingPayerUserSettings']
  savePayment: (formData: CreatePayerUserOneTimePaymentRequest) => Promise<void>
}
export default function OneTimePaymentModal(props: OneTimePaymentModalProps) {
  const { savePayment, isOpen, onClose, billingPayerUserSettings } = props
  const {
    control,
    handleSubmit,
    formState,
    formState: { errors, disabled },
  } = useForm()

  async function onSubmit(data: CreatePayerUserOneTimePaymentRequest) {
    await savePayment(data)
    onClose()
  }

  return (
    <Modal
      id="make-payment-modal"
      contentClassName={tw`w-[600px] min-w-[600px]`}
      open={isOpen}
      onClose={onClose}
    >
      <ModalTitle>Make a One Time Payment</ModalTitle>
      {billingPayerUserSettings.tag === 'Complete' && (
        <BankInfo
          billingPayerUserSettings={
            billingPayerUserSettings.value.paymentSettings
          }
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={tw`mt-[24px] flex gap-4`}>
          <MoneyInReactHookForm
            uppercaseTitle={false}
            subLabel={requiredLabel(Boolean(errors.amountCents))}
            control={control}
            inputProps={{ disabled }}
            name="amountCents"
            id="amountCents"
            required
            title="Amount"
          />
        </div>

        <div className={tw`mt-[32px]`}>
          <AnimatedPopupFormFooter
            yesBtn={{ label: 'Submit Payment', props: { disabled } }}
            noBtn={{ action: onClose }}
            formState={formState}
          />
        </div>
      </form>
    </Modal>
  )
}
