import { VitalType } from '@augusthealth/models/com/august/protos/vital'
import debounce from 'debounce-promise'
import { isEmpty } from 'lodash'
import { Controller } from 'react-hook-form'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import { Label } from '@shared/components/Labels'
import { XLDropdownIndicator } from '@shared/components/Selects/Components'
import {
  AsyncCreatableStyledSelect,
  CreatableStyledSelect,
  OptionTypeBase,
  SelectComponent,
  StyledOption,
  StyledOptionProps,
} from '@shared/components/Selects/StyledSelect'
import { MedicationOrder } from '@shared/types/medication_order'
import { DosageType } from '@shared/types/medication_statement'
import { tw, twx } from '@shared/utils/tailwind'
import {
  FormattedRxTerm,
  getAndCacheMedications,
  MedicationSearchStyles,
  RECORD_VITAL_OPTION,
  StrengthAndForm,
} from '@app/components/Residents/Medications/MedicationSearch/helpers'
import useMedicationSearch from '@app/components/Residents/Medications/MedicationSearch/useMedicationSearch'
import { MedicationSpecialtyBadges } from '@app/components/Residents/Medications/MedicationSpecialtyBadges/MedicationSpecialtyBadges'

const DropdownOption = (
  props: StyledOptionProps<OptionTypeBase<FormattedRxTerm | null>>
) => {
  const { children, ...rest } = props

  if (props.label === RECORD_VITAL_OPTION.label) {
    return (
      <StyledOption
        labelClassName={tw`text-primary`}
        iconClassName={twx(`text-primary fa-circle-plus ml-4`)}
        {...rest}
      >
        {children}
      </StyledOption>
    )
  }

  return <StyledOption {...rest}>{children}</StyledOption>
}

export default function MedicationSearchAndInstructions({
  order,
  mode = 'new',
}: {
  order?: MedicationOrder
  mode?: 'edit' | 'new'
}) {
  const {
    control,
    formState,
    medicationOptions,
    reset,
    register,
    selectedMedication,
    medicationOrderType,
    setRxTermResponse,
    setStrengthAndFormOptions,
    setValue,
    strengthAndFormOptions,
  } = useMedicationSearch({ order })

  const isVitalMedicationOrder = medicationOrderType === 'vital'
  const isMedicationOrder = medicationOrderType === 'rx'
  const isEditingOrder = mode === 'edit'

  const isEditingMedication =
    (!!selectedMedication || isEditingOrder) && isMedicationOrder

  const defaultOptions = !isEditingOrder ? [RECORD_VITAL_OPTION] : undefined

  const hasSelectedInitialValue = !!selectedMedication || isVitalMedicationOrder
  const displayNameValue = selectedMedication
  const displayNameError = formState.errors.displayName
  const disableVitalInputs = isEditingOrder && isVitalMedicationOrder

  const clearSelectedMedication = () => {
    setValue('displayName', undefined)
    setValue('rxCui', undefined)
    setValue('codeName', undefined)
    setValue('strengthAndForm', undefined)
  }
  const clearSelectedVital = () => {
    setValue('vitalType', null)
    setValue('doses.0.userSetDosageType', DosageType.DOSAGE_TYPE_ROUTINE)
  }

  const handleSelection = (selectedOption: OptionTypeBase<FormattedRxTerm>) => {
    if (selectedOption?.label === RECORD_VITAL_OPTION.label) {
      setValue('vitalType', VitalType.VITAL_TYPE_UNSPECIFIED)
      setValue('doses.0.userSetDosageType', DosageType.DOSAGE_TYPE_VITAL)
      return clearSelectedMedication()
    }

    clearSelectedVital()
    if (selectedOption) {
      setValue('displayName', selectedOption.value.displayName)
      setValue('codeName', selectedOption.value.codeName)

      const optionsForMed =
        selectedOption.value.strengthsAndForms.length > 0
          ? selectedOption.value.strengthsAndForms.map((saf) => ({
              label: saf.strengthAndForm,
              value: saf,
            }))
          : []
      setStrengthAndFormOptions(optionsForMed)
    } else {
      if (isEditingOrder) {
        clearSelectedMedication()
      } else {
        reset()
      }
      setStrengthAndFormOptions([])
      setRxTermResponse([])
    }
  }

  return (
    <fieldset className={'w-full'}>
      <Label htmlFor="medicationSearch" visuallyHidden>
        Medication name
      </Label>
      <AsyncCreatableStyledSelect
        id="medicationSearch"
        instanceId="medicationSearch"
        openMenuOnFocus={true}
        name="medicationSearch"
        className={'mb-[8px]'}
        defaultOptions={defaultOptions}
        isDisabled={disableVitalInputs}
        loadOptions={(searchTerm) => {
          clearSelectedVital()
          return debounce(
            getAndCacheMedications({
              cache: setRxTermResponse,
              includeVitalOption: true,
            }),
            750
          )(searchTerm)
        }}
        value={
          isVitalMedicationOrder
            ? RECORD_VITAL_OPTION
            : (medicationOptions.find(
                (re) =>
                  re.value.tag === 'Medication' &&
                  re.value.value.displayName === displayNameValue
              ) ?? null)
        }
        onChange={handleSelection}
        placeholder={'Enter medication or treatment...'}
        isClearable
        styles={MedicationSearchStyles(!!displayNameError)}
        components={{
          DropdownIndicator: XLDropdownIndicator as SelectComponent,
          Option: DropdownOption as SelectComponent,
        }}
        onCreateOption={(val: string) => {
          clearSelectedVital()

          if (!isEmpty(val)) {
            setValue('displayName', val)
            setStrengthAndFormOptions([])
            setValue('codeName', undefined)
          }
        }}
        noOptionsMessage={() => {
          return medicationOptions?.length > 0 && !displayNameValue
            ? 'No Options'
            : null
        }}
      />
      {isEditingMedication && (
        <>
          <Controller
            control={control}
            name={'strengthAndForm'}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <>
                  <Label htmlFor="strengthAndForm" visuallyHidden>
                    Medication strength and form factor
                  </Label>
                  <CreatableStyledSelect
                    id="strengthAndForm"
                    instanceId="strengthAndForm"
                    openMenuOnFocus={true}
                    placeholder="e.g. 325mg Tab"
                    name="strengthAndForm"
                    value={
                      strengthAndFormOptions.find(
                        (opt) => opt?.value.strengthAndForm === value
                      ) ?? null
                    }
                    options={strengthAndFormOptions}
                    isClearable
                    styles={MedicationSearchStyles(!!error)}
                    components={{
                      DropdownIndicator: XLDropdownIndicator,
                    }}
                    isDisabled={!selectedMedication}
                    onChange={(e: OptionTypeBase<StrengthAndForm> | null) => {
                      if (e) {
                        onChange(e.value.strengthAndForm)
                        setValue('rxCui', e.value.rxCui)
                      } else {
                        onChange(null)
                        setValue('rxCui', undefined)
                      }
                    }}
                    onCreateOption={(val: string) => {
                      if (val.trim().length > 0) {
                        onChange(val)
                        setStrengthAndFormOptions([
                          {
                            label: val,
                            value: {
                              strengthAndForm: val,
                              rxCui: undefined,
                            },
                          },
                        ])
                        setValue('rxCui', undefined)
                      }
                    }}
                    noOptionsMessage={() => {
                      return strengthAndFormOptions?.length > 0 && !value
                        ? 'No Options'
                        : null
                    }}
                  />
                </>
              )
            }}
          />
        </>
      )}
      {hasSelectedInitialValue && (
        <>
          <BasicTextarea
            {...register('sig', {
              required: true,
            })}
            placeholder={'Instructions...'}
            className={tw`mt-[8px] w-full max-w-full`}
            showErrorBorder={!!formState.errors.sig}
          />
          <MedicationSpecialtyBadges isVitalOrder={isVitalMedicationOrder} />
        </>
      )}
    </fieldset>
  )
}
