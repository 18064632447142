import { HTMLProps, ReactNode } from 'react'
import {
  AsyncIconButton as Button,
  Props as ButtonProps,
} from '@shared/components/AsyncButton'
import { RequiredActionModal } from '@shared/components/baseMui/Modal/RequiredActionModal'
import { tw, twx } from '@shared/utils/tailwind'

export type Props = {
  buttonProps: Partial<ButtonProps>
  children?: ReactNode
  imgProps?: HTMLProps<HTMLImageElement>
  title?: ReactNode
  id: string
  contentClassName?: string
}

export default function ExpectedErrorModal({
  buttonProps,
  children,
  imgProps = {
    className: twx('w-[376px]'),
    src: '/images/sad-people.svg',
  },
  title = 'Something went wrong!',
  id,
  contentClassName,
}: Props) {
  const {
    buttonStyle,
    className: buttonClassName,
    ...restBtnProps
  } = buttonProps

  return (
    <RequiredActionModal
      open={true}
      id={id}
      contentClassName={twx(
        `flex w-[440px] min-w-[440px] max-w-[440px] flex-col items-center text-center`,
        contentClassName
      )}
    >
      <img {...imgProps} alt={''} />
      <div
        className={twx(
          'mb-[12px] mt-[24px] text-[18px] font-semibold leading-[24px]'
        )}
      >
        {title}
      </div>
      <div
        className={tw`mb-[24px] text-[16px] font-medium leading-[24px] text-secondary-06`}
      >
        {children}
      </div>
      <Button
        buttonStyle={buttonStyle || 'primary-fill'}
        {...restBtnProps}
        className={twx('w-[300px]', buttonClassName)}
      />
    </RequiredActionModal>
  )
}
