import { uniqBy } from 'lodash'
import {
  FormDisplay,
  FormField,
  SignableForm,
  SignatureConfiguration,
} from '@shared/types/signable_form'
import { Signer, SignerRole } from '@shared/types/snapshot'
import { isEmptyObject } from '@shared/utils/common'
import notEmpty from '@shared/utils/notEmpty'
import { AsyncResult, getOrElse, mapAsyncResult } from './loading'

export const labelDict: { [key in SignerRole]: string } = {
  [SignerRole.SIGNER_ROLE_ADMIN]: 'Admin',
  [SignerRole.SIGNER_ROLE_RESPONSIBLE_PARTY]: 'RP',
  [SignerRole.SIGNER_ROLE_RESIDENT]: 'Resident',
  [SignerRole.SIGNER_ROLE_EXTERNAL]: 'External',
  [SignerRole.SIGNER_ROLE_UNSPECIFIED]: 'Other',
  [SignerRole.UNRECOGNIZED]: 'n/a',
}

export function signatureConfiguration(configuration: SignerRole[]) {
  const result = configuration.map((r: SignerRole) => {
    const label: string = labelDict[r]
    return label
  })

  return result.join(', ')
}

export function removeEmptyPageAndFieldFromDisplay(display: FormDisplay) {
  const newDisplay = { ...display }
  if (newDisplay.pages) {
    const newPages = newDisplay.pages.filter((p) => !isEmptyObject(p))
    if (newPages.length) {
      newDisplay.pages = newPages.map((p) => {
        const newP = { ...p }
        const newFields = p.fields?.filter((f) => !isEmptyObject(f))
        if (newFields?.length) {
          newP.fields = newFields
        } else {
          delete newP.fields
        }

        return newP
      })
    } else {
      delete newDisplay.pages
    }
  }

  return newDisplay
}

export function getFacilityFieldsFromTemplate({
  template,
}: {
  template: SignableForm
}) {
  const templateFields = getFacilityFields(template.template)
  const draftFields = getFacilityFields(template.draftTemplate)

  return uniqBy([...templateFields, ...draftFields], (field) => field.key)
}

function getFacilityFields(template?: { preFilledFields?: FormField[] }) {
  return (
    template?.preFilledFields
      ?.map((field) => field.facilityField)
      .filter(notEmpty) ?? []
  )
}

export function firstSignerRole(signableForm: SignableForm) {
  return signableForm.template?.signers?.[0]?.signerRole
}

export function isFirstSignerRp(signableForm: SignableForm) {
  return (
    firstSignerRole(signableForm) === SignerRole.SIGNER_ROLE_RESPONSIBLE_PARTY
  )
}

export function extractTemplateSigners({
  template,
}: {
  template: AsyncResult<SignableForm, 'Not Found'>
}): SignatureConfiguration[] {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return getOrElse(
    mapAsyncResult(template, (t) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return t.template?.signers ?? []
    }),
    []
  )
}

export function getTemplateSigner(
  configuration: SignatureConfiguration[],
  signer: Signer
): SignatureConfiguration | undefined {
  return configuration.find(
    (c) =>
      c.signerRole === signer.role &&
      (c.roleIndex || 0) === (signer.roleIndex || 0)
  )
}
