import { AugustFieldOption } from '@augusthealth/models/com/august/protos/api/json_report'
import {
  FormDisplay,
  SignableForm,
} from '@augusthealth/models/com/august/protos/signable_form'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import ButtonLink from '@shared/components/ButtonLink'
import { EmbossedCard } from '@shared/components/EmbossedCard'
import { LabelAboveInput, requiredWhenError } from '@shared/components/Labels'
import GlobalContext from '@shared/contexts/GlobalContext'
import { DataType } from '@shared/types/snapshot'
import { isEmptyObject } from '@shared/utils/common'
import { Loading } from '@shared/utils/loading'
import { removeEmptyPageAndFieldFromDisplay } from '@shared/utils/signableForm'
import { getForm, setFormDisplay } from '@app/api/form'
import { listAugustValues } from '@app/api/reports'
import HUD from '@app/components/HUD'
import PersonPageTitle from '@app/components/PersonPageTitle'
import { getDraggableElementProps } from '@app/libraries/DragAndDrop/helpers'
import CustomFormPreview from './CustomFormPreview'
import EditJsonPopup from './EditJsonPopup'
import Page from './Page'

type UrlProps = {
  tab: string
  formId: string
}

export default function DisplayForm() {
  const { setError } = useContext(GlobalContext)

  const { formId } = useParams<UrlProps>()
  const [openEditJsonPopup, setOpenEditJsonPopup] = useState<boolean>(false)
  const [openFormPreview, setOpenFormPreview] = useState<boolean>(false)
  const [showHUD, setShowHUD] = useState<boolean>(false)
  const [form, setForm] = useState<Loading<SignableForm>>({ tag: 'Loading' })
  const [augustValues, setAugustValues] = useState<AugustFieldOption[]>([])
  const useFormReturn = useForm<FormDisplay>()
  const { formState, handleSubmit, register, setValue, getValues, watch } =
    useFormReturn
  const { errors, isDirty, isSubmitting } = formState

  async function loadForm(formId: string) {
    const form = await getForm(formId)
    setForm({ tag: 'Complete', value: form })
    const { display } = form || {}
    const { pages = [], title = '' } = display || {}
    setValue('pages', pages)
    setValue('title', title)
  }

  useEffect(() => {
    setForm({ tag: 'Loading' })
    loadForm(formId).catch(setError)
  }, [formId])

  const save = async (display: FormDisplay) => {
    if (formId) {
      await setFormDisplay({
        id: formId,
        body: removeEmptyPageAndFieldFromDisplay(display),
      })
      setShowHUD(true)
      await loadForm(formId)
    }
  }
  const pages = watch('pages')
  const disabledNewPageBtn = Boolean(
    pages && pages.length && isEmptyObject(pages[pages.length - 1])
  )

  useEffect(() => {
    listAugustValues().then(setAugustValues).catch(setError)
  }, [])

  if (form.tag === 'Loading') {
    return <div>Loading...</div>
  }

  const formName = form.value.name

  return (
    <div className="mt-[24px]">
      <PersonPageTitle title={`Template Display Form - ${formName}`}>
        {pages && pages.length > 0 && (
          <ButtonLink
            onClick={() => setOpenFormPreview(true)}
            className={'mr-[16px]'}
          >
            Preview
          </ButtonLink>
        )}
        <ButtonLink onClick={() => setOpenEditJsonPopup(true)}>
          Edit JSON
        </ButtonLink>
      </PersonPageTitle>
      <form onSubmit={handleSubmit(save)}>
        <div className="mb-[24px]">
          <LabelAboveInput
            htmlFor="title"
            subLabel={requiredWhenError(Boolean(errors.title))}
          >
            Title
          </LabelAboveInput>
          <BasicInput {...register('title', { required: true })} />
        </div>
        <section>
          <LabelAboveInput htmlFor="pages">Pages</LabelAboveInput>
          {pages && pages.length > 0 && (
            <ol className="list-decimal pl-10 pr-[8px]">
              {pages.map((_p, i) => {
                return (
                  <li
                    className="mb-[24px]"
                    key={`item-pageTitle-menuTitle-${formName}-${i}`}
                    {...getDraggableElementProps({
                      indexName: 'pageIndex',
                      index: i,
                      list: watch('pages') || [],
                      onDrop: (updatedList) => setValue('pages', updatedList),
                    })}
                  >
                    <EmbossedCard>
                      <Page
                        augustValues={augustValues}
                        pageIndex={i}
                        pages={pages}
                        useFormReturn={useFormReturn}
                      />
                    </EmbossedCard>
                  </li>
                )
              })}
            </ol>
          )}
          {(!pages || pages.length === 0) && <div>Pages not found</div>}
        </section>
        <div className="text-right">
          <Button
            buttonStyle="primary-fill"
            buttonSize="xsmall"
            onClick={() => setValue('pages', [...(pages || []), {}])}
            type="button"
            disabled={disabledNewPageBtn}
          >
            Add new page
          </Button>
        </div>
        <hr className="my-[16px]" />
        <Button
          buttonStyle="primary-fill"
          disabled={!isDirty || isSubmitting}
          isLoading={isSubmitting}
          width="138px"
          type={'submit'}
        >
          Save
        </Button>
      </form>
      {openEditJsonPopup && (
        <EditJsonPopup
          form={form.value}
          reload={() => loadForm(formId)}
          onClose={() => setOpenEditJsonPopup(false)}
        />
      )}
      {openFormPreview && (
        <CustomFormPreview
          pdfTemplate={form.value}
          title={form.value.display?.title ?? getValues('title')!}
          pages={pages}
          dataType={DataType.DATA_TYPE_CUSTOM_SIGNABLE_FORM}
          customType={form.value.name}
          closePreview={() => setOpenFormPreview(false)}
        />
      )}
      {showHUD && (
        <HUD onExpire={() => setShowHUD(false)}>
          Form {form.value.name} updated!
        </HUD>
      )}
    </div>
  )
}
