import Sign, { SignProps } from './index'

type SignInPersonProps = Omit<SignProps, 'isSignInPerson'> & {
  email: string
}

export default function SignInPerson(props: SignInPersonProps) {
  const email = new URL(window.location.href).searchParams.get('email') || ''

  return <Sign {...props} email={email} isSignInPerson />
}
