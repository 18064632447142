import { AllergyIntolerance_AllergyIntoleranceCategory } from '@augusthealth/models/com/august/protos/allergy_intolerance'
import { flatten } from 'lodash'
import { mergePatchPerson } from '@shared/api/person'
import { mergePatchSnapshot } from '@shared/api/snapshot'
import { Person } from '@shared/types/person'
import { DataType, SnapshotStatus } from '@shared/types/snapshot'
import {
  GtkyPersonPatch,
  GtkySectionLabels,
  GtkySnapshotFields,
  GtkySnapshotPatch,
} from '@shared/utils/gtky'
import { allergyList } from '@shared/utils/person'
import { GettingToKnowYouSteps } from '../TaskPageNavigation/step-definitions'

export function getUpdatedCommunicationPreference({
  person,
  selectedLanguages,
  isPreferred,
}: {
  person: Person
  selectedLanguages: string[]
  isPreferred: boolean
}) {
  const newPreferences = selectedLanguages.map((lang) => ({
    languageStr: lang,
    preferred: isPreferred,
  }))
  let originalPreferences

  if (person!.communication && person.communication.length > 0) {
    // keep what is the opposite of being selected (if adjusting primary, maintain the other languages/if adjusting other, maintain primary)
    originalPreferences = [
      ...person.communication.filter((comm) => comm.preferred !== isPreferred),
    ]
  }

  if (originalPreferences && originalPreferences.length > 0) {
    return [...originalPreferences, ...newPreferences]
  }

  return flatten(
    selectedLanguages.map((selectedLanguage) => ({
      languageStr: selectedLanguage,
      preferred: isPreferred,
    }))
  )
}

export const getPrimaryLanguageOption = (
  person: Person
): { value: string; label: string } | undefined => {
  const preferredLanguage = person.communication
    ? person.communication!.find((comm) => comm.preferred)
    : null

  return preferredLanguage?.languageStr
    ? {
        value: preferredLanguage.languageStr,
        label: preferredLanguage.languageStr,
      }
    : undefined
}

export const getOtherLanguageOptions = (
  person: Person
): { value: string; label: string }[] => {
  const otherLanguagesSpoken = person.communication
    ? person.communication!.filter((comm) => !comm.preferred)
    : null

  return otherLanguagesSpoken
    ? otherLanguagesSpoken.map((lang) => ({
        value: lang.languageStr!,
        label: lang.languageStr!,
      }))
    : []
}

export const getFoodAllergiesForDisplay = ({
  isEditing,
  person,
}: {
  isEditing: boolean
  person: Person
}) => {
  const foodCategory =
    AllergyIntolerance_AllergyIntoleranceCategory.ALLERGY_INTOLERANCE_CATEGORY_FOOD
  const initialFoodAllergies = [
    ...allergyList(person).filter(
      (allergy) => allergy.category === foodCategory
    ),
  ]

  if (isEditing) {
    return [...initialFoodAllergies, { summary: '', category: foodCategory }]
  }

  return initialFoodAllergies.length > 0
    ? initialFoodAllergies
    : [{ summary: 'No allergies specified' }]
}

type MappedPatch = {
  fields?: {
    [type in GtkySnapshotFields]?: string | null
  }
  oneToManyFields?: {
    [type in GtkySnapshotFields]?: {
      values: string[]
    } | null
  }
}
export const updateSnapshot = async ({
  status,
  person,
  patch,
  initialPromise,
}: {
  status: SnapshotStatus
  person: Person
  patch: GtkySnapshotPatch | null
  initialPromise?: Promise<void>
}): Promise<void> => {
  let patchToSend = {}

  if (patch) {
    const mappedPatch: MappedPatch = Object.keys(patch).reduce(
      (accum, key) => {
        const patchValue = patch[key]

        if (typeof patchValue === 'string' && patchValue.length > 0) {
          accum.fields[key] = patchValue
        } else if (Array.isArray(patchValue) && patchValue.length > 0) {
          accum.oneToManyFields[key] = { values: patchValue }
        } else {
          // if field value is falsy, clear the field
          accum.fields[key] = null
          accum.oneToManyFields[key] = null
        }

        return accum
      },
      {
        fields: {},
        oneToManyFields: {},
      }
    )

    patchToSend = { formData: mappedPatch }
  }

  if (initialPromise) {
    await initialPromise
  }
  await mergePatchSnapshot({
    pId: person.id!,
    orgId: person.orgId!,
    snapshotId: 'latest',
    patch: patchToSend,
    dataType: DataType.DATA_TYPE_GETTING_TO_KNOW_YOU_V2,
    status,
  })
}

export const updatePerson = async ({
  person,
  patch,
  initialPromise,
  fields,
}: {
  person: Person
  patch: GtkyPersonPatch
  initialPromise?: Promise<void>
  fields: string
}): Promise<void> => {
  if (initialPromise) {
    await initialPromise
  }

  await mergePatchPerson({
    fId: person.facilityId,
    pId: person.id,
    orgId: person.orgId,
    patch,
    fields,
  })
}

export const GtkyPageToNumberedStepMap = Object.keys(GtkySectionLabels).reduce(
  (accum, step) => ({
    ...accum,
    [step]: GettingToKnowYouSteps.indexOf(GtkySectionLabels[step]) + 1,
  }),
  {} as { [key in keyof typeof GtkySectionLabels]: number }
)
