import { MedPass } from '@augusthealth/models/com/august/protos/api/med_pass'
import { requestJson } from '@shared/api/request'
import { apiFacilityUrl } from '@shared/legacy_routes'
import { FacilityIds } from '@shared/types/facility'

function getMedPassesApiUrl(orgId: string, facilityId: string) {
  return `${apiFacilityUrl(orgId, facilityId)}/medPasses`
}

export async function fetchMedPasses({
  orgId,
  id: facilityId,
}: FacilityIds): Promise<MedPass[]> {
  const response = await requestJson({
    url: getMedPassesApiUrl(orgId, facilityId),
  })

  return response.data
}

export async function updateMedPasses({
  orgId,
  facilityId,
  medPasses,
}: {
  orgId: string
  facilityId: string
  medPasses: MedPass[]
}): Promise<MedPass[]> {
  const response = await requestJson({
    url: getMedPassesApiUrl(orgId, facilityId),
    method: 'PUT',
    body: JSON.stringify(medPasses),
  })

  return response.data
}

export async function deleteMedPasses({
  orgId,
  id: facilityId,
}: FacilityIds): Promise<{ meta: { hello: 'Deleted' } }> {
  const response = await requestJson({
    url: getMedPassesApiUrl(orgId, facilityId),
    method: 'DELETE',
  })

  return response.meta
}
