import urlcat from 'urlcat'
import { getMedicationOrdersUrl } from '@shared/api/medication_orders'
import { fetchJson, requestJson } from '@shared/api/request'
import { ApiResponse } from '@shared/api/response'
import { RefillEligibility } from '@shared/types/pharmacy_refill'

export const getRefillEligibilityForMedicationOrderGroup = async ({
  orgId,
  facilityId,
  personId,
  medicationOrderGroupId,
}: {
  orgId: string
  facilityId: string
  personId: string
  medicationOrderGroupId: string
}): Promise<RefillEligibility> => {
  const url = urlcat(
    `${getMedicationOrdersUrl({ orgId, facilityId, id: personId })}/orderGroups/:orderGroupId/refillEligibility`,
    {
      orderGroupId: medicationOrderGroupId,
    }
  )

  return fetchJson(url).then(
    (response: ApiResponse<RefillEligibility>) => response.data
  )
}

export const getRefillEligibilityForPerson = async ({
  orgId,
  facilityId,
  personId,
}: {
  orgId: string
  facilityId: string
  personId: string
}): Promise<RefillEligibility> => {
  const url = `${getMedicationOrdersUrl({ orgId, facilityId, id: personId })}/orderGroups/refillEligibility`

  return fetchJson(url).then(
    (response: ApiResponse<RefillEligibility>) => response.data
  )
}

export const requestRefill = ({
  orgId,
  facilityId,
  personId,
  medicationOrderGroupId,
}: {
  orgId: string
  facilityId: string
  personId: string
  medicationOrderGroupId: string
}): Promise<unknown> => {
  const url = urlcat(
    `${getMedicationOrdersUrl({ orgId, facilityId, id: personId })}/orderGroups/:orderGroupId/requestRefill`,
    {
      orderGroupId: medicationOrderGroupId,
    }
  )

  return requestJson({
    url,
    method: 'POST',
  }) as Promise<unknown>
}
