import {
  RELATIONSHIPS_BY_CATEGORY_MAP,
  ROLE_RELATIONSHIP_LIST,
} from '@shared/constants/contactRelationship'
import { Contact_ContactRelationship as Relationship } from '@shared/types/contact'
import { getRelationships } from '@shared/utils/relationship'

export function getRelationshipTypeFromName(
  name: string
): Relationship | undefined {
  const match = name.match(/contact\[relationship=(\w*)\]/)
  if (Array.isArray(match) && match.length >= 2) {
    return match[1] as Relationship
  }
  return undefined
}

export function getDefaultRelationships(relationType?: Relationship) {
  const relationships = relationType ? [relationType] : []
  if (relationType) {
    const clinicianRelationships =
      RELATIONSHIPS_BY_CATEGORY_MAP[Relationship.CONTACT_RELATIONSHIP_CLINICIAN]
    const personalRelationships =
      RELATIONSHIPS_BY_CATEGORY_MAP[Relationship.CONTACT_RELATIONSHIP_PERSONAL]
    const professionalRelationships =
      RELATIONSHIPS_BY_CATEGORY_MAP[
        Relationship.CONTACT_RELATIONSHIP_PROFESSIONAL
      ]

    if (
      relationType === Relationship.CONTACT_RELATIONSHIP_GENERAL_PRACTITIONER
    ) {
      relationships.push(
        Relationship.CONTACT_RELATIONSHIP_CLINICIAN,
        Relationship.CONTACT_RELATIONSHIP_PHYSICIAN
      )
    } else if (clinicianRelationships?.includes(relationType)) {
      relationships.push(Relationship.CONTACT_RELATIONSHIP_CLINICIAN)
    } else if (
      personalRelationships?.includes(relationType) ||
      relationType ===
        Relationship.CONTACT_RELATIONSHIP_FINANCIAL_PAYMENT_OF_CARE
    ) {
      relationships.push(Relationship.CONTACT_RELATIONSHIP_PERSONAL)
    } else if (professionalRelationships?.includes(relationType)) {
      relationships.push(Relationship.CONTACT_RELATIONSHIP_PROFESSIONAL)
    }
  }

  return relationships
}

const RELATIONSHIP_LABELS_TO_HIDE: Relationship[] = [
  Relationship.CONTACT_RELATIONSHIP_C,
  Relationship.CONTACT_RELATIONSHIP_N,
  Relationship.CONTACT_RELATIONSHIP_FAMMEMB,
  Relationship.CONTACT_RELATIONSHIP_PERSONAL,
  Relationship.CONTACT_RELATIONSHIP_CLINICIAN,
  Relationship.CONTACT_RELATIONSHIP_PROFESSIONAL,
  ...ROLE_RELATIONSHIP_LIST,
]

export function getRelationshipLabel(relationships: Relationship[]) {
  return getRelationships(relationships, 'all', {
    excludes: RELATIONSHIP_LABELS_TO_HIDE,
  }) as string
}
