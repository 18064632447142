import { ReactNode, useContext } from 'react'
import {
  ActionButtonHolder,
  PrintButton,
} from '@shared/components/AnimatedPopup/Lightbox/ActionButtons/ActionButtons'
import {
  LightboxSidebarMessage,
  LightboxSidebarTitle,
} from '@shared/components/AnimatedPopup/Lightbox/Lightbox'
import CurrentFacilityContext from '@shared/contexts/CurrentFacilityContext'
import { formatDate } from '@shared/utils/date'
import { getFullName } from '@shared/utils/humanName'
import { taskTitle } from '@shared/utils/task'
import PersonContext from '@app/contexts/PersonContext'
import { MatchParams } from '../../FormLayout/type'

type Props = {
  params: MatchParams
  titleDecorator?: ReactNode
}

export default function LightboxHeader(props: Props) {
  const { params, titleDecorator } = props
  const { convertToFacilityTimezone } = useContext(CurrentFacilityContext)
  const { tasks = [] } = useContext(PersonContext)
  const { taskId: defaultTaskId } = params
  const task = tasks.find((t) => t.id === defaultTaskId)
  const { lastModification, statusHistory } = task || {}
  const lastM =
    lastModification ||
    (Array.isArray(statusHistory) && statusHistory[statusHistory.length - 1])
  const { modificationTime, modifiedByUserName } = lastM || {}
  const modifiedBy = getFullName(modifiedByUserName)
  const timestamp = formatDate(
    modificationTime
      ? convertToFacilityTimezone(modificationTime)
      : modificationTime
  )
  const subTitle =
    modifiedBy && timestamp
      ? `Last updated by ${modifiedBy} on ${timestamp}`
      : 'Not started yet'

  return (
    <div>
      <LightboxSidebarTitle>
        {task ? taskTitle(task) : ''}
        {titleDecorator}
      </LightboxSidebarTitle>
      <LightboxSidebarMessage>{subTitle}</LightboxSidebarMessage>
      <ActionButtonHolder showTopHR>
        <PrintButton onClick={() => window.print()} />
      </ActionButtonHolder>
    </div>
  )
}
