import { Contact } from '@augusthealth/models/com/august/protos/contact'
import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Loading } from '@shared/utils/loading'
import { getContacts } from '../api/contacts'
import PersonContext from '../contexts/PersonContext'

export function useContacts() {
  const { person } = useContext(PersonContext)
  const { setError } = useContext(GlobalContext)

  const [contacts, setContacts] = useState<Loading<Contact[]>>(
    person === undefined
      ? { tag: 'Loading' }
      : {
          tag: 'Complete',
          value: person.contact ?? [],
        }
  )

  const refreshContacts = async (): Promise<void> => {
    if (person) {
      try {
        const cs = await getContacts({
          personId: person.id!,
          orgId: person.orgId!,
          facilityId: person.facilityId!,
        })
        return setContacts({ tag: 'Complete', value: cs })
      } catch (e) {
        setError(e)
      }
    }
  }

  useEffect(() => {
    void refreshContacts()
  }, [person?.id])

  return { contacts, refreshContacts }
}
