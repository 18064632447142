import { formDataToVitalsPayload } from '@shared/components/Vitals/helpers'
import { PersonStats } from '@shared/types/person_stats'
import { isEmptyObject } from '@shared/utils/common'
import { QueuedVital, VitalsFormData } from '@shared/utils/vitals'
import { createVitals, mergePatchVitals } from '@app/api/vitals'

export const createOrUpdateVitals = async ({
  data,
  sortedPeopleStats,
  visiblePersonId,
  currentVitals,
  setCurrentVitals,
}: {
  data: VitalsFormData
  sortedPeopleStats: PersonStats[]
  visiblePersonId: string | undefined
  currentVitals: QueuedVital[]
  setCurrentVitals: React.Dispatch<React.SetStateAction<QueuedVital[]>>
}): Promise<{ id?: string | number }> => {
  const vitalsData = { ...data, date: undefined, time: undefined }
  if (isEmptyObject(vitalsData)) {
    return Promise.resolve({})
  }
  const vitals = formDataToVitalsPayload(data)

  if (isEmptyObject(vitals.panel)) {
    return Promise.resolve({})
  }

  const person = sortedPeopleStats.find(
    (ps) => ps.person!.id === visiblePersonId
  )!.person
  const currentVitalQueue =
    currentVitals.find((cv) => cv.personId === visiblePersonId)?.queue ??
    Promise.resolve({ id: undefined, noteId: undefined })

  const { id } = await currentVitalQueue

  let newPromise: Promise<{ id?: string | number }>
  if (id) {
    newPromise = mergePatchVitals(person, {
      ...vitals,
      id: id.toString(),
    })
  } else {
    newPromise = createVitals(person, vitals)
  }

  setCurrentVitals((prev) => {
    return prev.map((v) => {
      if (v.personId === visiblePersonId) {
        return {
          ...vitals,
          personId: person.id,
          status: v.status,
          queue: newPromise,
        } as QueuedVital
      } else {
        return v
      }
    })
  })

  return newPromise
}

export function modifyVitalFor(
  visiblePersonId: string,
  modifyVital: (current: QueuedVital) => QueuedVital
): (prev: QueuedVital[]) => QueuedVital[] {
  return (prev) =>
    prev.map((v) => {
      if (v.personId === visiblePersonId) {
        return modifyVital(v)
      } else {
        return v
      }
    })
}
