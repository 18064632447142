import { useContext } from 'react'
import { Props } from 'react-select'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import CurrentFacilityContext from '@shared/contexts/CurrentFacilityContext'
import { Person } from '@shared/types/person'
import PersonContext from '@app/contexts/PersonContext'

interface SelectProps extends Props {
  optionsToFilter?: string[]
}

export default function DietSelect(props: SelectProps) {
  const {
    settings: { dietSettingsOptions },
  } = useContext(CurrentFacilityContext)
  const { person = {} as Person } = useContext(PersonContext)
  const { optionsToFilter } = props
  const selectedOption = props.value as OptionTypeBase
  const existingDietTypes: string[] =
    optionsToFilter && optionsToFilter.length > 0
      ? optionsToFilter
      : person?.socialHistory?.diet?.dietTypeValue || []

  // Filter out existing diet type except current selected one which will be served as placeholder
  const filteredOptions = dietSettingsOptions.filter((type) => {
    const dietType = type.value
    return (
      (selectedOption && selectedOption.value === dietType) ||
      !existingDietTypes.includes(dietType)
    )
  })

  return (
    <StyledSelect
      {...props}
      options={filteredOptions}
      isClearable
      isSearchable
    />
  )
}
