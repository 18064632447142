import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext } from 'react'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import CurrentFacilityContext from '@shared/contexts/CurrentFacilityContext'
import { hasActiveAlert } from '@shared/utils/incident'
import { Switch } from '@app/components/Switch'
import { toCardIncident } from './helpers'
import { useIncidentCardContext } from './IncidentCardContext'

export default function IncidentHeader({ onToggle }: { onToggle: () => void }) {
  const { convertToFacilityTimezone } = useContext(CurrentFacilityContext)
  const { person, user, incident } = useIncidentCardContext()
  const { title, created, modification } = toCardIncident(
    incident,
    convertToFacilityTimezone
  )

  return (
    <div className={'mb-[32px] flex items-center'}>
      <div className={'grow'}>
        <h3 className="mb-[4px] text-[16px] font-semibold leading-[24px] text-gray-03">
          {title}
        </h3>
        <h4 className="mb-0 text-[14px] font-medium leading-[16px] text-gray-07">
          {created}
        </h4>
        {modification && (
          <h4 className="mb-0 mt-[4px] text-[14px] font-medium leading-[16px] text-gray-07">
            {modification}
          </h4>
        )}
      </div>
      <div className={'print-hide flex items-center'}>
        <Switch
          disabled={
            !hasPermissionForPerson({
              user,
              person,
              permissions: [GroupPermission.GROUP_PERMISSION_INCIDENT_ON_ALERT],
            })
          }
          holderClassName={'flex'}
          name={`incident-alert-${incident.id}`}
          onChange={onToggle}
          checked={hasActiveAlert(incident)}
          color={'alert'}
        />
        <span className={'ml-[12px] text-[14px] font-semibold'}>On Alert</span>
      </div>
    </div>
  )
}
