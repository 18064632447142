import { head, isEmpty } from 'lodash'
import { useEffect } from 'react'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { Facility } from '@shared/types/facility'
import { Person } from '@shared/types/person'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { displayResidentStatus } from '@shared/utils/person'
import usePeople from '@app/hooks/usePeople'

type Props = {
  prepopulateWithDefaults?: boolean
  facility?: Facility
  selectClassName?: string
  onPersonSelect: (person: OptionTypeBase<Person>) => void
  personLabel?: string
  selectedPerson: OptionTypeBase<Person> | null
  personSelectProps?: any
}

export const BasePersonSelect = ({
  prepopulateWithDefaults = false,
  facility,
  selectClassName,
  onPersonSelect,
  personLabel,
  selectedPerson,
  personSelectProps = {},
}: Props) => {
  const { people } = usePeople({
    facility,
  })

  useEffect(() => {
    if (people.tag === 'Complete' && prepopulateWithDefaults) {
      const firstPerson = head(people.value)

      if (isEmpty(firstPerson)) {
        return
      }

      const personToSave: OptionTypeBase<Person> = {
        label: getFirstAndLastName(firstPerson.name),
        subLabel: displayResidentStatus(firstPerson),
        value: firstPerson,
      }
      onPersonSelect(personToSave)
    }
  }, [people, prepopulateWithDefaults])

  return (
    <>
      {personLabel && (
        <LabelAboveInput htmlFor={'people'}>{personLabel}</LabelAboveInput>
      )}
      <StyledSelect
        isClearable
        className={selectClassName}
        placeholder="Select Person..."
        isDisabled={!facility || people?.tag === 'Loading'}
        options={
          people?.tag === 'Complete'
            ? people.value.map((f) => {
                return {
                  label: getFirstAndLastName(f.name),
                  subLabel: displayResidentStatus(f),
                  value: f,
                }
              })
            : []
        }
        name={'people'}
        id={'people'}
        instanceId={'people'}
        onChange={(o: OptionTypeBase<Person>) => {
          onPersonSelect(o)
        }}
        filterOption={(filterOption, input) => {
          return filterOption.label.toLowerCase().includes(input.toLowerCase())
        }}
        value={selectedPerson}
        {...personSelectProps}
      />
    </>
  )
}
