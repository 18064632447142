import Card from '@shared/components/Card'
import Icon from '@shared/components/Icon'
import { tw, twx } from '@shared/utils/tailwind'

type Props = {
  iconName: string
  iconColor: string
  text: string
  onClick: () => void
}
export const ActionCard = ({ iconName, iconColor, text, onClick }: Props) => {
  return (
    <Card
      className={tw`flex-row items-center justify-between border border-transparent p-4 hover:border-primary-light`}
      onClick={onClick}
    >
      <div
        className={tw`flex flex-row items-center text-[16px] font-semibold leading-[24px] text-gray-04`}
      >
        <Icon
          name={iconName}
          className={twx('fa-fw mr-4 text-[24px]', iconColor)}
        />
        {text}
      </div>
      <Icon
        name={'chevron-right'}
        className={'fa-fw text-[16px] text-gray-08'}
      />
    </Card>
  )
}
