import { add, sub } from 'date-fns'
import { DexieError } from 'dexie'
import { isArray, get as safeGet } from 'lodash'
import environment from '@shared/environment'
import {
  ApiError,
  ErrorCode,
  ErrorResponse,
} from '@shared/types/api/error_response'

export enum InternalErrorCode {
  FILE_TOO_LARGE = 'file-too-large', // value from useDropzone
  FILE_TOO_SMALL = 'file-too-small', // value from useDropzone
}

export const WARNING_LEVEL_INTERNAL_CODES = Object.values(
  InternalErrorCode
) as InternalErrorCode[]

export interface AugustError extends Error {
  status?: number
  json?: ErrorResponse
  requestId?: string
  internalCode?: InternalErrorCode
}

export function isClientVersionUnsupported(e: AugustError) {
  return (
    e.json?.errors?.some(
      (e) => e.code === ErrorCode.ERROR_CODE_UNSUPPORTED_VERSION
    ) || false
  )
}

export function isRequiredSignatureError(e: AugustError) {
  return (
    e.json?.errors?.some((e) => e.message?.match(/must have a date signed/i)) ||
    false
  )
}

export function isInMaintenanceMode(e: unknown) {
  return isAugustError(e) && e.status === 503
}

export function isEtagMismatch(e: AugustError) {
  return e.status === 412
}

export function isConnectivityError(e: unknown) {
  const isTypeError = e instanceof TypeError
  if (!(isTypeError || isAugustError(e))) {
    return false
  }

  return (
    (isTypeError && e.message.match(/failed to fetch/i)) ||
    errorMatchesMessage(e, 'failed to fetch') ||
    errorMatchesMessage(e, 'load failed') ||
    errorMatchesMessage(e, 'network error') ||
    errorMatchesMessage(e, 'connection failed') ||
    errorMatchesMessage(e, 'network request failed') ||
    errorMatchesMessage(e, 'networkerror')
  )
}

export function isMagicLinksNotAllowedError(e: unknown) {
  return (
    e instanceof ApiError &&
    e.status === 400 &&
    e.message.includes('cannot use magic links')
  )
}

export function isAugustError(e: unknown): e is AugustError {
  const asAugustError = e as { message?: unknown }

  return (
    !!e &&
    asAugustError.message !== undefined &&
    typeof asAugustError.message === 'string'
  )
}

export function isDexieError(e: unknown): e is DexieError {
  const asDexieError = e as { name?: unknown }

  return (
    !!e &&
    asDexieError.name !== undefined &&
    typeof asDexieError.name === 'string'
  )
}

function errorMatchesMessage(e: Error, message: string) {
  return (e.message || '').match(new RegExp(message, 'i')) || false
}

export const requiredSignatureError: AugustError = {
  name: 'RequiredSignatureError',
  status: 400,
  message: 'Please select a signature date for this document.',
  json: {
    errors: [
      {
        code: ErrorCode.ERROR_CODE_DISPLAY_TO_CLIENT,
        message: 'Please select a signature date for this document.',
      },
    ],
  },
}

export function buildAugustError({
  name,
  message,
}: {
  name: string
  message: string
}): AugustError {
  return {
    name,
    message,
    json: {
      errors: [
        {
          code: ErrorCode.ERROR_CODE_DISPLAY_TO_CLIENT,
          message,
        },
      ],
    },
  }
}

export function generateCloudwatchUrl(requestId: string): string {
  const logGroup = environment.cloudwatch.logGroup
  const awsRegion = environment.awsRegion

  const encodedFilterPattern = encodeURIComponent(
    `{$.request_id="${requestId}"}`
  )

  const start = sub(new Date(), { minutes: 10 })
  const end = add(new Date(), { minutes: 10 })

  const encodedLogGroup = encodeURIComponent(encodeURIComponent(logGroup))
    .split('%')
    .join('$')
  const encodedFilter = encodeURIComponent(
    `?filterPattern=${encodedFilterPattern}` +
      `&start=${start.getTime()}&end=${end.getTime()}`
  )
    .split('%')
    .join('$')

  return (
    `https://${awsRegion}.console.aws.amazon.com/cloudwatch/home` +
    `?region=${awsRegion}#logsV2:log-groups/log-group/${encodedLogGroup}` +
    `/log-events${encodedFilter}`
  )
}

export function singleServerErrorWithMessage(
  json?: ErrorResponse
): string | undefined {
  const jsonErrors = json?.errors
  if (isArray(jsonErrors) && jsonErrors.length === 1) {
    return safeGet(jsonErrors, '[0].message')
  } else {
    return undefined
  }
}
