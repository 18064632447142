import {
  EmailNotification,
  EmailNotificationEnumOption,
  NotificationSubscriptions,
} from '@augusthealth/models/com/august/protos/notification_subscriptions'
import { requestJson } from '@shared/api/request'
import {
  notificationOptions,
  notificationSubscriptions,
  notificationSubscriptionUrl,
} from '@shared/legacy_routes'

export async function getEmailNotificationOptions(): Promise<
  EmailNotificationEnumOption[]
> {
  const result = await requestJson({
    url: notificationOptions(),
  })

  return result.data
}

export async function getNotificationSubscriptions(): Promise<NotificationSubscriptions> {
  const result = await requestJson({
    url: notificationSubscriptions(),
  })

  return result.data
}

export async function subscribeToNotification(
  notification: EmailNotification
): Promise<void> {
  await requestJson({
    url: notificationSubscriptionUrl(notification),
    method: 'POST',
  })
}

export async function unsubscribeToNotification(
  notification: EmailNotification
): Promise<void> {
  await requestJson({
    url: notificationSubscriptionUrl(notification),
    method: 'DELETE',
  })
}
