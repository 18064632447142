import { useContext, useEffect, useState } from 'react'
import { getRecurringCharges } from '@shared/api/billing'
import GlobalContext from '@shared/contexts/GlobalContext'
import { RecurringCharge } from '@shared/types/billing'
import { RequiredPersonIds } from '@shared/types/person'
import { loaded, LOADING, Loading } from '@shared/utils/loading'

export default function useRecurringCharges({
  orgId,
  facilityId,
  id: personId,
}: RequiredPersonIds) {
  const { setError } = useContext(GlobalContext)
  const [recurringCharges, setRecurringCharges] =
    useState<Loading<RecurringCharge[]>>(LOADING)

  async function reloadRecurringCharges() {
    setRecurringCharges(LOADING)
    try {
      const charges = await getRecurringCharges({
        orgId,
        facilityId,
        personId,
      })
      setRecurringCharges(loaded(charges))
    } catch (e) {
      setError(e)
      setRecurringCharges(loaded([]))
    }
  }

  useEffect(() => {
    void reloadRecurringCharges()
  }, [orgId, facilityId, personId])

  return {
    recurringCharges,
    reloadRecurringCharges,
  }
}
