import { BasicCheckbox } from '@shared/components/BasicInput/BasicInput'
import { ResidentStatus } from '@shared/types/person'

export default function PersonStatusCheckbox({
  status,
  personFilter,
  setPersonFilter,
  count,
}: {
  personFilter: Set<ResidentStatus>
  setPersonFilter: (newValue: Set<ResidentStatus>) => void
  status:
    | ResidentStatus.RESIDENT_STATUS_PROSPECT
    | ResidentStatus.RESIDENT_STATUS_CURRENT_RESIDENT
  count: number
}) {
  const label =
    status === ResidentStatus.RESIDENT_STATUS_PROSPECT
      ? 'Move-ins'
      : 'Residents'

  return (
    <div className="ml-[16px] flex items-center">
      <BasicCheckbox
        label={label}
        id={`${label}-checkbox`}
        labelClassName="mb-0 text-[14px]"
        defaultChecked={personFilter.has(status)}
        onChange={(e) => {
          if (e.currentTarget.checked) {
            setPersonFilter(new Set([...personFilter, status]))
          } else {
            setPersonFilter(
              new Set([...personFilter].filter((v) => v !== status))
            )
          }
        }}
      >
        {label} ({count})
      </BasicCheckbox>
    </div>
  )
}
