import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Facility } from '@shared/types/facility'
import { Loading } from '@shared/utils/loading'
import { fetchAllMedGroups, MedGroupsMap } from '@app/api/medGroup'

export default function useMedGroups(
  { facilities }: { facilities: Facility[] },
  deps: React.DependencyList = []
) {
  const { setError } = useContext(GlobalContext)
  const [medGroupsMap, setMedGroupsMap] = useState<Loading<MedGroupsMap>>({
    tag: 'Loading',
  })

  const reloadMedGroups = async () => {
    return fetchAllMedGroups({ facilities })
      .then((medGroupsMap) =>
        setMedGroupsMap({
          tag: 'Complete',
          value: medGroupsMap,
        })
      )
      .catch(setError)
  }

  useEffect(() => {
    if (facilities) {
      void reloadMedGroups()
    }
  }, [facilities?.map((f) => f.id).join('-'), ...deps])

  return { medGroupsMap, reloadMedGroups }
}
