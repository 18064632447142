import { get } from '@augusthealth/august-frontend-form-elements'
import FormLayout from '../FormLayout'
import PageForm, {
  getSnapshotUpdater,
  snapshotLoader,
} from '../FormLayout/PageForm'
import {
  FormPageProps,
  PageFormProps,
  PageGeneratorItem,
} from '../FormLayout/type'
import gettingToKnowYouConf from './json/gettingToKnowYou.json'

function mapResponse(res: any) {
  return get(res, 'data.data.gettingToKnowYou')
}

export function GettingToKnowYouForm(
  props: Omit<PageFormProps, 'configuration' | 'loader' | 'mapResponse'>
) {
  return (
    <PageForm
      configuration={gettingToKnowYouConf}
      loader={snapshotLoader}
      mapResponse={mapResponse}
      {...props}
    />
  )
}

export default function GettingToKnowYou(props: FormPageProps) {
  const formList: PageGeneratorItem[] = [
    {
      menuTitle: '1. ABOUT RESIDENT',
      pageTitle: 'ABOUT RESIDENT',
      json: gettingToKnowYouConf,
      loader: snapshotLoader,
      updater: getSnapshotUpdater('gettingToKnowYou'),
      mapResponse,
    },
    {
      menuTitle: '2. REVIEW & COMPLETE',
      pageTitle: 'REVIEW & COMPLETE',
      content: 'Review',
    },
  ]

  return <FormLayout {...props} configuration={formList} />
}
