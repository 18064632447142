import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useEffect, useState } from 'react'
import { fetchTasks } from '@shared/api/task'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { Person } from '@shared/types/person'
import { Task } from '@shared/types/task'
import { loaded, Loading } from '@shared/utils/loading'
import PersonContext from '../contexts/PersonContext'

export function useTasksForPerson({ person }: { person: Loading<Person> }): {
  tasks: Loading<Task[]>
  refreshTasks: () => Promise<void>
} {
  const { user } = useUserContext()
  const { setError } = useContext(GlobalContext)

  const [tasks, setTasks] = useState<Loading<Task[]>>({ tag: 'Loading' })

  const refreshTasks = async () => {
    if (person.tag !== 'Complete') {
      return
    }

    const canRequestTasks = hasPermissionForPerson({
      user,
      person: person.value,
      permissions: [GroupPermission.GROUP_PERMISSION_TASK_READ],
    })

    if (!canRequestTasks) {
      setTasks({ tag: 'Complete', value: [] })
    } else {
      try {
        const { data: tasks } = await fetchTasks({
          pId: person.value.id,
          orgId: person.value.orgId,
        })
        setTasks(loaded(tasks))
      } catch (e) {
        setTasks(loaded([]))
        setError(e)
      }
    }
  }

  useEffect(() => {
    void refreshTasks()
  }, [person.tag, person.tag === 'Complete' ? person.value.id : ''])

  if (person.tag === 'Loading') {
    return { tasks: { tag: 'Loading' }, refreshTasks }
  }

  return { tasks, refreshTasks }
}

export function useTasks(
  skipFirstFetch = false,
  deps: React.DependencyList = []
) {
  const { user } = useUserContext()
  const { setError } = useContext(GlobalContext)
  const { person, tasks = [], setTasks } = useContext(PersonContext)
  const [shouldSkipFetch, setShouldSkipFetch] =
    useState<boolean>(skipFirstFetch)

  const refreshTasks = async () => {
    if (person === undefined) {
      return
    }

    const canRequestTasks = hasPermissionForPerson({
      user,
      person,
      permissions: [GroupPermission.GROUP_PERMISSION_TASK_READ],
    })

    if (!canRequestTasks) {
      setTasks([])
    } else {
      await fetchTasks({
        pId: person.id || '',
        orgId: person?.orgId || '',
      })
        .then((response) => setTasks(response.data))
        .catch(setError)
    }
  }

  useEffect(() => {
    if (shouldSkipFetch && person !== undefined) {
      setShouldSkipFetch(false)
    } else {
      void refreshTasks()
    }
  }, [person?.id, ...deps])

  return { tasks, refreshTasks }
}
