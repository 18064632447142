import { SignableForm } from '@augusthealth/models/com/august/protos/signable_form'
import { useContext, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { deleteForm } from '@app/api/form'
import ConfirmModal from '@app/components/ConfirmModal'
import HUD from '@app/components/HUD'

type Props = {
  form: SignableForm
  onClose: () => void
  afterDelete: () => void
}

export default function DeleteConfirmPopup({
  form,
  onClose,
  afterDelete,
}: Props) {
  const [showNotice, setShowNotice] = useState<boolean>(false)
  const { setError } = useContext(GlobalContext)
  const { id, name } = form
  const onConfirm = async () => {
    try {
      await deleteForm({ id })
      setShowNotice(true)
    } catch (e) {
      onClose()
      setError(e)
    }
  }
  const onExpire = () => {
    onClose()
    afterDelete()
  }

  if (showNotice) {
    return <HUD onExpire={onExpire}>Template {name} deleted</HUD>
  }

  return (
    <ConfirmModal
      title={`Are you sure that you want to delete ${name}?`}
      confirmButtonConfig={{
        children: 'Delete',
        onClick: onConfirm,
      }}
      denyButtonConfig={{
        onClick: onClose,
      }}
    />
  )
}
