import { AsyncIconButton } from '@shared/components/AsyncButton'
import VitalsInputProducer from '@shared/components/Vitals/VitalsInputProducer'

const INLINE_CONTAINER_CLASSNAME = 'flex items-center py-[8px] px-0'

export function EditVitalsRow(props: {
  methods: any
  onClickSave: (data) => Promise<void>
  activeTab: any
  latestVitals: any
  disabled: any
  onClickCancel: () => void
}) {
  const {
    methods,
    onClickSave,
    activeTab,
    latestVitals,
    disabled,
    onClickCancel,
  } = props

  return (
    <td colSpan={5}>
      <form
        onSubmit={methods.handleSubmit(onClickSave)}
        className={INLINE_CONTAINER_CLASSNAME}
      >
        <div className="grow">
          <VitalsInputProducer
            methods={methods}
            mode={{ tag: 'onClickSave' }}
            vitalsType={activeTab!}
            latestVitals={latestVitals}
            showTooltips={false}
          />
        </div>
        <div className={'mb-[8px]'}>
          <AsyncIconButton
            width="80px"
            data-testid="save-edit-vital"
            buttonStyle="primary-fill"
            className="ml-[8px] mr-[8px]"
            type="submit"
            disabled={disabled}
            isLoading={disabled}
          >
            Save
          </AsyncIconButton>
          <AsyncIconButton
            onClick={onClickCancel}
            width="80px"
            buttonStyle="tertiary-outline"
          >
            Cancel
          </AsyncIconButton>
        </div>
      </form>
    </td>
  )
}

export function DeleteVitalsRow(props: {
  value: any
  unitsLabel: any
  date: any
  disabled: any
  onClickDelete: () => Promise<void>
  onClickCancel: () => void
}) {
  return (
    <td colSpan={5}>
      <div className={INLINE_CONTAINER_CLASSNAME}>
        <div className="flex grow">
          <span className="w-[124px] text-[14px] font-semibold">
            {props.value} {props.unitsLabel}
          </span>
          <span className="w-[320px] text-[14px] font-medium text-gray-07">
            {props.date}
          </span>
          <span className="grow text-[14px] font-medium text-button-quaternary-color">
            Delete this vital?
          </span>
        </div>
        <div>
          <AsyncIconButton
            width="80px"
            buttonStyle="danger-fill"
            className="mr-[8px]"
            data-testid="confirm-delete-vital"
            disabled={props.disabled}
            isLoading={props.disabled}
            onClick={props.onClickDelete}
          >
            Delete
          </AsyncIconButton>
          <AsyncIconButton
            width="80px"
            buttonStyle="tertiary-outline"
            onClick={props.onClickCancel}
          >
            Cancel
          </AsyncIconButton>
        </div>
      </div>
    </td>
  )
}
