import { OrgFacilitySettings } from '@augusthealth/models/com/august/protos/org_facility_settings'
import {
  SignableFormAssignment,
  SignableFormMetaData,
} from '@augusthealth/models/com/august/protos/signable_form'
import { Organization } from '@shared/types/organization'
import {
  findScheduledTaskForTaskDefinition,
  sortTaskDefinitions,
} from '@shared/utils/orgFacilitySettings'
import TaskCard from '../TaskCard'
import { SuppressedTaskCard } from '../TaskCard/SuppressedTaskCard'

type Props = {
  allAssignments: SignableFormAssignment[]
  formList: SignableFormMetaData[]
  loadAssignments: () => Promise<any>
  onTaskDefinitionChange: () => Promise<void>
  org: Organization
  taskSettings: OrgFacilitySettings[]
  stateName: string
  scheduledTaskSettings: OrgFacilitySettings[]
  reloadScheduledTaskSettings: () => Promise<void>
}

export default function OrgStateTasks({
  allAssignments,
  formList,
  loadAssignments,
  onTaskDefinitionChange,
  org,
  taskSettings,
  stateName,
  scheduledTaskSettings,
  reloadScheduledTaskSettings,
}: Props) {
  const { id: orgId, name } = org

  return (
    <div data-testid="task-cards">
      {sortTaskDefinitions(taskSettings).map((ts: OrgFacilitySettings) => {
        const task = ts.settings!.taskDefinition!

        if (!task.template) {
          return (
            <SuppressedTaskCard
              key={`org-${ts.key}`}
              taskSetting={ts}
              reloadTaskSettings={onTaskDefinitionChange}
            />
          )
        }
        const { taskType, shortName, displayName, dataType } = task.template!
        const taskKey = [
          stateName,
          taskType,
          displayName || '',
          dataType || '',
          shortName || '',
        ].join('-')

        return (
          <TaskCard
            data-testid={`task-card-${taskKey}`}
            cardLevel={{
              tag: 'State/Org',
              orgId: orgId!,
              state: stateName,
            }}
            allAssignments={allAssignments}
            key={taskKey}
            formList={formList}
            name={name!}
            taskSettings={ts}
            scheduledTask={findScheduledTaskForTaskDefinition({
              cardLevel: { tag: 'State/Org', orgId: orgId!, state: stateName },
              scheduledTasks: scheduledTaskSettings,
              taskDefinition: ts,
            })}
            loadAssignments={loadAssignments}
            onTaskDefinitionChange={async () => {
              await onTaskDefinitionChange()
            }}
            onScheduledTaskChange={async () => {
              await reloadScheduledTaskSettings()
            }}
          />
        )
      })}
    </div>
  )
}
