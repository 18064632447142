import {
  DashboardSession,
  getRainforestDashboardSession,
} from 'app/api/payments'
import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Facility } from '@shared/types/facility'
import { loaded, LOADING, LoadingWithPriorValue } from '@shared/utils/loading'

export default function useRainforestDashboardSession(facility: Facility) {
  const { orgId, id: facilityId } = facility
  const { setError } = useContext(GlobalContext)
  const [dashboardSession, setDashboardSession] =
    useState<LoadingWithPriorValue<DashboardSession>>(LOADING)

  async function reloadDashboardSession() {
    if (dashboardSession.tag === 'Complete') {
      setDashboardSession({
        tag: 'LoadingWithPriorValue',
        value: dashboardSession.value,
      })
    }

    try {
      const session = await getRainforestDashboardSession({
        orgId,
        facilityId,
      })
      setDashboardSession(loaded(session))
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    void reloadDashboardSession()
  }, [orgId, facilityId])

  return {
    dashboardSession,
    reloadDashboardSession,
  }
}
