import { twx } from '@shared/utils/tailwind'
import styles from './styles.module.css'

export default function PersonPageTitle({
  title,
  children,
  className,
  subtitle = false,
  withBorder = true,
}: {
  title: string | React.ReactNode
  children?: React.ReactNode
  className?: string
  fontSize?: number | string
  subtitle?: boolean
  withBorder?: boolean
}) {
  const containerClasses = twx(styles.container, className, {
    'border-0': !withBorder,
    'mb-[8px]': !withBorder,
  })

  return (
    <section className={containerClasses}>
      <h2
        className={twx('mb-0 font-medium leading-[36px] text-secondary-02', {
          'text-[24px]': !subtitle,
          'text-[20px]': subtitle,
        })}
      >
        {title}
      </h2>
      <div>{children}</div>
    </section>
  )
}
