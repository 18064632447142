import { CiminoLevelOfCareAssessment } from '@augusthealth/models/com/august/protos/level_of_care'
import BulletedSection from '@app/components/BulletedSection'
import levelOfCare1Conf from './json/levelOfCare1.json'
import levelOfCare2Conf from './json/levelOfCare2.json'
import levelOfCare3Conf from './json/levelOfCare3.json'
import levelOfCare4Conf from './json/levelOfCare4.json'

const sections: { title: string; config: any[] }[] = [
  { title: 'Grooming', config: levelOfCare1Conf },
  { title: 'Mobility & Sleep', config: levelOfCare2Conf },
  { title: 'Mobility & Sleep', config: levelOfCare3Conf },
  {
    title: 'Dementia Quiz',
    config:
      levelOfCare4Conf[0].configuration[0].formGeneratorProps.configuration,
  },
]

export default function ReadOnlyLevelOfCare({
  levelOfCare,
}: {
  levelOfCare: CiminoLevelOfCareAssessment
}) {
  return (
    <>
      {sections.map((section, ix) => {
        return (
          <div className={ix === 3 ? 'pb-[48px]' : ''} key={`section-${ix}`}>
            <BulletedSection containerClasses="mb-[32px]">
              {section.title}
            </BulletedSection>
            {section.config.map((question, qx) => {
              if (question.type === 'dropdown') return null
              if (question.type === 'buttons')
                return (
                  <ButtonAsRadio
                    key={`bar-${qx}`}
                    question={question}
                    qx={qx}
                    levelOfCare={levelOfCare}
                  />
                )
              if (question.type === 'textarea') return null
              return (
                <RadioOrCheckbox
                  question={question}
                  key={`question-${qx}`}
                  qx={qx}
                  levelOfCare={levelOfCare}
                />
              )
            })}
          </div>
        )
      })}
      <BulletedSection containerClasses="mb-[32px]">
        Summary Notes
      </BulletedSection>
      {levelOfCare.notes}
    </>
  )
}

function RadioOrCheckbox({
  question,
  levelOfCare,
  qx,
}: {
  question: any
  levelOfCare: any
  qx: number
}) {
  return (
    <div className="mb-[32px]" key={`question-${qx}`}>
      <h3 className="mb-[16px] text-[14px] font-bold">{question.title}</h3>
      <>
        {question.options.map((option, ax) => {
          const checked =
            question.type === 'radio'
              ? option.value === levelOfCare[question.name]
              : (levelOfCare[question.name] || []).includes(option.value)

          return (
            <div
              key={`question-${qx}-${ax}`}
              className="mb-[8px] flex items-center"
            >
              <input
                className="mr-[8px]"
                readOnly
                checked={checked}
                type={question.type}
              />
              <label className="m-0 text-[16px] font-medium leading-[24px]">
                {option.label} ({option.score})
              </label>
            </div>
          )
        })}
      </>
    </div>
  )
}

function ButtonAsRadio({
  question,
  levelOfCare,
  qx,
}: {
  question: any
  levelOfCare: any
  qx: number
}) {
  return (
    <div key={`question-${qx}`}>
      <h3 className="mb-[16px] mt-[32px] text-[14px] font-bold uppercase">
        {question.title}
      </h3>
      <>
        {question.options.map((option, ax) => {
          const [key1, key2] = question.name.split('.')
          const chosenValue =
            levelOfCare && levelOfCare[key1] && levelOfCare[key1][key2]
          const checked = option.value === chosenValue

          return (
            <div
              key={`question-${qx}-${ax}`}
              className="mb-[8px] flex items-center"
            >
              <input
                className="mr-[8px]"
                readOnly
                checked={checked}
                type="radio"
              />
              <label className="m-0 text-[16px] font-medium leading-[24px]">
                {option.label} ({option.score})
              </label>
            </div>
          )
        })}
      </>
    </div>
  )
}
