import classNames from 'classnames/bind'
import { useContext } from 'react'
import { fetchBlobUrlAndContentType } from '@shared/api/request'
import ButtonLink from '@shared/components/ButtonLink'
import GlobalContext from '@shared/contexts/GlobalContext'
import { snapshotFileUrl } from '@shared/legacy_routes'
import { DataType, Snapshot } from '@shared/types/snapshot'

export default function AttachmentLink({
  attachment,
  isLoading,
  setIsLoading,
}: {
  attachment: Snapshot
  isLoading: boolean
  setIsLoading: (loading: string | undefined) => void
}) {
  const { setError } = useContext(GlobalContext)
  const { personId, orgId, id } = attachment

  const path = snapshotFileUrl({
    personId: personId!,
    orgId: orgId!,
    dataType: DataType.DATA_TYPE_INCIDENT_ATTACHMENT,
    snapshotId: id!,
  })

  const download = async () => {
    try {
      setIsLoading(attachment.id)
      const result = await fetchBlobUrlAndContentType({ url: path })
      window.open(result.url, 'noreferrer')
    } catch (e) {
      setError(e)
    } finally {
      setIsLoading(undefined)
    }
  }

  const iconStyles = classNames.call(null, 'fa fa-fw mr-[4px]', {
    'fa-file': !isLoading,
    'fa-solid fa-spinner fa-spin': isLoading,
  })

  return (
    <ButtonLink
      disabled={isLoading}
      onClick={async (e) => {
        e.preventDefault()
        await download()
      }}
    >
      <i className={iconStyles} />
      {attachment.data?.uploadInfo?.name}
    </ButtonLink>
  )
}
