import { ChangeEvent, useState } from 'react'
import { LabelAboveInput } from '@shared/components/Labels'
import { Snapshot, SnapshotStatus } from '@shared/types/snapshot'
import { GtkyFields, GtkySnapshotPatch } from '@shared/utils/gtky'
import LongformAnswer from '@app/pages/Tasks/GettingToKnowYouV2/components/LongformAnswer'
import ActivitiesSection from './components/ActivitiesSection'

type Props = {
  updateSnapshot: ({
    patch,
    status,
  }: {
    patch: GtkySnapshotPatch | null
    status: SnapshotStatus
  }) => Promise<void>
  snapshot: Snapshot
  isEditing?: boolean
}

export default function InterestsPage({
  updateSnapshot,
  snapshot,
  isEditing = false,
}: Props) {
  const [fields, setFields] = useState<GtkySnapshotPatch>({})

  const disabled = !isEditing

  const saveSnapshot = async (formValues = fields) =>
    await updateSnapshot({ patch: formValues, status: snapshot.status! })

  const formFields = [
    {
      field: GtkyFields.CLUBS_AND_ORGS,
      question:
        'Do you belong to any social or professional organizations, clubs, etc.? Tell us about them.',
    },
    {
      field: GtkyFields.MUSIC,
      question: 'What types of music do you enjoy?',
    },
    {
      field: GtkyFields.MOVIES,
      question: 'What types of movies do you enjoy?',
    },
    {
      field: GtkyFields.TV,
      question: 'Do you have any favorite television shows?',
    },
    {
      field: GtkyFields.BOOKS,
      question: 'What are your favorite books or reading genres?',
    },
    {
      field: GtkyFields.SPORTS,
      question: 'Do you have favorite sports teams or players?',
    },
  ]

  return (
    <>
      {formFields.map((field) => {
        return (
          <div key={field.field}>
            <LabelAboveInput htmlFor={field.field} uppercase={false}>
              {field.question}
            </LabelAboveInput>
            <LongformAnswer
              mode={isEditing ? 'edit' : 'view'}
              disabled={disabled}
              onBlur={async () => await saveSnapshot()}
              id={field.field}
              name={field.field}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                setFields({
                  ...fields,
                  [field.field]: e.target.value,
                })
              }}
              defaultValue={
                snapshot!.data?.formData?.fields?.[field.field] || ''
              }
            />
          </div>
        )
      })}
      <ActivitiesSection
        snapshot={snapshot}
        setFields={setFields}
        fields={fields}
        saveSnapshot={saveSnapshot}
        isEditing={isEditing}
      />
    </>
  )
}
