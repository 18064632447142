import { format, parseISO } from 'date-fns'
import { PayerDetailedStatement } from '@shared/types/billing'
import { formatCurrencyForBilling } from '@shared/utils/billing'

export function getFormattedStatementBalanceHeader(
  statement: PayerDetailedStatement
) {
  const { data: { issueDate } = {} } = statement
  if (!issueDate) return undefined

  const date = parseISO(issueDate)
  const formattedIssueDate = format(date, 'MMMM do')

  return `${formattedIssueDate} statement balance`
}

export function getFormattedStatementBalanceValue(
  statement: PayerDetailedStatement
) {
  const { data: { rollOverBalanceCents = 0 } = {}, invoiceAmountCents } =
    statement

  return formatCurrencyForBilling(invoiceAmountCents + rollOverBalanceCents)
}

export function getFormattedTotal(statement: PayerDetailedStatement) {
  const {
    data: { rollOverBalanceCents = 0 } = {},
    invoiceAmountCents,
    paidAmountCents,
  } = statement

  return formatCurrencyForBilling(
    invoiceAmountCents + rollOverBalanceCents - paidAmountCents
  )
}
