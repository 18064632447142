import ExpectedErrorModal from '@shared/components/ErrorModal/ExpectedErrorModal'

export default function ConnectionProblems() {
  return (
    <ExpectedErrorModal
      id={'connection-problems'}
      title={'Internet problems'}
      buttonProps={{
        children: 'Reload',
        onClick: () => window.location.reload(),
      }}
      imgProps={{
        src: '/images/illustration_no_internet.svg',
        className: 'flex justify-center h-auto mx-auto',
      }}
    >
      We've detected some internet problems. Please check your connection and
      reload.
    </ExpectedErrorModal>
  )
}
