import { Report } from '@augusthealth/models/com/august/protos/report'
import { useContext } from 'react'
import {
  ActionButtonHolder,
  DownloadButton,
  PrintButton,
} from '@shared/components/AnimatedPopup/Lightbox/ActionButtons/ActionButtons'
import Lightbox, {
  LightboxSidebarMessage,
  LightboxSidebarTitle,
} from '@shared/components/AnimatedPopup/Lightbox/Lightbox'
import { Facility } from '@shared/types/facility'
import { twx } from '@shared/utils/tailwind'
import FacilitiesContext from '@app/contexts/FacilitiesContext'
import { formatReportName } from '../helpers'
import ReportTable from '../ReportTable'

type PreviewLightboxProps = {
  orgId: string
  facilityId: string
  facility?: Facility
  name: string
  previewReport?: Report
  download?: (reportName: string) => void
  print?: (reportName: string) => void
  onClose: () => void
  printIFrameIsLoading?: boolean
}

export default function PreviewLightbox(props: PreviewLightboxProps) {
  const { facilities = [] } = useContext(FacilitiesContext)
  const {
    download,
    facilityId,
    facility: defaultFacility,
    name,
    onClose,
    print,
    printIFrameIsLoading,
  } = props
  const facility =
    defaultFacility ||
    (facilities && facilities.find((f) => f.id === facilityId))
  const downloadIcon = download && (
    <DownloadButton
      onClick={(ev) => {
        ev.preventDefault()
        download(name)
      }}
    />
  )
  const printIcon = print && (
    <PrintButton
      loading={printIFrameIsLoading}
      onClick={() => {
        if (!printIFrameIsLoading) {
          print(name)
        }
      }}
    />
  )

  return (
    <Lightbox
      mainContentBackgroundColor={'white'}
      mainContent={
        <div className={twx('flex h-full w-full flex-col')}>
          <ReportTable {...props} />
        </div>
      }
      onClose={onClose}
      sidebarContent={
        <div className={twx('flex flex-col justify-start')}>
          <LightboxSidebarTitle>{formatReportName(name)}</LightboxSidebarTitle>
          {facility && (
            <LightboxSidebarMessage>{facility.name}</LightboxSidebarMessage>
          )}
          <ActionButtonHolder>
            {downloadIcon}
            {printIcon}
          </ActionButtonHolder>
        </div>
      }
    />
  )
}
