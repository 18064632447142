import { Option } from 'effect'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { patchRoutineAdministration } from '@shared/api/careapp'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import Card from '@shared/components/Card'
import Icon from '@shared/components/Icon'
import {
  RescheduleExceptionRadioButton,
  ResidentRefusedExceptionRadioButton,
  ResidentUnavailableExceptionRadioButton,
} from '@shared/components/RoutineAdministrationButtons/ExceptionRadioButtons'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import {
  CareAppPerson,
  RoutineAdministration,
  RoutineAdministrationExceptionReason,
} from '@shared/types/careapp'
import {
  buildExceptionEvent,
  getMostRecentEventId,
  getUndoEvent,
} from '@shared/utils/routineAdministration'
import { tw, twx } from '@shared/utils/tailwind'
import RoutineAdministrationBanner from './RoutineAdministrationBanner'
import RoutineModal from './RoutineModal'

type ExceptionFormData = {
  exceptionReason: RoutineAdministrationExceptionReason | null
  note: string | null
}

export default function MarkAsExceptionModal({
  careAppPerson,
  onClose,
  routineAdministration,
  facilityTimeZone,
  reload,
}: {
  careAppPerson: CareAppPerson
  onClose: () => void
  routineAdministration: RoutineAdministration
  facilityTimeZone: string
  reload: () => void
}) {
  const { setError } = useContext(GlobalContext)
  const { user } = useUserContext()
  const {
    id: adminId,
    events,
    reasonForException,
    note,
  } = routineAdministration
  const { formState, register, handleSubmit } = useForm<ExceptionFormData>({
    defaultValues: { exceptionReason: reasonForException || null, note },
    resolver: (values) => {
      const { exceptionReason, note } = values
      const errors =
        exceptionReason !== null || (note !== null && note.trim().length > 0)
          ? {}
          : {
              exceptionReason: {
                type: 'required',
                message: 'Please select a reason and/or provide a note',
              },
            }
      return {
        values,
        errors,
      }
    },
  })
  const { errors } = formState
  const exceptionReasonProps = register('exceptionReason')
  const onSave = async ({ exceptionReason, note }: ExceptionFormData) => {
    try {
      const performer = {
        userId: user.id,
        userName: user.name,
      }

      const optionUndoEvent = getUndoEvent({
        performer,
        administration: routineAdministration,
      })

      const exceptionEvent = buildExceptionEvent({
        performer,
        exceptionReason,
        note,
        parentId: getMostRecentEventId(events),
      })

      const nextEvents = Option.match(optionUndoEvent, {
        onNone: () => [exceptionEvent],
        onSome: (undoEvent) => [undoEvent, exceptionEvent],
      })

      // Still need to add reasonForException under routineAdministration
      // They will not be added by exceptionReason in new event
      await patchRoutineAdministration({
        adminId,
        careAppPerson,
        routineAdministration: {
          events: nextEvents,
          reasonForException: exceptionReason,
        },
      })

      reload()
      onClose()
    } catch (err) {
      setError(err)
    }
  }

  return (
    <RoutineModal
      id="progress-routines-mark-as-done-modal"
      careAppPerson={careAppPerson}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSave)}>
        <Card className={tw`flex-col`}>
          <RoutineAdministrationBanner
            routineAdministration={routineAdministration}
            facilityTimeZone={facilityTimeZone}
          />
          <div className={tw`mt-[16px] flex-col px-[8px]`}>
            <div
              className={tw`mb-[16px] text-[14px] font-semibold text-gray-04`}
            >
              <Icon name="circle-x" variant="regular" />
              <span className={tw`ml-[8px]`}>Reason for exception?</span>
            </div>
            <fieldset className="flex flex-col gap-3">
              <legend className="hidden">Choose reason for exception</legend>
              <p
                className={twx(
                  {
                    hidden: !errors.exceptionReason && !errors.root,
                  },
                  'rounded-lg bg-alert-fill-color p-2 text-center text-alert'
                )}
              >
                {errors.exceptionReason?.message}
                {errors.root?.message}
              </p>
              <ResidentUnavailableExceptionRadioButton
                inputProps={exceptionReasonProps}
              />
              <RescheduleExceptionRadioButton
                inputProps={exceptionReasonProps}
              />
              <ResidentRefusedExceptionRadioButton
                inputProps={exceptionReasonProps}
              />
            </fieldset>
            <BasicTextarea
              {...register('note')}
              placeholder="Add optional note..."
              className="mt-[12px] h-[5.5rem] min-h-[5.5rem]"
            />
          </div>
        </Card>
        <div className={tw`mt-[24px]`}>
          <AnimatedPopupFormFooter
            yesBtn={{
              label: 'Mark as exception',
              props: { width: 'calc(50% - 8px)' },
            }}
            noBtn={{
              action: onClose,
              props: { width: 'calc(50% - 8px)' },
            }}
            formState={formState}
          />
        </div>
      </form>
    </RoutineModal>
  )
}
