import { useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { typedMergePatchPerson } from '@shared/api/person'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { RESIDENT_INFO } from '@shared/constants/custom_field/categories/residentInfo'
import { FieldWithOptionsConfiguration } from '@shared/constants/custom_field/type'
import GlobalContext from '@shared/contexts/GlobalContext'
import { AugustFieldType } from '@shared/types/custom_field'
import {
  Person_MaritalStatus as MaritalStatus,
  Person,
} from '@shared/types/person'
import { tw } from '@shared/utils/tailwind'
import usePerson from '@app/hooks/usePerson'
import { PortalModal } from '../components/PortalModal'

type Props = { person: Person; onClose: (updated: boolean) => Promise<void> }

const FIELDS = ['maritalStatus']
const OPTIONS = (
  RESIDENT_INFO[AugustFieldType.AUGUST_FIELD_TYPE_MARITAL_STATUS]
    ?.fields[0] as FieldWithOptionsConfiguration
).options

export default function EditMartialStatusModal({ person, onClose }: Props) {
  const { setError } = useContext(GlobalContext)
  const { control, handleSubmit, formState, reset } = useForm<Person>({
    defaultValues: person,
  })
  const { person: updatedPerson } = usePerson({
    initialData: person,
    fields: FIELDS,
  })
  const { orgId, facilityId: fId, id: pId } = person

  useEffect(() => {
    if (updatedPerson.tag === 'Complete') {
      reset(updatedPerson.value)
    }
  }, [updatedPerson.tag])

  const onSubmit = async ({ maritalStatus }: Person) => {
    if (updatedPerson.tag === 'Loading') {
      return
    }

    try {
      await typedMergePatchPerson({
        fId,
        pId,
        orgId,
        fields: FIELDS,
        patch: { maritalStatus },
      })
      await onClose(true)
    } catch (e) {
      setError(e)
    }
  }

  const formContent = (
    <form>
      <div>
        <section className={tw`mb-[32px]`}>
          <LabelAboveInput htmlFor="maritalStatus">
            Marital Status
          </LabelAboveInput>
          <Controller
            control={control}
            rules={{ required: true }}
            name="maritalStatus"
            render={({ field: { onChange, value } }) => {
              return (
                <StyledSelect
                  inputId="maritalStatus"
                  onChange={(opt: OptionTypeBase<MaritalStatus>) => {
                    onChange(opt.value)
                  }}
                  options={OPTIONS}
                  value={OPTIONS.find((o) => o.value === value)}
                  isDisabled={updatedPerson.tag === 'Loading'}
                />
              )
            }}
          />
        </section>
      </div>
      <footer className={tw`mt-[32px]`}>
        <AnimatedPopupFormFooter
          noBtn={{ action: () => void onClose(false) }}
          yesBtn={{
            props: {
              type: 'button',
              onClick: handleSubmit(onSubmit),
              disabled: updatedPerson.tag === 'Loading' || !formState.isDirty,
            },
          }}
          formState={formState}
        />
      </footer>
    </form>
  )

  return (
    <PortalModal>
      <AnimatedPopup title="Marital Status">{formContent}</AnimatedPopup>
    </PortalModal>
  )
}
