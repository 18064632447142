import {
  Contact,
  Contact_ContactRelationship,
} from '@augusthealth/models/com/august/protos/contact'
import { ChangeEvent, useState } from 'react'
import { createPortal } from 'react-dom'
import Skeleton from 'react-loading-skeleton'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect, {
  CreatableStyledMultiSelect,
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { Person } from '@shared/types/person'
import { Snapshot, SnapshotStatus } from '@shared/types/snapshot'
import { relationshipName } from '@shared/utils/contact'
import { GtkyFields, GtkySnapshotPatch } from '@shared/utils/gtky'
import { Loading } from '@shared/utils/loading'
import { ButtonGroup } from '@app/components/ButtonGroup'
import { useReligiousAdvisors } from '@app/hooks/useReligiousAdvisors'
import { AddContactModal } from '@app/pages/Contacts/ContactModal/ContactModal'
import {
  dayOfWeekOptions,
  longDayOfWeekOptions,
} from '@app/pages/Routines/constants'
import LongformAnswer from '@app/pages/Tasks/GettingToKnowYouV2/components/LongformAnswer'
import styles from './gtky.module.css'

type Props = {
  updateSnapshot: ({
    patch,
    status,
  }: {
    patch: GtkySnapshotPatch | null
    status: SnapshotStatus
  }) => Promise<void>
  snapshot: Snapshot
  person: Person
  contacts: Loading<Contact[]>
  refreshContacts: () => Promise<void>
  isEditing?: boolean
}

export default function ReligiousPreferencesPage({
  updateSnapshot,
  snapshot,
  person,
  contacts,
  refreshContacts,
  isEditing = false,
}: Props) {
  const [showContactModal, setShowContactModal] = useState(false)
  const [fields, setFields] = useState<GtkySnapshotPatch>({})

  const disabled = !isEditing
  const saveSnapshot = async (formValues = fields) =>
    await updateSnapshot({ patch: formValues, status: snapshot.status! })

  const saveReligiousAdvisorsOnSnapshot = async (
    selectedAdvisors: OptionTypeBase<Contact>[]
  ) => {
    const contactIds = selectedAdvisors
      .map((val) => `${val?.value.id || undefined}`)
      .filter((v) => v)

    await saveSnapshot({
      [GtkyFields.RELIGIOUS_ADVISOR]: contactIds,
    })
  }

  const {
    religiousAdvisorOptions,
    selectedReligiousAdvisors,
    setSelectedReligiousAdvisors,
  } = useReligiousAdvisors({
    contacts,
    snapshot,
    saveReligiousAdvisors: saveReligiousAdvisorsOnSnapshot,
  })

  const religiousServiceOptions = [
    {
      label: 'In Community',
      value: 'in-community',
    },
    {
      label: 'Outside',
      value: 'outside',
    },
    {
      label: 'Both',
      value: 'in-and-outside-community',
    },
  ]

  const defaultReligiousServiceLocation = snapshot.data?.formData?.fields?.[
    GtkyFields.RELIGIOUS_SERVICE_LOCATION
  ]
    ? religiousServiceOptions.find(
        ({ value }) =>
          value ===
          snapshot.data?.formData?.fields?.[
            GtkyFields.RELIGIOUS_SERVICE_LOCATION
          ]
      )
    : undefined

  return (
    <>
      <div>
        <LabelAboveInput
          htmlFor={GtkyFields.RELIGIOUS_PREFERENCE}
          uppercase={false}
        >
          Do you have a religious or spiritual preference? If so, how active are
          you? <br />
          <em>(very, fairly, less)</em>
        </LabelAboveInput>
        <LongformAnswer
          mode={isEditing ? 'edit' : 'view'}
          disabled={disabled}
          onBlur={async () => await saveSnapshot()}
          id={GtkyFields.RELIGIOUS_PREFERENCE}
          name={GtkyFields.RELIGIOUS_PREFERENCE}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            setFields({
              ...fields,
              [GtkyFields.RELIGIOUS_PREFERENCE]: e.target.value,
            })
          }}
          defaultValue={
            snapshot!.data?.formData?.fields?.[
              GtkyFields.RELIGIOUS_PREFERENCE
            ] || ''
          }
        />
      </div>
      <div>
        <LabelAboveInput
          htmlFor={GtkyFields.RELIGIOUS_ADVISOR}
          uppercase={false}
        >
          Who are your clergy or religious advisors?
        </LabelAboveInput>
        {contacts.tag === 'Complete' ? (
          <CreatableStyledMultiSelect
            disabled={disabled}
            id={GtkyFields.RELIGIOUS_ADVISOR}
            name={GtkyFields.RELIGIOUS_ADVISOR}
            instanceId={GtkyFields.RELIGIOUS_ADVISOR}
            isValidNewOption={() => true}
            createOptionPosition={'last'}
            formatCreateLabel={() => (
              <span className={styles.newContact}>
                <i className="fa fa-plus-circle mr-[8px]"></i>Add New Contact
              </span>
            )}
            onCreateOption={() => setShowContactModal(true)}
            onChange={async (value: OptionTypeBase<Contact>[]) => {
              setSelectedReligiousAdvisors(value)
              await saveReligiousAdvisorsOnSnapshot(value)
            }}
            value={selectedReligiousAdvisors}
            options={religiousAdvisorOptions?.filter(
              (opt) =>
                !selectedReligiousAdvisors.some(
                  (selected) => selected.value.id === opt.value!.id
                )
            )}
            placeholder="Select advisors..."
          />
        ) : (
          <Skeleton width={'600px'} height={'48px'} />
        )}
      </div>

      <div>
        <LabelAboveInput
          htmlFor={GtkyFields.RELIGIOUS_SERVICE_LOCATION}
          uppercase={false}
        >
          Ideally, would you like to attend services outside the building,
          within the community, or both?
        </LabelAboveInput>
        <StyledSelect
          isDisabled={disabled}
          id={GtkyFields.RELIGIOUS_SERVICE_LOCATION}
          name={GtkyFields.RELIGIOUS_SERVICE_LOCATION}
          instanceId={GtkyFields.RELIGIOUS_SERVICE_LOCATION}
          data-testid={GtkyFields.RELIGIOUS_SERVICE_LOCATION}
          onChange={async (value: OptionTypeBase) => {
            await saveSnapshot({
              [GtkyFields.RELIGIOUS_SERVICE_LOCATION]: value.value,
            })
          }}
          options={religiousServiceOptions}
          placeholder={'Select a preference...'}
          defaultValue={defaultReligiousServiceLocation}
        />
      </div>

      <div>
        <LabelAboveInput htmlFor={GtkyFields.DAYS_OF_WORSHIP} uppercase={false}>
          Day(s) of worship observed
        </LabelAboveInput>
        <ButtonGroup
          disabled={disabled}
          prependTestId={'days-of-worship'}
          onChange={async (selected) => {
            const selectedArray = selected.map((s) => s.value)
            const updatedFields = {
              ...fields,
              [GtkyFields.DAYS_OF_WORSHIP]: selectedArray,
            }
            setFields(updatedFields)
            await saveSnapshot(updatedFields)
          }}
          options={longDayOfWeekOptions}
          chosen={
            snapshot!.data?.formData?.oneToManyFields?.[
              GtkyFields.DAYS_OF_WORSHIP
            ]?.values
              ? (snapshot.data.formData.oneToManyFields[
                  GtkyFields.DAYS_OF_WORSHIP
                ].values!.map((d) => {
                  return dayOfWeekOptions.find(({ value }) => value === d)
                }) as OptionTypeBase[])
              : []
          }
        />
      </div>

      {showContactModal && (
        <PortalModal>
          <AddContactModal
            person={person}
            onClose={async (updated) => {
              setShowContactModal(false)
              if (updated) {
                await refreshContacts()
              }
            }}
            defaultFormValues={{
              primaryRelationship:
                Contact_ContactRelationship.CONTACT_RELATIONSHIP_PROFESSIONAL,
              secondaryRelationship: {
                label: relationshipName(
                  Contact_ContactRelationship.CONTACT_RELATIONSHIP_RELIGIOUS_ADVISOR
                ),
                value:
                  Contact_ContactRelationship.CONTACT_RELATIONSHIP_RELIGIOUS_ADVISOR,
              },
            }}
          />
        </PortalModal>
      )}
    </>
  )
}

function PortalModal({ children }) {
  const appRoot = document.getElementById('root')

  return createPortal(children, appRoot!)
}
