import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { ModalTitle } from '@shared/components/baseMui/Modal/Layout'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { EmbossedCard } from '@shared/components/EmbossedCard'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Incident, IncidentActionType } from '@shared/types/incidents'
import { Person } from '@shared/types/person'
import { customIncidentActionIdentifier } from '@shared/utils/incident'
import { getOrElse } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import { getIncident } from '@app/api/incidents'
import { RHFSwitch } from '@app/components/Switch'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import { useIncidentActionTemplates } from '@app/hooks/useIncidentActionTemplates'
import { followUpActionsForFacility } from '../helpers'
import {
  IncidentFormData,
  toDefaultValues,
} from '../IncidentForm/incidentFormHelpers'
import {
  getActionsToBeCreated,
  getActionsToBeDeleted,
  getActionTypeOtherToBeUpdated,
  updateIncidentActions,
} from './editFollowUpActionsModalHelpers'

export default function EditFollowUpActionsModal({
  incident,
  closeModal,
  person,
}: {
  incident: Incident
  closeModal: (updated?: Incident) => Promise<void>
  person: Person
}) {
  const { currentFacility } = useCurrentFacility()
  const { actionTemplates } = useIncidentActionTemplates({ person })

  const methods = useForm<IncidentFormData>({
    mode: 'onChange',
    defaultValues: toDefaultValues(incident),
  })

  const { formState, register } = methods
  const { setError } = useContext(GlobalContext)

  if (currentFacility === undefined) {
    return null
  }

  const filteredFollowupActions =
    currentFacility && currentFacility.tag === 'Complete'
      ? followUpActionsForFacility(currentFacility.value)
      : []

  async function onSubmit(formData: IncidentFormData) {
    const actionsToBeDeleted = getActionsToBeDeleted(formData, incident)
    const actionsToBeCreated = getActionsToBeCreated(
      formData,
      incident,
      getOrElse(actionTemplates, [])
    )
    const actionToBeUpdated = getActionTypeOtherToBeUpdated({
      formData,
      incident,
    })

    try {
      await updateIncidentActions({
        incident,
        actionsToBeDeleted,
        actionsToBeCreated,
        actionsToBeUpdated: actionToBeUpdated ? [actionToBeUpdated] : [],
        person,
      })

      const updatedIncident = await getIncident({ incident, person })

      await closeModal(updatedIncident)
    } catch (e) {
      setError(e)
    }
  }

  return (
    <Modal
      open={true}
      onClose={() => closeModal()}
      id={'edit-followup-actions'}
      contentClassName={tw`min-w-[600px] max-w-[600px]`}
    >
      <ModalTitle>Edit Follow Up Actions</ModalTitle>
      <form className="flex flex-col" onSubmit={methods.handleSubmit(onSubmit)}>
        <fieldset
          disabled={formState.isSubmitting || actionTemplates.tag === 'Loading'}
        >
          <EmbossedCard>
            {filteredFollowupActions.map((action, index) => (
              <RHFSwitch
                holderClassName={index !== 0 ? 'mt-[24px]' : undefined}
                register={register}
                switchSize={'small'}
                value={action.value}
                id={`fua-${index}`}
                name={'followUpActions'}
                key={`fua-${index}`}
                label={action.name}
              />
            ))}
            {getOrElse(actionTemplates, []).map((action, index) => {
              const customActionIdentifier =
                customIncidentActionIdentifier(action)

              if (!customActionIdentifier) {
                return null
              }

              return (
                <RHFSwitch
                  holderClassName={'mt-[24px]'}
                  register={register}
                  switchSize={'small'}
                  value={action.sideEffect?.fillablePdf?.customType}
                  id={`fua-${index}`}
                  name={`customFollowUpActions.${customActionIdentifier}`}
                  key={`fua-${index}`}
                  label={action.sideEffect?.fillablePdf?.customType}
                />
              )
            })}
            <div className={'mt-[20px] flex items-center'}>
              <RHFSwitch
                register={register}
                switchSize={'small'}
                name={'followUpActions'}
                value={IncidentActionType.INCIDENT_ACTION_TYPE_OTHER}
                id={`fua-other`}
              />
              <BasicInput
                containerClassName={tw`w-full max-w-[236px]`}
                className={'ml-[12px]'}
                {...register('followUpOther')}
                placeholder={'Other, e.g. file police report...'}
                inputSize={'inline'}
              />
            </div>
          </EmbossedCard>
        </fieldset>
        <footer className="flex-start mt-[32px]">
          <AnimatedPopupFormFooter
            yesBtn={{
              label: 'Save',
              props: {
                disabled:
                  !formState.isValid ||
                  !formState.isDirty ||
                  formState.isSubmitting,
                isLoading: formState.isSubmitting,
                ['data-testid']: 'edit-fua',
              },
            }}
            noBtn={{
              action: () => void closeModal(),
              label: 'Cancel',
            }}
            formState={formState}
          />
        </footer>
      </form>
    </Modal>
  )
}
