import Icon from '@shared/components/Icon'
import { SummarySectionDetails } from '@shared/components/LinkCard/SummarySection'
import { RoutineAdministrationProgressType } from '@shared/types/careapp'
import { getIconNameAndClassByStatus } from '@shared/utils/routineAdministrationProgressType'
import { tw, twx } from '@shared/utils/tailwind'

export default function BulkUpdateBanner({
  label,
  progressType,
  routineAdministrationsCount,
  peopleCount,
}: {
  label: string
  progressType: RoutineAdministrationProgressType
  routineAdministrationsCount: number
  peopleCount: number
}) {
  const { bgColor, barColorClass, iconName, iconClass } =
    getIconNameAndClassByStatus(progressType)

  return (
    <SummarySectionDetails
      bgColor={bgColor}
      barColor={barColorClass}
      className={'flex items-center gap-layout-gap-xs py-4 pl-2 pr-7'}
    >
      <Icon
        name={iconName}
        className={twx('fa-fw leading-relaxed', iconClass)}
      />
      <span className={tw`font-semibold text-secondary-04`}>
        {routineAdministrationsCount} {label}
      </span>
      <div className={tw`text-sm font-medium text-secondary-07`}>
        <span>across {peopleCount} Residents</span>
      </div>
    </SummarySectionDetails>
  )
}
