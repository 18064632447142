import { Person } from '@shared/types/person'
import { RoutableDocument } from '../helpers'
import GettingToKnowYouViewer from '../Viewer/GettingToKnowYouViewer'
import DocumentCard from './DocumentCard'

export default function GettingToKnowYouCard({
  animationStyle,
  current,
  person,
  onUpdate,
  title,
}: {
  animationStyle: React.CSSProperties
  current: RoutableDocument
  person: Person
  onUpdate: () => Promise<void>
  title: string
}) {
  return (
    <DocumentCard
      animationStyle={animationStyle}
      person={person}
      document={current}
      icon="fa-file-alt"
      title={title}
      completionText="Completed"
      viewer={(closeFn) => (
        <GettingToKnowYouViewer
          title={title}
          onComplete={async (updated) => {
            if (updated) {
              await onUpdate()
            }
            closeFn()
          }}
          gettingToKnowYou={current}
          person={person}
        />
      )}
    />
  )
}
