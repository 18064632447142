import classNames from 'classnames/bind'
import ButtonLink from '@shared/components/ButtonLink'
import { LabelAboveInput } from '@shared/components/Labels'
import styles from '../styles.module.css'
import { InputWithIcon } from '../types'

type ToggleProps = {
  allTagsSelected: boolean
  onToggle: () => void
}

export type InputGroupProps = {
  group: InputWithIcon[]
  name: string
  label: string
  inputType: 'radio' | 'checkbox'
  register: any
  globalToggleProps?: ToggleProps
}

function ToggleButton(props: ToggleProps) {
  const { onToggle, allTagsSelected } = props
  const term = allTagsSelected ? 'None' : 'All'

  return (
    <ButtonLink
      className="ml-[8px] text-[13px] font-semibold uppercase"
      data-testid="label"
      onClick={(e) => {
        e.preventDefault()
        onToggle()
      }}
    >
      Select {term}
    </ButtonLink>
  )
}

export default function InputGroup({
  group,
  inputType,
  label,
  name,
  register,
  globalToggleProps,
}: InputGroupProps) {
  return (
    <section className="mt-[32px]">
      <LabelAboveInput htmlFor={name}>
        {label}
        {!!globalToggleProps && <ToggleButton {...globalToggleProps} />}
      </LabelAboveInput>
      {group?.map((type, index: number) => {
        const iconClassName = classNames.call(
          null,
          `mr-[4px] fa-${type!.icon}`,
          {
            'fa-solid': !type.regularIconType,
            'fa-regular': type.regularIconType,
          }
        )
        return (
          <span key={`${name}-span-${index}`}>
            <input
              className={styles[inputType]}
              type={inputType}
              id={`${name}-tag-${index}`}
              data-testid={`${name}-tag-${index}`}
              value={type!.value}
              {...register(name)}
            />
            <label className={styles.tag} htmlFor={`${name}-tag-${index}`}>
              <i className={iconClassName} />
              <span className="text-[14px] font-medium leading-[16px]">
                {type!.name}
              </span>
            </label>
          </span>
        )
      })}
    </section>
  )
}
