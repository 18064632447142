export default function FormSectionTitle({
  title,
  className,
}: React.HTMLProps<HTMLHeadingElement> & {
  title: string
  className?: string
}) {
  return (
    <h2
      className={`text-[16px] font-semibold uppercase leading-[24px] text-secondary-07 ${
        className ?? ''
      }`}
    >
      <i className="fas fa-square mr-[8px] text-august-primary" /> {title}
    </h2>
  )
}
