import { tw } from '@shared/utils/tailwind'

export default function Footer() {
  return (
    <div
      className={tw`mt-[40px] flex flex-col items-center text-[12px] font-medium leading-[16px] text-secondary-07`}
    >
      <div>
        <a
          className={tw`text-rebrand-primary-light hover:underline`}
          href="mailto:support@augusthealth.com"
        >
          Contact support
        </a>{' '}
        if you need help to log in.
      </div>
      <div className={tw`mt-[4px]`}>
        By logging in, you accept our{' '}
        <a
          className={tw`text-rebrand-primary-light hover:underline`}
          href="/terms.html"
        >
          Terms
        </a>{' '}
        and{' '}
        <a
          className={tw`text-rebrand-primary-light hover:underline`}
          href="/privacy.html"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  )
}
