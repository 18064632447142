import { DashboardType } from '@shared/types/superset'
import { DashboardRecord } from '@app/api/organizations'
import RemoteDashboard from '@app/pages/Facilities/Insights/RemoteDashboard'

type Props = {
  orgId: string
  dashboard: DashboardRecord
  dashboardType: DashboardType
}

export default function Dashboard(props: Props) {
  const { orgId, dashboard, dashboardType } = props

  return (
    <RemoteDashboard
      orgId={orgId}
      dashboardRecord={dashboard}
      dashboardType={dashboardType}
    />
  )
}
