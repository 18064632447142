import {
  MedOrderFormData,
  MedOrderFormDose,
} from '@shared/types/medication_order'
import { UnitOfTime } from '@shared/types/timing'
import pluralize from '@shared/utils/pluralize'

export const validateOptionalPositiveNumber = (
  value?: number
): string | boolean => {
  if (value !== undefined && value <= 0) {
    return 'Must be greater than 0'
  }

  return true
}
export const validatePositiveNumber = (value?: number): string | boolean => {
  if (value !== undefined) {
    if (Number.isNaN(value)) {
      return 'Required'
    } else if (value <= 0) {
      return 'Must be greater than 0'
    }

    return true
  }

  return 'Required'
}

export const validateEveryXPeriod = ({
  value,
  periodUnit,
}: {
  value: number | undefined
  periodUnit: UnitOfTime | undefined
}): string | boolean => {
  // number is present and positive before narrowing down
  if (value && validatePositiveNumber(value) === true) {
    if (!periodUnit) {
      return true
    }

    if (periodUnit === UnitOfTime.UNIT_OF_TIME_DAY) {
      return true
    }

    if (periodUnit === UnitOfTime.UNIT_OF_TIME_HOUR) {
      if (value > 24) {
        return 'Must be less than 24'
      }

      return 24 % value === 0 ? true : 'Must be a factor of 24'
    }
  }

  return 'Required'
}

export const validatePainScale = (value?: number): string | boolean => {
  if (value !== undefined) {
    if (Number.isNaN(value)) {
      return 'Required'
    } else if (value < 1 || value > 10) {
      return 'Must be between 1 and 10'
    }

    return true
  }

  return 'Required'
}

function atLeastOnePositiveQuantity(doses: MedOrderFormDose[]) {
  return doses.some((d) =>
    d?.slidingScale?.entries?.some(
      (entry) => entry.doseQuantity?.value && entry.doseQuantity.value > 0
    )
  )
}

export const validateAtLeastOneSlidingQuantity = ({
  formData,
  unit,
}: {
  formData: MedOrderFormData
  unit: string
}): string | boolean => {
  const { doses } = formData
  if (!atLeastOnePositiveQuantity(doses)) {
    return `At least one "${pluralize(unit, 1, false).toUpperCase()}" input is required`
  }

  return true
}
export const validateRequired = (value: unknown) => {
  if (value === undefined) {
    return 'Required'
  }

  return true
}
export const validateRequiredNumberSelection = (
  value: unknown[],
  frequencyCount: number
) => {
  if (value.length === 0 || isNaN(frequencyCount) || frequencyCount === 0) {
    return 'Required'
  } else if (value.length !== frequencyCount) {
    return `Must select ${pluralize('day', frequencyCount, true)}`
  }
  return true
}
