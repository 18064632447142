import { PersonTransferRequest } from '@augusthealth/models/com/august/protos/jobs'
import { transferPerson } from '@shared/api/person'

export async function transferResident({
  oldOrgId,
  oldFacilityId,
  newOrgId,
  newFacilityId,
  personId,
}: {
  oldOrgId: string
  oldFacilityId: string
  personId: string
  newOrgId: string
  newFacilityId: string
}) {
  const body: Required<PersonTransferRequest> = {
    newFacility: {
      orgId: newOrgId,
      facilityId: newFacilityId,
    },
    person: {
      personId,
      orgId: oldOrgId,
      facilityId: oldFacilityId,
    },
  }

  await transferPerson({ request: body })
}
