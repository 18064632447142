import {
  ImportResult,
  ResultsOrErrors,
} from '@augusthealth/models/com/august/protos/api/import_results'
import {
  ImportKind,
  SuccessOrErrors,
} from '@augusthealth/models/com/august/protos/data_import'
import classNames from 'classnames/bind'
import { groupBy, map } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import CollapsibleCard from '@shared/components/CollapsibleCard'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Loading } from '@shared/utils/loading'
import { fetchImportList } from '@app/api/imports'
import styles from './styles.module.css'
import 'react-loading-skeleton/dist/skeleton.css'
import { ModalTitle } from '@shared/components/baseMui/Modal/Layout'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { twx } from '@shared/utils/tailwind'
import ResultsTable from './PreviewTable'

const cx = classNames.bind(styles)

type Props = {
  importKind: ImportKind
  importResponse?: Loading<ResultsOrErrors[]>
}
export default function ImportList({ importResponse, importKind }: Props) {
  const { setError } = useContext(GlobalContext)

  const [showResults, setShowResults] = useState<SuccessOrErrors[]>()
  const [allImports, setAllImports] = useState<Loading<ImportResult[]>>({
    tag: 'Loading',
  })

  useEffect(() => {
    fetchImportList(importKind)
      .then((res) => {
        setAllImports({ tag: 'Complete', value: res.reverse() })
      })
      .catch(setError)
  }, [importKind, importResponse])

  if (allImports.tag === 'Loading') {
    return <Skeleton />
  }

  const importsGroupedByOrg = groupBy(allImports.value, 'importStatus.orgId')

  return (
    <div>
      <h2 className="mb-[16px] ml-[8px] text-[20px] leading-[24px]">
        Previous Imports
      </h2>
      {map(importsGroupedByOrg, (importGroup, index) => (
        <OrgGroup
          key={importGroup[0].organizationName + index}
          importGroup={importGroup}
          setShowResults={setShowResults}
        />
      ))}
      <Modal
        open={!!showResults}
        id={'import-results'}
        onClose={() => setShowResults(undefined)}
        contentClassName={twx('text-secondary-02 h-full w-[1040px]')}
      >
        <ModalTitle>Import Results</ModalTitle>
        {showResults && <ResultsTable results={showResults} />}
        <div className="w-fulli mt-8 flex">
          <AsyncIconButton
            buttonStyle="primary-fill"
            width="136px"
            type="button"
            onClick={() => {
              setShowResults(undefined)
            }}
          >
            Done
          </AsyncIconButton>
        </div>
      </Modal>
    </div>
  )
}

function OrgGroup({
  importGroup,
  setShowResults,
}: {
  importGroup: ImportResult[]
  setShowResults: React.Dispatch<React.SetStateAction<SuccessOrErrors[]>>
}) {
  return (
    <CollapsibleCard cardTitle={importGroup[0].organizationName}>
      {importGroup.map((im, index) => (
        <ImportItem
          key={im.facilityName! + index}
          importResult={im}
          setShowResults={setShowResults}
        />
      ))}
    </CollapsibleCard>
  )
}

function ImportItem({
  importResult,
  setShowResults,
}: {
  importResult: ImportResult
  setShowResults: React.Dispatch<React.SetStateAction<SuccessOrErrors[]>>
}) {
  let iconClass =
    'fa-solid fa-spinner fa-spin fa-solid ml-[8px] text-august-primary'
  if (importResult.importStatus!.finishedAt) {
    iconClass = cx({
      'fa-solid ml-[8px]': true,
      'fa-circle-check text-tags-happy':
        importResult.importStatus!.results?.filter((r) => r.error).length === 0,
      'fa-triangle-exclamation text-tags-alert':
        importResult.importStatus!.results?.filter((r) => r.error).length,
      'fa-times-circle text-urgent': importResult.importStatus!.globalError,
    })
  }
  return (
    <div
      className={styles.importDetails}
      onClick={() => {
        if (importResult.importStatus!.globalError) {
          setShowResults([{ error: importResult.importStatus!.globalError }])
        } else {
          setShowResults(importResult.importStatus!.results!)
        }
      }}
    >
      <div className={styles.detailsRow}>
        <span className={styles.facilityName}>{importResult.facilityName}</span>
        <i className={iconClass} />
      </div>
      <div className="text-secondary-07">
        <span className="font-semibold">{importResult.importedBy}</span>
        <span> created on </span>
        {new Date(importResult.importStatus!.createdAt!).toLocaleString()}
      </div>
      <div className="text-secondary-07">
        Started{' '}
        {new Date(importResult.importStatus!.startedAt!).toLocaleTimeString()}
        {importResult.importStatus!.finishedAt && (
          <span>
            <span> and finished </span>
            {new Date(
              importResult.importStatus!.finishedAt!
            ).toLocaleTimeString()}
          </span>
        )}
      </div>
    </div>
  )
}
