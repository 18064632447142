import { OrgFacilitySettings } from '@augusthealth/models/com/august/protos/org_facility_settings'
import {
  SignableFormAssignment,
  SignableFormMetaData,
} from '@augusthealth/models/com/august/protos/signable_form'
import { useState } from 'react'
import Card from '@shared/components/Card'
import { ToolPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { useUserContext } from '@shared/contexts/UserContext'
import { Facility } from '@shared/types/facility'
import { CardLevel } from '@shared/types/org_facility_settings'
import { Organization } from '@shared/types/organization'
import { GroupPermission } from '@shared/types/permission'
import { SettingsLevel } from '@shared/utils/orgFacilitySettings'
import { tw } from '@shared/utils/tailwind'
import { getSettingsLevel } from '@app/pages/Tools/TaskDefinitions/helpers'
import ScheduleTaskModal from '@app/pages/Tools/TaskDefinitions/ScheduleTaskModal'
import TaskDefinitionModal from '@app/pages/Tools/TaskDefinitions/TaskDefinitionModal'
import styles from './styles.module.css'
import { DRAFT_STATUS_DISPLAY_DATA_MAP } from '../../helpers'
import { getAssignmentMetadata } from '../OrgFacilityTasks/helpers'
import Actions from './Actions'
import AssignmentModal from './AssignmentModal'
import EventBadges from './EventBadges'
import { canAssign, SuppressMode } from './helpers'
import SuppressModal from './SuppressionModal'
import TaskExplanationModal from './TaskExplanationModal'
import TaskTitle from './TaskTitle'
import UnassignConfirmPopup from './UnassignConfirmPopup'
import useCurrentAssignment from './useCurrentAssignment'

type Props = {
  cardLevel: CardLevel
  allAssignments: SignableFormAssignment[]
  formList: SignableFormMetaData[]
  loadAssignments: () => Promise<any>
  name: string
  onTaskDefinitionChange: () => Promise<void>
  onScheduledTaskChange?: () => Promise<void>
  scheduledTask?: OrgFacilitySettings
  taskSettings: OrgFacilitySettings
  // When displaying the TaskCard at the Facility level, we expect facility to be passed
  facility?: Facility
  org?: Organization
}

/**
 * Important note: 'cardLevel' is the level that the TaskCard is rendered at.
 * i.e. when rendering global task definitions, cardLevel is global
 * when rendering state task definitions, cardLevel is state, etc.
 */
export default function TaskCard({
  cardLevel,
  allAssignments,
  formList,
  loadAssignments,
  name,
  onTaskDefinitionChange,
  onScheduledTaskChange,
  scheduledTask,
  taskSettings,
  facility,
  org,
}: Props) {
  const { user } = useUserContext()
  const task = taskSettings.settings!.taskDefinition!
  const [assignmentOption, setAssignmentOption] = useState<OptionTypeBase>()
  const [showUnassignPopup, setShowUnassignPopup] = useState(false)
  const [showEditTask, setShowEditTask] = useState(false)
  const [showSchedulePopup, setShowSchedulePopup] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [showTaskExplanation, setShowTaskExplanation] = useState(false)
  const [showSuppressModal, setShowSuppressModal] = useState(false)
  const { isFile, dataType, customType } = task.template!
  const options = formList
    .filter((f) => f.hellosign?.subtemplateOf === undefined)
    .map(({ id, name, draftStatus }) => {
      const hasDraftStatus = Boolean(draftStatus)
      const statusData =
        draftStatus && DRAFT_STATUS_DISPLAY_DATA_MAP[draftStatus]
      return {
        label: `${id} ${name}`,
        subLabel: statusData?.label ? (
          <i
            className={tw`fa fa-solid fa-exclamation-circle`}
            title={statusData.label.toLowerCase()}
          />
        ) : undefined,
        value: id!,
        isDisabled: hasDraftStatus,
      }
    })

  /**
   * Figures out if there is an assignment for the given task setting at the current
   * card level.
   */
  const selectedData = useCurrentAssignment({
    allAssignments,
    cardLevel,
    options,
    taskDefinition: task,
  })

  const { assignment, dropdownOption: selectedOption } = selectedData || {}
  const onComplete = async () => {
    setLoading(true)
    void loadAssignments().finally(() => setLoading(false))
  }

  const onChangeAssignment = async (option: OptionTypeBase | null) => {
    if (option) {
      setAssignmentOption(option)
    } else if (assignment) {
      setShowUnassignPopup(true)
    } else {
      console.warn("Trying to remove an assignment that doesn't exist!")
    }
  }

  // Can this take the whole task definition as an argument, rather than pieces?
  const { placeholder, assignmentLevel } = getAssignmentMetadata({
    allAssignments,
    assignment,
    customType,
    dataType: dataType!,
    selectedOption,
    cardLevel,
    facility,
  })
  const taskLevel = getSettingsLevel(taskSettings)
  const enableSuppression =
    taskLevel !== SettingsLevel.FACILITY_LEVEL && cardLevel.tag === 'Facility'
  const suppressionMode: SuppressMode = enableSuppression
    ? {
        tag: 'enabled',
        onSuppress: async () => {
          setShowSuppressModal(true)
        },
      }
    : {
        tag: 'disabled',
      }
  const enableAssignDropdown = canAssign({ user, cardLevel })

  return (
    <>
      <Card className="mb-[8px] flex flex-col p-[16px]">
        <div className={styles.titleRow}>
          <TaskTitle
            task={task}
            taskLevel={taskLevel}
            assignmentLevel={assignmentLevel}
            setShowDetails={setShowDetails}
          />
          {isFile && (
            <ToolPermissionGate
              permissions={[
                GroupPermission.GROUP_PERMISSION_TOOL_FORM_ASSIGNMENT_READ,
              ]}
            >
              <StyledSelect
                options={options}
                onChange={onChangeAssignment}
                placeholder={placeholder}
                value={selectedOption ?? null}
                isClearable
                isDisabled={loading || !enableAssignDropdown}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    cursor: 'pointer',
                    fontSize: '14px',
                    lineHeight: '16px',
                    width: '264px',
                  }),
                }}
              />
            </ToolPermissionGate>
          )}
        </div>
        {showDetails && <EventBadges task={task} />}
        {showDetails && (
          <ToolPermissionGate
            permissions={[
              GroupPermission.GROUP_PERMISSION_TOOL_SETTINGS_CREATE,
              GroupPermission.GROUP_PERMISSION_TOOL_SETTINGS_READ,
              GroupPermission.GROUP_PERMISSION_TOOL_SETTINGS_DELETE,
            ]}
          >
            <Actions
              onClickEdit={() => setShowEditTask(true)}
              onClickSchedule={() => setShowSchedulePopup(true)}
              onClickTaskExplanation={() => setShowTaskExplanation(true)}
              afterRemove={onTaskDefinitionChange}
              taskDefinitionSettings={taskSettings}
              scheduleTaskSettings={scheduledTask}
              cardLevel={cardLevel}
              suppressMode={suppressionMode}
            />
          </ToolPermissionGate>
        )}
      </Card>
      {showTaskExplanation && (
        <TaskExplanationModal
          taskSettings={taskSettings}
          onClose={async (updated) => {
            if (updated) {
              await onTaskDefinitionChange()
            }

            setShowTaskExplanation(false)
          }}
        />
      )}
      {showSchedulePopup && onScheduledTaskChange && (
        <ScheduleTaskModal
          name={name}
          cardLevel={cardLevel}
          onClose={() => setShowSchedulePopup(false)}
          onScheduledTaskChange={onScheduledTaskChange}
          taskDefinitionSettings={taskSettings}
          scheduleTaskSettings={scheduledTask}
        />
      )}
      {showEditTask && (
        <TaskDefinitionModal
          name={name}
          cardLevel={cardLevel}
          onClose={async (updated) => {
            if (!updated) {
              return setShowEditTask(false)
            }

            await onTaskDefinitionChange()
            setShowEditTask(false)
          }}
          initialDefinition={taskSettings}
        />
      )}
      {showUnassignPopup && assignment && (
        <UnassignConfirmPopup
          formList={formList}
          assignment={assignment}
          onClose={async () => {
            await onComplete()
            setShowUnassignPopup(false)
          }}
        />
      )}
      {assignmentOption && (
        <AssignmentModal
          cardLevel={cardLevel}
          assignment={assignment ?? {}}
          dataType={dataType!}
          customType={customType}
          name={assignmentOption.label}
          onClose={async (updated) => {
            if (updated) {
              await onComplete()
            }

            setAssignmentOption(undefined)
          }}
          signableFormId={assignmentOption.value}
          taskSettings={taskSettings}
        />
      )}
      {showSuppressModal && (
        <SuppressModal
          cardLevel={cardLevel}
          taskSettings={taskSettings}
          onClose={async () => {
            setShowSuppressModal(false)
            await onTaskDefinitionChange()
          }}
          org={org}
          facility={facility!}
        />
      )}
    </>
  )
}
