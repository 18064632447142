import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { fetchPersonTasks } from '@shared/legacy_routes'
import { PersonTasks } from '@shared/types/api/person_tasks'
import { Loading } from '@shared/utils/loading'

export default function usePersonTasks(
  {
    orgId,
    facilityId,
  }: {
    orgId: string
    facilityId: string
  },
  deps: React.DependencyList = []
) {
  const [personTasks, setPersonTasks] = useState<Loading<PersonTasks[]>>({
    tag: 'Loading',
  })
  const { setError } = useContext(GlobalContext)

  const refreshTasks = () =>
    fetchPersonTasks({ orgId, facilityId })
      .then((pts) => setPersonTasks({ tag: 'Complete', value: pts }))
      .catch(setError)

  useEffect(() => {
    void refreshTasks()
  }, [orgId, facilityId, ...deps])

  return { personTasks, refreshTasks }
}
