import { BasicRadioButton } from '@shared/components/BasicInput/BasicInput'
import { OptionComponentProps } from './helpers'

export default function RadioInputOption(props: OptionComponentProps) {
  const { label, onClick, value, ...restProps } = props
  return (
    <BasicRadioButton
      {...restProps}
      onChange={() => onClick(value)}
      value={value as string}
    >
      {`${label || value}`}
    </BasicRadioButton>
  )
}
