import { EditableDetailsCard } from 'app/components/Prospects/Details/DetailsCard'
import { useState } from 'react'
import { BasicSpinner } from '@shared/components/BasicSpinner'
import { GroupPermission } from '@shared/types/permission'
import { Person } from '@shared/types/person'
import { tw } from '@shared/utils/tailwind'
import {
  getVitalLabelWithUnit,
  getVitalValueForType,
  VITALS_INPUT_PROPS_MAP,
  VitalsType,
} from '@shared/utils/vitals'
import ReadOnlyBadge from '@app/components/AugustFields/components/ReadOnlyBadge'
import styles from '@app/components/AugustFields/Diet/styles.module.css'
import useLatestVitals from '@app/hooks/useLatestVitals'
import EditVitalModal from './EditVitalModal'

export default function Vital({
  person,
  vitalsType,
  mode = 'view',
}: {
  person: Person
  vitalsType: VitalsType
  mode?: 'edit' | 'view'
}) {
  const [showModal, setShowModal] = useState(false)
  const { latestVitals, refreshLatestVitals } = useLatestVitals(person)
  if (latestVitals.tag === 'Loading') {
    return <BasicSpinner />
  }

  const { label, unitsLabel, iconClassName } =
    VITALS_INPUT_PROPS_MAP(vitalsType)
  const vital = latestVitals.value.compositePanel?.[vitalsType]
  const text = getVitalLabelWithUnit({
    label:
      getVitalValueForType({
        vital: latestVitals.value,
        vitalType: vitalsType,
      }) ?? '',
    unit: unitsLabel,
  })

  if (mode === 'view') {
    return <ReadOnlyBadge badgeLabel={label} textContent={text} />
  }

  return (
    <EditableDetailsCard
      id={`${label}-card`}
      icon={iconClassName}
      title={label}
      editPermissions={{
        person,
        permissions: [GroupPermission.GROUP_PERMISSION_VITAL_CREATE],
      }}
      onEditClick={() => setShowModal(true)}
      editButtonId={`edit-${label}`}
      className={tw`my-[12px]`}
    >
      <div className={styles.row}>
        <div className={styles.title}>{label}</div>
        <div>
          {text}
          {!vital && <span className="text-gray-08">None to show</span>}
        </div>
      </div>
      {showModal && (
        <EditVitalModal
          person={person}
          onClose={async (updated) => {
            if (updated) {
              await refreshLatestVitals()
            }
            setShowModal(false)
          }}
          vitalsType={vitalsType}
        />
      )}
    </EditableDetailsCard>
  )
}
