import { cloneDeep } from 'lodash'
import React from 'react'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import {
  BasicCheckbox,
  BasicInput,
} from '@shared/components/BasicInput/BasicInput'
import CollapsibleCard from '@shared/components/CollapsibleCard'
import { LabelAboveInput } from '@shared/components/Labels'
import {
  AssessmentCategory,
  AssessmentCategoryOptions,
  AssessmentConfig,
} from '@shared/types/assessment_configuration'
import ConfirmModal from '@app/components/ConfirmModal'
import styles from './styles.module.css'

type Props = {
  selectedCategory: AssessmentCategory
  updatedCategoryOptions: AssessmentCategoryOptions
  setUpdatedCategoryOptions: (options: AssessmentCategoryOptions) => void
  setAssessmentConfig: (config: AssessmentConfig) => void
  currentAssessmentConfig: AssessmentConfig
  categoryIsMarkedForDelete: boolean
  reloadCategoryDropdownOptions: (updatedConfig: AssessmentConfig) => void
}
export const CategoryOptions = ({
  selectedCategory,
  updatedCategoryOptions,
  setUpdatedCategoryOptions,
  setAssessmentConfig,
  currentAssessmentConfig,
  categoryIsMarkedForDelete,
  reloadCategoryDropdownOptions,
}: Props) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false)
  const rowClasses = 'flex flex-row items-center'
  const inputClasses = 'ml-[12px] mb-[12px] w-full'

  const deleteCategory = () => {
    setAssessmentConfig({
      ...currentAssessmentConfig,
      [selectedCategory!.categoryKey!]: {
        ...currentAssessmentConfig[selectedCategory!.categoryKey!],
        deleteOnSave: true,
      },
    })
    setShowDeleteConfirm(false)
  }

  const updateCategoryTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newOptions = {
      ...updatedCategoryOptions,
      title: e.target.value,
    }

    setUpdatedCategoryOptions(newOptions)
    const updatedConfig = {
      ...cloneDeep(currentAssessmentConfig),
      [selectedCategory!.categoryKey!]: {
        ...currentAssessmentConfig[selectedCategory!.categoryKey!],
        categoryOptions: cloneDeep(newOptions),
      },
    }
    setAssessmentConfig(updatedConfig)
    reloadCategoryDropdownOptions(updatedConfig)
  }

  return (
    <CollapsibleCard
      cardTitle={'CATEGORY OPTIONS'}
      className={styles.card}
      data-testid={'category-options-card'}
    >
      <div className={rowClasses}>
        <LabelAboveInput htmlFor={'category-title'} className={'w-[64px]'}>
          Title
        </LabelAboveInput>
        <BasicInput
          id={'category-title'}
          name={'category-title'}
          containerClassName={inputClasses}
          value={updatedCategoryOptions.title ?? ''}
          disabled={categoryIsMarkedForDelete}
          onChange={updateCategoryTitle}
        />
      </div>
      {selectedCategory.customKey && (
        <div className={rowClasses}>
          <LabelAboveInput
            htmlFor={'customKey'}
            className={'whitespace-nowrap'}
          >
            Custom Key
          </LabelAboveInput>
          <BasicInput
            id={'customKey'}
            name={'customKey'}
            containerClassName={inputClasses}
            value={selectedCategory.customKey}
            disabled={true}
          />
        </div>
      )}
      <div className={rowClasses}>
        <LabelAboveInput htmlFor={'page'} className={'w-[64px]'}>
          Page
        </LabelAboveInput>
        <BasicInput
          id={'page'}
          name={'page'}
          disabled={true}
          containerClassName={inputClasses}
          value={updatedCategoryOptions.page ?? ''}
        />
      </div>
      <div className={rowClasses}>
        <LabelAboveInput htmlFor={'Order'} className={'w-[64px]'}>
          Order
        </LabelAboveInput>
        <BasicInput
          id={'Order'}
          name={'Order'}
          disabled={true}
          containerClassName={inputClasses}
          value={updatedCategoryOptions.displayOrder ?? ''}
        />
      </div>
      <BasicCheckbox
        id={'exclude-from-service-plan'}
        name={'exclude-from-service-plan'}
        disabled={true}
        checked={!selectedCategory.isExcludedFromServicePlan}
      >
        Include on Service Plan
      </BasicCheckbox>
      <div className={rowClasses}>
        <AsyncIconButton
          className={'ml-auto'}
          buttonStyle={'danger-fill'}
          buttonSize={'small'}
          initialIcon={'fa-solid fa-ban'}
          onClick={() => setShowDeleteConfirm(true)}
          disabled={categoryIsMarkedForDelete}
        >
          {categoryIsMarkedForDelete ? 'Delete Pending' : 'Delete Category'}
        </AsyncIconButton>
      </div>

      {showDeleteConfirm && (
        <ConfirmModal
          testId={'confirm-delete-category'}
          title={`Delete this category?`}
          content={'The category will be deleted when you save the assessment.'}
          confirmButtonConfig={{
            buttonStyle: 'danger-fill',
            children: 'Delete Category',
            onClick: deleteCategory,
          }}
          denyButtonConfig={{
            onClick: () => setShowDeleteConfirm(false),
          }}
        />
      )}
    </CollapsibleCard>
  )
}
