import styles from './oneTimeChargeRow.module.css'
import { OneTimeCharge } from './OneTimeCharge'

interface Props {
  charge: OneTimeCharge
  onDelete: (charge: OneTimeCharge) => void
}

export default function OneTimeChargeRow({ charge, onDelete }: Props) {
  const amountIsNegative = charge.amount[0] === '-'
  const amountClass = amountIsNegative ? styles.negativeAmount : styles.amount

  return (
    <tr className={styles.activeRow}>
      <td>{charge.date}</td>
      <td>
        <div>
          <div className={styles.title}>{charge.title}</div>
          {styles.subtitle && (
            <div className={styles.subtitle}>{charge.subtitle}</div>
          )}
        </div>
      </td>
      <td>{charge.payer}</td>
      <td>
        <span className={amountClass}>{charge.amount}</span>
      </td>
      <td>
        <button
          className={styles.deleteBtn}
          onClick={() => {
            onDelete(charge)
          }}
        >
          <i className="far fa-fw fa-trash-can" />
        </button>
      </td>
    </tr>
  )
}
