import Card from '@shared/components/Card'
import { tw } from '@shared/utils/tailwind'
import RouterTabs from '@app/components/RouterTabs'
import { TOOLS_INTERNAL_USERS_PATH, TOOLS_MY_FEATURE_FLAGS_PATH } from '../path'

export const MY_FEATURE_FLAGS_LABEL = 'My Feature Flags'
export const INTERNAL_USERS_LABEL = 'Manage Users'

export default function SubPageTabs({ activeTab }: { activeTab: string }) {
  return (
    <Card className={tw`mt-[16px] p-0`}>
      <RouterTabs
        activeTab={activeTab}
        className={tw`rounded-t-[8px]`}
        tabList={[
          {
            label: INTERNAL_USERS_LABEL,
            url: TOOLS_INTERNAL_USERS_PATH,
          },
          {
            label: MY_FEATURE_FLAGS_LABEL,
            url: TOOLS_MY_FEATURE_FLAGS_PATH,
          },
        ]}
      />
    </Card>
  )
}
