import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import {
  MedicationOrder,
  RxMedicationOrder,
  VitalMedicationOrder,
} from '@shared/types/medication_order'
import { Loading } from '@shared/utils/loading'
import { isRxOrder, isVitalOrder } from '@shared/utils/medicationStatement'
import {
  getMedicationOrders,
  getSingleMedicationOrder,
} from '../api/medicationOrders'
import PersonContext from '../contexts/PersonContext'

export default function useMedications(): {
  rxOrders: Loading<RxMedicationOrder[]>
  vitalOrders: Loading<VitalMedicationOrder[]>
  reloadMedications: () => Promise<void>
} {
  const { setError } = useContext(GlobalContext)
  const { person } = useContext(PersonContext)

  const [rxOrders, setRxOrders] = useState<Loading<RxMedicationOrder[]>>({
    tag: 'Loading',
  })
  const [vitalOrders, setVitalOrders] = useState<
    Loading<VitalMedicationOrder[]>
  >({
    tag: 'Loading',
  })

  const reloadMedications = async () => {
    if (person) {
      try {
        const medications = await getMedicationOrders(person)

        const rxMeds = medications.filter((medication) => isRxOrder(medication))
        const vitals = medications.filter((medication) =>
          isVitalOrder(medication)
        )

        setRxOrders({ tag: 'Complete', value: rxMeds })
        setVitalOrders({ tag: 'Complete', value: vitals })
      } catch (e) {
        setError(e)
      }
    }
  }

  useEffect(() => {
    setRxOrders({ tag: 'Loading' })
    setVitalOrders({ tag: 'Loading' })
    void reloadMedications()
  }, [person?.id])

  return {
    rxOrders,
    vitalOrders,
    reloadMedications,
  }
}

export function useMedicationOrder(medicationId: string): {
  medicationOrder: Loading<MedicationOrder | null>
  reloadMedicationOrder: () => Promise<void>
} {
  const { setError } = useContext(GlobalContext)
  const { person } = useContext(PersonContext)

  const [medicationOrder, setMedicationOrder] = useState<
    Loading<MedicationOrder | null>
  >({
    tag: 'Loading',
  })

  const reloadMedicationOrder = async () => {
    if (person) {
      try {
        const medicationOrder = await getSingleMedicationOrder(
          person,
          medicationId
        )
        setMedicationOrder({
          tag: 'Complete',
          value: medicationOrder as MedicationOrder,
        })
      } catch (e) {
        setError(e)
        setMedicationOrder({
          tag: 'Complete',
          value: null,
        })
      }
    }
  }

  useEffect(() => {
    setMedicationOrder({ tag: 'Loading' })
    void reloadMedicationOrder()
  }, [`${person?.id} ${medicationId}`])

  return { medicationOrder: medicationOrder, reloadMedicationOrder }
}
