import { SignableFormAssignment } from '@augusthealth/models/com/august/protos/signable_form'
import React, { useContext, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import LoadingPopup from '@shared/components/LoadingPopup'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Loading } from '@shared/utils/loading'
import { getAssignmentsByOrg } from '@app/api/form'
import PersonPageTitle from '@app/components/PersonPageTitle'
import useTaskFacilitySettings from '@app/hooks/useTaskFacilitySettings'
import { useSignableFormContext } from '@app/pages/Tools/PdfTemplate/SignableFormContext'
import FacilityTasks from './FacilityTasks'
import OrgStateTasks from './OrgStateTasks'
import OrgTasks from './OrgTasks'

type MatchParams = { orgId?: string }
type Props = RouteComponentProps<MatchParams>

export default function OrgFacilityTasks(props: Props) {
  const { signableForms } = useSignableFormContext('All Templates')
  const { setError } = useContext(GlobalContext)
  const { match } = props
  const { orgId: currentOrgId } = match.params
  const [assignmentsForOrg, setAssignmentsForOrg] = useState<
    Loading<SignableFormAssignment[]>
  >({ tag: 'Loading' })
  const {
    organization,
    facilities,
    orgTaskDefinitions,
    facilityTaskDefinitions,
    orgStateTaskDefinitions,
    facilityScheduledTasks,
    orgScheduledTasks,
    orgStateScheduledTasks,
    reload,
  } = useTaskFacilitySettings({
    orgId: currentOrgId!,
  })

  /**
   * This loads all assignments for the current org. See `getAssignmentsByOrg` for
   * details on the assignments returned.
   */
  const loadAssignments = () => {
    return getAssignmentsByOrg(currentOrgId!)
      .then((res) =>
        setAssignmentsForOrg({
          tag: 'Complete',
          value: res,
        })
      )
      .catch(setError)
  }

  const onTaskDefinitionChange = async () => {
    await Promise.all([loadAssignments(), reload()])
  }

  useEffect(() => {
    void loadAssignments()
  }, [])

  if (
    organization === undefined ||
    signableForms.tag === 'Loading' ||
    assignmentsForOrg.tag === 'Loading' ||
    facilityTaskDefinitions.tag === 'Loading' ||
    orgTaskDefinitions.tag === 'Loading' ||
    orgStateTaskDefinitions.tag === 'Loading' ||
    facilityScheduledTasks.tag === 'Loading' ||
    orgScheduledTasks.tag === 'Loading' ||
    orgStateScheduledTasks.tag === 'Loading'
  ) {
    return <LoadingPopup loading />
  }

  const formList = signableForms.value
  const orgStateTaskDefinitionsValue = orgStateTaskDefinitions.value
  const hasStateTasks = Object.values(orgStateTaskDefinitionsValue).some(
    (list) => list.length
  )

  return (
    <>
      <div className="mt-[24px]">
        <OrgTasks
          key={`org-${organization.id}`}
          formList={formList}
          allAssignments={assignmentsForOrg.value}
          org={organization}
          loadAssignments={loadAssignments}
          onTaskDefinitionChange={onTaskDefinitionChange}
          orgTaskDefinitions={orgTaskDefinitions.value}
          scheduledTaskSettings={orgScheduledTasks.value}
          reloadScheduledTaskSettings={reload}
        />
      </div>
      {hasStateTasks && (
        <div className="mt-[32px]">
          <PersonPageTitle title="Org State Tasks" subtitle />
          {Object.keys(orgStateTaskDefinitionsValue).map((stateName) => {
            const taskDefinitionsForOrgState =
              orgStateTaskDefinitionsValue[stateName]
            return taskDefinitionsForOrgState.length ? (
              <React.Fragment key={`${stateName}-td`}>
                <div className="group-title mb-[16px] mt-[48px]">
                  {stateName}
                </div>
                <OrgStateTasks
                  data-testid="org-state-tasks"
                  stateName={stateName}
                  taskSettings={taskDefinitionsForOrgState}
                  formList={formList}
                  allAssignments={assignmentsForOrg.value}
                  org={organization}
                  loadAssignments={loadAssignments}
                  onTaskDefinitionChange={onTaskDefinitionChange}
                  scheduledTaskSettings={
                    orgStateScheduledTasks.value[stateName] || []
                  }
                  reloadScheduledTaskSettings={reload}
                />
              </React.Fragment>
            ) : null
          })}
        </div>
      )}
      <div className="mt-[32px]">
        <PersonPageTitle title="Facility Tasks" subtitle />
        {facilities.map((f) => {
          const { id: fId } = f
          return (
            <FacilityTasks
              taskDefinitions={facilityTaskDefinitions.value[fId]}
              formList={formList}
              key={`facility-${fId}`}
              allAssignments={assignmentsForOrg.value}
              facility={f}
              org={organization}
              loadAssignments={loadAssignments}
              onTaskDefinitionChange={onTaskDefinitionChange}
              scheduledTaskSettings={facilityScheduledTasks.value[fId]}
            />
          )
        })}
      </div>
    </>
  )
}
