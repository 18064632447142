import { AugustInitialAppraisal } from '@augusthealth/models/com/august/protos/august_initial_appraisal'
import { useContext, useState } from 'react'
import { typedMergePatchPerson } from '@shared/api/person'
import { RequiredLabel } from '@shared/components/Labels'
import StyledSelect from '@shared/components/Selects/StyledSelect'
import { AdmissionsInformation_AdmissionType } from '@shared/types/admissions'
import { Person } from '@shared/types/person'
import { Snapshot } from '@shared/types/snapshot'
import { getResidentAssessment } from '@app/api/resident_assessment'
import { admissionTypeOptions } from '@app/components/Prospects/VerifyAdmissionDetails/helpers'
import { AdmissionTypeOption } from '@app/components/Prospects/VerifyAdmissionDetails/types'
import PersonContext from '@app/contexts/PersonContext'
import styles from './styles.module.css'

export default function AdmissionType({
  assessment,
  disabled: initialDisabledValue,
  onUpdate,
}: {
  assessment: AugustInitialAppraisal
  disabled: boolean
  onUpdate?: (snapshot: Snapshot) => void
}) {
  const { person, setPerson } = useContext(PersonContext)
  const [admissionType, setAdmissionType] = useState(assessment.admissionType)
  const [disabled, setDisabled] = useState(!person || initialDisabledValue)
  const value = admissionTypeOptions.find((o) => o.value === admissionType)

  const onChange = person
    ? async (newValue: AdmissionTypeOption) => {
        const newAdmissionType = newValue.value
        try {
          setAdmissionType(newAdmissionType)
          setDisabled(true)

          await updateAdmissionType({ person, admissionType: newAdmissionType })
          if (onUpdate) {
            const appraisalStatus = await getResidentAssessment({
              person,
              withOriginalSettings: false,
            })
            onUpdate({
              data: { augustInitialAppraisal: appraisalStatus.appraisal },
            })
          }

          setPerson({
            ...person,
            admissionsInformation: {
              ...person.admissionsInformation,
              admissionType: newAdmissionType,
            },
          })
        } catch (e) {
          setAdmissionType(assessment.admissionType)
        } finally {
          setDisabled(false)
        }
      }
    : undefined

  return (
    <div className={'mb-[40px]'}>
      <h2 className={[styles.h2, 'mb-[16px]'].join(' ')}>
        <i className={`fas fa-square ${styles.square}`} />
        Admission Type
        <RequiredLabel showError={admissionType === undefined} />
      </h2>
      <StyledSelect
        isDisabled={disabled}
        options={admissionTypeOptions}
        onChange={onChange}
        value={value}
      />
    </div>
  )
}

async function updateAdmissionType({
  person,
  admissionType,
}: {
  person: Person
  admissionType: AdmissionsInformation_AdmissionType
}) {
  // Update Person but don't need to update Initial Appraisal Snapshot's admission type
  // Initial Appraisal Snapshot's admission type will be updated automatically from Backend
  await typedMergePatchPerson({
    fId: person.facilityId!,
    pId: person.id!,
    orgId: person.orgId!,
    fields: ['admissionsInformation'],
    patch: {
      admissionsInformation: {
        admissionType,
      },
    },
  })
}
