import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import CollapsibleCard from '@shared/components/CollapsibleCard'
import {
  MedicationOrder,
  MedOrderFormData,
} from '@shared/types/medication_order'
import { MedicationStatement } from '@shared/types/medication_statement'
import {
  dosageTypeToDisplay,
  KnownDosageType,
} from '@shared/utils/medicationStatement'
import { tw } from '@shared/utils/tailwind'
import { vitalEnumToLabel } from '@shared/utils/vitals'
import { MedOrderCardTitle } from '@app/components/Residents/Medications/Orders/MedicationOrderDetails/MedOrderCardTitle'
import { MedicationOrderDoseSchedules } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/MedicationOrderDoseSchedules'
import { VitalOrderSchedule } from '@app/components/Residents/Medications/Orders/VitalOrderSchedule'
import {
  canAddOrRemoveDoseParts,
  doesFormHaveSchedulingErrors,
  getMedOrderDosageType,
  isFormVitalOrder,
  mapFormMedToMedOrder,
} from '../helpers'
import { ScheduleBadges } from './ScheduleBadges'

type Props = {
  order: MedicationOrder
  use24HourTime: boolean
  readOnly: boolean
  mode: 'review' | 'edit'
}
export default function ReviewOrderScheduleCard(props: Props) {
  const { order, use24HourTime, mode, readOnly } = props
  const [expanded, setExpanded] = useState(false)
  const methods = useFormContext<MedOrderFormData>()

  const { formState, watch } = methods

  const medOrderFormData = watch()
  const formHasErrors = doesFormHaveSchedulingErrors(formState)
  const wholeOrderDoseType = getMedOrderDosageType(
    mapFormMedToMedOrder({
      formData: medOrderFormData,
      medOrder: order,
    }).medicationStatement as MedicationStatement
  )

  const canAddOrRemoveDoses = canAddOrRemoveDoseParts(order, medOrderFormData)
  const isVital = isFormVitalOrder(medOrderFormData)

  return (
    <CollapsibleCard
      defaultExpanded={true}
      enableAutoScrollOnExpand={false}
      expanded={formHasErrors}
      expandTrigger={formState.isSubmitting}
      contentTestId={`${wholeOrderDoseType}-schedule-card`}
      collapsedIcon={readOnly ? 'fa-lock text-secondary-07' : undefined}
      cardTitle={
        <div className={tw`w-full`}>
          {isVital ? (
            <>
              <MedOrderCardTitle
                value={`Record ${vitalEnumToLabel(medOrderFormData.vitalType)}`}
              />
              {!expanded && (
                <ScheduleBadges
                  order={order}
                  doseType={wholeOrderDoseType}
                  use24HourTime={use24HourTime}
                />
              )}
            </>
          ) : (
            <>
              {expanded ? (
                <MedOrderCardTitle value={'Schedule and Dosage...'} />
              ) : (
                <>
                  <MedOrderCardTitle
                    value={`${dosageTypeToDisplay(wholeOrderDoseType as KnownDosageType)} Schedule...`}
                  />
                  <ScheduleBadges
                    order={order}
                    doseType={wholeOrderDoseType}
                    use24HourTime={use24HourTime}
                  />
                </>
              )}
            </>
          )}
        </div>
      }
      onExpandClick={setExpanded}
      role={'list'}
    >
      {isVital ? (
        <VitalOrderSchedule
          order={order}
          index={0}
          readOnly={readOnly}
          mode={mode}
        />
      ) : (
        <MedicationOrderDoseSchedules
          canAddOrRemoveDoses={canAddOrRemoveDoses}
          readOnly={readOnly}
          order={order}
          mode={mode}
        />
      )}
    </CollapsibleCard>
  )
}
