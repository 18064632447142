import { SignableForm } from '@augusthealth/models/com/august/protos/signable_form'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { requiredWhenError } from '@shared/components/Labels'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import StateAbbrHash from '@shared/constants/states'
import GlobalContext from '@shared/contexts/GlobalContext'
import { DataType } from '@shared/types/snapshot'
import { createFormAssignment } from '@app/api/form'
import RHFSelect from './RHFSelect'

type FormData = {
  dataType: DataType
  signableFormId: string
  state: string
}

const STATE_OPTIONS = Object.keys(StateAbbrHash).map((k) => ({
  label: k,
  value: StateAbbrHash[k],
}))

export default function AssignPopup({
  signableForms,
  onClose,
}: {
  signableForms: SignableForm[]
  onClose: () => void
}) {
  const { setError } = useContext(GlobalContext)
  const options: OptionTypeBase[] = signableForms.map((f) => ({
    label: f.name || '',
    subLabel: f.description || ('' as string),
    value: f.id!,
  }))
  const firstOptionValue = options.length ? options[0].value : undefined
  const useFormReturn = useForm<FormData>({
    defaultValues: {
      dataType: DataType.DATA_TYPE_INCIDENT_REPORT,
      signableFormId: firstOptionValue,
    },
  })
  const { formState, handleSubmit } = useFormReturn
  const { errors } = formState
  const save = async (data: FormData) => {
    try {
      await createFormAssignment(data)
    } catch (err) {
      setError(err)
    } finally {
      onClose()
    }
  }

  return (
    <AnimatedPopup title="Assign State Incident Template">
      <form onSubmit={handleSubmit(save)}>
        <RHFSelect
          labelProps={{
            htmlFor: 'signableFormId',
            children: 'Template',
            subLabel: requiredWhenError(Boolean(errors.signableFormId)),
          }}
          rhfProps={{
            rules: { required: true },
            useFormReturn,
          }}
          selectProps={{
            name: 'signableFormId',
            options: options,
          }}
        />
        <RHFSelect
          labelProps={{
            htmlFor: 'state',
            children: 'State',
            subLabel: requiredWhenError(Boolean(errors.state)),
          }}
          rhfProps={{
            rules: { required: true },
            useFormReturn,
          }}
          selectProps={{
            name: 'state',
            options: STATE_OPTIONS,
          }}
        />
        <div className="mt-[32px]">
          <AnimatedPopupFormFooter
            formState={formState}
            yesBtn={{}}
            noBtn={{
              action: onClose,
            }}
          />
        </div>
      </form>
    </AnimatedPopup>
  )
}
