import { useEffect, useState } from 'react'
import useCurrentPage, { extractIds } from '@shared/hooks/useCurrentPage'
import { PersonIds } from '@shared/types/person'
import { DataType } from '@shared/types/snapshot'
import { Task, TaskTemplateInfo } from '@shared/types/task'
import { Loading } from '@shared/utils/loading'
import notEmpty from '@shared/utils/notEmpty'
import { isIncomplete } from '@shared/utils/task'
import useConfiguredTasks, {
  useToolsLoadingConfiguredTasks,
} from '../pages/Documents/useConfiguredTasks'

type DefaultProps = {
  tasks: Task[]
  overrideIds?: {
    orgId?: string
    facilityId?: string
    personId?: string
  }
}

const STANDARD_UPLOADS: TaskTemplateInfo[] = [
  {
    displayName: 'Advanced Directives and Power of Attorney',
    dataType: DataType.DATA_TYPE_DIRECTIVE,
  },
  {
    displayName: 'Insurance Card',
    dataType: DataType.DATA_TYPE_INSURANCE_CARD,
  },
  {
    displayName: 'Medical Records',
    dataType: DataType.DATA_TYPE_MEDICAL_RECORD,
  },
]
/**
 * Get the relevant uploadable documents for a person.
 * The list of standardUploads will always be returned, along with any configured tasks at the relevant global/state/org-state/org/facility levels.
 * 603 and 601 tasks will also be returned if there is an incomplete task.
 *
 * @param props.task A list of current tasks for the person
 * @param props.overrideIds Provide specific org, facility, and person IDs if you are using this hook anywhere that the IDs cannot be derived from the URL (ex: Tools or Compliance)
 */
export default function useUploadableDocuments(props: DefaultProps) {
  const { tasks, overrideIds = {} } = props
  const currentPage = useCurrentPage()
  const { orgId, facilityId, personId } = extractIds(currentPage)

  const changingOrgId = orgId || overrideIds.orgId
  const changingFacilityId = facilityId || overrideIds.facilityId
  const changingPersonId = personId || overrideIds.personId

  const configuredTemplates = useConfiguredTasks(
    personId
      ? {
          facilityId: facilityId,
          orgId: orgId,
          id: personId,
        }
      : {
          facilityId: overrideIds.facilityId,
          orgId: overrideIds.orgId,
          id: overrideIds.personId,
        },
    [changingOrgId, changingFacilityId, changingPersonId]
  )

  const [uploadableDocuments, setUploadableDocuments] = useState<
    Loading<TaskTemplateInfo[]>
  >({ tag: 'Loading' })

  const refreshUploadableDocuments = () => {
    if (configuredTemplates.tag === 'Complete') {
      const uploadableTemplates = configuredTemplates.value.filter(
        isUploadable(tasks)
      )
      setUploadableDocuments({
        tag: 'Complete',
        value: [
          ...uploadableTemplates,
          ...STANDARD_UPLOADS.filter((su) =>
            uploadableTemplates.every((t) => t.dataType !== su.dataType)
          ),
          incomplete603a(tasks)?.taskTemplateInfo,
        ].filter(notEmpty),
      })
    }
  }

  useEffect(() => {
    refreshUploadableDocuments()
  }, [configuredTemplates.tag, tasks.length])

  return {
    configuredTemplates,
    uploadableDocuments,
    refreshUploadableDocuments,
  }
}
const isUploadable =
  (tasks: Task[]) =>
  ({ dataType, customType, isUpload }: TaskTemplateInfo) => {
    if (isUpload) return true

    const dataTypesAllowedToUploadWhenIncomplete: DataType[] = [
      DataType.DATA_TYPE_CA_FORM_603,
      DataType.DATA_TYPE_CA_FORM_601,
      DataType.DATA_TYPE_CUSTOM_SIGNABLE_FORM,
    ]

    if (dataType && dataTypesAllowedToUploadWhenIncomplete.includes(dataType)) {
      return tasks.some(
        (t) =>
          t.taskTemplateInfo?.dataType === dataType &&
          t.taskTemplateInfo?.customType === customType &&
          isIncomplete(t)
      )
    }

    return false
  }

const incomplete603a = (tasks: Task[]): Task | undefined =>
  tasks
    .filter(
      (task) =>
        task.taskTemplateInfo?.dataType === DataType.DATA_TYPE_CA_FORM_603A
    )
    .find(isIncomplete)

export const useToolsUploadableDocuments = (props: {
  tasks: Task[]
  ids?: PersonIds
}) => {
  const { tasks, ids = {} } = props

  const configuredTemplates = useToolsLoadingConfiguredTasks(ids, [
    ids.orgId,
    ids.facilityId,
  ])

  const [uploadableDocuments, setUploadableDocuments] = useState<
    Loading<TaskTemplateInfo[]>
  >({ tag: 'Loading' })

  const refreshUploadableDocuments = () => {
    setUploadableDocuments({ tag: 'Loading' })

    if (configuredTemplates.tag === 'Complete') {
      const uploadableTemplates = configuredTemplates.value.filter(
        isUploadable(tasks)
      )
      setUploadableDocuments({
        tag: 'Complete',
        value: [
          ...uploadableTemplates,
          ...STANDARD_UPLOADS.filter((su) =>
            uploadableTemplates.every((t) => t.dataType !== su.dataType)
          ),
          incomplete603a(tasks)?.taskTemplateInfo,
        ].filter(notEmpty),
      })
    }
  }

  useEffect(() => {
    refreshUploadableDocuments()
  }, [configuredTemplates.tag, tasks.length])

  return {
    configuredTemplates,
    uploadableDocuments,
    refreshUploadableDocuments,
  }
}
