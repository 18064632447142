import {
  OrgFacilitySettings,
  SettingsType,
} from '@augusthealth/models/com/august/protos/org_facility_settings'
import { AmbulatorySettings } from '@augusthealth/models/com/august/protos/settings/ambulatory_settings'
import { useForm } from 'react-hook-form'
import { getFacilityAmbulatorySettings } from '@shared/api/facility_settings'
import { upsertSettings } from '@shared/api/orgFacilitySettings'
import OrgFacilityLoaderSaver, {
  OnSubmit,
} from '../subComponents/OrgFacilityLoaderSaver'
import AmbulatoryList from './AmbulatoryList'

export default function Ambulatory() {
  const useFormReturn = useForm<AmbulatorySettings>()

  return (
    <OrgFacilityLoaderSaver
      pageTitle="Ambulatory Settings"
      loaderTitle="I want to view Ambulatory settings for..."
      saverTitle="Save Ambulatory Settings at..."
      childrenTitle="Ambulatory Status Settings"
      saveBtnLabel="Save Ambulatory Settings"
      hudText="Ambulatory Settings Updated"
      useFormReturn={useFormReturn}
      path="ambulatoryStatusSettings"
      loaderFunc={(orgId, facilityId) =>
        getFacilityAmbulatorySettings(orgId, facilityId)
      }
      loaderFuncMap={(res: OrgFacilitySettings) =>
        res.settings?.ambulatorySettings?.ambulatoryStatusSettings || []
      }
      onSubmit={({ ids, data }: OnSubmit) => {
        return upsertSettings({
          ...ids,
          settings: { ambulatorySettings: data },
          settingsType: SettingsType.SETTINGS_TYPE_AMBULATORY_SETTINGS,
        })
      }}
    >
      <AmbulatoryList
        path="ambulatoryStatusSettings"
        useFormReturn={useFormReturn}
      />
    </OrgFacilityLoaderSaver>
  )
}
