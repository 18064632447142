import { AugustInitialAppraisal } from '@augusthealth/models/com/august/protos/august_initial_appraisal'
import {
  AppraisalSettings_AppraisalCategory,
  AppraisalSettings_Level,
} from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { mergePatchSnapshot } from '@shared/api/snapshot'
import { CategoryKeyIdentifier } from '@shared/types/assessment_configuration'
import { Facility } from '@shared/types/facility'
import { Person } from '@shared/types/person'
import { DataType, Snapshot } from '@shared/types/snapshot'
import { isVirginiaFacility } from '@shared/utils/facilities'
import {
  getCategoryAndCustomKeyFromCategoryKeyIdentifier,
  getValuesByCategory,
} from '@shared/utils/residentAssessment'
import { State } from './stateManagement'

export async function updateServicePlan({
  person,
  servicePlan,
  state,
}: {
  person: Person
  servicePlan: Snapshot
  state: State
}) {
  const patch = Object.keys(state).reduce(
    (accum, el: CategoryKeyIdentifier) => {
      const { categoryKey, customKey } =
        getCategoryAndCustomKeyFromCategoryKeyIdentifier(el)
      return [
        ...accum,
        {
          ...state[el],
          planCategoryKey: categoryKey,
          customKey,
        },
      ]
    },
    []
  )

  return await mergePatchSnapshot({
    pId: person.id!,
    orgId: person.orgId!,
    snapshotId: 'latest',
    patch: { servicePlan: { planCategories: patch } },
    dataType: DataType.DATA_TYPE_SERVICE_PLAN,
    status: servicePlan.status,
  })
}

export function countIncompleteSections({
  assessment,
  state,
  facility,
}: {
  assessment?: AugustInitialAppraisal
  state: State
  facility?: Facility
}) {
  if (!assessment) {
    return 0
  }

  // All categories
  const categories = assessment?.settings?.categories ?? []

  const incompleteCategories = categories.filter((category) => {
    if (category.isExcludedFromServicePlan) {
      return false
    }

    return isIncompleteCategory({
      category,
      state,
      assessment,
      facility,
    })
  })

  return incompleteCategories.length
}

function isIncompleteCategory({
  category,
  state,
  assessment,
  facility,
}: {
  category: AppraisalSettings_AppraisalCategory
  state: State
  assessment: AugustInitialAppraisal
  facility?: Facility
}) {
  // Don't count if the category has no configured levels
  const hasConfiguredNeeds = (category.levels ?? []).length > 0
  if (!hasConfiguredNeeds) {
    return false
  }

  // If the selected level is none, then exclude
  const selectedLevel = getValuesByCategory({
    assessment,
    category,
  }).level
  if (selectedLevel === AppraisalSettings_Level.LEVEL_NONE) {
    return false
  }

  // Check for missing values from service plan web form
  const categoryKey = category.customKey
    ? `${category.categoryKey}-${category.customKey}`
    : category.categoryKey!

  return (
    state[categoryKey]?.frequency === undefined ||
    state[categoryKey]?.personResponsible === undefined ||
    state[categoryKey]?.evaluation === undefined ||
    anyFacilityFieldsIncomplete({ categoryKey, state, facility })
  )
}

function anyFacilityFieldsIncomplete({
  categoryKey,
  state,
  facility,
}: {
  categoryKey: string
  state: State
  facility?: Facility
}) {
  if (facility && isVirginiaFacility(facility)) {
    return (
      state[categoryKey]?.expectedOutcomes === undefined ||
      state[categoryKey]?.timeFrame === undefined ||
      state[categoryKey]?.location?.selection === undefined
    )
  }

  return false
}
