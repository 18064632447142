import { JwtPayload } from 'jwt-decode'
import { loaded, Loading } from '@shared/utils/loading'

export type UnsubscribeJwt = JwtPayload & {
  facilityName?: string
}

export type JwtContents =
  | [string, string]
  | [string, undefined]
  | [undefined, string]
  | [undefined, undefined]

export type Subscription = 'Subscribed' | 'Unsubscribed'
export const subscribed: Loading<Subscription> = loaded('Subscribed')
export const unsubcribed: Loading<Subscription> = loaded('Unsubscribed')
