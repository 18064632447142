import { useForm } from 'react-hook-form'
import Button from '@shared/components/Auth/LoginWithUsernameOrEmail/Button'
import { ForgotPasswordRequest } from '@shared/types/api/forgot_password'
import { tw, twx } from '@shared/utils/tailwind'

export default function RequestPasswordCodeForm({
  onSubmit,
}: {
  onSubmit: (forgotPasswordRequest: ForgotPasswordRequest) => Promise<void>
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<ForgotPasswordRequest>({
    defaultValues: { userAlias: '' },
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className={twx(
          'relative text-[14px] transition-[height] duration-300 ease-out',
          'opacity-1 w-100 mb-[16px] h-[48px]',
          'mt-[48px]',
          'mb-[16px]'
        )}
      >
        <input
          type="text"
          className={twx(
            'h-[48px] w-[360px] rounded-[6px] border border-gray-10 pl-[40px] font-medium hover:outline hover:outline-1 hover:outline-rebrand-primary-light focus:outline focus:outline-1 focus:outline-rebrand-primary-light'
          )}
          placeholder="Email or username"
          {...register('userAlias', {
            required: true,
          })}
        />
        <i
          className={tw`fa fa-fw fa-envelope absolute left-[16px] top-[17px] text-[14px] text-rebrand-primary-light`}
        />
      </div>

      <Button
        disabled={isSubmitting || !isValid}
        text="Request password reset link"
        icon="envelope"
        state="engaged"
      />
    </form>
  )
}
