import { useContext, useEffect, useRef, useState } from 'react'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput } from '@shared/components/Labels'
import {
  FaxInput,
  PhoneInput,
} from '@shared/components/TextInputWithIcon/TextInputWithIcon'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import {
  getHospitalAddress,
  getHospitalFax,
  getHospitalPhone,
} from '@shared/utils/healthcareService'
import { getHospitalInfo } from '@shared/utils/person'
import usePerson from '@app/hooks/usePerson'
import usePlacesAutocomplete from '@app/hooks/usePlacesAutocomplete'
import styles from './styles.module.css'
import { updatePreferredHospital } from './helpers'

interface Props {
  person: Person
  onClose: (updated: boolean) => void
}
export default function EditPreferredHospital({ person, onClose }: Props) {
  const { setError } = useContext(GlobalContext)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    hospitalPhone: initialPhone = '',
    hospitalAddress: initialAddress = '',
    hospitalFax: initialFax = '',
    hospitalName: initialName = '',
  } = getHospitalInfo(person)

  const inputRef = useRef<HTMLInputElement>(null)

  const [hospitalName, setHospitalName] = useState(initialName)
  const [hospitalAddress, setHospitalAddress] = useState(initialAddress)
  const [hospitalPhone, setHospitalPhone] = useState(initialPhone)
  const [hospitalFax, setHospitalFax] = useState(initialFax)
  const [googlePlace, setGooglePlace] =
    useState<google.maps.places.PlaceResult>()

  usePlacesAutocomplete({
    inputRef,
    callback: (place) => {
      setGooglePlace(place)
      setHospitalName(place.name || '')
      setHospitalAddress(place.formatted_address || '')
      setHospitalPhone(place.formatted_phone_number || '')
      setHospitalFax('')
    },
  })

  const { person: updatedPerson } = usePerson({
    initialData: person,
    fields: ['hospital'],
  })

  useEffect(() => {
    if (updatedPerson.tag === 'Complete') {
      const hospital = person.hospital
      const initialPhone = hospital ? getHospitalPhone(hospital) : ''
      const initialAddress = hospital ? getHospitalAddress(hospital) : ''
      const initialFax = hospital ? getHospitalFax(hospital) : ''
      setHospitalName(hospital?.name || '')
      setHospitalAddress(initialAddress || '')
      setHospitalPhone(initialPhone || '')
      setHospitalFax(initialFax || '')
    }
  }, [updatedPerson.tag])

  const onSubmit = async () => {
    setIsSubmitting(true)

    try {
      await updatePreferredHospital(person, {
        googlePlace,
        hospitalName,
        hospitalFax,
        hospitalPhone,
      })

      onClose(true)
    } catch (e) {
      setError(e)
      setIsSubmitting(false)
    }
  }

  return (
    <form>
      <LabelAboveInput htmlFor="hospital">Hospital</LabelAboveInput>
      <div className={styles.inputContainer}>
        <BasicInput
          name="hospital"
          placeholder="Hospital Name"
          ref={inputRef}
          value={hospitalName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setHospitalName(e.target.value)
          }}
        />
        <img
          alt={'Google Logo'}
          src="/images/powered_by_google_on_white.png"
          className={styles.google}
        />
      </div>
      <div className={styles.inputContainer}>
        <BasicInput
          disabled
          placeholder="Hospital Address"
          value={hospitalAddress}
        />
      </div>
      <div className="mt-[32px]">
        <LabelAboveInput htmlFor="phone">Phone</LabelAboveInput>
        <PhoneInput
          inputProps={{
            name: 'phone',
            value: hospitalPhone,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setHospitalPhone(e.target.value),
          }}
        />
      </div>
      <div className="mt-[32px]">
        <LabelAboveInput htmlFor="fax">Fax</LabelAboveInput>
        <FaxInput
          inputProps={{
            name: 'fax',
            value: hospitalFax,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setHospitalFax(e.target.value),
          }}
        />
      </div>
      <div className="mt-[32px]">
        <AnimatedPopupFormFooter
          noBtn={{ action: () => onClose(false) }}
          yesBtn={{
            props: {
              onClick: (e) => {
                e.preventDefault()
                void onSubmit()
              },
              disabled:
                (!googlePlace && !initialAddress) ||
                updatedPerson.tag === 'Loading',
            },
          }}
          formState={{ isSubmitting }}
        />
      </div>
    </form>
  )
}
