import urlcat, { ParamMap } from 'urlcat'

export const FACILITIES_CARE_PROGRESS_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/care',
]

export function getProgressRoutinesPageUrl(
  orgId: string,
  facilityId: string,
  params: ParamMap
) {
  const path = FACILITIES_CARE_PROGRESS_PATHS[0]
    .replace(':orgId', orgId)
    .replace(':facilityId', facilityId)

  return urlcat('', path, params)
}
