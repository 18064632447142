import React from 'react'

export type CongratsType =
  | 'COMPLETED_TASK_VISIT_DOCUMENTS' // Modal with additional VISIT THE DOCUMENTS button
  | 'COMPLETED_TASK_CONTINUE' // Modal with one CONTINUE button
  | false // close Modal

type Props = {
  openShareWithRPModal: boolean
  setOpenShareWithRPModal: (open: boolean) => void
  openCongrats: CongratsType
  setOpenCongrats: (type: CongratsType) => void
}

const props: Props = {
  openShareWithRPModal: false,
  setOpenShareWithRPModal: () => {},
  openCongrats: false,
  setOpenCongrats: () => {},
}

export default React.createContext(props)
