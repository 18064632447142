import { DependencyList, useContext, useEffect, useState } from 'react'
import { listFacilities } from '@shared/api/facility'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { AsyncResult } from '@shared/utils/loading'

export default function useAllFacilities({
  orgs,
  deps = [],
}: {
  orgs: Organization[]
  deps?: DependencyList
}) {
  const { setError } = useContext(GlobalContext)
  const [facilities, setFacilities] = useState<AsyncResult<Facility[], any>>({
    tag: 'Loading',
  })

  useEffect(() => {
    if (orgs.length) {
      Promise.all(orgs.map((org) => listFacilities(org.id)))
        .then((results) => {
          const flattened = Array.prototype.concat.apply([], results)
          setFacilities({ tag: 'Complete', value: flattened })
        })
        .catch((err) => {
          setFacilities({ tag: 'Error', value: err })
          setError(err)
        })
    }
  }, [orgs, ...deps])

  return facilities
}
