import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput } from '@shared/components/Labels'
import { DetailModalForm } from '@app/pages/Tools/AssessmentConfiguration/DetailGroups/AddEditDetailModal/AddEditDetailModal'

export const TextareaDetail = () => {
  const { register, unregister } = useFormContext<DetailModalForm>()

  useEffect(() => {
    return () => {
      unregister(['description', 'helpText'])
    }
  }, [])

  return (
    <>
      <LabelAboveInput htmlFor={'description'}>Description</LabelAboveInput>
      <BasicInput
        type={'text'}
        placeholder={'Description'}
        {...register('description')}
      />

      <LabelAboveInput htmlFor={'helpText'}>Help Text</LabelAboveInput>
      <BasicInput
        type={'text'}
        placeholder={'Help text'}
        {...register('helpText')}
      />
    </>
  )
}
