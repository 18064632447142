import {
  ServicePlan,
  ServicePlan_EvaluationOption,
  ServicePlan_Frequency,
  ServicePlan_LocationOption,
  ServicePlan_ObjectiveOption,
  ServicePlan_PersonResponsible,
  ServicePlan_PlanCategoryData,
} from '@augusthealth/models/com/august/protos/service_plan'
import { CategoryKeyIdentifier } from '@shared/types/assessment_configuration'
import { buildCategoryKeyIdentifier } from '@shared/utils/residentAssessment'

export type State = Record<CategoryKeyIdentifier, ServicePlan_PlanCategoryData>

export type Action =
  | {
      actionType: 'changeFrequency'
      payload: {
        category: CategoryKeyIdentifier
        newFrequency?: ServicePlan_Frequency
      }
    }
  | {
      actionType: 'setResponsiblePeople'
      payload: {
        category: CategoryKeyIdentifier
        newPeople: ServicePlan_PersonResponsible[]
      }
    }
  | {
      actionType: 'changeObjectiveText'
      payload: { category: CategoryKeyIdentifier; newText: string }
    }
  | {
      actionType: 'changePresetObjective'
      payload: {
        category: CategoryKeyIdentifier
        checked: boolean
        objective: ServicePlan_ObjectiveOption
      }
    }
  | {
      actionType: 'clearPresetObjectives'
      payload: {
        category: CategoryKeyIdentifier
      }
    }
  | {
      actionType: 'changeEvaluationMethod'
      payload:
        | {
            category: CategoryKeyIdentifier
            kind: 'other'
            method: ServicePlan_EvaluationOption.EVALUATION_OPTION_OTHER
            text: string
          }
        | {
            category: CategoryKeyIdentifier
            kind: 'preselected'
            method?: ServicePlan_EvaluationOption
          }
    }
  | { actionType: 'loadData'; payload: { backendServicePlan: ServicePlan } }
  | {
      actionType: 'changeLocation'
      payload:
        | {
            category: CategoryKeyIdentifier
            kind: 'other'
            location: ServicePlan_LocationOption.LOCATION_OPTION_OTHER
            text: string
          }
        | {
            category: CategoryKeyIdentifier
            kind: 'preselected'
            location?: ServicePlan_LocationOption
            text: null
          }
    }
  | {
      actionType: 'changeExpectedOutcomes'
      payload: {
        category: CategoryKeyIdentifier
        text: string
      }
    }
  | {
      actionType: 'changeTimeframe'
      payload: {
        category: CategoryKeyIdentifier
        text: string
      }
    }

export function reducer(prevState: State, a: Action): State {
  switch (a.actionType) {
    case 'loadData':
      return (a.payload.backendServicePlan.planCategories || []).reduce(
        (accum, el) => {
          const key = buildCategoryKeyIdentifier({
            categoryKey: el.planCategoryKey,
            customKey: el.customKey,
          })
          return { ...accum, [key!]: el }
        },
        initialState
      )
    case 'changeFrequency':
      return {
        ...prevState,
        [a.payload.category]: {
          ...prevState[a.payload.category],
          frequency: a.payload.newFrequency,
        },
      }
    case 'setResponsiblePeople':
      return {
        ...prevState,
        [a.payload.category]: {
          ...prevState[a.payload.category],
          personResponsible: a.payload.newPeople,
        },
      }
    case 'changeObjectiveText':
      return {
        ...prevState,
        [a.payload.category]: {
          ...prevState[a.payload.category],
          objective: {
            ...(prevState[a.payload.category]?.objective || {}),
            text: a.payload.newText,
          },
        },
      }
    case 'clearPresetObjectives':
      return {
        ...prevState,
        [a.payload.category]: {
          ...prevState[a.payload.category],
          objective: {
            ...(prevState[a.payload.category]?.objective || {}),
            selections: [],
          },
        },
      }
    case 'changePresetObjective': {
      const priorObjectives =
        prevState[a.payload.category]?.objective?.selections || []

      if (a.payload.checked) {
        return {
          ...prevState,
          [a.payload.category]: {
            ...prevState[a.payload.category],
            objective: {
              ...(prevState[a.payload.category]?.objective || {}),
              selections: [...priorObjectives, a.payload.objective],
            },
          },
        }
      } else {
        return {
          ...prevState,
          [a.payload.category]: {
            ...prevState[a.payload.category],
            objective: {
              ...(prevState[a.payload.category]?.objective || {}),
              selections: priorObjectives.filter(
                (po) => po !== a.payload.objective
              ),
            },
          },
        }
      }
    }
    case 'changeExpectedOutcomes':
      return {
        ...prevState,
        [a.payload.category]: {
          ...prevState[a.payload.category],
          expectedOutcomes: a.payload.text,
        },
      }
    case 'changeTimeframe':
      return {
        ...prevState,
        [a.payload.category]: {
          ...prevState[a.payload.category],
          timeFrame: a.payload.text,
        },
      }
    case 'changeLocation':
      switch (a.payload.kind) {
        case 'other':
          return {
            ...prevState,
            [a.payload.category]: {
              ...prevState[a.payload.category],
              location: {
                selection: ServicePlan_LocationOption.LOCATION_OPTION_OTHER,
                text: a.payload.text,
              },
            },
          }
        case 'preselected':
          return {
            ...prevState,
            [a.payload.category]: {
              ...prevState[a.payload.category],
              location: {
                selection: a.payload.location,
                text: null,
              },
            },
          }
      }
    // eslint-disable-next-line no-fallthrough
    case 'changeEvaluationMethod':
      switch (a.payload.kind) {
        case 'preselected':
          return {
            ...prevState,
            [a.payload.category]: {
              ...prevState[a.payload.category],
              evaluation: {
                selection: a.payload.method,
                text: undefined,
              },
            },
          }
        case 'other':
          return {
            ...prevState,
            [a.payload.category]: {
              ...prevState[a.payload.category],
              evaluation: {
                selection: a.payload.method,
                text: a.payload.text,
              },
            },
          }
      }
  }
}

export const initialState: State = {
  PLAN_CATEGORY_KEY_BATHING: {},
  PLAN_CATEGORY_KEY_CARE_COORDINATION: {},
  PLAN_CATEGORY_KEY_COGNITIVE: {},
  PLAN_CATEGORY_KEY_COMMUNICATION: {},
  PLAN_CATEGORY_KEY_DENTAL: {},
  PLAN_CATEGORY_KEY_DISPOSITION_AND_BEHAVIORS: {},
  PLAN_CATEGORY_KEY_DRESSING: {},
  PLAN_CATEGORY_KEY_GROOMING: {},
  PLAN_CATEGORY_KEY_HEARING: {},
  PLAN_CATEGORY_KEY_MEALS_AND_NUTRITION: {},
  PLAN_CATEGORY_KEY_MEDICATION_MANAGEMENT: {},
  PLAN_CATEGORY_KEY_PSYCHO_SOCIAL_ENGAGEMENT: {},
  PLAN_CATEGORY_KEY_SLEEP: {},
  PLAN_CATEGORY_KEY_SPECIAL_CARE_NEEDS: {},
  PLAN_CATEGORY_KEY_TOILETING: {},
  PLAN_CATEGORY_KEY_TRANSFER_AND_MOBILITY: {},
  PLAN_CATEGORY_KEY_VISION: {},
  PLAN_CATEGORY_KEY_WANDERING_AND_ELOPEMENT: {},
  PLAN_CATEGORY_KEY_ADDITIONAL_SERVICES: {},
  PLAN_CATEGORY_KEY_MOBILITY: {},
  PLAN_CATEGORY_KEY_TRANSFER: {},
}
