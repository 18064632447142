import { endOfDay, isPast } from 'date-fns'
import {
  EffectiveStatus,
  RoutineOrder,
  RoutineStatus,
} from '@shared/types/routine_order'
import { fromDateMessageToDate } from './date'

/**
 * Return the effective status of a routine order, considering both the set
 * status and the effective period compared to today.
 */
export function effectiveStatus(routineOrder: RoutineOrder): EffectiveStatus {
  const endDate = fromDateMessageToDate(routineOrder.effectivePeriod?.endDate)

  switch (routineOrder.status) {
    case RoutineStatus.ROUTINE_STATUS_DRAFT:
      return 'active'
    case RoutineStatus.ROUTINE_STATUS_ACTIVE:
      return endDate && isPast(endOfDay(endDate)) ? 'expired' : 'active'
    case RoutineStatus.ROUTINE_STATUS_DISCONTINUED:
      return 'discontinued'
    case RoutineStatus.ROUTINE_STATUS_DISCARDED:
      return 'discarded'
    default:
      return 'discontinued'
  }
}

export function isMorningOrBedtime(routineOrder: RoutineOrder) {
  return ['Morning', 'Bedtime'].includes(routineOrder.name)
}

export function isDraft(routineOrder: RoutineOrder) {
  return routineOrder.status === RoutineStatus.ROUTINE_STATUS_DRAFT
}

export function isDraftOrDiscarded(routineOrder: RoutineOrder) {
  return (
    routineOrder.status === RoutineStatus.ROUTINE_STATUS_DRAFT ||
    routineOrder.status === RoutineStatus.ROUTINE_STATUS_DISCARDED
  )
}
