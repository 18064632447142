import { IncomingFile } from '@augusthealth/models/com/august/protos/incoming_file'
import { fetchBlobUrlAndContentType, requestJson } from '@shared/api/request'
import { FacilityIds } from '@shared/types/facility'
import { DataType, Snapshot_Data } from '@shared/types/snapshot'
import {
  getAssignFileToResidentUrl,
  getIncomingFilesInboxUrl,
  getIncomingFileSnapshotUrl,
  getIncomingFileUrl,
} from '@app/api/urls/incoming_files'

export const getIncomingFilesInbox = async (
  ids: FacilityIds
): Promise<IncomingFile[]> => {
  const url = getIncomingFilesInboxUrl(ids)
  const responseJson = await requestJson({ url, method: 'GET' })
  return responseJson.data
}

export const getIncomingFile = async ({
  orgId,
  facilityId,
  fileId,
}: {
  orgId: string
  facilityId: string
  fileId: string
}) => {
  const url = getIncomingFileSnapshotUrl({ orgId, facilityId, fileId })
  const responseJson = await fetchBlobUrlAndContentType({
    url,
    method: 'GET',
    requestContentType: 'application/pdf',
  })
  return responseJson
}

type AssignmentArgs = {
  formData: Snapshot_Data
  orgId: string
  facilityId: string
  personId: string
  dataType: DataType
  fileId: string
}
export const assignFileToResident = async ({
  formData,
  orgId,
  facilityId,
  personId,
  dataType,
  fileId,
}: AssignmentArgs) => {
  const url = getAssignFileToResidentUrl({
    orgId,
    facilityId,
    personId,
    dataType,
    fileId,
  })
  const responseJson = await requestJson({
    url,
    method: 'POST',
    body: JSON.stringify(formData),
  })
  return responseJson.data
}

type PatchArgs = {
  fileId: string
  orgId: string
  facilityId: string
  patch: Partial<IncomingFile>
}
export const patchIncomingFile = async ({
  patch,
  fileId,
  orgId,
  facilityId,
}: PatchArgs) => {
  const url = getIncomingFileUrl({ orgId, facilityId, fileId })
  const responseJson = await requestJson({
    url,
    method: 'PATCH',
    contentType: 'application/merge-patch+json',
    body: JSON.stringify(patch),
  })
  return responseJson.data
}
