import { HTMLProps } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { getDraggableElementProps } from '../helpers'

type Props = HTMLProps<HTMLLIElement> & {
  index?: number
  indexName?: string
  path: string
  useFormReturn: UseFormReturn
}

export default function ListItem(props: Props) {
  const { index, indexName, path, useFormReturn, ...restProps } = props
  const { setValue, watch } = useFormReturn
  const draggableProps =
    index !== undefined
      ? getDraggableElementProps({
          list: watch(path), // Must call watch to get new reference
          index,
          indexName: indexName || `${path}-indexName`,
          onDrop: (newList) => setValue(path, newList, { shouldDirty: true }),
        })
      : {}

  return <li {...draggableProps} {...restProps} />
}
