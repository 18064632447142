import { PharmacyPartner } from '@augusthealth/models/com/august/protos/pharmacy_partner'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import { Person } from '@shared/types/person'
import { PersonStats } from '@shared/types/person_stats'
import { ResidentOrders } from '@shared/types/pharmacy_integration'
import { formatDateMessage } from '@shared/utils/date'
import { getFullName } from '@shared/utils/humanName'
import { Loading } from '@shared/utils/loading'
import {
  ageString,
  displayGender,
  getProfileSvgPath,
} from '@shared/utils/person'

export type ReviewUnassignedOrderMode =
  | {
      tag: 'initial'
      orderCount: number
      onReject: () => void
      onAssign: () => void
    }
  | {
      tag: 'assignment' | 'confirmation'
      selectedPerson: OptionTypeBase<PersonStats> | null
      onPersonSelect: (e: OptionTypeBase<PersonStats>) => void
      onCancel: () => void
      onContinue: () => void
      person: Loading<Person>
    }
  | {
      tag: 'reject'
      onCancel: () => void
      onConfirmReject: () => Promise<void>
      orderCount: number
    }

export type AssignedPerson = Person & {
  profilePhotoUrl?: string
}

export type AssignmentLoading = 'reject' | 'assign' | false

export type UnassignedPerson = {
  name: string
  gender: string
  dateOfBirth: string
  age: string | number
  photoUrl: string
  partner: PharmacyPartner
  externalFacilityIdentifier: string
  externalResidentIdentifier: string
}

export const getUnassignedDetails = (
  order: ResidentOrders
): UnassignedPerson => {
  const pharmacyResident = order.matchDetails!.matchCriteria!

  return {
    name: getFullName(pharmacyResident.name!),
    gender: displayGender(pharmacyResident),
    dateOfBirth: formatDateMessage(pharmacyResident.birthDate!),
    age: ageString(pharmacyResident),
    photoUrl: getProfileSvgPath(pharmacyResident),
    partner: pharmacyResident.identifier!.partner!,
    externalFacilityIdentifier:
      pharmacyResident.identifier!.externalFacilityIdentifier!,
    externalResidentIdentifier:
      pharmacyResident.identifier!.externalResidentIdentifier!,
  }
}

export const getAgeDisplay = (age: string | number) => {
  return typeof age === 'number' ? `(${age} years)` : `(${age})`
}
