import React, { useEffect, useState } from 'react'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import {
  SETTINGS_LEVEL_OPTIONS,
  SettingsConfig,
  SettingsLevel,
} from '@shared/utils/orgFacilitySettings'
import {
  expandStateAbbreviation,
  StateAbbreviation,
  StateName,
  stateOptions,
} from '@shared/utils/state'
import { useFacilities } from '@app/hooks/useFacilities'
import useOrganizations from '@app/hooks/useOrganizations'
import styles from './styles.module.css'

type Props = {
  configOptions?: OptionTypeBase<SettingsLevel>[]
  defaultOrg: OptionTypeBase<Organization> | null
  defaultFacility: OptionTypeBase<Facility> | null
  onSelectionChange: React.Dispatch<React.SetStateAction<SettingsConfig>>
  labelText?: string
}
export const ConfigurationLevelSelects = ({
  configOptions = SETTINGS_LEVEL_OPTIONS,
  defaultOrg,
  defaultFacility,
  onSelectionChange,
  labelText,
}: Props) => {
  const [selectedConfigLevel, setSelectedConfigLevel] = useState<
    OptionTypeBase<SettingsLevel>
  >(configOptions[0])
  const [selectedState, setSelectedState] =
    useState<OptionTypeBase<StateAbbreviation> | null>(null)
  const [selectedOrganization, setSelectedOrganization] =
    useState<OptionTypeBase<Organization> | null>(defaultOrg)
  const [selectedFacility, setSelectedFacility] =
    useState<OptionTypeBase<Facility> | null>(defaultFacility)

  const { organizations } = useOrganizations()
  const { facilities } = useFacilities(
    { orgId: selectedOrganization?.value.id },
    [selectedOrganization]
  )

  useEffect(() => {
    const defaultState = defaultFacility?.value?.address?.state
      ? {
          label:
            expandStateAbbreviation(
              defaultFacility.value.address.state as StateName
            ) || (defaultFacility.value.address.state as StateName),
          value: defaultFacility.value.address.state as StateAbbreviation,
        }
      : null
    setSelectedFacility(defaultFacility)
    setSelectedOrganization(defaultOrg)
    setSelectedState(defaultState)
    onSelectionChange((prev) => ({
      ...prev,
      settingsLevel: selectedConfigLevel?.value,
      facilityId: defaultFacility?.value.id,
      orgId: defaultOrg?.value.id,
      state: defaultState?.value,
    }))
  }, [defaultOrg, defaultFacility])

  return (
    <>
      {labelText && (
        <LabelAboveInput htmlFor="configLevel">{labelText}</LabelAboveInput>
      )}
      <StyledSelect
        className="mb-[16px]"
        name="configLevel"
        maxMenuHeight={200}
        menuPlacement="auto"
        options={configOptions}
        onChange={(e: OptionTypeBase<SettingsLevel>) => {
          onSelectionChange((prev) => ({
            ...prev,
            settingsLevel: e.value,
          }))
          setSelectedConfigLevel(e)
        }}
        value={selectedConfigLevel}
      />
      {labelText && <hr className={`${styles.hr} my-[16px]`} />}
      {(selectedConfigLevel.value === SettingsLevel.FACILITY_LEVEL ||
        selectedConfigLevel.value === SettingsLevel.ORG_LEVEL ||
        selectedConfigLevel.value === SettingsLevel.ORG_STATE_LEVEL) && (
        <StyledSelect
          className="mb-[16px]"
          placeholder="Select Organization..."
          options={
            organizations?.tag === 'Complete'
              ? organizations.value.map((o) => {
                  return { label: o.name!, value: o }
                })
              : []
          }
          name="organizations"
          id="organizations"
          onChange={(e: OptionTypeBase<Organization>) => {
            onSelectionChange((prev) => ({
              ...prev,
              orgId: e.value.id,
              facilityId: undefined,
            }))
            setSelectedOrganization(e)
            if (selectedFacility) {
              setSelectedFacility(null)
            }
          }}
          value={selectedOrganization}
        />
      )}
      {(selectedConfigLevel.value === SettingsLevel.STATE_LEVEL ||
        selectedConfigLevel.value === SettingsLevel.ORG_STATE_LEVEL) && (
        <StyledSelect
          className="mb-[16px]"
          placeholder="Select State..."
          options={stateOptions}
          name="states"
          id="states"
          onChange={(e: OptionTypeBase<StateAbbreviation>) => {
            onSelectionChange((prev) => ({
              ...prev,
              state: e.value,
            }))
            setSelectedState(e)
          }}
          value={selectedState}
        />
      )}
      {selectedConfigLevel.value === SettingsLevel.FACILITY_LEVEL && (
        <StyledSelect
          className="mb-[16px]"
          placeholder={
            facilities.tag === 'Loading' ? 'Loading...' : 'Select Facility...'
          }
          isDisabled={!selectedOrganization || facilities.tag === 'Loading'}
          options={
            facilities?.tag === 'Complete'
              ? facilities.value.map((f) => {
                  return { label: f.name!, value: f }
                })
              : []
          }
          name="facilities"
          id="facilities"
          onChange={(e: OptionTypeBase<Facility>) => {
            onSelectionChange((prev) => ({
              ...prev,
              facilityId: e.value.id,
            }))
            setSelectedFacility(e)
          }}
          value={selectedFacility}
        />
      )}
    </>
  )
}
