import { parseISO } from 'date-fns'
import { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  closeBillingAccountingPeriod,
  fetchClosedBillingAccounting,
} from '@shared/api/billing'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { EmbossedCard } from '@shared/components/EmbossedCard'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { FacilityIds } from '@shared/types/facility'
import { getBillingPeriodOptions } from '@shared/utils/billing'
import { tw } from '@shared/utils/tailwind'
import { RHFSwitch } from '@app/components/Switch'
import BillingModal from '@app/pages/Facilities/Billing/Modals/components/BillingModal'

type CloseBooksFormData = {
  isoStr: string
  confirmChecked: boolean
}

export default function CloseBooksModal({
  facilityIds,
  closeFn,
  doneFn,
}: {
  facilityIds: FacilityIds
  closeFn: () => void
  doneFn: (msg: string) => Promise<void>
}) {
  const { setError } = useContext(GlobalContext)
  const { orgId, id: facilityId } = facilityIds
  const { control, formState, handleSubmit, register } =
    useForm<CloseBooksFormData>()
  const { isValid } = formState
  const [minDate, setMinDate] = useState<Date>()
  const billingPeriodOptions = getBillingPeriodOptions({
    returnIsoStr: true,
    minDate,
  })

  useEffect(() => {
    fetchClosedBillingAccounting(facilityIds)
      .then((res) => {
        if (res?.data.closedBefore) {
          setMinDate(parseISO(res.data.closedBefore))
        }
      })
      .catch(setError)
  }, [orgId, facilityId])

  const onSave = async (formData: CloseBooksFormData) => {
    try {
      await closeBillingAccountingPeriod({
        facilityIds,
        isoStr: formData.isoStr,
      })
      await doneFn('Books closed!')
      closeFn()
    } catch (e) {
      setError(e)
    }
  }

  return (
    <BillingModal
      id="billing-close-books-modal"
      title="Close Books"
      onClose={closeFn}
    >
      <form onSubmit={handleSubmit(onSave)}>
        <p
          className={tw`text-[14px] font-medium leading-[20px] text-secondary-06`}
        >
          This action will lock all charges before the selected date. The
          closing date will always be the last day of the month chosen. August
          Health support can only undo this action.
        </p>
        <p
          className={tw`mt-2 text-[14px] font-medium leading-[20px] text-secondary-06`}
        >
          To continue, select the month you'd like the books to close.
        </p>
        <EmbossedCard className={tw`my-[32px]`}>
          <Controller
            control={control}
            name="isoStr"
            rules={{ required: true }}
            render={({ field: { onChange } }) => {
              return (
                <StyledSelect
                  required
                  inputId="billingPeriod"
                  options={billingPeriodOptions}
                  onChange={(opt: OptionTypeBase) => onChange(opt.value)}
                  className={tw`mb-[24px]`}
                  maxMenuHeight={200}
                />
              )
            }}
          />
          <div className={tw`flex items-center`}>
            <RHFSwitch
              name="confirmChecked"
              register={register}
              registerOptions={{ required: true }}
            />
            <div
              className={tw`ml-[12px] text-[12px] font-medium leading-[16px] text-secondary-04`}
            >
              I understand this will lock all charges prior to the selected date
            </div>
          </div>
        </EmbossedCard>
        <AnimatedPopupFormFooter
          yesBtn={{
            label: 'Close Books',
            props: {
              buttonStyle: 'danger-fill',
              disabled: !isValid,
            },
          }}
          noBtn={{ action: closeFn }}
          formState={formState}
        />
      </form>
    </BillingModal>
  )
}
