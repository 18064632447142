import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import { OptionComponentProps } from './helpers'

export default function ButtonOption({
  name,
  value,
  onClick,
  label,
  checked,
  disabled,
}: OptionComponentProps) {
  return (
    <Button
      key={`${value}-radio`}
      name={name || label}
      className="mr-[12px]"
      style={{ minWidth: '84px' }}
      buttonStyle={checked ? 'primary-fill' : 'secondary-outline'}
      onClick={() => onClick(value)}
      disabled={disabled}
    >
      {`${label || value}`}
    </Button>
  )
}
