import { useState } from 'react'
import { MedicationOrder } from '@shared/types/medication_order'
import { Person } from '@shared/types/person'
import { WeakMatchModal } from '@app/components/Residents/Medications/Orders/WeakMatch/WeakMatchModal/WeakMatchModal'
import WeakMatchOrder from '@app/components/Residents/Medications/Orders/WeakMatch/WeakMatchOrder'

type Props = {
  filteredWeakMatchMeds: MedicationOrder[]
  allOrders: MedicationOrder[]
  onOrderReject: () => Promise<void>
  person: Person
}
export const WeakMatchOrdersList = ({
  filteredWeakMatchMeds,
  allOrders,
  onOrderReject,
  person,
}: Props) => {
  const [orderInWeakMatchModal, setOrderInWeakMatchModal] =
    useState<MedicationOrder | null>(null)

  return (
    <>
      {orderInWeakMatchModal && (
        <WeakMatchModal
          person={person}
          order={orderInWeakMatchModal}
          closeModal={() => {
            setOrderInWeakMatchModal(null)
          }}
          allOrders={allOrders}
          onOrderReject={onOrderReject}
        />
      )}
      {filteredWeakMatchMeds.map((medOrder) => {
        return (
          <WeakMatchOrder
            key={medOrder.sourceData?.messageId}
            order={medOrder}
            mode={{
              tag: 'list',
              showOrderInModal: setOrderInWeakMatchModal,
            }}
          />
        )
      })}
    </>
  )
}
