import { startCase } from 'lodash'
import { ValueOf } from 'type-fest'
import { PersonFilter } from '@shared/types/scheduled_task_assignment'

const EXCLUDE_PERSON_FILTERS = [
  PersonFilter.PERSON_FILTER_UNSPECIFIED,
  PersonFilter.UNRECOGNIZED,
]

export const PERSON_FILTER_OPTIONS = Object.keys(PersonFilter)
  .filter(
    (key: keyof typeof PersonFilter) =>
      !EXCLUDE_PERSON_FILTERS.includes(PersonFilter[key])
  )
  .map((key) => {
    const value = PersonFilter[key] as ValueOf<PersonFilter>
    return {
      label: startCase(
        value.toString().replace('PERSON_FILTER_', '').toLowerCase()
      ),
      value,
    }
  })
