import { Contact } from '@augusthealth/models/com/august/protos/contact'
import { useEffect } from 'react'
import ContactDropdown, {
  ContactDropdownProps,
} from '@app/components/formElements/ContactDropdown'

type CustomContactDropdownProps = Omit<ContactDropdownProps, 'value'> & {
  value?: string
}

// This is a drop down for updating a field with single contact id
export function CustomContactDropdown(props: CustomContactDropdownProps) {
  const { person, onUpdate, value, name, ...remainingProps } = props

  // Map the contact update to just the id
  const contactUpdate = (data: Contact, name: string) => {
    onUpdate(data?.id, name)
  }

  useEffect(() => {
    if (person && value) {
      // If a contact no longer exists remove it
      if (!person.contact?.find((c) => c.id === value)) {
        if (name.endsWith('[0]')) {
          // If we're dealing with an array of length 1
          onUpdate([], name.replace('[0]', ''))
        } else {
          // Or just a single value
          onUpdate(null, name)
        }
      }
    }
  }, [value, person])

  function onAddContact(id: number) {
    onUpdate(id.toString(), name)
  }

  const contactValue = value
    ? person.contact?.find((c) => c.id === value)
    : undefined

  return (
    <ContactDropdown
      {...remainingProps}
      person={person}
      onUpdate={contactUpdate}
      value={contactValue}
      useStandardUpdate
      name={name}
      onAddContact={onAddContact}
    />
  )
}
