import { get } from '@augusthealth/august-frontend-form-elements'
import { tw } from '@shared/utils/tailwind'
import ElementHolder, {
  Props as ElementHolderProps,
} from '@app/components/formElements/ElementHolder'
import RadioGroup from '@app/components/formElements/RadioGroup'
import ButtonOption from '@app/components/formElements/RadioGroup/ButtonOption'

interface RadioButtonOption {
  label: string
  value: string
}

interface Question {
  label: string
  value: string
}

type StringHash = Record<string, string>
type Hash = StringHash | Record<string, StringHash>

interface Props extends Omit<ElementHolderProps, 'children' | 'value'> {
  name: string
  onUpdate: (data: string | null, name: string) => void
  questions: Question[]
  options: RadioButtonOption[]
  data?: Hash
}

/**
 * Only use this UI component in Generator
 * Since it is highly customized in Custom Forms and not suggested to re-use outside
 */

export function SharedRadioButtonGroup(props: Props) {
  const { questions, name, onUpdate, options, data, ...rest } = props
  const radioButtons = questions.map((box) => {
    // props.value is always undefined since Generator default it to get(data, name)
    // But SharedRadioButtonGroup has a special name `${name}_${box.value}`, will rebuild it in code below
    const keyName = `${name}_${box.value}`
    const value = get(data, keyName)

    return (
      <tr key={keyName}>
        <td className={tw`pr-[16px]`}>{box.label}</td>
        <td>
          <RadioGroup
            options={options}
            name={keyName}
            value={value}
            onUpdate={onUpdate}
            OptionComponent={ButtonOption}
            isOptional={true}
          />
        </td>
      </tr>
    )
  })

  return (
    <ElementHolder {...rest}>
      <div className={tw`pt-[8px]`}>
        <table>
          <tbody>{radioButtons}</tbody>
        </table>
      </div>
    </ElementHolder>
  )
}
