import Badge from '@shared/components/Badge'
import { tw } from '@shared/utils/tailwind'

export default function ReadOnlyBadge({
  badgeLabel,
  textContent,
}: {
  badgeLabel: string
  textContent?: string
}) {
  if (textContent) {
    return (
      <div className={tw`my-[8px] flex items-center`}>
        <Badge
          badgeSize="medium"
          color="primary"
          shape="square"
          className={tw`mr-[8px] uppercase`}
        >
          {badgeLabel}
        </Badge>
        <span className={tw`font-medium`}>{textContent}</span>
      </div>
    )
  }

  return null
}
