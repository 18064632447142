import { download, getUrl } from '@shared/api/legacy'
import {
  MAR_V2_PDF_URL,
  MEDLIST_PDF_URL,
  MEDLOG_PDF_URL,
} from '@shared/api/person'
import { fetchBlobUrlAndContentType } from '@shared/api/request'
import { apiUrl, baseUrl } from '@shared/api/urls'
import {
  MedLogProps,
  PdfeMarReportProps,
  PdfMarProps,
} from '@app/components/Residents/Medications/PaperMar/interface'

//todo update this to use apiUrl
export function marPdfUrl({
  orgId,
  facilityId,
  date,
  people = [],
  blank = false,
  includePrnLog = false,
}: PdfMarProps): string {
  return getUrl({
    baseUrl: MAR_V2_PDF_URL,
    orgId,
    fId: facilityId,
    pId: people[0],
    params: {
      date,
      blank,
      includePrnLog,
    },
  })
}

export function emarReportPdfUrl({
  person,
  startMonth,
  endMonth,
}: PdfeMarReportProps): string {
  return apiUrl(
    baseUrl,
    '/organizations/:orgId/facilities/:facilityId/people/:personId/emarReport.pdf',
    {
      orgId: person.orgId,
      facilityId: person.facilityId,
      personId: person.id,
      startMonth,
      endMonth,
    }
  )
}

export const downloadMedLogPdf = async ({
  orgId,
  facilityId,
  personId,
  storedMedications,
  destroyedMedications,
}: MedLogProps) =>
  download({
    body: JSON.stringify({ destroyedMedications, storedMedications }),
    contentType: 'application/json',
    fileUrl: getUrl({
      baseUrl: MEDLOG_PDF_URL,
      orgId,
      fId: facilityId,
      pId: personId,
    }),
    target: '_blank',
    method: 'POST',
  })

export async function fetchMedListPdf({
  orgId,
  facilityId,
  personId,
  physicianReview = false,
}: {
  orgId: string
  facilityId: string
  personId: string
  physicianReview?: boolean
}): Promise<string> {
  const blob = await fetchBlobUrlAndContentType({
    url: getUrl({
      baseUrl: MEDLIST_PDF_URL,
      orgId,
      fId: facilityId,
      pId: personId,
      params: { physicianReview },
    }),
  })
  return blob.url
}
