import { download, getUrl } from '@shared/api/legacy'
import { FACESHEET_PDF_URL, FACILITY_FACESHEET_URL } from '@shared/api/person'
import { requestJson } from '@shared/api/request'
import { FacilityIds } from '@shared/types/facility'
import { Person } from '@shared/types/person'

export async function downloadFaceSheetPdf(person: Person): Promise<void> {
  const fileUrl = getUrl({
    baseUrl: FACESHEET_PDF_URL,
    orgId: person.orgId,
    fId: person.facilityId,
    pId: person.id,
  })

  await download({
    fileUrl,
    target: '_blank',
  })
}

export async function requestBulkFaceSheet(
  facility: FacilityIds
): Promise<void> {
  return await requestJson({
    url: getUrl({
      baseUrl: FACILITY_FACESHEET_URL,
      orgId: facility.orgId,
      fId: facility.id,
    }),
    method: 'POST',
  })
}
