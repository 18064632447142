import { ModalProps, ModalTitle } from '@shared/components/baseMui/Modal/Layout'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { twx } from '@shared/utils/tailwind'

type Props = Omit<ModalProps, 'open'> & {
  title: string
}

export default function BillingModal(props: Props) {
  const { children, contentClassName, title, ...restProps } = props

  return (
    <Modal
      contentClassName={twx('w-[600px] min-w-[600px]', contentClassName)}
      open
      {...restProps}
    >
      <ModalTitle>{title}</ModalTitle>
      {children}
    </Modal>
  )
}
