import { CSSProperties } from 'react'
import { ClearIndicatorProps } from 'react-select'
import Icon from '@shared/components/Icon'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'

/**
 * Same as default StyledSelect but with data-testid for tests
 */
export default function ClearIndicator(
  props: ClearIndicatorProps<OptionTypeBase, true>
) {
  const {
    getStyles,
    getValue,
    innerProps: { ref, ...restInnerProps },
  } = props
  const { value = '' } = getValue()[0] || {}

  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props) as CSSProperties}
      data-testid={`dropdown-option-x-icon-${value}`}
    >
      <Icon name="close" />
    </div>
  )
}
