import { createCtx } from '@shared/contexts/createCtx'
import { Facility } from '@shared/types/facility'
import { Incident } from '@shared/types/incidents'
import { Person } from '@shared/types/person'
import { UserAccount } from '@shared/types/user'

export interface IncidentCardContextData {
  facility: Facility
  incident: Incident
  person: Person
  user: UserAccount
}

export const [useIncidentCardContext, IncidentCardContextProvider] =
  createCtx<IncidentCardContextData>()
