import { Immunization } from '@augusthealth/models/com/august/protos/immunization'
import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { Person } from '@shared/types/person'
import { Snapshot } from '@shared/types/snapshot'
import AddImmunization, { ErrorType } from './AddImmunization'
import ImmunizationInfo from './ImmunizationInfo'

export default function FormContent({
  attachment,
  diseaseName,
  immunizations,
  person,
  onAdd,
  onDelete,
  onDateChange,
  errorType,
  onRemoveSnapshotId,
  onAddAttachment,
  switchAttachment,
}: {
  attachment?: Snapshot
  diseaseName: string
  immunizations: Immunization[]
  person: Person
  onAdd: (immu: Immunization) => void
  onDelete: (immu: Immunization) => void
  onDateChange?: (d: Date | null) => void
  errorType?: ErrorType
  onRemoveSnapshotId?: (immu: Immunization) => void
  onAddAttachment?: (files: FileList, immuId: Immunization) => void
  switchAttachment?: (snapshotId?: string) => void
}) {
  return (
    <div>
      <PersonPermissionGate
        person={person}
        permissions={[GroupPermission.GROUP_PERMISSION_SNAPSHOT_UPDATE]}
      >
        <AddImmunization
          diseaseName={diseaseName}
          onAdd={onAdd}
          onDateChange={onDateChange}
          errorType={errorType}
        />
      </PersonPermissionGate>
      <ol>
        {immunizations.map((immu, index) => {
          const { id: immuId, attachmentSnapshotId } = immu
          return (
            <li key={`immu-li-${immuId}-${index}`}>
              <ImmunizationInfo
                person={person}
                immunization={immu}
                onDelete={onDelete}
                onRemoveSnapshotId={onRemoveSnapshotId}
                onAddAttachment={onAddAttachment}
                selected={Boolean(
                  attachment?.id && attachment.id === attachmentSnapshotId
                )}
                switchAttachment={switchAttachment}
              />
            </li>
          )
        })}
      </ol>
    </div>
  )
}
