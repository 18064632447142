import { HTMLProps } from 'react'
import { twx } from '@shared/utils/tailwind'
import tableStyles from './table.module.css'

export function Table(props: HTMLProps<HTMLTableElement>) {
  const { children, className, ...tableProps } = props
  return (
    <table className={twx(tableStyles.table, className)} {...tableProps}>
      {children}
    </table>
  )
}

export function TableContainer(
  props: HTMLProps<HTMLDivElement> & {
    isEmpty?: boolean
    withScrollBar?: boolean
  }
) {
  const { children, className, isEmpty, withScrollBar, ...divProps } = props
  const content = (
    <div
      className={twx(
        'min-h-0 animate-fade-in rounded-[8px] bg-white p-[8px] shadow-card',
        { 'grow-0': isEmpty },
        className
      )}
      {...divProps}
    >
      {children}
    </div>
  )

  if (withScrollBar) {
    return (
      <div
        className={twx(
          'max-h-full overflow-y-scroll rounded-[8px] bg-white',
          'hover:max-h-auto hover:overflow-y-visible'
        )}
      >
        {content}
      </div>
    )
  }

  return content
}

export function TrWithHoverEffect(
  props: HTMLProps<HTMLTableRowElement> & {
    noBottomLine?: boolean
    disableHoverEffect?: boolean
  }
) {
  const {
    children,
    className,
    disableHoverEffect,
    noBottomLine = false,
    ...trProps
  } = props

  return (
    <tr
      className={twx(
        {
          'hover:cursor-pointer hover:bg-gray-13': !disableHoverEffect,
          [tableStyles.mainRowBottomLine]: !noBottomLine,
        },
        className
      )}
      {...trProps}
    >
      {children}
    </tr>
  )
}

export function TdWithExtraPadding(props: HTMLProps<HTMLTableCellElement>) {
  const { children, className, ...tdProps } = props
  return (
    <td
      className={twx(
        'p-[14px] align-middle text-[14px] font-medium',
        className
      )}
      {...tdProps}
    >
      {children}
    </td>
  )
}

export function NameTd(props: HTMLProps<HTMLTableCellElement>) {
  const { children, className, ...tdProps } = props
  return (
    <TdWithExtraPadding
      className={twx('font-semibold', className)}
      {...tdProps}
    >
      {children}
    </TdWithExtraPadding>
  )
}
