import { History } from 'history'
import { cloneDeep } from 'lodash'
import { ApiResponse } from '@shared/api/response'
import { fetchTasks, onViewCompletedTask } from '@shared/api/task'
import { getTaskUrl } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { DataType, Signer, SignerRole } from '@shared/types/snapshot'
import {
  SignatureInfo,
  SignatureStatus,
  StateMachineType,
  Task,
  TaskStatus,
  TaskTemplateInfo,
  TaskType,
} from '@shared/types/task'
import { UserAccount } from '@shared/types/user'
import { convertEnumValueToLabel } from '@shared/utils/common'
import { signaturesAreProcessing } from '@shared/utils/signature'
import { StateName } from '@shared/utils/state'
import { isAdmin, userMatchesSigner } from '@shared/utils/user'

export type CreateUpdateResponse = {
  data?: {
    id?: number | string
  }
  meta: {
    hello: 'Created' | 'Updated'
  }
}

type Props = {
  pId?: string
  task: Task
  user?: UserAccount
}

export type TaskSuppressionArgs = {
  facilityId: string | null
  orgId: string | null
  state: StateName | null
}
export const TASK_STATUS_COMPLETE: TaskStatus[] = [
  TaskStatus.TASK_STATUS_COMPLETE,
  TaskStatus.TASK_STATUS_COMPLETE_BUT_NEW,
  TaskStatus.TASK_STATUS_MANUALLY_COMPLETED,
]

export function getIsImmunization(task: Task) {
  return (
    task.taskTemplateInfo.dataType === DataType.DATA_TYPE_IMMUNIZATION_RECORD &&
    task.taskTemplateInfo.taskType === TaskType.TASK_TYPE_IMMUNIZATION_RECORD
  )
}

export function getIsComplete(task: Task) {
  return TASK_STATUS_COMPLETE.includes(task.status)
}

export function getCanEditAndReview({
  task,
  user,
  person,
}: Props & { person: Person }) {
  const { orgId: organizationId, facilityId } = person
  return (
    user &&
    isAdmin(user, { organizationId, facilityId }) &&
    task.status === TaskStatus.TASK_STATUS_AWAITING_REVIEW
  )
}

/**
 * Check if the task is awaiting signers.
 * If the task status is PENDING_SIGNATURES but all signature statuses are either signed or processing, returns false
 * @param {Task} task
 * @returns {boolean} true if the task is awaiting signers, false otherwise.
 */
export function awaitingSigners(task: Task): boolean {
  return Boolean(
    task.status === TaskStatus.TASK_STATUS_PENDING_SIGNATURES &&
      task.signatures?.some(
        (sig) => sig.status === SignatureStatus.SIGNATURE_STATUS_NOT_SIGNED
      )
  )
}

export function getCanReview({ task, user }: Props) {
  if (user === undefined) {
    return false
  }

  const isComplete = getIsComplete(task)
  const _nextSigner = nextSigner(task)
  const isNextSigner = _nextSigner && userMatchesSigner(user, _nextSigner)

  return (
    isComplete ||
    task.status === TaskStatus.TASK_STATUS_AWAITING_REVIEW ||
    (task.status === TaskStatus.TASK_STATUS_PENDING_SIGNATURES && !isNextSigner)
  )
}

export function rpCanAct(task: Task) {
  return (
    task.status === TaskStatus.TASK_STATUS_ASSIGNED_TO_RP ||
    nextSigner(task)?.role === SignerRole.SIGNER_ROLE_RESPONSIBLE_PARTY
  )
}

export function visibleToRP(task: Task) {
  return task.status === TaskStatus.TASK_STATUS_ASSIGNED_TO_RP
}

export function shouldRedirectToSignPage({
  task,
  user,
}: Props & { facilityId: string; orgId: string }) {
  if (!task.taskTemplateInfo.isFile) {
    return false
  }

  const _nextSigner = nextSigner(task)

  return (
    task.status === TaskStatus.TASK_STATUS_PENDING_SIGNATURES &&
    user &&
    _nextSigner &&
    userMatchesSigner(user, _nextSigner)
  )
}

const DATA_TYPE_TITLE_HASH: Record<string, string> = {
  [DataType.DATA_TYPE_PRN_AUTHORIZATION]: 'PRN Authorization',
  [DataType.DATA_TYPE_MEDICAL_RECORD]: 'Medical Records',
  [DataType.DATA_TYPE_DIRECTIVE]: 'Advanced Directives and Power of Attorney',
  [DataType.DATA_TYPE_INSURANCE_CARD]: 'Insurance Card',
  [DataType.DATA_TYPE_OTHER_UPLOAD]: 'Other Documents',
  [DataType.DATA_TYPE_IMMUNIZATION_RECORD]: 'Immunization Record',
  // DataType mapping below are kept for backward compatiblity
  // Task Title should be read from task.taskTemplateInfo.displayName instead
  [DataType.DATA_TYPE_GETTING_TO_KNOW_YOU]: 'Getting to Know You (Basic)',
  [DataType.DATA_TYPE_GETTING_TO_KNOW_YOU_V2]:
    'Getting to Know You (Comprehensive)',
  [DataType.DATA_TYPE_CA_FORM_601]: 'Identification and Emergency Information',
  [DataType.DATA_TYPE_CA_FORM_602]: "Physician's Report",
  [DataType.DATA_TYPE_CA_FORM_603]: 'Pre-Placement Appraisal',
  [DataType.DATA_TYPE_CA_FORM_603A]: 'Resident Appraisal',
  [DataType.DATA_TYPE_POLST]: 'POLST Form',
  [DataType.DATA_TYPE_LEVEL_OF_CARE_CIMINO]: 'Level of Care',
  [DataType.DATA_TYPE_AUGUST_INITIAL_APPRAISAL]: 'Resident Assessment',
  [DataType.DATA_TYPE_SERVICE_PLAN]: 'Service Plan',
  [DataType.DATA_TYPE_ADMISSIONS_AGREEMENT]: 'Admissions Agreement',
  [DataType.DATA_TYPE_CA_CONSENT_FORMS]: 'Consent Forms',
}

/**
 * To be used as a last resort. Titles/subtitles should come from
 * the corresponding Task.TaskTemplateInfo
 * Certain data types don't have associated tasks: advanced directives,
 * medical records, insurance cards: for those, we can use this function.
 * @param dataType
 */
export function getTitleFromDataType(dataType: DataType) {
  if (DATA_TYPE_TITLE_HASH[dataType]) {
    return DATA_TYPE_TITLE_HASH[dataType]
  }

  return convertEnumValueToLabel(dataType.replace('DATA_TYPE_', ''))
}

export function taskTitle(task: Task | TaskTemplateInfo): string {
  if ('taskTemplateInfo' in task) {
    return task.taskTemplateInfo.displayName ?? ''
  } else if ('displayName' in task) {
    return task.displayName ?? ''
  }

  return ''
}

export function taskSubtitle(
  task: Task | TaskTemplateInfo
): string | undefined {
  if ('taskTemplateInfo' in task) {
    return task.taskTemplateInfo.shortName
  } else if ('displayName' in task) {
    return task.shortName
  }

  return undefined
}

export function getTaskLastModification(task: Task) {
  const { statusHistory, lastModification } = task
  return (
    lastModification ||
    (Array.isArray(statusHistory) && statusHistory[statusHistory.length - 1])
  )
}

export function isCustomTask(task: Task) {
  return (
    task.taskTemplateInfo.taskType === TaskType.TASK_TYPE_CUSTOM_SIGNABLE_FORM
  )
}

const TASK_TYPE_ORDER_HASH: Record<string, number> = {
  [TaskType.TASK_TYPE_GETTING_TO_KNOW_YOU]: 1,
  [TaskType.TASK_TYPE_CA_FORM_601]: 2,
  [TaskType.TASK_TYPE_CA_FORM_602]: 3,
  [TaskType.TASK_TYPE_CA_FORM_603]: 4,
  [TaskType.TASK_TYPE_CA_FORM_603A]: 4,
  [TaskType.TASK_TYPE_POLST]: 5,
  [TaskType.TASK_TYPE_LEVEL_OF_CARE]: 6,
  [TaskType.TASK_TYPE_AUGUST_INITIAL_APPRAISAL]: 7,
  [TaskType.TASK_TYPE_ADMISSIONS_AGREEMENT]: 8,
  [TaskType.TASK_TYPE_MEDICAL_RECORD]: 9,
  [TaskType.TASK_TYPE_DIRECTIVE]: 10,
  [TaskType.TASK_TYPE_INSURANCE_CARD]: 11,
  [TaskType.TASK_TYPE_OTHER_UPLOAD]: 12,
  [TaskType.TASK_TYPE_CA_CONSENT_FORMS]: 13,
  [TaskType.TASK_TYPE_SERVICE_PLAN]: 15,
  [TaskType.TASK_TYPE_CUSTOM_SIGNABLE_FORM]: 16,
  [TaskType.TASK_TYPE_NURSE_DELEGATION]: 17,
}

export function taskOrder(task: Task) {
  const taskType = task.taskTemplateInfo.taskType
  if (taskType && TASK_TYPE_ORDER_HASH[taskType]) {
    return TASK_TYPE_ORDER_HASH[taskType]
  }

  return 14 // default order
}

export function incompleteTask(
  tasks: Task[] | undefined,
  dataType: DataType,
  customType?: string
) {
  return (tasks || []).find((t) => {
    if (customType) {
      return (
        t.taskTemplateInfo.customType === customType &&
        t.taskTemplateInfo.dataType === dataType &&
        isIncomplete(t)
      )
    }

    return t.taskTemplateInfo.dataType === dataType && isIncomplete(t)
  })
}

export const isIncomplete = (task: Task) =>
  !getIsComplete(task) && task.status !== TaskStatus.TASK_STATUS_MANUALLY_CLOSED

export const isClosed = (task: Task) =>
  task.status === TaskStatus.TASK_STATUS_MANUALLY_CLOSED

export function nextSigner(task: Task): Signer | undefined {
  const signatureData = task.signatures || []

  if (
    signatureData.some(
      (s) => s.status === SignatureStatus.SIGNATURE_STATUS_PROCESSING
    )
  ) {
    return undefined
  }

  return signatureData.find(
    (sigInfo) => sigInfo.status === SignatureStatus.SIGNATURE_STATUS_NOT_SIGNED
  )?.signer
}

export function isAwaitingReview(task: Task) {
  return task.status === TaskStatus.TASK_STATUS_AWAITING_REVIEW
}

export function isPendingSignatures(task: Task) {
  return task.status === TaskStatus.TASK_STATUS_PENDING_SIGNATURES
}

export function areExternalSignersNext(task: Task) {
  const signer = nextSigner(task)
  return signer && signer.role === SignerRole.SIGNER_ROLE_EXTERNAL
}

export const isUserAmongExternals = (user: UserAccount, task: Task) => {
  const signatures = task.signatures || []
  return signatures.some(
    (x) =>
      userMatchesSigner(user, x.signer) &&
      x.status === SignatureStatus.SIGNATURE_STATUS_NOT_SIGNED
  )
}

/**
 * Some tasks that are half-signed (by the admin only) can still be completed.
 * @param task
 * @param user
 */
export function getCanCompleteWithoutAllSignatures({
  task,
  user,
  person,
}: {
  task: Task
  user: UserAccount
  person: Person
}) {
  const { taskTemplateInfo = {}, status, signatures = [] } = task
  const { taskType } = taskTemplateInfo
  const isSignedByAdmin = signatures.some(
    (sigInfo) =>
      sigInfo.signer?.role === SignerRole.SIGNER_ROLE_ADMIN &&
      sigInfo.status === SignatureStatus.SIGNATURE_STATUS_SIGNED
  )
  const { orgId: organizationId, facilityId } = person

  return (
    isAdmin(user, { organizationId, facilityId }) &&
    isSignedByAdmin &&
    status === TaskStatus.TASK_STATUS_PENDING_SIGNATURES &&
    taskType === TaskType.TASK_TYPE_SERVICE_PLAN
  )
}

export function redirectToTask({
  facilityId,
  task,
  history,
  user,
  openUploadPopup,
  setOpenReviewLightbox,
  setOpenProcessingPopup,
  setTasks,
  setError,
}: {
  facilityId: string
  task: Task
  history: History
  user: UserAccount
  openUploadPopup: (task: Task) => void
  setOpenReviewLightbox: (open: boolean) => void
  setOpenProcessingPopup: (open: boolean) => void
  setTasks: (tasks: Task[]) => void
  setError: (error: unknown) => void
}) {
  const { personId: pId, orgId, id: taskId } = task
  const isComplete = getIsComplete(task)
  const reviewUrl = getTaskUrl({
    facilityId,
    personId: pId || '',
    orgId: orgId || '',
    id: taskId,
    page: 'review',
  })
  const goReview = () => {
    if (typeof setOpenReviewLightbox === 'function') {
      setOpenReviewLightbox(true)
    } else {
      history.push(reviewUrl)
    }
  }

  if (isComplete) {
    // Let the server know that the task has been viewed.
    // We don't need to wait for this response in order to update the UI.
    // Similarly, if there's an error, the user doesn't need to know about it.
    void onViewCompletedTask({
      pId: pId || '',
      facilityId,
      orgId: orgId || '',
      taskId: taskId || '',
    })
  }

  if (signaturesAreProcessing(task)) {
    if (typeof setOpenProcessingPopup === 'function') {
      // Get latest task to see if PROCESSING status has been updated
      void fetchTasks({ pId: pId || '', orgId: orgId || '' })
        .then((res: ApiResponse) => {
          const newTaskList = res.data as Task[]
          const newTask = newTaskList.find((item) => item.id === taskId)
          if (newTask) {
            if (signaturesAreProcessing(newTask)) {
              // If not updated yet, show HelloSign Processing popup
              setOpenProcessingPopup(true)
            } else {
              // Otherwise, update Tasks in Context and redirect to Review lightbox with singer info
              setTasks(newTaskList)
              // reuse redirectToTask again to redirect to sign page
              redirectToTask({
                facilityId,
                history,
                user,
                openUploadPopup,
                setOpenReviewLightbox,
                setOpenProcessingPopup,
                setTasks,
                setError,
                task: newTask,
              })
            }
          } else {
            setError({
              json: {
                errors: [
                  {
                    code: 'ERROR_CODE_DISPLAY_TO_CLIENT',
                    message: 'Task not found',
                  },
                ],
              },
            })
          }
        })
        .catch(setError)
    }
  } else if (getCanReview({ task, user })) {
    goReview()
  } else if (
    shouldRedirectToSignPage({
      pId,
      task,
      user,
      facilityId,
      orgId: orgId || '',
    })
  ) {
    goReview()
  } else if (getIsImmunization(task) || task.taskTemplateInfo.isUpload) {
    // 4. Launch Upload Popup
    openUploadPopup(task)
  } else {
    // 5. GO TO FORM
    if (taskId) {
      history.push(
        getTaskUrl({
          facilityId,
          personId: pId || '',
          orgId: orgId || '',
          id: taskId,
        })
      )
    } else {
      console.error('TaskId is missing')
    }
  }
}

export function signatureInfoForUser({
  user,
  currentAdminSigners,
}: {
  user: UserAccount
  currentAdminSigners: SignatureInfo[]
}) {
  return currentAdminSigners.find(
    (sig) =>
      sig.signer?.role === SignerRole.SIGNER_ROLE_ADMIN &&
      userMatchesSigner(user, sig.signer)
  )
}

export function rpCanCreateSignaturePacket({ task }: { task: Task }) {
  return (
    task.signatures === undefined &&
    task.taskTemplateInfo.stateMachineType ===
      StateMachineType.STATE_MACHINE_TYPE_SHAREABLE_AND_SIGNABLE_NO_REVIEW
  )
}

function sortTasks(task1: Task, task2: Task) {
  return taskTitle(task1).localeCompare(taskTitle(task2), 'default', {
    numeric: true,
  })
}

/**
 * Sort tasks by `taskTitle`, return in a new array
 */
export function getSortedTasks(tasks: Task[]) {
  return cloneDeep(tasks).sort(sortTasks)
}
