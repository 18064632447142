import { faker } from '@faker-js/faker'
import { Factory } from 'fishery'
import {
  ContactPoint,
  ContactPoint_ContactPointSystem,
  ContactPoint_ContactPointUse,
} from '@shared/types/contact_point'

export const contactPointFactory = Factory.define<ContactPoint>(
  (): ContactPoint => ({
    system: faker.helpers.enumValue(ContactPoint_ContactPointSystem),
    value: faker.phone.number(),
    use: faker.helpers.enumValue(ContactPoint_ContactPointUse),
    rank: faker.number.int(),
  })
)

export const emailContactPointFactory = contactPointFactory.params({
  system: ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_EMAIL,
  value: faker.internet.email(),
})

export const phoneContactPointFactory = contactPointFactory.params({
  system: ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_PHONE,
  value: faker.phone.number(),
})
