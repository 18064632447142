import { ChangeEvent, useState } from 'react'
import {
  BasicCheckbox,
  BasicInput,
} from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput } from '@shared/components/Labels'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import { Snapshot, SnapshotStatus } from '@shared/types/snapshot'
import { GtkyFields, GtkySnapshotPatch } from '@shared/utils/gtky'
import { ButtonGroup } from '@app/components/ButtonGroup'
import { dayOfWeekOptions } from '@app/pages/Routines/constants'
import LongformAnswer from '@app/pages/Tasks/GettingToKnowYouV2/components/LongformAnswer'
import styles from './gtky.module.css'

type Props = {
  updateSnapshot: ({
    patch,
    status,
  }: {
    patch: GtkySnapshotPatch | null
    status: SnapshotStatus
  }) => Promise<void>
  snapshot: Snapshot
  isEditing?: boolean
}

export default function RoutinesPage({
  updateSnapshot,
  snapshot,
  isEditing = false,
}: Props) {
  const [fields, setFields] = useState<GtkySnapshotPatch>({})

  const disabled = !isEditing

  const saveSnapshot = async (formValues = fields) =>
    await updateSnapshot({ patch: formValues, status: snapshot.status! })

  return (
    <>
      <div>
        <LabelAboveInput htmlFor={GtkyFields.TYPICAL_DAY} uppercase={false}>
          Describe your typical day - what do you do each
          morning/afternoon/evening/bedtime? Please be sure to address eating,
          rest periods, activities, and outdoor periods.
        </LabelAboveInput>
        <LongformAnswer
          mode={isEditing ? 'edit' : 'view'}
          disabled={disabled}
          onBlur={async () => await saveSnapshot()}
          id={GtkyFields.TYPICAL_DAY}
          name={GtkyFields.TYPICAL_DAY}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            setFields({
              ...fields,
              [GtkyFields.TYPICAL_DAY]: e.target.value,
            })
          }}
          defaultValue={
            snapshot!.data?.formData?.fields?.[GtkyFields.TYPICAL_DAY] || ''
          }
        />
      </div>

      <div>
        <LabelAboveInput htmlFor={GtkyFields.BATHING_DAYS} uppercase={false}>
          What days/time do you prefer for bathing/showering?
        </LabelAboveInput>

        <div className={`flex flex-row`}>
          <ButtonGroup
            containerClassName={'mr-[24px]'}
            disabled={disabled}
            prependTestId={'bathing'}
            onChange={async (selected) => {
              const selectedArray = selected.map((s) => s.value)
              const updatedFields = {
                ...fields,
                [GtkyFields.BATHING_DAYS]: selectedArray,
              }
              setFields(updatedFields)
              await saveSnapshot(updatedFields)
            }}
            options={dayOfWeekOptions}
            chosen={
              snapshot!.data?.formData?.oneToManyFields?.[
                GtkyFields.BATHING_DAYS
              ]?.values
                ? (snapshot.data.formData.oneToManyFields[
                    GtkyFields.BATHING_DAYS
                  ].values!.map((d) => {
                    return dayOfWeekOptions.find(({ value }) => value === d)
                  }) as OptionTypeBase[])
                : []
            }
          />
          <BasicInput
            type={'time'}
            id={GtkyFields.BATHING_TIME}
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              setFields({
                ...fields,
                [GtkyFields.BATHING_TIME]: e.target.value,
              })
            }}
            onBlur={async () => await saveSnapshot()}
            defaultValue={
              snapshot!.data?.formData?.fields?.[GtkyFields.BATHING_TIME] || ''
            }
            disabled={disabled}
          />
        </div>
      </div>

      <div>
        <LabelAboveInput htmlFor={GtkyFields.BATHING_METHOD} uppercase={false}>
          What bathing method do you prefer?
        </LabelAboveInput>
        <div className={'flex flex-row'}>
          {['shower', 'bath', 'sponge bath'].map((method) => {
            return (
              <BasicCheckbox
                key={`bathing-${method}`}
                labelClassName={styles.bathingMethod}
                disabled={disabled}
                name={GtkyFields.BATHING_METHOD}
                value={'shower'}
                defaultChecked={snapshot.data?.formData?.oneToManyFields?.[
                  GtkyFields.BATHING_METHOD
                ]?.values?.includes(method)}
                onChange={async (e) => {
                  // @ts-ignore
                  const updatedBathingMethod: string[] = fields?.[
                    GtkyFields.BATHING_METHOD
                  ]
                    ? fields[GtkyFields.BATHING_METHOD]
                    : []

                  if (e.currentTarget.checked) {
                    if (updatedBathingMethod.includes(method)) {
                      return
                    } else {
                      updatedBathingMethod.push(method)
                    }
                  } else {
                    const indexOfValue = updatedBathingMethod.indexOf(method)
                    if (indexOfValue > -1) {
                      delete updatedBathingMethod[indexOfValue]
                    }
                  }
                  const updatedFields = {
                    ...fields,
                    [GtkyFields.BATHING_METHOD]: updatedBathingMethod.filter(
                      (v) => v
                    ),
                  }
                  setFields(updatedFields)
                  await saveSnapshot(updatedFields)
                }}
              >
                {method}
              </BasicCheckbox>
            )
          })}
        </div>
      </div>

      <div>
        <LabelAboveInput
          htmlFor={GtkyFields.DAYTIME_DRESSING}
          uppercase={false}
        >
          What are your daytime dressing preferences?{' '}
          <em>i.e. casual, jewelry, suit/tie, dress, etc.</em>
        </LabelAboveInput>
        <BasicInput
          disabled={disabled}
          onBlur={async () => await saveSnapshot()}
          id={GtkyFields.DAYTIME_DRESSING}
          name={GtkyFields.DAYTIME_DRESSING}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFields({
              ...fields,
              [GtkyFields.DAYTIME_DRESSING]: e.target.value,
            })
          }}
          defaultValue={
            snapshot!.data?.formData?.fields?.[GtkyFields.DAYTIME_DRESSING] ||
            ''
          }
        />
      </div>
      <div>
        <LabelAboveInput
          htmlFor={GtkyFields.NIGHTTIME_DRESSING}
          uppercase={false}
        >
          What are your nighttime dressing preferences?{' '}
          <em>i.e. prefers to sleep in a a gown</em>
        </LabelAboveInput>
        <BasicInput
          disabled={disabled}
          onBlur={async () => saveSnapshot()}
          id={GtkyFields.NIGHTTIME_DRESSING}
          name={GtkyFields.NIGHTTIME_DRESSING}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFields({
              ...fields,
              [GtkyFields.NIGHTTIME_DRESSING]: e.target.value,
            })
          }}
          defaultValue={
            snapshot!.data?.formData?.fields?.[GtkyFields.NIGHTTIME_DRESSING] ||
            ''
          }
        />
      </div>

      <div>
        <LabelAboveInput htmlFor={GtkyFields.OTHER_ROUTINES} uppercase={false}>
          What else would you like us to know about you and your routines?
        </LabelAboveInput>
        <LongformAnswer
          mode={isEditing ? 'edit' : 'view'}
          disabled={disabled}
          onBlur={async () => await saveSnapshot()}
          id={GtkyFields.OTHER_ROUTINES}
          name={GtkyFields.OTHER_ROUTINES}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            setFields({
              ...fields,
              [GtkyFields.OTHER_ROUTINES]: e.target.value,
            })
          }}
          defaultValue={
            snapshot!.data?.formData?.fields?.[GtkyFields.OTHER_ROUTINES] || ''
          }
        />
      </div>
    </>
  )
}
