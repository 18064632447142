import Badge from '@shared/components/Badge'
import Card from '@shared/components/Card'
import { iconForChargeType } from '@shared/utils/charge'
import styles from './recurringCard.module.css'
import { RecurringCharge } from './RecurringCharge'

interface Props {
  charge: RecurringCharge
  onDelete: (charge: RecurringCharge) => void
  onChangeRate: (charge: RecurringCharge) => void
  onCancelScheduledRate: (charge: RecurringCharge, rateIndex: number) => void
}

export default function RecurringChargeCard({
  charge,
  onDelete,
  onChangeRate,
  onCancelScheduledRate,
}: Props) {
  return (
    <Card className="mb-[12px]">
      <i
        className={`fas ${iconForChargeType(charge.type)} fa-lg ${
          styles.cardIcon
        }`}
      />
      <div className={styles.cardContent}>
        <h3 className={styles.cardTitle}>{charge.title}</h3>
        <div className={styles.notes}>{charge.subtitle}</div>
        <div className={styles.badgeContainer}>
          <Badge color="gray">{charge.payer}</Badge>
          <Badge color="gray">{charge.recurrence}</Badge>
        </div>
        <div className={styles.dateBox}>
          {charge.rates.map((rate, ix) => {
            const style = rate.inPast ? styles.past : styles.upcoming
            const icon = rate.inPast ? 'fa-check-square' : 'fa-calendar-alt'
            const topMargin = ix === 0 ? '' : 'mt-[8px]'
            const isCancellable = !rate.inPast && !rate.isInitialRate

            return (
              <div
                className={`${styles.eventRow} ${style} ${topMargin}`}
                key={ix}
              >
                <i className={`far fa-fw ${icon} mr-[8px]`} />
                <div className={styles.eventDate}>{rate.date}</div>
                <div className={styles.eventDescription}>
                  {rate.description}
                  {isCancellable && (
                    <>
                      <span className={styles.spacerSmall}>·</span>
                      <button
                        className={styles.cancelBtn}
                        onClick={() => {
                          onCancelScheduledRate(charge, ix)
                        }}
                      >
                        Cancel
                      </button>
                    </>
                  )}
                </div>
              </div>
            )
          })}
        </div>
        <div className={styles.ctaSection}>
          <button
            className={styles.deleteBtn}
            onClick={() => {
              onDelete(charge)
            }}
          >
            <i className="far fa-fw fa-trash-can" /> Delete
          </button>
          <span className={styles.spacer}> · </span>
          <button
            id={`schedule-change-${charge.id}`}
            className={styles.deleteBtn}
            onClick={() => {
              onChangeRate(charge)
            }}
          >
            <i className="far fa-fw fa-calendar-alt" /> Schedule Rate Change
          </button>
        </div>
      </div>
      <div className={styles.amount}>{charge.amount}</div>
    </Card>
  )
}
