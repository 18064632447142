import { useState } from 'react'
import { AsyncIconButton } from '@shared/components/AsyncButton'

type ActionButtonProps = {
  sendStatementFn: () => Promise<void>
  disabled: boolean
}

export function ActionButtons({
  sendStatementFn,
  disabled,
}: ActionButtonProps) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  async function issueInvoice() {
    setIsSubmitting(true)
    await sendStatementFn()
    setIsSubmitting(false)
  }

  return (
    <div className="flex justify-center">
      <AsyncIconButton
        onClick={issueInvoice}
        buttonSize="medium"
        buttonStyle="primary-fill"
        isLoading={isSubmitting}
        disabled={isSubmitting || disabled}
        className="w-full"
      >
        Issue statement
      </AsyncIconButton>
    </div>
  )
}
