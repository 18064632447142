import {
  Parameter,
  ParameterConditional,
  ParameterType,
} from '@augusthealth/models/com/august/protos/dosage'

export {
  type Range,
  type Ratio,
  type DosageV2,
  type DoseAndRate,
  type Parameter,
  ParameterType,
  ParameterConditional,
  type SlidingScale,
  type SimpleQuantity,
  type SlidingScaleEntry,
} from '@augusthealth/models/com/august/protos/dosage'

export type ValidParameterConditional = Exclude<
  ParameterConditional,
  | ParameterConditional.UNRECOGNIZED
  | ParameterConditional.PARAMETER_CONDITIONAL_UNSPECIFIED
>
export type ValidParameterType = Exclude<
  ParameterType,
  ParameterType.UNRECOGNIZED | ParameterType.PARAMETER_TYPE_UNSPECIFIED
>

export const NEW_PARAMETER: Parameter = {
  parameterType: undefined,
  conditional: undefined,
  numeric: {
    value: undefined,
    unit: undefined,
  },
}
