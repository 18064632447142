import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import {
  MouseEventHandler,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import Badge from '@shared/components/Badge'
import ButtonLink from '@shared/components/ButtonLink'
import Card from '@shared/components/Card'
import { SmallDotDivider } from '@shared/components/DotDivider'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { getTaskUrl } from '@shared/legacy_routes'
import { DataType } from '@shared/types/snapshot'
import { TaskType } from '@shared/types/task'
import { humanizeReason } from '@shared/utils/assessmentConfiguration'
import { formatTimeAgo } from '@shared/utils/date'
import { getFullName } from '@shared/utils/humanName'
import {
  getIsCompleteOrProcessing,
  showSignInPersonLink,
  signaturesAreProcessing,
} from '@shared/utils/signature'
import {
  getCanCompleteWithoutAllSignatures,
  getTaskLastModification,
  nextSigner,
  redirectToTask,
  taskSubtitle,
  taskTitle,
} from '@shared/utils/task'
import { isResponsiblePerson } from '@shared/utils/user'
import { getResidentAssessment } from '@app/api/resident_assessment'
import Review from '@app/components/Prospects/Review'
import MarkAsDoneModal from '@app/components/Prospects/Tasks/popups/MarkAsDoneModal'
import Why from '@app/components/Prospects/Tasks/popups/Why'
import FacilitiesContext from '@app/contexts/FacilitiesContext'
import PersonContext from '@app/contexts/PersonContext'
import styles from './styles.module.css'
import { displayDataForTask } from '../tasksConfig'
import ProcessingPopup from './popups/ProcessingPopup'
import { ItemProps, MarkAsDoneProps } from './type'

export default function TaskItem({
  task,
  refreshTasks,
  openUploadPopup,
  animationStyle,
}: ItemProps) {
  const [openProcessingPopup, setOpenProcessingPopup] = useState(false)
  const [openReviewLightbox, setOpenReviewLightbox] = useState(false)
  const [openWhyPopup, setOpenWhyPopup] = useState<TaskType>()
  const [openMarkAsDoneData, setOpenMarkAsDoneData] =
    useState<MarkAsDoneProps>()
  const [assessmentReason, setAssessmentReason] = useState('')
  const { setError } = useContext(GlobalContext)
  const { person, setTasks, setSignInPersonPopupTask } =
    useContext(PersonContext)
  const { user } = useUserContext()
  const { facilities = [] } = useContext(FacilitiesContext)
  const { id: taskId } = task
  const title = taskTitle(task)
  const subTitle = taskSubtitle(task)
  const history = useHistory()

  useEffect(() => {
    if (
      person &&
      hasPermissionForPerson({
        user,
        person,
        permissions: [GroupPermission.GROUP_PERMISSION_SNAPSHOT_READ],
      }) &&
      task?.taskTemplateInfo?.dataType ===
        DataType.DATA_TYPE_AUGUST_INITIAL_APPRAISAL
    ) {
      void getResidentAssessment({ person }).then(
        ({ appraisal: { assessmentReason } = {} }) => {
          if (assessmentReason) {
            const humanReason = humanizeReason(assessmentReason)
            if (humanReason) {
              setAssessmentReason(humanReason)
            }
          }
        }
      )
    }
  }, [person?.id])

  if (!person) {
    return null
  }

  const { facilityId, orgId, id: personId } = person
  const currentFacility = facilities.find((f) => f.id === facilityId)
  const isRP = isResponsiblePerson({ user, orgId, facilityId, personId })
  const uploadable = Boolean(task.taskTemplateInfo?.isUpload)
  const taskType = task.taskTemplateInfo?.taskType
  const lastHistory = getTaskLastModification(task)
  const displayData = displayDataForTask({ task, user, person })
  let statusLabel = '',
    statusLink: ReactNode = null,
    actionLabel = '',
    formattedDate = '',
    modifiedBy = ''
  if (displayData) {
    const { actionTextTemplate, statusTextTemplate } = displayData

    if (uploadable) {
      actionLabel =
        actionTextTemplate.uploadable || actionTextTemplate.default || ''
      statusLabel =
        statusTextTemplate.uploadable || statusTextTemplate.default || ''
    } else {
      actionLabel = actionTextTemplate.default || ''
      statusLabel = statusTextTemplate.default || ''
    }
  }

  if (lastHistory) {
    const { modificationTime, modifiedByUserName } = lastHistory
    modifiedBy = getFullName(modifiedByUserName)
    if (modificationTime) {
      formattedDate = formatTimeAgo(modificationTime)
    }
  }

  if (showSignInPersonLink({ person, task, user })) {
    statusLink = (
      <ButtonLink
        className="font-semibold"
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
          setSignInPersonPopupTask({
            task,
            onComplete: () => {},
            ...(nextSigner(task) ? { email: nextSigner(task)?.email } : {}),
          })
        }}
        disabled={signaturesAreProcessing(task)}
      >
        Sign in-person now
      </ButtonLink>
    )
  }
  if (task.taskTemplateInfo?.descriptionCopy) {
    statusLink = (
      <ButtonLink
        className="font-semibold"
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
          setOpenWhyPopup(taskType)
        }}
      >
        Download & Learn More
      </ButtonLink>
    )
  }

  statusLabel = statusLabel.replace('$modifiedByUserName', modifiedBy)

  const onLinkClick: MouseEventHandler<HTMLAnchorElement> = (ev) => {
    ev.preventDefault()
    redirectToTask({
      facilityId: facilityId || '',
      history,
      task,
      openUploadPopup,
      user,
      setError,
      setOpenProcessingPopup,
      setOpenReviewLightbox,
      setTasks,
    })
  }
  const taskUrl = getTaskUrl({
    facilityId: facilityId || '',
    personId: personId || '',
    orgId: orgId || '',
    id: taskId,
  })
  const canCompleteWithoutAllSignatures = getCanCompleteWithoutAllSignatures({
    task,
    user,
    person,
  })
  const card = (
    <a
      data-testid={`task-id-${taskId}-card`}
      className={styles.link}
      href={taskUrl}
      onClick={onLinkClick}
      onKeyUp={(event) => {
        event.preventDefault()
        if (event.key === 'Enter') {
          onLinkClick(event as never)
        }
      }}
    >
      <Card
        style={animationStyle}
        className={`${styles.card} ${styles.slideIn}`}
      >
        <div className={styles.titleRow}>
          <section className="flex items-center">
            <i
              className={`fa-clipboard-list-check fa-fw fa-regular ${styles.icon}`}
            />
            <div className="flex-col justify-between">
              <div className={styles.titleText}>
                <h3 className="mb-0">{title}</h3>
                <h4 className="mb-0 ml-[8px]">{subTitle}</h4>
              </div>
              <div className={styles.subtitle}>
                {assessmentReason && (
                  <>
                    Assessment reason:{' '}
                    <span data-testid="assessment-reason">
                      {assessmentReason}
                    </span>
                  </>
                )}
                {!assessmentReason && (
                  <>
                    {statusLabel}
                    {formattedDate && <SmallDotDivider />}
                    {formattedDate}
                  </>
                )}
                {statusLink && <SmallDotDivider />}
                {statusLink}
                {canCompleteWithoutAllSignatures && <SmallDotDivider />}
                {canCompleteWithoutAllSignatures && (
                  <ButtonLink
                    className="font-semibold"
                    onClick={(event) => {
                      event.stopPropagation()
                      event.preventDefault()
                      setOpenMarkAsDoneData({ person, task })
                    }}
                  >
                    Mark as done
                  </ButtonLink>
                )}
              </div>
            </div>
          </section>
          {!isRP && displayData?.badgeText && (
            <Badge badgeSize="medium" color="purple">
              {displayData.badgeText}
            </Badge>
          )}
          {isRP &&
            displayData?.badgeText &&
            !getIsCompleteOrProcessing(task) && (
              <Badge badgeSize="medium" color="green">
                {displayData.badgeText}
              </Badge>
            )}
        </div>
        <div className={styles.action}>
          <span>{actionLabel}</span>
          <i className={'far fa-angle-right ml-[4px] text-[14px]'} />
        </div>
      </Card>
    </a>
  )

  let popup: React.ReactNode = null
  if (openReviewLightbox) {
    const reviewProps = {
      match: {
        params: {
          facilityId: facilityId!,
          id: personId!,
          orgId: orgId!,
          taskId,
        },
      },
      onClose: () => setOpenReviewLightbox(false),
    }
    popup = <Review {...reviewProps} />
  } else if (openProcessingPopup) {
    popup = (
      <ProcessingPopup
        task={task}
        onClose={() => setOpenProcessingPopup(false)}
      />
    )
  } else if (openWhyPopup) {
    popup = currentFacility ? (
      <Why
        person={person}
        task={task}
        currentFacility={currentFacility}
        onClose={() => setOpenWhyPopup(undefined)}
        onUpload={() => openUploadPopup(task)}
      />
    ) : (
      <></>
    )
  } else if (openMarkAsDoneData) {
    popup = (
      <MarkAsDoneModal
        {...openMarkAsDoneData}
        onClose={() => setOpenMarkAsDoneData(undefined)}
        onComplete={async () => await refreshTasks()}
      />
    )
  }

  return (
    <>
      {card}
      {popup}
    </>
  )
}
