import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { reversePayment } from '@shared/api/billing'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput } from '@shared/components/Labels'
import GlobalContext from '@shared/contexts/GlobalContext'
import { BillingEvent, ReversePaymentRequest } from '@shared/types/billing'
import { tw } from '@shared/utils/tailwind'
import BillingModal from '@app/pages/Facilities/Billing/Modals/components/BillingModal'
import { ModalProps } from '../helpers'

type Props = Pick<ModalProps, 'doneFn' | 'closeFn' | 'residentIds'> & {
  transaction: BillingEvent | undefined
}

type ReversePaymentFormData = Pick<ReversePaymentRequest, 'notes'>

export default function ReversePaymentModal(props: Props) {
  const { setError } = useContext(GlobalContext)
  const { residentIds, transaction, doneFn, closeFn } = props
  const {
    handleSubmit,
    register,
    formState,
    formState: { disabled },
  } = useForm<ReversePaymentFormData>({
    defaultValues: {
      notes: '',
    },
  })

  async function onSubmit(formData: ReversePaymentRequest) {
    try {
      await reversePayment({
        residentIds,
        transactionId: transaction!.id,
        notes: formData.notes,
      })
      await doneFn()
      closeFn()
    } catch (e) {
      setError(e)
    }
  }

  return (
    <BillingModal
      id="reverse-payment-modal"
      title="Reverse Payment"
      onClose={closeFn}
    >
      <form className={tw`mt-[32px]`} onSubmit={handleSubmit(onSubmit)}>
        <div className={tw`mt-[24px]`}>
          <LabelAboveInput htmlFor="notes" uppercase={false}>
            Notes
          </LabelAboveInput>
          <BasicTextarea {...register('notes')} />
        </div>

        <div className={tw`mt-[32px]`}>
          <AnimatedPopupFormFooter
            yesBtn={{ props: { disabled } }}
            noBtn={{ action: closeFn }}
            formState={formState}
          />
        </div>
      </form>
    </BillingModal>
  )
}
