import { DetailedStatement } from '@shared/types/billing'
import { tw } from '@shared/utils/tailwind'
import { BillingInvoicePill } from '../Components/Pills'

export default function Header({
  detailedInvoice,
}: {
  detailedInvoice: DetailedStatement
}) {
  return (
    <div className={tw`flex items-center gap-[16px]`}>
      <h1 className={tw`m-0 p-0 text-[24px] font-[500] text-secondary-02`}>
        Statement #{detailedInvoice.meta.id}
      </h1>
      <BillingInvoicePill
        id={detailedInvoice.meta.id}
        invoiceData={detailedInvoice.data}
      />
    </div>
  )
}
