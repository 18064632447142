import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  MultipleFieldErrors,
} from 'react-hook-form'
import {
  PasswordPolicyRequirement,
  PasswordPolicyRequirementLabel,
} from '@shared/constants/passwordPolicy'
import { tw, twx } from '@shared/utils/tailwind'
import { getErrorCopy } from './helpers'

export function PasswordRequirement({
  title,
  isSatisfied,
  keyName,
}: {
  title?: string
  isSatisfied: boolean
  keyName: PasswordPolicyRequirementLabel | 'mustMatch'
}) {
  return (
    <div className={tw`mb-[4px] flex items-center pl-[8px]`}>
      <i
        className={twx('fa-fw mr-[8px] text-[12px]', {
          'fa-regular fa-check text-august-primary': isSatisfied,
          'fa-regular fa-circle-xmark text-tags-alert': !isSatisfied,
        })}
      />
      <div
        data-testid={keyName}
        className={twx(
          'text-[12px] font-medium leading-[12px] text-secondary-07',
          {
            'line-through': isSatisfied,
          }
        )}
      >
        {title}
      </div>
    </div>
  )
}

export default function Requirements({
  requirements,
  errors,
}: {
  requirements: PasswordPolicyRequirement
  errors:
    | FieldError
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | Merge<FieldError, FieldErrorsImpl<any>>
    | MultipleFieldErrors
    | undefined
}) {
  const visibleRequirements = Object.keys(requirements).filter(
    (key) => requirements[key] !== false
  )

  return (
    <div className={tw`flex flex-col items-start pt-[16px]`}>
      {visibleRequirements.map((key: PasswordPolicyRequirementLabel) => {
        const title = getErrorCopy({ requirements, key })
        const isSatisfied = !errors?.[key]
        return (
          <PasswordRequirement
            key={key}
            keyName={key}
            title={title}
            isSatisfied={isSatisfied}
          />
        )
      })}
    </div>
  )
}
