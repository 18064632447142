import {
  AppraisalStatus,
  AugustInitialAppraisal,
} from '@augusthealth/models/com/august/protos/august_initial_appraisal'
import { DateMessage } from '@augusthealth/models/com/august/protos/date'
import { requestJson } from '@shared/api/request'
import environment from '@shared/environment'
import { Person, PersonIds } from '@shared/types/person'
import { DeepNull } from '@shared/types/utilities'

const { baseUrl } = environment

function assessmentUrl({ person }: { person: Required<PersonIds> }) {
  return `${baseUrl}/organizations/${person.orgId}/facilities/${person.facilityId}/people/${person.id}/appraisal`
}

export type GetResidentAssessmentProps = {
  person: Required<PersonIds>
  withOriginalSettings?: boolean
}

export async function getResidentAssessment({
  person,
  withOriginalSettings = false,
}: GetResidentAssessmentProps): Promise<AppraisalStatus> {
  const response = await requestJson({
    url: `${assessmentUrl({
      person,
    })}?withOriginalSettings=${withOriginalSettings}`,
  })
  return response.data
}

export async function patchResidentAssessment({
  person,
  patch,
}: {
  person: Person
  patch: DeepNull<AugustInitialAppraisal>
}): Promise<{ appraisal: AugustInitialAppraisal }> {
  // Omit read-only fields from the patch
  // Read only fields are annotated in the backend protos
  // See: src/main/protobuf/com/august/protos/august_initial_appraisal.proto
  const {
    levelOfCare,
    admissionType,
    levelOfCareSettings,
    allLevelOfCareSettings,
    settings,
    ...appraisal
  } = patch

  const response = await requestJson({
    url: assessmentUrl({ person }),
    method: 'PATCH',
    contentType: 'application/merge-patch+json',
    body: JSON.stringify({ appraisal }),
  })

  return response.data
}

export async function finalizeResidentAssessment({
  person,
  assessmentDate,
}: {
  person: Person
  assessmentDate?: DateMessage
}) {
  const body = assessmentDate
    ? { completedAt: { date: assessmentDate } }
    : undefined

  const response = await requestJson({
    url: assessmentUrl({ person }) + '/finalize',
    method: 'POST',
    body: JSON.stringify(body),
  })
  return response.data
}
