import { startOfYear, sub } from 'date-fns'
import {
  GroupByTimeUnit,
  IncidentActionType,
  IncidentStatus,
} from '@shared/types/incidents'
import { formatIsoDate } from '@shared/utils/date'
import {
  DateRangeParams,
  FacilityIncidentsParams,
  PaginationParams,
} from '@app/api/incidents'
import { FilterIncidentsFormData, FilterTimeSpan } from '@app/pages/Notes/types'

function getStartDate(
  timeSpan: Exclude<FilterTimeSpan, null>,
  now: Date
): Date {
  switch (timeSpan) {
    case 'lastTwentyFourHours':
      return sub(now, { days: 1 })
    case 'lastSevenDays':
      return sub(now, { days: 7 })
    case 'lastThirtyDays':
      return sub(now, { days: 30 })
    case 'lastSixtyDays':
      return sub(now, { days: 60 })
    case 'lastHundredAndTwentyDays':
      return sub(now, { days: 120 })
    case 'lastTwelveMonths':
      return sub(now, { months: 12 })
    case 'yearToDate':
      return startOfYear(now)
  }
}

function getGroupByTimeUnit(timeSpanUnit: TimeSpanUnit): GroupByTimeUnit {
  switch (timeSpanUnit) {
    case 'days':
      return GroupByTimeUnit.GROUP_BY_TIME_UNIT_DAY
    case 'weeks':
      return GroupByTimeUnit.GROUP_BY_TIME_UNIT_WEEK
    case 'months':
      return GroupByTimeUnit.GROUP_BY_TIME_UNIT_MONTH
    case 'yearToDate':
      return GroupByTimeUnit.GROUP_BY_TIME_UNIT_MONTH
  }
}

function toDateRangeParams(timeSpan: FilterTimeSpan): DateRangeParams {
  if (timeSpan === null) {
    return { startDate: undefined, endDate: undefined }
  } else {
    const now = new Date()
    return {
      startDate: formatIsoDate(getStartDate(timeSpan, now)),
      endDate: formatIsoDate(now),
    }
  }
}

function isIncidentStatus(
  value: IncidentStatus | IncidentActionType
): value is IncidentStatus {
  return Object.values(IncidentStatus).includes(value as IncidentStatus)
}

function separateTypes(
  value: IncidentStatus | IncidentActionType | null
):
  | { incidentStatus: IncidentStatus; actionType: undefined }
  | { incidentStatus: undefined; actionType: IncidentActionType }
  | { incidentStatus: undefined; actionType: undefined } {
  if (value === null) {
    return {
      incidentStatus: undefined,
      actionType: undefined,
    }
  } else if (isIncidentStatus(value)) {
    return {
      incidentStatus: value,
      actionType: undefined,
    }
  } else {
    return {
      incidentStatus: undefined,
      actionType: value,
    }
  }
}

export function facilityIncidentsFormDataToParams({
  filters,
}: {
  filters: FilterIncidentsFormData & PaginationParams
}): FacilityIncidentsParams {
  const { limit, offset } = filters
  const { startDate, endDate } = toDateRangeParams(filters.time)
  const { incidentStatus, actionType } = separateTypes(filters.incidentStatus)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, timeSpanUnit] = getNumberAndTypeOfUnits(filters.time)

  return {
    startDate,
    endDate,
    noteTypes: filters.noteTypes,
    incidentTypes: filters.incidentTypes,
    incidentStatus,
    actionType,
    onAlert: filters.onAlert ?? undefined,
    createdBy: filters.users,
    groupStatsBy: getGroupByTimeUnit(timeSpanUnit),
    limit,
    offset,
  }
}

type TimeSpanUnit = 'months' | 'weeks' | 'days' | 'yearToDate'

export function getNumberAndTypeOfUnits(
  time: FilterTimeSpan
): [number | null, TimeSpanUnit] {
  switch (time) {
    case 'lastTwentyFourHours':
      return [1, 'days']
    case 'lastSevenDays':
      return [7, 'days']
    case 'lastThirtyDays':
      return [30, 'days']
    case 'lastSixtyDays':
      return [60, 'days']
    case 'lastHundredAndTwentyDays':
      return [17, 'weeks']
    case 'lastTwelveMonths':
      return [12, 'months']
    default:
      return [null, 'months']
  }
}
