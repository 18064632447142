import DatePicker from 'react-datepicker'
import { Controller, useFormContext } from 'react-hook-form'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import { LabelAboveInput } from '@shared/components/Labels'
import { tw } from '@shared/utils/tailwind'

export default function FinancialStartDate() {
  const { control, getValues } = useFormContext()

  return (
    <div className={tw`mt-[32px]`}>
      <Controller
        control={control}
        name="financialStartDate"
        render={() => (
          <div className={tw`mr-[16px]`}>
            <LabelAboveInput htmlFor="financialStartDate">
              Financial effective start date
            </LabelAboveInput>
            <div>
              <DatePicker
                name="financialStartDate"
                disabled
                customInput={<CalendarInput />}
                selected={getValues('financialStartDate')}
                onChange={() => {
                  // Empty onChange function since field is read-only, onChange should not be triggered.
                }}
              />
              <span className={tw`ml-[24px] text-[14px] text-gray-07`}>
                To edit, update the Admissions Agreements
              </span>
            </div>
          </div>
        )}
      />
    </div>
  )
}
