import { useContext, useState } from 'react'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { Switch } from '@app/components/Switch'
import styles from './PaperMar/styles.module.css'
import { downloadMedListPDF } from './helpers'

interface Props {
  person: Person
  onClose: () => void
}

export default function MedListModal({ person, onClose }: Props) {
  const { setError } = useContext(GlobalContext)
  const [physicianReview, setPhysicianReview] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const download = () => {
    setIsLoading(true)
    downloadMedListPDF(person, physicianReview)
      .then(() => onClose())
      .catch(setError)
      .finally(() => setIsLoading(false))
  }

  return (
    <AnimatedPopup
      onClickBackground={onClose}
      containerClassName={styles.marSelectorModal}
      title="Download Medication List"
    >
      <div className={`${styles.switchHolder} mt-[24px]`}>
        <Switch
          checked={physicianReview}
          label="Format for Physician Orders"
          labelPosition="left"
          name="physicianReview"
          onChange={() => setPhysicianReview(!physicianReview)}
        />
      </div>
      <AsyncIconButton
        className="mt-[24px]"
        buttonStyle="primary-fill"
        id="printMedListContinueBtn"
        isLoading={isLoading}
        disabled={isLoading}
        width="100%"
        onClick={download}
      >
        Continue
      </AsyncIconButton>
    </AnimatedPopup>
  )
}
