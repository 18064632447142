import { Contact } from '@augusthealth/models/com/august/protos/contact'
import { range } from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import { match, P } from 'ts-pattern'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { Button } from '@shared/components/baseMui/Button'
import BaseCard from '@shared/components/ContactCard/BaseCard/BaseCard'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import {
  IconStyledSelect,
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import TextInputWithIcon from '@shared/components/TextInputWithIcon/TextInputWithIcon'
import { PayerSettingsData, PaymentCapType } from '@shared/types/billing'
import { toOrdinal } from '@shared/utils/date'
import { getFullName } from '@shared/utils/humanName'
import { tw } from '@shared/utils/tailwind'
import { MoneyInput } from '@app/components/MoneyInput'
import { RHFSwitch as Switch } from '@app/components/Switch'
import { useBillingResidentSummaryContext } from '@app/contexts/BillingResidentSummaryContext'

interface Props {
  contact: Contact
  payerSettings: PayerSettingsData
  onClose: () => void
  submitFn: (data: PayerSettingsData) => Promise<boolean>
  isSubmitting: boolean
  multiplePayers: boolean
}

type AutoPayFormData = {
  autoPay: boolean
  capType: PaymentCapType
  capAmount: number | undefined
  paymentDayOfMonth?: number
}

export default function AutoPayCard({
  contact,
  payerSettings,
  onClose,
  submitFn,
  isSubmitting,
  multiplePayers,
}: Props) {
  const displayName = getFullName(contact.name)
  const { billingSummary } = useBillingResidentSummaryContext()

  const { capType: defaultCapType, parameters } = payerSettings.paymentCap
  const defaultFormData: AutoPayFormData = {
    autoPay: payerSettings.autoPay,
    capType: defaultCapType,
    capAmount:
      defaultCapType === PaymentCapType.FIXED_PAYMENT_CAP
        ? parameters['amountCents']
        : defaultCapType === PaymentCapType.PERCENTAGE_PAYMENT_CAP
          ? parameters['percentage']
          : undefined,
    paymentDayOfMonth:
      billingSummary.tag === 'Complete'
        ? payerSettings.paymentDayOfMonth ||
          billingSummary.value.statementDueDay
        : undefined,
  }

  const {
    formState,
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm<AutoPayFormData>({
    defaultValues: defaultFormData,
  })

  const { errors } = formState
  const autoPay = watch('autoPay')
  const capType = watch('capType')
  const isRequired = autoPay && multiplePayers

  const payDateOptions = range(1, 29).map((day) => ({
    label: toOrdinal(day),
    value: day,
  }))

  async function onSubmit(formData: AutoPayFormData) {
    const capAmountInt = Number(formData.capAmount)
    // if autopay is turned off, unset PaymentCap
    const capTypeCalculated = formData.autoPay
      ? formData.capType
      : PaymentCapType.NO_PAYMENT_CAP

    const paymentCap = match([capTypeCalculated, capAmountInt])
      .with(
        [PaymentCapType.FIXED_PAYMENT_CAP, P.number.positive()],
        ([type, amount]) => {
          return {
            capType: type,
            parameters: { amountCents: amount },
          }
        }
      )
      .with([PaymentCapType.FIXED_PAYMENT_CAP, P.any], () => {
        setError('capAmount', {
          type: 'custom',
          message: 'Must be a positive value',
        })
        return null
      })
      .with(
        [PaymentCapType.PERCENTAGE_PAYMENT_CAP, P.number.between(1, 100)],
        ([type, amount]) => {
          return {
            capType: type,
            parameters: { percentage: amount },
          }
        }
      )
      .with([PaymentCapType.PERCENTAGE_PAYMENT_CAP, P.any], () => {
        setError('capAmount', {
          type: 'custom',
          message: 'Must be between 1 and 100',
        })
        return null
      })
      .with([PaymentCapType.NO_PAYMENT_CAP, P.any], ([type, _]) => {
        if (isRequired) {
          setError('capAmount', {
            type: 'custom',
            message: 'Required',
          })
          return null
        }
        return { capType: type, parameters: {} }
      })
      .exhaustive()

    if (!paymentCap) {
      return
    }

    const updatedSettings: PayerSettingsData = {
      ...payerSettings,
      autoPay: formData.autoPay,
      paymentCap: paymentCap,
      paymentDayOfMonth: formData.paymentDayOfMonth,
    }
    const success = await submitFn(updatedSettings)
    if (success) {
      onClose()
    }
  }

  return (
    <BaseCard
      linkable={false}
      header={displayName}
      subheader={'AUTO PAY'}
      testid={'autopay-card-content'}
      label="Edit Auto Pay"
      showDivider={false}
      footer={
        <div className={tw`flex justify-center`}>
          <AnimatedPopupFormFooter
            yesBtn={{
              props: {
                onClick: handleSubmit(onSubmit),
                isLoading: isSubmitting,
              },
            }}
            noBtn={{ action: onClose }}
            formState={formState}
          />
        </div>
      }
      topRightContent={
        <Controller
          control={control}
          name="autoPay"
          defaultValue={false}
          render={({ field: { onChange, value } }) => (
            <>
              <Switch
                checked={value}
                id={'autoPay'}
                onChange={() => {
                  onChange(!value)
                  clearErrors()
                }}
                switchSize={'medium'}
              />
            </>
          )}
        />
      }
    >
      <div className={tw`h-[176px]`}>
        {autoPay ? (
          <form data-testid="form" onSubmit={handleSubmit(onSubmit)}>
            <LabelAboveInput
              uppercase={false}
              subLabel={
                isRequired || errors.capAmount?.type === 'custom'
                  ? requiredLabel(!!errors.capAmount)
                  : undefined
              }
              errorMessage={
                errors.capAmount?.type === 'custom'
                  ? errors.capAmount.message
                  : undefined
              }
              htmlFor="capAmount"
            >
              Maximum withdraw amount
            </LabelAboveInput>
            <div className={tw`mb-8 flex justify-between`}>
              <div className={tw`mr-2 w-full`}>
                <Controller
                  control={control}
                  name="capAmount"
                  rules={{ required: isRequired }}
                  render={({ field }) => {
                    const { ref, onChange, value } = field
                    return capType === PaymentCapType.PERCENTAGE_PAYMENT_CAP ? (
                      <TextInputWithIcon
                        inputProps={{
                          ...field,
                          required: isRequired,
                          type: 'number',
                          min: 0,
                          max: 100,
                        }}
                        iconClassName="fas fa-fw fa-badge-percent"
                      />
                    ) : (
                      <MoneyInput
                        allowNegativeNumbers={false}
                        refCallback={ref}
                        onChange={onChange}
                        inputProps={{
                          required: isRequired,
                          disabled: capType === PaymentCapType.NO_PAYMENT_CAP,
                          value: typeof value === 'undefined' ? '' : value,
                          name: 'capAmount',
                          id: 'capAmount',
                        }}
                      />
                    )
                  }}
                />
              </div>
              <div className={tw`flex items-end gap-2`}>
                <CapTypeButton
                  icon="dollar"
                  active={capType === PaymentCapType.FIXED_PAYMENT_CAP}
                  onClick={() => {
                    setValue(
                      'capType',
                      capType === PaymentCapType.FIXED_PAYMENT_CAP
                        ? PaymentCapType.NO_PAYMENT_CAP
                        : PaymentCapType.FIXED_PAYMENT_CAP
                    )
                    setValue('capAmount', 0)
                    clearErrors()
                  }}
                />
                <CapTypeButton
                  icon="percent"
                  active={capType === PaymentCapType.PERCENTAGE_PAYMENT_CAP}
                  onClick={() => {
                    setValue(
                      'capType',
                      capType === PaymentCapType.PERCENTAGE_PAYMENT_CAP
                        ? PaymentCapType.NO_PAYMENT_CAP
                        : PaymentCapType.PERCENTAGE_PAYMENT_CAP
                    )
                    setValue('capAmount', 0)
                    clearErrors()
                  }}
                />
              </div>
            </div>
            <LabelAboveInput
              uppercase={false}
              subLabel={requiredLabel(!!errors.paymentDayOfMonth)}
              htmlFor="paymentDayOfMonth"
            >
              Auto pay date
            </LabelAboveInput>
            <Controller
              control={control}
              name="paymentDayOfMonth"
              render={({ field: { value, onChange } }) => {
                return (
                  <IconStyledSelect
                    id="due-day-selector"
                    aria-label="paymentDayOfMonth"
                    aria-labelledby="paymentDayOfMonth"
                    value={payDateOptions.find((opt) => opt.value === value)}
                    onChange={(value: OptionTypeBase<string>) =>
                      onChange(value.value)
                    }
                    options={payDateOptions}
                    iconName="fa-solid fa-calendar-day"
                  />
                )
              }}
            />
          </form>
        ) : (
          <div
            className={tw`h-full content-center rounded-md border border-gray-11 bg-gray-13 text-center text-xs font-semibold uppercase text-gray-07`}
          >
            Auto pay off
          </div>
        )}
      </div>
    </BaseCard>
  )
}

type CapTypeButtonProps = {
  onClick: () => void
  active: boolean
  icon: 'dollar' | 'percent'
}
const CapTypeButton = ({ onClick, active, icon }: CapTypeButtonProps) => {
  return (
    <Button
      buttonStyle={active ? 'primary-light-fill' : 'tertiary-outline'}
      buttonSize="medium"
      className={tw`size-[40px]`}
      onClick={onClick}
    >
      {icon === 'dollar' ? '$' : '%'}
    </Button>
  )
}
