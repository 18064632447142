// eslint-disable-next-line check-file/filename-naming-convention
import IMask from 'imask'
import { HTMLProps, useState } from 'react'
import {
  BasicInput,
  BasicInputProps,
} from '@shared/components/BasicInput/BasicInput'
import ButtonLink from '@shared/components/ButtonLink'
import { EMAIL_REGX } from '@shared/utils/regex'
import { twx } from '@shared/utils/tailwind'

export interface Props {
  children?: React.ReactNode
  holderClassName?: string
  inputProps: BasicInputProps
  iconClassName?: string
  containerProps?: HTMLProps<HTMLDivElement>
  ctaProps?: {
    text?: string
    onClick?: () => void
  }
}

export function isValidEmail(email: string) {
  return EMAIL_REGX.test(email)
}

export default function TextInputWithIcon(props: Props) {
  const { children, ctaProps, holderClassName, iconClassName, inputProps } =
    props
  const { className } = inputProps

  if (iconClassName) {
    return (
      <div
        className={twx(
          'grow-1 relative',
          {
            relative: Boolean(ctaProps),
          },
          holderClassName
        )}
        /* eslint-disable-next-line no-restricted-syntax */
        style={{ ...(props.containerProps?.style ?? {}) }}
      >
        <BasicInput
          {...inputProps}
          className={twx('pl-inputIconLeftPadding', className)}
          containerClassName={twx('w-full')}
        />
        <i
          className={twx(
            'absolute left-[12px] top-[50%] mt-[-0.5em] text-primary-light',
            {
              ['text-form-input-disabled-text-color']: inputProps.disabled,
            },
            iconClassName
          )}
        />
        {children}
        {ctaProps && (
          <div
            key={ctaProps.text}
            className={twx('absolute right-0 top-0 h-full pr-[12px] pt-[7px]')}
          >
            <ButtonLink
              className={twx('text-[14px] font-medium text-gray-07')}
              onClick={ctaProps.onClick}
            >
              {ctaProps.text}
            </ButtonLink>
          </div>
        )}
      </div>
    )
  }

  return <BasicInput {...inputProps} />
}

export function EmailInput({ inputProps }: Props) {
  const { placeholder = 'johnny@appleseed.com' } = inputProps
  const props = {
    ...inputProps,
    placeholder,
  }

  return (
    <TextInputWithIcon inputProps={props} iconClassName="fas fa-envelope" />
  )
}

export function LockedEmailInput(props: Props) {
  const { inputProps } = props
  const iconClassName = twx('fas grow-1', {
    ['fa-envelope']: !inputProps.disabled,
    ['fa-lock']: inputProps.disabled,
  })
  return (
    <TextInputWithIcon
      inputProps={{
        ...inputProps,
        placeholder: inputProps.placeholder || 'johnny@appleseed.com',
      }}
      iconClassName={iconClassName}
    />
  )
}

export const phoneMask = new IMask.MaskedDynamic({
  mask: [
    { mask: '(000) 000-0000 {x}****' },
    { mask: '+0 (000) 000-0000 {x}****' },
    { mask: /\D/ },
  ],
})

export function PhoneInput(props: Props) {
  const { inputProps, iconClassName = 'fas fa-phone' } = props
  const { onChange, value } = inputProps
  const [textValue, setTextValue] = useState(value ?? '')

  return (
    <TextInputWithIcon
      inputProps={{
        placeholder: '(555) 555-5555',
        ...inputProps,
        onChange: (e) => {
          if (onChange) {
            onChange(e)
          }
          setTextValue(phoneMask.resolve(e.currentTarget.value))
        },
        value: textValue,
      }}
      iconClassName={iconClassName}
    />
  )
}

export function FaxInput(props: Props) {
  return <PhoneInput {...props} iconClassName="fas fa-fw fa-fax" />
}
