import { CSSProperties } from 'react'
import { Props as ElementHolderProps } from './ElementHolder'
import TextInput, { InputType } from './TextInput/TextInputInGenerator'

export type Props = Omit<ElementHolderProps, 'children'> & {
  type?: 'hash_text' | 'hash_textarea' | InputType
  onUpdate: (value: string | undefined | null, name: string) => void
  disabled?: boolean
  className?: string
  name?: string
  placeholder?: string
  style?: CSSProperties
  value?: string | undefined
}

// Same as TextInput except sent null vs undefined to remove a value in hash
export default function HashTextInput(props: Props) {
  const { onUpdate, type = 'hash_text' } = props
  let convertedType: InputType
  if (type === 'hash_textarea') {
    convertedType = InputType.TEXT_INPUT_TYPE_TEXTAREA
  } else if (type === 'hash_text') {
    convertedType = InputType.TEXT_INPUT_TYPE_TEXT
  } else {
    convertedType = type
  }
  return (
    <TextInput
      {...props}
      type={convertedType}
      onUpdate={(value: string | undefined, name: string) => {
        if (value) {
          onUpdate(value, name)
        } else {
          onUpdate(null, name)
        }
      }}
    />
  )
}

export function HashTextarea(props: Omit<Props, 'type'>) {
  return <HashTextInput {...props} type="hash_textarea" />
}
