import { VictoryLabel, VictoryLabelProps } from 'victory'

export type TickLabelProps = VictoryLabelProps & {
  categories: string[]
  index?: number
}

/**
 * Transfer XTick labels, except to receive Month/Date in string[]
 * [JAN, FEV, MAR, ...] will remain [JAN, FEV, MAR, ...]
 * Dates in [JAN 01, FEV 12, MAR 21, ...] will be removed => [JAN, FEV, MAR, ...]
 * Duplicate months in [JAN 01, JAN 15, FEV 01, FEV 15, MAR 01, ...] will be empty [JAN, '', FEV, '', MAR, ...]
 */
export default function MonthXTickLabel(props: TickLabelProps) {
  const { categories, index, text } = props
  const monthsWithoutDay = categories.every((c) => c.length === 3)

  let monthLabel = ''
  if (monthsWithoutDay && typeof text === 'string') {
    // Return all portion of month array such as [JAN, FEV, ...]
    monthLabel = text
  } else if (
    index !== undefined &&
    index > 0 &&
    typeof text === 'string' &&
    typeof categories[index - 1] === 'string' &&
    text.substring(0, 3) !== categories[index - 1].substring(0, 3)
  ) {
    // Return only month portion of month date array such as [JAN 1, JAN 14, FEV 1, ...]
    // Remove duplicate Month (Display only current Month !== previous Month)
    monthLabel = text.substring(0, 3)
  }

  return <VictoryLabel {...props} text={monthLabel} />
}
