import useAsyncResult from '@careapp/hooks/useAsyncResult'
import { getFacilityUsers } from '@shared/api/facility'

export default function useFacilityUsers({
  orgId,
  facilityId,
}: {
  orgId: string
  facilityId: string
}) {
  const facilityUsers = useAsyncResult(() =>
    getFacilityUsers({ orgId, facilityId })
  )

  return {
    facilityUsers,
  }
}
