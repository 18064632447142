import { Shift } from '@augusthealth/models/com/august/protos/shift'
import { requestJson } from '@shared/api/request'
import { apiFacilityUrl } from '@shared/legacy_routes'
import { FacilityIds } from '@shared/types/facility'

function getShiftsApiUrl(orgId: string, facilityId: string) {
  return `${apiFacilityUrl(orgId, facilityId)}/shifts`
}

export async function fetchShifts({
  orgId,
  id: facilityId,
}: FacilityIds): Promise<Shift[]> {
  const response = await requestJson({
    url: getShiftsApiUrl(orgId, facilityId),
  })

  return response.data
}

export async function updateShifts({
  orgId,
  facilityId,
  shifts,
}: {
  orgId: string
  facilityId: string
  shifts: Shift[]
}): Promise<Shift[]> {
  const response = await requestJson({
    url: getShiftsApiUrl(orgId, facilityId),
    method: 'PUT',
    body: JSON.stringify(shifts),
  })

  return response.data
}

export async function deleteShifts({
  orgId,
  id: facilityId,
}: FacilityIds): Promise<{ meta: { hello: 'Deleted' } }> {
  const response = await requestJson({
    url: getShiftsApiUrl(orgId, facilityId),
    method: 'DELETE',
  })

  return response.meta
}
