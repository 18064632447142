import { PharmacyPartner } from '@shared/types/pharmacy_partner'

export const getReadablePharmacyPartnerName = (
  partner: PharmacyPartner
): string => {
  if (partner === PharmacyPartner.PHARMACY_PARTNER_PHARMERICA) {
    return 'LTC 400'
  } else if (partner === PharmacyPartner.PHARMACY_PARTNER_OMNICARE) {
    return 'Omnicare - OMNIDx'
  }

  return partner
    .replace(/PHARMACY_PARTNER_/, '')
    .split('_')
    .join(' ')
}
