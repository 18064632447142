import { Contact } from '@augusthealth/models/com/august/protos/contact'
import {
  ContactPoint,
  ContactPoint_ContactPointSystem,
  ContactPoint_ContactPointUse,
} from '@augusthealth/models/com/august/protos/contact_point'
import { isEmpty } from 'lodash'
import Badge from '@shared/components/Badge'
import {
  formatStreetAddress,
  getCityStatePostalCode,
} from '@shared/utils/address'
import { getFullName } from '@shared/utils/humanName'
import {
  getMainRelationship,
  getMainRole,
  getRoleRelationshipList,
} from '@shared/utils/relationship'
import { tw } from '@shared/utils/tailwind'
import BaseCard from './BaseCard/BaseCard'

interface Props {
  children?: React.ReactNode
  contact: Contact
  gridIndex?: number
  linkable?: boolean
  onClick?: () => void
}

export default function ContactCard({
  contact,
  children,
  gridIndex,
  linkable = true,
  onClick,
}: Props) {
  let extraRoleLabels: string[] = []
  let mainRole
  if (contact.relationship) {
    mainRole = getMainRole(contact.relationship)
    extraRoleLabels = getRoleRelationshipList(contact.relationship)
    if (contact.clinicalSpecialty) {
      extraRoleLabels.push(contact.clinicalSpecialty)
    }
  }

  const roleBadges = extraRoleLabels.reduce(
    (labels: React.ReactNode[], role) => {
      labels.push(
        <Badge
          key={role + contact.id}
          color={role !== mainRole ? 'gray' : 'purple'}
          badgeSize="small"
          className={tw`mb-[6px] mr-2 font-semibold uppercase`}
        >
          {role}
        </Badge>
      )
      return labels
    },
    []
  )

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const mainRelationship = getMainRelationship(contact.relationship!)
  const displayName = getFullName(contact.name)

  return (
    <BaseCard
      linkable={linkable}
      gridIndex={gridIndex}
      onClick={onClick}
      footer={children}
      header={displayName}
      subheader={mainRelationship}
      subcontent={roleBadges}
      testid={'contact-card-content'}
      label="Edit Contact"
    >
      <ContactDetails contact={contact} />
    </BaseCard>
  )
}

export const ContactDetails = ({ contact }: { contact: Contact }) => {
  const displayContactPoints = contact.telecom
    ? getDisplayContactPoints(contact.telecom)
    : undefined

  return (
    <div>
      {displayContactPoints?.phones.map((phone, index) =>
        telecomRow({
          icon: `fa fa-fw fa-${phone.type}`,
          title: 'Phone Number',
          value: phone.value,
          index,
          length: displayContactPoints.phones.length,
        })
      )}
      {displayContactPoints?.email &&
        telecomRow({
          icon: 'fa fa-fw fa-envelope',
          title: 'Email',
          value: displayContactPoints.email,
        })}
      {displayContactPoints?.fax &&
        telecomRow({
          icon: 'fas fa-fw fa-fax',
          title: 'Fax Number',
          value: displayContactPoints.fax,
        })}
      {!isEmpty(contact.address) &&
        telecomRow({
          icon: 'fa fa-fw fa-map-marker-alt',
          title: 'Address',
          value: (
            <div>
              {formatStreetAddress(contact.address)}
              <br />
              {getCityStatePostalCode(contact.address)}
            </div>
          ),
        })}
      {contact.organization?.name &&
        telecomRow({
          icon: 'fas fa-fw fa-building',
          title: 'Organization',
          value: contact.organization.name,
        })}
    </div>
  )
}

interface TelecomRow {
  icon: string
  title: string
  value: string | JSX.Element
  index?: number
  length?: number
}

const telecomRow = ({
  icon,
  title,
  value,
  index = 1,
  length = 0,
}: TelecomRow): React.ReactNode => (
  <div
    key={title + index}
    className={tw`mb-2 flex items-center text-duotone-level1`}
  >
    <span className={tw`visually-hidden`}>{title}</span>
    <i aria-hidden={true} className={tw`${icon} mr-2 text-gray-07`} />
    <span
      className={tw`align-center inline-block max-w-[280px] overflow-hidden text-ellipsis text-sm font-medium leading-5 text-gray-01`}
    >
      {value}
      {index === 0 && length > 1 && (
        <Badge color="darkGray" className={tw`ml-[8px] inline-block h-[18px]`}>
          Primary
        </Badge>
      )}
    </span>
  </div>
)

interface Phone {
  value: string
  type: string
}

interface DisplayContactPoint {
  phones: Phone[]
  email: string
  fax: string
}

export const getDisplayContactPoints = (
  telecom: ContactPoint[]
): DisplayContactPoint => {
  return telecom.reduce(
    (contactMethods: DisplayContactPoint, item) => {
      if (!item.value) {
        return contactMethods
      }

      if (
        item.system ===
        ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_PHONE
      ) {
        const phone = {
          value: item.value,
          type: 'phone',
        }
        if (
          item.use === ContactPoint_ContactPointUse.CONTACT_POINT_USE_MOBILE
        ) {
          phone.type = 'mobile-alt'
        }

        contactMethods.phones.push(phone)
      } else if (
        item.system ===
        ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_EMAIL
      ) {
        contactMethods.email = item.value
      } else if (
        item.system === ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_FAX
      ) {
        contactMethods.fax = item.value
      }
      return contactMethods
    },
    { phones: [], email: '', fax: '' }
  )
}
