import { Occupancy_Room as OccupancyRoom } from '@augusthealth/models/com/august/protos/api/occupancy'
import { Bed } from '@augusthealth/models/com/august/protos/bed'
import { Room } from '@augusthealth/models/com/august/protos/room'
import React from 'react'

export interface AssignRoomPopupData {
  bed: Bed
  room: Room
}

export interface DeleteRoomPopupData {
  occupancyRoom: OccupancyRoom
}

export interface EditRoomPopupData {
  occupancyRoom: OccupancyRoom
}

interface OccupancyData {
  setAssignRoomPopupData: (data?: AssignRoomPopupData) => void
  setDeleteRoomPopupData: (data?: DeleteRoomPopupData) => void
  setEditRoomPopupData: (data?: EditRoomPopupData) => void
}

export default React.createContext<OccupancyData>({
  setAssignRoomPopupData: () => null,
  setDeleteRoomPopupData: () => null,
  setEditRoomPopupData: () => null,
})
