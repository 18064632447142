import ButtonLink from '@shared/components/ButtonLink'
import styles from './styles.module.css'

type Props = {
  onEdit: () => void
  onRemove: () => void
  disabled: 'edit' | 'delete' | boolean
}

export const EditRemoveButtons = ({ onEdit, onRemove, disabled }: Props) => {
  return (
    <div className={styles.inlineButtonContainer}>
      <ButtonLink
        title={'Edit Item'}
        onClick={onEdit}
        className={styles.inlineButton}
        disabled={disabled === true || disabled === 'edit'}
      >
        <i className={`fa fa-fw fa-edit`} />
      </ButtonLink>
      <ButtonLink
        title={'Delete Item'}
        onClick={onRemove}
        className={styles.inlineButton}
        disabled={disabled === true || disabled === 'delete'}
      >
        <i className={`fa fa-fw fa-trash`} />
      </ButtonLink>
    </div>
  )
}
