import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput } from '@shared/components/Labels'
import { DetailModalForm } from '@app/pages/Tools/AssessmentConfiguration/DetailGroups/AddEditDetailModal/AddEditDetailModal'

export const CheckboxDetail = () => {
  const { register, unregister } = useFormContext<DetailModalForm>()

  useEffect(() => {
    return () => {
      unregister(['description', 'score'])
    }
  }, [])

  return (
    <>
      <LabelAboveInput htmlFor={'description'}>Description</LabelAboveInput>
      <BasicInput
        type={'text'}
        placeholder={'Description'}
        {...register('description')}
      />

      <LabelAboveInput htmlFor={'score'}>Score</LabelAboveInput>
      <BasicInput
        type={'number'}
        placeholder={'Score'}
        min={0}
        defaultValue={0}
        {...register('score')}
      />
    </>
  )
}
