import { LastUpdate } from '@augusthealth/models/com/august/protos/api/last_update'
import { formatTimeAgo } from '@shared/utils/date'

export function lastUpdateTime(lastUpdate: LastUpdate) {
  return formatTimeAgo(lastUpdate.updatedAt)
}

export const lastUpdatedBy = (lastUpdate: LastUpdate) => {
  return lastUpdate.userName
}
