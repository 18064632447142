import { Page } from '@shared/hooks/useCurrentPage'
import {
  admissionTasksPathForPerson,
  carePathForPerson,
  getProspectsUrl,
  getResidentsUrl,
  medicationsPathForPerson,
  notesPathForPerson,
} from '@shared/legacy_routes'
import { Facility } from '@shared/types/facility'
import { Person } from '@shared/types/person'
import { Task } from '@shared/types/task'
import { getFullName } from '@shared/utils/humanName'
import { isDischargedResident, isResident } from '@shared/utils/person'
import { taskTitle } from '@shared/utils/task'

export interface Breadcrumb {
  children?: React.ReactNode
  text: string
  url: string
}

export const RESIDENTS = 'Residents'
export const MOVE_INS = 'Move-ins'

export function toBreadcrumbs(
  page: Page,
  person: Person,
  tasks: Task[]
): Breadcrumb[] {
  if (page.tag === 'Person') {
    const { orgId, facilityId } = page
    const statusCrumb = facilityCrumb({ person, orgId, facilityId })
    const personCrumb = buildPersonCrumb({ page, person })

    if (page.tab.tag === 'Routine') {
      return [statusCrumb, personCrumb, routineCrumb()]
    }

    if (page.tab.tag === 'Medication') {
      return [
        statusCrumb,
        personCrumb,
        medicationCrumb({
          medicationId: page.tab.medicationId,
          action: page.tab.action,
        }),
      ]
    }

    if (page.tab.tag === 'Task') {
      return [
        statusCrumb,
        personCrumb,
        taskCrumb({ taskId: page.tab.taskId, tasks }),
      ]
    }

    if (page.tab.tag === 'Incident') {
      return [
        statusCrumb,
        personCrumb,
        incidentCrumb({ incidentId: page.tab.incidentId }),
      ]
    }

    return [statusCrumb, personCrumb]
  }

  return []
}

export function toRPBreadcrumbs(
  page: Page,
  person: Person,
  tasks: Task[],
  facility: Facility | undefined,
  personSwitcher: React.ReactNode
): Breadcrumb[] {
  if (page.tag === 'Person') {
    const facilityCrumb = {
      text: facility?.name ?? 'Community',
      url: '#',
    }
    const personCrumb1 = buildPersonCrumb({ page, person })

    if (page.tab.tag === 'Medication') {
      return [
        facilityCrumb,
        personCrumb1,
        medicationCrumb({
          medicationId: page.tab.medicationId,
          action: page.tab.action,
        }),
      ]
    }

    if (page.tab.tag === 'Task') {
      return [
        facilityCrumb,
        personCrumb1,
        taskCrumb({ taskId: page.tab.taskId, tasks }),
      ]
    }

    if (page.tab.tag === 'Incident') {
      return [
        facilityCrumb,
        personCrumb1,
        incidentCrumb({ incidentId: page.tab.incidentId }),
      ]
    }

    return [facilityCrumb, { ...personCrumb1, children: personSwitcher }]
  }

  return []
}

export function facilityCrumb({
  person,
  orgId,
  facilityId,
}: {
  person: Person
  orgId: string
  facilityId: string
}) {
  if (isResident(person) || isDischargedResident(person)) {
    return {
      text: RESIDENTS,
      url: getResidentsUrl(orgId, facilityId),
    }
  }

  return {
    text: MOVE_INS,
    url: getProspectsUrl(orgId, facilityId),
  }
}

export function buildPersonCrumb({
  page,
  person,
}: {
  page: Page
  person: Person
}): Breadcrumb {
  const text = getFullName(person.name![0])
  let url: string = ''

  if (page.tag === 'Person') {
    switch (page.tab.tag) {
      case 'Routine':
        url = carePathForPerson(person as Required<Person>)
        return { text, url }
      case 'Medications':
        url = medicationsPathForPerson(person as Required<Person>)
        return { text, url }
      case 'Medication':
        url = medicationsPathForPerson(person as Required<Person>)
        return { text, url }
      case 'Task':
        url = admissionTasksPathForPerson(person as Required<Person>)
        return { text, url }
      case 'Incident':
        url = notesPathForPerson(person as Required<Person>)
        return { text, url }
    }

    return { text, url: '#' }
  }

  throw new Error('Generating a person crumb on a non-Person page')
}

function medicationCrumb({
  medicationId,
  action,
}: {
  medicationId: string
  action?: string
}) {
  let text = ''

  if (medicationId === 'new') {
    text = 'New Order'
  } else if (action === 'edit') {
    text = 'Edit Order'
  }

  return { text, url: '#' }
}

function routineCrumb() {
  return { text: 'Routine', url: '#' }
}

function incidentCrumb({ incidentId }: { incidentId: string }) {
  const text = incidentId === 'new' ? 'Add Incident' : 'Edit Incident'
  return { text, url: '#' }
}

function taskCrumb({ taskId, tasks }: { taskId: string; tasks: Task[] }) {
  const currentTaskId = taskId
  const currentTask = tasks.find((t) => t.id === currentTaskId)
  const text = currentTask ? taskTitle(currentTask) : ''

  return { text, url: '#' }
}
