import Card from '@shared/components/Card'
import Uploader from '../Uploader'

export default function ImportUploader({
  importFile,
  setImportFile,
  fileFormat,
}: {
  importFile: File | undefined
  setImportFile: (file: File | undefined) => void
  fileFormat: { [key: string]: string[] }
}) {
  return (
    <Card className="flex-center h-full">
      {!importFile && (
        <Uploader
          handleChange={setImportFile}
          options={{
            accept: fileFormat,
          }}
        />
      )}
      {importFile && (
        <div className="flex items-center justify-between text-august-primary">
          <i className="far fa-cloud-upload mr-[8px]" />
          <span className="font-semibold">{importFile.name}</span>
          <button
            type="button"
            className="border-0 bg-transparent text-august-primary"
            onClick={() => {
              setImportFile(undefined)
            }}
          >
            <i className="fa-solid fa-trash-alt ml-[8px]" />
          </button>
        </div>
      )}
    </Card>
  )
}
