import { AsyncIconButton } from '@shared/components/AsyncButton'
import { formatDateMessage } from '@shared/utils/date'
import { getFullName } from '@shared/utils/humanName'
import { ageString, displayGender } from '@shared/utils/person'
import styles from './review_modal.module.css'
import {
  AssignedPerson,
  AssignmentLoading,
  getAgeDisplay,
  UnassignedPerson,
} from './helpers'

type Props = {
  orderCount: number
  assignedPerson?: AssignedPerson
  unassignedPerson: UnassignedPerson
  onCancel: () => void
  onConfirm: () => void
  loading: AssignmentLoading
}
export const ConfirmAssignmentOverlay = ({
  orderCount,
  unassignedPerson,
  assignedPerson,
  onCancel,
  onConfirm,
  loading,
}: Props) => {
  if (!assignedPerson) {
    return null
  }

  const rowClasses = 'flex flex-row items-center justify-content-center w-full'
  const personContainerClasses =
    'flex flex-col items-center justify-content-center w-[184px]'
  const personNameClasses =
    'font-semibold text-[14px] leading-[16px] text-secondary-04 capitalize'
  const personDetailClasses =
    'font-normal text-[14px] leading-[16px] text-secondary-07 mt-[4px] whitespace-nowrap'

  const unassignedAgeDisplay = getAgeDisplay(unassignedPerson.age)
  const assignedAgeDisplay = getAgeDisplay(ageString(assignedPerson))

  return (
    <div className={styles.confirmOverlay}>
      <div className={styles.assignQuestion}>
        Assign {orderCount} {orderCount === 1 ? 'order' : 'orders'} to{' '}
        {getFullName(assignedPerson.name![0])}?
      </div>
      <div className={styles.assignComment}>
        You can always discontinue{' '}
        {orderCount === 1 ? 'the order' : 'those orders'} later.
      </div>

      <div className={rowClasses}>
        <div className={personContainerClasses}>
          <img
            src={unassignedPerson.photoUrl}
            className={styles.assignPic}
            loading={'eager'}
          />
        </div>
        <i
          className={`fa-light fa-arrow-right-long w-[40px] text-[40px] text-secondary-09`}
        />
        <div className={personContainerClasses}>
          <img
            src={assignedPerson.profilePhotoUrl}
            className={styles.assignPic}
            loading={'eager'}
          />
        </div>
      </div>
      <div className={`${rowClasses} mt-[16px]`}>
        <div className={personContainerClasses}>
          <div className={personNameClasses}>
            {unassignedPerson.name.toLowerCase()}
          </div>
          <div className={personDetailClasses}>
            {unassignedPerson.dateOfBirth}
            <span className={'ml-[4px]'}>{unassignedAgeDisplay}</span>
          </div>
          <div className={personDetailClasses}>{unassignedPerson.gender}</div>
        </div>
        <div className={'w-[40px]'}></div>

        <div className={personContainerClasses}>
          <div className={personNameClasses}>
            {getFullName(assignedPerson.name![0])}
          </div>
          <div className={personDetailClasses}>
            {assignedPerson.birthDate &&
              formatDateMessage(assignedPerson.birthDate!)}
            <span className={'ml-[4px]'}>{assignedAgeDisplay}</span>
          </div>
          <div className={personDetailClasses}>
            {displayGender(assignedPerson)}
          </div>
        </div>
      </div>

      <div className={`${rowClasses} mt-[40px]`}>
        <AsyncIconButton
          buttonStyle={'secondary-outline'}
          onClick={onCancel}
          width={'200px'}
          disabled={!!loading}
        >
          Not Now
        </AsyncIconButton>
        <AsyncIconButton
          buttonStyle={'primary-fill'}
          width={'200px'}
          className={'ml-[24px]'}
          onClick={onConfirm}
          disabled={loading === 'assign'}
          isLoading={loading === 'assign'}
        >
          Yes, Assign
        </AsyncIconButton>
      </div>
    </div>
  )
}
