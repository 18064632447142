import { Incident, IncidentAlert } from '@shared/types/incidents'
import { DeepNull } from '@shared/types/utilities'
import { AlertData } from './OnAlertModal'

export const durationOptions = [
  { label: 'For 1 day', value: 1 },
  { label: 'For 2 days', value: 2 },
  { label: 'For 3 days', value: 3 },
  { label: 'For 4 days', value: 4 },
  { label: 'For 5 days', value: 5 },
  { label: 'For 6 days', value: 6 },
  { label: 'For 7 days', value: 7 },
  { label: 'For 8 days', value: 8 },
  { label: 'For 9 days', value: 9 },
  { label: 'For 10 days', value: 10 },
  { label: 'For 11 days', value: 11 },
  { label: 'For 12 days', value: 12 },
  { label: 'For 13 days', value: 13 },
  { label: 'For 14 days', value: 14 },
]

export const checkEveryOptions = [
  { label: 'Check every hour', value: 1 },
  { label: 'Check every 2 hours', value: 2 },
  { label: 'Check every 4 hours', value: 4 },
  { label: 'Check every 6 hours', value: 6 },
  { label: 'Check every 8 hours', value: 8 },
  { label: 'Check every 12 hours', value: 12 },
  { label: 'Check as needed', value: true },
]

export function toIncidentAlert(
  data: AlertData,
  originalIncident: Incident
): DeepNull<IncidentAlert> {
  const now = new Date()
  const asNeeded = data.checkEveryHours.value === true
  const startedAt = now.toISOString()
  let endedAt: string | undefined

  if (data.durationDays) {
    now.setDate(now.getDate() + data.durationDays.value)
    endedAt = now.toISOString()
  } else {
    endedAt = originalIncident.alert?.endedAt
  }

  return {
    frequency: asNeeded ? null : +data.checkEveryHours.value * 60,
    asNeeded,
    note: data.instructions,
    startedAt,
    endedAt,
  }
}

export function defaultDuration(incident: Incident) {
  if (incident.alert?.startedAt === undefined) {
    return durationOptions[1]
  }

  return null
}

export function defaultCheckEvery(incident: Incident) {
  if (incident.alert?.asNeeded) {
    return checkEveryOptions[6]
  }

  if (incident.alert?.frequency === undefined) {
    return checkEveryOptions[4]
  }

  const frequencyInHours = incident.alert.frequency / 60
  return (
    checkEveryOptions.find((o) => o.value === frequencyInHours) ??
    checkEveryOptions[6]
  )
}

export function constructDefaultValues(incident: Incident): AlertData {
  return {
    durationDays: defaultDuration(incident),
    checkEveryHours: defaultCheckEvery(incident),
    instructions: incident.alert?.note ?? '',
  }
}
