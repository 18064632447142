import { AugustFieldType } from '@augusthealth/models/com/august/protos/custom_field'
import {
  DateFilter_AugustDateFilterField,
  DateFilter_DateFilterOperation,
  Report,
  ReportOnlyField,
  StringFilterOperation,
} from '@augusthealth/models/com/august/protos/report'
import { Field, ReportFields } from './models'

type FilterOption = {
  value: StringFilterOperation | DateFilter_DateFilterOperation
  label: string
}

export const getAccessor = (value?: AugustFieldType | ReportOnlyField) => ({
  augustField:
    value && value in AugustFieldType ? (value as AugustFieldType) : undefined,
  reportField:
    value && value in ReportOnlyField ? (value as ReportOnlyField) : undefined,
})

export const isDateTypeFilter = (
  filter?: AugustFieldType | ReportOnlyField
): boolean => !!filter && Object.keys(dateFilterFields).includes(filter)

export const dateFilterFields = {
  [AugustFieldType.AUGUST_FIELD_TYPE_START_DATE]:
    DateFilter_AugustDateFilterField.AUGUST_DATE_FILTER_FIELD_MOVE_IN,
  [AugustFieldType.AUGUST_FIELD_TYPE_MOVE_OUT_DATE]:
    DateFilter_AugustDateFilterField.AUGUST_DATE_FILTER_FIELD_MOVE_OUT,
  [AugustFieldType.AUGUST_FIELD_TYPE_DATE_OF_BIRTH]:
    DateFilter_AugustDateFilterField.AUGUST_DATE_FILTER_FIELD_BIRTH_DATE,
}

export const convertReport = (report: ReportFields): Report => {
  const nonEmptyFields = report.fields.filter((f) => f.fieldOption?.length)
  return {
    fields: nonEmptyFields.map((f) => {
      if (f.fieldOption!.length === 1) {
        return convertSingleField(f)
      } else {
        return convertJoinFields(f)
      }
    }),
    orderBy: {
      fieldAccessor: {
        singleField: getAccessor(report.orderByField),
      },
    },
    filters: getFilters(report),
  }
}

function convertSingleField(f: Field) {
  const columnTitle = f.columnTitle
    ? f.columnTitle
    : f.fieldOption![0].label.displayName
  return {
    displayName: columnTitle,
    fieldAccessor: {
      singleField: getAccessor(f.fieldOption![0].value),
    },
  }
}

function convertJoinFields(f: Field) {
  let formatString = ''
  let columns = ''
  f.fieldOption!.forEach((fo, index) => {
    if (index === f.fieldOption!.length - 1) {
      formatString += ` {${index}}`
      columns += ` ${fo.label.displayName}`
    } else {
      formatString += ` {${index}},`
      columns += ` ${fo.label.displayName},`
    }
  })
  const columnTitle = f.columnTitle ? f.columnTitle : columns
  return {
    displayName: columnTitle,
    fieldAccessor: {
      joinField: {
        fields: f.fieldOption!.map((fo) => getAccessor(fo.value)),
        format: formatString,
      },
    },
  }
}

function getFilters(report: ReportFields) {
  if (report.dateFilter?.field) {
    return [{ dateFilter: report.dateFilter }]
  }
  if (
    report.fieldFilter?.accessor?.augustField ||
    report.fieldFilter?.accessor?.reportField
  ) {
    return [{ fieldAccessorFilter: report.fieldFilter }]
  }
  return []
}

export const dateFilterOptions: FilterOption[] = [
  {
    value: DateFilter_DateFilterOperation.DATE_FILTER_OPERATION_EQUAL,
    label: 'Equal to',
  },
  {
    value: DateFilter_DateFilterOperation.DATE_FILTER_OPERATION_GREATER_THAN,
    label: 'After',
  },
  {
    value: DateFilter_DateFilterOperation.DATE_FILTER_OPERATION_LESS_THAN,
    label: 'Before',
  },
  {
    value: DateFilter_DateFilterOperation.DATE_FILTER_OPERATION_IS_EMPTY,
    label: 'is Empty',
  },
  {
    value: DateFilter_DateFilterOperation.DATE_FILTER_OPERATION_NON_EMPTY,
    label: 'is Not Empty',
  },
]

export const stringFilterOptions: FilterOption[] = [
  {
    value: StringFilterOperation.STRING_FILTER_OPERATION_CONTAINS,
    label: 'Contains',
  },
  {
    value: StringFilterOperation.STRING_FILTER_OPERATION_EQUALS,
    label: 'Equal to',
  },
  {
    value: StringFilterOperation.STRING_FILTER_OPERATION_NOT_EQUAL,
    label: 'Not Equal to',
  },
  {
    value: StringFilterOperation.STRING_FILTER_OPERATION_IS_EMPTY,
    label: 'is Empty',
  },
  {
    value: StringFilterOperation.STRING_FILTER_OPERATION_NON_EMPTY,
    label: 'is Not Empty',
  },
]
