import ArchivedAssessmentWrapper from 'app/pages/ArchivedDocuments/ArchivedAssessmentWrapper'
import { RouteComponentProps } from 'react-router'
import { Snapshot } from '@shared/types/snapshot'
import ReadOnlyLevelOfCare from '@app/components/Prospects/Forms/LevelOfCare/ReadOnly'
import usePerson from '@app/hooks/usePerson'

interface Props
  extends RouteComponentProps<{
    orgId: string
    facilityId: string
    personId: string
    documentId: string
  }> {
  snapshot: Snapshot
}

export function LevelOfCarePage({ match, snapshot }: Props) {
  const { orgId, facilityId, personId } = match.params

  const { person } = usePerson({
    initialData: {
      orgId,
      facilityId,
      id: personId,
    },
    dependencies: [personId],
  })

  if (person.tag === 'Loading') {
    return null
  }

  const levelOfCareAssessment = snapshot.data?.levelOfCareAssessment || {}

  return (
    <ArchivedAssessmentWrapper
      title="Level of Care"
      person={person.value}
      snapshot={snapshot}
    >
      <ReadOnlyLevelOfCare levelOfCare={levelOfCareAssessment} />
    </ArchivedAssessmentWrapper>
  )
}
