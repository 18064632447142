import { VictoryLegendProps } from 'victory'
import { DomainPropType, DomainTuple, PaddingProps } from 'victory-core'
import { BASE_FONT } from './theme'

export const DEFAULT_MAX_Y = 10

export type BarChartData<T = Record<string, string>> = {
  x: number
  y: number
  y0?: number
  data?: T
}

export type ChartData = BarChartData & {
  date: Date
}

export enum DateRange {
  ONE_YEAR = 365,
  SIX_MONTHS = 180,
  NINETY_DAYS = 90,
  SIXTY_DAYS = 60,
  THIRTY_DAYS = 30,
}

export enum ChartSize {
  FULL = 'full',
  HALF = 'half',
}

export type ChartProps = {
  id?: string
  chartStyles?: {
    height?: number
    padding?: PaddingProps
  }
  categories?: string[]
  data: BarChartData[][]
  domain?: DomainPropType
  size?: ChartSize
  xAxisTitle?: string
  yAxisTitle?: string
  victoryLegendProps?: VictoryLegendProps
}

const CHART_SIZE_MAP = {
  [ChartSize.FULL]: {
    height: 100,
    padding: { top: 6, right: 6, bottom: 2, left: 20 },
    scatterSize: 2,
    fontSize: 5,
    flyoutPadding: { top: 3, right: 5, bottom: 3, left: 5 },
    flyoutStyle: {
      fontFamily: BASE_FONT,
      fontSize: 6,
    },
    flyoutWidth: 20,
    pointerLength: 5,
    xAxisTickLabelYOnZeroData: 99,
    yAxisLabelPadding: 20,
    strokeWidth: 1,
    tickLabelsFontSize: 4,
    tickLabelsPadding: 5,
  },
  [ChartSize.HALF]: {
    height: 200,
    padding: { top: 12, right: 12, bottom: 4, left: 40 },
    scatterSize: 4,
    fontSize: 10,
    flyoutPadding: 6,
    flyoutStyle: {
      fontFamily: BASE_FONT,
      fontSize: 12,
    },
    flyoutWidth: 30,
    pointerLength: 10,
    xAxisTickLabelYOnZeroData: 201,
    yAxisLabelPadding: 40,
    strokeWidth: 2,
    tickLabelsFontSize: 8,
    tickLabelsPadding: 10,
  },
}

type GetBarChartStyles = {
  chartSize: ChartSize
  hasXAxisTitle?: boolean
  hasYAxisTitle?: boolean
  hasLegend?: boolean
}

export function getAreaChartStyles({
  chartSize,
  hasLegend,
}: GetBarChartStyles) {
  const defaultStyles = CHART_SIZE_MAP[chartSize]
  const defaultPadding = defaultStyles.padding
  if (chartSize === ChartSize.FULL) {
    return {
      ...defaultStyles,
      padding: {
        ...defaultPadding,
        top: defaultPadding.top + (hasLegend ? 6 : 0),
      },
    }
  }

  return {
    ...defaultStyles,
    padding: {
      ...defaultPadding,
      top: defaultPadding.top + (hasLegend ? 12 : 0),
    },
  }
}

export function getBarChartStyles({
  chartSize,
  hasXAxisTitle,
  hasYAxisTitle,
}: GetBarChartStyles) {
  const defaultStyles = CHART_SIZE_MAP[chartSize]
  const defaultPadding = defaultStyles.padding
  if (chartSize === ChartSize.FULL) {
    return {
      ...defaultStyles,
      height: defaultStyles.height + 13,
      padding: {
        ...defaultPadding,
        left: defaultPadding.left + (hasYAxisTitle ? 6 : 0),
        bottom: hasXAxisTitle ? 14 : 9,
      },
    }
  }

  return {
    ...defaultStyles,
    height: defaultStyles.height + 26,
    padding: {
      ...defaultPadding,
      left: defaultPadding.left + (hasYAxisTitle ? 12 : 0),
      bottom: hasXAxisTitle ? 28 : 18,
    },
  }
}

export function getColorByIndex(colors: string[], index: number) {
  return colors[index % colors.length]
}

export function getDomainYWithMargin(y: DomainTuple): DomainTuple {
  const [min, max] = y
  if (min instanceof Date || max instanceof Date) {
    return y
  }

  const margin = (max - min) * 0.1
  return [min - margin, max + margin]
}

export function convertDateObjToString(date: Date) {
  return date.toLocaleString('default', {
    month: 'short',
    day: 'numeric',
  })
}
