export enum FeatureFlagNames {
  RESET_EMAR = 'reset_emar',
  MEDICATION_INBOX = 'medication_inbox',
  FAX_INBOX = 'fax_inbox',
  QUICK_BOOKS = 'quickbooks',
  DEMO = 'demo',
  IGNORE_IN_PRODUCTION = 'ignore_in_production', // Dummy flag uses in tests and experimental features
  CARE_LOG = 'care_log',
}

const NOT_ALLOWED_ON_PRODUCTION_FEATURE_FLAGS = [
  // Add FeatureFlagNames of features in which will be ignored in Production here
  FeatureFlagNames.IGNORE_IN_PRODUCTION,
] as FeatureFlagNames[]

function isNotAllowedInProductionFlag(flag: FeatureFlagNames) {
  return NOT_ALLOWED_ON_PRODUCTION_FEATURE_FLAGS.includes(flag)
}

export function isFeatureFlagAllowedForEnv(flag: FeatureFlagNames) {
  return !(
    isNotAllowedInProductionFlag(flag) &&
    process.env.REACT_APP_ENV === 'production'
  )
}
