type Props = {
  value: string
  saveAsNegative?: boolean
}

export function convertCentsToDollars({ saveAsNegative, value }: Props) {
  const val = Number(value)
  if (isNaN(val)) {
    return ''
  }

  const sign = saveAsNegative ? -1 : 1
  return (val * 0.01 * sign).toFixed(2)
}

export function convertDollarsToCents({ saveAsNegative, value }: Props) {
  const val = Number(value)
  if (isNaN(val)) {
    return undefined
  }

  const sign = saveAsNegative ? -1 : 1
  return Number((val * 100 * sign).toFixed(0))
}
