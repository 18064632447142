import { match } from 'ts-pattern'
import {
  RefillEligibility,
  RefillEvent,
  RefillStatus,
} from '@shared/types/pharmacy_refill'
import { formatIsoTime } from '@shared/utils/date'
import { getFirstAndLastName } from '@shared/utils/humanName'

export const getRefillDisplayDetails = ({
  actionType,
  data,
  use24HourClock,
}: {
  actionType: RefillStatus
  data: RefillEvent
  use24HourClock: boolean
}): {
  icon: string
  className: string
  statement: string
} | null => {
  const formattedDate = formatIsoTime(
    data.createdBy.modificationTime,
    `MMM dd, yyyy ${use24HourClock ? 'HH:mm' : 'hh:mm aa'}`
  )
  const user = getFirstAndLastName(data.createdBy.modifiedByUserName)

  return match(actionType)
    .with(RefillStatus.SENT, () => ({
      icon: 'prescription',
      className: 'text-secondary-07',
      statement: `Refill ordered on ${formattedDate} by ${user}`,
    }))
    .with(RefillStatus.DISPENSED, () => ({
      icon: 'truck',
      className: 'text-secondary-07',
      statement: `Refill dispensed on ${formattedDate} by ${data.actingPharmacy}`,
    }))
    .with(RefillStatus.RECEIVED, () => ({
      icon: 'circle-check',
      className: 'text-secondary-07',
      statement: `Refill accepted on ${formattedDate} by ${data.actingPharmacy}`,
    }))
    .with(RefillStatus.ERROR, () => ({
      icon: 'triangle-exclamation',
      className: 'text-form-alert',
      statement: `Refill error on ${formattedDate}: ${data.message}`,
    }))
    .exhaustive()
}

export const refillIsEligible = (refillEligibility: RefillEligibility) =>
  refillEligibility ? refillEligibility.refillAllowed : false
