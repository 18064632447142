import { get } from '@augusthealth/august-frontend-form-elements'
import { Person } from '@shared/types/person'
import { DataType } from '@shared/types/snapshot'
import { PageGeneratorItem } from '@app/components/Prospects/Forms/FormLayout/type'

export function getRootAttributeFromNamePath(namePath: string) {
  const attrMatch = namePath.match(/^([A-Za-z0-9_-]*)/)
  return attrMatch?.length ? attrMatch[0] : ''
}

type LoadAllPagesProps = {
  configuration: PageGeneratorItem[]
  dataType: DataType
  person: Person
}

/**
 * Call all loaders (for each pages) from configuration
 */
function loadDataForAllPages({
  configuration,
  dataType,
  person,
}: LoadAllPagesProps) {
  const { id: pId, facilityId, orgId } = person
  if (configuration && dataType && facilityId && orgId && pId) {
    return Promise.all(
      configuration.map((conf) => {
        const { loader } = conf
        if (loader && dataType) {
          return loader({ facilityId, pId, personId: pId, orgId, dataType })
        } else {
          return Promise.resolve(person)
        }
      })
    )
  }

  return Promise.resolve(undefined)
}

/**
 * Go through saved data for each pages and return page index if required field is not filled.
 */
export function fetchPageIndexWithEmptyRequiredField({
  configuration,
  dataType,
  person,
}: LoadAllPagesProps) {
  return loadDataForAllPages({
    configuration,
    dataType,
    person,
  }).then((results) => {
    return configuration.findIndex((conf, i) => {
      const { mapResponse, requiredFields = [] } = conf
      const result = results[i]
      return requiredFields.some((name) => {
        const data = mapResponse
          ? mapResponse(result, undefined, name, undefined)
          : result.data

        return !get(data, name)
      })
    })
  })
}
