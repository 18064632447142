import React from 'react'
import styles from './styles.module.css'

type Props = {
  children: React.ReactNode
}
export const SchedulePartWrapper = ({ children, ...rest }: Props) => {
  return (
    <div className={styles.schedulePartContainer} role={'listitem'} {...rest}>
      {children}
    </div>
  )
}
