import { Occupancy_Room as OccupancyRoom } from '@augusthealth/models/com/august/protos/api/occupancy'
import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useEffect, useState } from 'react'
import { hasPermissionForFacility } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { AsyncResult } from '@shared/utils/loading'
import { fetchRooms } from '../api/rooms'

type Props = {
  orgId: string
  facilityId: string
}

export default function useOccupancyRooms({ orgId, facilityId }: Props) {
  const { user } = useUserContext()
  const { setError } = useContext(GlobalContext)
  const [occupancyRooms, setOccupancyRooms] = useState<
    AsyncResult<OccupancyRoom[], 'PermissionError'>
  >({ tag: 'Loading' })

  const refreshOccupancyRooms = async () => {
    try {
      setOccupancyRooms({ tag: 'Loading' })

      const canRequestRooms = hasPermissionForFacility({
        user,
        facility: { id: facilityId, orgId },
        permissions: [GroupPermission.GROUP_PERMISSION_OCCUPANCY_READ],
      })

      if (canRequestRooms) {
        const rooms = await fetchRooms({ orgId, id: facilityId })
        setOccupancyRooms({ tag: 'Complete', value: rooms })
      } else {
        setOccupancyRooms({ tag: 'Error', value: 'PermissionError' })
      }
    } catch (e) {
      setError(e)
      setOccupancyRooms({ tag: 'Complete', value: [] })
    }
  }

  useEffect(() => {
    if (orgId && facilityId) {
      void refreshOccupancyRooms()
    }
  }, [orgId, facilityId])

  return { occupancyRooms, refreshOccupancyRooms }
}
