import { GenericOrganization } from '@augusthealth/models/com/august/protos/generic_organization'
import StyledSelect, {
  StyledSelectProps,
} from '@shared/components/Selects/StyledSelect'
import { covidDiseaseName, influenzaName } from '@shared/utils/immunizations'

export type ManufacturerOption = {
  label: string
  value: GenericOrganization
  categories: string[]
}

const MANUFACTURER_OPTIONS: ManufacturerOption[] = [
  {
    label: 'Pfizer-BioNTech',
    value: {
      name: 'Pfizer-BioNTech',
    },
    categories: [covidDiseaseName],
  },
  {
    label: 'Moderna',
    value: {
      name: 'Moderna',
    },
    categories: [covidDiseaseName],
  },
  {
    label: 'Johnson & Johnson',
    value: {
      name: 'Johnson & Johnson',
    },
    categories: [covidDiseaseName],
  },
  {
    label: 'AstraZeneca',
    value: {
      name: 'AstraZeneca',
    },
    categories: [influenzaName],
  },
  {
    label: 'GlaxoSmithKline',
    value: {
      name: 'GlaxoSmithKline',
    },
    categories: [influenzaName],
  },
  {
    label: 'Sanofi',
    value: {
      name: 'Sanofi',
    },
    categories: [influenzaName],
  },
  {
    label: 'Seqirus',
    value: {
      name: 'Seqirus',
    },
    categories: [influenzaName],
  },
  {
    label: 'Other',
    value: {
      name: 'Other',
    },
    categories: [covidDiseaseName, influenzaName],
  },
]

type Props = Omit<StyledSelectProps, 'options' | 'value'> & {
  diseaseName?: string
  manufacturer?: GenericOrganization
}

export default function ManufacturerSelect(props: Props) {
  const { diseaseName, manufacturer, ...selectProps } = props
  const options = MANUFACTURER_OPTIONS.filter(
    (opt) => !diseaseName || opt.categories.includes(diseaseName)
  )

  return (
    <StyledSelect
      {...selectProps}
      options={options}
      value={
        MANUFACTURER_OPTIONS.find(
          (opt) => opt.value.name === manufacturer?.name
        ) || null
      }
    />
  )
}
