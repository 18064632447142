import { sortBy } from 'lodash'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import SearchBox from '@shared/components/SearchBox'
import { useUserContext } from '@shared/contexts/UserContext'
import { ContactPoint_ContactPointSystem as ContactPointSystem } from '@shared/types/contact_point'
import { UserAccount } from '@shared/types/user'
import { getPhone } from '@shared/utils/contactPoint'
import { tw } from '@shared/utils/tailwind'
import { isSuperUser } from '@shared/utils/user'
import HUD from '@app/components/HUD'
import PersonPageTitle from '@app/components/PersonPageTitle'
import {
  NameTd,
  Table,
  TableContainer,
  TdWithExtraPadding,
  TrWithHoverEffect,
} from '@app/components/Table'
import TableHeader from '@app/components/Table/TableHeader'
import useOrganizations from '@app/hooks/useOrganizations'
import rootRouteForUser from '@app/routing/groupBasedRouting'
import OrgEditPopup from './OrgEditPopup'

const TABLE_COLUMN_LABELS = ['Organization', 'Phone']

function getOrgPath(user: UserAccount) {
  return (orgId: string) => {
    return rootRouteForUser(user, orgId)
  }
}

type Props = {
  getPath?: (orgId: string) => string
  showInactiveOrganizations?: boolean
  containerClassNames?: string
}

export default function List(props: Props) {
  const { user } = useUserContext()
  const {
    getPath = getOrgPath(user),
    showInactiveOrganizations = false,
    containerClassNames = '',
  } = props

  const { organizations, inactiveOrganizations, reloadOrgs } =
    useOrganizations()
  const [openOrgPopup, setOpenOrgPopup] = useState(false)
  const [showNotice, setShowNotice] = useState(false)
  const [searchText, setSearchText] = useState('')

  const onExpire = () => {
    setShowNotice(false)
  }

  const orgsSorted = sortBy(
    organizations.tag !== 'Loading'
      ? showInactiveOrganizations
        ? [...organizations.value, ...inactiveOrganizations]
        : [...organizations.value]
      : [],
    (o) => o.name.toLocaleLowerCase()
  )
  const orgsToDisplay = searchText.trim()
    ? orgsSorted.filter((org) => {
        const text = searchText.trim().toLowerCase()
        const { name, telecom } = org
        const firstPhone = telecom?.find(
          (t) => t.system === ContactPointSystem.CONTACT_POINT_SYSTEM_PHONE
        )

        return (
          name.toLowerCase().includes(text) || firstPhone?.value?.includes(text)
        )
      })
    : orgsSorted
  const isEmpty = orgsSorted.length === 0
  const orgNotFound =
    searchText.trim() && orgsToDisplay.length === 0 && !isEmpty

  return (
    <div className={containerClassNames}>
      <PersonPageTitle subtitle title="Organizations">
        <div className={tw`flex items-center justify-center gap-[16px]`}>
          <SearchBox
            holderClassName={tw`w-[200px] grow-0`}
            onChange={setSearchText}
            value={searchText}
            autoFocus
          />
          {isSuperUser(user) && (
            <div>
              <AsyncIconButton
                buttonStyle="primary-fill"
                initialIcon="fa-plus"
                buttonSize="small"
                onClick={() => setOpenOrgPopup(true)}
              >
                Add Organization
              </AsyncIconButton>
            </div>
          )}
        </div>
      </PersonPageTitle>
      <TableContainer isEmpty={isEmpty}>
        <Table>
          <TableHeader headers={TABLE_COLUMN_LABELS} />
          <tbody>
            {(isEmpty || orgNotFound) && (
              <TrWithHoverEffect>
                <TdWithExtraPadding colSpan={TABLE_COLUMN_LABELS.length}>
                  {isEmpty ? (
                    'Organization not found'
                  ) : (
                    <span>
                      No organization matching the search filter:
                      <code
                        className={tw`ml-[8px] font-semibold leading-[16px] text-august-primary`}
                      >
                        {searchText}
                      </code>
                    </span>
                  )}
                </TdWithExtraPadding>
              </TrWithHoverEffect>
            )}
            {!isEmpty &&
              !orgNotFound &&
              orgsToDisplay.map((org) => {
                const { id: orgId, name, telecom } = org
                const phone = getPhone(telecom)

                return (
                  <TrWithHoverEffect key={`org-row-${orgId}`}>
                    <NameTd>
                      <Link to={getPath(orgId)}>{name}</Link>
                    </NameTd>
                    <TdWithExtraPadding>
                      {phone?.value || ''}
                    </TdWithExtraPadding>
                  </TrWithHoverEffect>
                )
              })}
          </tbody>
        </Table>
      </TableContainer>
      {openOrgPopup && (
        <OrgEditPopup
          organization={undefined}
          onClose={() => setOpenOrgPopup(false)}
          onSave={async () => {
            setOpenOrgPopup(false)
            await reloadOrgs()
            setShowNotice(true)
          }}
        />
      )}
      {showNotice && <HUD onExpire={onExpire}>New organization created!</HUD>}
    </div>
  )
}
