import { ApprovalData } from '@augusthealth/models/com/august/protos/medication_order'
import { PharmacyPartner } from '@augusthealth/models/com/august/protos/pharmacy_partner'
import {
  getAssignExternalIdentifierToResidentUrl,
  getBulkRejectMedicationOrdersUrl,
  getMedicationHistoryUrl,
  getMedicationOrdersInboxUrl,
  getMedicationOrdersUrl,
  getSingleMedicationOrderUrl,
  reactivateMedicationOrderUrl,
} from '@shared/api/medication_orders'
import {
  approvePharmacyDiscontinueUrl,
  approvePharmacyMessageUrl,
  getMedicationOrderDiffUrl,
  getSinglePharmacyMessageUrl,
  rejectPharmacyMessageUrl,
} from '@shared/api/pharmacy_message'
import { requestJson } from '@shared/api/request'
import { FacilityIds } from '@shared/types/facility'
import {
  MedicationHistoryEntry,
  MedicationOrder,
  OrderAndDiff,
} from '@shared/types/medication_order'
import { Person } from '@shared/types/person'
import { ResidentOrders } from '@shared/types/pharmacy_integration'
import { DeepNull } from '@shared/types/utilities'
import { CreateUpdateResponse } from '@shared/utils/task'

const withDosageDeviationHeader = (allowDosageDeviation: boolean) => {
  return allowDosageDeviation
    ? { additionalHeaders: { 'update-or-replace-version': '2' } }
    : {}
}
export const getMedicationOrders = async (
  person: Person
): Promise<MedicationOrder[]> => {
  const url = getMedicationOrdersUrl(person as Required<Person>)
  const responseJson = await requestJson({ url, method: 'GET' })
  return responseJson.data
}

export const getSingleMedicationOrder = async (
  person: Person,
  medicationId: string
): Promise<MedicationOrder> => {
  const url = getSingleMedicationOrderUrl(
    person as Required<Person>,
    medicationId
  )
  const responseJson = await requestJson({ url, method: 'GET' })
  return responseJson.data
}

export const getSinglePharmacyMessage = async (
  person: Person,
  messageId: string
): Promise<MedicationOrder> => {
  const url = getSinglePharmacyMessageUrl(person as Required<Person>, messageId)
  const responseJson = await requestJson({ url, method: 'GET' })
  return responseJson.data
}

export const approvePharmacyMessage = async (
  person: Person,
  messageId: string,
  medicationOrder: DeepNull<MedicationOrder>,
  { allowDosageDeviation }: { allowDosageDeviation: boolean }
): Promise<any> => {
  const url = approvePharmacyMessageUrl(person as Required<Person>, messageId)
  const responseJson = await requestJson({
    url,
    method: 'POST',
    body: JSON.stringify(medicationOrder),
    ...withDosageDeviationHeader(allowDosageDeviation),
  })
  return responseJson.data
}

export const approvePharmacyDiscontinue = async (
  person: Person,
  messageId: string
): Promise<any> => {
  const url = approvePharmacyDiscontinueUrl(
    person as Required<Person>,
    messageId
  )
  const responseJson = await requestJson({
    url,
    method: 'POST',
    body: JSON.stringify({}),
  })
  return responseJson.data
}

export const rejectPharmacyMessage = async (
  person: Person,
  messageId: string,
  rejectionData: DeepNull<
    Pick<ApprovalData, 'rejectionReason' | 'rejectionNote'>
  >
): Promise<any> => {
  const url = rejectPharmacyMessageUrl(person as Required<Person>, messageId)
  const responseJson = await requestJson({
    url,
    method: 'POST',
    body: JSON.stringify(rejectionData),
  })
  return responseJson.data
}

export const updateMedicationOrder = async (
  person: Person,
  medicationId: string,
  medicationOrder: DeepNull<MedicationOrder>,
  { allowDosageDeviation }: { allowDosageDeviation: boolean }
): Promise<MedicationOrder> => {
  const url = getSingleMedicationOrderUrl(
    person as Required<Person>,
    medicationId
  )
  const responseJson = await requestJson({
    url,
    method: 'POST',
    body: JSON.stringify(medicationOrder),
    ...withDosageDeviationHeader(allowDosageDeviation),
  })

  return responseJson.data
}

export const createMedicationOrder = async (
  person: Person,
  medicationOrder: Partial<MedicationOrder>
): Promise<MedicationOrder> => {
  const url = getMedicationOrdersUrl(person as Required<Person>)
  const responseJson = await requestJson({
    url,
    method: 'POST',
    body: JSON.stringify(medicationOrder),
  })

  return responseJson.data
}

export const getMedicationOrderInbox = async (
  ids: FacilityIds
): Promise<ResidentOrders[]> => {
  const url = getMedicationOrdersInboxUrl(ids)
  const responseJson = await requestJson({ url, method: 'GET' })
  return responseJson.data
}

export const bulkRejectMedicationOrders = async ({
  orgId,
  facilityId,
  pharmacyPartner,
  externalFacilityId,
  externalResidentId,
}: {
  orgId: string
  facilityId: string
  pharmacyPartner: string
  externalFacilityId: string
  externalResidentId: string
}): Promise<CreateUpdateResponse> => {
  const url = getBulkRejectMedicationOrdersUrl({
    orgId,
    facilityId,
    pharmacyPartner,
    externalFacilityId,
    externalResidentId,
  })
  const responseJson = await requestJson({ url, method: 'POST' })
  return responseJson.data
}

export const assignMedicationOrdersToResident = async ({
  person,
  body,
}: {
  person: Person
  body: {
    partner: PharmacyPartner
    externalFacilityIdentifier: string
    externalResidentIdentifier: string
  }
}) => {
  const url = getAssignExternalIdentifierToResidentUrl({
    orgId: person.orgId,
    facilityId: person.facilityId,
    personId: person.id,
  })
  const responseJson = await requestJson({
    url,
    method: 'POST',
    body: JSON.stringify(body),
  })

  return responseJson.data
}

export const getMedicationOrderDiffs = async ({
  person,
  orderGroupId,
  pharmacyMessageId,
}: {
  person: Person
  orderGroupId: string
  pharmacyMessageId: string
}): Promise<OrderAndDiff> => {
  const url = getMedicationOrderDiffUrl({
    orgId: person.orgId,
    facilityId: person.facilityId,
    personId: person.id,
    orderGroupId,
    pharmacyMessageId,
  })

  const responseJson = await requestJson({
    url,
    method: 'GET',
  })

  return responseJson.data
}

export const getMedicationOrderHistory = async ({
  person,
  orderGroupId,
}: {
  person: Person
  orderGroupId: string
}): Promise<MedicationHistoryEntry[]> => {
  const url = getMedicationHistoryUrl({
    orgId: person.orgId,
    facilityId: person.facilityId,
    personId: person.id,
    orderGroupId,
  })

  const responseJson = await requestJson({
    url,
    method: 'GET',
  })

  return responseJson.data
}

export async function reactivateMedicationOrder({
  orgId,
  facilityId,
  personId,
  orderGroupId,
}: {
  orgId: string
  facilityId: string
  personId: string
  orderGroupId: string
}) {
  const url = reactivateMedicationOrderUrl({
    orgId,
    facilityId,
    personId,
    orderGroupId,
  })

  const responseJson = await requestJson({
    url,
    method: 'POST',
  })

  return responseJson.data
}
