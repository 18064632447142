import { range } from 'lodash'
import { Controller, useFormContext } from 'react-hook-form'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { toOrdinal } from '@shared/utils/date'
import { tw } from '@shared/utils/tailwind'
import { TdWithExtraPadding, TrWithHoverEffect } from '@app/components/Table'

const options = range(1, 29).map((day) => ({
  label: toOrdinal(day),
  value: day,
}))

export default function DaySettingRow({ data }: { data: number | undefined }) {
  const { control } = useFormContext()

  return (
    <TrWithHoverEffect disableHoverEffect>
      <TdWithExtraPadding>Due Date</TdWithExtraPadding>
      <TdWithExtraPadding
        className={tw`w-32 whitespace-nowrap pr-3 text-right`}
      >
        <Controller
          defaultValue={options.find((opt) => opt.value === data)}
          control={control}
          name="defaultDueDate.dayOfMonth"
          render={({ field: { value, onChange } }) => {
            return (
              <StyledSelect
                id="due-day-selector"
                value={options.find((opt) => opt.value === value)}
                onChange={(value: OptionTypeBase<string>) =>
                  onChange(value.value)
                }
                options={options}
              />
            )
          }}
        />
      </TdWithExtraPadding>
    </TrWithHoverEffect>
  )
}
