import { HL7MessageType } from '@augusthealth/models/com/august/protos/pharmacy_facility'
import { PharmacyPartner } from '@augusthealth/models/com/august/protos/pharmacy_partner'
import { Controller, useFormContext } from 'react-hook-form'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { LabelAboveInput } from '@shared/components/Labels'
import {
  OptionTypeBase,
  StyledMultiSelect,
} from '@shared/components/Selects/StyledSelect'
import { PharmacyFacility } from '@shared/types/pharmacy_facility'
import { getReadablePharmacyPartnerName } from '@shared/utils/pharmacyPartner'
import { tw, twx } from '@shared/utils/tailwind'
import {
  IntegrationsForm,
  mapPharmacyFacilityToIntegrationsForm,
} from '@app/pages/Tools/PharmacyTools/PharmacyIntegration/helpers'
import { LabelWithInput } from '@app/pages/Tools/PharmacyTools/PharmacyIntegration/Layout'
import {
  getFormConfigurationForPharmacyPartner,
  isFieldRequiredForOutboundMessageTypes,
  partnerConfigHasNoEditableFields,
  PartnerFormConfig,
} from '@app/pages/Tools/PharmacyTools/PharmacyIntegration/PartnerConfigurationForm/helpers'

const supportedOutboundMessageOptions: OptionTypeBase<HL7MessageType>[] = [
  {
    label: 'OMP',
    value: HL7MessageType.HL7_MESSAGE_TYPE_OMP,
  },
  {
    label: 'RDE',
    value: HL7MessageType.HL7_MESSAGE_TYPE_RDE,
  },
]

type Props = {
  editingIntegration: PharmacyFacility | undefined
}

export const PartnerConfigurationForm = ({ editingIntegration }: Props) => {
  const methods = useFormContext<IntegrationsForm>()
  const selectedPharmacyPartner = methods.watch('partner')

  const partnerConfig =
    selectedPharmacyPartner &&
    getFormConfigurationForPharmacyPartner(
      methods,
      selectedPharmacyPartner,
      editingIntegration
        ? {
            tag: 'edit',
            formData: mapPharmacyFacilityToIntegrationsForm(editingIntegration),
          }
        : { tag: 'create' }
    )

  const helperTextClasses = tw`mt-4 text-center text-[14px] leading-[20px]`

  const providedByClasses = twx(
    helperTextClasses,
    'tracking-wider text-gray-07'
  )

  if (!partnerConfig) {
    const message = selectedPharmacyPartner
      ? 'Pharmacy Partner not supported in Tools'
      : 'Select a facility and pharmacy partner to begin'

    return <div className={helperTextClasses}>{message}</div>
  }

  const noFieldsToEdit =
    editingIntegration && partnerConfigHasNoEditableFields(partnerConfig)

  return (
    <>
      <ValuesProvidedByPartner
        partnerConfig={partnerConfig}
        selectedPharmacyPartner={selectedPharmacyPartner}
        providedByClasses={providedByClasses}
      />
      <ValuesProvidedByAugust
        partnerConfig={partnerConfig}
        providedByClasses={providedByClasses}
      />
      <AdditionalConfigValues
        partnerConfig={partnerConfig}
        selectedPharmacyPartner={selectedPharmacyPartner}
        providedByClasses={providedByClasses}
      />
      <AsyncIconButton
        buttonStyle={'primary-fill'}
        type={'submit'}
        isLoading={false}
        disabled={!selectedPharmacyPartner || noFieldsToEdit}
        className={tw`mt-8`}
      >
        {editingIntegration ? 'Update' : 'Save'} Integration
      </AsyncIconButton>
    </>
  )
}

const ValuesProvidedByPartner = ({
  partnerConfig,
  selectedPharmacyPartner,
  providedByClasses,
}: {
  partnerConfig: PartnerFormConfig
  selectedPharmacyPartner: PharmacyPartner
  providedByClasses: string
}) => {
  if (partnerConfig.them.length === 0) {
    return null
  }
  return (
    <>
      <div className={providedByClasses}>
        Values provided by{' '}
        {getReadablePharmacyPartnerName(selectedPharmacyPartner)}
      </div>

      {partnerConfig.them.map((field) => (
        <LabelWithInput field={field} key={field.name} />
      ))}
    </>
  )
}
const ValuesProvidedByAugust = ({
  partnerConfig,
  providedByClasses,
}: {
  partnerConfig: PartnerFormConfig
  providedByClasses: string
}) => {
  if (partnerConfig.us.length === 0) {
    return null
  }

  return (
    <>
      <div className={providedByClasses}>Values provided by August Health</div>
      {partnerConfig.us.map((field) => (
        <LabelWithInput field={field} key={field.name} />
      ))}
    </>
  )
}

const AdditionalConfigValues = ({
  partnerConfig,
  selectedPharmacyPartner,
  providedByClasses,
}: {
  partnerConfig: PartnerFormConfig
  selectedPharmacyPartner: PharmacyPartner
  providedByClasses: string
}) => {
  const methods = useFormContext<IntegrationsForm>()

  if (partnerConfig.additional.length === 0) {
    return null
  }

  const outboundMessageTypes = methods.watch('supportedOutboundMessageTypes')

  return (
    <>
      <div className={providedByClasses}>Additional Configuration</div>
      {partnerConfig.additional.map((field) => {
        if (field.name === 'supportedOutboundMessageTypes') {
          return (
            <Controller
              key={field.name}
              name={field.name}
              control={methods.control}
              render={({ field: controlField }) => (
                <div>
                  <LabelAboveInput htmlFor={controlField.name}>
                    Supported Outbound Message Type
                  </LabelAboveInput>
                  <StyledMultiSelect
                    key={controlField.name}
                    placeholder={'Select a message type'}
                    value={supportedOutboundMessageOptions.filter((opt) =>
                      controlField.value?.includes(opt.value)
                    )}
                    isClearable
                    options={supportedOutboundMessageOptions}
                    name={controlField.name}
                    id={controlField.name}
                    instanceId={controlField.name}
                    onChange={(o: OptionTypeBase<HL7MessageType>[]) => {
                      const selectedValues = o.map((opt) => opt.value)
                      controlField.onChange(selectedValues)
                    }}
                  />
                </div>
              )}
            />
          )
        }

        return (
          <LabelWithInput
            field={{
              ...field,
              required: isFieldRequiredForOutboundMessageTypes(
                field.name,
                selectedPharmacyPartner,
                outboundMessageTypes
              ),
            }}
            key={field.name}
          />
        )
      })}
    </>
  )
}
