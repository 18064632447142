import { useEffect, useState } from 'react'
import {
  getFacilityCustomImages,
  mergePatchFacility,
} from '@shared/api/facility'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { Facility } from '@shared/types/facility'
import { Loading } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'

export function ManageImageModal({
  fieldKey,
  facility,
  onClose,
}: {
  fieldKey: string
  facility: Facility
  onClose: (updated: boolean) => Promise<void>
}) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [imageData, setImageData] = useState<Loading<string>>({
    tag: 'Loading',
  })

  useEffect(() => {
    void getFacilityCustomImages({
      facility,
      customFieldKey: fieldKey,
    }).then((image) => {
      setImageData({ tag: 'Complete', value: image })
    })
  }, [])

  return (
    <AnimatedPopup title={'Manage Image'}>
      {imageData.tag === 'Loading' && (
        <div className={'mb-[32px]'}>Loading...</div>
      )}
      {imageData.tag === 'Complete' && (
        <a href={imageData.value} rel="noreferrer" target="_blank">
          <img
            className={tw`mx-auto mb-[32px] max-w-[200px]`}
            src={imageData.value}
          />
        </a>
      )}
      <AnimatedPopupFormFooter
        yesBtn={{
          label: 'Delete',
          props: {
            buttonStyle: 'danger-fill',
            onClick: async () => {
              setIsSubmitting(true)
              await mergePatchFacility({
                facility,
                patch: { customFields: { [fieldKey]: null } },
              })
              void onClose(true)
            },
          },
        }}
        noBtn={{
          action: () => void onClose(false),
        }}
        formState={{ isSubmitting }}
      />
    </AnimatedPopup>
  )
}
