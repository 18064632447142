import { useContext, useState } from 'react'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Facility } from '@shared/types/facility'
import { MedicationOrder } from '@shared/types/medication_order'
import { Person } from '@shared/types/person'
import { isCaliforniaFacility } from '@shared/utils/facilities'
import { getLastName } from '@shared/utils/humanName'
import {
  isActive,
  isPending,
  sortMedsForDestructionLog,
} from '@shared/utils/medicationStatement'
import { twx } from '@shared/utils/tailwind'
import { downloadMedLogPdf } from '@app/api/medications'
import { MedicationLog } from '@app/components/Residents/Medications/MedicationLogModal/types'
import styles from './styles.module.css'
import MedBadges from '../MedBadges'
import MedicationCounter from './MedicationCounter'

export default function MedicationLogModal({
  setShowMedLog,
  person,
  type,
  allMeds,
  currentFacility,
}: {
  setShowMedLog: (value: React.SetStateAction<boolean>) => void
  person: Person
  type: 'central' | 'destruction'
  allMeds: MedicationOrder[]
  currentFacility: Facility
}) {
  const { setError } = useContext(GlobalContext)

  let title = ''
  let currentMeds: MedicationOrder[] = allMeds.filter((med) => !isPending(med))

  const subtitle = isCaliforniaFacility(currentFacility) ? 'LIC622' : ''

  if (type === 'central') {
    currentMeds = currentMeds.filter((med) =>
      isActive(med.medicationStatement!)
    )
    title = 'Centrally Stored Medication Log'
  }

  if (type === 'destruction') {
    currentMeds = sortMedsForDestructionLog(currentMeds)
    title = 'Medication Destruction Log'
  }

  const description = 'Select the medications to include on the ' + title
  const [medsToLog, setMedsToLog] = useState<MedicationLog[]>(
    currentMeds.map((medication) => ({
      medication: medication.medicationStatement!,
      quantity: 0,
    }))
  )
  const [isLoading, setIsLoading] = useState(false)

  const increaseQuantity = (index: number) =>
    setMedsToLog([
      ...medsToLog.slice(0, index),
      {
        ...medsToLog[index],
        quantity: medsToLog[index].quantity + 1,
      },
      ...medsToLog.slice(index + 1),
    ])

  const decreaseQuantity = (index: number) =>
    setMedsToLog([
      ...medsToLog.slice(0, index),
      {
        ...medsToLog[index],
        quantity: medsToLog[index].quantity - 1,
      },
      ...medsToLog.slice(index + 1),
    ])

  const onContinue = async () => {
    try {
      setIsLoading(true)
      let medFieldName = 'storedMedications'
      if (type === 'destruction') {
        medFieldName = 'destroyedMedications'
      }
      await downloadMedLogPdf({
        orgId: person.orgId!,
        facilityId: person.facilityId!,
        personId: person!.id!,
        [medFieldName]: medsToLog,
        filename: `${getLastName(person.name)}_${type}-med-log.pdf}`,
      })
      setShowMedLog(false)
    } catch (e) {
      setError(e)
    } finally {
      setShowMedLog(false)
      setIsLoading(false)
    }
  }

  return (
    <AnimatedPopup
      containerClassName={styles.modal}
      onClickBackground={() => setShowMedLog(false)}
      subtitle={subtitle}
      title={title}
    >
      <h3 className={styles.subheader}>{description}</h3>
      <div className={styles.wrapper}>
        <div className={styles.scroll}>
          <table className={styles.table}>
            <thead className={styles.thead}>
              <tr>
                <th>Medication</th>
                <th>Strength&nbsp;/&nbsp;Form</th>
              </tr>
            </thead>
            <tbody>
              {medsToLog.map((medLogEntry, index) => (
                <tr
                  data-cy="med-log-row"
                  key={`med-log-${index}`}
                  className={styles.tr}
                >
                  <td>
                    <div
                      className={twx(
                        'flex justify-end',
                        styles.quantityContainer
                      )}
                    >
                      <div
                        className={twx(styles.name, {
                          [styles.active]: medLogEntry.quantity > 0,
                        })}
                        onClick={() => increaseQuantity(index)}
                      >
                        {medLogEntry.medication!.medication!.drugName}
                      </div>
                      <MedicationCounter
                        medication={medLogEntry}
                        index={index}
                        increaseQuantity={increaseQuantity}
                        decreaseQuantity={decreaseQuantity}
                      />
                    </div>
                  </td>
                  <td>
                    <div className={styles.dosage}>
                      {medLogEntry.medication!.medication!.strengthAndForm}
                      <MedBadges medication={medLogEntry.medication} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <AsyncIconButton
        buttonStyle="secondary-outline"
        className="mr-[16px]"
        onClick={() => setShowMedLog(false)}
        width="136px"
      >
        Cancel
      </AsyncIconButton>
      <AsyncIconButton
        buttonStyle="primary-fill"
        disabled={!medsToLog.some((med) => med.quantity > 0) || isLoading}
        id="medLogDownload"
        isLoading={isLoading}
        onClick={() => onContinue()}
        width="136px"
      >
        Download
      </AsyncIconButton>
    </AnimatedPopup>
  )
}
