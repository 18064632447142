import PhoneInput, {
  Props as PhoneInputProps,
} from '@shared/components/TextInputWithIcon/TextInputWithIcon'
import UseDropdown, { Props as DropdownProps } from './ContactPointUseDropdown'

export default function PhoneInputWithUseDropdown(
  props: PhoneInputProps & {
    dropdownProps?: DropdownProps
  }
) {
  const { dropdownProps, ...rest } = props

  if (dropdownProps) {
    return (
      <div className="flex gap-[16px]">
        <div className="w-full">
          <PhoneInput {...rest} />
        </div>
        <div>
          <UseDropdown {...dropdownProps} holderStyle={{ width: '150px' }} />
        </div>
      </div>
    )
  }

  return <PhoneInput {...rest} />
}
