import { useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { formDataToVitalsPayload } from '@shared/components/Vitals/helpers'
import { VitalsModalContents } from '@shared/components/Vitals/VitalsModalContents'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { tw } from '@shared/utils/tailwind'
import { VitalsFormData, VitalsType } from '@shared/utils/vitals'
import { createVitals } from '@app/api/vitals'
import { PortalModal } from '../components/PortalModal'

export default function EditVitalModal({
  person,
  onClose,
  vitalsType,
}: {
  person: Person
  onClose: (updated: boolean) => Promise<void>
  vitalsType: VitalsType
}) {
  const { setError } = useContext(GlobalContext)
  const today = new Date()
  const methods = useForm<VitalsFormData>({
    defaultValues: {
      date: today,
      time: today.toLocaleTimeString('en-US', { hour12: false }).slice(0, 5),
    },
    mode: 'all',
  })
  const onSubmit = async (formData: VitalsFormData) => {
    try {
      const vitals = {
        ...formDataToVitalsPayload(formData),
        personId: person.id,
      }
      await createVitals(person, vitals)
      await onClose(true)
    } catch (e) {
      setError(e)
    }
  }

  const formContent = (
    <FormProvider {...methods}>
      <VitalsModalContents
        onSubmit={onSubmit}
        onCancel={() => onClose(false)}
        vitalsType={vitalsType}
        submitOnSaveBtn
      />
    </FormProvider>
  )

  return (
    <PortalModal>
      <Modal
        open
        onClose={() => onClose(false)}
        id={'add-vitals'}
        contentClassName={tw`w-[600px] min-w-[600px] max-w-[600px]`}
      >
        {formContent}
      </Modal>
    </PortalModal>
  )
}
