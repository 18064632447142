import {
  SignableForm,
  SignableFormMetaData,
} from '@augusthealth/models/com/august/protos/signable_form'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Loading } from '@shared/utils/loading'
import { getForm } from '@app/api/form'
import ConfirmModal from '@app/components/ConfirmModal'
import { TOOLS_PDF_TEMPLATE_HELLO_SIGN_PATH } from '@app/pages/Tools/path'

type Props = {
  form: SignableFormMetaData
  onClose: () => void
}

export default function BeforeEditPopup({ form, onClose }: Props) {
  const { id, name } = form
  const [fullForm, setFullForm] = useState<Loading<SignableForm>>({
    tag: 'Loading',
  })
  const history = useHistory()
  const { setError } = useContext(GlobalContext)

  useEffect(() => {
    getForm(id!)
      .then((f) => setFullForm({ tag: 'Complete', value: f }))
      .catch(setError)
  }, [id])
  const onConfirm = async () => {
    if (id) {
      const path = TOOLS_PDF_TEMPLATE_HELLO_SIGN_PATH.replace(':id', id)
      history.push(path)
      onClose()
    }
  }
  const contentStyle = {
    maxHeight: '30vh',
    backgroundColor: '#fff',
    padding: '12px',
  }
  let content
  if (fullForm.tag === 'Loading') {
    content = <div>Loading...</div>
  } else {
    content = (
      <div>
        <h6>Details:</h6>
        <pre style={contentStyle}>
          {JSON.stringify(fullForm.value, null, 2)}
        </pre>
      </div>
    )
  }

  return (
    <ConfirmModal
      title={name!}
      content={content}
      confirmButtonConfig={{
        buttonStyle: 'primary-fill',
        children: 'Edit',
        onClick: onConfirm,
      }}
      denyButtonConfig={{
        onClick: onClose,
      }}
    />
  )
}
