import { Option } from 'effect'
import { hasPermissionForFacility } from '@shared/components/PermissionGates/PermissionGates'
import { GroupPermission } from '@shared/types/permission'
import { UserAccount } from '@shared/types/user'
import { getFirstAndLastName } from '@shared/utils/humanName'

export function getOptionalUserSelectOption({
  user,
  facility,
  requiredPermissions,
  excludeUserIds,
}: {
  user: UserAccount
  facility: { orgId: string; id: string }
  requiredPermissions: GroupPermission[]
  excludeUserIds: UserAccount['id'][]
}) {
  if (
    hasPermissionForFacility({
      user,
      permissions: requiredPermissions,
      facility,
    }) &&
    !excludeUserIds.includes(user.id)
  ) {
    return Option.some({
      label: getFirstAndLastName(user.name),
      value: user.id,
      data: user,
    })
  } else {
    return Option.none()
  }
}
