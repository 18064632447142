import { ChangeEvent, useState } from 'react'
import { LabelAboveInput } from '@shared/components/Labels'
import { Snapshot, SnapshotStatus } from '@shared/types/snapshot'
import { GtkyFields, GtkySnapshotPatch } from '@shared/utils/gtky'
import LongformAnswer from '@app/pages/Tasks/GettingToKnowYouV2/components/LongformAnswer'

type Props = {
  updateSnapshot: ({
    patch,
    status,
  }: {
    patch: GtkySnapshotPatch | null
    status: SnapshotStatus
  }) => Promise<void>
  snapshot: Snapshot
  isEditing?: boolean
}

export default function FamilyAndFriendsPage({
  updateSnapshot,
  snapshot,
  isEditing = false,
}: Props) {
  const [fields, setFields] = useState<GtkySnapshotPatch>({})

  const disabled = !isEditing

  const saveSnapshot = async (formValues = fields) =>
    await updateSnapshot({ patch: formValues, status: snapshot.status! })

  return (
    <>
      <div>
        <LabelAboveInput htmlFor={GtkyFields.MARITAL_HISTORY} uppercase={false}>
          Tell us about your marital history. If applicable, please include
          details such as your spouse's name, when you were married, how you
          met, if you've married more than once, and if you have a special song,
          etc.
        </LabelAboveInput>
        <LongformAnswer
          mode={isEditing ? 'edit' : 'view'}
          disabled={disabled}
          onBlur={async () => await saveSnapshot()}
          id={GtkyFields.MARITAL_HISTORY}
          name={GtkyFields.MARITAL_HISTORY}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            setFields({
              ...fields,
              [GtkyFields.MARITAL_HISTORY]: e.target.value,
            })
          }}
          defaultValue={
            snapshot!.data?.formData?.fields?.[GtkyFields.MARITAL_HISTORY] || ''
          }
        />
      </div>

      <div>
        <LabelAboveInput htmlFor={GtkyFields.CHILDREN} uppercase={false}>
          Do you have children? If so, what are their names, spouse’s names, and
          where do they live?
        </LabelAboveInput>
        <LongformAnswer
          mode={isEditing ? 'edit' : 'view'}
          disabled={disabled}
          onBlur={async () => await saveSnapshot()}
          id={GtkyFields.CHILDREN}
          name={GtkyFields.CHILDREN}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            setFields({
              ...fields,
              [GtkyFields.CHILDREN]: e.target.value,
            })
          }}
          defaultValue={
            snapshot!.data?.formData?.fields?.[GtkyFields.CHILDREN] || ''
          }
        />
      </div>

      <div>
        <LabelAboveInput htmlFor={GtkyFields.GRANDCHILDREN} uppercase={false}>
          Do you have grandchildren? If so, what are their names, spouse’s
          names, and where do they live?
        </LabelAboveInput>
        <LongformAnswer
          mode={isEditing ? 'edit' : 'view'}
          disabled={disabled}
          onBlur={async () => await saveSnapshot()}
          id={GtkyFields.GRANDCHILDREN}
          name={GtkyFields.GRANDCHILDREN}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            setFields({
              ...fields,
              [GtkyFields.GRANDCHILDREN]: e.target.value,
            })
          }}
          defaultValue={
            snapshot!.data?.formData?.fields?.[GtkyFields.GRANDCHILDREN] || ''
          }
        />
      </div>

      <div>
        <LabelAboveInput htmlFor={GtkyFields.OTHER_FAMILY} uppercase={false}>
          Do you have other family members that are important in your life such
          as brothers, sisters, nieces, nephews, etc.? If so, what are their
          names, spouse’s names, and where do they live?
        </LabelAboveInput>
        <LongformAnswer
          mode={isEditing ? 'edit' : 'view'}
          disabled={disabled}
          onBlur={async () => await saveSnapshot()}
          id={GtkyFields.OTHER_FAMILY}
          name={GtkyFields.OTHER_FAMILY}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            setFields({
              ...fields,
              [GtkyFields.OTHER_FAMILY]: e.target.value,
            })
          }}
          defaultValue={
            snapshot!.data?.formData?.fields?.[GtkyFields.OTHER_FAMILY] || ''
          }
        />
      </div>

      <div>
        <LabelAboveInput htmlFor={GtkyFields.OTHER_FRIENDS} uppercase={false}>
          Are there other significant people/ relationships important to you
          that you wish to share with us? If so, please list their names.
        </LabelAboveInput>
        <LongformAnswer
          mode={isEditing ? 'edit' : 'view'}
          disabled={disabled}
          onBlur={async () => await saveSnapshot()}
          id={GtkyFields.OTHER_FRIENDS}
          name={GtkyFields.OTHER_FRIENDS}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            setFields({
              ...fields,
              [GtkyFields.OTHER_FRIENDS]: e.target.value,
            })
          }}
          defaultValue={
            snapshot!.data?.formData?.fields?.[GtkyFields.OTHER_FRIENDS] || ''
          }
        />
      </div>
    </>
  )
}
