import { HumanName } from '@augusthealth/models/com/august/protos/human_name'
import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { fetchPerson } from '@shared/api/person'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { hasPermissionForFacility } from '@shared/components/PermissionGates/PermissionGates'
import SearchBox from '@shared/components/SearchBox'
import { useUserContext } from '@shared/contexts/UserContext'
import useQuery from '@shared/hooks/useQuery'
import {
  defaultPathForPerson,
  getClosedProspectsUrl,
} from '@shared/legacy_routes'
import { getFullName } from '@shared/utils/humanName'
import { personNameMatchesSearch } from '@shared/utils/person'
import { sortPeopleByLastName } from '@shared/utils/personStats'
import AddPerson from '@app/components/AddPersonPopup/AddPersonModal'
import HUD from '@app/components/HUD'
import LowPriorityLink from '@app/components/LowPriorityLink'
import { LoadingTable } from '@app/components/Residents/List'
import tableStyles from '@app/components/Residents/list.module.css'
import { prospectHeaders } from '@app/components/Residents/List/helpers'
import { Placeholder } from '@app/components/Residents/List/Placeholder'
import { Table, TableContainer } from '@app/components/Table'
import TableHeader from '@app/components/Table/TableHeader'
import ProspectsContext from '@app/contexts/ProspectsContext'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import styles from './styles.module.css'
import { ProspectsList } from './ProspectsList'

type Props = {
  match: {
    params: {
      facilityId: string
      orgId: string
      id: string
    }
  }
}
export default function List(props: Props) {
  const history = useHistory()
  const { user } = useUserContext()
  const { setOpenShareWithRPModal } = useContext(ProspectsContext)
  const { prospects, closedProspects, refreshProspects } = useCurrentFacility()

  const [openAddProspectPopup, setOpenAddProspectPopup] = useState(false)
  const [nameFilter, setNameFilter] = useState('')
  const [name, setName] = useState<HumanName[] | undefined>(undefined)

  const { facilityId, orgId } = props.match.params
  const query = useQuery()
  const closedPersonId = query.get('closed')

  useEffect(() => {
    void refreshProspects(orgId, facilityId)
  }, [orgId, facilityId])

  useEffect(() => {
    if (closedPersonId) {
      void fetchPerson({
        orgId,
        facilityId,
        personId: closedPersonId,
        fields: ['name'],
      }).then((person) => {
        setName(person.name)
      })
    }
  }, [closedPersonId])

  const redirectToNewProspect = (
    prospectFacilityId?: string,
    prospectId?: number,
    showRPModal?: boolean
  ) => {
    setOpenAddProspectPopup(false)

    if (prospectFacilityId && prospectId) {
      history.push(
        defaultPathForPerson({
          orgId,
          facilityId: prospectFacilityId,
          id: prospectId.toString(),
        })
      )
      setOpenShareWithRPModal(showRPModal || false)
    }
  }

  if (prospects.tag === 'Loading') {
    return <LoadingTable />
  }

  const canCreateProspects = hasPermissionForFacility({
    user,
    facility: { orgId, id: facilityId },
    permissions: [
      GroupPermission.GROUP_PERMISSION_PERSON_CREATE,
      GroupPermission.GROUP_PERMISSION_PERSON_CONTACT_CREATE,
    ],
  })

  const filteredProspects = prospects.value.filter((prospect) =>
    personNameMatchesSearch(prospect!.person!, nameFilter)
  )
  const sortedProspects = sortPeopleByLastName(filteredProspects)
  const hasNoProspects = prospects && prospects.value.length === 0
  const placeholderText = hasNoProspects
    ? 'No move-ins yet.'
    : `No move-ins found matching "${nameFilter}"`

  return (
    <>
      {closedPersonId && name && (
        <HUD className={styles.hudContainer}>
          <i className={`fas fa-user-slash fa-3x ${styles.icon}`} />
          <div className={styles.closedText}>Prospect Closed</div>
          <div className={styles.name}>{getFullName(name[0])}</div>
        </HUD>
      )}
      <div className={styles.prospectHeader}>
        <div className={styles.prospectTitle}>Move-ins</div>
        <div className={tableStyles.tableButtons}>
          {!hasNoProspects && (
            <SearchBox
              onChange={setNameFilter}
              value={nameFilter}
              placeholder={'Search by prospect name...'}
              labelText="Search prospects"
            />
          )}
          {canCreateProspects && (
            <AsyncIconButton
              initialIcon={'fa-user-plus'}
              id="add-prospect-button"
              buttonStyle={'primary-fill'}
              className={'ml-[12px]'}
              onClick={() => setOpenAddProspectPopup(true)}
            >
              Add New
            </AsyncIconButton>
          )}
        </div>
      </div>
      {(hasNoProspects || filteredProspects.length === 0) && (
        <TableContainer isEmpty>
          <Table>
            <TableHeader headers={prospectHeaders} />
          </Table>
          <Placeholder
            text={placeholderText}
            action={hasNoProspects ? setOpenAddProspectPopup : undefined}
            showImage={hasNoProspects}
          />
        </TableContainer>
      )}
      {filteredProspects.length > 0 && (
        <ProspectsList sortedProspects={sortedProspects} user={user} />
      )}
      {closedProspects.length > 0 && (
        <LowPriorityLink to={getClosedProspectsUrl(orgId, facilityId)}>
          View closed move-ins
        </LowPriorityLink>
      )}
      {openAddProspectPopup && (
        <AddPerson
          facilityId={facilityId}
          orgId={orgId}
          redirectOnClose={redirectToNewProspect}
        />
      )}
    </>
  )
}
