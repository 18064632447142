import ElementHolder from '@app/components/formElements/ElementHolder'

type Props = {
  title: string
  description: string
}

export default function StaticText(props: Props) {
  return (
    <ElementHolder {...props}>
      <></>
    </ElementHolder>
  )
}
