import { useContext } from 'react'
import {
  fetchAmbulatoryStatus,
  fetchFunctionalCapabilities,
  fetchHealthHistoryPart1,
  fetchHealthHistoryPart2,
  fetchOtherServices,
  mergePatchAmbulatoryStatus,
  mergePatchFunctionalCapabilities,
  mergePatchHealthHistoryPart1,
  mergePatchHealthHistoryPart2,
  mergePatchOtherServices,
} from '@shared/api/person'
import { Person } from '@shared/types/person'
import { DeepNull } from '@shared/types/utilities'
import { filterOutEmptyAllergies } from '@shared/utils/allergyIntolerance'
import { filterOutEmptyConditions } from '@shared/utils/conditions'
import { isCaliforniaFacility } from '@shared/utils/facilities'
import { filterOutEmptyMedications } from '@shared/utils/medications'
import FacilitiesContext from '@app/contexts/FacilitiesContext'
import FormLayout from '../FormLayout'
import {
  FormPageProps,
  PageGeneratorItem,
  UpdaterProps,
} from '../FormLayout/type'
import ambulatoryStatusConf from '../sharable/json/ambulatoryStatus.json'
import functionalCapabilitiesConf from './json/functionalCapabilities.json'
import healthHistoryConf from './json/healthHistory.json'
import healthHistoryContinuedConf from './json/healthHistoryContinued.json'
import otherServicesConf from './json/otherServices.json'

export function filterOutEmptyData(person: DeepNull<Person>) {
  let newPerson = { ...person }
  newPerson = filterOutEmptyAllergies(newPerson as Required<Person>)
  newPerson = filterOutEmptyConditions(newPerson as Required<Person>)
  newPerson = filterOutEmptyMedications(newPerson as Required<Person>)
  return newPerson
}

export function getFormList(isCaliforniaFacility: boolean) {
  const formList: PageGeneratorItem[] = [
    {
      menuTitle: '1. HEALTH HISTORY',
      pageTitle: 'HEALTH HISTORY',
      json: healthHistoryConf,
      loader: fetchHealthHistoryPart1,
      updater: (params: UpdaterProps) => {
        const updatedParams = { ...params }
        updatedParams.patch = filterOutEmptyData(updatedParams.patch)
        return mergePatchHealthHistoryPart1(updatedParams)
      },
    },
    {
      menuTitle: '2. HEALTH HISTORY (CONTINUED)',
      pageTitle: 'PHYSICAL DISABILITIES',
      json: healthHistoryContinuedConf,
      loader: fetchHealthHistoryPart2,
      updater: mergePatchHealthHistoryPart2,
    },
  ]

  if (isCaliforniaFacility) {
    // Only provide Ambulatory page for California Facility
    formList.push(
      {
        menuTitle: '3. AMBULATORY STATUS',
        pageTitle: 'AMBULATORY STATUS',
        json: ambulatoryStatusConf,
        loader: fetchAmbulatoryStatus,
        updater: mergePatchAmbulatoryStatus,
      },
      {
        menuTitle: '4. FUNCTIONAL CAPABILITIES',
        pageTitle: 'FUNCTIONAL CAPABILITIES',
        json: functionalCapabilitiesConf,
        loader: fetchFunctionalCapabilities,
        updater: mergePatchFunctionalCapabilities,
      },
      {
        menuTitle: '5. OTHER SERVICES',
        pageTitle: 'OTHER SERVICES',
        json: otherServicesConf,
        loader: fetchOtherServices,
        updater: mergePatchOtherServices,
      },
      {
        menuTitle: '6. REVIEW',
        pageTitle: 'REVIEW',
        content: 'Review',
      }
    )
  } else {
    formList.push(
      {
        menuTitle: '3. FUNCTIONAL CAPABILITIES',
        pageTitle: 'FUNCTIONAL CAPABILITIES',
        json: functionalCapabilitiesConf,
        loader: fetchFunctionalCapabilities,
        updater: mergePatchFunctionalCapabilities,
      },
      {
        menuTitle: '4. OTHER SERVICES',
        pageTitle: 'OTHER SERVICES',
        json: otherServicesConf,
        loader: fetchOtherServices,
        updater: mergePatchOtherServices,
      },
      {
        menuTitle: '5. REVIEW',
        pageTitle: 'REVIEW',
        content: 'Review',
      }
    )
  }

  return formList
}

export default function Form603(props: FormPageProps) {
  const { orgId, facilityId } = props.match.params
  const { facilities } = useContext(FacilitiesContext)
  const facility = facilities?.find(
    (f) => f.id === facilityId && f.orgId === orgId
  )
  if (!facility) {
    return null
  }
  const isCA = isCaliforniaFacility(facility)
  const formList = getFormList(isCA)

  return <FormLayout {...props} configuration={formList} />
}
