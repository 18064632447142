import { useContext } from 'react'
import { UrlAndContentType } from '@shared/api/request'
import Lightbox, {
  LightboxSidebarContent,
} from '@shared/components/AnimatedPopup/Lightbox/Lightbox'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import { DataType, SnapshotStatus } from '@shared/types/snapshot'
import { AsyncResult, getOrElse } from '@shared/utils/loading'
import PersonContext from '@app/contexts/PersonContext'
import useSnapshots from '@app/hooks/useSnapshots'
import styles from './styles.module.css'
import { getArchivedDocuments } from '../helpers'
import ArchivedVersions from './ArchivedVersions'
import DocumentPreview from './DocumentPreview/DocumentPreview'
import DocumentPreviewActions from './DocumentPreview/DocumentPreviewActions'
import DocumentPreviewFloatingActions from './DocumentPreview/DocumentPreviewFloatingActions'

export type Deletable =
  | false
  | {
      deleting: boolean
      onClickDelete: () => Promise<void>
      confirmView: (
        onClickDelete: () => Promise<void>,
        onCancel: () => void
      ) => React.ReactNode
    }

export type SoftDeletable = false | { callbackFn: () => void }

interface Props {
  blobData: AsyncResult<UrlAndContentType, any> | null
  dataType: DataType
  customType?: string
  deletable: Deletable
  children: React.ReactNode
  filename: string
  notFoundMessage?: string
  showPrintDownload?: boolean
  softDeletable?: SoftDeletable
  showSmallPrintButton?: boolean
  popUpActions?: React.ReactNode
  onClose: () => void
}

export default function Viewer(props: Props) {
  const {
    blobData,
    deletable,
    softDeletable,
    showSmallPrintButton = false,
    onClose,
    notFoundMessage,
  } = props

  const { person } = useContext(PersonContext)
  const deleting = deletable && deletable.deleting

  if (person === undefined || blobData?.tag === 'Loading' || deleting) {
    return (
      <Lightbox
        onClose={onClose}
        mainContent={<SimpleSpinner />}
        sidebarContent={<SidebarContent {...props}></SidebarContent>}
      />
    )
  }

  return (
    <Lightbox
      mainContent={
        <>
          <DocumentPreview
            blobData={blobData}
            notFoundMessage={notFoundMessage}
          />
          <DocumentPreviewFloatingActions
            blobData={blobData}
            softDeletable={softDeletable}
            showSmallPrintButton={showSmallPrintButton}
            showSmallRotateButton
          />
        </>
      }
      sidebarContent={<SidebarContent {...props}></SidebarContent>}
      showSidebarOnPrint
      onClose={onClose}
    />
  )
}

function SidebarContent({
  children,
  blobData,
  softDeletable,
  showSmallPrintButton,
  deletable,
  showPrintDownload,
  popUpActions,
  filename,
  dataType,
  customType,
}: Omit<Props, 'children'> & {
  children?: React.ReactNode
}) {
  const { person } = useContext(PersonContext)
  const { snapshots: archivedVersions } = useSnapshots({
    dataType,
    status: SnapshotStatus.SNAPSHOT_STATUS_ARCHIVED,
    customType,
  })
  const archived = person
    ? getArchivedDocuments({
        snapshots: getOrElse(archivedVersions, []),
        dataType,
        person,
      })
    : []

  return (
    <>
      <LightboxSidebarContent>
        {children}
        <div className={styles.mobileUploadContainer}>
          <DocumentPreview blobData={blobData} />
          <DocumentPreviewFloatingActions
            blobData={blobData}
            softDeletable={softDeletable}
            showSmallPrintButton={showSmallPrintButton}
            showSmallRotateButton
          />
        </div>
        <DocumentPreviewActions
          blobData={blobData}
          deletable={deletable}
          filename={filename}
          showPrintDownload={showPrintDownload}
        />
        {archived && archived.length > 0 && (
          <ArchivedVersions documents={archived} />
        )}
      </LightboxSidebarContent>
      {popUpActions && (
        <div className={styles.actionsContainer}>{popUpActions}</div>
      )}
    </>
  )
}
