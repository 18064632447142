import ButtonLink from '@shared/components/ButtonLink'
import { Snapshot } from '@shared/types/snapshot'

export default function DeleteAttachmentLink({
  attachment,
  setShowAttachmentDeletionConfirmation,
}: {
  attachment: Snapshot
  setShowAttachmentDeletionConfirmation: (id: string | undefined) => void
}) {
  return (
    <ButtonLink
      data-testid={`delete-attachment-${attachment.id}`}
      onClick={() => setShowAttachmentDeletionConfirmation(attachment.id)}
    >
      <i className="fa fa-fw fa-trash mr-[4px]" />
    </ButtonLink>
  )
}
