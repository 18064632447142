import {
  AdmissionsInformation_AdmissionType,
  AdmissionsInformation_DesiredRange as DesiredRange,
} from '@augusthealth/models/com/august/protos/admissions'
import { isEqual } from 'lodash'
import { mergePatchPerson } from '@shared/api/person'
import { hasPermissionForFacility } from '@shared/components/PermissionGates/PermissionGates'
import { Facility } from '@shared/types/facility'
import { GroupPermission } from '@shared/types/permission'
import { Person, ResidentStatus } from '@shared/types/person'
import { UserAccount } from '@shared/types/user'
import { DeepNull } from '@shared/types/utilities'
import {
  getAdmissionType,
  getOnHospice,
  getResidentFinancialStartDate,
  getResidentMoveInDate,
  isDischargedResident,
  isRespite,
} from '@shared/utils/person'
import { FacilityOption } from '@app/components/AddPersonPopup/helpers'
import { AdmissionTypeOption, VerifyFormData } from './types'

type VerifyAdmissionPatch = DeepNull<
  Pick<
    Person,
    | 'facilityId'
    | 'residentStatus'
    | 'hospiceStatus'
    | 'respiteStatus'
    | 'admissionsInformation'
  >
>

const makeVerifyPatch = (formData: VerifyFormData): VerifyAdmissionPatch => ({
  facilityId: formData.facility!.value.id,
  hospiceStatus: {
    onHospice: formData.hospice,
  },
  respiteStatus: {
    isRespite: formData.respite,
  },
  admissionsInformation: {
    admissionType: formData.admissionType!.value,
    bedId: formData.bedId || null,
    startDate: formData.moveInDate
      ? {
          day: formData.moveInDate!.getDate(),
          month: formData.moveInDate!.getMonth() + 1,
          year: formData.moveInDate!.getFullYear(),
        }
      : null,
    startRange: formData.admissionASAP ? DesiredRange.DESIRED_RANGE_ASAP : null,
  },
})

export const admissionTypeOptions: AdmissionTypeOption[] = [
  {
    value: AdmissionsInformation_AdmissionType.ADMISSION_TYPE_ASSISTED_LIVING,
    label: 'Assisted Living',
  },
  {
    value: AdmissionsInformation_AdmissionType.ADMISSION_TYPE_MEMORY_CARE,
    label: 'Memory Care',
  },
  {
    value:
      AdmissionsInformation_AdmissionType.ADMISSION_TYPE_INDEPENDENT_LIVING,
    label: 'Independent Living',
  },
]

export const getDefaultValues = ({
  person,
  facilitySelectOptions,
  convertToCurrentResident,
}: {
  person?: Person
  facilitySelectOptions: FacilityOption[]
  convertToCurrentResident: boolean
}): VerifyFormData => {
  if (!person) {
    return {
      facility: null,
      moveInDate: null,
      financialStartDate: null,
      admissionASAP: false,
      admissionType: null,
      respite: false,
      hospice: false,
      bedId: null,
    }
  }

  const modification =
    isDischargedResident(person) && convertToCurrentResident
      ? {
          moveInDate: null,
          bedId: null,
        }
      : {}

  return {
    admissionASAP:
      person.admissionsInformation?.startRange ===
      DesiredRange.DESIRED_RANGE_ASAP,
    facility:
      facilitySelectOptions.find(
        (option) => option.value.id === person.facilityId
      ) || null,
    moveInDate: getResidentMoveInDate(person) || null,
    financialStartDate: getResidentFinancialStartDate(person) || null,
    admissionType:
      admissionTypeOptions.find(
        (option) => option.value === getAdmissionType(person)
      ) || null,
    respite: isRespite(person),
    hospice: getOnHospice(person),
    bedId: person.admissionsInformation?.bedId || null,
    ...modification,
  }
}

export function needToReloadOccupancyStats(
  originalPerson: Person,
  updatedPerson: Person
) {
  const originalMoveInDate = getResidentMoveInDate(originalPerson)
  const updatedMoveInDate = getResidentMoveInDate(updatedPerson)

  return !isEqual(originalMoveInDate, updatedMoveInDate)
}

export const personFieldsForVerifyForm = [
  'id',
  'orgId',
  'admissionsInformation',
  'hospiceStatus',
  'respiteStatus',
  'facilityId',
  'residentStatus',
]

export function updatePerson({
  person,
  formData,
  convertToCurrentResident,
}: {
  person: Person
  formData: VerifyFormData
  convertToCurrentResident: boolean
}) {
  let patch = makeVerifyPatch(formData)

  if (convertToCurrentResident) {
    patch = {
      ...patch,
      residentStatus: ResidentStatus.RESIDENT_STATUS_CURRENT_RESIDENT,
    }
  }

  return mergePatchPerson({
    fId: person.facilityId,
    pId: person.id,
    orgId: person.orgId,
    patch,
    fields: personFieldsForVerifyForm,
  })
}

export const getFacilityOptions = (
  facilities: Facility[],
  user: UserAccount
): FacilityOption[] =>
  facilities
    .filter((f) =>
      hasPermissionForFacility({
        user,
        facility: { id: f.id, orgId: f.orgId },
        permissions: [GroupPermission.GROUP_PERMISSION_PERSON_UPDATE],
      })
    )
    .map((facility) => ({
      value: facility,
      label: facility.name!,
    }))
