import { EmptyCard } from '@shared/components/EmptyCard'
import { SignerRole, Snapshot } from '@shared/types/snapshot'
import { UserAccount } from '@shared/types/user'
import { getOrElse } from '@shared/utils/loading'
import { isResponsiblePerson } from '@shared/utils/user'
import { useDocumentsContext } from '@app/contexts/DocumentsContext'
import CardProducer from './Cards'
import {
  buildDocumentEntries,
  documentEntryMatchesFilter,
  getSortedSnapshots,
  RoutableDocument,
} from './helpers'

type CardProps = {
  selectedDocumentId?: string
  reloadData: () => Promise<void>
  user: UserAccount
}

/**
 * A component responsible for matching up completed snapshots and
 * their associated task templates.
 * @param completedSnapshots
 * @param selectedDocumentId
 * @param reloadData
 * @constructor
 */
export function DocumentCards({
  selectedDocumentId,
  reloadData,
  user,
}: CardProps) {
  const {
    person,
    snapshots: allSnapshots,
    immunizations,
    documentFilter,
    snapshotSortOrder,
  } = useDocumentsContext()
  const filteredSnapshots = buildDocumentEntries({
    snapshots: getOrElse(allSnapshots, []),
    immunizations: getOrElse(immunizations, []),
  }).filter((snapshot: Snapshot) =>
    documentEntryMatchesFilter(snapshot, documentFilter)
  )
  const completedSnapshots = getSortedSnapshots(
    filteredSnapshots,
    snapshotSortOrder
  )
  const { orgId, facilityId, id: personId } = person
  const isRP = isResponsiblePerson({ user, orgId, facilityId, personId })

  if (completedSnapshots.length === 0) {
    return (
      <EmptyCard
        version={{
          tag: 'GetStarted',
          cta: 'upload or create a new document from the sidebar actions.',
        }}
        className="mt-[8px]"
        title="No documents yet."
      />
    )
  }

  const showDocumentToRP = (document: Snapshot) => {
    if (isRP) {
      const lastModifiedId = document.lastModification?.modifiedByUserId ?? 0
      const lastModifiedByRP = lastModifiedId === user.id
      const hasRPSigner =
        document.signatureDetails?.signers?.some(
          (sig) => sig.role === SignerRole.SIGNER_ROLE_RESPONSIBLE_PARTY
        ) ?? false

      return lastModifiedByRP || hasRPSigner
    }

    return true
  }

  if (isRP && !completedSnapshots.some(showDocumentToRP)) {
    return (
      <EmptyCard
        version={{
          tag: 'GetStarted',
          cta: 'upload a new document from the sidebar actions.',
        }}
        className="mt-[8px]"
        title="No relevant documents yet."
      />
    )
  }

  return (
    <>
      {completedSnapshots.map((document, ix) => {
        const routableDocument: RoutableDocument = {
          ...document,
          routeDirectlyToDocument:
            selectedDocumentId !== undefined &&
            document.id === selectedDocumentId, // Show Document Preview if id matches
        }

        if (!showDocumentToRP(document)) {
          return null
        }

        const animationStyle = {
          '--animation-order': ix,
        } as React.CSSProperties
        return (
          <CardProducer
            animationStyle={animationStyle}
            document={routableDocument}
            immunizations={getOrElse(immunizations, [])}
            onUpdate={reloadData}
            person={person}
            key={`doc-card-${document.id}`}
          />
        )
      })}
    </>
  )
}
