import { useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Lightbox from '@shared/components/AnimatedPopup/Lightbox/Lightbox'
import { billingRouteForPayer } from '@shared/routes'
import { tw } from '@shared/utils/tailwind'
import PersonContext from '@app/contexts/PersonContext'
import useBillingPayerUserStatementPreview from '@app/hooks/useBillingPayerUserStatementPreview'
import DocumentPreview from '@app/pages/Documents/Viewer/DocumentPreview/DocumentPreview'
import DocumentPreviewFloatingActions from '@app/pages/Documents/Viewer/DocumentPreview/DocumentPreviewFloatingActions'
import Hero from './Hero'

export default function PayerStatementViewer() {
  const {
    id: personId,
    statementId: invoiceId,
    facilityId,
    orgId,
  } = useParams<{
    id: string
    facilityId: string
    orgId: string
    statementId: string
  }>()
  const { statementBlob } = useBillingPayerUserStatementPreview({
    orgId,
    facilityId,
    invoiceId,
    personId,
  })

  const { person } = useContext(PersonContext)

  const history = useHistory()
  const goBack = () => {
    history.push(billingRouteForPayer(orgId, facilityId, personId))
  }

  return (
    <Lightbox
      data-testid="payer-statement-viewer"
      mainContent={
        <div>
          <DocumentPreview blobData={statementBlob} />
          <DocumentPreviewFloatingActions
            blobData={statementBlob}
            softDeletable={false}
            showSmallPrintButton
            showSmallDownloadButton
          />
        </div>
      }
      sidebarContent={
        <div
          className={tw`flex h-full flex-col justify-between gap-3 overflow-y-auto`}
        >
          <div className={tw`flex flex-col gap-[24px]`}>
            <Hero person={{ tag: 'Complete', value: person! }} />
          </div>
        </div>
      }
      showSidebarOnPrint
      onClose={goBack}
    />
  )
}
