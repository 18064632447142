import { Array } from 'effect'
import { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { patchRoutineAdministration } from '@shared/api/careapp'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import Card from '@shared/components/Card'
import Icon from '@shared/components/Icon'
import { LabelAboveInput, requiredWhenError } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { CareAppPerson, RoutineAdministration } from '@shared/types/careapp'
import { DateAndTime } from '@shared/types/date'
import { GroupPermission } from '@shared/types/permission'
import { UserAccount } from '@shared/types/user'
import {
  fromDateTimeToDateInTimezone,
  fromDateToDateAndTimeInZone,
  toUTCIso8601DateTime,
} from '@shared/utils/date'
import { isComplete, isError, mapAsyncResult } from '@shared/utils/loading'
import {
  buildAdministeredEvent,
  getMostRecentEventId,
} from '@shared/utils/routineAdministration'
import { tw } from '@shared/utils/tailwind'
import RHFDateTimePicker from '@app/components/reactHookForm/DateTimePicker'
import useFacilityUsers from '@app/hooks/useFacilityUsers'
import { getOptionalUserSelectOption } from './helpers'
import RoutineAdministrationBanner from './RoutineAdministrationBanner'
import RoutineModal from './RoutineModal'

type MarkAsAdministeredFormData = {
  administeredBy: UserAccount
  administeredOn: DateAndTime // DateTimePicker supports DateAndTime only
}

export default function MarkAsAdministeredModal({
  careAppPerson,
  onClose,
  routineAdministration,
  facilityTimeZone,
  reload,
}: {
  careAppPerson: CareAppPerson
  onClose: () => void
  routineAdministration: RoutineAdministration
  facilityTimeZone: string
  reload: () => void
}) {
  const { orgId, facilityId } = careAppPerson
  const { id: adminId, events } = routineAdministration
  const { setError } = useContext(GlobalContext)
  const { user: loggedInUser } = useUserContext()
  const { facilityUsers } = useFacilityUsers({ orgId, facilityId })
  const facilityUserOptions = mapAsyncResult(
    facilityUsers,
    Array.filterMap((facilityUser) =>
      getOptionalUserSelectOption({
        user: facilityUser,
        requiredPermissions: [
          GroupPermission.GROUP_PERMISSION_ROUTINE_ADMINISTER,
        ],
        facility: { id: facilityId, orgId },
        excludeUserIds: [loggedInUser.id],
      })
    )
  )
  const { formState, control, handleSubmit } =
    useForm<MarkAsAdministeredFormData>({
      defaultValues: {
        administeredBy: loggedInUser,
        administeredOn: fromDateToDateAndTimeInZone(
          new Date(),
          facilityTimeZone
        ),
      },
    })
  const onSave = async ({
    administeredBy,
    administeredOn,
  }: MarkAsAdministeredFormData) => {
    try {
      const occurredAt = fromDateTimeToDateInTimezone(
        administeredOn,
        facilityTimeZone
      )
      const newEvent = buildAdministeredEvent({
        performer: {
          userId: administeredBy.id,
          userName: administeredBy.name,
        },
        occurredAt: toUTCIso8601DateTime(occurredAt),
        parentId: getMostRecentEventId(events),
      })

      await patchRoutineAdministration({
        adminId,
        careAppPerson,
        routineAdministration: { events: [newEvent] },
      })
      reload()
      onClose()
    } catch (err) {
      setError(err)
    }
  }

  return (
    <RoutineModal
      id="progress-routines-mark-as-administered-modal"
      careAppPerson={careAppPerson}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSave)}>
        <Card className={tw`flex-col`}>
          <RoutineAdministrationBanner
            facilityTimeZone={facilityTimeZone}
            routineAdministration={routineAdministration}
          />
          <Card
            className={tw`mt-[16px] flex-col bg-gray-13 px-[8px] pb-[2px] pt-[16px]`}
          >
            <div
              className={tw`mb-[16px] text-[14px] font-semibold text-gray-04`}
            >
              <Icon name="circle-check" variant="regular" />
              <span className={tw`ml-[8px]`}>Mark as Completed</span>
            </div>
            <div className={tw`mb-[16px] w-[384px]`}>
              <LabelAboveInput htmlFor="administeredBy" uppercase={false}>
                Completed By
              </LabelAboveInput>
              {isComplete(facilityUserOptions) && (
                <Controller
                  control={control}
                  name="administeredBy"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <StyledSelect
                        id="administeredBy"
                        placeholder="Team Member"
                        onChange={(opt: OptionTypeBase<string, UserAccount>) =>
                          onChange(opt.data)
                        }
                        options={facilityUserOptions.value}
                        value={
                          facilityUserOptions.value.find(
                            (opt) => opt.value === value.id
                          ) || null
                        }
                      />
                    )
                  }}
                />
              )}
              {isError(facilityUserOptions) && (
                <p>Error occurred: Could not get users for facility</p>
              )}
            </div>
            <div>
              <RHFDateTimePicker
                control={control}
                id="administeredOn"
                name="administeredOn"
                title="Completed On"
                uppercase={false}
                subLabel={requiredWhenError(
                  Boolean(formState.errors.administeredOn)
                )}
                required
                timeZone={facilityTimeZone}
              />
            </div>
          </Card>
        </Card>
        <div className={tw`mt-[24px]`}>
          <AnimatedPopupFormFooter
            yesBtn={{
              label: 'Mark as completed',
              props: { width: 'calc(50% - 8px)' },
            }}
            noBtn={{
              action: onClose,
              props: { width: 'calc(50% - 8px)' },
            }}
            formState={formState}
          />
        </div>
      </form>
    </RoutineModal>
  )
}
