import { OrgFacilitySettings } from '@augusthealth/models/com/august/protos/org_facility_settings'
import { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import {
  BasicCheckbox,
  BasicInput,
} from '@shared/components/BasicInput/BasicInput'
import { EmbossedCard } from '@shared/components/EmbossedCard'
import {
  LabelAboveInput,
  requiredLabel,
  requiredWhenError,
} from '@shared/components/Labels'
import StyledSelect from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { CardLevel } from '@shared/types/org_facility_settings'
import { StateMachineType, TaskType } from '@shared/types/task'
import {
  SETTINGS_LEVEL_OPTIONS,
  SettingsLevel,
} from '@shared/utils/orgFacilitySettings'
import { stateOptionForAbbreviation, stateOptions } from '@shared/utils/state'
import {
  addTaskDefinition,
  eventNames,
  getDefaultValues,
  STATE_MACHINE_OPTIONS,
  TaskCreationFormData,
  taskTypes,
} from './helpers'

export default function TaskDefinitionModal({
  defaultSettingsLevel = SettingsLevel.FACILITY_LEVEL,
  initialDefinition,
  name,
  onClose,
  cardLevel,
}: {
  defaultSettingsLevel?: SettingsLevel
  initialDefinition?: OrgFacilitySettings
  name?: string
  onClose: (updated: boolean) => Promise<void>
  cardLevel: CardLevel
}) {
  const { setError } = useContext(GlobalContext)
  const [showCustomTaskInputs, setShowCustomTaskInputs] = useState(
    initialDefinition?.settings?.taskDefinition?.template?.taskType ===
      TaskType.TASK_TYPE_CUSTOM_SIGNABLE_FORM
  )
  const { control, handleSubmit, register, formState, setValue } =
    useForm<TaskCreationFormData>({
      defaultValues: getDefaultValues({
        defaultSettingsLevel,
        initialDefinition,
      }),
    })
  const { errors } = formState
  const onSubmit = async (formData: TaskCreationFormData) => {
    try {
      await addTaskDefinition({
        cardLevel,
        formData,
        initialCustomType:
          initialDefinition?.settings?.taskDefinition?.template?.customType,
      })
      await onClose(true)
    } catch (e) {
      setError(e)
    }
  }

  return (
    <AnimatedPopup
      onClickBackground={() => onClose(false)}
      title={`${initialDefinition ? 'Edit' : 'Create'} Task for ${name}`}
    >
      <form data-testid="modal-form" onSubmit={handleSubmit(onSubmit)}>
        <input {...register('descriptionCopy')} type="hidden" />
        <LabelAboveInput
          htmlFor="settingsLevel"
          subLabel={requiredLabel(!!errors.settingsLevel)}
        >
          Settings Level
        </LabelAboveInput>
        <Controller
          rules={{ required: true }}
          name="settingsLevel"
          control={control}
          render={({ field: { onChange, value } }) => (
            <StyledSelect
              name="settingsLevel"
              maxMenuHeight={200}
              isDisabled
              onChange={onChange}
              value={value}
              options={SETTINGS_LEVEL_OPTIONS}
            />
          )}
        />
        <EmbossedCard className={'mt-[32px]'}>
          {cardLevel.tag === 'State/Org' && (
            <div className="mb-[16px]">
              <LabelAboveInput
                data-testid="state-label"
                htmlFor="state"
                subLabel={requiredLabel(!!errors.state)}
              >
                State
              </LabelAboveInput>

              <Controller
                rules={{ required: true }}
                name="state"
                control={control}
                render={({ field: { onChange, value } }) => {
                  const selectedState = stateOptionForAbbreviation(value)
                  return (
                    <StyledSelect
                      name="state"
                      maxMenuHeight={200}
                      onChange={({ value }) => onChange(value)}
                      value={selectedState}
                      options={stateOptions}
                    />
                  )
                }}
              />
            </div>
          )}
          <div>
            <LabelAboveInput
              htmlFor="input-taskType"
              subLabel={requiredLabel(!!errors.taskType)}
            >
              Task Type
            </LabelAboveInput>
            <Controller
              rules={{ required: true }}
              name="taskType"
              control={control}
              render={({ field: { onChange, value } }) => (
                <StyledSelect
                  id="taskType"
                  name="taskType"
                  maxMenuHeight={200}
                  isDisabled={!!initialDefinition}
                  onChange={(e: { label: string; value: TaskType }) => {
                    if (e.value === TaskType.TASK_TYPE_CUSTOM_SIGNABLE_FORM) {
                      setShowCustomTaskInputs(true)
                      setValue(
                        'customTaskStateMachine',
                        STATE_MACHINE_OPTIONS.find(
                          (opt) =>
                            opt.value ===
                            StateMachineType.STATE_MACHINE_TYPE_SIGNABLE_NOT_SHAREABLE
                        ) || null
                      )
                    } else {
                      setShowCustomTaskInputs(false)
                    }

                    onChange(e)
                    setValue('displayName', e.label)
                  }}
                  value={value}
                  options={taskTypes()}
                />
              )}
            />
          </div>
          <div className="flex">
            <div className="mr-[8px] mt-[16px] grow">
              <LabelAboveInput
                htmlFor="displayName"
                subLabel={requiredWhenError(!!errors.displayName)}
              >
                Display Name
              </LabelAboveInput>
              <BasicInput {...register('displayName', { required: true })} />
            </div>
            <div className="ml-[8px] mt-[16px] grow">
              <LabelAboveInput htmlFor="shortName">Short Name</LabelAboveInput>
              <BasicInput {...register('shortName')} />
            </div>
          </div>
        </EmbossedCard>
        {showCustomTaskInputs && (
          <div className="mt-[32px]">
            <LabelAboveInput htmlFor="customTaskStateMachine">
              State Machine Type
            </LabelAboveInput>
            <Controller
              name="customTaskStateMachine"
              control={control}
              render={({ field: { onChange, value } }) => (
                <StyledSelect
                  name="customTaskStateMachine"
                  maxMenuHeight={200}
                  onChange={onChange}
                  value={value}
                  options={STATE_MACHINE_OPTIONS}
                />
              )}
            />
          </div>
        )}
        <div className="mb-[32px] mt-[32px]">
          <LabelAboveInput htmlFor="events">Events</LabelAboveInput>
          {eventNames().map((e) => (
            <BasicCheckbox
              key={e.label}
              value={e.value}
              {...register('events')}
            >
              {e.label}
            </BasicCheckbox>
          ))}
        </div>
        <AnimatedPopupFormFooter
          yesBtn={{}}
          noBtn={{
            action: () => void onClose(false),
          }}
          formState={formState}
        />
      </form>
    </AnimatedPopup>
  )
}
