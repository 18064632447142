import { AllergyIntolerance_AllergyIntoleranceCategory } from '@augusthealth/models/com/august/protos/allergy_intolerance'
import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useState } from 'react'
import Badge from '@shared/components/Badge'
import { Person } from '@shared/types/person'
import { allergyList, diagnosesList } from '@shared/utils/person'
import { tw } from '@shared/utils/tailwind'
import styles from './styles.module.css'
import { EditableDetailsCard } from '../DetailsCard'
import EditDiagnoses from './EditDiagnoses'

export default function DiagnosesAndAllergies({
  person,
  updatePerson,
}: {
  person: Person
  updatePerson: () => Promise<void>
}) {
  const [showDiagnosesModal, setShowDiagnosesModal] = useState(false)
  const diagnoses = diagnosesList(person)
  const allergies = allergyList(person).filter(
    (allergy) =>
      allergy.category ===
      AllergyIntolerance_AllergyIntoleranceCategory.ALLERGY_INTOLERANCE_CATEGORY_MEDICATION
  )

  return (
    <EditableDetailsCard
      id="diagnosesAndAllergies"
      icon="fas fa-fw fa-info-square"
      title="Diagnoses & Medical Allergies"
      editPermissions={{
        person,
        permissions: [GroupPermission.GROUP_PERMISSION_PERSON_UPDATE],
      }}
      onEditClick={() => setShowDiagnosesModal(true)}
      editButtonId={'editDiagnosesAllergies'}
    >
      <div className={tw`flex text-[14px] font-medium leading-[20px]`}>
        <div className={styles.title}>Diagnoses</div>
        <div className={styles.conditions}>
          {diagnoses.length > 0 ? (
            diagnoses.map((diagnosis, ix) => (
              <div className={styles.diagnosisRow} key={ix}>
                {diagnosis.code!.text}
                {diagnosis.isPrimary && (
                  <Badge className={styles.primaryBadge} color="darkGray">
                    Primary
                  </Badge>
                )}
              </div>
            ))
          ) : (
            <span className="text-gray-08">None to show</span>
          )}
        </div>
      </div>
      <div className={tw`mt-[4px] flex text-[14px] font-medium leading-[20px]`}>
        <div className={styles.title}>Medical Allergies</div>
        <div className={styles.conditions}>
          {allergies.length > 0 ? (
            allergies.map((allergy, index) => (
              <div key={index}>{allergy.summary}</div>
            ))
          ) : (
            <span className="text-gray-08">None to show</span>
          )}
        </div>
      </div>
      {showDiagnosesModal && (
        <EditDiagnoses
          person={person}
          onClose={async (updated) => {
            if (updated) {
              await updatePerson()
            }
            setShowDiagnosesModal(false)
          }}
        />
      )}
    </EditableDetailsCard>
  )
}
