import { MedPass } from '@augusthealth/models/com/august/protos/api/med_pass'
import { formatRelative } from 'date-fns'
import { useState } from 'react'
import {
  ModalButtons,
  ModalTitle,
} from '@shared/components/baseMui/Modal/Layout'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { BasicRadioButton } from '@shared/components/BasicInput/BasicInput'
import Icon from '@shared/components/Icon'
import {
  DosageDeviation as DosageDeviationType,
  DosageTransitionStrategy,
} from '@shared/types/dosage_deviation'
import { MedicationOrder } from '@shared/types/medication_order'
import { Person } from '@shared/types/person'
import { formatDateTimeInZone } from '@shared/utils/date'
import { getFirstAndLastName, getFirstName } from '@shared/utils/humanName'
import {
  getMedicationOrderName,
  medicationOrderDescriptor,
} from '@shared/utils/medicationOrder'
import { isVitalOrder } from '@shared/utils/medicationStatement'
import { tw, twx } from '@shared/utils/tailwind'
import { vitalEnumToLabel } from '@shared/utils/vitals'
import {
  getFormattedAdministrationTiming,
  getTimingDisplayDataForMedication,
} from '@app/components/Residents/Medications/EditMedication/helpers'
import { useCurrentFacility } from '@app/hooks/useFacilities'

type Props = {
  person: Person
  originalMedicationOrder: MedicationOrder
  updatedMedicationOrder: MedicationOrder
  dosageDeviation: DosageDeviationType
  setDosageTransitionStrategy: React.Dispatch<
    React.SetStateAction<DosageTransitionStrategy>
  >
  dosageTransitionStrategy: DosageTransitionStrategy | null
  closeModal: () => void
  onConfirm: () => Promise<void>
  medPasses: MedPass[]
}
export const DosageDeviationModal = ({
  person,
  originalMedicationOrder,
  updatedMedicationOrder,
  dosageDeviation,
  setDosageTransitionStrategy,
  dosageTransitionStrategy,
  closeModal,
  onConfirm,
  medPasses,
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {
    currentFacility,
    settings: { use24HourClock },
  } = useCurrentFacility()

  const timeZone =
    currentFacility?.tag === 'Complete'
      ? currentFacility?.value.timeZone
      : undefined

  const isVital = isVitalOrder(originalMedicationOrder)

  const currentOrderTiming = getTimingDisplayDataForMedication({
    medicationOrder: originalMedicationOrder,
    use24HourClock,
  })
  const updatedOrderTiming = getTimingDisplayDataForMedication({
    medicationOrder: updatedMedicationOrder,
    use24HourClock,
  })

  const lastAdministrationRelative =
    dosageDeviation.administrations?.lastAdministered?.occurrence &&
    formatRelative(
      new Date(dosageDeviation.administrations.lastAdministered.occurrence),
      new Date()
    )

  const lastAdministrationDateDescriptor =
    timeZone && lastAdministrationRelative?.match(/\d{2}\/\d{2}\/\d{4}/)
      ? formatDateTimeInZone(
          dosageDeviation.administrations!.lastAdministered!.occurrence!,
          timeZone,
          { includeDate: true, dateFormat: "MMM dd, yyyy 'at' " }
        )
      : (lastAdministrationRelative ?? null)

  const deferredAdministrations =
    dosageDeviation.administrations?.deferredUpdatedAdministrations ?? []
  const immediateAdministrations =
    dosageDeviation.administrations?.immediateUpdatedAdministrations ?? []

  const relevantAdministrations =
    dosageTransitionStrategy ===
    DosageTransitionStrategy.DOSAGE_TRANSITION_STRATEGY_IMMEDIATE
      ? immediateAdministrations
      : deferredAdministrations

  const nextDoseTiming = getFormattedAdministrationTiming({
    administration: relevantAdministrations[0],
    medPasses,
  })

  return (
    <>
      <Modal
        id={'dosage-deviation'}
        open={true}
        onClose={closeModal}
        contentClassName={tw`max-w-[720px]`}
      >
        <ModalTitle>Confirm medication update</ModalTitle>
        <div className={'flex flex-1 flex-col gap-4 text-secondary-06'}>
          <div>
            You have changed the scheduling of{' '}
            {getFirstAndLastName(person.name)}’s{' '}
            <span className={tw`font-semibold text-black`}>
              {isVital
                ? vitalEnumToLabel(
                    originalMedicationOrder.medicationStatement.vitalType
                  )
                : getMedicationOrderName(originalMedicationOrder)}
            </span>{' '}
            order from:
          </div>
          <div className={tw`flex flex-row items-center gap-4`}>
            <div
              className={tw`flex flex-1 flex-col gap-2 rounded-[4px] border border-[rgb(0,0,0)]/[0.12] bg-white p-3`}
            >
              <div
                className={tw`text-[12px] font-normal uppercase leading-[266%] text-[#525252]`}
              >
                Current Order
              </div>
              <div
                className={tw`flex flex-col gap-1 text-[16px] font-normal leading-[24px] text-[#000]`}
              >
                <div>{medicationOrderDescriptor(originalMedicationOrder)}</div>
                {currentOrderTiming.map((timing, index) => (
                  <div
                    key={`current-timing-${index}`}
                    className={tw`text-[14px] leading-[20px]`}
                  >
                    {timing}
                  </div>
                ))}
              </div>
            </div>
            <Icon name={'arrow-right'} />
            <div
              className={tw`flex flex-1 flex-col gap-2 rounded-[4px] border border-[rgb(0,0,0)]/[0.12] bg-white p-3`}
            >
              <div
                className={tw`text-[12px] font-normal uppercase leading-[266%] text-[#525252]`}
              >
                Updated Order
              </div>
              <div
                className={tw`flex flex-col gap-1 text-[16px] font-normal leading-[24px] text-[#000]`}
              >
                <div>{medicationOrderDescriptor(updatedMedicationOrder)}</div>
                {updatedOrderTiming.map((timing, index) => (
                  <div
                    key={`updated-timing-${index}`}
                    className={tw`text-[14px] leading-[20px]`}
                  >
                    {timing}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div>
            {lastAdministrationDateDescriptor && (
              <span className={tw`mr-1 font-medium text-black`}>
                {getFirstName(person.name)}’s last dose was administered{' '}
                {lastAdministrationDateDescriptor}.
              </span>
            )}
            Please confirm below how you want these changes to take place.
          </div>
          <fieldset className={tw`flex flex-col gap-1`}>
            <BasicRadioButton
              name={'dosage-deviation'}
              value={'immediate'}
              radioLabelClassName={tw`leading-[16px text-[14px] text-secondary-04`}
              holderClassName={tw`mb-0 items-center`}
              onClick={() =>
                setDosageTransitionStrategy(
                  DosageTransitionStrategy.DOSAGE_TRANSITION_STRATEGY_IMMEDIATE
                )
              }
              defaultChecked={
                dosageTransitionStrategy ===
                DosageTransitionStrategy.DOSAGE_TRANSITION_STRATEGY_IMMEDIATE
              }
            >
              Update order immediately
            </BasicRadioButton>
            <BasicRadioButton
              name={'dosage-deviation'}
              value={'deferred'}
              radioLabelClassName={tw`leading-[16px text-[14px] text-secondary-04`}
              holderClassName={tw`mb-0 items-center`}
              onClick={() =>
                setDosageTransitionStrategy(
                  DosageTransitionStrategy.DOSAGE_TRANSITION_STRATEGY_DEFERRED
                )
              }
              defaultChecked={
                dosageTransitionStrategy ===
                DosageTransitionStrategy.DOSAGE_TRANSITION_STRATEGY_DEFERRED
              }
            >
              Update order on next scheduled day
            </BasicRadioButton>
          </fieldset>
          {dosageTransitionStrategy && nextDoseTiming && (
            <div
              className={twx(
                'flex flex-col gap-2 rounded-[4px] border border-secondary-10',
                'bg-secondary-11 p-4 text-[12px] font-medium leading-[16px] text-[#002326] text-secondary-04'
              )}
            >
              <div className={twx('flex flex-row items-center gap-3')}>
                <Icon
                  name={'exclamation-square'}
                  className={tw`text-secondary-04`}
                />
                <>Next dose will be {nextDoseTiming}</>
              </div>
              {relevantAdministrations.length > 1 && (
                <details className={'ml-8'}>
                  <summary>Upcoming administrations</summary>
                  {relevantAdministrations.map((administration, index) => {
                    return (
                      <div key={index} className={'my-0.5 ml-4'}>
                        {getFormattedAdministrationTiming({
                          administration,
                          medPasses,
                        })}
                      </div>
                    )
                  })}
                </details>
              )}
            </div>
          )}
        </div>
        <ModalButtons
          cancelProps={{
            onClick: closeModal,
          }}
          confirmProps={{
            disabled: !dosageTransitionStrategy || isSubmitting,
            onClick: () => {
              setIsSubmitting(true)
              void onConfirm().finally(() => {
                setIsSubmitting(false)
              })
            },
          }}
        />
      </Modal>
    </>
  )
}
