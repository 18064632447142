import {
  FieldError,
  FieldErrorsImpl,
  FieldPath,
  Merge,
  useFormContext,
} from 'react-hook-form'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { GenericTooltip } from '@shared/components/Tooltips/GenericTooltip'
import {
  MedOrderFormData,
  SlidingScaleEntryWithId,
} from '@shared/types/medication_order'
import { tw, twx } from '@shared/utils/tailwind'

type Props = {
  min?: number
  max?: number
  readOnly?: boolean
  name: FieldPath<MedOrderFormData>
  errorMessage?: string
  validate: any
  onBlur: (ev: React.ChangeEvent<HTMLInputElement>) => void
  showRangeError: boolean
  formError?: Merge<FieldError, FieldErrorsImpl<SlidingScaleEntryWithId>>
}
export const ScaleInputWithErrorTooltip = ({
  min,
  max,
  readOnly,
  onBlur,
  name,
  validate,
  showRangeError,
  formError,
}: Props) => {
  const { register, clearErrors } = useFormContext<MedOrderFormData>()

  const errorMessage = formError?.message
  const showErrorTooltip = showRangeError && errorMessage

  const inputClasses = twx(
    'bg-transparent border-t-0 border-l-0 border-r-0 border-b-[1px] border-gray-10 rounded-none w-[48px] h-[28px] max-h-[28px] px-[6px] py-[8px] text-center self-end',
    'hover:enabled:outline-none active:enabled:outline-none focus-visible:enabled:outline-none focus:enabled:outline-none',
    'active:enabled:border-gray-06 active:enabled:border-gray-06 hover:enabled:border-gray-06 focus:enabled:border-gray-06',
    {
      'border-form-alert active:enabled:border-form-alert active:enabled:border-form-alert hover:enabled:border-form-alert focus:enabled:border-form-alert':
        !!errorMessage,
    }
  )

  return (
    <div className={tw`relative h-full max-h-full`}>
      {showErrorTooltip && (
        <GenericTooltip
          className={tw`absolute bottom-inlineInput w-fit whitespace-nowrap hover:opacity-80`}
        >
          {errorMessage}
        </GenericTooltip>
      )}
      <BasicInput
        type="number"
        min={min}
        max={max}
        {...register(name, {
          valueAsNumber: true,
          validate,
          onBlur,
          onChange: () => {
            clearErrors(name)
          },
        })}
        className={inputClasses}
        containerClassName={tw`static max-h-full`}
        disabled={readOnly}
      />
    </div>
  )
}
