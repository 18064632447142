// https://developers.google.com/maps/documentation/javascript/places-autocomplete?#get-place-information
import { Address } from '@augusthealth/models/com/august/protos/address'
import { set } from 'lodash'
import { FocusEvent, useCallback, useEffect, useState } from 'react'
import {
  buildAddressLabel,
  getAddressErrors,
  getAddressFromGeocoder,
} from '@shared/utils/address'
import ElementHolder, { Props as ElementHolderProps } from '../ElementHolder'
import HashTextInput from '../HashTextInput'
import './style.css'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { mergeAddresses } from './helpers'

type PossibleValue = string | Address | null // Can be null when reset value in patch

type Props = Omit<ElementHolderProps, 'children'> & {
  name: string
  value?: PossibleValue
  onUpdate: (
    value: PossibleValue,
    name: string,
    options?: { silence?: boolean }
  ) => void
  searchByName?: boolean
  enableErrors?: boolean
  addressName?: string
  disabledAutoComplete?: boolean
}

function isAddressObject(value?: PossibleValue): value is Address {
  return typeof value === 'object' && value !== null && !Array.isArray(value)
}

export default function AddressAutocomplete(props: Props) {
  const {
    addressName,
    name,
    value: defaultAddress,
    onUpdate,
    searchByName,
    disabledAutoComplete,
    enableErrors,
  } = props
  const addressLabel = (
    isAddressObject(defaultAddress)
      ? buildAddressLabel(defaultAddress, {
          ignoreAddressLine2: true,
        })
      : defaultAddress || ''
  ) as string
  const [address, setAddress] = useState<Address>(
    isAddressObject(defaultAddress) ? defaultAddress : {}
  )
  const [unitNumberInputValue, setUnitNumberInputValue] = useState<string>(
    (address?.line && address.line[1]) || ''
  )
  const [errors, setErrors] = useState(
    isAddressObject(defaultAddress) ? getAddressErrors(defaultAddress) : []
  )
  const [disableUnitNumberInputValue, setDisableUnitNumberInputValue] =
    useState(!addressLabel)
  const updateAddress = (newA: Address) => {
    setAddress((prev) => {
      const updatedAddress = mergeAddresses(prev, newA) as Address
      onUpdate(updatedAddress, name)
      return updatedAddress
    })
  }
  const updateAddressText = (v: string) => {
    // Only keep address.text, remove other attributes in address
    updateAddress({ text: v })
  }
  const updateUnitNumber = (u: string) => {
    updateAddress({ line: ['', u] })
  }
  const unsetAddress = () => {
    setAddress({})
    // .replace(/\[\d+\]/, '') will ensure that we set address to null rather than address [null]
    onUpdate(null, name.replace(/\[\d+\]/, ''))
  }
  const updateBuildingName = (bName: string, newA?: Address) => {
    if (addressName && newA) {
      setAddress((prev) => {
        const updatedAddress = mergeAddresses(prev, newA) as Address
        const data = {}
        set(data, name, bName)
        set(data, addressName, updatedAddress)

        const parentName = name.replace(/\.name/, '')
        onUpdate(data[parentName], parentName)
        return updatedAddress
      })
    } else {
      onUpdate(bName || null, name)
    }
  }

  // https://stackoverflow.com/questions/65016565/react-why-is-that-changing-the-current-value-of-ref-from-useref-doesnt-trigger
  // Use useCallback instead useRef, since useRef will not be tracked in useEffect
  const inputR = useCallback((node) => {
    const googleMaps = window.google?.maps
    const inputRef = { current: node }

    if (inputRef?.current && googleMaps) {
      const ac = new googleMaps.places.Autocomplete(inputRef.current)
      ac.setFields(['address_component', 'name'])

      googleMaps.event.addListener(ac, 'place_changed', () => {
        const place = ac.getPlace()
        const { address_components: addressComponents, name: buildingName } =
          place
        if (addressComponents) {
          // Select an address from AutoComplete dropdown
          const selectedAddress = getAddressFromGeocoder(addressComponents)
          const label = buildAddressLabel(selectedAddress, {
            ignoreAddressLine2: true,
          })
          if (enableErrors) {
            setErrors(getAddressErrors(selectedAddress))
          }

          if (searchByName && buildingName?.trim()) {
            // For Hospital Address, to fit Hospital Name and Hospital Address at once
            if (inputRef?.current) {
              inputRef.current.value = buildingName
            }
            updateBuildingName(buildingName, selectedAddress)
          } else {
            if (inputRef?.current) {
              inputRef.current.value = label as string
            }
            updateAddress(selectedAddress)
          }
        } else if (buildingName !== undefined) {
          // Enter manually the address as string or remove with empty string
          if (searchByName) {
            updateBuildingName(buildingName)
          } else if (buildingName.trim()) {
            updateAddressText(buildingName)
          } else {
            unsetAddress()
          }
        }
      })
    }
  }, [])

  useEffect(() => {
    const defaultValue = isAddressObject(defaultAddress) ? defaultAddress : {}
    setAddress(defaultValue)
    setUnitNumberInputValue((defaultValue?.line && defaultValue.line[1]) || '')
    setDisableUnitNumberInputValue(
      !(defaultValue?.line && defaultValue.line[0])
    )
  }, [defaultAddress])

  let errorMessage
  if (errors.length) {
    const rows = errors.map((err) => {
      return <div key={err}>{err}</div>
    })
    errorMessage = <div className="alert alert-danger">{rows}</div>
  }

  const unitInput =
    searchByName || disabledAutoComplete ? null : (
      <HashTextInput
        style={{ width: '150px' }}
        placeholder="Apt / Unit #"
        value={unitNumberInputValue}
        onUpdate={updateUnitNumber}
        disabled={disableUnitNumberInputValue}
      />
    )

  const addressInput = disabledAutoComplete ? (
    <HashTextInput
      {...props}
      value={addressLabel}
      onUpdate={updateAddressText}
      childrenOnly
    />
  ) : (
    <BasicInput
      name={name}
      ref={inputR}
      defaultValue={addressLabel}
      placeholder="Enter a location"
      onBlur={(ev: FocusEvent<HTMLInputElement>) => {
        // Reset to previous value before receiving new value updated from Backend
        ev.target.value = addressLabel
      }}
    />
  )

  return (
    <ElementHolder {...props}>
      <div className="address-autocomplete">
        <div className="flex">
          <div className="grow">{addressInput}</div>
          <div className="ml-[12px]">{unitInput}</div>
        </div>
        {errorMessage}
      </div>
    </ElementHolder>
  )
}
