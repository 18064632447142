import { AugustInitialAppraisal } from '@augusthealth/models/com/august/protos/august_initial_appraisal'
import { AppraisalSettings_AppraisalCategory as AppraisalCategory } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { BasicRadioButton } from '@shared/components/BasicInput/BasicInput'
import {
  getLevelLabel,
  getValuesByCategory,
} from '@shared/utils/residentAssessment'
import { AssessmentChange } from './types'

export default function Needs({
  assessment = {},
  category,
  disabled,
  onChange,
}: {
  assessment?: AugustInitialAppraisal
  category: AppraisalCategory
  disabled: boolean
  onChange: (appraisal: AssessmentChange) => void
}) {
  const { categoryKey, customKey, levels } = category
  const { level: selectedLevel } = getValuesByCategory({
    assessment,
    category,
  })

  const uniqueKey = customKey ? `${categoryKey}-${customKey}` : categoryKey

  return (
    <>
      {levels?.map((lvItem) => {
        const { description, level, score } = lvItem
        return (
          <BasicRadioButton
            holderClassName={'mt-2'}
            key={`${uniqueKey}-radio-${level}`}
            name={`${uniqueKey}-selectedNeed`}
            value={level}
            defaultChecked={selectedLevel === level}
            disabled={disabled}
            onClick={() => {
              onChange({
                tag: 'NeedChange',
                category,
                level: level!,
              })
            }}
          >
            <div>
              <span className="font-semibold capitalize">
                {level && getLevelLabel(level)}
              </span>
              <span>
                &nbsp;- {description} ({score})
              </span>
            </div>
          </BasicRadioButton>
        )
      })}
    </>
  )
}
