import { pickBy } from 'lodash'
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { getPersonCompletionStats } from '@shared/api/person'
import Card from '@shared/components/Card'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import { admissionTasksPathForPerson } from '@shared/legacy_routes'
import {
  CompletionType,
  ResidentCompletionStats,
} from '@shared/types/api/facility_stats'
import { PersonTasks } from '@shared/types/api/person_tasks'
import { Person } from '@shared/types/person'
import { CompletionTypeToTemplateHash } from '@shared/utils/facilityStats'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { Loading } from '@shared/utils/loading'
import notEmpty from '@shared/utils/notEmpty'
import { getProfileSvgPath } from '@shared/utils/person'
import HUD from '@app/components/HUD'
import {
  getTaskForItem,
  PossibleTasks,
  ResidentTask,
} from '@app/components/Residents/CompletionStats/helpers'
import ResidentModalProducer, {
  ModalType,
} from '@app/components/Residents/CompletionStats/ModalProducer'
import PieChartIcon, {
  strokeColorForPercent,
} from '@app/components/Residents/CompletionStats/PieChartIcon'
import itemStyles from '@app/components/Residents/CompletionStats/styles.module.css'
import { TaskRow } from '@app/components/Residents/CompletionStats/TaskRow'
import dashboardStyles from '../styles.module.css'

export default function ResidentCompletionItem({
  completionStats,
  person,
  personTasks,
  visibleItems,
  refreshStats,
  profilePhotoUrl,
  availableCompletionTasks,
  completionTypeToTemplateHash,
}: {
  completionStats: ResidentCompletionStats
  person: Person
  personTasks: PersonTasks
  visibleItems: CompletionType[]
  refreshStats: () => Promise<void>
  profilePhotoUrl?: string
  availableCompletionTasks: CompletionType[]
  completionTypeToTemplateHash: CompletionTypeToTemplateHash
}) {
  const history = useHistory()

  const [personStats, setPersonStats] = useState<
    Loading<ResidentCompletionStats>
  >({ tag: 'Complete', value: completionStats })
  const [modalType, setModalType] = useState<ModalType>()
  const [showReminderNotice, setShowReminderNotice] = useState(false)

  if (personStats.tag === 'Loading') {
    return <SimpleSpinner />
  }

  // The person-level completion data may contain keys
  // that aren't present in the Facility-level view
  const statsInCompletionTasks = pickBy(
    personStats.value.completionStatus!,
    (_value, key) => availableCompletionTasks.includes(key as CompletionType)
  )

  const completeItems = Object.values(statsInCompletionTasks).filter(
    (value) => value
  )
  const percentComplete = (
    (completeItems.length / availableCompletionTasks.length) *
    100
  ).toPrecision(3)

  const residentTasks = visibleItems
    .map((stat: PossibleTasks) => {
      return getTaskForItem({
        stat,
        completionTypeToTemplateHash,
        personStats: personStats.value,
        personTasks,
        history,
        setShowDietModal: () => setModalType('diet'),
        setShowPolstUploader: () => setModalType('polst'),
        setShow602Uploader: () => setModalType('602'),
        setShowEditAmbulatory: () => setModalType('ambulatory'),
        setShowReminder: () => setModalType('rp-reminder'),
      })
    })
    .filter(notEmpty)

  const closeModals = () => {
    setModalType(undefined)
  }
  const reloadCompletionStats = async () => {
    setPersonStats({ tag: 'Loading' })
    await refreshStats()
    const updatedStats = await getPersonCompletionStats(
      person as Required<Person>
    )
    setPersonStats({ tag: 'Complete', value: updatedStats })
  }

  if (
    residentTasks.length === 0 ||
    residentTasks.every((t: ResidentTask) => t.status === 'Complete')
  ) {
    return null
  }

  const modalConfiguration =
    modalType === 'rp-reminder'
      ? { modalType, setShowReminderNotice }
      : { modalType }

  return (
    <>
      <Card
        data-testid="resident-completion-item"
        className="full-width flex-col"
      >
        <div className={itemStyles.cardTitle}>
          <Link
            to={admissionTasksPathForPerson(person as Required<Person>)}
            className={`${dashboardStyles.personName} flex items-center`}
          >
            <img
              alt=""
              className={dashboardStyles.tinyPic}
              src={
                profilePhotoUrl || getProfileSvgPath(person as Required<Person>)
              }
            />
            <span className="text-[16px] leading-[24px]">
              {getFirstAndLastName(person.name)}
            </span>
            <i className="far fa-angle-right ml-[8px]" />
          </Link>
          <h4
            style={{ color: strokeColorForPercent(+percentComplete) }}
            className="flex items-center text-[16px] font-medium leading-[24px]"
          >
            <PieChartIcon percent={+percentComplete} />
            <span className="ml-[8px]">{percentComplete}%</span>
          </h4>
        </div>
        <div className={itemStyles.tasksContainer}>
          {residentTasks.map((task, i) => (
            <TaskRow
              key={`completion-task-${task.name}-${i}`}
              task={task!}
              person={person}
            />
          ))}
        </div>
      </Card>
      <ResidentModalProducer
        person={person}
        onUpdate={reloadCompletionStats}
        onClose={closeModals}
        modalConfiguration={modalConfiguration}
        tasks={personTasks.tasks!}
      />
      {showReminderNotice && (
        <HUD onExpire={() => setShowReminderNotice(false)}>
          Reminder email sent
        </HUD>
      )}
    </>
  )
}
