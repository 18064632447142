import { useContext, useEffect, useState } from 'react'
import {
  ActionButtonHolder,
  PrintButton,
} from '@shared/components/AnimatedPopup/Lightbox/ActionButtons/ActionButtons'
import Lightbox, {
  LightboxFooterButton,
  LightboxSidebarContent,
  LightboxSidebarFooter,
} from '@shared/components/AnimatedPopup/Lightbox/Lightbox'
import { DotDivider } from '@shared/components/DotDivider'
import { SquareBadge } from '@shared/components/SquareBadge'
import CurrentFacilityContext from '@shared/contexts/CurrentFacilityContext'
import { Person } from '@shared/types/person'
import { DataType, Snapshot, SnapshotStatus } from '@shared/types/snapshot'
import { formatDate, formatDateTime } from '@shared/utils/date'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { nameOfUploader } from '@shared/utils/snapshot'
import Print, { printSelectedElements } from '@app/components/Printable'
import { calculateTotalScore } from '@app/components/Prospects/Forms/LevelOfCare'
import ReadOnlyLevelOfCare from '@app/components/Prospects/Forms/LevelOfCare/ReadOnly'
import { SignerRow } from '@app/components/SignatureFlow/Components'
import useSnapshots from '@app/hooks/useSnapshots'
import { ArchivedDocument, getArchivedDocuments } from '../helpers'
import ArchivedVersions from './ArchivedVersions'

interface Props {
  person: Person
  levelOfCare: Snapshot
  onComplete: (updated: boolean) => void
}
export default function LevelOfCareViewer({
  person,
  onComplete,
  levelOfCare,
}: Props) {
  const [printLoC, setPrintLoC] = useState(false)
  const [archived, setArchived] = useState<ArchivedDocument[]>([])
  const { snapshots: archivedVersions } = useSnapshots({
    dataType: DataType.DATA_TYPE_LEVEL_OF_CARE_CIMINO,
    status: SnapshotStatus.SNAPSHOT_STATUS_ARCHIVED,
  })

  useEffect(() => {
    if (archivedVersions.tag === 'Complete') {
      const archivedDocs = getArchivedDocuments({
        snapshots: archivedVersions.value,
        dataType: DataType.DATA_TYPE_LEVEL_OF_CARE_CIMINO,
        person,
      })
      setArchived(archivedDocs)
    }
  }, [archivedVersions.tag])

  const levelOfCareAssessment = levelOfCare.data?.levelOfCareAssessment || {}
  const totalScore = calculateTotalScore({
    data: levelOfCareAssessment,
  })
  const level = person.levelOfCare?.value

  return (
    <>
      <Lightbox
        mainContent={
          <div className="flex flex-col">
            <ReadOnlyLevelOfCare
              levelOfCare={levelOfCare.data?.levelOfCareAssessment || {}}
            />
          </div>
        }
        sidebarContent={
          <>
            <LightboxSidebarContent
              title={
                <>
                  Level of Care
                  <div className="text-[16px] leading-[24px]">
                    {getFirstAndLastName(person.name)}
                  </div>
                  <div className="mt-[12px] leading-[16px]">
                    <SquareBadge>Score {totalScore}</SquareBadge>
                    <SquareBadge className="ml-[8px]">
                      Level {level}
                    </SquareBadge>
                  </div>
                </>
              }
              message={<CompletedAt document={levelOfCare} />}
            >
              <ActionButtonHolder showTopHR>
                <PrintButton
                  onClick={() => printSelectedElements(setPrintLoC)}
                />
              </ActionButtonHolder>
              {archived?.length > 0 && (
                <ArchivedVersions documents={archived} />
              )}
            </LightboxSidebarContent>
            <LightboxSidebarFooter>
              <LightboxFooterButton onClick={() => onComplete(false)} />
            </LightboxSidebarFooter>
          </>
        }
        mainContentBackgroundColor="white"
        onClose={() => onComplete(false)}
      />
      {printLoC && (
        <Print>
          <div style={{ margin: '100px' }}>
            <div className="text-[24px]">
              <span>Level of Care for {getFirstAndLastName(person.name)}</span>
              <span className="ml-[16px] text-[16px] font-semibold uppercase leading-[24px] text-august-primary">
                Score {totalScore}
                <DotDivider />
                Level {level}{' '}
              </span>
            </div>
            <div className="secondary-text mb-[32px] leading-[16px]">
              created on{' '}
              {formatDateTime(levelOfCare.createdAt!, { includeDate: true })}
            </div>
            <ReadOnlyLevelOfCare
              levelOfCare={levelOfCare.data?.levelOfCareAssessment || {}}
            />
          </div>
        </Print>
      )}
    </>
  )
}

export function CompletedAt({ document }: { document: Snapshot }) {
  const { convertToFacilityTimezone } = useContext(CurrentFacilityContext)
  const completer = nameOfUploader(document) || 'Unknown'
  const completedAt = document.completedAt
    ? formatDate(convertToFacilityTimezone(document.completedAt))
    : 'Unknown'

  return (
    <div className="mt-[32px]">
      <SignerRow
        title={`Completed by ${completer}`}
        subTitle={completedAt}
        state={'Completed'}
      />
    </div>
  )
}
