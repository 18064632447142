import { MedPass } from '@augusthealth/models/com/august/protos/api/med_pass'
import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { FacilityIds } from '@shared/types/facility'
import { Loading } from '@shared/utils/loading'
import { fetchMedPasses } from '@app/api/med_pass'

export default function useMedPasses({ facility }: { facility?: FacilityIds }) {
  const { setError } = useContext(GlobalContext)
  const [medPasses, setMedPasses] = useState<Loading<MedPass[]>>({
    tag: 'Loading',
  })

  const loadMedPasses = () => {
    if (facility) {
      setMedPasses({ tag: 'Loading' })
      fetchMedPasses(facility)
        .then((data) =>
          setMedPasses({
            tag: 'Complete',
            value: data,
          })
        )
        .catch(setError)
    }
  }

  useEffect(() => {
    loadMedPasses()
  }, [facility?.id, facility?.orgId])

  return {
    medPasses,
    loadMedPasses,
    setMedPasses,
  }
}
