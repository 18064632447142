import { OrgFacilitySettings } from '@augusthealth/models/com/august/protos/org_facility_settings'
import { useContext, useState } from 'react'
import { deleteOrgFacilitySetting } from '@shared/api/orgFacilitySettings'
import ButtonLink from '@shared/components/ButtonLink'
import { BigDotDivider } from '@shared/components/DotDivider'
import GlobalContext from '@shared/contexts/GlobalContext'
import { CardLevel } from '@shared/types/org_facility_settings'
import { SuppressMode } from './helpers'

export default function Actions({
  onClickEdit,
  onClickSchedule,
  onClickTaskExplanation,
  scheduleTaskSettings,
  taskDefinitionSettings,
  afterRemove,
  cardLevel,
  suppressMode,
}: {
  onClickEdit: () => void
  onClickSchedule: () => void
  onClickTaskExplanation: () => void
  afterRemove: () => Promise<void>
  scheduleTaskSettings?: OrgFacilitySettings
  taskDefinitionSettings: OrgFacilitySettings
  cardLevel: CardLevel
  suppressMode: SuppressMode
}) {
  const { setError } = useContext(GlobalContext)
  const [isRemoving, setIsRemoving] = useState(false)
  const { orgId, facilityId, state, settings } = taskDefinitionSettings
  const shouldAllowEdit =
    cardLevel.orgId === orgId &&
    cardLevel.facilityId === facilityId &&
    cardLevel.state === state
  const hasExplanation = !!settings?.taskDefinition?.template?.descriptionCopy

  return (
    <div
      className="mt-[16px] flex w-full justify-between pt-[16px] text-[11px] leading-[12px]"
      style={{ borderTop: '1px solid var(--secondary-12)' }}
    >
      <div>
        <ButtonLink
          className="font-bold uppercase"
          onClick={onClickTaskExplanation}
        >
          {hasExplanation ? 'Edit' : 'Add'} Explanation
        </ButtonLink>
        <BigDotDivider />
        <ButtonLink className="font-bold uppercase" onClick={onClickSchedule}>
          {scheduleTaskSettings ? 'View/Edit' : 'Add'} Schedule
        </ButtonLink>
      </div>
      <div>
        {shouldAllowEdit && (
          <ButtonLink className="uppercase" onClick={onClickEdit}>
            <i className="fa-solid fa-pencil mr-[8px]" />
            <span className="font-bold">Edit</span>
          </ButtonLink>
        )}
        {suppressMode.tag === 'enabled' && (
          <ButtonLink
            className="ml-[24px] uppercase"
            onClick={() => {
              void suppressMode.onSuppress()
            }}
          >
            <i className="fa-solid fa-ban mr-[8px]" />
            <span className="font-bold">Suppress</span>
          </ButtonLink>
        )}
        {shouldAllowEdit && (
          <ButtonLink
            className="ml-[24px] uppercase"
            disabled={isRemoving}
            onClick={async () => {
              try {
                setIsRemoving(true)
                await deleteOrgFacilitySetting(taskDefinitionSettings)
                await afterRemove()
              } catch (e) {
                setError(e)
                setIsRemoving(false)
              }
            }}
          >
            <i className="fa-solid fa-trash-can mr-[8px]" />
            <span className="font-bold">Remove</span>
          </ButtonLink>
        )}
      </div>
    </div>
  )
}
