import { AugustInitialAppraisal } from '@augusthealth/models/com/august/protos/august_initial_appraisal'
import { AppraisalSettings_AppraisalCategory as AppraisalCategory } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { ChangeEvent, useState } from 'react'
import { getValuesByCategory } from '@shared/utils/residentAssessment'
import LongformAnswer from '@app/pages/Tasks/GettingToKnowYouV2/components/LongformAnswer'
import { AssessmentChange } from './types'

export default function Notes({
  mode,
  assessment = {},
  category,
  disabled,
  onChange,
}: {
  mode: 'edit' | 'view'
  assessment?: AugustInitialAppraisal
  category: AppraisalCategory
  disabled: boolean
  onChange: (change: AssessmentChange) => void
}) {
  const { categoryKey, customKey } = category
  const { notes: selectedNotes = '' } = getValuesByCategory({
    assessment,
    category,
  })
  const [notes, setNotes] = useState<string>(selectedNotes)
  const uniqueKey = customKey ? `${categoryKey}-${customKey}` : categoryKey

  return (
    <LongformAnswer
      mode={mode}
      placeholder="Additional details regarding service needs"
      defaultValue={selectedNotes}
      disabled={disabled}
      onBlur={async () => {
        onChange({
          tag: 'NoteChange',
          category,
          note: notes,
        })
      }}
      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
        setNotes(e.target.value.trim())
      }}
      name={`${uniqueKey}-notes`}
      className="mb-[40px] mt-[24px]"
      style={{ maxWidth: '600px' }}
    />
  )
}
