/* eslint react-hooks/exhaustive-deps: 2 */

import useSearchTextParam from '@careapp/hooks/useSearchTextParam'
import { Array, DateTime, Option, Order, pipe } from 'effect'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom-v5-compat'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import { createCtx } from '@shared/contexts/createCtx'
import useFacilityCareProgress from '@shared/hooks/useFacilityCareProgress'
import { CareGroup } from '@shared/types/care_group'
import {
  FacilityProgressRoutines,
  PersonWithRoutineAdministrations,
  RoutineAdministrationProgressType,
} from '@shared/types/careapp'
import { Facility } from '@shared/types/facility'
import { formatIsoDate } from '@shared/utils/date'
import {
  byFamilyName,
  byRoomNumber,
  personWithRoutineAdministrationsFromPerson,
} from '@shared/utils/personWithRoutineAdministrations'
import { tw } from '@shared/utils/tailwind'
import Content from '@app/components/generic/Content'
import PersonPageTitle from '@app/components/PersonPageTitle'
import Warning from '@app/components/Warning'
import { filter } from './filter'

interface ProgressContextProps {
  selectedProgressTypes: RoutineAdministrationProgressType[]
  toggleProgressType: (progressType: RoutineAdministrationProgressType) => void
  isoDate: string
  careGroupId: string | null
  shiftId: string | null
  setIsoDate: (date: Date) => void
  setCareGroupId: (optionCareGroupId: Option.Option<CareGroup>) => void
  setShiftId: (shiftId: string) => void
  facilityCareProgress: FacilityProgressRoutines
  reloadFacilityCareProgress: () => Promise<void>
  filteredPeopleWithAdministrations: PersonWithRoutineAdministrations[]
  peopleWithRoutineAdministrations: PersonWithRoutineAdministrations[]
}

export const [useProgressContext, ProgressContextProvider] =
  createCtx<ProgressContextProps>('ProgressContextProvider')

const ISO_DATE_SEARCH_PARAM_KEY = 'date'
const CARE_GROUP_ID_SEARCH_PARAM_KEY = 'careGroupId'
const SHIFT_ID_SEARCH_PARAM_KEY = 'shiftId'

export default function ProgressContext({
  facility,
  children,
}: {
  facility: Facility
  children: ReactNode
}) {
  const { timeZone } = facility

  const [searchParams, setSearchParams] = useSearchParams()

  const isoDate = searchParams.get(ISO_DATE_SEARCH_PARAM_KEY)

  useEffect(() => {
    const setIsoDateToCurrentDateAtFacility = () => {
      if (!isoDate) {
        const dateAtFacility = pipe(
          DateTime.makeZoned(new Date(), {
            timeZone,
          }),
          Option.map(DateTime.formatIsoDate),
          Option.getOrThrow
        )

        setSearchParams((prev) => {
          prev.set(ISO_DATE_SEARCH_PARAM_KEY, dateAtFacility)
          return prev
        })
      }
    }

    setIsoDateToCurrentDateAtFacility()
  }, [isoDate, setSearchParams, timeZone])

  if (!isoDate) {
    return <SimpleSpinner />
  }

  return (
    <WithIsoDate isoDate={isoDate} facility={facility}>
      {children}
    </WithIsoDate>
  )
}

function WithIsoDate({
  isoDate,
  facility,
  children,
}: {
  isoDate: string
  facility: Facility
  children: ReactNode
}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchText] = useSearchTextParam()

  const [selectedProgressTypes, setSelectedProgressTypes] = useState<
    RoutineAdministrationProgressType[]
  >([])

  const toggleProgressType = (
    progressType: RoutineAdministrationProgressType
  ) => {
    setSelectedProgressTypes((prev) => {
      if (prev.includes(progressType)) {
        return prev.filter((t) => t !== progressType)
      } else {
        return [...prev, progressType]
      }
    })
  }

  const setIsoDate = (next: Date) => {
    setSearchParams((prev) => {
      prev.set(ISO_DATE_SEARCH_PARAM_KEY, formatIsoDate(next))
      return prev
    })
  }

  const careGroupId = searchParams.get(CARE_GROUP_ID_SEARCH_PARAM_KEY)
  const setCareGroupId = (optionNext: Option.Option<CareGroup>) => {
    setSearchParams((prev) => {
      Option.match(optionNext, {
        onNone: () => prev.delete(CARE_GROUP_ID_SEARCH_PARAM_KEY),
        onSome: ({ id }) => prev.set(CARE_GROUP_ID_SEARCH_PARAM_KEY, id!),
      })
      return prev
    })
  }

  const shiftId = searchParams.get(SHIFT_ID_SEARCH_PARAM_KEY)
  const setShiftId = useCallback(
    (next: string) =>
      setSearchParams((prev) => {
        prev.set(SHIFT_ID_SEARCH_PARAM_KEY, next)
        return prev
      }),
    [setSearchParams]
  )

  const { facilityCareProgress, reloadFacilityCareProgress } =
    useFacilityCareProgress({
      facility,
      date: isoDate,
      careGroupId: careGroupId ?? undefined,
    })

  if (facilityCareProgress.tag === 'Loading') {
    return <SimpleSpinner />
  }

  if (facilityCareProgress.tag === 'Error') {
    const errorMsg =
      facilityCareProgress.value.json?.errors?.[0].message ?? 'Unknown error!'

    return (
      <Content className={tw`mt-[32px]`}>
        <PersonPageTitle title="Care Progress" />
        <Warning>{errorMsg}</Warning>
      </Content>
    )
  }

  return (
    <WithFacilityCareProgress
      facility={facility}
      searchText={searchText}
      facilityCareProgress={facilityCareProgress.value}
      reloadFacilityCareProgress={reloadFacilityCareProgress}
      selectedProgressTypes={selectedProgressTypes}
      toggleProgressType={toggleProgressType}
      isoDate={isoDate}
      careGroupId={careGroupId}
      shiftId={shiftId}
      setIsoDate={setIsoDate}
      setCareGroupId={setCareGroupId}
      setShiftId={setShiftId}
    >
      {children}
    </WithFacilityCareProgress>
  )
}

function WithFacilityCareProgress({
  facilityCareProgress,
  reloadFacilityCareProgress,
  selectedProgressTypes,
  toggleProgressType,
  isoDate,
  careGroupId,
  shiftId,
  setIsoDate,
  setCareGroupId,
  setShiftId,
  searchText,
  facility,
  children,
}: Omit<
  ProgressContextProps,
  'filteredPeopleWithAdministrations' | 'peopleWithRoutineAdministrations'
> & {
  searchText: string
  facility: Facility
  children: ReactNode
}) {
  const { people, routineAdministrations: routineAdministrationsForDate } =
    facilityCareProgress

  const routineAdministrationsForSelectedShift = useMemo(() => {
    if (shiftId) {
      return routineAdministrationsForDate.filter(
        (administration) => shiftId === administration.shiftId
      )
    } else {
      return routineAdministrationsForDate
    }
  }, [routineAdministrationsForDate, shiftId])

  const peopleWithRoutineAdministrations = useMemo(() => {
    return people.map(
      personWithRoutineAdministrationsFromPerson(
        routineAdministrationsForSelectedShift
      )
    )
  }, [people, routineAdministrationsForSelectedShift])

  const filteredPeopleWithAdministrations = useMemo(() => {
    return pipe(
      filter({
        peopleWithRoutineAdministrations,
        searchText,
        routineAdministrationProgressTypes: selectedProgressTypes,
        facilityTimeZone: facility.timeZone,
      }),
      Array.sort(Order.combineAll([byFamilyName, byRoomNumber]))
    )
  }, [
    facility.timeZone,
    peopleWithRoutineAdministrations,
    searchText,
    selectedProgressTypes,
  ])

  return (
    <ProgressContextProvider
      value={{
        selectedProgressTypes,
        toggleProgressType,
        isoDate,
        careGroupId,
        shiftId,
        setIsoDate,
        setCareGroupId,
        setShiftId,
        facilityCareProgress,
        reloadFacilityCareProgress,
        filteredPeopleWithAdministrations,
        peopleWithRoutineAdministrations,
      }}
    >
      {children}
    </ProgressContextProvider>
  )
}
