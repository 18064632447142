import { AsyncIconButton } from '@shared/components/AsyncButton'
import { NestedModalConfirmation } from '@shared/components/baseMui/Modal/Layout'
import { twx } from '@shared/utils/tailwind'

export default function ConfirmDelete({
  onCancel,
  onConfirm,
  title,
  confirmText,
}: {
  title: string
  confirmText: string
  onCancel: () => void
  onConfirm: () => void
}) {
  return (
    <NestedModalConfirmation data-testid={'confirm-document-delete'}>
      <h2
        className={twx(
          'mb-4 text-[24px] font-medium leading-[38px] text-secondary-03'
        )}
      >
        {title}
      </h2>
      <div className={twx('text-[18px] text-secondary-font-color')}>
        {confirmText}
      </div>
      <div className={twx('mt-[32px] flex')}>
        <AsyncIconButton
          buttonStyle={'secondary-outline'}
          className={'mr-[16px]'}
          onClick={onCancel}
          buttonSize={'xlarge'}
        >
          Cancel
        </AsyncIconButton>
        <AsyncIconButton
          onClick={onConfirm}
          buttonStyle={'danger-fill'}
          buttonSize={'xlarge'}
        >
          Delete
        </AsyncIconButton>
      </div>
    </NestedModalConfirmation>
  )
}
