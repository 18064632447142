import classNames from 'classnames'
import {
  Controller,
  FieldPath,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { Label } from '@shared/components/Labels'
import StyledSelect, {
  CreatableStyledSelect,
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { ErrorDropdownStyleConfig } from '@shared/components/Selects/utils'
import { MedOrderFormData } from '@shared/types/medication_order'
import {
  validateOptionalPositiveNumber,
  validatePositiveNumber,
} from '@shared/utils/formValidationFunctions'
import { getQuantityUnitOptions } from '@shared/utils/medicationStatement'
import { tw } from '@shared/utils/tailwind'
import styles from '../styles.module.css'
import { updateDosesToMatchDoseUnit } from '../helpers'

type Props = {
  doseIndex: number
  readOnly: boolean
}
export const PRNForm = ({ doseIndex, readOnly }: Props) => {
  const methods = useFormContext<MedOrderFormData>()
  const { register, formState, control, watch, trigger } = methods

  const data = useWatch({
    control,
    name: `doses.${doseIndex}`,
  })

  const sharedIconClasses = classNames('fa-fw fa-solid text-[14px] mr-[8px]', {
    'text-gray-07': readOnly,
    'text-primary-light': !readOnly,
  })
  const sublabelClasses =
    'text-[14px] text-gray-07 font-medium leading-[16px] whitespace-nowrap'
  const inputClasses = tw`w-[64px]`

  const maxDosageValueName: `doses.${number}.maxDosage.value` = `doses.${doseIndex}.maxDosage.value`
  const maxDosageUnitName: `doses.${number}.maxDosage.unit` = `doses.${doseIndex}.maxDosage.unit`
  const minTimeName: `doses.${number}.minTime` = `doses.${doseIndex}.minTime`
  const quantityUnitName: `doses.${number}.quantity.unit` = `doses.${doseIndex}.quantity.unit`

  const maxDosage = watch(maxDosageValueName)
  const maxDosageHasValue = maxDosage !== undefined && !isNaN(maxDosage)

  const quantityUnitOptions: OptionTypeBase[] = getQuantityUnitOptions(
    watch(`doses.${doseIndex}.quantity.unit`)
  )

  const changeDoseUnit = ({
    newUnit,
    inputName,
  }: {
    newUnit: string
    inputName: FieldPath<MedOrderFormData>
  }) => {
    if (inputName === maxDosageUnitName) {
      methods.setValue(inputName, newUnit)
      methods.setValue('hasCustomPrnMaxDosage', true)
    } else {
      updateDosesToMatchDoseUnit({
        newUnit: newUnit,
        methods,
      })
    }

    if (formState.isSubmitted) {
      void trigger()
    }
  }

  return (
    <>
      <div className={styles.prnRow}>
        <div className={styles.prnLabel}>
          <i className={`${sharedIconClasses} fa-coins`} />
          Quantity
        </div>
        <Label visuallyHidden htmlFor={`doses.${doseIndex}.quantity.value`}>
          Quantity
        </Label>
        <BasicInput
          disabled={readOnly}
          type={'number'}
          {...register(`doses.${doseIndex}.quantity.value`, {
            valueAsNumber: true,
            validate: validatePositiveNumber,
          })}
          step={'any'}
          placeholder={'#'}
          className={inputClasses}
          showErrorBorder={
            !!formState.errors.doses?.[doseIndex]?.quantity?.value
          }
        />
        <Controller
          control={control}
          name={quantityUnitName}
          rules={{ required: true }}
          render={({ fieldState: { error } }) => {
            return (
              <>
                <Label visuallyHidden htmlFor={quantityUnitName}>
                  PRN Quantity Unit
                </Label>
                <CreatableStyledSelect
                  inputId={quantityUnitName}
                  options={quantityUnitOptions}
                  placeholder="Select..."
                  onChange={(e: OptionTypeBase) => {
                    changeDoseUnit({
                      newUnit: e.value,
                      inputName: quantityUnitName,
                    })
                  }}
                  value={quantityUnitOptions.find(
                    (opt) => opt.value === data?.quantity.unit
                  )}
                  isDisabled={readOnly}
                  styles={ErrorDropdownStyleConfig({ error: !!error })}
                  onCreateOption={(val: string) => {
                    if (val.trim().length > 0) {
                      if (
                        !quantityUnitOptions.find((opt) => opt.value === val)
                      ) {
                        quantityUnitOptions.push({
                          label: val,
                          value: val,
                        })
                      }

                      changeDoseUnit({
                        newUnit: val,
                        inputName: quantityUnitName,
                      })
                    }
                  }}
                />
                <span className={sublabelClasses}>per dose</span>
              </>
            )
          }}
        />
      </div>
      <div className={styles.prnRow}>
        <div className={styles.prnLabel}>
          <i className={`${sharedIconClasses} fa-mortar-pestle`} />
          Max Dosage
        </div>
        <BasicInput
          disabled={readOnly}
          type={'number'}
          step={'any'}
          {...register(maxDosageValueName, {
            valueAsNumber: true,
            validate: validateOptionalPositiveNumber,
          })}
          placeholder={'#'}
          className={inputClasses}
          showErrorBorder={
            !!formState.errors.doses?.[doseIndex]?.maxDosage?.value
          }
        />
        <Controller
          control={control}
          name={maxDosageUnitName}
          rules={{
            validate: (value) => {
              if (maxDosageHasValue) {
                // only required if the user enters a value into the max dosage # input
                return value ? true : 'Required'
              }

              return true
            },
          }}
          render={({ fieldState: { error } }) => {
            return (
              <>
                <Label visuallyHidden htmlFor={maxDosageUnitName}>
                  Max Unit
                </Label>
                <StyledSelect
                  inputId={maxDosageUnitName}
                  options={quantityUnitOptions}
                  placeholder="Select..."
                  onChange={(e: OptionTypeBase) => {
                    changeDoseUnit({
                      newUnit: e.value,
                      inputName: maxDosageUnitName,
                    })
                  }}
                  value={quantityUnitOptions.find(
                    (opt) => opt.value === data?.maxDosage?.unit
                  )}
                  isDisabled={readOnly}
                  styles={ErrorDropdownStyleConfig({ error: !!error })}
                />
                <span className={sublabelClasses}>in 24 hours</span>
              </>
            )
          }}
        />
      </div>
      <div className={styles.prnRow}>
        <div className={styles.prnLabel}>
          <i className={`${sharedIconClasses} fa-timer`} />
          Min Time
        </div>
        <BasicInput
          disabled={readOnly}
          type={'number'}
          {...register(minTimeName, {
            valueAsNumber: true,
            validate: validateOptionalPositiveNumber,
          })}
          placeholder={'e.g. 3 hours'}
          className={inputClasses}
          showErrorBorder={!!formState.errors.doses?.[doseIndex]?.minTime}
        />
        <span className={sublabelClasses}>hours between doses</span>
      </div>
    </>
  )
}
