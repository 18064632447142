import { endOfDay, endOfToday, parseISO } from 'date-fns'
import { isNil } from 'lodash'
import { BillingChargeData, RecurringCharge } from '@shared/types/billing'
import { getOrElse, loaded, Loading } from '@shared/utils/loading'
import { Order, sortNumber, sortStr } from '@shared/utils/sorting'
import { ChargesColumnName } from '../../helpers'

type BillingChargeDataKey = keyof BillingChargeData

export const humanizedFilterNames = {
  ['includeInactive']: 'Include Inactive',
}

const keysToSearch: BillingChargeDataKey[] = ['name', 'amountCents']

export function hasInactiveCharges(rows: Loading<RecurringCharge[]>) {
  return getOrElse(rows, []).some((charge) => !!charge.data.endDate)
}

export function getFilteredCharges(
  rows: Loading<RecurringCharge[]>,
  opts: { showInactive: boolean }
): Loading<RecurringCharge[]> {
  const filteredRows = getOrElse(rows, []).filter((charge) => {
    return opts.showInactive
      ? true
      : (!!charge.data.endDate &&
          endOfDay(parseISO(charge.data.endDate)) > endOfToday()) ||
          isNil(charge.data.endDate)
  })

  return loaded(filteredRows)
}

// TO DO: Need to add tests
export function getSearchedBillingCharges({
  rows,
  searchTerm,
}: {
  rows: RecurringCharge[]
  searchTerm: string
}) {
  const searchText = searchTerm.toLowerCase()

  return rows.filter((charge) => {
    return Object.entries(charge.data).some(([key, val]) => {
      if (keysToSearch.includes(key as BillingChargeDataKey) && val) {
        return val.toString().toLowerCase().includes(searchText)
      }

      return false
    })
  })
}

export function getSortedCharges({
  rows,
  selectedColumn,
  sortingOrder,
}: {
  rows: RecurringCharge[]
  selectedColumn: ChargesColumnName
  sortingOrder: Order
}) {
  if (selectedColumn === ChargesColumnName.DESCRIPTION) {
    return rows.sort((a, b) =>
      sortStr({
        strA: a.data.name,
        strB: b.data.name,
        sortingOrder,
      })
    )
  }

  if (selectedColumn === ChargesColumnName.AMOUNT) {
    return rows.sort((a, b) =>
      sortNumber({
        numA: a.data.amountCents,
        numB: b.data.amountCents,
        sortingOrder,
      })
    )
  }

  if (selectedColumn === ChargesColumnName.START_DATE) {
    return rows.sort((a, b) =>
      sortStr({
        strA: a.data.startDate,
        strB: b.data.startDate,
        sortingOrder,
      })
    )
  }

  if (selectedColumn === ChargesColumnName.END_DATE) {
    return rows.sort((a, b) =>
      sortStr({
        strA: a.data.endDate,
        strB: b.data.endDate,
        sortingOrder,
      })
    )
  }

  return rows
}
