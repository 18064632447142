import CSS from 'csstype'
import localForage from 'localforage'
import Icon from '@shared/components/Icon'
import { Order } from '@shared/utils/common'
import { tw } from '@shared/utils/tailwind'
import styles from './styles.module.css'

export type Header =
  | {
      label: string
      className?: string
      thStyles?: CSS.Properties
    }
  | string

type Props = {
  headers: Header[]
  sortable?: SortableTableHeader
}

interface SortableTableHeader {
  onToggle: (column: string) => void
  selectedColumn: string
  sortingOrder: Order
}

function getIcon(selected: boolean, order?: Order) {
  if (selected) {
    return (
      <Icon
        name={order === Order.DESC ? 'sort-down' : 'sort-up'}
        variant={'duotone'}
      />
    )
  } else {
    return <Icon name={'sort'} variant={'solid'} />
  }
}

type TableHeaderOnToggleProps = {
  selectedColumn: string
  selectedOrder?: Order
  setColumn: (column: string) => void
  setOrder: (order: Order) => void
  storageColumnName?: string
  storageOrderName?: string
}

/**
 * Set selected Column, if Column is same as previous Column, then change the Order [ASC|DESC]
 * Also save selection using localForage
 */

export function getTableHeaderOnToggle({
  selectedColumn,
  selectedOrder,
  setColumn,
  setOrder,
  storageColumnName,
  storageOrderName,
}: TableHeaderOnToggleProps) {
  return (column: string) => {
    if (column === selectedColumn) {
      const newOrder = selectedOrder === Order.ASC ? Order.DESC : Order.ASC
      setOrder(newOrder)
      if (storageOrderName) {
        void localForage.setItem(storageOrderName, newOrder.toString())
      }
    } else {
      setColumn(column)
      setOrder(Order.ASC)
      if (storageColumnName) {
        void localForage.setItem(storageColumnName, column)
      }
      if (storageOrderName) {
        void localForage.setItem(storageOrderName, Order.ASC.toString())
      }
    }
  }
}

function getLabelAndClassNameList(h: Header) {
  const isString = typeof h === 'string'
  const className = isString ? '' : h.className
  const label = isString ? h : h.label
  const thStyles = isString ? {} : h.thStyles
  const classNameList = [styles.columnHeading]
  if (className) {
    classNameList.push(className)
  }

  return {
    label,
    classNameList,
    thStyles,
  }
}

export default function TableHeader(props: Props) {
  const { headers, sortable } = props

  if (sortable === undefined) {
    return (
      <thead>
        <tr className={styles.headingRow}>
          {headers.map((h, ix) => {
            const { label, classNameList, thStyles } =
              getLabelAndClassNameList(h)
            return (
              <th
                key={`th-${label}-${ix}`}
                className={classNameList.join(' ')}
                style={thStyles}
              >
                {label}
              </th>
            )
          })}
        </tr>
      </thead>
    )
  }

  const { onToggle, sortingOrder, selectedColumn } = sortable

  return (
    <thead>
      <tr className={styles.headingRow}>
        {headers.map((h, ix) => {
          const { label, classNameList, thStyles } = getLabelAndClassNameList(h)
          let onClick
          let thContent
          if (label) {
            onClick = () => onToggle(label)
            const selected = selectedColumn === label
            classNameList.push('pointer')
            thContent = (
              <div className={tw`flex flex-row items-center gap-2`}>
                {label}
                {getIcon(selected, sortingOrder)}
              </div>
            )
          }

          return (
            <th
              key={`th-${label}-${ix}`}
              className={classNameList.join(' ')}
              onClick={onClick}
              style={thStyles}
            >
              {thContent}
            </th>
          )
        })}
      </tr>
    </thead>
  )
}
