// https://reactjs.org/docs/error-boundaries.html
import React from 'react'
import { UpdateAppPopup } from '@shared/components/UpdateAppPopup'

type Props = object

type State = {
  hasError: boolean
}

// https://stackoverflow.com/questions/60537645/how-to-implement-error-boundary-with-react-hooks-component
// Write as a traditional react class, since react hook doesn't support ErrorBoundary yet
export default class NewAppVersionBoundary extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/

    if (error && error.message && chunkFailedMessage.test(error.message)) {
      return { hasError: true }
    } else {
      throw error
    }
  }

  render() {
    const { children } = this.props as any
    const { hasError } = this.state

    if (hasError) {
      return <UpdateAppPopup />
    }

    return children
  }
}
