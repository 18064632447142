import { HTMLProps } from 'react'
import { Person } from '@shared/types/person'
import { PickPartial } from '@shared/types/utilities'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { getProfileSvgPath } from '@shared/utils/person'
import { twx } from '@shared/utils/tailwind'

/**
 * Change className h-[n-px] w-[n-px] min-w-[n-px] to adjust thumbnail size
 */

export default function ProfileImage(
  props: HTMLProps<HTMLImageElement> & {
    person: PickPartial<Person, 'gender' | 'name'>
    profilePhotoUrl?: string
  }
) {
  const { className, person, profilePhotoUrl, ...rest } = props

  return (
    <img
      className={twx(
        'mr-[8px] h-[28px] w-[28px] min-w-[28px] rounded-[2px] shadow-photo',
        className
      )}
      alt={`${getFirstAndLastName(person?.name)} profile picture`}
      src={profilePhotoUrl || getProfileSvgPath(person)}
      {...rest}
    />
  )
}
