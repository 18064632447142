import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { useUserContext } from '@shared/contexts/UserContext'
import useCurrentPage, { extractIds } from '@shared/hooks/useCurrentPage'
import { getOrElse } from '@shared/utils/loading'
import { VitalsType } from '@shared/utils/vitals'
import VitalsContext from '@app/contexts/VitalsContext'
import { useAllVitals } from '@app/hooks/useAllVitals'
import useIncidents from '@app/hooks/useIncidents'
import useLatestVitals from '@app/hooks/useLatestVitals'
import { usePersonInContext } from '@app/hooks/usePerson'
import useConfiguredTasks from '../Documents/useConfiguredTasks'
import FilterBadge from './FilterBadge'
import FilterIncidentsModal from './FilterIncidentsModal'
import {
  filterIncidents,
  getBadges,
  getNewFilterValue,
  getUsers,
  sortIncidents,
} from './helpers'
import HistoricVitalsModal from './HistoricVitalsModal'
import IncidentCard from './IncidentCard'
import EmptyIncidentCard from './IncidentCard/EmptyIncidentCard'
import { NoteCardSkeleton } from './NoteCardSkeleton'
import { AddNoteModal } from './NoteModal'
import { NotesTitleAndButtons } from './NotesTitleAndButtons'
import { FilterIncidentsFormData, InputWithIcon } from './types'
import LatestVitalsCard from './Vitals/LatestVitalsCard'

export const defaultFilterValues = {
  noteTypes: [],
  incidentTypes: [],
  incidentStatus: null,
  time: null,
  users: [],
  onAlert: false,
}

export default function Notes() {
  const history = useHistory()
  const { user } = useUserContext()
  const currentPage = useCurrentPage()
  const { orgId, facilityId, personId } = extractIds(currentPage)
  const { person, refreshPerson } = usePersonInContext({
    initialData: { orgId, facilityId, id: personId },
    skipFirstFetch: true,
  })
  const { latestVitals, refreshLatestVitals } = useLatestVitals(person)
  const { allVitals, refreshAllVitals } = useAllVitals(person)
  const { incidents: allIncidents, refreshIncidents } = useIncidents(person)
  const creatableTasks = useConfiguredTasks(person ?? { orgId, facilityId })
  const [showNoteModal, setShowNoteModal] = useState(false)
  const [showFilterIncidentsModal, setShowFilterIncidentsModal] =
    useState(false)
  const [filters, setFilters] =
    useState<FilterIncidentsFormData>(defaultFilterValues)
  const [activeTab, setActiveTab] = useState<VitalsType>()
  const [showHistoricVitalsModal, setShowHistoricVitalsModal] =
    useState<boolean>(false)

  useEffect(() => {
    if (activeTab) {
      setShowHistoricVitalsModal(true)
    }
  }, [activeTab])

  if (!person) {
    return null
  }

  const filteredIncidents = sortIncidents(
    filterIncidents({
      incidents: getOrElse(allIncidents, []),
      filters,
    })
  )

  const users =
    allIncidents.tag === 'Complete'
      ? getUsers(allIncidents.value)
      : defaultFilterValues.users

  const badges: InputWithIcon[] = getBadges({ filters, users })

  function clearFilterValue(name: string, value: string) {
    const newValue = getNewFilterValue(filters[name], value)

    setFilters({
      ...filters,
      [name]: newValue,
    })
  }

  return (
    <VitalsContext.Provider
      value={{
        activeTab,
        setActiveTab,
        allVitals,
        latestVitals,
        refreshLatestVitals,
        refreshAllVitals,
      }}
    >
      <div className="mb-[32px]">
        <NotesTitleAndButtons
          allIncidents={allIncidents}
          person={person}
          refreshIncidents={refreshIncidents}
          setShowNoteModal={setShowNoteModal}
          setShowFilterIncidentsModal={setShowFilterIncidentsModal}
        />
        {badges.length > 0 && (
          <div className="full-width mb-[24px]">
            {badges.map((badge) => (
              <FilterBadge
                icon={badge.icon}
                text={badge.name}
                key={badge.value}
                onClick={() => clearFilterValue(badge.groupName!, badge.value)}
              />
            ))}
          </div>
        )}
        <PersonPermissionGate
          person={person}
          permissions={[GroupPermission.GROUP_PERMISSION_VITAL_READ]}
        >
          <LatestVitalsCard showDateOfLastEdits />
        </PersonPermissionGate>
        {allIncidents.tag === 'Complete' &&
          (allIncidents.value.length === 0 ||
            filteredIncidents.length === 0) && (
            <EmptyIncidentCard
              user={user}
              person={person}
              isFiltered={badges.length > 0}
              onAddNote={() => setShowNoteModal(true)}
              onAddIncident={() => history.push('incidents/new')}
            />
          )}

        {(allIncidents.tag === 'Loading' ||
          creatableTasks.tag === 'Loading') && <NoteCardSkeleton />}
        {filteredIncidents.length > 0 &&
          creatableTasks.tag === 'Complete' &&
          filteredIncidents.map((note) => {
            return (
              <IncidentCard
                user={user}
                key={`note-incident-${note.id}`}
                incident={note}
                person={person}
                creatableTasks={creatableTasks.value}
                onChangeAlert={refreshPerson}
                onDelete={refreshIncidents}
              />
            )
          })}
        {allIncidents.tag === 'Complete' &&
          allIncidents.value.length > 0 &&
          showFilterIncidentsModal && (
            <FilterIncidentsModal
              closeModal={() => setShowFilterIncidentsModal(false)}
              filters={filters}
              userOptions={users}
              setFilters={setFilters}
            />
          )}
        {showNoteModal && (
          <AddNoteModal
            closeModal={async (updated) => {
              if (updated) {
                await refreshIncidents()
              }

              setShowNoteModal(false)
            }}
            person={person!}
          />
        )}
        {showHistoricVitalsModal && (
          <HistoricVitalsModal
            closeModal={() => {
              setShowHistoricVitalsModal(false)
              setActiveTab(undefined)
            }}
            person={person}
          />
        )}
      </div>
    </VitalsContext.Provider>
  )
}
