import {
  DataImportResponse,
  ImportResult,
} from '@augusthealth/models/com/august/protos/api/import_results'
import { ImportKind } from '@augusthealth/models/com/august/protos/data_import'
import { request, requestJson } from '@shared/api/request'
import {
  apiFacilityUrl,
  apiOrganizationUrl,
  getAllImportsUrl,
} from '@shared/legacy_routes'

type ImportProps = {
  facilityId?: string
  file: File
  force: boolean
  importKind: ImportKind
  orgId: string
}

export const fetchImportPreview = async ({
  facilityId,
  file,
  force,
  importKind,
  orgId,
}: ImportProps): Promise<DataImportResponse> => {
  const data = new FormData()
  data.append('file', file)

  const urlSuffix = `/imports/${importKind}/preview?force=${force}`
  const url = facilityId
    ? `${apiFacilityUrl(orgId, facilityId)}${urlSuffix}`
    : `${apiOrganizationUrl(orgId)}${urlSuffix}`
  const response = await request({ body: data, method: 'POST', url })

  return response.data
}

export const importFile = async ({
  facilityId,
  file,
  force,
  importKind,
  orgId,
}: ImportProps): Promise<DataImportResponse> => {
  const data = new FormData()
  data.append('file', file)

  const urlSuffix = `/imports/${importKind}?force=${force}`
  const url = facilityId
    ? `${apiFacilityUrl(orgId, facilityId)}${urlSuffix}`
    : `${apiOrganizationUrl(orgId)}${urlSuffix}`
  const response = await request({ body: data, method: 'POST', url })

  return response.data
}

export const fetchImportList = async (
  importKind: ImportKind
): Promise<ImportResult[]> => {
  const response = await requestJson({
    url: getAllImportsUrl({ kind: importKind }),
  })

  return response.data
}
