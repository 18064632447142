import { isBefore, startOfDay } from 'date-fns'
import { hasPermissionForFacility } from '@shared/components/PermissionGates/PermissionGates'
import {
  DATABASE_NAME,
  PROFILE_PICTURE_CACHE,
  RUNTIME_CACHE,
} from '@shared/constants/emar'
import ErrorMonitoring from '@shared/ErrorMonitoring'
import { DateMessage } from '@shared/types/date'
import { Facility } from '@shared/types/facility'
import { GroupPermission } from '@shared/types/permission'
import { UserAccount } from '@shared/types/user'
import { fromDateMessageToDate } from '@shared/utils/date'
import { isEmarActive } from '@shared/utils/facilities'
import { isFrontDeskUser } from '@shared/utils/user'

/**
 * Reset the eMAR by deleting the emar database as well as the
 * runtime & profile picture caches.
 */
export async function resetEmar() {
  try {
    indexedDB.deleteDatabase(DATABASE_NAME)
  } catch (e) {
    ErrorMonitoring.capture({
      error: e,
      extras: { message: 'Failed to delete database' },
    })
  }

  try {
    await window.caches.delete(RUNTIME_CACHE)
    await window.caches.delete(PROFILE_PICTURE_CACHE)
  } catch (e) {
    ErrorMonitoring.capture({
      error: e,
      extras: { message: 'Failed to delete runtime/photo caches' },
    })
  }
}

export async function clearEmarRuntimeCache() {
  try {
    await window.caches.delete(RUNTIME_CACHE)
  } catch (error) {
    ErrorMonitoring.capture({ error })
  }
}

export const usesStrictTiming = (facility: Facility): boolean => {
  return Boolean(facility.emarSettings?.usesStrictTiming)
}

export function isInEmarTraining({
  facility,
  currentMedPassDate,
}: {
  currentMedPassDate?: Date
  facility?: Facility
}) {
  if (facility && isEmarActive(facility) && currentMedPassDate) {
    const goLiveDate = facility.emarSettings?.goLiveMedPassDate

    if (goLiveDate) {
      return isBefore(
        startOfDay(currentMedPassDate),
        fromDateMessageToDate(goLiveDate as DateMessage) as Date
      )
    }

    return true
  }

  return false
}

export const hasAccessToEmar = ({
  user,
  facility,
}: {
  user: UserAccount
  facility: Facility
}): boolean => {
  return (
    isEmarActive(facility) &&
    hasPermissionForFacility({
      user,
      facility: facility,
      permissions: [GroupPermission.GROUP_PERMISSION_MEDICATION_READ],
    }) &&
    !isFrontDeskUser({
      user,
      orgId: facility.orgId,
      facilityId: facility.id,
    })
  )
}
