import classNames from 'classnames'
import { useState } from 'react'
import { BasicSpinner } from '@shared/components/BasicSpinner'
import styles from './styles.module.css'

export type Props = {
  isCurrentStep: boolean
  onStepClick: () => Promise<void>
  stepLabel: string
  taskStepUrl: string
}
export default function TaskNavigationStep({
  isCurrentStep,
  onStepClick,
  stepLabel,
  taskStepUrl,
}: Props) {
  const [showSpinner, setShowSpinner] = useState(false)

  const onLinkClick = async () => {
    if (showSpinner) {
      return
    }

    setShowSpinner(true)
    await onStepClick()
    setShowSpinner(false)
  }

  const linkClassNames = classNames(styles.stepName, {
    [styles.active]: isCurrentStep,
  })

  return (
    <a
      className={linkClassNames}
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginRight: '112px',
      }}
      href={taskStepUrl}
      onClick={async (e) => {
        e.preventDefault()
        await onLinkClick()
      }}
    >
      {stepLabel}
      {showSpinner && (
        <div style={{ display: 'inline' }}>
          <BasicSpinner />
        </div>
      )}
      <div className={styles.stepBorder} />
    </a>
  )
}
