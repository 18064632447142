import { useState } from 'react'
import { Facility } from '@shared/types/facility'
import useBillingLedger from '@app/hooks/useBillingLedger'
import { GenerateLedgerExportModal } from './GenerateLedgerExportModal'
import { LedgerExportsTable } from './LedgerExportsTable'

type Props = {
  facility: Facility
}
export default function GeneralLedger(props: Props) {
  const { ledgerExports, reloadExports } = useBillingLedger(props.facility)
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <div>
      <LedgerExportsTable
        facility={props.facility}
        ledgerExports={ledgerExports}
        onCtaClick={() => setIsModalOpen(true)}
      />
      {isModalOpen && (
        <GenerateLedgerExportModal
          facility={props.facility}
          closeFn={async (downloaded?: boolean) => {
            if (downloaded) {
              await reloadExports()
            }
            setIsModalOpen(false)
          }}
        />
      )}
    </div>
  )
}
