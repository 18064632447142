import { BasicCheckbox } from '@shared/components/BasicInput/BasicInput'
import ElementHolder, {
  Props as ElementHolderProps,
} from '@app/components/formElements/ElementHolder'

interface CheckboxGroupItem {
  label: string
  value: string
}

interface CheckboxGroupProps extends Omit<ElementHolderProps, 'children'> {
  prefix: string
  name: string
  value?: { [key: string]: string }
  onUpdate: (data: string | null, name: string) => void
  options: CheckboxGroupItem[]
}

export function CheckboxGroup(props: CheckboxGroupProps) {
  function changeHandler(fieldName: string) {
    return (e: React.FormEvent<HTMLInputElement>) => {
      const field = e.currentTarget.checked ? 'true' : null
      props.onUpdate(field, `${props.name}.${fieldName}`)
    }
  }

  const checkboxes = props.options.map((box) => {
    const name = `${props.prefix}_${box.value}`
    const fields = props.value || {}
    return (
      <BasicCheckbox
        key={name}
        checked={Boolean(fields[name])}
        name={name}
        disabled={false}
        onChange={changeHandler(name)}
        labelClassName={'inline-flex'}
      >
        {box.label}
      </BasicCheckbox>
    )
  })

  return <ElementHolder {...props}>{checkboxes}</ElementHolder>
}
