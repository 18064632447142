import './style.css'

type Props = {
  numberCompleted: number
  numberTotal: number
}
export default function CompletedTasksCounter(props: Props) {
  const { numberCompleted, numberTotal } = props

  const elements: JSX.Element[] = []
  for (let i = 1; i <= numberTotal; i++) {
    const digitElement = (
      <div key={`digit-${i}`} className="step--digit">
        {numberCompleted}
      </div>
    )
    // if numberCompleted is 0, put the digit element in front, if > 0 put the digit after
    if (i === 1 && numberCompleted === 0) elements.push(digitElement)

    // depending on how many tasks are completed, return the appropriate class
    elements.push(
      <div
        key={i}
        className={i <= numberCompleted ? 'step--done' : 'step--pending'}
      >
        &nbsp;
      </div>
    )

    // if not the 0th element, put the digit element after the task rectangle is rendered
    if (i === numberCompleted) elements.push(digitElement)
  }

  return <div className="completion">{elements}</div>
}
