import { IncidentAction } from '@augusthealth/models/com/august/protos/incidents'
import { useContext, useEffect, useState } from 'react'
import { getIncidentActionTemplates } from '@shared/api/incidents'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { loaded, LOADING, Loading } from '@shared/utils/loading'

/**
 * This provides access to configured incident action templates.
 * Most of our incident actions are fixed (via the IncidentActionType enum)
 * We added support for a limited type of custom/configurable subset for a large customer.
 *
 * See https://www.notion.so/augusthealth/Custom-Incident-Actions-8afec651098a4998ac28d25966eecfea?pvs=4
 */
export function useIncidentActionTemplates({ person }: { person: Person }) {
  const { setError } = useContext(GlobalContext)
  const [actionTemplates, setActionTemplates] =
    useState<Loading<IncidentAction[]>>(LOADING)

  async function fetchActionTemplates() {
    try {
      const actions = await getIncidentActionTemplates({ person })
      setActionTemplates(loaded(actions))
    } catch (e) {
      setActionTemplates(loaded([]))
      setError(e)
    }
  }

  useEffect(() => {
    void fetchActionTemplates()
  }, [person.id])

  return { actionTemplates }
}
