import {
  SignableForm,
  SignableFormMetaData,
  SignableFormWithAssignments,
} from '@augusthealth/models/com/august/protos/signable_form'
import classNames from 'classnames'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { fetchBlobUrlAndContentType } from '@shared/api/request'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import Badge from '@shared/components/Badge'
import Card from '@shared/components/Card'
import { ToolPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { useUserContext } from '@shared/contexts/UserContext'
import { pdfUrl } from '@shared/legacy_routes'
import { GroupPermission } from '@shared/types/permission'
import { hasPermissions } from '@shared/utils/permisson'
import { signatureConfiguration } from '@shared/utils/signableForm'
import {
  TOOLS_FACILITY_FIELDS_PATH,
  TOOLS_PDF_TEMPLATE_DISPLAY_FORM_PATH,
} from '@app/pages/Tools/path'
import EditTemplatePopup from '@app/pages/Tools/PdfTemplate/TemplateList/TemplateItem/EditTemplatePopup'
import styles from './styles.module.css'
import { DRAFT_STATUS_DISPLAY_DATA_MAP } from '../../helpers'
import BeforeEditPopup from './BeforeEditPopup'
import DeleteConfirmPopup from './DeleteConfirmPopup'

type ItemProps = {
  reload: (form?: SignableFormWithAssignments) => void
  setSelectedTemplate?: (form: SignableForm | undefined) => void
  formWithAssignments: SignableFormWithAssignments
  copiedFromForm?: SignableFormMetaData
}

export default function TemplateItem(props: ItemProps) {
  const { user } = useUserContext()
  const history = useHistory()
  const [openBeforeEditPopup, setOpenBeforeEditPopup] = useState<boolean>(false)
  const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false)
  const [openEditPopup, setOpenEditPopup] = useState<boolean>(false)
  const {
    draftStatus = 'DRAFT_STATUS_UNSPECIFIED',
    fileMetaData = {},
    id,
    name,
    description,
    signers,
    hellosign,
  } = props.formWithAssignments.signableForm!
  const { reload, setSelectedTemplate } = props
  const { fileName = '' } = fileMetaData
  const onDeleteForm = () => {
    setOpenDeletePopup(true)
  }
  const openLightbox = () => {
    if (setSelectedTemplate) {
      setSelectedTemplate(props.formWithAssignments.signableForm!)
    }
  }

  const onEditClick = () => {
    setOpenEditPopup(true)
  }

  const signatureOrder = signatureConfiguration(signers || [])

  const assignmentsLoaded = true
  const hasOrgAssignments =
    (props.formWithAssignments.organizations ?? []).length > 0
  const hasFacilityAssignments =
    (props.formWithAssignments.facilities ?? []).length > 0
  const hasStateAssignments =
    (props.formWithAssignments.states ?? []).length > 0
  const hasOrgStateAssignments =
    (props.formWithAssignments.orgStates ?? []).length > 0
  const hasGlobalAssignment = props.formWithAssignments.global ?? false
  const hasSomeAssignment =
    assignmentsLoaded &&
    (hasOrgAssignments ||
      hasFacilityAssignments ||
      hasOrgStateAssignments ||
      hasStateAssignments ||
      hasGlobalAssignment)
  async function handleDownload() {
    if (!fileName) {
      return
    }

    const url = pdfUrl(id!)
    const blobData = await fetchBlobUrlAndContentType({
      url,
    })
    window.open(blobData.url, '_archived_doc', 'noreferrer')
  }

  const cardClasses = classNames('mb-[16px] flex-col', {
    'ml-[32px]': hellosign?.subtemplateOf !== undefined,
  })
  const statusBadgeData = DRAFT_STATUS_DISPLAY_DATA_MAP[draftStatus]
  const hasReadAndPublishPermissions = hasPermissions({
    user,
    permissions: [
      GroupPermission.GROUP_PERMISSION_TOOL_FORM_READ,
      GroupPermission.GROUP_PERMISSION_TOOL_FORM_PUBLISH,
    ],
  })

  return (
    <>
      <Card className={cardClasses}>
        <div className={'flex grow justify-between'}>
          <div style={{ maxWidth: '65%' }}>
            <a
              className={styles.signableFormTitle}
              href="#edit"
              onClick={(ev) => {
                ev.preventDefault()
                setOpenBeforeEditPopup(true)
              }}
            >
              {id}. {name}
            </a>
          </div>
          <div>
            {statusBadgeData && (
              <Badge
                color={statusBadgeData.badgeColor}
                shape={'square'}
                badgeSize={'medium'}
              >
                {statusBadgeData.label}
              </Badge>
            )}
            <Badge
              shape={'square'}
              color={'darkGray'}
              badgeSize={'medium'}
              className={'ml-[8px]'}
            >
              {signatureOrder}
            </Badge>
          </div>
        </div>
        {description && (
          <div className={styles.signableFormDescription}>{description}</div>
        )}
        {props.copiedFromForm && (
          <div className={styles.signableFormCopiedFrom}>
            Copied from: {props.copiedFromForm.id}. {props.copiedFromForm.name}
          </div>
        )}
        <div className={styles.actionContainer}>
          {hasSomeAssignment && (
            <div className="flex-col text-[14px]">
              {assignmentsLoaded && (
                <div className={styles.assignmentsContainer}>
                  {hasGlobalAssignment && (
                    <span className="text-[14px] font-medium">
                      Global Assignment
                    </span>
                  )}
                  {hasStateAssignments && (
                    <dl>
                      <dt>States</dt>
                      <dd className={'mb-0'}>
                        <ul className={styles.assignmentList}>
                          {(props.formWithAssignments.states ?? []).map((s) => {
                            return <li key={`org-assign-${s}`}>{s}</li>
                          })}
                        </ul>
                      </dd>
                    </dl>
                  )}
                  {hasOrgAssignments && (
                    <dl>
                      <dt>Organizations</dt>
                      <dd className={'mb-0'}>
                        <ul className={styles.assignmentList}>
                          {(props.formWithAssignments.organizations ?? []).map(
                            (o, index) => {
                              return (
                                <li key={`org-assign-${o.id}-${index}`}>
                                  {o.name}
                                </li>
                              )
                            }
                          )}
                        </ul>
                      </dd>
                    </dl>
                  )}
                  {hasOrgStateAssignments && (
                    <dl>
                      <dt>Org-States</dt>
                      <dd className={'mb-0'}>
                        <ul className={styles.assignmentList}>
                          {(props.formWithAssignments.orgStates ?? []).map(
                            (os) => {
                              return (
                                <li
                                  key={`org-assign-${os.state}-${os.organization?.id}`}
                                >
                                  {os.organization?.name} - {os.state}
                                </li>
                              )
                            }
                          )}
                        </ul>
                      </dd>
                    </dl>
                  )}
                  {hasFacilityAssignments && (
                    <dl>
                      <dt>Facilities</dt>
                      <dd className={'mb-0'}>
                        <ul className={styles.assignmentList}>
                          {(props.formWithAssignments.facilities ?? []).map(
                            (f, ix) => {
                              return (
                                <li key={`fac-assign-${f.id}-${ix}`}>
                                  {f.name}
                                </li>
                              )
                            }
                          )}
                        </ul>
                      </dd>
                    </dl>
                  )}
                </div>
              )}
            </div>
          )}
          {!hasSomeAssignment && !hasGlobalAssignment && (
            <span className="text-[14px] font-medium">
              No assignments found
            </span>
          )}
          <div>
            <ToolPermissionGate
              permissions={[GroupPermission.GROUP_PERMISSION_TOOL_FORM_UPDATE]}
            >
              <AsyncIconButton
                className={'mr-[8px]'}
                buttonStyle={'primary-outline'}
                buttonSize={'small'}
                onClick={() =>
                  history.push(
                    TOOLS_PDF_TEMPLATE_DISPLAY_FORM_PATH.replace(':formId', id!)
                  )
                }
              >
                Webform
              </AsyncIconButton>
            </ToolPermissionGate>
            <ToolPermissionGate
              permissions={[GroupPermission.GROUP_PERMISSION_TOOL_FORM_READ]}
            >
              <AsyncIconButton
                buttonStyle={'primary-outline'}
                buttonSize={'small'}
                className={'mr-[8px]'}
                onClick={openLightbox}
              >
                {hasReadAndPublishPermissions ? 'Preview/Publish' : 'Preview'}
              </AsyncIconButton>
            </ToolPermissionGate>
            <ToolPermissionGate
              permissions={[GroupPermission.GROUP_PERMISSION_TOOL_FORM_UPDATE]}
            >
              <AsyncIconButton
                className={'mr-[8px]'}
                buttonStyle={'primary-outline'}
                buttonSize={'small'}
                onClick={() =>
                  history.push(
                    TOOLS_FACILITY_FIELDS_PATH.replace(':templateId', id!)
                  )
                }
              >
                Facility Fields
              </AsyncIconButton>
              <AsyncIconButton
                buttonStyle={'primary-outline'}
                buttonSize={'small'}
                className={'mr-[8px]'}
                initialIconClassName={'m-0'}
                initialIcon={'edit'}
                onClick={onEditClick}
              >
                Signers
              </AsyncIconButton>
            </ToolPermissionGate>
            <ToolPermissionGate
              permissions={[GroupPermission.GROUP_PERMISSION_TOOL_FORM_DELETE]}
            >
              <AsyncIconButton
                buttonStyle={'danger-outline'}
                buttonSize={'small'}
                className={'mr-[8px]'}
                initialIconClassName={'m-0'}
                initialIcon={'trash'}
                onClick={onDeleteForm}
                title="Delete"
              />
            </ToolPermissionGate>
            {fileName && (
              <AsyncIconButton
                buttonStyle={'primary-fill'}
                buttonSize={'small'}
                initialIcon={'download'}
                initialIconClassName={'m-0'}
                onClick={handleDownload}
                data-testid={'downloadPDFButton'}
                title="Download"
              />
            )}
          </div>
        </div>
      </Card>

      {openBeforeEditPopup && (
        <BeforeEditPopup
          form={props.formWithAssignments.signableForm!}
          onClose={() => setOpenBeforeEditPopup(false)}
        />
      )}
      {openDeletePopup && (
        <DeleteConfirmPopup
          form={props.formWithAssignments.signableForm!}
          onClose={() => setOpenDeletePopup(false)}
          afterDelete={reload}
        />
      )}
      {openEditPopup && (
        <EditTemplatePopup
          form={props.formWithAssignments.signableForm!}
          onClose={(form?: SignableFormMetaData) => {
            setOpenEditPopup(false)
            form && reload({ signableForm: form })
          }}
        />
      )}
    </>
  )
}
