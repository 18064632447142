import {
  OptionTypeBase,
  StyledOption,
  StyledOptionProps,
} from '@shared/components/Selects/StyledSelect'
import { Contact } from '@shared/types/contact'
import { tw, twx } from '@shared/utils/tailwind'

type LinkOptionType = 'ADD_LINK' | 'EDIT_LINK'

export type OptionData = Contact | LinkOptionType

type Option = OptionTypeBase<string, OptionData>

export default function ContactDropdownOption(
  props: StyledOptionProps<Option>
) {
  const {
    data: { data },
  } = props

  if (data === 'ADD_LINK' || data === 'EDIT_LINK') {
    // Use primary-color for Add Contact and Edit Contacts links
    return (
      <StyledOption
        labelClassName={tw`text-primary`}
        iconClassName={twx(`text-primary`, {
          'fa-plus-circle': data === 'ADD_LINK',
          'fa-address-book': data === 'EDIT_LINK',
        })}
        {...props}
      />
    )
  }

  return <StyledOption {...props} />
}
