import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { sortBy } from 'lodash'
import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { BasicSpinner } from '@shared/components/BasicSpinner'
import Card from '@shared/components/Card'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Facility } from '@shared/types/facility'
import { Person } from '@shared/types/person'
import { DataType } from '@shared/types/snapshot'
import { TaskTemplateInfo } from '@shared/types/task'
import { covidDiseaseName } from '@shared/utils/immunizations'
import { incompleteTask } from '@shared/utils/task'
import { useDocumentsContext } from '@app/contexts/DocumentsContext'
import styles from './styles.module.css'
import {
  recreateTaskAndReturnUrl,
  taskName,
  taskNameData,
  taskTemplateInfoMatchesFilter,
} from './helpers'
import GeneralUploader from './Uploader'
import VaccineViewer from './Viewer/VaccineViewer'

export default function NewDocumentSidebar({
  onUploadComplete,
}: {
  onUploadComplete: (updated: boolean) => Promise<void>
}) {
  const {
    person,
    tasks,
    configuredTemplates,
    immunizations,
    currentFacility,
    documentFilter,
  } = useDocumentsContext()

  const history = useHistory()
  const { setError } = useContext(GlobalContext)
  const [creatingNewDocument, setCreatingNewDocument] = useState<{
    indexOfDocument: number
  }>()
  const [showUploader, setShowUploader] = useState<{
    initialType: DataType | undefined
    customType?: string
  }>()
  const [showImmunizationUploader, setShowImmunizationUploader] =
    useState<boolean>(false)

  const possibleTasks = configuredTemplates

  if (possibleTasks.tag === 'Loading' || immunizations.tag === 'Loading') {
    return (
      <div className={styles.newDocSidebar}>
        <Card className={`${styles.uploadCards}`}>
          <h4 className={styles.uploadCardTitle}>New Document</h4>
        </Card>
      </div>
    )
  }

  const creatableTasks = sortBy(
    possibleTasks.value.filter((taskTemplateInfo: TaskTemplateInfo) =>
      taskTemplateInfoMatchesFilter(taskTemplateInfo, documentFilter)
    ),
    [(f) => taskName(f, person, currentFacility)]
  )

  const onClickNewDocument = async (
    taskTemplateInfo: TaskTemplateInfo,
    webFormIndex: number
  ) => {
    const { dataType, customType } = taskTemplateInfo

    if (dataType === undefined) {
      return
    }

    try {
      setCreatingNewDocument({ indexOfDocument: webFormIndex })
      const task = incompleteTask(tasks, dataType, customType)
      const url = await recreateTaskAndReturnUrl(
        person,
        task,
        dataType,
        customType
      )
      history.push(url)
    } catch (e) {
      setError(e)
      setCreatingNewDocument(undefined)
    }
  }

  return (
    <div className={styles.newDocSidebar}>
      <Card className={`${styles.uploadCards}`}>
        <h4 className={styles.uploadCardTitle}>New Document</h4>
        <PersonPermissionGate
          person={person}
          permissions={[GroupPermission.GROUP_PERMISSION_TASK_CREATE]}
        >
          <ul>
            {creatableTasks.map((task, ix) => (
              <NewDocumentListItem
                key={`ndli-${ix}`}
                person={person}
                ix={ix}
                task={task}
                currentFacility={currentFacility}
                onClickNewDocument={onClickNewDocument}
                creatingNewDocument={
                  creatingNewDocument?.indexOfDocument === ix
                }
              />
            ))}
          </ul>
        </PersonPermissionGate>
        <PersonPermissionGate
          person={person}
          permissions={[GroupPermission.GROUP_PERMISSION_SNAPSHOT_CREATE]}
        >
          <div className={creatableTasks.length > 0 ? 'mt-[24px]' : ''}>
            <AsyncIconButton
              id={'uploadDocument'}
              className={'w-full'}
              isLoading={false}
              initialIcon={'fa-cloud-upload'}
              buttonStyle={'tertiary-outline'}
              onClick={() => setShowUploader({ initialType: undefined })}
            >
              Upload a Document
            </AsyncIconButton>
          </div>
        </PersonPermissionGate>
      </Card>
      {showImmunizationUploader && (
        <VaccineViewer
          diseaseName={covidDiseaseName}
          immunizations={immunizations.value}
          person={person}
          onUpdate={async () => {
            await onUploadComplete(true)
          }}
          onClose={() => setShowImmunizationUploader(false)}
          displayUploaderByDefault
        />
      )}
      {showUploader && (
        <GeneralUploader
          initialCustomType={showUploader.customType}
          initialType={showUploader.initialType}
          person={person}
          tasks={tasks}
          onUpdate={async () => {
            await onUploadComplete(true)
          }}
          onClose={() => setShowUploader(undefined)}
        />
      )}
    </div>
  )
}

function NewDocumentListItem({
  ix,
  onClickNewDocument,
  creatingNewDocument,
  task,
  person,
  currentFacility,
}: {
  ix: number
  creatingNewDocument: boolean
  onClickNewDocument: (
    taskTemplateInfo: TaskTemplateInfo,
    ix: number
  ) => Promise<void>
  task: TaskTemplateInfo
  person: Person
  currentFacility: Facility
}) {
  const { title, subtitle } = taskNameData(task, person, currentFacility)

  return (
    <li key={`doc-webform-${ix}`}>
      <button
        disabled={creatingNewDocument}
        id={buttonIdForTask({ dataType: task.dataType! })}
        onClick={async () => {
          await onClickNewDocument(task, ix)
        }}
      >
        {title}
        {subtitle && <span color={'red'}> {subtitle}</span>}
        {creatingNewDocument && (
          <span className={'ml-[8px]'}>
            <BasicSpinner />
          </span>
        )}
      </button>
    </li>
  )
}

function buttonIdForTask(task: { dataType: DataType }) {
  switch (task.dataType) {
    case DataType.DATA_TYPE_POLST:
      return 'uploadPolst'
    case DataType.DATA_TYPE_OTHER_UPLOAD:
      return 'uploadOther'
    case DataType.DATA_TYPE_GETTING_TO_KNOW_YOU:
      return 'newGettingToKnowYou'
    default:
      return task.dataType
  }
}
