import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { DotDivider } from '@shared/components/DotDivider'
import PersonPills from '@shared/components/PersonPills'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { contactsPathForPerson } from '@shared/legacy_routes'
import { Person, ResidentStatus } from '@shared/types/person'
import { firstLastName } from '@shared/utils/contact'
import { formatDateMessage } from '@shared/utils/date'
import { getFirstAndLastName } from '@shared/utils/humanName'
import {
  ageString,
  displayGender,
  getResponsiblePerson,
} from '@shared/utils/person'
import { isAdmin, isResponsiblePerson } from '@shared/utils/user'
import ContactLink from '@app/components/ContactModalLink'
import { RoomNumber } from '@app/components/Prospects/Details/AdmissionDetails/AdmissionDetails'
import VerifyAdmissionDetails from '@app/components/Prospects/VerifyAdmissionDetails/VerifyAdmissionDetails'
import useAmbulatoryStatus from '@app/hooks/useAmbulatoryStatus'
import styles from './styles.module.css'
import ActionMenuContainer from '../ActionMenuContainer'
import ProfileForm from '../ProfileForm'
import AlertPill from './AlertPill'
import Avatar from './Avatar'

export default function PersonHeader({
  person,
  setPerson,
}: {
  person: Person
  setPerson: React.Dispatch<React.SetStateAction<Person>>
}) {
  const { user } = useUserContext()
  const { setError } = useContext(GlobalContext)
  const [showProfile, setShowProfile] = useState(false)
  const [showAdmissionDetails, setShowAdmissionDetails] = useState(false)

  if (!person) {
    return null
  }

  const { orgId, facilityId, id: personId } = person
  const isAnAdmin = isAdmin(user, { organizationId: orgId, facilityId })
  const isNotAnRP = !isResponsiblePerson({
    user,
    orgId,
    facilityId,
    personId,
  })

  return (
    <div className={styles.personHeader}>
      <Avatar setShowProfile={setShowProfile} person={person} />
      <div className={styles.infoContainer}>
        <div className={styles.textContent}>
          <h1 className={styles.name}>{getFirstAndLastName(person.name)}</h1>
          <ProfileInfo
            person={person}
            isAdmin={isAnAdmin}
            setShowAdmissionDetails={setShowAdmissionDetails}
          />
          <ResponsiblePersonInfo person={person} />
        </div>
        {isNotAnRP && (
          <div className={styles.actionMenuContainer}>
            {person.isOnAlert && <AlertPill person={person} />}
            <ActionMenuContainer setShowProfile={setShowProfile} />
          </div>
        )}
      </div>

      {showProfile && (
        <ProfileForm
          person={person}
          doneFn={(updatedPerson) => {
            if (updatedPerson) {
              setPerson(updatedPerson)
            }
            setShowProfile(false)
          }}
          handleError={setError}
        />
      )}
      {showAdmissionDetails && (
        <VerifyAdmissionDetails
          doneFn={setShowAdmissionDetails}
          person={person}
        />
      )}
    </div>
  )
}

function ProfileInfo(props: {
  person: Person
  isAdmin: boolean
  setShowAdmissionDetails: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { ambulatoryBadgeColor, ambulatoryStatus } = useAmbulatoryStatus(
    props.person
  )
  return (
    <section>
      <div className={`${styles.profile} mb-[8px]`}>
        {props.person.birthDate ? (
          <>
            {formatDateMessage(props.person.birthDate)}
            <span className={styles.hideOnMobile}>
              {' '}
              ({ageString(props.person)})
            </span>
          </>
        ) : (
          <>No birthdate on record</>
        )}
        <span className={styles.hideOnMobile}>
          <DotDivider />
          {displayGender(props.person)}
        </span>
        {props.isAdmin &&
          props.person.residentStatus ===
            ResidentStatus.RESIDENT_STATUS_CURRENT_RESIDENT && (
            <>
              <DotDivider />
              Room&nbsp;
              <RoomNumber
                person={props.person}
                setShowAdmissionDetails={props.setShowAdmissionDetails}
              />
            </>
          )}
      </div>
      <PersonPills
        person={props.person}
        containerClass={styles.hideOnMobile}
        ambulatoryBadgeColor={ambulatoryBadgeColor}
        ambulatoryStatus={ambulatoryStatus}
      />
    </section>
  )
}

function ResponsiblePersonInfo({ person }: { person: Person }) {
  const rp = getResponsiblePerson(person)

  if (!rp) {
    return (
      <div className={`${styles.rpText} ${styles.hideOnMobile}`}>
        <div>
          <i className="fa fa-hands-heart mr-[8px] text-[14px]" />
          <span className="font-medium text-secondary-07">
            No Responsible Person
          </span>
          <DotDivider />
          <Link
            className={styles.link}
            to={contactsPathForPerson(person as Required<Person>)}
          >
            Add one now
          </Link>
        </div>
      </div>
    )
  }

  const name = firstLastName(rp)

  return (
    <div className={`${styles.rpLink} ${styles.hideOnMobile}`}>
      <ContactLink
        contact={rp}
        linkStyles={'mt-[24px] text-[16px] leading-[16px]'}
        linkContent={
          <div>
            <i className="fa fa-hands-heart mr-[8px] text-[14px]" />
            Responsible Person: {name}
          </div>
        }
        person={person}
        allowEditingWithPermissions={true}
      />
    </div>
  )
}
