import { Report } from '@augusthealth/models/com/august/protos/report'
import { Link } from 'react-router-dom'
import Card from '@shared/components/Card'
import Icon from '@shared/components/Icon'
import { tw } from '@shared/utils/tailwind'
import { getAssignedReportPath } from '@app/pages/Tools/path'
import DeleteButton from './DeleteButton'

export type Props = {
  reload: () => void
  reports: Report[] | undefined
}

export default function ReportList({ reload, reports }: Props) {
  let list
  if (reports) {
    list = reports.map((r: Report) => {
      const { id, description, fields } = r
      const title = `${id}. ${description}`
      const subTitle = fields?.map((f) => f.displayName).join(', ')

      return (
        <Card
          key={`report-item-${id}`}
          className={tw`align-center mb-[16px] flex justify-between`}
        >
          <div>
            <div className={tw`font-bold`}>{title}</div>
            <div>{subTitle}</div>
          </div>
          <div className={tw`flex items-center`}>
            {id && (
              <Link to={getAssignedReportPath(id)} title="Assign">
                <Icon name="ballot-check" />
              </Link>
            )}
            <div className={tw`ml-[8px]`}>
              <DeleteButton report={r} afterDelete={reload} />
            </div>
          </div>
        </Card>
      )
    })
  }

  return (
    <>
      <h4 className="page-title mt-[24px]">Report List</h4>
      {list}
    </>
  )
}
