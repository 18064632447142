import { useEffect, useState } from 'react'
import {
  fetchBlobUrlAndContentType,
  UrlAndContentType,
} from '@shared/api/request'
import { AsyncResult } from '@shared/utils/loading'

/* This is meant to be used for fetching the file associated with a Snapshot. */

export default function useBlobData(fileUrl: string | null) {
  const [blobData, setBlobData] = useState<AsyncResult<UrlAndContentType, any>>(
    { tag: 'Loading' }
  )

  useEffect(() => {
    if (fileUrl) {
      setBlobData({ tag: 'Loading' })
      fetchBlobUrlAndContentType({ url: fileUrl })
        .then((v) => {
          setBlobData({ tag: 'Complete', value: v })
        })
        .catch((e) => {
          setBlobData({ tag: 'Error', value: e })
        })
    }
  }, [fileUrl])

  return blobData
}
