import { useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { UnauthedUserSessionSynchronizer } from '@shared/components/Auth/UnauthedUserSessionSynchronizer'
import { ErrorModalBeforeLogin } from '@shared/components/ErrorModal/ErrorModal'
import Unsubscribe from '@shared/pages/Unsubscribe/Unsubscribe'
import {
  forgotPasswordPath,
  loginPath,
  logoutPath,
  magicEmailPath,
  MAIN_APP_BASE_PATH,
} from '@shared/routes'
import { tw, twx } from '@shared/utils/tailwind'
import Login from './Login'
import ForgotPassword from './LoginWithUsernameOrEmail/ForgotPassword/ForgotPassword'
import LoginWithUsernameOrEmail, {
  DEFAULT_LOGIN_TITLE,
} from './LoginWithUsernameOrEmail/LoginWithUsernameOrEmail'
import Logout from './Logout'

/**
 * @param basePath - Application base path (must end with trailing '/')
 */
export default function LoginRouter({
  title = DEFAULT_LOGIN_TITLE,
  applicationBasePath = MAIN_APP_BASE_PATH,
}: {
  title?: string
  applicationBasePath?: string
}) {
  useEffect(() => {
    document.body.classList.add('bg-background-login')
    document.body.classList.add('h-screen')
    document.body.classList.add('overflow-hidden')

    return () => {
      document.body.classList.remove('bg-background-login')
      document.body.classList.remove('h-screen')
      document.body.classList.remove('overflow-hidden')
    }
  })

  return (
    <div className={twx(`flex h-screen items-center justify-center`)}>
      <UnauthedUserSessionSynchronizer>
        <BrowserRouter key="login-router">
          <Switch key="error-popup">
            <Route path="*">
              <ErrorModalBeforeLogin />
            </Route>
          </Switch>
          <Switch key="magic-link">
            <Route path={'/unsubscribe'} component={Unsubscribe} />
            <Route
              path={magicEmailPath(applicationBasePath)}
              component={() => (
                <Login applicationBasePath={applicationBasePath} />
              )}
            />
            <Route
              exact
              path={loginPath(applicationBasePath)}
              component={() =>
                LoginWithUsernameOrEmail({
                  title,
                  applicationBasePath,
                })
              }
            />
            <Route
              path={forgotPasswordPath(applicationBasePath)}
              component={() => (
                <ForgotPassword applicationBasePath={applicationBasePath} />
              )}
            />
            <Route
              path={logoutPath(applicationBasePath)}
              exact
              component={() => <Logout />}
            />
            <Route
              path={'*'}
              component={() =>
                LoginWithUsernameOrEmail({
                  title,
                  applicationBasePath,
                })
              }
            />
          </Switch>
        </BrowserRouter>
      </UnauthedUserSessionSynchronizer>
      <img
        src={'/svg/bg-circle.svg'}
        className={tw`absolute bottom-[-15%] z-[-10] w-screen`}
      />
    </div>
  )
}
