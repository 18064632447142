import { Immunization } from '@augusthealth/models/com/august/protos/immunization'
import { DataType } from '@shared/types/snapshot'
import { PolstData } from '@app/pages/Documents/Viewer/helpers'
import { CustomSignableForm } from './CustomSignableForm'
import { NamedUpload } from './NamedUpload'
import { POLST } from './POLST'
import { SignedDocument } from './SignedDocument'

export type FormValues =
  | {
      tag: 'NamedUpload'
      dataType: DataType
      name: string
    }
  | {
      tag: 'SignedDocument'
      dataType: DataType
      dateSigned: Date
    }
  | PolstData
  | {
      tag: 'Immunization'
      name: string
      immunizations: Immunization[]
      newImmunization: Immunization
    }
  | {
      tag: 'CustomSignableForm'
      dataType: DataType
      customType: string
      dateSigned?: Date
    }

export const getFormPerDataType = (
  currentDataType: DataType | undefined
): {
  component: ((props: any) => JSX.Element) | null
  submitTestId: string
} => {
  if (!currentDataType) {
    return {
      component: null,
      submitTestId: '',
    }
  }

  if (namedDocuments.includes(currentDataType)) {
    return {
      component: NamedUpload,
      submitTestId: 'upload-named-doc',
    }
  }

  if (signedDocuments.includes(currentDataType)) {
    return {
      component: SignedDocument,
      submitTestId: 'upload-signed-doc',
    }
  }

  if (currentDataType === DataType.DATA_TYPE_POLST) {
    return {
      component: POLST,
      submitTestId: 'uploadPolst',
    }
  }

  if (currentDataType === DataType.DATA_TYPE_CUSTOM_SIGNABLE_FORM) {
    return {
      component: CustomSignableForm,
      submitTestId: '',
    }
  }

  return {
    component: null,
    submitTestId: '',
  }
}

export const namedDocuments = [
  DataType.DATA_TYPE_DIRECTIVE,
  DataType.DATA_TYPE_MEDICAL_RECORD,
  DataType.DATA_TYPE_INSURANCE_CARD,
  DataType.DATA_TYPE_OTHER_UPLOAD,
]

export const signedDocuments = [
  DataType.DATA_TYPE_CA_FORM_601,
  DataType.DATA_TYPE_CA_FORM_602,
  DataType.DATA_TYPE_CA_FORM_603,
  DataType.DATA_TYPE_CA_FORM_603A,
  DataType.DATA_TYPE_PRN_AUTHORIZATION,
  DataType.DATA_TYPE_NURSE_DELEGATION,
]
