import { ContactPoint_ContactPointUse } from '@augusthealth/models/com/august/protos/contact_point'
import { Props as ReactSelectOption } from 'react-select'
import StyledSelect from '@shared/components/Selects/StyledSelect'

export type Option = {
  label: string
  value: ContactPoint_ContactPointUse
}

export type Props = Omit<ReactSelectOption, 'options'> & {
  holderStyle?: Record<string, string>
  options?: Option[]
}

export const CONTACT_POINT_USE_OPTIONS = [
  {
    label: 'Home',
    value: ContactPoint_ContactPointUse.CONTACT_POINT_USE_HOME,
  },
  {
    label: 'Work',
    value: ContactPoint_ContactPointUse.CONTACT_POINT_USE_WORK,
  },
  {
    label: 'Mobile',
    value: ContactPoint_ContactPointUse.CONTACT_POINT_USE_MOBILE,
  },
  {
    label: 'Emergency',
    value: ContactPoint_ContactPointUse.CONTACT_POINT_USE_EMERGENCY,
  },
]

export default function ContactPointUseDropdown(props: Props) {
  const { holderStyle, ...selectProps } = props
  return (
    <div style={holderStyle}>
      <StyledSelect {...selectProps} options={CONTACT_POINT_USE_OPTIONS} />
    </div>
  )
}
