import { fetchAnything } from '@shared/api/request'
import { loginUrl } from '@shared/api/urls'

export async function login(email: string, path?: string): Promise<void> {
  await fetchAnything(loginUrl(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, path }),
  })
}
