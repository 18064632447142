import { compact } from 'lodash'

const consoleMessageStyles = [
  'border-radius: 0.5em',
  'color: white',
  'font-weight: bold',
  'padding: 2px 0.5em',
]

const taggedConsoleMessageStyles = [
  ...consoleMessageStyles,
  'background-color: #4daab3',
].join(';')

export const taggedConsoleMessage = (
  tag: string,
  message: string,
  ...args: Array<unknown>
): [string, string, string, ...unknown[]] => {
  return consoleMessage(taggedConsoleMessageStyles, tag, message, ...args)
}

const errorConsoleMessageStyles = [
  ...consoleMessageStyles,
  'background-color: #E09D00',
].join(';')

export const errorConsoleMessage = (
  tag: string,
  message: string,
  ...args: Array<unknown>
): [string, string, string, ...unknown[]] => {
  return consoleMessage(errorConsoleMessageStyles, tag, message, ...args)
}

const consoleMessage = (
  styles: string,
  tag: string,
  message: string,
  ...args: Array<unknown>
): [string, string, string, ...unknown[]] => {
  const error: Error | undefined = args.find((value) => value instanceof Error)
  const rest: Array<unknown> = args.filter((value) => !(value instanceof Error))

  return [`%c${tag}`, styles, message, ...compact([...rest, error])]
}
