import classNames from 'classnames'
import { useContext, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { Snapshot, SnapshotStatus } from '@shared/types/snapshot'
import {
  GtkyPageProducerMode,
  GtkyPersonPatch,
  GtkySnapshotPatch,
} from '@shared/utils/gtky'
import FormSectionTitle from '@app/components/FormSectionTitle'
import FullpageFormFooter from '@app/components/FullpageFormFooter'
import { useContacts } from '@app/hooks/useContacts'
import styles from './gtky.module.css'
import { GettingToKnowYouSteps } from '../TaskPageNavigation/step-definitions'
import AboutYouPage from './AboutYouPage'
import FamilyAndFriendsPage from './FamilyAndFriendsPage'
import { GtkyPageToNumberedStepMap } from './helpers'
import InterestsPage from './InterestsPage'
import MealsPage from './MealsPage'
import ReligiousPreferencesPage from './ReligiousPreferencesPage'
import RoutinesPage from './RoutinesPage'

type Props = {
  snapshot: Snapshot
  person: Person
  currentStepNumber: number
  mode: GtkyPageProducerMode
}

export default function GettingToKnowYouPageProducer({
  snapshot,
  person,
  currentStepNumber,
  mode,
}: Props) {
  const { setError } = useContext(GlobalContext)
  const { contacts, refreshContacts } = useContacts()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const title = GettingToKnowYouSteps[currentStepNumber - 1]
  const isEditing = mode.tag === 'edit'

  const saveForm = async ({
    status,
    patch = null,
  }: {
    status: SnapshotStatus
    patch?: GtkySnapshotPatch | null
  }) => {
    if (isEditing) {
      try {
        setIsSubmitting(true)
        await mode.updateSnapshot({ patch, status })
      } catch (e) {
        setError(e)
      } finally {
        setIsSubmitting(false)
      }
    }
  }

  const savePerson = async ({
    patch,
    fields,
  }: {
    patch: GtkyPersonPatch
    fields: string
  }) => {
    if (isEditing) {
      try {
        setIsSubmitting(true)

        await mode.updatePerson({ patch, fields })
      } catch (e) {
        setError(e)
      } finally {
        setIsSubmitting(false)
      }
    }
  }

  const finishLater = async () => {
    if (isEditing) {
      await saveForm({ status: snapshot.status! })

      return mode.onFinishLater()
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (isEditing) {
      return mode.onContinue()
    }
  }

  const inputWrapperClass = classNames(styles.formInputs, {
    [styles.viewingSavedForm]: mode.tag === 'view',
  })

  return (
    <>
      <form onSubmit={handleSubmit} className={styles.form} id={'gtky'}>
        <section className={'h-fit'}>
          <FormSectionTitle title={title} className={'mb-[24px]'} />
          <div className={inputWrapperClass}>
            {currentStepNumber === GtkyPageToNumberedStepMap.ABOUT_YOU && (
              <AboutYouPage
                updateSnapshot={saveForm}
                updatePerson={savePerson}
                snapshot={snapshot}
                person={person}
                isEditing={isEditing}
              />
            )}
            {currentStepNumber ===
              GtkyPageToNumberedStepMap.FAMILY_AND_FRIENDS && (
              <FamilyAndFriendsPage
                updateSnapshot={saveForm}
                snapshot={snapshot}
                isEditing={isEditing}
              />
            )}
            {currentStepNumber ===
              GtkyPageToNumberedStepMap.RELIGIOUS_PREFERENCES && (
              <ReligiousPreferencesPage
                updateSnapshot={saveForm}
                person={person}
                snapshot={snapshot}
                contacts={contacts}
                refreshContacts={refreshContacts}
                isEditing={isEditing}
              />
            )}
            {currentStepNumber === GtkyPageToNumberedStepMap.INTERESTS && (
              <InterestsPage
                updateSnapshot={saveForm}
                snapshot={snapshot}
                isEditing={isEditing}
              />
            )}
            {currentStepNumber === GtkyPageToNumberedStepMap.ROUTINES && (
              <RoutinesPage
                updateSnapshot={saveForm}
                snapshot={snapshot}
                isEditing={isEditing}
              />
            )}
            {currentStepNumber === GtkyPageToNumberedStepMap.MEALS && (
              <MealsPage
                updateSnapshot={saveForm}
                updatePerson={savePerson}
                snapshot={snapshot}
                person={person}
                isEditing={isEditing}
              />
            )}
          </div>
        </section>
        {isEditing && (
          <FullpageFormFooter
            yesBtn={{
              text: (
                <>
                  Continue
                  <i className="far fa-angle-right ml-[8px]" />
                </>
              ),
              props: {
                isLoading: isSubmitting,
                disabled: isSubmitting,
                form: 'gtky',
              },
            }}
            noBtn={{
              text: 'Finish Later',
              action: finishLater,
            }}
          />
        )}
      </form>
    </>
  )
}
