import { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { BasicRadioButton } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import { Polst_CPR, Snapshot } from '@shared/types/snapshot'
import { tw } from '@shared/utils/tailwind'
import { PolstData } from '@app/pages/Documents/Viewer/helpers'

export default function PolstRadioGroup({
  polst,
  useFormReturn,
  onRequiredAttachmentChange,
}: {
  polst?: Snapshot // undefined if adding new POLST
  useFormReturn: UseFormReturn<PolstData>
  onRequiredAttachmentChange?: (required: boolean) => void // Use to enable Save without attachment
}) {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormReturn
  const isAddNewPolstMode = !polst
  const defaultAttachmentIsRequired = isAddNewPolstMode // default Required when in Add mode

  // Disable Radio group when attachment is missing in Viewer,
  // since you cannot switch between Full Code to DNR, No-attachment only works with Full Code.
  const disabled = Boolean(polst && !polst.fileMetaData)
  const [attachmentIsRequired, setAttachmentIsRequired] = useState(
    defaultAttachmentIsRequired
  )
  const codeStatus = watch('codeStatus')

  useEffect(() => {
    if (codeStatus === Polst_CPR.CPR_ATTEMPT_RESUSCITATION) {
      setAttachmentIsRequired(defaultAttachmentIsRequired)
      onRequiredAttachmentChange?.(defaultAttachmentIsRequired)
    } else if (codeStatus === Polst_CPR.CPR_DO_NOT_ATTEMPT_RESUSCITATION) {
      setAttachmentIsRequired(true)
      onRequiredAttachmentChange?.(true)
    }
  }, [codeStatus, defaultAttachmentIsRequired])

  return (
    <div>
      <input type="hidden" value="POLST" {...register('tag')} />
      <LabelAboveInput
        htmlFor="codeStatus"
        subLabel={requiredLabel(!!errors.codeStatus)}
      >
        Code Status
      </LabelAboveInput>
      <BasicRadioButton
        id="fullCode"
        value={Polst_CPR.CPR_ATTEMPT_RESUSCITATION}
        {...register('codeStatus', { required: true })}
        disabled={disabled}
      >
        Attempt Resuscitation (Full Code)
      </BasicRadioButton>
      <BasicRadioButton
        id="dnr"
        value={Polst_CPR.CPR_DO_NOT_ATTEMPT_RESUSCITATION}
        {...register('codeStatus', { required: true })}
        disabled={disabled}
      >
        Do Not Attempt Resuscitation (DNR)
      </BasicRadioButton>
      {codeStatus === Polst_CPR.CPR_ATTEMPT_RESUSCITATION &&
        isAddNewPolstMode && (
          <>
            <LabelAboveInput className={tw`mt-[24px]`} htmlFor="attachment">
              Attachment
            </LabelAboveInput>
            <div className={tw`flex`}>
              <BasicRadioButton
                name="attachment"
                value="required"
                disabled={disabled}
                checked={attachmentIsRequired}
                onChange={() => {
                  setAttachmentIsRequired(true)
                  onRequiredAttachmentChange?.(true)
                }}
              >
                Required
              </BasicRadioButton>
              <BasicRadioButton
                name="attachment"
                value="optional"
                disabled={disabled}
                className={tw`ml-[16px]`}
                checked={!attachmentIsRequired}
                onChange={() => {
                  setAttachmentIsRequired(false)
                  onRequiredAttachmentChange?.(false)
                }}
              >
                Optional
              </BasicRadioButton>
            </div>
          </>
        )}
    </div>
  )
}
