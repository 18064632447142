import {
  SignableFormMetaData,
  SignerRequest,
} from '@augusthealth/models/com/august/protos/signable_form'
import { useFieldArray, useForm } from 'react-hook-form'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { ModalTitle } from '@shared/components/baseMui/Modal/Layout'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import {
  Label,
  LabelAboveInput,
  requiredLabel,
} from '@shared/components/Labels'
import { tw } from '@shared/utils/tailwind'
import { patchForm } from '@app/api/form'

type EditTemplatePopupProps = {
  form: SignableFormMetaData
  onClose: (form?: SignableFormMetaData) => void
}

export type EditTemplateFormData = {
  name: string
  description: string
  signers: SignerRequest[]
}

// TODO: Error handling
export default function EditTemplatePopup({
  onClose,
  form,
}: EditTemplatePopupProps) {
  const signerConfiguration = form.signerConfiguration ?? []
  const { handleSubmit, register, control, formState } =
    useForm<EditTemplateFormData>({
      mode: 'onChange',
      defaultValues: {
        name: form.name ?? '',
        description: form.description ?? '',
        signers: signerConfiguration,
      },
    })

  const { fields } = useFieldArray({
    name: 'signers',
    control,
  })

  return (
    <Modal
      open={true}
      id={'edit-signers'}
      onClose={onClose}
      contentClassName={'w-[646px] min-w-[646px]'}
    >
      <ModalTitle>Editing {form.name}</ModalTitle>
      <form
        onSubmit={handleSubmit(async (formData) => {
          const response = await patchForm({
            id: `${form.id}`,
            patch: {
              ...form,
              name: formData.name,
              description: formData.description,
              signerConfiguration: formData.signers,
            },
          })
          onClose(response)
        })}
      >
        <section>
          <LabelAboveInput
            subLabel={requiredLabel(Boolean(formState.errors.name))}
            className={tw`pb-2`}
            htmlFor="name"
          >
            Name
          </LabelAboveInput>
          <BasicInput {...register('name', { required: true, minLength: 1 })} />
          <LabelAboveInput className={tw`pb-2 pt-input`} htmlFor="description">
            Description
          </LabelAboveInput>
          <BasicInput {...register('description', { required: false })} />
          {fields.map((x, i) => (
            <div key={x.id} className={tw`pt-input`}>
              <div className={tw`align-items-baseline flex justify-between`}>
                <Label htmlFor={`signers.${i}.description`}>
                  Description for {x.role}
                </Label>
                <div
                  title={'This gives the ability to skip a signer.'}
                  className={tw`flex pl-4`}
                >
                  <label htmlFor={`signers.${i}.canSkip`}>
                    <span
                      className={tw`text-[14px] font-medium text-sub-title-font-color`}
                    >
                      Optional?
                    </span>
                  </label>
                  <div className={tw`pl-2 pt-0.5`}>
                    <input
                      data-testid={`signers.${i}.canSkip`}
                      type="checkbox"
                      id={`tag-${i}`}
                      {...register(`signers.${i}.canSkip`)}
                    />
                  </div>
                </div>
              </div>
              <BasicInput {...register(`signers.${i}.description`)} />
            </div>
          ))}
        </section>
        <footer>
          <div className={tw`bottom-2 flex justify-end gap-4 pt-input`}>
            <AsyncIconButton
              id="denyBtn"
              className={tw`w-15 mt-[12px]`}
              buttonStyle="secondary-outline"
              onClick={() => onClose()}
              tabIndex={0}
              width="100%"
            >
              Cancel
            </AsyncIconButton>
            <AsyncIconButton
              buttonStyle={'primary-fill'}
              className={tw`w-15 mt-[12px]`}
              data-testid="confirm-button"
              id="confirmBtn"
              type={'submit'}
              autoFocus={true}
              tabIndex={0}
              width="100%"
            >
              Save
            </AsyncIconButton>
          </div>
        </footer>
      </form>
    </Modal>
  )
}
