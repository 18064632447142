import { useContext, useEffect, useState } from 'react'
import { fetchResidentBillingEvents } from '@shared/api/billing'
import GlobalContext from '@shared/contexts/GlobalContext'
import { BillingEvent } from '@shared/types/billing'
import { RequiredPersonIds } from '@shared/types/person'
import { loaded, LOADING, Loading } from '@shared/utils/loading'

export default function useBillingEvents(props: RequiredPersonIds) {
  const { orgId, facilityId, id: personId } = props
  const { setError } = useContext(GlobalContext)
  const [billingEvents, setBillingEvents] =
    useState<Loading<BillingEvent[]>>(LOADING)

  async function reloadBillingEvents() {
    setBillingEvents(LOADING)
    try {
      const events = await fetchResidentBillingEvents(props)
      setBillingEvents(loaded(events))
    } catch (e) {
      setError(e)
      setBillingEvents(loaded([]))
    }
  }

  useEffect(() => {
    void reloadBillingEvents()
  }, [orgId, facilityId, personId])

  return {
    billingEvents,
    reloadBillingEvents,
  }
}
