import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { fetchPerson } from '@shared/api/person'
import { useUserContext } from '@shared/contexts/UserContext'
import useCurrentPage, { extractIds } from '@shared/hooks/useCurrentPage'
import { admissionTasksPathForPerson, documentUrl } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { Task, TaskType } from '@shared/types/task'
import { isResponsiblePerson } from '@shared/utils/user'
import {
  getAdmissionTasks,
  getHasCompletedTasks,
  getSortedTasks,
} from '@app/components/Prospects/Tasks/helpers'
import List from '@app/components/Prospects/Tasks/List'
import { GroupProps } from '@app/components/Prospects/Tasks/type'
import { TaskSortOrder } from '@app/components/SortByDropdown/helpers'
import PersonContext from '@app/contexts/PersonContext'
import GeneralUploader from '@app/pages/Documents/Uploader'

export default function TaskGroups(props: GroupProps) {
  const {
    onUploadDocument,
    uploadPopupTask,
    setOpenUploadPopup,
    refreshTasks,
    taskSortOrder = TaskSortOrder.TASK_SORT_BY_NAME_ASC,
  } = props
  const history = useHistory()
  const page = useCurrentPage()
  const { facilityId, orgId, personId } = extractIds(page)
  const { tasks = [], person, setPerson } = useContext(PersonContext)
  const { user } = useUserContext()
  const isRP = Boolean(
    orgId &&
      facilityId &&
      personId &&
      isResponsiblePerson({ user, orgId, facilityId, personId })
  )
  const validTasks = getAdmissionTasks(tasks, isRP, user)
  const sortedTasks = getSortedTasks(validTasks, taskSortOrder)

  if (!personId || !orgId || !facilityId || !person) {
    return null
  }

  if (uploadPopupTask?.snapshotId) {
    // snapshotId indicates the task has an upload, so redirect to documents to view it
    history.push(
      documentUrl({
        person: person as Required<Person>,
        documentId: uploadPopupTask.snapshotId,
      })
    )
  }

  const updatePerson = async () => {
    return fetchPerson({
      orgId,
      facilityId,
      personId,
    }).then(setPerson)
  }

  return (
    <>
      <List
        orgId={orgId}
        facilityId={facilityId}
        openUploadPopup={(task: Task) => {
          setOpenUploadPopup(task)
        }}
        list={sortedTasks}
        hasCompletedTasks={getHasCompletedTasks(tasks)}
        refreshTasks={refreshTasks}
      />
      {uploadPopupTask && (
        <GeneralUploader
          initialCustomType={uploadPopupTask.taskTemplateInfo?.customType}
          initialType={uploadPopupTask.taskTemplateInfo!.dataType}
          person={person}
          tasks={[uploadPopupTask]}
          onUpdate={async () => {
            // Update the person because a POLST may have been uploaded
            // which results in a change on the Person object
            const showOpenCongrats =
              uploadPopupTask.taskTemplateInfo?.taskType !==
              TaskType.TASK_TYPE_IMMUNIZATION_RECORD
            // Do not show Congratz popup for TASK_TYPE_IMMUNIZATION_RECORD
            // So user can keep edit when Add or Delete an immunization record
            await Promise.all([
              onUploadDocument(showOpenCongrats),
              updatePerson(),
            ])
          }}
          onClose={() => {
            if (page.tag === 'Person' && page.tab.tag === 'Task') {
              history.push(
                admissionTasksPathForPerson(person as Required<Person>)
              )
            } else {
              setOpenUploadPopup(undefined)
            }
          }}
        />
      )}
    </>
  )
}
