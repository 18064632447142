import { AsyncIconButton } from '@shared/components/AsyncButton'
import FilterBadge from '@app/pages/Notes/FilterBadge'
import { InputWithIcon } from '@app/pages/Notes/types'
import styles from './styles.module.css'

export function HeaderAndBadges({
  showFilterButton,
  onFilterButtonClick,
  badges,
  onFilterBadgeClick,
}: {
  showFilterButton: boolean
  badges: InputWithIcon[]
  onFilterButtonClick: () => void
  onFilterBadgeClick: (name: string, value: string) => void
}) {
  return (
    <>
      <div className={`page-title-compact ${styles.titleContainer}`}>
        <span>Notes & Incidents</span>
        {showFilterButton && (
          <AsyncIconButton
            data-testid="incidents-filter-button"
            buttonStyle={'secondary-outline'}
            onClick={onFilterButtonClick}
          >
            <i className="fa-solid fa-bars-filter mr-[8px]" />
            Filter
          </AsyncIconButton>
        )}
      </div>
      {badges.length > 0 && (
        <div>
          <div className="full-width mb-[16px]">
            {badges.map((badge) => (
              <FilterBadge
                icon={badge.icon}
                text={badge.name}
                key={badge.value}
                onClick={() =>
                  onFilterBadgeClick(badge.groupName!, badge.value)
                }
              />
            ))}
          </div>
        </div>
      )}
    </>
  )
}
