import {
  ContactPoint,
  ContactPoint_ContactPointSystem,
} from '@augusthealth/models/com/august/protos/contact_point'
import { Person } from '@shared/types/person'
import { filterOutEmptyTelecom } from '@shared/utils/contactPoint'
import notEmpty from '@shared/utils/notEmpty'
import { FormData } from './types'

export function makePatch(formData: FormData, person: Partial<Person>) {
  return {
    name: name(formData),
    birthDate: formData.dob,
    gender: formData.gender,
    telecom: filterOutEmptyTelecom(updateTelecom(person, formData)),
  }
}

function name(formData: FormData) {
  return [
    {
      given: [formData.firstName, formData.middleName],
      family: formData.lastName,
      prefix: formData.title,
    },
  ]
}

function updateTelecom(
  person: Partial<Person>,
  formData: FormData
): ContactPoint[] {
  if (person.telecom) {
    const existingTelecom = person.telecom
    const updatedExisting = updateExistingTelecom(existingTelecom, formData)
    const newlyAdded = addNewTelecom(existingTelecom, formData)

    return updatedExisting.concat(newlyAdded)
  } else {
    return telecom(formData)
  }
}

function updateExistingTelecom(
  existingTelecom: ContactPoint[],
  formData: FormData
) {
  return existingTelecom
    .map((tcom) => {
      const isPhone =
        tcom.system ===
        ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_PHONE
      const isEmail =
        tcom.system ===
        ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_EMAIL

      if (isPhone) {
        return getPhone(formData)
      } else if (isEmail) {
        return email(formData)
      } else {
        return tcom
      }
    })
    .filter(notEmpty)
}

function addNewTelecom(existingTelecom: ContactPoint[], formData: FormData) {
  return [getPhone(formData), email(formData)]
    .filter(notEmpty)
    .filter((newContact) => {
      const existingSystems = existingTelecom.map((tcom) => tcom.system)
      return !existingSystems.includes(newContact.system)
    })
}

function getPhone(formData: FormData): ContactPoint | null {
  if (formData.phone.length === 0) {
    return null
  }

  const use =
    formData.phoneUse && formData.phoneUse.length > 0
      ? formData.phoneUse
      : undefined

  return {
    system: ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_PHONE,
    use,
    value: formData.phone,
  }
}

function email(formData: FormData): ContactPoint | null {
  if (formData.email.length === 0) {
    return null
  }

  return {
    system: ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_EMAIL,
    value: formData.email,
  }
}

function telecom(formData: FormData) {
  const comms: Array<ContactPoint | null> = []

  if (formData.phone.length > 0) {
    comms.push(getPhone(formData))
  }

  if (formData.email.length > 0) {
    comms.push(email(formData))
  }

  return comms.filter(notEmpty)
}
