import { AppraisalSettings_CustomDetails } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { FrontendDetail } from '@shared/types/assessment'
import { ServicePlan_PlanCategoryKey as CatKey } from '@shared/types/service_plan'
import {
  AppraisalSettings_AppraisalCategory,
  AppraisalSettings_AppraisalCategoryLevel,
  AppraisalSettings_AppraisalCategoryOptions,
  AppraisalSettings_AssessmentReasonWrapper,
  AppraisalSettings_Level,
} from '@shared/types/settings/appraisal_settings'
import { AugustFieldType } from './custom_field'

export type CategoryKeyIdentifier =
  | Exclude<
      CatKey,
      | CatKey.UNRECOGNIZED
      | CatKey.PLAN_CATEGORY_KEY_UNSPECIFIED
      | CatKey.PLAN_CATEGORY_KEY_CUSTOM
    >
  | `${CatKey.PLAN_CATEGORY_KEY_CUSTOM}-${string}`

export const CUSTOM_DETAIL =
  AppraisalSettings_CustomDetails.CUSTOM_DETAILS_DEFAULT
export const CUSTOM_TEXTBOX_DETAIL =
  AppraisalSettings_CustomDetails.CUSTOM_DETAILS_TEXTBOX
export const CUSTOM_TEXTAREA_DETAIL =
  AppraisalSettings_CustomDetails.CUSTOM_DETAILS_TEXTAREA
export const CUSTOM_DROPDOWN_DETAIL = 'CUSTOM_DETAILS_DROPDOWN' as const

export type CustomDetailEnum =
  | AppraisalSettings_CustomDetails
  | typeof CUSTOM_DROPDOWN_DETAIL
  | AugustFieldType

export type AssessmentConfig = {
  CategoryKeyIdentifier?: {
    levels: AssessmentLevel[]
    details: Map<string, FrontendDetail[]>
    categoryOptions: AssessmentCategoryOptions
    deleteOnSave?: boolean
    isExcludedFromServicePlan: boolean
  }
}

export type AssessmentCategory = Omit<
  AppraisalSettings_AppraisalCategory,
  'categoryKey' | 'categoryOptions'
> & {
  categoryKey: CategoryKeyIdentifier
  categoryOptions: AssessmentCategoryOptions
}

export type AssessmentCategoryOptions =
  AppraisalSettings_AppraisalCategoryOptions & {
    originalTitle?: string
  }

export type AssessmentLevel = AppraisalSettings_AppraisalCategoryLevel & {
  tempId?: string // used to track adding/removing of levels in the Assessment Configuration Tool
}

export type AssessmentReason = AppraisalSettings_AssessmentReasonWrapper & {
  tempId: string // used to track changes of assessment reasons in the Assessment Configuration Tool
}

export type UpdateDetailByPageMap = {
  CategoryKeyIdentifier?: UpdatedDetailMap
}

export type UpdatedDetailMap = {
  [index: number | string]: ConfigurationUpdate[]
}

export type ConfigurationUpdate =
  | {
      type: 'option'
      valueChanged: 'category title'
      updated: string
      original: string
    }
  | {
      type: 'level'
      valueChanged: 'description' | 'score' | 'question'
      original: string | number
      updated: string | number
      level?: AppraisalSettings_Level // used for description and score
      group: string
    }
  | {
      type: 'detail'
      valueChanged: 'description'
      original: string | number
      updated: string | number
      answerGroup?: string
      group: string
    }
  | {
      type: 'detail'
      valueChanged: 'score' | 'enum' | 'isRequired' | 'helpText'
      original: string | number | boolean
      updated: string | number | boolean
      descriptor: string
      answerGroup?: string
      group: string
    }
  | {
      type: 'newDetail'
      valueChanged: 'added detail'
      description: string
      helpText?: string
      score?: number
      answerGroup?: string // to be used for new dropdowns
      group: string
      isRequired?: boolean // used for textboxes only
    }
  | {
      type: 'removeDetail'
      valueChanged: 'removed detail' | 'removed option'
      helpText?: string
      description: string
      answerGroup?: string
      group: string
    }
  | {
      type: 'removeLevel'
      valueChanged: 'removed level'
      level: AppraisalSettings_Level
      group: string
    }
  | {
      type: 'newLevel'
      valueChanged: 'added level'
      description: string
      score: number
      group: string
      level: AppraisalSettings_Level
    }
  | {
      type: 'detailGroup'
      valueChanged: 'removed detail group' | 'new detail group'
      group: string
    }
  | {
      type: 'deleteCategory'
      valueChanged: 'Category will be deleted'
      categoryTitle: string
    }

export type DetailModalMode =
  | {
      tag: 'add'
      addDetail: ({
        newDetail,
        key,
      }: {
        newDetail: Partial<FrontendDetail>
        key: string
      }) => void
    }
  | {
      tag: 'edit'
      currentDetail: FrontendDetail
      optIndex?: number
      index: number
      editDetail: ({
        updatedDetail,
        index,
        optIndex,
      }: {
        updatedDetail: Partial<FrontendDetail>
        index: number
        optIndex?: number
      }) => void
    }

export type LevelModalMode =
  | {
      tag: 'add'
      addLevel: ({ newLevel }: { newLevel: AssessmentLevel }) => void
    }
  | {
      tag: 'edit'
      editLevel: ({
        updatedLevel,
        index,
      }: {
        updatedLevel: AssessmentLevel
        index: number
      }) => void
      currentLevel: AssessmentLevel
      index: number
    }
