import { DateMessage } from '@augusthealth/models/com/august/protos/date'
import classNames from 'classnames'
import React from 'react'
import { formatDateMessage } from '@shared/utils/date'
import { twx } from '@shared/utils/tailwind'
import styles from '../styles.module.css'

type Props = {
  onClick?: () => void
  date?: DateMessage
  label: string
  icon: string
  readOnly?: boolean
  isPartialTaper?: boolean
  children?: React.ReactNode
  rowClassName?: string
}
export const FooterRowDisplay = ({
  onClick,
  label,
  readOnly,
  icon,
  children,
  rowClassName,
}: Props) => {
  const infoRowClass = classNames(styles.additionalInfoRow, rowClassName, {
    [styles.readOnlyInfo]: readOnly,
  })

  return (
    <div onClick={onClick} className={infoRowClass}>
      <i className={`fa-fw fas mr-[8px] text-[14px] ${icon}`} />
      {label}
      {children}
    </div>
  )
}

type DateDisplayProps = {
  onClick?: () => void
  date?: DateMessage
  label: string
  readOnly?: boolean
  isPartialTaper?: boolean
}

export const DateDisplay = ({
  onClick,
  date,
  label,
  readOnly,
  isPartialTaper = false,
}: DateDisplayProps) => {
  const infoRowClass = twx({
    [styles.taperPartDate]: isPartialTaper,
  })

  const validDate = date && !isNaN(date.year as number)

  const icon = isPartialTaper ? 'fa-calendar-week' : 'fa-calendar-day'

  return (
    <FooterRowDisplay
      onClick={isPartialTaper ? undefined : onClick}
      label={label}
      icon={icon}
      rowClassName={infoRowClass}
      readOnly={readOnly}
    >
      <span className={styles.readValue}>
        {validDate ? formatDateMessage(date as DateMessage) : 'None'}
      </span>
    </FooterRowDisplay>
  )
}
