import Select, { components, ContainerProps, Props } from 'react-select'
import {
  getComponents,
  sharedSelectProps,
} from '@shared/components/Selects/StyledSelect'
import { BadgeSelectStyleConfig } from '@shared/components/Selects/utils'
import { SquareBadge } from '@shared/components/SquareBadge'
import { GenericTooltip } from '@shared/components/Tooltips/GenericTooltip'
import { tw, twx } from '@shared/utils/tailwind'

type extraProps = {
  badgeLabel: string
  name: string
  showErrorBorder?: boolean
  className?: string
  containerClassName?: string
  tooltip?: string
}
type BadgeSelectProps = Props & extraProps

export const BadgeSelect = (props: BadgeSelectProps) => {
  return (
    <Select
      styles={BadgeSelectStyleConfig({
        inputName: props.name,
        error: props.showErrorBorder,
      })}
      id={props.name}
      instanceId={props.name}
      inputId={`input-${props.name}`}
      aria-labelledby={`label-${props.name}`}
      {...sharedSelectProps}
      {...props}
      components={getComponents({
        ...props.components,
        SelectContainer,
      })}
    />
  )
}

const SelectContainer = ({
  children,
  ...props
}: ContainerProps & {
  selectProps: extraProps
}) => {
  const {
    selectProps: {
      badgeLabel,
      className,
      containerClassName,
      showErrorBorder = false,
      tooltip,
      name,
    },
    ...rest
  } = props

  const containerClasses = twx(
    'flex items-center h-input border border-form-input-border rounded-input py-inputPadding pl-inputPadding pr-[4px] bg-white hover:border-form-input-border-hover focus-within:border-form-input-border-hover focus-within:outline-double focus-within:outline-1 focus-within:outline-form-input-border-hover',
    {
      'border-form-input-error-border hover:border-form-input-error-border hover:outline-form-input-error-border focus-within:border-form-input-error-border focus-within:outline-form-input-error-border':
        showErrorBorder,
      'bg-form-input-disabled-background-color text-form-input-disabled-text-color hover:outline-none hover:border-form-input-border cursor-default':
        props.isDisabled,
    },
    className,
    containerClassName
  )

  const badgeClasses = twx(
    'h-[18px] py-[3px] px-[4px] text-[12px] leading-[12px] whitespace-nowrap flex-0',
    {
      'bg-gray-07': props.isDisabled,
      'bg-form-alert': showErrorBorder,
    }
  )

  return (
    <div className={tw`${containerClasses}`}>
      <label
        htmlFor={name}
        id={`label-${name}`}
        className={twx(
          'mx-0 my-[4px] inline-flex w-fit items-center justify-center'
        )}
      >
        <SquareBadge className={tw`${badgeClasses}`}>{badgeLabel}</SquareBadge>
      </label>
      <div className={tw`relative flex-1`}>
        {tooltip && (
          <GenericTooltip
            className={tw`absolute bottom-input w-fit whitespace-nowrap hover:opacity-80`}
          >
            {tooltip}
          </GenericTooltip>
        )}
        <components.SelectContainer {...rest} selectProps={props.selectProps}>
          {children}
        </components.SelectContainer>
      </div>
    </div>
  )
}
