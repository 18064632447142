import { useContext } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import ConfirmModal from '@app/components/ConfirmModal'
import styles from './deleteCharge.module.css'
import { deleteCharge } from './helpers'
import { OneTimeCharge } from './OneTimeCharge'
import { RecurringCharge } from './RecurringCharge'

interface Props {
  chargeToDelete: OneTimeCharge | RecurringCharge
  person: Person
  done: (deleted: boolean) => void
}

export function DeleteCharge({ chargeToDelete, person, done }: Props) {
  const { setError } = useContext(GlobalContext)

  const onConfirm = async () => {
    try {
      await deleteCharge(chargeToDelete, person)
      done(true)
    } catch (e) {
      setError(e)
    }
  }

  return (
    <ConfirmModal
      title="Do you want to delete this charge?"
      content={contentForCharge(chargeToDelete)}
      confirmButtonConfig={{
        children: 'Delete Charge',
        onClick: onConfirm,
      }}
      denyButtonConfig={{ onClick: () => done(false) }}
    />
  )
}

function contentForCharge(chargeToDelete: RecurringCharge | OneTimeCharge) {
  return (
    <div className={styles.deleteCharge}>
      <h3>{chargeToDelete.title}</h3>
      <span>
        {chargeToDelete.payer} · {chargeToDelete.amount}
      </span>
    </div>
  )
}
