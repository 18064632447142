import {
  ContactPoint,
  ContactPoint_ContactPointUse,
} from '@augusthealth/models/com/august/protos/contact_point'
import { Ombudsman } from '@augusthealth/models/com/august/protos/facility'
import { ChangeEvent } from 'react'
import {
  setFirstEmail,
  setFirstFax,
  setFirstPhone,
} from '@shared/utils/contactPoint'

type SetTelecomProps = {
  telecom: ContactPoint[]
  value: string
  use?: ContactPoint_ContactPointUse
}

type GetOnTelecomChangeProps = {
  onTelecomChange: (telecom: ContactPoint[]) => void
  setTelecom: (props: SetTelecomProps) => ContactPoint[]
  telecom: ContactPoint[]
}

type GetOnEmailFaxChangeProps = Omit<GetOnTelecomChangeProps, 'setTelecom'>

type GetOnPhoneChangeProps = GetOnEmailFaxChangeProps & {
  use: ContactPoint_ContactPointUse
}

function getOnTelecomChange(props: GetOnTelecomChangeProps) {
  const { onTelecomChange, setTelecom, telecom } = props

  return (ev: ChangeEvent<HTMLInputElement>) => {
    const newEmail = ev.currentTarget.value.trim()
    const newTelecom = setTelecom({
      telecom,
      value: newEmail,
    })
    onTelecomChange(newTelecom)
  }
}

export function getOnEmailChange(props: GetOnEmailFaxChangeProps) {
  return getOnTelecomChange({
    ...props,
    setTelecom: setFirstEmail,
  })
}

export function getOnPhoneChange(props: GetOnPhoneChangeProps) {
  return getOnTelecomChange({
    ...props,
    setTelecom: setFirstPhone,
  })
}

export function getOnFaxChange(props: GetOnEmailFaxChangeProps) {
  return getOnTelecomChange({
    ...props,
    setTelecom: setFirstFax,
  })
}

type OnOmbudsmanPhoneChangeProps = {
  ombudsman: Ombudsman
  onChange: (ombudsman?: Ombudsman) => void
  use: ContactPoint_ContactPointUse
}

export function getOnOmbudsmanPhoneChange(props: OnOmbudsmanPhoneChangeProps) {
  const { ombudsman, onChange, use } = props

  return (ev: ChangeEvent<HTMLInputElement>) => {
    const newPhone = ev.currentTarget.value.trim()
    const { telecom = [] } = ombudsman
    const newTelecom = setFirstPhone({
      telecom,
      value: newPhone,
      use,
    })
    if (newTelecom.length) {
      onChange({
        ...ombudsman,
        telecom: newTelecom,
      })
    } else {
      onChange(undefined)
    }
  }
}
