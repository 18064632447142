import { Contact_ContactRelationship as Relationship } from '@augusthealth/models/com/august/protos/contact'
import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import { RELATIONSHIPS_BY_CATEGORY_MAP } from '@shared/constants/contactRelationship'
import { useUserContext } from '@shared/contexts/UserContext'
import ContactDropdown, { ContactDropdownProps } from './index'

export default function RpContactDropdown(props: ContactDropdownProps) {
  const { user } = useUserContext()
  const { person } = props

  // Force to read-only, if GROUP_PERMISSION_PERSON_CONTACT_SET_RP, allow to redirect to Contacts page
  const hasAccess = hasPermissionForPerson({
    permissions: [GroupPermission.GROUP_PERMISSION_PERSON_CONTACT_SET_RP],
    person,
    user,
  })

  // CLINICIAN related relationshps cannot be RP, except OTHER which is part of PERSONAL relationship category too.
  const relationshipsToExclude = RELATIONSHIPS_BY_CATEGORY_MAP[
    Relationship.CONTACT_RELATIONSHIP_CLINICIAN
  ]?.filter((r) => r !== Relationship.CONTACT_RELATIONSHIP_OTHER)

  return (
    <ContactDropdown
      title="RESPONSIBLE PERSON"
      description="Select the primary representative who has authority to act on behalf of the resident"
      placeholder="Select responsible person"
      unique
      includeContactsPageLink={hasAccess}
      {...props}
      readOnly
      relationshipsToExclude={relationshipsToExclude || []}
    />
  )
}
