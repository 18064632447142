import { Control, Controller } from 'react-hook-form'
import { BadgeSelect } from '@shared/components/Selects/BadgeSelect'
import {
  OptionTypeBase,
  SelectComponent,
  StyledOption,
  StyledOptionProps,
} from '@shared/components/Selects/StyledSelect'
import { MedOrderFormData } from '@shared/types/medication_order'
import { VitalType } from '@shared/types/vital'
import notEmpty from '@shared/utils/notEmpty'
import { tw, twx } from '@shared/utils/tailwind'
import {
  VitalEnumToVitalsType,
  VITALS_INPUT_PROPS_MAP,
  VitalsInputData,
} from '@shared/utils/vitals'

const VITAL_OPTIONS: OptionTypeBase<
  VitalType,
  VitalsInputData<MedOrderFormData>
>[] = Object.values(VitalType)
  .filter(
    (vital) =>
      vital !== VitalType.UNRECOGNIZED &&
      vital !== VitalType.VITAL_TYPE_UNSPECIFIED
  )
  .map((vital) => {
    const vitalType = vital && VitalEnumToVitalsType(vital)
    const vitalProps: VitalsInputData<MedOrderFormData> | null =
      vitalType && VITALS_INPUT_PROPS_MAP(vitalType)

    if (!vitalProps) {
      return null
    }
    return {
      label: vitalProps.label,
      value: vital,
      data: vitalProps,
    }
  })
  .filter(notEmpty)

type Props = {
  control: Control<MedOrderFormData>
  disabled: boolean
}
export const VitalsDropdown = ({ control, disabled }: Props) => {
  return (
    <Controller
      control={control}
      name={'vitalType'}
      shouldUnregister={true}
      rules={{
        required: true,
        validate: {
          required: (option) => {
            if (!option || option === VitalType.VITAL_TYPE_UNSPECIFIED) {
              return 'Required'
            }

            return true
          },
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <BadgeSelect
            badgeLabel={'Vital Type'}
            containerClassName={tw`w-full`}
            name="vitalType"
            openMenuOnFocus={true}
            placeholder="Select vital..."
            value={VITAL_OPTIONS.find((opt) => opt?.value === value) ?? null}
            options={VITAL_OPTIONS}
            isDisabled={disabled}
            showErrorBorder={!!error}
            components={{
              Option: VitalDropdownOption as SelectComponent,
            }}
            onChange={(e: OptionTypeBase<VitalType> | null) => {
              if (e) {
                onChange(e.value)
              } else {
                onChange(null)
              }
            }}
          />
        )
      }}
    />
  )
}

const VitalDropdownOption = (
  props: StyledOptionProps<OptionTypeBase<VitalType, Record<string, string>>>
) => {
  const { children, ...rest } = props

  return (
    <StyledOption
      labelClassName={tw`text-secondary-04`}
      iconClassName={twx(
        `text-primary-light ml-3`,
        props.data.data?.iconClassName
      )}
      {...rest}
    >
      {children}
    </StyledOption>
  )
}
