import { useContext } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { UseFormReturn } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
  SelectComponent,
  StyledOption,
  StyledOptionProps,
} from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { FormDisplay } from '@shared/types/signable_form'
import { getOrElse } from '@shared/utils/loading'
import {
  getInternalErrorCode,
  imageOnlyDropzoneOptions,
} from '@shared/utils/reactDropzone'
import { tw, twx } from '@shared/utils/tailwind'
import { getFileNameWithoutExtension, uploadFormImage } from '@app/api/form'
import { useFormImageKeys } from '@app/hooks/useFormImage'
import { getCustomFieldName } from '../../helpers'

type OptionData = 'UPLOAD_IMAGE_ACTION' | 'IMAGE_SELECTION'

type Option = OptionTypeBase<string, OptionData>

function getOptions(keys: string[]): Option[] {
  const options: Option[] = keys.map((item) => ({
    label: item,
    value: item,
    data: 'IMAGE_SELECTION',
  }))

  options.push({
    label: 'Upload a new image...',
    value: '',
    data: 'UPLOAD_IMAGE_ACTION',
  })

  return options
}

function convertFileNameToFieldName(fileName: string) {
  return `logo.${fileName}`
}

export function FormImageOption(props: StyledOptionProps<Option>) {
  const {
    data: { data },
  } = props

  if (data === 'UPLOAD_IMAGE_ACTION') {
    return (
      <StyledOption
        labelClassName={tw`text-primary`}
        iconClassName={twx(`text-primary`, {
          'fa-upload': data === 'UPLOAD_IMAGE_ACTION',
        })}
        {...props}
      />
    )
  }

  return <StyledOption {...props} />
}

export default function FormImageDropdownRow({
  fieldIndex,
  pageIndex,
  useFormReturn,
}: {
  fieldIndex: number
  pageIndex: number
  useFormReturn: UseFormReturn<FormDisplay>
}) {
  const { setError } = useContext(GlobalContext)
  const { formId } = useParams<{ formId: string }>()
  const formImageDropdownName = `formImageDropdownName-${pageIndex}-${fieldIndex}`
  const { getValues, setValue } = useFormReturn
  const { formImageKeys, loadFormImageKeys } = useFormImageKeys({
    signableFormId: formId,
  })
  const fieldName = getCustomFieldName(pageIndex, fieldIndex, 'name')
  const defaultValue = getValues(fieldName)?.split('.')[1]
  const options = getOptions(getOrElse(formImageKeys, []))
  const defaultOption = options.find((opt) => opt.value === defaultValue)

  const onDrop = (files: File[], fileRejections: FileRejection[]) => {
    const internalErrorCode = getInternalErrorCode({ fileRejections })
    if (internalErrorCode) {
      setError({ internalCode: internalErrorCode })
    }

    if (files.length) {
      const uploadedFile = files[0]

      uploadFormImage({
        signableFormId: formId,
        file: uploadedFile,
      })
        .then(() => loadFormImageKeys())
        .then(() => {
          setValue(
            fieldName,
            convertFileNameToFieldName(
              getFileNameWithoutExtension(uploadedFile)
            )
          )
        })
        .catch(setError)
    }
  }

  const { inputRef, getInputProps } = useDropzone({
    ...imageOnlyDropzoneOptions,
    maxFiles: 1,
    onDrop,
  })

  return (
    <tr>
      <td>
        <LabelAboveInput
          className={tw`mb-0`}
          htmlFor={formImageDropdownName}
          uppercase={false}
        >
          Form Image
        </LabelAboveInput>
      </td>
      <td>
        <StyledSelect
          components={{ Option: FormImageOption as SelectComponent }}
          placeholder="Select an image key..."
          options={options}
          onChange={(opt: Option) => {
            if (opt.data === 'UPLOAD_IMAGE_ACTION') {
              inputRef.current?.click()
            } else {
              setValue(fieldName, convertFileNameToFieldName(opt.value))
            }
          }}
          value={defaultOption || null}
        />
        <input {...getInputProps()} />
      </td>
    </tr>
  )
}
