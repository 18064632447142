import { useEffect, useState } from 'react'
import { Person } from '@shared/types/person'
import { DataType, Snapshot } from '@shared/types/snapshot'
import {
  getImmunizationsByDiseaseName,
  mostRecentAttachmentId as getMostRecentAttachmentId,
} from '@shared/utils/immunizations'
import { Loading } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import { fetchSnapshot } from '@app/api/snapshot'
import useImmunizations from '@app/hooks/useImmunizations'
import VaccineViewer from '@app/pages/Documents/Viewer/VaccineViewer'
import { PlainDetailsCard } from '../DetailsCard'
import ImmunizationList from './ImmunizationList'

export default function Vaccinations({ person }: { person: Person }) {
  const [diseaseInPopup, setDiseaseInPopup] = useState<string | undefined>(
    undefined
  )
  const [attachmentToView, setAttachmentToView] = useState<
    Loading<Snapshot | undefined> | undefined
  >()

  const { immunizations, reloadImmunizations } = useImmunizations()
  const onUpdate = async () => await reloadImmunizations()
  const onClose = () => setDiseaseInPopup(undefined)

  // TO-DO, fetch Attchment Snapshot inside <ImmunizationCard /> once we need to display multiple vaccination such as COVID-19 and Influenza
  useEffect(() => {
    if (immunizations.tag !== 'Complete' || !diseaseInPopup) {
      setAttachmentToView(undefined)
      return
    }

    const mostRecentAttachmentId = getMostRecentAttachmentId(
      getImmunizationsByDiseaseName({
        diseaseName: diseaseInPopup,
        immunizations: immunizations.value,
      })
    )
    if (mostRecentAttachmentId) {
      fetchSnapshot({
        personId: person.id!,
        orgId: person.orgId!,
        snapshotId: mostRecentAttachmentId,
        dataType: DataType.DATA_TYPE_IMMUNIZATION_RECORD,
      })
        .then((snapshot) => {
          setAttachmentToView({ tag: 'Complete', value: snapshot })
        })
        .catch(() => {
          setAttachmentToView({ tag: 'Complete', value: undefined })
        })
    } else {
      setAttachmentToView({ tag: 'Complete', value: undefined })
    }
  }, [diseaseInPopup])

  return (
    <PlainDetailsCard
      titleUnderline={false}
      icon="fas fa-syringe"
      title="Immunizations"
    >
      <section className={tw`text-[14px] leading-[20px]`}>
        <ImmunizationList
          immunizations={
            immunizations.tag === 'Complete' ? immunizations.value : []
          }
          person={person}
          onClick={(disease: string) => setDiseaseInPopup(disease)}
        />
      </section>
      {diseaseInPopup && (
        <VaccineViewer
          diseaseName={diseaseInPopup}
          immunizations={
            immunizations.tag === 'Complete'
              ? getImmunizationsByDiseaseName({
                  diseaseName: diseaseInPopup,
                  immunizations: immunizations.value,
                })
              : []
          }
          onClose={onClose}
          onUpdate={onUpdate}
          person={person}
          document={
            attachmentToView?.tag === 'Complete'
              ? attachmentToView.value
              : undefined
          }
        />
      )}
    </PlainDetailsCard>
  )
}
