import { escapeRegExp, shuffle } from 'lodash'
import { generate } from 'random-words'
import { UserFormData } from '@shared/components/Auth/LoginWithUsernameOrEmail/PasswordField/types'
import {
  allowedSpecialCharacters,
  PasswordPolicyRequirement,
  PasswordPolicyRequirementLabel,
} from '@shared/constants/passwordPolicy'

export function validate({
  requirements,
  key,
  values,
}: {
  requirements: PasswordPolicyRequirement
  key: PasswordPolicyRequirementLabel
  values?: UserFormData
}) {
  if (!requirements[key]) return true

  const { password } = values ?? {}

  if (!password) return true

  const validators = {
    minLength: validateMinLength({
      value: password,
      minLength: requirements.minLength,
    }),
    noKnownStrings: validateHasNoKnownStrings(values ?? {}),
    requiresOneLowercase: validateHasLowerCase(password),
    requiresOneUppercase: validateHasUpperCase(password),
    requiresOneNumber: validateHasNumber(password),
    requiresOneSpecialChar: validateHasSpecialChar(password),
  }

  return validators[key] || getErrorCopy({ requirements, key })
}

function validateMinLength({
  value,
  minLength,
}: {
  value: string
  minLength: number
}) {
  return value.length >= minLength
}

function validateHasNoKnownStrings(values: UserFormData | undefined) {
  if (!values) return true

  const { preferredUsername, email, name, password } = values

  if (!password) return true

  const lastName = name?.family
  const firstNames = name?.given

  if (
    [preferredUsername, email, lastName, ...(firstNames ?? [])].every(
      (attr) => {
        return attr?.toLowerCase() !== password.toLowerCase()
      }
    )
  ) {
    return true
  }

  return false
}

function validateHasNumber(value: string) {
  const regex = new RegExp('[0-9]')
  return !!value.match(regex)
}

function validateHasLowerCase(value: string) {
  const regex = new RegExp('[a-z]')
  return !!value.match(regex)
}

function validateHasUpperCase(value: string) {
  const regex = new RegExp('[A-Z]')
  return !!value.match(regex)
}

function validateHasSpecialChar(value: string) {
  const escapedString = escapeRegExp(allowedSpecialCharacters)
  const regexPattern = new RegExp(`[${escapedString}]`)
  return regexPattern.test(value)
}

export function getErrorCopy({
  requirements,
  key,
}: {
  requirements: PasswordPolicyRequirement
  key: PasswordPolicyRequirementLabel
}) {
  const titles: { [key in keyof PasswordPolicyRequirement]: string } = {
    minLength: `Minimum of ${requirements.minLength} characters`,
    requiresOneLowercase: 'Lowercase letter',
    requiresOneUppercase: 'Uppercase letter',
    requiresOneNumber: 'Number',
    requiresOneSpecialChar: `Special character: ^$*.[]{}()?"!@#%&/\\,><':;|_~\`=+-`,
    noKnownStrings:
      'Cannot be your username, phone number, email, first or last name',
  }

  return titles[key]
}

/**
 * Generates a memorable password with the following pattern:
 * - at least 8 chars
 * - one uppercase char
 * - one number
 * - one special char
 */
export function generatePassword() {
  const words = generate({
    exactly: 2,
    minLength: 4,
    maxLength: 8,
  })
  const [wordOne, wordTwo] = shuffle(words)
  const upcasedWords = [
    wordOne.slice(0, 1).toUpperCase().concat(wordOne.slice(1)),
    wordTwo,
  ]

  const randomNumber = Math.floor(Math.random() * 100).toString()
  const randomChar = ['.', '$', '%', '&', '*', '?', '+', '='][
    Math.floor(Math.random() * 8)
  ]

  const [charOne, charTwo] = shuffle([randomNumber, randomChar])

  const result = upcasedWords.join(charOne) + charTwo

  return result
}

export async function copyToClipboard(text: string) {
  await navigator.clipboard.writeText(text)
}
