import { BlockedEmail } from '@augusthealth/models/com/august/protos/api/blocked_email'
import {
  Group,
  GroupType,
} from '@augusthealth/models/com/august/protos/permission'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import Badge from '@shared/components/Badge'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import Card from '@shared/components/Card'
import GlobalContext from '@shared/contexts/GlobalContext'
import { UserAccount } from '@shared/types/user'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { getGroupLabelAndType } from '@shared/utils/permisson'
import {
  checkBlocklist,
  getUserByEmail,
  removeFromBlocklist,
} from '@app/api/users'
import Content from '@app/components/generic/Content'

export default function EmailInfo() {
  const [user, setUser] = useState<UserAccount>()
  const [blockResult, setBlockResult] = useState<BlockedEmail>()
  const [isDeleting, setIsDeleting] = useState<'Yes' | 'No' | 'Complete'>('No')
  const [accountNotFound, setAccountNotFound] = useState<boolean>(false)
  const { setError } = useContext(GlobalContext)

  const { handleSubmit, formState, register, reset } = useForm<{
    emailAddress: string
  }>({ mode: 'onChange' })

  const onSubmit = async (data: { emailAddress: string }) => {
    setUser(undefined)
    setBlockResult(undefined)
    setIsDeleting('No')

    checkBlocklist(data.emailAddress)
      .then(setBlockResult)
      .then(() => getUserByEmail(data.emailAddress))
      .then(setUser)
      .catch((e) => {
        if (e.status === 404) {
          setAccountNotFound(true)
        } else {
          setError(e)
        }
      })
      .finally(reset)
  }

  return (
    <Content title={'Email Information'} className={'mt-[32px]'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'flex items-center gap-5'}>
          <BasicInput
            {...register('emailAddress', {
              required: true,
              minLength: 1,
            })}
            placeholder={'email@example.com'}
            className={'min-w-[348px]'}
          />
          <AsyncIconButton
            buttonStyle={'primary-fill'}
            type={'submit'}
            disabled={!formState.isValid || formState.isSubmitting}
            isLoading={formState.isSubmitting}
          >
            Lookup Email
          </AsyncIconButton>
        </div>
      </form>
      <div className={'mt-[32px] flex flex-wrap'}>
        {blockResult && (
          <Card className={'mb-[16px] ml-[8px] mr-[8px] flex grow flex-col'}>
            <h2
              className={'mb-[24px] border-b pb-[12px] text-[20px] font-medium'}
            >
              {blockResult.email}
            </h2>
            <div className={'flex flex-col'}>
              <div className={'flex items-center'}>
                {blockResult.blocked && (
                  <Badge
                    badgeSize={'large'}
                    color={'darkOrange'}
                    shape={'square'}
                  >
                    Blocked
                  </Badge>
                )}
                {accountNotFound && (
                  <>
                    <Badge
                      shape={'square'}
                      badgeSize={'large'}
                      color={'darkOrange'}
                      className={'mr-[16px]'}
                    >
                      No Account Found
                    </Badge>
                    <br />
                  </>
                )}
                {!blockResult.blocked && (
                  <Badge shape={'square'} badgeSize={'large'} color={'green'}>
                    Not Blocked
                  </Badge>
                )}
                {blockResult.blocked && (
                  <AsyncIconButton
                    buttonStyle={'secondary-fill'}
                    className={'ml-[16px]'}
                    disabled={isDeleting === 'Yes'}
                    isLoading={isDeleting === 'Yes'}
                    onClick={async () => {
                      setIsDeleting('Yes')
                      removeFromBlocklist(blockResult.email!)
                        .then(() => checkBlocklist(blockResult.email!))
                        .then(setBlockResult)
                        .catch(setError)
                        .finally(() => setIsDeleting('Complete'))
                    }}
                  >
                    Remove from Blocklist
                  </AsyncIconButton>
                )}
              </div>
              {isDeleting === 'Complete' && (
                <div className={'mt-[8px]'}>
                  Delete request sent successfully.
                </div>
              )}
            </div>
          </Card>
        )}
        {user && (
          <Card
            className={
              'mb-[16px] ml-[8px] mr-[8px] grow flex-col text-[16px] leading-[24px]'
            }
          >
            <h2
              className={'mb-[24px] border-b pb-[12px] text-[20px] font-medium'}
            >
              August Account Details
            </h2>
            <div>
              <span>{getFirstAndLastName(user.name)}</span>
            </div>
            <div>
              <ul className={'mb-0 mt-0 list-none p-0'}>
                {roleInfo(user.groups ?? []).map((info, ix) => {
                  return (
                    <li key={`info-${ix}`}>
                      {info.roleName} {info.roleIDs && `(${info.roleIDs})`}
                    </li>
                  )
                })}
              </ul>
            </div>
          </Card>
        )}
      </div>
    </Content>
  )
}

function roleInfo(groups: Group[]) {
  return groups.reduce((accum, el) => {
    const roleName = nameForGroupType(el.groupType!)
    const { personId, facilityId, organizationId } = el.personMatcher ?? {}
    let roleIDs = ''
    if (organizationId) {
      roleIDs += `Org: ${organizationId}`
    }
    if (facilityId) {
      roleIDs += `, Facility: ${facilityId}`
    }
    if (personId) {
      roleIDs += `, Person: ${personId}`
    }

    return [...accum, { roleName, roleIDs }]
  }, [])
}

function nameForGroupType(groupType: GroupType) {
  return getGroupLabelAndType(groupType).label
}
