import { useContext, useState } from 'react'
import { fetchPerson } from '@shared/api/person'
import { useUserContext } from '@shared/contexts/UserContext'
import { SnapshotStatus } from '@shared/types/snapshot'
import PersonPageTitle from '@app/components/PersonPageTitle'
import { Props } from '@app/components/Prospects/Tasks/type'
import { SnapshotSortOrder } from '@app/components/SortByDropdown/helpers'
import { DocumentsContextProvider } from '@app/contexts/DocumentsContext'
import FacilitiesContext from '@app/contexts/FacilitiesContext'
import PersonContext from '@app/contexts/PersonContext'
import useImmunizations from '@app/hooks/useImmunizations'
import useSnapshots from '@app/hooks/useSnapshots'
import { useTasks } from '@app/hooks/useTasks'
import List from './List'
import SearchAndSortByControls from './SearchAndSortByControls'
import useConfiguredTasks from './useConfiguredTasks'

export default function Documents(props: Props) {
  const { match } = props
  const { facilityId, orgId, id } = match.params
  const { tasks, refreshTasks } = useTasks()
  const { person, setPerson } = useContext(PersonContext)
  const { facilities = [] } = useContext(FacilitiesContext)
  const currentFacility = facilities.find((f) => f.id === facilityId)
  const { user } = useUserContext()
  const [documentFilter, setDocumentFilter] = useState('')
  const [snapshotSortOrder, setSnapshotSortOrder] = useState<SnapshotSortOrder>(
    SnapshotSortOrder.SNAPSHOT_SORT_BY_LAST_UPDATE_DESC
  )

  const configuredTemplates = useConfiguredTasks(
    person ?? { facilityId, orgId, id }
  )
  const { snapshots, refreshSnapshots } = useSnapshots({
    status: SnapshotStatus.SNAPSHOT_STATUS_COMPLETE,
  })
  const { immunizations, reloadImmunizations } = useImmunizations()

  if (person === undefined || currentFacility === undefined) {
    return null
  }

  const updatePerson = async () => {
    if (person) {
      void fetchPerson({
        orgId: person.orgId!,
        facilityId: person.facilityId!,
        personId: person.id!,
      }).then(setPerson)
    }
  }

  return (
    <DocumentsContextProvider
      value={{
        person,
        tasks,
        refreshTasks,
        snapshots,
        refreshSnapshots,
        immunizations,
        refreshImmunizations: reloadImmunizations,
        user,
        configuredTemplates,
        refreshPerson: updatePerson,
        currentFacility,
        documentFilter,
        setDocumentFilter,
        snapshotSortOrder,
        setSnapshotSortOrder,
      }}
    >
      <PersonPageTitle title={'Documents'} withBorder={false}>
        <SearchAndSortByControls />
      </PersonPageTitle>
      <List match={match} />
    </DocumentsContextProvider>
  )
}
