export type ConfigurationType =
  | 'Buttons'
  | 'CustomContactDropdown'
  | 'CustomContactDropdownList'
  | 'HashTextarea'
  | 'HashTextInput'
  | 'StyledSelect'
  | 'YesNoButtons'
  | 'CheckboxGroup'
  | 'StaticText'
  | 'FormImage'
  | 'SharedRadioButtonGroup'

// snake_case LegacyConfigurationType was used before, see CONFIGURATION_TYPE_MAP below for mapping
type LegacyConfigurationType =
  | 'buttons'
  | 'custom_contact_dropdown'
  | 'custom_contact_dropdown_list'
  | 'hash_text'
  | 'hash_textarea'
  | 'boolean_buttons'

type ConfigurationTypeMap = Record<
  | 'Buttons'
  | 'CustomContactDropdown'
  | 'CustomContactDropdownList'
  | 'HashTextInput'
  | 'HashTextarea'
  | 'YesNoButtons',
  LegacyConfigurationType
>

const CONFIGURATION_TYPE_MAP: ConfigurationTypeMap = {
  Buttons: 'buttons',
  CustomContactDropdown: 'custom_contact_dropdown',
  CustomContactDropdownList: 'custom_contact_dropdown_list',
  HashTextInput: 'hash_text',
  HashTextarea: 'hash_textarea',
  YesNoButtons: 'boolean_buttons',
}

export type FormOptions =
  | 'options'
  | 'questions'
  | 'requiredCheckbox'
  | 'requiredContactFields'
  | 'variableName'
  | 'formImageDropdown'

const FORM_FIELD_CONFIGURATION: Record<ConfigurationType, FormOptions[]> = {
  Buttons: ['options', 'requiredCheckbox'],
  CustomContactDropdown: [
    'requiredContactFields',
    'variableName',
    'requiredCheckbox',
  ],
  CustomContactDropdownList: ['variableName', 'requiredCheckbox'],
  HashTextarea: ['requiredCheckbox'],
  HashTextInput: ['requiredCheckbox'],
  StyledSelect: ['options', 'requiredCheckbox'],
  YesNoButtons: ['requiredCheckbox'],
  CheckboxGroup: ['options'],
  StaticText: [],
  FormImage: ['formImageDropdown'],
  SharedRadioButtonGroup: ['options', 'questions', 'requiredCheckbox'],
}

function getKeyByValue(value: string) {
  return (
    Object.entries(CONFIGURATION_TYPE_MAP).find(
      ([_, val]) => val === value
    )?.[0] || ''
  )
}

export function showUiField({
  type,
  uiFieldToCheck,
}: {
  type: ConfigurationType | LegacyConfigurationType
  uiFieldToCheck: FormOptions
}) {
  const list =
    FORM_FIELD_CONFIGURATION[type] ||
    FORM_FIELD_CONFIGURATION[getKeyByValue(type)]
  if (Array.isArray(list)) {
    return list.includes(uiFieldToCheck)
  }

  return false
}

const PREFIX_PATTERN = 'contactLabels\\.'
const SUFFIX_PATTERN = '\\.ids((\\[0\\])?)'
const VARIABLE_NAME_PATTERN = '((\\w|\\d|-|_)+)'

const VARIABLE_NAME_REGEXP = new RegExp(`^${VARIABLE_NAME_PATTERN}$`)
const VARIABLE_NAME_FROM_CONTACT_DROPDOWN_REGEXP = new RegExp(
  `^${PREFIX_PATTERN}(.*)${SUFFIX_PATTERN}$`
) // Get any value between PRE and POSTFIX without validation, UI will validate it.
const CONTACT_DROPDOWN_NAME_REGEXP = new RegExp(
  `^${PREFIX_PATTERN}${VARIABLE_NAME_PATTERN}\\.ids\\[0\\]$`
)
const CONTACT_DROPDOWN_LIST_NAME_REGEXP = new RegExp(
  `^${PREFIX_PATTERN}${VARIABLE_NAME_PATTERN}\\.ids$`
)

export function buildContactDropdownName({
  type,
  variableName,
}: {
  type: ConfigurationType
  variableName: string
}) {
  const prefix = PREFIX_PATTERN.replace('\\', '')
  if (type === 'CustomContactDropdown') {
    return `${prefix}${variableName}.ids[0]`
  }

  if (type === 'CustomContactDropdownList') {
    return `${prefix}${variableName}.ids`
  }

  return variableName
}

/** Allow empty variable name */
export function isValidVariableName(variableName: string) {
  return !variableName || VARIABLE_NAME_REGEXP.test(variableName)
}

export function getContactDropdownVariableName({
  name = '',
  type,
}: {
  name: string
  type: ConfigurationType
}) {
  let variableName = name
  if (
    type === 'CustomContactDropdown' ||
    type === 'CustomContactDropdownList'
  ) {
    variableName = name.replace(
      VARIABLE_NAME_FROM_CONTACT_DROPDOWN_REGEXP,
      '$1'
    )
  }

  return name === variableName ? '' : variableName
}

function getPatternOption(regexPattern: RegExp) {
  return {
    pattern: {
      value: regexPattern,
      message: 'Invalid format, please use Variable Name to generate Name',
    },
  }
}

export function getNameRegisterOptions(type: ConfigurationType) {
  if (type === 'CustomContactDropdown') {
    return getPatternOption(CONTACT_DROPDOWN_NAME_REGEXP)
  } else if (type === 'CustomContactDropdownList') {
    return getPatternOption(CONTACT_DROPDOWN_LIST_NAME_REGEXP)
  }

  return {}
}

function getTypeKey(t: ConfigurationType | LegacyConfigurationType) {
  return CONFIGURATION_TYPE_MAP[t] || t
}

export function areSameTypes(
  t1: ConfigurationType | LegacyConfigurationType,
  t2: ConfigurationType | LegacyConfigurationType
) {
  return getTypeKey(t1) === getTypeKey(t2)
}

export type ConfigurationOption = {
  label: string
  subLabel?: string
  value: ConfigurationType
  isDisabled?: boolean
}

export const CONFIGURATION_OPTIONS: ConfigurationOption[] = [
  {
    label: 'Text',
    subLabel: 'One line text field',
    value: 'HashTextInput',
  },
  {
    label: 'Textarea',
    subLabel: 'Multi-line text field',
    value: 'HashTextarea',
  },
  {
    label: 'Yes or No Buttons',
    subLabel: 'Buttons support Yes and No options',
    value: 'YesNoButtons',
  },
  {
    label: 'Buttons',
    subLabel: 'Buttons with customized options',
    value: 'Buttons',
  },
  {
    label: 'Select',
    subLabel: 'Single value select dropdown',
    value: 'StyledSelect',
  },
  {
    label: 'Contact Dropdown',
    subLabel: 'Select one contact',
    value: 'CustomContactDropdown',
  },
  {
    label: 'Contact Dropdown List',
    subLabel: 'Select multiple contacts',
    value: 'CustomContactDropdownList',
  },
  {
    label: 'Checkbox Group',
    subLabel: 'A group of related checkboxes, all under one heading',
    value: 'CheckboxGroup',
  },
  {
    label: 'Static Text',
    subLabel: 'Display static text',
    value: 'StaticText',
  },
  {
    label: 'Form Image',
    subLabel: 'Display facility image',
    value: 'FormImage',
  },
  {
    label: 'Shared Radio Group',
    subLabel: 'A group of related radio buttons, all with the same options',
    value: 'SharedRadioButtonGroup',
  },
]

export const CONTACT_FIELD_OPTIONS = [
  { label: 'Phone', value: 'phone' },
  { label: 'Email', value: 'email' },
  { label: 'Address', value: 'address' },
]
