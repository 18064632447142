import { SummarySectionList } from '@shared/components/LinkCard/SummarySection'
import { RoutineAdministration } from '@shared/types/careapp'
import { getProgressType } from '@shared/utils/routineAdministration'
import { getIconNameAndClassByStatus } from '@shared/utils/routineAdministrationProgressType'
import { RoutineAdministrationListItem } from '../RoutineAdministrationsStatusSummary'

function Content({
  routineAdministration,
  facilityTimeZone,
}: {
  routineAdministration: RoutineAdministration
  facilityTimeZone: string
}) {
  const progressType = getProgressType(routineAdministration, facilityTimeZone)
  const { bgColor } = getIconNameAndClassByStatus(progressType)
  return (
    <SummarySectionList title="" bgColor={bgColor}>
      <RoutineAdministrationListItem
        administration={routineAdministration}
        progressType={progressType}
        facilityTimeZone={facilityTimeZone}
        itemsCount={1}
        idx={0}
        innerVerticalPaddingClassName="py-4"
      />
    </SummarySectionList>
  )
}

export default function RoutineAdministrationBanner({
  facilityTimeZone,
  routineAdministration,
}: {
  facilityTimeZone: string
  routineAdministration: RoutineAdministration
}) {
  return (
    <Content
      routineAdministration={routineAdministration}
      facilityTimeZone={facilityTimeZone}
    />
  )
}
