import {
  Report,
  ReportAssignment,
} from '@augusthealth/models/com/august/protos/report'
import { ReactNode } from 'react'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import './style.css'
import FacilityTd from './FacilityAssignmentTd'
import THead from './GlobalAssignmentsTHead'
import OrgTh from './OrgAssignmentTh'

export default function ReportTable({
  facilities: allFacilities,
  organizations: orgs,
  reports,
  assignments,
  reload,
}: {
  facilities: Facility[]
  organizations: Organization[]
  reports: Report[]
  assignments: ReportAssignment[]
  reload: () => void
}) {
  const orgTBodies = orgs.map(({ id: orgId, name: oName }) => {
    const orgTitle = `${orgId}. ${oName}`
    const facilities = allFacilities.filter((f) => f.orgId === orgId)
    const trs = facilities.map((f) => {
      const { id: facilityId, name: fName } = f
      const trKey = `${orgId}-${facilityId}-${fName}`
      const fTitle = `${facilityId}. ${fName}`
      const tds = reports.reduce((list: ReactNode[], r) => {
        if (orgId && facilityId) {
          const { id: reportId, description } = r
          const tdKey = `${reportId}-${description}-td`
          list.push(
            <FacilityTd
              key={tdKey}
              facility={f}
              assignments={assignments}
              report={r}
              reload={reload}
            />
          )
        } else {
          list.push(<td />)
        }
        return list
      }, [])

      return (
        <tr key={trKey}>
          <td className="report-sub-title">{fTitle}</td>
          {tds}
        </tr>
      )
    })

    return (
      <tbody key={`tbody-${orgId}`}>
        <tr>
          <th>{orgTitle}</th>
          {reports.reduce((list: ReactNode[], r) => {
            const thKey = `org-th-${orgId}-${r.id}`
            if (orgId) {
              list.push(
                <OrgTh
                  key={thKey}
                  orgId={orgId}
                  report={r}
                  assignments={assignments}
                  reload={reload}
                />
              )
            } else {
              list.push(<th key={thKey} />)
            }
            return list
          }, [])}
        </tr>
        {trs}
      </tbody>
    )
  })

  return (
    <table className="report-assignment-table table">
      <THead reports={reports} assignments={assignments} reload={reload} />
      {orgTBodies}
    </table>
  )
}
