import { Immunization } from '@augusthealth/models/com/august/protos/immunization'
import { SetRequired } from 'type-fest'
import { request, requestJson } from '@shared/api/request'
import {
  attachImmunizationUrl,
  attachPartialImmunizationUrl,
  createImmunizationUrl,
  getImmunizationsUrl,
  getSingleImmunizationUrl,
} from '@shared/legacy_routes'
import { Person, PersonIds } from '@shared/types/person'
import { Snapshot, Snapshot_Data } from '@shared/types/snapshot'
import { dateMessageComparator } from '@shared/utils/date'

export const getSortedImmunizations = async (
  person: Required<PersonIds>
): Promise<Immunization[]> => {
  const url = getImmunizationsUrl(person)
  const response = await requestJson({ url, method: 'GET' })

  return response.data.sort((a: Immunization, b: Immunization) =>
    dateMessageComparator(a.occurrenceDate!, b.occurrenceDate!)
  )
}

export const createImmunization = async (
  person: Required<PersonIds>,
  immunization: Immunization
): Promise<{ id: number }> => {
  const url = createImmunizationUrl(person)
  const responseJson = await requestJson({
    url,
    method: 'POST',
    body: JSON.stringify(immunization),
  })

  return responseJson.data
}

export const deleteImmunization = async (
  person: Required<PersonIds>,
  immunization: Immunization
): Promise<{ id: number }> => {
  const url = getSingleImmunizationUrl(person, immunization)
  const responseJson = await requestJson({
    url,
    method: 'DELETE',
    body: JSON.stringify(immunization),
  })

  return responseJson.data
}

export const updateImmunization = async (
  person: Person,
  immunization: Immunization
): Promise<Immunization> => {
  const url = getSingleImmunizationUrl(
    person as SetRequired<Person, 'id' | 'orgId' | 'facilityId'>,
    immunization
  )
  const responseJson = await request({
    url,
    method: 'PATCH',
    contentType: 'application/merge-patch+json',
    body: JSON.stringify(immunization),
  })

  return responseJson.data
}

export const removeSnapshotIdFromImmunization = async (
  person: Person,
  immunization: Immunization
): Promise<Immunization> => {
  return updateImmunization(person, {
    ...immunization,
    attachmentSnapshotId: null,
  } as Snapshot) // use null to remove attachmentSnapshotId)
}

export const attachImmunization = async (
  files: File[],
  immunizationId: string,
  person: Person
): Promise<Immunization> => {
  const formData = new FormData()
  const snapshotData: Snapshot_Data = {}

  formData.append('data', JSON.stringify(snapshotData))
  files.forEach((file) => {
    formData.append('file[]', file)
  })

  const url = attachImmunizationUrl(
    person as SetRequired<Person, 'id' | 'orgId' | 'facilityId'>,
    immunizationId
  )

  const response = await request({ url, method: 'POST', body: formData })
  return response.data
}

export const attachImmunizationWithPartialUpload = async ({
  file,
  selectedPages,
  immunizationId,
  person,
}: {
  file: File
  selectedPages: number[]
  immunizationId: string
  person: Person
}) => {
  const formData = new FormData()
  const snapshotData: Snapshot_Data = {}

  formData.append(
    'data',
    JSON.stringify({
      pages: selectedPages,
      data: {
        ...snapshotData,
      },
    })
  )
  formData.append('file[]', file)

  const url = attachPartialImmunizationUrl(
    person as SetRequired<Person, 'id' | 'orgId' | 'facilityId'>,
    immunizationId
  )
  return await request({ url, method: 'POST', body: formData })
}
