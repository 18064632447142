import { useContext } from 'react'
import { listFacilities } from '@shared/api/facility'
import { mergePatchPerson } from '@shared/api/person'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person, ResidentStatus } from '@shared/types/person'
import ConfirmModal from '@app/components/ConfirmModal'
import FacilitiesContext from '@app/contexts/FacilitiesContext'

interface Props {
  person: Person
  onYes: () => void
  onNotNow: () => void
}

type CloseProspectPatch = Pick<Person, 'residentStatus'>
const patch: CloseProspectPatch = {
  residentStatus: ResidentStatus.RESIDENT_STATUS_CLOSED,
}

function updatePerson(person: Person) {
  return mergePatchPerson({
    fId: person.facilityId,
    pId: person.id,
    orgId: person.orgId,
    patch,
    fields: ['residentStatus'],
    manualETag: '*',
  })
}

export default function CloseProspect({ onYes, onNotNow, person }: Props) {
  const { setFacilities } = useContext(FacilitiesContext)
  const { setError } = useContext(GlobalContext)

  return (
    <ConfirmModal
      title="Do you want to close this move-in?"
      confirmButtonConfig={{
        children: 'Yes, Close',
        onClick: async () => {
          try {
            await updatePerson(person)
            onYes()
            const facilities = await listFacilities(person.orgId!)
            setFacilities(facilities)
          } catch (e) {
            setError(e)
          }
        },
      }}
      denyButtonConfig={{
        onClick: onNotNow,
      }}
    />
  )
}
