import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { LinkCard } from '@shared/components/LinkCard'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import { admissionTasksPathForPerson } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { isResponsiblePerson } from '@shared/utils/user'
import { getAdmissionTasks } from '@app/components/Prospects/Tasks/helpers'
import { useDocumentsContext } from '@app/contexts/DocumentsContext'
import styles from './styles.module.css'
import { NoteCardSkeleton } from '../Notes/NoteCardSkeleton'
import { DocumentCards } from './DocumentCards'
import NewDocumentSidebar from './NewDocumentSidebar'

interface Props {
  match: {
    params: {
      facilityId: string
      orgId: string
      id: string
      documentId?: string
    }
  }
}

export default function DocumentGroups(props: Props) {
  const { documentId } = props.match.params
  const {
    user,
    person,
    tasks,
    snapshots: allSnapshots,
    configuredTemplates,
    immunizations,
    refreshSnapshots,
    refreshImmunizations,
    refreshPerson,
    refreshTasks,
  } = useDocumentsContext()

  const reloadData = async () => {
    await refreshPerson()
    await refreshSnapshots()
    await refreshImmunizations()
    await refreshTasks()
  }

  if (
    allSnapshots.tag === 'Loading' ||
    configuredTemplates.tag === 'Loading' ||
    immunizations.tag === 'Loading'
  ) {
    return (
      <div className={styles.container}>
        <div className={styles.cards}>
          <NoteCardSkeleton />
        </div>
      </div>
    )
  }

  const { orgId, facilityId, id: personId } = person
  const outstandingTaskCount = getAdmissionTasks(
    tasks,
    isResponsiblePerson({ user, orgId, facilityId, personId }),
    user
  ).length

  const canCreateNewDocuments = hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_SNAPSHOT_CREATE],
  })

  return (
    <div className={styles.container}>
      <div className={styles.cards}>
        {outstandingTaskCount > 0 && canCreateNewDocuments && (
          <LinkCard
            className={styles.tasksLink}
            href={admissionTasksPathForPerson(person as Required<Person>)}
          >
            <div className="flex items-center">
              <i className="fa-solid fa-brake-warning mr-[8px] text-tags-blue" />
              <div className="text-[14px] font-medium leading-[16px] text-gray-07">
                You still have {outstandingTaskCount} incomplete documents
                in&nbsp;
                <span className="text-gray-01">Tasks</span>
              </div>
            </div>
            <i className={'far fa-angle-right ml-[4px] text-[14px]'} />
          </LinkCard>
        )}
        <DocumentCards
          reloadData={reloadData}
          selectedDocumentId={documentId}
          user={user}
        />
      </div>
      {canCreateNewDocuments && (
        <NewDocumentSidebar
          onUploadComplete={(updated) => {
            if (updated) {
              return reloadData()
            }

            return Promise.resolve()
          }}
        />
      )}
    </div>
  )
}
