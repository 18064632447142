import { Contact } from '@augusthealth/models/com/august/protos/contact'
import { useState } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import { useUserContext } from '@shared/contexts/UserContext'
import { Person } from '@shared/types/person'
import { isResponsiblePerson as isContactRP } from '@shared/utils/contact'
import { getFullName } from '@shared/utils/humanName'
import { isResponsiblePerson } from '@shared/utils/user'
import { RpEmailWarning } from '@app/components/Warning'
import styles from './styles.module.css'
import HashTextInput from '../HashTextInput'
import { InputType } from '../TextInput/TextInputInGenerator'

type ContactData = {
  contact: Contact
}

type Props = {
  title: string
  name: string
  data: ContactData
  value?: string
  onUpdate: (value: string, name: string) => void
  person: Person
}
/**
 * This UI component doesn't seem to be used anymore once convert Add/Edit Contact popup with react-hook-form
 */
export default function ContactEmail(props: Props) {
  const { data, name, title, person, value = '', onUpdate } = props
  const { user } = useUserContext()
  const { orgId, facilityId, id: personId } = person
  const isRP = isResponsiblePerson({ user, orgId, facilityId, personId })

  // If the person is an RP, display one type of element, if not, just a regular text box
  const [originalEmail] = useState(value) // Allow to switch back to original value
  const [email, setEmail] = useState(originalEmail)
  const [fieldLocked, setFieldLocked] = useState(true)

  function toggleFieldLock() {
    if (!fieldLocked) {
      // If the field is currently unlocked and is being canceled/re-locked, set the email value back to original email
      setEmail(originalEmail)
      onUpdate(originalEmail, name)
    }
    setFieldLocked(!fieldLocked)
  }

  if (isContactRP(data.contact)) {
    return (
      <div className="form-group">
        <div
          className="form-control-label horizontal mb-[13px]"
          style={{ justifyContent: 'space-between' }}
        >
          <label className="form-element-title">{title}</label>
          <ButtonLink
            disabled={isRP}
            onClick={toggleFieldLock}
            className={styles.lockToggleText}
          >
            {fieldLocked ? 'Unlock to Edit' : 'Cancel Edit'}
          </ButtonLink>
        </div>
        {fieldLocked && (
          <div className={`${styles.lockedFieldWrapper} read-only-holder`}>
            <div>
              <i className="fas fa-lock mr-[12px]" /> {originalEmail}
            </div>
          </div>
        )}
        {!fieldLocked && (
          <>
            <HashTextInput
              name={name}
              title={title}
              onUpdate={onUpdate}
              type={InputType.TEXT_INPUT_TYPE_EMAIL}
              childrenOnly
              value={email}
            />
            {
              <RpEmailWarning
                className="mt-[12px]"
                name={data.contact?.name && getFullName(data.contact.name)}
              />
            }
          </>
        )}
      </div>
    )
  }

  return (
    <HashTextInput
      name={name}
      title={title}
      onUpdate={onUpdate}
      type={InputType.TEXT_INPUT_TYPE_EMAIL}
      value={originalEmail}
    />
  )
}
