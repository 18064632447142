import { DateMessage } from '@augusthealth/models/com/august/protos/date'
import { Controller, useFormContext } from 'react-hook-form'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import StyledSelect from '@shared/components/Selects/StyledSelect'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { getOrElse } from '@shared/utils/loading'
import DateOfBirth from '@app/components/DateOfBirth'
import { isCompleteAndValidBirthday } from '@app/components/DateOfBirth/utils'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import styles from '../styles.module.css'
import { genderOptions } from '../helpers'
import SamePeopleWarning from './SamePeopleWarning'

export default function ResidentStep({
  allowEmptyDob,
  onDobUpdate,
}: {
  allowEmptyDob: boolean
  onDobUpdate: (dob?: DateMessage) => void
}) {
  const {
    control,
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const { prospects, residents, movedOutResidents, closedProspects } =
    useCurrentFacility()
  const people = [
    ...getOrElse(prospects, []),
    ...getOrElse(residents, []),
    ...movedOutResidents,
    ...closedProspects,
  ]

  const firstName = watch('firstName')
  const lastName = watch('lastName')
  const sameNamePeople = people
    .filter((p) => {
      const fullName = getFirstAndLastName(p.person?.name)
      return fullName.toLowerCase() === `${firstName} ${lastName}`.toLowerCase()
    })
    .map((p) => p.person)

  return (
    <>
      <div className={styles.inputRow}>
        <div className={styles.formInput}>
          <LabelAboveInput
            htmlFor="firstName"
            subLabel={requiredLabel(!!errors.firstName)}
          >
            First Name
          </LabelAboveInput>
          <BasicInput
            data-cy="add-prospect-first-name"
            data-testid="add-prospect-first-name"
            {...register('firstName', {
              required: true,
              validate: (value: string) => !!value.trim(),
            })}
            placeholder="First Name"
          />
        </div>
        <div className={styles.formInput}>
          <LabelAboveInput
            htmlFor="lastName"
            subLabel={requiredLabel(!!errors.lastName)}
          >
            Last Name
          </LabelAboveInput>
          <BasicInput
            data-cy="add-prospect-last-name"
            data-testid="add-prospect-last-name"
            {...register('lastName', {
              required: true,
              validate: (value: string) => !!value.trim(),
            })}
            placeholder="Last Name"
          />
        </div>
      </div>
      {sameNamePeople.length > 0 && (
        <SamePeopleWarning people={sameNamePeople} />
      )}
      <Controller
        control={control}
        name="gender"
        rules={{ required: false }}
        render={({ field: { onChange, value } }) => {
          return (
            <div className={styles.gender}>
              <LabelAboveInput htmlFor="gender">Gender</LabelAboveInput>
              <StyledSelect
                name="gender"
                inputId={'gender'}
                value={value}
                onChange={onChange}
                options={genderOptions}
                placeholder="Select..."
              />
            </div>
          )
        }}
      />
      <Controller
        control={control}
        name="dob"
        rules={{
          required: false,
          validate: isCompleteAndValidBirthday,
        }}
        render={({ field: { value } }) => (
          <div className={styles.dob}>
            <LabelAboveInput htmlFor="dob">Date of Birth</LabelAboveInput>
            <DateOfBirth
              allowEmpty={allowEmptyDob}
              value={value}
              onUpdate={onDobUpdate}
              menuPlacement="top"
              acceptDateObject
              sendUpdateOnError
              updateOnEachKeyUp
            />
          </div>
        )}
      />
    </>
  )
}
