import { useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import ButtonLink from '@shared/components/ButtonLink'
import { BadgeSelect } from '@shared/components/Selects/BadgeSelect'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import {
  NEW_PARAMETER,
  ValidParameterConditional,
  ValidParameterType,
} from '@shared/types/dosage'
import { MedOrderFormData } from '@shared/types/medication_order'
import {
  getParameterConditionalOptions,
  getParameterTypeOptions,
  parameterTypeToUnit,
  parameterTypeToValidation,
} from '@shared/utils/dosageParameters'
import { tw, twx } from '@shared/utils/tailwind'
import { Switch, SwitchSize } from '@app/components/Switch'

type Props = {
  doseIndex: number
}
export const ConditionalParameters = ({ doseIndex }: Props) => {
  const { register, setValue, control, formState, unregister, watch } =
    useFormContext<MedOrderFormData>()

  const parametersName = `doses.${doseIndex}.parameters` as const
  const { fields, append, remove } = useFieldArray({
    control,
    name: parametersName,
  })
  const [showParameters, setShowParameters] = useState(fields.length > 0)

  const parameterOptions = getParameterTypeOptions()
  const conditionalOptions = getParameterConditionalOptions()

  const toggleParamsOff = () => {
    setShowParameters(false)
    setValue(parametersName, [], {
      shouldDirty: true,
    })
    unregister(parametersName)
  }

  const paramErrors = formState.errors.doses?.[doseIndex]?.parameters

  return (
    <>
      <Switch
        name={'conditionalParams'}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (!event.target.checked) {
            toggleParamsOff()
          } else {
            setShowParameters(true)
            setValue(parametersName, [NEW_PARAMETER])
          }
        }}
        label={'Conditional parameters'}
        labelClassName={'text-august-primary'}
        size={SwitchSize.small}
        checked={showParameters}
        holderClassName={twx(`mt-2`, {
          'mb-4': !showParameters,
          'mb-2': showParameters,
        })}
      />
      {showParameters && (
        <div className={tw`mb-6 flex flex-col gap-2`}>
          {fields.map((field, index) => {
            const paramError = paramErrors?.[index]

            const chosenParameterType = watch(
              `doses.${doseIndex}.parameters.${index}.parameterType`
            ) as ValidParameterType
            const validation = chosenParameterType
              ? parameterTypeToValidation(chosenParameterType)
              : {
                  props: {
                    type: 'number',
                  },
                  validationFunction: undefined,
                  valuePlaceholder: undefined,
                }

            return (
              <div
                key={field.id}
                className={tw`flex flex-row items-center gap-2`}
              >
                <Controller
                  name={`doses.${doseIndex}.parameters.${index}.parameterType`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <BadgeSelect
                        name={`doses.${doseIndex}.parameters.${index}.parameterType`}
                        badgeLabel={'Hold if'}
                        options={parameterOptions}
                        className={'min-w-[40%]'}
                        onChange={(e: OptionTypeBase<ValidParameterType>) => {
                          onChange(e.value)
                          setValue(
                            `doses.${doseIndex}.parameters.${index}.numeric.unit`,
                            parameterTypeToUnit(e.value)
                          )
                        }}
                        value={parameterOptions.find(
                          (opt) => opt.value === value
                        )}
                        showErrorBorder={!!paramError?.parameterType}
                      />
                    )
                  }}
                />
                <Controller
                  name={`doses.${doseIndex}.parameters.${index}.conditional`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <BadgeSelect
                        name={`doses.${doseIndex}.parameters.${index}.conditional`}
                        badgeLabel={'Is'}
                        options={conditionalOptions}
                        placeholder={'Select...'}
                        className={tw`min-w-[30%]`}
                        onChange={(
                          e: OptionTypeBase<ValidParameterConditional>
                        ) => {
                          onChange(e.value)
                        }}
                        value={conditionalOptions.find(
                          (opt) => opt.value === value
                        )}
                        showErrorBorder={!!paramError?.conditional}
                      />
                    )
                  }}
                />
                <BasicInput
                  containerClassName={tw`w-full`}
                  placeholder={'#'}
                  {...validation.props}
                  {...register(
                    `doses.${doseIndex}.parameters.${index}.numeric.value`,
                    {
                      required: true,
                      validate: validation.validationFunction,
                      valueAsNumber: true,
                    }
                  )}
                  showErrorBorder={!!paramError?.numeric?.value}
                  valueSuffix={validation.valuePlaceholder}
                />
                <div className={tw`flex flex-row items-center gap-1`}>
                  <ButtonLink
                    icon={'fa-regular fa-fw fa-circle-plus'}
                    title={'Add conditional parameter'}
                    iconClasses={tw`m-0 text-[20px] text-gray-08 hover:enabled:text-gray-06`}
                    onClick={() => append(NEW_PARAMETER)}
                  />
                  <ButtonLink
                    icon={'fa-regular fa-fw fa-circle-minus'}
                    title={'Remove conditional parameter'}
                    iconClasses={tw`m-0 text-[20px] text-gray-08 hover:enabled:text-gray-06`}
                    className={tw`disabled:opacity-50 hover:disabled:cursor-not-allowed`}
                    onClick={() => {
                      remove(index)

                      if (index === 0 && fields.length === 1) {
                        toggleParamsOff()
                      }
                    }}
                  />
                </div>
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}
