import { useFormContext } from 'react-hook-form'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput } from '@shared/components/Labels'
import { tw } from '@shared/utils/tailwind'
import BillingModal from '../../Modals/components/BillingModal'

export type ModalProps = {
  inputLabel: string
  inputName: string
  closeFn: () => void
  title: string
}
export default function TextSettingModal({
  title,
  inputName,
  closeFn,
  inputLabel,
}: ModalProps) {
  const {
    formState,
    formState: { disabled, isLoading },
    register,
  } = useFormContext()
  return (
    <BillingModal id={inputName} onClose={closeFn} title={title}>
      <LabelAboveInput htmlFor="note">{inputLabel}</LabelAboveInput>
      <BasicInput {...register(inputName)} />

      <div className={tw`mt-[24px]`}>
        <AsyncIconButton
          disabled={formState.isSubmitting || disabled || isLoading}
          isLoading={formState.isSubmitting || isLoading}
          buttonStyle="primary-fill"
          buttonSize="medium"
          type="submit"
          onClick={() => closeFn()}
        >
          Save
        </AsyncIconButton>
      </div>
    </BillingModal>
  )
}
