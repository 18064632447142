import { GenerateBulkMarParameters_SortBy as SortBy } from '@augusthealth/models/com/august/protos/jobs'
import { addMonths, parseISO, setDate } from 'date-fns'
import { useContext, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useForm, UseFormRegister } from 'react-hook-form'
import { sendBulkEMar, sendBulkMar } from '@shared/api/facility'
import { download } from '@shared/api/legacy'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { BasicRadioButton } from '@shared/components/BasicInput/BasicInput'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Facility } from '@shared/types/facility'
import { Person } from '@shared/types/person'
import { UserAccount } from '@shared/types/user'
import { formatMarDate } from '@shared/utils/date'
import { isEmarActive } from '@shared/utils/facilities'
import { tw } from '@shared/utils/tailwind'
import { emarReportPdfUrl, marPdfUrl } from '@app/api/medications'
import { RHFSwitch } from '@app/components/Switch'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import styles from './styles.module.css'

type Props = {
  person?: Person
  facility?: Facility
  onClose: () => void
  downloadAll?: boolean
}

type FormData = {
  marDate: string
  isBlankMar: boolean
  showPRNLog: boolean
  emarReport: boolean
  sortBy: SortBy
}

export function DownloadIndividualMarModal({
  person,
  onClose,
}: {
  person: Person
  onClose: () => void
  user: UserAccount
}) {
  const { currentFacility } = useCurrentFacility()
  const facility =
    currentFacility?.tag === 'Complete' ? currentFacility.value : undefined

  return (
    <DownloadMarModal
      person={person}
      onClose={onClose}
      downloadAll={false}
      facility={facility}
    />
  )
}

export function DownloadBulkMarModal({
  facility,
  onClose,
}: {
  facility: Facility
  onClose: () => void
}) {
  return (
    <DownloadMarModal
      facility={facility}
      onClose={onClose}
      downloadAll={true}
    />
  )
}

function DownloadMarModal({
  person,
  onClose,
  facility,
  downloadAll = false,
}: Props) {
  const { setError } = useContext(GlobalContext)
  const now = new Date()
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0)
  const firstOfNextMonth = setDate(addMonths(today, 1), 1)
  const { handleSubmit, register, watch } = useForm<FormData>({
    defaultValues: {
      marDate: formatMarDate(firstOfNextMonth),
      sortBy: SortBy.SORT_BY_LAST_NAME,
    },
  })

  const emarActive = facility && isEmarActive(facility)

  const [startDate, setStartDate] = useState<Date | null>(new Date())
  const [endDate, setEndDate] = useState<Date | null>(new Date())

  const [isLoadingMar, setIsLoadingMar] = useState(false)
  const [processingMar, setProcessingMar] = useState(false)
  const marDate = parseISO(watch('marDate'))
  const emarSelected = watch('emarReport')
  const blankSelected = watch('isBlankMar')
  const showPRNLogSelected = watch('showPRNLog')

  const onSubmit = (data: FormData) => {
    setIsLoadingMar(true)
    const formattedDate = formatMarDate(marDate)

    if (emarSelected && startDate && endDate && downloadAll && facility) {
      sendBulkEMar({
        orgId: facility.orgId,
        facilityId: facility.id,
        startMonth: formatMarDate(startDate),
        endMonth: formatMarDate(endDate),
        sortBy: data.sortBy,
      })
        .then(() => setProcessingMar(true))
        .catch(setError)
        .finally(() => {
          setIsLoadingMar(false)
        })
    } else if (downloadAll && facility) {
      sendBulkMar({
        orgId: facility.orgId,
        facilityId: facility.id,
        date: formattedDate,
        blank: data.isBlankMar,
        includePrnLog: data.showPRNLog,
        sortBy: data.sortBy,
      })
        .then(() => setProcessingMar(true))
        .catch(setError)
        .finally(() => {
          setIsLoadingMar(false)
        })
    } else {
      let fileUrl: string
      if (emarSelected && startDate && endDate) {
        fileUrl = emarReportPdfUrl({
          person: person!,
          startMonth: formatMarDate(startDate),
          endMonth: formatMarDate(endDate),
        })
      } else {
        fileUrl = marPdfUrl({
          orgId: person!.orgId!,
          facilityId: person!.facilityId!,
          date: formattedDate,
          blank: data.isBlankMar,
          includePrnLog: data.showPRNLog,
          people: [person!.id!],
        })
      }

      download({ fileUrl, target: '_blank' })
        .catch(setError)
        .finally(() => {
          setIsLoadingMar(false)
          onClose()
        })
    }
  }

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  const disableContinue =
    isLoadingMar || (emarSelected && (startDate === null || endDate === null))

  return (
    <AnimatedPopup
      onClickBackground={onClose}
      containerClassName={styles.marSelectorModal}
      title={null}
    >
      <h2 className="mb-[24px] text-center text-[18px] font-semibold leading-[24px]">
        {downloadAll ? 'Download MAR' : 'Print MAR'}
      </h2>
      {processingMar && <ProcessingMarMessage onClose={onClose} />}
      {!processingMar && (
        <form data-testid="download-mar-form" onSubmit={handleSubmit(onSubmit)}>
          {!emarSelected && (
            <PaperMarDateSelector
              today={today}
              register={register}
              firstOfNextMonth={firstOfNextMonth}
              marDate={marDate}
            />
          )}
          {emarSelected && (
            <div className={tw`my-[26px] flex justify-center`}>
              <DatePicker
                onChange={handleChange}
                maxDate={new Date(Date.now())}
                dateFormat="MM/yyyy"
                startDate={startDate}
                endDate={endDate}
                selectsRange
                customInput={
                  <CalendarInput
                    data-testid={'download-eMar-date-range-start'}
                  />
                }
                portalId={'root'}
                showMonthYearPicker
              />
            </div>
          )}
          <section>
            <div className={styles.switchHolder}>
              {emarActive && (
                <RHFSwitch
                  holderClassName={'mb-[8px]'}
                  labelPosition="left"
                  label="eMAR Report"
                  register={register}
                  name="emarReport"
                  id="emarReport"
                  disabled={blankSelected || showPRNLogSelected}
                />
              )}
              <RHFSwitch
                holderClassName={'mb-[8px]'}
                labelPosition="left"
                label="Blank MAR"
                register={register}
                name="isBlankMar"
                id="isBlankMar"
                disabled={emarSelected}
              />
              <RHFSwitch
                labelPosition="left"
                register={register}
                name="showPRNLog"
                id="showPRNLog"
                label="Include PRN Medication Log"
                disabled={emarSelected}
              />
            </div>
            {downloadAll && (
              <div className={styles.sortByGroup}>
                <span className="font-semibold">Sort By:</span>
                <div className="flex">
                  <BasicRadioButton
                    data-testid="mar-sort-by-room"
                    id="mar-sort-by-room"
                    value={SortBy.SORT_BY_ROOM_NUMBER}
                    {...register('sortBy')}
                  >
                    Room number
                  </BasicRadioButton>
                  <BasicRadioButton
                    className="ml-[16px]"
                    data-testid="mar-sort-by-name"
                    id="mar-sort-by-name"
                    value={SortBy.SORT_BY_LAST_NAME}
                    {...register('sortBy')}
                  >
                    Last name
                  </BasicRadioButton>
                </div>
              </div>
            )}
          </section>
          {downloadAll && (
            <div className={styles.subHeading}>
              This will generate a MAR for all current residents.
              <br />
              You will receive an email when it is ready to download.
            </div>
          )}
          <AsyncIconButton
            className="mt-[24px]"
            buttonStyle="primary-fill"
            id="printMarContinueBtn"
            isLoading={isLoadingMar}
            disabled={disableContinue}
            width="100%"
            type={'submit'}
          >
            Continue
          </AsyncIconButton>
        </form>
      )}
    </AnimatedPopup>
  )
}

function ProcessingMarMessage(props: { onClose: () => void }) {
  return (
    <>
      <div className={styles.subHeading}>
        We are generating the MAR for all current residents. We will email you
        when they are ready to download.
      </div>
      <AsyncIconButton
        className={'mt-[24px]'}
        buttonStyle="primary-fill"
        id="okCloseMarModalBtn"
        onClick={props.onClose}
        width="100%"
      >
        OK
      </AsyncIconButton>
    </>
  )
}

function PaperMarDateSelector(props: {
  today: Date
  register: UseFormRegister<FormData>
  firstOfNextMonth: Date
  marDate: Date
}) {
  const { today, register, firstOfNextMonth, marDate } = props
  return (
    <div className={styles.radioBtnGroup}>
      <input
        type="radio"
        className={styles.radioBtn}
        id="marMonthCurrent"
        value={formatMarDate(today)}
        {...register('marDate')}
      />
      <label className={styles.radioBtnLabel} htmlFor="marMonthCurrent">
        {today.toLocaleDateString('en-us', { month: 'long' })}
      </label>
      <input
        type="radio"
        className={styles.radioBtn}
        id="marMonthNext"
        value={formatMarDate(firstOfNextMonth)}
        {...register('marDate')}
        defaultChecked
      />
      <label className={styles.radioBtnLabel} htmlFor="marMonthNext">
        {firstOfNextMonth.toLocaleDateString('en-us', { month: 'long' })}
      </label>
      <div className={styles.radioSlider}>
        {marDate.toLocaleDateString('en-us', { month: 'long' })}
      </div>
    </div>
  )
}
