import { AppraisalSettings_AppraisalCategory } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { RoutineOrder, RoutineStatus } from '@shared/types/routine_order'
import { twx } from '@shared/utils/tailwind'
import { getAppraisalCategory } from './helpers'

export default function CategoryLabel({
  categories,
  routineOrder,
}: {
  categories: AppraisalSettings_AppraisalCategory[]
  routineOrder: RoutineOrder
}) {
  if (routineOrder.routineType.custom) {
    const appraisalCategory = getAppraisalCategory({
      categories,
      categoryKeys: routineOrder,
    })
    const title =
      appraisalCategory?.categoryOptions?.title || 'Unknown category'
    const isDiscontinued =
      routineOrder.status === RoutineStatus.ROUTINE_STATUS_DISCONTINUED

    return (
      <div
        className={twx({
          'bg-primary-soft': !isDiscontinued,
          'bg-gray-10': isDiscontinued,
          'pl-[6px] pr-[6px]': true,
        })}
      >
        <span
          className={twx({
            'text-primary': !isDiscontinued,
            'text-[11px] font-semibold uppercase': true,
          })}
        >
          {title}
        </span>
      </div>
    )
  }

  return undefined
}
