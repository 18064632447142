import { ReactNode } from 'react'
import { tw } from '@shared/utils/tailwind'

export default function PageTitle({ children }: { children: ReactNode }) {
  return (
    <h2
      className={tw`mb-[12px] text-[24px] font-medium leading-[36px] text-secondary-02`}
    >
      {children}
    </h2>
  )
}
