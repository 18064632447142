import List from '@app/pages/Organizations/List'
import { PageTab } from '@app/pages/Tools/PdfTemplate/helpers'
import { useSignableFormContext } from '@app/pages/Tools/PdfTemplate/SignableFormContext'
import ListOfStates from '../StateTasks/ListOfStates'
import GlobalAssignments from './GlobalAssignments'
import GlobalTasks from './GlobalTasks'

export default function GlobalStateTasksAndOrgList() {
  const { signableForms } = useSignableFormContext('All Templates')
  const tab = PageTab.ASSIGN_TEMPLATE
  const getPath = (orgId: string) => `/tools/pdfTemplate/${tab}/orgs/${orgId}`

  if (signableForms.tag === 'Loading') {
    return <>Loading...</>
  }

  return (
    <div>
      <GlobalAssignments formList={signableForms.value} />
      <GlobalTasks formList={signableForms.value} />
      <ListOfStates />
      <List getPath={getPath} showInactiveOrganizations={true} />
    </div>
  )
}
