import '@shared/global'
import ReactDOM from 'react-dom/client'
import App from './App'
import NewAppVersionBoundary from './components/NewAppVersionBoundary'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-pro/css/all.css'
import '@shared/styles/variables.css'
import '@shared/styles/index.css'
import '@shared/styles/layout.css'
import '@shared/styles/icons.css'
import '@shared/styles/modal.css'
import '@shared/styles/print.css'
import '@shared/styles/popup.css'
import '@shared/styles/dropdown.css'
import '@shared/styles/radio.css'
import '@shared/styles/progressbar.css'
import '@shared/styles/generator.css'
import '@shared/styles/dropzone.css'
import '@shared/styles/form.css'
import '@shared/styles/draganddrop.css'
import '@shared/styles/tailwind.css'
import '@shared/styles/reactDatePicker.css'
import { pdfjs } from 'react-pdf'
import ErrorMonitoring from '@shared/ErrorMonitoring'
import { configureLocalStorage } from '@shared/utils/localStorage'

ErrorMonitoring.init()

/**
 * Configure the PDFjs worker
 * We use the legacy worker to support older browsers
 * See https://github.com/wojtekmaj/react-pdf?tab=readme-ov-file#configure-pdfjs-worker
 */
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`

const rootElem = document.getElementById('root')
if (rootElem) {
  void configureLocalStorage().then(() => {
    ReactDOM.createRoot(rootElem).render(
      <ErrorMonitoring.Boundary showDialog>
        <NewAppVersionBoundary>
          <App />
        </NewAppVersionBoundary>
      </ErrorMonitoring.Boundary>
    )
  })
}
