import {
  Charge,
  ChargeType,
  Recurring_Interval,
  Recurring_Rate,
  Recurring_Rate_RateChangeReason,
} from '@augusthealth/models/com/august/protos/charge'
import {
  Contact,
  Contact_ContactRelationship,
} from '@augusthealth/models/com/august/protos/contact'
import { Person } from '@shared/types/person'
import { nameForChargeType, nameForRateChange } from '@shared/utils/charge'
import { firstLastName, relationshipName } from '@shared/utils/contact'
import {
  fromDateMessageToDate,
  fromDateToDateMessage,
} from '@shared/utils/date'
import {
  createOneTimeCharge,
  createRecurringCharge,
  updateRecurringCharge,
} from '@app/api/charges'

export interface FormData {
  amount: number
  chargeType: ChargeTypeSelection
  frequency?: FrequencySelection
  notes: string
  payer: PayerSelection
  recurring: boolean
  recurringEndDate?: Date | null
  recurringStartDate: Date
  serviceDate?: Date | null
}

export async function addCharge(person: Person, formData: FormData) {
  if (formData.recurring) {
    const response = await createRecurringCharge(
      person,
      makePatch(person, formData)
    )
    return `recurring-${response.id}`
  } else {
    const response = await createOneTimeCharge(
      person,
      makePatch(person, formData)
    )
    return `onetime-${response.id}`
  }
}

function makePatch(person: Person, formData: FormData): Charge {
  const sharedPatch = {
    orgId: person.orgId,
    facilityId: person.facilityId,
    personId: person.id,
    type: formData.chargeType.value,
    payerId: formData.payer.value,
    notes: formData.notes,
  }

  if (formData.recurring) {
    return {
      ...sharedPatch,
      recurring: {
        interval: formData.frequency?.value,
        rates: [
          {
            amount: formData.amount,
            start: fromDateToDateMessage(formData.recurringStartDate),
            end: formData.recurringEndDate
              ? fromDateToDateMessage(formData.recurringEndDate)
              : undefined,
            notes: formData.notes,
            reason:
              Recurring_Rate_RateChangeReason.RATE_CHANGE_REASON_INITIAL_RATE,
          },
        ],
      },
    }
  } else {
    return {
      ...sharedPatch,
      oneTime: {
        amount: formData.amount,
        date: fromDateToDateMessage(formData.serviceDate!),
      },
    }
  }
}

export const recurringChargeTypes = [
  ChargeType.CHARGE_TYPE_CARE_RATE,
  ChargeType.CHARGE_TYPE_DAILY_RATE,
  ChargeType.CHARGE_TYPE_MONTHLY_RATE,
]

export const monthlyChargeTypes = [
  ChargeType.CHARGE_TYPE_CARE_RATE,
  ChargeType.CHARGE_TYPE_MONTHLY_RATE,
]

export type FrequencySelection = { label: string; value: Recurring_Interval }
export function frequencies(): FrequencySelection[] {
  return [
    dailyFrequency,
    { label: 'Weekly', value: Recurring_Interval.INTERVAL_WEEK },
    monthlyFrequency,
  ]
}

export const monthlyFrequency = {
  label: 'Monthly',
  value: Recurring_Interval.INTERVAL_MONTH,
}
export const dailyFrequency = {
  label: 'Daily',
  value: Recurring_Interval.INTERVAL_DAY,
}

export function isMonthly(chargeType: ChargeTypeSelection) {
  return monthlyChargeTypes.includes(chargeType.value)
}

export type ChargeTypeSelection = { label: string; value: ChargeType }

export function chargeTypeToSelect(): ChargeTypeSelection[] {
  const result: ChargeTypeSelection[] = []

  for (const key in ChargeType) {
    if (excludedKeys.includes(ChargeType[key])) {
      continue
    }

    const chargeType: ChargeType = ChargeType[key]

    result.push({
      label: nameForChargeType(ChargeType[key]),
      value: chargeType,
    })
  }

  return result.sort((a, b) => {
    if (a.label > b.label) {
      return 1
    } else if (a.label === b.label) {
      return 0
    } else {
      return -1
    }
  })
}

export type PayerSelection = { label: string; value: string }
export function contactsToSelect(contacts: Contact[]): PayerSelection[] {
  return contacts.map((contact) => {
    const relationships = (contact.relationship || [])
      .filter((relationship) => {
        const isExcluded = excludedRelationships.includes(relationship)
        return !isExcluded
      })
      .map(relationshipName)
      .join(', ')
    return {
      label: `${firstLastName(contact)} (${relationships})`,
      value: contact.id!,
    }
  })
}

const excludedKeys = [
  ChargeType.CHARGE_TYPE_ADJUSTMENT_ACCIDENTAL_CHARGE,
  ChargeType.CHARGE_TYPE_ADJUSTMENT_APPOINTMENT_CANCELLED,
  ChargeType.CHARGE_TYPE_HOSPITAL_STAY,
  ChargeType.UNRECOGNIZED,
  ChargeType.CHARGE_TYPE_UNSPECIFIED,
]

const excludedRelationships = [
  Contact_ContactRelationship.CONTACT_RELATIONSHIP_FAMMEMB,
  Contact_ContactRelationship.CONTACT_RELATIONSHIP_PERSONAL,
  Contact_ContactRelationship.CONTACT_RELATIONSHIP_CLINICIAN,
  Contact_ContactRelationship.CONTACT_RELATIONSHIP_PROFESSIONAL,
  Contact_ContactRelationship.CONTACT_RELATIONSHIP_C,
]

export interface AdjustmentFormData {
  amount: number
  reason: ChargeTypeSelection
  payer: PayerSelection
  notes: string
}

export function adjustmentReasons(): ChargeTypeSelection[] {
  const result: ChargeTypeSelection[] = []

  for (const key in ChargeType) {
    if (!includedKeys.includes(ChargeType[key])) {
      continue
    }

    const chargeType: ChargeType = ChargeType[key]

    result.push({
      label: nameForChargeType(ChargeType[key]),
      value: chargeType,
    })
  }

  return result.sort((a, b) => {
    if (a.label > b.label) {
      return 1
    } else if (a.label === b.label) {
      return 0
    } else {
      return -1
    }
  })
}

const includedKeys = [
  ChargeType.CHARGE_TYPE_ADJUSTMENT_ACCIDENTAL_CHARGE,
  ChargeType.CHARGE_TYPE_ADJUSTMENT_APPOINTMENT_CANCELLED,
  ChargeType.CHARGE_TYPE_OTHER,
  ChargeType.CHARGE_TYPE_HOSPITAL_STAY,
]

export async function addAdjustment(
  person: Person,
  formData: AdjustmentFormData
) {
  const response = await createOneTimeCharge(
    person,
    makeAdjustmentPatch(person, formData)
  )
  return `onetime-${response.id}`
}

function makeAdjustmentPatch(
  person: Person,
  formData: AdjustmentFormData
): Charge {
  return {
    orgId: person.orgId,
    facilityId: person.facilityId,
    personId: person.id,
    type: formData.reason.value,
    payerId: formData.payer.value,
    notes: formData.notes,
    oneTime: {
      amount: formData.amount,
      date: fromDateToDateMessage(new Date()),
    },
  }
}

export type RateChangeSelection = {
  label: string
  value: Recurring_Rate_RateChangeReason
}

export function rateChangeReasons() {
  return [
    Recurring_Rate_RateChangeReason.RATE_CHANGE_REASON_ANNUAL_INCREASE,
    Recurring_Rate_RateChangeReason.RATE_CHANGE_REASON_LEVEL_OF_CARE_ADJUSTMENT,
  ].map((reason) => ({
    label: nameForRateChange(reason),
    value: reason,
  }))
}

export interface RateChangeFormData {
  reason: RateChangeSelection
  newAmount: number
  effectiveDate: Date
}

export function scheduleRateChange(
  charge: Charge,
  formData: RateChangeFormData,
  person: Person
) {
  const newRate: Recurring_Rate = {
    amount: formData.newAmount,
    start: fromDateToDateMessage(formData.effectiveDate),
    reason: formData.reason.value,
  }

  const newCharge: Charge = {
    ...charge,
    payerId: charge.payer?.id,
    payer: undefined,
    recurring: {
      ...charge.recurring!,
      rates: [...(charge.recurring!.rates || []), newRate],
    },
  }

  return updateRecurringCharge(person, newCharge)
}

export function nextSchedulableDate(charge: Charge) {
  const rates = charge.recurring?.rates || []
  const mostRecentRate = rates[rates.length - 1]

  if (mostRecentRate && mostRecentRate.start) {
    const mostRecentDate = fromDateMessageToDate(mostRecentRate.start)
    mostRecentDate!.setDate(mostRecentDate!.getDate() + 1)

    return mostRecentDate
  } else {
    return undefined
  }
}
