import { useState } from 'react'
import {
  Props as AsyncButtonProps,
  AsyncIconButton,
  ButtonStyle,
} from '@shared/components/AsyncButton'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import notEmpty from '@shared/utils/notEmpty'
import { tw, twx } from '@shared/utils/tailwind'

interface ModalButtonConfig
  extends Omit<AsyncButtonProps, 'buttonStyle' | 'onClick'> {
  buttonStyle?: ButtonStyle
  onClick: () => Promise<void> | void
  hidden?: boolean
}

export interface Props {
  confirmButtonConfig: ModalButtonConfig
  denyButtonConfig: ModalButtonConfig
  title: string | React.ReactNode
  content?: React.ReactNode
  testId?: string
  contentClassNames?: string
}

export default function ConfirmModal({
  title,
  testId,
  content,
  denyButtonConfig,
  confirmButtonConfig,
  contentClassNames = '',
}: Props) {
  const {
    buttonStyle: confirmButtonFill = 'danger-fill',
    children: confirmationText = 'Okay',
    onClick: onConfirm,
    className: confirmationClass,
    disabled: confirmDisabled,
    hidden: confirmHidden,
    ...confirmProps
  } = confirmButtonConfig
  const {
    buttonStyle: denyButtonFill = 'secondary-outline',
    children: denyText = 'Not now',
    onClick: onDeny,
    className: denyClass,
    disabled: denyDisabled,
    hidden: denyHidden,
    ...denyProps
  } = denyButtonConfig

  const [confirmed, setConfirmed] = useState(false)
  const onClickConfirm = async () => {
    setConfirmed(true)

    try {
      onConfirm && (await onConfirm())
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      setConfirmed(false)
    }
  }

  return (
    <Modal
      open={true}
      id={['confirm', testId].filter(notEmpty).join('-')}
      data-testid={testId}
      onClose={onDeny}
      contentClassName={twx(
        'min-w-[364px] max-w-[464px] text-center flex flex-col gap-3'
      )}
    >
      <h2 className=" text-[18px] font-semibold leading-[24px]">{title}</h2>
      {content && (
        <div
          className={tw`mb-4 text-[16px] leading-[24px] text-secondary-07 ${contentClassNames}`}
        >
          {content}
        </div>
      )}
      <AsyncIconButton
        buttonStyle={confirmButtonFill}
        className={twx(confirmationClass, 'w-full', {
          hidden: confirmHidden,
        })}
        data-testid="confirm-button"
        id="confirmBtn"
        onClick={onClickConfirm}
        autoFocus={true}
        tabIndex={0}
        disabled={confirmed || confirmDisabled}
        {...confirmProps}
      >
        {confirmationText}
      </AsyncIconButton>
      <AsyncIconButton
        id="denyBtn"
        className={twx(denyClass, 'w-full', {
          hidden: denyHidden,
        })}
        buttonStyle={denyButtonFill}
        onClick={onDeny}
        tabIndex={0}
        disabled={confirmed || denyDisabled}
        {...denyProps}
      >
        {denyText}
      </AsyncIconButton>
    </Modal>
  )
}
