import { MedPass } from '@augusthealth/models/com/august/protos/api/med_pass'
import { useState } from 'react'
import Card from '@shared/components/Card'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { tw } from '@shared/utils/tailwind'
import useMedPasses from '@app/hooks/useMedPasses'
import { OrgFacilitySelects } from '@app/pages/Tools/SharedSelects/OrgFacilitySelects'
import styles from './styles.module.css'
import MedPassesForm from './MedPassesForm'

export const MedpassConfiguration = () => {
  const [selectedOrganization, setSelectedOrganization] =
    useState<OptionTypeBase<Organization> | null>(null)
  const [selectedFacility, setSelectedFacility] =
    useState<OptionTypeBase<Facility> | null>(null)
  const { medPasses, loadMedPasses, setMedPasses } = useMedPasses({
    facility: selectedFacility?.value || undefined,
  })

  return (
    <div className={tw`content-holder`}>
      <h4 className={tw`page-title mt-[24px]`}>Medpass Configuration</h4>
      <div className={styles.container}>
        <div>
          <Card className={tw`mb-[16px] flex flex-col`}>
            <div>
              <OrgFacilitySelects
                onOrgSelect={setSelectedOrganization}
                onFacilitySelect={setSelectedFacility}
                orgLabel={'I want to view the medpass settings for...'}
                selectedOrganization={selectedOrganization}
                selectedFacility={selectedFacility}
              />
            </div>
          </Card>
        </div>
        <div>
          {selectedFacility && medPasses.tag === 'Loading' && (
            <Card className={styles.card}>
              <SimpleSpinner />
            </Card>
          )}
          {selectedFacility && medPasses.tag === 'Complete' && (
            <MedPassesForm
              facility={selectedFacility.value}
              medPasses={medPasses.value}
              loadMedPasses={loadMedPasses}
              setMedPasses={(passes: MedPass[]) =>
                setMedPasses({
                  tag: 'Complete',
                  value: passes,
                })
              }
            />
          )}
        </div>
      </div>
    </div>
  )
}
