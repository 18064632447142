import { Facility } from '@shared/types/facility'
import { getOrElse, Loading, mapLoading } from '@shared/utils/loading'

export function hasRainforestMerchantId(
  facility: Loading<Facility> | undefined
) {
  if (facility === undefined) return false

  return getOrElse(
    mapLoading(
      facility,
      (f: Facility) => !!f.externalFields?.rainforestMerchantId
    ),
    false
  )
}
