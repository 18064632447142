import Skeleton from 'react-loading-skeleton'
import { RequiredLabel } from '@shared/components/Labels'
import { Person } from '@shared/types/person'
import { MedicationBreadcrumbs } from '@app/components/Breadcrumbs/MedicationBreadcrumbs'
import styles from '@app/components/Residents/Medications/Orders/styles.module.css'

export const SectionTitle = ({ children }: { children: React.ReactNode }) => (
  <h3 className={styles.sectionTitle}>{children}</h3>
)

export const OrderSectionWrapper = ({
  children,
  showRequired = false,
}: {
  showRequired?: boolean
  children: React.ReactNode
}) => {
  return (
    <div>
      <SectionTitle>
        Order
        <RequiredLabel showError={showRequired} />
      </SectionTitle>
      {children}
    </div>
  )
}

export const DetailSectionWrapper = ({
  children,
  showRequired = false,
}: {
  showRequired?: boolean
  children: React.ReactNode
}) => {
  return (
    <div className={styles.detailCardsContainer}>
      <SectionTitle>
        Details
        <RequiredLabel showError={showRequired} />
      </SectionTitle>
      {children}
    </div>
  )
}

export const FullMedicationOrderWrapper = ({
  title,
  children,
}: {
  children: React.ReactNode
  title: string
}) => {
  return (
    <div className={styles.reviewOrderContainer}>
      <div className="page-title horizontal">
        <div>{title}</div>
      </div>
      {children}
    </div>
  )
}

export const MedicationOrderLoading = ({
  title,
  person,
}: {
  title: string
  person?: Person
}) => {
  return (
    <>
      {person && <MedicationBreadcrumbs person={person} />}
      <FullMedicationOrderWrapper title={title}>
        <OrderSectionWrapper>
          <Skeleton />
        </OrderSectionWrapper>
        <DetailSectionWrapper>
          {Array(4)
            .fill(undefined)
            .map((_, ix) => (
              <Skeleton key={`ls-${ix}`} />
            ))}
        </DetailSectionWrapper>
      </FullMedicationOrderWrapper>
    </>
  )
}
