import {
  Report,
  ReportAssignment,
} from '@augusthealth/models/com/august/protos/report'
import { useState } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import { Facility } from '@shared/types/facility'
import PreviewLightbox from '@app/pages/Facilities/Reports/PreviewLightbox'

type Props = {
  assignment: ReportAssignment
  facility: Facility
  report: Report
}

export default function PreviewIcon(props: Props) {
  const { assignment, facility } = props
  const { id: defaultFacilityId, orgId: defaultOrgId } = facility
  const { orgId: aOrgId, facilityId: aFacilityId, name, id } = assignment
  const orgId = defaultOrgId || aOrgId
  const facilityId = defaultFacilityId || aFacilityId
  const [showPreviewLightbox, setShowPreviewLightbox] = useState<boolean>(false)

  if (showPreviewLightbox && name && orgId && facilityId && id) {
    return (
      <PreviewLightbox
        {...props}
        orgId={orgId}
        facilityId={facilityId}
        name={name}
        onClose={() => setShowPreviewLightbox(false)}
      />
    )
  }

  if (orgId && facilityId && name) {
    return (
      <ButtonLink title={name} onClick={() => setShowPreviewLightbox(true)}>
        <i className="fa fa-eye" />
      </ButtonLink>
    )
  }

  return null
}
