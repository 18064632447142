import { Immunization } from '@augusthealth/models/com/august/protos/immunization'
import { Person } from '@shared/types/person'
import {
  getDiseaseLabel,
  getImmunizationsByDiseaseName,
  sortByDateOfOccurrence,
  TARGET_DISEASE_OPTIONS,
} from '@shared/utils/immunizations'
import ImmunizationCard from './ImmunizationCard'

type ImmunizationGroup = {
  name: string
  list: Immunization[]
}

export default function ImmunizationList({
  immunizations,
  person,
  onClick,
}: {
  immunizations: Immunization[]
  person: Person
  onClick: (disease: string) => void
}) {
  const groups: ImmunizationGroup[] = TARGET_DISEASE_OPTIONS.map((opt) => {
    const diseaseName = opt.value.text || 'Unknown'
    return {
      name: diseaseName,
      list: getImmunizationsByDiseaseName({ diseaseName, immunizations }),
    }
  })

  return (
    <ul>
      {groups.map((g) => {
        const { name, list } = g
        const sortedList = sortByDateOfOccurrence(list)

        return (
          <li key={`immunization-row-${name}`}>
            <ImmunizationCard
              immunization={
                sortedList.length ? sortedList[0] : getDiseaseLabel(name)
              }
              person={person}
              onClick={() => onClick(name)}
            />
          </li>
        )
      })}
    </ul>
  )
}
