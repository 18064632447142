import { RouteComponentProps } from 'react-router'
import { DataType } from '@shared/types/snapshot'
import { useSnapshotById } from '@app/hooks/useSnapshot'
import ArchivedAssessment from './ArchivedAssessment'
import { LevelOfCarePage } from './ArchivedLevelOfCare'

type Props = RouteComponentProps<{
  orgId: string
  facilityId: string
  personId: string
  documentId: string
}>

export function ArchivedDocuments({ match, ...rest }: Props) {
  const snapshot = useSnapshotById({
    personId: match.params.personId,
    orgId: match.params.orgId,
    snapshotId: match.params.documentId,
  })

  if (snapshot.tag === 'Loading') {
    return null
  }

  switch (snapshot.value.dataType!) {
    case DataType.DATA_TYPE_LEVEL_OF_CARE_CIMINO:
      return (
        <LevelOfCarePage snapshot={snapshot.value} match={match} {...rest} />
      )
    case DataType.DATA_TYPE_AUGUST_INITIAL_APPRAISAL:
      return (
        <ArchivedAssessment snapshot={snapshot.value} match={match} {...rest} />
      )
  }

  return null
}
