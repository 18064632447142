import { IncomingFile } from '@augusthealth/models/com/august/protos/incoming_file'
import { useContext, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import Card from '@shared/components/Card'
import CurrentFacilityContext from '@shared/contexts/CurrentFacilityContext'
import useCurrentPage, { extractIds } from '@shared/hooks/useCurrentPage'
import { formatDateTime } from '@shared/utils/date'
import { tw } from '@shared/utils/tailwind'
import { useIncomingFilesInbox } from '@app/hooks/useIncomingFilesInbox'
import { ReviewPendingFileModal } from '@app/pages/Facilities/Inbox/IncomingFiles/ReviewPendingFileModal'

export const IncomingFiles = () => {
  const { convertToFacilityTimezone } = useContext(CurrentFacilityContext)
  const page = useCurrentPage()
  const { facilityId, orgId } = extractIds(page)
  const { pendingFiles, refreshFiles } = useIncomingFilesInbox({
    id: facilityId!,
    orgId: orgId!,
  })

  const [showReviewModal, setShowReviewModal] = useState<IncomingFile | null>(
    null
  )

  return (
    <>
      {showReviewModal && (
        <ReviewPendingFileModal
          file={showReviewModal}
          closeModal={() => setShowReviewModal(null)}
          afterSubmission={async () => {
            await refreshFiles()
            setShowReviewModal(null)
          }}
        />
      )}
      <div
        className={
          'mb-[16px] ml-[8px] mt-[48px] flex flex-row items-center justify-between text-[24px] font-medium leading-[36px] text-secondary-02'
        }
      >
        Incoming Files
      </div>

      {pendingFiles.tag === 'Loading' ? (
        <>
          {Array(4)
            .fill(undefined)
            .map((_, ix) => (
              <Skeleton
                key={`pending-files-ls-${ix}`}
                height={'48px'}
                className={'mb-[8px]'}
              />
            ))}
        </>
      ) : (
        <>
          {pendingFiles.value.length === 0 && (
            <Card>There are no pending files to review.</Card>
          )}
          {pendingFiles.value?.map((file) => {
            return (
              <div
                key={file.id}
                className={
                  'hover:card-outline mb-[8px] flex cursor-pointer flex-row items-center justify-between rounded-[8px] bg-white p-4 text-[14px] font-medium leading-[20px] text-gray-07 shadow-card '
                }
                onClick={() => setShowReviewModal(file)}
              >
                <div className={tw`flex grow-[2] flex-row items-center gap-2`}>
                  <i className={'fa-regular fa-fax mr-2'} />
                  <div
                    className={tw`flex flex-row items-center justify-start gap-2`}
                  >
                    <span className={tw`font-semibold text-gray-03`}>Fax</span>
                    from
                    <span className={tw`font-semibold text-gray-03`}>
                      {file.from}
                    </span>
                  </div>
                </div>
                <div
                  className={tw`flex grow flex-row items-center justify-between gap-2`}
                >
                  <span className={tw`text-gray-07`}>
                    {formatDateTime(
                      convertToFacilityTimezone(file.receivedAt!),
                      { includeDate: true }
                    )}
                  </span>
                  <i
                    className={'fa-regular fa-chevron-right fa-sm text-gray-07'}
                  />
                </div>
              </div>
            )
          })}
        </>
      )}
    </>
  )
}
