import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Organization } from '@shared/types/organization'
import { Loading } from '@shared/utils/loading'
import { listOrganizations } from '../api/organizations'

export default function useOrganizations(deps: React.DependencyList = []) {
  const { setError } = useContext(GlobalContext)
  const [organizations, setOrganizations] = useState<Loading<Organization[]>>({
    tag: 'Loading',
  })
  const [inactiveOrganizations, setInactiveOrganizations] = useState<
    Organization[]
  >([])
  const [allOrganizations, setAllOrganizations] = useState<
    Loading<Organization[]>
  >({ tag: 'Loading' })

  const reloadOrgs = async () => {
    const sortedOrganizations: {
      active: Organization[]
      inactive: Organization[]
    } = {
      active: [],
      inactive: [],
    }

    try {
      const allOrgs = await listOrganizations()
      setAllOrganizations({ tag: 'Complete', value: allOrgs })
      const { active, inactive } = allOrgs.reduce((accum, org) => {
        if (org.isActive) {
          accum.active.push(org)
        } else {
          accum.inactive.push(org)
        }

        return accum
      }, sortedOrganizations)
      setOrganizations({ tag: 'Complete', value: active })
      setInactiveOrganizations(inactive)
    } catch (e) {
      setError(e)
      setOrganizations({ tag: 'Complete', value: [] })
    }
  }

  useEffect(() => {
    void reloadOrgs()
  }, deps)

  return { organizations, reloadOrgs, inactiveOrganizations, allOrganizations }
}
