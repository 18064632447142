import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Redirect, useHistory } from 'react-router-dom'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import ButtonLink from '@shared/components/ButtonLink'
import Card from '@shared/components/Card'
import { hasPermissionForFacility } from '@shared/components/PermissionGates/PermissionGates'
import { FeatureFlagNames } from '@shared/constants/feature_flags'
import CurrentFacilityContext from '@shared/contexts/CurrentFacilityContext'
import { useUserContext } from '@shared/contexts/UserContext'
import useCurrentPage, { extractIds } from '@shared/hooks/useCurrentPage'
import { getFacilityUrl } from '@shared/legacy_routes'
import { Facility } from '@shared/types/facility'
import { hasActivePharmacyIntegration } from '@shared/utils/facilities'
import { isFeatureAllowed } from '@shared/utils/user'
import { usePendingMedicationsInbox } from '@app/hooks/usePendingMedicationsInbox'
import { IncomingFiles } from '@app/pages/Facilities/Inbox/IncomingFiles/IncomingFiles'
import styles from './inbox.module.css'
import { InboxPendingOrder } from './InboxPendingOrder'

export const FacilityInbox = () => {
  const history = useHistory()
  const page = useCurrentPage()
  const { facilityId, orgId } = extractIds(page)
  const { user } = useUserContext()
  const { currentFacility } = useContext(CurrentFacilityContext)
  const { pendingOrders, refreshOrders } = usePendingMedicationsInbox({
    orgId: orgId!,
    id: facilityId!,
  })

  const facility =
    currentFacility?.tag === 'Complete' ? currentFacility.value : undefined

  const canAccessInbox = (checkFacility: Facility) =>
    hasPermissionForFacility({
      user,
      facility: { orgId: orgId!, id: facilityId! },
      permissions: [GroupPermission.GROUP_PERMISSION_MEDICATION_UPDATE],
    }) && hasActivePharmacyIntegration(checkFacility)

  if (facility && !canAccessInbox(facility)) {
    return <Redirect to={getFacilityUrl(orgId!, facilityId!)} />
  }

  const userCanAccessFaxInbox = isFeatureAllowed(
    user,
    FeatureFlagNames.FAX_INBOX
  )

  const showFilterButton = false

  const fauxBadges = [
    'Medications',
    'Pending',
    'Last 48 Hours',
    'Sort by room number',
  ]

  const createFilter = () => {
    console.log('filter here')
  }

  return (
    <div className={styles.pageContainer}>
      <div
        className={
          'mb-[24px] ml-[8px] mt-[24px] flex flex-row items-center justify-between text-[24px] font-medium leading-[36px] text-secondary-02'
        }
      >
        Pending Medication Orders
        {showFilterButton && (
          <AsyncIconButton
            buttonStyle={'secondary-outline'}
            initialIcon={'fas fa-bars-filter'}
          >
            Filter
          </AsyncIconButton>
        )}
      </div>

      <div className={styles.badgeContainer}>
        {fauxBadges.map((badge) => (
          <AppliedFilterBadge text={badge} key={badge} />
        ))}

        <AsyncIconButton
          buttonStyle={'primary-fill'}
          initialIcon={'fas fa-save'}
          buttonSize={'small'}
        >
          Save
        </AsyncIconButton>
        <AsyncIconButton
          className={'ml-[8px]'}
          buttonStyle={'secondary-outline'}
          initialIcon={'fas fa-xmark'}
          buttonSize={'small'}
        >
          Clear Filters
        </AsyncIconButton>
      </div>

      <div className={styles.contentContainer}>
        <div className={styles.inboxListContainer}>
          {pendingOrders.tag === 'Loading' ? (
            <>
              {Array(4)
                .fill(undefined)
                .map((_, ix) => (
                  <Skeleton
                    key={`pending-meds-ls-${ix}`}
                    height={'48px'}
                    className={'mb-[8px]'}
                  />
                ))}
            </>
          ) : (
            <>
              {pendingOrders.value.length === 0 && (
                <Card>There are no currently pending medication orders.</Card>
              )}
              {pendingOrders.value?.map((order) => {
                return (
                  <InboxPendingOrder
                    key={
                      order.medicationOrders?.[0].sourceData?.externalMessageId
                    }
                    order={order}
                    history={history}
                    refreshOrders={refreshOrders}
                    facility={{
                      orgId: orgId!,
                      id: facilityId!,
                    }}
                  />
                )
              })}
            </>
          )}

          {userCanAccessFaxInbox && <IncomingFiles />}
        </div>
        <div className={styles.savedTasksCard}>
          <div className={styles.taskTitle}>Saved Tasks</div>
          <div>No tasks saved yet.</div>
          <div>
            <ButtonLink onClick={createFilter}>Create a filter</ButtonLink> to
            get started.
          </div>
        </div>
      </div>
    </div>
  )
}

const AppliedFilterBadge = ({ text }: { text: string }) => {
  return <div className={styles.appliedFilterBadge}>{text}</div>
}
