import {
  GroupPermission,
  GroupType,
} from '@augusthealth/models/com/august/protos/permission'
import { UserAccount } from '@shared/types/user'

/**
 * Returns new user with updated permissions.
 * @function
 * @param {UserAccount} user
 * @param {GroupPermission[]} suppressedPermissions
 * @returns {UserAccount} newUser
 */
export function setNewUserWithUpdatedPermissions({
  user,
  suppressedPermissions,
}: {
  user: UserAccount
  suppressedPermissions: GroupPermission[]
}): UserAccount {
  const currentUserGroup = user.groups![0]

  const newGroup = {
    groupType: GroupType.GROUP_TYPE_SUPER_USER,
    personMatcher: {},
    groupPermissions: currentUserGroup!.groupPermissions!.filter(
      (permission) => !suppressedPermissions.includes(permission)
    ),
  }

  const newUser = { ...user, groups: [newGroup] }
  return newUser
}
