import { AugustInitialAppraisal } from '@augusthealth/models/com/august/protos/august_initial_appraisal'
import { AppraisalSettings_AppraisalCategory as AppraisalCategory } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import {
  getIncompleteCount,
  getUnansweredRequiredTextFields,
} from '@shared/utils/residentAssessment'
import styles from '../styles.module.css'

export default function WarningBanners({
  assessment,
  categories,
}: {
  categories: AppraisalCategory[]
  assessment: AugustInitialAppraisal
}) {
  const incompleteCount =
    (assessment &&
      getIncompleteCount({
        categories,
        assessment,
      })) ||
    0

  const unansweredRequiredTextFields = getUnansweredRequiredTextFields({
    data: { augustInitialAppraisal: assessment },
  })
  const unansweredRequiredCount = unansweredRequiredTextFields.length

  if (incompleteCount === 0 && unansweredRequiredCount === 0) {
    return null
  }

  return (
    <div className={'mb-[40px]'}>
      {incompleteCount > 0 && (
        <div className={styles.incompleteBanner}>
          <i className="fas fa-info-circle mr-[8px]" />
          {incompleteCount === 1
            ? 'One section is'
            : `${incompleteCount} sections are`}{' '}
          incomplete. Please review before finalizing!
        </div>
      )}
      {unansweredRequiredCount > 0 && (
        <div className={styles.incompleteBanner}>
          <i className="fas fa-info-circle mr-[8px]" />
          {unansweredRequiredCount === 1
            ? 'One required question is'
            : `${unansweredRequiredCount} required questions are`}{' '}
          incomplete. Please review before finalizing!
        </div>
      )}
    </div>
  )
}
