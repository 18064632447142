import { ServicePlan_PlanCategoryKey } from '@augusthealth/models/com/august/protos/service_plan'
import CollapsibleCard from '@shared/components/CollapsibleCard'
import {
  CategoryKeyIdentifier,
  ConfigurationUpdate,
  UpdateDetailByPageMap,
} from '@shared/types/assessment_configuration'
import {
  getCategoryKeyTitle,
  getLevelLabel,
} from '@shared/utils/residentAssessment'
import FormSectionTitle from '@app/components/FormSectionTitle'
import styles from './styles.module.css'

type Props = {
  hasPendingChanges: boolean
  pendingUpdates: UpdateDetailByPageMap
}

export const PendingChanges = ({
  hasPendingChanges,
  pendingUpdates,
}: Props) => {
  return (
    <CollapsibleCard className={styles.card} cardTitle={'PENDING CHANGES'}>
      {hasPendingChanges ? (
        <div>
          {Object.keys(pendingUpdates).map((key: CategoryKeyIdentifier) => {
            const changes = pendingUpdates![key]

            const categoryIsBeingDeleted = changes?.[key]?.find(
              (change: any) => change.type === 'deleteCategory'
            )

            let title = categoryIsBeingDeleted
              ? categoryIsBeingDeleted.categoryTitle
              : getCategoryKeyTitle(key as ServicePlan_PlanCategoryKey)

            const titleWasChanged =
              changes?.options &&
              changes.options.some(
                (opt: any) =>
                  opt.type === 'option' && opt.valueChanged === 'category title'
              )

            if (titleWasChanged) {
              title = changes.options.find(
                (opt: any) =>
                  opt.type === 'option' && opt.valueChanged === 'category title'
              )?.updated
            }

            return (
              <div
                key={`${key}-changes`}
                className={styles.changeSectionContainer}
              >
                {Object.values(changes).some(
                  (ch: ConfigurationUpdate[]) => ch.length > 0
                ) && (
                  <>
                    <FormSectionTitle title={title} />
                    {Object.values(changes)
                      .map((changesValue: ConfigurationUpdate[]) => {
                        if (changesValue && changesValue.length) {
                          return changesValue.map((ch, index) => (
                            <div
                              key={`${key}-change-${index}`}
                              className={styles.changeContainer}
                            >
                              <div className={styles.changeSummary}>
                                <div className={styles.changedValueType}>
                                  {ch.valueChanged}
                                </div>
                                <ChangeBreadcrumbs change={ch} />
                              </div>
                              <DescriptionOfChanges change={ch} />
                            </div>
                          ))
                        } else {
                          return null
                        }
                      })
                      .filter((v) => v)}
                  </>
                )}
              </div>
            )
          })}
        </div>
      ) : (
        <div className={styles.noChanges}>No changes have been made</div>
      )}
    </CollapsibleCard>
  )
}

const ChangeBreadcrumbs = ({ change }: { change: ConfigurationUpdate }) => {
  if (change.type === 'level') {
    return (
      <div className={styles.changedValue}>
        <div>{change.group}</div>
        {change.level && <div>Level {getLevelLabel(change.level)}</div>}
      </div>
    )
  }

  if (change.type === 'detail') {
    return (
      <div className={styles.changedValue}>
        <div>{change.group}</div>
        <div>{change.answerGroup}</div>
        {(change.valueChanged === 'score' ||
          change.valueChanged === 'enum' ||
          change.valueChanged === 'isRequired' ||
          change.valueChanged === 'helpText') && <div>{change.descriptor}</div>}
      </div>
    )
  }

  if (change.type === 'newDetail') {
    return (
      <div className={styles.changedValue}>
        <div>{change.group}</div>
        {change.answerGroup && <div>{change.answerGroup}</div>}
      </div>
    )
  }

  if (change.type === 'detailGroup') {
    return (
      <div className={styles.changedValue}>
        <div>{change.group}</div>
      </div>
    )
  }

  if (change.type === 'removeDetail') {
    return (
      <div className={styles.changedValue}>
        <div>{change.group}</div>
        {change.valueChanged === 'removed option' && (
          <div>{change.answerGroup}</div>
        )}
      </div>
    )
  }
  if (change.type === 'removeLevel') {
    return (
      <div className={styles.changedValue}>
        <div>{change.group}</div>
      </div>
    )
  }
  if (change.type === 'newLevel') {
    return (
      <div className={styles.changedValue}>
        {change.level && <div>{getLevelLabel(change.level)}</div>}
      </div>
    )
  }

  return null
}

const DescriptionOfChanges = ({ change }: { change: ConfigurationUpdate }) => {
  if (change.type === 'newDetail') {
    return (
      <>
        <div>
          Description:
          <code className={styles.changedValue}>{change.description}</code>
        </div>
        {change.helpText && (
          <div>
            Help Text:
            <code className={styles.changedValue}>{change.helpText}</code>
          </div>
        )}
        <div>
          Score:
          <code className={styles.changedValue}>{change.score ?? 'N/A'}</code>
        </div>
        {change.isRequired !== undefined && (
          <div>
            Required:
            <code className={styles.changedValue}>
              {change.isRequired?.toString()}
            </code>
          </div>
        )}
      </>
    )
  }
  if (change.type === 'newLevel') {
    return (
      <>
        <div>
          Description:
          <code className={styles.changedValue}>{change.description}</code>
        </div>
        <div>
          Score:
          <code className={styles.changedValue}>{change.score ?? 'N/A'}</code>
        </div>
      </>
    )
  }

  if (change.type === 'removeDetail') {
    return (
      <div>
        Removed:
        <code className={styles.changedValue}>{change.description}</code>
      </div>
    )
  }

  if (change.type === 'removeLevel') {
    return (
      <div>
        Removed:
        <code className={styles.changedValue}>
          {getLevelLabel(change.level!)}
        </code>
      </div>
    )
  }

  if (change.type === 'detailGroup' || change.type === 'deleteCategory') {
    return null
  }

  return (
    <>
      <div>
        From:
        <code className={styles.changedValue}>
          {change.original?.toString() ?? '--'}
        </code>
      </div>
      <div>
        To:
        <code className={styles.changedValue}>
          {change.updated?.toString() ?? '--'}
        </code>
      </div>
    </>
  )
}
