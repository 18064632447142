import { HTMLProps } from 'react'
import Badge, { Props as BadgeProps } from '@shared/components/Badge'
import { tw, twx } from '@shared/utils/tailwind'

export type BadgeInputProps = {
  badgeProps: BadgeProps
  inputProps: HTMLProps<HTMLInputElement>
}

function BadgeInput({ badgeProps, inputProps }: BadgeInputProps) {
  const { className: badgeClassName, ...restBadgeProps } = badgeProps
  const { className: inputClassName, ...restInputProps } = inputProps

  return (
    <label className={tw`mb-0`}>
      <Badge
        className={twx('mr-[12px] cursor-pointer border', badgeClassName)}
        {...restBadgeProps}
      />
      <input
        className={twx('hidden', inputClassName)}
        type="radio"
        {...restInputProps}
      />
    </label>
  )
}

export default function BadgeFilter({
  name,
  badgeInputs,
}: {
  name: string
  badgeInputs: BadgeInputProps[]
}) {
  return (
    <div>
      {badgeInputs.map((props, index) => {
        return <BadgeInput key={`badge-input-${name}-${index}`} {...props} />
      })}
    </div>
  )
}
