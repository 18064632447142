import { useState } from 'react'
import { FieldPath, useFormContext } from 'react-hook-form'
import {
  MedOrderFormData,
  SlidingScaleEntryWithId,
} from '@shared/types/medication_order'
import { twx } from '@shared/utils/tailwind'
import { SlidingScaleDoseEntry } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/helpers'
import { ScaleInputWithErrorTooltip } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/SlidingScaleSchedule/SlidingScaleEntryRow/ScaleInputWithErrorTooltip'
import styles from '../styles.module.css'

export const ScaleEntryBoundsInput = ({
  scalePrefix,
  scaleEntry,
  entryIndex,
  doseIndex,
  isFinalEntry,
  isFirstEntry,
  readOnly,
  showRangeError,
}: {
  scalePrefix: SlidingScaleDoseEntry
  scaleEntry: SlidingScaleEntryWithId
  isFinalEntry: boolean
  isFirstEntry: boolean
  entryIndex: number
  doseIndex: number
  readOnly: boolean
  showRangeError: boolean
}) => {
  const allEntriesPrefix = `doses.${doseIndex}.slidingScale.entries` as const
  const [tooltipError, setTooltipError] = useState<null | 'lower' | 'upper'>(
    null
  )
  const methods = useFormContext<MedOrderFormData>()
  const { formState, getValues, setValue, clearErrors, trigger } = methods

  const inputContainerClasses = twx(styles.scaleInputContainer, {
    [styles.readOnly]: readOnly,
  })

  const prevRangeUpper =
    getValues(`${allEntriesPrefix}.${entryIndex - 1}.bounds.high.value`) ??
    undefined
  const lowerBound = getValues(`${scalePrefix}.bounds.low.value`) ?? undefined

  const validLowerBound = (value?: number) => {
    if (value === undefined || isNaN(value)) {
      setTooltipError('lower')
      return 'missing value(s)'
    }

    if (!isFirstEntry && value === 0) {
      setTooltipError('lower')
      return 'must be greater than 0'
    }

    if (!isFirstEntry && prevRangeUpper && value - 1 !== prevRangeUpper) {
      setTooltipError('lower')
      return 'invalid value(s)'
    }

    setTooltipError(null)
    return true
  }
  const validUpperBound = (value?: number) => {
    if (value === undefined || isNaN(value)) {
      setTooltipError('upper')
      return 'missing value(s)'
    }

    if ((lowerBound && value <= lowerBound) || value === 0) {
      setTooltipError('upper')
      return 'invalid value(s)'
    }

    setTooltipError(null)
    return true
  }
  const isValidUpperBound = (value: number) => {
    return validUpperBound(value) === true
  }

  const updateValues = (
    name: FieldPath<MedOrderFormData>,
    boundType: 'lower' | 'upper',
    value?: number
  ) => {
    setValue(name, value)

    if (boundType === 'upper' && value && isValidUpperBound(value)) {
      const nextEntryLower: FieldPath<MedOrderFormData> = `${allEntriesPrefix}.${
        entryIndex + 1
      }.bounds.low.value`
      setValue(nextEntryLower, value + 1)
      void trigger(nextEntryLower)
    }
  }

  const triggerRelevantValidation = async (
    name: FieldPath<MedOrderFormData>,
    value?: string
  ) => {
    if (formState.isSubmitted) {
      clearErrors()
      await trigger()
    } else {
      clearErrors(name)
      if (value) {
        await trigger(name)
      }
    }
  }

  return (
    <div className={inputContainerClasses}>
      {isFinalEntry && <span>above</span>}
      <ScaleInputWithErrorTooltip
        min={prevRangeUpper ? prevRangeUpper + 1 : undefined}
        max={prevRangeUpper ? prevRangeUpper + 1 : undefined}
        validate={{
          validBounds: validLowerBound,
        }}
        onBlur={(ev: React.ChangeEvent<HTMLInputElement>) => {
          const value = ev.target.value
          const name: FieldPath<MedOrderFormData> = `${scalePrefix}.bounds.low.value`
          updateValues(name, 'lower', value ? parseInt(value) : undefined)
          void triggerRelevantValidation(`${scalePrefix}.bounds`, value)
        }}
        name={`${scalePrefix}.bounds.low.value`}
        readOnly={readOnly}
        showRangeError={showRangeError && tooltipError === 'lower'}
        formError={
          formState.errors?.doses?.[doseIndex]?.slidingScale?.entries?.[
            entryIndex
          ]?.bounds?.low?.value
        }
      />
      {isFinalEntry ? (
        <span className={styles.rangeUnitLabel}>
          {scaleEntry?.bounds?.low?.unit}
        </span>
      ) : (
        <>
          <span>to</span>
          <ScaleInputWithErrorTooltip
            min={lowerBound ? lowerBound + 1 : 1}
            validate={{
              validBounds: validUpperBound,
            }}
            onBlur={(ev: React.ChangeEvent<HTMLInputElement>) => {
              const value = ev.target.value
              const name: FieldPath<MedOrderFormData> = `${scalePrefix}.bounds.high.value`
              updateValues(name, 'upper', value ? parseInt(value) : undefined)
              void triggerRelevantValidation(`${scalePrefix}.bounds`, value)
            }}
            name={`${scalePrefix}.bounds.high.value`}
            readOnly={readOnly}
            showRangeError={showRangeError && tooltipError === 'upper'}
            formError={
              formState.errors?.doses?.[doseIndex]?.slidingScale?.entries?.[
                entryIndex
              ]?.bounds?.high?.value
            }
          />
          <span className={styles.rangeUnitLabel}>
            {scaleEntry?.bounds?.high?.unit}
          </span>
        </>
      )}
    </div>
  )
}
