import { DropzoneOptions, FileError, FileRejection } from 'react-dropzone'
import { InternalErrorCode } from '@shared/utils/error'

// https://convertlive.com/u/convert/megabytes/to/bytes#10
export const MAX_UPLOAD_SIZE = 31457280 // 30 mb

export const dropzoneSizeOptions: Pick<DropzoneOptions, 'minSize' | 'maxSize'> =
  {
    minSize: 1,
    maxSize: MAX_UPLOAD_SIZE, // 30 MB
  }

const imageAcceptOption: Pick<DropzoneOptions, 'accept'> = {
  accept: { 'image/*': ['.gif', '.jpeg', '.jpg', '.png'] },
}

const pdfAcceptOption: Pick<DropzoneOptions, 'accept'> = {
  accept: { 'application/pdf': ['.pdf'] },
}

const pdfAndImageAcceptOption: Pick<DropzoneOptions, 'accept'> = {
  accept: {
    ...imageAcceptOption.accept,
    ...pdfAcceptOption.accept,
  },
}

export const imageOnlyDropzoneOptions = {
  ...dropzoneSizeOptions,
  ...imageAcceptOption,
}

export const pdfAndImageDropzoneOptions = {
  ...dropzoneSizeOptions,
  ...pdfAndImageAcceptOption,
}

const INTERNAL_ERROR_CODES = Object.values(InternalErrorCode)

export function includeInternalErrorCode(error: FileError) {
  return (INTERNAL_ERROR_CODES as string[]).includes(error.code)
}

export function getInternalErrorCode({
  fileRejections,
}: {
  fileRejections: FileRejection[]
}) {
  const file = fileRejections.find((file) =>
    file.errors.find(includeInternalErrorCode)
  )
  const error = file?.errors.find(includeInternalErrorCode)

  return error?.code
}
