export const LOADING = { tag: 'Loading' } as const
export type Complete<T> = { tag: 'Complete'; value: T }
export type Loading<T> = typeof LOADING | Complete<T>
export type Error<E> = { tag: 'Error'; value: E }
export type Result<T, E> = Complete<T> | Error<E>
export type AsyncResult<T, E> = typeof LOADING | Result<T, E>
export type LoadingWithPriorValue<T> =
  | Complete<T>
  | typeof LOADING
  | { tag: 'LoadingWithPriorValue'; value: T }

export const match =
  <T, E, U>({
    onLoading,
    onError,
    onComplete,
  }: {
    onLoading: () => U
    onError: (error: E) => U
    onComplete: (data: T) => U
  }) =>
  (asyncResult: AsyncResult<T, E>) => {
    switch (asyncResult.tag) {
      case 'Loading':
        return onLoading()
      case 'Error':
        return onError(asyncResult.value)
      case 'Complete':
        return onComplete(asyncResult.value)
    }
  }

/**
 * Map a Loading<T> value into a Loading<V> value
 * @function
 * @param boxed
 * @param f
 * @return Loading<V>
 */
export function mapLoading<T, V>(
  boxed: Loading<T>,
  f: (v: T) => V
): Loading<V> {
  if (boxed.tag === 'Loading') {
    return { tag: 'Loading' }
  }

  return { tag: 'Complete', value: f(boxed.value) }
}

export const mapLoadingCurried =
  <T, V>(f: (v: T) => V) =>
  (boxed: Loading<T>): Loading<V> => {
    return mapLoading(boxed, f)
  }

/**
 * Map an AsyncResult<T, E> value into a AsyncResult<V, E> value
 * @function
 * @param boxed
 * @param f
 * @return AsyncResult<V, E>
 */
export function mapAsyncResult<T, V, E>(
  boxed: AsyncResult<T, E>,
  f: (v: T) => V
): AsyncResult<V, E> {
  if (boxed.tag !== 'Complete') {
    return boxed
  }

  return { tag: 'Complete', value: f(boxed.value) }
}

/**
 * Get the complete value from an AsyncResult<T, E> or Loading<T> or a provided default
 * @function
 * @param boxed
 * @param defaultValue
 * @return T
 */
export function getOrElse<T, E>(
  boxed: AsyncResult<T, E> | Loading<T>,
  defaultValue: T
) {
  return boxed.tag === 'Complete' ? boxed.value : defaultValue
}

export const getOrElseCurried =
  <T, E>(defaultValue: T) =>
  (boxed: AsyncResult<T, E> | Loading<T>) => {
    return getOrElse(boxed, defaultValue)
  }

/**
 * Find a value matching the provided id from a Loading<T[]> or AsyncResult<T,E>
 * @param boxedList
 * @param id
 */
export function getById<T extends { id: string }, E>(
  boxedList: Loading<T[]> | AsyncResult<T[], E>,
  id: string
): T | undefined {
  return getOrElse(boxedList, []).find((item) => item.id === id)
}

export function loaded<T>(value: T): Complete<T> {
  return { tag: 'Complete', value }
}

export function error<T>(value: T): Error<T> {
  return { tag: 'Error', value }
}

export function loading(): typeof LOADING {
  return LOADING
}

export function isLoading<T, E>(
  result: AsyncResult<T, E>
): result is typeof LOADING {
  return result.tag === 'Loading'
}

export function isComplete<T, E>(
  result: AsyncResult<T, E>
): result is Complete<T> {
  return result.tag === 'Complete'
}

export function isError<T, E>(result: AsyncResult<T, E>): result is Error<E> {
  return result.tag === 'Error'
}

export function loadingFromNullable<T>(
  value: T | undefined | null
): Loading<T> {
  if (value) {
    return loaded(value)
  } else {
    return LOADING
  }
}
