import { useContext, useEffect, useState } from 'react'
import { getPharmacyIntegrations } from '@shared/api/pharmacy_integration'
import GlobalContext from '@shared/contexts/GlobalContext'
import { PharmacyFacility } from '@shared/types/pharmacy_facility'
import { loaded, LOADING, Loading } from '@shared/utils/loading'

export const usePharmacyIntegrations = () => {
  const { setError } = useContext(GlobalContext)
  const [allIntegrations, setAllIntegrations] =
    useState<Loading<PharmacyFacility[]>>(LOADING)
  const [editingIntegration, setEditingIntegration] =
    useState<PharmacyFacility>()

  const listAllPharmacyIntegrations = async (
    currentlyEditingExternalId?: string
  ) => {
    setAllIntegrations(LOADING)
    try {
      const response = await getPharmacyIntegrations()

      setAllIntegrations(loaded(response))

      if (currentlyEditingExternalId) {
        setEditingIntegration(
          response.find(
            (integration) =>
              integration.externalIdentifier === currentlyEditingExternalId
          )
        )
      }
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    void listAllPharmacyIntegrations()
  }, [])

  return {
    allIntegrations,
    setAllIntegrations,
    editingIntegration,
    setEditingIntegration,
    listAllPharmacyIntegrations,
  }
}
