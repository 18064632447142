import { useState } from 'react'
import { tw } from '@shared/utils/tailwind'
import ConfirmModal from '@app/components/ConfirmModal'

type Props = {
  closeFn: () => void
  doneFn: () => Promise<void>
  facilityId: string
  orgId: string
  statementId: string
}

export default function IssueStatementsModal(props: Props) {
  const { doneFn, closeFn } = props
  const [isSubmitting, setIsSubmitting] = useState(false)

  async function onSubmit() {
    setIsSubmitting(true)
    await doneFn()
    setIsSubmitting(false)
    closeFn()
  }

  return (
    <ConfirmModal
      title="Issue Statements?"
      denyButtonConfig={{
        onClick: closeFn,
        disabled: isSubmitting,
        isLoading: isSubmitting,
        children: 'Cancel',
      }}
      confirmButtonConfig={{
        onClick: onSubmit,
        disabled: isSubmitting,
        isLoading: isSubmitting,
        buttonStyle: 'primary-fill',
        type: 'submit',
      }}
      content={
        <form onSubmit={onSubmit}>
          <div className={tw`text-[14px] font-medium`}>
            This resident has open billing updates. Are you sure you would like
            to proceed?
          </div>
        </form>
      }
      testId="issue-individual-statement"
    />
  )
}
