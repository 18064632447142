import { tw } from '@shared/utils/tailwind'

export default function LogoHeader({
  title,
  copy,
}: {
  title: string
  copy?: string
}) {
  return (
    <div className={tw`flex flex-col items-center`}>
      <div className={tw`flex items-center`}>
        <img src="/svg/rebrand_logotype.svg" alt="August Health logo copy" />
      </div>

      <div
        className={tw`mt-[16px] font-inter text-[20px] font-semibold leading-[24px]`}
      >
        {title}
      </div>
      {copy && (
        <div className={tw`text-center font-medium text-gray-07`}>{copy}</div>
      )}
    </div>
  )
}
