import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import localForage from 'localforage'
import { useContext, useEffect, useState } from 'react'
import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import {
  hasPermissionForFacility,
  PersonPermissionGate,
} from '@shared/components/PermissionGates/PermissionGates'
import { useUserContext } from '@shared/contexts/UserContext'
import { documentsPathForPerson } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { Task } from '@shared/types/task'
import { UserAccount } from '@shared/types/user'
import { getResponsiblePerson, isResident } from '@shared/utils/person'
import { tw } from '@shared/utils/tailwind'
import CompletedTaskCongratsModal from '@app/components/CongratsModal/CompletedTaskVisitDocuments'
import PersonPageTitle from '@app/components/PersonPageTitle'
import PromptToShareWithRP from '@app/components/PromptToShareWithRP'
import Groups from '@app/components/Prospects/Tasks/Groups'
import ReminderConfirm from '@app/components/Prospects/Tasks/ReminderConfirm'
import { Props } from '@app/components/Prospects/Tasks/type'
import CompletionStats from '@app/components/Residents/CompletionStats'
import ShareWithRP from '@app/components/ShareWithRP'
import SortByDropdown from '@app/components/SortByDropdown'
import {
  Option,
  TASK_SORT_OPTIONS,
  TaskSortOrder,
} from '@app/components/SortByDropdown/helpers'
import PersonContext from '@app/contexts/PersonContext'
import ProspectsContext from '@app/contexts/ProspectsContext'
import { useTasks } from '@app/hooks/useTasks'

const SORT_TASKS_STORAGE_NAME = 'sortTasksOrder' as const

export default function Tasks(props: Props) {
  const { match, history, uploadPopupTask } = props
  const { openShareWithRPModal, setOpenShareWithRPModal } =
    useContext(ProspectsContext)
  const { person } = useContext(PersonContext)
  const { tasks, refreshTasks } = useTasks()
  const { user } = useUserContext()
  const [openCollabPopup, setOpenCollabPopup] = useState<boolean>(false)
  const [openCongrats, setOpenCongrats] = useState(false)
  const [showUploadPopup, setShowUploadPopup] = useState<Task | undefined>(
    uploadPopupTask
  )
  const [taskSortOrder, setTaskSortOrder] = useState<TaskSortOrder>(
    TaskSortOrder.TASK_SORT_BY_NAME_ASC
  )
  const { facilityId, id: pId, orgId } = match.params

  const openCongratsAndReloadTasks = async (showOpenCongrats: boolean) => {
    await refreshTasks()
    if (showOpenCongrats) {
      setOpenCongrats(true)
    }
  }

  useEffect(() => {
    void localForage.getItem(SORT_TASKS_STORAGE_NAME).then((sortOrder) => {
      if (sortOrder) {
        setTaskSortOrder(sortOrder as TaskSortOrder)
      }
    })
  }, [])

  if (!person || !tasks) {
    return null
  }

  let congratsModal
  if (openCongrats) {
    congratsModal = (
      <CompletedTaskCongratsModal
        redirectToTasksPage={() => setOpenCongrats(false)}
        redirectToDocumentsPage={() => {
          setOpenCongrats(false)
          history.push(documentsPathForPerson(person as Required<Person>))
        }}
      />
    )
  }

  return (
    <>
      <PersonPageTitle title={'Your Tasks'}>
        <div className={tw`flex`}>
          <div>
            <ShareWithRPButton
              user={user}
              person={person}
              onClick={() => setOpenCollabPopup(true)}
            />
          </div>
          <div>
            <ReminderConfirm
              orgId={orgId}
              facilityId={facilityId}
              personId={pId}
              tasks={tasks}
            />
          </div>
          <div className={tw`ml-[8px]`}>
            <SortByDropdown
              options={TASK_SORT_OPTIONS}
              value={
                TASK_SORT_OPTIONS.find((opt) => opt.value === taskSortOrder) ||
                null
              }
              onChange={(opt: Option | null) => {
                const selectedValue = opt?.value
                  ? opt.value
                  : TaskSortOrder.TASK_SORT_BY_NAME_ASC
                setTaskSortOrder(selectedValue as TaskSortOrder)
                void localForage.setItem(SORT_TASKS_STORAGE_NAME, selectedValue)
              }}
            />
          </div>
        </div>
      </PersonPageTitle>
      {isResident(person) && (
        <PersonPermissionGate
          person={person}
          permissions={[GroupPermission.GROUP_PERMISSION_TASK_CREATE]}
        >
          <CompletionStats
            person={person}
            tasks={tasks}
            refreshTasks={refreshTasks}
          />
        </PersonPermissionGate>
      )}
      <Groups
        {...props}
        onUploadDocument={openCongratsAndReloadTasks}
        uploadPopupTask={showUploadPopup}
        setOpenUploadPopup={setShowUploadPopup}
        refreshTasks={refreshTasks}
        taskSortOrder={taskSortOrder}
      />
      {openCollabPopup && (
        <ShareWithRP
          onComplete={async (shared) => {
            if (shared) {
              await refreshTasks()
            }

            setOpenCollabPopup(false)
          }}
        />
      )}
      {congratsModal}
      {openShareWithRPModal && (
        <PromptToShareWithRP
          setOpenShareWithRPModal={setOpenShareWithRPModal}
          setOpenCollabModal={setOpenCollabPopup}
        />
      )}
    </>
  )
}

function ShareWithRPButton({
  user,
  person,
  onClick,
}: {
  user: UserAccount
  person: Person
  onClick: () => void
}) {
  const { orgId, facilityId } = person
  const responsiblePerson = getResponsiblePerson(person)

  if (
    responsiblePerson &&
    hasPermissionForFacility({
      user,
      facility: { orgId, id: facilityId },
      permissions: [GroupPermission.GROUP_PERMISSION_TASK_CREATE],
    })
  ) {
    return (
      <Button buttonStyle="primary-fill" id="share-with-rp" onClick={onClick}>
        <span className="icon-before">
          <label className="visually-hidden">
            Share with Responsible Person
          </label>
          <i className="fas fa-user-edit" />
        </span>
        Share with Responsible Person
      </Button>
    )
  } else {
    return null
  }
}
