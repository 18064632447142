import { formatInTimeZone } from 'date-fns-tz'
import { Fragment, useContext, useEffect, useState } from 'react'
import CollapsibleCard from '@shared/components/CollapsibleCard'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Diff, MedicationHistoryEntry } from '@shared/types/medication_order'
import { Person } from '@shared/types/person'
import { getFullName } from '@shared/utils/humanName'
import { Loading } from '@shared/utils/loading'
import { tw, twx } from '@shared/utils/tailwind'
import { getMedicationOrderHistory } from '@app/api/medicationOrders'

type Props = {
  person: Person
  orderGroupId: string
  facilityTimeZone: string | undefined
  use24HourClock: boolean
}
export default function OrderHistory({
  person,
  orderGroupId,
  facilityTimeZone,
  use24HourClock,
}: Props) {
  const [orderHistory, setOrderHistory] = useState<
    Loading<MedicationHistoryEntry[]>
  >({ tag: 'Loading' })

  const { setError } = useContext(GlobalContext)

  useEffect(() => {
    if (person.id && person.facilityId && person.orgId)
      setOrderHistory({ tag: 'Loading' })
    getMedicationOrderHistory({ person, orderGroupId })
      .then((value) => setOrderHistory({ tag: 'Complete', value }))
      .catch(setError)
  }, [person, orderGroupId])

  if (orderHistory.tag === 'Loading' || !facilityTimeZone) {
    return null
  } else {
    return (
      <OrderHistoryContents
        orderHistory={orderHistory.value}
        facilityTimeZone={facilityTimeZone}
        use24HourClock={use24HourClock}
      />
    )
  }
}

function OrderHistoryContents({
  orderHistory,
  facilityTimeZone,
  use24HourClock,
}: {
  orderHistory: MedicationHistoryEntry[]
  facilityTimeZone: string
  use24HourClock: boolean
}) {
  const dateFormat = `${use24HourClock ? 'HH:mm' : 'hh:mm aa'} 'on' MMM dd, yyyy`

  return (
    <CollapsibleCard
      cardTitle="Order History"
      defaultExpanded={false}
      expandedIcon="fa-minus"
      collapsedIcon="fa-plus"
    >
      {orderHistory.map((historyRow, index) => (
        <div
          key={index}
          className={twx(
            'mb-2 mt-2 flex flex-row items-start gap-4 border-b border-b-gray-11 py-4 first:mt-0 last:border-b-0'
          )}
        >
          <div
            className={twx(
              'flex w-[200px] shrink-0 flex-col gap-1 text-[14px] leading-[20px] text-gray-04'
            )}
          >
            <span className={twx('font-semibold')}>
              {formatInTimeZone(
                historyRow.modification.modificationTime,
                facilityTimeZone,
                dateFormat
              )}
            </span>

            <span className={twx('whitespace-nowrap font-medium text-gray-07')}>
              {historyRow.modification.modifiedByUserName ? (
                <>
                  Approved by
                  <span className={twx('block whitespace-nowrap')}>
                    {getFullName(historyRow.modification.modifiedByUserName)}
                  </span>
                </>
              ) : (
                'Auto-updated'
              )}
            </span>
          </div>
          <HistoryDiff orderDiffs={historyRow} />
        </div>
      ))}
    </CollapsibleCard>
  )
}

type DiffProps = {
  orderDiffs: MedicationHistoryEntry
}
export const HistoryDiff = (props: DiffProps) => {
  const { orderDiffs } = props

  return (
    <div>
      {orderDiffs.diffs.map((diff) => {
        return <LineDiff diff={diff} key={diff.key} />
      })}
      {orderDiffs.dosageDiffs.map((diff, index) => {
        return (
          <Fragment key={index}>
            {diff.map((d) => {
              return <LineDiff diff={d} key={d.key} />
            })}
          </Fragment>
        )
      })}
    </div>
  )
}

const LineDiff = ({ diff }: { diff: Diff }) => {
  const lineClasses = tw`mb-1 flex flex flex-row flex-row text-[14px] font-medium leading-tight`
  const valueClasses = tw`uppercase text-gray-04`
  const labelClasses = tw`mr-2 inline-block w-[40px] shrink-0 font-medium normal-case text-gray-07 no-underline`

  return (
    <div key={diff.key} className={twx('mb-3')}>
      <div className={lineClasses}>
        <span className={labelClasses}>New:</span>
        <span className={valueClasses}>{diff.updatedValue}</span>
      </div>
      <div className={lineClasses}>
        <span className={labelClasses}>Old:</span>
        <span className={`${valueClasses} text-gray-07 line-through`}>
          {diff.originalValue || '-'}
        </span>
      </div>
    </div>
  )
}
