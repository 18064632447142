import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import ErrorMonitoring from '@shared/ErrorMonitoring'
import { MedicationOrder } from '@shared/types/medication_order'
import { tw } from '@shared/utils/tailwind'
import { PossibleMatchCard } from '@app/components/Residents/Medications/Orders/WeakMatch/WeakMatchModal/PossibleMatches/PossibleMatchCard'

type Props = {
  order: MedicationOrder
  goBack: () => void
  allOrders: MedicationOrder[]
}
export const ReviewPossibleMatches = ({ order, goBack, allOrders }: Props) => {
  const history = useHistory()
  const [selectedOrderGroupId, setSelectedOrderGroupId] = useState<
    string | null
  >(null)

  const confirmReplacement = () => {
    history.push(
      `pharmacy-messages/${order.sourceData?.messageId}/order-groups/${selectedOrderGroupId}/review`
    )
  }

  const handleSelection = (orderGroupId: string) => {
    setSelectedOrderGroupId(
      selectedOrderGroupId === orderGroupId ? null : orderGroupId
    )
  }

  return (
    <>
      <div
        className={tw`mb-4 mt-6 text-[14px] font-semibold leading-[20px] text-secondary-04`}
      >
        Select the order you want to replace
      </div>

      <div className={tw`flex max-h-[600px] flex-col gap-2 overflow-y-scroll`}>
        {order.sourceData!.possibleMatches!.map((match) => {
          const matchingMed = allOrders.find(
            (med) => med.orderGroupId === match.orderGroupId
          )

          if (matchingMed) {
            return (
              <PossibleMatchCard
                order={matchingMed as MedicationOrder}
                key={match.orderGroupId}
                selected={selectedOrderGroupId === match.orderGroupId}
                onClick={() => handleSelection(match.orderGroupId)}
              />
            )
          }

          ErrorMonitoring.capture({
            error: `No medication order found matching the possible match orderGroupId ${match.orderGroupId}`,
            level: 'warning',
          })

          return null
        })}
      </div>
      <div className={`mx-auto mt-6 flex flex-row justify-between gap-2`}>
        <AsyncIconButton
          buttonStyle={'secondary-outline'}
          width={'fit-content'}
          onClick={goBack}
          initialIcon={'fa-fw fa-chevron-left'}
        >
          Back
        </AsyncIconButton>
        <AsyncIconButton
          buttonStyle={'primary-fill'}
          onClick={confirmReplacement}
          width={'100%'}
          disabled={!selectedOrderGroupId}
          initialIcon={'fa-fw fa-chevron-right'}
          initialIconPosition={'right'}
        >
          Replace Selected Order
        </AsyncIconButton>
      </div>
    </>
  )
}
