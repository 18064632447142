import { CustomFacilityField } from '@augusthealth/models/com/august/protos/facility'
import { FormField_FacilityCustomField } from '@augusthealth/models/com/august/protos/signable_form'
import { isEmpty, isEqual } from 'lodash'
import { mergePatchFacility } from '@shared/api/facility'
import { fetchBlobUrlAndContentType } from '@shared/api/request'
import { apiOrganizationUrl } from '@shared/legacy_routes'
import { Facility } from '@shared/types/facility'

export type KeyType = `facility-${string}-key-${string}`

export function makeKey({
  facility,
  field,
}: {
  facility: Facility
  field: FormField_FacilityCustomField
}): KeyType {
  return `facility-${facility.id}-key-${field.key}`
}

export function splitKey(key: KeyType) {
  const splitKey = key.split('-')
  const facilityId = splitKey[1]
  const fieldKey = splitKey[3]

  return {
    facilityId,
    fieldKey,
  }
}

export function saveFields({
  fieldValues,
  facilities,
}: {
  fieldValues: Map<KeyType, CustomFacilityField>
  facilities: Facility[]
}) {
  const patchMap = new Map<
    string,
    { keyName: string; value: CustomFacilityField }[]
  >()

  fieldValues.forEach((value, key) => {
    const splitKey = key.split('-')
    const facilityId = splitKey[1]
    const fieldKey = splitKey[3]

    const existingValues = patchMap.get(facilityId) ?? []

    patchMap.set(facilityId, [...existingValues, { keyName: fieldKey, value }])
  })

  const promises: Promise<any>[] = []

  patchMap.forEach((value, facilityId) => {
    const facility = facilities.find((f) => f.id === facilityId)
    if (facility === undefined) {
      return
    }

    const existingCustomFields = existingCustomTextFields(facility)

    const patch: {
      customFields: { [key: string]: CustomFacilityField }
    } = {
      customFields: {
        ...value.reduce((acc, { keyName, value }) => {
          if (value.image) {
            return acc
          }

          const textValue = value.text ?? ''

          const newValue =
            textValue.trim().length === 0 ? null : textValue.trim()
          return {
            ...acc,
            [keyName]:
              newValue === null
                ? null
                : {
                    text: newValue,
                  },
          }
        }, {}),
      },
    }

    const hasNotChanged = isEqual(existingCustomFields, patch.customFields)
    const remainsEmpty =
      isEmpty(existingCustomFields) &&
      Object.values(patch.customFields).every((v) => v === null)

    if (hasNotChanged || remainsEmpty) {
      return
    } else {
      promises.push(
        mergePatchFacility({
          facility,
          patch,
        })
      )
    }
  })

  return Promise.all(promises)
}

function existingCustomTextFields(f: Facility) {
  const allFields = f.customFields ?? {}

  return Object.fromEntries(Object.entries(allFields).filter((v) => v[1].text))
}

export type Extension = '.csv' | '.xlsx'

export async function downloadFacilityFields({
  orgId,
  extension,
  setDownloading,
}: {
  orgId: string
  extension: Extension
  setDownloading: (ext: Extension | undefined) => void
}) {
  const url = `${apiOrganizationUrl(orgId)}/facilityFields${extension}`

  setDownloading(extension)
  const blobData = await fetchBlobUrlAndContentType({ url })
  window.open(blobData.url, '_archived_doc', 'noreferrer')
  setDownloading(undefined)
}
