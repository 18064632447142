import classNames from 'classnames'
import { FieldErrors } from 'react-hook-form'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import { EmailInput } from '@shared/components/TextInputWithIcon/TextInputWithIcon'
import { UserAccount } from '@shared/types/user'
import { EMAIL_REGX } from '@shared/utils/regex'

export default function EmailInputWithValidation({
  required,
  disabled = false,
  errors,
  name = 'email',
  register,
  holderClassName,
  ctaProps,
}: {
  required?: boolean
  disabled?: boolean
  errors: FieldErrors<UserAccount>
  name?: string
  register: any
  holderClassName?: string
  ctaProps?: {
    onClick?: () => void
    text?: string
  }
}) {
  const error = errors[name]
  const holderClasses = classNames('mb-[32px]', holderClassName)
  return (
    <div className={holderClasses}>
      <div className="flex">
        <LabelAboveInput
          htmlFor={name}
          subLabel={required ? requiredLabel(Boolean(error)) : undefined}
          errorMessage={error?.message || 'Required'}
          ctaProps={ctaProps}
        >
          Email
        </LabelAboveInput>
      </div>
      <EmailInput
        inputProps={{
          id: name,
          disabled,
          ...register(name, {
            required,
            validate: (value: string) => {
              if (!required || EMAIL_REGX.test(value)) {
                return true
              }

              return 'Invalid format'
            },
          }),
        }}
      />
    </div>
  )
}
