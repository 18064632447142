export enum RequiredUserActionType {
  NO_ACTION = 'NO_ACTION',
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  ACCOUNT_LOCKED_TRY_AGAIN_LATER = 'ACCOUNT_LOCKED_TRY_AGAIN_LATER',
  SETUP_MFA = 'SETUP_MFA',
  SMS_MFA = 'SMS_MFA',
  EMAIL_OTP = 'EMAIL_OTP',
}

export type CreateSessionResponse = {
  action: RequiredUserActionType
  username?: string
  mfaChallenge?: MfaChallenge
}

export enum MfaMethod {
  UNKNOWN = 'UNKNOWN',
  SMS = 'SMS',
  EMAIL = 'EMAIL',
}

export type MfaCredentials = {
  username: string
  password: string
}

export type MfaConfiguration = {
  value: string
  credentials: MfaCredentials
  method: MfaMethod
}

export type MfaChallenge = {
  username: string
  method: MfaMethod
  challengeSessionId: string
  maskedCodeDeliveryDestination?: string
}

export type MfaCodeVerification = {
  code: string
  challengeSessionId: string
  method: MfaMethod
}
