import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { DataType, Snapshot } from '@shared/types/snapshot'
import { Loading } from '@shared/utils/loading'
import { fetchSnapshot, fetchSnapshotById } from '../api/snapshot'
import PersonContext from '../contexts/PersonContext'

export default function useSnapshot({
  dataType,
  createOnFetch = false,
}: {
  dataType: DataType
  createOnFetch?: boolean
}) {
  const { setError } = useContext(GlobalContext)
  const { person } = useContext(PersonContext)

  const [snapshot, setSnapshot] = useState<Loading<Snapshot>>({
    tag: 'Loading',
  })

  const refreshSnapshot = async (initialFetch = false) => {
    if (person === undefined) {
      return
    }

    fetchSnapshot({
      personId: person.id!,
      orgId: person.orgId!,
      dataType: dataType,
      create: initialFetch ? createOnFetch : false,
    })
      .then((response) => {
        setSnapshot({ tag: 'Complete', value: response })
      })
      .catch(setError)
  }

  useEffect(() => {
    void refreshSnapshot(true)
  }, [])

  return { snapshot, refreshSnapshot }
}

export function useSnapshotById({
  personId,
  orgId,
  snapshotId,
}: {
  personId: string
  orgId: string
  snapshotId: string
}) {
  const { setError } = useContext(GlobalContext)

  const [snapshot, setSnapshot] = useState<Loading<Snapshot>>({
    tag: 'Loading',
  })

  useEffect(() => {
    setSnapshot({ tag: 'Loading' })

    fetchSnapshotById({
      personId,
      orgId,
      snapshotId,
    })
      .then((response) => {
        setSnapshot({ tag: 'Complete', value: response })
      })
      .catch(setError)
  }, [])

  return snapshot
}
