import { CreateRoomRequest } from '@augusthealth/models/com/august/protos/api/occupancy'
import { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import Button from '@shared/components/Button'
import GlobalContext from '@shared/contexts/GlobalContext'
import { bulkCreateRooms } from '@app/api/rooms'
import { Table, TableContainer } from '@app/components/Table'
import TableHeader from '@app/components/Table/TableHeader'
import RoomCapacitySelect, { CAPACITY_OPTIONS } from '../RoomCapacitySelect'
import { PopupProps, SettingsData } from './types'

type Props = PopupProps & {
  data: SettingsData
}

function getDeaultCreateRoomData(settingsData: SettingsData) {
  const { numberOfRooms, startRoomNumber, defaultCapacity } = settingsData
  const returnValues: CreateRoomRequest[] = []
  for (let i = 0; i < numberOfRooms; i++) {
    returnValues.push({
      name: (startRoomNumber + i).toString(),
      capacity: defaultCapacity,
    })
  }

  return returnValues
}

export default function Preview(props: Props) {
  const { setError } = useContext(GlobalContext)
  const { data, facilityId, orgId, onClose, onSave } = props
  const list = getDeaultCreateRoomData(data)
  const { control, handleSubmit } = useForm<{ list: CreateRoomRequest[] }>({
    defaultValues: { list },
  })

  const onSubmit = async ({
    list: roomList,
  }: {
    list: CreateRoomRequest[]
  }) => {
    // Convert { 0: Object, 1: Object } to [Object, Object]
    const rooms = Object.values(roomList)
    bulkCreateRooms({ orgId, id: facilityId }, rooms)
      .then(onSave)
      .catch(setError)
  }

  const trs = list.map((_v, i) => {
    return (
      <tr key={`preview-row-${i}`}>
        <td className="p-[8px] pb-0 pr-0 pt-[8px]">
          <Controller
            control={control}
            name={`list.${i}.name`}
            render={({ field }) => (
              <BasicInput
                className="alert-text"
                placeholder="Required"
                {...field}
              />
            )}
          />
        </td>
        <td className="p-[8px] pb-0 pr-[8px] pt-[8px]">
          <Controller
            control={control}
            name={`list.${i}.capacity`}
            render={({ field: { onChange, value } }) => (
              <RoomCapacitySelect
                name={`${i}.capacity`}
                onChange={(opt: { label: string; value: number }) =>
                  onChange(opt?.value)
                }
                value={CAPACITY_OPTIONS.find((opt) => opt.value === value)}
              />
            )}
          />
        </td>
      </tr>
    )
  })

  return (
    <AnimatedPopup title="Add Rooms">
      <TableContainer>
        <form>
          <Table>
            <TableHeader
              headers={[
                { label: 'Name', className: 'w-[50%]' },
                { label: 'Type', className: 'w-[50%]' },
              ]}
            />
            <tbody>{trs}</tbody>
          </Table>
        </form>
      </TableContainer>
      <div className="mt-[32px] space-x-[12px] whitespace-nowrap">
        <Button
          data-cy="backBulkRoomsButton"
          className="w-[110px] border border-button-tertiary-color bg-button-default-bg-color uppercase text-button-tertiary-color hover:bg-button-tertiary-color hover:text-button-fill-font-color"
          onClick={onClose}
        >
          Back
        </Button>
        <Button
          data-cy="saveBulkRoomsButton"
          className="w-[110px] bg-button-primary-color uppercase  text-button-fill-font-color hover:brightness-125"
          onClick={handleSubmit(onSubmit)}
        >
          Add
        </Button>
      </div>
    </AnimatedPopup>
  )
}
