import Skeleton from 'react-loading-skeleton'
import { PersonTasks } from '@shared/types/api/person_tasks'
import { ResidentStatus } from '@shared/types/person'
import { Loading } from '@shared/utils/loading'
import count from '@shared/utils/personTasks'
import PersonStatusCheckbox from './PersonStatusCheckbox'
import 'react-loading-skeleton/dist/skeleton.css'

export default function ProspectResidentSelector({
  personTasks,
  personFilter,
  setPersonFilter,
}: {
  personFilter: Set<ResidentStatus>
  setPersonFilter: React.Dispatch<React.SetStateAction<Set<ResidentStatus>>>
  personTasks: Loading<PersonTasks[]>
}) {
  return (
    <div>
      {personTasks.tag === 'Complete' && (
        <div className="flex w-full justify-between">
          <div className="flex items-center">
            <PersonStatusCheckbox
              count={count({
                personTasks: personTasks.value,
                status: ResidentStatus.RESIDENT_STATUS_CURRENT_RESIDENT,
              })}
              personFilter={personFilter}
              setPersonFilter={setPersonFilter}
              status={ResidentStatus.RESIDENT_STATUS_CURRENT_RESIDENT}
            />
            <PersonStatusCheckbox
              count={count({
                personTasks: personTasks.value,
                status: ResidentStatus.RESIDENT_STATUS_PROSPECT,
              })}
              personFilter={personFilter}
              setPersonFilter={setPersonFilter}
              status={ResidentStatus.RESIDENT_STATUS_PROSPECT}
            />
          </div>
        </div>
      )}
      {personTasks.tag === 'Loading' && (
        <div className={'grow'}>
          <Skeleton />
        </div>
      )}
    </div>
  )
}
