import {
  GroupPermission,
  GroupType,
} from '@augusthealth/models/com/august/protos/permission'
import { useState } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import { DotDivider } from '@shared/components/DotDivider'
import OutOfCommunityBanner from '@shared/components/OutOfCommunityBanner'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { Person } from '@shared/types/person'
import MarkAsAwayPopup from '@app/components/Residents/MoveOutDetails/MarkAsAwayPopup'

export default function OutOfCommunityBannerWithEditControls({
  person,
}: {
  person: Person
}) {
  const [showPopup, setShowPopup] = useState(false)
  const [includeEndDate, setIncludeEndDate] = useState(false)

  return (
    <OutOfCommunityBanner person={person}>
      <PersonPermissionGate
        person={person}
        permissions={[GroupPermission.GROUP_PERMISSION_PERSON_UPDATE]}
        excludedRoles={[GroupType.GROUP_TYPE_RESPONSIBLE_PARTY]}
      >
        <div data-testid="cta-block">
          <ButtonLink
            className={'uppercase'}
            linkStyle={'secondary'}
            onClick={() => {
              setShowPopup(true)
              setIncludeEndDate(false)
            }}
          >
            Edit
          </ButtonLink>
          <DotDivider />
          <ButtonLink
            className={'uppercase'}
            linkStyle={'secondary'}
            onClick={() => {
              setShowPopup(true)
              setIncludeEndDate(true)
            }}
          >
            End Now
          </ButtonLink>
        </div>
      </PersonPermissionGate>
      {showPopup && (
        <MarkAsAwayPopup
          setShowPopup={setShowPopup}
          includeEndDate={includeEndDate}
          doneFn={() => setShowPopup(false)}
          person={person}
        />
      )}
    </OutOfCommunityBanner>
  )
}
