import { useContext, useEffect, useState } from 'react'
import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import ButtonLink from '@shared/components/ButtonLink'
import Icon from '@shared/components/Icon'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { UserAccount } from '@shared/types/user'
import { getFullName } from '@shared/utils/humanName'
import { loaded, LOADING, Loading } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import { isSuperUser } from '@shared/utils/user'
import { fetchInternalUsers } from '@app/api/users'
import Content from '@app/components/generic/Content'
import HUD from '@app/components/HUD'
import PersonPageTitle from '@app/components/PersonPageTitle'
import {
  NameTd,
  Table,
  TableContainer,
  TdWithExtraPadding,
  TrWithHoverEffect,
} from '@app/components/Table'
import TableHeader from '@app/components/Table/TableHeader'
import { getUserLabelAndType } from '@app/pages/Organizations/Settings/UsersPage/UserTable/helpers'
import SubPageTabs, { INTERNAL_USERS_LABEL } from '../SubPageTabs'
import AddEditInternalUserPopup from './AddEditInternalUserPopup'
import DeleteInternalUserModal from './DeleteInternalUserModal'

export default function InternalUsers() {
  const { setError } = useContext(GlobalContext)
  const { user: loginUser } = useUserContext()
  const isAnotherSuperUser = (u: UserAccount) => {
    return isSuperUser(u) && u.id !== loginUser.id
  }
  const [users, setUsers] = useState<Loading<UserAccount[]>>(LOADING)
  const [selectedUser, setSelectedUser] = useState<UserAccount | undefined>(
    undefined
  )
  const [userToBeDeleted, setUserToBeDeleted] = useState<
    UserAccount | undefined
  >(undefined)
  const [notice, setNotice] = useState<string>('')

  const reload = () => {
    return fetchInternalUsers()
      .then((list) => setUsers(loaded(list)))
      .catch(setError)
  }

  useEffect(() => void reload(), [])

  return (
    <Content>
      <SubPageTabs activeTab={INTERNAL_USERS_LABEL} />
      <PersonPageTitle className={tw`mt-[32px]`} title={INTERNAL_USERS_LABEL}>
        <Button
          buttonStyle="primary-fill"
          onClick={() => setSelectedUser({ isActive: true } as UserAccount)}
        >
          <Icon name="plus" className={tw`mr-[8px]`} />
          Create New User
        </Button>
      </PersonPageTitle>
      {users.tag === 'Loading' && <SimpleSpinner />}
      {users.tag === 'Complete' && (
        <TableContainer>
          <Table>
            <TableHeader
              headers={[
                {
                  label: 'Name',
                  className: 'w-[25%]',
                },
                {
                  label: 'Role',
                  className: 'w-[20%]',
                },
                {
                  label: 'Email',
                  className: 'w-[45%]',
                },
              ]}
            />
            <tbody>
              {users.value.map((u) => {
                const { id, name, email } = u
                const fullName = getFullName(name)

                return (
                  <TrWithHoverEffect
                    key={`internal-user-row-${id}`}
                    onClick={() => setSelectedUser(u)}
                  >
                    <NameTd>{fullName}</NameTd>
                    <TdWithExtraPadding>
                      {getUserLabelAndType(u).label}
                    </TdWithExtraPadding>
                    <TdWithExtraPadding>
                      <div className={tw`flex`}>
                        <div className={tw`grow`}>{email}</div>
                        <div>
                          {!isSuperUser(u) && (
                            <ButtonLink
                              title={`Delete ${fullName}`}
                              onClick={(ev) => {
                                ev.stopPropagation()
                                setUserToBeDeleted(u)
                              }}
                            >
                              <Icon name="trash-can" />
                            </ButtonLink>
                          )}
                        </div>
                      </div>
                    </TdWithExtraPadding>
                  </TrWithHoverEffect>
                )
              })}
            </tbody>
          </Table>
        </TableContainer>
      )}
      {selectedUser && (
        <AddEditInternalUserPopup
          user={selectedUser}
          onClose={() => setSelectedUser(undefined)}
          reload={reload}
          setNotice={setNotice}
          disabled={isAnotherSuperUser(selectedUser)}
        />
      )}
      {userToBeDeleted && (
        <DeleteInternalUserModal
          user={userToBeDeleted}
          onClose={() => setUserToBeDeleted(undefined)}
          reload={reload}
          setNotice={setNotice}
        />
      )}
      {notice && <HUD onExpire={() => setNotice('')}>{notice}</HUD>}
    </Content>
  )
}
