import { RouteComponentProps } from 'react-router-dom'

export const ORGANIZATIONS_SETTINGS_PATH = '/orgs/:orgId/settings'
export const ORGANIZATIONS_SETTINGS_TAB_PATH = '/orgs/:orgId/settings/:tab'
export const ORGANIZATIONS_SETTINGS_GENERAL_PATH =
  '/orgs/:orgId/settings/general'
export const ORGANIZATIONS_SETTINGS_COMMUNITIES_PATH =
  '/orgs/:orgId/settings/communities'
export const ORGANIZATIONS_SETTINGS_USERS_PATH = '/orgs/:orgId/settings/users'

type OrgParams = {
  orgId: string
}

export type Props = RouteComponentProps<OrgParams>
