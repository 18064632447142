import { useContext } from 'react'
import { getLedgerExportUrl } from '@shared/api/billing'
import { download } from '@shared/api/legacy'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import GlobalContext from '@shared/contexts/GlobalContext'
import { LedgerExport } from '@shared/types/billing'
import { Facility } from '@shared/types/facility'
import {
  convertISODateStringToLabel,
  formatDateTimeInZone,
} from '@shared/utils/date'
import { getFullName } from '@shared/utils/humanName'
import { Loading } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import { TdWithExtraPadding, TrWithHoverEffect } from '@app/components/Table'
import WithTable from '../../Components/WithTable'
import { GeneralLedgerColumnName } from './helpers'

export function LedgerExportsTable({
  ledgerExports,
  facility,
  onCtaClick,
}: {
  ledgerExports: Loading<LedgerExport[]>
  facility: Facility
  onCtaClick: () => void
}) {
  const { setError } = useContext(GlobalContext)
  const headers = [
    { label: GeneralLedgerColumnName.CREATED_AT },
    { label: GeneralLedgerColumnName.END_OF_PERIOD },
    { label: GeneralLedgerColumnName.EXPORTED_BY },
    { label: GeneralLedgerColumnName.DOWNLOAD_LINK },
  ]
  return (
    <div className={tw`pt-8`}>
      <WithTable
        title="Previous GL Exports"
        rows={ledgerExports}
        headers={headers}
        defaultSelectedColumn={GeneralLedgerColumnName.CREATED_AT}
        addBtnProps={{
          onClick: onCtaClick,
          label: 'Generate GL Export',
        }}
      >
        {(sortedRows: LedgerExport[]) => {
          return sortedRows.map((row: LedgerExport) => {
            const {
              data: { endDate, createdAt, id },
              user: { name },
            } = row
            return (
              <TrWithHoverEffect
                data-testid={`ledger-export-${id}`}
                key={id}
                noBottomLine={false}
              >
                <TdWithExtraPadding>
                  {formatDateTimeInZone(
                    createdAt,
                    Intl.DateTimeFormat().resolvedOptions().timeZone,
                    {
                      includeDate: true,
                      includeTz: true,
                    }
                  )}
                </TdWithExtraPadding>
                <TdWithExtraPadding>
                  {convertISODateStringToLabel(endDate)}
                </TdWithExtraPadding>
                <TdWithExtraPadding>{getFullName(name)}</TdWithExtraPadding>
                <td>
                  <AsyncIconButton
                    buttonSize="small"
                    initialIcon="fa-download"
                    buttonStyle="tertiary-outline"
                    onClick={() => {
                      download({
                        fileUrl: getLedgerExportUrl({ facility, id }),
                        target: '_blank',
                      }).catch(setError)
                    }}
                  >
                    Download
                  </AsyncIconButton>
                </td>
              </TrWithHoverEffect>
            )
          })
        }}
      </WithTable>
    </div>
  )
}
