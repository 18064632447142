import localForage from 'localforage'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PersonSearchBox } from '@shared/components/PersonSearchBox'
import { useUserContext } from '@shared/contexts/UserContext'
import { getResidentsUrl } from '@shared/legacy_routes'
import { PersonStats } from '@shared/types/person_stats'
import { Order } from '@shared/utils/common'
import { formatDate } from '@shared/utils/date'
import { getFirstAndLastName } from '@shared/utils/humanName'
import {
  getResidentMoveOutDate,
  personNameMatchesSearch,
} from '@shared/utils/person'
import LowPriorityLink from '@app/components/LowPriorityLink'
import {
  ColumnName,
  getMovedOutResidentSortFunc,
  movedOutResidentHeaders,
  MovedOutResidentTableColumn,
  NameToSort,
  onNameSortingToggle,
  sortPeopleByName,
  STORAGE_NAME_TO_SORT,
  STORAGE_PREVIOUS_COLUMN,
} from '@app/components/Residents/List/helpers'
import {
  NameTd,
  Table,
  TableContainer,
  TdWithExtraPadding,
  TrWithHoverEffect,
} from '@app/components/Table'
import TableHeader, {
  getTableHeaderOnToggle,
} from '@app/components/Table/TableHeader'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import { getResidentPath } from '@app/routing/helpers'
import styles from '../list.module.css'
import { Placeholder } from '../List/Placeholder'
import { getDischargeReasonDisplayName } from './helpers'

interface Props {
  match: { params: { facilityId: string; orgId: string } }
}

const STORAGE_RESIDENT_COLUMN = 'movedOutResidentListSelectedColumn'
const STORAGE_RESIDENT_ORDER = 'movedOutResidentListColumnOrder'

export default function List({ match }: Props) {
  const history = useHistory()
  const { facilityId, orgId } = match.params
  const [nameFilter, setNameFilter] = useState('')
  const { movedOutResidents: residents } = useCurrentFacility()
  const [selectedColumn, setSelectedColumn] =
    useState<MovedOutResidentTableColumn>(MovedOutResidentTableColumn.NAME)
  const [sortingOrder, setSortingOrder] = useState<Order>(Order.ASC)
  const [nameToSort, setNameToSort] = useState<NameToSort>('LastName')
  const [previousColumn, setPreviousColumn] = useState<ColumnName | undefined>(
    undefined
  )
  const filteredResidents = residents.filter((resident) =>
    personNameMatchesSearch(resident.person!, nameFilter)
  )
  const sortedResidents =
    selectedColumn === 'Name'
      ? sortPeopleByName(filteredResidents, sortingOrder, nameToSort)
      : getMovedOutResidentSortFunc(selectedColumn)(
          filteredResidents,
          sortingOrder
        )

  useEffect(() => {
    void localForage.getItem(STORAGE_RESIDENT_COLUMN).then((column) => {
      setSelectedColumn(column as MovedOutResidentTableColumn)
    })
    void localForage.getItem(STORAGE_RESIDENT_ORDER).then((order) => {
      setSortingOrder(Number(order))
    })
    void localForage.getItem(STORAGE_NAME_TO_SORT).then((name) => {
      setNameToSort(name as NameToSort)
    })
    void localForage.getItem(STORAGE_PREVIOUS_COLUMN).then((previousCol) => {
      setPreviousColumn(previousCol as ColumnName)
    })
  }, [])

  return (
    <>
      <div className={`${styles.residentTitle} horizontal`}>
        <div>Moved-out Residents</div>
        <PersonSearchBox onChange={setNameFilter} value={nameFilter} />
      </div>
      <TableContainer withScrollBar isEmpty={filteredResidents.length === 0}>
        <Table>
          <TableHeader
            headers={movedOutResidentHeaders}
            sortable={{
              onToggle: (colName: string) => {
                if (colName === 'Name') {
                  onNameSortingToggle({
                    nameToSort,
                    previousColumn,
                    sortingOrder,
                    setNameToSort,
                    setPreviousColumn,
                  })
                }
                getTableHeaderOnToggle({
                  selectedColumn,
                  selectedOrder: sortingOrder,
                  setColumn: setSelectedColumn as (c: string) => void,
                  setOrder: setSortingOrder,
                  storageColumnName: STORAGE_RESIDENT_COLUMN,
                  storageOrderName: STORAGE_RESIDENT_ORDER,
                })(colName)
              },
              selectedColumn,
              sortingOrder,
            }}
          />
          {sortedResidents.length > 0 && (
            <tbody>
              {sortedResidents.map((resident, ix) => {
                return tableRow(resident, history, ix)
              })}
            </tbody>
          )}
        </Table>
        {sortedResidents.length === 0 && (
          <Placeholder text="No Moved-out Residents Yet" showImage={false} />
        )}
      </TableContainer>
      <LowPriorityLink to={getResidentsUrl(orgId, facilityId)}>
        View all residents
      </LowPriorityLink>
    </>
  )
}

function tableRow(resident: PersonStats, history: any, ix: number) {
  const { user } = useUserContext()
  const person = resident.person!

  return (
    <TrWithHoverEffect
      key={person.id}
      id={`resident-${ix}`}
      onClick={() => {
        history.push(
          getResidentPath({
            user,
            person,
          })
        )
      }}
    >
      <NameTd>{getFirstAndLastName(person.name)}</NameTd>
      <TdWithExtraPadding>
        {person.dischargeInformation?.moveOutDate
          ? formatDate(getResidentMoveOutDate(person))
          : '--'}
      </TdWithExtraPadding>
      <TdWithExtraPadding>
        {person.dischargeInformation?.reason
          ? getDischargeReasonDisplayName(person.dischargeInformation?.reason)
          : '--'}
      </TdWithExtraPadding>
    </TrWithHoverEffect>
  )
}
