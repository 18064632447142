import { MedPass } from '@augusthealth/models/com/august/protos/api/med_pass'
import {
  DosageV2,
  SlidingScaleEntry,
} from '@augusthealth/models/com/august/protos/dosage'
import { DeviationAdministration } from '@augusthealth/models/com/august/protos/dosage_deviation'
import { MedicationAdministration } from '@augusthealth/models/com/august/protos/medication_administration'
import {
  getFormattedDaysOfMonth,
  getFormattedDaysOfWeek,
  getFormattedTimesOfDay,
} from '@shared/components/TimingBadges'
import { MedicationOrder } from '@shared/types/medication_order'
import {
  formatDateMessage,
  formatDateToDateAtTimeLabel,
} from '@shared/utils/date'
import {
  isSlidingScaleDosageType,
  isSplitDosageType,
} from '@shared/utils/dosage'
import { administrationIsTimeDependent } from '@shared/utils/medicationAdministration'
import {
  getDosageInstructions,
  getDoseQuantityUnit,
  getDoseQuantityValue,
  getSlidingScaleEntriesFromDose,
  getStructuredSlidingScaleDisplayData,
  getVitalInstructions,
} from '@shared/utils/medicationOrder'
import { isVitalOrder } from '@shared/utils/medicationStatement'
import notEmpty from '@shared/utils/notEmpty'
import pluralize from '@shared/utils/pluralize'
import {
  getFormattedPeriodAndFrequencyDisplayData,
  getTimesOfDay,
} from '@shared/utils/timing'
import { getMedOrderDosageType } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'

export const getTimingDisplayDataForMedication = ({
  medicationOrder,
  use24HourClock,
}: {
  medicationOrder: MedicationOrder
  use24HourClock: boolean
}): string[] => {
  const isVital = isVitalOrder(medicationOrder)
  const dosageType = getMedOrderDosageType(medicationOrder.medicationStatement)
  const instructions = isVital
    ? getVitalInstructions(medicationOrder)
    : getDosageInstructions(medicationOrder)

  if (isSplitDosageType(dosageType)) {
    return getSplitMedTimings({
      dosageInstruction: instructions,
      use24HourClock,
    })
  } else if (isSlidingScaleDosageType(dosageType)) {
    return getSlidingScaleTimings({
      dose: instructions[0],
      use24HourClock,
    })
  } else {
    return instructions.flatMap((dose) =>
      getTimingFromDose({ dose, use24HourClock })
    )
  }
}

const getSplitMedTimings = ({
  dosageInstruction,
  use24HourClock,
}: {
  dosageInstruction: DosageV2[]
  use24HourClock: boolean
}): string[] => {
  const timings = dosageInstruction.flatMap((dose) => {
    const timesOfDay = getTimesOfDay(dose.timing)
    const doseValue = getDoseQuantityValue(dose)
    const doseUnit = getDoseQuantityUnit(dose)

    const scaleEntriesForDose = getSlidingScaleEntriesFromDose(dose)
    if (scaleEntriesForDose.length > 0) {
      return getSlidingScaleEntryInstructions(scaleEntriesForDose)
    }

    if (dose.asNeededBoolean) {
      return `PRN - ${pluralize(doseUnit, doseValue, true)}`
    }

    const descriptor = timesOfDay.length > 0 ? 'at' : 'on'
    const specificTimes = getSpecificTimesForDose({ dose, use24HourClock })

    return `${pluralize(doseUnit, doseValue, true)} ${descriptor} ${specificTimes}`
  })

  return ['Split Schedule', ...(timings ?? [])]
}

const getSlidingScaleTimings = ({
  dose,
  use24HourClock,
}: {
  dose: DosageV2
  use24HourClock: boolean
}): string[] => {
  const scaleEntries = getSlidingScaleEntriesFromDose(dose)
  const scaleInstructions = getSlidingScaleEntryInstructions(scaleEntries)
  const timingInstructions = getTimingFromDose({
    dose,
    use24HourClock,
  })
  return [...timingInstructions, ...scaleInstructions]
}

const getTimingFromDose = ({
  dose,
  use24HourClock,
}: {
  dose: DosageV2
  use24HourClock: boolean
}): string[] => {
  return [
    getFormattedPeriodAndFrequencyDisplayData(dose.timing),
    getSpecificTimesForDose({
      dose,
      use24HourClock,
    }),
  ].filter(notEmpty)
}

const getSlidingScaleEntryInstructions = (
  scaleEntries?: SlidingScaleEntry[]
): string[] => {
  const structuredData = getStructuredSlidingScaleDisplayData(scaleEntries)

  if (structuredData.length === 0 || !scaleEntries) {
    console.warn('no scale entries, cannot show sliding scale info')
    return []
  }

  return structuredData.map(({ rangeText, administerDose }) => {
    return `If ${rangeText} administer ${administerDose}`
  })
}

const getSpecificTimesForDose = ({
  dose,
  use24HourClock,
}: {
  dose: DosageV2
  use24HourClock: boolean
}): string => {
  const timing = dose.timing
  if (!timing) {
    return ''
  }

  const timesOfDay = getFormattedTimesOfDay(timing, { use24HourClock })
  const daysOfWeek = getFormattedDaysOfWeek(timing)
  const daysOfMonth = getFormattedDaysOfMonth(timing)

  return [...timesOfDay, ...daysOfWeek, ...daysOfMonth].join(', ')
}

export const getFormattedAdministrationTiming = ({
  administration,
  medPasses,
}: {
  administration: DeviationAdministration | MedicationAdministration | undefined
  medPasses: MedPass[]
}): string | null => {
  if (!administration) {
    return null
  }
  let timeToShow: string = formatDateToDateAtTimeLabel(
    administration?.scheduled?.beginScheduledTime || ''
  )
  if (!administrationIsTimeDependent(administration)) {
    const matchingMedPass = medPasses.find(
      (medPass) => medPass?.id === administration.medPassId
    )
    timeToShow = `${formatDateMessage(administration?.scheduled?.beginScheduledTime?.date)} - ${matchingMedPass!.name!} med pass`
  }

  return timeToShow
}
