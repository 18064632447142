import Badge from '@shared/components/Badge'
import { TaskCreationEvent, TaskDefinition } from '@shared/types/task'
import { eventName } from '@shared/utils/taskCreationEvent'

const unsupportedEvents = [
  TaskCreationEvent.TASK_CREATION_EVENT_UNSPECIFIED,
  TaskCreationEvent.UNRECOGNIZED,
]

export default function EventBadges({ task }: { task: TaskDefinition }) {
  return (
    <div className="mt-[8px] flex items-center">
      {task.events &&
        Object.values(TaskCreationEvent)
          .filter(
            (tce) =>
              task.events?.includes(tce) && !unsupportedEvents.includes(tce)
          )
          .map((tce) => {
            return (
              <Badge
                className="mr-[8px]"
                color="gray"
                shape="round"
                key={`tc-${tce}`}
              >
                {eventName(tce)}
              </Badge>
            )
          })}
      {(task.events ?? []).length === 0 && (
        <Badge color="gray" shape="round" className="mr-[8px]">
          Documents Sidebar Only
        </Badge>
      )}
    </div>
  )
}
