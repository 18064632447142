import { Fragment } from 'react'
import styles from './styles.module.css'
import TabLink, { TabData } from './TabLink'

export type TabListProps = Omit<TabData, 'active'>[]

/**
 * Tab UI list based on `Link` of 'react-router-dom'
 * @param {string} activeTab [undefined] - Active tab that should match label in TabData
 * @param {TabData[]} tabList - { url, label, active? }[]
 * @returns
 */

type Props = React.HTMLProps<HTMLDivElement> & {
  activeTab?: string
  tabList: TabListProps
}

export default function RouterTabs({ activeTab, tabList, ...divProps }: Props) {
  return (
    <div className={styles.divHolder} {...divProps}>
      <ul className={styles.tabContainer} role="tablist">
        {tabList.map((tab) => {
          const { label } = tab
          // Get active from `tab` first, if undefined, check `activeTab`
          const tabLink = (
            <TabLink
              key={`tab-link-${label}`}
              {...tab}
              active={tab.label === activeTab}
            />
          )

          return (
            <Fragment key={`tab-fragment-${label}`}>
              {tabLink}
              <li key={`tab-divider-${label}`} className={styles.tabDivider} />
            </Fragment>
          )
        })}
      </ul>
    </div>
  )
}
