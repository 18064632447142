import { IncidentAction } from '@augusthealth/models/com/august/protos/incidents'
import { fetchJson } from '@shared/api/request'
import { apiUrl } from '@shared/api/urls'
import { apiPersonUrl } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'

export async function getIncidentActionTemplates({
  person,
}: {
  person: Person
}) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response = await fetchJson<IncidentAction[]>(
    apiUrl(
      apiPersonUrl(person.orgId, person.facilityId, person.id),
      '/incidents/actions/availableTemplates',
      {}
    )
  )

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return response.data as IncidentAction[]
}
