import DatePicker from 'react-datepicker'
import { Controller, useForm } from 'react-hook-form'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { ModalTitle } from '@shared/components/baseMui/Modal/Layout'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import {
  BasicInput,
  BasicTextarea,
} from '@shared/components/BasicInput/BasicInput'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import { Label, LabelAboveInput } from '@shared/components/Labels'
import { Incident, NoteType } from '@shared/types/incidents'
import { Person } from '@shared/types/person'
import { formatTime, fromDateMessageToDate } from '@shared/utils/date'
import { noteTypes } from '@shared/utils/incident'
import { tw } from '@shared/utils/tailwind'
import styles from './styles.module.css'
import { onNoteSubmit, updateIncidentOrNoteTypes } from './helpers'
import { NoteFormData } from './types'

type ModalProps = {
  closeModal: (updated?: Incident) => Promise<void>
  note?: Incident
  person: Person
}

export function AddNoteModal(props: Exclude<ModalProps, 'note'>) {
  return <NoteModal {...props} />
}

export function EditNoteModal(props: Required<ModalProps>) {
  return <NoteModal {...props} />
}

function NoteModal({ closeModal, note, person }: ModalProps) {
  const { handleSubmit, register, formState, control, setValue, watch } =
    useForm<NoteFormData>({
      mode: 'onChange',
      defaultValues: {
        noteText: note?.detail?.noteDetail?.note ?? '',
        noteTypes: note?.detail?.noteDetail?.types ?? [],
        serviceDate:
          fromDateMessageToDate(note?.occurredAt?.date) ?? new Date(),
        time: note?.occurredAt?.time
          ? formatTime(note.occurredAt.time, { use24HourClock: true })
          : null,
      },
    })
  const currentNoteTypes = watch('noteTypes', [])

  return (
    <Modal
      open={true}
      id={'add-edit-note'}
      onClose={() => closeModal()}
      contentClassName={tw`w-[664px]`}
      disableBackgroundClick
    >
      <ModalTitle className={'mb-2'}>{note ? 'Edit' : 'Add'} Note</ModalTitle>
      <form
        onSubmit={handleSubmit(async (formData) => {
          const updated = await onNoteSubmit({
            formData,
            person,
            originalNote: note,
          })
          await closeModal(updated)
        })}
      >
        <section>
          <Label visuallyHidden htmlFor="noteText">
            Notes
          </Label>
          <BasicTextarea
            data-cy="notes-tab-note"
            {...register('noteText', { required: true, minLength: 1 })}
            placeholder="Add a note, e.g. resident is not feeling well after overeating..."
            className={styles.noteMargin}
          />
          <LabelAboveInput htmlFor="noteTypes">Select Tag(s)</LabelAboveInput>
          {noteTypes.map((type, index) => {
            const { onChange, ...rest } = register('noteTypes')

            return (
              <span key={`note-${index}`}>
                <input
                  className={styles.checkbox}
                  type="checkbox"
                  id={`tag-${index}`}
                  {...rest}
                  value={type.value}
                  onChange={(ev) => {
                    const selectedType = ev.target.value as NoteType
                    const updatedTypes = updateIncidentOrNoteTypes({
                      originalTypes: currentNoteTypes,
                      selectedType,
                    })
                    setValue('noteTypes', updatedTypes as NoteType[])
                  }}
                />
                <label htmlFor={`tag-${index}`} className={styles.tag}>
                  <i className={`fa-solid mr-[4px] fa-${type.icon}`} />
                  <span className="text-[14px] font-medium leading-[16px]">
                    {type.name}
                  </span>
                </label>
              </span>
            )
          })}
          <div className={'mt-[24px]'}>
            <LabelAboveInput htmlFor={'serviceDate'}>
              Service Date
            </LabelAboveInput>
            <div className="flex items-center">
              <Controller
                control={control}
                name="serviceDate"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    selected={value}
                    id={'serviceDate'}
                    onChange={(v: Date | null) => {
                      onChange(v)
                    }}
                    //using Date.now() to make this easy to test
                    maxDate={new Date(Date.now())}
                    customInput={
                      <CalendarInput data-testid={'note-modal-date-input'} />
                    }
                    portalId={'root'}
                  />
                )}
              />
              <div className={'ml-[16px] w-[184px]'}>
                <BasicInput
                  type={'time'}
                  {...register('time')}
                  id={'incident-time'}
                />
              </div>
            </div>
          </div>
        </section>
        <footer className="mt-[32px]">
          <AsyncIconButton
            isLoading={false}
            buttonStyle={'secondary-outline'}
            className="mr-[16px]"
            width={'136px'}
            onClick={async () => {
              await closeModal()
            }}
            type={'button'}
          >
            Cancel
          </AsyncIconButton>
          <AsyncIconButton
            isLoading={formState.isSubmitting}
            disabled={
              !formState.isValid || !formState.isDirty || formState.isSubmitting
            }
            buttonStyle={'primary-fill'}
            width={'136px'}
            id={'save-note'}
            type={'submit'}
            data-testid={'save-note'}
          >
            {note ? 'Update' : 'Add'} Note
          </AsyncIconButton>
        </footer>
      </form>
    </Modal>
  )
}
