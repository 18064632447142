import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryThemeDefinition,
} from 'victory'
import { convertPercentageToString } from '@shared/utils/occupancy'
import {
  BASE_FILL_COLOR,
  BASE_TEXT_COLOR,
  getDefaultTheme,
  TRANSPARENT,
} from '@app/libraries/Victory/theme'
import { BarData } from './types'

type Props = {
  data: BarData[]
}

type ColorProps = {
  datum: BarData
}

function getBarFill({ datum }: ColorProps) {
  const { percentage } = datum || {}
  if (percentage === 0) {
    return BASE_TEXT_COLOR
  } else if (percentage < 0.5) {
    return '#f2c380' // Low Occupancy Color
  }

  return BASE_FILL_COLOR
}

function getBarLabelColor({ datum }: ColorProps) {
  const { percentage } = datum || {}
  if (percentage === 0) {
    return TRANSPARENT
  }

  return BASE_TEXT_COLOR
}

export default function BarChart({ data }: Props) {
  const noStats = data.every((d) => d.percentage === 0)
  const categories = data.map(({ date }) => {
    return date.toLocaleString('default', { month: 'short' })
  })
  const theme = getDefaultTheme({
    baseFillColor: getBarFill,
    baseTextColor: getBarLabelColor,
  })

  return (
    <VictoryChart
      height={64}
      padding={{ top: 16, bottom: 16, left: 16, right: 16 }}
      theme={theme as VictoryThemeDefinition}
    >
      <VictoryBar
        barRatio={1}
        categories={{ x: categories }}
        data={data}
        domain={noStats ? { y: [0, 20] } : undefined} // Adjust domain to limit bar height
        labels={({ datum }) => convertPercentageToString(datum.percentage)}
      />
      <VictoryAxis />
    </VictoryChart>
  )
}
