export const active = {
  title: 'Active',
  options: [
    { label: 'Golf', value: 'golf' },
    { label: 'Swimming', value: 'swimming' },
    { label: 'Group exercise', value: 'group-exercise' },
    { label: 'Individual exercise', value: 'individual-exercise' },
    { label: 'Dancing', value: 'dancing' },
    { label: 'Walks', value: 'walks' },
    { label: 'Shopping', value: 'shopping' },
    { label: 'Bowling', value: 'bowling' },
    { label: 'Gardening', value: 'gardening' },
    { label: 'Fixing things', value: 'fixing-things' },
    { label: 'Working on cars', value: 'working-on-cars' },
    { label: 'Visiting with animals', value: 'visiting-with-animals' },
    {
      label: 'Interacting with children',
      value: 'interacting-with-children',
    },
    { label: 'Visiting with others', value: 'visiting-with-others' },
  ],
}

const artsCulture = {
  title: 'Arts & Culture',
  options: [
    { label: 'Music/Singing', value: 'music-singing' },
    { label: 'Playing an instrument', value: 'playing-an-instrument' },
    { label: 'Movies', value: 'movies' },
    { label: 'Plays/Theater', value: 'plays-theater' },
    { label: 'Visiting museums', value: 'visiting-museums' },
    { label: 'Floral arranging', value: 'floral-arranging' },
    { label: 'Painting/Arts', value: 'painting-arts' },
    { label: 'Pottery', value: 'pottery' },
    { label: 'Woodworking', value: 'woodworking' },
  ],
}

const quietActivities = {
  title: 'Quiet Activities',
  options: [
    { label: 'Sewing', value: 'sewing' },
    { label: 'Knitting', value: 'knitting' },
    { label: 'Cooking/Baking', value: 'cooking-baking' },
    { label: 'Bridge/Cards', value: 'bridge-cards' },
    { label: 'Bingo', value: 'bingo' },
    { label: 'Jigsaw puzzles', value: 'jigsaw-puzzles' },
    { label: 'Crosswords', value: 'crosswords' },
    { label: 'Checkers/Chess', value: 'checkers-chess' },
    { label: 'Reading books', value: 'reading-books' },
    { label: 'Trivia', value: 'trivia' },
    { label: 'Collections', value: 'collections' },
    { label: 'Reminiscing', value: 'reminiscing' },
  ],
}

const sections = [active, artsCulture, quietActivities]
export default sections
