import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useHistory } from 'react-router-dom'
import ButtonLink from '@shared/components/ButtonLink'
import { EmptyCard } from '@shared/components/EmptyCard'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { Person } from '@shared/types/person'
import { DataType } from '@shared/types/snapshot'
import { TaskTemplateInfo, TaskType } from '@shared/types/task'
import { recreateTaskAndReturnUrl } from '@app/pages/Documents/helpers'

export function NoRoutines({
  person,
  configuredTasks,
}: {
  person: Person
  configuredTasks: TaskTemplateInfo[]
}) {
  const history = useHistory()
  const showCreateAppraisalLink = configuredTasks?.find(
    (t) => t?.taskType === TaskType.TASK_TYPE_AUGUST_INITIAL_APPRAISAL
  )

  return (
    <>
      <div>
        <EmptyCard
          version={{
            tag: 'Children',
            children: showCreateAppraisalLink ? (
              <PersonPermissionGate
                person={person}
                permissions={[GroupPermission.GROUP_PERMISSION_TASK_CREATE]}
              >
                <div className={'mt-[4px]'}>
                  To get started, begin a{' '}
                  <ButtonLink
                    onClick={async () => {
                      const url = await recreateTaskAndReturnUrl(
                        person,
                        undefined,
                        DataType.DATA_TYPE_AUGUST_INITIAL_APPRAISAL
                      )
                      history.push(url)
                    }}
                  >
                    Resident Assessment
                  </ButtonLink>{' '}
                  or add a routine above
                </div>
              </PersonPermissionGate>
            ) : undefined,
          }}
          title="No routines yet."
          className={'mb-[32px]'}
        />
      </div>
    </>
  )
}
