import { useForm } from 'react-hook-form'
import { Incident } from '@shared/types/incidents'
import { Person } from '@shared/types/person'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { stopIncidentAlert } from '@app/api/incidents'
import ConfirmModal from '@app/components/ConfirmModal'

export default function StopAlertModal({
  incident,
  person,
  onClose,
}: {
  incident: Incident
  person: Person
  onClose: (updated?: Incident) => Promise<void>
}) {
  const { handleSubmit } = useForm()

  const onSubmit = async () => {
    const newIncident: Incident = {
      id: incident.id,
      alert: {
        ...incident.alert,
        endedAt: new Date().toISOString(),
      },
    }

    await stopIncidentAlert({
      person,
      incident: newIncident,
    })
    await onClose({ ...incident, ...newIncident })
  }

  const content = (
    <>
      <p className="m-0 p-0 text-center text-[16px] leading-[24px] text-secondary-06">
        This will remove the alert for {getFirstAndLastName(person.name)}.
      </p>
      <p className="mb-[24px] p-0 text-center text-[16px] leading-[24px] text-secondary-06">
        You can always change this again later.
      </p>
    </>
  )
  return (
    <ConfirmModal
      title={'Stop this alert?'}
      content={content}
      confirmButtonConfig={{
        'data-cy': 'stop-alert',
        type: 'submit',
        onClick: handleSubmit(onSubmit),
        children: 'Stop Alert',
      }}
      denyButtonConfig={{
        onClick: () => onClose(),
        children: 'Not Now',
      }}
    />
  )
}
