import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import useLocalStorage from '@shared/hooks/useLocalStorage'
import { FacilityIds } from '@shared/types/facility'
import { IncidentsResponse } from '@shared/types/incidents'
import { UserAccount } from '@shared/types/user'
import {
  AsyncResult,
  getOrElse,
  LOADING,
  mapAsyncResult,
} from '@shared/utils/loading'
import { getFacilityUsers } from '@app/api/users'
import { facilityIncidentsFormDataToParams } from '@app/pages/Facilities/Incidents/helpers'
import { FilterIncidentsFormData } from '@app/pages/Notes/types'
import { fetchFacilityIncidents } from '../api/incidents'

const PER_PAGE = 100

const defaultFilterValues = {
  noteTypes: [],
  incidentTypes: [],
  incidentStatus: null,
  time: null,
  users: [],
  onAlert: null,
}

export function useFacilityIncidentsAndUsers(facility: FacilityIds) {
  const { setError } = useContext(GlobalContext)
  const [incidents, setIncidents] =
    useState<AsyncResult<IncidentsResponse, unknown>>(LOADING)
  const [communityUsers, setCommunityUsers] =
    useState<AsyncResult<UserAccount[], unknown>>(LOADING)
  const [storedFilters, setStoredFilters] = useLocalStorage(
    'facilityWideIncidentsAndNotesFilters',
    defaultFilterValues
  )
  const [offset, setOffset] = useState(0)
  const currentPage = offset / PER_PAGE + 1
  const numPages = getOrElse(
    mapAsyncResult(incidents, (result) => {
      if (result.meta.count === 0) {
        return 1
      } else {
        return Math.ceil(result.meta.count / PER_PAGE)
      }
    }),
    1
  )

  const refreshIncidents = (
    filters: FilterIncidentsFormData,
    newOffset: number
  ) => {
    setIncidents({ tag: 'Loading' })
    setOffset(newOffset)
    const params = facilityIncidentsFormDataToParams({
      filters: { ...filters, limit: PER_PAGE, offset: newOffset },
    })
    return fetchFacilityIncidents({ facility, params: params })
      .then((response) => setIncidents({ tag: 'Complete', value: response }))
      .catch(setError)
  }

  const goToPage = async (n: number) => {
    const pageNumber = Math.min(Math.max(1, n), numPages)
    const newOffset = (pageNumber - 1) * PER_PAGE

    if (storedFilters.tag === 'Complete') {
      await refreshIncidents(storedFilters.value, newOffset)
    }
  }

  const hasNextPageOfIncidents = () => {
    return currentPage < numPages
  }

  const goToNextPageOfIncidents = async () => {
    await goToPage(currentPage + 1)
  }

  const hasPreviousPageOfIncidents = () => {
    return currentPage > 1
  }

  const goToPreviousPageOfIncidents = async () => {
    await goToPage(currentPage - 1)
  }

  const refreshCommunityUsers = ({ facility }: { facility: FacilityIds }) => {
    setCommunityUsers({ tag: 'Loading' })
    return getFacilityUsers({ facility })
      .then((userAccounts) => {
        setCommunityUsers({ tag: 'Complete', value: userAccounts })
      })
      .catch(() => setCommunityUsers({ tag: 'Complete', value: [] }))
  }

  const { orgId, id: facilityId } = facility
  useEffect(() => {
    if (orgId && facilityId && storedFilters.tag === 'Complete') {
      void refreshIncidents(storedFilters.value, 0)
    }
  }, [orgId, facilityId, storedFilters.tag])

  useEffect(() => {
    if (orgId && facility) {
      void refreshCommunityUsers({ facility })
    }
  }, [orgId, facilityId])

  return {
    filters: getOrElse(storedFilters, defaultFilterValues),
    setFilters: setStoredFilters,
    incidents: mapAsyncResult(incidents, (incidents) => incidents.data),
    numPages,
    currentPage,
    goToPage,
    goToNextPageOfIncidents,
    goToPreviousPageOfIncidents,
    hasNextPageOfIncidents,
    hasPreviousPageOfIncidents,
    communityUsers,
  }
}
