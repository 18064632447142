import { useContext, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router-dom'
import {
  createPayerUserOneTimePayment,
  setPayerUserSettings,
} from '@shared/api/billing'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import GlobalContext from '@shared/contexts/GlobalContext'
import {
  CreatePayerUserOneTimePaymentRequest,
  PayerSettingsData,
} from '@shared/types/billing'
import { formatCurrencyForBilling } from '@shared/utils/billing'
import { getOrElse } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import HUD from '@app/components/HUD'
import useBillingPayerUserSettings from '@app/hooks/useBillingPayerUserSettings'
import useBillingPayerUserStatements from '@app/hooks/useBillingPayerUserStatements'
import useBillingPayerUserTransactions from '@app/hooks/useBillingPayerUserTransactions'
import useBillingResidentSummary from '@app/hooks/useBillingResidentSummary'
import Cards from './Cards'
import OneTimePaymentModal from './OneTimePaymentModal'
import TransactionsTable from './TransactionsTable'

export default function CommunityBilling() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [hudText, setHudText] = useState<string>('')

  const { setError } = useContext(GlobalContext)
  const { id, facilityId, orgId } = useParams<{
    id: string
    facilityId: string
    orgId: string
  }>()

  const { billingPayerUserStatements, reloadBillingPayerUserStatements } =
    useBillingPayerUserStatements({
      orgId: orgId!,
      facilityId: facilityId!,
      personId: id!,
    })

  const { billingPayerUserSettings, reloadBillingPayerUserSettings } =
    useBillingPayerUserSettings({
      orgId: orgId!,
      facilityId: facilityId!,
      personId: id!,
    })

  const { billingSummary, reloadBillingSummary } = useBillingResidentSummary({
    orgId: orgId!,
    facilityId: facilityId!,
    personId: id!,
  })

  const { billingPayerUserTransactions, reloadBillingPayerUserTransactions } =
    useBillingPayerUserTransactions({
      orgId: orgId!,
      facilityId: facilityId!,
      personId: id!,
    })

  async function reload() {
    await reloadBillingSummary()
    await reloadBillingPayerUserSettings()
    await reloadBillingPayerUserTransactions()
    await reloadBillingPayerUserStatements()
  }

  const isCardsDataLoading =
    billingPayerUserSettings.tag === 'Loading' ||
    billingSummary.tag === 'Loading' ||
    billingPayerUserStatements.tag === 'Loading'

  async function saveSettings(updatedSettings: PayerSettingsData) {
    try {
      await setPayerUserSettings({
        personId: id!,
        facilityId: facilityId!,
        orgId: orgId!,
        updatedSettings,
      })
      await reload()
    } catch (e) {
      setError(e)
    }
  }

  async function createPayment(formData: CreatePayerUserOneTimePaymentRequest) {
    try {
      const { amountCents } = formData
      await createPayerUserOneTimePayment({
        personId: id!,
        facilityId: facilityId!,
        orgId: orgId!,
        amountCents,
      })
      await reload()
      setHudText(
        `Payment of ${formatCurrencyForBilling(amountCents)} has been submitted`
      )
    } catch (e) {
      setError(e)
    }
  }

  return (
    <div className={tw`mt-8 flex flex-col gap-6`} data-testid="payer-billing">
      <div className={tw`h-[308px]`}>
        <>
          {hudText && <HUD onExpire={() => setHudText('')}>{hudText}</HUD>}
          <OneTimePaymentModal
            billingPayerUserSettings={billingPayerUserSettings}
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            savePayment={createPayment}
          />

          <div className={tw`flex items-center justify-between`}>
            <h2
              data-testid="overview-header"
              className={tw`mb-0 text-2xl font-medium leading-9 text-secondary-02`}
            >
              Overview
            </h2>

            <AsyncIconButton
              data-testid="make-one-time-payment-btn"
              buttonStyle="primary-fill"
              isLoading={false}
              initialIcon="square-dollar"
              onClick={() => setIsModalOpen(true)}
              disabled={
                !getOrElse(billingPayerUserSettings, null)?.paymentSettings
                  ?.paymentMethod
              }
            >
              Make One Time Payment
            </AsyncIconButton>
          </div>
          <div
            className={tw`mb-6 mt-[26px] flex w-full items-start justify-between gap-4 text-sm`}
          >
            {isCardsDataLoading ? (
              <div
                className={tw`flex w-full gap-4`}
                data-testid="overview-cards"
              >
                <div className={tw`w-[40%]`}>
                  <Skeleton className={tw`h-[130px] rounded-lg p-2`} />
                </div>
                <div className={tw`w-[60%]`}>
                  <Skeleton className={tw`h-[130px] rounded-lg p-2`} />
                </div>
              </div>
            ) : (
              <Cards
                billingSummary={billingSummary.value}
                payer={billingPayerUserSettings.value}
                billingPayerUserStatements={billingPayerUserStatements.value}
                saveSettings={saveSettings}
              />
            )}
          </div>
        </>
      </div>
      {billingPayerUserTransactions.tag === 'Loading' ? (
        <Skeleton className={tw`h-[400px]`} />
      ) : (
        <TransactionsTable
          billingPayerUserTransactions={billingPayerUserTransactions}
          reload={reload}
        />
      )}
    </div>
  )
}
