import { ReportAssignment } from '@augusthealth/models/com/august/protos/report'
import { useRef, useState } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import { updateReportAssignment } from '@app/api/reports'

export default function AssignmentName({
  assignment,
  reload,
}: {
  assignment: ReportAssignment
  reload: () => void
}) {
  const inputRef = useRef(null)
  const [editing, setEditing] = useState(false)
  const { name } = assignment

  if (editing) {
    return (
      <div>
        <input ref={inputRef} defaultValue={name} />
        <ButtonLink
          title="Save"
          onClick={() => {
            if (inputRef.current) {
              const input = inputRef.current as HTMLInputElement
              void updateReportAssignment({
                ...assignment,
                name: input.value.trim(),
              }).then(() => {
                setEditing(false)
                reload()
              })
            }
          }}
        >
          <i className="fa-solid fa-check ml-[8px]" />
        </ButtonLink>
        <ButtonLink title="Close" onClick={() => setEditing(false)}>
          <i className="fa-solid fa-x ml-[8px]" />
        </ButtonLink>
      </div>
    )
  }

  return (
    <div className="assignment-name">
      {assignment.name}
      <ButtonLink title={name} onClick={() => setEditing(true)}>
        <i className="fas fa-edit ml-[8px]" />
      </ButtonLink>
    </div>
  )
}
