import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { mergePatchPerson } from '@shared/api/person'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { requiredWhenError } from '@shared/components/Labels'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import {
  fromDateMessageToDate,
  fromDateToDateMessage,
} from '@shared/utils/date'
import RHFDateTimePicker from '@app/components/reactHookForm/DateTimePicker'
import { usePersonInContext } from '@app/hooks/usePerson'
import styles from './styles.module.css'

// Jazz TODO: why is this form type Person? Need to revisit to fix typing issues
export default function MarkAsAwayPopup({
  doneFn,
  includeEndDate = false,
  person,
  setShowPopup,
}: {
  doneFn: React.Dispatch<React.SetStateAction<boolean>>
  includeEndDate?: boolean
  person: Person
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { refreshPerson } = usePersonInContext({ initialData: person })
  const { setError } = useContext(GlobalContext)
  const now = new Date()
  const { date: originalDate, time: originalTime } =
    person.outOfFacilityDetail?.start || {}
  const { control, formState, handleSubmit, watch } = useForm<Person>({
    defaultValues: {
      outOfFacilityDetail: {
        start: {
          date: originalDate ? originalDate : fromDateToDateMessage(now), // Default to today
          time: originalTime ? originalTime : {},
        },
      },
    },
  })
  const { errors } = formState
  const { orgId, facilityId, id: personId } = person
  const confirmSubmit = async (patch: Person) => {
    try {
      await mergePatchPerson({
        orgId,
        fId: facilityId,
        pId: personId,
        patch,
        fields: ['outOfFacilityDetail'],
      })
      await refreshPerson()
      doneFn(true)
    } catch (e) {
      setError(e)
      doneFn(false)
    }
  }
  const title = includeEndDate ? 'Confirm the dates' : 'Mark resident as away'
  const startDate = fromDateMessageToDate(
    watch('outOfFacilityDetail.start.date')
  )
  const endDate = fromDateMessageToDate(watch('outOfFacilityDetail.end.date'))

  return (
    <AnimatedPopup
      title={title}
      onClickBackground={() => {
        setShowPopup(false)
      }}
    >
      <form
        data-testid="mark-as-away-form"
        onSubmit={handleSubmit(confirmSubmit)}
      >
        <RHFDateTimePicker
          control={control}
          datePickerProps={{
            maxDate: endDate && endDate < now ? endDate : now,
          }}
          id="startDate"
          name="outOfFacilityDetail.start"
          required
          subLabel={requiredWhenError(
            Boolean(errors.outOfFacilityDetail?.start?.date)
          )}
          title="Start Date"
        />
        {includeEndDate && (
          <RHFDateTimePicker
            control={control}
            datePickerProps={{
              maxDate: now,
              minDate: startDate,
            }}
            id="endDate"
            required
            name="outOfFacilityDetail.end"
            subLabel={requiredWhenError(
              Boolean(errors.outOfFacilityDetail?.end?.date)
            )}
            title="Return Date"
          />
        )}
        <footer className={styles.footer}>
          <AnimatedPopupFormFooter
            yesBtn={{
              label: includeEndDate ? 'Confirm' : 'Set',
              props: {
                id: 'set-mark-resident-as-away',
              },
            }}
            noBtn={{
              action: () => doneFn(false),
            }}
            formState={formState}
          />
        </footer>
      </form>
    </AnimatedPopup>
  )
}
