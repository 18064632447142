import { OccupancyStats } from '@augusthealth/models/com/august/protos/api/occupancy'
import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Loading } from '@shared/utils/loading'
import { fetchOccupancyStats } from '../api/rooms'
import FacilitiesContext from '../contexts/FacilitiesContext'

type Props = {
  orgId: string
  facilityId: string
}

type UseOccupancyStatsProps = Props & {
  callback?: (stats?: OccupancyStats) => void
}

export function useOccupancyStatsInFacilitiesContext(props: Props) {
  const { setOccupancyStats } = useContext(FacilitiesContext)
  return useOccupancyStats({
    ...props,
    callback: setOccupancyStats,
  })
}

export default function useOccupancyStats({
  orgId,
  facilityId,
  callback,
}: UseOccupancyStatsProps) {
  const { setError } = useContext(GlobalContext)
  const [occupancyStats, setOccupancyStats] = useState<
    Loading<OccupancyStats | undefined>
  >({ tag: 'Loading' })

  const refreshOccupancyStats = async () => {
    try {
      setOccupancyStats({ tag: 'Loading' })
      const stats = await fetchOccupancyStats({ orgId, id: facilityId })
      setOccupancyStats({ tag: 'Complete', value: stats })
      if (callback) {
        callback(stats)
      }
    } catch (e) {
      setError(e)
      setOccupancyStats({ tag: 'Complete', value: undefined })
      if (callback) {
        callback(undefined)
      }
    }
  }

  useEffect(() => {
    if (orgId && facilityId) {
      void refreshOccupancyStats()
    }
  }, [orgId, facilityId])

  return { occupancyStats, refreshOccupancyStats }
}
