import { Recurring_Interval } from '@augusthealth/models/com/august/protos/charge'
import {
  CareLevelValue,
  LevelOfCareSettingsList,
} from '@augusthealth/models/com/august/protos/level_of_care'
import {
  Control,
  Controller,
  FieldPath,
  useFieldArray,
  useFormContext,
  UseFormRegister,
} from 'react-hook-form'
import { AsyncIconButton as AsyncButton } from '@shared/components/AsyncButton'
import Badge from '@shared/components/Badge'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import ButtonLink from '@shared/components/ButtonLink'
import CollapsibleCard from '@shared/components/CollapsibleCard'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { admissionTypeOptions } from '@shared/utils/orgFacilitySettings'
import { tw } from '@shared/utils/tailwind'
import { Switch, SwitchSize } from '@app/components/Switch'

export function AdmissionTypeGroup({ parentIndex }: { parentIndex: number }) {
  const { control, register, getValues, watch, setValue } =
    useFormContext<LevelOfCareSettingsList>()

  const settings = getValues(`careLevelsByAdmissionType.${parentIndex}`)

  const admissionType = admissionTypeOptions.find(
    (admissionType) => admissionType.value === settings.admissionType
  )

  const { fields, append, remove } = useFieldArray<LevelOfCareSettingsList>({
    control,
    name: `careLevelsByAdmissionType.${parentIndex}.levelValues`,
  })

  const lowestLevel = watch(
    `careLevelsByAdmissionType.${parentIndex}.lowestLevel`
  ) as number
  return (
    <CollapsibleCard cardTitle={admissionType?.label}>
      <div className={tw`flex flex-row items-center justify-end gap-4`}>
        <Switch
          size={SwitchSize.small}
          label={'Start at Care Level 0'}
          labelPosition={'left'}
          holderClassName={tw`mb-0 flex flex-row gap-2`}
          name={`careLevelsByAdmissionType.${parentIndex}.lowestLevel`}
          onChange={(event) => {
            setValue(
              `careLevelsByAdmissionType.${parentIndex}.lowestLevel`,
              event.target.checked ? 0 : 1
            )
          }}
          checked={lowestLevel === 0}
        />
      </div>
      <div
        className={tw`flex flex-col gap-4`}
        data-testid={`${admissionType?.value}-care-levels`}
      >
        {fields.map((c, index) => {
          const isLastCareLevel = index === fields.length - 1
          const parentField =
            `careLevelsByAdmissionType.${parentIndex}.levelValues.${index}` as const
          return (
            <div key={c.id}>
              <Badge shape="square" badgeSize="small" color="primaryLight">
                Care Level {index + lowestLevel!}
              </Badge>
              <div className={tw`ml-[8px] mt-[8px] flex flex-row gap-2`}>
                <div className={tw`flex-1`}>
                  <LabelAboveInput
                    uppercase={false}
                    htmlFor={`${parentField}.maxScore`}
                  >
                    Max Score
                  </LabelAboveInput>
                  <BasicInput
                    {...register(`${parentField}.maxScore`, {
                      valueAsNumber: true,
                      required: !isLastCareLevel,
                    })}
                    disabled={isLastCareLevel}
                    type="number"
                    className="h-[40px] w-[124px] text-[14px]"
                    placeholder="None"
                  />
                </div>
                <CareFeeForLevel
                  parentField={parentField}
                  register={register}
                  control={control}
                />
                <div>
                  <LabelAboveInput className={`invisible`}>
                    Remove
                  </LabelAboveInput>
                  <ButtonLink
                    title={'Delete Care Level'}
                    onClick={() => remove(index)}
                    className={tw`ml-2 h-input`}
                    disabled={fields.length === 1}
                  >
                    <i className={`fa fa-fw fa-trash`} />
                  </ButtonLink>
                </div>
              </div>
              <div className={tw`ml-[8px] mt-[8px]`}>
                <LabelAboveInput
                  uppercase={false}
                  htmlFor={`${parentField}.name`}
                >
                  Level Name
                </LabelAboveInput>
                <BasicInput
                  {...register(`${parentField}.name`)}
                  className="h-[40px] w-1/2 text-[14px]"
                  placeholder="Optional"
                />
              </div>
            </div>
          )
        })}
        <AsyncButton
          data-testid={`add-loc-${admissionType?.value}`}
          buttonStyle="primary-outline"
          buttonSize="small"
          initialIcon={'fa-fw fa-circle-plus'}
          className="mt-[8px]"
          type="button"
          onClick={() =>
            append({
              maxScore: null as any,
              careFees: [{ careFee: null as any, interval: null as any }],
            } as CareLevelValue)
          }
        >
          Add Level
        </AsyncButton>
      </div>
    </CollapsibleCard>
  )
}

const CareFeeForLevel = ({
  parentField,
  register,
  control,
}: {
  parentField: FieldPath<LevelOfCareSettingsList>
  register: UseFormRegister<LevelOfCareSettingsList>
  control: Control<any>
}) => {
  const { fields } = useFieldArray({
    control,
    name: `${parentField}.careFees`,
  })

  const intervalOptions = [
    { label: 'Daily', value: Recurring_Interval.INTERVAL_DAY },
    { label: 'Weekly', value: Recurring_Interval.INTERVAL_WEEK },
    { label: 'Monthly', value: Recurring_Interval.INTERVAL_MONTH },
  ]

  return (
    <div className="ml-4 flex flex-col">
      <h4 className="mb-[4px] text-[14px] font-semibold leading-[18px]">
        Care Fees
      </h4>
      {fields.map((fee, cfIndex) => {
        const careFeePrefix =
          `${parentField}.careFees.${cfIndex}` as FieldPath<LevelOfCareSettingsList>

        const careFeeName =
          `${careFeePrefix}.careFee` as FieldPath<LevelOfCareSettingsList>
        const intervalName = `${careFeePrefix}.interval` as const
        return (
          <div className="mt-[8px]" key={fee.id}>
            <LabelAboveInput
              uppercase={false}
              htmlFor={careFeeName}
              className="visually-hidden"
            >
              Care Fees
            </LabelAboveInput>
            <div className={tw`flex flex-row gap-2`}>
              <BasicInput
                {...register(careFeeName, {
                  valueAsNumber: true,
                })}
                min={0}
                type="number"
                className="h-input w-[124px] text-[14px]"
                placeholder="None set"
              />
              <Controller
                control={control}
                name={intervalName}
                render={({ field: { onChange, value } }) => (
                  <StyledSelect
                    className="w-[124px]"
                    name={intervalName}
                    onChange={(e: OptionTypeBase<Recurring_Interval>) => {
                      onChange(e.value)
                    }}
                    options={intervalOptions}
                    value={intervalOptions.find((o) => o.value === value)}
                  />
                )}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}
