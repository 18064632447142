import {
  FunctionalStatus_Capability as Capability,
  FunctionalStatus_CapabilityCodeBathing as CodeBathing,
  FunctionalStatus_CapabilityCodeCommunication as CodeCommunication,
  FunctionalStatus_CapabilityCodeDressing as CodeDressing,
  FunctionalStatus_CapabilityCodeEating as CodeEating,
  FunctionalStatus_CapabilityCodeGrooming as CodeGrooming,
  FunctionalStatus_CapabilityCodeMedication as CodeMedication,
  FunctionalStatus_CapabilityCodeMobility as CodeMobility,
  FunctionalStatus_CapabilityCodeMoneyMgmt as CodeMoneyMgmt,
  FunctionalStatus_CapabilityCodeToileting as CodeToileting,
  FunctionalStatus_CapabilityCodeTransferring as CodeTransferring,
} from '@shared/types/functional_status'

type CapabilityKey = keyof Capability

const CAPABILITY_UNSPECIFIED_MAP: Record<
  CapabilityKey,
  | string
  | CodeBathing
  | CodeDressing
  | CodeGrooming
  | CodeToileting
  | CodeTransferring
  | CodeMobility
  | CodeEating
  | CodeCommunication
  | CodeMedication
  | CodeMoneyMgmt
> = {
  summary: '',
  bathing: CodeBathing.CAPABILITY_CODE_BATHING_UNSPECIFIED,
  dressing: CodeDressing.CAPABILITY_CODE_DRESSING_UNSPECIFIED,
  grooming: CodeGrooming.CAPABILITY_CODE_GROOMING_UNSPECIFIED,
  toileting: CodeToileting.CAPABILITY_CODE_TOILETING_UNSPECIFIED,
  transferring: CodeTransferring.CAPABILITY_CODE_TRANSFERRING_UNSPECIFIED,
  mobility: CodeMobility.CAPABILITY_CODE_MOBILITY_UNSPECIFIED,
  eating: CodeEating.CAPABILITY_CODE_EATING_UNSPECIFIED,
  communication: CodeCommunication.CAPABILITY_CODE_COMMUNICATION_UNSPECIFIED,
  medication: CodeMedication.CAPABILITY_CODE_MEDICATION_UNSPECIFIED,
  money: CodeMoneyMgmt.CAPABILITY_CODE_MONEY_MGMT_UNSPECIFIED,
}

const CAPABILITY_KEYS = Object.keys(CAPABILITY_UNSPECIFIED_MAP)

/**
 * Loop thought all keys of Capability (bathing, dressing, eating...)
 * If a true value is found, ex.: { bathing: true }
 * Replace it with appropriate Unspecified Code, ex.: { bathing: CODE_BATHING_UNSPECIFIED }
 * Do it for all entries in Capability[]
 */

export function fixCapabilities(capabilities?: Capability[]) {
  if (capabilities) {
    return capabilities.map((c) => {
      const index = CAPABILITY_KEYS.findIndex((k) => c[k] === true)
      if (index > -1) {
        const key = CAPABILITY_KEYS[index]
        return {
          ...c,
          [key]: CAPABILITY_UNSPECIFIED_MAP[key] as string,
        }
      }

      return c
    })
  }

  return undefined
}
