import { Fragment } from 'react'
import Card from '@shared/components/Card'
import { Diff, OrderAndDiff } from '@shared/types/medication_order'
import { formatDateToDateAtTimeLabel } from '@shared/utils/date'
import { getFullName } from '@shared/utils/humanName'
import { validStringOrNull } from '@shared/utils/parsing'
import { tw } from '@shared/utils/tailwind'
import { SectionTitle } from '@app/components/Residents/Medications/Orders/MedicationOrderDetails/layout'

type Props = {
  orderDiffs: OrderAndDiff
}
export const ReviewPendingChangesCard = (props: Props) => {
  const { orderDiffs } = props
  const hasDiffsToShow =
    orderDiffs.withDiffs.diffs.some(
      (diff) =>
        validStringOrNull(diff.originalValue) ||
        validStringOrNull(diff.updatedValue)
    ) ||
    orderDiffs.withDiffs.dosageDiffs.some((dosageDiff) =>
      dosageDiff.some(
        (dd) =>
          validStringOrNull(dd.originalValue) ||
          validStringOrNull(dd.updatedValue)
      )
    )

  if (!hasDiffsToShow) {
    return null
  }

  let warning: string | null = null
  if (orderDiffs.existingOrder.discontinue) {
    const fullName = getFullName(
      orderDiffs.existingOrder.discontinue.approvedBy!.modifiedByUserName
    )
    const dateAndTime = formatDateToDateAtTimeLabel(
      orderDiffs.existingOrder.discontinue.approvedBy!.modificationTime!
    )
    warning = `This medication was discontinued by ${fullName} on ${dateAndTime}. Approving this update will re-activate the medication.`
  }
  return (
    <div>
      <SectionTitle>Pending Changes</SectionTitle>
      <Card className={tw`flex-col [&>*:not(:last-child)]:mb-4`}>
        {warning && <div className={tw`text-tags-red`}>{warning}</div>}
        {orderDiffs.withDiffs.diffs.map((diff) => {
          return <LineDiff diff={diff} key={diff.key} />
        })}
        {orderDiffs.withDiffs.dosageDiffs.map((diff, index) => {
          return (
            <Fragment key={index}>
              {diff.map((d) => {
                return <LineDiff diff={d} key={d.key} />
              })}
            </Fragment>
          )
        })}
      </Card>
    </div>
  )
}

const LineDiff = ({ diff }: { diff: Diff }) => {
  const lineClasses = tw`mb-1 text-[14px] font-medium leading-tight`
  const valueClasses = tw`uppercase text-gray-04`
  const labelClasses = tw`mr-2 inline-block w-[40px] font-semibold normal-case text-gray-07 no-underline`

  return (
    <div key={diff.key}>
      <div className={lineClasses}>
        <span className={labelClasses}>New:</span>
        <span className={valueClasses}>{diff.updatedValue}</span>
      </div>
      <div className={lineClasses}>
        <span className={labelClasses}>Old:</span>
        <span className={`${valueClasses} text-gray-07 line-through`}>
          {diff.userConflictingEdit ?? diff.originalValue}
        </span>
      </div>
    </div>
  )
}
