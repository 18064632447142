import { UseFormReturn } from 'react-hook-form'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import { BillingChargeFormData } from '../helpers'

export default function QuantityInput({
  useFormReturn,
  disabled,
}: {
  useFormReturn: UseFormReturn<BillingChargeFormData>
  disabled?: boolean
}) {
  const {
    register,
    formState: { errors },
  } = useFormReturn
  return (
    <div>
      <LabelAboveInput
        uppercase={false}
        subLabel={requiredLabel(Boolean(errors.quantity))}
        htmlFor="quantity"
      >
        Quantity
      </LabelAboveInput>
      <BasicInput
        {...register('quantity', {
          required: true,
          valueAsNumber: true,
          validate: { positive: (value) => value > 0 },
          min: 1,
        })}
        type="number"
        disabled={disabled}
      />
    </div>
  )
}
