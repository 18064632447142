import { useContext, useEffect, useState } from 'react'
import { getResidentList } from '@shared/api/billing'
import GlobalContext from '@shared/contexts/GlobalContext'
import { ResidentListEntry } from '@shared/types/billing'
import { loaded, LOADING, Loading } from '@shared/utils/loading'

export default function useBillingResidentList({
  orgId,
  facilityId,
}: {
  orgId: string
  facilityId: string
}) {
  const { setError } = useContext(GlobalContext)
  const [residents, setResidents] =
    useState<Loading<ResidentListEntry[]>>(LOADING)

  async function reloadResidentList() {
    setResidents(LOADING)
    try {
      const newResidentList = await getResidentList({
        orgId,
        facilityId,
      })
      setResidents(loaded(newResidentList))
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    void reloadResidentList()
  }, [orgId, facilityId])

  return {
    residents,
    reloadResidentList,
  }
}
