import { flatten, orderBy } from 'lodash'
import {
  MedicationOrder,
  RxMedicationOrder,
  VitalMedicationOrder,
} from '@shared/types/medication_order'
import {
  isActive,
  isApproved,
  isDiscontinued,
  isExternal,
  isOnlyPRN,
  isPending,
  isPendingDiscontinue,
  isPendingNew,
  isPendingUpdate,
  isRejected,
  isTreatment,
  isWeakMatch,
} from '@shared/utils/medicationStatement'
import notEmpty from '@shared/utils/notEmpty'

export const getMedicationOrderKey = (order: MedicationOrder): string => {
  if (isPending(order as MedicationOrder)) {
    return `pending-${order.sourceData!.messageId!}`
  }

  const med = order.medicationStatement!
  const pharmacy = med.pharmacy
  const rxNumber = med.rxNumber
  const medId = order.id

  return [pharmacy, rxNumber, medId].filter(notEmpty).join('-')
}

export const getUnassignedMedicationOrderKey = (
  order: MedicationOrder
): string => {
  const pharmacy = order.medicationStatement?.pharmacy
  const externalMessageId = order.sourceData?.externalMessageId
  const externalOrderId = order.sourceData?.externalOrderId

  return [pharmacy, externalMessageId, externalOrderId]
    .filter(notEmpty)
    .join('-')
}

const alphabetizeByDrugName = (
  medOrders: RxMedicationOrder[]
): RxMedicationOrder[] => {
  return orderBy(medOrders, 'medicationStatement.medication.drugName')
}

export const getSortedMedicationOrderList = (
  rxOrders: RxMedicationOrder[]
): {
  weakMatchMeds: RxMedicationOrder[]
  activeMeds: RxMedicationOrder[]
  discontinuedMeds: RxMedicationOrder[]
} => {
  const pharmacyMessages = rxOrders.filter((medOrder) => isExternal(medOrder))
  const communityManagedOrders = rxOrders.filter(
    (medOrder) => !isExternal(medOrder)
  )

  const weakMatches = pharmacyMessages.filter((med) =>
    isWeakMatch(med as MedicationOrder)
  )
  const pendingNew = pharmacyMessages.filter((med) =>
    isPendingNew(med as MedicationOrder)
  )
  const pendingUpdate = pharmacyMessages.filter((med) =>
    isPendingUpdate(med as MedicationOrder)
  )
  const pendingDiscontinue = pharmacyMessages.filter((med) =>
    isPendingDiscontinue(med as MedicationOrder)
  )
  const approvedExternal = pharmacyMessages.filter(
    (med) =>
      isApproved(med as MedicationOrder) &&
      !isDiscontinued(med!.medicationStatement!)
  )

  const activeMeds = [
    ...communityManagedOrders.filter((med) =>
      isActive(med.medicationStatement!)
    ),
    ...approvedExternal,
  ]

  const scheduledMeds = activeMeds.filter(
    (med) =>
      !isOnlyPRN(med.medicationStatement!) &&
      !isTreatment(med.medicationStatement!)
  )
  const treatmentMeds = activeMeds.filter((med) =>
    isTreatment(med.medicationStatement!)
  )
  const prnOnlyMeds = activeMeds.filter(
    (med) =>
      isOnlyPRN(med.medicationStatement!) &&
      !isTreatment(med.medicationStatement!)
  )

  const discontinuedMeds = [
    ...communityManagedOrders.filter((med) =>
      isDiscontinued(med.medicationStatement!)
    ),
    ...pharmacyMessages.filter(
      (med) =>
        isDiscontinued(med!.medicationStatement!) &&
        !isPending(med as MedicationOrder)
    ),
  ]

  const activeMedsList = [
    alphabetizeByDrugName([
      ...pendingNew,
      ...pendingUpdate,
      ...pendingDiscontinue,
    ]),
    alphabetizeByDrugName(scheduledMeds),
    alphabetizeByDrugName(treatmentMeds),
    alphabetizeByDrugName(prnOnlyMeds),
  ]

  return {
    weakMatchMeds: alphabetizeByDrugName(weakMatches),
    activeMeds: flatten(activeMedsList),
    discontinuedMeds: alphabetizeByDrugName(discontinuedMeds),
  }
}

export const getSortedVitalOrderList = (
  vitalOrders: VitalMedicationOrder[]
): {
  discontinuedVitals: VitalMedicationOrder[]
  activeVitals: VitalMedicationOrder[]
} => {
  const discontinuedVitals = vitalOrders.filter((vital) =>
    isDiscontinued(vital.medicationStatement)
  )
  const activeVitals = vitalOrders.filter(
    (vital) => !isDiscontinued(vital.medicationStatement)
  )

  return {
    discontinuedVitals: discontinuedVitals,
    activeVitals: activeVitals,
  }
}

export const getPharmacyMessageTitle = (order: MedicationOrder) => {
  let text = 'Review Order'

  if (isPendingUpdate(order)) {
    text = 'Update Order'
  } else if (isPendingDiscontinue(order)) {
    text = 'Discontinue Order'
  } else if (isRejected(order)) {
    text = 'Rejected Order'
  } else if (isDiscontinued(order.medicationStatement) && !isPending(order)) {
    text = 'Discontinued Order'
  }

  return text
}
const getMedicationOrderTitle = (
  order: MedicationOrder,
  useOrderTerminology = true
) => {
  const noun = useOrderTerminology ? 'Order' : 'Medication'

  let text = `Edit ${noun}`

  if (isDiscontinued(order.medicationStatement)) {
    text = `Discontinued ${noun}`
  }

  return text
}

export const getMedicationBreadCrumbText = (
  medicationOrder: MedicationOrder,
  useOrderTerminology: boolean
) => {
  // only external pharmacy messages need to worry about the various states
  if (isExternal(medicationOrder)) {
    return getPharmacyMessageTitle(medicationOrder)
  }

  return getMedicationOrderTitle(medicationOrder, useOrderTerminology)
}
