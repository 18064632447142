import { CustomDetailsField } from '@augusthealth/models/com/august/protos/august_initial_appraisal'
import { AppraisalSettings_AppraisalCategory as AppraisalCategory } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, SubLabel } from '@shared/components/Labels'
import { DetailWithTextbox } from '@shared/types/assessment'
import { AssessmentChange } from '../types'

export default function TextboxDetail({
  detailData,
  onChange,
  category,
  selectedCustomDetails,
  disabled,
}: {
  detailData: DetailWithTextbox
  selectedCustomDetails: CustomDetailsField[]
  category: AppraisalCategory
  disabled: boolean
  onChange: (change: AssessmentChange) => void
}) {
  const defaultValue = selectedCustomDetails.find(
    (cd) => cd.id === detailData.value.id
  )?.textField?.value

  const subLabel: SubLabel | undefined = detailData.value.isRequired
    ? {
        tag: 'Required',
        hasError: disabled && (defaultValue ?? '').length === 0,
      }
    : undefined

  return (
    <div className={'mb-[16px] mt-[16px]'}>
      <LabelAboveInput
        uppercase={false}
        subLabel={subLabel}
        className={'mb-[4px]'}
      >
        {detailData.value.description}
      </LabelAboveInput>
      <div
        className={
          'mb-[8px] text-[14px] font-semibold leading-[18px] text-secondary-07'
        }
      >
        {detailData.value.helpText}
      </div>
      <BasicInput
        disabled={disabled}
        defaultValue={defaultValue}
        onBlur={(e) => {
          onChange({
            tag:
              e.currentTarget.value.length > 0
                ? 'AddUniversal'
                : 'RemoveUniversal',
            detail: detailData,
            category,
            text: e.currentTarget.value,
          })
        }}
      />
    </div>
  )
}
