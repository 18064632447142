import { Contact_ContactRelationship } from '@augusthealth/models/com/august/protos/contact'
import { cloneDeep } from 'lodash'
import { Person } from '@shared/types/person'
import { getPrimaryPhysician, getResponsiblePerson } from '@shared/utils/person'
import {
  getContacts,
  updateContact,
  updateContactGeneralPractitioner,
  updateContactResponsibleParty,
} from '@app/api/contacts'
import { LoaderProps, UpdaterProps } from './FormLayout/type'

type ResponseData = {
  data: Pick<Person, 'contact'>
}

export async function contactLoader(props: LoaderProps): Promise<ResponseData> {
  return getContacts(props).then((contact) => ({ data: { contact } }))
}

const rpSubFieldReg = /contact\[relationship=(\w+)]\.(\w+)/

export async function contactUpdater(
  props: UpdaterProps
): Promise<ResponseData> {
  // value: Current selected Contact or specific attribute of contact based on name
  const { name, pId: personId, value, patch: person } = props
  const match = name.match(rpSubFieldReg)
  let attr: string | undefined
  if (match && match.length > 2) {
    attr = match[2]
  }
  if (
    name.includes(
      Contact_ContactRelationship.CONTACT_RELATIONSHIP_RESPONSIBLE_PERSON
    )
  ) {
    if (attr) {
      let rp
      if (name.endsWith('.address')) {
        // Hacky Patch to fix RP Address Autocomplete in Generator
        const {
          data: { contact },
        } = await contactLoader(props)
        rp = cloneDeep(getResponsiblePerson({ contact }) || {})
        rp = { id: rp.id, address: value }
      } else {
        rp = person?.contact && getResponsiblePerson(person)
      }
      // Update specific value of Responsible Person
      if (rp?.id) {
        return updateContact({
          ...props,
          personId,
          contact: rp,
        }).then(() => contactLoader(props))
      }

      throw new Error('Responsible Person not found!')
    }

    if (value?.id) {
      return updateContactResponsibleParty({
        ...props,
        personId,
        contactId: value.id,
      }).then(() => contactLoader(props))
    }

    throw new Error('You cannot remove Responsible Person, but switch.')
  } else if (
    name.includes(
      Contact_ContactRelationship.CONTACT_RELATIONSHIP_GENERAL_PRACTITIONER
    )
  ) {
    if (attr) {
      // Update specific value of Primary Physician
      const pp = person?.contact && getPrimaryPhysician(person)
      if (pp) {
        return updateContact({
          ...props,
          personId,
          contact: pp,
        }).then(() => contactLoader(props))
      }

      throw new Error('Primary Physician not found!')
    }

    if (value?.id) {
      return updateContactGeneralPractitioner({
        ...props,
        personId,
        contactId: value.id,
      }).then(() => contactLoader(props))
    }

    throw new Error('You cannot remove Primary Physician, but switch.')
  }

  if (attr) {
    throw new Error(
      'Unable to update specific attribute of contact with relationship other than Responsible Person or Primary Physician!'
    )
  }

  return updateContact({
    ...props,
    personId,
    contact: value,
  }).then(() => contactLoader(props))
}
