import { useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Card from '@shared/components/Card'
import GlobalContext from '@shared/contexts/GlobalContext'
import useCurrentPage, { extractIds } from '@shared/hooks/useCurrentPage'
import { medicationsPathForPerson } from '@shared/legacy_routes'
import { MedOrderFormData } from '@shared/types/medication_order'
import { Person } from '@shared/types/person'
import { tw } from '@shared/utils/tailwind'
import { createMedicationOrder } from '@app/api/medicationOrders'
import FullpageFormFooter from '@app/components/FullpageFormFooter'
import { mapNewFormMedToMedOrder } from '@app/components/Residents/Medications/AddMedication/helpers'
import NewOrderScheduleCard from '@app/components/Residents/Medications/AddMedication/NewOrderScheduleCard'
import MedicationSearchAndInstructions from '@app/components/Residents/Medications/MedicationSearch/MedicationSearchAndInstructions'
import {
  DetailSectionWrapper,
  FullMedicationOrderWrapper,
  MedicationOrderLoading,
  OrderSectionWrapper,
} from '@app/components/Residents/Medications/Orders/MedicationOrderDetails/layout'
import {
  doesFormHaveMissingMedicationInformation,
  doesFormHaveSchedulingErrors,
  newMedicationOrderDefaultValues,
} from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'
import ReviewOrderHoldForCard from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderHoldForCard/ReviewOrderHoldForCard'
import ReviewOrderPrescriptionDetailsCard from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderPrescriptionDetailsCard'
import {
  errorIsTaperMultipartValidation,
  TaperValidationError,
} from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/TaperSchedule/helpers'
import ReviewOrderTreatmentCard from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderTreatmentCard'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import { usePersonInContext } from '@app/hooks/usePerson'

export default function AddMedication() {
  const { setError } = useContext(GlobalContext)
  const page = useCurrentPage()
  const { orgId, facilityId, personId } = extractIds(page)

  const {
    settings: { use24HourClock },
  } = useCurrentFacility()
  const { person } = usePersonInContext({
    initialData: {
      orgId,
      facilityId,
      id: personId,
    },
    skipFirstFetch: true,
  })
  const history = useHistory()
  const methods = useForm<MedOrderFormData>({
    mode: 'onSubmit',
    defaultValues: newMedicationOrderDefaultValues,
  })

  const formData = methods.watch()

  if (!person) {
    return <MedicationOrderLoading title={'New Order'} />
  }

  const approveMed = async () => {
    const formData = methods.getValues()
    const updatedWithForm = mapNewFormMedToMedOrder({
      formData,
      personId: person.id,
    })

    try {
      const response = await createMedicationOrder(person, updatedWithForm)

      history.push(medicationsPathForPerson(person), {
        highlightId: response.id.toString(),
        statusChange: 'Saved',
      })
    } catch (e) {
      if (errorIsTaperMultipartValidation(e)) {
        setError(TaperValidationError)
      } else {
        setError(e)
      }
      methods.reset(formData, {
        keepDirtyValues: true,
        keepTouched: true,
      })
    }
  }

  const formHasErrors = Object.keys(methods.formState.errors).length > 0
  const showRequiredFieldsAtTop = doesFormHaveMissingMedicationInformation(
    methods.formState
  )

  const hasSelectedMedOrVital = !!formData.displayName || !!formData.vitalType
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(approveMed)}>
        <FullMedicationOrderWrapper title={'New Order'}>
          <OrderSectionWrapper showRequired={showRequiredFieldsAtTop}>
            <Card className={tw`p-[16px]`}>
              <MedicationSearchAndInstructions />
            </Card>
          </OrderSectionWrapper>

          {hasSelectedMedOrVital && (
            <DetailSectionWrapper
              showRequired={doesFormHaveSchedulingErrors(methods.formState)}
            >
              <NewOrderScheduleCard
                use24HourTime={use24HourClock}
                personId={person.id}
              />
              <ReviewOrderTreatmentCard />
              <ReviewOrderPrescriptionDetailsCard
                readOnly={false}
                person={person}
              />
              <ReviewOrderHoldForCard use24HourTime={use24HourClock} />
            </DetailSectionWrapper>
          )}
        </FullMedicationOrderWrapper>

        <FullpageFormFooter
          yesBtn={{
            text: 'Save',
            props: {
              id: 'saveMedicationOrder',
              type: 'submit',
              disabled: formHasErrors || !hasSelectedMedOrVital,
            },
          }}
          noBtn={{
            text: 'Cancel',
            action: async () =>
              history.push(
                medicationsPathForPerson(person as Required<Person>)
              ),
          }}
          formState={methods.formState}
        />
      </form>
    </FormProvider>
  )
}
