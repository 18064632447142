import { ReportAssignment } from '@augusthealth/models/com/august/protos/report'
import { Facility } from '@shared/types/facility'

export type Props = {
  facilityId?: string
  orgId?: string
}

export function filterInFacilities({
  facilities,
  facilityId,
  orgId,
}: Props & {
  facilities: Facility[]
}) {
  return facilities.filter((f) => {
    return (
      (facilityId === undefined || facilityId === f.id) &&
      (orgId === undefined || orgId === f.orgId)
    )
  })
}

export function filterOutAssignments({
  assignments,
  facilityId,
  orgId,
}: Props & {
  assignments: ReportAssignment[]
}) {
  return assignments.filter((a) => {
    return (
      (facilityId !== undefined && facilityId !== a.facilityId) ||
      (orgId !== undefined && orgId !== a.orgId)
    )
  })
}

export function mergeAssignments({
  existingAssignments,
  newAssignments,
}: {
  existingAssignments: ReportAssignment[]
  newAssignments: ReportAssignment[]
}) {
  const returnedAssignments = [...existingAssignments]
  newAssignments.forEach((newA: ReportAssignment) => {
    if (!returnedAssignments.find((a) => newA.id === a.id)) {
      returnedAssignments.push(newA)
    }
  })

  return returnedAssignments
}
