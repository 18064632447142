import {
  ChangeTaskDueDateRequest,
  GetTaskDueDateResponse,
} from '@augusthealth/models/com/august/protos/api/task_due_date'
import { useContext } from 'react'
import DatePicker from 'react-datepicker'
import { Controller, useForm } from 'react-hook-form'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { DataType } from '@shared/types/snapshot'
import {
  fromDateMessageToDate,
  fromDateToDateMessage,
  monthDayYear,
} from '@shared/utils/date'
import { updateTaskDueDate } from '@app/api/tasks'
import styles from './styles.module.css'

export default function EditDueDate({
  dueDate,
  person,
  onClose,
}: {
  person: Person
  dueDate: GetTaskDueDateResponse
  onClose: (updated: boolean) => Promise<void>
}) {
  const { setError } = useContext(GlobalContext)
  const { control, handleSubmit, formState } =
    useForm<ChangeTaskDueDateRequest>({
      defaultValues: {
        dueDate: dueDate.openTaskDueDate ?? dueDate.scheduledDueDate,
      },

      mode: 'all',
      criteriaMode: 'all',
    })

  const onSubmit = async ({ dueDate }: ChangeTaskDueDateRequest) => {
    try {
      if (dueDate) {
        await updateTaskDueDate({
          person,
          dataType: DataType.DATA_TYPE_AUGUST_INITIAL_APPRAISAL,
          dueDate,
        })
        await onClose(true)
      }
    } catch (e) {
      setError(e)
    }
  }

  const scheduledDueDate = dueDate.scheduledDueDate ?? undefined
  const userSetDueDate = dueDate.openTaskDueDate ?? undefined

  const description = scheduledDueDate
    ? `This resident's next scheduled assessment must be completed on or before ${monthDayYear(
        scheduledDueDate
      )}. If you would like to assess them sooner, change the date here.`
    : `
    If you would like to assess this resident sooner than their next
    scheduled assessment, change the date here.
    `
  return (
    <AnimatedPopup title="Next Assessment Date">
      <div className={styles.description}>{description}</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="dueDate"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              id="dueDate"
              selected={fromDateMessageToDate(value)}
              onChange={(v: Date | null, context) => {
                onChange(v ? fromDateToDateMessage(v) : null, context)
              }}
              customInput={<CalendarInput data-testid="calendar" />}
              portalId={'root'}
              maxDate={fromDateMessageToDate(
                userSetDueDate ?? scheduledDueDate
              )}
            />
          )}
        />
        <div className="mt-[32px]">
          <AnimatedPopupFormFooter
            yesBtn={{}}
            noBtn={{ action: () => void onClose(false) }}
            formState={formState}
          />
        </div>
      </form>
    </AnimatedPopup>
  )
}
