import { mergePatchPerson } from '@shared/api/person'
import { Person } from '@shared/types/person'

export async function updateNotes(
  newNote: string,
  person: Person
): Promise<{ prospectInfo: { details: string } }> {
  const { id: personId, facilityId, orgId } = person

  const { data } = await mergePatchPerson({
    fId: facilityId,
    pId: personId,
    orgId,
    patch: {
      prospectInfo: {
        details: newNote,
      },
    },
    fields: ['prospectInfo/details'],
  })

  return data
}
