import { useContext } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { addNewUser, patchUser } from '@shared/api/user'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import PasswordField from '@shared/components/Auth/LoginWithUsernameOrEmail/PasswordField/PasswordField'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { strictPolicyRequirement } from '@shared/constants/passwordPolicy'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Group, GroupType } from '@shared/types/permission'
import { UserAccount } from '@shared/types/user'
import { getGroupLabelAndType } from '@shared/utils/permisson'
import { isAnalyst, isSuperUser, isToolsUser } from '@shared/utils/user'
import { listOrganizations } from '@app/api/organizations'
import { setPassword } from '@app/api/users'
import EmailInputWithValidation from '@app/components/reactHookForm/EmailInputWithValidation'
import FullNameInputs from '@app/components/reactHookForm/FullNameInputs'
import MultiFactorAuthenticationInput from '@app/components/reactHookForm/MultiFactorAuthenticationInput'

function getOption(groupType: GroupType) {
  return {
    label: getGroupLabelAndType(groupType).label,
    value: [
      {
        groupType,
        personMatcher: {},
      },
    ],
  }
}

const OPTIONS: OptionTypeBase<Group[]>[] = [
  getOption(GroupType.GROUP_TYPE_ANALYST),
  getOption(GroupType.GROUP_TYPE_TOOL_USER),
  getOption(GroupType.GROUP_TYPE_SUPER_USER),
]

function getDefaultRole(groups: Group[] | undefined) {
  if (!groups) return null

  const userWithGroups = { groups } as UserAccount
  if (isSuperUser(userWithGroups)) {
    return getOption(GroupType.GROUP_TYPE_SUPER_USER)
  } else if (isToolsUser(userWithGroups)) {
    return getOption(GroupType.GROUP_TYPE_TOOL_USER)
  } else if (isAnalyst(userWithGroups)) {
    return getOption(GroupType.GROUP_TYPE_ANALYST)
  }

  return null
}

type UserAccountWithPassword = Omit<UserAccount, 'phoneNumber'> & {
  password?: string
  oneTimeUse?: boolean
  phoneNumber?: string | null
  resetMfa?: boolean
}

export default function AddEditInternalUserPopup({
  user,
  onClose,
  reload,
  disabled = false,
  setNotice,
}: {
  user: UserAccountWithPassword
  onClose: () => void
  reload: () => Promise<void>
  disabled?: boolean
  setNotice: (text: string) => void
}) {
  const { setError } = useContext(GlobalContext)
  const useFormReturn = useForm<UserAccountWithPassword>({
    defaultValues: user,
    mode: 'all',
    criteriaMode: 'all',
  })
  const { control, formState, handleSubmit, register, setValue } = useFormReturn
  const { errors, isDirty, isValid } = formState
  const isAddMode = Boolean(user.id)

  let title = 'Add User'
  if (disabled) {
    title = 'User Details'
  } else if (isAddMode) {
    title = 'Edit User'
  }

  const addPassword = async ({
    userId,
    password,
    oneTimeUse,
  }: {
    userId: string
    password: string
    oneTimeUse: boolean
  }) => {
    const orgs = await listOrganizations()
    const orgId = orgs.length ? orgs[0].id : '2' // Current POST Password API requires an OrgId

    return await setPassword({ userId, password, oneTimeUse, orgId })
  }

  const onSubmit = async (data: UserAccountWithPassword) => {
    const { password, oneTimeUse, resetMfa, ...updatedUser } = data
    const { id, name, email, groups } = updatedUser
    try {
      if (id) {
        await patchUser({
          user: {
            id,
            name,
            email,
            groups,
            ...(resetMfa && { phoneNumber: null }),
          },
        })
        if (password) {
          await addPassword({
            userId: id,
            password,
            oneTimeUse: oneTimeUse ?? false,
          })
        }
        setNotice('User updated!')
      } else {
        const res = await addNewUser(updatedUser)
        const newId = res.data.id.toString()
        if (password) {
          await addPassword({
            userId: newId,
            password,
            oneTimeUse: oneTimeUse ?? false,
          })
        }
        setNotice('User created!')
      }
      await reload()
      onClose()
    } catch (err) {
      setError(err)
    }
  }

  return (
    <AnimatedPopup title={title}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FullNameInputs
          register={register}
          errors={errors}
          disabled={disabled}
        />
        <EmailInputWithValidation
          register={register}
          errors={errors}
          disabled={disabled}
        />
        <MultiFactorAuthenticationInput
          inputProps={{ ...register('resetMfa') }}
          user={user}
          disabled={disabled}
          onReset={() => {
            setValue('resetMfa', true, { shouldDirty: true })
          }}
        />
        {!disabled && (
          <FormProvider {...useFormReturn}>
            <PasswordField
              disabled={false}
              isEmailAuth
              isAddUser={isAddMode}
              requirements={strictPolicyRequirement}
            />
          </FormProvider>
        )}
        <div className="mb-[32px]">
          <LabelAboveInput
            htmlFor="role-dropdown"
            subLabel={requiredLabel(Boolean(errors.groups))}
          >
            Role
          </LabelAboveInput>
          <Controller
            control={control}
            name="groups"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => {
              return (
                <StyledSelect
                  inputId="role-dropdown"
                  onChange={(opt: { value }) => {
                    onChange(opt.value)
                  }}
                  options={OPTIONS}
                  isDisabled={disabled}
                  value={getDefaultRole(value)}
                />
              )
            }}
          />
        </div>
        <AnimatedPopupFormFooter
          formState={formState}
          noBtn={{ action: onClose }}
          yesBtn={{
            props: {
              disabled: !isDirty || !isValid,
            },
          }}
        />
      </form>
    </AnimatedPopup>
  )
}
