import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function ScrollToTop() {
  const { pathname } = useLocation()
  // The documents viewers/modals have their own url (.../documents/SNAPSHOT_ID)
  // but we don't want to scroll the underlying page when opening the viewer
  const isDocumentViewer = pathname.includes('document')

  useEffect(() => {
    if (!isDocumentViewer) {
      window.scrollTo(0, 0)
    }
  }, [pathname])

  return null
}
