import { MedPass } from '@augusthealth/models/com/august/protos/api/med_pass'
import { EventTiming } from '@augusthealth/models/com/august/protos/timing'
import { Controller, UseFormReturn } from 'react-hook-form'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import ButtonLink from '@shared/components/ButtonLink'
import Card from '@shared/components/Card'
import { LabelAboveInput, requiredWhenError } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { convertTimeToTimeInputValue } from '@shared/utils/date'
import { tw } from '@shared/utils/tailwind'
import { fromTimeStringToTime } from '@shared/utils/time'
import { EVENT_TIMING_OPTIONS } from '@shared/utils/timing'

export type FormData = { medPasses: MedPass[] }

export default function MedPassItem({
  index,
  onDelete,
  useFormReturn,
}: {
  index: number
  onDelete: () => void
  useFormReturn: UseFormReturn<FormData>
}) {
  const { control, formState, register } = useFormReturn
  const { errors } = formState
  let hasNameError = false
  let hasStartTimeError = false
  let hasEndTimeError = false
  if (Array.isArray(errors.medPasses) && errors.medPasses[index]) {
    hasNameError = Boolean(errors.medPasses[index].name)
    hasStartTimeError = Boolean(errors.medPasses[index].period?.startTime)
    hasEndTimeError = Boolean(errors.medPasses[index].period?.endTime)
  }

  return (
    <Card>
      <div className={tw`flex-1`}>
        <input type="hidden" {...register(`medPasses.${index}.id`)} />
        <input type="hidden" {...register(`medPasses.${index}.facilityId`)} />
        <div>
          <LabelAboveInput
            htmlFor={`medPasses.${index}.name`}
            subLabel={requiredWhenError(hasNameError)}
          >
            Name
          </LabelAboveInput>
          <BasicInput
            {...register(`medPasses.${index}.name`, { required: true })}
          />
        </div>
        <div className={tw`mt-[16px]`}>
          <LabelAboveInput htmlFor={`medPasses.${index}.eventTiming`}>
            Event Timing
          </LabelAboveInput>
          <Controller
            control={control}
            name={`medPasses.${index}.eventTiming`}
            render={({ field: { onChange, value } }) => (
              <StyledSelect
                inputId={`medPasses.${index}.eventTiming`}
                isClearable
                name={`medPasses.${index}.eventTiming`}
                value={
                  EVENT_TIMING_OPTIONS.find((opt) => opt.value === value) ||
                  null
                }
                onChange={(opt: OptionTypeBase<EventTiming> | null) =>
                  opt ? onChange(opt.value) : onChange(null)
                }
                options={EVENT_TIMING_OPTIONS}
              />
            )}
          />
        </div>
        <div className={tw`mt-[16px] flex`}>
          <div className={tw`mr-[8px] flex-1`}>
            <LabelAboveInput
              htmlFor={`medPasses.${index}.period.startTime`}
              subLabel={requiredWhenError(hasStartTimeError)}
            >
              Start Time
            </LabelAboveInput>
            <Controller
              control={control}
              name={`medPasses.${index}.period.startTime`}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <BasicInput
                  id={`medPasses.${index}.period.startTime`}
                  type="time"
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                    if (ev.target.value) {
                      onChange(fromTimeStringToTime(ev.target.value))
                    }
                  }}
                  value={value ? convertTimeToTimeInputValue(value) : undefined}
                />
              )}
            />
          </div>
          <div className={tw`ml-[8px] flex-1`}>
            <LabelAboveInput
              htmlFor={`medPasses.${index}.period.endTime`}
              subLabel={requiredWhenError(hasEndTimeError)}
            >
              End Time
            </LabelAboveInput>
            <Controller
              control={control}
              name={`medPasses.${index}.period.endTime`}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <BasicInput
                  id={`medPasses.${index}.period.endTime`}
                  type="time"
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                    if (ev.target.value) {
                      onChange(fromTimeStringToTime(ev.target.value))
                    }
                  }}
                  value={value ? convertTimeToTimeInputValue(value) : undefined}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className={tw`flex-0 ml-[8px]`}>
        <ButtonLink onClick={onDelete}>
          <i className={tw`fa fa-trash`} />
        </ButtonLink>
      </div>
    </Card>
  )
}
