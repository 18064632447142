import styles from './styles.module.css'
import ButtonGroup from '../generic/ButtonGroup'
import BaseUI from './index'

type Props = {
  redirectToDocumentsPage: () => void
  redirectToTasksPage: () => void
}

/**
 * Display after upload, submit or sign a document (except sign in person)
 */
export default function CompletedTaskVisitDocuments({
  redirectToDocumentsPage,
  redirectToTasksPage,
}: Props) {
  return (
    <BaseUI
      content={
        <>
          <h4>Nice work!</h4>
          <p>
            You are done with this task. The completed
            <br />
            document got saved to the <strong>Resident Documents</strong>.
          </p>
        </>
      }
      footer={
        <ButtonGroup
          className="vertical"
          options={[
            {
              id: 'congrats-continue',
              label: 'GOT IT',
              className: `font-bold text-[11px] leading-[14px] rounded-[6px] py-[11px] px-[12px] hover:brightness-125 bg-button-primary-color text-button-fill-font-color  ${styles.largeButton}`,
              onClick: redirectToTasksPage,
            },
            {
              id: 'congrats-visit-documents',
              label: 'VISIT DOCUMENTS',
              className: `font-bold text-[11px] leading-[14px] rounded-[6px] py-[11px] px-[12px] hover:bg-button-tertiary-color hover:text-button-fill-font-color text-button-tertiary-color border-button-tertiary-color bg-button-default-bg-color border ${styles.largeButton}`,
              onClick: redirectToDocumentsPage,
            },
          ]}
        />
      }
    />
  )
}
