import { useContext, useState } from 'react'
import { fetchBlobUrlAndContentType } from '@shared/api/request'
import ButtonLink from '@shared/components/ButtonLink'
import Icon from '@shared/components/Icon'
import CurrentFacilityContext from '@shared/contexts/CurrentFacilityContext'
import GlobalContext from '@shared/contexts/GlobalContext'
import { DataType } from '@shared/types/snapshot'
import { displayCPRCode } from '@shared/utils/person'
import { getFormattedCompletionDate } from '@shared/utils/snapshot'
import { tw } from '@shared/utils/tailwind'
import PersonContext from '@app/contexts/PersonContext'
import { ArchivedDocument } from '../helpers'

interface Props {
  documents: ArchivedDocument[]
}

export default function ArchivedVersions({ documents }: Props) {
  const { convertToFacilityTimezone } = useContext(CurrentFacilityContext)
  const { person } = useContext(PersonContext)
  const [showArchived, setShowArchived] = useState(false)

  if (person === undefined) {
    return null
  }

  const listItem = (version: ArchivedDocument, ix: number) => {
    const completionDate = getFormattedCompletionDate(
      version,
      convertToFacilityTimezone
    )

    switch (version.mode) {
      case 'View':
        return (
          <ViewableListItem
            document={version}
            key={`av-view-${ix}`}
            completionDate={completionDate}
          />
        )
      case 'Download':
        return (
          <DownloadableListItem
            document={version}
            key={`av-download-${ix}`}
            completionDate={completionDate}
          />
        )
      case 'No Files':
        return (
          <NoFiles
            document={version}
            key={`av-noFiles-${ix}`}
            completionDate={completionDate}
          />
        )
    }
  }

  return (
    <div className="mt-[24px]">
      <button
        className="cursor-pointer border-0 bg-transparent p-0 text-[12px] font-semibold uppercase text-secondary"
        onClick={() => {
          setShowArchived(!showArchived)
        }}
        type="button"
      >
        <Icon name={'history'} className={'mr-[8px]'} />
        View Archived Versions ({documents.length})
      </button>{' '}
      {showArchived && (
        <ul className="mt-[8px] list-none p-0 text-[12px] text-secondary">
          {documents.map(listItem)}
        </ul>
      )}
    </div>
  )
}

function ViewableListItem({
  document,
  completionDate,
}: {
  document: ArchivedDocument
  completionDate: string
}) {
  return (
    <li className={tw`ml-[20px]`}>
      <Icon name="file" className={tw`fa-fw mr-[8px]`} />
      <a
        target="_blank"
        className={tw`font-medium`}
        href={document.fileUrl}
        rel="noreferrer"
        title="View document"
      >
        {completionDate}
      </a>
    </li>
  )
}

function DownloadableListItem({
  document,
  completionDate,
}: {
  document: ArchivedDocument
  completionDate: string
}) {
  const { setError } = useContext(GlobalContext)
  const [loading, setLoading] = useState(false)

  const onClick = async () => {
    setLoading(true)
    try {
      const blobData = await fetchBlobUrlAndContentType({
        url: document.fileUrl,
      })
      window.open(blobData.url, '_archived_doc', 'noreferrer')
    } catch (err) {
      setError(err)
    }

    setLoading(false)
  }

  return (
    <li className={tw`ml-[20px]`}>
      <Icon className={tw`fa-fw mr-[8px]`} name="file" />
      <ButtonLink
        className={tw`font-medium uppercase text-secondary`}
        disabled={loading}
        onClick={onClick}
        title="Download document"
      >
        {loading ? 'Loading...' : completionDate}
      </ButtonLink>
    </li>
  )
}

/**
 * For now, only expect POLST without attachment using NoFiles component
 */
function NoFiles({
  document,
  completionDate,
}: {
  document: ArchivedDocument
  completionDate: string
}) {
  const cprCode =
    document.dataType === DataType.DATA_TYPE_POLST
      ? document.data?.uploadInfo?.polst?.cprCode
      : undefined
  const cprCodeLabel = cprCode ? displayCPRCode({ cprCode }) : 'No Data'

  return (
    <li className={tw`ml-20px`}>
      <Icon className={tw`fa-fw mr-[8px]`} name="info-circle" />
      <span className={tw`font-medium uppercase text-secondary`}>
        {completionDate} ({cprCodeLabel})
      </span>
    </li>
  )
}
