import { ModalProps } from '@shared/components/baseMui/Modal/Layout'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { PersonCard } from '@shared/components/LinkCard/PersonCard'
import { CareAppPerson } from '@shared/types/careapp'
import { tw, twx } from '@shared/utils/tailwind'

type Props = Omit<ModalProps, 'open'> & {
  careAppPerson: CareAppPerson
}

export default function RoutineModal(props: Props) {
  const { careAppPerson, children, contentClassName, title, ...restProps } =
    props
  const {
    admissionsInformation: { bedDetails, roomDetails },
  } = careAppPerson

  return (
    <Modal
      contentClassName={twx('w-[565px] min-w-[565px]', contentClassName)}
      open
      {...restProps}
    >
      <PersonCard
        cardClassName={tw`mb-[24px]`}
        anchorClassName={tw`text-gray-01`}
        href=""
        person={careAppPerson}
        roomInfo={{ bedDetails, roomDetails }}
      />
      {children}
    </Modal>
  )
}
