import { useHistory } from 'react-router-dom'
import { notesPathForPerson } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import styles from './styles.module.css'

export default function AlertPill({ person }: { person: Person }) {
  const history = useHistory()

  return (
    <button
      data-cy={'alert-pill'}
      className={styles.pill}
      onClick={() =>
        history.push(notesPathForPerson(person as Required<Person>))
      }
    >
      <i className={'fa fa-circle-exclamation fa-lg mr-[8px]'} />
      <span>On Alert</span>
    </button>
  )
}
