import { Contact } from '@augusthealth/models/com/august/protos/contact'
import { useContext, useState } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import { DotDivider } from '@shared/components/DotDivider'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { UserAccount } from '@shared/types/user'
import { buildAddressLabel } from '@shared/utils/address'
import { isEmptyObject } from '@shared/utils/common'
import {
  isPrimaryCareProvider,
  isResponsiblePerson,
} from '@shared/utils/contact'
import { getFullName } from '@shared/utils/humanName'
import { getResponsiblePerson } from '@shared/utils/person'
import { getMainRelationship } from '@shared/utils/relationship'
import ConfirmModal, {
  Props as ConfirmModalProps,
} from '@app/components/ConfirmModal'
import Warning from '@app/components/Warning'
import { usePersonInContext } from '@app/hooks/usePerson'
import styles from './styles.module.css'
import {
  allContactActions,
  makeContactPrimaryCare,
  makeContactRP,
  removeContact,
} from './helpers'

type Props = {
  user: UserAccount
  contact: Contact
  person: Person
  refreshContacts: () => Promise<void>
}

export default function ContactActions({
  user,
  contact,
  person,
  refreshContacts,
}: Props) {
  const { setError } = useContext(GlobalContext)

  const { refreshPerson } = usePersonInContext({
    initialData: person,
    skipFirstFetch: true,
  })
  const [confirmationModalProps, setConfirmationModalProps] =
    useState<ConfirmModalProps>()
  const handleEvent = ({ event, adminAction }) => {
    event.stopPropagation()
    event.preventDefault()
    if (!event.key || event.key === 'Enter') {
      adminAction(contact)
    }
  }
  const isMainRole =
    isResponsiblePerson(contact) || isPrimaryCareProvider(contact)

  // setup the modal with the delete message
  const hideConfirmation = () => setConfirmationModalProps(undefined)
  const onRemove = (contact: Contact) => {
    setConfirmationModalProps((existing) => ({
      ...existing,
      title: 'Do you want to remove this contact?',
      content: getConfirmModalContent(contact),
      confirmButtonConfig: {
        children: 'Remove Contact',
        buttonStyle: 'danger-fill',
        onClick: async () => {
          try {
            await removeContact(contact, person)
            await refreshContacts()
            await refreshPerson()
          } catch (error) {
            setError(error)
          } finally {
            hideConfirmation()
          }
        },
      },
      denyButtonConfig: {
        onClick: hideConfirmation,
      },
    }))
  }

  // setup the modal with the RP message
  const onMakeContactRP = (contact: Contact) => {
    const modalContent = (
      <>
        {getConfirmModalContent(contact)}
        {getResponsiblePerson(person!) && (
          <Warning className="mt-[24px]">
            Incomplete tasks that were previously shared will be unshared &amp;
            any documents awaiting signature will be canceled.{' '}
            <strong>Are you sure?</strong>
          </Warning>
        )}
      </>
    )
    setConfirmationModalProps((existing) => ({
      ...existing,
      title: 'Make them the Responsible Person?',
      content: modalContent,
      confirmButtonConfig: {
        children: 'Make Responsible Person',
        buttonStyle: 'primary-fill',
        onClick: async () => {
          try {
            await makeContactRP(contact, person)
            await refreshContacts()
            await refreshPerson()
          } catch (error) {
            setError(error)
          } finally {
            hideConfirmation()
          }
        },
      },
      denyButtonConfig: {
        onClick: hideConfirmation,
      },
    }))
  }

  // setup the modal with the PCP message
  const onMakeContactPrimaryCare = (contact: Contact) => {
    setConfirmationModalProps((existing) => ({
      ...existing,
      title: 'Make them the Primary Care Provider?',
      content: getConfirmModalContent(contact),
      confirmButtonConfig: {
        children: 'Make Primary Care Provider',
        buttonStyle: 'primary-fill',
        onClick: async () => {
          try {
            await makeContactPrimaryCare(contact, person)
            await refreshContacts()
          } catch (error) {
            setError(error)
          } finally {
            hideConfirmation()
          }
        },
      },
      denyButtonConfig: {
        onClick: hideConfirmation,
      },
    }))
  }

  const allActions = allContactActions({
    contact,
    onRemove,
    onMakeContactRP,
    onMakeContactPrimaryCare,
  })

  const availableActions = allActions.filter((action) => {
    return hasPermissionForPerson({
      user,
      person,
      permissions: action.permissions,
    })
  })

  if (availableActions.length === 0) {
    return null
  }

  // actually render the footer and the modal
  return (
    <>
      {confirmationModalProps && <ConfirmModal {...confirmationModalProps} />}
      <div className={styles.footer} data-testid="contact-actions">
        {availableActions.map((availableAction, index) => (
          <span key={`${availableAction.name}-${index}`}>
            {index !== 0 && <DotDivider />}
            <ButtonLink
              id={availableAction.name}
              data-testid={`button-${availableAction.name}`}
              disabled={isMainRole}
              tabIndex={0}
              className={styles.action}
              onClick={(event) =>
                handleEvent({
                  event,
                  adminAction: availableAction.adminAction,
                })
              }
            >
              <i
                className={`${availableAction.iconClass} ${styles.actionIcon}`}
              />
              <span className={styles.actionText}>{availableAction.label}</span>
            </ButtonLink>
          </span>
        ))}
      </div>
    </>
  )
}

function getConfirmModalContent(contact: Contact) {
  const { address, name, relationship, telecom } = contact
  const fullName = getFullName(name!)
  const relationshipLabel = getMainRelationship(relationship ?? [])
  const addressLabel = buildAddressLabel(address, { abbreviated: true })
  const contactPoints: React.ReactNode[] = []

  telecom?.forEach((t, index) => {
    if (!isEmptyObject(t)) {
      contactPoints.push(
        <div key={`${t.value}-${index}`} className={styles.contactSpan}>
          {t.value}
        </div>
      )
    }
  })

  return (
    <div className={'mt-[16px]'}>
      <span className="font-semibold">
        {fullName} ({relationshipLabel})
      </span>
      <br />
      {contactPoints}
      {addressLabel}
    </div>
  )
}
