import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form'
import {
  LabelledCalendarInput,
  LabelledCalendarInputProps,
} from '@shared/components/CalendarInput/LabelledCalendarInput'

/**
 * @param inputProps.onChange - additional actions to take when the value changes. Parameter will be a non-transformed Date object.
 */
type Props<Form extends FieldValues, SavedValueType> = {
  control: Control<Form>
  name: FieldPath<Form>
  rules?: RegisterOptions<Form>
  convertToSavedValue: (d: Date | null) => SavedValueType
  convertToSelectedValue: (val: SavedValueType) => Date | undefined
  inputProps: Omit<LabelledCalendarInputProps, 'onChange'> & {
    onChange?: (d: Date | null) => void
    allowErrorBorder?: boolean
    label: string
  }
}
export function ControlledLabelledCalendarInput<
  Form extends FieldValues,
  SavedValueType,
>({
  control,
  name,
  rules,
  inputProps,
  convertToSelectedValue,
  convertToSavedValue,
}: Props<Form, SavedValueType>) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <LabelledCalendarInput
            {...inputProps}
            label={inputProps.label}
            iconName={inputProps.iconName}
            name={name}
            onChange={(d: Date | null) => {
              const convertedValue = d ? convertToSavedValue(d) : null
              onChange(convertedValue)
              inputProps.onChange?.(d)
            }}
            selected={value ? convertToSelectedValue(value) : null}
            showErrorBorder={inputProps.allowErrorBorder && !!error}
            portalId={'root'}
          />
        )
      }}
    />
  )
}
