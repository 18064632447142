import { GroupType } from '@augusthealth/models/com/august/protos/permission'
import { some } from 'lodash'
import { Facility } from '@shared/types/facility'
import { UserAccount } from '@shared/types/user'
import { getFullName } from '@shared/utils/humanName'
import { getGroupLabelAndType } from '@shared/utils/permisson'
import {
  getRelevantFacilities,
  hasBillingRole,
  hasCaregiverPlusEhrRole,
  hasCaregiverRole,
  hasDirectorRole,
  hasFacilitySalesAndMarketingRole,
  hasFacilityStaffRole,
  hasFacilityUserManagementRole,
  hasFrontDeskRole,
  hasMedTechRole,
  hasMedTechSupervisorRole,
  hasNurseRole,
  hasOrgAdminRole,
  hasOrganizationUserManagementRole,
  hasPharmacistRole,
  hasSalesAndMarketingPlusBillingRole,
  hasSocialWorkerRole,
  isAnalyst,
  isDirector,
  isFacilityUserManager,
  isOrgAdmin,
  isOrgUserManager,
  isSuperUser,
  isToolsUser,
} from '@shared/utils/user'

function sortByName(user1: UserAccount, user2: UserAccount) {
  if (user1.name && user2.name) {
    if (getFullName(user1.name) < getFullName(user2.name)) {
      return -1
    }

    return 0
  } else if (user1.name) {
    return -1
  }

  return 1
}

function sortByRole(user1: UserAccount, user2: UserAccount) {
  const isOrgAdmin1 = hasOrgAdminRole(user1)
  const isOrgAdmin2 = hasOrgAdminRole(user2)
  if (isOrgAdmin1 && isOrgAdmin2) {
    return 0
  } else if (isOrgAdmin1) {
    return -1
  }

  return 1
}

export function getUserLabelAndType(user: UserAccount) {
  if (hasOrgAdminRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_ORGANIZATION_ADMIN)
  } else if (hasDirectorRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_FACILITY_DIRECTOR)
  } else if (hasFacilityStaffRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_FACILITY_STAFF)
  } else if (hasFacilitySalesAndMarketingRole(user)) {
    return getGroupLabelAndType(
      GroupType.GROUP_TYPE_FACILITY_SALES_AND_MARKETING
    )
  } else if (hasSalesAndMarketingPlusBillingRole(user)) {
    return getGroupLabelAndType(
      GroupType.GROUP_TYPE_SALES_AND_MARKETING_PLUS_BILLING
    )
  }
  if (hasSocialWorkerRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_SOCIAL_WORKER)
  } else if (hasBillingRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_BILLING)
  } else if (hasNurseRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_NURSE)
  } else if (hasMedTechRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_MED_TECH)
  } else if (hasMedTechSupervisorRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_MED_TECH_SUPERVISOR)
  } else if (hasFrontDeskRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_FRONT_DESK)
  } else if (hasPharmacistRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_PHARMACIST)
  } else if (isSuperUser(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_SUPER_USER)
  } else if (isToolsUser(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_TOOL_USER)
  } else if (isAnalyst(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_ANALYST)
  } else if (hasOrganizationUserManagementRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_ORG_USER_MANAGEMENT)
  } else if (hasFacilityUserManagementRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_FACILITY_USER_MANAGEMENT)
  } else if (hasCaregiverRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_CAREGIVER)
  } else if (hasCaregiverPlusEhrRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_CAREGIVER_PLUS_EHR)
  }

  return getGroupLabelAndType(GroupType.GROUP_TYPE_UNSPECIFIED)
}

export function getFilteredUsers({
  users,
  facilities,
  filterString,
}: {
  users: UserAccount[]
  facilities: Facility[]
  filterString?: string
}) {
  const filteredUsers = [...users]
  if (filterString && filterString.trim()) {
    return filteredUsers.filter((user) => {
      const fullName = getFullName(user.name)?.toLowerCase() || ''
      const groupLabel = getUserLabelAndType(user)?.label?.toLowerCase() || ''
      const email = user.email?.toLowerCase() || ''
      const userName = user.preferredUsername?.toLowerCase() || ''
      const userFacilities = getRelevantFacilities({ facilities, user })
      const searchStr = filterString.trim().toLowerCase()
      return (
        fullName.includes(searchStr) ||
        groupLabel.includes(searchStr) ||
        email.includes(searchStr) ||
        userName.includes(searchStr) ||
        some(userFacilities, (f) => f.name.toLowerCase().includes(searchStr))
      )
    })
  }

  return filteredUsers
}

export function getSortedUsers(users: UserAccount[]) {
  const sortedUsers = [...users]
  sortedUsers.sort(sortByName)
  sortedUsers.sort(sortByRole)

  return sortedUsers
}

export const determineIsUserEditable = ({
  orgId,
  loggedInUser,
  targetUser,
}: {
  orgId: string
  loggedInUser: UserAccount
  targetUser: UserAccount
}): boolean => {
  const loggedInAsSuperUser = isSuperUser(loggedInUser)
  const loggedInAsDirector = isDirector({ user: loggedInUser, orgId })
  const loggedInAsOrgAdmin = isOrgAdmin({ user: loggedInUser, orgId })
  const loggedInAsOrgUserManager = isOrgUserManager({
    user: loggedInUser,
    orgId,
  })
  const loggedInAsFacilityUserManager = isFacilityUserManager({
    user: loggedInUser,
    orgId,
  })

  const targetUserIsDirector = isDirector({ user: targetUser, orgId })
  const targetUserIsOrgAdmin = isOrgAdmin({ user: targetUser, orgId })
  const targetUserIsOrgUserManager = isOrgUserManager({
    user: targetUser,
    orgId,
  })

  if (loggedInAsSuperUser || loggedInAsOrgUserManager) {
    return true
  }

  if (loggedInAsOrgAdmin) {
    return !targetUserIsOrgAdmin
  }

  if (loggedInAsDirector) {
    return (
      !targetUserIsDirector &&
      !targetUserIsOrgAdmin &&
      !targetUserIsOrgUserManager
    )
  }

  if (loggedInAsFacilityUserManager) {
    return !targetUserIsOrgAdmin && !targetUserIsOrgUserManager
  }

  return false
}

export const determineIsUserDeletable = ({
  orgId,
  loggedInUser,
  targetUser,
}: {
  orgId: string
  loggedInUser: UserAccount
  targetUser: UserAccount
}): boolean => {
  const isLoggedInUserFacilityUserManager = isFacilityUserManager({
    user: loggedInUser,
    orgId,
  })

  if (isLoggedInUserFacilityUserManager) {
    return false
  }

  const isUserEditable = determineIsUserEditable({
    orgId,
    loggedInUser,
    targetUser,
  })

  const isUserSelf = loggedInUser.id === targetUser.id

  return isUserEditable && !isUserSelf
}
