import { Link } from 'react-router-dom'

export default function LowPriorityLink({
  to,
  children,
}: {
  to: string
  children: React.ReactNode
}) {
  return (
    <div className="ml-[8px] mt-[24px] text-[12px] font-medium leading-[16px]">
      <Link to={to} style={{ color: 'var(--secondary)' }}>
        {children}
        <i className="fas fa-chevron-right ml-[6px] text-[10px] leading-[14px]" />
      </Link>
    </div>
  )
}
