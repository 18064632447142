import { Bed } from '@augusthealth/models/com/august/protos/bed'
import { Room } from '@augusthealth/models/com/august/protos/room'
import styles from './styles.module.css'

type Props = {
  bed: Bed
  index?: number
  room: Room
}

export default function RoomBedName({ bed, index = 0, room }: Props) {
  const { id: roomId, name: roomName } = room
  const { id: bedId, name: bedName } = bed
  let label = roomName
  if (bedName) {
    label += ` ${bedName}`
  }
  const classNameList = [styles.rowInRow]
  if (index > 0) {
    classNameList.push('hidden')
  }

  return (
    <div
      key={`room${roomId}-bed${bedId}-name-${index}`}
      className={classNameList.join(' ')}
    >
      {label}
    </div>
  )
}
