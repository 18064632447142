import { ServicePlanObjectivesGetResponse } from '@augusthealth/models/com/august/protos/api/service_plan_objectives'
import { requestJson } from '@shared/api/request'
import { apiFacilityUrl, apiPersonUrl } from '@shared/legacy_routes'
import { FacilityIds } from '@shared/types/facility'
import { DataType } from '@shared/types/snapshot'

export async function fetchObjectiveOptions({
  personId,
  facilityId,
  orgId,
}: {
  personId: string
  facilityId: string
  orgId: string
}): Promise<ServicePlanObjectivesGetResponse> {
  const url =
    apiPersonUrl(orgId, facilityId, personId) +
    `/service_plan/objective_options`

  const responseJson = await requestJson({ url })
  return responseJson.data
}

export function requestBulkServicePlans(facility: FacilityIds) {
  const url =
    apiFacilityUrl(facility.orgId, facility.id) +
    `/snapshots/${DataType.DATA_TYPE_SERVICE_PLAN}/sendBulk`

  return requestJson({
    url,
    method: 'POST',
  })
}
