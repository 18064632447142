import classNames from 'classnames'
import { twx } from '@shared/utils/tailwind'

type Props = {
  value?: string
  valueSuffix?: string
  className?: string
  suffixClassName?: string
  iconClasses?: string
  testId?: string
  underline?: boolean
  title?: string
}
export default function MedMetadata(props: Props) {
  const {
    className,
    suffixClassName,
    value,
    valueSuffix,
    iconClasses,
    testId,
    underline = false,
    title,
  } = props

  if (value === undefined) {
    return null
  }

  const iconClassNames = classNames('mr-[4px] fas fa-fw', iconClasses)

  return (
    <div
      data-testid={testId}
      className={twx('capitalize', className)}
      title={title}
    >
      <i className={iconClassNames}></i>
      <span className={twx({ underline: underline })}>{value}</span>
      {!!valueSuffix && (
        <span className={twx('ml-1', suffixClassName)}>{valueSuffix}</span>
      )}
    </div>
  )
}
