import { PayerDetailedStatement } from '@shared/types/billing'
import { formatCurrencyForBilling } from '@shared/utils/billing'
import { tw, twx } from '@shared/utils/tailwind'
import {
  getFormattedStatementBalanceHeader,
  getFormattedStatementBalanceValue,
  getFormattedTotal,
} from './helpers'

type Props = {
  statement: PayerDetailedStatement
}

export default function BalanceCard(props: Props) {
  const { statement } = props

  const { data: { rollOverBalanceCents = 0 } = {}, paidAmountCents } = statement

  return (
    <div
      className={twx(
        'flex min-h-0 animate-fade-in rounded-lg bg-white p-2 shadow-card'
      )}
    >
      <div className={tw`flex w-full flex-col p-4`}>
        <div className={tw`mb-1 flex items-center justify-between`}>
          <span
            data-testid="balance-card-title"
            className={tw`font-medium text-gray-07`}
          >
            {getFormattedStatementBalanceHeader(statement)}
          </span>
          <span
            data-testid="balance-card-amount"
            className={tw`font-semibold text-gray-04`}
          >
            {getFormattedStatementBalanceValue(statement)}
          </span>
        </div>

        <div className={tw`mb-2 flex items-center justify-between`}>
          <span className={tw`font-medium text-gray-07`}>
            Payments received
          </span>
          <span
            data-testid="paid-amount-cents"
            className={twx('font-semibold', {
              'text-primary-light': rollOverBalanceCents === 0,
              'text-orange': rollOverBalanceCents > 0,
            })}
          >
            {formatCurrencyForBilling(paidAmountCents)}
          </span>
        </div>

        <hr className={tw`border-1 border-gray-10`} />

        <div className={tw`mt-2 flex items-center justify-between`}>
          <span className={tw`font-semibold text-gray-04`}>Total</span>
          <span
            data-testid="total-amount-cents"
            className={tw`font-semibold text-gray-04`}
          >
            {getFormattedTotal(statement)}
          </span>
        </div>
      </div>
    </div>
  )
}
