import { ServicePlan_PlanCategoryKey } from '@augusthealth/models/com/august/protos/service_plan'
import {
  AppraisalSettings_AppraisalCategory,
  AppraisalSettings_Level as Level,
} from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { useContext, useEffect } from 'react'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import { CategoryKeyIdentifier } from '@shared/types/assessment_configuration'
import { getValuesByCategory } from '@shared/utils/residentAssessment'
import ServicePlanContext from './ServicePlanContext'
import { reducer, State } from './stateManagement'

export function PresetObjectiveText({
  planCategory,
  saveServicePlan,
  assessmentCategory,
}: {
  planCategory: CategoryKeyIdentifier
  saveServicePlan: (state: State) => Promise<void>
  assessmentCategory: AppraisalSettings_AppraisalCategory | undefined
}) {
  const { assessment, dispatch, state } = useContext(ServicePlanContext)

  const objectiveTextFromState = state[planCategory]?.objective?.text

  useEffect(() => {
    const { level } =
      getValuesByCategory({
        assessment:
          (assessment.tag === 'Complete' && assessment.value.appraisal) || {},
        category: assessmentCategory || {},
      }) || {}

    if (objectiveTextFromState === undefined && level !== undefined) {
      const newText =
        level === Level.LEVEL_NONE
          ? 'The Care Team will monitor for changes in condition and conduct a reappraisal as appropriate.'
          : presetTextForObjective(planCategory)

      dispatch({
        actionType: 'changeObjectiveText',
        payload: {
          category: planCategory,
          newText,
        },
      })
    }
  }, [])

  const handleOnBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    const newState = reducer(state, {
      actionType: 'changeObjectiveText',
      payload: {
        category: planCategory,
        newText: e.currentTarget.value,
      },
    })

    return saveServicePlan(newState)
  }

  return (
    <BasicTextarea
      placeholder="Enter Objective or Plan"
      value={objectiveTextFromState}
      onChange={(e) => {
        dispatch({
          actionType: 'changeObjectiveText',
          payload: {
            category: planCategory,
            newText: e.currentTarget.value,
          },
        })
      }}
      onBlur={handleOnBlur}
    />
  )
}

function presetTextForObjective(planCategoryKey: CategoryKeyIdentifier) {
  // Hard coded customization for a large customer - see AH-7943
  if (planCategoryKey.match(/evacuation status/i)) {
    return 'The Care Team supports me with my needs to safely evacuate in case of emergency.'
  }

  switch (planCategoryKey) {
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_BATHING:
      return 'The Care Team supports me with my bathing needs, preserves my dignity and helps me remain clean, neat and odor free.'
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_GROOMING:
      return 'The Care Team supports me with my grooming needs and helps me present neat, clean and in an acceptable manner.'
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_DRESSING:
      return 'The Care Team supports me with my dressing and helps me present in clean, well maintained/fitting appropriate clothing and footwear.'
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_DENTAL:
      return 'The Care Team supports me maintaining proper dental and oral hygiene.'
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_TOILETING:
      return 'The Care Team supports me maintaining healthy urine and bowel output and proper personal hygiene.'
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_TRANSFER_AND_MOBILITY:
      return 'The Care Team supports me with safe ambulation, mobility and repositioning.'
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_HEARING:
      return 'The Care Team supports me in maintaining proper care for and use of my hearing devices.'
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_VISION:
      return 'The Care Team supports me in maintaining proper care for and use of my eyewear.'
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_COMMUNICATION:
      return 'The Care Team supports me with communicating my needs and preferences.'
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_MEDICATION_MANAGEMENT:
      return 'The Care Team supports me in the 7 Rights of Medication Management including storing and receiving my medications in a safe and timely manner.'
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_CARE_COORDINATION:
      return 'The Care Team supports me with receiving quality health care services by helping to coordinate my healthcare and homecare appointments.'
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_SPECIAL_CARE_NEEDS:
      return 'The Care Team supports me in meeting my special care needs in order to improve my overall wellbeing.'
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_COGNITIVE:
      return 'The Care Team supports me with orientation, redirection and wayfinding.'
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_WANDERING_AND_ELOPEMENT:
      return 'The Care Team provides redirection when I am wandering or attempting to leave the building unassisted in order to help keep me safe.'
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_DISPOSITION_AND_BEHAVIORS:
      return 'The Care Team helps me in expressing my feelings and disposition in positive ways.'
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_SLEEP:
      return 'The Care Team supports me with strategies for maintaining healthy sleeping patterns.'
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_PSYCHO_SOCIAL_ENGAGEMENT:
      return 'The Care Team supports me with participating in activities and community engagement.'
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_MEALS_AND_NUTRITION:
      return 'The Care Team supports me with eating and drinking in order to meet my overall nutritional needs.'
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_MOBILITY:
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_TRANSFER:
      return 'The Care Team supports me with safe ambulation, mobility and repositioning.'
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_ADDITIONAL_SERVICES:
      return 'TODO FIXME'
    // @ts-ignore TODO FIXME
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_CUSTOM:
      return 'TODO FIXME'
    default:
      return ''
  }
}
