import { UseFormReturn } from 'react-hook-form'
import { BasicInputProps } from '@shared/components/BasicInput/BasicInput'
import DeletableInput from '@app/components/formElements/DeletableInput'

export default function NoDuplicateInput({
  index,
  path,
  useFormReturn,
}: {
  index: number
  path: string
  useFormReturn: UseFormReturn
}) {
  const { clearErrors, formState, register, setValue, watch } = useFormReturn
  const { errors } = formState
  const list = watch(path)
  const itemPath: `${string}.${number}` = `${path}.${index}`
  const error = Boolean(errors?.[path]?.[index])
  const inputProps = {
    ...register(itemPath, {
      validate: {
        noDuplicate: (v) => {
          const firstIndex = list.indexOf(v)
          const isFirstOne = firstIndex === index
          const onlyAppearOnce = firstIndex === list.lastIndexOf(v)
          return isFirstOne || onlyAppearOnce
        },
      },
    }),
  } as BasicInputProps

  return (
    <div>
      <DeletableInput
        inputProps={inputProps}
        trashIconProps={{
          onClick: () => {
            const newList = list.filter((_item, i) => i !== index)
            if (newList.length) {
              setValue(path, newList)
            } else {
              setValue(path, undefined)
            }

            clearErrors()
          },
        }}
      />
      {error && <div className="text-alert">Duplicate</div>}
    </div>
  )
}
