import { ReactNode, useContext } from 'react'
import Card from '@shared/components/Card'
import { DotDivider } from '@shared/components/DotDivider'
import { SquareBadge } from '@shared/components/SquareBadge'
import CurrentFacilityContext from '@shared/contexts/CurrentFacilityContext'
import { Person } from '@shared/types/person'
import { Snapshot } from '@shared/types/snapshot'
import { getFirstAndLastName, getFullName } from '@shared/utils/humanName'
import { hasAssessmentLevelValues } from '@shared/utils/residentAssessment'
import { getFormattedCompletionDate } from '@shared/utils/snapshot'
import { tw } from '@shared/utils/tailwind'
import {
  calculateLevel,
  calculateTotalScore,
} from '@app/components/Prospects/Forms/LevelOfCare'
import LevelBadges from '../Tasks/ResidentAssessment/LevelBadges'

export default function ArchivedAssessmentWrapper({
  title,
  children,
  person,
  snapshot,
}: {
  title: string
  children: ReactNode
  person: Person
  snapshot: Snapshot
}) {
  const { convertToFacilityTimezone } = useContext(CurrentFacilityContext)
  const { lastModification } = snapshot
  const completionDate = getFormattedCompletionDate(
    snapshot,
    convertToFacilityTimezone
  )

  /**
   * The snapshot in question is either the current Resident Assessment
   * or the ancient (and deprecated) Level of Care.
   */

  const isAssessment = hasAssessmentLevelValues(snapshot)

  /**
   * Score/level handling for the ancient Level of Care
   */
  const levelOfCareAssessment = snapshot.data?.levelOfCareAssessment || {}
  const score = calculateTotalScore({ data: levelOfCareAssessment })
  const level = calculateLevel({
    totalScore: score,
  })

  return (
    <main className={tw`content-holder vertical mt-[48px] font-inter`}>
      <div>
        <img alt="" src="/svg/rebrand_logotype.svg" />
      </div>
      <h2 className={tw`mb-[8px] mt-[16px] text-[16px] leading-[24px]`}>
        {getFirstAndLastName(person.name)}
      </h2>
      <h1 className={tw`flex items-center text-[24px] font-medium`}>
        <span className="mr-4">{title}</span>
        {isAssessment && <LevelBadges assessment={snapshot} />}
        {!isAssessment && (
          <>
            <SquareBadge>Score {score}</SquareBadge>
            <SquareBadge className={tw`ml-[16px]`}>Level {level}</SquareBadge>
          </>
        )}
      </h1>
      {lastModification && (
        <div className={tw`text-[16px] leading-[24px] text-gray-07`}>
          {lastModification.modifiedByUserName &&
            `Completed by ${getFullName(lastModification.modifiedByUserName)}`}
          <DotDivider />
          {completionDate}
        </div>
      )}
      <Card className={tw`mt-[16px] flex flex-col items-center`}>
        <div className={tw`mt-[16px] max-w-[600px]`}>{children}</div>
      </Card>
    </main>
  )
}
