import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { getFacilityUrl } from '@shared/legacy_routes'
import { tw } from '@shared/utils/tailwind'
import Content from '@app/components/generic/Content'
import PersonPageTitle from '@app/components/PersonPageTitle'
import {
  NameTd,
  Table,
  TableContainer,
  TdWithExtraPadding,
  TrWithHoverEffect,
} from '@app/components/Table'
import TableHeader from '@app/components/Table/TableHeader'
import FacilitiesContext from '@app/contexts/FacilitiesContext'

const TABLE_COLUMN_LABELS = ['Name', 'Move-ins', 'Residents']

export default function List() {
  const { facilities = [] } = useContext(FacilitiesContext)
  const isEmpty = facilities.length === 0

  return (
    <Content>
      <PersonPageTitle
        className={tw`my-[32px]`}
        title="Communities"
        withBorder={false}
      />
      <TableContainer isEmpty={isEmpty}>
        <Table>
          <TableHeader headers={TABLE_COLUMN_LABELS} />
          <tbody>
            {isEmpty && (
              <tr>
                <TdWithExtraPadding colSpan={TABLE_COLUMN_LABELS.length}>
                  Facility not found
                </TdWithExtraPadding>
              </tr>
            )}
            {!isEmpty &&
              facilities.map((f) => {
                const { id: fId, orgId, name, statistics } = f
                const { prospectCount = 0, residentCount = 0 } =
                  statistics || {}
                const path = `${getFacilityUrl(orgId, fId)}/residents`

                return (
                  <TrWithHoverEffect key={`facility-row-${fId}`}>
                    <NameTd>
                      <Link to={path}>{name}</Link>
                    </NameTd>
                    <TdWithExtraPadding>{prospectCount}</TdWithExtraPadding>
                    <TdWithExtraPadding>{residentCount}</TdWithExtraPadding>
                  </TrWithHoverEffect>
                )
              })}
          </tbody>
        </Table>
      </TableContainer>
    </Content>
  )
}
