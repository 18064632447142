import { HTMLProps } from 'react'
import { NestedModalConfirmation } from '@shared/components/baseMui/Modal/Layout'
import { twx } from '@shared/utils/tailwind'

export function ModalOverlaysTitle({
  className,
  children,
  ...restProps
}: HTMLProps<HTMLHeadingElement>) {
  return (
    <h1
      {...restProps}
      className={twx(
        'mx-[0] mb-[32px] mt-0 p-0 text-[24px] font-semibold',
        className
      )}
    >
      {children}
    </h1>
  )
}

/**
 * TO-DO: replace ModalOverlays entirely with NestedModalConfirmation
 * TO-DO: Sychronize CSS styles for Title and Paragraph components cross pages
 */
export function ModalOverlays({
  children,
  title,
  ...restProps
}: HTMLProps<HTMLDivElement>) {
  return (
    <NestedModalConfirmation {...restProps}>
      {title && <ModalOverlaysTitle>{title}</ModalOverlaysTitle>}
      {children}
    </NestedModalConfirmation>
  )
}

export function ModalOverlaysParagraph({
  className,
  children,
  ...restProps
}: HTMLProps<HTMLParagraphElement>) {
  return (
    <p
      {...restProps}
      className={twx(
        'text-[16px] font-medium leading-[24px] text-secondary-06',
        className
      )}
    >
      {children}
    </p>
  )
}
