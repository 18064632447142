import { sortBy } from 'lodash'
import { Task, TaskStatus } from '@shared/types/task'
import {
  isCustomTask,
  isIncomplete,
  taskSubtitle,
  taskTitle,
} from '@shared/utils/task'

export interface TaskCheckboxItem {
  taskId: string
  title: string
  subtitle?: string
  disabled: boolean
  shared: boolean
}

export function shareableTasks(tasks: Task[]): TaskCheckboxItem[] {
  return tasks
    .filter((t) => t.status)
    .filter((t) => isIncomplete(t))
    .filter(isShareableTask)
    .filter(ensureMostRecentTaskOfType(tasks))
    .map(taskToTaskCheckboxItem)
}

export function defaultPersonalMessage(
  rpName: string,
  personName: string,
  adminEmail: string
) {
  return `Hi ${rpName}, please complete these forms to expedite the evaluation of ${personName}. Email me at ${adminEmail} if you have any questions. Thanks!`
}

function ensureMostRecentTaskOfType(tasks: Task[]): (task: Task) => boolean {
  return (task) => {
    const tasksOfSameType = tasks.filter((otherTask) => {
      if (isCustomTask(task)) {
        return (
          task.taskTemplateInfo!.customType ===
          otherTask.taskTemplateInfo!.customType
        )
      }

      return (
        otherTask.taskTemplateInfo?.taskType === task.taskTemplateInfo?.taskType
      )
    })

    if (tasksOfSameType.length > 1) {
      // When there is more than one task of the same type
      // we want to grab the newest one
      const sorted = sortBy(tasksOfSameType, (t) => {
        if (t.lastModification?.modificationTime) {
          return new Date(t.lastModification?.modificationTime)
        }
        return new Date()
      }).reverse()

      return sorted[0]?.id === task.id
    }

    // If there's only one, we include it
    return true
  }
}

function taskToTaskCheckboxItem(task: Task): TaskCheckboxItem {
  const SHAREABLE_STATUSES: TaskStatus[] = [
    TaskStatus.TASK_STATUS_NOT_STARTED,
    TaskStatus.TASK_STATUS_ASSIGNED_TO_ADMIN,
  ]

  return {
    taskId: task.id!,
    title: taskTitle(task),
    subtitle: taskSubtitle(task),
    disabled: task.shared || !SHAREABLE_STATUSES.includes(task.status!),
    shared: task.shared || false,
  }
}

function isShareableTask(task: Task): boolean {
  return task.taskTemplateInfo?.isShareable ?? false
}
