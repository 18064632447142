import { useContext, useEffect } from 'react'
import { TAB_SEPARATOR } from '@shared/components/Auth/GatedRoute'
import useCurrentPage from '@shared/hooks/useCurrentPage'
import { getFullName } from '@shared/utils/humanName'
import {
  Breadcrumb,
  MOVE_INS,
  RESIDENTS,
  toBreadcrumbs,
} from '../components/Breadcrumbs/helpers'
import PersonContext from '../contexts/PersonContext'

/**
 * Sets the title on the current browser tab when viewing person pages
 * AKA anything falling under a resident or move-in profile
 * EditMedications needs to specify precalculatedCrumbs in order to correctly show Pending Status (see MedicationBreadcrumbs.tsx)
 */
export const usePersonTabTitle = (precalculatedCrumbs?: Breadcrumb[]) => {
  const currentPage = useCurrentPage()
  const { person, tasks = [] } = useContext(PersonContext)

  useEffect(() => {
    if (person && person.name) {
      const crumbs = precalculatedCrumbs
        ? precalculatedCrumbs
        : toBreadcrumbs(currentPage, person, tasks)

      const breadcrumbs = crumbs
        .map((bc) => bc.text)
        .filter((bc) => bc !== RESIDENTS && bc !== MOVE_INS)

      if (breadcrumbs.length > 1) {
        document.title = breadcrumbs.join(TAB_SEPARATOR)
      } else {
        if (currentPage.tag === 'Person') {
          const tab = currentPage.tab

          const fullName = getFullName(person!.name[0])
          document.title = tab
            ? `${fullName}${TAB_SEPARATOR}${tab.tag}`
            : fullName
        }
      }
    }
  }, [person?.id, tasks, currentPage, precalculatedCrumbs])

  return null
}
