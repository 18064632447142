import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { request, requestJson } from '@shared/api/request'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import {
  apiUploadPOLSTUrl,
  apiUploadUrl,
  completeViaUploadUrl,
} from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { DataType, Polst_CPR, Snapshot_Data } from '@shared/types/snapshot'
import { Task, TaskTemplateInfo } from '@shared/types/task'
import { UserAccount } from '@shared/types/user'
import { fromDateToDateMessage } from '@shared/utils/date'
import notEmpty from '@shared/utils/notEmpty'
import { isIncomplete, taskSubtitle, taskTitle } from '@shared/utils/task'
import { markTaskAsComplete } from '@app/api/tasks'
import { SelectDocumentUploadType } from '@app/pages/Documents/Uploader/index'
import { taskViaUploadDataTypes } from './constants'
import { FormValues } from './Forms'

/**
 * Find the configured name for a data type, given a list of tasks and a
 * configured template for the facility.
 * @param configuredTemplate
 * @param tasks
 */
export function configuredNameForDataType({
  configuredTemplate,
  tasks,
}: {
  tasks: Task[]
  configuredTemplate: TaskTemplateInfo
}) {
  const allTaskTemplates = [
    ...tasks.map((t) => t.taskTemplateInfo).filter(notEmpty),
    configuredTemplate,
  ]

  const matchingTaskTemplate = allTaskTemplates.find((template) => {
    if (template.dataType === DataType.DATA_TYPE_CUSTOM_SIGNABLE_FORM) {
      return template.customType === configuredTemplate.customType
    }

    return template.dataType === configuredTemplate.dataType
  })

  let label = taskTitle(matchingTaskTemplate!)
  if (taskSubtitle(matchingTaskTemplate!)) {
    label += ` (${taskSubtitle(matchingTaskTemplate!)})`
  }

  return label
}

export async function uploadSignedDocumentWithoutTask({
  dataType,
  dateSigned,
  files,
  person,
}: {
  dataType:
    | DataType.DATA_TYPE_PRN_AUTHORIZATION
    | DataType.DATA_TYPE_CA_FORM_602
    | DataType.DATA_TYPE_NURSE_DELEGATION
  files: File[]
  dateSigned: Date
  person: Person
}) {
  const url = apiUploadUrl({
    orgId: person.orgId!,
    personId: person.id!,
    dataType,
    customType: undefined,
  })
  const formData = new FormData()
  const data: Snapshot_Data = {
    uploadInfo: {
      signatureData: { dateSigned: fromDateToDateMessage(dateSigned) },
    },
  }

  formData.append('data', JSON.stringify(data))
  files.forEach((file) => {
    formData.append('file[]', file)
  })

  return await request({ url, method: 'POST', body: formData })
}

interface UpsertPOLSTParams {
  person: Person
  files?: File[]
  codeStatus: Polst_CPR
}

async function upsertPOLST({
  files,
  person,
  codeStatus,
}: UpsertPOLSTParams): Promise<{ data: { id: string } }> {
  const snapshotData: Snapshot_Data = {
    uploadInfo: { polst: { cprCode: codeStatus } },
  }
  const url = apiUploadPOLSTUrl({ orgId: person.orgId!, personId: person.id! })

  if (files?.length) {
    const formData = new FormData()
    formData.append('data', JSON.stringify(snapshotData))
    files.forEach((file) => {
      formData.append('file[]', file)
    })

    return await request({ url, method: 'POST', body: formData })
  }

  return await requestJson({
    url,
    method: 'POST',
    body: JSON.stringify(snapshotData),
  })
}

export async function uploadDocument({
  files,
  person,
  name,
  dataType,
  customType,
}: {
  files: File[]
  person: Person
  name: string
  dataType: DataType
  customType?: string
}) {
  const url = apiUploadUrl({
    orgId: person.orgId!,
    personId: person.id!,
    dataType,
    customType,
  })

  const formData = new FormData()
  const data: Snapshot_Data = {
    uploadInfo: {
      name,
    },
  }

  formData.append('data', JSON.stringify(data))
  files.forEach((file) => {
    formData.append('file[]', file)
  })

  return await request({ url, method: 'POST', body: formData })
}

export const completeTaskViaUpload = async ({
  files,
  person,
  task,
  data,
}: {
  files: File[]
  person: Person
  task: Task
  data: Snapshot_Data
}) => {
  const url = completeViaUploadUrl({
    personId: person.id!,
    orgId: person.orgId!,
    id: task.id!,
  })

  const formData = new FormData()

  formData.append('data', JSON.stringify(data))
  files.forEach((file) => {
    formData.append('file[]', file)
  })

  return await request({ url, method: 'POST', body: formData })
}

export function defaultValueForInitialType(initialType?: DataType) {
  if (initialType === DataType.DATA_TYPE_OTHER_UPLOAD) {
    return { label: 'Other', value: initialType }
  } else if (initialType === DataType.DATA_TYPE_DIRECTIVE) {
    return {
      label: 'Advanced Directives and Power of Attorney',
      value: initialType,
    }
  } else if (initialType === DataType.DATA_TYPE_MEDICAL_RECORD) {
    return {
      label: 'Medical Record',
      value: initialType,
    }
  } else if (initialType === DataType.DATA_TYPE_INSURANCE_CARD) {
    return {
      label: 'Insurance Card',
      value: initialType,
    }
  }

  return undefined
}

export function isPermittedOption({
  uploadOption,
  user,
  person,
}: {
  uploadOption: { value: SelectDocumentUploadType }
  user: UserAccount
  person: Person
}) {
  if (
    taskViaUploadDataTypes.includes(uploadOption.value.dataType as DataType)
  ) {
    return hasPermissionForPerson({
      user,
      person,
      permissions: [GroupPermission.GROUP_PERMISSION_TASK_COMPLETE_VIA_UPLOAD],
    })
  }

  return true
}

export async function submitDocument({
  data,
  person,
  files,
  tasks,
}: {
  data: FormValues
  person: Person
  files: File[]
  tasks: Task[]
}) {
  if (data.tag === 'CustomSignableForm') {
    const incompleteTask =
      data.customType &&
      tasks
        .filter(isIncomplete)
        .find(
          (t) =>
            t.taskTemplateInfo?.dataType ===
              DataType.DATA_TYPE_CUSTOM_SIGNABLE_FORM &&
            t.taskTemplateInfo?.customType === data.customType
        )

    if (incompleteTask) {
      return await completeTaskViaUpload({
        files,
        person,
        task: incompleteTask,
        data: {
          uploadInfo: {
            name: data.customType,
            signatureData: {
              dateSigned: data.dateSigned
                ? fromDateToDateMessage(data.dateSigned)
                : undefined,
            },
          },
        },
      })
    }

    await uploadDocument({
      files,
      person,
      name: data.customType,
      dataType: DataType.DATA_TYPE_CUSTOM_SIGNABLE_FORM,
      customType: data.customType,
    })
  }

  if (data.tag === 'NamedUpload') {
    const incompleteTask = tasks
      .filter(isIncomplete)
      .find((t) => t.taskTemplateInfo!.dataType === data.dataType)
    if (incompleteTask) {
      await completeTaskViaUpload({
        files,
        person,
        task: incompleteTask,
        data: {
          uploadInfo: {
            name: data.name,
          },
        },
      })
    } else {
      await uploadDocument({
        files,
        person,
        name: data.name,
        dataType: data.dataType,
      })
    }
  }

  if (data.tag === 'SignedDocument') {
    const incompleteTask = tasks
      .filter(isIncomplete)
      .find((t) => t.taskTemplateInfo!.dataType === data.dataType)

    if (incompleteTask) {
      await completeTaskViaUpload({
        files,
        person,
        task: incompleteTask,
        data: {
          uploadInfo: {
            signatureData: {
              dateSigned: fromDateToDateMessage(data.dateSigned),
            },
          },
        },
      })
    } else if (
      data.dataType === DataType.DATA_TYPE_CA_FORM_602 ||
      data.dataType === DataType.DATA_TYPE_PRN_AUTHORIZATION ||
      data.dataType === DataType.DATA_TYPE_NURSE_DELEGATION
    ) {
      await uploadSignedDocumentWithoutTask({
        dataType: data.dataType,
        dateSigned: data.dateSigned,
        files: files!,
        person,
      })
    } else {
      throw new Error(
        'You can only upload a 601 or 603 when there is a corresponding open task'
      )
    }
  }

  if (data.tag === 'POLST') {
    const incompleteTask = tasks
      .filter(isIncomplete)
      .find((t) => t.taskTemplateInfo!.dataType === DataType.DATA_TYPE_POLST)
    const noFiles = !files || files.length === 0
    if (noFiles) {
      await upsertPOLST({
        person,
        codeStatus: data.codeStatus,
      })
      if (incompleteTask) {
        await markTaskAsComplete(person, incompleteTask)
      }
    } else {
      if (incompleteTask) {
        await completeTaskViaUpload({
          files: files!,
          person,
          task: incompleteTask,
          data: {
            uploadInfo: {
              polst: {
                cprCode: data.codeStatus,
              },
            },
          },
        })
      } else {
        await upsertPOLST({
          files,
          person,
          codeStatus: data.codeStatus,
        })
      }
    }
  }

  if (data.tag === 'Immunization') {
    // this path should never be hit
    // immunizations should submit using the AddImmunization form which handles uploading on its own
    throw new Error('Immunization records must be uploaded to the correct form')
  }
}

export function setDocumentTypeSelectOptionValue({
  tasks,
  customType,
  uploadableDocuments,
}: {
  tasks: Task[]
  customType?: string
  uploadableDocuments: TaskTemplateInfo[]
}) {
  if (customType) {
    const matchingTask = tasks.find(
      (t) => t.taskTemplateInfo?.customType === customType
    )

    if (matchingTask) {
      const {
        taskTemplateInfo: {
          displayName: label = null,
          dataType: value = null,
        } = {},
      } = matchingTask || {}

      return { label, value }
    }

    const matchingDocument = uploadableDocuments.find(
      (o) => o.customType === customType
    )

    if (matchingDocument) {
      const { displayName: label, dataType: value } = matchingDocument
      return { label, value }
    }
  }

  return null
}

export function getCustomTypeFromUploadableDocuments({
  documents,
  label,
}: {
  documents: TaskTemplateInfo[]
  label: string
}) {
  return documents.find((d) => d.displayName === label)?.customType
}
