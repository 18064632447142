import {
  Report,
  ReportAssignment,
} from '@augusthealth/models/com/august/protos/report'
import { ChangeEvent } from 'react'
import { BasicCheckbox } from '@shared/components/BasicInput/BasicInput'
import { assignReport, unassignReport } from '@app/api/reports'

export default function AssignCheckbox({
  assignment,
  facilityId,
  orgId,
  report,
  reload,
}: {
  assignment?: ReportAssignment
  facilityId?: string
  orgId?: string
  report: Report
  reload: () => void
}) {
  const { id: rId, description } = report

  return (
    <BasicCheckbox
      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
        if (ev.target.checked && rId && description) {
          void assignReport({
            facilityId,
            orgId,
            reportId: rId,
            name: description,
          }).finally(reload)
        } else if (!ev.target.checked && assignment?.id) {
          void unassignReport(assignment.id).finally(reload)
        }
      }}
      checked={Boolean(assignment)}
    >
      <i className={'fa-solid fa-file-check mr-[4px]'} />
      Assign
    </BasicCheckbox>
  )
}
