import { useContext, useState } from 'react'
import { downloadEmergencyPacket } from '@shared/api/person'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import { BasicSpinner } from '@shared/components/BasicSpinner'
import ButtonLink from '@shared/components/ButtonLink'
import { SmallDotDivider } from '@shared/components/DotDivider'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { DataType, Snapshot } from '@shared/types/snapshot'
import { Loading, mapLoading } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import { taskTitle } from '@shared/utils/task'
import useConfiguredTasks from '@app/pages/Documents/useConfiguredTasks'
import styles from './styles.module.css'

const CHECK_ICON = 'far fa-check ml-[8px] mr-[8px] text-tags-happy'
const BAN_ICON = 'far fa-ban ml-[8px] mr-[8px] text-urgent'

type Props = {
  person: Person
  hasCurrentPOLST: Loading<Snapshot | undefined>
  hasInsuranceCard: Loading<boolean>
  setUploaderDataType: React.Dispatch<React.SetStateAction<DataType>>
  onClose: () => void
}

export default function EmergencyPacketModal({
  person,
  hasCurrentPOLST,
  hasInsuranceCard,
  setUploaderDataType,
  onClose,
}: Props) {
  const { setError } = useContext(GlobalContext)

  const [loadingEmergencyPacket, setLoadingEmergencyPacket] = useState(false)
  const onDownload = async () => {
    setLoadingEmergencyPacket(true)

    try {
      await downloadEmergencyPacket(person as Required<Person>)
      setLoadingEmergencyPacket(false)
      onClose()
    } catch (error) {
      setError(error)
    }
  }

  const tasks = useConfiguredTasks(person)
  const polstTask =
    tasks.tag === 'Complete' &&
    tasks.value.find((t) => t.dataType === DataType.DATA_TYPE_POLST)

  return (
    <AnimatedPopup
      onClickBackground={onClose}
      containerClassName={styles.modalContainer}
      title="Emergency Packet"
    >
      <h3>The Emergency Packet includes the following items:</h3>
      <ul className={styles.packetList}>
        <ListItem
          title="Facesheet"
          hasDocument={{ tag: 'Complete', value: true }}
        />
        <ListItem
          hasDocument={mapLoading(hasCurrentPOLST, (s) => s !== undefined)}
          title={polstTask ? taskTitle(polstTask) : 'Missing Document'}
          onClick={() => {
            setUploaderDataType(DataType.DATA_TYPE_POLST)
          }}
        />
        <ListItem
          hasDocument={hasInsuranceCard}
          title="Insurance Cards"
          onClick={() => {
            setUploaderDataType(DataType.DATA_TYPE_INSURANCE_CARD)
          }}
        />
      </ul>
      <Button
        buttonStyle="primary-fill"
        className={tw`full-width mt-[16px]`}
        disabled={loadingEmergencyPacket}
        onClick={onDownload}
      >
        {loadingEmergencyPacket ? (
          <BasicSpinner />
        ) : (
          <span className="uppercase">Download</span>
        )}
      </Button>
    </AnimatedPopup>
  )
}

const ListItem = ({
  hasDocument,
  onClick,
  title,
}: {
  hasDocument: Loading<boolean>
  onClick?: () => void
  title: string
}) => (
  <li data-testid={title}>
    {hasDocument.tag === 'Loading' && (
      <>
        <BasicSpinner className="far ml-[8px] mr-[8px]" />
        <span>{title}</span>
        <span className={styles.missing}>
          <SmallDotDivider />
          Loading...
        </span>
      </>
    )}
    {hasDocument.tag === 'Complete' && (
      <>
        <i className={hasDocument.value ? CHECK_ICON : BAN_ICON} />
        <span>{title}</span>
        {!hasDocument.value && (
          <span className={styles.missing}>
            <SmallDotDivider />
            Missing
            <SmallDotDivider />
            <ButtonLink data-testid={`upload-${title}`} onClick={onClick}>
              Upload
            </ButtonLink>
          </span>
        )}
      </>
    )}
  </li>
)
