import { Contact } from '@augusthealth/models/com/august/protos/contact'
import { ModalTitle } from '@shared/components/baseMui/Modal/Layout'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import Card from '@shared/components/Card'
import { Person } from '@shared/types/person'
import { formatCurrencyForBilling } from '@shared/utils/billing'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { tw, twx } from '@shared/utils/tailwind'
import ProfileImage from '@app/components/Table/ProfileImage'
import { useBillingResidentSummaryContext } from '@app/contexts/BillingResidentSummaryContext'
import PayerCardContainer from './PayerCardContainer'

interface PayerDetailsModalProps {
  person: Person
  payers: Contact[]
  open: boolean
  onClose: () => void
}
export default function PayerDetailsModal({
  person,
  payers,
  open,
  onClose,
}: PayerDetailsModalProps) {
  const { billingSummary } = useBillingResidentSummaryContext()

  const maybeMonthlyRecurring =
    billingSummary.tag === 'Loading'
      ? null
      : formatCurrencyForBilling(billingSummary.value.monthlyRecurringCents)

  return (
    <Modal
      open={open}
      onClose={onClose}
      id="payer-detail-modal"
      // max-w values are quick work around to keep payer cards with changing
      // content approximately the same size
      contentClassName={twx(
        `w-full max-w-[422px] lg:max-w-[796px] xl:max-w-[1170px]`,
        {
          'xl:max-w-[422px]': payers.length === 1,
          'xl:max-w-[796px]': payers.length === 2,
        }
      )}
    >
      <ModalTitle className={tw`mb-6 border-none pb-0 text-secondary-02`}>
        Payer Details
      </ModalTitle>
      <Card className={tw`mb-4 flex flex-row p-4`}>
        <ProfileImage person={person} className={tw`mr-4 h-10 w-10`} />
        <div>
          <div
            data-testid="person-name"
            className={tw`mb-1 text-sm font-semibold text-gray-04`}
          >
            {getFirstAndLastName(person.name)}
          </div>
          {maybeMonthlyRecurring && (
            <div
              data-testid="monthly-recurring-cents"
              className={tw`text-xs font-medium text-gray-07`}
            >
              Monthly recurring: {maybeMonthlyRecurring}
            </div>
          )}
        </div>
      </Card>
      <div
        className={twx('grid grid-cols-1 gap-4', {
          'lg:grid-cols-2': payers.length >= 2,
          'xl:grid-cols-3': payers.length >= 3,
        })}
      >
        {payers.map((p, i) => {
          return (
            <PayerCardContainer
              key={`${p.id}-${i}`}
              payer={p}
              person={person}
              multiplePayers={payers.length > 1}
            />
          )
        })}
      </div>
    </Modal>
  )
}
