import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useState } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import { DotDivider } from '@shared/components/DotDivider'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import { Person } from '@shared/types/person'
import { DataType, Snapshot, SnapshotStatus } from '@shared/types/snapshot'
import { Task, TaskStatus } from '@shared/types/task'
import { UserAccount } from '@shared/types/user'
import { formatDate } from '@shared/utils/date'
import { getOrElse } from '@shared/utils/loading'
import { levelOfCareLabel } from '@shared/utils/person'
import useAssessmentDueDate from '@app/hooks/useAssessmentDueDate'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import AssessmentViewer from '@app/pages/Documents/Viewer/AssessmentViewer/AssessmentViewer'
import styles from './styles.module.css'
import CareRow from './CareRow'
import EditDueDate from './EditCareHighlights/EditDueDate'

const LABEL = 'Assessment'

export default function LevelOfCareRow({
  levelOfCareTask,
  serviceAppraisals,
  person,
  showLoC,
  goToLoC,
  user,
}: {
  levelOfCareTask: Task | undefined
  serviceAppraisals: Snapshot[]
  person: Person
  showLoC: () => void
  goToLoC: (loc: Task) => void
  user: UserAccount
}) {
  const { convertToFacilityTimezone } = useCurrentFacility()
  const { assessmentDueDate, refreshAssessmentDueDate } = useAssessmentDueDate({
    person,
  })

  const [showEditDueDate, setShowEditDueDate] = useState(false)
  const { openTaskDueDate, scheduledDueDate } = getOrElse(assessmentDueDate, {})
  const dueDate = openTaskDueDate || scheduledDueDate

  const [showAppraisalViewer, setShowAppraisalViewer] = useState(false)
  const canEdit = hasPermissionForPerson({
    user,
    person,
    permissions: [
      GroupPermission.GROUP_PERMISSION_PERSON_UPDATE,
      GroupPermission.GROUP_PERMISSION_SNAPSHOT_UPDATE,
      GroupPermission.GROUP_PERMISSION_TASK_UPDATE,
      GroupPermission.GROUP_PERMISSION_UI_PERSON_DETAILS_EDIT,
    ],
  })

  const locLabel = levelOfCareLabel(person)
  const levelOfCareOrUnknownLabel = locLabel ?? 'Unknown'
  const levelOfCareOrIncompleteLabel = locLabel ?? (
    <span className={styles.incomplete}>Not completed</span>
  )

  if (!canEdit) {
    return <CareRow label={LABEL}>{levelOfCareOrUnknownLabel}</CareRow>
  }

  let completeAppraisal

  if (serviceAppraisals.length > 0) {
    // If the Person has at least one Service Appraisal, it takes precedence over Level of Care.
    // Level of Care is being phased out in favor of the Service Appraisal.
    completeAppraisal = serviceAppraisals.find(
      (sa) => sa.status === SnapshotStatus.SNAPSHOT_STATUS_COMPLETE
    )
    const viewLoC = () => {
      setShowAppraisalViewer(true)
    }

    return (
      <>
        <CareRow label={LABEL}>
          <div>{levelOfCareOrIncompleteLabel}</div>
          {completeAppraisal && (
            <>
              <div>
                Last:{' '}
                {formatDate(
                  convertToFacilityTimezone(completeAppraisal.completedAt)
                )}
                <DotDivider />
                <ButtonLink onClick={viewLoC}>View</ButtonLink>
              </div>
              <div>
                Next:{' '}
                {dueDate ? (
                  formatDate(dueDate)
                ) : (
                  <span className={styles.incomplete}>Not scheduled</span>
                )}
                <DotDivider />
                <ButtonLink onClick={() => setShowEditDueDate(true)}>
                  {dueDate ? 'Edit' : 'Add'}
                </ButtonLink>
              </div>
            </>
          )}
        </CareRow>
        {showAppraisalViewer && (
          <AssessmentViewer
            assessment={{
              // @ts-ignore
              ...completeAppraisal,
              dataType: DataType.DATA_TYPE_AUGUST_INITIAL_APPRAISAL,
            }}
            mode={{
              tag: 'view',
              onClose: () => setShowAppraisalViewer(false),
            }}
            person={person}
          />
        )}
        {showEditDueDate && assessmentDueDate.tag === 'Complete' && dueDate && (
          <EditDueDate
            dueDate={assessmentDueDate.value}
            onClose={async (updated: boolean) => {
              if (updated) {
                await refreshAssessmentDueDate()
              }

              setShowEditDueDate(false)
            }}
            person={person}
          />
        )}
      </>
    )
  }

  if (levelOfCareTask) {
    // Person has a Cimino Level of Care Task
    const isComplete =
      levelOfCareTask.status === TaskStatus.TASK_STATUS_COMPLETE
    const onClick = isComplete ? showLoC : () => goToLoC(levelOfCareTask)
    return (
      <CareRow label={LABEL}>
        {levelOfCareOrIncompleteLabel}
        <DotDivider />
        <ButtonLink onClick={onClick}>
          {isComplete ? 'View' : 'Complete'}
        </ButtonLink>
      </CareRow>
    )
  }

  return <CareRow label={LABEL}>{levelOfCareOrUnknownLabel}</CareRow>
}
