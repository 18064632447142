import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { ReactNode } from 'react'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import StyledSelect from '@shared/components/Selects/StyledSelect'
import { useUserContext } from '@shared/contexts/UserContext'
import { Person } from '@shared/types/person'
import { getRoomNumber } from '@shared/utils/person'
import ElementHolder from '../formElements/ElementHolder'
import RoomBedSelect, { SelectProps } from './PrefilledSelect'

type Props = Omit<SelectProps, 'orgId' | 'facilityId'> & {
  person?: Person
  onUpdate: (value?: string, name?: string) => void
}

export default function SelectInGenerator(props: Props) {
  const { user } = useUserContext()
  const { onUpdate, person, ...restProps } = props
  const { name, value } = restProps
  if (!person?.orgId || !person?.facilityId) {
    return null
  }

  let content: ReactNode
  if (
    hasPermissionForPerson({
      person,
      permissions: [GroupPermission.GROUP_PERMISSION_OCCUPANCY_READ],
      user,
    })
  ) {
    const { orgId, facilityId } = person
    content = (
      <RoomBedSelect
        orgId={orgId}
        facilityId={facilityId}
        {...restProps}
        onChange={(option: { value: string }) => {
          if (option) {
            if (option.value !== value) {
              onUpdate(option.value, name)
            }
          } else {
            onUpdate(undefined, name)
          }
        }}
      />
    )
  } else {
    const roomBedNumber = getRoomNumber(person, { bedNameInParentheses: true })
    const option = roomBedNumber && {
      label: roomBedNumber,
      value: roomBedNumber,
    }
    content = (
      <StyledSelect
        inputId="room-bed-input-read-only"
        placeholder="No room assigned"
        options={[option]}
        isDisabled
        value={option}
      />
    )
  }

  return <ElementHolder {...props}>{content}</ElementHolder>
}
