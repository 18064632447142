import { isEmpty } from '@augusthealth/august-frontend-form-elements'
import { Condition } from '@augusthealth/models/com/august/protos/condition'
import DeletableInputInGenerator, {
  Props,
} from './DeletableInput/DeletableInputIngenerator'
import HashList, { Props as ListProps } from './List/HashList'

function ConditionTextInput(props: Props) {
  const { hashValue, name, onUpdate } = props
  const condition = hashValue as Condition
  // Disable code with .coding, for example:
  // "coding": [{"system_enum": "SYSTEM_URL_SNOMED_CT", "code": "52448006", "display": "Dementia"}]
  // "coding": [{"system_enum": "SYSTEM_URL_SNOMED_CT", "code": "386805003", "display": "Mild cognitive impairment"}]
  const isPrimary = condition?.isPrimary || false
  const disabled = !isEmpty(condition?.code?.coding)
  const switchName = name.replace('.code.text', '.isPrimary')
  const onSwitchChange = () => {
    onUpdate(!isPrimary, switchName)
  }

  return (
    <DeletableInputInGenerator
      {...props}
      disabled={disabled}
      switchLabel="PRIMARY"
      switchChecked={isPrimary}
      onSwitchChange={onSwitchChange}
    />
  )
}

export default function ConditionList(props: ListProps) {
  return <HashList {...props} Component={ConditionTextInput} />
}
