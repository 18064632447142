import { Control, Controller } from 'react-hook-form'
import { BasicInputProps } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, SubLabel } from '@shared/components/Labels'
import { MoneyInput } from '.'

export type MoneyInReactHookFormProps = {
  control: Control<any>
  id: string
  name: string
  required?: boolean
  subLabel?: SubLabel
  title: string
  inputProps?: BasicInputProps
  holderClassName?: string
  uppercaseTitle?: boolean
  saveAsNegative?: boolean
  allowNegativeNumbers?: boolean
  showErrorBorder?: boolean
  hideLabel?: boolean
}

export default function MoneyInReactHookForm({
  control,
  holderClassName,
  id,
  inputProps,
  name,
  required = false,
  subLabel,
  title,
  uppercaseTitle = false,
  saveAsNegative = false,
  allowNegativeNumbers = false,
  showErrorBorder,
  hideLabel = false,
}: MoneyInReactHookFormProps) {
  return (
    <div className={holderClassName}>
      {!hideLabel && (
        <LabelAboveInput
          uppercase={uppercaseTitle}
          htmlFor={id}
          subLabel={subLabel}
        >
          {title}
        </LabelAboveInput>
      )}
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { ref, onChange, value } }) => {
          return (
            <MoneyInput
              refCallback={ref}
              onChange={onChange}
              inputProps={{
                ...inputProps,
                value: typeof value === 'undefined' ? '' : value,
                name,
                id,
                showErrorBorder,
              }}
              saveAsNegative={saveAsNegative}
              allowNegativeNumbers={allowNegativeNumbers}
            />
          )
        }}
      />
    </div>
  )
}
