import { toInteger } from 'lodash'
import { useState } from 'react'
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import GatedRoute from '@shared/components/Auth/GatedRoute'
import { billingRoute } from '@shared/routes'
import { GroupPermission } from '@shared/types/permission'
import { PersonParams } from '@shared/types/person'
import { DashboardType } from '@shared/types/superset'
import { getOrElse } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import { DashboardRecord } from '@app/api/organizations'
import RouterTabs, { TabListProps } from '@app/components/RouterTabs'
import useBillingDashboards from '@app/hooks/useBillingDashboards'
import BillingBreadcrumbs from '@app/pages/Facilities/Billing/Components/BillingBreadcrumbs'
import Dashboard from '@app/pages/Facilities/Billing/Dashboard'
import {
  BILLING_DASHBOARD_PATHS,
  BILLING_DEFAULT_PATH,
  BILLING_PERSON_PATHS,
  BILLING_PERSON_STATEMENT_PATHS,
  BILLING_REPORTS_PATHS,
  BILLING_RESIDENTS_LIST_PATHS,
  BILLING_SETTINGS_PATHS,
  Tab,
  tabFromUrl,
} from '@app/pages/Facilities/Billing/paths'
import Reports from './Reports'
import Residents from './Residents'
import Resident from './Residents/Resident'
import Settings from './Settings'
import StatementViewer from './StatementViewer'

const PATHS_WITH_HIDDEN_TABS = [
  BILLING_PERSON_PATHS[0],
  BILLING_PERSON_STATEMENT_PATHS[0],
]

const PATHS_WITH_HIDDEN_BUTTONS = [
  BILLING_SETTINGS_PATHS[0],
  BILLING_REPORTS_PATHS[0],
  BILLING_DASHBOARD_PATHS[0],
]

type BillingPageProps = RouteComponentProps<
  PersonParams & { dashboardIndex?: string }
>

export default function FacilityBilling(props: BillingPageProps) {
  const { params, path } = props.match
  const { orgId, facilityId } = params
  const [modalType, setModalType] = useState<'ADD_CHARGE' | 'RECORD_CREDIT'>()
  const { billingDashboards: loadingDashboards } = useBillingDashboards(
    orgId,
    facilityId
  )
  const billingDashboards = getOrElse(loadingDashboards, [])

  const shouldHideTabs = PATHS_WITH_HIDDEN_TABS.includes(path)
  const shouldHideButtons = PATHS_WITH_HIDDEN_BUTTONS.includes(path)
  const activeTab = getActiveTab({ path, params, billingDashboards })

  return (
    <div className={tw`flex flex-col px-8 py-8`}>
      <div className={tw`flex min-h-[52px] items-center pb-[12px]`}>
        <div className={tw`flex-grow`}>
          <BillingBreadcrumbs activeTab={activeTab} {...params} />
        </div>
        {!shouldHideButtons && (
          <>
            <AsyncIconButton
              buttonStyle="secondary-fill"
              initialIcon="calculator"
              onClick={() => setModalType('ADD_CHARGE')}
            >
              Add Charge
            </AsyncIconButton>
            <AsyncIconButton
              buttonStyle="secondary-outline"
              initialIcon="percentage"
              className={tw`ml-[8px]`}
              onClick={() => setModalType('RECORD_CREDIT')}
            >
              Record Credit
            </AsyncIconButton>
          </>
        )}
      </div>
      {!shouldHideTabs && (
        <Tabs
          activeTab={activeTab}
          orgId={orgId}
          facilityId={facilityId}
          dashboards={billingDashboards}
        />
      )}
      <Switch>
        <Redirect
          exact
          from={BILLING_DEFAULT_PATH}
          to={BILLING_RESIDENTS_LIST_PATHS[0]}
        />
        <GatedRoute
          exact
          minimalPermissions={[
            GroupPermission.GROUP_PERMISSION_BILLING_V2_READ,
            GroupPermission.GROUP_PERMISSION_BILLING_V2_WRITE,
          ]}
          path={BILLING_SETTINGS_PATHS}
          component={Settings}
          title={'Settings'}
        />
        <GatedRoute
          exact
          minimalPermissions={[
            GroupPermission.GROUP_PERMISSION_BILLING_V2_READ,
            GroupPermission.GROUP_PERMISSION_BILLING_V2_WRITE,
          ]}
          path={BILLING_REPORTS_PATHS}
          component={Reports}
          title={'Reports'}
        />
        <GatedRoute
          exact
          minimalPermissions={[
            GroupPermission.GROUP_PERMISSION_BILLING_V2_READ,
          ]}
          path={BILLING_RESIDENTS_LIST_PATHS}
          render={(routeProps) => {
            return (
              // @ts-expect-error Fix react-router types?
              <Residents
                {...routeProps}
                showChargeModal={modalType}
                closeChargeModal={() => setModalType(undefined)}
              />
            )
          }}
          title={'Residents'}
        />
        <GatedRoute
          exact
          minimalPermissions={[
            GroupPermission.GROUP_PERMISSION_BILLING_V2_READ,
          ]}
          path={BILLING_PERSON_PATHS}
          render={(routeProps) => {
            return (
              // @ts-expect-error Fix react-router types?
              <Resident
                {...routeProps}
                showChargeModal={modalType}
                closeChargeModal={() => setModalType(undefined)}
              />
            )
          }}
          title={'Resident'}
        />
        <GatedRoute
          exact
          minimalPermissions={[
            GroupPermission.GROUP_PERMISSION_BILLING_V2_WRITE,
          ]}
          path={BILLING_PERSON_STATEMENT_PATHS}
          component={StatementViewer}
          title={'Statement Preview'}
        />
        {billingDashboards.map((dashboard, index) => (
          <GatedRoute
            exact
            key={index}
            minimalPermissions={[
              GroupPermission.GROUP_PERMISSION_BILLING_SUPERSET_DASHBOARD_READ,
            ]}
            path={billingRoute(orgId, facilityId) + `/dashboard/${index}`}
            component={(props) => (
              <Dashboard
                {...props}
                orgId={orgId}
                dashboard={dashboard}
                dashboardType={DashboardType.BILLING}
              />
            )}
            title={dashboard.label}
          />
        ))}
      </Switch>
    </div>
  )
}

function Tabs({
  activeTab,
  orgId,
  facilityId,
  dashboards,
}: {
  activeTab: Tab
  orgId: string
  facilityId: string
  dashboards: DashboardRecord[]
}) {
  const tabList: TabListProps = [
    {
      url: billingRoute(orgId, facilityId) + '/residents',
      label: 'Residents',
    },
    {
      url: billingRoute(orgId, facilityId) + '/reports',
      label: 'Reports',
    },
    ...dashboards.map((dashboard, index) => ({
      url: billingRoute(orgId, facilityId) + `/dashboard/${index}`,
      label: dashboard.label,
    })),
    {
      url: billingRoute(orgId, facilityId) + '/settings',
      label: 'Settings',
    },
  ]

  return <RouterTabs activeTab={activeTab} tabList={tabList} />
}

function getActiveTab({
  path,
  params,
  billingDashboards,
}: {
  path: string
  billingDashboards: DashboardRecord[]
  params: BillingPageProps['match']['params']
}) {
  const tab = tabFromUrl(path)
  const dashboardIndex = params.dashboardIndex
    ? toInteger(params.dashboardIndex)
    : 0
  const dashboardName = billingDashboards[dashboardIndex]?.label ?? 'Dashboard'

  return tab === 'Dashboard' ? dashboardName : tab
}
