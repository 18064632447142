import { capitalize } from 'lodash'
import { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Facility } from '@shared/types/facility'
import { CareGroupDetails, MedGroupDetails, Person } from '@shared/types/person'
import { FacilityGroupType } from '@shared/utils/careGroup'
import useGroupsByFacility from '@app/hooks/useGroupsByFacility'
import styles from './styles.module.css'
import { updateCareGroup, updateMedGroup } from './helpers'

export default function EditCareOrMedGroup({
  facility,
  facilityGroupType,
  person,
  onClose,
}: {
  facility: Facility
  facilityGroupType: FacilityGroupType
  person: Person
  onClose: (updated: boolean) => Promise<void>
}) {
  const { setError } = useContext(GlobalContext)
  const isMedGroup = facilityGroupType === 'med'
  const groupLabel = isMedGroup ? 'Med Group' : 'Care Group'
  const { careGroupDetails, medGroupDetails } = person
  const { control, handleSubmit, formState } = useForm<
    CareGroupDetails | MedGroupDetails
  >({
    defaultValues: isMedGroup ? medGroupDetails : careGroupDetails,
  })
  const { groups } = useGroupsByFacility({ facility, facilityGroupType })
  const groupsList = (groups.tag === 'Complete' && groups.value) || []
  const groupOptions = groupsList.map((c) => ({
    label: c.name,
    value: c.id,
  }))

  const onSubmit = async (groupDetails: CareGroupDetails | MedGroupDetails) => {
    if (
      (isMedGroup && person.medGroupDetails?.id !== groupDetails.id) ||
      (!isMedGroup && person.careGroupDetails?.id !== groupDetails.id)
    ) {
      try {
        const details = groupsList.find((c) => c.id === groupDetails.id)
        if (isMedGroup) {
          await updateMedGroup(person, details || null)
        } else {
          await updateCareGroup(person, details || null)
        }

        await onClose(true)
      } catch (e) {
        setError(e)
      }
    } else {
      await onClose(false)
    }
  }

  return (
    <AnimatedPopup title={<h2 className={styles.title}>{groupLabel}</h2>}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="id"
          render={({ field: { onChange, value } }) => (
            <StyledSelect
              isDisabled={groups.tag === 'Loading'}
              isClearable
              onChange={(opt: OptionTypeBase | null) => {
                if (opt?.value) {
                  onChange(opt.value)
                } else {
                  onChange(null)
                }
              }}
              options={groupOptions}
              noOptionsMessage={() => (
                <span>
                  {capitalize(groupLabel)} not found, please create a{' '}
                  {groupLabel.toLowerCase()} first
                </span>
              )}
              placeholder={`Select a ${groupLabel.toLowerCase()}...`}
              value={groupOptions.find(
                (c: OptionTypeBase) => c.value === value
              )}
            />
          )}
        />
        <div className="mt-[32px]">
          <AnimatedPopupFormFooter
            yesBtn={{}}
            noBtn={{ action: () => void onClose(false) }}
            formState={formState}
          />
        </div>
      </form>
    </AnimatedPopup>
  )
}
