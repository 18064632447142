import { AsyncIconButton } from '@shared/components/AsyncButton'
import { UserAccount } from '@shared/types/user'
import { getFirstName, getLastName } from '@shared/utils/humanName'
import styles from './styles.module.css'

type BasicOverlayProps = {
  user: UserAccount
  handleStillHere: () => void
  logInAsSomeoneElse: () => Promise<void>
}
export const BasicOverlay = ({
  user,
  handleStillHere,
  logInAsSomeoneElse,
}: BasicOverlayProps) => {
  const name = getFirstName(user.name)
  const lastName = getLastName(user.name)
  const email = user.email

  return (
    <div className={styles.background} data-testid={'idle-overlay'}>
      <h1 className={styles.prompt}>{name}, are you still there?</h1>
      <h2 className={styles.subprompt}>
        {name} {lastName} · {email}
      </h2>
      <AsyncIconButton
        buttonStyle={'tertiary-fill'}
        buttonSize={'xlarge'}
        onClick={handleStillHere}
        className={styles.actionButton}
      >
        Continue
      </AsyncIconButton>
      <AsyncIconButton
        buttonStyle={'tertiary-outline'}
        buttonSize={'xlarge'}
        onClick={logInAsSomeoneElse}
        className={`${styles.actionButton} ${styles.logout} border-[3px] text-white hover:bg-transparent hover:outline-0`}
      >
        Log in as someone else
      </AsyncIconButton>
    </div>
  )
}
