import { get, set } from '@augusthealth/august-frontend-form-elements'
import { useContext, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { TaskType } from '@shared/types/task'
import PersonContext from '@app/contexts/PersonContext'
import './index.css'
import { mergePatchPerson } from '@shared/api/person'
import { TASK_REQUIRED_FIELDS_HASH } from '@app/components/Prospects/tasksConfig'
import FormLayout from '../FormLayout'
import PageForm, {
  getSnapshotUpdater,
  snapshotLoader,
} from '../FormLayout/PageForm'
import {
  FormPageProps,
  LoaderProps,
  OnDataChange,
  PageFormProps,
  PageGeneratorItem,
  UpdaterProps,
} from '../FormLayout/type'
import levelOfCare1Conf from './json/levelOfCare1.json'
import levelOfCare2Conf from './json/levelOfCare2.json'
import levelOfCare3Conf from './json/levelOfCare3.json'
import levelOfCare4Conf from './json/levelOfCare4.json'

// Refactored and renamed the original getScore function
function calculateScore({ data }) {
  const configurationList: any[] = [
    levelOfCare1Conf,
    levelOfCare2Conf,
    levelOfCare3Conf,
    levelOfCare4Conf,
  ]
  const values = configurationList.map((configuration) => {
    if (data === undefined || data === null) return 0
    return Object.keys(data).reduce((t, n) => {
      // the problem is that for dementia Quiz it's val is an object
      const val = get(data, n)
      if (Array.isArray(configuration)) {
        let conf = configuration.find((c) => c.name === n)
        if (!conf) {
          const groupConf = configuration.find((c) => c.type === 'group')
          if (groupConf) {
            const gC =
              groupConf.configuration[0].formGeneratorProps.configuration
            conf = gC.find((c) => c.name === n)
          }
        }
        if (conf) {
          if (Array.isArray(val)) {
            const opts = conf.options.filter((o) => val.includes(o.value))
            return (
              t +
              opts.reduce((score, o) => {
                return score + ((o && o.score) || 0)
              }, 0)
            )
          } else if (val instanceof Object) {
            return (
              t +
              Object.keys(val).reduce((score, key) => {
                let opt
                const innerVal = val[key]
                const innerName = n + '.' + key
                let innerConf = conf
                // @TODO (future) - there's probably a more clever way of doing this to clean up this code
                // if it's an object we need to check not just the key as the name but iterate through the \
                // key-value pairs and construct the innerName
                if (conf.type === 'group') {
                  const objectGroupConf =
                    conf.configuration[0].formGeneratorProps.configuration
                  innerConf = objectGroupConf.find((c) => c.name === innerName)
                }
                if (innerConf) {
                  opt = innerConf.options.find((o) => o.value === innerVal)
                }
                return score + ((opt && opt.score) || 0)
              }, 0)
            )
          } else {
            if (conf.options) {
              const opt = conf.options.find((o) => o.value === val)
              return t + ((opt && opt.score) || 0)
            }
          }
        }
      }
      // if hasn't already returned, just return t
      return t
    }, 0)
  })

  // Lastly, we know that the 4th config page is the Dementia Quiz. The scoring for it is different and it needs to be adjusted
  values[3] = calculateQuizScore(values[3])
  return values
}

function calculateQuizScore(quizScore) {
  if (quizScore >= 6) {
    return 2
  } else if (quizScore >= 4) {
    return 1
  }
  return 0
}

export function calculateTotalScore({ data }): number {
  return calculateScore({ data }).reduce((total, score) => {
    return total + score
  }, 0)
}

export function calculateLevel({
  totalScore,
  admissionType = 'ADMISSION_TYPE_ASSISTED_LIVING',
}): number {
  const levelCriteria = {
    ADMISSION_TYPE_ASSISTED_LIVING: {
      5: 40,
      4: 30,
      3: 20,
      2: 10,
      1: 0,
    },
    ADMISSION_TYPE_MEMORY_CARE: {
      5: 60,
      4: 45,
      3: 30,
      2: 15,
      1: 0,
    },
  }
  if (admissionType === undefined) return 1
  for (let level = 5; level >= 1; level--) {
    if (totalScore > levelCriteria[admissionType][level]) return level
  }
  return 1
}

export function getLevelOfCarePills({ totalScore, level }) {
  return (
    <>
      <div className="score-pill">
        <span className="score-text">Score {totalScore}</span>
      </div>
      <div className="score-pill">
        <span className="score-text">Level {level}</span>
      </div>
    </>
  )
}

function getLevelOfCareHeader({ title, totalScore, level }) {
  return (
    <div>
      <h2 className="page-title level-of-care-flex">
        <span className="mr-[16px]">{title}</span>
        {getLevelOfCarePills({ totalScore, level })}
      </h2>
    </div>
  )
}

/**
 * To imitate API response.json()
 * Since the first page, accept the combination of Person and Snapshot
 * Need to merge Snapshot data and AdmissionType manually
 */
function wrapperInLevelOfCareObject(patch: object) {
  return {
    data: {
      data: {
        levelOfCareAssessment: patch,
      },
    },
  }
}

const LEVEL_OF_CARE_PATH = 'data.data.levelOfCareAssessment'

function getLevelOfCareData(data: object) {
  return get(data, LEVEL_OF_CARE_PATH)
}

function getPersonAdmissionType(data: object) {
  return get(data, 'admissionsInformation.admissionType')
}

function getPersonLevelOfCare(data: object) {
  return get(data, 'levelOfCare.value')
}

function snapshotLoaderWithAdmissionTask(
  props: LoaderProps,
  admissionType?: string
) {
  return snapshotLoader(props).then((res) => {
    // Merge admissionType into levelOfCareAssessment
    const mergedRes = { ...res }
    if (admissionType) {
      set(
        mergedRes,
        `${LEVEL_OF_CARE_PATH}.admissionsInformation.admissionType`,
        admissionType
      )
    }
    return mergedRes
  })
}

export function LevelOfCareForm(
  props: Omit<PageFormProps, 'configuration' | 'loader' | 'mapResponse'>
) {
  const levelOfCareConf: any[] = [
    ...levelOfCare1Conf,
    ...levelOfCare2Conf,
    ...levelOfCare3Conf,
    ...levelOfCare4Conf,
  ]
  const { person } = useContext(PersonContext)
  const admissionType = getPersonAdmissionType(person || {})

  return (
    <PageForm
      configuration={levelOfCareConf}
      loader={(props: LoaderProps) =>
        snapshotLoaderWithAdmissionTask(props, admissionType)
      }
      mapResponse={getLevelOfCareData}
      {...props}
    />
  )
}

const requiredFieldsByPageIndex =
  TASK_REQUIRED_FIELDS_HASH[TaskType.TASK_TYPE_LEVEL_OF_CARE]

export default function LevelOfCare(props: FormPageProps) {
  const { facilityId: fId, id: pId, orgId } = props.match.params
  const { setError } = useContext(GlobalContext)
  const { person, setPerson } = useContext(PersonContext)
  // Do not put admissionType in useState
  // since it is not affect UI (Dropdown takes care of it)
  // and need its value immediately for API call
  // vs waiting for React state change
  let admissionType = getPersonAdmissionType(person || {})
  const levelOfCareValue = getPersonLevelOfCare(person || {})

  const [score, setScore] = useState<number>(0)
  const [level, setLevel] = useState<number>(levelOfCareValue)
  const onDataChange = ({ data }: OnDataChange) => {
    const totalScore = calculateTotalScore({ data })
    if (score !== totalScore) {
      setScore(totalScore)
    }

    const newLevel = calculateLevel({ totalScore, admissionType })
    // Compare levelOfCareValue from Person rather than level from useState
    // to update level on first load if need
    if (levelOfCareValue !== newLevel) {
      setLevel(newLevel)
      const newPerson: Person = { ...person! }
      newPerson.levelOfCare = { value: newLevel }
      mergePatchPerson({ fId, orgId, pId, patch: newPerson })
        .then((res) => setPerson(res.data))
        .catch(setError)
    }
  }
  const snapshotUpdater = getSnapshotUpdater('levelOfCareAssessment')
  const pageItemProps = {
    loader: snapshotLoader,
    updater: snapshotUpdater,
    mapResponse: getLevelOfCareData,
    onDataChange,
  }

  const formList: PageGeneratorItem[] = [
    {
      ...pageItemProps,
      menuTitle: '1. LEVEL OF CARE',
      pageTitle: 'LEVEL OF CARE ASSESSMENT',
      json: levelOfCare1Conf,
      requiredFields: requiredFieldsByPageIndex[0],
      loader: (props: LoaderProps) =>
        snapshotLoaderWithAdmissionTask(props, admissionType),
      updater: (props: UpdaterProps) => {
        const { patch } = props
        const newAdmissionType = getPersonAdmissionType(patch)
        // On AdmissionType change, update Person
        if (admissionType !== newAdmissionType) {
          const newPerson = { ...person }
          set(
            newPerson,
            'admissionsInformation.admissionType',
            newAdmissionType
          )
          admissionType = newAdmissionType
          return mergePatchPerson({
            ...props,
            fId: props.facilityId,
            patch: newPerson,
          }).then(() => wrapperInLevelOfCareObject(patch))
        }

        // Otherwise, update Snapshot
        const newPatch = { ...patch }
        delete newPatch.admissionsInformation
        return snapshotUpdater({
          ...props,
          patch: newPatch,
        }).then(() => wrapperInLevelOfCareObject(patch))
      },
    },
    {
      ...pageItemProps,
      menuTitle: '2. LEVEL OF CARE (CONTINUED)',
      pageTitle: 'LEVEL OF CARE ASSESSMENT',
      json: levelOfCare2Conf,
    },
    {
      ...pageItemProps,
      menuTitle: '3. LEVEL OF CARE (CONTINUED)',
      pageTitle: 'LEVEL OF CARE ASSESSMENT',
      json: levelOfCare3Conf,
    },
    {
      ...pageItemProps,
      menuTitle: '4. LEVEL OF CARE (CONTINUED)',
      pageTitle: 'LEVEL OF CARE ASSESSMENT',
      json: levelOfCare4Conf,
    },
    {
      menuTitle: '5. REVIEW & COMPLETE',
      pageTitle: 'REVIEW & COMPLETE',
      content: 'Review',
    },
  ]
  const title = getLevelOfCareHeader({
    title: props.title,
    totalScore: score,
    level,
  })

  return <FormLayout {...props} title={title} configuration={formList} />
}
