import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ButtonLink from '@shared/components/ButtonLink'
import { CategoryKeyIdentifier } from '@shared/types/assessment_configuration'
import { DataType } from '@shared/types/snapshot'
import {
  getCategoriesFromAppraisal,
  getDisplayLabelsByCategory,
  getMatchingAssessmentCategory,
} from '@shared/utils/residentAssessment'
import ConfirmModal, {
  Props as ConfirmModalProps,
} from '@app/components/ConfirmModal'
import PersonContext from '@app/contexts/PersonContext'
import { recreateTaskAndReturnUrl } from '@app/pages/Documents/helpers'
import styles from './styles.module.css'
import ServicePlanContext from './ServicePlanContext'

export default function AssessmentNeeds({
  planCategory,
  setShowEditAssessmentCategory,
}: {
  planCategory: CategoryKeyIdentifier
  setShowEditAssessmentCategory: (categoryKey: CategoryKeyIdentifier) => void
}) {
  const [confirmationModalProps, setConfirmationModalProps] =
    useState<ConfirmModalProps>()
  const history = useHistory()
  const { person } = useContext(PersonContext)
  const { assessment } = useContext(ServicePlanContext)
  const categories =
    (assessment.tag === 'Complete' &&
      getCategoriesFromAppraisal(assessment.value)) ||
    []
  const augustInitialAppraisal =
    (assessment.tag === 'Complete' && assessment.value.appraisal) || {}
  const allowInlineEdits =
    assessment.tag === 'Complete' &&
    assessment.value.newerSettingsAvailable === false
  const {
    level,
    details = [],
    notes = '',
  } = getDisplayLabelsByCategory({
    assessment: augustInitialAppraisal,
    category:
      getMatchingAssessmentCategory({
        categoryKey: planCategory,
        categories: categories,
      }) || {},
  }) || {}
  const showList = level !== undefined || details?.length > 0

  async function handleEdit() {
    if (allowInlineEdits) {
      return setShowEditAssessmentCategory(planCategory)
    } else {
      setConfirmationModalProps((existing) => ({
        ...existing,
        title: 'Editing not available',
        content:
          'The assessment questions changed since your last edits. To make edits, start a new assessment.',
        confirmButtonConfig: {
          children: 'Start new assessment',
          buttonStyle: 'danger-fill',
          onClick: async () => {
            const url = await recreateTaskAndReturnUrl(
              person!,
              undefined,
              DataType.DATA_TYPE_AUGUST_INITIAL_APPRAISAL
            )
            history.push(url)
          },
        },
        denyButtonConfig: {
          onClick: () => setConfirmationModalProps(undefined),
        },
      }))
    }
  }

  return (
    <>
      <div className={styles.appraisalBox}>
        <div className="flex items-baseline justify-between">
          <h5 className="pb-[2px] text-[14px] font-bold uppercase">
            Assessment Needs
          </h5>
          <ButtonLink
            className="text-[11px] font-semibold uppercase"
            onClick={async () => handleEdit()}
          >
            <i className="fa-solid fa-pencil mr-[8px]" />
            Edit
          </ButtonLink>
        </div>
        {showList && (
          <ul className="mb-0 list-disc pl-[24px] text-[14px] font-medium text-secondary-06">
            {level !== undefined && <li>{level}</li>}
            {details.map((detail, ix) => (
              <li key={`detail-${planCategory}-${ix}`}>{detail}</li>
            ))}
          </ul>
        )}
        {!showList && (
          <span className="mb-0 text-[14px] font-medium text-secondary-06">
            No assessment needs completed yet.
          </span>
        )}
        {notes.length > 0 && (
          <div className="mt-[16px] text-[14px] font-medium text-secondary-06">
            {notes}
          </div>
        )}
      </div>

      {confirmationModalProps && (
        <div className="min-w-[364px] max-w-[464px] text-center">
          <ConfirmModal {...confirmationModalProps} />
        </div>
      )}
    </>
  )
}
