import { AppraisalSettings_AssessmentType } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { Option } from 'effect'
import { assessmentTypeToDisplay } from '@shared/utils/assessmentConfiguration'

/**
 * Creates an array of dropdown options for an assessment type
 */
export const ASSESSMENT_TYPE_OPTIONS = Object.keys(
  AppraisalSettings_AssessmentType
)
  .filter(
    (assessType: AppraisalSettings_AssessmentType) =>
      assessType !==
        AppraisalSettings_AssessmentType.ASSESSMENT_TYPE_UNSPECIFIED &&
      assessType !== AppraisalSettings_AssessmentType.UNRECOGNIZED
  )
  .map((assessType: AppraisalSettings_AssessmentType) => {
    const labelString = assessmentTypeToDisplay(assessType!)
    return {
      label: Option.isSome(labelString) ? labelString.value : '',
      value: assessType,
    }
  })

export const haveAssessmentTypesChanged = (
  assessmentTypes: AppraisalSettings_AssessmentType[],
  originalAssessmentTypes: AppraisalSettings_AssessmentType[] = []
): boolean => {
  if (assessmentTypes.length !== originalAssessmentTypes.length) {
    return true
  }

  const sortedTypes1 = [...assessmentTypes].sort()
  const sortedTypes2 = [...originalAssessmentTypes].sort()
  return sortedTypes1.some(
    (assessmentType, index) => assessmentType !== sortedTypes2[index]
  )
}
