import { forwardRef, ReactNode } from 'react'
import { ClassNameValue } from 'tailwind-merge'
import { Button } from '@shared/components/baseMui/Button'
import {
  BasicRadioButton,
  BasicRadioButtonProps,
} from '@shared/components/BasicInput/BasicInput'
import Icon from '@shared/components/Icon'
import { tw, twx } from '@shared/utils/tailwind'

export const HOLDER_CLASSNAME = tw`items-center rounded-md border border-secondary-10 bg-gray-13 p-4 text-secondary-04`

export function IconAndLabel({
  iconName,
  label,
}: {
  iconName: string
  label: string | undefined
}) {
  return (
    <span className={tw`flex items-center gap-3`}>
      <Icon name={iconName} className={tw`text-2xl`} variant="regular" />
      {label}
    </span>
  )
}

type CardRadioButtonProps = BasicRadioButtonProps & {
  iconName?: string
  children?: ReactNode
}

export const CardRadioButton = forwardRef<
  HTMLInputElement,
  CardRadioButtonProps
>((props, ref) => {
  const { iconName, label, children, ...rest } = props

  return (
    <BasicRadioButton
      {...rest}
      holderClassName={HOLDER_CLASSNAME}
      radioLabelClassName="w-full"
      ref={ref}
    >
      {iconName && <IconAndLabel iconName={iconName} label={label} />}
      {children}
    </BasicRadioButton>
  )
})
CardRadioButton.displayName = 'CardRadioButton'

export function CardButton({
  children,
  onClick,
  className,
}: {
  children: ReactNode
  onClick: () => void
  className?: ClassNameValue
}) {
  return (
    <Button
      onClick={onClick}
      className={twx(
        HOLDER_CLASSNAME,
        'text-md font-medium',
        'h-fit p-4 normal-case',
        className
      )}
    >
      {children}
    </Button>
  )
}
