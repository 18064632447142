import {
  SignableForm,
  SignableFormAssignment,
} from '@augusthealth/models/com/august/protos/signable_form'
import { useContext } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { deleteFormAssignment } from '@app/api/form'
import ConfirmModal from '@app/components/ConfirmModal'

type Props = {
  formList: SignableForm[]
  assignment: SignableFormAssignment
  onClose: () => void
}

export default function UnassignConfirmPopup({
  formList,
  assignment,
  onClose,
}: Props) {
  const { setError } = useContext(GlobalContext)
  const { facilityId, signableFormId, orgId, dataType, customType, state } =
    assignment
  const form = formList.find((f) => f.id === signableFormId)
  const onConfirm = async () => {
    if (dataType) {
      try {
        await deleteFormAssignment({
          facilityId,
          orgId,
          dataType,
          customType,
          state,
        })
      } catch (e) {
        setError(e)
      } finally {
        onClose()
      }
    }
  }

  return (
    <ConfirmModal
      title={`Are you sure that you want to unassign ${form?.name}?`}
      confirmButtonConfig={{
        children: 'Unassign',
        onClick: onConfirm,
      }}
      denyButtonConfig={{
        onClick: onClose,
      }}
    />
  )
}
