import {
  OrgFacilitySettings,
  SettingsType,
} from '@augusthealth/models/com/august/protos/org_facility_settings'
import { useContext, useEffect, useState } from 'react'
import {
  fetchFacilitySettings,
  fetchGlobalSettings,
  fetchOrgSettings,
  fetchOrgStateSettings,
  fetchStateSettings,
} from '@shared/api/orgFacilitySettings'
import GlobalContext from '@shared/contexts/GlobalContext'
import ErrorMonitoring from '@shared/ErrorMonitoring'
import { Loading } from '@shared/utils/loading'
import { dataTypeForTaskType } from '../pages/Tools/TaskDefinitions/helpers'

/**
 * Fetches settings for a facility, org, state, or globally.
 * Note that this returns the settings defined _exactly_ for the corresponding level;
 * it does not return settings inherited from higher levels.
 * @param facilityId
 * @param orgId
 * @param state
 * @param settingsType
 */
export default function useOrgFacilitySettings({
  facilityId,
  orgId,
  state,
  settingsType,
}: {
  facilityId?: string
  orgId?: string
  state?: string
  settingsType: SettingsType
}) {
  const { setError } = useContext(GlobalContext)
  const [settings, setSettings] = useState<Loading<OrgFacilitySettings[]>>({
    tag: 'Loading',
  })

  const reloadSettings = async () => {
    setSettings({ tag: 'Loading' })

    try {
      let value
      if (facilityId && orgId) {
        value = await fetchFacilitySettings({ orgId, facilityId, settingsType })
      } else if (orgId && state) {
        value = await fetchOrgStateSettings({ orgId, state, settingsType })
      } else if (orgId) {
        value = await fetchOrgSettings({ orgId, settingsType })
      } else if (state) {
        value = await fetchStateSettings({ state, settingsType })
      } else {
        value = await fetchGlobalSettings({ settingsType })
      }

      setSettings({ tag: 'Complete', value })
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    void reloadSettings()
  }, [facilityId, orgId, state, settingsType])

  useEffect(() => {
    if (
      settingsType === SettingsType.SETTINGS_TYPE_SCHEDULED_TASK &&
      settings.tag === 'Complete'
    ) {
      if (settings.value.some(isScheduledTaskSettingInvalid)) {
        logInvalidSettings({ orgId, facilityId, state })
      }
    }
  }, [settingsType, settings.tag])

  return { settings, reloadSettings }
}

/**
 * The scheduled task data model is strange, so this is a defense measure
 * It checks that the scheduled tasks dataType matches its tasksToCreate
 * It also checks that only one task is being created
 * @param s
 */
function isScheduledTaskSettingInvalid(s: OrgFacilitySettings) {
  const multipleTasks =
    (s.settings?.scheduledTask?.parameters?.tasksToCreate ?? []).length > 1

  const dataType = s.settings?.scheduledTask?.dataType
  const relevantTask = (s.settings?.scheduledTask?.parameters?.tasksToCreate ??
    [])[0]
  const typesMatch =
    relevantTask && dataType && dataTypeForTaskType(relevantTask) === dataType

  return multipleTasks || !typesMatch
}

function logInvalidSettings({
  orgId,
  facilityId,
  state,
}: {
  orgId?: string
  facilityId?: string
  state?: string
}) {
  ErrorMonitoring.capture({
    error: 'Detected scheduled task with invalid settings',
    tags: {
      settings_ids: `org ${orgId}, facility ${facilityId}, state ${state}`,
    },
  })
}
