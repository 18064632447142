import Badge from '@shared/components/Badge'
import { DetailWithAugustField } from '@shared/types/assessment'
import { tw } from '@shared/utils/tailwind'
import { isSupportedAugustField } from '@app/pages/Tools/AssessmentConfiguration/DetailGroups/augustFieldHelpers'
import { EditRemoveButtons } from '../EditRemoveButtons'

export default function EditAugustField({
  detail,
  removeDetail,
  index,
  detailGroupKey,
}: {
  detail: DetailWithAugustField
  removeDetail: ({
    index,
    optIndex,
    key,
  }: {
    index: number
    optIndex?: number
    key: string
  }) => void
  index: number
  detailGroupKey: string
}) {
  const onRemove = () => {
    removeDetail({ index, key: detailGroupKey })
  }

  return (
    <>
      <EditRemoveButtons
        onEdit={() => {}}
        onRemove={onRemove}
        disabled={'edit'}
      />
      <div className={tw`font-mono`}>{detail.value.augustFieldType}</div>
      {isSupportedAugustField(detail.value.augustFieldType) ? (
        <Badge color={'primaryLight'} shape={'square'}>
          UI Support
        </Badge>
      ) : (
        <Badge color={'orange'} shape={'square'}>
          No UI Support
        </Badge>
      )}
    </>
  )
}
