import { AdmissionsInformation_DesiredRange } from '@augusthealth/models/com/august/protos/admissions'
import {
  Contact,
  Contact_ContactRelationship,
} from '@augusthealth/models/com/august/protos/contact'
import {
  ContactPoint_ContactPointSystem,
  ContactPoint_ContactPointUse,
} from '@augusthealth/models/com/august/protos/contact_point'
import { DateMessage } from '@augusthealth/models/com/august/protos/date'
import { Gender } from '@augusthealth/models/com/august/protos/gender'
import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { createPerson } from '@shared/api/person'
import { hasPermissionForFacility } from '@shared/components/PermissionGates/PermissionGates'
import { Facility } from '@shared/types/facility'
import { NewPerson, ResidentStatus } from '@shared/types/person'
import { UserAccount } from '@shared/types/user'
import { filterOutEmptyTelecom } from '@shared/utils/contactPoint'
import { fromDateToDateMessage } from '@shared/utils/date'
import { createRPContact } from '@app/api/contacts'
import { AdmissionTypeOption } from '@app/components/Prospects/VerifyAdmissionDetails/types'
import { Option as PhoneUseOption } from '@app/components/TelecomInputs/ContactPointUseDropdown'

export type GenderOption = { label: string; value: Gender }

export type FacilityOption = { label: string; value: Facility }

export type FormData = {
  firstName: string
  lastName: string
  dob?: DateMessage
  gender?: GenderOption
  rpFirstName?: string
  rpLastName?: string
  rpPhone?: string
  rpPhoneUse?: PhoneUseOption
  rpEmail?: string
  rpPrimaryRelationship?: RelationshipOption
  rpSecondaryRelationship?: RelationshipOption
  admissionFacility?: FacilityOption
  admissionDate?: Date
  admissionASAP?: boolean
  admissionNotes?: string
  admissionType?: AdmissionTypeOption | null
  bedId?: string | null
  hospice?: boolean
  respite?: boolean
}

export type RelationshipOption = {
  value: Contact_ContactRelationship
  label: string
}

export const genderOptions: GenderOption[] = [
  { value: Gender.GENDER_FEMALE, label: 'Female' },
  { value: Gender.GENDER_MALE, label: 'Male' },
  { value: Gender.GENDER_NON_BINARY, label: 'Non-Binary' },
]

export const facilityOptions = (
  facilities: Facility[],
  user: UserAccount
): FacilityOption[] =>
  facilities
    .filter((f) =>
      hasPermissionForFacility({
        user,
        facility: { id: f.id, orgId: f.orgId },
        permissions: [GroupPermission.GROUP_PERMISSION_PERSON_CREATE],
      })
    )
    .map((facility) => ({
      value: facility,
      label: facility.name!,
    }))

export const addNewPerson = async ({
  data,
  facilityId,
  orgId,
  residentStatus = ResidentStatus.RESIDENT_STATUS_PROSPECT,
}: {
  data: FormData
  facilityId: string
  orgId: string
  residentStatus?: ResidentStatus
}): Promise<{ newFacilityId: string; personId: number }> => {
  const person: NewPerson = {
    facilityId: data.admissionFacility?.value.id
      ? data.admissionFacility?.value?.id
      : facilityId,
    orgId: data.admissionFacility?.value?.orgId
      ? data.admissionFacility.value.orgId
      : orgId,
    name: [{ given: [data.firstName], family: data.lastName }],
    gender: data.gender?.value ?? Gender.GENDER_UNSPECIFIED,
    birthDate: data.dob!,
    prospectInfo: { details: data.admissionNotes },
    residentStatus,
    admissionsInformation: formatAdmissionsData(data),
    hospiceStatus: {
      onHospice: data.hospice,
    },
    respiteStatus: {
      isRespite: data.respite,
    },
  }
  const { id: personId } = await createPerson({ person })
  // contact first & last name are required on the backend
  if (data.rpFirstName && data.rpLastName) {
    const contact: Contact = {
      name: {
        family: data.rpLastName,
        given: data.rpFirstName ? [data.rpFirstName] : undefined,
      },
      relationship: [
        data.rpPrimaryRelationship?.value as Contact_ContactRelationship,
        data.rpSecondaryRelationship?.value as Contact_ContactRelationship,
      ].filter((relationship) => relationship),
      telecom: filterOutEmptyTelecom([
        {
          system: ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_PHONE,
          value: data.rpPhone,
          use:
            data.rpPhoneUse?.value ||
            ContactPoint_ContactPointUse.CONTACT_POINT_USE_UNSPECIFIED,
        },
        {
          system: ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_EMAIL,
          value: data.rpEmail,
        },
      ]),
    }

    await createRPContact({
      contact,
      facilityId: person.facilityId!,
      orgId,
      personId: `${personId}`,
    })
  }

  return { newFacilityId: person.facilityId!, personId }
}

const formatAdmissionsData = (data: FormData) => ({
  admissionType: data.admissionType?.value || undefined,
  bedId: data.bedId || undefined,
  startDate: data.admissionDate
    ? fromDateToDateMessage(data.admissionDate)
    : undefined,
  startRange: data.admissionASAP
    ? AdmissionsInformation_DesiredRange.DESIRED_RANGE_ASAP
    : undefined,
})
