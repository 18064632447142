import { useEffect, useState } from 'react'
import styles from './styles.module.css'

interface Props extends React.HTMLProps<HTMLDivElement> {
  milliseconds?: number
  onExpire?: () => void
}

export default function HUD({
  milliseconds = 2500,
  children,
  className,
  onExpire,
}: Props) {
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setVisible(false)
      if (onExpire) {
        onExpire()
      }
    }, milliseconds)
  }, [milliseconds])

  if (visible && children) {
    return (
      <div className={styles.background}>
        <div
          className={`animate-fade-in ${styles.container} ${className}`}
          data-testid="hud"
        >
          {children}
        </div>
      </div>
    )
  }

  return null
}
