import styles from './styles.module.css'
import BaseUI from './index'

type Props = {
  continueAndClose: () => void
  label?: string
}

/**
 * Display after convert a prospect to a resident
 */
export default function CompletedTaskContinue(props: Props) {
  const { label = 'CONTINUE', continueAndClose } = props
  return (
    <BaseUI
      footer={
        <button
          id="congrats-continue"
          className={`rounded-[6px] bg-button-primary-color px-[12px] py-[11px] text-[11px] font-bold leading-[14px] text-button-fill-font-color hover:brightness-125  ${styles['close-button']}`}
          onClick={continueAndClose}
        >
          {label}
        </button>
      }
    />
  )
}
