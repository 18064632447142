import { SimpleSpinner } from '@shared/components/LoadingPopup'
import { getFormPdfPreviewUrl } from '@app/api/form'
import BlobFileReader from '@app/components/generic/BlobFileReader'

type PreviewProps = {
  id?: string
  orgId?: string
  facilityId?: string
  personId?: string
}

export default function Preview(props: PreviewProps) {
  const { id, orgId, facilityId, personId } = props

  if (id && orgId && facilityId && personId) {
    const pdfUrl = getFormPdfPreviewUrl({ id, orgId, facilityId, personId })

    return <BlobFileReader filePath={pdfUrl} documentScale={1.25} />
  }

  return <SimpleSpinner />
}
