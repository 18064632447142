import { useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { Controller, useFormContext } from 'react-hook-form'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import { UnitOfTime } from '@shared/types/timing'
import {
  fromDateMessageToDate,
  fromDateToDateMessage,
} from '@shared/utils/date'
import { RoutineOrderFormData } from './RoutineOrderForm'

type Props = {
  index: number
  readOnly: boolean
}
export const RoutineOnThisDay = ({ index, readOnly }: Props) => {
  const { register, unregister, control, setValue, getValues } =
    useFormContext<RoutineOrderFormData>()

  useEffect(() => {
    register(`schedules.${index}.period`)
    setValue(`schedules.${index}.period`, 1)
    register(`schedules.${index}.periodUnit`)
    setValue(`schedules.${index}.periodUnit`, UnitOfTime.UNIT_OF_TIME_DAY)
    return () => {
      if (getValues(`schedules.${index}.periodChoice`) !== 'OnThisDay') {
        unregister(`schedules.${index}.onThisDate`)
      }
    }
  }, [])

  return (
    <Controller
      control={control}
      name={`schedules.${index}.onThisDate`}
      rules={{ required: true }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <DatePicker
            customInput={
              <CalendarInput
                showErrorBorder={!!error}
                data-testid={`schedules.${index}.onThisDate`}
              />
            }
            onChange={(e: Date) => {
              if (e) {
                const date = fromDateToDateMessage(e)
                onChange(date)
                setValue('effectivePeriod.startDate', date)
                setValue('effectivePeriod.endDate', date)
              }
            }}
            selected={fromDateMessageToDate(
              value ?? getValues('effectivePeriod.startDate')
            )}
            disabled={readOnly}
          />
        </>
      )}
    />
  )
}
