import { ChangeEvent, useEffect, useState } from 'react'
import {
  BasicInput,
  BasicRadioButton,
} from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect, {
  StyledMultiSelect,
} from '@shared/components/Selects/StyledSelect'
import { Person } from '@shared/types/person'
import { Snapshot, SnapshotStatus } from '@shared/types/snapshot'
import { getLanguageOptions } from '@shared/utils/communication'
import {
  GtkyFields,
  GtkyPersonPatch,
  GtkySnapshotPatch,
} from '@shared/utils/gtky'
import LongformAnswer from '@app/pages/Tasks/GettingToKnowYouV2/components/LongformAnswer'
import {
  getOtherLanguageOptions,
  getPrimaryLanguageOption,
  getUpdatedCommunicationPreference,
} from './helpers'

type Props = {
  updateSnapshot: ({
    patch,
    status,
  }: {
    patch: GtkySnapshotPatch | null
    status: SnapshotStatus
  }) => Promise<void>
  updatePerson: ({
    patch,
    fields,
  }: {
    patch: GtkyPersonPatch
    fields: string
  }) => Promise<void>
  snapshot: Snapshot
  person: Person
  isEditing: boolean
}

const SERVED_IN_MILITARY = 'Yes'
export default function AboutYouPage({
  updateSnapshot,
  updatePerson,
  snapshot,
  person,
  isEditing,
}: Props) {
  const [fields, setFields] = useState<GtkySnapshotPatch>({})

  const [militaryService, setMilitaryService] = useState<boolean>(
    snapshot.data?.formData?.fields?.['military-service'] === SERVED_IN_MILITARY
  )
  const [occupations, setOccupations] = useState<string>()
  const [education, setEducation] = useState<string>()

  const disabled = !isEditing

  const languageOptions = getLanguageOptions({
    communication: person.communication,
    countryCode: 'USA',
  })
  const defaultPrimaryLanguage = getPrimaryLanguageOption(person)
  const defaultOtherLanguages = getOtherLanguageOptions(person)

  useEffect(() => {
    const served =
      snapshot.data?.formData?.fields?.[GtkyFields.MILITARY_SERVICE]
    setMilitaryService(served === SERVED_IN_MILITARY)
  }, [snapshot!.data])

  const saveSnapshot = async (formValues = fields) =>
    await updateSnapshot({ patch: formValues, status: snapshot.status! })

  const savePerson = async (formData: GtkyPersonPatch, fieldsToMatch: string) =>
    await updatePerson({ patch: formData, fields: fieldsToMatch })

  return (
    <>
      <div>
        <LabelAboveInput htmlFor={GtkyFields.BORN_AND_LIVED} uppercase={false}>
          Where were you born? Where have you lived?
        </LabelAboveInput>
        <LongformAnswer
          mode={isEditing ? 'edit' : 'view'}
          disabled={disabled}
          onBlur={async () => await saveSnapshot()}
          id={GtkyFields.BORN_AND_LIVED}
          name={GtkyFields.BORN_AND_LIVED}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            setFields({
              ...fields,
              [GtkyFields.BORN_AND_LIVED]: e.target.value,
            })
          }}
          defaultValue={
            snapshot.data?.formData?.fields?.[GtkyFields.BORN_AND_LIVED] || ''
          }
        />
      </div>

      <div>
        <LabelAboveInput
          htmlFor={GtkyFields.PRIMARY_LANGUAGE}
          uppercase={false}
        >
          What is your primary language?
        </LabelAboveInput>
        <StyledSelect
          isDisabled={disabled}
          id={GtkyFields.PRIMARY_LANGUAGE}
          name={GtkyFields.PRIMARY_LANGUAGE}
          instanceId={GtkyFields.PRIMARY_LANGUAGE}
          data-testid={GtkyFields.PRIMARY_LANGUAGE}
          onChange={async (value: { label: string; value: string }) => {
            const communication = getUpdatedCommunicationPreference({
              person,
              selectedLanguages: [value.label],
              isPreferred: true,
            })
            await savePerson(
              {
                communication,
              },
              'communication'
            )
          }}
          options={languageOptions}
          placeholder="Select a language..."
          defaultValue={defaultPrimaryLanguage}
        />
      </div>

      <div>
        <LabelAboveInput htmlFor={GtkyFields.OTHER_LANGUAGES} uppercase={false}>
          What other languages do you speak?
        </LabelAboveInput>
        <StyledMultiSelect
          isDisabled={disabled}
          id={GtkyFields.OTHER_LANGUAGES}
          name={GtkyFields.OTHER_LANGUAGES}
          instanceId={GtkyFields.OTHER_LANGUAGES}
          onChange={async (value: { label: string; value: string }[]) => {
            const communication = getUpdatedCommunicationPreference({
              person,
              selectedLanguages: value.map((val) => val.label),
              isPreferred: false,
            })
            await savePerson({ communication }, 'communication')
          }}
          options={languageOptions}
          placeholder="Select languages..."
          defaultValue={defaultOtherLanguages}
        />
      </div>

      <div>
        <LabelAboveInput
          htmlFor={GtkyFields.PREVIOUS_OCCUPATIONS}
          uppercase={false}
        >
          What were your former occupation(s)/profession(s)?
        </LabelAboveInput>
        <LongformAnswer
          mode={isEditing ? 'edit' : 'view'}
          disabled={disabled}
          onBlur={async () => {
            await savePerson(
              {
                socialHistory: {
                  ...person.socialHistory,
                  occupationSummary: occupations,
                },
              },
              'socialHistory'
            )
          }}
          id={GtkyFields.PREVIOUS_OCCUPATIONS}
          name={GtkyFields.PREVIOUS_OCCUPATIONS}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            setOccupations(e.target.value)
          }}
          defaultValue={person.socialHistory?.occupationSummary}
        />
      </div>

      <div>
        <LabelAboveInput
          htmlFor={GtkyFields.EDUCATION_HISTORY}
          uppercase={false}
        >
          Education Level, School Attended, Degrees
        </LabelAboveInput>
        <LongformAnswer
          mode={isEditing ? 'edit' : 'view'}
          disabled={disabled}
          onBlur={async () => {
            await savePerson(
              {
                socialHistory: {
                  ...person.socialHistory,
                  educationSummary: education,
                },
              },
              'socialHistory'
            )
          }}
          id={GtkyFields.EDUCATION_HISTORY}
          name={GtkyFields.EDUCATION_HISTORY}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            setEducation(e.target.value)
          }}
          defaultValue={person.socialHistory?.educationSummary}
        />
      </div>

      <div>
        <LabelAboveInput
          htmlFor={GtkyFields.MILITARY_SERVICE}
          uppercase={false}
        >
          Did you serve in the military?
        </LabelAboveInput>
        <div className={'flex flex-row'}>
          <BasicRadioButton
            disabled={disabled}
            name={GtkyFields.MILITARY_SERVICE}
            value={'true'}
            holderClassName={'ml-[8px]'}
            defaultChecked={militaryService}
            onClick={async () => {
              setMilitaryService(true)
              const updatedFields = {
                ...fields,
                [GtkyFields.MILITARY_SERVICE]: SERVED_IN_MILITARY,
              }
              setFields(updatedFields)
              await saveSnapshot(updatedFields)
            }}
          >
            Yes
          </BasicRadioButton>
          <BasicRadioButton
            disabled={disabled}
            name={GtkyFields.MILITARY_SERVICE}
            value={'false'}
            holderClassName={'ml-[24px]'}
            defaultChecked={!militaryService}
            onClick={async () => {
              setMilitaryService(false)
              const updatedFields = {
                ...fields,
                [GtkyFields.MILITARY_SERVICE]: 'No',
                [GtkyFields.MILITARY_BRANCH]: null,
              }
              setFields(updatedFields)
              await saveSnapshot(updatedFields)
            }}
          >
            No
          </BasicRadioButton>
        </div>
      </div>

      <div>
        <LabelAboveInput htmlFor={GtkyFields.MILITARY_BRANCH} uppercase={false}>
          If yes, which branch and for how long?
        </LabelAboveInput>
        <BasicInput
          disabled={disabled}
          name={GtkyFields.MILITARY_BRANCH}
          id={GtkyFields.MILITARY_BRANCH}
          onBlur={async () => saveSnapshot()}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFields({
              ...fields,
              [GtkyFields.MILITARY_BRANCH]: e.target.value,
            })
          }}
          defaultValue={
            snapshot.data?.formData?.fields?.[GtkyFields.MILITARY_BRANCH] || ''
          }
        />
      </div>

      <div>
        <LabelAboveInput
          htmlFor={GtkyFields.DESCRIBE_YOURSELF}
          uppercase={false}
        >
          How would you describe yourself? What makes you laugh?
        </LabelAboveInput>
        <LongformAnswer
          mode={isEditing ? 'edit' : 'view'}
          disabled={disabled}
          onBlur={async () => await saveSnapshot()}
          id={GtkyFields.DESCRIBE_YOURSELF}
          name={GtkyFields.DESCRIBE_YOURSELF}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            setFields({
              ...fields,
              [GtkyFields.DESCRIBE_YOURSELF]: e.target.value,
            })
          }}
          defaultValue={
            snapshot.data?.formData?.fields?.[GtkyFields.DESCRIBE_YOURSELF] ||
            ''
          }
        />
      </div>

      <div>
        <LabelAboveInput htmlFor={GtkyFields.PETS} uppercase={false}>
          Do/have you had any pets that are important to you?
        </LabelAboveInput>
        <LongformAnswer
          mode={isEditing ? 'edit' : 'view'}
          disabled={disabled}
          onBlur={async () => await saveSnapshot()}
          id={GtkyFields.PETS}
          name={GtkyFields.PETS}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            setFields({
              ...fields,
              [GtkyFields.PETS]: e.target.value,
            })
          }}
          defaultValue={
            snapshot.data?.formData?.fields?.[GtkyFields.PETS] || ''
          }
        />
      </div>

      <div>
        <LabelAboveInput htmlFor={GtkyFields.ACCOMPLISHMENTS} uppercase={false}>
          What are past accomplishments, sources of pride, and the greatest
          pleasures in your life?
        </LabelAboveInput>
        <LongformAnswer
          mode={isEditing ? 'edit' : 'view'}
          disabled={disabled}
          onBlur={async () => await saveSnapshot()}
          id={GtkyFields.ACCOMPLISHMENTS}
          name={GtkyFields.ACCOMPLISHMENTS}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            setFields({
              ...fields,
              [GtkyFields.ACCOMPLISHMENTS]: e.target.value,
            })
          }}
          defaultValue={
            snapshot.data?.formData?.fields?.[GtkyFields.ACCOMPLISHMENTS] || ''
          }
        />
      </div>

      <div>
        <LabelAboveInput htmlFor={GtkyFields.VOLUNTEERING} uppercase={false}>
          Are you interested in volunteering within the community?
        </LabelAboveInput>
        <LongformAnswer
          mode={isEditing ? 'edit' : 'view'}
          disabled={disabled}
          onBlur={async () => await saveSnapshot()}
          id={GtkyFields.VOLUNTEERING}
          name={GtkyFields.VOLUNTEERING}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            setFields({
              ...fields,
              [GtkyFields.VOLUNTEERING]: e.target.value,
            })
          }}
          defaultValue={
            snapshot.data?.formData?.fields?.[GtkyFields.VOLUNTEERING] || ''
          }
        />
      </div>
    </>
  )
}
