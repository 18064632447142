import { useState } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { mergePatchTask } from '@shared/api/task'
import useCurrentPage, { extractIds } from '@shared/hooks/useCurrentPage'
import { documentsUrl, getTasksUrl, getTaskUrl } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { DataType, SnapshotStatus } from '@shared/types/snapshot'
import { Task, TaskStatus } from '@shared/types/task'
import { GtkyPersonPatch, GtkySnapshotPatch } from '@shared/utils/gtky'
import CompletedTaskVisitDocuments from '@app/components/CongratsModal/CompletedTaskVisitDocuments'
import { usePersonInContext } from '@app/hooks/usePerson'
import useSnapshot from '@app/hooks/useSnapshot'
import { useTasks } from '@app/hooks/useTasks'
import GettingToKnowYouViewerV2 from '@app/pages/Documents/Viewer/GettingToKnowYouViewerV2'
import styles from './gtky.module.css'
import { GettingToKnowYouSteps } from '../TaskPageNavigation/step-definitions'
import TaskPageNavigation from '../TaskPageNavigation/TaskPageNavigation'
import GettingToKnowYouPageProducer from './GettingToKnowYouPageProducer'
import {
  GtkyPageToNumberedStepMap,
  updatePerson,
  updateSnapshot,
} from './helpers'

const gtkyPath =
  '/orgs/:orgId/facilities/:facilityId/(prospects|residents)/:personId/tasks/:taskId/:stepNumber?'
export default function GettingToKnowYouV2() {
  const history = useHistory()
  const currentPage = useCurrentPage()
  const { facilityId, orgId, personId, stepNumber, taskId } =
    extractIds(currentPage)

  const { person, refreshPerson } = usePersonInContext({
    initialData: { id: personId, orgId, facilityId },
  })
  const { tasks } = useTasks(true, [])
  const { snapshot, refreshSnapshot } = useSnapshot({
    dataType: DataType.DATA_TYPE_GETTING_TO_KNOW_YOU_V2,
    createOnFetch: true,
  })
  const [promiseQueue, setPromiseQueue] = useState<Promise<void>>(
    Promise.resolve()
  )
  const [showCongrats, setShowCongrats] = useState(false)

  const task = tasks.find((task) => task.id === taskId)

  if (
    person === undefined ||
    task === undefined ||
    orgId === undefined ||
    personId === undefined ||
    snapshot.tag === 'Loading'
  ) {
    return null
  }

  const finishLater = async () => {
    return history.push(
      getTasksUrl({ orgId, facilityId: facilityId || '', personId })
    )
  }

  const currentStepNumber = parseInt(stepNumber || '1')
  const taskPath = getTaskUrl({
    orgId,
    facilityId: facilityId || '',
    personId,
    id: taskId,
  })
  const tasksPath = getTasksUrl({
    orgId,
    facilityId: facilityId || '',
    personId,
  })
  const documentsPath = documentsUrl({ person: person as Required<Person> })

  const saveSnapshot = async ({
    patch,
    status,
  }: {
    patch: GtkySnapshotPatch | null
    status: SnapshotStatus
  }) => {
    const nextPromise = updateSnapshot({
      status,
      person,
      patch,
      initialPromise: promiseQueue,
    })
    setPromiseQueue(nextPromise)
    await nextPromise
    await refreshSnapshot()
  }

  const savePerson = async ({
    patch,
    fields,
  }: {
    patch: GtkyPersonPatch
    fields: string
  }) => {
    const nextPromise = updatePerson({
      person,
      patch,
      fields,
      initialPromise: promiseQueue,
    })
    setPromiseQueue(nextPromise)
    await nextPromise
    await refreshPerson()
  }

  const finishTask = async () => {
    const completedTask: Partial<Task> = {
      snapshotId: snapshot.value.id,
      status: TaskStatus.TASK_STATUS_COMPLETE,
      shared: true,
    }

    await mergePatchTask({
      pId: person.id,
      orgId: person.orgId,
      taskId: task.id,
      task: completedTask,
    })
  }

  const onCompleteForm = async () => {
    await saveSnapshot({
      patch: null,
      status: SnapshotStatus.SNAPSHOT_STATUS_COMPLETE,
    })
    await finishTask()
    setShowCongrats(true)
  }

  const onContinue = () => {
    const nextStepNumber = currentStepNumber + 1
    history.push(`${taskPath}/${nextStepNumber}`)
  }

  return (
    <div className={styles.formBody}>
      <header>
        <h1 className={styles.taskTitle}>
          {task.taskTemplateInfo?.displayName || 'Getting to Know You'}
        </h1>
      </header>
      <div className="flex">
        <TaskPageNavigation
          stepLinks={GettingToKnowYouSteps}
          currentStepNumber={currentStepNumber}
          promiseQueue={promiseQueue}
          person={person}
          task={task}
        />
        {snapshot.tag === 'Complete' && (
          <Switch>
            <Route
              exact
              path={`${taskPath}/${GtkyPageToNumberedStepMap.REVIEW_AND_FINALIZE}`}
            >
              <>
                {!showCongrats && (
                  <GettingToKnowYouViewerV2
                    person={person}
                    gettingToKnowYou={snapshot.value}
                    mode={{
                      tag: 'review',
                      onComplete: () => void onCompleteForm(),
                      onMakeChanges: () =>
                        history.push(
                          `${taskPath}/${GtkyPageToNumberedStepMap.ABOUT_YOU}`
                        ),
                    }}
                    title={'Getting To Know You'}
                  />
                )}
                {showCongrats && (
                  <CompletedTaskVisitDocuments
                    redirectToDocumentsPage={() => history.push(documentsPath)}
                    redirectToTasksPage={() => history.push(tasksPath)}
                  />
                )}
              </>
            </Route>
            <Route exact path={gtkyPath}>
              <GettingToKnowYouPageProducer
                mode={{
                  tag: 'edit',
                  updateSnapshot: saveSnapshot,
                  updatePerson: savePerson,
                  onContinue: onContinue,
                  onFinishLater: () => void finishLater(),
                }}
                snapshot={snapshot.value}
                person={person}
                currentStepNumber={currentStepNumber}
              />
            </Route>
          </Switch>
        )}
      </div>
    </div>
  )
}
