import {
  SignableFormAssignment,
  SignableFormMetaData,
} from '@augusthealth/models/com/august/protos/signable_form'
import { tw } from '@shared/utils/tailwind'
import AssignmentCard from '../AssignTemplates/AssignmentCard'

export default function AssignmentList({
  assignments,
  forms,
  sortFunc,
  reloadAssignments,
}: {
  assignments: SignableFormAssignment[]
  forms: SignableFormMetaData[]
  sortFunc?: (s1: SignableFormAssignment, s2: SignableFormAssignment) => number
  reloadAssignments?: () => Promise<void>
}) {
  const sortAssignments = [...assignments]
  if (sortFunc) {
    sortAssignments.sort(sortFunc)
  }

  if (sortAssignments.length) {
    return (
      <ol>
        {sortAssignments.map((a) => {
          return (
            <li key={`assignment-li-${a.id}`}>
              <AssignmentCard
                assignment={a}
                formList={forms}
                includeDeleteBtn
                reloadAssignments={reloadAssignments}
              />
            </li>
          )
        })}
      </ol>
    )
  }

  return <div className={tw`mt-[16px]`}>No assignments found</div>
}
