import { SlidingScaleEntry } from '@augusthealth/models/com/august/protos/dosage'
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import { BadgeSelect } from '@shared/components/Selects/BadgeSelect'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import {
  MedOrderFormData,
  SlidingScaleEntryWithId,
} from '@shared/types/medication_order'
import { getQuantityUnitOptions } from '@shared/utils/medicationStatement'
import { areStringsIdentical } from '@shared/utils/string'
import { tw } from '@shared/utils/tailwind'
import { DynamicFrequencyInputs } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/DynamicFrequencyInputs/DynamicFrequencyInputs'
import { updateSlidingScaleDosesToMatchDoseUnit } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/helpers'
import { SlidingScaleEntryRow } from './SlidingScaleEntryRow/SlidingScaleEntryRow'
import { VitalCheck } from './VitalCheck'

type Props = {
  readOnly: boolean
  doseIndex: number
}
export const SlidingScaleForm = ({ doseIndex, readOnly }: Props) => {
  const methods = useFormContext<MedOrderFormData>()
  const { formState, getValues, control } = methods
  const { fields, insert, remove } = useFieldArray({
    control,
    name: `doses.${doseIndex}.slidingScale.entries`,
  })
  const formData: MedOrderFormData = useWatch({ control }) as MedOrderFormData
  const firstDose = formData.doses![doseIndex]
  const scaleEntries = firstDose?.slidingScale?.entries ?? []

  const measuredUnit = 'mg/dL'
  const doseUnit = scaleEntries[0]?.doseQuantity?.unit

  const entryErrors: Merge<
    FieldError,
    FieldErrorsImpl<SlidingScaleEntry[]>
  > = formState.errors?.doses?.[doseIndex]?.slidingScale?.entries ?? []
  const firstRangeErrorIndex =
    // @ts-ignore
    entryErrors?.findIndex(
      (entry: FieldErrorsImpl<SlidingScaleEntry>) =>
        entry?.bounds &&
        // @ts-ignore
        (!!entry.bounds.low?.value || !!entry.bounds.high?.value)
    ) ?? undefined

  const firstUnitErrorIndex =
    // @ts-ignore
    entryErrors?.findIndex((entry) => !!entry?.doseQuantity?.value) ?? undefined

  const addNewScaleEntry = (entryIndex: number) => {
    const currentEntries = getValues(
      `doses.${doseIndex}.slidingScale.entries`
    ) as SlidingScaleEntryWithId[]

    const previousHigh = currentEntries?.[entryIndex]?.bounds?.high?.value
      ? (currentEntries[entryIndex].bounds!.high!.value as number) + 1
      : undefined

    insert(
      entryIndex + 1,
      {
        tempId: uuid(),
        bounds: {
          low: {
            value: previousHigh,
            unit: measuredUnit,
          },
          high: {
            value: undefined,
            unit: measuredUnit,
          },
        },
        doseQuantity: {
          value: undefined,
          unit: doseUnit,
        },
        note: undefined,
      },
      {
        shouldFocus: true,
        focusName: `doses.${doseIndex}.slidingScale.entries.${entryIndex + 1}.bounds.high`,
      }
    )
  }
  const removeScaleEntry = (entryIndex: number) => {
    remove(entryIndex)
  }

  const wasSubmitted = formState.isSubmitted
  const selectedSlidingScaleUnit =
    scaleEntries.find((entry) => entry?.doseQuantity?.unit)?.doseQuantity
      ?.unit ??
    getValues(`doses.${doseIndex}`)?.quantity?.unit ??
    'unit'

  const quantityUnitOptions: OptionTypeBase[] = getQuantityUnitOptions(
    selectedSlidingScaleUnit
  )
  const unitName = `sliding-scale-${doseIndex}-unit`

  return (
    <>
      <VitalCheck dose={firstDose} index={doseIndex} />
      <DynamicFrequencyInputs index={doseIndex} readOnly={readOnly} />
      <div className={'mt-[16px]'}>
        <BadgeSelect
          containerClassName={tw`w-fit min-w-[300px] max-w-[380px]`}
          badgeLabel={'Administered Unit'}
          inputId={unitName}
          instanceId={unitName}
          name={unitName}
          data-testid={unitName}
          options={quantityUnitOptions}
          placeholder="Select..."
          onChange={(e: OptionTypeBase) => {
            updateSlidingScaleDosesToMatchDoseUnit({
              newUnit: e.value,
              methods,
              doseIndex,
            })
          }}
          value={quantityUnitOptions.find((opt) =>
            areStringsIdentical(selectedSlidingScaleUnit, opt.value, {
              caseSensitive: false,
            })
          )}
          isDisabled={readOnly}
        />
        {fields?.map((se, sIndex) => {
          return (
            <SlidingScaleEntryRow
              key={`sliding-scale-entry-${se.tempId}`}
              scaleEntry={se}
              readOnly={readOnly}
              index={sIndex}
              doseIndex={doseIndex}
              addScaleEntry={() => addNewScaleEntry(sIndex)}
              removeScaleEntry={() => removeScaleEntry(sIndex)}
              showRangeError={wasSubmitted && sIndex === firstRangeErrorIndex}
              showUnitError={wasSubmitted && sIndex === firstUnitErrorIndex}
              scaleUnit={selectedSlidingScaleUnit}
            />
          )
        })}
      </div>
    </>
  )
}
