import { useContext, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { fetchFacility } from '@shared/api/facility'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Facility } from '@shared/types/facility'
import { tw } from '@shared/utils/tailwind'
import Content from '@app/components/generic/Content'
import PersonPageTitle from '@app/components/PersonPageTitle'
import { formatReportName } from './helpers'
import ReportTable from './ReportTable'

type ReportParams = {
  orgId: string
  facilityId: string
  reportName: string
}

type ReportPageProps = RouteComponentProps<ReportParams>

export default function ReportReview(props: ReportPageProps) {
  const { setError } = useContext(GlobalContext)
  const [facility, setFacility] = useState<Facility | undefined>()
  const { orgId, facilityId, reportName } = props.match.params

  useEffect(() => {
    fetchFacility({ orgId, fId: facilityId })
      .then((res) => setFacility(res.data))
      .catch(setError)
  }, [orgId, facilityId])

  if (orgId && facility && facilityId && reportName) {
    return (
      <Content className={tw`mt-[36px]`}>
        <PersonPageTitle
          title={`${formatReportName(reportName)} - ${facility.name}`}
          withBorder={false}
        />
        <ReportTable orgId={orgId} facilityId={facilityId} name={reportName} />
      </Content>
    )
  }

  return <SimpleSpinner />
}
