import { useCallback, useContext, useEffect, useState } from 'react'
import { getFacility } from '@shared/api/facility'
import Card from '@shared/components/Card'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useMARClockSettings } from '@shared/hooks/useMARClockSettings'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { getOrganization } from '@app/api/organizations'
import HUD from '@app/components/HUD'
import { OrgFacilitySelects } from '../SharedSelects/OrgFacilitySelects'
import FacilitySettings from './FacilitySettings'
import OrgSettings from './OrgSettings'

export default function FacilityManagement() {
  const { setError } = useContext(GlobalContext)
  const [selectedOrganization, setSelectedOrganization] =
    useState<OptionTypeBase<Organization> | null>(null)
  const [selectedFacility, setSelectedFacility] =
    useState<OptionTypeBase<Facility> | null>(null)
  const [facility, setFacility] = useState<Facility | null>(null)
  const [organization, setOrganization] = useState<Organization | null>(null)
  const [showHUD, setShowHUD] = useState(false)

  const { orgFacilitySettings, refreshMarSettings } = useMARClockSettings({
    orgId: selectedOrganization?.value.id,
    facilityId: selectedFacility?.value.id,
  })

  const getAndSetFacility = useCallback(() => {
    if (!selectedOrganization || !selectedFacility) {
      setFacility(null)
    }

    if (selectedOrganization && selectedFacility) {
      getFacility({
        orgId: selectedOrganization.value.id,
        facilityId: selectedFacility.value.id,
      })
        .then(setFacility)
        .catch(setError)
    }
  }, [selectedOrganization, selectedFacility])

  useEffect(() => {
    getAndSetFacility()
  }, [getAndSetFacility])

  useEffect(() => {
    if (selectedOrganization) {
      getOrganization(selectedOrganization.value.id)
        .then(setOrganization)
        .catch(setError)
    }
  }, [selectedOrganization])

  useEffect(() => {
    refreshMarSettings().catch(setError)
  }, [selectedOrganization, selectedFacility])

  const handleOnFacilitySelect = (
    selectedFacility: OptionTypeBase<Facility> | null
  ) => {
    setSelectedFacility(selectedFacility)
  }

  const handleOnOrgSelect = (
    selectedOrganization: OptionTypeBase<Organization> | null
  ) => {
    setSelectedOrganization(selectedOrganization)
  }

  const handleOnUpdateOrgSettings = () => {
    getAndSetFacility()
  }

  return (
    <div className="content-holder">
      <h1 className="page-title mt-[24px]">Org & Facility Management</h1>
      <div
        style={{
          display: 'grid',
          gap: '32px',
          gridTemplateColumns: 'repeat(auto-fill, minmax(496px, 1fr))',
        }}
      >
        <section>
          <Card className="mb-[16px] flex flex-col">
            <OrgFacilitySelects
              onFacilitySelect={handleOnFacilitySelect}
              onOrgSelect={handleOnOrgSelect}
              orgLabel={'I want to view settings for...'}
              selectedOrganization={selectedOrganization}
              selectedFacility={selectedFacility}
              showInactiveOrganizations
            />
          </Card>
        </section>
      </div>

      <div className="flex gap-x-4">
        {organization && (
          <OrgSettings
            org={organization}
            setShowHUD={setShowHUD}
            onUpdate={handleOnUpdateOrgSettings}
          />
        )}

        {facility && orgFacilitySettings.tag === 'Complete' && (
          <FacilitySettings
            facility={facility}
            marClockSettings={orgFacilitySettings.value}
            setFacility={setFacility}
            setShowHUD={setShowHUD}
          />
        )}
      </div>

      {showHUD && (
        <HUD
          onExpire={() => {
            setShowHUD(false)
          }}
        >
          <div className="text-[16px] font-semibold leading-[24px]">
            Settings updated
          </div>
        </HUD>
      )}
    </div>
  )
}
