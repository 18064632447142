import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import {
  PaymentBillingStrategy,
  PaymentFrequency,
  PaymentProduct,
} from '@shared/types/payment'
import { isEmptyObject } from '@shared/utils/common'

export const BILLING_STRATEGY_MAP = {
  [PaymentBillingStrategy.PAYMENT_BILLING_STRATEGY_PER_FACILITY]: 'By Facility',
  [PaymentBillingStrategy.PAYMENT_BILLING_STRATEGY_PER_ORGANIZATION]:
    'By Organization',
}

export const FREQUENCY_MAP = {
  [PaymentFrequency.PAYMENT_FREQUENCY_1_MONTH]: '1 month',
  [PaymentFrequency.PAYMENT_FREQUENCY_1_YEAR]: '1 year',
}

export const PRODUCT_MAP = {
  [PaymentProduct.PAYMENT_PRODUCT_BOARD_AND_CARE]: 'Board and care',
  [PaymentProduct.PAYMENT_PRODUCT_MOVE_IN]: 'Move in',
  [PaymentProduct.PAYMENT_PRODUCT_PLATFORM]: 'Platform',
}

function covertMapToOptions(hashMap: Record<string, string>) {
  return Object.keys(hashMap).map((k) => ({
    label: hashMap[k],
    value: k,
  }))
}

export const BillingStrategyOptions = covertMapToOptions(BILLING_STRATEGY_MAP)

export const FrequencyOptions = covertMapToOptions(FREQUENCY_MAP)

export const ProductOptions = covertMapToOptions(PRODUCT_MAP)

export function removeEmptyPaymentCustomer(organization: Organization) {
  const newOrganization = { ...organization }
  const { paymentCustomer } = newOrganization
  if (isEmptyObject(paymentCustomer)) {
    delete newOrganization.paymentCustomer
  }

  return newOrganization
}

export function removeEmptyPaymentSubscription(facility: Partial<Facility>) {
  const newFacility = { ...facility }
  const { paymentSubscription } = newFacility
  if (isEmptyObject(paymentSubscription)) {
    delete newFacility.paymentSubscription
  }

  return newFacility
}
