import {
  AugustFieldOption,
  FieldOption,
  JsonReport,
} from '@augusthealth/models/com/august/protos/api/json_report'
import { ReportAndAssignments } from '@augusthealth/models/com/august/protos/api/report_assignments'
import {
  Report,
  ReportAssignment,
} from '@augusthealth/models/com/august/protos/report'
import { requestJson } from '@shared/api/request'
import environment from '@shared/environment'
import { Facility } from '@shared/types/facility'
import { ONE_TIME_CHARGES_REPORT_NAME } from '../hooks/useReportMenuAndPreview'

const baseUrl = environment.baseUrl
const reportsUrl = `${baseUrl}/reports`
const orgUrl = `${baseUrl}/organizations`

type CreateResponse = {
  data: {
    id: string
  }
  meta: {
    hello: 'Created'
  }
}

export function createReport(body: Report): Promise<CreateResponse> {
  return requestJson({
    method: 'POST',
    url: reportsUrl,
    body: JSON.stringify(body),
  })
}

type ApiResponse = {
  data: object
  meta: {
    hello: 'Deleted' | 'Updated'
  }
}

export function deleteReport(reportId: string): Promise<ApiResponse> {
  return requestJson({
    method: 'DELETE',
    url: `${reportsUrl}/${reportId}`,
  })
}

type FetchReportsResponse = {
  data: Report[]
}

export function fetchReports(): Promise<FetchReportsResponse> {
  return requestJson({ url: reportsUrl })
}

export type FetchAssignmentsResponse = {
  data: ReportAssignment[]
}

export function fetchReportAssignmentsByFacility({
  facility,
}: {
  facility: Facility
}): Promise<FetchAssignmentsResponse> {
  const { id, orgId } = facility
  return requestJson({
    url: `${orgUrl}/${orgId}/facilities/${id}/reports/assignments`,
  })
}

export function assignReport(body: ReportAssignment): Promise<CreateResponse> {
  return requestJson({
    method: 'POST',
    url: `${reportsUrl}/assignments`,
    body: JSON.stringify(body),
  })
}

export function unassignReport(assignmentId: string): Promise<ApiResponse> {
  return requestJson({
    method: 'DELETE',
    url: `${reportsUrl}/assignments/${assignmentId}`,
  })
}

export function updateReportAssignment(
  assignment: ReportAssignment
): Promise<ReportAssignment> {
  const { id, ...rest } = assignment
  if (id) {
    return requestJson({
      method: 'PATCH',
      contentType: 'application/merge-patch+json',
      url: `${reportsUrl}/assignments/${id}`,
      body: JSON.stringify(rest),
    })
  }

  return Promise.reject(new Error('Assign ID not found'))
}

export function getReportCsvUrl(
  orgId: string,
  facilityId: string,
  reportName: string
): string {
  return `${orgUrl}/${orgId}/facilities/${facilityId}/reports/${reportName}.csv`
}

export function previewReportCsvUrl(orgId: string, facilityId: string): string {
  return `${orgUrl}/${orgId}/facilities/${facilityId}/reports/preview.csv`
}

type FetchReportJson = {
  data: JsonReport
}

export function fetchReportJSON(
  orgId: string,
  facilityId: string,
  reportName: string
): Promise<FetchReportJson> {
  if (reportName === ONE_TIME_CHARGES_REPORT_NAME) {
    return requestJson({
      url: `${orgUrl}/${orgId}/facilities/${facilityId}/charges/report.json`,
    })
  }
  return requestJson({
    url: `${orgUrl}/${orgId}/facilities/${facilityId}/reports/${reportName}.json`,
  })
}

export function previewReportJson(
  orgId: string,
  facilityId: string,
  report: Report
): Promise<FetchReportJson> {
  return requestJson({
    url: `${orgUrl}/${orgId}/facilities/${facilityId}/reports/preview.json`,
    method: 'POST',
    body: JSON.stringify(report),
  })
}

type FetchReportNames = {
  data: string[]
}

export function fetchReportNamesByFacility(
  orgId: string,
  facilityId: string,
  { limitedRead = false }: { limitedRead: boolean }
): Promise<FetchReportNames> {
  return requestJson({
    url: `${orgUrl}/${orgId}/facilities/${facilityId}/reports?limitedRead=${limitedRead}`,
  })
}

export function listAvailableFields(): Promise<FieldOption[]> {
  return requestJson({ url: `${reportsUrl}/availableFields` }).then((r) =>
    r.data.map((v) => {
      if (v.displayInfo.description === undefined) {
        v.displayInfo.description = ''
      }
      return v
    })
  )
}

export function listAugustValues(): Promise<AugustFieldOption[]> {
  return requestJson({ url: `${reportsUrl}/augustValues` }).then((r) => r.data)
}

export async function listAllReportAndAssignments(): Promise<
  ReportAndAssignments[]
> {
  const response = await requestJson({
    url: `${reportsUrl}/allReportAndAssignments`,
  })
  return response.data
}
