import {
  Charge,
  Recurring_Rate,
} from '@augusthealth/models/com/august/protos/charge'
import { useContext } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { centsToMoney } from '@shared/utils/charge'
import { formatDateMessage } from '@shared/utils/date'
import ConfirmModal from '@app/components/ConfirmModal'
import styles from './deleteCharge.module.css'
import { deleteRateChange } from './helpers'

interface Props {
  charge: Charge
  rateIndex: number
  person: Person
  done: (cancelled: boolean) => void
}

export default function CancelScheduledRateChange({
  charge,
  person,
  rateIndex,
  done,
}: Props) {
  const { setError } = useContext(GlobalContext)

  const onConfirm = async () => {
    try {
      await deleteRateChange(person, charge, rateIndex)
      done(true)
    } catch (e) {
      setError(e)
    }
  }

  return (
    <ConfirmModal
      title="Do you want to cancel this rate change?"
      content={contentForCharge(charge.recurring!.rates![rateIndex])}
      confirmButtonConfig={{
        children: 'Cancel Rate Change',
        onClick: onConfirm,
      }}
      denyButtonConfig={{ onClick: () => done(false) }}
    />
  )
}

function contentForCharge(rate: Recurring_Rate) {
  const amount = centsToMoney(rate.amount!)

  return (
    <div className={styles.deleteCharge}>
      <h3>Change to {amount}</h3>
      <span>{formatDateMessage(rate.start)}</span>
    </div>
  )
}
