import { useState } from 'react'
import useLocalStorage from '@shared/hooks/useLocalStorage'
import { BillingEvent } from '@shared/types/billing'
import { RequiredPersonIds } from '@shared/types/person'
import { BillingModalType } from '@shared/utils/billing'
import { getOrElse, Loading } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import { DownloadMenu } from '@app/components/DownloadMenu'
import { Header } from '@app/components/Table/TableHeader'
import WithTable from '@app/pages/Facilities/Billing/Components/WithTable'
import { TransactionsColumnName } from '../../helpers'
import {
  BadgeFilterType,
  filterTransactions,
  getBadgeFilterOptions,
  getPeriodItems,
} from './helpers'
import TransactionsBadgeFilter from './TransactionBadgesFilter'
import TransactionRow from './TransactionRow'

type Props = {
  personIds: RequiredPersonIds
  billingTransactions: Loading<BillingEvent[]>
  openTransactionModal: (
    transaction: BillingEvent,
    modalType: BillingModalType
  ) => void
}

const headers: Header[] = [
  {
    label: TransactionsColumnName.SERVICE_DATE,
    className: 'w-auto',
  },
  {
    label: TransactionsColumnName.DESCRIPTION,
    className: 'w-[18%]',
  },
  {
    label: TransactionsColumnName.TRANSACTION_TYPE,
    className: 'w-[18%]',
  },
  {
    label: TransactionsColumnName.AMOUNT,
    className: 'w-[18%]',
  },
  {
    label: TransactionsColumnName.BALANCE,
    className: 'w-[18%]',
  },
  {
    label: '',
    className: 'w-[120px]',
  }, // Column for Action Menu, no label
]

export const BILLING_MONTH_INDEX_STORAGE_NAME = 'monthIndex'

export default function BillingTransactionsTable(props: Props) {
  const { billingTransactions, openTransactionModal, personIds } = props
  const [badgeFilter, setBadgeFilter] = useState<BadgeFilterType>()
  const [storedMonthIndex, setMonthIndex] = useLocalStorage<number | undefined>(
    BILLING_MONTH_INDEX_STORAGE_NAME,
    undefined
  )
  const monthIndex = getOrElse(storedMonthIndex, undefined)
  const periodOptions = getPeriodItems({
    selectedMonthIndex: monthIndex,
    onClick: setMonthIndex,
  })

  const selectedPeriodLabel = (
    periodOptions.find((opt) => opt.mIndex === monthIndex) || periodOptions[0]
  ).title

  return (
    <div className={tw`mt-8 flex flex-col`}>
      <div className={tw`flex items-center justify-between`}>
        <div className={tw`flex items-center justify-start gap-4`}>
          <h3 className={tw`m-0`}>Transactions</h3>
          <TransactionsBadgeFilter
            name="transactionBadgeFilter"
            options={getBadgeFilterOptions(getOrElse(billingTransactions, []))}
            onClick={(badgeValue) =>
              setBadgeFilter((prev) =>
                prev === badgeValue
                  ? undefined
                  : (badgeValue as BadgeFilterType)
              )
            }
            selectedValue={badgeFilter}
          />
        </div>
        <DownloadMenu
          key={'time-download-menu'}
          menuItems={periodOptions}
          menuProps={{
            buttonProps: {
              initialIcon: 'calendar',
              cta: selectedPeriodLabel,
              dataTestId: 'time-filter-menu',
            },
          }}
        />
      </div>
      <div className={tw`pb-8 pt-4`}>
        <WithTable
          rows={billingTransactions}
          headers={headers}
          defaultSelectedColumn={TransactionsColumnName.SERVICE_DATE}
        >
          {(sortedRows: BillingEvent[]) => (
            <>
              {filterTransactions({
                transactions: sortedRows,
                badgeFilter,
                monthIndex,
              }).map((transaction, index) => {
                return (
                  <TransactionRow
                    key={`transaction-row-${index}`}
                    personIds={personIds}
                    transaction={transaction}
                    openTransactionModal={openTransactionModal}
                  />
                )
              })}
            </>
          )}
        </WithTable>
      </div>
    </div>
  )
}
