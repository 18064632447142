/* eslint-disable @typescript-eslint/no-explicit-any */
import { fetchBlobUrlAndContentType } from '@shared/api/request'

const URL_REPLACE_MAP = {
  fId: ':facilityId',
  pId: ':personId',
  formCode: ':formCode',
  taskId: ':taskId',
  snapshotId: ':snapshotId',
  dataType: ':dataType',
  orgId: ':orgId',
}

interface QueueItem {
  params: Record<string, unknown>
  promise: Promise<unknown>
}

/**
 * Takes a patch function and makes it 'serial' such that no two calls can be subsequently outstanding.
 * This is so that a user cannot 'conflict' with itself by sending a second patch before the first patch has returned.
 */
export function queued(
  patchFn: (params: Record<string, unknown>) => Promise<any>
) {
  const queue: Array<QueueItem> = []
  return function (params: Record<string, unknown>): Promise<any> {
    let promise: Promise<unknown>
    if (queue.length === 0) {
      promise = patchFn(params)
    } else {
      // Await completion of previous promise before running the new one
      const previousItem = queue[queue.length - 1]
      promise = previousItem.promise.then(() => patchFn(params))
    }
    // Using finally here leads to an uncaught exception warning when the request fails
    promise = promise
      .then((result) => {
        queue.shift()
        return result
      })
      .catch((e) => {
        queue.shift()
        return Promise.reject(e)
      })
    const queueItem: QueueItem = {
      params,
      promise,
    }

    queue.push(queueItem)
    return promise
  }
}

/**
 * This is truly a nonsense method. Why did we reimplement URL construction?
 *
 * @deprecated Do not use this. Replace with proper URL handling.
 */
export function getUrl({
  baseUrl = '',
  params,
  ...props
}: {
  baseUrl?: string
  params?: Record<string, unknown>
  [key: string]: unknown
}): string {
  let url = baseUrl

  Object.keys(URL_REPLACE_MAP).forEach((k) => {
    if (props[k] !== undefined) {
      url = url.replace(URL_REPLACE_MAP[k] as string, props[k] as string)
    }
  })

  if (params !== undefined) {
    url =
      url +
      '?' +
      Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key] as string)}`)
        .join('&')
  }

  return url
}

//
// 601
//

//
// 603
//

//
// Admissions Agreement related
//

//
// Consent Forms
//

//
// Snapshots
//

interface DownloadBaseProps {
  body?: BodyInit
  contentType?: string
  fileUrl: string
  method?: 'GET' | 'POST'
}

type DownloadOneOfProps =
  | {
      target: '_blank' | '_parent' | '_self' | '_top'
      fileName?: undefined
    }
  | {
      target?: undefined
      fileName: string
    }
//
// E-Sign
//
export async function download(props: DownloadBaseProps & DownloadOneOfProps) {
  const { body, contentType, fileName, fileUrl, method = 'GET', target } = props
  const blobData = await fetchBlobUrlAndContentType({
    url: fileUrl,
    method,
    body,
    requestContentType: contentType,
  })

  const a = document.createElement('a')
  a.href = blobData.url
  if (target) {
    a.target = target
  } else {
    a.download = fileName || blobData.filename || 'Untitled'
  }

  a.click()
}
