import { AugustFieldFormConfigurationMap } from '@shared/constants/custom_field/type'
import { AugustFieldType } from '@shared/types/custom_field'

export const HEALTH_DIET: AugustFieldFormConfigurationMap = {
  [AugustFieldType.AUGUST_FIELD_TYPE_ALLERGIES]: {
    fieldLists: ['allergiesAndIntolerances'],
    requiredFields: [],
    fields: [
      {
        title: 'FOOD ALLERGIES',
        name: 'allergiesAndIntolerances.allergies[category=ALLERGY_INTOLERANCE_CATEGORY_FOOD][*]',
        holderClassName: 'mb-0',
        itemNamePostfix: 'summary',
        type: 'hash_list',
        className: 'no-style',
        placeholder: 'Enter food allergies. Leave blank if none.',
      },
      {
        title: 'MEDICAL ALLERGIES',
        description: 'List medication and environmental allergies.',
        name: 'allergiesAndIntolerances.allergies[category=ALLERGY_INTOLERANCE_CATEGORY_MEDICATION][*]',
        itemNamePostfix: 'summary',
        type: 'hash_list',
        className: 'no-style',
        showRowNumber: true,
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_AMBULATORY_STATUS]: {
    fieldLists: ['ambulatoryStatus/code'],
    requiredFields: [],
    fields: [
      {
        title: 'WHICH OF THESE BEST DESCRIBES THE RESIDENT?',
        name: 'ambulatoryStatus.code',
        type: 'ambulatory_radio_group',
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_ON_HOSPICE]: {
    fieldLists: ['hospiceStatus/onHospice'],
    requiredFields: [],
    fields: [
      {
        title: 'HOSPICE',
        name: 'hospiceStatus.onHospice',
        type: 'boolean_buttons',
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_IS_RESPITE]: {
    fieldLists: ['respiteStatus/isRespite'],
    requiredFields: [],
    fields: [
      {
        title: 'RESPITE',
        name: 'respiteStatus.isRespite',
        type: 'boolean_buttons',
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_CONDITION]: {
    fieldLists: ['conditions'],
    requiredFields: [],
    fields: [
      {
        title: 'DIAGNOSES',
        description:
          'List medical conditions including major illness, surgeries, or accidents.',
        name: 'conditions',
        itemNamePostfix: 'code.text',
        type: 'condition_list',
        className: 'no-style',
        hideRemoveBtn: true,
        showRowNumber: true,
        useEmptyFieldToAddNewOption: true,
        defaultItem: '',
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_DIETARY_RESTRICTIONS]: {
    fieldLists: ['socialHistory/diet'],
    requiredFields: [],
    fields: [
      {
        name: 'diet',
        type: 'group',
        configuration: [
          {
            groupId: 'dietGroup',
            formGeneratorProps: {
              title: 'DIET',
              description:
                'Does resident have a special diet, food allergies, or food preference?',
              configuration: [
                {
                  title: 'Diet type',
                  labelClassName: 'question-label',
                  holderClassName: 'mb-0',
                  name: 'socialHistory.diet.dietTypeValue',
                  type: 'diet_dropdown_list',
                },
                {
                  title: 'Additional notes',
                  labelClassName: 'question-label',
                  name: 'socialHistory.diet.summary',
                  type: 'hash_text',
                  placeholder:
                    'Enter any additional information on diet or food preference.',
                },
              ],
            },
          },
        ],
      },
    ],
  },
}
