import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import Card from '@shared/components/Card'
import { tw, twx } from '@shared/utils/tailwind'

export interface Props extends PropsWithChildren {
  children: React.ReactNode
  cardTitle: string | React.ReactNode
  iconClassName?: string
  className?: string
  contentClassName?: string
  defaultExpanded?: boolean
  expanded?: boolean
  expandTrigger?: boolean
  onExpandClick?: (willBeExpanded: boolean) => void
  contentTestId?: string
  ignoreClickIds?: string[]
  role?: string
  'data-testid'?: string
  expandedIcon?: string
  collapsedIcon?: string
  enableAutoScrollOnExpand?: boolean
}

export default function CollapsibleCard(props: Props) {
  const {
    children,
    cardTitle,
    className,
    contentClassName,
    iconClassName,
    onExpandClick,
    contentTestId,
    defaultExpanded = true,
    ignoreClickIds = [],
    expanded,
    expandTrigger = expanded,
    expandedIcon = 'fa-xmark fa-lg',
    collapsedIcon = 'fa-pencil',
    role,
    enableAutoScrollOnExpand = false,
  } = props
  const [isExpanded, setIsExpanded] = useState(defaultExpanded)
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const toExpand = expanded ? expanded : isExpanded
    setIsExpanded(toExpand)
    onExpandClick?.(toExpand)
  }, [expandTrigger])

  useEffect(() => {
    if (isExpanded && enableAutoScrollOnExpand) {
      contentRef.current?.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [isExpanded])

  return (
    <Card
      data-testid={props['data-testid']}
      role={role}
      className={twx(
        'mb-[24px] flex w-full flex-col rounded-[8px] bg-secondary-12',
        className,
        {
          'bg-white shadow-card': isExpanded,
          'shadow-inset-card': !isExpanded,
        }
      )}
    >
      <div
        className={tw`m-0 flex w-full cursor-pointer items-start justify-between`}
        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
          const clickShouldBeIgnored = ignoreClickIds.some((id) => {
            const ignoreElem = document.getElementById(id)
            return ignoreElem?.contains(event.target as Node)
          })

          if (!clickShouldBeIgnored) {
            const opposite = !isExpanded
            setIsExpanded(opposite)
            onExpandClick?.(opposite)
          }
        }}
      >
        {cardTitle ? (
          typeof cardTitle === 'string' ? (
            <h3
              className={tw`m-0 text-[14px] font-semibold leading-[24px] text-secondary-04`}
            >
              {cardTitle}
            </h3>
          ) : (
            cardTitle
          )
        ) : (
          <div>{/* empty for spacing */}</div>
        )}
        <i
          className={twx(
            'flex h-[24px] w-[24px] items-center justify-center',
            iconClassName,
            'fa-solid',
            {
              'text-gray-07': isExpanded,
              'text-secondary-04': !isExpanded,
              [expandedIcon]: isExpanded,
              [collapsedIcon]: !isExpanded,
            }
          )}
        />
      </div>
      <div
        className={twx(contentClassName, {
          'mt-[24px] border-t border-gray-11 pt-[24px]': isExpanded,
          hidden: !isExpanded,
        })}
        data-testid={contentTestId ?? 'collapsible-card-content'}
        ref={contentRef}
      >
        {children}
      </div>
    </Card>
  )
}
