import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { EditableDetailsCard } from 'app/components/Prospects/Details/DetailsCard'
import { useState } from 'react'
import { Person } from '@shared/types/person'
import notEmpty from '@shared/utils/notEmpty'
import { diagnosesList } from '@shared/utils/person'
import { tw } from '@shared/utils/tailwind'
import styles from '@app/components/AugustFields/Diet/styles.module.css'
import EditDiagnosesModel from './EditDiagnosesModel'

export default function Diagnoses({
  person,
  updatePerson,
}: {
  person: Person
  updatePerson: () => Promise<void>
}) {
  const [showModal, setShowModal] = useState(false)
  const conditions = diagnosesList(person)
  const filteredDiagnosesLabel = conditions
    .map((c) => c.code?.text)
    .filter(notEmpty)
    .join(', ')

  return (
    <EditableDetailsCard
      id="diagnoses-card"
      icon="fas fa-fw fa-stethoscope"
      title="Diagnoses"
      editPermissions={{
        person,
        permissions: [GroupPermission.GROUP_PERMISSION_PERSON_UPDATE],
      }}
      onEditClick={() => setShowModal(true)}
      editButtonId={'edit-diagnoses'}
      className={tw`my-[12px]`}
    >
      <div className={styles.row}>
        <div className={styles.title}>Diagnoses</div>
        <div>
          {filteredDiagnosesLabel ? (
            filteredDiagnosesLabel
          ) : (
            <span className="text-gray-08">None to show</span>
          )}
        </div>
      </div>
      {showModal && (
        <EditDiagnosesModel
          person={person}
          onClose={async (updated) => {
            if (updated) {
              await updatePerson()
            }
            setShowModal(false)
          }}
        />
      )}
    </EditableDetailsCard>
  )
}
