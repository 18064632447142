import { Order } from 'effect'
import {
  CareAppPerson,
  PersonWithRoutineAdministrations,
  RoutineAdministration,
  RoutineAdministrationStatus,
} from '@shared/types/careapp'
import { getAdministrationsForPerson } from '@shared/utils/careapp'
import { sortPeopleByRoomNumber } from '@shared/utils/person'

/**
 * We don't have to check the outOfFacilityDetail start and end date because
 * the backend does not return an outOfFacilityDetail if we have past the
 * outOfFacilityDetail end date.
 */
export function isAway({ person }: PersonWithRoutineAdministrations) {
  return person.outOfFacilityDetail !== undefined
}

export const byFamilyName = Order.mapInput(
  Order.string,
  ({ person }: PersonWithRoutineAdministrations) => person.name.family ?? ''
)

export const byRoomNumber = Order.make<PersonWithRoutineAdministrations>(
  (a, b) => sortPeopleByRoomNumber(a.person, b.person)
)

export const byAwayStatus = Order.mapInput(Order.boolean, isAway)

export const byUnspecifiedAdministrationsCount = Order.mapInput(
  Order.number,
  ({ administrations }: PersonWithRoutineAdministrations) =>
    administrations.filter(
      ({ status }) =>
        status ===
        RoutineAdministrationStatus.ROUTINE_ADMINISTRATION_STATUS_UNSPECIFIED
    ).length
)

export const personWithRoutineAdministrationsFromPerson =
  (shiftOccurrenceAdministrations: RoutineAdministration[]) =>
  (person: CareAppPerson): PersonWithRoutineAdministrations => {
    return {
      person,
      administrations: getAdministrationsForPerson({
        person,
        routineAdministrations: shiftOccurrenceAdministrations,
      }),
    }
  }
