import { useContext, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { confirmForgotPassword, requestPasswordCode } from '@shared/api/user'
import Footer from '@shared/components/Auth/LoginWithUsernameOrEmail/Footer'
import Loader from '@shared/components/Auth/LoginWithUsernameOrEmail/Loader'
import LogoHeader from '@shared/components/LogoHeader'
import GlobalContext from '@shared/contexts/GlobalContext'
import { loginPath } from '@shared/routes'
import { ApiError, ErrorCode } from '@shared/types/api/error_response'
import { ForgotPasswordRequest } from '@shared/types/api/forgot_password'
import { PasswordPolicy } from '@shared/types/settings/security_policy'
import { tw } from '@shared/utils/tailwind'
import ChangePasswordForm from './ChangePasswordForm'
import RequestPasswordCodeForm from './RequestPasswordCodeForm'
import { ForgotPasswordFormProps } from './type'

export default function ForgotPassword({
  applicationBasePath,
}: {
  applicationBasePath: string
}) {
  const { setError } = useContext(GlobalContext)
  const [isLoading, setIsLoading] = useState(false)
  const [forgotPasswordStep, setForgotPasswordStep] = useState<
    | { name: 'resetPassword' }
    | { name: 'codeRequestError' }
    | { name: 'confirmPasswordError'; error: Error }
    | { name: 'requestResetLink' }
  >({ name: 'requestResetLink' })
  const [passwordPolicy, setPasswordPolicy] = useState<
    PasswordPolicy | undefined
  >(undefined)
  const [forgotPasswordRequest, setForgotPasswordRequest] = useState<
    ForgotPasswordRequest | undefined
  >(undefined) // Email or Username
  const history = useHistory()

  const methods = useForm<ForgotPasswordFormProps>({
    defaultValues: {
      code: '',
      password: '',
      passwordConfirmation: '',
    },
    mode: 'all',
    criteriaMode: 'all',
  })

  const { reset } = methods

  async function handleRequestPasswordCode(
    forgotPasswordRequest: ForgotPasswordRequest
  ): Promise<void> {
    setIsLoading(true)
    try {
      const passwordPolicyResponse: PasswordPolicy | undefined =
        await requestPasswordCode(forgotPasswordRequest)
      if (passwordPolicyResponse) {
        setPasswordPolicy(passwordPolicyResponse)
        setForgotPasswordRequest(forgotPasswordRequest)
        setForgotPasswordStep({ name: 'resetPassword' })
      } else {
        setForgotPasswordStep({ name: 'codeRequestError' })
      }
    } catch (e) {
      reset()
      setForgotPasswordStep({ name: 'codeRequestError' })
      setError(e)
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <Loader title="Loading" />
  }

  if (forgotPasswordStep.name === 'codeRequestError') {
    return (
      <div
        className={tw`align-items-center flex flex-col rounded-xl bg-white bg-white px-[60px] pb-[24px] pt-[24px] text-center`}
      >
        <div
          className={tw`font-inter font-[20px] font-semibold leading-[24px] text-secondary-04`}
        >
          Please contact an administrator to reset your password
        </div>
        <div
          className={tw`mt-[16px] font-inter font-[16px] font-medium leading-[24px] text-secondary-07`}
        >
          We don't have an email for that account to reset the password.
        </div>
        <button
          className={tw`border-1 mt-[40px] h-[48px] w-[360px] rounded-[8px] border-gray-10 bg-rebrand-primary text-[14px] font-semibold uppercase leading-[16px] text-white transition-colors duration-[350ms] hover:brightness-90`}
          value="Back to login"
          onClick={() => history.push(loginPath(applicationBasePath))}
          type="button"
        >
          Back to Login
        </button>
      </div>
    )
  }

  if (forgotPasswordStep.name === 'confirmPasswordError') {
    let errorMessages = [
      'It looks like the code or the password you entered is incorrect.',
    ]
    if (forgotPasswordStep.error instanceof ApiError) {
      const errsFiltered = (forgotPasswordStep.error.json?.errors || []).filter(
        (e) => e.code === ErrorCode.ERROR_CODE_DISPLAY_TO_CLIENT
      )
      if (errsFiltered.length > 0) {
        errorMessages = errsFiltered.flatMap((e) => e.message || '')
      }
    }
    return (
      <div
        className={tw`flex flex-col items-center rounded-xl bg-white px-[60px] pb-[24px] pt-[24px]`}
      >
        <div
          className={tw`font-inter font-[20px] font-semibold leading-[24px] text-secondary-04`}
        >
          {errorMessages.map((m, i) => (
            <div key={`error-row-${i}`}>{m}</div>
          ))}
        </div>
        <div
          className={tw`mt-[16px] font-inter font-[16px] font-medium leading-[24px] text-secondary-07`}
        >
          Please try again, or contact an administrator to reset your password
        </div>
        <button
          className={tw`border-1 mt-[40px] h-[48px] w-[360px] rounded-[8px] border-gray-10 bg-rebrand-primary text-[14px] font-semibold uppercase leading-[16px] text-white transition-colors duration-[350ms] hover:brightness-90`}
          value="Back to login"
          onClick={() => setForgotPasswordStep({ name: 'resetPassword' })}
          type="button"
        >
          Try again
        </button>
      </div>
    )
  }

  async function changePassword({
    password,
    code,
  }: {
    password: string
    code: string
  }) {
    try {
      if (forgotPasswordRequest?.userAlias === undefined) {
        throw new Error('User alias is undefined')
      }

      await confirmForgotPassword({
        userAlias: forgotPasswordRequest.userAlias,
        code,
        password,
      })

      history.push(loginPath(applicationBasePath))
    } catch (e) {
      if (e instanceof Error) {
        setForgotPasswordStep({ name: 'confirmPasswordError', error: e })
      } else {
        setForgotPasswordStep({
          name: 'confirmPasswordError',
          error: new Error(),
        })
      }
    }
  }

  if (
    forgotPasswordStep.name === 'resetPassword' &&
    passwordPolicy !== undefined
  ) {
    return (
      <div
        className={tw`flex flex-col items-center rounded-xl bg-white px-[60px] pb-[16px] pt-[60px]`}
      >
        <LogoHeader title="" />
        <FormProvider {...methods}>
          <ChangePasswordForm
            passwordPolicy={passwordPolicy}
            onSubmit={changePassword}
          />
        </FormProvider>
        <Footer />
      </div>
    )
  }

  return (
    <div
      className={tw`flex w-[480px] flex-col items-center rounded-xl bg-white px-[60px] pb-[16px] pt-[60px]`}
    >
      <LogoHeader
        title={''}
        copy="Enter the email address or username for your account, and we'll send you a link to reset your password."
      />

      <RequestPasswordCodeForm onSubmit={handleRequestPasswordCode} />
      <Footer />
    </div>
  )
}
