import { Contact_ContactRelationship } from '@augusthealth/models/com/august/protos/contact'
import styles from './styles.module.css'

export default function RelationshipLabels({
  register,
}: {
  register: (name: string, options: object) => object
}) {
  return (
    <div className={styles.labelList}>
      <div>
        <input
          className="visually-hidden"
          type="radio"
          id="personal-relationship"
          value={Contact_ContactRelationship.CONTACT_RELATIONSHIP_PERSONAL}
          {...register('primaryRelationship', { required: true })}
        />
        <label htmlFor="personal-relationship" className={styles.cardLabel}>
          <i className="fa-solid fa-user fa-2x" />
          Personal
        </label>
      </div>
      <div>
        <input
          className="visually-hidden"
          type="radio"
          id="medical-relationship"
          value={Contact_ContactRelationship.CONTACT_RELATIONSHIP_CLINICIAN}
          {...register('primaryRelationship', { required: true })}
        />
        <label htmlFor="medical-relationship" className={styles.cardLabel}>
          <i className="fa-solid fa-user-nurse fa-2x" />
          Medical
        </label>
      </div>
      <div>
        <input
          className="visually-hidden"
          type="radio"
          id="professional-relationship"
          value={Contact_ContactRelationship.CONTACT_RELATIONSHIP_PROFESSIONAL}
          {...register('primaryRelationship', { required: true })}
        />
        <label htmlFor="professional-relationship" className={styles.cardLabel}>
          <i className="fa-solid fa-user-tie fa-2x" />
          Professional
        </label>
      </div>
    </div>
  )
}
