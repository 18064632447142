import { PersonWithRoutineAdministrations } from '@shared/types/careapp'
import { tw } from '@shared/utils/tailwind'
import { useProgressContext } from '../ProgressContext'
import { useSelectedRoutineAdministrationsContext } from '../SelectedRoutineAdministrationsContext'
import ResidentRoutinesCard from './ResidentRoutinesCard'

export default function ResidentRoutes({
  filteredPeopleWithAdministration,
  facilityTimeZone,
  searchText,
  reload,
}: {
  filteredPeopleWithAdministration: PersonWithRoutineAdministrations[]
  facilityTimeZone: string
  searchText: string
  reload: () => void
}) {
  const { selectedProgressTypes } = useProgressContext()
  const { selected: selectedAdministrations, toggleAdministration } =
    useSelectedRoutineAdministrationsContext()

  if (filteredPeopleWithAdministration.length === 0) {
    return (
      <div className={tw`mt-1 text-center text-[14px] text-secondary-07`}>
        {searchText
          ? 'No people or room matching the search filter: '
          : 'No administrations for selected filters'}
        {searchText && (
          <code
            className={tw`font-semibold leading-[16px] text-august-primary`}
          >
            {searchText}
          </code>
        )}
      </div>
    )
  }

  return (
    <>
      {filteredPeopleWithAdministration.map(({ person, administrations }) => {
        return (
          <ResidentRoutinesCard
            key={`person-card-${person.id}`}
            careAppPerson={person}
            routineAdministrations={administrations}
            facilityTimeZone={facilityTimeZone}
            reload={reload}
            selectedProgressTypes={selectedProgressTypes}
            selectedAdministrations={selectedAdministrations}
            toggleAdministration={toggleAdministration}
          />
        )
      })}
    </>
  )
}
