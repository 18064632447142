import { ReactNode } from 'react'
import { ClassNameValue } from 'tailwind-merge'
import Pill from '@shared/components/Pill'
import { display as displayShift, Shift } from '@shared/types/shift'
import { Timing } from '@shared/types/timing'
import notEmpty from '@shared/utils/notEmpty'
import { twx } from '@shared/utils/tailwind'
import {
  formatDayOfMonth,
  formatDayOfWeekAbbreviated,
  formatTimeOfDayEventTiming,
  formatTimeOfDayTime,
  getDaysOfMonth,
  getDaysOfWeek,
  getFormattedPeriodAndFrequencyDisplayData,
  getTimesOfDay,
} from '@shared/utils/timing'

export const getFormattedDaysOfWeek = (timing: Timing): string[] => {
  return getDaysOfWeek(timing).map(
    (day) => formatDayOfWeekAbbreviated(day) as string
  )
}

export const getFormattedDaysOfMonth = (timing: Timing): string[] => {
  return getDaysOfMonth(timing).map((day) => {
    return formatDayOfMonth(day)
  })
}

export const getFormattedTimesOfDay = (
  timing: Timing,
  { use24HourClock }: { use24HourClock: boolean }
): string[] => {
  return getTimesOfDay(timing)
    .map((time) => {
      if (time.time?.hour !== undefined) {
        return formatTimeOfDayTime({ time: time.time, use24HourClock })
      }

      if (time.when) {
        return formatTimeOfDayEventTiming(time.when)
      }

      if (time.shift) {
        return displayShift(time.shift as Shift)
      }

      return null
    })
    .filter(notEmpty)
}

export const TimingBadge = (props: {
  children: ReactNode
  className?: ClassNameValue
  ignoreBaseClass?: boolean
  badgeStyle?: 'default' | 'alert'
}) => {
  if (!props.children) {
    return null
  }
  return (
    <Pill
      shape="rectangle"
      size={'medium'}
      className={twx(
        'inline-flex bg-primary-light text-white',
        {
          capitalize: !props.ignoreBaseClass,
          'normal-case': props.ignoreBaseClass,
          'border border-button-quaternary-color bg-button-quaternary-color text-white':
            props.badgeStyle === 'alert',
        },
        props.className
      )}
    >
      {props.children}
    </Pill>
  )
}

export default function TimingBadges({
  displayPeriodAndFrequency = false,
  allowUnspecifiedFrequency = false, // Some legacy routines have no frequency specified; we can still display their period.
  timing,
  use24HourTime,
  keyPrefix,
  className,
}: {
  displayPeriodAndFrequency?: boolean
  allowUnspecifiedFrequency?: boolean
  timing?: Timing
  use24HourTime: boolean
  keyPrefix: string
  className?: string
}) {
  if (!timing) {
    return null
  }

  const periodAndFrequency = displayPeriodAndFrequency
    ? getFormattedPeriodAndFrequencyDisplayData(timing, {
        allowUnspecifiedFrequency,
      })
    : undefined

  return (
    <>
      {periodAndFrequency && (
        <TimingBadge
          key={`${keyPrefix}-period-frequency`}
          className={twx(className)}
        >
          {periodAndFrequency}
        </TimingBadge>
      )}
      {getFormattedDaysOfMonth(timing).map((day, idx) => {
        return (
          <TimingBadge
            key={`${keyPrefix}-day-of-month-${idx}`}
            className={twx(className)}
          >
            {day}
          </TimingBadge>
        )
      })}
      {getFormattedDaysOfWeek(timing).map((day) => (
        <TimingBadge
          key={`${keyPrefix}-day-of-week-${day}`}
          className={twx(className)}
        >
          {day}
        </TimingBadge>
      ))}
      {getFormattedTimesOfDay(timing, {
        use24HourClock: use24HourTime,
      }).map((formattedTime, idx) => {
        return (
          <TimingBadge
            key={`${keyPrefix}-time-${idx}`}
            className={twx(className)}
          >
            {formattedTime}
          </TimingBadge>
        )
      })}
    </>
  )
}
