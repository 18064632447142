import { FunctionComponent } from 'react'
import { UseFormReturn } from 'react-hook-form'
import {
  AsyncIconButton as Button,
  Props as ButtonProps,
} from '@shared/components/AsyncButton'
import { tw } from '@shared/utils/tailwind'
import DraggableListItem from './DraggableLiWrapper'
import NoDuplicateInput from './ListItems/NoDuplicateInput'

/**
 * HTML OrderList with drag-and-drop re-order ability based on ract-hook-form
 * @param ListItem [FunctionComponent] UI Component for List Item, default to NoDuplicateInput
 * @param itemProps [Hash] Additional props pass to ListItem
 * @param addBtnLabel [string | ButtonProps | false] Don't show Add Button when received false
 * @param placeholder [string] Text when list is empty
 * @param useFormReturn [UseFormReturn] return of useForm
 */

const DEFAULT_BUTTON_PROPS: ButtonProps = {
  buttonStyle: 'primary-fill',
  buttonSize: 'small',
}

export default function DraggableList({
  ListItem = NoDuplicateInput,
  addBtn = 'Add new',
  placeholder,
  path,
  useFormReturn,
  itemProps = {},
  defaultValueToBeAdded = '',
}: {
  ListItem?: FunctionComponent<
    Partial<{
      index: number
      path: string
      useFormReturn: UseFormReturn<any>
    }>
  >
  addBtn?: string | ButtonProps | false
  placeholder?: string
  path: string
  useFormReturn: UseFormReturn<any>
  itemProps?: Record<string, any>
  defaultValueToBeAdded?: string | Record<string, any>
}) {
  const { setValue, watch } = useFormReturn
  const list = watch(path)

  let addBtnProps: ButtonProps | undefined
  if (addBtn) {
    if (typeof addBtn === 'string') {
      addBtnProps = {
        ...DEFAULT_BUTTON_PROPS,
        children: addBtn,
      }
    } else {
      addBtnProps = {
        ...DEFAULT_BUTTON_PROPS,
        ...addBtn,
      }
    }
  }

  return (
    <div>
      <ol className={tw`list-decimal pl-10`}>
        {list?.map((_item, index) => {
          return (
            <DraggableListItem
              key={`list-item-${index}`}
              index={index}
              path={path}
              useFormReturn={useFormReturn}
            >
              <ListItem
                {...itemProps}
                index={index}
                path={path}
                useFormReturn={useFormReturn}
              />
            </DraggableListItem>
          )
        })}
      </ol>
      {addBtnProps && (
        <div className={tw`mb-[16px] flex`}>
          <div className={tw`flex-1`}>
            {(!list || list.length === 0) && placeholder}
          </div>
          <div className={tw`flex-0`}>
            <Button
              data-testid="add-new-item-btn"
              type="button"
              onClick={() => {
                setValue(path, [...(list || []), defaultValueToBeAdded], {
                  shouldDirty: true,
                })
              }}
              {...addBtnProps}
            />
          </div>
        </div>
      )}
    </div>
  )
}
