import Card from '@shared/components/Card'
import { Vital } from '@shared/types/vital'
import { tw } from '@shared/utils/tailwind'
import { Table, TableContainer } from '@app/components/Table'
import TableHeader from '@app/components/Table/TableHeader'
import VitalsTableBody from './VitalsTableBody'

export default function VitalsTable({
  label,
  vitalsForTab,
}: {
  label: string
  vitalsForTab: Vital[]
}) {
  return (
    <Card className={tw`p-0`}>
      <TableContainer id="vitals-table-holder" withScrollBar>
        <Table>
          <TableHeader
            headers={[
              { label, className: 'w-[30%]' },
              { label: 'Date', className: 'w-[30%]' },
              { label: '', className: 'w-[30%]' },
              { label: '', className: 'w-[5%]' },
              { label: '', className: 'w-[5%]' },
            ]}
          />
          <VitalsTableBody vitals={vitalsForTab} />
        </Table>
      </TableContainer>
    </Card>
  )
}
