import { Controller, useFormContext } from 'react-hook-form'
import { LabelAboveInput, requiredWhenError } from '@shared/components/Labels'
import StyledSelect from '@shared/components/Selects/StyledSelect'
import { Switch } from '@app/components/Switch'
import styles from '../styles.module.css'
import { admissionTypeOptions } from '../helpers'

export default function AdmissionType() {
  const { control, setValue, watch } = useFormContext()

  return (
    <div className="mb-[32px] mt-[32px] flex">
      <Controller
        control={control}
        name="admissionType"
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { invalid } }) => (
          <div className="mr-[24px]">
            <LabelAboveInput
              subLabel={requiredWhenError(invalid)}
              htmlFor="admissionType"
            >
              Admission Type
            </LabelAboveInput>
            <div style={{ width: '240px' }}>
              <StyledSelect
                name="admissionType"
                onChange={onChange}
                options={admissionTypeOptions}
                placeholder="Select a type..."
                value={value}
              />
            </div>
          </div>
        )}
      />
      <div className={styles.switch}>
        <Switch
          name="respite"
          onChange={(event) => {
            setValue('respite', event.target.checked)
          }}
          checked={watch('respite', false)}
        />
        <label className={styles.switchLabel} htmlFor="respite">
          Respite
        </label>
      </div>
      <div className={`${styles.switch} ml-[24px]`}>
        <Switch
          name="hospice"
          onChange={(event) => {
            setValue('hospice', event.target.checked)
          }}
          checked={watch('hospice', false)}
        />
        <label className={styles.switchLabel} htmlFor="hospice">
          Hospice
        </label>
      </div>
    </div>
  )
}
