import { Route, Switch } from 'react-router-dom'
import ExternalSignerPage from '@app/pages/ExternalSigner'

export const EXTERNAL_SIGNER_PATH =
  '/orgs/:orgId/facilities/:facilityId/person/:id/signDocuments'
export const PERSON_PAGE_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/person/:id',
  EXTERNAL_SIGNER_PATH,
]

/**
 * We started this code path when implementing the 'External Signers' feature.
 * Traditionally, pages that are person-specific would be added to both the
 * Prospects/index and Residents/index routing files. However, the prospect/resident
 * distinction isn't relevant to this flow, so we added a Person/index file to
 * handle the External Signer page.
 */
export default function Person() {
  return (
    <Switch>
      <Route exact path={EXTERNAL_SIGNER_PATH} component={ExternalSignerPage} />
    </Switch>
  )
}
