import { useContext } from 'react'
import { useUserContext } from '@shared/contexts/UserContext'
import useCurrentPage from '@shared/hooks/useCurrentPage'
import {
  isExternalSigner,
  isResponsiblePerson,
  isSocialWorker,
} from '@shared/utils/user'
import {
  BreadcrumbContainer,
  BreadcrumbLinksContainer,
} from '@app/components/Breadcrumbs/layout'
import PersonContext from '@app/contexts/PersonContext'
import { usePersonTabTitle } from '@app/hooks/usePersonTabTitle'
import BreadcrumbEntry from './BreadcrumbEntry'
import { toBreadcrumbs } from './helpers'
import RPBreadcrumbs from './RPBreadcrumbs'

export default function Breadcrumbs() {
  const currentPage = useCurrentPage()
  const { person, tasks = [] } = useContext(PersonContext)
  const { user } = useUserContext()

  usePersonTabTitle()

  if (!person) {
    return null
  }

  const { orgId, facilityId, id: personId } = person
  const params = { orgId, facilityId, personId, user }
  const isRP = isResponsiblePerson(params)
  const isSW = isSocialWorker(params)
  const isExternal = isExternalSigner(params)

  if (isRP || isSW || isExternal) {
    return (
      <RPBreadcrumbs
        person={person}
        tasks={tasks}
        currentPage={currentPage}
        user={user}
      />
    )
  }

  const breadcrumbs = toBreadcrumbs(currentPage, person, tasks)

  return (
    <BreadcrumbContainer>
      <BreadcrumbLinksContainer>
        {breadcrumbs.map((breadcrumb, index) => (
          <BreadcrumbEntry
            key={breadcrumb.text + index}
            breadcrumb={breadcrumb}
            index={index}
          />
        ))}
      </BreadcrumbLinksContainer>
    </BreadcrumbContainer>
  )
}
