import { VictoryLabel, VictoryLabelProps } from 'victory'

// Can be replaced by tickFormat={(t) => t.toFixed(0)} in VictoryAxis
// But is safer to create YTickLabel for type check, etc.
export default function YTickLabel(props: VictoryLabelProps) {
  const { text } = props
  const formattedText =
    typeof text === 'string' ? text.replace(/\.0$/, '') : text

  return <VictoryLabel {...props} text={formattedText} />
}
