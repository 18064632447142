import { AppraisalSettings_AppraisalCategory } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { useContext } from 'react'
import { SectionDisclosure } from '@shared/components/Disclosure'
import CurrentFacilityContext from '@shared/contexts/CurrentFacilityContext'
import { Person } from '@shared/types/person'
import { RoutineOrder, RoutineStatus } from '@shared/types/routine_order'
import { TaskTemplateInfo } from '@shared/types/task'
import { AsyncResult, getOrElse, mapAsyncResult } from '@shared/utils/loading'
import { effectiveStatus } from '@shared/utils/routineOrder'
import PersonContext from '@app/contexts/PersonContext'
import useAppraisalSettingsCategories from '@app/hooks/useAppraisalSettingsCategories'
import useRoutines from '@app/hooks/useRoutines'
import { NoteCardSkeleton } from '../Notes/NoteCardSkeleton'
import { NoRoutines } from './components/NoRoutines'
import RoutinePageTitle from './components/RoutinePageTitle'
import { RoutineOrderList } from './RoutineOrders'

export default function RoutinesWrapper() {
  const { configuredTasks = { tag: 'Loading' } } = useContext(
    CurrentFacilityContext
  )
  const { person } = useContext(PersonContext)
  const { categories } = useAppraisalSettingsCategories({
    person,
  })

  if (person === undefined) {
    return null
  }

  return (
    <Routines
      person={person}
      configuredTasks={getOrElse(configuredTasks, [])}
      categories={getOrElse(categories, [])}
    />
  )
}

export function Routines({
  person,
  configuredTasks,
  categories,
}: {
  person: Person
  configuredTasks: TaskTemplateInfo[]
  categories: AppraisalSettings_AppraisalCategory[]
}) {
  const { routineOrders, refreshRoutineOrders } = useRoutines({ person })
  const displayableRoutineOrders = mapAsyncResult(
    routineOrders,
    (routineOrders) =>
      routineOrders.filter((order) => {
        if (order.routineType.assessment) {
          return (
            order.routineType.assessment.tasks &&
            order.routineType.assessment.tasks.length > 0
          )
        }
        return true
      })
  )

  const activeRoutineOrders = mapAsyncResult(
    displayableRoutineOrders,
    (routineOrders) =>
      routineOrders.filter((order) => effectiveStatus(order) === 'active')
  )
  const expiredRoutineOrders = mapAsyncResult(
    displayableRoutineOrders,
    (routineOrders) =>
      routineOrders.filter((order) => effectiveStatus(order) === 'expired')
  )
  const discontinuedRoutineOrders = mapAsyncResult(
    displayableRoutineOrders,
    (routineOrders) =>
      routineOrders.filter((order) => effectiveStatus(order) === 'discontinued')
  )
  const unscheduledRoutineOrders = mapAsyncResult(
    displayableRoutineOrders,
    (routineOrders) =>
      routineOrders.filter((order) => effectiveStatus(order) === 'discarded')
  )
  const routinesToDisplay = (
    routines: AsyncResult<RoutineOrder[], unknown>
  ): boolean => routines.tag === 'Complete' && !!routines.value.length

  const noActiveRoutineOrders = !routinesToDisplay(displayableRoutineOrders)
  const onlyUnscheduledActiveRoutineOrders =
    activeRoutineOrders.tag === 'Complete' &&
    activeRoutineOrders.value.filter(
      (routineOrder) =>
        routineOrder.status !== RoutineStatus.ROUTINE_STATUS_DISCARDED
    ).length === 0

  if (routineOrders.tag === 'Loading') {
    return (
      <>
        <RoutinePageTitle person={person} />
        <NoteCardSkeleton />
      </>
    )
  }

  return (
    <div className={'mb-[32px] flex flex-col gap-4'}>
      <RoutinePageTitle person={person} />
      {noActiveRoutineOrders && (
        <NoRoutines person={person} configuredTasks={configuredTasks} />
      )}
      <RoutineOrderList
        categories={categories}
        routineOrders={activeRoutineOrders}
        person={person}
        onRoutineOrderChange={refreshRoutineOrders}
      />
      {routinesToDisplay(unscheduledRoutineOrders) && (
        <SectionDisclosure
          summary="Unscheduled Routines"
          open={onlyUnscheduledActiveRoutineOrders}
        >
          <RoutineOrderList
            categories={categories}
            routineOrders={unscheduledRoutineOrders}
            person={person}
            onRoutineOrderChange={refreshRoutineOrders}
          />
        </SectionDisclosure>
      )}
      {routinesToDisplay(expiredRoutineOrders) && (
        <SectionDisclosure summary="Expired Routines">
          <RoutineOrderList
            categories={categories}
            routineOrders={expiredRoutineOrders}
            person={person}
            onRoutineOrderChange={refreshRoutineOrders}
          />
        </SectionDisclosure>
      )}
      {routinesToDisplay(discontinuedRoutineOrders) && (
        <SectionDisclosure summary="Discontinued Routines">
          <RoutineOrderList
            categories={categories}
            routineOrders={discontinuedRoutineOrders}
            person={person}
            onRoutineOrderChange={refreshRoutineOrders}
          />
        </SectionDisclosure>
      )}
    </div>
  )
}
