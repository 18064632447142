import { VictoryLabel, VictoryLegendProps, VictoryZoomContainer } from 'victory'
import { Vital } from '@shared/types/vital'
import { VitalsType } from '@shared/utils/vitals'
import AreaChart from '@app/libraries/Victory/AreaChart'
import { CategoriesDataDomain, getAreaChartProps } from './helpers'

export default function Chart({
  activeTab,
  vitalsForTab,
  limit: defaultLimit = 24,
}: {
  activeTab?: VitalsType
  vitalsForTab: Vital[]
  limit?: number
}) {
  const totalCount = vitalsForTab.length
  const limit = Math.min(defaultLimit, totalCount)
  let categoriesDataDomain: CategoriesDataDomain = { data: [[]] }
  if (vitalsForTab.length && activeTab) {
    categoriesDataDomain = getAreaChartProps({
      vitals: vitalsForTab,
      vitalsType: activeTab,
    })
  }
  const victoryLegendProps: VictoryLegendProps | undefined =
    activeTab === VitalsType.BLOOD_PRESSURE
      ? {
          data: [{ name: 'systolic' }, { name: 'diastolic' }],
        }
      : undefined

  return (
    <AreaChart
      id={`vital-${activeTab}-chart`}
      {...categoriesDataDomain}
      chartStyles={{
        height: 89,
        padding: { top: 8, bottom: 12, left: 16, right: 8 },
      }}
      XTickLabel={VictoryLabel}
      victoryLegendProps={victoryLegendProps}
      containerComponent={
        <VictoryZoomContainer
          zoomDimension="x"
          zoomDomain={{ x: [totalCount - limit + 1, totalCount] }}
          allowZoom={totalCount > limit}
        />
      }
    />
  )
}
