import { ErrorCode } from '@augusthealth/models/com/august/protos/api/error_response'
import { useContext, useState } from 'react'
import { useFormContext, UseFormRegister } from 'react-hook-form'
import { UserFormData } from '@shared/components/Auth/LoginWithUsernameOrEmail/PasswordField/types'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import TextInputWithIcon from '@shared/components/TextInputWithIcon/TextInputWithIcon'
import GlobalContext from '@shared/contexts/GlobalContext'
import { isAnEmailString } from '@shared/utils/regex'
import { tw } from '@shared/utils/tailwind'
import { isUsernameTaken } from '@app/api/users'

export default function PreferredUsernameInput({
  setIsEmailAuth,
  register,
  disabled,
}: {
  setIsEmailAuth: React.Dispatch<React.SetStateAction<boolean>>
  register: UseFormRegister<UserFormData>
  disabled: boolean
}) {
  const { formState, getValues } = useFormContext()
  const { setError } = useContext(GlobalContext)
  const [isPreferredUsernameDisabled, setIsPreferredUsernameDisabled] =
    useState<boolean>(false)

  const { errors, dirtyFields } = formState

  async function handleUsernameLookup(value: string | undefined) {
    if (value && dirtyFields.preferredUsername) {
      setIsPreferredUsernameDisabled(true)

      const result = await isUsernameTaken({
        preferredUsername: value,
      })

      if (result === true) {
        setError({
          json: {
            errors: [
              {
                code: ErrorCode.ERROR_CODE_DISPLAY_TO_CLIENT,
                message:
                  'Username already exists. Please pick a different one or contact support for help.',
              },
            ],
          },
        })
      }

      setIsPreferredUsernameDisabled(false)
    }
  }

  return (
    <div className={tw`mb-[32px]`}>
      <div className={tw`flex`}>
        <LabelAboveInput
          htmlFor="preferredUsername"
          subLabel={requiredLabel(Boolean(errors.preferredUsername))}
          errorMessage={
            (errors.preferredUsername?.message as string) || undefined
          }
          ctaProps={{
            text: 'Use email instead',
            onClick: () => setIsEmailAuth(true),
          }}
        >
          Username
        </LabelAboveInput>
      </div>
      <TextInputWithIcon
        inputProps={{
          ...register('preferredUsername', {
            required: !getValues().email,
            validate: {
              mustNotUseEmail: (v: string) =>
                !v || !isAnEmailString(v) ? true : 'Email is not allowed',
            },
          }),
          onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
            const value = e.target.value
            void handleUsernameLookup(value)
          },
          placeholder: 'e.g. employee ID or phone number',
          disabled: isPreferredUsernameDisabled || disabled,
        }}
        iconClassName={tw`fas fa-id-card-clip`}
      />
    </div>
  )
}
