import { useContext, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { match } from 'ts-pattern'
import { verifyMfaCode } from '@shared/components/Auth/helpers'
import Button from '@shared/components/Auth/LoginWithUsernameOrEmail/Button'
import Footer from '@shared/components/Auth/LoginWithUsernameOrEmail/Footer'
import Badge from '@shared/components/Badge'
import { DotDivider } from '@shared/components/DotDivider'
import LogoHeader from '@shared/components/LogoHeader'
import GlobalContext from '@shared/contexts/GlobalContext'
import { ApiError } from '@shared/types/api/error_response'
import { MfaChallenge, MfaMethod } from '@shared/types/auth'
import { buildAugustError } from '@shared/utils/error'
import { tw, twx } from '@shared/utils/tailwind'

function displayCodeDeliveryDestination(
  mfaChallenge: MfaChallenge
): string | undefined {
  const { maskedCodeDeliveryDestination, method } = mfaChallenge
  if (!maskedCodeDeliveryDestination) {
    return undefined
  }

  if (method === MfaMethod.SMS) {
    const lastFour = maskedCodeDeliveryDestination.slice(
      maskedCodeDeliveryDestination.length - 4
    )
    if (lastFour.length !== 4) {
      return undefined
    }

    return `(XXX) XXX-${lastFour}`
  }

  return maskedCodeDeliveryDestination
}

enum MfaBadgeMessage {
  NONE = 'NONE',
  INVALID_CODE = 'INVALID_CODE',
  CODE_RESENT = 'CODE_RESENT',
}

function MfaErrorBadge({ state }: { state: MfaBadgeMessage }) {
  return match(state)
    .with(MfaBadgeMessage.NONE, () => null)
    .with(MfaBadgeMessage.INVALID_CODE, () => (
      <div className={tw`mt-[24px]`}>
        <Badge color={'red'}>The code you entered is invalid</Badge>
      </div>
    ))
    .with(MfaBadgeMessage.CODE_RESENT, () => (
      <div className={tw`mt-[24px]`}>
        <Badge color={'orange'}>A new security code is on the way</Badge>
      </div>
    ))
    .exhaustive()
}

export interface Props {
  mfaChallenge: MfaChallenge
  requestNewCode: () => Promise<void>
  onSuccess: () => void
}

export default function MfaCode({
  mfaChallenge,
  onSuccess,
  requestNewCode,
}: Props) {
  const { setError } = useContext(GlobalContext)
  const methods = useForm({
    defaultValues: { code: '' },
  })
  const {
    formState: { isSubmitting, isValid },
    register,
    handleSubmit,
  } = methods
  const [pillMessage, setPillMessage] = useState<MfaBadgeMessage>(
    MfaBadgeMessage.NONE
  )

  async function onSubmit({ code }: { code: string }) {
    setPillMessage(MfaBadgeMessage.NONE)

    try {
      await verifyMfaCode(code, mfaChallenge)
      onSuccess()
    } catch (e) {
      const isApiError = e instanceof ApiError
      if (isApiError && e.status === 400) {
        setPillMessage(MfaBadgeMessage.INVALID_CODE)
      } else if (isApiError && e.status === 401) {
        setError(
          buildAugustError({
            name: 'Your session has expired',
            message: 'Your session has expired. Please login again.',
          })
        )
      } else {
        setError(
          buildAugustError({
            name: 'Something went wrong!',
            message:
              'Unable to verify MFA code. Please reach out to August Health support for assistance',
          })
        )
      }
    }
  }

  const maskedDeliveryDestination = displayCodeDeliveryDestination(mfaChallenge)

  return (
    <div className={tw`flex flex-col items-center`}>
      <LogoHeader
        title={`We've sent a code to ${maskedDeliveryDestination || 'your configured multi-factor method'}`}
      />
      <MfaErrorBadge state={pillMessage} />
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={tw`mt-[40px] text-center`}
        >
          <div className={tw`relative mb-[16px]`}>
            <input
              type="text"
              autoFocus
              maxLength={6}
              className={twx(
                'h-[48px] w-[360px] rounded-[6px] border pl-[40px] font-medium hover:outline hover:outline-1  focus:outline focus:outline-1 ',
                {
                  'border-gray-10 hover:outline-rebrand-primary-light focus:outline-rebrand-primary-light':
                    pillMessage !== MfaBadgeMessage.INVALID_CODE,
                  'border-tags-red hover:outline-tags-red focus:outline-tags-red':
                    pillMessage === MfaBadgeMessage.INVALID_CODE,
                }
              )}
              placeholder="Enter the verification code"
              {...register('code', { required: true, pattern: /^[0-9]{6}$/ })}
            />
            <i
              className={tw`fa fa-fw fa-shield-halved absolute left-[16px] top-[17px] text-rebrand-primary opacity-75`}
            />
          </div>
          <Button
            isLoading={isSubmitting}
            disabled={isSubmitting || !isValid}
            text="Verify and sign in"
            state="engaged"
          />
        </form>
      </FormProvider>
      <div className={tw`mt-[40px] text-[12px]`}>
        <button
          className={tw`text-secondary-07 underline`}
          onClick={async () => {
            setPillMessage(MfaBadgeMessage.CODE_RESENT)
            try {
              await requestNewCode()
            } catch (error) {
              setError(
                buildAugustError({
                  name: 'Something went wrong!',
                  message:
                    'Unable to request new MFA code. Please reach out to August Health support for assistance',
                })
              )
            }
          }}
        >
          Resend code
        </button>
        <DotDivider />
        <a href="/" className={tw`text-secondary-07 underline`}>
          Back to log in
        </a>
      </div>
      <Footer />
    </div>
  )
}
