import { compact } from 'lodash'
import { FunctionComponent, useEffect, useState } from 'react'
import ElementHolder, { ValueProps } from '../ElementHolder'
import ButtonOption from './ButtonOption'
import CardOption from './CardOption'
import CheckboxOption from './CheckboxOption'
import { Option, OptionComponentProps } from './helpers'
import RadioInputOption from './RadioInputOption'

// TO DO: Need to change onUpdate to onClick so it can be more generic rather Generator specific
type Props = {
  OptionComponent?: FunctionComponent<OptionComponentProps>
  disabled?: boolean
  isOptional?: boolean // Allow to unclick a selected option
  name: string
  onUpdate: (value: ValueProps, name: string) => void
  options: Option[]
  value?: ValueProps
  inGenerator?: boolean
  isMulti?: boolean
}

export default function RadioGroup(props: Props) {
  const {
    name,
    options,
    value,
    onUpdate,
    disabled: defaultDisabled,
    isOptional = false,
    OptionComponent = RadioInputOption,
    inGenerator = false,
    isMulti = false,
  } = props
  const [data, setData] = useState<ValueProps | ValueProps[]>(value)

  useEffect(() => {
    setData(value)
  }, [value])

  function update(val: ValueProps | ValueProps[]) {
    if (isMulti) {
      // CheckboxGroup
      let newData = compact(Array.isArray(data) ? [...data] : [data])
      if (newData.includes(val)) {
        newData = newData.filter((v) => v !== val)
      } else {
        newData = [...newData, val]
      }

      if (newData.length) {
        setData(newData)
        onUpdate(newData, name)
      } else {
        setData(undefined)
        onUpdate(null, name) // Use null value to reset in PATCH
      }
    } else {
      // RadioGroup
      if (val === data && isOptional) {
        setData(undefined)
        onUpdate(null, name) // Use null value to reset in PATCH
      } else {
        setData(val)
        onUpdate(val, name)
      }
    }
  }

  const radioGroup = options.map((opt) => {
    const { disabled = defaultDisabled, value: val } = opt

    return (
      <OptionComponent
        key={`${val}-radio`}
        {...opt}
        name={name}
        onClick={update}
        checked={
          isMulti && Array.isArray(data) ? data.includes(val) : val === data
        }
        disabled={disabled}
      />
    )
  })
  const group = <div>{radioGroup}</div>

  if (inGenerator) {
    return <ElementHolder {...props}>{group}</ElementHolder>
  }

  return group
}

export function CheckboxGroup(props: Omit<Props, 'OptionComponent'>) {
  return <RadioGroup isMulti OptionComponent={CheckboxOption} {...props} />
}

export function CheckboxGroupInGenerator(
  props: Omit<Props, 'OptionComponent'>
) {
  return <CheckboxGroup {...props} inGenerator />
}

export function RadioButtonGroup(props: Omit<Props, 'OptionComponent'>) {
  return <RadioGroup OptionComponent={ButtonOption} {...props} />
}

export function RadioCardGroup(props: Omit<Props, 'OptionComponent'>) {
  return <RadioGroup OptionComponent={CardOption} {...props} />
}

export function RadioInputGroup(props: Omit<Props, 'OptionComponent'>) {
  return <RadioGroup {...props} OptionComponent={RadioInputOption} />
}

export function RadioButtonGroupInGenerator(
  props: Omit<Props, 'OptionComponent'>
) {
  return <RadioButtonGroup {...props} inGenerator />
}

export function RadioCardGroupInGenerator(
  props: Omit<Props, 'OptionComponent'>
) {
  return <RadioCardGroup {...props} inGenerator />
}

export function RadioInputGroupInGenerator(
  props: Omit<Props, 'OptionComponent'>
) {
  return <RadioInputGroup {...props} inGenerator />
}
