import { useState } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import {
  Description,
  Instructions,
  toFullDescription,
} from '@shared/components/RoutineOrders/Instructions'
import { RoutineOrder } from '@shared/types/routine_order'
import { isMorningOrBedtime } from '@shared/utils/routineOrder'

function hasDetailedAssessmentDescription(routineOrder: RoutineOrder): boolean {
  const categoryTasks = routineOrder.routineType?.assessment?.tasks ?? []
  const fullDescription: Description[] = toFullDescription(categoryTasks)
  return fullDescription.some(
    (i) => i.details.length > 0 || i.options.length > 0
  )
}

export function CareTabInstructions({
  routineOrder,
  className,
}: {
  routineOrder: RoutineOrder
  className?: string
}) {
  const [showFullInstructions, setShowFullInstructions] = useState(
    !(
      isMorningOrBedtime(routineOrder) &&
      hasDetailedAssessmentDescription(routineOrder)
    )
  )

  return (
    <>
      <Instructions
        routineOrder={routineOrder}
        abbreviated={!showFullInstructions}
        className={className}
      />
      {routineOrder.routineType.assessment && !showFullInstructions && (
        <div>
          <ButtonLink
            onClick={(e) => {
              e.stopPropagation()
              setShowFullInstructions(true)
            }}
            className="text-left leading-[20px]"
          >
            Show full instructions...
          </ButtonLink>
        </div>
      )}
    </>
  )
}
