import { useContext } from 'react'
import {
  fetchAmbulatoryStatus,
  fetchEmergencyInformation,
  fetchResidentDetails,
  mergePatchAmbulatoryStatus,
  mergePatchEmergencyInformation,
  mergePatchResidentDetails,
} from '@shared/api/person'
import { TaskType } from '@shared/types/task'
import { isCaliforniaFacility } from '@shared/utils/facilities'
import { TASK_REQUIRED_FIELDS_HASH } from '@app/components/Prospects/tasksConfig'
import FacilitiesContext from '@app/contexts/FacilitiesContext'
import { contactLoader, contactUpdater } from '../contactHelpers'
import FormLayout from '../FormLayout'
import { FormPageProps, PageGeneratorItem } from '../FormLayout/type'
import ambulatoryStatusConf from '../sharable/json/ambulatoryStatus.json'
import {
  getContactsJson,
  getEmergencyInfoJson,
  getResidentDetailsJson,
} from './config'

const requiredFieldsByPageIndex =
  TASK_REQUIRED_FIELDS_HASH[TaskType.TASK_TYPE_CA_FORM_601]

export function getFormList(isCaliforniaFacility: boolean) {
  const formList: PageGeneratorItem[] = [
    {
      menuTitle: '1. RESIDENT DETAILS',
      pageTitle: 'RESIDENT DETAILS',
      json: getResidentDetailsJson(),
      loader: fetchResidentDetails,
      updater: mergePatchResidentDetails,
      requiredFields: requiredFieldsByPageIndex[0],
    },
    {
      menuTitle: '2. CONTACTS',
      pageTitle: 'CONTACTS',
      json: getContactsJson(),
      loader: contactLoader,
      updater: contactUpdater,
      requiredFields: requiredFieldsByPageIndex[1],
    },
    {
      menuTitle: '3. EMERGENCY INFORMATION',
      json: getEmergencyInfoJson(),
      loader: fetchEmergencyInformation,
      updater: mergePatchEmergencyInformation,
    },
  ]

  if (isCaliforniaFacility) {
    // Only provide Ambulatory page for California Facility
    formList.push(
      {
        menuTitle: '4. AMBULATORY STATUS',
        pageTitle: 'AMBULATORY STATUS',
        json: ambulatoryStatusConf,
        loader: fetchAmbulatoryStatus,
        updater: mergePatchAmbulatoryStatus,
      },
      {
        menuTitle: '5. REVIEW',
        pageTitle: 'REVIEW',
        content: 'Review',
      }
    )
  } else {
    formList.push({
      menuTitle: '4. REVIEW',
      pageTitle: 'REVIEW',
      content: 'Review',
    })
  }

  return formList
}

export default function Form601(props: FormPageProps) {
  const { orgId, facilityId } = props.match.params
  const { facilities } = useContext(FacilitiesContext)
  const facility = facilities?.find(
    (f) => f.id === facilityId && f.orgId === orgId
  )
  if (!facility) {
    return null
  }
  const isCA = isCaliforniaFacility(facility)
  const formList = getFormList(isCA)

  return <FormLayout {...props} configuration={formList} />
}
