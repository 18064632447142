import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { notesPathForPerson } from '@shared/legacy_routes'
import { Incident } from '@shared/types/incidents'
import { Person } from '@shared/types/person'
import { isDateWithinRange } from '@shared/utils/date'
import { frequencyText, remainingAlertTime } from '@shared/utils/incident'
import { residentHeaders } from '@app/components/Residents/List/helpers'
import PersonContext from '@app/contexts/PersonContext'
import { toCardIncident } from '@app/pages/Notes/IncidentCard/helpers'
import styles from '../list.module.css'

export default function AlertTableRow({
  incidentsWithAlerts,
  person,
}: {
  incidentsWithAlerts: Incident[]
  person: Person
}) {
  const { setPerson } = useContext(PersonContext)
  const history = useHistory()

  return (
    <tr
      className={styles.alertRow}
      onClick={() => {
        setPerson(person)
        history.push(notesPathForPerson(person as Required<Person>))
      }}
      data-cy={`alert-row-${person.id}`}
    >
      <td colSpan={residentHeaders.length}>
        {incidentsWithAlerts.map((incident) => {
          const { icon, title } = toCardIncident(incident)

          return (
            <div key={`alerts-${incident.id}`} className="flex">
              <div
                className="mb-[8px]"
                style={{
                  border: '2px solid var(--alert)',
                  borderRadius: '2px',
                  marginLeft: '40px',
                  width: '4px',
                }}
              />
              <div className="mb-[8px] flex flex-col p-[16px] text-[14px] leading-[20px]">
                <div className="mb-[8px] flex items-center font-semibold text-gray-04">
                  <i
                    data-testid="alert-icon"
                    className={`fa-solid fa-${icon} mr-[8px]`}
                  />
                  {title}
                  {isDateWithinRange(
                    new Date(incident.updatedBy?.modificationTime as string),
                    -1
                  ) && (
                    <div
                      data-testid={`new-alert-${incident.id}`}
                      className={styles.newIcon}
                    >
                      new
                    </div>
                  )}
                </div>
                <p className="m-0 font-medium text-gray-07">
                  On alert for another
                  <i className="fa fa-clock ml-[6px] mr-[6px]" />
                  {remainingAlertTime(incident)}
                </p>
                <p className="mb-0 mt-[8px] font-medium text-gray-07">
                  {incident.alert!.note} &ndash; {frequencyText(incident)}
                </p>
              </div>
            </div>
          )
        })}
      </td>
    </tr>
  )
}
