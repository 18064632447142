import { useContext, useEffect, useState } from 'react'
import { fetchPerson } from '@shared/api/person'
import { Person, PersonIds } from '@shared/types/person'
import { Loading } from '@shared/utils/loading'
import PersonContext from '../contexts/PersonContext'

export default function usePerson({
  initialData,
  fields,
  skipFirstFetch = false,
  dependencies = [],
  showWhenLoading = false,
}: {
  initialData: PersonIds
  fields?: string[]
  skipFirstFetch?: boolean
  showWhenLoading?: boolean
  dependencies?: any[]
}) {
  const [person, setPerson] = useState<Loading<Person>>({ tag: 'Loading' })
  const [shouldSkipFetch, setShouldSkipFetch] =
    useState<boolean>(skipFirstFetch)

  const refreshPerson = async () => {
    if (initialData.orgId && initialData.facilityId && initialData.id) {
      if (showWhenLoading) {
        setPerson({ tag: 'Loading' })
      }

      return fetchPerson({
        orgId: initialData.orgId!,
        facilityId: initialData.facilityId!,
        personId: initialData.id!,
        fields: fields,
      }).then((newPerson) => {
        setPerson({ tag: 'Complete', value: newPerson })
        return newPerson
      })
    }

    return {}
  }

  useEffect(() => {
    if (shouldSkipFetch) {
      setShouldSkipFetch(false)
    } else {
      void refreshPerson()
    }
  }, dependencies)

  return { person, refreshPerson }
}

/**
 * A hook that provides access to the person from PersonContext, as well as a
 * function to fetch & set Person from the backend. The `skipFirstFetch` parameter
 * is useful if you already have a Person & only want access to the refresh function.
 *
 * @param initialData
 * @param skipFirstFetch
 */
export function usePersonInContext({
  initialData,
  skipFirstFetch = false,
}: {
  initialData: PersonIds
  skipFirstFetch?: boolean
}) {
  const { person, setPerson } = useContext(PersonContext)
  const { person: _person, refreshPerson } = usePerson({
    initialData,
    skipFirstFetch,
  })

  useEffect(() => {
    if (_person.tag === 'Complete') {
      setPerson(_person.value)
    }
  }, [_person.tag])

  return {
    person,
    refreshPerson: () =>
      refreshPerson().then((newPerson: Person) => {
        setPerson(newPerson)
      }),
  }
}
