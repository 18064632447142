import { AllergyIntolerance_AllergyIntoleranceCategory } from '@augusthealth/models/com/august/protos/allergy_intolerance'
import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { EditableDetailsCard } from 'app/components/Prospects/Details/DetailsCard'
import { useState } from 'react'
import { Person } from '@shared/types/person'
import { isEmptyObject } from '@shared/utils/common'
import { allergyList } from '@shared/utils/person'
import { tw } from '@shared/utils/tailwind'
import styles from '@app/components/AugustFields/Diet/styles.module.css'
import EditAllergiesModel from './EditAllergiesModel'

export default function Allergies({
  person,
  updatePerson,
}: {
  person: Person
  updatePerson: () => Promise<void>
}) {
  const [showModal, setShowModal] = useState(false)
  const medicalAllergies = allergyList(person).filter(
    (allergy) =>
      allergy.category ===
      AllergyIntolerance_AllergyIntoleranceCategory.ALLERGY_INTOLERANCE_CATEGORY_MEDICATION
  )

  return (
    <EditableDetailsCard
      id="allergies-card"
      icon="fas fa-fw fa-hand-dots"
      title="Allergies"
      editPermissions={{
        person,
        permissions: [GroupPermission.GROUP_PERMISSION_PERSON_UPDATE],
      }}
      onEditClick={() => setShowModal(true)}
      editButtonId={'edit-allergies'}
      className={tw`my-[12px]`}
    >
      <div className={styles.row}>
        <div className={styles.title}>Medical Allergies</div>
        <div>
          {!isEmptyObject(medicalAllergies) &&
            medicalAllergies.map((a) => a.summary).join(', ')}
          {isEmptyObject(medicalAllergies) && (
            <span className="text-gray-08">None to show</span>
          )}
        </div>
      </div>
      {showModal && (
        <EditAllergiesModel
          person={person}
          onClose={async (updated) => {
            if (updated) {
              await updatePerson()
            }
            setShowModal(false)
          }}
        />
      )}
    </EditableDetailsCard>
  )
}
