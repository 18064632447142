import environment from '@shared/environment'

export default function VersionInfo() {
  return (
    <div
      className={
        'ml-[8px] mt-[8px] flex items-center p-[12px] text-[14px] text-gray-06'
      }
    >
      <span className={'font-semibold'}>Version:</span>
      <span className={'ml-[4px]'}>{environment.clientVersion}</span>
      &#8194;·&#8194;
      <span className={'font-semibold'}>SHA:</span>
      <span className={'ml-[4px]'}>{environment.clientSha}</span>
    </div>
  )
}
