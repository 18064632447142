import classNames from 'classnames'
import { useContext, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Controller, useForm } from 'react-hook-form'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import {
  BasicInput,
  BasicTextarea,
} from '@shared/components/BasicInput/BasicInput'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import Card from '@shared/components/Card'
import SearchBox from '@shared/components/SearchBox'
import VitalsInputProducer from '@shared/components/Vitals/VitalsInputProducer'
import GlobalContext from '@shared/contexts/GlobalContext'
import { PersonStats } from '@shared/types/person_stats'
import { Order } from '@shared/utils/common'
import { getFirstAndLastName } from '@shared/utils/humanName'
import {
  getProfileSvgPath,
  getRoomNumber,
  personNameMatchesSearch,
  personRoomMatchesSearch,
} from '@shared/utils/person'
import { sortPeopleByRoomNumber } from '@shared/utils/personStats'
import { twx } from '@shared/utils/tailwind'
import {
  getDefaultVitalValues,
  QueuedVital,
  VitalsFormData,
  VitalsType,
} from '@shared/utils/vitals'
import useLatestVitals from '@app/hooks/useLatestVitals'
import styles from './quickVitals.module.css'
import { createOrUpdateVitals, modifyVitalFor } from './quickVitalsHelpers'

export default function QuickVitals({
  peopleStats,
  setShowModal,
}: {
  peopleStats: PersonStats[]
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { setError } = useContext(GlobalContext)

  const [nameFilter, setNameFilter] = useState('')
  const [visiblePersonId, setVisiblePersonId] = useState<string>()
  const [currentVitals, setCurrentVitals] = useState<QueuedVital[]>(
    peopleStats.map(
      (ps) =>
        ({
          personId: ps.person!.id,
          queue: Promise.resolve({}),
          status: 'untouched',
        }) as QueuedVital
    )
  )

  const methods = useForm<VitalsFormData>({
    mode: 'all',
  })
  const { control, formState, handleSubmit, register, reset } = methods

  const { latestVitals } = useLatestVitals(
    peopleStats.find((ps) => ps.person?.id === visiblePersonId)?.person
  )
  useEffect(() => {
    if (visiblePersonId) {
      reset(
        getDefaultVitalValues(
          currentVitals.find((v) => v.personId === visiblePersonId)
        )
      )
    }
  }, [visiblePersonId])

  const filteredPeopleStats = peopleStats.filter(
    (personStats) =>
      personNameMatchesSearch(personStats.person, nameFilter) ||
      personRoomMatchesSearch(personStats.person, nameFilter)
  )
  const sortedPeopleStats = sortPeopleByRoomNumber(
    filteredPeopleStats,
    Order.ASC
  )
  const togglePersonVitals = (id) => {
    if (visiblePersonId === id) {
      setVisiblePersonId(undefined)
    } else {
      setVisiblePersonId(id)
    }
  }

  const onSubmit = async (data: VitalsFormData) => {
    try {
      setCurrentVitals(
        modifyVitalFor(visiblePersonId!, (v) => ({ ...v, status: 'saving' }))
      )

      const { id } = await createOrUpdateVitals({
        data,
        sortedPeopleStats,
        visiblePersonId,
        currentVitals,
        setCurrentVitals,
      })
      setCurrentVitals(
        modifyVitalFor(visiblePersonId!, (v) => ({
          ...v,
          status: id === undefined ? 'untouched' : 'saved',
        }))
      )
    } catch (e) {
      setError(e)
    }
  }

  return (
    <AnimatedPopup
      containerClassName={twx(
        'text-secondary-02 max-h-[90vh] max-w-[1340px] w-full p-0 overflow-y-hidden flex flex-col'
      )}
      title={null}
    >
      <div className={styles.header} data-testid="quick-vitals-modal">
        <h2 className="text-[24px] leading-[36px]">Quick Vitals</h2>
        <div>
          <SearchBox
            name="quick-vitals-search"
            onChange={setNameFilter}
            value={nameFilter}
            placeholder="Name or room number..."
          />
        </div>
      </div>
      <section className={styles.scrollSection}>
        {sortedPeopleStats.length === 0 && (
          <Card className={styles.emptyCard}>
            {nameFilter.length > 0
              ? `No residents found matching "${nameFilter}"`
              : `No residents found.`}
          </Card>
        )}
        {sortedPeopleStats.map((personStats) => {
          const person = personStats.person!
          const recordedVitals = currentVitals.find(
            (rv) => rv.personId === person.id
          )
          const profileUrl = personStats.profilePhotoUrl
            ? personStats.profilePhotoUrl
            : getProfileSvgPath(person)
          const roomNumber = getRoomNumber(person)
          const vitalsContainerClasses = classNames({
            ['visually-hidden']: visiblePersonId !== person.id,
            [`animate-fade-in ${styles.vitalsContainer}`]:
              visiblePersonId === person.id,
          })
          const iconClasses = classNames({
            [`fa-light fa-circle-xmark fa-2x ${styles.plusIcon}`]:
              recordedVitals?.status === 'untouched' &&
              visiblePersonId !== person.id,
            [`fa-solid fa-circle-check fa-2x ${styles.checkIcon}`]:
              recordedVitals?.status === 'saved' &&
              visiblePersonId !== person.id,
            [`fa-light fa-solid fa-spinner fa-spin fa-2x ${styles.plusIcon}`]:
              recordedVitals?.status === 'saving' &&
              visiblePersonId !== person.id,
            [`fa-light fa-circle-xmark fa-2x ${styles.xIcon}`]:
              visiblePersonId === person.id,
          })
          return (
            <Card
              className={`${styles.card} p-[16px]`}
              key={`quick-vitals-${person.id}`}
            >
              <div
                className={styles.vitalsRow}
                onClick={() => togglePersonVitals(person.id)}
                data-testid={`quick-vitals-${person.id}`}
              >
                <div className="flex grow items-center">
                  <div className="flex min-w-[25%] items-center gap-1 pr-2">
                    <img
                      className="mr-[16px] h-[40px] w-[40px]"
                      src={profileUrl}
                      alt="profile picture"
                    />
                    <span className="text-[14px] font-semibold leading-[16px]">
                      {getFirstAndLastName(personStats.person?.name)}
                    </span>
                  </div>
                  {roomNumber && (
                    <span className="text-[14px] leading-[16px] text-gray-07">
                      <i className="fa-solid fa-bed mr-[8px]" />
                      Room {roomNumber}
                    </span>
                  )}
                </div>
                <i
                  data-testid={`quick-vitals-status-${person.id}`}
                  className={iconClasses}
                />
              </div>
              <form
                data-testid={`quick-vitals-${person.id}-form`}
                className={vitalsContainerClasses}
              >
                {visiblePersonId === person.id && (
                  <>
                    <div>
                      <div className={styles.dateTime}>
                        <Controller
                          control={control}
                          name="date"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              id="date"
                              selected={value}
                              onChange={(v: Date | null, context) =>
                                onChange(v, context)
                              }
                              customInput={<CalendarInput width="100%" />}
                              maxDate={new Date()}
                            />
                          )}
                        />
                        <div className="ml-[16px]">
                          <BasicInput
                            type="time"
                            id="time"
                            {...register('time')}
                          />
                        </div>
                      </div>
                      <div className="mt-[16px]">
                        <BasicTextarea
                          data-testid={`quick-vitals-note-${person.id}`}
                          style={{ height: '144px' }}
                          placeholder="Optional note..."
                          {...register('incidentNote')}
                          onBlur={handleSubmit(onSubmit)}
                        />
                      </div>
                    </div>
                    <div>
                      {Object.keys(VitalsType).map((type) => (
                        <VitalsInputProducer
                          key={type}
                          mode={{ tag: 'onBlur', handleSubmit, onSubmit }}
                          methods={methods}
                          latestVitals={latestVitals}
                          vitalsType={VitalsType[type]}
                        />
                      ))}
                    </div>
                  </>
                )}
              </form>
            </Card>
          )
        })}
      </section>
      <div className={styles.footer}>
        <AsyncIconButton
          buttonStyle="primary-fill"
          width={'136px'}
          isLoading={formState.isSubmitting}
          disabled={formState.isSubmitting}
          type="button"
          onClick={() => {
            setShowModal(false)
          }}
        >
          Done
        </AsyncIconButton>
      </div>
    </AnimatedPopup>
  )
}
