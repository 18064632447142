import { sortBy } from 'lodash'
import { ChangeEvent, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { uploadCustomFacilityImage } from '@shared/api/facility'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import ButtonLink from '@shared/components/ButtonLink'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Facility } from '@shared/types/facility'
import { getById, getOrElse } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import PersonPageTitle from '@app/components/PersonPageTitle'
import { FieldValuesCells } from '@app/pages/Tools/FacilityFieldManagement/FieldValueCells'
import { ManageImageModal } from '@app/pages/Tools/FacilityFieldManagement/ManageImageModal'
import useFacilityFieldsData from '@app/pages/Tools/FacilityFieldManagement/useFacilityFieldsData'
import { BaseOrgSelect } from '@app/pages/Tools/SharedSelects/base/BaseOrgSelect'
import {
  downloadFacilityFields,
  Extension,
  KeyType,
  saveFields,
  splitKey,
} from './helpers'

export default function FacilityFieldManagement() {
  const [manageImageModalData, setManageImageModalData] = useState<{
    key: string
    facility: Facility
  }>()
  const [isUploading, setIsUploading] = useState<KeyType>()
  const [disableForm, setDisableForm] = useState(false)
  const [downloading, setDownloading] = useState<Extension | undefined>()
  const { setError } = useContext(GlobalContext)
  const {
    changedValues,
    facilities,
    facilityFields,
    fieldValues,
    noChangesDetected,
    organization,
    refreshFacilities,
    setFieldValues,
    setOrganization,
    template,
  } = useFacilityFieldsData()

  const onImageUpload = async (
    key: KeyType,
    facility: Facility,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { fieldKey } = splitKey(key)
    const file = event.currentTarget.files?.[0]

    if (file) {
      setIsUploading(key)
      setDisableForm(true)
      await uploadCustomFacilityImage({
        facility,
        customFieldKey: fieldKey,
        file: file,
      })
      await refreshFacilities()
      setDisableForm(false)
      setIsUploading(undefined)
    }
  }

  const onManageImage = async (key: KeyType) => {
    const { facilityId, fieldKey } = splitKey(key)
    const facility = getById(facilities, facilityId)
    if (facility) {
      setManageImageModalData({ key: fieldKey, facility })
    }
  }

  if (template.tag === 'Loading' || template.tag === 'Error') {
    return <></>
  }

  const showTable =
    facilities.tag === 'Complete' &&
    facilities.value.length > 0 &&
    facilityFields.length > 0

  const sortedFacilities = sortBy(getOrElse(facilities, []), 'name')
  const history = useHistory()
  const orgId = organization?.value.id

  return (
    <>
      <section className={'m-[32px]'}>
        <PersonPageTitle
          title={`Facility Fields for ${template.value.name} (${template.value.id})`}
        >
          <ButtonLink onClick={history.goBack}>Back</ButtonLink>
        </PersonPageTitle>
        <BaseOrgSelect
          onOrgSelect={setOrganization}
          selectedOrganization={organization}
        />
      </section>
      {facilityFields.length === 0 && (
        <div className={'mx-[32px]'}>This template has no facility fields.</div>
      )}
      {showTable && (
        <>
          <section
            className={
              'mx-[32px] max-h-[calc(100vh-141px)] w-[calc(100vw-312px)] overflow-scroll'
            }
          >
            <table
              className={tw`table-fixed border-separate border-spacing-0 border border-black`}
            >
              <TableHeader facilities={sortedFacilities} />
              <tbody>
                {facilityFields.map((field) => (
                  <tr key={`ff-${field.key}`}>
                    <th
                      className={tw`relative sticky left-0 border border-black bg-white p-[8px] font-mono text-[12px]`}
                    >
                      {field.key}
                    </th>
                    <FieldValuesCells
                      isUploading={isUploading}
                      onImageUpload={onImageUpload}
                      onManageImage={onManageImage}
                      facilities={sortedFacilities}
                      field={field}
                      changedValues={changedValues}
                      fieldValues={fieldValues}
                      setFieldValues={setFieldValues}
                      disableForm={disableForm}
                      setDisableForm={setDisableForm}
                    />
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
          <div className={tw`mx-[32px] mt-[16px] flex justify-between`}>
            <div>
              <AsyncIconButton
                buttonStyle={'primary-fill'}
                onClick={async () => {
                  try {
                    setDisableForm(true)
                    await saveFields({
                      fieldValues,
                      facilities: facilities.value,
                    })
                    await refreshFacilities()
                  } catch (e) {
                    setError(e)
                  } finally {
                    setDisableForm(false)
                  }
                }}
                disabled={noChangesDetected || disableForm}
              >
                Save
              </AsyncIconButton>
            </div>
            {orgId && (
              <div>
                <AsyncIconButton
                  buttonStyle="secondary-outline"
                  onClick={() =>
                    downloadFacilityFields({
                      orgId,
                      extension: '.csv',
                      setDownloading,
                    })
                  }
                  isLoading={downloading === '.csv'}
                  disabled={Boolean(downloading)}
                >
                  Download .csv
                </AsyncIconButton>
                <AsyncIconButton
                  buttonStyle="secondary-outline"
                  className={tw`ml-[8px]`}
                  onClick={() =>
                    downloadFacilityFields({
                      orgId,
                      extension: '.xlsx',
                      setDownloading,
                    })
                  }
                  isLoading={downloading === '.xlsx'}
                  disabled={Boolean(downloading)}
                >
                  Download .xlsx
                </AsyncIconButton>
              </div>
            )}
          </div>
        </>
      )}
      {manageImageModalData && (
        <ManageImageModal
          facility={manageImageModalData.facility}
          fieldKey={manageImageModalData.key}
          onClose={async (updated) => {
            if (updated) {
              await refreshFacilities()
            }

            setManageImageModalData(undefined)
          }}
        />
      )}
    </>
  )
}

/**
 * Table columns – each column is a facility
 */
function TableHeader({ facilities }: { facilities: Facility[] }) {
  return (
    <thead>
      <tr>
        <th
          className={tw`sticky left-0 top-0 border border-black bg-primary-light`}
        ></th>
        {facilities.map((f) => {
          return (
            <th
              className={tw`sticky top-0 border border-black bg-white p-[8px] font-mono text-[12px] text-august-primary`}
              key={f.id}
            >
              {f.name}
            </th>
          )
        })}
      </tr>
    </thead>
  )
}
