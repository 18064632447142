import {
  OrgFacilitySettings,
  Settings,
  SettingsType,
} from '@augusthealth/models/com/august/protos/org_facility_settings'
import { PdfViewSettings_DocumentType } from '@augusthealth/models/com/august/protos/settings/pdf_view_settings'
import { useForm } from 'react-hook-form'
import {
  getFacilityServicePlanPdfViewSettings,
  upsertSettings,
} from '@shared/api/orgFacilitySettings'
import { BasicCheckbox } from '@shared/components/BasicInput/BasicInput'
import { tw } from '@shared/utils/tailwind'
import OrgFacilityLoaderSaver, {
  OnSubmit,
} from '../../subComponents/OrgFacilityLoaderSaver'

export default function ServicePlanPdfViewSettings() {
  const useFormReturn = useForm<Settings>()
  const { register } = useFormReturn

  return (
    <OrgFacilityLoaderSaver
      pageTitle="Service Plan PDF View Settings"
      loaderTitle="I want to view Service Plan PDF view settings for..."
      saverTitle="Save Service Plan PDF View Settings at..."
      childrenTitle="Service Plan PDF View Settings"
      saveBtnLabel="Save Service Plan PDF View Settings"
      hudText="Service Plan PDF View Settings Updated"
      useFormReturn={useFormReturn}
      path={'pdfViewSettings'}
      loaderFunc={getFacilityServicePlanPdfViewSettings}
      loaderFuncMap={(res: OrgFacilitySettings) =>
        res.settings?.pdfViewSettings
      }
      onSubmit={({ ids, data }: OnSubmit) => {
        return upsertSettings({
          ...ids,
          settings: data,
          settingsType: SettingsType.SETTINGS_TYPE_PDF_VIEW_SETTINGS,
          key: PdfViewSettings_DocumentType.DOCUMENT_TYPE_SERVICE_PLAN,
        })
      }}
    >
      <div className={tw`mb-[16px] flex flex-col`}>
        <BasicCheckbox
          {...register(
            'pdfViewSettings.servicePlanView.hideScoreFromServicePlan'
          )}
        >
          Hide Score
        </BasicCheckbox>
        <BasicCheckbox
          {...register(
            'pdfViewSettings.servicePlanView.usesCheckMarkForAssessedCategories'
          )}
        >
          Uses checkmark for assessed categories
        </BasicCheckbox>
      </div>
    </OrgFacilityLoaderSaver>
  )
}
