import { Bed } from '@augusthealth/models/com/august/protos/bed'
import { Room } from '@augusthealth/models/com/august/protos/room'
import { useContext, useState } from 'react'
import { createFilter } from 'react-select'
import { typedMergePatchPerson } from '@shared/api/person'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import StyledSelect from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person, ResidentStatus } from '@shared/types/person'
import { PickPartial } from '@shared/types/utilities'
import { getFirstAndLastName, getLastName } from '@shared/utils/humanName'
import {
  displayResidentStatus,
  getGenderLetter,
  getRoomNumber,
  sortPeopleByStatus,
} from '@shared/utils/person'
import { alphaNumericSort } from '@shared/utils/sorting'

function sortPeopleByRoomNumberAtEnd(
  p1: PickPartial<Person, 'admissionsInformation'>,
  p2: PickPartial<Person, 'admissionsInformation'>
) {
  const roomNumber1 = getRoomNumber(p1)
  const roomNumber2 = getRoomNumber(p2)

  // People without Room Number at the top
  if (!roomNumber1 && !roomNumber2) {
    return 0
  } else if (!roomNumber1) {
    return -1
  } else if (!roomNumber2) {
    return 1
  }

  // People with Room Number at the end ASC order
  return alphaNumericSort(roomNumber1, roomNumber2)
}

function sortPeopleByLastName(
  p1: Pick<Person, 'name'>,
  p2: Pick<Person, 'name'>
) {
  const lastName1 = getLastName(p1.name)
  const lastName2 = getLastName(p2.name)

  return alphaNumericSort(lastName1, lastName2)
}

type Props = {
  bed: Bed
  people: Person[]
  room: Room
  onComplete: (didUpdate: boolean) => void
}

export default function AssignRoomPopup(props: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { setError } = useContext(GlobalContext)
  const { bed, people, room, onComplete } = props
  const { id: bedId } = bed
  const { id: roomId, orgId, facilityId } = room
  const options = people
    .filter(
      (p) =>
        p.residentStatus === ResidentStatus.RESIDENT_STATUS_CURRENT_RESIDENT ||
        p.residentStatus === ResidentStatus.RESIDENT_STATUS_PROSPECT
    )
    .sort(sortPeopleByLastName)
    .sort(sortPeopleByStatus)
    .sort(sortPeopleByRoomNumberAtEnd)
    .map((p) => {
      const status = displayResidentStatus(p)
      const fullName = getFirstAndLastName(p.name)
      const genderLabel = p.gender && getGenderLetter(p)
      const roomNumber = getRoomNumber(p)
      const roomBedLabel = roomNumber && `Room ${roomNumber}`

      return {
        label: (
          <div>
            <span className="mr-[8px]">{fullName}</span>
            <span className="text-[14px] text-gray-07">
              {[genderLabel, status, roomBedLabel]
                .filter((str) => str)
                .join(' - ')}
            </span>
          </div>
        ),
        value: p.id,
        searchText: fullName,
      }
    })

  const onChange = (pId: string) => {
    if (orgId && facilityId && pId && roomId && bedId) {
      setIsSubmitting(true)
      const patch = {
        admissionsInformation: {
          bedId: bedId,
        },
      }

      typedMergePatchPerson({
        fId: facilityId,
        orgId,
        pId,
        patch,
        fields: ['admissionsInformation/bedId'],
      })
        .then(() => onComplete(true))
        .catch(setError)
        .finally(() => setIsSubmitting(false))
    }
  }

  return (
    <AnimatedPopup
      title="Assign Room"
      onClickBackground={(ev) => {
        ev.stopPropagation()
        onComplete(false)
      }}
    >
      <StyledSelect
        id="assignRoomSelect"
        instanceId="assignRoomSelect"
        isDisabled={isSubmitting}
        options={options}
        onChange={(opt: { value: string }) => {
          const personId = opt?.value
          if (personId) {
            onChange(personId)
          }
        }}
        placeholder="Enter resident first or last name......"
        filterOption={createFilter<{ searchText: string }>({
          stringify: (opt) => opt.data.searchText,
        })}
      />
    </AnimatedPopup>
  )
}
