import { EncryptedString } from '@augusthealth/models/com/august/protos/encryption'
import {
  KeyboardEvent,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  BasicInput,
  BasicInputProps,
} from '@shared/components/BasicInput/BasicInput'
import { DeepNull } from '@shared/types/utilities'
import { tw } from '@shared/utils/tailwind'
import ElementHolder, { Props as ElementHolderProps } from '../ElementHolder'

type Props = Omit<ElementHolderProps, 'children' | 'value'> & {
  data?: object
  onUpdate: (value: DeepNull<EncryptedString> | null, name?: string) => void
  visibility?: boolean
  onChange?: (value?: string, name?: string) => void
  value?: DeepNull<EncryptedString>
}

export const SSN_MASK = '*********'

function getRefValue(ref: RefObject<HTMLInputElement>) {
  return ref?.current?.value || ''
}

function focusRef(ref: RefObject<HTMLInputElement>) {
  return ref?.current?.focus()
}

export default function SsnInGenerator(props: Props) {
  const {
    data,
    onUpdate,
    visibility = true,
    onChange,
    alertMessage: defaultAlertMessage,
    value,
    ...restProps
  } = props
  const { name } = restProps
  const [inputValue, setInputValue] = useState<string>('') // value after on change
  const [updatedValue, setUpdatedValue] = useState<string>('') // value after press ENTER or onBlur
  const [alertMessage, setAlertMessage] =
    useState<ReactNode>(defaultAlertMessage)
  const firstThreeInput = useRef<HTMLInputElement>(null)
  const middleTwoInput = useRef<HTMLInputElement>(null)
  const lastThreeInput = useRef<HTMLInputElement>(null)
  let onBlurTimer: NodeJS.Timeout | undefined

  useEffect(() => {
    if (value) {
      setInputValue(SSN_MASK)
      setUpdatedValue(SSN_MASK)
    }
  }, [value])

  useEffect(() => setAlertMessage(alertMessage), [alertMessage])

  if (visibility === false) {
    return null
  }

  function update() {
    if (
      inputValue === SSN_MASK ||
      (inputValue !== SSN_MASK && inputValue === updatedValue)
    ) {
      // If is SSN_MASK or nothing changes
      return // Do Nothing
    }
    if (isNaN(Number(inputValue)) || inputValue.length !== 9) {
      setAlertMessage('* Invalid format')
    } else {
      setUpdatedValue(inputValue)
      onUpdate?.(
        inputValue
          ? {
              unencryptedPlainText: inputValue,
              encryptedValue: null,
            }
          : null,
        name
      )
      setAlertMessage(undefined)
    }
  }

  function change(val: string) {
    setInputValue(val)
    onChange?.(val, name)
    if (!val || val.length === 9) {
      setAlertMessage(undefined)
    }
  }

  function getCombinedValue() {
    const combinedValue = `${getRefValue(firstThreeInput)}${getRefValue(middleTwoInput)}${getRefValue(lastThreeInput)}`
    return combinedValue.substring(0, 9)
  }

  function handleBackspacePress(
    ev: KeyboardEvent<HTMLInputElement>,
    previousRef: RefObject<HTMLInputElement>
  ) {
    const input = ev.target as HTMLInputElement
    if (ev.key === 'Backspace' && input.value === '') {
      setInputValue((prev) => prev.substring(0, prev.length))
      focusRef(previousRef)
    }
  }

  const inputProps: BasicInputProps = {
    ...restProps,
    type: isNaN(Number(updatedValue)) ? 'text' : 'number',
    onFocus: () => {
      clearTimeout(onBlurTimer)
      if (inputValue === SSN_MASK) {
        setInputValue('')
        setUpdatedValue('')
        focusRef(firstThreeInput)
      }
    },
    onBlur: () => {
      // Do not execute update when focus on SSN fields right after onBlur
      onBlurTimer = setTimeout(update, 250)
    },
    onKeyDown: (ev: KeyboardEvent<HTMLInputElement>) => {
      // Filter-out characters to convert Number to Integer
      if (['+', '-', 'e', 'E', '.'].includes(ev.key)) {
        ev.preventDefault()
      }

      if (ev.key === 'Enter') {
        update()
      }
    },
    autoComplete: 'off',
    autoCorrect: 'off',
  }

  return (
    <ElementHolder
      {...restProps}
      value={updatedValue}
      alertMessage={alertMessage}
    >
      <div className={tw`flex`}>
        <BasicInput
          ref={firstThreeInput}
          className={tw`w-[4em]`}
          {...inputProps}
          value={inputValue.substring(0, 3) || ''}
          onChange={() => {
            const newValue = getCombinedValue()
            change(newValue)
            if (newValue.length >= 3) {
              focusRef(middleTwoInput)
            }
          }}
        />
        <BasicInput
          className={tw`mx-[16px] w-[3em]`}
          ref={middleTwoInput}
          {...inputProps}
          value={inputValue.substring(3, 5) || ''}
          onChange={() => {
            const newValue = getCombinedValue()
            change(newValue)
            if (newValue.length < 3) {
              focusRef(firstThreeInput)
            } else if (newValue.length >= 5) {
              focusRef(lastThreeInput)
            }
          }}
          onKeyUp={(ev: KeyboardEvent<HTMLInputElement>) => {
            handleBackspacePress(ev, firstThreeInput)
          }}
        />
        <BasicInput
          className={tw`w-[5em]`}
          ref={lastThreeInput}
          {...inputProps}
          value={inputValue.substring(5, 9) || ''}
          onChange={() => {
            const newValue = getCombinedValue()
            change(newValue)
            if (newValue.length < 3) {
              focusRef(firstThreeInput)
            } else if (newValue.length < 5) {
              focusRef(middleTwoInput)
            }
          }}
          onKeyUp={(ev: KeyboardEvent<HTMLInputElement>) => {
            handleBackspacePress(ev, middleTwoInput)
          }}
        />
      </div>
    </ElementHolder>
  )
}
