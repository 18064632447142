import { VitalType } from '@augusthealth/models/com/august/protos/vital'
import { Controller, useFormContext } from 'react-hook-form'
import { BadgeSelect } from '@shared/components/Selects/BadgeSelect'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import { ValidParameterConditional } from '@shared/types/dosage'
import {
  MedicationOrder,
  MedOrderFormData,
  MedOrderFormDoseVital,
} from '@shared/types/medication_order'
import { DosageType } from '@shared/types/medication_statement'
import {
  DIY_SCHEDULE_TYPE_OPTIONS,
  VITAL_SCHEDULE_TYPE_OPTIONS,
} from '@shared/utils/medicationStatement'
import { tw } from '@shared/utils/tailwind'
import {
  VitalEnumToVitalsType,
  VITALS_INPUT_PROPS_MAP,
} from '@shared/utils/vitals'
import { VitalsDropdown } from '@app/components/Residents/Medications/MedicationSearch/VitalsDropdown'
import { handleChangingDoseType } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'
import { DynamicFrequencyInputs } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/DynamicFrequencyInputs/DynamicFrequencyInputs'
import { ScheduleFooter } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/ScheduleFooter/ScheduleFooter'
import { ScheduleHeader } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/ScheduleHeader'
import { SchedulePartWrapper } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/SchedulePartWrapper'

type Props = {
  order: Partial<MedicationOrder>
  index: number
  readOnly?: boolean
  mode: 'edit' | 'create' | 'review'
}
export const VitalOrderSchedule = ({
  order,
  index,
  readOnly = false,
  mode,
}: Props) => {
  const methods = useFormContext<MedOrderFormData>()
  const { watch, control } = methods

  const selectedType = watch(`doses.${index}.userSetDosageType`)
  const vitalType = watch('vitalType')
  const vitalProps =
    vitalType &&
    VitalEnumToVitalsType(vitalType as VitalType) &&
    VITALS_INPUT_PROPS_MAP<MedOrderFormDoseVital>(
      VitalEnumToVitalsType(vitalType as VitalType)!
    )

  const dropdownName = vitalProps?.dropdownName
    ? (`doses.${index}.vitals.${vitalProps.dropdownName}` as const)
    : null

  const vitalHasDropdown =
    !!vitalProps &&
    !!dropdownName &&
    !!vitalProps.options &&
    !!vitalProps.dropdownLabel

  const disableVitalDropdown = !!order.orderGroupId && mode !== 'review'
  const scheduleOptions =
    mode === 'review' ? DIY_SCHEDULE_TYPE_OPTIONS : VITAL_SCHEDULE_TYPE_OPTIONS

  const updateDoseType = (newDoseType: DosageType, index: number) => {
    return handleChangingDoseType({
      newDoseType,
      index,
      methods,
      isNewOrder: mode === 'create',
    })
  }

  return (
    <>
      <SchedulePartWrapper data-testid={'vital-order-creation'}>
        <ScheduleHeader
          readOnly={readOnly}
          options={scheduleOptions}
          order={order}
          forceSelection={selectedType}
          onChangeDoseType={updateDoseType}
          index={index}
        />
        <div
          className={tw`mb-3 flex flex-col gap-1 border-b border-transparent-gray pb-3`}
        >
          <VitalsDropdown
            control={control}
            disabled={readOnly || disableVitalDropdown}
          />
          {vitalHasDropdown && (
            <Controller
              name={dropdownName}
              shouldUnregister={true}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <BadgeSelect
                    isClearable
                    name={dropdownName}
                    badgeLabel={vitalProps.dropdownLabel as string}
                    options={vitalProps.options}
                    placeholder={'Select...'}
                    containerClassName={tw`mt-4`}
                    className={tw`max-w-[280px]`}
                    onChange={(
                      e: OptionTypeBase<ValidParameterConditional>
                    ) => {
                      onChange(e?.value ?? null)
                    }}
                    value={
                      vitalProps.options?.find((opt) => opt.value === value) ??
                      null
                    }
                    isDisabled={readOnly}
                  />
                )
              }}
            />
          )}
        </div>
        <DynamicFrequencyInputs index={index} readOnly={readOnly} />
      </SchedulePartWrapper>
      <ScheduleFooter
        readOnly={readOnly}
        mode={{
          tag: `full-${DosageType.DOSAGE_TYPE_ROUTINE}-Vital`,
          noteName: `doses.${index}.vitals.observationActionNote`,
        }}
      />
    </>
  )
}
