import { pickBy } from 'lodash'
import {
  PasswordPolicyRequirement,
  passwordPolicyRequirements,
} from '@shared/constants/passwordPolicy'
import { PasswordPolicy } from '@shared/types/settings/security_policy'

export function setRequirements({
  passwordPolicy,
  keysToExclude,
}: {
  passwordPolicy: PasswordPolicy
  keysToExclude: string[]
}): PasswordPolicyRequirement {
  const allRequirements = {
    ...passwordPolicyRequirements[passwordPolicy],
  }

  return pickBy(allRequirements, (_, key) => {
    return !keysToExclude.includes(key)
  }) as PasswordPolicyRequirement
}
