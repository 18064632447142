import { cloneDeep } from 'lodash'
import { useState } from 'react'
import { SignatureInfoWithSkip } from '@shared/api/task'
import HUD from '@app/components/HUD'
import { BaseMissingEmailOverlays } from '../BaseMissingEmailOverlays'
import { hudDelay } from './constants'
import { addMissingEmailToSignerInList } from './helpers'

/**
 * ModalOverlays loops through all external signers without email
 * A fake HUD loader is added as onContinue UI feedback to notice user that next external user has been loaded
 */
export function ExternalSignerMissingEmailOverlays({
  externalSignersWithoutEmail,
  onContinue,
  continueWithEmailButtonText,
  allSigners,
}: {
  externalSignersWithoutEmail: SignatureInfoWithSkip[]
  continueWithEmailButtonText: string
  onContinue: (updatedSigners: SignatureInfoWithSkip[]) => Promise<void>
  allSigners: SignatureInfoWithSkip[]
}) {
  const [loading, setLoading] = useState(false)
  const [sigerIndex, setSignerIndex] = useState(0)
  const currentSigner = externalSignersWithoutEmail[sigerIndex]
  const [updatedSigners, setUpdateSigners] = useState(cloneDeep(allSigners))
  const isLastExernalSigner =
    sigerIndex === externalSignersWithoutEmail.length - 1

  return (
    <>
      <BaseMissingEmailOverlays
        data-testid={'missingExternalSignerEmail'}
        contact={{ name: currentSigner.signer?.name }}
        onContinue={async (newEmail: string) => {
          if (isLastExernalSigner) {
            // Get updatedSigners with addMissingEmailToSignerInList from ReactState updatedSigners
            // Do NOT use updatedSigners directly due to the delay of ReactState
            await onContinue(
              addMissingEmailToSignerInList({
                allSigners: updatedSigners,
                currentSigner,
                newEmail,
              })
            )
          } else {
            if (newEmail) {
              // It is OK to update ReactState, before submit, the value will be saved correctly.
              setUpdateSigners((prev) =>
                addMissingEmailToSignerInList({
                  allSigners: prev,
                  currentSigner,
                  newEmail,
                })
              )
            }
            setLoading(true)
          }
        }}
        continueWithEmailButtonText={continueWithEmailButtonText}
      />
      {loading && (
        <HUD
          milliseconds={hudDelay}
          onExpire={() => {
            setLoading(false)
            setSignerIndex((prev) => prev + 1) // Change Signer after HUD for consistency (vs Name is changed but still see the HUD)
          }}
        >
          Loading...
        </HUD>
      )}
    </>
  )
}
