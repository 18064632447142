import { Person } from '@shared/types/person'
import styles from './styles.module.css'
import { RoutableDocument } from '../helpers'
import SignedDocViewer from '../Viewer/SignedDocViewer'
import DocumentCard from './DocumentCard'

export default function SignedDocumentCard({
  animationStyle,
  document,
  person,
  completionText,
  title,
  subtitle,
  onUpdate,
}: {
  animationStyle: React.CSSProperties
  document: RoutableDocument
  person: Person
  completionText: 'Completed' | 'Uploaded'
  title: string
  subtitle?: string
  onUpdate: () => Promise<void>
}) {
  const fullTitle = subtitle ? (
    <>
      {title} <span className={`${styles.subtitle} ml-[8px]`}>{subtitle}</span>
    </>
  ) : (
    title
  )

  return (
    <DocumentCard
      person={person}
      document={document}
      icon="fa-file-contract"
      title={fullTitle}
      completionText={completionText}
      viewer={(closeFn) => (
        <SignedDocViewer
          title={title}
          person={person}
          onComplete={async (updated) => {
            if (updated) {
              await onUpdate()
            }
            closeFn()
          }}
          document={document}
        />
      )}
      animationStyle={animationStyle}
    />
  )
}
