import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useEffect, useState } from 'react'
import { getRefillEligibilityForPerson } from '@shared/api/pharmacy_refill'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { Person } from '@shared/types/person'
import { RefillEligibility } from '@shared/types/pharmacy_refill'
import { loaded, LOADING, Loading } from '@shared/utils/loading'

type Props = { person: Person }

export const useRefillsByPerson = ({
  person,
}: Props): { refills: Loading<RefillEligibility | null> } => {
  const { user } = useUserContext()
  const { setError } = useContext(GlobalContext)
  const [refillStatuses, setRefillStatuses] =
    useState<Loading<RefillEligibility | null>>(LOADING)

  const hasPermissionForRefills = hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_MEDICATION_REFILL],
  })

  useEffect(() => {
    if (!hasPermissionForRefills) {
      setRefillStatuses(loaded(null))
      return
    }

    void getRefillEligibilityForPerson({
      orgId: person.orgId,
      facilityId: person.facilityId,
      personId: person.id,
    })
      .then((response) => {
        setRefillStatuses(loaded(response))
      })
      .catch((e) => {
        setError(e)
        setRefillStatuses(loaded(null))
      })
  }, [person.id])

  return {
    refills: refillStatuses,
  }
}
