import { Occupancy_Room as OccupancyRoom } from '@augusthealth/models/com/august/protos/api/occupancy'
import CSS from 'csstype'
import { Order } from '@shared/utils/common'
import { sortRoomsByName, sortRoomsByStatus } from '@shared/utils/occupancy'

export enum TableColumn {
  DELETE = '',
  NAME = 'Room',
  STATUS = 'Status',
}

type TableHeaderData = {
  label: TableColumn
  thStyles?: CSS.Properties
  sortFunc?: (rooms: OccupancyRoom[], order?: Order) => OccupancyRoom[]
}

export const ROOM_THEAD_DATA: TableHeaderData[] = [
  {
    label: TableColumn.NAME,
    thStyles: { width: '20%' },
    sortFunc: sortRoomsByName,
  },
  {
    label: TableColumn.STATUS,
    sortFunc: sortRoomsByStatus,
    thStyles: { width: '40%' },
  },
  {
    label: TableColumn.DELETE,
    // Person Status: Empty Header, no sortFunc to hide chevron icons
  },
  {
    label: TableColumn.DELETE,
    // Delete: Empty Header, no sortFunc to hide chevron icons
    thStyles: { width: '20%' },
  },
]

export function getRoomSortFunc(column: TableColumn) {
  const data = ROOM_THEAD_DATA.find((r) => r.label === column)
  return data?.sortFunc || sortRoomsByName
}
