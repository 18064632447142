type Options = {
  idName?: string
  eventName?: string
  onIframeAppended?: () => void
  onPrint?: () => void
}

export function printFileFromIFrame(url: string, options: Options = {}) {
  const {
    eventName = 'load',
    idName = 'printer-iframe',
    onIframeAppended,
    onPrint,
  } = options
  let iframe: HTMLIFrameElement | undefined =
    (document.getElementById(idName) as HTMLIFrameElement | null) || undefined
  if (iframe) {
    document.body.removeChild(iframe)
  }

  iframe = document.createElement('iframe')
  iframe.id = idName
  iframe.src = url
  // Hide iframe
  // display: none will make print area blank in Chrome
  // visibility: hidden has no effect on Safari
  iframe.style.width = '0px'
  iframe.style.height = '0px'
  iframe.addEventListener(eventName, () => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    iframe && iframe.contentWindow?.print()
    if (onPrint) {
      onPrint()
    }
  })
  iframe.addEventListener('afterprint', () => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    iframe && document.body.removeChild(iframe)
  })

  document.body.appendChild(iframe)
  if (onIframeAppended) {
    onIframeAppended()
  }
}
