import {
  AmbulatoryStatus,
  AmbulatoryStatus_AmbulatoryCode,
  AmbulatoryStatus_AmbulatoryResult,
} from '@shared/types/ambulatory_status'
import { Facility } from '@shared/types/facility'
import { Person } from '@shared/types/person'
import {
  AmbulatorySettings,
  AmbulatoryStatusSettings,
} from '@shared/types/settings/ambulatory_settings'
import { PickPartial } from '@shared/types/utilities'
import { isCaliforniaFacility } from './facilities'

export function isAmbulatory({
  ambulatoryStatus,
}: {
  ambulatoryStatus?: AmbulatoryStatus
}) {
  return (
    ambulatoryStatus?.ambulatoryResult ===
    AmbulatoryStatus_AmbulatoryResult.AMBULATORY_RESULT_AMBULATORY
  )
}

export type AmbulatoryBadgeColor = 'green' | 'orange'
export function getAmbulatoryBadgeColor({
  ambulatoryStatus,
}: {
  ambulatoryStatus?: AmbulatoryStatus
}): AmbulatoryBadgeColor {
  return isAmbulatory({ ambulatoryStatus }) ? 'green' : 'orange'
}

/**
 * @returns the appropriate ambulatory status to display based on the facility location and configured ambulatory settings when appropriate.
 */
export function getAmbulatoryStatus({
  person,
  facility,
  ambulatorySettings,
}: {
  person: PickPartial<Person, 'ambulatoryStatus'>
  facility: Facility
  ambulatorySettings?: AmbulatorySettings
}) {
  if (isCaliforniaFacility(facility)) {
    return getCaliforniaAmbulatoryStatus(
      person.ambulatoryStatus?.ambulatoryResult
    )
  } else {
    return getConfiguredAmbulatoryStatus({
      person,
      settings: ambulatorySettings?.ambulatoryStatusSettings || [],
    })
  }
}

export const UNKNOWN_AMBULATORY_STATUS = 'Unknown'
/**
 * This function returns the appropriate ambulatory status to display for the given person.
 * Non-California communities have their ambulatory settings driven by configuration.
 * @param person
 * @param settings
 */
export function getConfiguredAmbulatoryStatus({
  person,
  settings,
}: {
  person: PickPartial<Person, 'ambulatoryStatus'>
  settings: AmbulatoryStatusSettings[]
}): string {
  return (
    settings.find(
      (setting) =>
        setting.ambulatoryStatus === person.ambulatoryStatus?.ambulatoryResult
    )?.title || UNKNOWN_AMBULATORY_STATUS
  )
}

/**
 * California communities do not use configuration-driven ambulatory settings.
 * @param ambulatoryStatus
 */
export const getCaliforniaAmbulatoryStatus = (
  ambulatoryStatus: AmbulatoryStatus_AmbulatoryResult | undefined
): string => {
  switch (ambulatoryStatus) {
    case AmbulatoryStatus_AmbulatoryResult.AMBULATORY_RESULT_AMBULATORY:
      return 'Ambulatory'
    case AmbulatoryStatus_AmbulatoryResult.AMBULATORY_RESULT_BEDRIDDEN:
      return 'Bedridden'
    case AmbulatoryStatus_AmbulatoryResult.AMBULATORY_RESULT_NON_AMBULATORY:
      return 'Non-ambulatory'
    case AmbulatoryStatus_AmbulatoryResult.AMBULATORY_RESULT_SEMI_AMBULATORY:
      return 'Semi-ambulatory'
    default:
      return 'Unspecified'
  }
}

export const getAmbulatoryStatusCssClassOrSvgPath = (
  ambulatoryStatus: AmbulatoryStatus_AmbulatoryResult | undefined
): string => {
  switch (ambulatoryStatus) {
    case AmbulatoryStatus_AmbulatoryResult.AMBULATORY_RESULT_AMBULATORY:
      return 'fa-solid fa-person-walking'
    case AmbulatoryStatus_AmbulatoryResult.AMBULATORY_RESULT_BEDRIDDEN:
      return '/svg/bedridden--can_turn.svg'
    case AmbulatoryStatus_AmbulatoryResult.AMBULATORY_RESULT_NON_AMBULATORY:
      return '/svg/bedridden--can_turn.svg'
    case AmbulatoryStatus_AmbulatoryResult.AMBULATORY_RESULT_SEMI_AMBULATORY:
      return '/svg/walk--walker.svg'
    default:
      return ''
  }
}

export const AMBULATORY_STATUS_OPTIONS = [
  AmbulatoryStatus_AmbulatoryResult.AMBULATORY_RESULT_AMBULATORY,
  AmbulatoryStatus_AmbulatoryResult.AMBULATORY_RESULT_BEDRIDDEN,
  AmbulatoryStatus_AmbulatoryResult.AMBULATORY_RESULT_NON_AMBULATORY,
  AmbulatoryStatus_AmbulatoryResult.AMBULATORY_RESULT_SEMI_AMBULATORY,
].map((status) => ({
  label: getCaliforniaAmbulatoryStatus(status),
  value: status,
}))

export function nameForAmbulatoryCode(code: AmbulatoryStatus_AmbulatoryCode) {
  switch (code) {
    case AmbulatoryStatus_AmbulatoryCode.AMBULATORY_CODE_WALKS_WITHOUT_SUPPORT:
      return 'Walks without device or support'
    case AmbulatoryStatus_AmbulatoryCode.AMBULATORY_CODE_WALKS_WITH_CANE:
      return 'Walks with cane'
    case AmbulatoryStatus_AmbulatoryCode.AMBULATORY_CODE_WALKS_WITH_WALKER_OR_OTHER_SUPPORT:
      return 'Walks with walker or other support'
    case AmbulatoryStatus_AmbulatoryCode.AMBULATORY_CODE_USES_WHEELCHAIR:
      return 'Uses wheelchair'
    case AmbulatoryStatus_AmbulatoryCode.AMBULATORY_CODE_BEDRIDDEN_ABLE_TO_TURN:
      return 'Mostly in bed, able to turn self'
    case AmbulatoryStatus_AmbulatoryCode.AMBULATORY_CODE_BEDRIDDEN_UNABLE_TO_TURN:
      return 'Bedridden, unable to turn self'
  }

  return undefined
}
