import Badge from '@shared/components/Badge'
import { Person } from '@shared/types/person'
import { displayCPRCode } from '@shared/utils/person'
import { getSnapshotName } from '@shared/utils/snapshot'
import { RoutableDocument } from '../helpers'
import POLSTViewer from '../Viewer/POLSTViewer'
import DocumentCard from './DocumentCard'

export default function POLSTCard({
  animationStyle,
  currentPOLST,
  person,
  onUpdate,
}: {
  animationStyle: React.CSSProperties
  currentPOLST: RoutableDocument
  person: Person
  onUpdate: () => Promise<void>
}) {
  const cprCode = displayCPRCode(person)

  let badgeColor: 'gray' | 'green' | 'orange' = 'gray'

  if (cprCode === 'DNR') {
    badgeColor = 'orange'
  } else if (cprCode === 'Full Code') {
    badgeColor = 'green'
  }

  return (
    <DocumentCard
      person={person}
      document={currentPOLST}
      icon="fa-file-image"
      title={
        <>
          {getSnapshotName(currentPOLST, 'POLST Form')}
          <Badge color={badgeColor} className="ml-[8px]">
            {cprCode}
          </Badge>
        </>
      }
      completionText="Uploaded"
      viewer={(closeFn) => (
        <POLSTViewer
          person={person}
          currentPOLST={currentPOLST}
          onComplete={async (updated) => {
            if (updated) {
              await onUpdate()
            }
            closeFn()
          }}
        />
      )}
      cypressData="polstCard"
      animationStyle={animationStyle}
    />
  )
}
