import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PersonSearchBox } from '@shared/components/PersonSearchBox'
import { useUserContext } from '@shared/contexts/UserContext'
import { Person } from '@shared/types/person'
import { getFirstAndLastName } from '@shared/utils/humanName'
import notEmpty from '@shared/utils/notEmpty'
import { personNameMatchesSearch } from '@shared/utils/person'
import { tw } from '@shared/utils/tailwind'
import styles from '@app/components/Prospects/List/styles.module.css'
import tableStyles from '@app/components/Residents/list.module.css'
import {
  Table,
  TableContainer,
  TdWithExtraPadding,
  TrWithHoverEffect,
} from '@app/components/Table'
import TableHeader from '@app/components/Table/TableHeader'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import { getResidentPath } from '@app/routing/helpers'

export default function ClosedProspectsList() {
  const [nameFilter, setNameFilter] = useState('')

  const { closedProspects } = useCurrentFacility()

  const filteredProspects = closedProspects.filter((prospect) =>
    personNameMatchesSearch(prospect.person!, nameFilter)
  )

  return (
    <>
      <div className={styles.prospectHeader}>
        <div className={styles.prospectTitle}>Closed Move-ins</div>
        <div className={tableStyles.tableButtons}>
          <PersonSearchBox onChange={setNameFilter} value={nameFilter} />
        </div>
      </div>
      <ProspectsList
        sortedProspects={filteredProspects
          .map((p) => p.person)
          .filter(notEmpty)}
      />
    </>
  )
}

function ProspectsList({ sortedProspects }: { sortedProspects: Person[] }) {
  const history = useHistory()
  const { user } = useUserContext()

  return (
    <TableContainer withScrollBar>
      <Table>
        <TableHeader headers={[{ label: 'Name' }]} />
        <tbody>
          {sortedProspects.map((person, index) => {
            return (
              <TrWithHoverEffect
                key={person.id}
                id={`prospect-${index}`}
                onClick={() =>
                  history.push(
                    getResidentPath({
                      user,
                      person,
                    })
                  )
                }
              >
                <TdWithExtraPadding className={tw`font-semibold text-gray-01`}>
                  {getFirstAndLastName(person.name)}
                </TdWithExtraPadding>
              </TrWithHoverEffect>
            )
          })}
        </tbody>
      </Table>
    </TableContainer>
  )
}
