import { range } from 'lodash'
import Skeleton from 'react-loading-skeleton'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { tw } from '@shared/utils/tailwind'
import PersonPageTitle from '@app/components/PersonPageTitle'
import {
  Table,
  TableContainer,
  TdWithExtraPadding,
  TrWithHoverEffect,
} from '@app/components/Table'

export default function LoadingTable() {
  return (
    <div className={tw`flex flex-col py-8`}>
      <PersonPageTitle title="General Settings" withBorder={false} />
      <TableContainer>
        <Table className="table-auto">
          <tbody>
            <TrWithHoverEffect className={tw`h-[69px]`} disableHoverEffect>
              <TdWithExtraPadding>
                <Skeleton />
              </TdWithExtraPadding>
              <TdWithExtraPadding
                className={tw`w-32 whitespace-nowrap pr-3 text-right`}
              >
                <Skeleton />
              </TdWithExtraPadding>
            </TrWithHoverEffect>
            {range(1, 5).map((i) => (
              <TrWithHoverEffect
                className={tw`h-[61px]`}
                key={i}
                disableHoverEffect
              >
                <TdWithExtraPadding>
                  <Skeleton />
                </TdWithExtraPadding>
                <TdWithExtraPadding
                  className={tw`w-32 whitespace-nowrap pr-3 text-right`}
                >
                  <Skeleton />
                </TdWithExtraPadding>
              </TrWithHoverEffect>
            ))}
          </tbody>
        </Table>

        <div className={tw`mb-4 mt-4 flex w-full justify-end pr-3`}>
          <AsyncIconButton
            disabled
            isLoading
            buttonStyle="primary-fill"
            buttonSize="medium"
            type="submit"
          >
            Loading
          </AsyncIconButton>
        </div>
      </TableContainer>
    </div>
  )
}
