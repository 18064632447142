import { PropsWithChildren } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import Card from '@shared/components/Card'
import { tw } from '@shared/utils/tailwind'

interface EmptyProps extends PropsWithChildren {
  version:
    | { tag: 'GetStarted'; cta: string; ctaFn?: () => void }
    | { tag: 'Children'; children: React.ReactNode }
  subtitle?: string
  className?: string
  title?: string
  'data-testid'?: string
}

export const EmptyCard = ({
  version,
  title,
  subtitle,
  className,
  ...rest
}: EmptyProps) => {
  const dataTestId = rest['data-testid']
  return (
    <Card
      className={tw`flex-1 flex-col items-center justify-center p-[80px] text-[14px] font-medium leading-[20px] text-gray-07 ${className}`}
      data-testid={dataTestId}
    >
      <div>{title}</div>
      {subtitle && <div>{subtitle}</div>}
      {version.tag === 'GetStarted' && (
        <div className={tw`mt-[4px]`}>
          To get started, {!version.ctaFn && <strong>{version.cta}</strong>}
          {version.ctaFn && (
            <ButtonLink onClick={version.ctaFn} className={tw`font-medium`}>
              {version.cta}.
            </ButtonLink>
          )}
        </div>
      )}
      {version.tag === 'Children' && version.children}
    </Card>
  )
}
