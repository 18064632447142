import { set } from '@augusthealth/august-frontend-form-elements'
import { useContext, useEffect, useState } from 'react'
import { fetchPerson } from '@shared/api/person'
import { patchSnapshot } from '@shared/api/snapshot'
import Icon from '@shared/components/Icon'
import GlobalContext from '@shared/contexts/GlobalContext'
import { tw } from '@shared/utils/tailwind'
import { fetchSnapshot } from '@app/api/snapshot'
import FullpageFormFooter from '@app/components/FullpageFormFooter'
import AdvancedFormGenerator from '@app/components/generators/AdvancedFormGenerator'
import PersonContext from '@app/contexts/PersonContext'
import { LoaderProps, PageFormProps, UpdaterProps } from './type'
import { getRootAttributeFromNamePath } from './utils'

export function snapshotLoader(fetchProps: LoaderProps) {
  const newFetchProps = { ...fetchProps, create: true, fields: '' }
  return fetchSnapshot(newFetchProps).then((s) => {
    return { data: s }
  })
}

interface SnapshotUpdater extends UpdaterProps {
  patchKey: string
}

function snapshotUpdater(patchProps: SnapshotUpdater) {
  const newPatchProps = {
    ...patchProps,
    snapshotId: 'latest',
    patch: { [patchProps.patchKey]: patchProps.patch },
  }

  return patchSnapshot(newPatchProps)
}

export function getSnapshotUpdater(patchKey: string) {
  return (patchProps: UpdaterProps) =>
    snapshotUpdater({
      ...patchProps,
      patchKey,
    })
}

export default function PageForm(props: PageFormProps) {
  const {
    configuration,
    match,
    loader,
    updater,
    cancelButtonClick,
    saveButtonClick,
    requiredFields,
    readOnly = false,
    mapResponse = (res) => res?.data,
    onDataChange,
    defaultShowRequiredFieldAlert = false,
    setIsApiLoading,
    returnUpdatedAttributeOnly = false,
    pageIndex,
  } = props
  const { setError } = useContext(GlobalContext)
  const { person, setPerson, tasks = [] } = useContext(PersonContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [data, setData] = useState({})
  const { id: pId, facilityId, orgId, taskId } = match.params
  const task = tasks.find((t) => t.id === taskId)
  const dataType = task && task.taskTemplateInfo?.dataType

  useEffect(reloadData, [pId, facilityId, orgId, taskId, pageIndex])

  const updateData = ({
    res,
    value,
    name,
  }: {
    res: any
    value?: any
    name?: string
  }) => {
    const newData = mapResponse(res, data, name, value)
    setData(newData)
    if (onDataChange) {
      onDataChange({ name, value, data: newData })
    }
  }

  function reloadData() {
    if (dataType && loader) {
      loader({ facilityId, pId, personId: pId, orgId, dataType })
        .then((res) => updateData({ res }))
        .catch(setError)
    }
  }

  const onUpdate = (value: any, name: string) => {
    const newData = { ...data }
    set(newData, name, value)

    let patch = newData
    if (returnUpdatedAttributeOnly) {
      const attrName = getRootAttributeFromNamePath(name)
      if (attrName) {
        patch = { [attrName]: newData[attrName] }
      }
    }
    if (dataType && updater) {
      setIsApiLoading && setIsApiLoading(true)
      setIsSubmitting(true)
      updater({
        facilityId,
        personId: pId,
        pId,
        orgId,
        dataType,
        snapshotId: 'latest',
        taskId: taskId || '',
        patch,
        value,
        name,
      })
        .then((res) => {
          // To-Do: don't need to update PersonContext for GettingToKnowYou, etc.
          return fetchPerson({ personId: pId, facilityId, orgId }).then((p) => {
            // Prevent setPerson on RequiredFields mode (in which :page is missing)
            // so PageForm will not be reloaded unintentional
            // TO DO: include :page on RequiredFields mode
            if (!defaultShowRequiredFieldAlert) {
              setPerson(p)
            }
            // Make sure that onDataChange will be executed after onUpdate
            updateData({ res, name, value })
            setIsApiLoading?.(false)
          })
        })
        .catch(setError)
        .finally(() => setIsSubmitting(false))
    }
  }

  const mapComponentProps = (props: object) => ({
    ...props,
    person,
    reloadData,
  })

  const showButtons = Boolean(cancelButtonClick || saveButtonClick)
  return (
    <div style={{ marginBottom: '128px' }}>
      <AdvancedFormGenerator
        data={data}
        configuration={configuration}
        mapComponentProps={mapComponentProps}
        onUpdate={onUpdate}
        cancelButtonClick={cancelButtonClick}
        saveButtonClick={saveButtonClick}
        requiredFields={requiredFields}
        readOnly={readOnly}
        defaultShowRequiredFieldAlert={defaultShowRequiredFieldAlert}
      />
      {showButtons && (
        <FullpageFormFooter
          yesBtn={{
            text: (
              <>
                Continue
                <Icon
                  name="angle-right"
                  className={tw`ml-[8px]`}
                  variant="regular"
                />
              </>
            ),
            props: {
              isLoading: isSubmitting,
              disabled: isSubmitting,
              onClick: saveButtonClick,
            },
          }}
          noBtn={{
            text: 'Finish Later',
            action: () => {
              cancelButtonClick && cancelButtonClick()
              return Promise.resolve()
            },
          }}
        />
      )}
    </div>
  )
}
