import ButtonLink from '@shared/components/ButtonLink'
import Icon from '@shared/components/Icon'
import { Facility } from '@shared/types/facility'
import { tw } from '@shared/utils/tailwind'
import { CareGroupsMap } from '@app/api/careGroup'
import { MedGroupsMap } from '@app/api/medGroup'
import {
  Table,
  TableContainer,
  TdWithExtraPadding,
  TrWithHoverEffect,
} from '@app/components/Table'
import TableHeader, { Header } from '@app/components/Table/TableHeader'
import { FacilityGroupsPopupStateProps } from '../FacilityGroupPopup/helpers'

const TABLE_HEADERS: Header[] = [
  {
    label: 'Community',
    className: 'w-[31%]',
  },
  {
    label: 'Care Groups',
    className: 'w-[31%]',
  },
  {
    label: 'Med Groups',
    className: 'w-[31%]',
  },
  {
    label: '', // TH for Edit button
    className: 'w-[7px]',
  },
]

type Props = {
  careGroupsMap: CareGroupsMap
  medGroupsMap: MedGroupsMap
  facilities: Facility[]
  setCareGroupPopupData: (data: FacilityGroupsPopupStateProps) => void
}

export default function FacilityGroupTable(props: Props) {
  const { careGroupsMap, medGroupsMap, facilities, setCareGroupPopupData } =
    props

  let trs
  if (facilities.length) {
    trs = facilities.map((f) => {
      const { id, orgId, name } = f
      const careGroups = careGroupsMap[id!] || []
      const medGroups = medGroupsMap[id!] || []

      return (
        <TrWithHoverEffect
          key={`facility-row-${orgId}-${id}`}
          onClick={() =>
            setCareGroupPopupData({
              facility: f,
              careGroups,
              medGroups,
            })
          }
        >
          <TdWithExtraPadding>
            <Icon name="house" className={tw`mr-[8px] text-gray-07`} />
            {name}
          </TdWithExtraPadding>
          <TdWithExtraPadding>
            {careGroups.length > 0 &&
              careGroups.map((c) => (
                <div key={`cg-${c.id}`}>
                  <Icon
                    name="people-group"
                    className={tw`mr-[8px] text-gray-07`}
                  />
                  {c.name}
                </div>
              ))}
            {!careGroups.length && (
              <span className={tw`mute-text`}>No care groups set up yet</span>
            )}
          </TdWithExtraPadding>
          <TdWithExtraPadding>
            {medGroups.length > 0 &&
              medGroups.map((c) => (
                <div key={`cg-${c.id}`}>
                  <Icon
                    name="people-group"
                    className={tw`mr-[8px] text-gray-07`}
                  />
                  {c.name}
                </div>
              ))}
            {!medGroups.length && (
              <span className={tw`mute-text`}>No med groups set up yet</span>
            )}
          </TdWithExtraPadding>
          <TdWithExtraPadding className={tw`text-clip text-right`}>
            <ButtonLink>
              <Icon name="fa-pen" />
            </ButtonLink>
          </TdWithExtraPadding>
        </TrWithHoverEffect>
      )
    })
  } else {
    trs = (
      <tr>
        <TdWithExtraPadding colSpan={TABLE_HEADERS.length}>
          Record not found
        </TdWithExtraPadding>
      </tr>
    )
  }

  return (
    <TableContainer>
      <Table>
        <TableHeader headers={TABLE_HEADERS} />
        <tbody>{trs}</tbody>
      </Table>
    </TableContainer>
  )
}
