import {
  Occupancy_Room as OccupancyRoom,
  UpdateCapacityRequest,
} from '@augusthealth/models/com/august/protos/api/occupancy'
import { Room } from '@augusthealth/models/com/august/protos/room'
import { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { BasicSpinner } from '@shared/components/BasicSpinner'
import Button from '@shared/components/Button'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { updateRoom, updateRoomCapacity } from '@app/api/rooms'
import RoomCapacitySelect, { CAPACITY_OPTIONS } from './RoomCapacitySelect'

type Props = {
  occupancyRoom: OccupancyRoom
  onComplete: (updated: boolean) => void
}

type FormData = UpdateCapacityRequest & Room

export default function EditRoomPopup(props: Props) {
  const { setError } = useContext(GlobalContext)
  const { occupancyRoom, onComplete } = props
  const { room = {}, beds = [] } = occupancyRoom
  const { facilityId, orgId, id, name: originalName } = room
  const originalCapacity = beds.length
  const { control, formState, handleSubmit, register } = useForm<FormData>({
    defaultValues: {
      name: originalName,
      capacity: originalCapacity,
    },
  })

  const { errors } = formState

  const { isDirty: dataChanged } = formState
  const [saving, setSaving] = useState(false)

  const onSubmit = (formData: FormData) => {
    const { name, capacity } = formData
    const promises: Promise<any>[] = []
    if (orgId && facilityId && id) {
      if (name !== originalName) {
        promises.push(updateRoom({ ...room, name }))
      }

      if (capacity !== originalCapacity) {
        promises.push(
          updateRoomCapacity(room, {
            capacity,
          })
        )
      }

      setSaving(true)
      Promise.all(promises)
        .then(() => onComplete(true))
        .catch(setError)
        .finally(() => setSaving(false))
    }
  }

  return (
    <AnimatedPopup title="Edit Room">
      <form>
        <div className="mt-[32px]">
          <LabelAboveInput
            htmlFor="name"
            subLabel={requiredLabel(Boolean(errors.name))}
          >
            Room Name
          </LabelAboveInput>
          <BasicInput {...register('name', { required: true })} />
        </div>
        <Controller
          control={control}
          name="capacity"
          render={({ field: { onChange, value } }) => (
            <div className="mt-[32px]">
              <LabelAboveInput htmlFor="capacity">Room Type</LabelAboveInput>
              <RoomCapacitySelect
                name="capacity"
                id="capacity"
                instanceId="capacity"
                menuPlacement="top"
                onChange={(opt: OptionTypeBase) => onChange(opt.value)}
                value={CAPACITY_OPTIONS.find((opt) => opt.value === value)}
              />
            </div>
          )}
        />
        <div className="mt-[32px] space-x-[12px] whitespace-nowrap">
          <Button
            data-cy="cancelEditRoomButton"
            className="w-[110px] border border-button-tertiary-color bg-button-default-bg-color uppercase text-button-tertiary-color hover:bg-button-tertiary-color hover:text-button-fill-font-color"
            onClick={() => onComplete(false)}
          >
            Cancel
          </Button>
          <Button
            data-cy="saveEditRoomButton"
            className="w-[110px] bg-button-primary-color uppercase  text-button-fill-font-color hover:brightness-125"
            onClick={handleSubmit(onSubmit)}
            disabled={!dataChanged || saving}
          >
            {saving ? <BasicSpinner /> : 'Save'}
          </Button>
        </div>
      </form>
    </AnimatedPopup>
  )
}
