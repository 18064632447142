import { SignatureConfiguration } from '@augusthealth/models/com/august/protos/signable_form'
import { ReactNode } from 'react'
import { SmallDotDivider } from '@shared/components/DotDivider'
import { tw } from '@shared/utils/tailwind'

export default function WithSignerDescription({
  templateSigner,
  children,
}: {
  templateSigner?: SignatureConfiguration
  children: ReactNode | string
}) {
  const signerDescription = templateSigner?.description

  if (!signerDescription) {
    return children
  }

  return (
    <div className={tw`mt-0.5 flex`}>
      <span
        data-testid={`signer-description-${templateSigner.signerRole}-${templateSigner.roleIndex}`}
        className={tw`text-secondary-07`}
      >
        {signerDescription}
      </span>
      <SmallDotDivider />
      {children}
    </div>
  )
}
