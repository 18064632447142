import { useContext, useEffect, useState } from 'react'
import { getRefillEligibilityForMedicationOrderGroup } from '@shared/api/pharmacy_refill'
import GlobalContext from '@shared/contexts/GlobalContext'
import { RefillEligibility } from '@shared/types/pharmacy_refill'
import { loaded, LOADING, Loading } from '@shared/utils/loading'

type Props =
  | {
      orgId: string
      facilityId: string
      personId: string
      medicationOrderGroupId: string
    }
  | undefined

export const useRefillsByMedicationOrderGroup = (
  ids?: Props
): { refills: Loading<RefillEligibility | null> } => {
  const { setError } = useContext(GlobalContext)
  const [refillStatuses, setRefillStatuses] =
    useState<Loading<RefillEligibility | null>>(LOADING)
  const idString = [
    ids?.orgId,
    ids?.facilityId,
    ids?.personId,
    ids?.medicationOrderGroupId,
  ].join(' ')

  useEffect(() => {
    if (ids) {
      const { orgId, facilityId, personId, medicationOrderGroupId } = ids
      void getRefillEligibilityForMedicationOrderGroup({
        orgId,
        facilityId,
        personId,
        medicationOrderGroupId,
      })
        .then((response) => {
          setRefillStatuses(loaded(response))
        })
        .catch((e) => {
          setError(e)
          setRefillStatuses(loaded(null))
        })
    }
  }, [idString])

  return {
    refills: refillStatuses,
  }
}
