import { GtkySectionLabels } from '@shared/utils/gtky'

const planAndAssessmentSteps = [
  'Physical & Functional',
  'Physical & Functional',
  'Physical & Functional',
  'Cognitive & Social',
  'Cognitive & Social',
  'Meds & Care Coordination',
  'Review & Finalize',
]
export const ServicePlanSteps = planAndAssessmentSteps
export const ResidentAssessmentSteps = planAndAssessmentSteps

export const GettingToKnowYouSteps = [
  GtkySectionLabels.ABOUT_YOU,
  GtkySectionLabels.FAMILY_AND_FRIENDS,
  GtkySectionLabels.RELIGIOUS_PREFERENCES,
  GtkySectionLabels.INTERESTS,
  GtkySectionLabels.ROUTINES,
  GtkySectionLabels.MEALS,
  GtkySectionLabels.REVIEW_AND_FINALIZE,
]
