import styles from './styles.module.css'
import ConfirmModal from '../ConfirmModal'

interface Props {
  setOpenShareWithRPModal: (open: boolean) => void
  setOpenCollabModal: (open: boolean) => void
}

export default function PromptToShareWithRP({
  setOpenShareWithRPModal,
  setOpenCollabModal,
}: Props) {
  return (
    <ConfirmModal
      title={
        <div>
          <img
            className={styles.clappingImg}
            src="/svg/gratitude.svg"
            alt="A pair of hands clapping"
          />
          You added a new prospect!
        </div>
      }
      content={
        <div>
          Next, invite the Responsible Person to help
          <br />
          complete the admission documents.
        </div>
      }
      confirmButtonConfig={{
        buttonStyle: 'primary-fill',
        children: 'Share tasks',
        onClick: () => {
          setOpenCollabModal(true)
          setOpenShareWithRPModal(false)
        },
      }}
      denyButtonConfig={{
        onClick: () => setOpenShareWithRPModal(false),
      }}
    />
  )
}
