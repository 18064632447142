import { Immunization } from '@augusthealth/models/com/august/protos/immunization'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import StyledSelect from '@shared/components/Selects/StyledSelect'
import { DataType } from '@shared/types/snapshot'
import { TaskTemplateInfo } from '@shared/types/task'
import {
  getImmunizationsByDiseaseName,
  TARGET_DISEASE_OPTIONS,
  TargetDiseaseOption,
} from '@shared/utils/immunizations'
import { Loading } from '@shared/utils/loading'
import { taskTitle } from '@shared/utils/task'
import { isSignable } from '@shared/utils/taskTemplateInfo'
import {
  FormValues,
  getFormPerDataType,
} from '@app/pages/Documents/Uploader/Forms'
import AddImmunization from '@app/pages/Documents/Viewer/VaccineViewer/FormContent/AddImmunization'

export default function FormProducer({
  currentTaskTemplate,
  setMethods,
  immunizations,
}: {
  currentTaskTemplate: TaskTemplateInfo
  setMethods: Dispatch<SetStateAction<UseFormReturn<FormValues> | undefined>>
  immunizations: Loading<Immunization[]>
}) {
  if (
    immunizations.tag === 'Complete' &&
    currentTaskTemplate.dataType === DataType.DATA_TYPE_IMMUNIZATION_RECORD
  ) {
    return (
      <ImmunizationForm
        setMethods={setMethods}
        immunizations={immunizations.value}
      />
    )
  }

  return (
    <GenericForm
      currentTaskTemplate={currentTaskTemplate}
      setMethods={setMethods}
    />
  )
}

function ImmunizationForm({
  setMethods,
  immunizations: originalImmunizations = [],
}: {
  setMethods: (methods: UseFormReturn<FormValues>) => void
  immunizations: Immunization[]
}) {
  const [diseaseName, setDiseaseName] = useState<string | undefined>(undefined)
  const getImmuListByDiseaseName = () => {
    return diseaseName
      ? getImmunizationsByDiseaseName({
          diseaseName,
          immunizations: originalImmunizations,
        })
      : []
  }
  const methods = useForm<FormValues>({
    defaultValues: {
      tag: 'Immunization',
      name: diseaseName,
      immunizations: getImmuListByDiseaseName(),
      newImmunization: {},
    },
  })
  const { setValue } = methods

  useEffect(() => {
    setMethods(methods)
  }, [])

  useEffect(() => {
    setValue('name', diseaseName || '')
    setValue('immunizations', getImmuListByDiseaseName())
  }, [diseaseName])

  return (
    <>
      <StyledSelect
        options={TARGET_DISEASE_OPTIONS}
        onChange={(opt: TargetDiseaseOption) => {
          setDiseaseName(opt.value.text)
        }}
        placeholder="Select Vaccination..."
      />
      {diseaseName && (
        <AddImmunization
          diseaseName={diseaseName}
          onAdd={() => {}}
          onValueChange={(immunization: Immunization) => {
            setValue('newImmunization', immunization)
          }}
          hideAddButton={true}
        />
      )}
    </>
  )
}

function GenericForm({
  currentTaskTemplate,
  setMethods,
}: {
  setMethods: (methods: UseFormReturn<FormValues>) => void
  currentTaskTemplate: TaskTemplateInfo
}) {
  const methods = useForm<FormValues>()

  useEffect(() => {
    setMethods(methods)
    methods.reset()
  }, [currentTaskTemplate])

  const { component: ChosenFormComponent } = getFormPerDataType(
    currentTaskTemplate.dataType
  )

  const props = {
    dataType: currentTaskTemplate.dataType,
    customType: currentTaskTemplate.customType,
    isSignable: isSignable(currentTaskTemplate),
    label: methods.getValues('name') ?? taskTitle(currentTaskTemplate),
    setAllowToSubmitWithoutFiles: () => {}, // Quick fix, for POLST, still required a file in Document Uploader
  }

  return (
    <FormProvider {...methods}>
      {ChosenFormComponent && <ChosenFormComponent {...props} />}
    </FormProvider>
  )
}
