import { get, isEmpty } from '@augusthealth/august-frontend-form-elements'

// Return true when invalid
function checkRequiredField(value: any, valuesToBeByPassed?: any[]) {
  if (typeof value === 'string' && !value.trim()) {
    return true
  }

  return isEmpty(value, valuesToBeByPassed)
}

export type RequiredField =
  | string
  | string[]
  | {
      path: string
      isInvalidFunc: (value: any) => boolean | string
    }

type GetInvalidFields = {
  data: object
  requiredFields: RequiredField[]
  defaultRequiredMessage?: string
  requiredMessageMap?: { [key: string]: string }
}

export default function getInvalidFields({
  data,
  requiredFields = [],
  defaultRequiredMessage = '* Required',
  requiredMessageMap = {},
}: GetInvalidFields) {
  const invalidFieldsMap = {}
  requiredFields.forEach((rf) => {
    if (Array.isArray(rf)) {
      const allEmpty = rf.every((f) =>
        checkRequiredField(get(data, f), [false, 0])
      )
      if (allEmpty) {
        // false is valid
        rf.forEach((f) => {
          invalidFieldsMap[f] = requiredMessageMap[f] || defaultRequiredMessage
        })
      }
    } else if (typeof rf === 'object') {
      const { path, isInvalidFunc } = rf
      if (path && typeof isInvalidFunc === 'function') {
        const invalidMessage = isInvalidFunc(get(data, path))
        if (invalidMessage) {
          if (typeof invalidMessage === 'string') {
            invalidFieldsMap[path] = invalidMessage
          } else {
            invalidFieldsMap[path] =
              requiredMessageMap[path] || defaultRequiredMessage
          }
        }
      }
    } else if (
      typeof rf === 'string' &&
      checkRequiredField(get(data, rf), [false, 0])
    ) {
      invalidFieldsMap[rf] = requiredMessageMap[rf] || defaultRequiredMessage
    }
  })

  return invalidFieldsMap
}
