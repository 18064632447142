import {
  OrgFacilitySettings,
  SettingsType,
} from '@augusthealth/models/com/august/protos/org_facility_settings'
import { PdfViewSettings_DocumentType } from '@augusthealth/models/com/august/protos/settings/pdf_view_settings'
import { requestJson } from '@shared/api/request'
import { inheritedSettingsUrl } from '@shared/api/urls'
import environment from '@shared/environment'
import { Facility } from '@shared/types/facility'
import { DeepNull } from '@shared/types/utilities'

const orgFacilitySettingsUrl = `${environment.baseUrl}/orgFacilitySettings`

export async function fetchInheritedSettings<T = OrgFacilitySettings>(params: {
  orgId?: string
  facilityId?: string
  state?: string
  settingsType: SettingsType
}): Promise<T> {
  const response = (await requestJson({
    url: inheritedSettingsUrl(params),
  })) as { data: T }

  return response.data
}

export async function fetchSettingsByKey<T = OrgFacilitySettings>(
  orgId: string,
  facilityId: string,
  settingsType: SettingsType
): Promise<T[]> {
  const response = (await requestJson({
    url: `${environment.baseUrl}/organizations/${orgId}/facilities/${facilityId}/settings/${settingsType}/byKey`,
  })) as { data: T[] }

  return response.data
}

export async function fetchOrgSettingsByKey<T = OrgFacilitySettings>(
  orgId: string,
  settingsType: SettingsType
): Promise<T[]> {
  const response = (await requestJson({
    url: `${environment.baseUrl}/organizations/${orgId}/settings/${settingsType}/byKey`,
  })) as { data: T[] }

  return response.data
}

export type OrgFacilitySettingsHash = Record<string, OrgFacilitySettings[]>

type TaskFacilitySettingsResponse = {
  activeFacilities: Facility[]
  organizationId: string
  scheduledTaskDefinitions: {
    facilitySettings: OrgFacilitySettingsHash
    orgSettings: OrgFacilitySettings[]
    orgStateSettings: OrgFacilitySettingsHash
  }
  taskDefinitions: {
    inheritedFacilitySettings: OrgFacilitySettingsHash
    orgSettings: OrgFacilitySettings[]
    orgStateSettings: OrgFacilitySettingsHash
  }
}

export async function fetchTaskFacilitySettings(
  orgId: string
): Promise<TaskFacilitySettingsResponse> {
  const response = (await requestJson({
    url: `${environment.baseUrl}/organizations/${orgId}/taskFacilitySettings`,
  })) as { data: TaskFacilitySettingsResponse }

  return response.data
}

export async function upsertSettings(
  orgFacilitySettings: OrgFacilitySettings
): Promise<void> {
  return requestJson({
    url: orgFacilitySettingsUrl,
    method: 'POST',
    body: JSON.stringify(orgFacilitySettings),
  }) as Promise<void>
}

export function deleteOrgFacilitySetting(
  orgFacilitySettings: OrgFacilitySettings
): Promise<void> {
  return requestJson({
    url: orgFacilitySettingsUrl,
    method: 'DELETE',
    body: JSON.stringify(orgFacilitySettings),
  }) as Promise<void>
}

export async function fetchGlobalSettings({
  settingsType,
}: {
  settingsType: SettingsType
}): Promise<OrgFacilitySettings[]> {
  const res = (await requestJson({
    url: `${orgFacilitySettingsUrl}?settingsType=${settingsType}`,
    method: 'GET',
  })) as { data: OrgFacilitySettings[] }

  return res.data
}

export async function fetchStateSettings({
  state,
  settingsType,
}: {
  state: string
  settingsType: SettingsType
}): Promise<OrgFacilitySettings[]> {
  const res = (await requestJson({
    url: `${orgFacilitySettingsUrl}?settingsType=${settingsType}&state=${state}`,
    method: 'GET',
  })) as { data: OrgFacilitySettings[] }

  return res.data
}

export async function fetchOrgSettings({
  orgId,
  settingsType,
}: {
  orgId: string
  settingsType: SettingsType
}): Promise<OrgFacilitySettings[]> {
  const res = (await requestJson({
    url: `${orgFacilitySettingsUrl}?settingsType=${settingsType}&orgId=${orgId}`,
    method: 'GET',
  })) as { data: OrgFacilitySettings[] }

  return res.data
}

export async function fetchOrgStateSettings({
  orgId,
  state,
  settingsType,
}: {
  orgId: string
  state: string
  settingsType: SettingsType
}): Promise<OrgFacilitySettings[]> {
  const res = (await requestJson({
    url: `${orgFacilitySettingsUrl}?settingsType=${settingsType}&orgId=${orgId}&state=${state}`,
    method: 'GET',
  })) as { data: OrgFacilitySettings[] }

  return res.data
}

export async function fetchFacilitySettings({
  orgId,
  facilityId,
  settingsType,
}: {
  orgId: string
  facilityId: string
  settingsType: SettingsType
}): Promise<OrgFacilitySettings[]> {
  const res = (await requestJson({
    url: `${orgFacilitySettingsUrl}?settingsType=${settingsType}&orgId=${orgId}&facilityId=${facilityId}`,
    method: 'GET',
  })) as { data: OrgFacilitySettings[] }

  return res.data
}

export const createFacilityLoCSettings = async (
  orgFacilitySettings: DeepNull<OrgFacilitySettings>
) => {
  const params = new URLSearchParams({
    settingsType: SettingsType.SETTINGS_TYPE_LEVEL_OF_CARE_SETTINGS,
  })
  if (orgFacilitySettings.orgId) {
    params.append('orgId', orgFacilitySettings.orgId)
  }
  if (orgFacilitySettings.facilityId) {
    params.append('facilityId', orgFacilitySettings.facilityId)
  }
  return requestJson({
    method: 'POST',
    body: JSON.stringify(orgFacilitySettings),
    url: `${orgFacilitySettingsUrl}?${params.toString()}`,
  }) as Promise<void>
}

export const getFacilityPdfViewSettings = async (
  orgId: string,
  facilityId: string,
  documentType: PdfViewSettings_DocumentType
): Promise<OrgFacilitySettings> => {
  return fetchSettingsByKey(
    orgId,
    facilityId,
    SettingsType.SETTINGS_TYPE_PDF_VIEW_SETTINGS
  ).then((list) => list.find((settings) => settings.key === documentType) || {})
}

export const getFacilityServicePlanPdfViewSettings = async (
  orgId: string,
  facilityId: string
): Promise<OrgFacilitySettings> => {
  return getFacilityPdfViewSettings(
    orgId,
    facilityId,
    PdfViewSettings_DocumentType.DOCUMENT_TYPE_SERVICE_PLAN
  )
}

export const getFacilityMedListPdfViewSettings = async (
  orgId: string,
  facilityId: string
): Promise<OrgFacilitySettings> => {
  return getFacilityPdfViewSettings(
    orgId,
    facilityId,
    PdfViewSettings_DocumentType.DOCUMENT_TYPE_MED_LIST
  )
}
