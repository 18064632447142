import { UserSessionLogoutReason } from '@augusthealth/models/com/august/protos/user_session'
import localForage from 'localforage'
import { matchPath } from 'react-router-dom'
import {
  fetchUser,
  loginWithMagicLink as loginWithMagicLinkApi,
  loginWithUsernameAndPassword,
  verifyMfaCode as verifyMfaCodeApi,
} from '@shared/api/user'
import { clearCachesAndRedirect, logout } from '@shared/components/Auth/Auth'
import environment from '@shared/environment'
import { scheme } from '@shared/hooks/useCurrentPage'
import {
  CreateSessionResponse,
  MfaChallenge,
  RequiredUserActionType,
} from '@shared/types/auth'
import { isSuperUser } from '@shared/utils/user'

const AUTHENTICATED_USER_KEY = 'authenticatedUser'

export function authEnabled(): boolean {
  return environment.authMethod !== 'none'
}

export async function login(
  username: string,
  password: string
): Promise<CreateSessionResponse> {
  const response = await loginWithUsernameAndPassword(username, password)
  if (
    response.username &&
    response.action === RequiredUserActionType.NO_ACTION
  ) {
    await setAuthenticatedUser(response.username)
  }

  return response
}

export async function loginWithMagicLink(
  email: string,
  code: string
): Promise<void> {
  // We shouldn't have to check if the user is already logged in here.
  // If the session cookie in the request is for the same user, the backend will return early.
  // If it's for a different user, the associated session will be terminated before processing the code.
  const username = await loginWithMagicLinkApi(email, code)
  await setAuthenticatedUser(username)
}

export async function verifyMfaCode(
  code: string,
  challenge: MfaChallenge
): Promise<void> {
  await verifyMfaCodeApi({
    code: code,
    challengeSessionId: challenge.challengeSessionId,
    method: challenge.method,
  })
  await setAuthenticatedUser(challenge.username)
}

export async function setAuthenticatedUser(username: string): Promise<void> {
  await localForage.setItem(AUTHENTICATED_USER_KEY, username)
}

export async function getAuthenticatedUser(): Promise<string | null> {
  return localForage.getItem(AUTHENTICATED_USER_KEY)
}

export async function clearAuthenticatedUser(): Promise<void> {
  await localForage.removeItem(AUTHENTICATED_USER_KEY)
}

export async function userHasAccessToOrg(redirectPath: string) {
  const user = await fetchUser()

  const matchResult = matchPath<{
    orgId?: string
    personId?: string
    facilityId?: string
  }>(redirectPath, {
    path: scheme,
  })

  return (
    user.groups.some(
      (g) => g.personMatcher?.organizationId === matchResult?.params.orgId
    ) || isSuperUser(user)
  )
}

export const logoutIfNoSessionIsStored = (event: StorageEvent) => {
  const { key, oldValue, newValue } = event

  if (isAuthenticatedUserKey(key)) {
    const userHasChanged = oldValue !== null && oldValue !== newValue
    const noUser = newValue === null
    if (userHasChanged || noUser) {
      clearCachesAndRedirect({ clearUser: false })
    }
  }

  // The key is null when window.localStorage.clear() is called
  if (key === null) {
    // TODO: new reason for this?
    void logout(UserSessionLogoutReason.USER_SESSION_LOGOUT_REASON_UNSPECIFIED)
  }
}

export const isAuthenticatedUserKey = (key: string | null) => {
  return typeof key === 'string' && key.endsWith(AUTHENTICATED_USER_KEY)
}

export async function hasPassedInactivityThreshold(
  allowedInactivityMs: number
): Promise<boolean> {
  const raw = await localForage.getItem<string>('lastUserActivity')

  if (raw === null) {
    return true
  }

  return parseInt(raw) + allowedInactivityMs < Date.now()
}
