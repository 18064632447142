import { useContext } from 'react'
import CurrentFacilityContext from '@shared/contexts/CurrentFacilityContext'
import { Incident } from '@shared/types/incidents'
import { formatDateTime } from '@shared/utils/date'
import {
  commentsUpdatedByText,
  commentsUpdatedTimeText,
} from '@shared/utils/incident'
import { tw } from '@shared/utils/tailwind'
import styles from '../styles.module.css'

type Props = {
  incident: Incident
}

export default function IncidentComments({ incident }: Props) {
  const { convertToFacilityTimezone } = useContext(CurrentFacilityContext)

  return (
    <div className={tw`mb-[32px]`}>
      {(incident.comments ?? []).map((comment) => {
        const modificationTime = comment.createdBy?.modificationTime
        const dateTime = modificationTime
          ? formatDateTime(convertToFacilityTimezone(modificationTime), {
              includeDate: true,
            })
          : undefined

        return (
          <div className={styles.commentRow} key={`comment-${comment.id}`}>
            <div>
              <div>
                {comment.createdBy?.modifiedByUserName && (
                  <span data-testid="edited-name" className={tw`font-bold`}>
                    {commentsUpdatedByText(comment.createdBy)}
                  </span>
                )}
                {comment.createdBy?.modificationTime && (
                  <span
                    data-testid="edited-time"
                    className={tw`ml-[8px] font-medium text-gray-07`}
                    title={dateTime}
                  >
                    <span className={tw`print:hidden`}>
                      {commentsUpdatedTimeText(comment.createdBy)}
                    </span>
                    <span className={tw`hidden print:inline-block`}>
                      {dateTime}
                    </span>
                  </span>
                )}
              </div>
              <div data-testid="comment-text">{comment.comment}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
