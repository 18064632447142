import { Bed } from '@augusthealth/models/com/august/protos/bed'
import { Room } from '@augusthealth/models/com/august/protos/room'
import { useContext } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import { DotDivider } from '@shared/components/DotDivider'
import OccupancyContext from '@app/pages/Facilities/Occupancy/OccupancyContext'
import styles from './styles.module.css'

export default function VacantRow({ bed, room }: { bed: Bed; room: Room }) {
  const { setAssignRoomPopupData } = useContext(OccupancyContext)
  const genderSvg = (
    <div className={`${styles.square} ${styles.emptyPerson}`}>
      <i className="fas fa-user" />
    </div>
  )
  const label = (
    <span className="text-gray-07">
      Vacant
      <DotDivider />
      <ButtonLink
        data-cy={`assignLink${room?.name}`}
        onClick={(ev) => {
          ev.stopPropagation()
          if (room) {
            setAssignRoomPopupData({ bed, room })
          }
        }}
      >
        Assign
      </ButtonLink>
    </span>
  )

  return (
    <div className={styles.row}>
      <div className={`${styles.square} ${styles.emptyBed}`}>
        <i className="far fa-bed-empty" />
      </div>
      {genderSvg}
      {label}
    </div>
  )
}
