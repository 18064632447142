import { Controller, UseFormReturn } from 'react-hook-form'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import ButtonLink from '@shared/components/ButtonLink'
import Card from '@shared/components/Card'
import Icon from '@shared/components/Icon'
import { LabelAboveInput, requiredWhenError } from '@shared/components/Labels'
import { convertTimeToTimeInputValue } from '@shared/utils/date'
import { tw } from '@shared/utils/tailwind'
import { fromTimeStringToTime } from '@shared/utils/time'
import { ShiftsData } from './helpers'

export default function ShiftItem({
  index,
  useFormReturn,
  onRemove,
}: {
  index: number
  useFormReturn: UseFormReturn<ShiftsData>
  onRemove: () => void
}) {
  const { control, formState, register } = useFormReturn
  const { errors } = formState
  const namePath: `shifts.${number}.name` = `shifts.${index}.name`
  const startDatePath: `shifts.${number}.period.startTime` = `shifts.${index}.period.startTime`
  const endDatePath: `shifts.${number}.period.endTime` = `shifts.${index}.period.endTime`
  const hasNameError = Boolean(errors?.shifts?.[index]?.name)
  const hasStartTimeError = Boolean(errors?.shifts?.[index]?.period?.startTime)
  const hasEndTimeError = Boolean(errors?.shifts?.[index]?.period?.endTime)
  const registerOptions = { required: true }

  return (
    <Card
      className={tw`mb-[16px] items-center gap-4`}
      key={`shift-item-${index}`}
    >
      <div className={tw`flex-1`}>
        <LabelAboveInput
          htmlFor={namePath}
          subLabel={requiredWhenError(hasNameError)}
        >
          Name
        </LabelAboveInput>
        <BasicInput
          {...register(namePath, registerOptions)}
          placeholder="Name"
        />
      </div>
      <div className={tw`flex-1`}>
        <LabelAboveInput
          htmlFor={startDatePath}
          subLabel={requiredWhenError(hasStartTimeError)}
        >
          Start Time
        </LabelAboveInput>
        <Controller
          control={control}
          name={startDatePath}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <BasicInput
              name={startDatePath}
              type="time"
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                if (ev.target.value) {
                  onChange(fromTimeStringToTime(ev.target.value))
                }
              }}
              value={value ? convertTimeToTimeInputValue(value) : undefined}
            />
          )}
        />
      </div>
      <div className={tw`flex-1`}>
        <LabelAboveInput
          htmlFor={endDatePath}
          subLabel={requiredWhenError(hasEndTimeError)}
        >
          End Time
        </LabelAboveInput>
        <Controller
          control={control}
          name={endDatePath}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <BasicInput
              name={endDatePath}
              type="time"
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                if (ev.target.value) {
                  onChange(fromTimeStringToTime(ev.target.value))
                }
              }}
              value={value ? convertTimeToTimeInputValue(value) : undefined}
            />
          )}
        />
      </div>
      <div className={tw`flex-0`}>
        <ButtonLink onClick={onRemove}>
          <Icon name="trash" />
        </ButtonLink>
      </div>
    </Card>
  )
}
