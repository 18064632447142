import { UnitOfTime } from '@augusthealth/models/com/august/protos/timing'
import classNames from 'classnames'
import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { LabelAboveInput, requiredWhenError } from '@shared/components/Labels'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import { DaysOfMonthOptions } from '@shared/constants/timing'
import { validateRequiredNumberSelection } from '@shared/utils/formValidationFunctions'
import { ButtonGroup } from '@app/components/ButtonGroup'
import styles from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/styles.module.css'
import { RoutineOrderFormData } from './RoutineOrderForm'

type Props = {
  index: number
  readOnly: boolean
}
export const RoutineDaysOfMonth = ({ index, readOnly }: Props) => {
  const { register, unregister, control, watch, setValue, getValues } =
    useFormContext<RoutineOrderFormData>()

  const frequencyCount = watch(`schedules.${index}.frequency`)
  const numberOfDays = watch(`schedules.${index}.dayOfMonth`)?.length || 0

  useEffect(() => {
    register(`schedules.${index}.period`)
    setValue(`schedules.${index}.period`, 1)
    return () => {
      if (
        getValues(`schedules.${index}.periodUnit`) !==
        UnitOfTime.UNIT_OF_TIME_MONTH
      ) {
        unregister(`schedules.${index}.dayOfMonth`)
      }
    }
  }, [])

  useEffect(() => {
    setValue(`schedules.${index}.frequency`, numberOfDays)
  }, [numberOfDays])

  return (
    <Controller
      control={control}
      name={`schedules.${index}.dayOfMonth`}
      rules={{
        validate: (value: number[]) =>
          validateRequiredNumberSelection(value, frequencyCount),
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const labelClasses = classNames('ml-auto w-fit', {
          'mb-[4px]': error,
          'mb-0': !error,
        })
        return (
          <>
            <LabelAboveInput
              className={labelClasses}
              subLabel={requiredWhenError(!!error)}
              htmlFor={`schedules.${index}.dayOfMonth`}
              errorMessage={error?.message}
            />
            <ButtonGroup
              options={DaysOfMonthOptions}
              onChange={(e: OptionTypeBase<number>[]) =>
                onChange(e.map((opt) => opt.value))
              }
              prependTestId={'days-of-month'}
              buttonClassName={styles.monthOption}
              containerClassName={styles.monthContainerClass}
              chosen={DaysOfMonthOptions.filter((dayOfMonth) =>
                value?.includes(dayOfMonth.value)
              )}
              disabled={readOnly}
            />
          </>
        )
      }}
    />
  )
}
