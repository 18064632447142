import { isNil, omitBy } from 'lodash'
import { getUrl } from '@shared/api/legacy'
import { fetchJson } from '@shared/api/request'
import environment from '@shared/environment'

const BASE_URL = environment.baseUrl
const ORGANIZATIONS_URL = `${BASE_URL}/organizations`
const ORG_URL = `${ORGANIZATIONS_URL}/:orgId`
const PERSON_URL = `${ORG_URL}/people/:personId`
const SNAPSHOT_URL = `${PERSON_URL}/snapshots/:dataType/:snapshotId`
const SIGNATURE_URL_URL = `${SNAPSHOT_URL}/signatureDetails/url`
const SIGN_IN_PERSON_URL = `${SNAPSHOT_URL}/signatureDetails/inPerson/url`

/**
 * Returns a signature URL and client id for the embedded signing experience.
 */
export function getSignatureUrl({
  pId,
  orgId,
  dataType,
  customType,
}: {
  pId: string
  orgId: string
  dataType: string
  customType?: string
}) {
  const params = omitBy({ customType }, isNil)

  const url = getUrl({
    pId,
    orgId,
    snapshotId: 'latest',
    dataType,
    baseUrl: SIGNATURE_URL_URL,
    params,
  })

  return fetchJson(url, { method: 'GET' })
}

/**
 * Returns a signature URL and client id for the embedded sign in person experience.
 */
export function getSignInPersonUrl({
  pId,
  orgId,
  dataType,
  customType,
  email,
}: {
  pId: string
  orgId: string
  dataType: string
  customType?: string
  email: string
}) {
  const params = { ...omitBy({ customType }, isNil), email }

  const url = getUrl({
    pId,
    orgId,
    snapshotId: 'latest',
    dataType,
    baseUrl: SIGN_IN_PERSON_URL,
    params,
  })
  return fetchJson(url, { method: 'GET' })
}
