import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useState } from 'react'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import Icon from '@shared/components/Icon'
import { Person } from '@shared/types/person'
import { getHospitalInfo } from '@shared/utils/person'
import { tw } from '@shared/utils/tailwind'
import styles from '../PrimaryPhysician/styles.module.css'
import { EditableDetailsCard } from '../DetailsCard'
import EditPreferredHospital from './EditPreferredHospital'

export default function PreferredHospital({
  person,
  updatePerson,
}: {
  person: Person
  updatePerson: () => Promise<void>
}) {
  const [showEditHospital, setShowEditHospital] = useState(false)

  const hospital = person.hospital
  const {
    hospitalPhone: phone = '',
    hospitalAddress: address = '',
    hospitalFax: fax = '',
    hospitalName: name = '',
  } = getHospitalInfo(person)

  return (
    <EditableDetailsCard
      id="preferredHospital"
      icon="fas fa-fw fa-hospital"
      title="Preferred Hospital"
      editPermissions={{
        person,
        permissions: [GroupPermission.GROUP_PERMISSION_PERSON_UPDATE],
      }}
      onEditClick={() => setShowEditHospital(true)}
      editButtonId={'editPreferredHospital'}
    >
      {!hospital && <span className={styles.incomplete}>None yet</span>}
      {hospital && (
        <>
          <span className={tw`text-[14px] font-semibold leading-[20px]`}>
            {name}
          </span>
          <div>
            {address && (
              <div
                className={tw`mt-[4px] text-[14px] font-medium leading-[20px]`}
              >
                <Icon className="text-gray-07" name="map-marker-alt" />{' '}
                {address}
              </div>
            )}
            {phone && (
              <div
                className={tw`mt-[4px] text-[14px] font-medium leading-[20px]`}
              >
                <Icon className="text-gray-07" name="phone" /> {phone}
              </div>
            )}
            {fax && (
              <div
                className={tw`mt-[4px] text-[14px] font-medium leading-[20px]`}
              >
                <Icon className="text-gray-07" name="fax" /> {fax}
              </div>
            )}
          </div>
        </>
      )}
      {showEditHospital && (
        <AnimatedPopup title="Preferred Hospital">
          <EditPreferredHospital
            person={person}
            onClose={async (updated) => {
              if (updated) {
                await updatePerson()
              }
              setShowEditHospital(false)
            }}
          />
        </AnimatedPopup>
      )}
    </EditableDetailsCard>
  )
}
