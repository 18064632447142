import { StatementData, StatementStatus } from '@shared/types/billing'

function isSpecificStatus(
  invoiceData: StatementData,
  specificStatus: StatementStatus
): boolean {
  return invoiceData.status === specificStatus
}

export function isPending(invoiceData: StatementData): boolean {
  return isSpecificStatus(invoiceData, StatementStatus.PENDING)
}

export function isPaid(invoiceData: StatementData): boolean {
  return isSpecificStatus(invoiceData, StatementStatus.PAID)
}

export function isDue(invoiceData: StatementData): boolean {
  return isSpecificStatus(invoiceData, StatementStatus.DUE)
}

export function isApproved(invoiceData: StatementData): boolean {
  return isSpecificStatus(invoiceData, StatementStatus.APPROVED)
}

export function isNeedsWork(invoiceData: StatementData): boolean {
  return isSpecificStatus(invoiceData, StatementStatus.NEEDS_WORK)
}

export function isError(invoiceData: StatementData): boolean {
  return isSpecificStatus(invoiceData, StatementStatus.ERROR)
}
