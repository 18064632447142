import { PasswordPolicy } from '@shared/types/settings/security_policy'

export type PasswordPolicyRequirementLabel =
  | 'minLength'
  | 'noKnownStrings'
  | 'requiresOneNumber'
  | 'requiresOneLowercase'
  | 'requiresOneUppercase'
  | 'requiresOneSpecialChar'

export type PasswordPolicyRequirement = {
  minLength: number
  noKnownStrings: boolean
  requiresOneNumber: boolean
  requiresOneLowercase: boolean
  requiresOneUppercase: boolean
  requiresOneSpecialChar: boolean
}

type PasswordPolicyRequirements = {
  [key in PasswordPolicy]: PasswordPolicyRequirement
}

export const standardPolicyRequirement: PasswordPolicyRequirement = {
  minLength: 8,
  noKnownStrings: true,
  requiresOneNumber: false,
  requiresOneLowercase: false,
  requiresOneUppercase: false,
  requiresOneSpecialChar: false,
}

const standardPlusPolicyRequirement: PasswordPolicyRequirement = {
  minLength: 8,
  noKnownStrings: true,
  requiresOneNumber: true,
  requiresOneLowercase: true,
  requiresOneUppercase: true,
  requiresOneSpecialChar: false,
}

export const strictPolicyRequirement: PasswordPolicyRequirement = {
  minLength: 10,
  noKnownStrings: true,
  requiresOneNumber: true,
  requiresOneLowercase: true,
  requiresOneUppercase: true,
  requiresOneSpecialChar: true,
}

export const passwordPolicyRequirements: PasswordPolicyRequirements = {
  [PasswordPolicy.PASSWORD_POLICY_STANDARD]: standardPolicyRequirement,
  [PasswordPolicy.PASSWORD_POLICY_STANDARD_PLUS]: standardPlusPolicyRequirement,
  [PasswordPolicy.PASSWORD_POLICY_STRICT]: strictPolicyRequirement,
  [PasswordPolicy.PASSWORD_POLICY_UNSPECIFIED]: standardPolicyRequirement,
  [PasswordPolicy.UNRECOGNIZED]: standardPolicyRequirement,
}

export const allowedSpecialCharacters = '^$*.[]{}()?"!@#%&/\\,><\':;|_~`=+-'
