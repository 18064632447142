import { PersonTasks } from '@shared/types/api/person_tasks'
import { ResidentStatus } from '@shared/types/person'
import { isIncomplete } from './task'

export default function count({
  personTasks,
  status,
}: {
  personTasks: PersonTasks[]
  status: ResidentStatus
}) {
  return personTasks
    .filter((pt) => status === pt.person.residentStatus)
    .filter((pt) => (pt.tasks || []).filter(isIncomplete).length > 0).length
}
