import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Facility } from '@shared/types/facility'
import { Loading } from '@shared/utils/loading'
import { CareGroupsMap, fetchAllCareGroups } from '@app/api/careGroup'

export default function useCareGroups(
  { facilities }: { facilities: Facility[] },
  deps: React.DependencyList = []
) {
  const { setError } = useContext(GlobalContext)
  const [careGroupsMap, setCareGroupsMap] = useState<Loading<CareGroupsMap>>({
    tag: 'Complete',
    value: careGroupMapFromFacilities(facilities),
  })

  const reloadCareGroups = async () => {
    return fetchAllCareGroups({ facilities: facilities })
      .then((careGroupsMap) =>
        setCareGroupsMap({
          tag: 'Complete',
          value: careGroupsMap,
        })
      )
      .catch(setError)
  }

  useEffect(() => {
    if (facilities) {
      void reloadCareGroups()
    }
  }, [facilities?.map((f) => f.id).join('-'), ...deps])

  return { careGroupsMap, reloadCareGroups }
}

function careGroupMapFromFacilities(facilities: Facility[]): CareGroupsMap {
  return facilities.reduce((accum, el) => {
    return {
      ...accum,
      [el.id]: el.careGroups ?? [],
    }
  }, {} as CareGroupsMap)
}
