import { useEffect, useState } from 'react'
import { FormProvider, useFormContext } from 'react-hook-form'
import Button from '@shared/components/Auth/LoginWithUsernameOrEmail/Button'
import { validate } from '@shared/components/Auth/LoginWithUsernameOrEmail/PasswordField/helpers'
import Requirements, {
  PasswordRequirement,
} from '@shared/components/Auth/LoginWithUsernameOrEmail/PasswordField/Requirements'
import { PasswordPolicy } from '@shared/types/settings/security_policy'
import { tw, twx } from '@shared/utils/tailwind'
import { setRequirements } from './helpers'

export default function ChangePasswordForm({
  hideCodeInput = false,
  onSubmit,
  passwordPolicy,
  disabled,
}: {
  hideCodeInput?: boolean
  passwordPolicy: PasswordPolicy
  onSubmit: ({
    password,
    code,
  }: {
    password: string
    code: string
  }) => Promise<void>
  disabled?: boolean
}) {
  useEffect(() => {
    reset()
  }, [])

  const [isManualEntry, setIsManualEntry] = useState(false)

  const methods = useFormContext()
  const {
    reset,
    getValues,
    register,
    formState: { errors, isSubmitting, isValid },
    watch,
    handleSubmit,
  } = methods

  const requirements = setRequirements({
    passwordPolicy,
    keysToExclude: ['noKnownStrings'],
  })

  const password = watch('password') as string
  const passwordConfirmation = watch('passwordConfirmation') as string

  return (
    <form
      className={tw`mt-[40px] text-center`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormProvider {...methods}>
        {hideCodeInput ? (
          <input {...register('code')} type="hidden" />
        ) : (
          <InputWithIcon
            iconClassName="fas fa-lock text-rebrand-primary-light"
            inputProps={{
              placeholder: 'Enter code you received',
              ...register('code', {
                required: true,
              }),
              disabled,
            }}
          />
        )}
        <InputWithIcon
          inputProps={{
            type: 'password',
            placeholder: 'New password',
            disabled,
            ...register('password', {
              required: true,
              onChange: () => setIsManualEntry(true),
              validate: {
                minLength: () =>
                  validate({
                    key: 'minLength',
                    requirements,
                    values: getValues(),
                  }),
                noKnownStrings: () =>
                  validate({
                    key: 'noKnownStrings',
                    requirements,
                    values: getValues(),
                  }),
                requiresOneNumber: () =>
                  validate({
                    key: 'requiresOneNumber',
                    requirements,
                    values: getValues(),
                  }),
                requiresOneUppercase: () =>
                  validate({
                    key: 'requiresOneUppercase',
                    requirements,
                    values: getValues(),
                  }),
                requiresOneLowercase: () =>
                  validate({
                    key: 'requiresOneLowercase',
                    requirements,
                    values: getValues(),
                  }),
                requiresOneSpecialChar: () =>
                  validate({
                    key: 'requiresOneSpecialChar',
                    requirements,
                    values: getValues(),
                  }),
              },
            }),
          }}
          iconClassName="fas fa-key"
        />
        <InputWithIcon
          inputProps={{
            type: 'password',
            placeholder: 'Confirm new password',
            disabled,
            ...register('passwordConfirmation', {
              required: true,

              validate: {
                mustMatch: (e) => e === getValues('password'),
              },
            }),
          }}
          iconClassName="fas fa-key"
        />
      </FormProvider>

      {isManualEntry && password.length > 0 && (
        <>
          <Requirements
            requirements={requirements}
            errors={errors.password?.types}
          />

          <PasswordRequirement
            keyName="mustMatch"
            title="Passwords must match"
            isSatisfied={password === passwordConfirmation}
          />
        </>
      )}

      <div className={tw`mt-[24px]`}>
        <Button
          disabled={isSubmitting || !isValid}
          text="Change Password"
          state="engaged"
        />
      </div>
    </form>
  )
}

function InputWithIcon({
  iconClassName,
  inputProps,
}: {
  iconClassName: string
  inputProps: React.HTMLProps<HTMLInputElement>
}) {
  return (
    <div className={tw`relative mb-[16px]`}>
      <input
        {...inputProps}
        className={twx(
          'h-[48px] w-[360px] rounded-[6px] border border-gray-10 pl-[40px]',
          'border hover:outline-1 hover:outline-rebrand-primary-light focus:outline-1 focus:outline-rebrand-primary-light',
          'cursor-text text-sm font-medium'
        )}
      />
      <i
        className={twx(
          'absolute left-[16px] top-[53%] -mt-2 text-[14px] text-rebrand-primary-light',
          iconClassName
        )}
      />
    </div>
  )
}
