import {
  Report,
  ReportAssignment,
} from '@augusthealth/models/com/august/protos/report'
import AllowLimitedReadCheckbox from './AllowLimitedReadCheckbox'
import AssignCheckbox from './AssignCheckbox'
import AssignmentName from './AssignmentName'

export default function OrgAssignmentTh({
  orgId,
  report,
  assignments,
  reload,
}: {
  orgId: string
  report: Report
  assignments: ReportAssignment[]
  reload: ({ orgId }: { orgId: string }) => void
}) {
  const { id: reportId } = report
  const assignment = assignments.find(
    (a) =>
      a.facilityId === undefined && a.orgId === orgId && a.reportId === reportId
  )

  return (
    <th className="report-cell">
      {report && (
        <div>
          <AssignCheckbox
            assignment={assignment}
            orgId={orgId}
            report={report}
            reload={() => reload({ orgId })}
          />
          {assignment && (
            <AllowLimitedReadCheckbox
              assignment={assignment}
              reload={() => reload({ orgId })}
            />
          )}
        </div>
      )}
      {assignment && (
        <AssignmentName
          assignment={assignment}
          reload={() => reload({ orgId })}
        />
      )}
    </th>
  )
}
