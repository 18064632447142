import { get, set } from '@augusthealth/august-frontend-form-elements'
import {
  CustomDisplayField,
  FormDisplay,
  FormField_CustomField,
} from '@augusthealth/models/com/august/protos/signable_form'
import { uniqBy } from 'lodash'
import { alternateFetchPerson, mergePatchPerson } from '@shared/api/person'
import { patchSnapshot } from '@shared/api/snapshot'
import { AUGUST_FIELD_DISPLAY_CONFIGURATION } from '@shared/constants/custom_field/custom_field'
import { AugustFieldFormConfiguration } from '@shared/constants/custom_field/type'
import { Person } from '@shared/types/person'
import { DataType, FormData, Snapshot } from '@shared/types/snapshot'
import { UserAccount } from '@shared/types/user'
import { removeStartRange } from '@shared/utils/admissions'
import { isSuperUser } from '@shared/utils/user'
import { fetchSnapshot } from '@app/api/snapshot'
import { filterOutEmptyData } from '../603'
import { contactUpdater } from '../contactHelpers'
import {
  LoaderProps,
  PageGeneratorItem,
  UpdaterProps,
} from '../FormLayout/type'

function getNameWithPrefixFromCustomField(
  customDisplayField: CustomDisplayField
) {
  if (customDisplayField.formFieldConfiguration?.type === 'CheckboxGroup') {
    // ugly hack to allow checkbox_group to edit multiple formData.fields
    // in a single form element despite fields have a flat structure
    customDisplayField.formFieldConfiguration.prefix = customDisplayField.name
    return 'fields'
  } else if (customDisplayField.name!.includes('.')) {
    return customDisplayField.name!
  } else {
    // backwards compatibility with customDisplay fields that spell out the full path
    return `fields.${customDisplayField.name}`
  }
}

export function convertToPageGeneratorItem(
  display: FormDisplay,
  freeTextFields: FormField_CustomField[],
  dataType: DataType,
  customType: string | undefined,
  user: UserAccount
): PageGeneratorItem[] {
  const loginAsSuperUser = isSuperUser(user)
  const configuredCustomFields: Set<string> = new Set()
  const pages: PageGeneratorItem[] = (display.pages || []).map(
    (page, index) => {
      if (page.fields && page.fields.length > 0) {
        const fieldConfiguration = (
          page.fields || []
        ).reduceRight<AugustFieldFormConfiguration>(
          (allFields, field) => {
            const { fieldType } = field
            const next =
              fieldType && AUGUST_FIELD_DISPLAY_CONFIGURATION[fieldType]
            if (next) {
              return {
                fields: next.fields.concat(allFields.fields),
                fieldLists: Array.from(
                  new Set([...allFields.fieldLists, ...next.fieldLists])
                ),
                requiredFields: field.required
                  ? next.requiredFields.concat(allFields.requiredFields)
                  : allFields.requiredFields,
              }
            } else if (loginAsSuperUser) {
              // Display not supported message for Super User only
              return {
                ...allFields,
                fields: [
                  ...allFields.fields,
                  {
                    type: 'content',
                    content: (
                      <div>
                        <b>{fieldType}</b> is not supported yet
                      </div>
                    ),
                  },
                ],
              }
            }

            return allFields
          },
          { fields: [], fieldLists: [], requiredFields: [] }
        )

        return {
          menuTitle: `${index + 1}. ${page.menuTitle!}`.toUpperCase(),
          pageTitle: page.pageTitle!.toUpperCase(),
          json: fieldConfiguration.fields,
          requiredFields: fieldConfiguration.requiredFields,
          loader: fieldLoader(fieldConfiguration.fieldLists),
          updater: mergePatchFields(fieldConfiguration.fieldLists),
        }
      } else {
        const requiredFields: string[] = []
        const config = page.customFields?.map((customDisplayField) => {
          const nameWithPrefix =
            getNameWithPrefixFromCustomField(customDisplayField)
          configuredCustomFields.add(customDisplayField.name!)

          if (customDisplayField.formFieldConfiguration?.required) {
            requiredFields.push(nameWithPrefix)
          }

          return {
            ...customDisplayField.formFieldConfiguration,
            name: nameWithPrefix,
          }
        })

        return {
          menuTitle: `${index + 1}. ${page.menuTitle!}`.toUpperCase(),
          pageTitle: page.pageTitle!.toUpperCase(),
          json: config,
          requiredFields,
          loader: snapshotLoader(dataType, customType),
          updater: snapshotUpdate(dataType, customType),
          mapResponse: mapFormDataResponse,
        }
      }
    }
  )

  const unconfiguredCustomFields = uniqBy(
    freeTextFields.filter((f) => !configuredCustomFields.has(f.name!)),
    (v) => v.name
  )

  if (unconfiguredCustomFields.length > 0) {
    const json = unconfiguredCustomFields.map((f) => {
      return {
        name: `fields.${f.name}`,
        title: f.name?.toUpperCase().replace(/_/g, ' '),
      }
    })
    pages.push({
      menuTitle: `${pages.length + 1}. ADDITIONAL INFORMATION`,
      pageTitle: 'ADDITIONAL INFORMATION',
      json,
      loader: snapshotLoader(dataType, customType),
      updater: snapshotUpdate(dataType, customType),
      mapResponse: (x) => x,
    })
  }
  pages.push({
    menuTitle: `${pages.length + 1}. REVIEW`,
    pageTitle: 'REVIEW',
    content: 'Review',
  })
  return pages
}

export function getRequiredFields(display: FormDisplay): string[] {
  const requiredFields: string[] = []
  display.pages?.forEach((page) => {
    page.fields?.forEach((field) => {
      if (field.required) {
        requiredFields.push(
          ...(AUGUST_FIELD_DISPLAY_CONFIGURATION[field.fieldType!]
            ?.requiredFields || [])
        )
      }
    })
    page.customFields?.forEach((customDisplayField) => {
      const nameWithPrefix =
        getNameWithPrefixFromCustomField(customDisplayField)
      const { formFieldConfiguration: config } = customDisplayField
      if (config?.required && nameWithPrefix) {
        requiredFields.push(nameWithPrefix)
      }
    })
  })

  return requiredFields
}

interface ResponseData<T> {
  data: T
  meta?: any
}

// (contact\[relationship\=) for Delete
// (contact\[(\d+)\]$) for Update
// Createing new contact is handled by Add Contact popup
const CONTACT_NAME_REG = /(contact\[relationship=)|(contact\[(\d+)]$)/

function mergePatchFields(
  fieldList: string[]
): (props: UpdaterProps) => Promise<ResponseData<Person>> {
  return (props) => {
    const { facilityId: fId, pId, orgId, patch, taskId, name } = props
    if (CONTACT_NAME_REG.test(name)) {
      return contactUpdater(props).then(() => fieldLoader(fieldList)(props))
    }

    return mergePatchPerson({
      fId,
      pId,
      orgId,
      patch: filterOutEmptyData(removeStartRange(patch)),
      fields: fieldList.join(','),
      taskId,
    })
  }
}

function fieldLoader(
  fieldList: string[]
): (props: LoaderProps) => Promise<ResponseData<Person>> {
  return ({ facilityId: fId, pId, orgId }) =>
    alternateFetchPerson({ fId, pId, orgId, fields: fieldList.join(',') })
}

// Snapshot return data.data.formData
function snapshotLoader(
  dataType: DataType,
  customType: string | undefined
): (props: LoaderProps) => Promise<FormData> {
  return ({ pId, orgId }) =>
    fetchSnapshot({
      personId: pId,
      orgId,
      dataType,
      customType,
      create: true,
      fields: 'data/formData',
    }).then((s: Snapshot) => s.data?.formData || {})
}

function snapshotUpdate(
  dataType: DataType,
  customType: string | undefined
): (props: UpdaterProps) => Promise<FormData> {
  return ({ pId, orgId, patch, taskId, facilityId }) =>
    patchSnapshot({
      orgId,
      facilityId,
      pId,
      taskId,
      snapshotId: 'latest',
      patch: {
        formData: { fields: patch.fields, contactLabels: patch.contactLabels },
      },
      dataType,
      customType,
      params: {
        fields: 'data/formData',
      },
    }).then((s: ResponseData<Snapshot>) => s.data.data?.formData as FormData)
}

function mapFormDataResponse(
  res: FormData,
  priorData: FormData,
  name: string | undefined
): FormData {
  if (!name) {
    return res
  }
  const newData = { ...priorData }
  const updatedValue = get(res, name)
  set(newData, name, updatedValue)
  return newData
}
