import {
  GroupPermission,
  GroupType,
} from '@augusthealth/models/com/august/protos/permission'
import { useState } from 'react'
import { updatePersonToProspect } from '@shared/api/person'
import Banner from '@shared/components/Banner'
import { BasicSpinner } from '@shared/components/BasicSpinner'
import ButtonLink from '@shared/components/ButtonLink'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { Person } from '@shared/types/person'
import { tw } from '@shared/utils/tailwind'
import { usePersonInContext } from '@app/hooks/usePerson'

export default function ClosedProspectBanner({ person }: { person: Person }) {
  const [isLoading, setIsLoading] = useState(false)
  const { refreshPerson } = usePersonInContext({ initialData: person })

  return (
    <Banner
      description={{
        icon: 'fa-user-slash',
        copy: 'This prospect is closed.',
      }}
    >
      <PersonPermissionGate
        person={person}
        permissions={[GroupPermission.GROUP_PERMISSION_PERSON_UPDATE]}
        excludedRoles={[GroupType.GROUP_TYPE_RESPONSIBLE_PARTY]}
      >
        <ButtonLink
          disabled={isLoading}
          onClick={async () => {
            setIsLoading(true)

            await updatePersonToProspect({ person })
            await refreshPerson()
          }}
          className={tw`underline`}
          linkStyle={'secondary'}
          data-testid="reopen-prospect-btn"
        >
          {isLoading && <BasicSpinner className={tw`mr-[8px]`} />}
          Re-Open
        </ButtonLink>
      </PersonPermissionGate>
    </Banner>
  )
}
