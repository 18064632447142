import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { ImageSize } from '@augusthealth/models/com/august/protos/snapshot'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { PersonImage } from '@shared/components/PersonImage'
import { Person } from '@shared/types/person'
import styles from './styles.module.css'

export default function Avatar({
  setShowProfile,
  person,
}: {
  setShowProfile: React.Dispatch<React.SetStateAction<boolean>>
  person: Person
}) {
  return (
    <div>
      <div className={styles.avatarContainer}>
        <PersonImage person={person} size={ImageSize.IMAGE_SIZE_184X184} />
        <PersonPermissionGate
          person={person}
          permissions={[GroupPermission.GROUP_PERMISSION_PERSON_UPDATE]}
        >
          <button
            onClick={() => setShowProfile(true)}
            className={`${styles.editBtn} ${styles.hideOnMobile}`}
          >
            <span>
              <label className="visually-hidden">Edit Profile Details</label>
              <i className="fas fa-pencil fa-sm" />
            </span>
          </button>
        </PersonPermissionGate>
      </div>
    </div>
  )
}
