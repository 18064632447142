import { AsyncIconButton } from '@shared/components/AsyncButton'
import { tw } from '@shared/utils/tailwind'
import { TdWithExtraPadding, TrWithHoverEffect } from '@app/components/Table'

export default function TextSettingRow({
  title,
  data,
  handleEditClick,
}: {
  title: string
  data: string | undefined
  handleEditClick: () => void
}) {
  return (
    <TrWithHoverEffect disableHoverEffect>
      <TdWithExtraPadding>
        {title}:{' '}
        <span className={tw`text-gray-07`} data-testid={`view-${title}`}>
          {data}
        </span>
      </TdWithExtraPadding>

      <TdWithExtraPadding className={tw`whitespace-nowrap text-right`}>
        <AsyncIconButton
          data-testid={`edit-${title}`}
          buttonStyle="secondary-outline"
          buttonSize="small"
          className={tw``}
          initialIcon="pen"
          onClick={handleEditClick}
        >
          Edit
        </AsyncIconButton>
      </TdWithExtraPadding>
    </TrWithHoverEffect>
  )
}
