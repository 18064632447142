import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Lightbox, {
  LightboxSidebarContent,
  LightboxSidebarFooter,
} from '@shared/components/AnimatedPopup/Lightbox/Lightbox'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import LoadingPopup from '@shared/components/LoadingPopup'
import GlobalContext from '@shared/contexts/GlobalContext'
import { admissionTasksPathForPerson, getTaskUrl } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { DataType } from '@shared/types/snapshot'
import { Task } from '@shared/types/task'
import { taskTitle } from '@shared/utils/task'
import { markTaskAsComplete } from '@app/api/tasks'
import { PageGeneratorItem } from '@app/components/Prospects/Forms/FormLayout/type'
import useBlobData from '@app/hooks/useBlobData'
import usePerson from '@app/hooks/usePerson'
import { useTasks } from '@app/hooks/useTasks'
import {
  PDFBody,
  PrintDownloadButtons,
  StaticReviewCopy,
} from '../SignatureFlow/Components'
import { pdfUrl, useIsTaskIncomplete } from '../SignatureFlow/helpers'

export default function FillablePdfFlow({
  task,
  person: initialPerson,
  closeModal,
  configuration,
  dataType,
}: {
  task: Task
  person: Person
  closeModal: () => void
  configuration: PageGeneratorItem[]
  dataType: DataType
}) {
  const history = useHistory()
  const { person } = usePerson({ initialData: initialPerson })
  const blobData = useBlobData(pdfUrl({ person: initialPerson, task }))
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { setError } = useContext(GlobalContext)
  const { refreshTasks } = useTasks(true)
  const isTaskIncomplete = useIsTaskIncomplete({
    configuration,
    dataType,
    person,
    task,
  })

  if (
    person.tag === 'Loading' ||
    isLoading ||
    isTaskIncomplete.tag === 'Loading'
  ) {
    return <LoadingPopup loading />
  }

  async function completeTask() {
    if (person.tag === 'Complete' && isTaskIncomplete.tag === 'Complete') {
      try {
        setIsLoading(true)

        if (isTaskIncomplete.value) {
          history.push(
            getTaskUrl({
              facilityId: person.value.facilityId || '',
              personId: person.value.id || '',
              orgId: person.value.orgId || '',
              id: task.id,
              page: 'requiredFields',
            })
          )
          closeModal()
          return
        }

        await markTaskAsComplete(person.value, task)
        await refreshTasks()
        closeModal()
        history.push(
          admissionTasksPathForPerson(person.value as Required<Person>)
        )
      } catch (e) {
        setError(e)
      } finally {
        setIsLoading(false)
      }
    }
  }

  return (
    <Lightbox
      mainContent={<PDFBody blobData={blobData} />}
      sidebarContent={
        <>
          <LightboxSidebarContent title={taskTitle(task)}>
            <StaticReviewCopy signable={false} />
            <PrintDownloadButtons blobData={blobData} />
          </LightboxSidebarContent>
          <LightboxSidebarFooter className="flex-col">
            <AsyncIconButton
              className="mb-[16px]"
              isLoading={false}
              disabled={
                person.tag !== 'Complete' && isTaskIncomplete.tag !== 'Complete'
              }
              buttonStyle="primary-fill"
              onClick={(ev) => {
                ev.stopPropagation()
                void completeTask()
              }}
            >
              Complete
            </AsyncIconButton>
          </LightboxSidebarFooter>
        </>
      }
      showSidebarOnPrint
      onClose={closeModal}
    />
  )
}
