import { useEffect, useState } from 'react'
import { Document, Page } from 'react-pdf'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { BasicSpinner } from '@shared/components/BasicSpinner'
import ErrorMonitoring from '@shared/ErrorMonitoring'
import { tw } from '@shared/utils/tailwind'
import styles from './styles.module.css'

type Props = {
  pdfToCreateFrom: string
  pdfToCreate: string
  documentProps: {
    error: JSX.Element
  }

  selectedPages: number[]
  setSelectedPages: (number) => void
}

const pdfDocumentOptions = {
  standardFontDataUrl: `/standard_fonts/`,
}

export default function PdfCreator({
  pdfToCreateFrom,
  pdfToCreate,
  documentProps,
  selectedPages,
  setSelectedPages,
}: Props) {
  const [numberOfPages, setNumberOfPages] = useState(1)
  const [userSelectedPages, setUserSelectedPages] = useState<React.ReactNode[]>(
    []
  )
  const setPages = (pageIndex: number) => {
    if (selectedPages.includes(pageIndex)) {
      const indexOfPage = selectedPages.indexOf(pageIndex)
      const updatedPages = [...selectedPages]
      delete updatedPages[indexOfPage]
      setSelectedPages(updatedPages.filter((v) => v === 0 || v))
    } else {
      setSelectedPages([...selectedPages, pageIndex])
    }
  }

  const clearSelectedPages = () => {
    setSelectedPages([])
  }

  const selectAllPages = () => {
    setSelectedPages(new Array(numberOfPages).fill(undefined).map((_, i) => i))
  }

  useEffect(() => {
    const selected = selectedPages.map((page, i) => (
      <Page
        key={`pdf-selection-${i}`}
        className={styles.pdfPage}
        scale={1}
        renderTextLayer={false}
        renderAnnotationLayer={false}
        pageIndex={page}
      />
    ))
    setUserSelectedPages(selected)
  }, [selectedPages])

  const pages = Array(numberOfPages)
    .fill(undefined)
    .map((_, i) => (
      <Page
        key={`pdf-${i}`}
        className={styles.pdfPage}
        pageIndex={i}
        scale={0.4}
        renderTextLayer={false}
        renderAnnotationLayer={false}
        onClick={() => setPages(i)}
      />
    ))

  return (
    <ErrorMonitoring.Boundary fallback={<span>Unable to load document</span>}>
      <div className={styles.pdfContainer}>
        <div className={styles.pdfDocumentContainer}>
          <div className={styles.docHeader}>
            <p>Original Document</p>
          </div>
          <Document
            file={pdfToCreateFrom}
            onLoadSuccess={(pdf) => setNumberOfPages(pdf.numPages)}
            loading={LoadingSpinner}
            noData={LoadingSpinner}
            {...documentProps}
            options={pdfDocumentOptions}
            className={styles.pdfDocument}
          >
            {pages}
          </Document>
        </div>
        <div className={styles.newDocContainer}>
          <div className={styles.docHeader}>
            <p className={styles.newDocTitle}>Your Document</p>
            <AsyncIconButton
              className={tw`mr-[8px]`}
              buttonStyle={'secondary-fill'}
              buttonSize={'small'}
              initialIcon={'fa-check-double'}
              type="button"
              onClick={selectAllPages}
              disabled={selectedPages.length === numberOfPages}
            >
              Select All Pages
            </AsyncIconButton>
            <AsyncIconButton
              buttonStyle={'secondary-fill'}
              buttonSize={'small'}
              initialIcon={'fa-trash'}
              type="button"
              onClick={clearSelectedPages}
              disabled={selectedPages.length === 0}
            >
              Clear Selection
            </AsyncIconButton>
          </div>
          <Document
            file={pdfToCreate}
            loading={LoadingSpinner}
            noData={LoadingSpinner}
            {...documentProps}
            className={styles.pdfDocument}
            options={pdfDocumentOptions}
          >
            {userSelectedPages}
          </Document>
        </div>
      </div>
    </ErrorMonitoring.Boundary>
  )
}

const LoadingSpinner = () => (
  <div className={tw`flex-center`}>
    <BasicSpinner className={tw`fa-3x`} />
  </div>
)
