import { Organization } from '@shared/types/organization'
import { BillingStrategyOptions, FrequencyOptions } from '@shared/utils/payment'
import fieldsForAll from './fieldsForAllUser.json'

export default function getFormConfig({
  isSuperUser,
  organization,
}: {
  isSuperUser: boolean
  organization?: Organization
}) {
  // billingStrategy and paymentFrequency are only available for SuperUser
  // Required when one of them is filled
  if (isSuperUser) {
    const subTitle = organization?.paymentCustomer && 'REQUIRED'

    return [
      ...fieldsForAll,
      {
        id: 'strategySelect',
        instanceId: 'strategySelect',
        title: 'PAYMENT BILLING STRATEGY',
        subTitle,
        name: 'paymentCustomer.billingStrategy',
        type: 'StyledSelect',
        options: BillingStrategyOptions,
        placeholder: 'Select a billing strategy',
        isClearable: true,
      },
      {
        id: 'frequencySelect',
        instanceId: 'frequencySelect',
        title: 'PAYMENT FREQUENCY',
        subTitle,
        name: 'paymentCustomer.paymentFrequency',
        type: 'StyledSelect',
        options: FrequencyOptions,
        placeholder: 'Select a frequency',
        isClearable: true,
      },
    ]
  }

  return fieldsForAll
}
