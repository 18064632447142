import { Immunization } from '@augusthealth/models/com/august/protos/immunization'
import {
  FileSource,
  ProcessingStatus,
} from '@augusthealth/models/com/august/protos/incoming_file'
import { PersonIds } from '@shared/types/person'
import { DataType, Snapshot_Data } from '@shared/types/snapshot'
import { fromDateToDateMessage } from '@shared/utils/date'
import { getTitleFromDataType } from '@shared/utils/task'
import {
  assignFileToResident,
  patchIncomingFile,
} from '@app/api/incoming_files'
import { FormValues } from '@app/pages/Documents/Uploader/Forms'
import { saveImmunizations } from '@app/pages/Documents/Viewer/VaccineViewer/helpers'

export const fileSourceToDisplay = (source?: FileSource) => {
  switch (source) {
    case FileSource.FILE_SOURCE_FAX:
      return 'Fax'
    case FileSource.FILE_SOURCE_EMAIL:
      return 'Email'
    default:
      return 'Not Specified'
  }
}

export const assignIncomingFileToResident = async ({
  formData,
  person,
  dataType,
  fileId,
  originalImmunizations,
}: {
  formData: FormValues
  person: Required<PersonIds>
  dataType: DataType
  fileId: string
  originalImmunizations: Immunization[]
}) => {
  let submissionData: Snapshot_Data = {}
  if (
    dataType === DataType.DATA_TYPE_IMMUNIZATION_RECORD &&
    formData.tag === 'Immunization'
  ) {
    const response = await assignFileToResident({
      formData: submissionData,
      orgId: person.orgId,
      facilityId: person.facilityId,
      personId: person.id,
      dataType,
      fileId,
    })

    const {
      name: diseaseName,
      immunizations: updatedImmunizationsForSelectedDisease,
      newImmunization,
    } = formData

    const updatedImmunizations = [
      ...updatedImmunizationsForSelectedDisease,
      ...originalImmunizations.filter(
        (immu) => immu.protocolApplied?.targetDisease?.text !== diseaseName
      ),
      {
        ...newImmunization,
        attachmentSnapshotId: response.id,
      },
    ]

    await saveImmunizations({
      originalImmunizations,
      updatedImmunizations,
      person,
    })
    return
  } else {
    const readableDataType = getTitleFromDataType(dataType)

    if (formData.tag === 'NamedUpload') {
      submissionData = {
        uploadInfo: {
          name: formData.name?.length > 0 ? formData.name : readableDataType,
        },
      }
    } else if (formData.tag === 'SignedDocument') {
      // TODO: should this handle the logic of 601 or 603 only uploadable with an open task? (see ResidentDocumentUploader/helpers)
      submissionData = {
        uploadInfo: {
          signatureData: {
            dateSigned: fromDateToDateMessage(formData.dateSigned),
          },
        },
      }
    } else if (formData.tag === 'POLST') {
      submissionData = {
        uploadInfo: {
          polst: { cprCode: formData.codeStatus },
        },
      }
    }
  }

  await assignFileToResident({
    formData: submissionData,
    orgId: person.orgId,
    facilityId: person.facilityId,
    personId: person.id,
    dataType,
    fileId,
  })
}

export const ignoreIncomingFile = async ({ orgId, facilityId, fileId }) => {
  const response = await patchIncomingFile({
    patch: {
      processingStatus: ProcessingStatus.PROCESSING_STATUS_IGNORED,
    },
    fileId,
    orgId,
    facilityId,
  })

  return response
}
