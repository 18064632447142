import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import { hasPermissionForOrg } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { BillingCategory, BillingCategoryData } from '@shared/types/billing'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { GroupPermission } from '@shared/types/permission'
import { isEmptyObject } from '@shared/utils/common'
import useBillingCategories from '@app/hooks/useBillingCategories'
import BillingModal from '@app/pages/Facilities/Billing/Modals/components/BillingModal'

type Props = {
  billingCategory: BillingCategory | null
  facility: Facility
  doneFn: (msg: string) => Promise<void>
  closeFn: () => void
  organization: Organization
}

export default function BillingCategoryModal(props: Props) {
  const { user } = useUserContext()
  const { setError } = useContext(GlobalContext)
  const { billingCategory, facility, organization, doneFn, closeFn } = props
  const { orgId } = facility
  const isAddMode = isEmptyObject(billingCategory)
  const hasFullAccess = hasPermissionForOrg({
    user,
    orgId,
    permissions: [GroupPermission.GROUP_PERMISSION_BILLING_V2_WRITE],
  })
  const readOnly = !hasFullAccess
  const { upsertBillingCategoryData } = useBillingCategories({
    orgId,
  })
  const { handleSubmit, register, formState } = useForm<BillingCategoryData>({
    defaultValues: billingCategory?.data ?? {},
  })
  const { errors } = formState

  async function onSubmit(billingCategoryData: BillingCategoryData) {
    try {
      await upsertBillingCategoryData({
        billingCategoryData,
        categoryId: billingCategory?.meta?.id ?? '',
      })
      await doneFn(isAddMode ? 'Category added!' : 'Category updated!')
      closeFn()
    } catch (err) {
      setError(err)
    }
  }

  const title = readOnly
    ? 'View Billing Category'
    : billingCategory?.meta?.id
      ? 'Edit Billing Category'
      : 'Add Billing Category'

  return (
    <BillingModal id="billing-category-modal" title={title} onClose={closeFn}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5">
          <LabelAboveInput
            uppercase={false}
            htmlFor="name"
            subLabel={requiredLabel(Boolean(errors.name))}
          >
            Name
          </LabelAboveInput>
          <BasicInput
            {...register('name', { required: true })}
            disabled={readOnly}
          />
        </div>
        <div className="mt-4">
          <LabelAboveInput uppercase={false} htmlFor="facilityId">
            Community
          </LabelAboveInput>
          <BasicInput
            disabled={true}
            value={`${organization.name} - All Communities`}
          />
        </div>
        <div className="mt-8">
          <AnimatedPopupFormFooter
            yesBtn={{
              hideButton: readOnly,
              label: isAddMode ? 'Add' : 'Edit',
              props: { disabled: readOnly },
            }}
            noBtn={{
              action: closeFn,
              label: readOnly ? 'Close' : 'Cancel',
            }}
            formState={formState}
          />
        </div>
      </form>
    </BillingModal>
  )
}
