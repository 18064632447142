import { SignableForm } from '@augusthealth/models/com/august/protos/signable_form'
import { LightboxSidebarTitle } from '@shared/components/AnimatedPopup/Lightbox/Lightbox'

export default function LightboxTitleHeader(signableForm: SignableForm) {
  const {
    id,
    name,
    fileMetaData = {},
    hellosign = {},
    draftStatus,
  } = signableForm
  const { fileName } = fileMetaData
  const { templateId = '' } = hellosign

  return (
    <LightboxSidebarTitle>
      <div>
        {id}. {name} {fileName}
      </div>
      <div className="text-[12px]">
        <div>{templateId}</div>
        <div>{draftStatus}</div>
      </div>
    </LightboxSidebarTitle>
  )
}
