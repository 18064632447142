import { add, isAfter } from 'date-fns'
import ErrorMonitoring from '@shared/ErrorMonitoring'
import { MedPass } from '@shared/types/api/med_pass'
import { addLeadingZero, isWithinInterval } from '@shared/utils/date'

const ERROR_TITLE_CONTENT_REG = /^(.*): (.*)$/
const TIME_PERIODS_REG =
  /((?:TimePeriod\(Some\(Time\(Some\(\d+\),Some\(\d+\)\)\),Some\(Time\(Some\(\d+\),Some\(\d+\)\)\)\))+)/g
const TIMES_REG = /((?:Time\(Some\(\d+\),Some\(\d+\)\))+)/g
const HOUR_MINUTE_REG = /Time\(Some\((\d+)\),Some\((\d+)\)\)/

/**
 * Return string before ": "
 * @param message {string}
 * @returns "Missing period" | "Overlapping periods"
 */

export function getErrorTitle(message: string) {
  return message.replace(ERROR_TITLE_CONTENT_REG, '$1')
}

export function getErrorMessage(message: string) {
  const errorContent = message.replace(ERROR_TITLE_CONTENT_REG, '$2')
  const periodMatch = errorContent.match(TIME_PERIODS_REG)
  if (periodMatch) {
    return periodMatch
      .map((period) => {
        const timeMatch = period.match(TIMES_REG)
        if (timeMatch) {
          return timeMatch
            .map((time) => {
              const hourMinuteMatch = time.match(HOUR_MINUTE_REG) as string[]
              const hour = hourMinuteMatch[1] || '0'
              const minute = hourMinuteMatch[2] || '0'
              return `${addLeadingZero(parseInt(hour))}:${addLeadingZero(
                parseInt(minute)
              )}`
            })
            .join(' - ')
        }

        return period
      })
      .join(' and ')
  }

  return errorContent
}

const timeFallsWithinMedPass = ({
  date,
  medPass,
}: {
  date: Date
  medPass: MedPass
}) => {
  const startTime = new Date(date)
  if (medPass.period.startTime?.hour !== undefined) {
    startTime.setHours(medPass.period.startTime.hour)
  }
  if (medPass.period.startTime?.minute !== undefined) {
    startTime.setMinutes(medPass.period.startTime.minute)
  }

  let endTime = new Date(date)
  if (medPass.period.endTime?.hour !== undefined) {
    endTime.setHours(medPass.period.endTime.hour)
  }
  if (medPass.period.endTime?.minute !== undefined) {
    endTime.setMinutes(medPass.period.endTime.minute)
  }

  if (isAfter(startTime, endTime)) {
    // set endTime to the next day to maintain correct interval
    endTime = add(endTime, { days: 1 })
  }

  return isWithinInterval(date, {
    start: startTime,
    end: endTime,
  })
}
export const getRelevantMedPassByCurrentTime = (
  medPasses: Array<MedPass>,
  timeZone?: string
): MedPass => {
  const localizedDate = new Date(
    new Date().toLocaleString('en-US', { timeZone: timeZone })
  )

  const matchingMedPass = medPasses.find((medPass) => {
    return timeFallsWithinMedPass({ date: localizedDate, medPass })
  })

  if (!matchingMedPass) {
    ErrorMonitoring.capture({
      error: `Failure to get current med pass by time using date "${localizedDate.toString()}"`,
      level: 'warning',
    })
  }

  return matchingMedPass ?? medPasses[0]
}
