import { EmptyCard } from '@shared/components/EmptyCard'
import {
  CompletionType,
  ResidentCompletionStats,
} from '@shared/types/api/facility_stats'
import { PersonTasks } from '@shared/types/api/person_tasks'
import { CompletionTypeToTemplateHash } from '@shared/utils/facilityStats'
import ResidentCompletionItem from './Item'

export default function ResidentCompletionItems({
  filteredResidentStats,
  visibleTasks,
  personTasks,
  availableCompletionTasks,
  refreshStats,
  completionTypeToTemplateHash,
}: {
  visibleTasks: CompletionType[]
  filteredResidentStats: ResidentCompletionStats[]
  personTasks: PersonTasks[]
  availableCompletionTasks: CompletionType[]
  refreshStats: () => Promise<void>
  completionTypeToTemplateHash: CompletionTypeToTemplateHash
}) {
  if (visibleTasks.length === 0) {
    return (
      <EmptyCard
        title=""
        version={{
          tag: 'Children',
          children: 'Select one or more filters to get started.',
        }}
      />
    )
  }

  if (!filteredResidentStats.length) {
    return (
      <EmptyCard
        title="No residents found."
        version={{
          tag: 'Children',
          children: 'Add a resident to get started',
        }}
      />
    )
  }

  if (visibleTasks.length > 0) {
    return (
      <>
        {filteredResidentStats.map((stat) => (
          <ResidentCompletionItem
            availableCompletionTasks={availableCompletionTasks}
            key={`resident-completion-${stat.person!.id}`}
            completionStats={stat}
            profilePhotoUrl={
              personTasks.find((task) => task.person!.id === stat.person!.id!)
                ?.profilePhotoUrl
            }
            person={stat.person!}
            personTasks={
              personTasks.find(
                (task) => task.person!.id === stat.person!.id!
              ) ?? ({} as PersonTasks)
            }
            visibleItems={visibleTasks}
            refreshStats={refreshStats}
            completionTypeToTemplateHash={completionTypeToTemplateHash}
          />
        ))}
      </>
    )
  }

  return null
}
