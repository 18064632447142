import { AsyncIconButton } from '@shared/components/AsyncButton'
import Card from '@shared/components/Card'
import { MedicationOrder } from '@shared/types/medication_order'
import {
  getMedicationOrderInstructions,
  getMedicationOrderName,
} from '@shared/utils/medicationOrder'
import { tw, twx } from '@shared/utils/tailwind'
import { OrderStatusPill } from '@app/components/Residents/Medications/Orders/OrderStatusPill'
import styles from '@app/components/Residents/Medications/Orders/styles.module.css'
import { WeakMatchOrderFooter } from '@app/components/Residents/Medications/Orders/WeakMatch/WeakMatchOrderFooter'

type WeakMatchModalMode =
  | {
      tag: 'list'
      showOrderInModal: React.Dispatch<
        React.SetStateAction<MedicationOrder | null>
      >
    }
  | {
      tag: 'modal'
    }
type Props = {
  order: MedicationOrder
  mode: WeakMatchModalMode
}
const WeakMatchOrder = ({ order, mode }: Props) => {
  const instructions = getMedicationOrderInstructions(order as MedicationOrder)
  const medicationName = getMedicationOrderName(order as MedicationOrder)

  return (
    <>
      <Card
        className={twx(
          `flex cursor-pointer flex-row items-center justify-between gap-8 border-[1px] border-transparent`,
          { 'hover:border-primary-light': mode.tag === 'list' }
        )}
        onClick={() => {
          mode.tag === 'list' && mode.showOrderInModal(order)
        }}
      >
        <div className={tw`flex flex-col`}>
          <div
            className={twx(
              `flex flex-wrap items-center text-[14px] font-semibold leading-5 text-gray-04`
            )}
          >
            <span className={tw`text-pretty`}>{medicationName}</span>
            <div className={tw`ml-1.5 flex flex-wrap items-center gap-2`}>
              <OrderStatusPill order={order as MedicationOrder} />
            </div>
          </div>
          <div className={styles.medInstructions}>
            {instructions.map((inst, index) => (
              <span
                key={`${order.id}-instruction-${index}`}
                className={tw`block text-pretty`}
              >
                {inst}
              </span>
            ))}
          </div>
          <WeakMatchOrderFooter
            order={order as MedicationOrder}
            renderMode={mode.tag}
          />
        </div>
        {mode.tag === 'list' && (
          <AsyncIconButton
            className={tw`text-nowrap`}
            buttonStyle={'tertiary-outline'}
            buttonSize={'small'}
            onClick={() => mode.showOrderInModal(order)}
          >
            Match Orders
          </AsyncIconButton>
        )}
      </Card>
    </>
  )
}

export default WeakMatchOrder
