import Badge, { BadgeColor } from '@shared/components/Badge'
import { MedicationStatement } from '@shared/types/medication_statement'
import {
  isDiscontinued,
  statementHasPrn,
} from '@shared/utils/medicationStatement'
import styles from './styles.module.css'

const MedBadges = ({ medication }: { medication: MedicationStatement }) => (
  <>
    {statementHasPrn(medication) && badge('darkGray', 'PRN')}
    {medication.isTreatment && badge('darkGray', 'Treatment')}
    {isDiscontinued(medication) && badge('orange', 'Discontinued')}
  </>
)

const badge = (color: BadgeColor, text: string) => (
  <Badge color={color} className={styles.prnBadge}>
    {text}
  </Badge>
)

export default MedBadges
