import { AppraisalSettings_AssessmentType } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import CollapsibleCard from '@shared/components/CollapsibleCard'
import notEmpty from '@shared/utils/notEmpty'
import { AssessmentType } from './AssessmentType'

type Props = {
  assessmentTypes: AppraisalSettings_AssessmentType[]
  setAssessmentTypes: (
    assessmentTypes: AppraisalSettings_AssessmentType[]
  ) => void
}
export const AssessmentTypes = ({
  assessmentTypes,
  setAssessmentTypes,
}: Props) => {
  const saveAssessmentType = (assessType: AppraisalSettings_AssessmentType) => {
    setAssessmentTypes([
      ...assessmentTypes.filter(
        (at) =>
          at !== AppraisalSettings_AssessmentType.ASSESSMENT_TYPE_UNSPECIFIED
      ),
      assessType,
    ])
  }
  const deleteAssessmentType = (
    assessmentType: AppraisalSettings_AssessmentType
  ) => {
    const afterDelete = assessmentTypes.filter((at) => at !== assessmentType)
    setAssessmentTypes(afterDelete)
  }

  const currentAssessmentTypeEnums = Array.from(
    new Set(assessmentTypes)
  ).filter(notEmpty)

  const createEmptyAssessmentType = () => {
    setAssessmentTypes([
      ...assessmentTypes,
      AppraisalSettings_AssessmentType.ASSESSMENT_TYPE_UNSPECIFIED,
    ])
  }

  return (
    <CollapsibleCard cardTitle={'ASSESSMENT TYPES'}>
      {assessmentTypes.length === 0 && (
        <div className={'mb-[8px] text-center text-[14px]'}>
          No assessment types have been specified.
        </div>
      )}
      {assessmentTypes.map((at) => (
        <AssessmentType
          key={at}
          assessType={at}
          deleteType={deleteAssessmentType}
          saveType={saveAssessmentType}
          currentEnums={currentAssessmentTypeEnums}
        />
      ))}
      <AsyncIconButton
        buttonStyle={'tertiary-fill'}
        buttonSize={'small'}
        onClick={createEmptyAssessmentType}
        initialIcon={'fa-solid fa-plus'}
        className={'mt-[16px]'}
      >
        Add Assessment Type
      </AsyncIconButton>
    </CollapsibleCard>
  )
}
