import { PharmacyFacility } from '@shared/types/pharmacy_facility'
import { Order } from '@shared/utils/common'
import { getReadablePharmacyPartnerName } from '@shared/utils/pharmacyPartner'
import { PharmacyIntegrationTableHeader } from '@app/pages/Tools/PharmacyTools/PharmacyIntegration/CurrentIntegrationsTable/CurrentIntegrationsTable'

export const getSortedIntegrations = ({
  integrations,
  column,
  sortOrder,
}: {
  integrations: PharmacyFacility[]
  column: PharmacyIntegrationTableHeader
  sortOrder: Order
}) => {
  if (column === PharmacyIntegrationTableHeader.pharmacyPartner) {
    return integrations.sort((a, b) => {
      const partnerA = getReadablePharmacyPartnerName(a.partner!)
      const partnerB = getReadablePharmacyPartnerName(b.partner!)

      if (sortOrder === Order.ASC) {
        return partnerA.localeCompare(partnerB)
      } else {
        return partnerB.localeCompare(partnerA)
      }
    })
  }

  if (column === PharmacyIntegrationTableHeader.facilityId) {
    return integrations.sort((a, b) => {
      const facilityA = a.facilityId
      const facilityB = b.facilityId

      if (sortOrder === Order.ASC) {
        return facilityA.localeCompare(facilityB)
      } else {
        return facilityB.localeCompare(facilityA)
      }
    })
  }

  if (column === PharmacyIntegrationTableHeader.externalId) {
    return integrations.sort((a, b) => {
      const externalA = a.externalIdentifier
      const externalB = b.externalIdentifier

      if (sortOrder === Order.ASC) {
        return externalA.localeCompare(externalB)
      } else {
        return externalB.localeCompare(externalA)
      }
    })
  }

  return integrations
}
export const filterIntegrationsBySearchTerm = ({
  integrations,
  searchTerm,
}: {
  integrations: PharmacyFacility[]
  searchTerm: string
}): PharmacyFacility[] => {
  return searchTerm
    ? integrations.filter((integration) => {
        const matchOn: string[] = [
          integration.partner!.toLowerCase(),
          getReadablePharmacyPartnerName(integration.partner!).toLowerCase(),
          integration.facilityId!.toLowerCase(),
          integration.externalIdentifier!.toLowerCase(),
        ]
        return matchOn.some((stringMatch) =>
          stringMatch.includes(searchTerm.toLowerCase())
        )
      })
    : integrations
}
