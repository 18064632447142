import { GetTaskDueDateResponse } from '@augusthealth/models/com/august/protos/api/task_due_date'
import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { DataType } from '@shared/types/snapshot'
import { Loading } from '@shared/utils/loading'
import { fetchTaskDueDate } from '../api/tasks'

export default function useAssessmentDueDate({ person }: { person: Person }) {
  const { setError } = useContext(GlobalContext)
  const [assessmentDueDate, setAssessmentDueDate] = useState<
    Loading<GetTaskDueDateResponse>
  >({
    tag: 'Loading',
  })
  const loadTaskDueDate = () => {
    return fetchTaskDueDate({
      person: person,
      dataType: DataType.DATA_TYPE_AUGUST_INITIAL_APPRAISAL,
    })
      .then((value) => setAssessmentDueDate({ tag: 'Complete', value }))
      .catch(setError)
  }

  useEffect(() => {
    void loadTaskDueDate()
  }, [person.id])

  return { assessmentDueDate, refreshAssessmentDueDate: loadTaskDueDate }
}
