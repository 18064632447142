import { pickBy, get as safeGet } from 'lodash'
import { useEffect } from 'react'
import safeJsonStringify from 'safe-json-stringify'
import ErrorMonitoring, { ErrorLevel } from '@shared/ErrorMonitoring'
import { ApiError, ErrorCode } from '@shared/types/api/error_response'
import { UserAccount } from '@shared/types/user'
import {
  AugustError,
  generateCloudwatchUrl,
  singleServerErrorWithMessage,
  WARNING_LEVEL_INTERNAL_CODES,
} from '@shared/utils/error'

export default function useErrorLogging({
  user,
  error,
}: {
  user?: UserAccount
  error: AugustError | ApiError | TypeError | undefined
}) {
  useEffect(() => {
    const tags = {}
    tags['user_id'] = user?.id

    if (error instanceof TypeError) {
      return ErrorMonitoring.capture({
        error,
        level: 'error',
        tags,
      })
    }

    const { json } = error || {}
    const errs = json && json.errors ? json.errors : []
    const errsFiltered = errs.filter(
      (e) => e.code === ErrorCode.ERROR_CODE_DISPLAY_TO_CLIENT
    )

    if (error) {
      if (error.status || error.json || 'internalCode' in error) {
        // server error

        // Set Warning instead of Error if all errors are ERROR_CODE_DISPLAY_TO_CLIENT
        // or if we are showing an error that is already surfaced to the user
        let severity: ErrorLevel = 'error'
        if (
          (errs.length > 0 && errs.length === errsFiltered.length) ||
          ('internalCode' in error &&
            error.internalCode &&
            WARNING_LEVEL_INTERNAL_CODES.includes(error.internalCode))
        ) {
          severity = 'warning'
        }
        let augmentedError: unknown = undefined
        if (error instanceof ApiError) {
          augmentedError = error
        } else {
          const message = singleServerErrorWithMessage(error.json)
          augmentedError = message
            ? new Error(message)
            : new Error(
                error.json
                  ? safeJsonStringify(error.json)
                  : `Unknown error with status ${error.status}`
              )
        }

        const extras = {
          code: safeGet(error, 'json?.errors?.[0]?.code'),
          message: safeGet(error, 'json?.errors?.[0]?.message'),
          data: safeGet(error, 'json?.errors?.[0]?.data'),
          status_code: error.status?.toString(),
        }

        if (error.requestId) {
          tags['request_id'] = error.requestId
          extras['cloudwatch_url'] = generateCloudwatchUrl(error.requestId)
        }
        if (error.status) {
          extras['status_code'] = error.status.toString()
        }

        ErrorMonitoring.capture({
          level: severity,
          error: augmentedError,
          tags,
          extras: pickBy(extras) as Record<string, string>,
        })
      } else {
        ErrorMonitoring.capture({ error, tags })
      }
    }
  }, [error, user])
}
