import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router-dom'
import { useUserContext } from '@shared/contexts/UserContext'
import {
  canViewCommunityBilling,
  canViewResidentBilling,
} from '@shared/utils/billing'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import usePerson from '@app/hooks/usePerson'
import CommunityBilling from './CommunityBilling'
import ResidentBilling from './ResidentBilling'

export default function BillingDecider() {
  const { id, facilityId, orgId } = useParams<{
    id: string
    facilityId: string
    orgId: string
  }>()

  const { person: personPromise } = usePerson({
    initialData: {
      orgId,
      facilityId,
      id,
    },
    dependencies: [id, facilityId, orgId],
  })
  const { currentFacility: currentFacilityPromise } = useCurrentFacility()
  const { user } = useUserContext()

  if (!currentFacilityPromise) {
    return null
  }
  if (
    personPromise.tag === 'Loading' ||
    currentFacilityPromise.tag === 'Loading'
  ) {
    return <Skeleton height={300} />
  }

  const person = personPromise.value
  const facility = currentFacilityPromise.value

  if (canViewResidentBilling({ user, person, facility })) {
    return <ResidentBilling />
  }

  if (canViewCommunityBilling({ user, person })) {
    return <CommunityBilling />
  }

  return null
}
