import classNames from 'classnames/bind'
import {
  components,
  GroupBase,
  MultiValueGenericProps,
  OptionProps,
  SingleValueProps,
} from 'react-select'
import selectStyles from '@app/pages/Tools/SharedSelects/base/select.module.css'
import styles from './styles.module.css'

const cx = classNames.bind(selectStyles)

export const SingleValue = ({
  children,
  ...rest
}: SingleValueProps<unknown, boolean, GroupBase<unknown>>) => {
  // @ts-expect-error
  const label = children.displayName
  return <components.SingleValue {...rest}>{label}</components.SingleValue>
}

export const MultiValueLabel = ({
  children,
  ...rest
}: MultiValueGenericProps<unknown, boolean, GroupBase<unknown>>) => {
  // @ts-expect-error
  const label = children.displayName
  return (
    <components.MultiValueLabel {...rest}>{label}</components.MultiValueLabel>
  )
}

export function FieldOptionComponent({
  // @ts-ignore
  children: { description, displayName },
  innerRef,
  innerProps,
  isDisabled,
  isSelected,
}: OptionProps) {
  const icon = cx({
    ['fa-check']: isSelected,
  })
  const selectedClass = cx({
    [selectStyles.selected]: isSelected,
  })
  const holderClass = cx({
    [selectStyles.optionContainer]: true,
    ['mute-text']: isDisabled,
  })

  return (
    <div ref={innerRef} {...innerProps} className={holderClass}>
      <div className={selectStyles.labelContainer}>
        <i className={`far fa-fw ${icon}`} />
        <span className={`${selectStyles.label} ${selectedClass}`}>
          {displayName}
        </span>
        {description && (
          <div className={`text-gray-08 ${styles.tiny}`}>{description}</div>
        )}
      </div>
      <div className={selectStyles.divider} />
    </div>
  )
}
