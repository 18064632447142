import { Immunization } from '@augusthealth/models/com/august/protos/immunization'
import { Person } from '@shared/types/person'
import { DataType } from '@shared/types/snapshot'
import { getTargetDiseaseName } from '@shared/utils/immunizations'
import { getSnapshotName } from '@shared/utils/snapshot'
import { getImmunizationLabel } from '@app/components/Prospects/Details/Vaccinations/helpers'
import AssessmentViewer from '@app/pages/Documents/Viewer/AssessmentViewer/AssessmentViewer'
import VaccineViewer from '@app/pages/Documents/Viewer/VaccineViewer'
import { RoutableDocument } from '../helpers'
import DocumentCard from './DocumentCard'
import GettingToKnowYouCard from './GettingToKnowYou'
import GettingToKnowYouV2Card from './GettingToKnowYouV2'
import { generateCompletionText } from './helpers'
import LevelOfCareCard from './LevelOfCare'
import NamedCard from './Named'
import POLSTCard from './POLST'
import SignedDocumentCard from './SignedDocument'

interface Props {
  animationStyle: React.CSSProperties
  document: RoutableDocument
  person: Person
  onUpdate: () => Promise<void>
  immunizations: Immunization[]
}

export default function CardProducer({
  animationStyle,
  document,
  person,
  onUpdate,
  immunizations,
}: Props) {
  const title = getSnapshotName(document, '') as string
  const subTitle = document.snapshotDetail?.shortName

  switch (document.dataType!) {
    case DataType.DATA_TYPE_GETTING_TO_KNOW_YOU:
      return (
        <GettingToKnowYouCard
          title={title}
          current={document}
          person={person}
          onUpdate={onUpdate}
          animationStyle={animationStyle}
        />
      )
    case DataType.DATA_TYPE_GETTING_TO_KNOW_YOU_V2:
      return (
        <GettingToKnowYouV2Card
          title={title}
          current={document}
          person={person}
          animationStyle={animationStyle}
        />
      )
    case DataType.DATA_TYPE_CA_FORM_601:
    case DataType.DATA_TYPE_ADMISSIONS_AGREEMENT:
    case DataType.DATA_TYPE_CA_CONSENT_FORMS:
    case DataType.DATA_TYPE_CA_FORM_603:
    case DataType.DATA_TYPE_CA_FORM_603A:
    case DataType.DATA_TYPE_SERVICE_PLAN:
    case DataType.DATA_TYPE_CA_FORM_602:
    case DataType.DATA_TYPE_PRN_AUTHORIZATION:
    case DataType.DATA_TYPE_NURSE_DELEGATION:
    case DataType.DATA_TYPE_VA_UAI: {
      const completionText = generateCompletionText(
        document.data?.uploadInfo !== undefined
      )

      return (
        <SignedDocumentCard
          person={person}
          document={document}
          completionText={completionText}
          title={title}
          subtitle={subTitle}
          animationStyle={animationStyle}
          onUpdate={onUpdate}
        />
      )
    }
    case DataType.DATA_TYPE_LEVEL_OF_CARE_CIMINO:
      return (
        <LevelOfCareCard
          title={title}
          current={document}
          person={person}
          animationStyle={animationStyle}
        />
      )
    case DataType.DATA_TYPE_INSURANCE_CARD:
    case DataType.DATA_TYPE_MEDICAL_RECORD:
    case DataType.DATA_TYPE_OTHER_UPLOAD:
    case DataType.DATA_TYPE_DIRECTIVE:
      return (
        <NamedCard
          title={title}
          person={person}
          document={document}
          onUpdate={onUpdate}
          animationStyle={animationStyle}
        />
      )
    case DataType.DATA_TYPE_POLST:
      return (
        <POLSTCard
          currentPOLST={document}
          person={person}
          onUpdate={onUpdate}
          animationStyle={animationStyle}
        />
      )
    case DataType.DATA_TYPE_CUSTOM_SIGNABLE_FORM:
      return (
        <SignedDocumentCard
          person={person}
          document={document}
          completionText={'Completed'}
          title={title}
          subtitle={subTitle}
          onUpdate={onUpdate}
          animationStyle={animationStyle}
        />
      )
    case DataType.DATA_TYPE_AUGUST_INITIAL_APPRAISAL:
      return (
        <DocumentCard
          document={document}
          icon="fa-file-alt"
          title={title}
          person={person}
          completionText="Completed"
          viewer={(closeFn) => {
            return (
              <AssessmentViewer
                person={person}
                assessment={document}
                mode={{
                  tag: 'view',
                  onClose: closeFn,
                }}
              />
            )
          }}
          animationStyle={animationStyle}
        />
      )
    case DataType.DATA_TYPE_IMMUNIZATION_RECORD: {
      const immunization = immunizations.find(
        (i) => i.attachmentSnapshotId === document.id
      )
      if (immunization) {
        const diseaseName = getTargetDiseaseName(immunization)
        if (diseaseName) {
          return (
            <DocumentCard
              document={document}
              icon="fa-syringe"
              title={getImmunizationLabel(immunization)}
              completionText="Uploaded"
              viewer={(closeFn) => (
                <VaccineViewer
                  diseaseName={diseaseName}
                  immunizations={immunizations}
                  document={document}
                  person={person}
                  onUpdate={async () => await onUpdate()}
                  onClose={closeFn}
                />
              )}
              person={person}
              animationStyle={animationStyle}
            />
          )
        }
      }

      return null
    }
    default:
      return (
        <NamedCard
          person={person}
          document={document}
          onUpdate={onUpdate}
          animationStyle={animationStyle}
        />
      )
  }
}
