import { Charge } from '@augusthealth/models/com/august/protos/charge'
import { requestJson } from '@shared/api/request'
import {
  apiChargesUrl,
  apiOneTimeChargeUrl,
  apiRecurringChargesUrl,
  apiRecurringChargeUrl,
} from '@shared/legacy_routes'
import { Person } from '@shared/types/person'

interface CreateChargeResponse {
  id: number
}

export async function createOneTimeCharge(
  person: Person,
  charge: Charge
): Promise<CreateChargeResponse> {
  const url = apiChargesUrl(person as Required<Person>)
  return createCharge(url, charge)
}

export async function createRecurringCharge(
  person: Person,
  charge: Charge
): Promise<CreateChargeResponse> {
  const url = apiRecurringChargesUrl(person as Required<Person>)
  return createCharge(url, charge)
}

export async function updateRecurringCharge(
  person: Person,
  charge: Charge
): Promise<'OK'> {
  const url = apiRecurringChargeUrl(charge.id!, person as Required<Person>)
  await requestJson({
    url,
    method: 'PUT',
    body: JSON.stringify(charge),
  })

  return 'OK'
}

async function createCharge(
  url: string,
  charge: Charge
): Promise<CreateChargeResponse> {
  const responseJson = await requestJson({
    url,
    method: 'POST',
    body: JSON.stringify(charge),
  })

  return responseJson.data
}

export async function getRecurringCharges(person: Person) {
  const url = apiRecurringChargesUrl(person as Required<Person>)
  const responseJson = await requestJson({ url })

  return responseJson.data
}

export async function getOnetimeCharges(person: Person) {
  const url = apiChargesUrl(person as Required<Person>)
  const responseJson = await requestJson({ url, method: 'GET' })

  return responseJson.data
}

export async function deleteRecurringCharge(
  chargeId: string,
  person: Person
): Promise<'OK'> {
  const url = apiRecurringChargeUrl(chargeId, person as Required<Person>)
  await requestJson({ url, method: 'DELETE' })

  return 'OK'
}

export async function deleteOneTimeCharge(
  chargeId: string,
  person: Person
): Promise<'OK'> {
  const url = apiOneTimeChargeUrl(chargeId, person as Required<Person>)
  await requestJson({ url, method: 'DELETE' })

  return 'OK'
}
