import { ErrorCode } from '@augusthealth/models/com/august/protos/api/error_response'
import {
  Incident,
  IncidentAction,
  IncidentActionStatus,
  IncidentActionType,
  IncidentLocation,
  IncidentStatus,
  IncidentType,
} from '@shared/types/incidents'
import { Person } from '@shared/types/person'
import { Snapshot } from '@shared/types/snapshot'
import {
  formatTime,
  fromDateMessageToDate,
  fromDateToDateMessage,
  fromTimeStringToTime,
} from '@shared/utils/date'
import { AugustError } from '@shared/utils/error'
import { customIncidentActionIdentifier } from '@shared/utils/incident'
import { AsyncResult } from '@shared/utils/loading'
import notEmpty from '@shared/utils/notEmpty'
import {
  createIncident,
  createIncidentAttachment,
  updateIncident,
} from '@app/api/incidents'
import { IncidentAttachmentType } from '../IncidentAttachments'

export type IncidentFormData = {
  notes: string
  date: Date | null
  time: string | null
  location: string | null
  otherLocation: string | null
  injuries: string
  incidentTypes: IncidentType[]
  followUpActions: IncidentActionType[]
  /**
   * These are special in that they're configurable, unlike the follow-up actions
   * above. They still get sent to the backend as a list of IncidentActions.
   *
   * This value here is a list of customType parameters from the Incident Action
   * See useIncidentActionTemplates for more info.
   */
  customFollowUpActions: {
    [key: string]: string | false
  }
  followUpOther: string | null
  attachments?: IncidentAttachmentType[]
}

export function toBackendIncident({
  formData,
  incident,
  actionTemplates,
}: {
  formData: IncidentFormData
  incident: Incident | undefined
  actionTemplates: IncidentAction[]
}): Incident {
  const time = formData.time ? fromTimeStringToTime(formData.time) : null
  const incidentActions: IncidentAction[] = formData.followUpActions.map(
    (action) => {
      const initialMatching = (
        incident?.detail?.incidentDetail?.incidentActions ?? []
      ).find((initialAction) => initialAction.type === action)

      if (action === IncidentActionType.INCIDENT_ACTION_TYPE_OTHER) {
        return {
          type: action,
          otherType: formData.followUpOther ?? '',
          status:
            initialMatching?.status ??
            IncidentActionStatus.INCIDENT_ACTION_STATUS_OPEN,
        }
      }

      return {
        type: action,
        status:
          initialMatching?.status ??
          IncidentActionStatus.INCIDENT_ACTION_STATUS_OPEN,
      }
    }
  )

  const specialActions = actionTemplates.filter((action) =>
    Object.values(formData.customFollowUpActions).some(
      (sfa) => sfa === customIncidentActionIdentifier(action)
    )
  )

  return {
    detail: {
      incidentDetail: {
        note: formData.notes,
        types: formData.incidentTypes,
        injuries: {
          note: formData.injuries,
        },
        location: formData.location as IncidentLocation,
        // @ts-ignore - We send nulls to mark the key as 'deletable'
        otherLocation: formData.otherLocation ?? null,
        status:
          incident?.detail?.incidentDetail?.status ??
          IncidentStatus.INCIDENT_STATUS_OPEN,
        incidentActions: [...incidentActions, ...specialActions],
      },
    },
    occurredAt: {
      date: fromDateToDateMessage(formData.date!),
      // @ts-ignore - We send nulls to mark the key as 'deletable'
      time: time ?? null,
    },
  }
}

export function toDefaultValues(incident: Incident | undefined) {
  return {
    attachments: incident?.attachments?.map((a) => ({
      attachmentId: a.id,
      file: {
        name: a.fileMetaData?.fileName,
      } as File,
      attachmentType: {
        value: a.data?.uploadInfo?.name,
        label: a.data?.uploadInfo?.name,
      },
    })),
    notes: incident?.detail?.incidentDetail?.note ?? '',
    date: fromDateMessageToDate(incident?.occurredAt?.date) ?? null,
    location: incident?.detail?.incidentDetail?.location ?? null,
    time: incident?.occurredAt?.time
      ? formatTime(incident.occurredAt.time, { use24HourClock: true })
      : null,
    otherLocation: incident?.detail?.incidentDetail?.otherLocation ?? '',
    injuries: incident?.detail?.incidentDetail?.injuries?.note ?? '',
    incidentTypes: incident?.detail?.incidentDetail?.types ?? [],
    followUpActions: (incident?.detail?.incidentDetail?.incidentActions ?? [])
      .filter((ia) => customIncidentActionIdentifier(ia) === undefined)
      .map((ia) => ia.type)
      .filter(notEmpty),
    customFollowUpActions: (
      incident?.detail?.incidentDetail?.incidentActions ?? []
    ).reduce(
      (accum, el) => {
        const customActionIdentifier = customIncidentActionIdentifier(el)

        if (customActionIdentifier) {
          return { ...accum, [customActionIdentifier]: customActionIdentifier }
        }

        return accum
      },
      {} as { [key: string]: string }
    ),
    followUpOther:
      (incident?.detail?.incidentDetail?.incidentActions ?? []).find(
        (ia) => ia.type === IncidentActionType.INCIDENT_ACTION_TYPE_OTHER
      )?.otherType ?? null,
  }
}

export const locations = [
  { label: 'Room', value: IncidentLocation.INCIDENT_LOCATION_ROOM },
  { label: 'Bathroom', value: IncidentLocation.INCIDENT_LOCATION_BATHROOM },
  {
    label: 'Shower / Bathtub',
    value: IncidentLocation.INCIDENT_LOCATION_SHOWER_BATHTUB,
  },
  {
    label: 'Common Area',
    value: IncidentLocation.INCIDENT_LOCATION_COMMON_AREA,
  },
  {
    label: 'Dining Room',
    value: IncidentLocation.INCIDENT_LOCATION_DINING_ROOM,
  },
  {
    label: 'Activity Room',
    value: IncidentLocation.INCIDENT_LOCATION_ACTIVITY_ROOM,
  },
  {
    label: 'Beauty Salon',
    value: IncidentLocation.INCIDENT_LOCATION_BEAUTY_SALON,
  },
  { label: 'Lobby', value: IncidentLocation.INCIDENT_LOCATION_LOBBY },
  { label: 'Hallway', value: IncidentLocation.INCIDENT_LOCATION_HALLWAY },
  { label: 'Other', value: IncidentLocation.INCIDENT_LOCATION_OTHER },
]

export async function persistIncident({
  formData,
  incident,
  person,
  actionTemplates,
}: {
  formData: IncidentFormData
  incident: Incident | undefined
  person: Person
  actionTemplates: IncidentAction[]
}) {
  const formIncident = toBackendIncident({
    formData,
    incident,
    actionTemplates,
  })

  let incidentId: string

  if (incident) {
    incidentId = incident.id!
    await updateIncident({
      person,
      incident: { ...formIncident, id: incident.id },
    })
  } else {
    const response = await createIncident({
      person,
      incident: formIncident,
    })

    incidentId = response.id?.toString()
  }

  return { incidentId }
}
export async function createAttachments({
  incidentId,
  attachments,
  person,
}: {
  incidentId: string
  attachments: IncidentAttachmentType[]
  person: Person
}): Promise<AsyncResult<{ id: number }, { fileName: string }>[]> {
  async function wrappedResponse(
    attachment: IncidentAttachmentType
  ): Promise<AsyncResult<{ id: number }, { fileName: string }>> {
    try {
      const result = await createIncidentAttachment({
        person,
        incidentId,
        attachment,
      })
      return { tag: 'Complete', value: result }
    } catch (e) {
      return {
        tag: 'Error',
        value: { fileName: attachment.file.name ?? '' },
      }
    }
  }

  return Promise.all(attachments.map((a) => wrappedResponse(a)))
}

export function getAttachmentError(failedAttachments: any): AugustError {
  const fileNames = failedAttachments.map(
    (r: { value: { fileName: string } }) => r.value.fileName
  )

  return {
    name: 'AttachmentError',
    message: '',
    json: {
      errors: [
        {
          code: ErrorCode.ERROR_CODE_DISPLAY_TO_CLIENT,
          message: `There was a problem with attachments ${fileNames.join(
            ', '
          )}. Please try again, or contact us for help.`,
        },
      ],
    },
  }
}

export function selectNewOrEditedAttachments({
  incidentAttachments,
  newAttachments,
}: {
  incidentAttachments: Snapshot[] | undefined
  newAttachments: IncidentAttachmentType[]
}) {
  if (!incidentAttachments) {
    return newAttachments
  }

  return newAttachments.filter((na) => {
    const { file, attachmentType } = na
    const unchangedAttachment = incidentAttachments.find(
      (ia) =>
        ia?.data?.uploadInfo?.name === attachmentType?.value &&
        ia?.fileMetaData?.fileName === file?.name
    )

    if (unchangedAttachment) {
      return false
    }

    return na
  })
}

export function isIncidentTimeRequired(incident: Incident | undefined) {
  const hasTimeAlready = Boolean(incident?.occurredAt?.time?.hour)
  const isNewIncident = incident?.id === undefined

  return hasTimeAlready || isNewIncident
}
