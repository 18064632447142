import { get } from 'lodash'
import { useEffect } from 'react'
import { FieldPath, useFormContext } from 'react-hook-form'
import {
  BasicInput,
  BasicInputProps,
} from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredWhenError } from '@shared/components/Labels'
import { tw, twx } from '@shared/utils/tailwind'
import { IntegrationsForm } from '@app/pages/Tools/PharmacyTools/PharmacyIntegration/helpers'

export const LabelWithInput = ({
  field,
  className,
}: {
  field: Omit<BasicInputProps, 'name'> & { name: FieldPath<IntegrationsForm> }
  className?: string
}) => {
  const { formState, register, setValue } = useFormContext<IntegrationsForm>()
  useEffect(() => {
    if ('value' in field && field.value) {
      // @ts-ignore
      setValue(field.name, field.value ?? null)
    }
  }, [field.value])

  const hasError = get(formState.errors, field.name)

  const { required, readOnly, name, label, ...rest } = field
  return (
    <div className={twx(className)}>
      <LabelAboveInput
        htmlFor={name}
        className={tw`mb-2`}
        subLabel={hasError ? requiredWhenError(true) : undefined}
      >
        {label}
      </LabelAboveInput>
      <BasicInput
        {...register(name, {
          required: required,
        })}
        {...rest}
        id={name}
        readOnly={readOnly ?? false}
        showErrorBorder={!!hasError}
      />
    </div>
  )
}
