import {
  MenuButton as BaseMuiMenuButton,
  MenuItem as BaseMuiMenuItem,
} from '@mui/base'
import { Dropdown as BaseMuiDropdown } from '@mui/base/Dropdown'
import { Menu as BaseMuiMenu } from '@mui/base/Menu'
import { prepareForSlot } from '@mui/base/utils'
import { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { Button, ButtonProps } from '@shared/components/baseMui/Button'
import { twx } from '@shared/utils/tailwind'

export type MenuOption = {
  onClick: React.MouseEventHandler<HTMLElement>
  label: React.ReactNode
  className?: string
  disabled?: boolean
}
type MenuProps = {
  anchor?: 'bottom-right' | 'bottom-left' | 'top-right' | 'top-left'
  children: React.ReactNode
  options: MenuOption[]
  id: string
  wrapperClassName?: string
}
/**
 * @param id - the ID of the menu - will be applied to the testID attribute
 * @param anchor - the corner of the trigger button that the menu will align with
 * @param children - the contents of the trigger button that will hide/show the menu
 * @param options - an array of options to be shown in the menu
 * @param wrapperClassName - class to apply to the wrapping span
 */
export const Menu = ({
  anchor = 'bottom-right',
  children,
  options,
  id,
  wrapperClassName,
}: MenuProps) => {
  const [buttonId] = useState(uuid())
  const [bottomButtonId] = useState(`${buttonId}-bottom`)
  const [menuId] = useState(id)

  return (
    <span className={twx(wrapperClassName)}>
      <BaseMuiDropdown>
        <span id={buttonId} className={twx('block h-[1px] w-full')} />
        <MenuTriggerButton>{children}</MenuTriggerButton>
        <span id={bottomButtonId} className={twx('block h-[1px] w-full')} />
        <BaseMuiMenu
          data-testid={menuId}
          id={menuId}
          anchor={{
            nodeType: 1,
            getBoundingClientRect: () => {
              const relevantElement = anchor.startsWith('bottom')
                ? bottomButtonId
                : buttonId
              const boundingRect = document
                .getElementById(relevantElement)
                ?.getBoundingClientRect() as DOMRect

              const menuRect = document
                .getElementById(menuId)
                ?.getBoundingClientRect() as DOMRect

              return getAnchorPositioning({
                anchorPosition: anchor,
                boundingRect,
                menuRect,
              })
            },
          }}
          className={twx(
            'flex w-[160px] min-w-fit gap-2 rounded-[4px] bg-white shadow-card [&>ul]:w-full'
          )}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          {options.map((opt, i) => {
            return (
              <MenuItem
                key={i}
                className={twx(opt.className)}
                onClick={opt.onClick}
                disabled={opt.disabled}
              >
                {opt.label}
              </MenuItem>
            )
          })}
        </BaseMuiMenu>
      </BaseMuiDropdown>
    </span>
  )
}

const getAnchorPositioning = ({
  anchorPosition,
  boundingRect,
  menuRect,
}: {
  anchorPosition: MenuProps['anchor']
  boundingRect: DOMRect
  menuRect: DOMRect
}) => {
  const extraPadding = 4
  switch (anchorPosition) {
    case 'top-left':
      return new DOMRect(
        boundingRect.x + boundingRect.width * 1.5,
        boundingRect.y - menuRect.height - extraPadding,
        boundingRect.width,
        boundingRect.height
      )
    case 'top-right':
      return new DOMRect(
        boundingRect.x - boundingRect.width * 1.5,
        boundingRect.y - menuRect.height - extraPadding,
        boundingRect.width,
        boundingRect.height
      )
    case 'bottom-left':
      return new DOMRect(
        boundingRect.x + boundingRect.width * 1.5,
        boundingRect.y + extraPadding,
        boundingRect.width,
        boundingRect.height
      )
    case 'bottom-right':
    default:
      return new DOMRect(
        boundingRect.x - boundingRect.width * 1.5,
        boundingRect.y + extraPadding,
        boundingRect.width,
        boundingRect.height
      )
  }
}

type MenuTriggerProps = {
  triggerButtonProps?: ButtonProps
  children: React.ReactNode
}
const MenuTriggerButton = ({
  triggerButtonProps = {},
  children,
}: MenuTriggerProps) => {
  const { className, ...rest } = triggerButtonProps
  return (
    <>
      {/* @ts-expect-error this allows us to use Button props*/}
      <BaseMuiMenuButton<typeof Button, ButtonProps>
        slots={{
          root: prepareForSlot(Button),
        }}
        buttonStyle={'tertiary-outline'}
        className={twx(`px-3 py-2`, className)}
        {...rest}
      >
        {children}
      </BaseMuiMenuButton>
    </>
  )
}
const MenuItem = ({
  className,
  onClick,
  children,
  disabled = false,
}: {
  className?: string
  onClick: React.MouseEventHandler<HTMLElement>
  children: React.ReactNode
  disabled?: boolean
}) => {
  const itemClasses = twx(
    'w-full text-left text-gray-01 font-medium py-2 px-3 border-b border-secondary-13',
    'text-[14px] leading-[16px] cursor-pointer first:rounded-t-[4px] last:rounded-b-[4px] last:border-transparent',
    {
      '[&>*]:text-gray-09 hover:cursor-not-allowed': disabled,
      'hover:bg-[#f0edeb]': !disabled,
    },
    className
  )
  return (
    <BaseMuiMenuItem
      className={twx(itemClasses)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </BaseMuiMenuItem>
  )
}
