import { useContext, useEffect, useState } from 'react'
import { getPayerUserTransactions } from '@shared/api/billing'
import GlobalContext from '@shared/contexts/GlobalContext'
import { PayerBillingEvent } from '@shared/types/billing'
import { loaded, LOADING, Loading } from '@shared/utils/loading'

type Props = {
  orgId: string
  facilityId: string
  personId: string
}

export default function useBillingPayerUserTransactions(props: Props) {
  const [billingPayerUserTransactions, setBillingPayerUserTransactions] =
    useState<Loading<PayerBillingEvent[]>>(LOADING)
  const { setError } = useContext(GlobalContext)

  async function reloadBillingPayerUserTransactions() {
    try {
      const result = await getPayerUserTransactions(props)
      setBillingPayerUserTransactions(loaded(result))
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    void reloadBillingPayerUserTransactions()
  }, [])

  return {
    billingPayerUserTransactions,
    reloadBillingPayerUserTransactions,
  }
}
