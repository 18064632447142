import { useContext } from 'react'
import { LightboxSidebarTitle } from '@shared/components/AnimatedPopup/Lightbox/Lightbox'
import CurrentFacilityContext, {
  TimeZoneConvertFunc,
} from '@shared/contexts/CurrentFacilityContext'
import { snapshotFileUrl } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { DataType, Signer, Snapshot } from '@shared/types/snapshot'
import { formatDate, formatDateMessage } from '@shared/utils/date'
import { getFullName, getLastName } from '@shared/utils/humanName'
import { wasCompletedWithoutAllSignatures } from '@shared/utils/snapshot'
import { SignerRow } from '@app/components/SignatureFlow/Components'
import useBlobData from '@app/hooks/useBlobData'
import styles from '@app/pages/Documents/Viewer/styles.module.css'
import useDeletable from '@app/pages/Documents/Viewer/useDeletable'
import { spaceFree } from './helpers'
import Viewer from './index'

interface Props {
  person: Person
  onComplete: (updated: boolean) => Promise<void>
  document: Snapshot
  title: string
}

export default function SignedDocViewer({
  document,
  onComplete,
  person,
  title,
}: Props) {
  const { convertToFacilityTimezone } = useContext(CurrentFacilityContext)
  const params = document.customType ? { customType: document.customType } : {}
  const pdfPath = snapshotFileUrl({
    personId: person.id!,
    orgId: person.orgId!,
    dataType: document.dataType!,
    snapshotId: document.id!,
    params,
  })

  const blobData = useBlobData(pdfPath)
  const filename = spaceFree(`${getLastName(person.name)} ${title}`)
  const signers = getSigners(document, convertToFacilityTimezone)

  const { deletable } = useDeletable({
    document,
    onComplete,
    person,
  })
  return (
    <Viewer
      customType={document.customType}
      dataType={document.dataType!}
      deletable={deletable}
      blobData={blobData}
      filename={filename}
      onClose={() => onComplete(false)}
    >
      <LightboxSidebarTitle>{title}</LightboxSidebarTitle>
      <div className={styles.formContainer}>
        {signers.length > 0 &&
          signers.map((signer, index) => (
            <SignerRowInReviewer signer={signer} key={`signer-${index}`} />
          ))}
      </div>
    </Viewer>
  )
}

type SignerData = {
  signedAt: string
  name: string
  actionLabel?: string
  reason?: string
  state: 'Signed' | 'Completed'
}

function mapSigners(signer: Signer): SignerData {
  return {
    name: getFullName(signer.name),
    signedAt: formatDate(signer.signedAt) || '',
    state: 'Signed',
  }
}

const getSigners = (
  document: Snapshot,
  timezoneConverter: TimeZoneConvertFunc
): SignerData[] => {
  const { data, lastModification, signatureDetails, snapshotDetail } = document
  const { modifiedByUserName, modificationTime } = lastModification || {}
  const { note } = snapshotDetail || {}
  const signers = signatureDetails?.signers

  if (signers?.length) {
    if (wasCompletedWithoutAllSignatures(document)) {
      const signersWithSignedAt = signers
        .filter((s) => s.signedAt)
        .map(mapSigners)
      if (
        signersWithSignedAt.length === 1 &&
        modifiedByUserName &&
        modificationTime
      ) {
        signersWithSignedAt.push({
          name: getFullName(modifiedByUserName),
          signedAt: formatDate(timezoneConverter(modificationTime)) || '',
          actionLabel: 'Marked as done on',
          reason: note,
          state: 'Completed',
        })
      }
      return signersWithSignedAt
    } else {
      return signers.map(mapSigners)
    }
  } else if (data?.uploadInfo?.signatureData) {
    return [
      {
        name:
          document.dataType === DataType.DATA_TYPE_CA_FORM_602
            ? 'Physician Signature'
            : 'In-Person Signature',
        signedAt: data.uploadInfo.signatureData.dateSigned
          ? formatDateMessage(data.uploadInfo.signatureData.dateSigned)
          : 'Unknown',
        state: 'Signed',
      },
    ]
  } else {
    return []
  }
}

function SignerRowInReviewer({ signer }: { signer: SignerData }) {
  const { actionLabel, name, reason, signedAt, state } = signer
  return (
    <SignerRow
      title={name}
      subTitle={`${actionLabel ? actionLabel : 'Signed on'} ${signedAt}`}
      state={state}
      additionalContent={
        reason ? (
          <div
            className="mt-[8px] text-[14px] font-medium text-gray-07"
            style={{ maxWidth: '272px' }}
          >
            Reason: {reason}
          </div>
        ) : undefined
      }
    />
  )
}
