import { SignableForm } from '@augusthealth/models/com/august/protos/signable_form'
import { useEffect, useState } from 'react'
import { Person } from '@shared/types/person'
import { DataType } from '@shared/types/snapshot'
import { AsyncResult } from '@shared/utils/loading'
import { getForm, getTemplate } from '../api/form'

export default function useTemplate({
  dataType,
  person,
  customType,
}: {
  dataType: DataType
  person: Person
  customType?: string
}) {
  const [template, setTemplate] = useState<
    AsyncResult<SignableForm, 'Not Found'>
  >({
    tag: 'Loading',
  })

  useEffect(() => {
    getTemplate({
      orgId: person.orgId!,
      facilityId: person.facilityId!,
      dataType,
      customType,
    })
      .then((response) =>
        setTemplate({ tag: 'Complete', value: response.data })
      )
      .catch(() => setTemplate({ tag: 'Error', value: 'Not Found' }))
  }, [])

  return template
}

export function useTemplateById({ templateId }: { templateId: string }) {
  const [template, setTemplate] = useState<
    AsyncResult<SignableForm, 'Not Found'>
  >({
    tag: 'Loading',
  })

  useEffect(() => {
    getForm(templateId)
      .then((value) => setTemplate({ tag: 'Complete', value }))
      .catch(() => setTemplate({ tag: 'Error', value: 'Not Found' }))
  }, [])

  return template
}
