import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import ButtonLink from '@shared/components/ButtonLink'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import {
  CreatableStyledSelect,
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { Person } from '@shared/types/person'
import ConfirmModal from '@app/components/ConfirmModal'
import { selectorOptions } from './constants'

type Props = {
  index: number
  isSubmitting: boolean
  file: File
  attachmentType?: OptionTypeBase
  control: Control<any>
  removeAttachment: () => Promise<void>
  isDisabled: boolean
  person: Person
}

export default function TypeSelector(props: Props) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false)
  const {
    removeAttachment,
    index,
    isSubmitting,
    file,
    attachmentType,
    isDisabled,
    control,
    person,
  } = props

  return (
    <>
      <div data-cy="type-selector">
        <div className="flex justify-between pt-[24px]">
          <span className="text-[14px] font-semibold">{file.name}</span>
          <PersonPermissionGate
            person={person}
            permissions={[
              GroupPermission.GROUP_PERMISSION_INCIDENT_ATTACHMENT_DELETE,
            ]}
          >
            <ButtonLink
              className="text-[14px] text-button-quaternary-color"
              onClick={(e: React.SyntheticEvent) => {
                e.preventDefault()
                setShowDeleteConfirmation(true)
              }}
              data-cy={`delete-attachment-${index}`}
            >
              Remove
            </ButtonLink>
          </PersonPermissionGate>
        </div>
        <div className="pt-[14px]" data-cy={`type-selector-${index}`}>
          <Controller
            data-testid="selector-input"
            control={control}
            name={`attachments.${index}.attachmentType`}
            defaultValue={attachmentType}
            render={({ field: { onChange, value } }) => (
              <CreatableStyledSelect
                isDisabled={isDisabled}
                id="assignDocumentType"
                instanceId="assignDocumentType"
                data-testid="selector-container"
                value={value}
                onChange={(e: OptionTypeBase) => onChange(e)}
                isClearable={true}
                placeholder="Enter or select document title..."
                isLoading={isSubmitting}
                autoFocus={true}
                options={selectorOptions}
              />
            )}
          />
        </div>
      </div>
      {showDeleteConfirmation && (
        <ConfirmModal
          title="Remove attachment?"
          content={<div className="content-text">This cannot be undone.</div>}
          confirmButtonConfig={{
            children: 'Remove attachment',
            onClick: () => removeAttachment(),
          }}
          denyButtonConfig={{
            onClick: () => setShowDeleteConfirmation(false),
          }}
        />
      )}
    </>
  )
}
