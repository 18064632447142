import { AppraisalStatus } from '@augusthealth/models/com/august/protos/august_initial_appraisal'
import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Loading } from '@shared/utils/loading'
import {
  getResidentAssessment,
  GetResidentAssessmentProps,
} from '../api/resident_assessment'

export default function useAssessment({
  person,
  withOriginalSettings,
}: GetResidentAssessmentProps) {
  const { setError } = useContext(GlobalContext)
  const [assessment, setAssessment] = useState<Loading<AppraisalStatus>>({
    tag: 'Loading',
  })

  const refreshAssessment = async () => {
    try {
      const fetchedAssessment = await getResidentAssessment({
        person,
        withOriginalSettings,
      })
      setAssessment({ tag: 'Complete', value: fetchedAssessment })
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    void refreshAssessment()
  }, [person.id, withOriginalSettings])

  return { assessment, refreshAssessment }
}
