import { get } from '@augusthealth/august-frontend-form-elements'
import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useState } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import { SmallDotDivider } from '@shared/components/DotDivider'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { Facility } from '@shared/types/facility'
import { Person } from '@shared/types/person'
import { getRoomNumber } from '@shared/utils/person'
import { twx } from '@shared/utils/tailwind'
import VerifyAdmissionDetails from '@app/components/Prospects/VerifyAdmissionDetails/VerifyAdmissionDetails'
import styles from './admissionDetailsStyles.module.css'
import { EditableDetailsCard } from '../DetailsCard'
import {
  admissionType,
  getFinancialStartDateLabel,
  hospice,
  moveIn,
  respite,
} from './admissionDetailsHelpers'

export default function AdmissionDetails({
  person,
  facility,
}: {
  person: Person
  facility: Facility
}) {
  const [showAdmissionDetails, setShowAdmissionDetails] = useState(false)

  return (
    <EditableDetailsCard
      icon="fas fa-file-lines fa-fw"
      title="Admission Details"
      editPermissions={{
        person,
        permissions: [GroupPermission.GROUP_PERMISSION_PERSON_UPDATE],
      }}
      onEditClick={() => setShowAdmissionDetails(true)}
      editButtonId={'editAdmissionDetails'}
    >
      <DetailsRow name="Community" value={facilityName(facility)} />
      <DetailsRow name="Room">
        <RoomNumber
          person={person}
          setShowAdmissionDetails={setShowAdmissionDetails}
        />
      </DetailsRow>
      <DetailsRow name="Move-In Date" value={moveIn(person)} />
      <DetailsRow
        name="Financial Start"
        value={getFinancialStartDateLabel(person)}
      />
      <DetailsRow name="Admission Type" value={admissionType(person)} />
      <DetailsRow name="Hospice" value={hospice(person)} />
      <DetailsRow className="mb-0" name="Respite" value={respite(person)} />
      {showAdmissionDetails && (
        <VerifyAdmissionDetails
          person={person}
          doneFn={setShowAdmissionDetails}
        />
      )}
    </EditableDetailsCard>
  )
}

function DetailsRow(props: {
  name: string
  value?: string
  children?: React.ReactNode
  className?: string
}) {
  const { children, name, value, className } = props

  return (
    <div
      className={twx(
        className,
        'mt-[4px] flex text-[14px] font-medium leading-[20px] text-duotone-level1'
      )}
    >
      <div className={styles.infoKey}>{name}</div>
      {children}
      {value && <div>{value}</div>}
    </div>
  )
}

export const RoomNumber = ({
  person,
  setShowAdmissionDetails,
  showIcon,
}: {
  person: Person
  setShowAdmissionDetails: React.Dispatch<React.SetStateAction<boolean>>
  showIcon?: boolean
}) => (
  <span>
    {showIcon && <i className={`${styles.roomIcon} fas fa-bed-alt`} />}
    {getRoomNumber(person) ? (
      getRoomNumber(person)
    ) : (
      <span className="text-gray-07">
        Not assigned
        <PersonPermissionGate
          person={person}
          permissions={[GroupPermission.GROUP_PERMISSION_OCCUPANCY_UPDATE]}
        >
          <SmallDotDivider />
          <ButtonLink onClick={() => setShowAdmissionDetails(true)}>
            Add one now
          </ButtonLink>
        </PersonPermissionGate>
      </span>
    )}
  </span>
)

function facilityName(facility: Facility) {
  return get(facility, 'name')
}
