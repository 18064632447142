import { Popup } from '@augusthealth/august-frontend-form-elements'
import { BasicSpinner } from '@shared/components/BasicSpinner'
import { tw, twx } from '@shared/utils/tailwind'

export default function LoadingPopup({
  loading,
  className,
}: {
  loading: boolean
  className?: string
}) {
  return (
    <Popup
      holderClassName={`${className} top-modal light-modal`}
      defaultOpen={loading}
      returnChildrenDirectly
    >
      <SimpleSpinner />
    </Popup>
  )
}

export function AnimatedSpinner(props: { message: string }) {
  return (
    <div className={tw`text-center`}>
      <LoaderSvg />
      <div className={tw`mt-[20px] font-inter text-[18px] leading-[20px]`}>
        {props.message}
      </div>
    </div>
  )
}

export const LoaderSvg = () => {
  let svgSrc = ['/svg/spinner--cane.svg']

  if (new Date().getMonth() === 11) {
    svgSrc = ['/svg/spinner_animated.svg']
  }

  return (
    <img
      src={svgSrc[Math.floor(Math.random() * svgSrc.length)]}
      width="172px"
      height="150px"
    />
  )
}

export function SimpleSpinner(props: { size?: string; className?: string }) {
  const size = props.size || 'fa-3x'

  return (
    <div className={tw`flex-center ${props.className}`}>
      <BasicSpinner className={twx(size)} />
    </div>
  )
}
