import classNames from 'classnames'
import { mergeWith } from 'lodash'
import { useState } from 'react'
import { FormState } from 'react-hook-form'
import {
  Props as AsyncButtonProps,
  AsyncIconButton,
} from '@shared/components/AsyncButton'
import styles from './styles.module.css'

/**
 * A cancel/continue form footer for our full-page forms.
 *
 * @param config - Configuration for yes & no buttons, and optional form state
 * @param config.yesBtn - Props to manage the 'yes' button
 * @param config.noBtn - Props to manage the 'no' button
 * @param config.formState - Used if the form in question is managed by react-hook-form
 */
export default function FullpageFormFooter({
  yesBtn,
  noBtn,
  formState,
  withoutSidebarMargin = false,
}: {
  yesBtn: {
    text: React.ReactNode
    props?: Partial<AsyncButtonProps>
  }
  noBtn: {
    text: React.ReactNode
    action: () => Promise<void>
    props?: Partial<AsyncButtonProps>
  }
  formState?: FormState<any>
  withoutSidebarMargin?: boolean
}) {
  const [noInProgress, setNoInProgress] = useState(false)

  // Combine props for no button with props generated from formState, if present
  const internalNoBtnProps = mergeWithDefaultValues(
    { ...noBtnDefaults, disabled: noInProgress, isLoading: noInProgress },
    { ...propsForFormState(formState, 'No') }
  )
  // Combine internal no button props with provided values
  const noBtnProps = mergeWithDefaultValues(
    internalNoBtnProps,
    noBtn.props || {}
  )
  // Combine internal yes button props with provided values
  const yesBtnProps = mergeWithDefaultValues(
    { ...yesBtnDefaults, ...propsForFormState(formState, 'Yes') },
    yesBtn.props || {}
  )

  return (
    <footer
      className={classNames(styles.footer, {
        [styles.footerWithSidebar]: !withoutSidebarMargin,
      })}
    >
      <AsyncIconButton
        {...noBtnProps}
        onClick={async () => {
          setNoInProgress(true)
          await noBtn.action()
        }}
      >
        {noBtn.text}
      </AsyncIconButton>
      <AsyncIconButton {...yesBtnProps}>{yesBtn.text}</AsyncIconButton>
    </footer>
  )
}

function mergeWithDefaultValues(
  defaultValues: AsyncButtonProps,
  providedProps: Partial<AsyncButtonProps>
): AsyncButtonProps {
  return mergeWith(
    defaultValues,
    providedProps,
    (defaultValue, srcValue, key) => {
      if (key === 'className') {
        return `${defaultValue} ${srcValue}`
      }

      if (key === 'isLoading' || key === 'disabled') {
        return defaultValue || srcValue
      }

      return undefined
    }
  )
}

function propsForFormState(
  fs: FormState<any> | undefined,
  button: 'Yes' | 'No'
) {
  if (fs) {
    switch (button) {
      case 'Yes':
        return {
          isLoading: fs.isSubmitting,
          disabled: fs.isSubmitting,
        }
      case 'No':
        return {
          disabled: fs.isSubmitting,
        }
    }
  } else {
    return {}
  }
}

export const noBtnDefaults: AsyncButtonProps = {
  buttonStyle: 'secondary-outline',
  width: '256px',
  className: 'mr-[24px]',
  type: 'button',
}

export const yesBtnDefaults: AsyncButtonProps = {
  buttonStyle: 'primary-fill',
  width: '256px',
  type: 'submit',
}
