import Icon from '@shared/components/Icon'
import {
  DetailedStatement,
  PaymentReceivedData,
  StatementAction,
  StatementActionType,
} from '@shared/types/billing'
import { formatCurrencyForBilling } from '@shared/utils/billing'
import { convertISODateStringToLabel } from '@shared/utils/date'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { tw } from '@shared/utils/tailwind'

export default function EventHistory({
  detailedInvoice,
}: {
  detailedInvoice: DetailedStatement
}) {
  const { events } = detailedInvoice
  return (
    <div className={tw`flex flex-col gap-[16px]`}>
      {events.map((data) => {
        return (
          <EventTemplate
            invoiceEventData={data}
            key={data.eventData.occurredAt}
          />
        )
      })}
    </div>
  )
}

const dict = {
  [StatementActionType.InvoiceGenerated]: {
    icon: 'file-invoice-dollar',
    text: 'Statement generated',
  },
  [StatementActionType.MarkedApproved]: {
    icon: 'check-circle',
    text: 'Statement approved',
  },
  [StatementActionType.MarkedNeedsWork]: {
    icon: 'circle-x',
    text: 'Statement needs work',
  },
  [StatementActionType.InvoiceError]: {
    icon: 'triangle-exclamation',
    text: 'Failed to send statement',
  },
  [StatementActionType.InvoiceSent]: {
    icon: 'envelope',
    text: 'Statement sent',
  },
  [StatementActionType.PaymentReceived]: {
    icon: 'money-bill',
    text: 'Payment',
  },
} as const

function EventTemplate({
  invoiceEventData,
}: {
  invoiceEventData: StatementAction
}) {
  const { eventData, userName } = invoiceEventData
  const { occurredAt, eventType } = eventData

  if (!dict[eventType]) return null

  const { icon, text } = dict[eventType]
  const createdAt = convertISODateStringToLabel(occurredAt, 'MMMM d, yyyy')
  const fullName =
    getFirstAndLastName(userName) === 'System User'
      ? null
      : getFirstAndLastName(userName)

  let note: string | undefined, payments: PaymentReceivedData | undefined

  if (
    (eventType === StatementActionType.MarkedNeedsWork ||
      eventType === StatementActionType.MarkedApproved) &&
    eventData.data.note
  ) {
    note = eventData.data.note
  } else if (eventType === StatementActionType.InvoiceError) {
    note = eventData.data.message
  }

  if (eventType === StatementActionType.PaymentReceived) {
    payments = eventData.data
  }

  return (
    <div className={tw`flex items-center`}>
      <div
        className={tw`mr-[14px] flex h-full items-start pt-1 text-secondary-05`}
      >
        <Icon name={icon} />
      </div>
      <div
        className={tw`flex flex-col text-[14px] font-[600] text-secondary-05`}
      >
        <div>
          <span data-testid="text">{text}</span>
          &nbsp;
          <span className={tw`font-[500] text-secondary-07`}>on</span>
          &nbsp;
          {createdAt}
        </div>
        <div className={tw`font-[500] text-secondary-07`}>{fullName}</div>
        {note && <div className={tw`font-[500] text-secondary-07`}>{note}</div>}
        {payments?.paymentData.amountCents && (
          <dl
            className={tw`grid w-[100px] grid-cols-2 grid-rows-1 text-[12px] font-[600] leading-[16px]`}
          >
            <dt className={tw`text-rainbow-green`}>Paid:</dt>
            <dd data-testid="payment-amount" className={tw`text-rainbow-green`}>
              {formatCurrencyForBilling(payments.paymentData.amountCents)}
            </dd>
            <dt className={tw`text-orange`}>Due:</dt>
            <dd data-testid="remaining-balance" className={tw`text-orange`}>
              {formatCurrencyForBilling(payments.remainingBalanceCents)}
            </dd>
          </dl>
        )}
      </div>
    </div>
  )
}
