import { cloneDeep } from 'lodash'
import {
  MonthlyOccupancyStats,
  Occupancy_Room as OccupancyRoom,
} from '@shared/types/api/occupancy'
import { Order, sortNumberString } from '@shared/utils/common'

// Rooms functions

export function sortRoomsByName(
  rooms: OccupancyRoom[],
  order: Order
): OccupancyRoom[] {
  return cloneDeep(rooms).sort((r1, r2) => {
    if (!r1.room?.name) {
      return order as number
    } else if (!r2.room?.name) {
      return -order
    }

    return sortNumberString(r1.room.name, r2.room.name) * (order as number)
  })
}

function getCurrentOccupancy(oRoom: OccupancyRoom) {
  const { beds } = oRoom
  if (beds && beds.length) {
    return beds.filter(({ occupants }) => occupants?.length).length
  }

  return 0
}

function getMaxOccupancy(oRoom: OccupancyRoom) {
  return oRoom.beds?.length || 0
}

function getOccupancyRate(oRoom: OccupancyRoom) {
  const maxOccupancy = getMaxOccupancy(oRoom)
  return maxOccupancy ? getCurrentOccupancy(oRoom) / maxOccupancy : 0
}

export function sortRoomsByStatus(
  rooms: OccupancyRoom[],
  order: Order
): OccupancyRoom[] {
  return cloneDeep(rooms).sort((r1, r2) => {
    const rate1 = getOccupancyRate(r1)
    const rate2 = getOccupancyRate(r2)

    if (rate1 > rate2) {
      return order as number
    }

    return -(order as number)
  })
}

// Stats functions

export function getCurrentMonthStats(
  monthlyStatsList: MonthlyOccupancyStats[]
) {
  const today = new Date()
  return monthlyStatsList.find((ms) => {
    const startDate = ms.period?.startDate
    return (
      startDate &&
      startDate.year === today.getFullYear() &&
      startDate.month === today.getMonth() + 1
    )
  })
}

export function getMonthlyPercentage(monthlyStats: MonthlyOccupancyStats) {
  const { bedDays = '0', residentDays = '0' } = monthlyStats
  const bDays = Number(bedDays)
  const rDays = Number(residentDays)
  return bDays ? rDays / bDays : 0
}

export function convertPercentageToString(percentage: number) {
  const numberString = Math.round(percentage * 100)
  return `${numberString}%`
}
