import { HTMLProps } from 'react'
import { twx } from '@shared/utils/tailwind'

type ActionButtonProps = HTMLProps<HTMLButtonElement> & {
  iconClass?: string
  loading?: boolean
}

type DeletePrintButtonsProps = Omit<ActionButtonProps, 'iconClass'>

const ACTION_BUTTON_CLASSES = (loading: boolean | undefined) =>
  twx(
    'rounded-[4px] bg-transparent cursor-pointer py-[6px] px-[12px] text-center font-semibold text-[9px]',
    'leading-[10px] uppercase text-secondary-06 border border-secondary-06 basis-1/2 transition ease-in-out',
    'hover:enabled:bg-secondary-06 hover:enabled:text-white no-underline hover:no-underline',
    { 'pointer-events-none text-secondary opacity-50': loading }
  )

function ActionButton(props: ActionButtonProps) {
  const { children, className, iconClass, loading, type, ...btnProps } = props

  return (
    <button
      className={twx(ACTION_BUTTON_CLASSES(loading), className)}
      type="button"
      {...btnProps}
    >
      <i className={twx('mr-[8px]', iconClass)} />
      {children}
    </button>
  )
}

export function PrintButton(props: DeletePrintButtonsProps) {
  return (
    <ActionButton iconClass="fas fa-print" {...props}>
      Print
    </ActionButton>
  )
}

export function DeleteButton(props: DeletePrintButtonsProps) {
  return (
    <ActionButton iconClass="fas fa-trash-can" {...props}>
      Delete
    </ActionButton>
  )
}

export function DownloadButton(
  props: HTMLProps<HTMLAnchorElement> & {
    loading?: boolean
  }
) {
  const { className, loading, ...linkProps } = props

  return (
    <a
      className={twx(
        ACTION_BUTTON_CLASSES(loading),
        'no-underline hover:bg-secondary-06 hover:text-white hover:no-underline',
        className
      )}
      download={true}
      tabIndex={0}
      {...linkProps}
    >
      <i className={twx('fas fa-arrow-alt-to-bottom mr-[8px]')} />
      Download
    </a>
  )
}

type ActionButtonHolderProps = HTMLProps<HTMLDivElement> & {
  showTopHR?: boolean
}

export function ActionButtonHolder(props: ActionButtonHolderProps) {
  const { children, className, showTopHR = false, ...divProps } = props

  return (
    <div
      className={twx('flex justify-between gap-2 pt-4 xs:pt-6', className, {
        ['border-t border-secondary-10']: showTopHR,
      })}
      {...divProps}
    >
      {children}
    </div>
  )
}
