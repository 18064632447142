import {
  Occupancy_Bed as OccupancyBed,
  Occupancy_Room as OccupancyRoom,
} from '@augusthealth/models/com/august/protos/api/occupancy'
import { ReactNode } from 'react'
import styles from './styles.module.css'
import OccupantRow from './OccupantRow'
import VacantRow from './VacantRow'

type Props = {
  occupancyBed: OccupancyBed
  occupancyRoom: OccupancyRoom
}

export default function BedRow({ occupancyBed, occupancyRoom }: Props) {
  const { bed = {}, occupants = [] } = occupancyBed
  const { id: bedId } = bed
  const { room } = occupancyRoom
  // Still need to get people vs beds[*].occupants
  // Since beds[*].occupants doesn't include gender
  let content: ReactNode // Can be a Vacant Row or multiple Occupant rows

  if (occupants && occupants.length) {
    content = occupants.map((o, i) => {
      const key = `occupant-row-${bedId}-${i}`
      return <OccupantRow key={key} bed={bed} occupant={o} index={i} />
    })
  } else {
    content = room && <VacantRow bed={bed} room={room} />
  }

  return <div className={styles.bedRow}>{content}</div>
}
