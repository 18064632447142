import { Clock } from '@augusthealth/models/com/august/protos/date'
import {
  OrgFacilitySettings,
  SettingsType,
} from '@augusthealth/models/com/august/protos/org_facility_settings'
import { useEffect, useState } from 'react'
import { mergePatchFacility } from '@shared/api/facility'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import Card from '@shared/components/Card'
import { Facility } from '@shared/types/facility'
import { updateOrgFacilitySettings } from '@app/api/organizations'
import { Switch } from '@app/components/Switch'

export default function FacilitySettings({
  facility,
  marClockSettings,
  setFacility,
  setShowHUD,
  onClose,
}: {
  facility: Facility
  marClockSettings?: OrgFacilitySettings
  setFacility: React.Dispatch<React.SetStateAction<Facility>>
  setShowHUD: React.Dispatch<React.SetStateAction<boolean>>
  onClose?: () => void
}) {
  const initialIsActive = facility.isActive
  const [isActive, setIsActive] = useState(initialIsActive)
  const initialIsMar24HrFormat =
    marClockSettings?.settings?.marSettings?.timeFormat?.clock ===
    Clock.CLOCK_24_HOUR
  const [isMar24HrFormat, setIsMar24HrFormat] = useState(initialIsMar24HrFormat)

  useEffect(() => {
    setIsActive(initialIsActive)
  }, [facility])

  useEffect(() => {
    setIsMar24HrFormat(initialIsMar24HrFormat)
  }, [marClockSettings])

  const updateFacilitySettings = async () => {
    const clockFormat = isMar24HrFormat
      ? Clock.CLOCK_24_HOUR
      : Clock.CLOCK_12_HOUR
    if (
      clockFormat !== marClockSettings?.settings?.marSettings?.timeFormat?.clock
    ) {
      const newSettings: OrgFacilitySettings = {
        facilityId: facility.id,
        orgId: facility.orgId,
        settingsType: SettingsType.SETTINGS_TYPE_MAR_SETTINGS,
        settings: {
          marSettings: {
            timeFormat: {
              clock: clockFormat,
            },
          },
        },
      }
      await updateOrgFacilitySettings(newSettings)
    }

    if (isActive !== facility.isActive) {
      const { data: updated } = await mergePatchFacility({
        facility,
        patch: { isActive },
      })
      setFacility(updated)
    }

    setShowHUD(true)

    if (onClose) {
      onClose()
    }
  }

  return (
    <Card className="w-min flex-col">
      <div className="mb-[32px] text-[20px] font-semibold leading-[20px]">
        Facility Settings
      </div>

      <div className="mb-[16px] flex items-center justify-between">
        <label className="mb-0" htmlFor="MAR-clock">
          MAR 24-hr Clock
        </label>
        <Switch
          name="MAR-clock"
          onChange={() => setIsMar24HrFormat(!isMar24HrFormat)}
          checked={isMar24HrFormat}
        />
      </div>

      <div className="flex items-center justify-between">
        <label className="mb-0" htmlFor="inactive">
          Mark as inactive
        </label>
        <Switch
          name="inactive"
          onChange={() => setIsActive(!isActive)}
          checked={!isActive}
        />
      </div>

      <div className="mt-[24px] flex justify-between gap-x-4">
        <AsyncIconButton
          buttonStyle="secondary-outline"
          buttonSize="small"
          onClick={onClose}
          width="120px"
        >
          Cancel
        </AsyncIconButton>

        <AsyncIconButton
          buttonStyle="primary-fill"
          buttonSize="small"
          onClick={async () => {
            await updateFacilitySettings()
          }}
          width="120px"
        >
          Save
        </AsyncIconButton>
      </div>
    </Card>
  )
}
