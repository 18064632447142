import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { Redirect, Route, Switch } from 'react-router-dom'
import GatedRoute, {
  FacilityGatedRoute,
} from '@shared/components/Auth/GatedRoute'
import { useUserContext } from '@shared/contexts/UserContext'
import {
  getOrgSettingsCommunitiesUrl,
  getOrgSettingsGeneralUrl,
  getOrgSettingsUsersUrl,
} from '@shared/legacy_routes'
import { UserAccount } from '@shared/types/user'
import { tw } from '@shared/utils/tailwind'
import {
  isDirector,
  isFacilityUserManager,
  isOrgAdmin,
  isOrgUserManager,
  isSuperUser,
} from '@shared/utils/user'
import PageTitle from '../sharedUIs/PageTitle'
import CommunitiesPage from './CommunitiesPage'
import GeneralPage from './GeneralPage'
import RouterTabBar, { RouterTab } from './RouterTabBar'
import {
  ORGANIZATIONS_SETTINGS_COMMUNITIES_PATH,
  ORGANIZATIONS_SETTINGS_GENERAL_PATH,
  ORGANIZATIONS_SETTINGS_PATH,
  ORGANIZATIONS_SETTINGS_USERS_PATH,
  Props,
} from './type'
import UsersPage from './UsersPage'

type TabId = 'General' | 'Communities' | 'Users'

const showTab =
  (user: UserAccount, orgId: string) =>
  (tab: RouterTab<TabId>): boolean => {
    switch (tab.id) {
      case 'General':
        return (
          !isDirector({ user, orgId }) &&
          !isFacilityUserManager({ user, orgId })
        )
      case 'Communities':
        return (
          !isOrgUserManager({ user, orgId }) &&
          !isFacilityUserManager({ user, orgId })
        )
      case 'Users':
        return true
    }
  }

export default function Settings({
  match: {
    params: { orgId },
  },
}: Props) {
  const { user } = useUserContext()

  const communitiesPath = ORGANIZATIONS_SETTINGS_COMMUNITIES_PATH.replace(
    ':orgId',
    orgId
  )
  const generalPath = ORGANIZATIONS_SETTINGS_GENERAL_PATH.replace(
    ':orgId',
    orgId
  )
  const usersPath = ORGANIZATIONS_SETTINGS_USERS_PATH.replace(':orgId', orgId)

  const tabs: RouterTab<TabId>[] = [
    {
      id: 'General' as const,
      label: 'General',
      path: getOrgSettingsGeneralUrl(orgId),
    },
    {
      id: 'Communities' as const,
      label: 'Communities',
      path: getOrgSettingsCommunitiesUrl(orgId),
    },
    {
      id: 'Users' as const,
      label: 'Users',
      path: getOrgSettingsUsersUrl(orgId),
    },
  ].filter(showTab(user, orgId))

  return (
    <div className={tw`content-holder mt-6`}>
      <PageTitle>Org Settings</PageTitle>
      <RouterTabBar tabs={tabs} containerClassName="mb-4" />

      <div>
        <Switch key="org-settings-switch">
          {isDirector({ user, orgId }) && (
            <>
              <Route exact path={ORGANIZATIONS_SETTINGS_PATH}>
                <Redirect to={communitiesPath} />
              </Route>
              <Route
                exact
                path={ORGANIZATIONS_SETTINGS_COMMUNITIES_PATH}
                component={CommunitiesPage}
              />
              <Route
                exact
                path={ORGANIZATIONS_SETTINGS_USERS_PATH}
                component={UsersPage}
              />
            </>
          )}

          {isOrgUserManager({ user, orgId }) && (
            <>
              <Route exact path={ORGANIZATIONS_SETTINGS_PATH}>
                <Redirect to={usersPath} />
              </Route>
              <Route
                exact
                path={ORGANIZATIONS_SETTINGS_GENERAL_PATH}
                component={GeneralPage}
              />
              <Route
                exact
                path={ORGANIZATIONS_SETTINGS_USERS_PATH}
                component={UsersPage}
              />
            </>
          )}

          {(isSuperUser(user) || isOrgAdmin({ user, orgId })) && (
            <>
              <Route exact path={ORGANIZATIONS_SETTINGS_PATH}>
                <Redirect to={generalPath} />
              </Route>
              <GatedRoute
                exact
                minimalPermissions={[
                  GroupPermission.GROUP_PERMISSION_ORGANIZATION_READ,
                ]}
                path={ORGANIZATIONS_SETTINGS_GENERAL_PATH}
                component={GeneralPage}
              />
              <GatedRoute
                exact
                minimalPermissions={[
                  GroupPermission.GROUP_PERMISSION_ORGANIZATION_READ,
                ]}
                path={ORGANIZATIONS_SETTINGS_COMMUNITIES_PATH}
                component={CommunitiesPage}
              />
              <FacilityGatedRoute
                exact
                minimalPermissions={[
                  GroupPermission.GROUP_PERMISSION_FACILITY_USER_READ,
                ]}
                path={ORGANIZATIONS_SETTINGS_USERS_PATH}
                component={UsersPage}
              />
            </>
          )}

          {isFacilityUserManager({ user, orgId }) && (
            <>
              <Route exact path={ORGANIZATIONS_SETTINGS_PATH}>
                <Redirect to={usersPath} />
              </Route>
              <Route exact path={ORGANIZATIONS_SETTINGS_COMMUNITIES_PATH}>
                <Redirect to={usersPath} />
              </Route>
              <FacilityGatedRoute
                exact
                minimalPermissions={[
                  GroupPermission.GROUP_PERMISSION_FACILITY_USER_READ,
                ]}
                path={ORGANIZATIONS_SETTINGS_USERS_PATH}
                component={UsersPage}
              />
            </>
          )}
        </Switch>
      </div>
    </div>
  )
}
