import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { Person } from '@shared/types/person'

export enum ActionMenuItemType {
  EDIT_ADMISSION_DETAILS = 'Edit Admission Details',
  EDIT_PROFILE = 'Edit Profile',
  SCHEDULE_MOVE_IN = 'Move-In Resident',
  CLOSE_PROSPECT = 'Close Move-In',
  REOPEN = 'Re-open',
  MARK_AS_AWAY = 'Mark As Away',
  MOVE_OUT_RESIDENT = 'Move-Out Resident',
  PRINT_FACE_SHEET = 'Print Face Sheet',
  UNDO_MOVE_IN = 'Undo Move-In',
}

export interface ActionMenuItemProps {
  item: ActionMenuItemType
  onClick: () => void
  setShowDropdown: (value: React.SetStateAction<boolean>) => void
  icon: string
  disabled?: boolean
  permissions: GroupPermission[]
  person: Person
}
