import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { AssessmentLevel } from '@shared/types/assessment_configuration'
import { getLevelLabel } from '@shared/utils/residentAssessment'
import styles from '../styles.module.css'
import { EditRemoveButtons } from '../EditRemoveButtons'

type Props = {
  level: AssessmentLevel
  editLevel: ({ index }: { index: number }) => void
  removeLevel: ({ index }: { index: number }) => void
  updateLevel: (
    value: string,
    index: number,
    type: 'description' | 'score'
  ) => void
  index: number
  disabled: boolean
}
export const Level = ({
  level,
  editLevel,
  removeLevel,
  updateLevel,
  index,
  disabled,
}: Props) => {
  return (
    <>
      <EditRemoveButtons
        onEdit={() => editLevel({ index })}
        onRemove={() => removeLevel({ index })}
        disabled={disabled}
      />
      <BasicInput
        type={'text'}
        defaultValue={getLevelLabel(level.level!)}
        disabled={true}
      />
      <BasicInput
        type={'text'}
        value={level.description}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          updateLevel(e.target.value, index, 'description')
        }
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.code === 'Enter') {
            updateLevel(e.currentTarget.value, index, 'description')
          }
        }}
        data-testid={`${level.level!}-description`}
        disabled={disabled}
      />
      <BasicInput
        type={'number'}
        value={level.score}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          updateLevel(e.target.value, index, 'score')
        }
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.code === 'Enter') {
            updateLevel(e.currentTarget.value, index, 'score')
          }
        }}
        className={styles.scoreInput}
        data-testid={`${level.level!}-score`}
        disabled={disabled}
      />
    </>
  )
}
