import { forwardRef, MouseEventHandler } from 'react'
import Datepicker, { ReactDatePickerProps } from 'react-datepicker'
import Icon from '@shared/components/Icon'
import { twx } from '@shared/utils/tailwind'

export type LabelledCalendarInputProps = ReactDatePickerProps & {
  label: string
  showErrorBorder?: boolean
  iconName?: string
  iconClassName?: string
}
export const LabelledCalendarInput = (props: LabelledCalendarInputProps) => {
  const {
    label,
    showErrorBorder = false,
    name,
    iconName,
    iconClassName,
    ...rest
  } = props
  return (
    <Datepicker
      name={name}
      {...rest}
      customInput={
        <LabelledCalendarInputDisplay
          label={label}
          showErrorBorder={showErrorBorder}
          name={name}
          iconName={iconName}
          iconClassName={iconClassName}
        />
      }
    />
  )
}

const LabelledCalendarInputDisplay = forwardRef<
  HTMLInputElement,
  React.HTMLProps<HTMLInputElement> & {
    label: string
    className?: string
    width?: string
    onClick?: MouseEventHandler<HTMLButtonElement>
    showErrorBorder?: boolean
    disabled?: boolean
    name?: string
    iconName?: string
    iconClassName?: string
  }
>((props, ref) => {
  const {
    label,
    onClick,
    showErrorBorder = false,
    name,
    disabled,
    iconName,
    iconClassName,
    ...rest
  } = props

  return (
    <button
      className={twx(
        'flex h-input w-full flex-row items-center justify-start border border-form-input-border bg-white',
        'rounded-input p-inputPadding text-formInput font-medium leading-[16px]',
        'focus-within:enabled:border-form-input-border-hover focus-within:enabled:outline focus-within:enabled:outline-1 focus-within:enabled:outline-form-input-border-hover hover:enabled:border-form-input-border-hover',
        'disabled:cursor-default disabled:bg-form-input-disabled-background-color disabled:text-form-input-disabled-text-color',
        {
          ['border-form-input-error-border focus-within:enabled:border-form-input-error-border focus-within:enabled:outline focus-within:enabled:outline-1 focus-within:enabled:outline-form-input-error-border hover:enabled:border-form-input-error-border']:
            showErrorBorder,
        }
      )}
      onClick={onClick}
      type={'button'}
      disabled={disabled}
    >
      <Icon
        name={iconName ?? 'arrow-right-from-line'}
        className={twx('text-primary-light', iconClassName)}
      />
      <label
        className={twx(
          'mx-2 my-0 whitespace-nowrap text-[14px] font-semibold leading-[16px] text-gray-03',
          {
            'text-form-input-disabled-text-color': disabled,
          }
        )}
        htmlFor={name}
      >
        {label}
      </label>
      <input
        ref={ref}
        {...rest}
        disabled={disabled}
        id={name}
        name={name}
        className={twx(
          'w-full border-0 focus-within:enabled:outline-0 hover:enabled:outline-0 focus:enabled:outline-0',
          'focus-within:enabled:border-0 hover:enabled:border-0 focus:enabled:border-0',
          'placeholder:text-form-input-placeholder-color'
        )}
        placeholder="MM/DD/YYYY"
      />
    </button>
  )
})

LabelledCalendarInputDisplay.displayName = 'LabelledCalendarInputDisplay'
