import { Contact_ContactRelationship as CR } from '@shared/types/contact'

export const PERSONAL_RELATIONSHIP_MAP: Partial<Record<CR, string>> = {
  [CR.CONTACT_RELATIONSHIP_PERSONAL]: 'For UI Category and "Other Personal"',
  [CR.CONTACT_RELATIONSHIP_FAMMEMB]: 'Family Member',
  [CR.CONTACT_RELATIONSHIP_CHILD]: 'Daughter / Son',
  [CR.CONTACT_RELATIONSHIP_CHILD_INLAW]: 'Daughter-in-law / Son-in-law',
  [CR.CONTACT_RELATIONSHIP_GRNDCHILD]: 'Grandchild',
  [CR.CONTACT_RELATIONSHIP_SPS]: 'Spouse',
  [CR.CONTACT_RELATIONSHIP_SIB]: 'Sibling',
  [CR.CONTACT_RELATIONSHIP_NIENEPH]: 'Niece / Nephew',
  [CR.CONTACT_RELATIONSHIP_GRNIENEPH]: 'Grand Niece / Nephew',
  [CR.CONTACT_RELATIONSHIP_COUSN]: 'Cousin',
  [CR.CONTACT_RELATIONSHIP_FRND]: 'Friend',
  [CR.CONTACT_RELATIONSHIP_SELF]: 'Self',
  [CR.CONTACT_RELATIONSHIP_OTHER]: 'Other Personal',
}

export const PERSONAL_RELATIONSHIP_LIST = Object.keys(
  PERSONAL_RELATIONSHIP_MAP
) as CR[]

export const CLINICIAN_RELATIONSHIP_MAP: Partial<Record<CR, string>> = {
  [CR.CONTACT_RELATIONSHIP_CLINICIAN]: 'For UI Category and "Other Clinician"',
  [CR.CONTACT_RELATIONSHIP_PHYSICIAN]: 'Physician',
  [CR.CONTACT_RELATIONSHIP_NURSE_PRACTITIONER]: 'Nurse Practitioner',
  [CR.CONTACT_RELATIONSHIP_PHYSICIAN_ASSISTANT]: 'Physician Assistant',
  [CR.CONTACT_RELATIONSHIP_PHARMACIST]: 'Pharmacist',
  [CR.CONTACT_RELATIONSHIP_NURSE]: 'Nurse',
  [CR.CONTACT_RELATIONSHIP_SKILLED_THERAPIST]:
    'Skilled Therapist (PT, OT, Speech)',
  [CR.CONTACT_RELATIONSHIP_PSYCHOLOGIST]: 'Psychologist',
  [CR.CONTACT_RELATIONSHIP_DIETICIAN]: 'Dietitian',
  [CR.CONTACT_RELATIONSHIP_SOCIAL_WORKER]: 'Social Worker',
  [CR.CONTACT_RELATIONSHIP_DENTIST]: 'Dentist',
  [CR.CONTACT_RELATIONSHIP_PODIATRIST]: 'Podiatrist',
  [CR.CONTACT_RELATIONSHIP_OTHER]: 'Other Clinician',
}

export const CLINICIAN_RELATIONSHIP_LIST = Object.keys(
  CLINICIAN_RELATIONSHIP_MAP
) as CR[]

export const PRIMARY_CARE_ROLES = [
  CR.CONTACT_RELATIONSHIP_PHYSICIAN,
  CR.CONTACT_RELATIONSHIP_PHYSICIAN_ASSISTANT,
  CR.CONTACT_RELATIONSHIP_NURSE_PRACTITIONER,
]

export const PROFESSIONAL_RELATIONSHIP_MAP: Partial<Record<CR, string>> = {
  [CR.CONTACT_RELATIONSHIP_PROFESSIONAL]:
    'For UI Category and "Other Professional"',
  [CR.CONTACT_RELATIONSHIP_CASE_MANAGER]: 'Case Manager',
  [CR.CONTACT_RELATIONSHIP_CONSERVATOR]: 'Conservator',
  [CR.CONTACT_RELATIONSHIP_FIDUCIARY]: 'Fiduciary',
  [CR.CONTACT_RELATIONSHIP_FUNERAL_HOME]: 'Funeral Home',
  [CR.CONTACT_RELATIONSHIP_LEGALLY_RESPONSIBLE]: 'Guardian',
  [CR.CONTACT_RELATIONSHIP_PLACEMENT_AGENT]: 'Placement Agent',
  [CR.CONTACT_RELATIONSHIP_REFERRAL_AGENT]: 'Referral Agent',
  [CR.CONTACT_RELATIONSHIP_RELIGIOUS_ADVISOR]: 'Religious Advisor',
  [CR.CONTACT_RELATIONSHIP_THIRD_PARTY_PAYER]: 'Third Party Payer',
  [CR.CONTACT_RELATIONSHIP_OTHER]: 'Other Professional',
}

export const PROFESSIONAL_RELATIONSHIP_LIST = Object.keys(
  PROFESSIONAL_RELATIONSHIP_MAP
) as CR[]

export const NO_ROLE_RELATIONSHIP_MAP: Partial<Record<CR, string>> = {
  ...PERSONAL_RELATIONSHIP_MAP,
  ...CLINICIAN_RELATIONSHIP_MAP,
  ...PROFESSIONAL_RELATIONSHIP_MAP,
}

export const ROLE_RELATIONSHIP_MAP: Partial<Record<CR, string>> = {
  [CR.CONTACT_RELATIONSHIP_GENERAL_PRACTITIONER]: 'Primary Care Provider',
  [CR.CONTACT_RELATIONSHIP_MENTAL_HEALTH_PROVIDER]: 'Mental Health Provider',
  [CR.CONTACT_RELATIONSHIP_HOSPICE_PROVIDER]: 'Hospice Provider',
  [CR.CONTACT_RELATIONSHIP_RESPONSIBLE_PERSON]: 'Responsible Person',
  [CR.CONTACT_RELATIONSHIP_FINANCIAL_PAYMENT_OF_CARE]: 'Payment of Care',
  [CR.CONTACT_RELATIONSHIP_FINANCIAL_POWER_OF_ATTORNEY]:
    'Financial Power of Attorney',
  [CR.CONTACT_RELATIONSHIP_MEDICAL_POWER_OF_ATTORNEY]:
    'Medical Power of Attorney',
  [CR.CONTACT_RELATIONSHIP_C]: 'Emergency Contact',
}

export const ROLE_RELATIONSHIP_LIST = Object.keys(ROLE_RELATIONSHIP_MAP) as CR[]

export const ALL_RELATIONSHIP_MAP: Partial<Record<CR, string>> = {
  ...NO_ROLE_RELATIONSHIP_MAP,
  ...ROLE_RELATIONSHIP_MAP,
}

export const RELATIONSHIPS_BY_CATEGORY_MAP: Partial<Record<CR, CR[]>> = {
  [CR.CONTACT_RELATIONSHIP_PERSONAL]: PERSONAL_RELATIONSHIP_LIST.filter(
    (r) =>
      r !== CR.CONTACT_RELATIONSHIP_PERSONAL &&
      r !== CR.CONTACT_RELATIONSHIP_FAMMEMB
  ),
  [CR.CONTACT_RELATIONSHIP_CLINICIAN]: CLINICIAN_RELATIONSHIP_LIST.filter(
    (r) => r !== CR.CONTACT_RELATIONSHIP_CLINICIAN
  ),
  [CR.CONTACT_RELATIONSHIP_PROFESSIONAL]: PROFESSIONAL_RELATIONSHIP_LIST.filter(
    (r) => r !== CR.CONTACT_RELATIONSHIP_PROFESSIONAL
  ),
}

export const RELATIONSHIPS_HASH_BY_CATEGORY_MAP = {
  [CR.CONTACT_RELATIONSHIP_PERSONAL]: PERSONAL_RELATIONSHIP_MAP,
  [CR.CONTACT_RELATIONSHIP_CLINICIAN]: CLINICIAN_RELATIONSHIP_MAP,
  [CR.CONTACT_RELATIONSHIP_PROFESSIONAL]: PROFESSIONAL_RELATIONSHIP_MAP,
}

export const PRIMARY_RELATIONSHIP_LIST = Object.keys(
  RELATIONSHIPS_HASH_BY_CATEGORY_MAP
)

export const SUB_RELATIONSHIP_LIST: CR[] = ([] as CR[]).concat(
  ...Object.values(RELATIONSHIPS_BY_CATEGORY_MAP)
)

export const SUB_RELATIONSHIP_MAP: Partial<Record<CR, string>> =
  SUB_RELATIONSHIP_LIST.reduce((hash, k) => {
    hash[k] = ALL_RELATIONSHIP_MAP[k]
    return hash
  }, {})
