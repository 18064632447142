import { BasicCheckbox } from '@shared/components/BasicInput/BasicInput'
import Card from '@shared/components/Card'
import { SmallDotDivider } from '@shared/components/DotDivider'

export default function OnAlertFilter({
  alertCount,
  newAlertCount,
  filterOnAlert,
  setFilterOnAlert,
}: {
  alertCount: number
  newAlertCount: number
  filterOnAlert: boolean
  setFilterOnAlert: React.Dispatch<React.SetStateAction<boolean>>
}) {
  return (
    <Card className="mb-[16px] text-[14px] leading-[20px]">
      <BasicCheckbox
        labelClassName="mb-0"
        data-testid="filter-on-alert"
        name="filter-on-alert"
        checked={filterOnAlert}
        onChange={(e) => setFilterOnAlert(e.currentTarget.checked)}
      >
        <span data-testid="alert-count">
          {`${alertCount} ${alertCount > 1 ? 'residents' : 'resident'}`}
        </span>
        <span className="text-tags-alert">&nbsp;On Alert</span>
        {newAlertCount > 0 && (
          <span className="text-gray-07">
            <SmallDotDivider />
            <span data-testid="new-alert-count">
              {`${newAlertCount} new since yesterday`}
            </span>
          </span>
        )}
      </BasicCheckbox>
    </Card>
  )
}
