import { PayerSettingsData } from '@shared/types/billing'
import { tw } from '@shared/utils/tailwind'
import { getBankName, getLastFourDigits } from './helpers'

type Props = {
  billingPayerUserSettings: PayerSettingsData
}
export default function BankInfo({ billingPayerUserSettings }: Props) {
  return (
    <div className={tw`flex flex-col`}>
      <span
        data-testid="bank-name"
        className={tw`text-sm font-semibold text-gray-04`}
      >
        {getBankName(billingPayerUserSettings)}
      </span>
      <span
        data-testid="last4"
        className={tw`text-sm font-medium text-gray-07`}
      >
        {getLastFourDigits(billingPayerUserSettings) && (
          <>Ending in {getLastFourDigits(billingPayerUserSettings)}</>
        )}
      </span>
    </div>
  )
}
