import { PayerSettingsData } from '@shared/types/billing'

export function getBankName(billingPayerUserSettings: PayerSettingsData) {
  return (
    billingPayerUserSettings.paymentMethod?.ach?.bankName ||
    'No payment method provided'
  )
}

export function getLastFourDigits(billingPayerUserSettings: PayerSettingsData) {
  return billingPayerUserSettings.paymentMethod?.ach?.accountNumberLast4
}
