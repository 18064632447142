import { AppraisalSettings_Level } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import {
  AssessmentLevel,
  LevelModalMode,
} from '@shared/types/assessment_configuration'
import styles from '../styles.module.css'
import { ADDED } from '../helpers'
import {
  getAddEditLevelModalDefaultValues,
  getCurrentlyImplementedLevels,
  SUPPORTED_LEVELS,
} from './helpers'

type LevelModalForm = {
  description: string
  score: number
  level: AppraisalSettings_Level
}
type Props = {
  setShowModal: (mode: LevelModalMode | undefined) => void
  mode?: LevelModalMode
  currentLevels: AssessmentLevel[]
}

export const AddEditLevelModal = ({
  setShowModal,
  mode,
  currentLevels,
}: Props) => {
  const showModal = !!mode

  const defaultVals = getAddEditLevelModalDefaultValues({
    mode,
  })

  const {
    reset,
    handleSubmit,
    register,
    formState,
    control,
    getValues,
    setValue,
  } = useForm<LevelModalForm>({
    mode: 'onChange',
    defaultValues: defaultVals ?? {},
  })

  useEffect(() => {
    reset()
    if (showModal && defaultVals) {
      setValue('description', defaultVals.description!)
      setValue('level', defaultVals.level!)
      setValue('score', defaultVals.score!)
    }
  }, [showModal])

  const currentLevelTypes = getCurrentlyImplementedLevels(currentLevels)
  const options: OptionTypeBase[] = Object.values(AppraisalSettings_Level)
    .filter((v) => SUPPORTED_LEVELS.includes(v))
    .map((v) => ({
      label: v,
      value: v,
      isDisabled: currentLevelTypes.includes(v),
    }))
  const onSubmit = () => {
    if (mode?.tag === 'edit') {
      mode.editLevel({
        updatedLevel: {
          description: getValues('description'),
          score: getValues('score'),
          level: getValues('level'),
          tempId: mode.currentLevel.tempId,
        },
        index: mode.index,
      })
    } else if (mode?.tag === 'add') {
      mode.addLevel({
        newLevel: {
          description: getValues('description'),
          score: getValues('score'),
          level: getValues('level'),
          tempId: ADDED,
        },
      })
    }
    setShowModal(undefined)
  }

  return (
    <>
      {showModal && (
        <AnimatedPopup
          title={`${mode!.tag} Level`}
          containerClassName={styles.detailModal}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <LabelAboveInput
              htmlFor={'description'}
              subLabel={requiredLabel(!!formState.errors.description)}
            >
              Description
            </LabelAboveInput>
            <BasicInput
              type={'text'}
              placeholder={'Description'}
              data-testid={'level-description-input'}
              {...register('description', { required: true })}
            />
            <LabelAboveInput
              htmlFor={'score'}
              subLabel={requiredLabel(!!formState.errors.score)}
            >
              Score
            </LabelAboveInput>
            <BasicInput
              type={'number'}
              placeholder={'Score'}
              min={0}
              defaultValue={0}
              data-testid={'level-score-input'}
              {...register('score', { required: true })}
            />
            <LabelAboveInput
              htmlFor={'level'}
              subLabel={requiredLabel(!!formState.errors.level)}
            >
              Level
            </LabelAboveInput>
            <Controller
              control={control}
              name="level"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StyledSelect
                  placeholder={'Select a level...'}
                  name={'level'}
                  options={options}
                  onChange={(opt: OptionTypeBase | null) =>
                    onChange(opt?.value || null)
                  }
                  value={options.find((opt) => opt!.value === value)}
                  isDisabled={mode?.tag === 'edit'}
                  inputId={'level'}
                />
              )}
            />
            <div className={styles.footer}>
              <AnimatedPopupFormFooter
                yesBtn={{
                  label: 'Save',
                  props: {
                    disabled: !formState.isValid,
                  },
                }}
                noBtn={{
                  action: () => setShowModal(undefined),
                }}
                formState={formState}
              />
            </div>
          </form>
        </AnimatedPopup>
      )}
    </>
  )
}
