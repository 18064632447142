import { useContext, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { fetchTasks } from '@shared/api/task'
import GlobalContext from '@shared/contexts/GlobalContext'
import { DataType } from '@shared/types/snapshot'
import { TaskType } from '@shared/types/task'
import PersonContext from '@app/contexts/PersonContext'
import { CONFIG } from './index'

export default function TaskLoader({ formProps }: { formProps: any }) {
  const { taskId } = formProps.match.params.id
  const currentTask = useCurrentTask(taskId)

  if (currentTask === undefined || !currentTask.taskTemplateInfo) {
    return <div>'Form not found'</div>
  }

  const { taskTemplateInfo: template } = currentTask
  const configElement = CONFIG[template.taskType!]

  if (configElement && configElement.Ui) {
    const Ui = configElement.Ui

    let additionalProps = {}
    if (template?.taskType === TaskType.TASK_TYPE_CUSTOM_SIGNABLE_FORM) {
      additionalProps = {
        defaultForm: null,
        dataType: DataType.DATA_TYPE_CUSTOM_SIGNABLE_FORM,
        customType: template?.customType,
      }
    }

    return <Ui {...formProps} {...additionalProps} />
  } else {
    return <Redirect to="/" />
  }
}

function useCurrentTask(taskId: string) {
  const { setError } = useContext(GlobalContext)
  const { person, setTasks, tasks } = useContext(PersonContext)

  useEffect(() => {
    if (person) {
      const taskInMemory = (tasks || []).find((t) => t.id === taskId)

      if (taskInMemory) {
        return
      }

      fetchTasks({ orgId: person.orgId || '', pId: person.id || '' })
        .then((res) => setTasks(res && res.data))
        .catch(setError)
    }
  }, [taskId])

  return (tasks || []).find((t) => t.id === taskId)
}
