import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'
import { tw, twx } from '@shared/utils/tailwind'

export function EmbossedCard({
  children,
  className,
  ...rest
}: React.HTMLProps<HTMLDivElement> & {
  className?: string
}) {
  return (
    <div
      className={tw`rounded-[8px] border border-secondary-10 bg-secondary-12 p-[24px] shadow-card ${className}`}
      {...rest}
    >
      {children}
    </div>
  )
}

export function ClickableEmbossedCard({
  buttonProps,
  cardProps,
  children,
}: {
  buttonProps: DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
  cardProps: React.HTMLProps<HTMLDivElement>
  children: React.ReactNode
}) {
  const { className: buttonClassName, ...buttonRest } = buttonProps
  const canClick = Boolean(buttonProps.onClick)
  const card = (
    <EmbossedCard
      {...cardProps}
      className={twx(cardProps.className, {
        'hover:card-outline': canClick,
      })}
    >
      {children}
    </EmbossedCard>
  )

  if (canClick) {
    return (
      <button
        {...buttonRest}
        className={twx('block w-full text-left', buttonClassName)}
      >
        {card}
      </button>
    )
  }

  return card
}
