import { Contact, Contact_BillingStatementMethod } from '@shared/types/contact'
import { Person } from '@shared/types/person'
import { formatSingleLineAddress } from '@shared/utils/address'
import { primaryEmail } from '@shared/utils/contact'
import { getRoomNumber } from '@shared/utils/person'
import { tw } from '@shared/utils/tailwind'
import { ContactFormData } from '@app/pages/Contacts/ContactModal/types'

type Props = {
  submitFn: (data: ContactFormData) => Promise<void>
  contact: Contact
  statementDelivery: Contact_BillingStatementMethod
  person: Person
}

function getAttrs({
  statementDelivery,
  contact,
  person,
}: {
  statementDelivery: Contact_BillingStatementMethod
  contact: Contact
  person?: Person
}): [string, string] | [] {
  switch (statementDelivery) {
    case Contact_BillingStatementMethod.BILLING_STATEMENT_METHOD_EMAIL:
      return ['Email to: ', primaryEmail(contact)!]
    case Contact_BillingStatementMethod.BILLING_STATEMENT_METHOD_MAIL:
      return ['Mail to: ', formatSingleLineAddress(contact.address)!]
    case Contact_BillingStatementMethod.BILLING_STATEMENT_METHOD_PRINT:
      return ['Deliver to room: ', getRoomNumber(person!) || '']
    default:
      return []
  }
}

export default function ContactInfo({
  statementDelivery,
  contact,
  person,
}: Props) {
  const attrs = getAttrs({ statementDelivery, contact, person })

  const [verb, value] = attrs
  return (
    <>
      <div className={tw`flex w-full items-center justify-between p-4 py-6`}>
        <span className={tw`text-sm font-semibold text-gray-04`}>
          {verb}
          <span className={tw`text-gray-07`}>{value}</span>
        </span>
      </div>
    </>
  )
}
