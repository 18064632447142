import { useContext, useEffect, useState } from 'react'
import { getResidentBillingSummary } from '@shared/api/billing'
import GlobalContext from '@shared/contexts/GlobalContext'
import { BillingResidentSummary } from '@shared/types/billing'
import { loaded, LOADING, Loading } from '@shared/utils/loading'

export default function useBillingResidentSummary({
  orgId,
  facilityId,
  personId,
}: {
  orgId: string
  facilityId: string
  personId: string
}) {
  const { setError } = useContext(GlobalContext)
  const [billingSummary, setBillingSummary] =
    useState<Loading<BillingResidentSummary>>(LOADING)

  async function reloadBillingSummary() {
    setBillingSummary(LOADING)
    try {
      const newBillingSummary = await getResidentBillingSummary({
        orgId,
        facilityId,
        personId,
      })
      setBillingSummary(loaded(newBillingSummary))
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    void reloadBillingSummary()
  }, [orgId, facilityId, personId])

  return {
    billingSummary,
    reloadBillingSummary,
  }
}
