import { SignatureInfoWithSkip } from '@shared/api/task'

export function toggleSkip(signer: SignatureInfoWithSkip) {
  return {
    ...signer,
    signer: {
      ...signer.signer,
      skip: !signer.signer?.skip,
    },
  }
}
