import { Control, Controller } from 'react-hook-form'
import { LabelAboveInput } from '@shared/components/Labels'
import { RHFSwitch as Switch } from '@app/components/Switch'

type Props = {
  control: Control<any>
  id: string
  label: string
  name: string
  switchLabel: string
  value?: boolean
}

export default function ToggleSwitch({
  control,
  id,
  label,
  name,
  switchLabel,
}: Props) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={false}
      render={({ field: { onChange, value } }) => (
        <section className="mt-[40px]">
          <LabelAboveInput htmlFor={name}>{label}</LabelAboveInput>
          <Switch
            checked={value}
            id={id}
            label={switchLabel}
            onChange={() => onChange(!value)}
            switchSize={'small'}
          />
        </section>
      )}
    />
  )
}
