import ButtonLink from '@shared/components/ButtonLink'
import Icon from '@shared/components/Icon'
import { tw } from '@shared/utils/tailwind'
import { MedicationLog } from '@app/components/Residents/Medications/MedicationLogModal/types'
import styles from './styles.module.css'

export default function MedicationCounter({
  medication,
  index,
  decreaseQuantity,
  increaseQuantity,
}: {
  medication: MedicationLog
  index: number
  decreaseQuantity: (i: number) => void
  increaseQuantity: (i: number) => void
}) {
  return (
    <div className={styles.quantityButtons}>
      <ButtonLink
        className={tw`text-[16px] text-gray-09`}
        onClick={() => increaseQuantity(index)}
      >
        <Icon name="plus-square" />
      </ButtonLink>
      <span className={styles.quantity}>{medication.quantity}</span>
      <ButtonLink
        className={tw`text-[16px] text-gray-09 disabled:opacity-25`}
        onClick={() => decreaseQuantity(index)}
        disabled={medication.quantity === 0}
      >
        <Icon name="minus-square" />
      </ButtonLink>
    </div>
  )
}
