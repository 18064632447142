import { intersection, sortBy } from 'lodash'
import React from 'react'
import { Snapshot } from '@shared/types/snapshot'
import {
  GtkyFields,
  GtkySnapshotFields,
  GtkySnapshotPatch,
} from '@shared/utils/gtky'
import FormSectionTitle from '@app/components/FormSectionTitle'
import styles from '../gtky.module.css'
import sections from './constants'

export default function ActivitiesSection({
  snapshot,
  setFields,
  fields,
  saveSnapshot,
  isEditing,
}: {
  snapshot: Snapshot
  setFields: React.Dispatch<React.SetStateAction<GtkySnapshotPatch>>
  fields: GtkySnapshotPatch
  saveSnapshot: (formValues: any) => Promise<void>
  isEditing: boolean
}) {
  return (
    <>
      {sections.map((section) => {
        const keys = section.options.map((o) => o.value)
        const activityValues = defaultOrCurrentValues(fields, snapshot)
        const hasValuesForSection = isNonEmptySection(keys, activityValues)

        if (!isEditing && !hasValuesForSection) {
          return null
        }

        const sortedOptions = sortBy(section.options, 'label')

        return (
          <React.Fragment key={`as-${section.title}`}>
            <FormSectionTitle
              title={section.title}
              className={'mb-[4px]8 mt-[48px]'}
            />
            {sortedOptions.map((o) => (
              <ActivityRow
                key={`a-${o.value}`}
                isEditing={isEditing}
                activityName={o}
                snapshot={snapshot}
                setFields={setFields}
                fields={fields}
                saveSnapshot={saveSnapshot}
              />
            ))}
          </React.Fragment>
        )
      })}
    </>
  )
}

function defaultOrCurrentValues(fields: GtkySnapshotPatch, snapshot: Snapshot) {
  return (fields[GtkyFields.ACTIVITIES] ||
    (snapshot!.data?.formData?.oneToManyFields?.[GtkyFields.ACTIVITIES]
      ?.values ??
      [])) as string[]
}

function isNonEmptySection(keys: string[], activityValues: string[]) {
  return (
    intersection(
      keys,
      activityValues.map((av) => av.split('/')[0])
    ).length > 0
  )
}

function ActivityRow({
  snapshot,
  setFields,
  fields,
  saveSnapshot,
  activityName,
  isEditing,
}: {
  isEditing: boolean
  activityName: { value: string; label: string }
  snapshot: Snapshot
  setFields: React.Dispatch<React.SetStateAction<GtkySnapshotPatch>>
  fields: GtkySnapshotPatch
  saveSnapshot: (formValues: any) => Promise<void>
}) {
  const key = activityName.value
  const activityValues = defaultOrCurrentValues(fields, snapshot)

  if (!isEditing && !activityValues.some((v) => v.startsWith(key))) {
    return null
  }

  return (
    <div className={'mb-[16px] flex items-center justify-between'}>
      <div
        className={
          'text-[14px] font-semibold leading-[18px] text-heading-font-color'
        }
      >
        {activityName.label}
      </div>
      <ul className={styles.activityButtons}>
        <li>
          <input
            disabled={!isEditing}
            type={'radio'}
            id={`activity-${key}-al`}
            name={`activity-${key}`}
            defaultChecked={activityValues.includes(`${key}/like`)}
            onChange={() => {
              const newFields = {
                ...fields,
                [GtkySnapshotFields.ACTIVITIES]: changeActivityValue(
                  key,
                  activityValues,
                  'like'
                ),
              }

              setFields(newFields)
              void saveSnapshot(newFields)
            }}
          />
          <label htmlFor={`activity-${key}-al`}>Already Like</label>
        </li>
        <li>
          <input
            disabled={!isEditing}
            type={'radio'}
            id={`activity-${key}-wtt`}
            name={`activity-${key}`}
            defaultChecked={activityValues.includes(`${key}/want-to-try`)}
            onChange={() => {
              const newFields = {
                ...fields,
                [GtkySnapshotFields.ACTIVITIES]: changeActivityValue(
                  key,
                  activityValues,
                  'want-to-try'
                ),
              }

              setFields(newFields)
              void saveSnapshot(newFields)
            }}
          />
          <label htmlFor={`activity-${key}-wtt`}>Want to Try</label>
        </li>
      </ul>
    </div>
  )
}

function changeActivityValue(
  key: string,
  activityValues: string[],
  newValue: 'like' | 'want-to-try'
) {
  return [
    ...activityValues.filter((v) => !v.startsWith(key)),
    `${key}/${newValue}`,
  ]
}
