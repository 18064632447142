import { match } from 'ts-pattern'
import { Order, sortNumberString } from '@shared/utils/common'

export { Order } from '@shared/utils/common'

export type SortableValue = Date | string | number | undefined | null

type SortResult = -1 | 0 | 1

const numberToSortResult = (n: number): SortResult =>
  match(n)
    .when(
      (v) => v < 0,
      () => -1 as const
    )
    .when(
      (v) => v === 0,
      () => 0 as const
    )
    .when(
      (v) => v > 0,
      () => 1 as const
    )
    .run()

export function sortStr({
  strA,
  strB,
  sortingOrder,
}: {
  strA?: string
  strB?: string
  sortingOrder: Order
}): SortResult {
  if (!strA && !strB) return 0
  if (!strA) return 1
  if (!strB) return -1

  if (sortingOrder === Order.ASC) {
    return numberToSortResult(strA.localeCompare(strB))
  }

  return numberToSortResult(strB.localeCompare(strA))
}

export function sortNumber({
  numA,
  numB,
  sortingOrder,
}: {
  numA?: number
  numB?: number
  sortingOrder: Order
}): SortResult {
  if (!numA && !numB) return 0
  if (numA === undefined) return -1
  if (numB === undefined) return 1

  if (sortingOrder === Order.ASC) {
    return numberToSortResult(numA - numB)
  }
  return numberToSortResult(numB - numA)
}

function isNoZeroEmpty(val: SortableValue) {
  return val === undefined || val === null || val === ''
}

/*
 Sort Priority
 - No 0 empty value at end
 - Two strings will be sorted as alpha number if applicable
 - default sort by operator > (greater)
 */
export function alphaNumericSort(
  val1: SortableValue,
  val2: SortableValue,
  order: Order = Order.ASC
): SortResult {
  // Empty value other than 0 will always be at the end
  if (isNoZeroEmpty(val1) && isNoZeroEmpty(val2)) {
    return 0
  } else if (isNoZeroEmpty(val1)) {
    return 1
  } else if (isNoZeroEmpty(val2)) {
    return -1
  }

  if (typeof val1 === 'string' && typeof val2 === 'string') {
    return numberToSortResult(sortNumberString(val1, val2) * order)
  }

  if ((val1 || 0) > (val2 || 0)) {
    return numberToSortResult(order)
  }

  return numberToSortResult(-order)
}
