import { isAfter, isBefore } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { Instant, LocalDateTime } from '@shared/types/careapp'

export function isBeforeInZone(a: Instant, b: LocalDateTime, timeZone: string) {
  return isBefore(a, zonedTimeToUtc(b, timeZone))
}

export function isAfterInZone(a: Instant, b: LocalDateTime, timeZone: string) {
  return isAfter(a, zonedTimeToUtc(b, timeZone))
}
