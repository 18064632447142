import { createContext, useContext } from 'react'
import {
  SignableFormMetaData,
  SignableFormWithAssignments,
} from '@shared/types/signable_form'
import { LoadingWithPriorValue } from '@shared/utils/loading'
import { TemplateLimit } from '@app/pages/Tools/PdfTemplate/helpers'

export interface SignableFormContextProps {
  signableFormsWithAssignments: LoadingWithPriorValue<
    SignableFormWithAssignments[]
  >
  signableForms: LoadingWithPriorValue<SignableFormMetaData[]>
  reloadSignableForms: (form?: SignableFormWithAssignments) => Promise<void>
  recentSignableFormsWithAssignments: LoadingWithPriorValue<
    SignableFormWithAssignments[]
  >
  recentSignableForms: LoadingWithPriorValue<SignableFormMetaData[]>
  reloadRecentSignableForms: (
    form?: SignableFormWithAssignments
  ) => Promise<void>
}

const defaultValues: SignableFormContextProps = {
  signableFormsWithAssignments: { tag: 'Loading' },
  signableForms: { tag: 'Loading' },
  reloadSignableForms: () => Promise.resolve(),
  recentSignableFormsWithAssignments: { tag: 'Loading' },
  recentSignableForms: { tag: 'Loading' },
  reloadRecentSignableForms: () => Promise.resolve(),
}

const exportedContext = createContext(defaultValues)
export default exportedContext

export function useSignableFormContext(templates: TemplateLimit) {
  const {
    signableFormsWithAssignments,
    signableForms,
    reloadSignableForms,
    recentSignableFormsWithAssignments,
    recentSignableForms,
    reloadRecentSignableForms,
  } = useContext(exportedContext)

  return {
    signableFormsWithAssignments:
      templates === 'All Templates'
        ? signableFormsWithAssignments
        : recentSignableFormsWithAssignments,
    reloadSignableForms:
      templates === 'All Templates'
        ? reloadSignableForms
        : reloadRecentSignableForms,
    signableForms:
      templates === 'All Templates' ? signableForms : recentSignableForms,
  }
}
