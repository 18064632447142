import { AugustFieldFormConfigurationMap } from '@shared/constants/custom_field/type'
import { AugustFieldType } from '@shared/types/custom_field'

export const ADMISSIONS_INFORMATION: AugustFieldFormConfigurationMap = {
  [AugustFieldType.AUGUST_FIELD_TYPE_INITIAL_RENT]: {
    fieldLists: ['admissionsInformation/initialIntendedRate'],
    requiredFields: ['admissionsInformation.initialIntendedRate'],
    fields: [
      {
        title: 'OVERALL RATE ($)',
        name: 'admissionsInformation.initialIntendedRate',
        type: 'money',
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_IS_SINGLE_ROOM]: {
    fieldLists: ['admissionsInformation/assignedRoomType'],
    requiredFields: ['admissionsInformation.assignedRoomType'],
    fields: [
      {
        title: 'ROOM TYPE',
        name: 'admissionsInformation.assignedRoomType',
        type: 'buttons',
        options: [
          { label: 'Single', value: 'ROOM_TYPE_SINGLE' },
          { label: 'Double', value: 'ROOM_TYPE_DOUBLE' },
        ],
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_ROOM_NAME]: {
    fieldLists: ['admissionsInformation/bedId'],
    requiredFields: ['admissionsInformation.bedId'],
    fields: [
      {
        title: 'ROOM NUMBER',
        name: 'admissionsInformation.bedId',
        type: 'RoomBedSelect',
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_START_DATE]: {
    fieldLists: [
      'admissionsInformation/startDate',
      'admissionsInformation/startRange', // Do Not Remove, need for XOR validation between startDate and startRange
    ],
    requiredFields: ['admissionsInformation.startDate'],
    fields: [
      {
        type: 'datepicker',
        title: 'MOVE-IN DATE',
        name: 'admissionsInformation.startDate',
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_FINANCIAL_START_DATE]: {
    fieldLists: ['admissionsInformation/financialStartDate'],
    requiredFields: ['admissionsInformation.financialStartDate'],
    fields: [
      {
        type: 'datepicker',
        title: 'FINANCIAL EFFECTIVE START DATE',
        name: 'admissionsInformation.financialStartDate',
      },
    ],
  },
}
