export const MedOrderCardTitle = ({
  value,
}: {
  value: string | React.ReactNode
}) => {
  return (
    <div
      className={
        'flex flex-row items-center text-[14px] font-semibold capitalize leading-[24px] text-secondary-04'
      }
    >
      {value}
    </div>
  )
}
