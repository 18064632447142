import { ReactNode, useContext, useEffect, useState } from 'react'
import { listFacilities } from '@shared/api/facility'
import Card from '@shared/components/Card'
import GlobalContext from '@shared/contexts/GlobalContext'
import { tw } from '@shared/utils/tailwind'
import {
  DashboardRecord,
  fetchOrganizationDashboards,
} from '@app/api/organizations'
import RouterTabs from '@app/components/RouterTabs'
import FacilitiesContext from '@app/contexts/FacilitiesContext'
import RemoteDashboard from '@app/pages/Facilities/Insights/RemoteDashboard'
import { DashboardProps, ORGANIZATION_DASHBOARD_PAGE_PATH } from './type'

export default function Dashboard({ match }: DashboardProps) {
  const { orgId, facilityId, tab } = match.params
  const { facilities = [], setFacilities } = useContext(FacilitiesContext)
  const { setError } = useContext(GlobalContext)
  const [dashboardRecords, setDashboardRecords] = useState<
    DashboardRecord[] | undefined
  >()

  useEffect(() => {
    if (orgId) {
      if (facilities.length === 0) {
        listFacilities(orgId).then(setFacilities).catch(setError)
      }

      fetchOrganizationDashboards(orgId)
        .then(setDashboardRecords)
        .catch(setError)
    }
  }, [orgId])

  let content: ReactNode = 'Loading...'
  if (Array.isArray(dashboardRecords)) {
    const len = dashboardRecords.length
    if (len === 0) {
      content = 'Dashboard not found...'
    } else if (len === 1) {
      content = (
        <RemoteDashboard orgId={orgId} dashboardRecord={dashboardRecords[0]} />
      )
    } else if (len > 1) {
      const labels = dashboardRecords.map(({ label }) => label)
      const tabList = labels.map((label, index) => {
        return {
          label,
          url: ORGANIZATION_DASHBOARD_PAGE_PATH.replace(':orgId', orgId)
            .replace(':facilityId', facilityId)
            .replace(':tab', (index + 1).toString()),
        }
      })
      const tabIndex = !tab || isNaN(Number(tab)) ? 0 : Number(tab) - 1
      content = (
        <>
          <Card className={tw`m-0 mb-[24px] p-0`}>
            <RouterTabs
              className={tw`rounded-t-[8px]`}
              activeTab={labels[tabIndex]}
              tabList={tabList}
            />
          </Card>
          <RemoteDashboard
            orgId={orgId}
            dashboardRecord={dashboardRecords[tabIndex]}
          />
        </>
      )
    }
  }

  return (
    <section className={tw`ml-[12px] mr-[12px] mt-[12px]`}>{content}</section>
  )
}
