import { intersectionWith } from 'lodash'
import { MedGroup } from '@shared/types/api/med_group'
import { CareGroup } from '@shared/types/care_group'

/**
 * Functions original used for Care Groups, but since the logics are same for Med Groups
 * will be reused for Med Groups without the need to create /src/models/functions/careGroup.ts
 */

export type FacilityGroupType = 'care' | 'med'

export function isDuplicateGroupName({
  groups,
  index,
  name,
}: {
  groups: CareGroup[] | MedGroup[]
  index: number
  name: string
}) {
  const allNames = groups.map((c: CareGroup | MedGroup) => c.name)
  const firstIndex = allNames.indexOf(name)
  if (firstIndex !== -1) {
    const lastIndex = allNames.lastIndexOf(name)
    if (firstIndex !== lastIndex && firstIndex !== index) {
      return true
    }
  }

  return false
}

export function hasDuplicateGroupName(groups: CareGroup[] | MedGroup[]) {
  const names = groups.map((c: CareGroup | MedGroup) => c.name)
  const nameSet = new Set(names)

  return names.length > nameSet.size
}

export function getUpdatedGroup(
  newGroups: CareGroup[] | MedGroup[],
  originalGroups: CareGroup[] | MedGroup[]
) {
  return intersectionWith(newGroups, originalGroups, (a, b) => {
    return a.id === b.id && a.name !== b.name
  })
}

export function hasEmptyGroupName(groups: CareGroup[] | MedGroup[]) {
  return groups.some((c, i, list) => {
    const len = list.length
    if (i === len - 1) {
      return false // Ignore new care group input, it can be empty.
    }

    return !c.name || c.name.trim().length === 0
  })
}
