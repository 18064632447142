import { Contact } from '@augusthealth/models/com/august/protos/contact'
import { useEffect } from 'react'
import ContactDropdownList, {
  ContactDropdownListProps,
} from '@app/components/formElements/ContactDropdownList'

type CustomContactDropdownListProps = Omit<
  ContactDropdownListProps,
  'value'
> & {
  value: string[]
}

// This is a drop down for updating a field with a list of contact ids
export function CustomContactDropdownList(
  props: CustomContactDropdownListProps
) {
  const { person, onUpdate, value = [], name, ...remainingProps } = props
  const contactUpdate = (data: Contact, name: string) => {
    onUpdate(data.id!, name)
  }

  const onRemove = (contact: Contact) => {
    const updatedValues = value.filter((c) => c !== contact.id)
    onUpdate(updatedValues, name)
  }

  useEffect(() => {
    if (person && value) {
      const contactIds = (person.contact || []).map((c) => c.id)
      const foundContacts = value.filter((cid) => contactIds.includes(cid))
      if (foundContacts.length < value.length) {
        // Remove any contacts that no longer exist
        onUpdate(foundContacts, name)
      }
    }
  }, [value, person])

  function onAddContact(id: number) {
    onUpdate([...value, id.toString()], name)
  }

  const mappedValue = value
    .map((cid) => person.contact?.find((c) => c.id === cid))
    .filter((c) => c) as Contact[]

  return (
    <ContactDropdownList
      {...remainingProps}
      person={person}
      onUpdate={contactUpdate}
      value={mappedValue}
      useStandardUpdate
      name={name}
      onAddContact={onAddContact}
      onRemove={onRemove}
    />
  )
}
