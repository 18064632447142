import { useContext } from 'react'
import { deleteRecurringCharge, deleteTransaction } from '@shared/api/billing'
import GlobalContext from '@shared/contexts/GlobalContext'
import { BillingEvent } from '@shared/types/billing'
import { RequiredPersonIds } from '@shared/types/person'
import {
  formatCurrencyForBilling,
  isPendingOneTimeCharge,
  isPendingRecurringCharge,
} from '@shared/utils/billing'
import ConfirmModal from '@app/components/ConfirmModal'
import { ModalProps } from '../helpers'

type BasicProps = Pick<ModalProps, 'closeFn' | 'doneFn'> & {
  personIds: RequiredPersonIds
}

type Props = BasicProps & {
  transaction: BillingEvent
}

export default function DeleteConfirmModal(props: Props) {
  const { setError } = useContext(GlobalContext)
  const { transaction, closeFn, doneFn, personIds } = props
  const { orgId, facilityId, id: personId } = personIds
  const { amountCents, description } = transaction
  const isOneTime = isPendingOneTimeCharge(transaction)
  const isRecurring = isPendingRecurringCharge(transaction)
  const onDelete = async () => {
    if (isOneTime) {
      deleteTransaction({
        personIds: { orgId, facilityId, id: personId },
        invoiceItemId: transaction.id,
      })
        .then(doneFn)
        .catch(setError)
        .finally(closeFn)
    } else if (isRecurring) {
      deleteRecurringCharge({
        personIds: { orgId, facilityId, id: personId },
        chargeId: transaction.id,
      })
        .then(doneFn)
        .catch(setError)
        .finally(closeFn)
    }
  }

  if (isOneTime || isRecurring) {
    return (
      <ConfirmModal
        title={
          isRecurring
            ? 'Cancel this recurring charge?'
            : 'Delete this one time charge?'
        }
        confirmButtonConfig={{
          onClick: onDelete,
          children: isRecurring ? 'Yes, cancel' : 'Yes, delete',
        }}
        denyButtonConfig={{
          onClick: closeFn,
        }}
        content={
          <div>
            {description}
            {' - '}
            {amountCents !== undefined && formatCurrencyForBilling(amountCents)}
          </div>
        }
      />
    )
  }

  return null
}
