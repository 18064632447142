import styles from './styles.module.css'
import BaseUI from './index'

type Props = {
  continueAndClose: () => void
}

export default function NewResident({ continueAndClose }: Props) {
  return (
    <BaseUI
      content={
        <>
          <h4>You have a new resident moving in!</h4>
          Please review the&nbsp;<strong>Documents</strong>,&nbsp;
          <strong>Medications</strong>, and
          <br />
          <strong>Billing</strong> sections, as well as any remaining open
          tasks.
        </>
      }
      footer={
        <button
          id="congrats-continue"
          className={`rounded-[6px] bg-button-primary-color px-[12px] py-[11px] text-[11px] font-bold leading-[14px] text-button-fill-font-color hover:brightness-125  ${styles.largeButton}`}
          onClick={continueAndClose}
        >
          GOT IT
        </button>
      }
    />
  )
}
