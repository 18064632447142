import { Route, Switch } from 'react-router-dom'
import {
  TOOLS_PDF_TEMPLATE_LIST_PATHS,
  TOOLS_PDF_TEMPLATE_ORG_PATH,
} from '@app/pages/Tools/path'
import GlobalStateTasksAndOrgList from './GlobalStateTasksAndOrgList'
import OrgFacilityTasks from './OrgFacilityTasks'

export default function AssignTemplates() {
  return (
    <>
      <Switch>
        <Route
          exact
          path={TOOLS_PDF_TEMPLATE_ORG_PATH}
          render={(props) => <OrgFacilityTasks {...props} />}
        />
        <Route
          exact
          path={TOOLS_PDF_TEMPLATE_LIST_PATHS}
          render={() => <GlobalStateTasksAndOrgList />}
        />
      </Switch>
    </>
  )
}
