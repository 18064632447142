import { HealthcareService } from '@shared/types/healthcare_service'
import { formatSingleLineAddress } from '@shared/utils/address'
import { getFax, getPhone } from '@shared/utils/contactPoint'

export function getHospitalAddress(hospital: HealthcareService) {
  return formatSingleLineAddress(hospital.location?.[0].address)
}

export function getHospitalPhone(hospital: HealthcareService) {
  return getPhone(hospital.telecom || [])?.value
}

export function getHospitalFax(hospital: HealthcareService) {
  return getFax(hospital.telecom || [])?.value
}
