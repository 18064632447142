import { ImageSize } from '@augusthealth/models/com/august/protos/snapshot'
import { DotDivider } from '@shared/components/DotDivider'
import Icon from '@shared/components/Icon'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import { PersonImage } from '@shared/components/PersonImage'
import { Person } from '@shared/types/person'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { getOrElse, Loading } from '@shared/utils/loading'
import { getRoomNumber, levelOfCareLabel } from '@shared/utils/person'
import { tw } from '@shared/utils/tailwind'

export default function Hero({ person }: { person: Loading<Person> }) {
  const maybePerson = getOrElse(person, { name: {} } as Person)
  const fullName = getFirstAndLastName(maybePerson?.name)
  const roomNumber = getRoomNumber(maybePerson)
  const careLevel = levelOfCareLabel(maybePerson)

  return (
    <div
      className={tw`flex justify-between rounded-[4px] bg-white p-[12px] shadow-card`}
    >
      <div className={tw`flex gap-[16px]`}>
        <div>
          {!person && <SimpleSpinner />}
          {person.tag === 'Complete' && (
            <div className={tw`mr-0 h-[40px] w-[40px] min-w-[40px]`}>
              <PersonImage
                size={ImageSize.IMAGE_SIZE_32X32}
                person={person.value}
              />
            </div>
          )}
        </div>
        <div className={tw`flex flex-col text-[14px]`}>
          <div
            data-testid="person-name"
            className={tw`font-[600] text-secondary-01`}
          >
            {fullName}
          </div>
          <div
            data-testid="person-room-and-care"
            className={tw`font-[500] text-secondary-07`}
          >
            <Icon name="bed" /> {roomNumber}
            <DotDivider />
            Care {careLevel}
          </div>
        </div>
      </div>
      <div className={tw`flex flex-col justify-center text-secondary-07`}>
        <Icon name="chevron-right" variant="regular" />
      </div>
    </div>
  )
}
