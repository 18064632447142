import {
  BasicTextarea,
  BasicTextAreaProps,
} from '@shared/components/BasicInput/BasicInput'

interface LongformAnswerProps extends BasicTextAreaProps {
  mode: 'edit' | 'view'
}

export default function LongformAnswer({
  mode,
  ref,
  ...rest
}: LongformAnswerProps) {
  if (mode === 'edit') {
    return <BasicTextarea {...rest} />
  } else {
    return (
      <div
        className={
          'mb-6 rounded border border-form-input-border bg-form-input-disabled-background-color p-[12px] text-[14px]'
        }
      >
        {rest.defaultValue ?? rest.value}
      </div>
    )
  }
}
