import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { DocumentViewerUploaderFooter } from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { LightboxSidebarTitle } from '@shared/components/AnimatedPopup/Lightbox/Lightbox'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import StyledSelect from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { snapshotFileUrl } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { DataType, Snapshot } from '@shared/types/snapshot'
import { getLastName } from '@shared/utils/humanName'
import { getSnapshotName } from '@shared/utils/snapshot'
import { getTitleFromDataType } from '@shared/utils/task'
import useBlobData from '@app/hooks/useBlobData'
import styles from './styles.module.css'
import { generalUploadOptions } from '../Uploader/constants'
import { defaultValueForInitialType } from '../Uploader/helpers'
import { spaceFree, updateDocument } from './helpers'
import Viewer from './index'
import useDeletable from './useDeletable'

interface Props {
  person: Person
  onComplete: (updated: boolean) => Promise<void>
  document: Snapshot
}

interface FormData {
  documentType: { label: string; value: DataType }
  name: string
}

/**
 * A viewer used to view 'named documents', e.g. Advanced Directives, Insurance
 * Cards, Medical Records, and Other Uploads
 * @param document
 * @param onComplete
 * @param person
 */
export default function NamedViewer({ document, onComplete, person }: Props) {
  const { user } = useUserContext()
  const { setError } = useContext(GlobalContext)
  const { id: personId, orgId } = person
  const { dataType, id: snapshotId } = document
  const { deletable } = useDeletable({
    document,
    onComplete,
    person,
  })

  const { handleSubmit, control, register, formState } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      documentType: defaultValueForInitialType(document.dataType!),
      name: document.data?.uploadInfo?.name,
    },
  })

  const onSubmit = async (data: FormData) => {
    try {
      await updateDocument(person, document, data.name)
      await onComplete(true)
    } catch (e) {
      setError(e)
    }
  }

  const pdfPath = snapshotFileUrl({
    personId: personId!,
    orgId: orgId!,
    dataType: dataType!,
    snapshotId,
  })
  const blobData = useBlobData(pdfPath)
  const documentTitle =
    getSnapshotName(document) || getTitleFromDataType(document.dataType!)
  const filename = spaceFree(
    `${getLastName(person.name)} ${documentTitle} ${
      document.data?.uploadInfo?.name
    }`
  )
  const canUpdate = hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_SNAPSHOT_UPDATE],
  })

  // Outer <Form /> doesn't go well with MUI Modal, need to put handleSubmit on Save button
  return (
    <form>
      <Viewer
        dataType={dataType!}
        deletable={deletable}
        blobData={blobData}
        filename={filename}
        popUpActions={
          <DocumentViewerUploaderFooter
            yesBtn={{
              label: 'Save',
              props: {
                disabled: !canUpdate || formState.isSubmitting,
                onClick: handleSubmit(onSubmit),
              },
            }}
            noBtn={{ action: () => void onComplete(false) }}
            formState={formState}
          />
        }
        onClose={() => onComplete(false)}
      >
        <LightboxSidebarTitle>{documentTitle}</LightboxSidebarTitle>
        <div className={styles.formContainer}>
          <div>
            <LabelAboveInput
              htmlFor="documentType"
              subLabel={requiredLabel(false)}
            >
              <span className={styles.labelText}>Type</span>
            </LabelAboveInput>
            <Controller
              control={control}
              name="documentType"
              render={({ field: { onChange, value } }) => {
                return (
                  <StyledSelect
                    isDisabled={true}
                    value={value}
                    options={generalUploadOptions}
                    name="documentType"
                    onChange={onChange}
                  />
                )
              }}
            />
          </div>
          <div className="mt-[32px]">
            <LabelAboveInput htmlFor="name" subLabel={requiredLabel(false)}>
              Document Title
            </LabelAboveInput>
            <BasicInput {...register('name')} disabled={!canUpdate} />
          </div>
        </div>
      </Viewer>
    </form>
  )
}
