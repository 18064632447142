import { TimeZoneConvertFunc } from '@shared/contexts/CurrentFacilityContext'
import { Signer } from '@shared/types/snapshot'
import { formatDate } from '@shared/utils/date'

export function getSignatureLabel(
  signer: Signer,
  timezoneCoverter?: TimeZoneConvertFunc
) {
  const { signedAt } = signer
  if (signedAt) {
    return `${formatDate(timezoneCoverter ? timezoneCoverter(signedAt) : signedAt)}`
  }

  return 'Pending'
}
