import { TimeZoneConvertFunc } from '@shared/contexts/CurrentFacilityContext'
import {
  Incident,
  IncidentAction,
  IncidentActionStatus,
} from '@shared/types/incidents'
import { Person } from '@shared/types/person'
import { Snapshot } from '@shared/types/snapshot'
import { UserAccount } from '@shared/types/user'
import {
  createdByString,
  icon,
  injuriesString,
  locationString,
  modifiedByString,
  occurredAtString,
  title,
} from '@shared/utils/incident'
import { createIncidentComment, updateIncidentAction } from '@app/api/incidents'

interface SharedIncidentAttributes {
  icon: string
  title: string
  created: string | undefined
  modification: string | undefined
  note: string | undefined
  date: string
}

export type FrontendIncident = {
  id: string | undefined
  tag: 'Incident'
  location: string | undefined
  injuries: string | undefined
  followUpActions: IncidentAction[]
  attachments: Snapshot[] | undefined
} & SharedIncidentAttributes

export type FrontendNote = { tag: 'Note' } & SharedIncidentAttributes

type IncidentCardData = FrontendIncident | FrontendNote

export function toCardIncident(
  incident: Incident,
  timezoneConverter?: TimeZoneConvertFunc
): IncidentCardData {
  const tag: 'Incident' | 'Note' = incident.detail?.incidentDetail
    ? 'Incident'
    : 'Note'
  const created = createdByString(incident, timezoneConverter)
  let modification: string | undefined
  if (
    incident.updatedBy?.modificationTime !==
    incident.createdBy?.modificationTime
  ) {
    modification = modifiedByString(incident, timezoneConverter)
  }
  const date = occurredAtString(incident)!
  const incidentTitle = title(incident)
  const incidentIcon = icon(incident)

  if (tag === 'Incident') {
    const incidentDetail = incident.detail?.incidentDetail
    const location = locationString(incident)
    const injuries = injuriesString(incident)
    const attachments = incident.attachments
    const id = incident.id

    return {
      id,
      tag,
      icon: incidentIcon,
      title: incidentTitle,
      created,
      modification,
      date,
      location,
      injuries,
      note: incidentDetail?.note,
      attachments,
      followUpActions: incidentDetail?.incidentActions ?? [],
    }
  } else {
    const noteDetail = incident.detail?.noteDetail

    return {
      tag,
      icon: incidentIcon,
      title: incidentTitle,
      created,
      modification,
      date,
      note: noteDetail?.note,
    }
  }
}

export async function toggleIncidentActionStatus({
  incident,
  followUpAction,
  person,
}: {
  incident: Incident
  followUpAction: IncidentAction
  person: Person
}) {
  const done =
    followUpAction.status ===
    IncidentActionStatus.INCIDENT_ACTION_STATUS_COMPLETED

  const patch: IncidentAction = {
    ...followUpAction,
    status: done
      ? IncidentActionStatus.INCIDENT_ACTION_STATUS_OPEN
      : IncidentActionStatus.INCIDENT_ACTION_STATUS_COMPLETED,
  }

  return await updateIncidentAction({
    person,
    incident,
    incidentAction: patch,
  })
}

export async function addIncidentComment(
  person: Person,
  incident: Incident,
  commentToEdit,
  onChangeStatus: (newIncident: Incident) => void,
  user: UserAccount
) {
  const { id: newId } = await createIncidentComment({
    person,
    incident,
    commentText: commentToEdit.comment,
  })
  onChangeStatus({
    ...incident,
    comments: (incident.comments ?? []).concat({
      isActive: true,
      comment: commentToEdit.comment,
      createdBy: {
        modificationTime: new Date().toISOString(),
        modifiedByUserName: user.name,
        modifiedByUserId: user.id,
      },
      id: newId.toString(),
    }),
  })
}
