import { UserSessionLogoutReason } from '@augusthealth/models/com/august/protos/user_session'
import { flatten } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { listFacilities } from '@shared/api/facility'
import { logout } from '@shared/components/Auth/Auth'
import ButtonLink from '@shared/components/ButtonLink'
import { INTERCOM_RP_HELP_CENTER } from '@shared/components/Intercom/intercom_provided_code'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Page } from '@shared/hooks/useCurrentPage'
import { Facility } from '@shared/types/facility'
import { Person } from '@shared/types/person'
import { Task } from '@shared/types/task'
import { UserAccount } from '@shared/types/user'
import {
  getOrgIdsFromPersonMatchers,
  getPersonMatchersWithPersonId,
} from '@shared/utils/permisson'
import {
  BreadcrumbContainer,
  BreadcrumbLinksContainer,
} from '@app/components/Breadcrumbs/layout'
import FacilitiesContext from '@app/contexts/FacilitiesContext'
import styles from './styles.module.css'
import ResidentSwitcher from '../RPView/ResidentSwitcher'
import BreadcrumbEntry from './BreadcrumbEntry'
import { toRPBreadcrumbs } from './helpers'

interface Props {
  person: Person
  tasks: Task[]
  currentPage: Page
  user: UserAccount
  className?: string
}

export default function RPBreadcrumbs({
  currentPage,
  person,
  tasks,
  user,
  className,
}: Props) {
  const [showResidentSwitcher, setShowResidentSwitcher] = useState(false)
  const { setError } = useContext(GlobalContext)
  const { facilities = [] } = useContext(FacilitiesContext)
  const [allFacilities, setAllFacilities] = useState<Facility[]>(facilities)
  const personMatchers = getPersonMatchersWithPersonId(user)

  useEffect(() => {
    const orgIds = getOrgIdsFromPersonMatchers(personMatchers)
    if (orgIds.length > 1) {
      // If user belongs to more than one organization,
      // Fetch extra facilities from other organizations
      Promise.all(orgIds.map((oId) => listFacilities(oId)))
        .then((fList) => setAllFacilities(flatten(fList)))
        .catch((e) => setError(e))
    }
  }, [])

  if (currentPage.tag !== 'Person') {
    return null
  }

  const currentFacility = allFacilities.find(
    (facility) => facility.id === currentPage.facilityId
  )

  const openRPIntercomNewTab = () => {
    window.open(INTERCOM_RP_HELP_CENTER)
  }
  let residentSwitcher: React.ReactNode = null
  const allowChangeResident =
    currentPage.tab.tag !== 'Task' && personMatchers.length > 1

  if (allowChangeResident) {
    residentSwitcher = (
      <span className={styles.changeButton}>
        (
        <ButtonLink
          className={styles.changeButton}
          onClick={() => setShowResidentSwitcher(!showResidentSwitcher)}
        >
          change
        </ButtonLink>
        )
      </span>
    )
  }

  const breadcrumbs = toRPBreadcrumbs(
    currentPage,
    person,
    tasks,
    currentFacility,
    residentSwitcher
  )

  return (
    <BreadcrumbContainer className={className}>
      <div className={styles.desktopActions}>
        <BreadcrumbLinksContainer>
          {breadcrumbs.map((breadcrumb, index) => (
            <BreadcrumbEntry
              key={`rpbc-${index}`}
              breadcrumb={breadcrumb}
              index={index}
            />
          ))}
        </BreadcrumbLinksContainer>
        <div>
          <button className={styles.logoff} onClick={openRPIntercomNewTab}>
            Help
          </button>
          <button
            data-testid="log-out-button"
            className={styles.logoff}
            onClick={() =>
              logout(
                UserSessionLogoutReason.USER_SESSION_LOGOUT_REASON_EXPLICIT
              )
            }
          >
            Log Out
          </button>
        </div>
      </div>
      <div className={styles.mobileActions}>
        {!allowChangeResident && (
          <BreadcrumbEntry breadcrumb={breadcrumbs[0]} index={0} />
        )}
        {allowChangeResident && (
          <button
            className={styles.logoff}
            onClick={() => setShowResidentSwitcher(!showResidentSwitcher)}
          >
            Change Resident
          </button>
        )}
        <button
          className={styles.logoff}
          onClick={() =>
            logout(UserSessionLogoutReason.USER_SESSION_LOGOUT_REASON_EXPLICIT)
          }
        >
          <span className="uppercase">Log Out</span>
        </button>
      </div>
      {showResidentSwitcher && (
        <ResidentSwitcher
          facilities={allFacilities}
          currentPage={currentPage}
          closeModal={() => setShowResidentSwitcher(false)}
          currentPerson={person}
          personMatchers={personMatchers}
        />
      )}
    </BreadcrumbContainer>
  )
}
