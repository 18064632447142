import BadgeFilter, { BadgeInputProps } from '@shared/components/BadgeFilter'
import { BadgeOption } from './types'

type Props = {
  name: string
  options: BadgeOption[]
  selectedValue?: string
  onClick: (val: string) => void
}

function getBadgeInputs({
  name,
  options,
  selectedValue,
  onClick,
}: Props): BadgeInputProps[] {
  return options.map(({ label, value, counter }) => {
    return {
      badgeProps: {
        badgeSize: 'mediumSmall',
        children: `${label} (${counter})`,
        color: value === selectedValue ? 'primary' : 'secondary-outline',
      },
      inputProps: {
        name,
        onClick: () => onClick(value),
      },
    }
  })
}

export default function TransactionBadgesFilter(props: Props) {
  const badgeInputs = getBadgeInputs(props)

  return <BadgeFilter name={props.name} badgeInputs={badgeInputs} />
}
