import { useState } from 'react'
import { PersonCard } from '@shared/components/LinkCard/PersonCard'
import {
  CareAppPerson,
  RoutineAdministration,
  RoutineAdministrationProgressType,
} from '@shared/types/careapp'
import {
  routineAdminIsAdministered,
  routineAdminIsException,
  routineAdminIsOverDue,
} from '@shared/utils/careapp'
import { getRoutineAdministrationMap } from '@shared/utils/routineAdministration'
import { tw } from '@shared/utils/tailwind'
import MarkAsAdministeredModal from '@app/pages/Facilities/Progress/Routines/Modals/MarkAsAdministeredModal'
import ModalToHandleOverdue from '@app/pages/Facilities/Progress/Routines/Modals/ModalToHandleOverdue'
import MarkAsExceptionModal from '../../Modals/MarkAsExceptionModal'
import RoutineAdministrationsStatusSummary, {
  CheckableRoutineAdministrationListItem,
} from '../../RoutineAdministrationsStatusSummary'

export default function ResidentRoutinesCard({
  careAppPerson,
  routineAdministrations,
  facilityTimeZone,
  reload,
  selectedProgressTypes,
  selectedAdministrations,
  toggleAdministration,
}: {
  careAppPerson: CareAppPerson
  routineAdministrations: RoutineAdministration[]
  facilityTimeZone: string
  reload: () => void
  selectedProgressTypes: RoutineAdministrationProgressType[]
  selectedAdministrations: RoutineAdministration[]
  toggleAdministration: (administration: RoutineAdministration) => void
}) {
  const [selectedAdministration, setSelectedAdministration] = useState<
    RoutineAdministration | undefined
  >()
  const {
    admissionsInformation: { bedDetails, roomDetails },
  } = careAppPerson
  const { Administered, Exception, Overdue } = getRoutineAdministrationMap(
    routineAdministrations,
    facilityTimeZone
  )

  const hasAdministrationsToDisplay = Boolean(
    Exception.length || Overdue.length || Administered.length
  )

  return (
    <PersonCard
      cardClassName={tw`mb-[24px]`}
      anchorClassName={tw`text-gray-01`}
      href=""
      person={careAppPerson}
      roomInfo={{ bedDetails, roomDetails }}
    >
      {hasAdministrationsToDisplay && (
        <RoutineAdministrationsStatusSummary
          routineAdministrationMap={{ Exception, Overdue, Administered }}
          onAdministrationClick={setSelectedAdministration}
          facilityTimeZone={facilityTimeZone}
          ListItem={(props) => (
            <CheckableRoutineAdministrationListItem
              {...props}
              selectedProgressTypes={selectedProgressTypes}
              selectedAdministrations={selectedAdministrations}
              toggleAdministration={toggleAdministration}
            />
          )}
        />
      )}

      {selectedAdministration &&
        routineAdminIsAdministered(selectedAdministration) && (
          <MarkAsExceptionModal
            onClose={() => setSelectedAdministration(undefined)}
            careAppPerson={careAppPerson}
            routineAdministration={selectedAdministration}
            facilityTimeZone={facilityTimeZone}
            reload={reload}
          />
        )}

      {selectedAdministration &&
        routineAdminIsException(selectedAdministration) && (
          <MarkAsAdministeredModal
            onClose={() => setSelectedAdministration(undefined)}
            careAppPerson={careAppPerson}
            routineAdministration={selectedAdministration}
            facilityTimeZone={facilityTimeZone}
            reload={reload}
          />
        )}

      {selectedAdministration &&
        routineAdminIsOverDue(selectedAdministration, facilityTimeZone) && (
          <ModalToHandleOverdue
            onClose={() => setSelectedAdministration(undefined)}
            careAppPerson={careAppPerson}
            routineAdministration={selectedAdministration}
            facilityTimeZone={facilityTimeZone}
            reload={reload}
          />
        )}
    </PersonCard>
  )
}
