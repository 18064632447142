import { LevelOfCareSettingsList } from '@augusthealth/models/com/august/protos/level_of_care'
import {
  OrgFacilitySettings,
  SettingsType,
} from '@augusthealth/models/com/august/protos/org_facility_settings'
import { createFacilityLoCSettings } from '@shared/api/orgFacilitySettings'
import { DeepNull } from '@shared/types/utilities'
import {
  configureSettingsId,
  SettingsConfig,
} from '@shared/utils/orgFacilitySettings'
import { validStringOrNull } from '@shared/utils/parsing'

export const sortLoCSettings = (data: LevelOfCareSettingsList) => {
  data.careLevelsByAdmissionType!.forEach((cl) => {
    if (cl.levelValues?.length && cl.levelValues.length > 1) {
      cl.levelValues.sort((lvlA, lvlB) => {
        if (isNaN(lvlA.maxScore!) || lvlA.maxScore === null) {
          return 1
        } else if (isNaN(lvlB.maxScore!)) {
          return -1
        } else {
          return lvlA.maxScore! - lvlB.maxScore!
        }
      })
    }
  })

  return data
}

export const excludeIncompleteLocSettings = (
  data: LevelOfCareSettingsList
): DeepNull<LevelOfCareSettingsList> => {
  return {
    ...data,
    careLevelsByAdmissionType: data.careLevelsByAdmissionType!.map(
      (byAdmission) => {
        if (byAdmission.levelValues?.length) {
          let filteredLevelValues = byAdmission.levelValues

          if (
            filteredLevelValues.length > 1 &&
            filteredLevelValues.every((lv) => lv.maxScore === undefined)
          ) {
            filteredLevelValues = [filteredLevelValues[0]]
          }

          return {
            ...byAdmission,
            levelValues: filteredLevelValues.map((v) => ({
              ...v,
              name: validStringOrNull(v.name),
            })),
          }
        }
        return byAdmission
      }
    ),
  }
}

export const createLoCSettings = async (
  data: LevelOfCareSettingsList,
  settingsConfig: SettingsConfig
): Promise<DeepNull<LevelOfCareSettingsList>> => {
  const sortedData = sortLoCSettings(data)
  const onlyValidSettings = excludeIncompleteLocSettings(sortedData)
  const orgFacilitySettings: DeepNull<OrgFacilitySettings> = {
    settings: {
      levelOfCareSettingsList: onlyValidSettings,
    },
    settingsType: SettingsType.SETTINGS_TYPE_LEVEL_OF_CARE_SETTINGS,
    ...configureSettingsId(settingsConfig),
  }
  await createFacilityLoCSettings(orgFacilitySettings)

  return onlyValidSettings
}

export const defaultValuesFromResponse = (
  res: OrgFacilitySettings
): LevelOfCareSettingsList => ({
  effectiveDate: res.settings?.levelOfCareSettingsList?.effectiveDate,
  careLevelsByAdmissionType: [
    ...(
      res.settings?.levelOfCareSettingsList?.careLevelsByAdmissionType ?? []
    ).map((cl) => {
      return {
        ...cl,
        lowestLevel: cl.lowestLevel ?? 1,
        levelValues: (cl.levelValues ?? []).map((lv) => {
          return {
            ...lv,
            maxScore: lv.maxScore ?? (null as any),
            careFees: lv.careFees?.map((cf) => ({
              careFee: null as any,
              interval: null as any,
              ...cf,
            })) ?? [{ careFee: null, interval: null }],
          }
        }),
      }
    }),
  ],
})
