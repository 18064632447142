import { Control, Controller, FieldErrors } from 'react-hook-form'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import { RHFSwitch } from '../Switch'

type SwitchValue = string | number
type Option = {
  label: string
  value: SwitchValue
  disabled?: boolean
}

export default function SwitchGroup({
  control,
  errors,
  isMulti,
  name,
  onClick,
  options,
  required = false,
  title,
}: {
  control: Control<any>
  errors: FieldErrors<any>
  isMulti: boolean
  name: string
  onClick?: (opt: SwitchValue[] | null) => void
  options: Option[]
  required?: boolean
  title: string
}) {
  return (
    <div className="mb-[32px]">
      <LabelAboveInput
        htmlFor={name}
        subLabel={required ? requiredLabel(!!errors[name]) : undefined}
      >
        {title}
      </LabelAboveInput>
      <Controller
        defaultValue={[]}
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { onChange, value } }) => {
          return (
            <div>
              {options.map(({ label, value: optValue, disabled }, index) => {
                const key = `${name}-${optValue}`
                return (
                  <RHFSwitch
                    holderClassName={index !== 0 ? 'mt-[12px]' : undefined}
                    switchSize="small"
                    name={name}
                    value={optValue}
                    data-testid={key}
                    id={key}
                    key={key}
                    label={label}
                    onChange={() => {
                      let returnVal: SwitchValue[] | null
                      if (isMulti) {
                        if (value?.includes(optValue)) {
                          const newList = value?.filter(
                            (v: SwitchValue) => v !== optValue
                          )
                          returnVal = newList?.length ? newList : null
                        } else {
                          if (value) {
                            value.push(optValue)
                            returnVal = value
                          } else {
                            returnVal = [optValue]
                          }
                        }
                      } else {
                        // isMulti = false
                        if (value?.includes(optValue)) {
                          returnVal = null
                        } else {
                          returnVal = [optValue]
                        }
                      }
                      if (onClick) {
                        onClick(returnVal)
                      }
                      onChange(returnVal)
                    }}
                    checked={value ? value.includes(optValue) : false}
                    disabled={disabled}
                  />
                )
              })}
            </div>
          )
        }}
      />
    </div>
  )
}
