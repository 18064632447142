import { useContext, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { CategoryKeyIdentifier } from '@shared/types/assessment_configuration'
import FullpageFormFooter from '@app/components/FullpageFormFooter'
import { EditAssessmentCategory } from '@app/pages/Routines/EditAssessmentCategory'
import Category from './Category'
import { updateServicePlan } from './helpers'
import ServicePlanContext from './ServicePlanContext'
import { State } from './stateManagement'

export default function ServicePlanPage({
  categories,
  onContinue,
  onFinishLater,
  promiseRef,
}: {
  categories: CategoryKeyIdentifier[]
  onContinue: () => void
  onFinishLater: () => void
  promiseRef: React.MutableRefObject<Promise<void>>
}) {
  const { setError } = useContext(GlobalContext)
  const { assessment, person, refreshAssessment, servicePlan, state } =
    useContext(ServicePlanContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showEditAssessmentCategory, setShowEditAssessmentCategory] =
    useState<CategoryKeyIdentifier>()
  const augustInitialAppraisal =
    (assessment.tag === 'Complete' && assessment.value.appraisal) || {}
  const saveServicePlan = async (newState: State = state) => {
    try {
      setIsSubmitting(true)

      if (servicePlan.tag === 'Loading') {
        return
      }

      promiseRef.current = promiseRef.current.then(() =>
        updateServicePlan({
          person,
          servicePlan: servicePlan.value,
          state: newState,
        })
      )
      await promiseRef.current
    } catch (e) {
      setError(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  const finishLater = async () => {
    await saveServicePlan()

    return onFinishLater()
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await saveServicePlan()

    return onContinue()
  }

  return (
    <>
      <form
        style={{ marginBottom: '128px', flexGrow: 1, paddingRight: '100px' }}
        onSubmit={handleSubmit}
      >
        {categories.map((category, ix) => (
          <Category
            planCategory={category}
            key={`${category}-${ix}`}
            saveServicePlan={saveServicePlan}
            incomplete={
              !state[category]?.frequency &&
              !state[category]?.personResponsible &&
              !state[category]?.objective &&
              !state[category]?.evaluation
            }
            setShowEditAssessmentCategory={setShowEditAssessmentCategory}
          />
        ))}
        <FullpageFormFooter
          yesBtn={{
            text: (
              <>
                Continue
                <i className="far fa-angle-right ml-[8px]" />
              </>
            ),
            props: {
              isLoading: isSubmitting,
              disabled: isSubmitting,
            },
          }}
          noBtn={{
            text: 'Finish Later',
            action: finishLater,
          }}
        />
      </form>
      {showEditAssessmentCategory && (
        <EditAssessmentCategory
          person={person!}
          categoryKey={showEditAssessmentCategory}
          assessment={{ appraisal: augustInitialAppraisal }}
          onComplete={async (updated) => {
            if (updated) {
              await refreshAssessment()
            }

            setShowEditAssessmentCategory(undefined)
          }}
        />
      )}
    </>
  )
}
