import { FieldValues, FormState } from 'react-hook-form'
import FullpageFormFooter from '@app/components/FullpageFormFooter'

export default function Footer({
  formState,
  onFinishLater,
  disableContinue,
}: {
  formState: FormState<FieldValues>
  onFinishLater: () => Promise<void>
  disableContinue: boolean
}) {
  return (
    <FullpageFormFooter
      yesBtn={{
        text: (
          <>
            Continue
            <i className="far fa-angle-right ml-[8px]" />
          </>
        ),
        props: { disabled: disableContinue },
      }}
      noBtn={{
        text: 'Finish Later',
        action: onFinishLater,
      }}
      formState={formState}
    />
  )
}
