import classNames from 'classnames'
import { History } from 'history'
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form'
import { OverrideProperties } from 'type-fest'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import {
  BasicInput,
  BasicTextarea,
} from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, RequiredLabel } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { carePathForPerson } from '@shared/legacy_routes'
import { DateMessage, Time } from '@shared/types/date'
import { PersonIds } from '@shared/types/person'
import { ServicePlan_PlanCategoryKey } from '@shared/types/service_plan'
import { AppraisalSettings_AppraisalCategory } from '@shared/types/settings/appraisal_settings'
import { ShiftId } from '@shared/types/shift'
import { DayOfWeek, UnitOfTime } from '@shared/types/timing'
import { formatTime } from '@shared/utils/date'
import { tw } from '@shared/utils/tailwind'
import { CreateRoutineOrderPayload } from '@app/api/routineOrders'
import PersonPageTitle from '@app/components/PersonPageTitle'
import footerStyles from '@app/components/Residents/Medications/Orders/styles.module.css'
import { DynamicFrequencyRoutines } from './DynamicFrequencyRoutines'
import { RoutineDateInput } from './RoutineDateInput'

export const UNSELECTED_TIMING_OPTION = null
export type RoutineOrderFormTiming =
  | OptionTypeBase<Time | ShiftId>
  | typeof UNSELECTED_TIMING_OPTION

export function toTimeOption(
  time: Time,
  options: { use24HourClock: boolean; isDisabled?: boolean }
): OptionTypeBase<Time> {
  return {
    label: formatTime(time, options) || 'Unspecified time',
    value: time,
    isDisabled: options.isDisabled ?? false,
  }
}

// Roughly corresponds to periodUnit; however, for the "DaysOfWeek" option,
// routines use a UNIT_OF_TIME_DAY period, because they can allow for multiple
// times of day to be specified.
export enum RoutineOrderPeriodChoice {
  Daily = 'Daily',
  DaysOfWeek = 'DaysOfWeek',
  DaysOfMonth = 'DaysOfMonth',
  Every = 'Every',
  OnThisDay = 'OnThisDay',
}

export type RoutineOrderFormSchedule = {
  frequency: number
  periodChoice?: keyof typeof RoutineOrderPeriodChoice
  period?: number
  periodUnit?: UnitOfTime
  dayOfWeek?: DayOfWeek[]
  dayOfMonth?: number[]
  timeOfDay?: RoutineOrderFormTiming[]
  onThisDate?: DateMessage
}

export type RoutineOrderFormData = OverrideProperties<
  Omit<
    CreateRoutineOrderPayload,
    'routineType' | 'status' | 'categoryKey' | 'customKey' | 'personId'
  >,
  { schedules: RoutineOrderFormSchedule[] }
> & { category: CategoryOption }

export type CategoryOption = OptionTypeBase<{
  categoryKey: ServicePlan_PlanCategoryKey
  customKey: string | undefined
}>

export function toCategoryOptions(
  categories: AppraisalSettings_AppraisalCategory[]
): CategoryOption[] {
  return categories
    .map(
      (category): CategoryOption => ({
        value: {
          categoryKey: category.categoryKey!,
          customKey: category.customKey,
        },
        label: category.categoryOptions!.title!,
      })
    )
    .sort((a, b) => (a.label! < b.label! ? -1 : 1))
}

export type Props = {
  title: string
  history: History<unknown>
  methods: UseFormReturn<RoutineOrderFormData, unknown, undefined>
  onSubmit: (onValid: RoutineOrderFormData) => Promise<void>
  onDiscontinue?: () => void
  categoryOptions: CategoryOption[]
  person: Required<PersonIds>
  disabled?: boolean
}

export default function RoutineOrderForm({
  title,
  history,
  methods,
  onSubmit,
  onDiscontinue,
  categoryOptions,
  person,
  disabled = false,
}: Props) {
  const fieldHasError = (field: keyof RoutineOrderFormData) => {
    return methods.formState.errors[field] !== undefined
  }

  function SaveButton({ className }: { className?: string }) {
    return (
      <AsyncIconButton
        id="save-routine-order"
        type="submit"
        buttonStyle="primary-fill"
        className={className}
        disabled={methods.formState.isSubmitting}
      >
        Save
      </AsyncIconButton>
    )
  }

  function CancelButton({ className }: { className?: string }) {
    return (
      <AsyncIconButton
        id="cancel-routine-order-changes"
        buttonStyle="secondary-outline"
        type="button"
        className={className}
        disabled={methods.formState.isSubmitting}
        onClick={async () => history.push(carePathForPerson(person))}
      >
        Cancel
      </AsyncIconButton>
    )
  }

  function FormActions() {
    return (
      <footer className={classNames(footerStyles.footer)}>
        <div className="mx-auto flex w-[720px] max-w-[720px] justify-between">
          {onDiscontinue && (
            <div>
              <AsyncIconButton
                id="discontinue-routine-order"
                width="152px"
                buttonStyle="danger-outline"
                onClick={onDiscontinue}
              >
                Discontinue
              </AsyncIconButton>
            </div>
          )}
          {onDiscontinue ? (
            <div className="flex gap-6">
              <CancelButton className="w-[152px]" />
              <SaveButton className="w-[152px]" />
            </div>
          ) : (
            <div className="w-[720px]">
              <div className="flex justify-center gap-6">
                <CancelButton className="w-[256px]" />
                <SaveButton className="w-[256px]" />
              </div>
            </div>
          )}
        </div>
      </footer>
    )
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className={tw`mb-28 ml-auto mr-auto mt-8 h-fit w-[720px]`}>
          <PersonPageTitle title={title} />
          <LabelAboveInput className={tw`mt-input`} htmlFor="name">
            Routine Name <RequiredLabel showError={fieldHasError('name')} />
          </LabelAboveInput>
          <BasicInput
            {...methods.register('name', {
              required: true,
              disabled,
            })}
          />
          <LabelAboveInput className={tw`mt-input`} htmlFor="instructions">
            Instructions{' '}
            <RequiredLabel showError={fieldHasError('instructions')} />
          </LabelAboveInput>
          <BasicTextarea
            className={tw`w-full`}
            {...methods.register('instructions', {
              required: true,
              disabled,
            })}
          />
          <Controller
            control={methods.control}
            name="category"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => {
              return (
                <>
                  <LabelAboveInput className={tw`mt-input`} htmlFor="category">
                    Category{' '}
                    <RequiredLabel showError={fieldHasError('category')} />
                  </LabelAboveInput>
                  <StyledSelect
                    inputId="category"
                    options={categoryOptions}
                    isDisabled={disabled}
                    value={value}
                    onChange={onChange}
                  />
                </>
              )
            }}
          />
          <LabelAboveInput className={tw`mt-input`}>
            Schedule <RequiredLabel showError={fieldHasError('schedules')} />
          </LabelAboveInput>
          <DynamicFrequencyRoutines index={0} readOnly={disabled} />
          <div className={tw`flex gap-4`}>
            <RoutineDateInput
              index={0}
              disabled={disabled}
              dateLabel={'startDate'}
            />
            <RoutineDateInput
              index={0}
              disabled={disabled}
              dateLabel={'endDate'}
            />
          </div>
        </div>

        <FormActions />
      </form>
    </FormProvider>
  )
}
