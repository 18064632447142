import states from '@shared/constants/states'
import Link from '@app/components/Link'
import PersonPageTitle from '@app/components/PersonPageTitle'
import { getStateTasksUrl } from '@app/pages/Tools/path'
import styles from './styles.module.css'

export default function ListOfStates() {
  return (
    <div>
      <PersonPageTitle subtitle title="State Tasks" />
      <div className={`${styles.stateList} mb-[32px] ml-[8px]`}>
        {Object.keys(states).map((stateName) => {
          const stateAbbr = states[stateName]
          return (
            <Link
              key={stateAbbr}
              className="mr-[8px] text-[18px] font-medium"
              to={getStateTasksUrl(stateAbbr)}
            >
              {stateName}
            </Link>
          )
        })}
      </div>
    </div>
  )
}
