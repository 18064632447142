import React from 'react'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { PersonStats } from '@shared/types/person_stats'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { displayResidentStatus } from '@shared/utils/person'
import { twx } from '@shared/utils/tailwind'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import { AssignmentLoading, ReviewUnassignedOrderMode } from './helpers'

type Props = {
  mode: ReviewUnassignedOrderMode
  loading: AssignmentLoading
}
export const ReviewUnassignedOrderModalFooter = ({ mode, loading }: Props) => {
  const [disableSelect, setDisableSelect] = React.useState(false)
  const { residents, prospects } = useCurrentFacility()
  const residentOptions: PersonStats[] =
    residents.tag === 'Complete' ? residents.value : []
  const prospectOptions: PersonStats[] =
    prospects.tag === 'Complete' ? prospects.value : []

  const peopleOptions: OptionTypeBase<PersonStats>[] = [
    ...residentOptions,
    ...prospectOptions,
  ].map((p) => {
    return {
      label: `${getFirstAndLastName(p.person!.name)}`,
      subLabel: `${displayResidentStatus(p.person!)}`,
      value: p,
    }
  })

  return (
    <div className={twx('mt-6 flex items-center justify-between')}>
      {mode.tag === 'initial' && (
        <>
          <AsyncIconButton
            buttonStyle={'danger-outline'}
            width={'252px'}
            onClick={mode.onReject}
            disabled={!!loading}
            isLoading={loading === 'reject'}
          >
            {mode.orderCount === 1
              ? 'Reject this order'
              : `Reject all ${mode.orderCount} orders`}
          </AsyncIconButton>
          <AsyncIconButton
            buttonStyle={'primary-fill'}
            width={'252px'}
            onClick={mode.onAssign}
            disabled={!!loading}
          >
            Assign to an existing resident
            <i className={'fas fa-chevron-right ml-[8px]'} />
          </AsyncIconButton>
        </>
      )}
      {(mode.tag === 'confirmation' || mode.tag === 'assignment') &&
        peopleOptions.length > 0 && (
          <>
            <StyledSelect
              placeholder={'Enter first or last name...'}
              options={peopleOptions}
              value={mode.selectedPerson}
              onChange={mode.onPersonSelect}
              className={'w-[320px]'}
              isDisabled={disableSelect || mode.tag === 'confirmation'}
            />
            <AsyncIconButton
              buttonStyle={'secondary-outline'}
              onClick={mode.onCancel}
              width={'88px'}
              className={'ml-[12px] mr-[12px]'}
              disabled={!!loading || mode.tag === 'confirmation'}
            >
              Cancel
            </AsyncIconButton>
            <AsyncIconButton
              buttonStyle={'primary-fill'}
              onClick={() => {
                setDisableSelect(true)
                mode.onContinue()
                setDisableSelect(false)
              }}
              width={'88px'}
              disabled={
                !mode.selectedPerson ||
                (mode.tag === 'confirmation' &&
                  mode.person.tag === 'Loading') ||
                !!loading
              }
              isLoading={
                mode.tag === 'confirmation' && mode.person.tag === 'Loading'
              }
            >
              Continue
            </AsyncIconButton>
          </>
        )}
      {mode.tag === 'reject' && (
        <>
          <AsyncIconButton
            buttonStyle={'primary-outline'}
            width={'252px'}
            onClick={mode.onCancel}
            disabled={!!loading}
          >
            Cancel
          </AsyncIconButton>
          <AsyncIconButton
            buttonStyle={'danger-fill'}
            width={'252px'}
            onClick={mode.onConfirmReject}
            disabled={!!loading}
            isLoading={loading === 'reject'}
          >
            {mode.orderCount === 1
              ? 'Reject this order'
              : `Reject all ${mode.orderCount} orders`}
          </AsyncIconButton>
        </>
      )}
    </div>
  )
}
