import { ReactNode } from 'react'
import { BackgroundColor } from '@shared/components/AnimatedPopup/Lightbox/types'
import {
  AsyncIconButton,
  Props as ButtonProps,
} from '@shared/components/AsyncButton'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { twx } from '@shared/utils/tailwind'

type Props = {
  children?: ReactNode
  mainContent: ReactNode
  mainContentBackgroundColor?: BackgroundColor
  onClose: () => void
  sidebarContent: ReactNode
  showSidebarOnPrint?: boolean
  dataCy?: string
}

/**
 * Lightbox component based on existing FadeWithBackground
 * @param children {ReactNode} [] - Content without additional styles, if mainContent or sidebarContent exists, children will be the additional content
 * @param mainContent {ReactNode} - Specify main content with default main content styles
 * @param mainContentBackgroundColor {'dark' | 'white'} ['dark'] - Background color of main content, usually 'dark' for PDF content and 'white' for Web form content
 * @param onCloseButtonClick {Function} [] If specified, show X icon on top-right corner
 * @param sidebarContent {ReactNode} - Specify sidebar content with default sidebar content styles
 * @param showSidebarOnPrint {boolean} [false] - show/hide Sidebar on print, useful to print Web form (vs PDF form) without Sidebar
 * @param dataCy {string} [] - data key for cypress id
 */

export default function Lightbox({
  children,
  mainContent,
  sidebarContent,
  onClose,
  mainContentBackgroundColor = 'dark',
  showSidebarOnPrint = false,
  dataCy,
}: Props) {
  return (
    <LightboxHolder dataCy={dataCy} onClose={onClose}>
      <LightboxContent backgroundColor={mainContentBackgroundColor}>
        {mainContent}
      </LightboxContent>
      <LightboxSidebar showSidebarOnPrint={showSidebarOnPrint}>
        {sidebarContent}
      </LightboxSidebar>
      {children}
    </LightboxHolder>
  )
}

export function LightboxHolder({
  children,
  className,
  onClose,
  dataCy,
  id,
}: {
  id?: string
  children: React.ReactNode
  dataCy?: string
  className?: string
  onClose: () => void
}) {
  return (
    <Modal
      id={id ?? 'lightbox'}
      open={true}
      onClose={onClose}
      data-cy={dataCy}
      contentClassName={twx(
        'w-full h-full flex p-0',
        'h-[calc(95vh-48px)] max-h-[calc(95vh-48px)] min-h-[calc(95vh-48px)]',
        'w-[calc(97vw-48px)] max-w-[calc(97vw-48px)] min-w-[calc(97vw-48px)]',
        className
      )}
      backgroundClassName={twx('print:hidden')}
    >
      {children}
    </Modal>
  )
}

export function LightboxContent({
  children,
  backgroundColor,
}: {
  children: ReactNode
  backgroundColor?: BackgroundColor
}) {
  return (
    <div
      className={twx(
        'grow-1 relative flex w-full justify-center overflow-y-auto rounded-l-[8px] p-12 text-white transition ease-in mobile:hidden',
        {
          ['bg-gray-03 text-white']: backgroundColor === 'dark',
          ['bg-white text-secondary-01']: backgroundColor === 'white',
        }
      )}
    >
      {children}
    </div>
  )
}

export function LightboxSidebar({
  children,
  showSidebarOnPrint = false,
}: {
  children: ReactNode
  showSidebarOnPrint?: boolean
}) {
  return (
    <div
      className={twx(
        'flex w-[384px] shrink-0 flex-col justify-between overflow-hidden bg-background p-8 mobile:w-full',
        {
          ['print:hidden']: !showSidebarOnPrint,
        }
      )}
    >
      {children}
    </div>
  )
}

export function LightboxSidebarContent({
  children,
  className,
  title,
  message,
}: {
  children?: ReactNode
  className?: string
  title?: ReactNode
  message?: ReactNode
}) {
  return (
    <div className={twx('grow-1 overflow-y-auto pb-2', className)}>
      {title && <LightboxSidebarTitle>{title}</LightboxSidebarTitle>}
      {message && <LightboxSidebarMessage>{message}</LightboxSidebarMessage>}
      {children}
    </div>
  )
}

export function LightboxSidebarFooter({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) {
  return <div className={twx('flex grow-0', className)}>{children}</div>
}

export function LightboxFooterButton(props: Partial<ButtonProps>) {
  const { children = 'Close', className, ...restProps } = props

  return (
    <AsyncIconButton
      className={twx('grow', className)}
      buttonStyle="secondary-outline"
      {...restProps}
    >
      {children}
    </AsyncIconButton>
  )
}

export function LightboxSidebarTitle({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) {
  return (
    <h2
      className={twx(
        'mb-4 border-b border-secondary-10 px-1 pb-4 pt-0 text-[24px] font-medium leading-[36px] text-secondary-02',
        className
      )}
    >
      {children}
    </h2>
  )
}

export function LightboxSidebarMessage({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) {
  return (
    <div
      className={twx(
        'mb-[32px] mt-[16px] text-[14px] font-medium leading-[20px] text-secondary-06',
        className
      )}
    >
      {children}
    </div>
  )
}
