import { GenerateBulkMarParameters_SortBy as SortBy } from '@augusthealth/models/com/august/protos/jobs'
import { useContext, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useForm } from 'react-hook-form'
import { sendBulkEMar } from '@shared/api/facility'
import { download } from '@shared/api/legacy'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { BasicRadioButton } from '@shared/components/BasicInput/BasicInput'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Facility } from '@shared/types/facility'
import { Person } from '@shared/types/person'
import { UserAccount } from '@shared/types/user'
import { formatMarDate } from '@shared/utils/date'
import { tw } from '@shared/utils/tailwind'
import { careLogPdfUrl } from '@app/api/routines'
import { useCurrentFacility } from '@app/hooks/useFacilities'

type Props = {
  person?: Person
  facility?: Facility
  onClose: () => void
  downloadAll?: boolean
}

type FormData = {
  sortBy: SortBy
}

export function DownloadIndividualCareLogModal({
  person,
  onClose,
}: {
  person: Person
  onClose: () => void
  user: UserAccount
}) {
  const { currentFacility } = useCurrentFacility()
  const facility =
    currentFacility?.tag === 'Complete' ? currentFacility.value : undefined

  return (
    <DownloadCareLogModal
      person={person}
      onClose={onClose}
      downloadAll={false}
      facility={facility}
    />
  )
}

export function DownloadBulkCareLogModal({
  facility,
  onClose,
}: {
  facility: Facility
  onClose: () => void
}) {
  return (
    <DownloadCareLogModal
      facility={facility}
      onClose={onClose}
      downloadAll={true}
    />
  )
}

function DownloadCareLogModal({
  person,
  onClose,
  facility,
  downloadAll = false,
}: Props) {
  const { setError } = useContext(GlobalContext)
  const { handleSubmit, register } = useForm<FormData>({
    defaultValues: {
      sortBy: SortBy.SORT_BY_LAST_NAME,
    },
  })

  const [startDate, setStartDate] = useState<Date | null>(new Date())
  const [endDate, setEndDate] = useState<Date | null>(new Date())

  const [isLoadingCareLog, setIsLoadingCareLog] = useState(false)
  const [isProcessingCareLog, setIsProcessingCareLog] = useState(false)

  const onSubmit = (data: FormData) => {
    setIsLoadingCareLog(true)

    if (startDate && endDate) {
      if (downloadAll && facility) {
        sendBulkEMar({
          orgId: facility.orgId,
          facilityId: facility.id,
          startMonth: formatMarDate(startDate),
          endMonth: formatMarDate(endDate),
          sortBy: data.sortBy,
        })
          .then(() => setIsProcessingCareLog(true))
          .catch(setError)
          .finally(() => {
            setIsLoadingCareLog(false)
          })
      } else {
        const fileUrl = careLogPdfUrl({
          person: person!,
          startMonth: formatMarDate(startDate),
          endMonth: formatMarDate(endDate),
        })

        download({ fileUrl, target: '_blank' })
          .catch(setError)
          .finally(() => {
            setIsLoadingCareLog(false)
            onClose()
          })
      }
    }
  }

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  const disableContinue = startDate === null || endDate === null

  return (
    <AnimatedPopup
      onClickBackground={onClose}
      containerClassName="max-w-[424px]"
      title={null}
    >
      <h2 className="mb-[24px] text-center text-[18px] font-semibold leading-[24px]">
        {downloadAll ? 'Download Care Log' : 'Print Care Log'}
      </h2>
      {isProcessingCareLog && <ProcessingCareLogMessage onClose={onClose} />}
      {!isProcessingCareLog && (
        <form data-testid="download-mar-form" onSubmit={handleSubmit(onSubmit)}>
          <div className={tw`my-[26px] flex justify-center`}>
            <DatePicker
              onChange={handleChange}
              maxDate={new Date(Date.now())}
              dateFormat="MM/yyyy"
              startDate={startDate}
              endDate={endDate}
              selectsRange
              customInput={
                <CalendarInput data-testid={'download-eMar-date-range-start'} />
              }
              portalId={'root'}
              showMonthYearPicker
            />
          </div>
          <section>
            {downloadAll && (
              <div>
                <span className="font-semibold">Sort By:</span>
                <div className="flex">
                  <BasicRadioButton
                    data-testid="mar-sort-by-room"
                    id="mar-sort-by-room"
                    value={SortBy.SORT_BY_ROOM_NUMBER}
                    {...register('sortBy')}
                  >
                    Room number
                  </BasicRadioButton>
                  <BasicRadioButton
                    className="ml-[16px]"
                    data-testid="mar-sort-by-name"
                    id="mar-sort-by-name"
                    value={SortBy.SORT_BY_LAST_NAME}
                    {...register('sortBy')}
                  >
                    Last name
                  </BasicRadioButton>
                </div>
              </div>
            )}
          </section>
          {downloadAll && (
            <div>
              This will generate a Care Log for all current residents.
              <br />
              You will receive an email when it is ready to download.
            </div>
          )}
          <AsyncIconButton
            className="mt-[24px]"
            buttonStyle="primary-fill"
            id="printMarContinueBtn"
            isLoading={isLoadingCareLog}
            disabled={disableContinue}
            width="100%"
            type={'submit'}
          >
            Continue
          </AsyncIconButton>
        </form>
      )}
    </AnimatedPopup>
  )
}

function ProcessingCareLogMessage(props: { onClose: () => void }) {
  return (
    <>
      <div>
        We are generating the Care Log for all current residents. We will email
        you when they are ready to download.
      </div>
      <AsyncIconButton
        className={'mt-[24px]'}
        buttonStyle="primary-fill"
        id="okCloseMarModalBtn"
        onClick={props.onClose}
        width="100%"
      >
        OK
      </AsyncIconButton>
    </>
  )
}
