import { ClassNameValue } from 'tailwind-merge'
import { CategoryKeyIdentifier } from '@shared/types/assessment_configuration'
import { CategoryTasks, RoutineStatus } from '@shared/types/routine'
import { RoutineOrder } from '@shared/types/routine_order'
import { ServicePlan_PlanCategoryKey } from '@shared/types/service_plan'
import { AppraisalSettings_Level } from '@shared/types/settings/appraisal_settings'
import notEmpty from '@shared/utils/notEmpty'
import { getCategoryKey } from '@shared/utils/routine'
import { twx } from '@shared/utils/tailwind'

export function getLevelLabel(level: AppraisalSettings_Level) {
  return level
    .replace(/^LEVEL_/, '')
    .toLowerCase()
    .replace(/^(\b.)/, (c) => c.toUpperCase())
}

export function getCategoryAndCustomKeyFromCategoryKeyIdentifier(
  categoryKey: CategoryKeyIdentifier | ServicePlan_PlanCategoryKey
) {
  const [parsedCategoryKey, customKey] = categoryKey.split('-')

  return {
    categoryKey: parsedCategoryKey,
    customKey: customKey,
  }
}

export function getCategoryKeyTitle(categoryKey: ServicePlan_PlanCategoryKey) {
  if (
    categoryKey.startsWith(ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_CUSTOM)
  ) {
    return getCategoryAndCustomKeyFromCategoryKeyIdentifier(categoryKey)
      .customKey
  }
  return categoryKey.replace(/^PLAN_CATEGORY_KEY_/, '').replace(/_/g, ' ')
}

export type Description = {
  categoryKey: ServicePlan_PlanCategoryKey
  categoryTitle: string
  categoryLevel: string | undefined
  details: string[]
  options: (string | undefined)[]
  notes: string | undefined
}

export function toFullDescription(
  categoryTasks: CategoryTasks[]
): Description[] {
  return categoryTasks
    .map((ct) => {
      const appraisalTasks = ct.appraisalTasks
      const servicePlanTasks = ct.servicePlanTasks
      const categoryKey = getCategoryKey(ct)
      const categoryTitle = getCategoryKeyTitle(categoryKey).toLowerCase()

      const details = appraisalTasks?.details ?? []
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const detailDescriptions = details.map((d) => d.detail!.description!)

      const servicePlanOptions = servicePlanTasks?.servicePlanOptions ?? []
      const optionDescriptions = servicePlanOptions.map((o) => o.description)

      const level = appraisalTasks?.level?.level
      const categoryLevel = level ? getLevelLabel(level) : undefined
      const notes = appraisalTasks?.appraisalNotes

      if (categoryLevel === undefined && detailDescriptions.length === 0) {
        return undefined
      }

      return {
        categoryKey,
        categoryTitle,
        categoryLevel,
        details: detailDescriptions,
        options: optionDescriptions,
        notes,
      }
    })
    .filter(notEmpty)
}

export function levelsForRoutine(categoryTasks: CategoryTasks[]) {
  return categoryTasks
    .map((ct) => {
      const appraisalTasks = ct.appraisalTasks
      const categoryKey =
        ct.category ?? ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_UNSPECIFIED

      if (appraisalTasks?.level?.level) {
        const categoryTitle = getCategoryKeyTitle(categoryKey).toLowerCase()
        return `${getLevelLabel(appraisalTasks.level.level)} ${categoryTitle}`
      }

      return undefined
    })
    .filter(notEmpty)
}

export function Instructions({
  routineOrder,
  abbreviated,
  className,
}: {
  routineOrder: RoutineOrder
  abbreviated: boolean
  className?: ClassNameValue
}) {
  if (routineOrder.routineType.assessment) {
    const categoryTasks = routineOrder.routineType.assessment.tasks ?? []
    if (abbreviated) {
      const levelsTextForRoutine = levelsForRoutine(categoryTasks)

      return (
        <div className={twx('flex', className)}>
          <div
            className={twx('min-h-full rounded-sm border border-solid', {
              'border-gray-10':
                routineOrder.status === RoutineStatus.ROUTINE_STATUS_ACTIVE,
              'border-l-gray-10':
                routineOrder.status === RoutineStatus.ROUTINE_STATUS_DRAFT,
            })}
          />
          <div className={twx('mx-4 my-0 text-sm font-medium leading-5')}>
            <>
              {levelsTextForRoutine.map((s, i) => (
                <div key={`l-i${i}`}>{s}</div>
              ))}
              {levelsTextForRoutine.length === 0 && (
                <div>No level information available.</div>
              )}
            </>
          </div>
        </div>
      )
    } else {
      const fullDescription: Description[] = toFullDescription(categoryTasks)
      return (
        <>
          {fullDescription.map((fid, i) => (
            <div
              className={twx('flex', className)}
              key={`${routineOrder.id}-${i}`}
            >
              <div
                className={twx('min-h-full rounded-sm border border-solid', {
                  'border-gray-10':
                    routineOrder.status === RoutineStatus.ROUTINE_STATUS_ACTIVE,
                  'border-l-gray-10':
                    routineOrder.status === RoutineStatus.ROUTINE_STATUS_DRAFT,
                })}
              />
              <div className={twx('mx-4 my-0 text-sm font-medium leading-5')}>
                <ul className={twx('m-0 list-inside p-0')}>
                  {fid.categoryLevel && (
                    <li className={twx('mb-2')}>
                      {fid.categoryLevel} {fid.categoryTitle}{' '}
                      {fid.notes && <>- {fid.notes}</>}
                    </li>
                  )}

                  {fid.details.map((d, j) => (
                    <li key={`${routineOrder.id}-${i}-${j}`}>
                      <span>{d}</span>
                    </li>
                  ))}
                  {fid.options.map((o, j) => (
                    <li key={`${routineOrder.id}-${i}-${j}`}>
                      <span>{o}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </>
      )
    }
  } else {
    return (
      <div className={twx('border-l-2 border-l-gray-10', className)}>
        <pre className={twx('whitespace-pre-wrap px-4 font-inter font-medium')}>
          {routineOrder.instructions}
        </pre>
      </div>
    )
  }
}
