import { Occupancy_Room as OccupancyRoom } from '@augusthealth/models/com/august/protos/api/occupancy'
import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import localForage from 'localforage'
import { ReactNode, useEffect, useState } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import { FacilityPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import useCurrentPage, { extractIds } from '@shared/hooks/useCurrentPage'
import { Order } from '@shared/utils/common'
import { Table, TableContainer } from '@app/components/Table'
import TableHeader, {
  getTableHeaderOnToggle,
} from '@app/components/Table/TableHeader'
import styles from './styles.module.css'
import { getRoomSortFunc, ROOM_THEAD_DATA, TableColumn } from '../types'
import TableRow from './TableRow'

type Props = {
  occupancyRooms: OccupancyRoom[]
  onAdd: () => void
}

const STORAGE_ROOM_COLUMN = 'roomListSelectedColumn'
const STORAGE_ROOM_ORDER = 'roomListColumnOrder'
export default function RoomTable(props: Props) {
  const page = useCurrentPage()
  const { facilityId, orgId } = extractIds(page)
  const { occupancyRooms, onAdd } = props
  const [selectedColumn, setSelectedColumn] = useState<TableColumn>(
    TableColumn.NAME
  )
  const [sortingOrder, setSortingOrder] = useState<Order>(Order.ASC)

  useEffect(() => {
    void localForage.getItem(STORAGE_ROOM_COLUMN).then((column) => {
      setSelectedColumn(column as TableColumn)
    })

    void localForage.getItem(STORAGE_ROOM_ORDER).then((order) => {
      setSortingOrder(Number(order) as Order)
    })
  }, [])

  const sortFunc = getRoomSortFunc(selectedColumn)
  const sortedRooms = sortFunc
    ? sortFunc(occupancyRooms, sortingOrder)
    : occupancyRooms
  let trs: ReactNode
  if (sortedRooms.length) {
    trs = sortedRooms.map((oRoom: OccupancyRoom) => {
      const { room = {} } = oRoom
      const { id, name } = room

      return <TableRow key={`room-tr-${id}-${name}`} occupancyRoom={oRoom} />
    })
  } else {
    trs = (
      <tr className={styles.emptyHolder}>
        <td colSpan={ROOM_THEAD_DATA.length}>
          <div>No rooms yet.</div>
          <FacilityPermissionGate
            facility={
              facilityId && orgId ? { id: facilityId, orgId } : undefined
            }
            permissions={[GroupPermission.GROUP_PERMISSION_OCCUPANCY_CREATE]}
          >
            <div>
              To get started,&nbsp;
              <ButtonLink onClick={onAdd}>add a room</ButtonLink>.
            </div>
          </FacilityPermissionGate>
        </td>
      </tr>
    )
  }

  return (
    <TableContainer>
      <Table data-cy="roomsTable">
        <TableHeader
          headers={ROOM_THEAD_DATA}
          sortable={{
            onToggle: getTableHeaderOnToggle({
              selectedColumn,
              selectedOrder: sortingOrder,
              setColumn: setSelectedColumn as (c: string) => void,
              setOrder: setSortingOrder,
              storageColumnName: STORAGE_ROOM_COLUMN,
              storageOrderName: STORAGE_ROOM_ORDER,
            }),
            selectedColumn,
            sortingOrder,
          }}
        />
        <tbody>{trs}</tbody>
      </Table>
    </TableContainer>
  )
}
