import { ReportAndAssignments } from '@augusthealth/models/com/august/protos/api/report_assignments'
import { ReportAssignment } from '@augusthealth/models/com/august/protos/report'
import { DependencyList, useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Facility } from '@shared/types/facility'
import { AsyncResult } from '@shared/utils/loading'
import {
  FetchAssignmentsResponse,
  fetchReportAssignmentsByFacility,
  listAllReportAndAssignments,
} from '@app/api/reports'
import {
  filterInFacilities,
  filterOutAssignments,
  mergeAssignments,
  Props,
} from './utils'

export default function useAllReportAssignments({
  facilities,
  deps = [],
}: {
  facilities: Facility[]
  deps?: DependencyList
}) {
  const { setError } = useContext(GlobalContext)
  const [assignments, setAssignments] = useState<
    AsyncResult<ReportAssignment[], any>
  >({
    tag: 'Loading',
  })
  const loadAssignments = ({ facilityId, orgId }: Props = {}) => {
    if (!facilityId && !orgId) {
      listAllReportAndAssignments()
        .then((response) => {
          const allAssignments = response.reduce(
            (list: ReportAssignment[], r: ReportAndAssignments) => {
              if (r.assignments) {
                list.push(...r.assignments)
              }
              return list
            },
            []
          )
          setAssignments({
            tag: 'Complete',
            value: allAssignments,
          })
        })
        .catch((err) => {
          setAssignments({ tag: 'Error', value: err })
          setError(err)
        })
    } else {
      // Only keep Facilities that need to be updated
      const filteredFacilities = filterInFacilities({
        facilities,
        facilityId,
        orgId,
      })
      if (filteredFacilities.length) {
        const promiseAssignments = filteredFacilities.reduce(
          (list: Promise<FetchAssignmentsResponse>[], f) => {
            const { id, orgId } = f
            if (id && orgId) {
              list.push(fetchReportAssignmentsByFacility({ facility: f }))
            }

            return list
          },
          []
        )

        // Remove Report assignments that will be updated
        const assignmentsNotUpdated = filterOutAssignments({
          assignments:
            (assignments.tag === 'Complete' && assignments.value) || [],
          facilityId,
          orgId,
        })

        setAssignments({ tag: 'Loading' })
        Promise.all(promiseAssignments)
          .then((responseList: FetchAssignmentsResponse[]) => {
            const assignments = responseList.map((r) => r.data)
            const newAssignments = Array.prototype.concat.apply([], assignments)
            setAssignments({
              tag: 'Complete',
              value: mergeAssignments({
                existingAssignments: assignmentsNotUpdated,
                newAssignments,
              }),
            })
          })
          .catch((err) => {
            setAssignments({ tag: 'Error', value: err })
            setError(err)
          })
      }
    }
  }

  useEffect(loadAssignments, [facilities, ...deps])

  return {
    assignments,
    loadAssignments,
  }
}
