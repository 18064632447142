import { AmbulatoryStatus_AmbulatoryResult as Status } from '@augusthealth/models/com/august/protos/ambulatory_status'
import { AmbulatorySettings } from '@augusthealth/models/com/august/protos/settings/ambulatory_settings'
import { UseFormReturn } from 'react-hook-form'
import DraggableList from '@app/libraries/DragAndDrop/DraggableList'
import AmbulatoryStatusSettings from './AmbulatoryStatusSettings'

// Minus Status.AMBULATORY_RESULT_UNSPECIFIED and Status.UNRECOGNIZED
const STATUS_LEN = Object.keys(Status).length - 2

type AmbulatorySettingsKey = keyof AmbulatorySettings

export default function AmbulatoryList({
  path = 'ambulatoryStatusSettings',
  useFormReturn,
}: {
  path?: AmbulatorySettingsKey
  useFormReturn: UseFormReturn<AmbulatorySettings>
}) {
  const list = useFormReturn.watch(path)

  return (
    <DraggableList
      path="ambulatoryStatusSettings"
      useFormReturn={useFormReturn}
      addBtn={
        list === undefined || list.length < STATUS_LEN
          ? 'Add new ambulatory'
          : false
      }
      ListItem={AmbulatoryStatusSettings}
    />
  )
}
