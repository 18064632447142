import { Brand, Predicate, String } from 'effect'
import { validStringOrNull } from '@shared/utils/parsing'

/**
 * Returns true if strings are exact matches, case-sensitive optional
 */
export const areStringsIdentical = (
  string1: string,
  string2: string,
  options?: { caseSensitive?: boolean }
) => {
  const parsedFirstString = validStringOrNull(string1)

  if (!parsedFirstString) {
    return false
  }

  const compareFirst = options?.caseSensitive ? string1 : string1.toLowerCase()
  const compareSecond = options?.caseSensitive ? string2 : string2.toLowerCase()

  return compareFirst === compareSecond
}

export type NonEmptyString = Brand.Brand<'NonEmptyString'> & string

export function isNotNullableOrEmpty(
  str: string | null | undefined
): str is NonEmptyString {
  return Predicate.isNotNullable(str) && String.isNonEmpty(str)
}
