import {
  ManualPaymentEntry,
  ManualPaymentRequest,
  PaymentMethod,
  ResidentListEntry,
} from '@shared/types/billing'
import { formatIsoDate } from '@shared/utils/date'

export interface BulkPaymentsForm {
  residents: {
    resident: ResidentListEntry
    payments: SinglePaymentForm[]
  }[]
  externalId: string
  depositDate: Date
}

export interface SinglePaymentForm {
  checkNumber: string
  checkDate: Date | null
  amountInCents: number | undefined
}

export function isValidPaymentRow(
  p: SinglePaymentForm,
  paymentMethod: PaymentMethod
) {
  if (paymentMethod === PaymentMethod.CASH) {
    return Boolean(p.checkDate && p.amountInCents)
  }
  return Boolean(p.checkNumber && p.checkDate && p.amountInCents)
}

export function needsValidation(
  p: SinglePaymentForm,
  paymentMethod: PaymentMethod
) {
  if (paymentMethod === PaymentMethod.CASH) {
    return Boolean(p.checkDate || p.amountInCents)
  }
  return Boolean(p.checkNumber || p.checkDate || p.amountInCents)
}

export function formToRequest(
  data: BulkPaymentsForm,
  paymentMethod: PaymentMethod
): ManualPaymentRequest {
  // Convert to API format

  const entries: ManualPaymentEntry[] = data.residents.flatMap((r) =>
    r.payments
      .filter((p) => isValidPaymentRow(p, paymentMethod))
      .map((p) => ({
        personId: r.resident.personId,
        ...(paymentMethod !== PaymentMethod.CASH && {
          checkNumber: p.checkNumber,
        }),
        paymentDate: formatIsoDate(p.checkDate!),
        amountCents: p.amountInCents!,
      }))
  )
  if (paymentMethod === PaymentMethod.WIRE) {
    return {
      paymentMethod,
      payments: entries,
      //This is a bogus input
      //Backend requires it but doesn't use it
      depositDate: formatIsoDate(new Date()),
    }
  }

  return {
    depositDate: formatIsoDate(data.depositDate),
    externalId: data.externalId,
    paymentMethod,
    payments: entries,
  }
}

export function getDefaultValues(residents: ResidentListEntry[]) {
  return {
    residents: residents.map((r) => ({
      resident: r,
      payments: [
        {
          checkNumber: '',
          checkDate: null,
          amountInCents: undefined,
        },
      ],
    })),
  }
}
