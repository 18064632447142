import { GroupType } from '@augusthealth/models/com/august/protos/permission'
import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import environment from '@shared/environment'
import useCurrentPage, { extractIds } from '@shared/hooks/useCurrentPage'
import { UserAccount } from '@shared/types/user'

type Props = {
  timeout: number
  user?: UserAccount
}
export const useInactivityTimer = ({ timeout, user }: Props) => {
  const [state, setState] = useState<'Active' | 'Idle'>('Active')
  const currentPage = useCurrentPage()
  const { orgId } = extractIds(currentPage)

  const handleStillHere = () => {
    activate()
  }

  const { activate, pause, start } = useIdleTimer({
    onIdle: () => setState('Idle'),
    onActive: () => setState('Active'),
    timeout,
    stopOnIdle: true,
    startManually: true,
  })

  useEffect(() => {
    if (
      user &&
      (environment.name === 'production' || environment.name === 'test')
    ) {
      const isNurseOrMedTechForOrg = user.groups?.some((g) => {
        const isMedtech = g.groupType === GroupType.GROUP_TYPE_MED_TECH
        const isNurse = g.groupType === GroupType.GROUP_TYPE_NURSE

        return isMedtech || isNurse
      })

      if (
        isNurseOrMedTechForOrg ||
        (isNurseOrMedTechForOrg && orgId === undefined)
      ) {
        start()
      } else {
        pause()
      }
    } else if (user) {
      start()
    }
  }, [user?.id])

  return {
    state,
    handleStillHere,
  }
}
