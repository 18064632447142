import {
  SignableFormAssignment,
  SignableFormMetaData,
} from '@augusthealth/models/com/august/protos/signable_form'
import { useEffect, useState } from 'react'
import { BasicSpinner } from '@shared/components/BasicSpinner'
import { Loading } from '@shared/utils/loading'
import { getGlobalAssignments } from '@app/api/form'
import PersonPageTitle from '@app/components/PersonPageTitle'
import AssignmentCard from '../AssignmentCard'

export default function GlobalAssignments({
  formList,
}: {
  formList: SignableFormMetaData[]
}) {
  const [globalAssignments, setGlobalAssignments] = useState<
    Loading<SignableFormAssignment[]>
  >({ tag: 'Loading' })

  useEffect(() => {
    void getGlobalAssignments().then((value) =>
      setGlobalAssignments({ tag: 'Complete', value })
    )
  }, [])

  if (globalAssignments.tag === 'Loading') {
    return (
      <div className="mt-[24px]">
        <PersonPageTitle subtitle title="Global Assignments" />
        <div className="mb-[32px]">
          <BasicSpinner />
        </div>
      </div>
    )
  }

  return (
    <div className="mt-[24px]">
      <PersonPageTitle subtitle title="Global Assignments" />
      <div className="mb-[32px]">
        {globalAssignments.value.map((a) => (
          <AssignmentCard
            key={`assignment-card-${a.id}`}
            assignment={a}
            formList={formList}
          />
        ))}
      </div>
    </div>
  )
}
