import { Keys } from 'react-hook-form/dist/types/path/common'
import { request, requestJson } from '@shared/api/request'
import {
  deleteIncidentAttachmentUrl,
  facilityIncidentsUrl,
  followUpActionsUrl,
  followUpActionUrl,
  incidentActionUrl,
  incidentAttachmentsUrl,
  incidentCommentsUrl,
  incidentStatusUrl,
  incidentsUrl,
  incidentUrl,
  startIncidentAlertUrl,
  stopIncidentAlertUrl,
} from '@shared/legacy_routes'
import { FacilityIds } from '@shared/types/facility'
import {
  GroupByTimeUnit,
  Incident,
  IncidentAction,
  IncidentActionType,
  IncidentsPageStats,
  IncidentStatus,
  IncidentType,
  NoteType,
} from '@shared/types/incidents'
import { Person, PersonIds } from '@shared/types/person'
import { DeepNull } from '@shared/types/utilities'
import { IncidentAttachmentType } from '../pages/Notes/IncidentAttachments'

export async function deleteIncidentAction({
  person,
  incidentId,
  actionId,
}: {
  person: Person
  incidentId: string
  actionId: string
}) {
  await requestJson({
    url: followUpActionUrl({
      person: person as Required<Person>,
      incidentId,
      actionId,
    }),
    method: 'DELETE',
  })
}

export async function createIncidentAction({
  person,
  incidentId,
  action,
}: {
  person: Person
  incidentId: string
  action: IncidentAction
}) {
  await requestJson({
    url: followUpActionsUrl({ person: person as Required<Person>, incidentId }),
    contentType: 'application/json',
    method: 'POST',
    body: JSON.stringify({ ...action, incidentId }),
  })
}

export async function createIncident({
  person,
  incident,
}: {
  person: Person
  incident: Incident
}): Promise<{ id: number }> {
  const response = await requestJson({
    url: incidentsUrl(person as Required<Person>),
    body: JSON.stringify(incident),
    contentType: 'application/json',
    method: 'POST',
  })

  return response.data
}

export async function deleteIncidentAttachment({
  person,
  incidentId,
  snapshotId,
}: {
  person: Person
  incidentId: string
  snapshotId: string
}) {
  await requestJson({
    url: deleteIncidentAttachmentUrl(
      person as Required<Person>,
      incidentId,
      snapshotId
    ),
    method: 'DELETE',
  })
}

export async function createIncidentAttachment({
  person,
  incidentId,
  attachment,
}: {
  person: Person
  incidentId: string
  attachment: IncidentAttachmentType
}): Promise<{ id: number }> {
  const { attachmentType, file } = attachment
  const formData = new FormData()
  formData.append('file', file)
  formData.append(
    'data',
    JSON.stringify({ name: attachmentType?.value || attachment.file.name })
  )

  const response = await request({
    url: incidentAttachmentsUrl(person as Required<Person>, incidentId),
    body: formData,
    method: 'POST',
  })

  return response.data
}

export async function setIncidentAlert({
  person,
  incident,
}: {
  person: Person
  incident: DeepNull<Incident>
}): Promise<Incident> {
  const response = await requestJson({
    url: startIncidentAlertUrl(
      person as Required<Person>,
      incident as Required<Incident>
    ),
    body: JSON.stringify(incident.alert),
    method: 'POST',
  })

  return response.data
}

export async function stopIncidentAlert({
  person,
  incident,
}: {
  person: Person
  incident: DeepNull<Incident>
}): Promise<Incident> {
  const response = await requestJson({
    url: stopIncidentAlertUrl(
      person as Required<Person>,
      incident as Required<Incident>
    ),
    body: JSON.stringify(incident.alert),
    method: 'POST',
  })

  return response.data
}

export async function updateIncident({
  person,
  incident,
}: {
  person: Person
  incident: DeepNull<Incident>
}): Promise<Incident> {
  const response = await requestJson({
    url: incidentUrl(
      person as Required<Person>,
      incident as Required<Incident>
    ),
    body: JSON.stringify(incident),
    contentType: 'application/merge-patch+json',
    method: 'PATCH',
  })

  return response.data
}

export async function updateIncidentAction({
  person,
  incident,
  incidentAction,
}: {
  person: Person
  incident: Incident
  incidentAction: IncidentAction
}): Promise<IncidentAction> {
  const response = await requestJson({
    url: incidentActionUrl(
      person as Required<Person>,
      incident as Required<Incident>,
      incidentAction as Required<IncidentAction>
    ),
    body: JSON.stringify(incidentAction),
    contentType: 'application/merge-patch+json',
    method: 'PATCH',
  })

  return response.data
}

export async function updateIncidentStatus({
  person,
  incident,
  incidentStatus,
}: {
  person: Person
  incident: Incident
  incidentStatus: IncidentStatus
}): Promise<Incident> {
  const response = await requestJson({
    url: incidentStatusUrl(
      person as Required<Person>,
      incident as Required<Incident>
    ),
    body: JSON.stringify(incidentStatus),
    method: 'POST',
  })

  return response.data
}

export async function fetchIncidents({
  person,
}: {
  person: Person
}): Promise<Incident[]> {
  const response = await requestJson({
    url: incidentsUrl(person as Required<Person>),
  })

  return response.data
}

type UserId = string
type ISODate = string

export type DateRangeParams = {
  startDate?: ISODate
  endDate?: ISODate
}

export type PaginationParams = {
  limit: number
  offset: number
}

export type FacilityIncidentsParams = DateRangeParams &
  PaginationParams & {
    fields?: Keys<Incident>
    noteTypes?: NoteType[]
    incidentTypes?: IncidentType[]
    incidentStatus?: IncidentStatus
    actionType?: IncidentActionType
    onAlert?: boolean
    createdBy?: UserId[]
    groupStatsBy: GroupByTimeUnit
  }

export type FacilityIncidentsResponse = {
  data: Incident[]
  meta: IncidentsPageStats
}

export async function fetchFacilityIncidents({
  facility,
  params,
}: {
  facility: FacilityIds
  params: FacilityIncidentsParams
}): Promise<FacilityIncidentsResponse> {
  return await requestJson({
    url: facilityIncidentsUrl(facility, params),
  })
}

export async function deleteIncident({
  person,
  incident,
}: {
  person: Person
  incident: Incident
}): Promise<{ id: string }> {
  const response = await requestJson({
    url: incidentUrl(
      person as Required<Person>,
      incident as Required<Incident>
    ),
    method: 'DELETE',
  })

  return response.data
}

export async function getIncident({
  person,
  incident,
}: {
  person: Required<PersonIds>
  incident: Incident
}): Promise<Incident> {
  const response = await requestJson({
    url: incidentUrl(person, incident as Required<Incident>),
  })

  return response.data
}

export const createIncidentComment = async ({
  person,
  incident,
  commentText,
}: {
  person: Person
  incident: Incident
  commentText: string
}): Promise<{ id: number }> => {
  const response = await requestJson({
    url: incidentCommentsUrl(
      person as Required<Person>,
      incident as Required<Incident>
    ),
    body: JSON.stringify({ comment: commentText }),
    contentType: 'application/json',
    method: 'POST',
  })

  return response.data
}
