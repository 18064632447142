export const BILLING_DEFAULT_PATH =
  '/orgs/:orgId/facilities/:facilityId/billing'
export const BILLING_RESIDENTS_LIST_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/billing/residents',
]
export const BILLING_SETTINGS_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/billing/settings',
]
export const BILLING_REPORTS_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/billing/reports',
]
export const BILLING_PERSON_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/billing/people/:personId',
]
export const BILLING_PERSON_STATEMENT_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/billing/people/:personId/invoice/:invoiceId/preview',
]
export const BILLING_DASHBOARD_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/billing/dashboard/:dashboardIndex',
]

export const BILLING_PATHS = [
  BILLING_DEFAULT_PATH,
  ...BILLING_RESIDENTS_LIST_PATHS,
  ...BILLING_SETTINGS_PATHS,
  ...BILLING_REPORTS_PATHS,
  ...BILLING_PERSON_PATHS,
  ...BILLING_PERSON_STATEMENT_PATHS,
  ...BILLING_DASHBOARD_PATHS,
]

// The superset dashboards have dynamic names, hence the 'string' type
export type Tab = 'Settings' | 'Residents' | 'Invoices' | 'Reports' | string

export function tabFromUrl(path: string): Tab {
  switch (path) {
    case BILLING_SETTINGS_PATHS[0]:
      return 'Settings'
    case BILLING_REPORTS_PATHS[0]:
      return 'Reports'
    case BILLING_RESIDENTS_LIST_PATHS[0]:
    case BILLING_PERSON_STATEMENT_PATHS[0]:
    case BILLING_PERSON_PATHS[0]:
      return 'Residents'
    case BILLING_DASHBOARD_PATHS[0]:
      return 'Dashboard'
    default:
      return 'Invoices'
  }
}
