import { Contact } from '@shared/types/contact'
import {
  ContactPoint,
  ContactPoint_ContactPointSystem,
} from '@shared/types/contact_point'
import { RequiredPersonIds } from '@shared/types/person'
import { updateContact } from '@app/api/contacts'

export async function setRPEmail({
  responsiblePerson,
  personIds,
  newRpEmail,
}: {
  responsiblePerson: Contact
  personIds: RequiredPersonIds
  newRpEmail: string
}) {
  const { orgId, facilityId, id: personId } = personIds
  const rpTelecom = responsiblePerson.telecom || []
  const newTelecom: ContactPoint[] = [
    ...rpTelecom,
    {
      system: ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_EMAIL,
      value: newRpEmail,
    },
  ]

  await updateContact({
    contact: { ...responsiblePerson, telecom: newTelecom },
    orgId,
    facilityId,
    personId,
  })
}
