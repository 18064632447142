import { useEffect, useState } from 'react'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { ModalTitle } from '@shared/components/baseMui/Modal/Layout'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import SearchBox from '@shared/components/SearchBox'
import { PharmacyFacility } from '@shared/types/pharmacy_facility'
import { Order } from '@shared/utils/common'
import { getOrElse, Loading } from '@shared/utils/loading'
import { getReadablePharmacyPartnerName } from '@shared/utils/pharmacyPartner'
import { tw, twx } from '@shared/utils/tailwind'
import {
  Table,
  TableContainer,
  TdWithExtraPadding,
  TrWithHoverEffect,
} from '@app/components/Table'
import TableHeader, {
  getTableHeaderOnToggle,
} from '@app/components/Table/TableHeader'
import {
  filterIntegrationsBySearchTerm,
  getSortedIntegrations,
} from '@app/pages/Tools/PharmacyTools/PharmacyIntegration/CurrentIntegrationsTable/helpers'

export enum PharmacyIntegrationTableHeader {
  pharmacyPartner = 'Pharmacy Partner',
  facilityId = 'Facility ID',
  externalId = 'External ID',
  configuration = 'Configuration',
}

type Props = {
  integrations: Loading<PharmacyFacility[]>
}
export const CurrentIntegrationsTable = ({ integrations }: Props) => {
  const [pharmacyFacilityToShow, setPharmacyFacilityToShow] =
    useState<PharmacyFacility>()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [sortingOrder, setSortingOrder] = useState<Order>(Order.ASC)
  const [selectedColumn, setSelectedColumn] =
    useState<PharmacyIntegrationTableHeader>(
      PharmacyIntegrationTableHeader.pharmacyPartner
    )
  const [sortedFilteredRows, setSortedFilteredRows] = useState<
    PharmacyFacility[]
  >(
    getSortedIntegrations({
      integrations: getOrElse(integrations, []),
      column: selectedColumn,
      sortOrder: sortingOrder,
    })
  )

  useEffect(() => {
    if (integrations.tag === 'Complete' && integrations.value.length > 1) {
      const matchesSearchTerm = filterIntegrationsBySearchTerm({
        integrations: integrations.value,
        searchTerm,
      })

      const sorted = getSortedIntegrations({
        integrations: matchesSearchTerm,
        column: selectedColumn,
        sortOrder: sortingOrder,
      })
      setSortedFilteredRows(sorted)
      return
    }
  }, [integrations, searchTerm, selectedColumn, sortingOrder])

  return (
    <div className={tw`mt-8 border-t border-border pt-4`}>
      <Modal
        open={!!pharmacyFacilityToShow}
        onClose={() => setPharmacyFacilityToShow(undefined)}
        id={'pharmacy-facility-configuration'}
      >
        <ModalTitle>
          {pharmacyFacilityToShow &&
            getReadablePharmacyPartnerName(pharmacyFacilityToShow.partner!)}
        </ModalTitle>
        <pre className={twx('overflow-auto rounded-md bg-badge-green p-4')}>
          <code>{JSON.stringify(pharmacyFacilityToShow, null, 4)}</code>
        </pre>
      </Modal>
      <div className={tw`flex flex-row items-center justify-between`}>
        <h2 className={tw`text-[20px] font-semibold`}>
          Current Pharmacy Integrations
        </h2>
        <SearchBox
          onChange={setSearchTerm}
          placeholder={'Partner, Facility ID, External ID'}
          value={searchTerm}
          holderClassName={tw`w-[300px]`}
          disabled={integrations.tag === 'Loading'}
        />
      </div>
      {integrations.tag === 'Loading' ? (
        <SimpleSpinner />
      ) : (
        <>
          <TableContainer
            className={tw`mt-4 max-h-[800px] overflow-y-scroll border border-form-input-border p-0`}
          >
            <Table>
              <TableHeader
                headers={Object.values(PharmacyIntegrationTableHeader)}
                sortable={{
                  onToggle: (columnName: string) => {
                    getTableHeaderOnToggle({
                      selectedColumn,
                      selectedOrder: sortingOrder,
                      setColumn: setSelectedColumn as (c: string) => void,
                      setOrder: setSortingOrder,
                    })(columnName)
                  },
                  selectedColumn,
                  sortingOrder,
                }}
              />
              <>
                {sortedFilteredRows && sortedFilteredRows.length > 0 ? (
                  <tbody>
                    {sortedFilteredRows.map((integration) => {
                      return (
                        <TrWithHoverEffect
                          key={`${integration.partner}:${integration.externalIdentifier}:${integration.isActive}`}
                          className={tw`px-2`}
                        >
                          <TdWithExtraPadding>
                            {getReadablePharmacyPartnerName(
                              integration.partner!
                            )}
                            <br />
                            <span className={tw`text-[10px] text-gray-07`}>
                              {integration.partner}
                            </span>
                          </TdWithExtraPadding>
                          <TdWithExtraPadding>
                            {integration.facilityId}
                          </TdWithExtraPadding>
                          <TdWithExtraPadding>
                            {integration.externalIdentifier}
                          </TdWithExtraPadding>
                          <TdWithExtraPadding>
                            <AsyncIconButton
                              buttonStyle={'primary-outline'}
                              initialIcon={'fa-magnifying-glass'}
                              onClick={() =>
                                setPharmacyFacilityToShow(integration)
                              }
                            >
                              Config Details
                            </AsyncIconButton>
                          </TdWithExtraPadding>
                        </TrWithHoverEffect>
                      )
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td>No integrations</td>
                    </tr>
                  </tbody>
                )}
              </>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  )
}
