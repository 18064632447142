import { formatCurrencyForBilling } from '@shared/utils/billing'
import { tw, twx } from '@shared/utils/tailwind'

export default function DueNowBox({ amountCents }: { amountCents: number }) {
  return (
    <div
      data-testid="due-now-box"
      className={twx(
        'flex h-full w-[210px] flex-col items-center justify-center p-6',
        {
          'bg-[#fff4f2]': amountCents > 0,
          'bg-primary-soft': amountCents === 0,
        }
      )}
    >
      <span
        className={tw`text-center text-[10px] font-semibold uppercase text-gray-04`}
      >
        Your scheduled payment
      </span>
      <span
        className={twx('mt-2 text-2xl font-semibold', {
          'text-primary-light': amountCents === 0,
          'text-orange': amountCents > 0,
        })}
      >
        {formatCurrencyForBilling(amountCents)}
      </span>
    </div>
  )
}
