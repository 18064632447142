import PrintModal, {
  FormData as PrintPopupFormData,
} from 'app/pages/Tasks/ResidentAssessment/PrintModal'
import { useContext, useEffect, useState } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { fetchPerson } from '@shared/api/person'
import GlobalContext from '@shared/contexts/GlobalContext'
import useCurrentPage, { extractIds } from '@shared/hooks/useCurrentPage'
import { documentsUrl, getTasksUrl, getTaskUrl } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { Snapshot } from '@shared/types/snapshot'
import { Task } from '@shared/types/task'
import { Loading } from '@shared/utils/loading'
import {
  hasAssessmentLevelValues as assessmentHasLevelOfCare,
  getCategoriesFromSnapshot,
} from '@shared/utils/residentAssessment'
import { tw } from '@shared/utils/tailwind'
import { taskTitle } from '@shared/utils/task'
import { getResidentAssessment } from '@app/api/resident_assessment'
import CompletedTaskVisitDocuments from '@app/components/CongratsModal/CompletedTaskVisitDocuments'
import Print, { printSelectedElements } from '@app/components/Printable'
import PersonContext from '@app/contexts/PersonContext'
import AssessmentViewer from '@app/pages/Documents/Viewer/AssessmentViewer/AssessmentViewer'
import { PrintableAssessment } from '@app/pages/Documents/Viewer/AssessmentViewer/PrintableAssessment'
import styles from './styles.module.css'
import { ResidentAssessmentSteps } from '../TaskPageNavigation/step-definitions'
import TaskPageNavigation from '../TaskPageNavigation/TaskPageNavigation'
import AssessmentPage from './AssessmentPage'
import {
  getOrderedCategoriesByStep,
  residentAssessmentOrServicePlanPath,
} from './helpers'
import LevelBadges from './LevelBadges'

/**
 * This component is represented in the UI as the Resident Assessment,
 * and can be customized via the Assessment Configuration Tool.
 */
export default function ResidentAssessment() {
  const history = useHistory()
  const currentPage = useCurrentPage()
  const { orgId, facilityId, personId, taskId, stepNumber } =
    extractIds(currentPage)
  const [printOptions, setPrintOptions] = useState<PrintPopupFormData>()
  const [showCongrats, setShowCongrats] = useState(false)
  const [showPrintPopup, setShowPrintPopup] = useState(false)
  const [snapshot, setSnapshot] = useState<Loading<Snapshot>>({
    tag: 'Loading',
  })

  const { setError } = useContext(GlobalContext)
  const { person, setPerson, tasks = [] } = useContext(PersonContext)
  const [promiseQueue, setPromiseQueue] = useState<Promise<any>>(
    Promise.resolve()
  )

  const initialTask = tasks.find((task) => task.id === taskId)
  const [task, setTask] = useState<Task | undefined>(initialTask)

  const categories =
    (snapshot.tag === 'Complete' &&
      getCategoriesFromSnapshot(snapshot.value)) ||
    []

  useEffect(() => {
    setSnapshot({ tag: 'Loading' })

    getResidentAssessment({ person: person!, withOriginalSettings: false })
      .then((value) =>
        setSnapshot({
          tag: 'Complete',
          value: { data: { augustInitialAppraisal: value.appraisal } },
        })
      )
      .catch(setError)
  }, [stepNumber])

  const taskPath = getTaskUrl({
    orgId: orgId || '',
    facilityId: facilityId || '',
    personId: personId || '',
    id: taskId,
  })

  const currentStepNumber = parseInt(stepNumber || '1')
  const onContinue = () => {
    setSnapshot({ tag: 'Loading' })
    const nextStepNumber = currentStepNumber + 1
    history.push(`${taskPath}/${nextStepNumber}`)
  }

  if (person === undefined || task === undefined) {
    return null
  }

  const tasksPath = getTasksUrl({
    orgId: orgId || '',
    facilityId: facilityId || '',
    personId: personId || '',
  })
  const documentsPath = documentsUrl({ person: person as Required<Person> })
  const pageTitle = taskTitle(task)

  const hasLevelOfCare =
    snapshot.tag === 'Complete' && assessmentHasLevelOfCare(snapshot.value)

  return (
    <div className={styles.formBody}>
      <header>
        <h1
          className={tw`mb-0 mr-[16px] text-[24px] font-medium leading-[36px]`}
        >
          {pageTitle}
        </h1>
        {hasLevelOfCare && <LevelBadges assessment={snapshot.value} />}

        <div className={styles.printHolder}>
          <i
            className="fas fa-print cursor-pointer"
            onClick={() => setShowPrintPopup(true)}
          />
        </div>
      </header>
      <div className="flex">
        <TaskPageNavigation
          stepLinks={ResidentAssessmentSteps}
          currentStepNumber={currentStepNumber}
          promiseQueue={promiseQueue}
          person={person}
          task={task}
          onStepClick={() => setSnapshot({ tag: 'Loading' })}
        />
        {snapshot.tag !== 'Loading' && (
          <Switch>
            <Route exact path={taskPath + `/7`}>
              <>
                {!showCongrats && (
                  <AssessmentViewer
                    mode={{
                      tag: 'review',
                      onMakeChanges: history.goBack,
                      onSubmit: () => {
                        // Reload Person so person.levelOfCare will be updated across pages
                        void fetchPerson({
                          orgId: orgId!,
                          personId: personId!,
                          facilityId: facilityId!,
                        }).then((value) => {
                          setPerson(value)
                          setShowCongrats(true)
                        })
                      },
                      assessmentTask: task,
                    }}
                    person={person}
                    assessment={snapshot.value}
                  />
                )}
                {showCongrats && (
                  <CompletedTaskVisitDocuments
                    redirectToDocumentsPage={() => history.push(documentsPath)}
                    redirectToTasksPage={() => history.push(tasksPath)}
                  />
                )}
              </>
            </Route>
            <Route exact path={residentAssessmentOrServicePlanPath}>
              <section className={styles.active}>
                <AssessmentPage
                  mode={{
                    tag: 'fillOut',
                    assessmentTask: task,
                    setAssessmentTask: setTask,
                    goToNextStep: onContinue,
                    onFinishLater: () => history.push(tasksPath),
                    promiseQueue,
                    setPromiseQueue,
                    onUpdate: (snapshot) =>
                      setSnapshot({ tag: 'Complete', value: snapshot }),
                  }}
                  person={person}
                  snapshot={snapshot.value}
                  categories={getOrderedCategoriesByStep({
                    categories,
                    step: currentStepNumber,
                  })}
                  showAdmissionTypeAndAssessmentReason={currentStepNumber === 1}
                  showAssessmentType={currentStepNumber === 6}
                />
              </section>
            </Route>
          </Switch>
        )}
      </div>
      {showPrintPopup && (
        <PrintModal
          onClose={(printing: boolean) => {
            setShowPrintPopup(false)
            // If printing reset printOptions afterprint
            // Otherwise, reset here to remove Print version of Appraisal
            if (!printing) {
              setPrintOptions(undefined)
            }
          }}
          onPrint={(formData: PrintPopupFormData) => {
            const setPrint = (toggle: boolean) =>
              setPrintOptions(toggle ? formData : undefined)
            printSelectedElements(setPrint)
          }}
        />
      )}
      {printOptions && snapshot.tag === 'Complete' && (
        <Print>
          <PrintableAssessment
            assessment={snapshot.value}
            categories={categories}
            person={person}
            {...printOptions}
          />
        </Print>
      )}
    </div>
  )
}
