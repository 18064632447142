import {
  SignableFormAssignment as Assignment,
  SignableFormMetaData,
} from '@augusthealth/models/com/august/protos/signable_form'

/**
 * Sort Assigments by State ASC, assignment without state will be placed at the end
 */
export function sortAssignmentsByState(s1: Assignment, s2: Assignment) {
  if (!s1.state) {
    // Assignment without state at the end
    return 1
  } else if (!s2.state) {
    return -1
  } else if (s1.state > s2.state) {
    return 1
  } else if (s1.state < s2.state) {
    return -1
  }

  return 0
}

/**
 * Filter assignments only associated with given Signable Forms
 * @param assignments [Assignment[]] All Assignments
 * @param forms [SignableForm[]] Corresponding Forms
 */
export function filterAssignmentsByForms({
  assignments,
  forms,
}: {
  assignments: Assignment[]
  forms: SignableFormMetaData[]
}) {
  const signableFormIds = forms.map((f) => f.id)

  return assignments.filter((a) => signableFormIds.includes(a.signableFormId))
}
