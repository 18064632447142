import Badge from '@shared/components/Badge'
import { Assessment } from '@shared/types/assessment'
import { Snapshot } from '@shared/types/snapshot'
import { getLevelDisplay, LevelDisplay } from '@shared/utils/residentAssessment'
import { tw } from '@shared/utils/tailwind'

export default function LevelBadges({ assessment }: { assessment: Snapshot }) {
  if (assessment.data?.augustInitialAppraisal === undefined) {
    // Blank Assessment
    return null
  }

  const levelDisplay = getLevelDisplay(assessment as Assessment)
  const badgeColor = colorForLevel(levelDisplay)

  switch (levelDisplay.tag) {
    case 'Level and Score':
      return (
        <div className={'inline-block'}>
          <Badge
            color={badgeColor}
            shape="square"
            className={tw`mr-[8px] h-[24px] text-[13px] leading-[16px]`}
          >
            Score {levelDisplay.value.score}
          </Badge>
          <Badge
            color={badgeColor}
            shape="square"
            className={tw`h-[24px] text-[13px] leading-[16px]`}
          >
            Level {levelDisplay.value.level}
          </Badge>
        </div>
      )
    case 'Level Name':
      return (
        <Badge
          color={badgeColor}
          shape="square"
          className={tw`h-[24px] text-[13px] leading-[16px]`}
        >
          {levelDisplay.value}
        </Badge>
      )
  }
}

function colorForLevel(level: LevelDisplay) {
  switch (level.tag) {
    case 'Level and Score':
      return level.value.score === 0 ? 'darkGray' : 'primaryLight'
    case 'Level Name':
      return 'primaryLight'
  }
}
