import React from 'react'
import { Person } from '@shared/types/person'
import { Task } from '@shared/types/task'
import { SignInPersonData } from '@app/components/Prospects/Tasks/popups/SignInPersonPopup'

export type PersonContextProps = {
  person?: Person
  setPerson: (person: Person) => void
  tasks?: Task[]
  setTasks: (tasks?: Task[]) => void
  isPersonLoading: boolean
  setIsPersonLoading: (isLoading: boolean) => void
  signInPersonPopupTask?: SignInPersonData
  setSignInPersonPopupTask: (task: SignInPersonData) => void
}

export const defaultProps: PersonContextProps = {
  person: undefined,
  setPerson: () => {},
  tasks: undefined,
  setTasks: () => {},
  isPersonLoading: false,
  setIsPersonLoading: () => {},
  signInPersonPopupTask: undefined,
  setSignInPersonPopupTask: () => {},
}

export default React.createContext(defaultProps)
