import { useEffect, useState } from 'react'
import { getFacilityAssessmentConfiguration } from '@shared/api/facility_settings'
import { OrgFacilitySettings } from '@shared/types/org_facility_settings'
import { Person } from '@shared/types/person'
import { AsyncResult, mapAsyncResult } from '@shared/utils/loading'

export default function ({ person }: { person: Person | undefined }) {
  const [config, setConfig] = useState<
    AsyncResult<OrgFacilitySettings, unknown>
  >({
    tag: 'Loading',
  })
  const categories = mapAsyncResult(config, (loadedConfig) => {
    return loadedConfig.settings?.appraisalSettings?.categories || []
  })

  useEffect(() => {
    if (person) {
      void getFacilityAssessmentConfiguration(person.orgId, person.facilityId)
        .then((data) => {
          setConfig({ tag: 'Complete', value: data })
        })
        .catch((error: unknown) => {
          setConfig({ tag: 'Error', value: error })
        })
    }
  }, [person])

  return {
    categories,
  }
}
