import { createCtx } from '@shared/contexts/createCtx'
import { BillingResidentSummary } from '@shared/types/billing'
import { Loading } from '@shared/utils/loading'

export type BillingResidentSummaryContextProps = {
  billingSummary: Loading<BillingResidentSummary>
}

export const [
  useBillingResidentSummaryContext,
  BillingResidentSummaryContextProvider,
] = createCtx<BillingResidentSummaryContextProps>()
