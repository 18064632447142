import { Report } from '@augusthealth/models/com/august/protos/report'
import { useState } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import DeleteConfirmPopup from './DeleteConfirmPopup'

export default function DeleteButtom({
  report,
  afterDelete,
}: {
  report: Report
  afterDelete: () => void
}) {
  const [open, setOpen] = useState(false)

  const deleteConfirmPopup = open && (
    <DeleteConfirmPopup
      report={report}
      onClose={() => setOpen(false)}
      afterDelete={afterDelete}
    />
  )

  return (
    <>
      <ButtonLink onClick={() => setOpen(true)} title="Delete">
        <i className="fa-solid fa-xmark" />
      </ButtonLink>
      {deleteConfirmPopup}
    </>
  )
}
