import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useRef, useState } from 'react'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { ResizingTextarea } from '@shared/components/BasicInput/BasicInput'
import ButtonLink from '@shared/components/ButtonLink'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { incidentPathForPerson } from '@shared/legacy_routes'
import { Incident, IncidentComment } from '@shared/types/incidents'
import { Person } from '@shared/types/person'
import Link from '@app/components/Link'
import styles from '../styles.module.css'
import {
  canCreateIncident,
  canDeleteIncident,
  canEditIncident,
} from '../helpers'
import IncidentStatusButton from '../IncidentStatusButton'
import { addIncidentComment, toCardIncident } from './helpers'
import { useIncidentCardContext } from './IncidentCardContext'
import IncidentComments from './IncidentComments'

export default function IncidentFooter({
  onIncidentChange,
  onClickEditNote,
  onClickDeleteIncident,
  onClickAddDocuments,
}: {
  onIncidentChange: (newIncident: Incident) => void
  onClickEditNote: () => void
  onClickDeleteIncident: () => void
  onClickAddDocuments: () => void
}) {
  const { setError } = useContext(GlobalContext)
  const { user, person, incident } = useIncidentCardContext()
  const [showCommentBox, setShowCommentBox] = useState(false)
  const [commentToEdit, setCommentToEdit] = useState<IncidentComment>({
    comment: '',
  })
  const [isCommentSubmitting, setIsCommentSubmitting] = useState(false)
  const textareaRef = useRef<HTMLSpanElement>(null)

  const { tag } = toCardIncident(incident)
  const canComment = hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_INCIDENT_UPDATE],
  })
  const canEdit = canEditIncident({ user, person, incident })
  const canDelete = canDeleteIncident({ user, person })
  const canCreate = canCreateIncident({ user, person })

  async function addOrEditComment() {
    setIsCommentSubmitting(true)
    try {
      await addIncidentComment(
        person,
        incident,
        commentToEdit,
        onIncidentChange,
        user
      )
      setCommentToEdit({ comment: '' })
      setShowCommentBox(false)
    } catch (e) {
      setError(e)
    }
    setIsCommentSubmitting(false)
  }

  return (
    <div className="mt-[32px]">
      {(incident.comments ?? []).length > 0 && (
        <IncidentComments incident={incident} />
      )}
      {showCommentBox && (
        <section className={styles.comments}>
          <ResizingTextarea
            className={styles.commentBox}
            name={`incident-comment-${incident.id}`}
            placeholder="Add a comment..."
            value={commentToEdit.comment}
            onChange={(e) =>
              setCommentToEdit({
                ...commentToEdit,
                comment: e.target.innerText,
              })
            }
            formControls={{
              onClickCancel: () => {
                setShowCommentBox(false)
                setCommentToEdit({ comment: '' })
              },
              onClickSubmit: () => void addOrEditComment(),
              isSubmitting: isCommentSubmitting,
            }}
            ref={textareaRef}
          />
        </section>
      )}
      <div className="print-hide flex w-full items-center justify-between">
        <div className="flex">
          {canComment && (
            <AsyncIconButton
              isLoading={false}
              buttonStyle={'tertiary-outline'}
              className={'mr-[12px]'}
              initialIcon={'fa-message'}
              onClick={() => {
                setShowCommentBox((prev) => {
                  if (!prev) {
                    // using a timeout allows for focus to shift from the button after it is clicked
                    setTimeout(() => {
                      textareaRef.current?.focus()
                    })
                  }
                  return !prev
                })
              }}
              data-testid="add-comment"
            >
              Comment
            </AsyncIconButton>
          )}
          {tag === 'Incident' && canCreate && (
            <AsyncIconButton
              isLoading={false}
              buttonStyle="tertiary-outline"
              className="mr-[12px]"
              initialIcon="fa-message"
              data-testid="add-documents"
              onClick={onClickAddDocuments}
            >
              Add Documents
            </AsyncIconButton>
          )}
          {tag === 'Incident' && (
            <IncidentStatusButton
              user={user}
              incident={incident}
              person={person}
              onChange={async (updated) => onIncidentChange(updated)}
            />
          )}
        </div>
        <div className={'flex text-[11px] leading-[12px]'}>
          {canEdit && tag === 'Incident' && (
            <Link
              data-testid={`edit-incident-${incident.id}`}
              className={`flex items-center uppercase ${
                canDelete ? 'mr-[24px]' : ''
              }`}
              to={incidentPathForPerson(
                person as Required<Person>,
                incident as Required<Incident>
              )}
            >
              <i className="fa-solid fa-pencil mr-[8px]" />
              <span className="font-bold">Edit</span>
            </Link>
          )}
          {canEdit && tag === 'Note' && (
            <ButtonLink
              data-testid="edit-note"
              className={`uppercase ${canDelete ? 'mr-[24px]' : ''}`}
              onClick={onClickEditNote}
            >
              <i className="fa-solid fa-pencil mr-[8px]" />
              <span className="font-bold">Edit</span>
            </ButtonLink>
          )}
          {canDelete && (
            <ButtonLink
              data-testid={`delete-${tag.toLowerCase()}`}
              className="uppercase"
              onClick={onClickDeleteIncident}
            >
              <i className="fa-solid fa-trash-can mr-[8px]" />
              <span className="font-bold">Delete</span>
            </ButtonLink>
          )}
        </div>
      </div>
    </div>
  )
}
