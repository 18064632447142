import { ValidDate } from '@augusthealth/models/com/august/protos/date'
import { CareAppChartingType } from '@augusthealth/models/com/august/protos/facility'
import { ReactNode } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Badge from '@shared/components/Badge'
import {
  BasicCheckbox,
  BasicInput,
} from '@shared/components/BasicInput/BasicInput'
import { ControlledLabelledCalendarInput } from '@shared/components/CalendarInput/ControlledLabelledCalendarInput'
import { EmbossedCard } from '@shared/components/EmbossedCard'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect from '@shared/components/Selects/StyledSelect'
import {
  fromDateMessageToDate,
  fromDateToDateMessage,
} from '@shared/utils/date'
import { tw } from '@shared/utils/tailwind'
import { FacilityForm } from '@app/pages/Organizations/Settings/CommunitiesPage/FacilityFormPopup/types'

export default function SuperUserSettings() {
  const { register, control, watch } = useFormContext<FacilityForm>()
  const emarSettingsIsActive = watch('emarSettings.isActive')
  const billingSettingsIsActive = watch('billingSettings.isActive')
  const careAppSettingsIsActive = watch('careAppSettings.isActive')

  return (
    <>
      <Badge
        color={'darkOrange'}
        badgeSize={'medium'}
        shape={'square'}
        className={'mt-6'}
      >
        Super User Settings
      </Badge>
      <SuperUserSetting
        title={'eMAR'}
        explanation={
          'Communities will remain in training mode until the go live date is set & passed.'
        }
      >
        <BasicCheckbox
          data-testid="emar-active-toggle"
          {...register('emarSettings.isActive')}
          labelClassName={tw`mb-0`}
        >
          Enabled
        </BasicCheckbox>
        <ControlledLabelledCalendarInput<FacilityForm, ValidDate>
          control={control}
          name={'emarSettings.goLiveMedPassDate'}
          convertToSavedValue={fromDateToDateMessage}
          convertToSelectedValue={fromDateMessageToDate}
          inputProps={calendarInputProps(!emarSettingsIsActive)}
        />
      </SuperUserSetting>
      <SuperUserSetting title={'Controlled Substance Count'}>
        <BasicCheckbox
          data-testid="controlled-substance-count"
          {...register('emarSettings.controlledCount')}
          labelClassName={tw`mb-0`}
          disabled
        >
          Enabled
        </BasicCheckbox>
      </SuperUserSetting>
      <SuperUserSetting
        title={'Billing 2.0'}
        explanation={
          'Enabling Billing will remove the resident-level Billing tab from the application.'
        }
      >
        <BasicCheckbox
          data-testid="billing-active-toggle"
          {...register('billingSettings.isActive')}
          labelClassName={tw`mb-0`}
        >
          Enabled
        </BasicCheckbox>
        <ControlledLabelledCalendarInput<FacilityForm, ValidDate>
          control={control}
          name={'billingSettings.goLiveDate'}
          convertToSavedValue={fromDateToDateMessage}
          convertToSelectedValue={fromDateMessageToDate}
          inputProps={calendarInputProps(!billingSettingsIsActive)}
        />
        <BasicInput
          placeholder={'Rainforest ID'}
          label="Rainforest ID"
          className={tw`w-full`}
          {...register('externalFields.rainforestMerchantId')}
        />
      </SuperUserSetting>
      <SuperUserSetting title={'Care Track'}>
        <BasicCheckbox
          data-testid="careapp-active-toggle"
          {...register('careAppSettings.isActive')}
          labelClassName={tw`mb-0`}
        >
          Enabled
        </BasicCheckbox>
        <ControlledLabelledCalendarInput<FacilityForm, ValidDate>
          control={control}
          name={'careAppSettings.goLiveDate'}
          convertToSavedValue={fromDateToDateMessage}
          convertToSelectedValue={fromDateMessageToDate}
          inputProps={calendarInputProps(!careAppSettingsIsActive)}
        />
        <div className={'w-1/3'}>
          <Controller
            control={control}
            name={'careAppSettings.chartingType'}
            render={({ field: { onChange, value, name } }) => (
              <StyledSelect
                placeholder={'Chart by...'}
                options={chartByOptions}
                name={name}
                value={
                  chartByOptions.find((opt) => opt.value === value) ?? null
                }
                onChange={(opt?: { value: CareAppChartingType }) =>
                  onChange(opt?.value || null)
                }
              />
            )}
          />
        </div>
      </SuperUserSetting>
      <SuperUserSetting
        title={'Test Community?'}
        explanation={'Test communities are excluded from Insights.'}
      >
        <BasicCheckbox
          data-testid="testcommunity-active-toggle"
          {...register('hellosignTestMode')}
          labelClassName={tw`mb-0`}
        >
          Yes
        </BasicCheckbox>
      </SuperUserSetting>
    </>
  )
}

function SuperUserSetting({
  children,
  title,
  explanation,
}: {
  children: ReactNode
  title: string
  explanation?: string
}) {
  return (
    <EmbossedCard className={'mt-4'}>
      <LabelAboveInput>{title}</LabelAboveInput>
      <div className={tw`flex items-center gap-4`}>{children}</div>
      {explanation && (
        <p className={tw`mt-2 text-sm font-medium text-secondary-06`}>
          {explanation}
        </p>
      )}
    </EmbossedCard>
  )
}

const calendarInputProps = (disabled: boolean) => ({
  wrapperClassName: tw`flex-grow`,
  isClearable: true,
  label: 'Go Live Date',
  iconName: 'calendar-heart',
  disabled,
})

const chartByOptions = [
  {
    label: 'Exception',
    value: CareAppChartingType.CARE_APP_CHARTING_TYPE_BY_EXCEPTION,
  },
  {
    label: 'Everything',
    value: CareAppChartingType.CARE_APP_CHARTING_TYPE_EVERYTHING,
    isDisabled: true,
  },
]
