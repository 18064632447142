import { Array, pipe } from 'effect'
import { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { bulkUpdateRoutineAdministrations } from '@shared/api/careapp'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import Card from '@shared/components/Card'
import Icon from '@shared/components/Icon'
import { LabelAboveInput, requiredWhenError } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { UserReference } from '@shared/types/annotation'
import {
  RoutineAdministration,
  RoutineAdministrationEvent,
} from '@shared/types/careapp'
import { DateAndTime } from '@shared/types/date'
import { GroupPermission } from '@shared/types/permission'
import { UserAccount } from '@shared/types/user'
import {
  fromDateTimeToDateInTimezone,
  fromDateToDateAndTimeInZone,
  toUTCIso8601DateTime,
} from '@shared/utils/date'
import { isComplete, isError, mapAsyncResult } from '@shared/utils/loading'
import {
  buildAdministeredEvent,
  getMostRecentEventId,
  getProgressType,
} from '@shared/utils/routineAdministration'
import { getProgressTypeLabel } from '@shared/utils/routineAdministrationProgressType'
import { tw, twx } from '@shared/utils/tailwind'
import RHFDateTimePicker from '@app/components/reactHookForm/DateTimePicker'
import useFacilityUsers from '@app/hooks/useFacilityUsers'
import BulkUpdateBanner from './BulkUpdateBanner'
import { getOptionalUserSelectOption } from './helpers'

type MarkAsAdministeredFormData = {
  administeredBy: UserAccount
  administeredOn: DateAndTime // DateTimePicker supports DateAndTime only
}

export default function BulkMarkAsAdministeredModal({
  orgId,
  facilityId,
  peopleCount,
  routineAdministrations,
  facilityTimeZone,
  onSubmitSuccess,
  onClose,
}: {
  orgId: string
  facilityId: string
  peopleCount: number
  routineAdministrations: RoutineAdministration[]
  facilityTimeZone: string
  onSubmitSuccess: () => void
  onClose: () => void
}) {
  const { setError } = useContext(GlobalContext)
  const { user } = useUserContext()
  const { formState, control, handleSubmit } =
    useForm<MarkAsAdministeredFormData>({
      defaultValues: {
        administeredOn: fromDateToDateAndTimeInZone(
          new Date(),
          facilityTimeZone
        ),
        administeredBy: user,
      },
    })
  const { facilityUsers } = useFacilityUsers({ orgId, facilityId })
  const facilityUserOptions = mapAsyncResult(
    facilityUsers,
    Array.filterMap((facilityUser) =>
      getOptionalUserSelectOption({
        user: facilityUser,
        requiredPermissions: [
          GroupPermission.GROUP_PERMISSION_ROUTINE_ADMINISTER,
        ],
        facility: { id: facilityId, orgId },
        excludeUserIds: [user.id],
      })
    )
  )

  const onSave = async ({
    administeredOn,
    administeredBy,
  }: MarkAsAdministeredFormData) => {
    try {
      const performer: UserReference = {
        userId: administeredBy.id,
        userName: administeredBy.name,
      }

      const withAdministered: RoutineAdministration[] = pipe(
        routineAdministrations,
        Array.map((administration) => ({
          ...administration,
          events: buildNextEvents(
            performer,
            administeredOn,
            administration,
            facilityTimeZone
          ),
        }))
      )

      await bulkUpdateRoutineAdministrations({
        orgId,
        facilityId,
        routineAdministrations: withAdministered,
      })

      onSubmitSuccess()
      onClose()
    } catch (err) {
      setError(err)
    }
  }

  const progressType = getProgressType(
    routineAdministrations[0],
    facilityTimeZone
  )

  const routineAdministrationsCount = routineAdministrations.length

  const label = getProgressTypeLabel(progressType)

  return (
    <Modal
      id="bulk-mark-administered-modal"
      contentClassName={twx('w-[565px] min-w-[565px]')}
      open
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSave)} className="flex flex-col gap-6">
        <Card className={tw`flex-col`}>
          <BulkUpdateBanner
            label={label}
            routineAdministrationsCount={routineAdministrationsCount}
            peopleCount={peopleCount}
            progressType={progressType}
          />

          <div className={tw`mt-[16px] flex-col px-[8px]`}>
            <div
              className={tw`mb-[16px] text-[14px] font-semibold text-gray-04`}
            >
              <Icon name="circle-check" variant="regular" />
              <span className={tw`ml-[8px]`}>Mark as Completed</span>
            </div>

            <div className={tw`mb-[16px] w-[384px]`}>
              <LabelAboveInput htmlFor="administeredBy" uppercase={false}>
                Completed By
              </LabelAboveInput>

              {isComplete(facilityUserOptions) && (
                <Controller
                  control={control}
                  name="administeredBy"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <StyledSelect
                        id="administeredBy"
                        placeholder="Team Member"
                        onChange={(opt: OptionTypeBase<string, UserAccount>) =>
                          onChange(opt.data)
                        }
                        options={facilityUserOptions.value}
                        value={
                          facilityUserOptions.value.find(
                            (opt) => opt.value === value.id
                          ) || null
                        }
                      />
                    )
                  }}
                />
              )}

              {isError(facilityUserOptions) && (
                <p>Error occurred: Could not get users for facility</p>
              )}
            </div>

            <div>
              <RHFDateTimePicker
                control={control}
                id="administeredOn"
                name="administeredOn"
                title="Completed On"
                uppercase={false}
                subLabel={requiredWhenError(
                  Boolean(formState.errors.administeredOn)
                )}
                required
                timeZone={facilityTimeZone}
              />
            </div>
          </div>
        </Card>

        <AsyncIconButton
          type="submit"
          disabled={formState.isSubmitting}
          isLoading={formState.isSubmitting}
          buttonStyle="primary-fill"
          buttonSize="medium"
        >
          Mark {routineAdministrationsCount} {label} as Completed
        </AsyncIconButton>
      </form>
    </Modal>
  )
}

const buildNextEvents = (
  performer: UserReference,
  administeredOn: DateAndTime,
  routineAdministration: RoutineAdministration,
  facilityTimeZone: string
): RoutineAdministrationEvent[] => {
  const occurredAt = fromDateTimeToDateInTimezone(
    administeredOn,
    facilityTimeZone
  )

  const administeredEvent = buildAdministeredEvent({
    performer,
    occurredAt: toUTCIso8601DateTime(occurredAt),
    parentId: getMostRecentEventId(routineAdministration.events),
  })

  return [administeredEvent]
}
