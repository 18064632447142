import { LinkProps, Link as RRDLink } from 'react-router-dom'
import styles from './styles.module.css'

export default function Link(props: LinkProps) {
  const { className, children, ...rest } = props

  return (
    <RRDLink {...rest} className={`${className} ${styles.link}`}>
      {children}
    </RRDLink>
  )
}
