import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import { CompletionType } from '@shared/types/api/facility_stats'
import { Person } from '@shared/types/person'
import { UserAccount } from '@shared/types/user'
import { ResidentTask } from '@app/components/Residents/CompletionStats/helpers'

export const permissionDict = {
  [CompletionType.COMPLETION_TYPE_HAS_PROFILE_PHOTO]: [
    GroupPermission.GROUP_PERMISSION_PERSON_UPDATE,
  ],
  [CompletionType.COMPLETION_TYPE_HAS_MEDICATIONS]: [
    GroupPermission.GROUP_PERMISSION_MEDICATION_CREATE,
  ],
  [CompletionType.COMPLETION_TYPE_HAS_DIET]: [
    GroupPermission.GROUP_PERMISSION_PERSON_UPDATE,
  ],
  [CompletionType.COMPLETION_TYPE_HAS_AMBULATORY_STATUS]: [
    GroupPermission.GROUP_PERMISSION_PERSON_UPDATE,
  ],
  [CompletionType.COMPLETION_TYPE_HAS_BILLING]: [
    GroupPermission.GROUP_PERMISSION_BILLING_CREATE,
  ],
  [CompletionType.COMPLETION_TYPE_HAS_PRIMARY_DR]: [
    GroupPermission.GROUP_PERMISSION_PERSON_CONTACT_UPDATE,
  ],
  [CompletionType.COMPLETION_TYPE_HAS_RESPONSIBLE_PERSON]: [
    GroupPermission.GROUP_PERMISSION_PERSON_CONTACT_SET_RP,
  ],
  [CompletionType.COMPLETION_TYPE_HAS_POLST]: [
    GroupPermission.GROUP_PERMISSION_TASK_COMPLETE_VIA_UPLOAD,
    GroupPermission.GROUP_PERMISSION_SNAPSHOT_CREATE,
  ],
  [CompletionType.COMPLETION_TYPE_HAS_602]: [
    GroupPermission.GROUP_PERMISSION_TASK_COMPLETE_VIA_UPLOAD,
    GroupPermission.GROUP_PERMISSION_SNAPSHOT_CREATE,
  ],
  [CompletionType.COMPLETION_TYPE_HAS_APPRAISAL]: [
    GroupPermission.GROUP_PERMISSION_TASK_CREATE,
    GroupPermission.GROUP_PERMISSION_SNAPSHOT_CREATE,
  ],
  [CompletionType.COMPLETION_TYPE_HAS_SERVICE_PLAN]: [
    GroupPermission.GROUP_PERMISSION_TASK_CREATE,
    GroupPermission.GROUP_PERMISSION_SNAPSHOT_CREATE,
  ],
}

export function canViewCTA({
  task,
  user,
  person,
}: {
  task: ResidentTask
  user: UserAccount | undefined
  person: Person
}) {
  const { stat } = task
  const permissions = permissionDict[stat]

  if (!permissions || !user) {
    return false
  }
  return hasPermissionForPerson({ user, person, permissions })
}
