import { ChangeEvent, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Controller, useFormContext } from 'react-hook-form'
import { BasicCheckbox } from '@shared/components/BasicInput/BasicInput'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import asapCheckboxStyles from '@app/components/AddPersonPopup/styles.module.css'

export default function MoveInDate({ required }: { required: boolean }) {
  const { control, register, getValues, setValue } = useFormContext()
  const [disableDatepicker, setDisableDatepicker] = useState(
    getValues('admissionASAP') && !required
  )

  return (
    <div className={`mt-[32px] ${asapCheckboxStyles.dateContainer}`}>
      <Controller
        control={control}
        name="moveInDate"
        rules={{ required }}
        render={({ field: { onChange }, fieldState: { error } }) => (
          <div className="mr-[16px]">
            <LabelAboveInput
              subLabel={
                required ? requiredLabel(error !== undefined) : undefined
              }
              htmlFor="moveInDate"
            >
              Move-in Date
            </LabelAboveInput>
            <DatePicker
              name="moveInDate"
              disabled={disableDatepicker}
              customInput={<CalendarInput />}
              selected={getValues('moveInDate')}
              onChange={(newValue) => {
                onChange(newValue)
                // admission date & ASAP are mutually exclusive
                setValue('admissionASAP', undefined)
              }}
            />
          </div>
        )}
      />
      {!required && (
        <div className={asapCheckboxStyles.asapCheckbox}>
          <BasicCheckbox
            {...register('admissionASAP')}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              // admission date & ASAP are mutually exclusive
              if (event.target.checked) {
                setValue('moveInDate', undefined)
                setDisableDatepicker(true)
              } else {
                setValue('admissionASAP', undefined)
                setDisableDatepicker(false)
              }
            }}
          >
            As soon as possible
          </BasicCheckbox>
        </div>
      )}
    </div>
  )
}
