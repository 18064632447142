import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import AddVitalsModal from 'app/pages/Notes/Vitals/AddVitalsModal'
import { useHistory } from 'react-router-dom'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import Icon from '@shared/components/Icon'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { Incident } from '@shared/types/incidents'
import { Person } from '@shared/types/person'
import { Loading } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import PersonPageTitle from '@app/components/PersonPageTitle'

const BTN_CLASS_NAME = tw`ml-[12px] print:hidden`

type Props = {
  allIncidents: Loading<Incident[]>
  person: Person
  refreshIncidents: () => Promise<void>
  setShowNoteModal: React.Dispatch<React.SetStateAction<boolean>>
  setShowFilterIncidentsModal: React.Dispatch<React.SetStateAction<boolean>>
}

export function NotesTitleAndButtons({
  allIncidents,
  person,
  refreshIncidents,
  setShowNoteModal,
  setShowFilterIncidentsModal,
}: Props) {
  const history = useHistory()

  return (
    <PersonPageTitle title="Notes">
      <div className={tw`flex`}>
        <PersonPermissionGate
          person={person}
          permissions={[GroupPermission.GROUP_PERMISSION_INCIDENT_CREATE]}
        >
          <AsyncIconButton
            buttonStyle="secondary-fill"
            data-testid="add-note"
            initialIcon="fa-note"
            className={BTN_CLASS_NAME}
            onClick={() => setShowNoteModal(true)}
          >
            Add Note
          </AsyncIconButton>
          <AsyncIconButton
            data-testid="add-incident"
            buttonStyle="secondary-fill"
            initialIcon="fa-hexagon-exclamation"
            className={BTN_CLASS_NAME}
            onClick={() => history.push('incidents/new')}
          >
            Add Incident
          </AsyncIconButton>
        </PersonPermissionGate>
        <PersonPermissionGate
          person={person}
          permissions={[GroupPermission.GROUP_PERMISSION_VITAL_CREATE]}
        >
          <AddVitalsModal
            btnClassName={BTN_CLASS_NAME}
            refreshIncidents={refreshIncidents}
            person={person}
          />
        </PersonPermissionGate>
        {allIncidents.tag === 'Complete' && allIncidents.value.length > 0 && (
          <AsyncIconButton
            initialIcon="fa-bars-filter"
            buttonStyle="secondary-outline"
            onClick={() => setShowFilterIncidentsModal(true)}
            className={BTN_CLASS_NAME}
          >
            Filter
          </AsyncIconButton>
        )}
        <AsyncIconButton
          buttonStyle="secondary-outline"
          buttonSize="medium"
          onClick={() => window?.print?.()}
          className={BTN_CLASS_NAME}
        >
          <Icon name="print" variant="solid" className={tw`text-[14px]`} />
        </AsyncIconButton>
      </div>
    </PersonPageTitle>
  )
}
