import { requestJson } from '@shared/api/request'
import { closeTaskUrl } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { Task } from '@shared/types/task'

export async function closeTasks(tasks: Set<Task>, person: Person) {
  return Promise.all(
    [...tasks].map((task) => {
      return requestJson({
        url: closeTaskUrl({
          task: task as Required<Task>,
          person: person as Required<Person>,
        }),
        method: 'POST',
      })
    })
  )
}
