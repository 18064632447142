import { CustomFacilityField } from '@augusthealth/models/com/august/protos/facility'
import { FormField_FacilityCustomField } from '@augusthealth/models/com/august/protos/signable_form'
import { ChangeEvent } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import Icon from '@shared/components/Icon'
import { Facility } from '@shared/types/facility'
import { tw, twx } from '@shared/utils/tailwind'
import {
  KeyType,
  makeKey,
} from '@app/pages/Tools/FacilityFieldManagement/helpers'

interface Props {
  facilities: Facility[]
  field: FormField_FacilityCustomField
  changedValues: Map<KeyType, CustomFacilityField>
  fieldValues: Map<KeyType, CustomFacilityField>
  setFieldValues: (fieldValues: Map<KeyType, CustomFacilityField>) => void
  disableForm: boolean
  onManageImage: (key: string, isNew?: boolean) => Promise<void>
  setDisableForm: (newValue: boolean) => void
  isUploading: KeyType | undefined
  onImageUpload: (
    key: KeyType,
    facility: Facility,
    event: ChangeEvent<HTMLInputElement>
  ) => Promise<void>
}

/**
 * Each row contains the value of that field for each facility
 */
export function FieldValuesCells({
  facilities,
  changedValues,
  field,
  fieldValues,
  setFieldValues,
  disableForm,
  onManageImage,
  onImageUpload,
  isUploading,
}: Props) {
  return facilities.map((f) => {
    const key = makeKey({ facility: f, field })

    const hasChanged = changedValues.has(key)
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      setFieldValues(
        new Map(fieldValues.set(key, { text: e.currentTarget.value }))
      )
    }

    if (fieldValues.get(key)?.image) {
      return (
        <td key={key} className={tw`border border-black bg-white text-center`}>
          <ButtonLink
            onClick={() => onManageImage(key)}
            disabled={changedValues.size > 0}
          >
            Manage Image
          </ButtonLink>
        </td>
      )
    }

    const textValue = fieldValues.get(key)?.text
    const iconName = isUploading === key ? 'spinner' : 'image'
    return (
      <td key={key} className={tw`border border-black bg-white`}>
        <div className={'flex items-center'}>
          <input
            id={`text-${key}`}
            disabled={disableForm}
            className={twx(`flex-grow p-[4px]`, {
              'bg-white': !hasChanged,
              'bg-[rgb(233,213,255)]': hasChanged,
            })}
            value={textValue ?? ''}
            onChange={onChange}
          />
          {!textValue && !hasChanged && (
            <ButtonLink disabled={changedValues.size > 0}>
              <input
                className="visually-hidden"
                disabled={changedValues.size > 0 || isUploading !== undefined}
                id={`picture-${key}`}
                type="file"
                accept="image/png, image/jpeg"
                capture="user"
                onChange={(e) => onImageUpload(key, f, e)}
              />
              <label
                htmlFor={`picture-${key}`}
                className={'mb-0 cursor-pointer'}
              >
                <Icon
                  name={iconName}
                  className={twx('mx-[8px]', {
                    'fa-spin': isUploading === key,
                  })}
                />
              </label>
            </ButtonLink>
          )}
        </div>
      </td>
    )
  })
}
