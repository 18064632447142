import { Facility } from '@shared/types/facility'
import { Incident } from '@shared/types/incidents'
import { Person } from '@shared/types/person'
import { Download624Link } from './ActionLinks'

export default function NotifyStateLink({
  facility,
  incident,
  person,
}: {
  facility: Facility
  incident: Incident
  person: Person
}) {
  const { infoUrl, isProjectable = false } =
    facility.stateIncidentReportInfo || {}
  if (isProjectable) {
    return <Download624Link incident={incident} person={person} />
  } else if (infoUrl) {
    return (
      <a target="_blank" rel="noreferrer" href={infoUrl}>
        Reporting website
      </a>
    )
  }

  return null
}
