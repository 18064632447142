import ArchivedAssessmentWrapper from 'app/pages/ArchivedDocuments/ArchivedAssessmentWrapper'
import { RouteComponentProps } from 'react-router'
import { Snapshot } from '@shared/types/snapshot'
import { getCategoriesFromSnapshot } from '@shared/utils/residentAssessment'
import usePerson from '@app/hooks/usePerson'
import AssessmentPage from '../Tasks/ResidentAssessment/AssessmentPage'
import { getOrderedCategories } from '../Tasks/ResidentAssessment/helpers'

interface Props
  extends RouteComponentProps<{
    orgId: string
    facilityId: string
    personId: string
    documentId: string
  }> {
  snapshot: Snapshot
}

export default function ArchivedAssessment({ match, snapshot }: Props) {
  const { orgId, facilityId, personId } = match.params
  const categories = getCategoriesFromSnapshot(snapshot) ?? []

  const { person } = usePerson({
    initialData: {
      orgId,
      facilityId,
      id: personId,
    },
    dependencies: [personId],
  })

  if (person.tag === 'Loading') {
    return null
  }

  const isExternalAppraisal =
    snapshot.data?.augustInitialAppraisal?.isExternalAppraisal ?? false

  return (
    <ArchivedAssessmentWrapper
      title="Resident Assessment"
      person={person.value}
      snapshot={snapshot}
    >
      {isExternalAppraisal && (
        <div className={'mb-[16px]'}>
          This appraisal was conducted outside of August Health.
        </div>
      )}
      {!isExternalAppraisal && (
        <AssessmentPage
          showAdmissionTypeAndAssessmentReason={true}
          showAssessmentType={true}
          mode={{ tag: 'view' }}
          person={person.value}
          snapshot={snapshot}
          categories={getOrderedCategories({ categories })}
        />
      )}
    </ArchivedAssessmentWrapper>
  )
}
