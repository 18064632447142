import { DischargeDetails_DischargeReason } from '@augusthealth/models/com/august/protos/discharge_details'
import { mergePatchPerson } from '@shared/api/person'
import { Person, ResidentStatus } from '@shared/types/person'
import { DeepNull } from '@shared/types/utilities'
import { fromDateToDateMessage } from '@shared/utils/date'
import { DischargeReasonOption, MoveOutFormData } from './types'

type MoveOutDetailsPatch = DeepNull<
  Pick<Person, 'residentStatus' & 'dischargeInformation'>
>

export const dischargeReasonOptions: DischargeReasonOption[] = [
  {
    value: DischargeDetails_DischargeReason.DISCHARGE_REASON_DECEASED,
    label: 'Deceased',
  },
  {
    value: DischargeDetails_DischargeReason.DISCHARGE_REASON_MOVE_TO_PRIVATE,
    label: 'Moved to Private Residence',
  },
  {
    value:
      DischargeDetails_DischargeReason.DISCHARGE_REASON_MOVE_TO_INDEPENDENT_LIVING,
    label: 'Moved to Independent Living',
  },
  {
    value:
      DischargeDetails_DischargeReason.DISCHARGE_REASON_MOVE_TO_OTHER_ASSISTED_LIVING,
    label: 'Moved to Other Assisted Living',
  },
  {
    value:
      DischargeDetails_DischargeReason.DISCHARGE_REASON_MOVE_TO_SKILLED_NURSING,
    label: 'Moved to Skilled Nursing',
  },
  {
    value: DischargeDetails_DischargeReason.DISCHARGE_REASON_OTHER,
    label: 'Other',
  },
]

export const getDischargeReasonDisplayName = (
  reason: DischargeDetails_DischargeReason
): string | undefined => {
  return dischargeReasonOptions.find((r) => r.value === reason)?.label
}

export const getDefaultValues = (): MoveOutFormData => {
  return {
    reason: null,
    moveOutDate: new Date(),
    financialEndDate: null,
    notes: null,
  }
}

const makeMoveOutPatch = (formData: MoveOutFormData): MoveOutDetailsPatch => ({
  dischargeInformation: {
    reason: formData.reason!.value,
    notes: formData.notes ? formData.notes : null,
    moveOutDate: fromDateToDateMessage(formData.moveOutDate!),
    financialMoveOutDate: formData.financialEndDate
      ? fromDateToDateMessage(formData.financialEndDate)
      : null,
  },
  residentStatus: ResidentStatus.RESIDENT_STATUS_DISCHARGED,
})

const personFieldsForMoveOutForm = [
  'id',
  'orgId',
  'residentStatus',
  'dischargeInformation',
  'facilityId',
]

export function updatePerson(person: Person, formData: MoveOutFormData) {
  return mergePatchPerson({
    fId: person.facilityId,
    pId: person.id,
    orgId: person.orgId,
    patch: makeMoveOutPatch(formData),
    fields: personFieldsForMoveOutForm,
  })
}
