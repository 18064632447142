import { MedGroup } from '@augusthealth/models/com/august/protos/api/med_group'
import { requestJson } from '@shared/api/request'
import { apiFacilityUrl } from '@shared/legacy_routes'
import { Facility, FacilityIds } from '@shared/types/facility'
import { getUpdatedGroup } from '@shared/utils/careGroup'

function apiMedGroupUrl(facility: FacilityIds) {
  const { id: facilityId, orgId } = facility
  return `${apiFacilityUrl(orgId, facilityId)}/medGroups`
}

export async function fetchMedGroups({
  facility,
}: {
  facility: FacilityIds
}): Promise<MedGroup[]> {
  const response = await requestJson({
    url: apiMedGroupUrl(facility),
    method: 'GET',
  })

  return response.data
}

type FacilityId = string
export type MedGroupsMap = Record<FacilityId, MedGroup[]>

export async function fetchAllMedGroups({
  facilities,
}: {
  facilities: FacilityIds[]
}): Promise<MedGroupsMap> {
  const facilityIds = facilities.map((f) => f.id)
  const promises = facilities.map((f) => fetchMedGroups({ facility: f }))

  return Promise.all(promises).then((listInList: MedGroup[][]) => {
    return listInList.reduce((hash, groups, i) => {
      hash[facilityIds[i]] = groups
      return hash
    }, {})
  })
}

async function createMedGroup({
  medGroup,
  facility,
}: {
  medGroup: MedGroup
  facility: FacilityIds
}): Promise<{ id: string }> {
  const response = await requestJson({
    url: apiMedGroupUrl(facility),
    method: 'POST',
    body: JSON.stringify(medGroup),
  })

  return response.data
}

async function updateMedGroup({
  medGroup,
  facility,
}: {
  medGroup: MedGroup
  facility: FacilityIds
}): Promise<{ id: string }> {
  const { name, id } = medGroup
  const response = await requestJson({
    contentType: 'application/merge-patch+json',
    url: `${apiMedGroupUrl(facility)}/${id}`,
    method: 'PATCH',
    body: JSON.stringify({ name }),
  })

  return response.data
}

export async function deleteMedGroup({
  group,
  facility,
  force = false,
}: {
  group: MedGroup
  facility: FacilityIds
  force?: boolean
}): Promise<{ id: string }> {
  const response = await requestJson({
    url: `${apiMedGroupUrl(facility)}/${group.id}?force=${force}`,
    method: 'DELETE',
  })

  return response.data
}

export async function createOrUpdateMultipleMedGroups({
  facility,
  originalMedGroups,
  updatedMedGroups,
}: {
  facility: Facility
  originalMedGroups: MedGroup[]
  updatedMedGroups: MedGroup[]
}): Promise<any> {
  const medGroupsToCreate = updatedMedGroups.filter((c) => !c.id && c.name)
  const promisesToCreate = medGroupsToCreate.map(async (g) => {
    await createMedGroup({
      facility: facility as FacilityIds,
      medGroup: g,
    })
  })

  const medGroupsToUpdate = getUpdatedGroup(updatedMedGroups, originalMedGroups)
  const promisesToUpdate = medGroupsToUpdate
    .filter((cg) => cg.name)
    .map(async (g) => {
      await updateMedGroup({
        facility: facility as FacilityIds,
        medGroup: g,
      })
    })

  return Promise.all([...promisesToCreate, ...promisesToUpdate])
}
