import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import classNames from 'classnames'
import { useState } from 'react'
import { DotDivider } from '@shared/components/DotDivider'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import {
  Incident,
  IncidentAction,
  IncidentActionStatus,
} from '@shared/types/incidents'
import { Person } from '@shared/types/person'
import { UserAccount } from '@shared/types/user'
import { toggleIncidentActionStatus } from '../helpers'

export function ActionButton({
  incident,
  incidentAction,
  user,
  person,
  onUpdate,
}: {
  incident: Incident
  incidentAction: IncidentAction
  user: UserAccount
  person: Person
  onUpdate: (newIncident: Incident) => Promise<void>
}) {
  const [updating, setUpdating] = useState(false)
  const canEdit = hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_INCIDENT_ACTION_UPDATE],
  })

  const actionComplete =
    incidentAction.status ===
    IncidentActionStatus.INCIDENT_ACTION_STATUS_COMPLETED
  const iconClasses = classNames({
    'fa mr-[8px]': true,
    'fa-circle-dashed': !actionComplete && !updating,
    'fa-circle-check': actionComplete && !updating,
    'fa-solid fa-spinner fa-spin': updating,
  })
  const textColor = classNames({
    'text-tags-happy': actionComplete,
    'text-gray-07': !actionComplete,
  })

  return (
    <button
      data-cy={`toggle-${incidentAction.type}`}
      disabled={updating || !canEdit}
      onClick={async () => {
        setUpdating(true)
        try {
          const updatedIncidentAction = await toggleIncidentActionStatus({
            incident,
            followUpAction: incidentAction,
            person,
          })

          await onUpdate({
            ...buildUpdatedIncident(incident, updatedIncidentAction),
            comments: incident.comments ?? [],
          })
          setUpdating(false)
        } catch (e) {
          /* empty */
        }
      }}
    >
      <div className={textColor}>
        <i className={iconClasses} />
        {canEdit && (
          <>
            {actionComplete ? 'Done' : 'Mark as Done'}
            {actionComplete && canEdit && (
              <span className={'text-gray-07'}>
                <DotDivider />
                Undo
              </span>
            )}
          </>
        )}
        {!canEdit && <>{actionComplete ? 'Done' : 'Open'}</>}
      </div>
    </button>
  )
}

function buildUpdatedIncident(
  incident: Incident,
  updatedIncidentAction: IncidentAction
): Incident {
  return {
    ...incident,
    detail: {
      ...incident.detail,
      incidentDetail: {
        ...incident.detail?.incidentDetail,
        incidentActions: incident.detail?.incidentDetail?.incidentActions?.map(
          (action) => {
            if (action.id === updatedIncidentAction.id) {
              return updatedIncidentAction
            }
            return action
          }
        ),
      },
    },
  }
}
