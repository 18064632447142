import {
  FormDisplay,
  SignableForm,
} from '@augusthealth/models/com/august/protos/signable_form'
import { ChangeEvent, useContext, useState } from 'react'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredWhenError } from '@shared/components/Labels'
import GlobalContext from '@shared/contexts/GlobalContext'
import { setFormDisplay } from '@app/api/form'

type Props = {
  form: SignableForm
  onClose: () => void
  reload: () => Promise<any>
}

export default function EditDisplayPopup(props: Props) {
  const { setError } = useContext(GlobalContext)
  const { form, onClose, reload } = props
  const { id, name, display } = form
  const [data, setData] = useState<FormDisplay | undefined>(display)
  const [isInvalidJson, setIsInvalidJson] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const setFormDisplayData = (str: string) => {
    try {
      setData(JSON.parse(str))
      setIsInvalidJson(false)
    } catch (err) {
      console.warn(err)
      setIsInvalidJson(true)
    }
  }
  const onConfirm = async () => {
    if (!isInvalidJson && id && data) {
      setIsSubmitting(true)
      try {
        await setFormDisplay({ id, body: data })
        return reload().then(() => onClose())
      } catch (e) {
        setError(e)
      } finally {
        setIsSubmitting(false)
      }
    }

    return Promise.resolve()
  }

  return (
    <AnimatedPopup title={name!}>
      <LabelAboveInput
        htmlFor="display-textarea"
        subLabel={requiredWhenError(isInvalidJson)}
        errorMessage="This JSON is not valid!"
      >
        Display:
      </LabelAboveInput>
      <BasicTextarea
        id="display-textarea"
        style={{
          height: '60vh',
          backgroundColor: '#fff',
          padding: '12px',
        }}
        onChange={(ev: ChangeEvent<HTMLTextAreaElement>) =>
          setFormDisplayData(ev.target.value)
        }
        defaultValue={(data && JSON.stringify(data, null, 2)) || ''}
      />
      <div className="mt-[16px]">
        <Button
          buttonStyle="secondary-outline"
          type="button"
          width="138px"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          className="ml-[16px]"
          buttonStyle="primary-fill"
          type="button"
          width="138px"
          onClick={onConfirm}
          disabled={isSubmitting || isInvalidJson}
        >
          Save
        </Button>
      </div>
    </AnimatedPopup>
  )
}
