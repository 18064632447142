import {
  AppraisalSettings_AssessmentReason,
  AppraisalSettings_AssessmentReasonWrapper,
} from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { v4 as uuidv4 } from 'uuid'
import { AssessmentReason } from '@shared/types/assessment_configuration'
import { assessmentReasonToDisplay } from '@shared/utils/assessmentConfiguration'

/**
 * Creates an array of dropdown options for an assessment reason
 */
export const ASSESSMENT_REASON_OPTIONS = Object.keys(
  AppraisalSettings_AssessmentReason
)
  .filter(
    (reason: AppraisalSettings_AssessmentReason) =>
      reason !==
        AppraisalSettings_AssessmentReason.ASSESSMENT_REASON_UNSPECIFIED &&
      reason !== AppraisalSettings_AssessmentReason.UNRECOGNIZED &&
      reason !== AppraisalSettings_AssessmentReason.ASSESSMENT_REASON_OTHER
  )
  .map((reason: AppraisalSettings_AssessmentReason) => ({
    label: assessmentReasonToDisplay({
      assessmentReason: reason,
      otherReason: undefined,
    }),
    value: reason,
  }))
export const buildAssessmentReasonsForEditing = (
  reasons: AppraisalSettings_AssessmentReasonWrapper[]
): AssessmentReason[] => {
  return reasons.map((r) => ({
    ...r,
    tempId: uuidv4(),
  }))
}

export const buildAssessmentReasonsForSubmission = (
  reasons: AssessmentReason[]
): AppraisalSettings_AssessmentReasonWrapper[] => {
  return reasons.map((r) => {
    return {
      ...r,
      tempId: undefined,
    }
  })
}

export const haveAssessmentReasonsChanged = (
  reasons: AssessmentReason[],
  originalReasons: AppraisalSettings_AssessmentReasonWrapper[] = []
): boolean => {
  const hasMatchingOriginalReason = originalReasons.every((og) => {
    const matchedOnReason = reasons.filter(
      (r) =>
        r.assessmentReason === og?.assessmentReason &&
        r.otherReason === og?.otherReason
    )

    return matchedOnReason.length > 0
  })

  if (!hasMatchingOriginalReason) {
    return true
  }

  return reasons.some((newReason) => {
    const matchingOriginal = originalReasons.find(
      (og) =>
        og.assessmentReason === newReason.assessmentReason &&
        og.otherReason === newReason.otherReason
    )

    return !matchingOriginal
  })
}
