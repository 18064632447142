import { SignatureInfoWithSkip } from '@shared/api/task'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import { GroupPermission } from '@shared/types/permission'
import { Person } from '@shared/types/person'
import { SignerRole } from '@shared/types/snapshot'
import { SignatureStatus, Task, TaskStatus } from '@shared/types/task'
import { UserAccount } from '@shared/types/user'
import { getIsComplete, nextSigner } from './task'

type TaskUserProps = {
  person: Person
  task: Task
  user: UserAccount
}

export function showSignInPersonLink({ person, task, user }: TaskUserProps) {
  const nextToSigner = nextSigner(task)
  const nextSignerRole = nextToSigner?.role

  return (
    hasPermissionForPerson({
      user,
      person,
      permissions: [
        GroupPermission.GROUP_PERMISSION_SNAPSHOT_IN_PERSON_SIGNATURE_URL_READ,
      ],
    }) &&
    !!nextSignerRole &&
    [
      SignerRole.SIGNER_ROLE_RESPONSIBLE_PARTY,
      SignerRole.SIGNER_ROLE_RESIDENT,
      SignerRole.SIGNER_ROLE_EXTERNAL,
    ].includes(nextSignerRole)
  )
}

function getIsProcessingOrSigned(task: Task) {
  return (
    task.status === TaskStatus.TASK_STATUS_PENDING_SIGNATURES &&
    task.signatures?.every(
      (v) =>
        v.status === SignatureStatus.SIGNATURE_STATUS_PROCESSING ||
        v.status === SignatureStatus.SIGNATURE_STATUS_SIGNED
    )
  )
}

export function getIsCompleteOrProcessing(task: Task) {
  return getIsComplete(task) || getIsProcessingOrSigned(task)
}

export function signaturesAreProcessing(task: Task) {
  return task.signatures?.some(
    (s) => s.status === SignatureStatus.SIGNATURE_STATUS_PROCESSING
  )
}

export function oneOfSignaturesIsSigned(task: Task) {
  return task.signatures?.some(
    (s) => s.status === SignatureStatus.SIGNATURE_STATUS_SIGNED
  )
}

export function getIsRpSignatureRequired(signers: SignatureInfoWithSkip[]) {
  return signers.some(
    (s) =>
      s.signer?.role === SignerRole.SIGNER_ROLE_RESPONSIBLE_PARTY &&
      !s.signer.skip
  )
}

export function getIsExternalSignatureRequired(
  signers: SignatureInfoWithSkip[]
) {
  return signers.some(
    (s) => s.signer?.role === SignerRole.SIGNER_ROLE_EXTERNAL && !s.signer.skip
  )
}
