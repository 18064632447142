import { Immunization } from '@augusthealth/models/com/august/protos/immunization'
import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { PersonIds } from '@shared/types/person'
import { Loading } from '@shared/utils/loading'
import { getSortedImmunizations } from '../api/immunizations'
import PersonContext from '../contexts/PersonContext'

export default function useImmunizations(): {
  immunizations: Loading<Immunization[]>
  reloadImmunizations: () => Promise<void>
} {
  const { setError } = useContext(GlobalContext)
  const { person } = useContext(PersonContext)

  const [immunizations, setImmunizations] = useState<Loading<Immunization[]>>({
    tag: 'Loading',
  })

  const reloadImmunizations = async () => {
    if (person) {
      try {
        const immunizations = await getSortedImmunizations(person)
        setImmunizations({ tag: 'Complete', value: immunizations })
      } catch (e) {
        setError(e)
      }
    }
  }

  useEffect(() => {
    setImmunizations({ tag: 'Loading' })
    void reloadImmunizations()
  }, [person?.id])

  return { immunizations, reloadImmunizations }
}

export function useImmunizationsByPerson({ person }: { person?: PersonIds }) {
  const { setError } = useContext(GlobalContext)

  const [immunizations, setImmunizations] = useState<Loading<Immunization[]>>({
    tag: 'Loading',
  })

  const reloadImmunizations = async () => {
    if (person?.id) {
      try {
        const immunizations = await getSortedImmunizations(
          person as Required<PersonIds>
        )
        setImmunizations({ tag: 'Complete', value: immunizations })
      } catch (e) {
        setError(e)
      }
    }
  }

  useEffect(() => {
    setImmunizations({ tag: 'Loading' })
    void reloadImmunizations()
  }, [person?.id])

  return { immunizations, reloadImmunizations }
}
