import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import styles from './styles.module.css'
import { ActionMenuItemProps } from './types'

export function ActionMenuItem({
  onClick,
  setShowDropdown,
  item,
  icon,
  permissions,
  person,
  disabled = false,
}: ActionMenuItemProps) {
  return (
    <PersonPermissionGate person={person} permissions={permissions}>
      <li className={styles.dropdownListItem}>
        <button
          disabled={disabled}
          className={styles.dropdownButton}
          id={item}
          onClick={() => {
            setShowDropdown(false)
            onClick()
          }}
        >
          <i className={`${icon} ${styles.icon}`} />
          <span className="ml-[8px]">{item}</span>
        </button>
      </li>
    </PersonPermissionGate>
  )
}
