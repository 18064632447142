import urlcat from 'urlcat'
import { fetchJson, requestJson } from '@shared/api/request'
import { ApiResponse } from '@shared/api/response'
import { baseUrl, facilityUrl } from '@shared/api/urls'
import { PharmacyFacility } from '@shared/types/pharmacy_facility'

export const savePharmacyIntegration = async ({
  orgId,
  facilityId,
  payload,
}: {
  orgId: string
  facilityId: string
  payload: PharmacyFacility
}): Promise<ApiResponse> => {
  const response = (await fetchJson<ApiResponse>(
    `${facilityUrl(orgId, facilityId)}/pharmacyIntegration`,
    {
      method: 'POST',
      body: JSON.stringify(payload),
    }
  )) as ApiResponse

  return response
}

export const updatePharmacyIntegration = async (
  payload: PharmacyFacility
): Promise<PharmacyFacility> => {
  const url = urlcat(
    `${baseUrl}/pharmacies/partners/:partnerId/facilities/:externalIdentifier`,
    {
      partnerId: payload.partner,
      externalIdentifier: payload.externalIdentifier,
    }
  )
  const response = (await requestJson({
    url,
    contentType: 'application/merge-patch+json',
    method: 'PATCH',
    body: JSON.stringify(payload),
  })) as ApiResponse<PharmacyFacility>

  return response.data
}

export const getPharmacyIntegrations = (): Promise<PharmacyFacility[]> => {
  return fetchJson<PharmacyFacility[]>(`${baseUrl}/pharmacies/facilities`, {
    method: 'GET',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  }).then((response) => response.data) as Promise<PharmacyFacility[]>
}
