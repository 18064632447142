import { Charge } from '@augusthealth/models/com/august/protos/charge'
import { Person } from '@shared/types/person'
import {
  deleteOneTimeCharge,
  deleteRecurringCharge,
  updateRecurringCharge,
} from '@app/api/charges'
import { OneTimeCharge } from './OneTimeCharge'
import { RecurringCharge } from './RecurringCharge'

export async function deleteCharge(
  charge: RecurringCharge | OneTimeCharge,
  person: Person
) {
  if (isRecurring(charge)) {
    return await deleteRecurringCharge(charge.id, person)
  } else {
    return await deleteOneTimeCharge(charge.id, person)
  }
}

export async function deleteRateChange(
  person: Person,
  charge: Charge,
  rateIndex: number
) {
  const updatedRates = [...(charge.recurring!.rates || [])]
  updatedRates.splice(rateIndex, 1)

  const updatedCharge: Charge = {
    ...charge,
    payer: undefined,
    payerId: charge.payer?.id,
    recurring: {
      ...charge.recurring,
      rates: updatedRates,
    },
  }

  return await updateRecurringCharge(person, updatedCharge)
}

export function isRecurring(
  charge: OneTimeCharge | RecurringCharge
): charge is RecurringCharge {
  return (charge as RecurringCharge).recurrence !== undefined
}
