import SearchBox from '@shared/components/SearchBox'
import { tw } from '@shared/utils/tailwind'

type Props = {
  onChange: (val: string) => void
  value: string
  clearIconClass?: string
  disabled?: boolean
  holderClassName?: string
  name?: string
  onFocus?: () => void
  placeholder?: string
}

export function PersonSearchBox({
  placeholder = 'First or last name...',
  ...rest
}: Props) {
  return (
    <SearchBox
      holderClassName={tw`w-[240px] grow-0`}
      placeholder={placeholder}
      {...rest}
    />
  )
}
