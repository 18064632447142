import { cloneDeep } from 'lodash'
import React, { useContext, useEffect } from 'react'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { SmallDotDivider } from '@shared/components/DotDivider'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { MedicationOrder } from '@shared/types/medication_order'
import { PersonStats } from '@shared/types/person_stats'
import { ResidentOrders } from '@shared/types/pharmacy_integration'
import { getProfileSvgPath } from '@shared/utils/person'
import { tw } from '@shared/utils/tailwind'
import {
  assignMedicationOrdersToResident,
  bulkRejectMedicationOrders,
} from '@app/api/medicationOrders'
import { getUnassignedMedicationOrderKey } from '@app/components/Residents/Medications/Orders/helpers'
import usePerson from '@app/hooks/usePerson'
import styles from './review_modal.module.css'
import { ConfirmAssignmentOverlay } from './ConfirmAssignmentOverlay'
import {
  AssignedPerson,
  AssignmentLoading,
  getUnassignedDetails,
  ReviewUnassignedOrderMode,
} from './helpers'
import { ReviewUnassignedOrderModalFooter } from './ReviewUnassignedOrderModalFooter'
import UnassignedOrder from './UnassignedOrder'

type Props = {
  order: ResidentOrders
  orgId: string
  facilityId: string
  closeModal: () => void
  refreshOrders: () => Promise<void>
}

const ReviewUnassignedOrderModal = ({
  order,
  orgId,
  facilityId,
  closeModal,
  refreshOrders,
}: Props) => {
  const { setError } = useContext(GlobalContext)
  const [modalState, setModalState] = React.useState<
    'initial' | 'assignment' | 'confirmation' | 'reject'
  >('initial')

  const [selectedPerson, setSelectedPerson] =
    React.useState<OptionTypeBase<PersonStats> | null>(null)

  const [loading, setLoading] = React.useState<AssignmentLoading>(false)

  const { person } = usePerson({
    initialData: {
      id: selectedPerson?.value.person?.id,
      orgId: selectedPerson?.value.person?.orgId,
      facilityId: selectedPerson?.value.person?.facilityId,
    },
    fields: ['birthDate'],
    dependencies: [selectedPerson],
    showWhenLoading: true,
  })

  useEffect(() => {
    if (modalState === 'initial') {
      setSelectedPerson(null)
    }
  }, [modalState])

  const unassignedPerson = getUnassignedDetails(order)
  const orderCount = order.medicationOrders?.length

  const assignedPerson: AssignedPerson | undefined =
    selectedPerson && person.tag === 'Complete'
      ? {
          ...cloneDeep(selectedPerson!.value.person),
          birthDate: person?.value?.birthDate,
          profilePhotoUrl:
            selectedPerson!.value.profilePhotoUrl ??
            getProfileSvgPath(selectedPerson!.value.person),
        }
      : undefined

  const { partner, externalFacilityIdentifier, externalResidentIdentifier } =
    unassignedPerson
  const assignToResident = async () => {
    setLoading('assign')
    try {
      await assignMedicationOrdersToResident({
        person: selectedPerson!.value.person,
        body: {
          partner,
          externalFacilityIdentifier,
          externalResidentIdentifier,
        },
      })

      await refreshOrders()
    } catch (e) {
      setError(e)
    }
    closeModal()
  }

  const onConfirmReject = async () => {
    setLoading('reject')
    try {
      await bulkRejectMedicationOrders({
        orgId: orgId,
        facilityId: facilityId,
        pharmacyPartner: partner,
        externalFacilityId: externalFacilityIdentifier,
        externalResidentId: externalResidentIdentifier,
      })
      await refreshOrders()
    } catch (e) {
      setError(e)
    } finally {
      closeModal()
    }
  }

  function getMode(): ReviewUnassignedOrderMode {
    if (modalState === 'initial')
      return {
        tag: modalState,
        orderCount: order.medicationOrders?.length as number,
        onReject: () => setModalState('reject'),
        onAssign: () => setModalState('assignment'),
      }
    else if (modalState === 'confirmation' || modalState === 'assignment')
      return {
        tag: modalState,
        selectedPerson,
        person,
        onPersonSelect: (e: OptionTypeBase<PersonStats>) => {
          setSelectedPerson(e)
        },
        onCancel: () => setModalState('initial'),
        onContinue: () => setModalState('confirmation'),
      }
    else
      return {
        tag: modalState,
        onCancel: () => setModalState('initial'),
        onConfirmReject: onConfirmReject,
        orderCount: order.medicationOrders?.length as number,
      }
  }

  return (
    <Modal
      id="review-unassigned-med-orders"
      open={true}
      onClose={closeModal}
      contentClassName={tw`max-h-[644px] w-[584px] min-w-[584px] max-w-[584px]`}
    >
      <div className={styles.modalContents}>
        <div className={styles.details}>
          <img src={unassignedPerson.photoUrl} className={styles.pic} />
          <div className={styles.header}>
            {orderCount} {orderCount === 1 ? 'order' : 'orders'} for{' '}
            <span className={'capitalize'}>
              {unassignedPerson.name.toLowerCase()}
            </span>
          </div>
          <div className={'text-[16px] leading-[24px]'}>
            {unassignedPerson.gender} <SmallDotDivider />{' '}
            {unassignedPerson.dateOfBirth} ({unassignedPerson.age} years)
          </div>
        </div>
        <div className={styles.unmatchedMessage}>
          We don't have an exact match for this resident
        </div>

        <div className={styles.pendingOrderContainer}>
          {order.medicationOrders?.map((medOrder) => (
            <UnassignedOrder
              order={medOrder as MedicationOrder}
              key={getUnassignedMedicationOrderKey(medOrder)}
            />
          ))}
        </div>
        <ReviewUnassignedOrderModalFooter mode={getMode()} loading={loading} />
        {modalState === 'confirmation' && selectedPerson && assignedPerson && (
          <ConfirmAssignmentOverlay
            orderCount={orderCount as number}
            unassignedPerson={unassignedPerson}
            assignedPerson={assignedPerson}
            onCancel={() => {
              setSelectedPerson(null)
              setModalState('assignment')
            }}
            onConfirm={assignToResident}
            loading={loading}
          />
        )}
      </div>
    </Modal>
  )
}

export default ReviewUnassignedOrderModal
