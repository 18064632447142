import { AppraisalSettings_AppraisalCategoryOptions } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { cloneDeep } from 'lodash'
import { useState } from 'react'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import CollapsibleCard from '@shared/components/CollapsibleCard'
import {
  AssessmentCategory,
  AssessmentConfig,
  AssessmentLevel,
  LevelModalMode,
} from '@shared/types/assessment_configuration'
import styles from '../styles.module.css'
import { NEEDS } from '../helpers'
import { AddEditLevelModal } from './AddEditLevelModal'
import * as helpers from './helpers'
import { allLevelsAreRepresented, replaceExistingLevel } from './helpers'
import { Level } from './Level'

type Props = {
  selectedCategory: AssessmentCategory
  updatedLevels: AssessmentLevel[]
  setUpdatedLevels: (levels: AssessmentLevel[]) => void
  updatedCategoryOptions: AppraisalSettings_AppraisalCategoryOptions
  setUpdatedCategoryOptions: (
    options: AppraisalSettings_AppraisalCategoryOptions
  ) => void
  setAssessmentConfig: (config: AssessmentConfig) => void
  currentAssessmentConfig: AssessmentConfig
  categoryIsMarkedForDelete: boolean
}
export const Levels = ({
  selectedCategory,
  updatedLevels,
  setUpdatedLevels,
  updatedCategoryOptions,
  setUpdatedCategoryOptions,
  setAssessmentConfig,
  currentAssessmentConfig,
  categoryIsMarkedForDelete,
}: Props) => {
  const [showAddEditModal, setShowAddEditModal] = useState<
    LevelModalMode | undefined
  >()
  const updateParent = (newLevels: AssessmentLevel[]) => {
    setUpdatedLevels(newLevels)
    setAssessmentConfig({
      ...currentAssessmentConfig,
      [selectedCategory!.categoryKey!]: {
        ...currentAssessmentConfig[selectedCategory!.categoryKey!],
        levels: cloneDeep(newLevels),
      },
    })
  }

  const addNewLevel = () => {
    setShowAddEditModal({
      tag: 'add',
      addLevel: ({ newLevel }: { newLevel: AssessmentLevel }) => {
        const newLevels = helpers.addLevel({
          currentLevels: updatedLevels!,
          newLevel,
        })

        updateParent(newLevels)
      },
    })
  }
  const updateLevel = (
    value: string | number,
    index: number,
    toUpdate: 'description' | 'score'
  ) => {
    const newLevels = helpers.generateNewLevels({
      value,
      index,
      toUpdate,
      currentLevels: updatedLevels!,
    })

    updateParent(newLevels)
  }

  const editLevel = ({ index }: { index: number }) => {
    setShowAddEditModal({
      tag: 'edit',
      editLevel: ({
        updatedLevel,
        index,
      }: {
        updatedLevel: AssessmentLevel
        index: number
      }) => {
        const newLevels = replaceExistingLevel({
          index,
          updatedLevel,
          currentLevels: updatedLevels!,
        })

        updateParent(newLevels)
      },
      currentLevel: updatedLevels[index],
      index,
    })
  }

  const removeLevel = ({ index }: { index: number }) => {
    const newLevels = helpers.removeLevel({
      index,
      currentLevels: updatedLevels!,
    })

    updateParent(newLevels)
  }

  const allLevelsPresent = allLevelsAreRepresented(updatedLevels!)

  return (
    <CollapsibleCard
      cardTitle={NEEDS}
      className={styles.card}
      defaultExpanded={!categoryIsMarkedForDelete}
      data-testid={'level-card'}
    >
      <AddEditLevelModal
        setShowModal={setShowAddEditModal}
        mode={showAddEditModal}
        currentLevels={updatedLevels!}
      />
      <BasicInput
        id={'needs-question'}
        name={'needs-question'}
        className={'mb-[12px]'}
        placeholder={'Enter a question to assess category needs'}
        value={updatedCategoryOptions.question ?? ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const newOptions = {
            ...updatedCategoryOptions,
            question: e.target.value,
          }
          setUpdatedCategoryOptions(newOptions)

          setAssessmentConfig({
            ...currentAssessmentConfig,
            [selectedCategory!.categoryKey!]: {
              ...currentAssessmentConfig[selectedCategory!.categoryKey!],
              categoryOptions: cloneDeep(newOptions),
            },
          })
        }}
        disabled={categoryIsMarkedForDelete}
      />
      <div className={styles.levels}>
        {updatedLevels.length > 0 && (
          <>
            <div />
            <div />
            <div className={styles.label}>Description</div>
            <div className={`${styles.label} text-center`}>Score</div>
          </>
        )}
        {updatedLevels?.map((level, index) => {
          if (level.tempId === 'REMOVED') {
            return null
          }

          return (
            <Level
              key={`${selectedCategory.categoryKey}-${level.level}`}
              level={level}
              editLevel={editLevel}
              removeLevel={removeLevel}
              updateLevel={updateLevel}
              index={index}
              disabled={categoryIsMarkedForDelete}
            />
          )
        })}
      </div>
      <AsyncIconButton
        buttonStyle={'tertiary-fill'}
        buttonSize={'small'}
        initialIcon={'fa-solid fa-plus'}
        className="mt-[16px] uppercase"
        onClick={addNewLevel}
        disabled={allLevelsPresent || categoryIsMarkedForDelete}
      >
        Add Level
      </AsyncIconButton>
    </CollapsibleCard>
  )
}
