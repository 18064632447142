import { DateMessage } from '@augusthealth/models/com/august/protos/date'
import { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { listFacilities } from '@shared/api/facility'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { ModalTitle } from '@shared/components/baseMui/Modal/Layout'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import GlobalContext from '@shared/contexts/GlobalContext'
import { ResidentStatus } from '@shared/types/person'
import { isEmptyObject } from '@shared/utils/common'
import { tw } from '@shared/utils/tailwind'
import FacilitiesContext from '@app/contexts/FacilitiesContext'
import styles from './styles.module.css'
import { isCompleteAndValidBirthday } from '../DateOfBirth/utils'
import AdmissionStep from './AdmissionStep'
import { addNewPerson, FormData } from './helpers'
import ResidentStep from './ResidentStep'
import RPStep from './RPStep'

type Props = {
  facilityId: string
  orgId: string
  redirectOnClose: (
    prospectFacilityId?: string,
    prospectId?: number,
    showRPModal?: boolean
  ) => void
}

export default function AddPersonPopup(props: Props) {
  const { facilityId, orgId, redirectOnClose } = props
  const { setFacilities } = useContext(FacilitiesContext)
  const { setError } = useContext(GlobalContext)
  const methods = useForm<FormData>({
    mode: 'onChange',
    shouldFocusError: true,
  })
  const {
    formState: { errors, isSubmitting },
    setValue,
    trigger,
    handleSubmit,
    getValues,
    watch,
  } = methods
  const [currentStep, setCurrentStep] = useState(0)
  const [dob, setDob] = useState<DateMessage | undefined>(getValues('dob'))
  const [allowEmptyDob, setAllowEmptyDob] = useState<boolean>(true)

  const title = 'Add Move-in'
  const rpFields = [
    'rpFirstName',
    'rpLastName',
    'rpPrimaryRelationship',
    'rpSecondaryRelationship',
    'rpEmail',
    'rpPhone',
  ] as const
  type RpField = (typeof rpFields)[0]

  const isRPDataRequired = watch(rpFields).some(
    (value: RpField) => !isEmptyObject(value)
  )

  const onDobUpdate = (d?: DateMessage) => {
    setDob(d)
    if (d && isCompleteAndValidBirthday(d)) {
      // Set dob value only when it is complete
      // To avoid Required warning when user still filling
      // Will validate when click on Next button
      setValue('dob', d)
      void trigger(['dob'])
    }
  }

  useEffect(() => {
    void trigger(['rpFirstName', 'rpLastName', 'rpPrimaryRelationship'])
  }, [isRPDataRequired])

  const steps = [
    {
      title: 'Resident Info',
      content: (
        <ResidentStep allowEmptyDob={allowEmptyDob} onDobUpdate={onDobUpdate} />
      ),
      fields: ['firstName', 'lastName', 'gender', 'dob'],
    },
    {
      title: 'Responsible Person',
      content: <RPStep isRPDataRequired={isRPDataRequired} />,
      fields: rpFields,
    },
    {
      title: 'Admission Details',
      content: <AdmissionStep orgId={orgId} facilityId={facilityId} />,
      fields: [
        'admissionASAP',
        'admissionType',
        'respite',
        'hospice',
        'bedId',
        'admissionNotes',
        'admissionFacility',
        'admissionDate',
      ],
    },
  ]
  type StepField = (typeof steps)[0]['fields'][0]

  const getStepHeaderClasses = (index: number): string =>
    `${styles.stepName} ${index === currentStep ? styles.active : ''}`
  const getStepContentClasses = (index: number): string =>
    `${currentStep === index ? '' : 'visually-hidden'}`

  const cancelOrBackButton = () => {
    if (currentStep === 0) {
      return (
        <AsyncIconButton
          buttonStyle={'secondary-outline'}
          className={styles.button}
          type="button"
          onClick={() => redirectOnClose()}
        >
          Cancel
        </AsyncIconButton>
      )
    } else {
      return (
        <AsyncIconButton
          buttonStyle={'secondary-outline'}
          className={styles.button}
          type="button"
          onClick={() => {
            setCurrentStep(currentStep - 1)
            setAllowEmptyDob(true)
          }}
        >
          Back
        </AsyncIconButton>
      )
    }
  }

  const nextOrSubmitButton = () => {
    if (currentStep < steps.length - 1) {
      return (
        <AsyncIconButton
          buttonStyle={'primary-fill'}
          data-testid="add-prospect-modal-button"
          type="button"
          className={styles.button}
          disabled={steps[currentStep].fields.some(
            (field: StepField) => errors[field]
          )}
          onClick={() => {
            // @ts-expect-error
            void trigger(steps[currentStep].fields).then((valid) => {
              if (dob && !isCompleteAndValidBirthday(dob)) {
                // If any dob is entered, check if it is complete
                setAllowEmptyDob(false)
              } else if (valid) {
                setCurrentStep(currentStep + 1)
              }
            })
          }}
        >
          Next
        </AsyncIconButton>
      )
    } else {
      return (
        <AsyncIconButton
          buttonStyle="primary-fill"
          data-testid="add-prospect-modal-button"
          isLoading={isSubmitting}
          disabled={
            steps[currentStep].fields.some(
              (field: StepField) => errors[field]
            ) || isSubmitting
          }
          type="button"
          className={styles.button}
          onClick={handleSubmit(onSubmit)}
        >
          {title}
        </AsyncIconButton>
      )
    }
  }

  const onSubmit = async (data: FormData) => {
    try {
      const { newFacilityId, personId } = await addNewPerson({
        data,
        facilityId,
        orgId,
        residentStatus: ResidentStatus.RESIDENT_STATUS_PROSPECT,
      })
      // Update Prospect / Resident counters in Sidebar
      await listFacilities(orgId).then((res) => setFacilities(res))
      redirectOnClose(newFacilityId, personId, !!data.rpEmail)
    } catch (error) {
      setError(error)
    }
  }

  return (
    <Modal
      id="add-prospect"
      open={true}
      onClose={() => redirectOnClose()}
      contentClassName={tw`w-[664px]`}
    >
      <ModalTitle>{title}</ModalTitle>
      <FormProvider {...methods}>
        <form data-cy="add-prospect-modal" className={styles.formBody}>
          <section>
            <nav className={styles.header}>
              {steps.map((step, index) => {
                const isPreviousStep = index < currentStep
                return (
                  <div
                    key={step.title}
                    data-cy={`add-prospect-step-${index}`}
                    data-testid={`add-prospect-step-${index}`}
                    className={getStepHeaderClasses(index)}
                    onClick={() =>
                      // @ts-expect-error
                      trigger(steps[currentStep].fields).then(
                        (valid) =>
                          (valid || isPreviousStep) && setCurrentStep(index)
                      )
                    }
                  >
                    {step.title}
                    <div className={styles.stepBorder} />
                  </div>
                )
              })}
            </nav>
            {steps.map((step, index) => (
              <section
                className={getStepContentClasses(index)}
                key={`app-step-${index}`}
              >
                {step.content}
              </section>
            ))}
          </section>
          <footer className={styles.footer}>
            {cancelOrBackButton()}
            {nextOrSubmitButton()}
          </footer>
        </form>
      </FormProvider>
    </Modal>
  )
}
