import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { SetRequired } from 'type-fest'
import SearchBox from '@shared/components/SearchBox'
import StyledSelect, {
  OptionTypeBase,
  SelectComponent,
} from '@shared/components/Selects/StyledSelect'
import useCurrentPage, { pageToPath } from '@shared/hooks/useCurrentPage'
import { detailsPathForPerson } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { PersonStats } from '@shared/types/person_stats'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { getOrElse } from '@shared/utils/loading'
import {
  personNameMatchesSearch,
  personRoomMatchesSearch,
} from '@shared/utils/person'
import { tw } from '@shared/utils/tailwind'
import QuickVitals from '@app/components/QuickVitals/QuickVitals'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import { QuickVitalsMenu, ResidentOption } from './ResidentOption'

export default function ResidentSearch({
  closeAllMenus,
  showDropdown,
  setShowDropdown,
  canCreateVitals,
}: {
  closeAllMenus: () => void
  showDropdown: boolean
  setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>
  canCreateVitals: boolean
}) {
  const history = useHistory()
  const currentPage = useCurrentPage()
  const { currentFacility, prospects, residents } = useCurrentFacility()
  const allPeople = [...getOrElse(prospects, []), ...getOrElse(residents, [])]
  const [searchText, setSearchText] = useState('')
  const [residentOptions, setResidentOptions] = useState<
    OptionTypeBase<PersonStats>[]
  >([])
  const [showQuickVitals, setShowQuickVitals] = useState(false)

  useEffect(() => {
    if (
      prospects.tag === 'Complete' &&
      residents.tag === 'Complete' &&
      searchText
    ) {
      const filteredPeople = [...prospects.value, ...residents.value].filter(
        (p) =>
          personNameMatchesSearch(p.person!, searchText) ||
          personRoomMatchesSearch(p.person!, searchText)
      )
      setResidentOptions(
        filteredPeople.map((p) => ({
          label: getFirstAndLastName(p.person?.name),
          value: p,
        }))
      )
      closeAllMenus()
      setShowDropdown(true)
    }

    if (searchText.length === 0) {
      setShowDropdown(false)
      setResidentOptions([])
    }
  }, [searchText])

  if (currentFacility === undefined) {
    return null
  }

  let placeholder: string
  if (prospects.tag === 'Loading' || residents.tag === 'Loading') {
    placeholder = 'Loading...'
  } else {
    placeholder = 'Jump to...'
  }

  const components = canCreateVitals
    ? {
        Option: ResidentOption as SelectComponent,
        MenuList: QuickVitalsMenu(() => {
          setShowQuickVitals(true)
          setShowDropdown(false)
        }),
      }
    : {
        Option: ResidentOption as SelectComponent,
      }

  return (
    <section>
      <SearchBox
        holderClassName={tw`weight-normal [&>input]:weight-normal m-[16px] w-auto rounded-[6px] border-[2px] border-solid border-scrim-white-alt bg-scrim-alt text-[14px] leading-[16px] text-white shadow-card focus-within:outline-none hover:outline-none focus-visible:outline-none [&:focus-within>input]:outline-none [&>input::placeholder]:text-scrim-white [&>input:disabled]:bg-black [&>input]:text-[14px] [&>input]:leading-[16px] [&>input]:text-white`}
        iconClassName={tw`text-scrim-white`}
        clearIconClass={tw`fa-times text-scrim-white`}
        inputClassName={tw`border-transparent`}
        onChange={setSearchText}
        onFocus={() => {
          setShowDropdown(true)
        }}
        placeholder={placeholder}
        value={searchText}
        disabled={prospects.tag === 'Loading' || residents.tag === 'Loading'}
      />
      <StyledSelect
        components={components}
        onChange={(e: { value: { person: Person } }) => {
          if (currentPage.tag === 'Person') {
            history.push(
              pageToPath({
                page: {
                  ...currentPage,
                  personId: e.value.person.id ?? '',
                },
                forPerson: e.value.person as SetRequired<
                  Person,
                  'id' | 'orgId' | 'facilityId'
                >,
              })
            )
          } else {
            history.push(
              detailsPathForPerson(e.value.person as Required<Person>)
            )
          }
          setSearchText('')
          setShowDropdown(false)
        }}
        options={residentOptions}
        noOptionsMessage={() => <div className="p-0" />}
        menuIsOpen={showDropdown}
        styles={{
          control: () => ({
            visibility: 'hidden',
            height: '0',
            pointerEvents: 'none',
          }),
          menu: (provided) => ({
            ...provided,
            boxShadow:
              '2px 4px 4px rgba(0, 0, 0, 0.05), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
            width: '216px',
            marginTop: '-8px',
            marginLeft: '16px',
          }),
          noOptionsMessage: () => ({}),
        }}
      />
      {showQuickVitals && (
        <QuickVitals
          setShowModal={setShowQuickVitals}
          peopleStats={allPeople}
        />
      )}
    </section>
  )
}
