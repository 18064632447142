import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import {
  defaultPathForPerson,
  documentPathForPerson,
} from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { Task } from '@shared/types/task'
import { getIsComplete, isAwaitingReview } from '@shared/utils/task'
import ConfirmModal from '@app/components/ConfirmModal'
import SignatureFlow from '@app/components/SignatureFlow'
import InProgressSignatureFlow from '@app/components/SignatureFlow/InProgress'
import PersonContext from '@app/contexts/PersonContext'
import TaskTemplateContext from '@app/contexts/TaskTemplateContext'
import useTemplate from '@app/hooks/useTemplate'
import PdfPopup from '../Forms/sharable/PdfPopup'

interface TaskUrlProps {
  orgId: string
  facilityId: string
  id: string
  taskId?: string
}

interface ReviewProps {
  onClose?: () => void
  match: {
    params: TaskUrlProps
  }
}

export default function Review(props: ReviewProps) {
  const { match, onClose } = props
  const { params } = match
  const { facilityId, id: pId, orgId, taskId } = params
  const history = useHistory()
  const { tasks = [], person } = useContext(PersonContext)
  const close =
    typeof onClose === 'function'
      ? onClose
      : () => history.push(defaultPathForPerson({ facilityId, id: pId, orgId }))

  const task = tasks.find((t) => t.id === taskId)

  if (task && person) {
    return (
      <SignatureFlowSelector
        params={params}
        task={task}
        person={person}
        onClose={close}
      />
    )
  }

  return 'Loading...'
}

function SignatureFlowSelector({
  task,
  person,
  onClose,
  params,
}: {
  task: Task
  person: Person
  onClose: () => void
  params: TaskUrlProps
}) {
  const history = useHistory()
  const template = useTemplate({
    dataType: task.taskTemplateInfo!.dataType!,
    person,
    customType: task.taskTemplateInfo!.customType,
  })

  if (task.signatures && task.signatures.length > 0) {
    return (
      <TaskTemplateContext.Provider value={{ signableForm: template }}>
        <InProgressSignatureFlow
          task={task}
          person={person}
          onClose={onClose}
        />
      </TaskTemplateContext.Provider>
    )
  }
  if (getIsComplete(task) && task.snapshotId) {
    return (
      <ConfirmModal
        title="Task is complete"
        confirmButtonConfig={{
          children: 'View Document',
          onClick: async () => {
            history.push(documentPathForPerson(person, { id: task.snapshotId }))
            return
          },
        }}
        denyButtonConfig={{
          children: 'Back to tasks',
          onClick: onClose,
        }}
      />
    )
  }

  if (isAwaitingReview(task) && template.tag === 'Complete') {
    return (
      <TaskTemplateContext.Provider value={{ signableForm: template }}>
        <SignatureFlow task={task} person={person} onClose={onClose} />
      </TaskTemplateContext.Provider>
    )
  } else if (isAwaitingReview(task)) {
    return null
  } else {
    return <PdfPopup params={params} onClose={close} />
  }
}
