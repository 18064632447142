import { useContext } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { Person } from '@shared/types/person'
import { canViewCTA } from '@app/pages/Compliance/ResidentCompletion/helpers'
import styles from './styles.module.css'
import { ResidentTask } from './helpers'

export function TaskRow({
  task,
  person,
}: {
  task: ResidentTask
  person: Person
}) {
  const { setError } = useContext(GlobalContext)
  const { user } = useUserContext()

  return (
    <div data-cy="completion-task-row" className={styles.taskRow}>
      <div className={styles.taskName}>
        <i
          className={
            task.status === 'Incomplete'
              ? 'fa-regular fa-circle-minus mr-[8px] text-tags-alert'
              : 'far fa-check mr-[8px] text-tags-happy'
          }
        />
        {task.name}
      </div>
      {task.status === 'Incomplete' &&
        canViewCTA({
          user,
          task,
          person,
        }) && (
          <ButtonLink
            data-cy={`${task.name}-cta`}
            data-testid={`${task.name}-cta`}
            className={styles.taskAction}
            onClick={async () => {
              try {
                await task.cta.action()
              } catch (error) {
                setError(error)
              }
            }}
          >
            {task.cta.text}
          </ButtonLink>
        )}
    </div>
  )
}
