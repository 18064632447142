import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { EditableDetailsCard } from 'app/components/Prospects/Details/DetailsCard'
import { useState } from 'react'
import { Person } from '@shared/types/person'
import { convertEnumValueToLabel } from '@shared/utils/common'
import { tw } from '@shared/utils/tailwind'
import styles from '@app/components/AugustFields/Diet/styles.module.css'
import EditMaritalStatusModal from '@app/components/AugustFields/MaritalStatus/EditMaritalStatusModal'

export default function MaritalStatus({
  person,
  updatePerson,
}: {
  person: Person
  updatePerson: () => Promise<void>
}) {
  const [showModal, setShowModal] = useState(false)
  const { maritalStatus } = person

  return (
    <EditableDetailsCard
      id="marital-status-card"
      icon="fas fa-fw fa-family"
      title="Marital Status"
      editPermissions={{
        person,
        permissions: [GroupPermission.GROUP_PERMISSION_PERSON_UPDATE],
      }}
      onEditClick={() => setShowModal(true)}
      editButtonId="edit-marital-status"
      className={tw`my-[12px]`}
    >
      <div className={styles.row}>
        <div className={styles.title}>Marital Status</div>
        <div>
          {maritalStatus &&
            convertEnumValueToLabel(
              maritalStatus.replace('MARITAL_STATUS_', '')
            )}
          {!maritalStatus && <span className="text-gray-08">None to show</span>}
        </div>
      </div>
      {showModal && (
        <EditMaritalStatusModal
          person={person}
          onClose={async (updated) => {
            if (updated) {
              await updatePerson()
            }
            setShowModal(false)
          }}
        />
      )}
    </EditableDetailsCard>
  )
}
