import { useContext } from 'react'
import { patchUser } from '@shared/api/user'
import GlobalContext from '@shared/contexts/GlobalContext'
import { UserAccount } from '@shared/types/user'
import { getFullName } from '@shared/utils/humanName'
import ConfirmModal from '@app/components/ConfirmModal'
import { getUserLabelAndType } from '@app/pages/Organizations/Settings/UsersPage/UserTable/helpers'

type Props = {
  user: UserAccount
  onClose: () => void
  reload: () => Promise<void>
  setNotice: (text: string) => void
}

export default function DeleteInternalUserModal({
  user,
  onClose,
  reload,
  setNotice,
}: Props) {
  const { setError } = useContext(GlobalContext)
  const { id, name: n, email } = user
  const name = n ? getFullName(n) : ''
  const onConfirm = async () => {
    if (id) {
      try {
        await patchUser({ user: { id, groups: [] } })
        setNotice('User deleted!')
        await reload()
        onClose()
      } catch (e) {
        onClose()
        setError(e)
      }
    }
  }

  const { label = '' } = getUserLabelAndType(user)
  const content = (
    <div>
      <span className="font-semibold">
        {name} ({label})
      </span>
      <br />
      {email}
    </div>
  )

  return (
    <ConfirmModal
      title="Do you want to delete this user?"
      content={content}
      confirmButtonConfig={{
        children: 'Delete User',
        onClick: onConfirm,
      }}
      denyButtonConfig={{
        onClick: onClose,
      }}
    />
  )
}
