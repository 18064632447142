import { Coverage_CoverageType } from '@augusthealth/models/com/august/protos/coverage'
import { isEmpty } from 'lodash'
import { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { tw } from '@shared/utils/tailwind'
import {
  COVERAGE_TYPE_OPTIONS,
  FormData,
  IndexedCoverage,
  upsertCoverage,
} from './helpers'

interface Props {
  coverage?: IndexedCoverage
  person: Person
  onClose: (updated: boolean) => void
}

export default function EditInsurance({ coverage, onClose, person }: Props) {
  const { setError } = useContext(GlobalContext)
  const { setValue, control, watch, handleSubmit, formState, register } =
    useForm<FormData>({
      defaultValues: {
        type: coverage?.type,
        provider: coverage?.name || '',
        memberId: coverage?.subscriberId || '',
        groupNumber: coverage?.plan?.value || '',
        index: coverage?.index,
        rxpcn: coverage?.rxpcn?.value,
        rxbin: coverage?.rxbin?.value,
      },
      mode: 'all',
    })

  const type = watch('type')

  const onSubmit = async (data: FormData) => {
    try {
      await upsertCoverage({ data, person })
      onClose(true)
    } catch (e) {
      setError(e)
    }
  }

  const title =
    COVERAGE_TYPE_OPTIONS.find((o) => o.value === type)?.label || 'New'

  return (
    <AnimatedPopup title={`${title} Insurance`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <LabelAboveInput
          htmlFor="type"
          className={tw`mt-[32px]`}
          subLabel={requiredLabel(!!formState.errors.type)}
        >
          Type
        </LabelAboveInput>
        <Controller
          control={control}
          rules={{ required: true }}
          name="type"
          defaultValue={coverage?.type}
          render={({ field: { onChange, value } }) => {
            const { index, ...restOfCoverageData } = coverage || {}
            const isDisabled = !isEmpty(restOfCoverageData)
            return (
              <StyledSelect
                isDisabled={isDisabled}
                inputId="type"
                id="select-type"
                name="type"
                instanceId="type"
                value={COVERAGE_TYPE_OPTIONS.find((o) => o.value === value)}
                onChange={(
                  opt: OptionTypeBase<Coverage_CoverageType> | null
                ) => {
                  if (opt) {
                    onChange && onChange(opt.value)
                    setValue('type', opt?.value)
                  }
                }}
                options={COVERAGE_TYPE_OPTIONS}
              />
            )
          }}
        />
        <input type="hidden" {...register('index')} />
        <div className="mt-[32px]">
          <LabelAboveInput
            htmlFor="provider"
            subLabel={requiredLabel(!!formState.errors.provider)}
          >
            Insurance Provider
          </LabelAboveInput>
          <BasicInput
            {...register('provider', { required: true })}
            placeholder="e.g. United Healthcare"
          />
        </div>
        <div className="mt-[32px]">
          <LabelAboveInput
            htmlFor="memberId"
            subLabel={requiredLabel(!!formState.errors.memberId)}
          >
            Member ID
          </LabelAboveInput>
          <BasicInput {...register('memberId', { required: true })} />
        </div>
        <div className="mt-[32px]">
          <LabelAboveInput htmlFor="groupNumber">Group Number</LabelAboveInput>
          <BasicInput {...register('groupNumber')} />
        </div>
        {type === Coverage_CoverageType.COVERAGE_TYPE_PHARMACY && (
          <>
            <div className="mt-[32px]">
              <LabelAboveInput htmlFor="rxbin">BIN</LabelAboveInput>
              <BasicInput {...register('rxbin')} />
            </div>

            <div className="mt-[32px]">
              <LabelAboveInput htmlFor="rxpcn">PCN</LabelAboveInput>
              <BasicInput {...register('rxpcn')} />
            </div>
          </>
        )}
        <div className="mt-[32px]">
          <button
            type="submit"
            disabled
            style={{ display: 'none' }}
            aria-hidden="true"
          ></button>
          <AnimatedPopupFormFooter
            noBtn={{ action: () => onClose(false) }}
            yesBtn={{
              props: { id: 'saveInsuranceInfo', disabled: !formState.isValid },
            }}
            formState={formState}
          />
        </div>
      </form>
    </AnimatedPopup>
  )
}
