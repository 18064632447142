import localForage from 'localforage'
import { useContext, useEffect, useState } from 'react'
import { fetchFacilities } from '@shared/api/facility'
import { useUserContext } from '@shared/contexts/UserContext'
import useCurrentPage, { extractIds, Page } from '@shared/hooks/useCurrentPage'
import { Facility } from '@shared/types/facility'
import { UserAccount } from '@shared/types/user'
import { getRelevantFacilities } from '@shared/utils/user'
import FacilitiesContext from '@app/contexts/FacilitiesContext'

interface SidebarData {
  currentPage: Page
  currentFacility: Facility | undefined
  facilities: Facility[]
  user: UserAccount
  personId: string | undefined
  orgId: string | undefined
}

export default function useSidebarData(): SidebarData | undefined {
  const { user } = useUserContext()

  const { facilities: availableFacilities, setFacilities } =
    useContext(FacilitiesContext)
  const [currentFacility, setCurrentFacility] = useState<Facility | undefined>(
    undefined
  )

  let facilities = getRelevantFacilities({
    facilities: availableFacilities,
    user,
  })

  const currentPage = useCurrentPage()
  const { orgId, facilityId, personId } = extractIds(currentPage)

  useEffect(() => {
    if (!availableFacilities && orgId) {
      void fetchFacilities({ orgId }).then((res) => {
        if (res && res.data) {
          setFacilities(res.data)
          facilities = getRelevantFacilities({
            facilities: res.data,
            user,
          })
        }
      })
    }
  }, [orgId])

  useEffect(() => {
    if (facilityId) {
      if (facilities) {
        // If facilityId and facilities found, set currentFacility
        setCurrentFacility(facilities.find((f) => f.id === facilityId))
      }

      // If facilityId found set facilityId in localForage
      void localForage
        .getItem<string>('facilityId')
        .then((storageFacilityId) => {
          if (storageFacilityId !== facilityId) {
            void localForage.setItem('facilityId', facilityId)
          }
        })
    } else {
      // If facilityId not found, check localForage
      void localForage
        .getItem<string>('facilityId')
        .then((storageFacilityId) => {
          if (storageFacilityId) {
            // Set currentFacility based on facilityId in storage
            setCurrentFacility(
              facilities.find((f) => f.id === storageFacilityId)
            )
          } else if (facilities.length) {
            // Otherwise, use the first facility in the list as current
            setCurrentFacility(facilities[0])
          }
        })
    }
  }, [facilityId, facilities?.map((f) => f.id)])

  if (currentPage.tag === 'Unknown' || orgId === undefined) {
    return undefined
  }

  return {
    currentPage: currentPage,
    currentFacility,
    facilities,
    user,
    personId: personId,
    orgId: orgId,
  }
}
