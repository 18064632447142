import { GetTaskDueDateResponse } from '@augusthealth/models/com/august/protos/api/task_due_date'
import { DateMessage } from '@augusthealth/models/com/august/protos/date'
import { SignatureInfo } from '@augusthealth/models/com/august/protos/task'
import { requestJson } from '@shared/api/request'
import { mergePatchTask } from '@shared/api/task'
import {
  apiCompleteTaskManuallyUrl,
  apiRecreateTaskUrl,
  apiTaskDueDateUrl,
  apiTasksReminderUrl,
  CompleteTaskManuallyUrlProps,
  PersonIdsWithDataType,
  ReminderUrlProps,
  shareTasksUrl,
} from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { DataType, Signer, SignerRole } from '@shared/types/snapshot'
import { SignatureStatus, Task, TaskStatus } from '@shared/types/task'

export async function shareTasks({
  person,
  taskIds,
  message,
}: {
  person: Person
  taskIds: string[]
  message: string
}): Promise<'OK'> {
  await requestJson({
    url: shareTasksUrl({
      orgId: person.orgId!,
      personId: person.id!,
    }),
    method: 'POST',
    body: JSON.stringify({
      taskIds,
      message,
    }),
  })

  return 'OK'
}

export async function sendReminder(props: ReminderUrlProps) {
  const url = apiTasksReminderUrl(props)
  return await requestJson({ url, method: 'POST' })
}

export async function markTaskAsComplete(person: Person, task: Task) {
  return await updateTaskStatus({
    task,
    person,
    newStatus: TaskStatus.TASK_STATUS_COMPLETE,
    share: true,
  })
}

export async function markInProgressByAdmin(person: Person, task: Task) {
  return updateTaskStatus({
    task,
    person,
    newStatus: TaskStatus.TASK_STATUS_ASSIGNED_TO_ADMIN,
  })
}

export async function unshareAndAssignTaskToAdmin(task: Task, person: Person) {
  return updateTaskStatus({
    task,
    person,
    newStatus: TaskStatus.TASK_STATUS_ASSIGNED_TO_ADMIN,
    share: false,
  })
}

export async function markTaskAsAwaitingReview({
  person,
  task,
}: {
  person: Person
  task: Task
}) {
  return updateTaskStatus({
    task,
    newStatus: TaskStatus.TASK_STATUS_AWAITING_REVIEW,
    person,
  })
}

async function updateTaskStatus({
  task,
  newStatus,
  person,
  share,
}: {
  task: Task
  newStatus: TaskStatus
  person: Person
  share?: boolean
}) {
  let patch: Partial<Task>
  if (share === undefined) {
    patch = { status: newStatus }
  } else {
    patch = { status: newStatus, shared: share }
  }

  return await mergePatchTask({
    pId: person.id,
    orgId: person.orgId,
    taskId: task.id,
    task: patch,
  })
}

export const updateTaskAdminSigner = async ({
  person,
  task,
  newAdminSigner,
}: {
  person: Person
  task: Task
  newAdminSigner: Signer
}) => {
  let signatures: SignatureInfo[]

  if (task.signatures) {
    const signers = task.signatures ?? []
    signatures = signers.map((s) => {
      if (s.signer?.role === SignerRole.SIGNER_ROLE_ADMIN) {
        return {
          signer: newAdminSigner,
          status: SignatureStatus.SIGNATURE_STATUS_NOT_SIGNED,
        }
      } else {
        return s
      }
    })
  } else {
    signatures = [
      {
        signer: newAdminSigner,
        status: SignatureStatus.SIGNATURE_STATUS_NOT_SIGNED,
      },
    ]
  }

  const patch = {
    status: TaskStatus.TASK_STATUS_PENDING_SIGNATURES,
    signatures,
  }

  return await mergePatchTask({
    pId: person.id!,
    orgId: person.orgId!,
    taskId: task.id!,
    task: patch,
    withEtagProtection: true,
  })
}

type CompleteTaskManuallyProps = CompleteTaskManuallyUrlProps & {
  note: string
}

export async function completeTaskManually(props: CompleteTaskManuallyProps) {
  const url = apiCompleteTaskManuallyUrl(props)
  const { note } = props
  return await requestJson({
    url,
    method: 'POST',
    body: JSON.stringify({ note }),
  })
}

export async function fetchTaskDueDate(
  props: PersonIdsWithDataType
): Promise<GetTaskDueDateResponse> {
  const response = await requestJson({
    url: apiTaskDueDateUrl(props),
  })

  return response.data
}

type UpdateTaskDueDate = PersonIdsWithDataType & {
  dueDate: DateMessage
}

export async function updateTaskDueDate(
  props: UpdateTaskDueDate
): Promise<GetTaskDueDateResponse> {
  const { dueDate } = props
  const response = await requestJson({
    url: apiTaskDueDateUrl(props),
    method: 'POST',
    body: JSON.stringify({ dueDate }),
  })

  return response.data
}

export async function recreateTask({
  person,
  dataType,
  customType,
}: {
  person: Person
  dataType: DataType
  customType?: string
}) {
  return await requestJson({
    url: apiRecreateTaskUrl({
      orgId: person.orgId!,
      facilityId: person.facilityId!,
      personId: person.id!,
      dataType,
      customType,
    }),
    method: 'POST',
  })
}
