import { LevelOfCareSettingsList } from '@augusthealth/models/com/august/protos/level_of_care'
import { useContext, useEffect, useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { getFacilityLoCSettings } from '@shared/api/facility_settings'
import { AsyncIconButton as AsyncButton } from '@shared/components/AsyncButton'
import Card from '@shared/components/Card'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { fromDateToDateMessage, monthDayYear } from '@shared/utils/date'
import { Loading } from '@shared/utils/loading'
import {
  SettingsConfig,
  SettingsLevel,
} from '@shared/utils/orgFacilitySettings'
import HUD from '@app/components/HUD'
import { ConfigurationLevelSelects } from '../ConfigurationLevelSelects/ConfigurationLevelSelects'
import { OrgFacilitySelects } from '../SharedSelects/OrgFacilitySelects'
import { AdmissionTypeGroup } from './AdmissionTypeGroup'
import {
  createLoCSettings,
  defaultValuesFromResponse,
} from './locSettingsHelpers'

export default function LevelOfCareSettings() {
  const { setError } = useContext(GlobalContext)
  const [selectedOrganization, setSelectedOrganization] =
    useState<OptionTypeBase<Organization> | null>(null)
  const [selectedFacility, setSelectedFacility] =
    useState<OptionTypeBase<Facility> | null>(null)
  const [locSettings, setLocSettings] = useState<
    Loading<LevelOfCareSettingsList>
  >({ tag: 'Loading' })
  const [settingsConfig, setSettingsConfig] = useState<SettingsConfig>({})
  const [showHUD, setShowHUD] = useState(false)

  const methods = useForm<LevelOfCareSettingsList>({
    defaultValues: {
      effectiveDate: undefined,
      careLevelsByAdmissionType: [],
    },
  })
  const { formState, handleSubmit, reset, control } = methods

  const { fields } = useFieldArray<LevelOfCareSettingsList>({
    control,
    name: 'careLevelsByAdmissionType',
  })

  useEffect(() => {
    if (selectedFacility && selectedOrganization) {
      void getFacilityLoCSettings({
        orgId: selectedOrganization.value.id,
        facilityId: selectedFacility.value.id,
      }).then((res) => {
        setSettingsConfig({
          orgId: selectedOrganization.value.id,
          facilityId: selectedFacility.value.id,
          settingsLevel: SettingsLevel.FACILITY_LEVEL,
        })
        setLocSettings({
          tag: 'Complete',
          value: res.settings!.levelOfCareSettingsList!,
        })
        reset(defaultValuesFromResponse(res))
      })
    }
  }, [locSettings.tag, selectedOrganization, selectedFacility])

  const onSubmit = async (data: LevelOfCareSettingsList) => {
    try {
      const updated = await createLoCSettings(data, settingsConfig)
      setShowHUD(true)
      reset(updated as LevelOfCareSettingsList)
    } catch (e) {
      setError(e)
    }
  }

  return (
    <div className="content-holder">
      <h1 className="page-title mt-[24px]">Level of Care Settings</h1>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'grid',
          gap: '32px',
          gridTemplateColumns: 'repeat(auto-fill, minmax(496px, 1fr))',
        }}
      >
        <section>
          <Card className="mb-[16px] flex flex-col">
            <OrgFacilitySelects
              onFacilitySelect={setSelectedFacility}
              onOrgSelect={setSelectedOrganization}
              orgLabel={'I want to view Level of Care settings for...'}
              selectedOrganization={selectedOrganization}
              selectedFacility={selectedFacility}
            />
          </Card>
          {locSettings.tag === 'Complete' &&
            selectedFacility &&
            selectedOrganization && (
              <Card className="flex flex-col">
                <ConfigurationLevelSelects
                  onSelectionChange={setSettingsConfig}
                  defaultOrg={selectedOrganization}
                  defaultFacility={selectedFacility}
                  labelText={'Save Config at...'}
                />
                <AsyncButton
                  data-testid="submit-loc-settings"
                  isLoading={formState.isSubmitting}
                  disabled={!selectedFacility}
                  buttonStyle="primary-fill"
                  className="mt-[8px]"
                  type="submit"
                >
                  Save Level of Care Settings
                </AsyncButton>
              </Card>
            )}
        </section>
        {locSettings.tag === 'Complete' && selectedFacility && (
          <section>
            <h2 className="mb-[16px] text-[16px] leading-[24px]">
              Level of Care
              <span className="ml-[8px] text-[14px] text-gray-07">
                Effective on{' '}
                {monthDayYear(
                  locSettings.value.effectiveDate ??
                    fromDateToDateMessage(new Date())
                )}
              </span>
            </h2>
            <FormProvider {...methods}>
              {fields.map((a, index) => (
                <AdmissionTypeGroup key={a.id} parentIndex={index} />
              ))}
            </FormProvider>
          </section>
        )}
      </form>
      {showHUD && (
        <HUD
          onExpire={() => {
            setShowHUD(false)
          }}
        >
          <div className="text-[16px] font-semibold leading-[24px]">
            Level of Care Settings Updated
          </div>
        </HUD>
      )}
    </div>
  )
}
