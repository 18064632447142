import { Occupancy_Occupant as Occupant } from '@augusthealth/models/com/august/protos/api/occupancy'
import { Bed } from '@augusthealth/models/com/august/protos/bed'
import { Link } from 'react-router-dom'
import { detailsPathForPerson } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { getFullName } from '@shared/utils/humanName'
import { displayGender } from '@shared/utils/person'
import ProfileImage from '@app/components/Table/ProfileImage'
import styles from './styles.module.css'

export default function OccupantRow({
  bed,
  occupant,
  index,
}: {
  bed: Bed
  occupant: Occupant
  index: number
}) {
  const { orgId, facilityId } = bed
  const { gender, personId, profilePhotoUrl } = occupant
  const occupantName = occupant.name && getFullName(occupant.name)
  const label = (
    <span>
      <Link
        to={detailsPathForPerson({
          orgId: orgId || '',
          facilityId: facilityId || '',
          id: personId || '',
        })}
      >
        {occupantName}
      </Link>
      &nbsp;
      <span className="text-gray-07">
        {gender && `(${displayGender(occupant as Person)})`}
      </span>
    </span>
  )
  const classNameList = [styles.square, styles.occupiedBed]
  if (index > 0) {
    classNameList.push('hidden')
  }

  return (
    <div className={styles.row}>
      <div className={classNameList.join(' ')}>
        <i className="fas fa-bed" />
      </div>
      <ProfileImage
        profilePhotoUrl={profilePhotoUrl}
        person={occupant as Person}
      />
      {label}
    </div>
  )
}
