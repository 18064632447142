import { List } from '@augusthealth/august-frontend-form-elements'
import DietSelect from './index'

export default function DietList(props) {
  return (
    <List
      className="no-style"
      item={DietSelect}
      defaultItem=""
      hideRemoveBtn
      useEmptyFieldToAddNewOption
      {...props}
    />
  )
}
