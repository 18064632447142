import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import styles from './styles.module.css'

export default function AdminMobilePrompt({
  onClose,
}: {
  onClose: () => void
}) {
  return (
    <AnimatedPopup containerClassName={styles.mobilePrompt} title={null}>
      <img
        alt="animation of mobile phone rotating from portrait mode to landscape mode"
        src="/svg/PhonePrompt.svg"
      />
      <div className="mb-[8px] text-[18px] font-semibold leading-[20px]">
        Use landscape orientation
      </div>
      <div className="mb-[24px] text-[16px] leading-[24px] text-secondary-06">
        for a better experience
      </div>
      <AsyncIconButton
        onClick={onClose}
        buttonStyle="primary-fill"
        width="100%"
      >
        Got it
      </AsyncIconButton>
    </AnimatedPopup>
  )
}
