import { Communication } from '@shared/types/communication'
import { Person_AllergiesAndIntolerances } from '@shared/types/person'
import { SnapshotStatus } from '@shared/types/snapshot'
import { SocialHistory } from '@shared/types/social_history'

export enum GtkySectionLabels {
  ABOUT_YOU = 'About You',
  FAMILY_AND_FRIENDS = 'Family & Friends',
  RELIGIOUS_PREFERENCES = 'Religious Preferences',
  INTERESTS = 'Interests',
  MEALS = 'Meals',
  ROUTINES = 'Routines',
  REVIEW_AND_FINALIZE = 'Review & Finalize',
}
enum GtkyPersonFields {
  PRIMARY_LANGUAGE = 'primary-language',
  OTHER_LANGUAGES = 'other-languages',
  PREVIOUS_OCCUPATIONS = 'previous-occupations',
  EDUCATION_HISTORY = 'education-history',
  FOOD_ALLERGIES = 'food-allergies',
}
export enum GtkySnapshotFields {
  BORN_AND_LIVED = 'born-and-lived',
  MILITARY_SERVICE = 'military-service',
  MILITARY_BRANCH = 'military-branch',
  DESCRIBE_YOURSELF = 'describe-yourself',
  PETS = 'pets',
  ACCOMPLISHMENTS = 'accomplishments',
  VOLUNTEERING = 'volunteering',
  MARITAL_HISTORY = 'marital-history',
  CHILDREN = 'children',
  GRANDCHILDREN = 'grandchildren',
  OTHER_FAMILY = 'other-family',
  OTHER_FRIENDS = 'other-friends',
  RELIGIOUS_ADVISOR = 'religious-advisor',
  RELIGIOUS_PREFERENCE = 'religious-preference',
  RELIGIOUS_SERVICE_LOCATION = 'religious-service-location',
  DAYS_OF_WORSHIP = 'days-of-worship',
  CLUBS_AND_ORGS = 'clubs-and-organizations',
  MUSIC = 'music',
  MOVIES = 'movies',
  TV = 'television',
  BOOKS = 'books-and-genres',
  SPORTS = 'sports',
  ACTIVITIES = 'other-activities',
  BREAKFAST_TIME = 'time-breakfast',
  LUNCH_TIME = 'time-lunch',
  DINNER_TIME = 'time-dinner',
  FAVORITE_FOODS = 'food-favorites',
  DISLIKED_FOODS = 'food-dislikes',
  SNACK_FOODS = 'food-snacks',
  TYPICAL_DAY = 'typical-day',
  BATHING_TIME = 'bathing-time',
  BATHING_DAYS = 'bathing-days',
  BATHING_METHOD = 'bathing-method',
  DAYTIME_DRESSING = 'dressing-daytime',
  NIGHTTIME_DRESSING = 'dressing-nighttime',
  OTHER_ROUTINES = 'other-routines',
}

export const GtkyFields = {
  ...GtkyPersonFields,
  ...GtkySnapshotFields,
}

export type GtkyPersonPatch = {
  communication?: Communication[]
  socialHistory?: SocialHistory
  allergiesAndIntolerances?: Person_AllergiesAndIntolerances
}

export type GtkySnapshotPatch = {
  [type in GtkySnapshotFields]?: string | string[] | null
}

export type GtkyViewerMode =
  | {
      tag: 'review'
      onComplete: () => void
      onMakeChanges: () => void
    }
  | {
      tag: 'view'
      onClose: () => void
    }

export type GtkyPageProducerMode =
  | {
      tag: 'edit'
      updateSnapshot: ({
        patch,
        status,
      }: {
        patch: GtkySnapshotPatch | null
        status: SnapshotStatus
      }) => Promise<void>
      updatePerson: ({
        patch,
        fields,
      }: {
        patch: GtkyPersonPatch
        fields: string
      }) => Promise<void>
      onContinue: () => void
      onFinishLater: () => void
    }
  | {
      tag: 'view'
    }
