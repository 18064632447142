import {
  ContactPoint,
  ContactPoint_ContactPointSystem,
  ContactPoint_ContactPointUse,
} from '@shared/types/contact_point'
import { isEmptyObject } from '@shared/utils/common'
import notEmpty from '@shared/utils/notEmpty'

// This assumes there is only one ContactPoint w/ System == Phone
// Can we make this assumption?

function getTelecomValue(point?: ContactPoint) {
  return point?.value
}

export function getPhone(
  contactPoints?: ContactPoint[]
): ContactPoint | undefined {
  return contactPoints?.find(
    ({ system }) =>
      system === ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_PHONE
  )
}
export function getPhoneValue(contactPoints?: ContactPoint[]) {
  return getTelecomValue(getPhone(contactPoints))
}

export function getEmail(
  contactPoints?: ContactPoint[]
): ContactPoint | undefined {
  return contactPoints?.find(
    ({ system }) =>
      system === ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_EMAIL
  )
}
export function getEmailValue(contactPoints?: ContactPoint[]) {
  return getTelecomValue(getEmail(contactPoints))
}

export function filterOutEmptyTelecom(contactPoints?: (ContactPoint | null)[]) {
  return contactPoints?.filter((t) => t?.value?.trim()) as ContactPoint[]
}

export function getFax(contactPoints?: ContactPoint[]) {
  return contactPoints?.find(
    ({ system }) =>
      system === ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_FAX
  )
}
export function getFaxValue(contactPoints?: ContactPoint[]) {
  return getTelecomValue(getFax(contactPoints))
}

export function makeTelecom(
  initialValue: ContactPoint[],
  newPhone?: string,
  newFax?: string
): ContactPoint[] {
  if (initialValue.length > 0) {
    return initialValue
      .filter(
        (contactPoint) =>
          contactPoint.system !==
          ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_PHONE
      )
      .filter(
        (contactPoint) =>
          contactPoint.system !==
          ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_FAX
      )
      .concat(
        [
          makeContactPoint('phone', newPhone),
          makeContactPoint('fax', newFax),
        ].filter(notEmpty)
      )
  }

  return [
    makeContactPoint('phone', newPhone),
    makeContactPoint('fax', newFax),
  ].filter(notEmpty)
}

function makeContactPoint(
  system: 'phone' | 'fax' | 'email',
  value: string | undefined
): ContactPoint | undefined {
  if (value && value.length > 0) {
    return {
      value,
      system: systemValue(system),
    }
  }

  return undefined
}

function systemValue(system: 'phone' | 'fax' | 'email') {
  switch (system) {
    case 'phone':
      return ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_PHONE
    case 'email':
      return ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_EMAIL
    case 'fax':
      return ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_FAX
  }
}

type SetFirstContactPointProps = {
  telecom: ContactPoint[]
  value?: string
  system?: ContactPoint_ContactPointSystem
  use?: ContactPoint_ContactPointUse
}

/** Find first contact point based on system */
function setFirstContactPoint({
  telecom,
  value,
  system,
  use,
}: SetFirstContactPointProps): ContactPoint[] {
  const index = telecom.findIndex((t) => t.system === system)
  if (!value) {
    // Remove
    return telecom.filter((_t, i) => i !== index)
  } else if (index > -1) {
    // Update if index matches
    return telecom.map((t, i) => {
      const { system: s, use: u } = t
      if (i === index) {
        return {
          system: system || s,
          use: use || u,
          value,
        }
      }

      return t
    })
  }

  // Add new
  return [
    ...telecom,
    {
      system,
      use,
      value,
    },
  ]
}

type SetFirstSystemProps = Omit<SetFirstContactPointProps, 'system'>

export function setFirstEmail({
  telecom,
  value,
}: SetFirstSystemProps): ContactPoint[] {
  return setFirstContactPoint({
    telecom,
    system: ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_EMAIL,
    value,
  })
}

export function setFirstPhone({
  telecom,
  value,
  use,
}: SetFirstSystemProps): ContactPoint[] {
  return setFirstContactPoint({
    telecom,
    system: ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_PHONE,
    value,
    use,
  })
}

export function setFirstFax({
  telecom,
  value,
}: SetFirstSystemProps): ContactPoint[] {
  return setFirstContactPoint({
    telecom,
    system: ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_FAX,
    value,
  })
}

// functions to build individual ContactPoint

/**
 * Update or create a new Telecom
 */

type UpsertTelecomProps = {
  contactPointSystem: ContactPoint_ContactPointSystem
  contactPointUse?: ContactPoint_ContactPointUse
  originalTelecom?: ContactPoint
  value?: string
}

// TO-DO Props parameters are confused for example contactPointSystem is used when originalTelecom is missing
export function upsertTelecom(props: UpsertTelecomProps): ContactPoint | null {
  const { contactPointSystem, contactPointUse, originalTelecom, value } = props

  // Delete
  if (!value) {
    return null
  }

  // Insert
  if (isEmptyObject(originalTelecom)) {
    return {
      system: contactPointSystem,
      use: contactPointUse,
      value,
    }
  }

  // Update
  return {
    ...originalTelecom,
    use: contactPointUse,
    value,
  }
}
