import Icon from '@shared/components/Icon'
import { tw } from '@shared/utils/tailwind'
import styles from './styles.module.css'

export default function MissingSectionsBanner({
  incompleteCount,
}: {
  incompleteCount: number
}) {
  if (incompleteCount === 0) {
    return null
  }

  return (
    <div className={tw`bg-white p-[16px]`}>
      <div className={styles.incompleteBanner}>
        <Icon name="info-circle" className={tw`mr-[8px]`} />
        <div>
          <p className={tw`m-0 text-[14px] font-semibold leading-[16px]`}>
            {incompleteCount === 1
              ? 'One section is'
              : `${incompleteCount} sections are`}{' '}
            incomplete. Please review before finalizing!
          </p>
        </div>
      </div>
    </div>
  )
}
