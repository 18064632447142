import { useContext, useState } from 'react'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import GlobalContext from '@shared/contexts/GlobalContext'
import { twx } from '@shared/utils/tailwind'
import { deleteCareGroup } from '@app/api/careGroup'
import { deleteMedGroup } from '@app/api/medGroup'
import { DeleteOverlay } from './DeleteOverlay'
import Form from './Form'
import {
  FacilityGroupsAndError,
  FacilityGroupsPopupProps,
  OnDeleteError,
} from './helpers'

export default function FacilityGroupPopup(props: FacilityGroupsPopupProps) {
  const { careGroups, medGroups, facility, onClose } = props
  const { setError } = useContext(GlobalContext)
  const [deleteHasResidents, setDeleteHasResidents] =
    useState<FacilityGroupsAndError>()
  const onDeleteError = async ({
    callback,
    fieldName,
    group,
    error,
  }: OnDeleteError) => {
    const { code, data } = error.json?.errors?.[0] || {}
    if (code === 'ERROR_CODE_CONFIRMATION_REQUIRED') {
      setDeleteHasResidents({
        callback,
        fieldName,
        group,
        error: {
          residents_affected: data.residents_affected,
        },
      })
    } else {
      setError(error)
    }
  }

  return (
    <Modal
      open={true}
      onClose={() => onClose()}
      id={'facility-group'}
      contentClassName={twx('w-[664px]')}
    >
      {deleteHasResidents && (
        <DeleteOverlay
          fieldName={deleteHasResidents.fieldName}
          onCancel={() => setDeleteHasResidents(undefined)}
          onDelete={async () => {
            const groups =
              deleteHasResidents.fieldName === 'medGroups'
                ? medGroups
                : careGroups
            const group = groups.find(
              (cg) => cg.id === deleteHasResidents.group.id
            )

            try {
              if (group) {
                const func =
                  deleteHasResidents.fieldName === 'medGroups'
                    ? deleteMedGroup
                    : deleteCareGroup
                await func({
                  group,
                  facility,
                  force: true,
                })
                deleteHasResidents.callback(true)
              }
            } catch (err) {
              setError(err)
            } finally {
              setDeleteHasResidents(undefined)
            }
          }}
          error={deleteHasResidents}
        />
      )}
      <Form {...props} onDeleteError={onDeleteError} />
    </Modal>
  )
}
