import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useEffect, useState } from 'react'
import { fetchAllPeopleStats } from '@shared/api/person'
import { hasPermissionForFacility } from '@shared/components/PermissionGates/PermissionGates'
import * as fieldLists from '@shared/constants/fieldLists'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Facility } from '@shared/types/facility'
import { PersonStats } from '@shared/types/person_stats'
import { UserAccount } from '@shared/types/user'
import { loaded, LOADING, Loading } from '@shared/utils/loading'
import {
  isCurrentResident,
  isDischargedResident,
  isProspect,
  isProspectClosed,
} from '@shared/utils/personStats'

export default function usePeopleInFacility({
  user,
  currentFacility,
}: {
  currentFacility: Loading<Facility> | undefined
  user: UserAccount | undefined
}) {
  const { setError } = useContext(GlobalContext)
  const [prospects, setProspects] = useState<Loading<PersonStats[]>>(LOADING)
  const [closedProspects, setClosedProspects] = useState<PersonStats[]>([])
  const [residents, setResidents] = useState<Loading<PersonStats[]>>(LOADING)
  const [movedOutResidents, setMovedOutResidents] = useState<PersonStats[]>([])
  const [allPeople, setAllPeople] = useState<Loading<PersonStats[]>>(LOADING)

  const refreshAllPeople = async (orgId: string, facilityId: string) => {
    if (
      user &&
      hasPermissionForFacility({
        user: user,
        facility: { orgId, id: facilityId },
        permissions: [GroupPermission.GROUP_PERMISSION_PERSON_READ],
      })
    ) {
      try {
        setProspects(LOADING)
        setResidents(LOADING)
        setAllPeople(LOADING)

        const allPeople = await fetchAllPeopleStats({
          orgId,
          facilityId,
          fields: fieldLists.RESIDENT_STATS_FIELDS,
        })

        setAllPeople(loaded(allPeople))
        setResidents(loaded(allPeople.filter(isCurrentResident)))
        setMovedOutResidents(allPeople.filter(isDischargedResident))
        setProspects(loaded(allPeople.filter(isProspect)))
        setClosedProspects(allPeople.filter(isProspectClosed))
      } catch (e) {
        setError(e)
      }
    } else {
      setAllPeople(loaded([]))
      setProspects(loaded([]))
      setResidents(loaded([]))
      setClosedProspects([])
      setMovedOutResidents([])
    }
  }

  useEffect(() => {
    if (user && currentFacility?.tag === 'Complete') {
      void refreshAllPeople(
        currentFacility.value.orgId,
        currentFacility.value.id
      )
    }
  }, [currentFacility, user])

  return {
    allPeople,
    setAllPeople,
    prospects,
    setProspects,
    closedProspects,
    setClosedProspects,
    refreshAllPeople,
    refreshProspects: refreshAllPeople,
    refreshResidents: refreshAllPeople,
    residents,
    setResidents,
    movedOutResidents,
    setMovedOutResidents,
  }
}
