import { useEffect, useState } from 'react'

export default function useDelayUnmount(isMounted: boolean, delay: number) {
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    let timeoutId: number

    if (isMounted && !shouldRender) {
      // If the component should enter the tree but hasn't yet,
      // set shouldRender to true, so it can enter the tree
      setShouldRender(true)
    } else if (!isMounted && shouldRender) {
      // If the component should exit the tree but hasn't yet,
      // Give it time to complete its out-animation
      timeoutId = window.setTimeout(() => setShouldRender(false), delay)
    }
    return () => clearTimeout(timeoutId)
  }, [isMounted, delay, shouldRender])

  return shouldRender
}
