import { useState } from 'react'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { Contact } from '@shared/types/contact'
import { firstLastName } from '@shared/utils/contact'
import { EMAIL_REGX } from '@shared/utils/regex'
import { tw } from '@shared/utils/tailwind'
import {
  ModalOverlays,
  ModalOverlaysParagraph,
} from '@app/components/ModalOverlays'

export function BaseMissingEmailOverlays({
  contact,
  onContinue,
  continueWithEmailButtonText,
}: {
  contact: Contact
  continueWithEmailButtonText: string
  onContinue: (email?: string) => Promise<void>
}) {
  const [newEmail, setNewEmail] = useState<string>('')
  const [buttonClick, setButtonClick] = useState<'withEmail' | 'withoutEmail'>()
  const isValidEmail = EMAIL_REGX.test(newEmail)
  const isSubmitting = Boolean(buttonClick)

  return (
    <ModalOverlays
      title={`Missing email address for ${firstLastName(contact)}`}
    >
      <ModalOverlaysParagraph>
        If you'd like them to sign online, enter their email.
      </ModalOverlaysParagraph>
      <ModalOverlaysParagraph>
        Or continue without email, and they can electronically sign in person
        later.
      </ModalOverlaysParagraph>
      <BasicInput
        autoFocus={true}
        className={tw`mt-[32px] w-[360px]`}
        placeholder={`Email address for ${firstLastName(contact)}`}
        onChange={(e) => setNewEmail(e.currentTarget.value)}
        disabled={isSubmitting}
        value={newEmail}
      />
      <div className={tw`mt-[32px] text-center`}>
        <AsyncIconButton
          buttonStyle={'secondary-fill'}
          className={'mr-[16px]'}
          width={'224px'}
          onClick={async () => {
            setButtonClick('withoutEmail')
            await onContinue()
            setButtonClick(undefined)
            setNewEmail('')
          }}
          disabled={isValidEmail || isSubmitting}
          isLoading={buttonClick === 'withoutEmail'}
        >
          Continue without Email
        </AsyncIconButton>
        <AsyncIconButton
          disabled={!isValidEmail || isSubmitting}
          width={'224px'}
          isLoading={buttonClick === 'withEmail'}
          buttonStyle={'primary-fill'}
          onClick={async () => {
            setButtonClick('withEmail')
            await onContinue(newEmail)
            setButtonClick(undefined)
            setNewEmail('')
          }}
        >
          {continueWithEmailButtonText}
        </AsyncIconButton>
      </div>
    </ModalOverlays>
  )
}
