import { get } from '@augusthealth/august-frontend-form-elements'
import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { patchSnapshot } from '@shared/api/snapshot'
import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import GlobalContext from '@shared/contexts/GlobalContext'
import { getTaskUrl } from '@shared/legacy_routes'
import { getRequiredFields } from '@app/components/SignatureFlow/api'
import PersonContext from '@app/contexts/PersonContext'
import ProspectsContext from '@app/contexts/ProspectsContext'
import { MatchParams } from '../../FormLayout/type'
import { Props as ConfirmLightboxProps } from './ConfirmLightbox'

type Props = {
  onClose: () => void
  params: MatchParams
  setConfirmLightboxData: (
    confirmLightboxProps: ConfirmLightboxProps | null
  ) => void
}

/**
 * Allow to submit GettingToKnowYou, LevelOfCare, etc. directly from Preview lightbox
 */
export default function SubmitButton({
  onClose,
  params,
  setConfirmLightboxData,
}: Props) {
  const { setError } = useContext(GlobalContext)
  const { person, tasks = [] } = useContext(PersonContext)
  const { setOpenCongrats } = useContext(ProspectsContext)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { facilityId, orgId, id: pId, taskId: defaultTaskId } = params
  const task = tasks.find((t) => t.id === defaultTaskId)
  const { id: taskId, taskTemplateInfo } = task || {}
  const { dataType } = taskTemplateInfo || {}
  const history = useHistory()
  const backToRequiredFieldsPage = () => {
    onClose()
    history.push(
      getTaskUrl({
        facilityId,
        personId: pId,
        orgId,
        id: taskId,
        page: 'requiredFields',
      })
    )
  }
  const showGoToRequiredFieldsConfirmPopup = () => {
    setConfirmLightboxData({
      title: 'Some fields are required to complete this form',
      buttonOptions: [
        {
          label: 'CANCEL',
          className:
            'hover:bg-button-tertiary-color hover:text-button-fill-font-color text-button-tertiary-color border-button-tertiary-color bg-button-default-bg-color border',
          onClick: () => setConfirmLightboxData(null),
        },
        {
          label: 'FILL REQUIRED FIELDS',
          className: 'btn-fill-quaternary',
          onClick: backToRequiredFieldsPage,
        },
      ],
    })
  }

  const onSubmitNonPdf = () => {
    if (taskTemplateInfo) {
      setIsSubmitting(true)
      getRequiredFields(orgId, facilityId, taskTemplateInfo)
        .then((requiredFields) => {
          setIsSubmitting(false)
          const needToFillRequiredFields = requiredFields.some(
            (field) => !get(person, field)
          )
          if (needToFillRequiredFields) {
            return showGoToRequiredFieldsConfirmPopup()
          } else {
            return patchSnapshot({
              orgId,
              facilityId,
              pId,
              taskId,
              snapshotId: 'latest',
              dataType,
              submit: true,
            }).then(() => setOpenCongrats('COMPLETED_TASK_VISIT_DOCUMENTS'))
          }
        })
        .catch(setError)
    }
  }

  return (
    <Button
      buttonStyle="primary-fill"
      id="submit-no-pdf-form"
      initialIcon="fa-check-circle"
      isLoading={isSubmitting}
      disabled={isSubmitting}
      onClick={onSubmitNonPdf}
    >
      Submit
    </Button>
  )
}
