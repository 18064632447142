import { useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { alternateFetchPerson } from '@shared/api/person'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { getCaliforniaAmbulatoryStatus } from '@shared/utils/ambulatoryStatus'
import styles from '../styles.module.css'
import {
  ambulatoryCodeOptions,
  defaultValues,
  FormData,
  localAmbulatoryStatus,
  PersonAndOnClose,
  updateAmbulatoryStatus,
  yesNoOptions,
} from '../helpers'

export default function QuestionsGuidingPopup({
  person,
  onClose,
}: PersonAndOnClose) {
  const { setError } = useContext(GlobalContext)
  useEffect(() => {
    void alternateFetchPerson({
      fId: person.facilityId!,
      pId: person.id!,
      orgId: person.orgId!,
      fields: ['ambulatoryStatus'],
    })
  }, [])

  const { control, handleSubmit, watch, formState } = useForm<FormData>({
    defaultValues: defaultValues(person),
  })

  const onSubmit = async (data: FormData) => {
    try {
      await updateAmbulatoryStatus(person, data)
      await onClose(true)
    } catch (e) {
      setError(e)
    }
  }

  const ambulatoryStatusFromForm = localAmbulatoryStatus(
    watch('ambulatoryCode')?.value,
    watch('followsInstructions')?.value,
    watch('evacuatesQuickly')?.value
  )

  const localBadgeText =
    ambulatoryStatusFromForm &&
    getCaliforniaAmbulatoryStatus(ambulatoryStatusFromForm)

  const title = (
    <div className={styles.container}>
      <h2 className={styles.title}>Ambulatory Status</h2>
      {localBadgeText && <div className={styles.badge}>{localBadgeText}</div>}
    </div>
  )

  return (
    <AnimatedPopup title={title}>
      <form
        data-testid="ambulatory-form-with-three-questions"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <LabelAboveInput htmlFor="ambulatoryCode">
            <span className={styles.labelText}>
              Which of these best describes the resident?
            </span>
          </LabelAboveInput>
          <Controller
            control={control}
            name="ambulatoryCode"
            render={({ field: { onChange, value } }) => {
              return (
                <StyledSelect
                  value={value}
                  options={ambulatoryCodeOptions}
                  name="ambulatoryCode"
                  onChange={onChange}
                />
              )
            }}
          />
        </div>
        <div className="mt-[32px]">
          <LabelAboveInput htmlFor="followsInstructions">
            <span className={styles.labelText}>
              Does the applicant have the mental competence to follow signals
              and instructions for evacuation in case of fire?
            </span>
          </LabelAboveInput>
          <Controller
            control={control}
            name="followsInstructions"
            render={({ field: { onChange, value } }) => {
              return (
                <StyledSelect
                  value={value}
                  options={yesNoOptions}
                  name="followsInstructions"
                  onChange={onChange}
                />
              )
            }}
          />
        </div>
        <div className="mt-[32px]">
          <LabelAboveInput htmlFor="evacuatesQuickly">
            <span className={styles.labelText}>
              For purposes of an emergency exit, can the resident evacuate
              reasonably quickly, including taking the stairs as necessary?
            </span>
          </LabelAboveInput>
          <Controller
            control={control}
            name="evacuatesQuickly"
            render={({ field: { onChange, value } }) => {
              return (
                <StyledSelect
                  options={yesNoOptions}
                  name="evacuatesQuickly"
                  menuPlacement="top"
                  value={value}
                  onChange={onChange}
                />
              )
            }}
          />
        </div>
        <div className="mt-[32px]">
          <AnimatedPopupFormFooter
            yesBtn={{ props: { 'data-testid': 'save-ambulatory-status' } }}
            noBtn={{ action: () => void onClose(false) }}
            formState={formState}
          />
        </div>
      </form>
    </AnimatedPopup>
  )
}
