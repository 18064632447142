import { ComponentProps, FunctionComponent, ReactNode } from 'react'
import Button from '@shared/components/Button'

export type ButtonGroupOption = ComponentProps<'button'> & {
  label?: ReactNode
  className?: string
  component?: FunctionComponent<any>
}

type ButtonGroupProps = {
  className?: string
  options: ButtonGroupOption[]
  numOfItemsByRow?: number
}

export default function ButtonGroup(props: ButtonGroupProps) {
  const { className, options, numOfItemsByRow } = props
  const divClassNameList = ['whitespace-nowrap [&>*]:grow']
  if (className) {
    divClassNameList.push(className)

    if (className.match(/vertical/)) {
      divClassNameList.push('space-y-[12px]')
    } else {
      divClassNameList.push('space-x-[12px]')
    }
  } else {
    divClassNameList.push('space-x-[12px]')
  }

  const buttons = options.reduce((list: ButtonGroupOption[], opt, i) => {
    const { className, component, label, disabled = false, onClick } = opt
    const key = `${label}-${i}`
    const Component = component || Button
    const btnClassNameList: string[] = []
    if (className) {
      btnClassNameList.push(className)
    }
    if (disabled) {
      btnClassNameList.push('disabled')
    }

    list.push(
      <Component
        {...opt}
        key={key}
        className={btnClassNameList.join(' ')}
        onClick={onClick}
      >
        {label}
      </Component>
    )

    if (numOfItemsByRow && i !== 0 && i % numOfItemsByRow === 0) {
      list.push(<br />)
    }

    return list
  }, [])

  return (
    <div className={divClassNameList.join(' ')}>
      <>{buttons}</>
    </div>
  )
}
