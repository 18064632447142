import { Shift } from '@augusthealth/models/com/august/protos/shift'
import { useContext, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import ExpectedErrorModal, {
  Props as PopupUIProps,
} from '@shared/components/ErrorModal/ExpectedErrorModal'
import Icon from '@shared/components/Icon'
import GlobalContext from '@shared/contexts/GlobalContext'
import { FacilityIds } from '@shared/types/facility'
import { AugustError } from '@shared/utils/error'
import { getErrorMessage, getErrorTitle } from '@shared/utils/medPass'
import { tw } from '@shared/utils/tailwind'
import { updateShifts } from '@app/api/shift'
import { ShiftsData } from './helpers'
import ShiftItem from './ShiftItem'

export default function ShiftsForm({
  facility,
  onSave,
  shifts,
}: {
  facility: FacilityIds
  onSave: (shifts: Shift[]) => void
  shifts: Shift[]
}) {
  const { setError } = useContext(GlobalContext)
  const [expectedErrorData, setExpectedErrorData] = useState<
    Omit<PopupUIProps, 'buttonProps' | 'id'> | undefined
  >(undefined)
  const useFormReturn = useForm<ShiftsData>({
    defaultValues: { shifts },
  })
  const { control, formState, handleSubmit, reset } = useFormReturn
  const { append, fields, remove } = useFieldArray({
    control,
    name: 'shifts',
  })
  const onSubmit = async (formData: ShiftsData) => {
    try {
      const res = await updateShifts({
        facilityId: facility.id,
        orgId: facility.orgId,
        shifts: formData.shifts,
      })

      onSave(res)
    } catch (err) {
      const { status, json } = err as AugustError
      // Temporary display Shifts error to client
      if (status === 400 && json?.errors && json.errors.length) {
        const { message } = json.errors[0]
        if (message) {
          const expectedErrorTitle = getErrorTitle(message)
          if (expectedErrorTitle) {
            setExpectedErrorData({
              children: getErrorMessage(message),
              title: expectedErrorTitle,
            })
            return
          }
        }
      }

      setError(err)
    }
  }

  useEffect(() => {
    reset({ shifts })
  }, [shifts.map((s) => s.id).join()])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((f, i) => {
          return (
            <ShiftItem
              key={`shift-row-${f.id}`}
              index={i}
              useFormReturn={useFormReturn}
              onRemove={() => remove(i)}
            />
          )
        })}
        <div className={tw`mb-[16px] text-right`}>
          <Button
            buttonSize="small"
            buttonStyle="primary-fill"
            onClick={() => {
              append({
                name: '',
                period: { startTime: null, endTime: null },
              } as unknown as Shift)
            }}
          >
            <Icon name="plus" className={tw`mr-[8px]`} />
            Add new shift
          </Button>
        </div>
        <hr />
        <div className={tw`mt-[24px] text-right`}>
          <AnimatedPopupFormFooter
            yesBtn={{}}
            noBtn={{ action: () => reset(), label: 'Reset' }}
            formState={formState}
          />
        </div>
      </form>
      {expectedErrorData && (
        <ExpectedErrorModal
          id={'shifts-config-error'}
          buttonProps={{
            children: 'Close',
            onClick: () => setExpectedErrorData(undefined),
          }}
          {...expectedErrorData}
        />
      )}
    </>
  )
}
