import Pill from '@shared/components/Pill'
import { StatementData, StatementStatus } from '@shared/types/billing'
import {
  isApproved,
  isDue,
  isError,
  isNeedsWork,
  isPaid,
  isPending,
} from '@shared/utils/statementData'
import { tw, twx } from '@shared/utils/tailwind'

type Props = {
  id: string
  invoiceData: StatementData
}

export function BillingInvoicePill({ id, invoiceData }: Props) {
  const humanInvoiceStatus = {
    [StatementStatus.NEEDS_WORK]: 'NEEDS WORK',
    [StatementStatus.DUE]: 'DUE',
    [StatementStatus.PENDING]: 'PENDING',
    [StatementStatus.PAID]: 'PAID',
    [StatementStatus.APPROVED]: 'APPROVED',
    [StatementStatus.ERROR]: 'ERROR',
  } as const
  const pillClassName = twx({
    'bg-urgent-color': isNeedsWork(invoiceData),
    'bg-tags-blue': isDue(invoiceData),
    'bg-secondary-07': isPending(invoiceData),
    'bg-tags-happy': isPaid(invoiceData),
    'bg-primary-light': isApproved(invoiceData),
    'bg-black': isError(invoiceData),
  })

  return (
    <Pill
      data-testid={`billing-pill-${id}`}
      shape="rectangle"
      size="small"
      className={pillClassName}
    >
      {humanInvoiceStatus[invoiceData.status]}
    </Pill>
  )
}

export function BillingInfoPill({ children }: { children: string }) {
  return (
    <Pill
      shape="rectangle"
      size="small"
      className={tw`ml-[8px] self-center bg-secondary-07`}
    >
      {children}
    </Pill>
  )
}
