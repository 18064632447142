import useOccupancyRooms from '@app/hooks/useOccupancyRooms'
import RoomBedSelect, { Props } from './index'

export interface SelectProps extends Omit<Props, 'occupancyRooms'> {
  orgId: string
  facilityId: string
}

export default function PrefilledSelect(props: SelectProps) {
  const { occupancyRooms } = useOccupancyRooms(props)

  if (occupancyRooms.tag === 'Loading') {
    return <RoomBedSelect {...props} occupancyRooms={[]} isDisabled />
  }

  if (occupancyRooms.tag === 'Error') {
    return <RoomBedSelect {...props} occupancyRooms={[]} isDisabled />
  }

  return <RoomBedSelect {...props} occupancyRooms={occupancyRooms.value} />
}
