import { useContext, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router-dom'
import { setPayerUserSettings } from '@shared/api/billing'
import GlobalContext from '@shared/contexts/GlobalContext'
import { PayerWithScheduledPayment } from '@shared/types/billing'
import { tw } from '@shared/utils/tailwind'
import { updateContact } from '@app/api/contacts'
import HUD from '@app/components/HUD'
import PersonContext from '@app/contexts/PersonContext'
import useBillingPayerUserSettings from '@app/hooks/useBillingPayerUserSettings'
import { contactFromFormValues } from '@app/pages/Contacts/ContactModal/helpers'
import { ContactFormData } from '@app/pages/Contacts/ContactModal/types'
import ContactInfo from './ContactInfo'
import StatementDeliveryPreference from './StatementDeliveryPreference'

export default function Settings() {
  const { setError } = useContext(GlobalContext)
  const [hudText, setHudText] = useState<string>('')
  const {
    id: personId,
    facilityId,
    orgId,
  } = useParams<{
    id: string
    facilityId: string
    orgId: string
  }>()

  const { billingPayerUserSettings, reloadBillingPayerUserSettings } =
    useBillingPayerUserSettings({
      orgId,
      facilityId,
      personId,
    })
  const { person } = useContext(PersonContext)

  const isLoading = billingPayerUserSettings.tag === 'Loading'

  if (isLoading) {
    return <Skeleton count={3} />
  }

  async function saveStatementDeliveryMethod(data: PayerWithScheduledPayment) {
    const {
      paymentSettings,
      paymentSettings: { statementDelivery },
    } = data

    try {
      await setPayerUserSettings({
        orgId,
        facilityId,
        personId,
        updatedSettings: {
          ...paymentSettings,
          statementDelivery,
        },
      })
      setHudText('Statement delivery method saved.')
      await reloadBillingPayerUserSettings()
    } catch (e) {
      setError(e)
    }
  }

  async function saveContactInfo(formData: ContactFormData) {
    if (billingPayerUserSettings.tag === 'Loading') return

    const contact = {
      ...contactFromFormValues(formData),
      id: billingPayerUserSettings.value.contact.id,
    }

    try {
      await updateContact({
        orgId,
        facilityId,
        personId,
        contact,
      })
      await reloadBillingPayerUserSettings()
      setHudText('Updated')
    } catch (e) {
      setError(e)
    }
  }

  return (
    <>
      {hudText && <HUD onExpire={() => setHudText('')}>{hudText}</HUD>}
      <div className={tw`mb-6 mt-0`}>
        <h2
          className={tw`mb-0 text-2xl font-medium leading-9 text-secondary-02`}
        >
          General Settings
        </h2>
      </div>
      <div
        className={tw`min-h-0 animate-fade-in rounded-lg bg-white p-2 shadow-card`}
      >
        <StatementDeliveryPreference
          settings={billingPayerUserSettings.value}
          submitFn={saveStatementDeliveryMethod}
        />

        <hr className={tw`border-1 border-gray-10`} />
        {person && (
          <ContactInfo
            statementDelivery={
              billingPayerUserSettings.value.paymentSettings.statementDelivery
            }
            submitFn={saveContactInfo}
            contact={billingPayerUserSettings.value.contact}
            person={person}
          />
        )}
      </div>
    </>
  )
}
