import { useContext, useEffect, useState } from 'react'
import { getPayerSettings } from '@shared/api/billing'
import GlobalContext from '@shared/contexts/GlobalContext'
import { PayerSettingsData } from '@shared/types/billing'
import { loaded, LOADING, Loading } from '@shared/utils/loading'

export default function usePayerSettings({
  orgId,
  facilityId,
  personId,
  contactId,
}: {
  orgId: string
  facilityId: string
  personId: string
  contactId: string
}) {
  const { setError } = useContext(GlobalContext)
  const [payerSettings, setPayerSettings] =
    useState<Loading<PayerSettingsData>>(LOADING)

  async function reloadPayerSettings() {
    setPayerSettings(LOADING)
    try {
      const newSettings = await getPayerSettings({
        orgId,
        facilityId,
        personId,
        contactId,
      })
      setPayerSettings(loaded(newSettings))
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    void reloadPayerSettings()
  }, [orgId, facilityId, personId, contactId])

  return {
    payerSettings,
    setPayerSettings,
  }
}
