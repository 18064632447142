import { AmbulatoryStatus_AmbulatoryResult } from '@augusthealth/models/com/august/protos/ambulatory_status'
import { useContext, useEffect, useState } from 'react'
import { FormState } from 'react-hook-form'
import { alternateFetchPerson } from '@shared/api/person'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { LabelAboveInput } from '@shared/components/Labels'
import CurrentFacilityContext from '@shared/contexts/CurrentFacilityContext'
import GlobalContext from '@shared/contexts/GlobalContext'
import { RadioCardGroup } from '@app/components/formElements/RadioGroup'
import styles from '../styles.module.css'
import { PersonAndOnClose, updateAmbulatoryResult } from '../helpers'

export default function AmbulatoryFromApiPopup({
  person,
  onClose,
}: PersonAndOnClose) {
  const {
    settings: { ambulatorySettingsOptions },
  } = useContext(CurrentFacilityContext)
  const { setError } = useContext(GlobalContext)
  const [ambulatoryResult, setAmbulatoryResult] =
    useState<AmbulatoryStatus_AmbulatoryResult>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const defaultAmbulatoryResult = person.ambulatoryStatus?.ambulatoryResult

  useEffect(() => {
    void alternateFetchPerson({
      fId: person.facilityId!,
      pId: person.id!,
      orgId: person.orgId!,
      fields: ['ambulatoryStatus'],
    })
  }, [])

  const onSubmit = async () => {
    try {
      setIsSubmitting(true)
      if (ambulatoryResult) {
        await updateAmbulatoryResult(person, ambulatoryResult)
        await onClose(true)
      }
    } catch (e) {
      setError(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <AnimatedPopup title="Ambulatory Status">
      <form data-testid="ambulatory-settings-api-form">
        <div>
          <LabelAboveInput htmlFor="ambulatoryCode">
            <span className={styles.labelText}>
              Which of these best describes the resident?
            </span>
          </LabelAboveInput>
          <RadioCardGroup
            name="ambulatoryStatus"
            options={ambulatorySettingsOptions}
            onUpdate={setAmbulatoryResult}
            value={defaultAmbulatoryResult}
          />
        </div>
        <div className="mt-[32px]">
          <AnimatedPopupFormFooter
            yesBtn={{
              props: {
                'data-testid': 'save-ambulatory-status',
                disabled: !ambulatoryResult,
                onClick: () => void onSubmit(),
                type: 'button',
              },
            }}
            noBtn={{ action: () => void onClose(false) }}
            formState={{ isSubmitting } as FormState<any>}
          />
        </div>
      </form>
    </AnimatedPopup>
  )
}
