import { useEffect, useState } from 'react'
import { fetchPeople } from '@shared/api/person'
import { FacilityIds } from '@shared/types/facility'
import { Person, ResidentStatus } from '@shared/types/person'
import { AsyncResult } from '@shared/utils/loading'

export default function usePeople({
  facility,
  fields,
  residentStatus,
}: {
  facility: FacilityIds | undefined
  fields?: string[]
  residentStatus?: ResidentStatus
}) {
  const [people, setPeople] = useState<AsyncResult<Person[], any>>({
    tag: 'Loading',
  })
  const { orgId, id: facilityId } = facility ?? {}
  const refreshPeople = async () => {
    if (facility === undefined) {
      setPeople({
        tag: 'Error',
        value: new Error('No orgId or facilityId present'),
      })
      return
    }

    return fetchPeople({
      orgId: facility.orgId,
      facilityId: facility.id,
      fields,
      residentStatus,
    }).then((value) => setPeople({ tag: 'Complete', value }))
  }

  useEffect(() => {
    void refreshPeople()
  }, [orgId, facilityId, fields])

  return { people, refreshPeople }
}
