import { useContext } from 'react'
import CurrentFacilityContext from '@shared/contexts/CurrentFacilityContext'
import { isCaliforniaFacility } from '@shared/utils/facilities'
import { PersonAndOnClose } from '../helpers'
import AmbulatoryFromApiPopup from './AmbulatoryFromApiPopup'
import QuestionsGuidingPopup from './QuestionsGuidingPopup'

export default function EditAmbulatoryStatus(props: PersonAndOnClose) {
  const { currentFacility } = useContext(CurrentFacilityContext)
  if (!currentFacility || currentFacility.tag === 'Loading') {
    return null
  }

  if (isCaliforniaFacility(currentFacility.value)) {
    return <QuestionsGuidingPopup {...props} />
  }

  return <AmbulatoryFromApiPopup {...props} />
}
