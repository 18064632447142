import { Array, Option } from 'effect'

export function nonEmptyJoin(
  list: (string | undefined | null)[],
  separator: string
) {
  return list
    .filter((item) => !['', null, undefined].includes(item))
    .join(separator)
}

export const findFirstWithIndex =
  <A>(predicate: (a: A) => boolean) =>
  (list: A[]): Option.Option<[A, number]> => {
    const item = Array.findFirst(list, predicate)
    const idx = Array.findFirstIndex(list, predicate)

    if (Option.isSome(item) && Option.isSome(idx)) {
      return Option.some([item.value, idx.value])
    } else {
      return Option.none()
    }
  }
