import { Lightbox } from '@augusthealth/august-frontend-form-elements'
import { ReactNode } from 'react'
import ElementHolder from '@app/components/formElements/ElementHolder'
import ButtonGroup from '@app/components/generic/ButtonGroup'
import './style.css'

type ButtonOption = {
  label: ReactNode
  className: string
  onClick: () => void
}

export type Props = {
  title: string
  description?: string
  buttonOptions: ButtonOption[]
  onClose?: () => void
  children?: ReactNode
}

export default function ConfirmLightbox(props: Props) {
  const { title, description, buttonOptions, onClose, children } = props

  const buttonGroup = buttonOptions && <ButtonGroup options={buttonOptions} />

  return (
    <Lightbox defaultOpen onClose={onClose} holderClassName="confirm-lightbox">
      <ElementHolder title={title} description={description}>
        {children}
        {buttonGroup}
      </ElementHolder>
    </Lightbox>
  )
}
