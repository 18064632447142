import { SettingsType } from '@augusthealth/models/com/august/protos/org_facility_settings'
import {
  SignableForm,
  SignableFormAssignment,
} from '@augusthealth/models/com/august/protos/signable_form'
import { useEffect, useState } from 'react'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { SuperUserOnlyGate } from '@shared/components/PermissionGates/PermissionGates'
import { Loading } from '@shared/utils/loading'
import {
  findScheduledTaskForTaskDefinition,
  SettingsLevel,
  sortTaskDefinitions,
} from '@shared/utils/orgFacilitySettings'
import { getGlobalAssignments } from '@app/api/form'
import PersonPageTitle from '@app/components/PersonPageTitle'
import useOrgFacilitySettings from '@app/hooks/useOrgFacilitySettings'
import TaskDefinitionModal from '@app/pages/Tools/TaskDefinitions/TaskDefinitionModal'
import TaskCard from '../TaskCard'

export default function GlobalTasks({
  formList,
}: {
  formList: SignableForm[]
}) {
  const [showGlobalTaskPopup, setShowGlobalTaskPopup] = useState(false)
  const { settings: loadingTaskSettings, reloadSettings: reloadTaskSettings } =
    useOrgFacilitySettings({
      settingsType: SettingsType.SETTINGS_TYPE_TASK_DEFINITION,
    })
  const {
    settings: scheduledTaskSettings,
    reloadSettings: reloadScheduledTaskSettings,
  } = useOrgFacilitySettings({
    settingsType: SettingsType.SETTINGS_TYPE_SCHEDULED_TASK,
  })

  const [globalAssignments, setGlobalAssignments] = useState<
    Loading<SignableFormAssignment[]>
  >({ tag: 'Loading' })

  const loadAssignments = async () => {
    await getGlobalAssignments().then((value) =>
      setGlobalAssignments({ tag: 'Complete', value })
    )
  }

  useEffect(() => {
    void loadAssignments()
  }, [])

  if (
    loadingTaskSettings.tag === 'Loading' ||
    scheduledTaskSettings.tag === 'Loading' ||
    globalAssignments.tag === 'Loading'
  ) {
    return <>Loading...</>
  }

  const taskSettings = loadingTaskSettings.value

  const rows = sortTaskDefinitions(taskSettings).map((t) => {
    return (
      <TaskCard
        cardLevel={{ tag: 'Global' }}
        key={`global-task-card-${t.key}`}
        allAssignments={globalAssignments.value}
        formList={formList}
        loadAssignments={loadAssignments}
        name="Global"
        onTaskDefinitionChange={reloadTaskSettings}
        onScheduledTaskChange={async () => {
          await reloadScheduledTaskSettings()
        }}
        taskSettings={t}
        scheduledTask={findScheduledTaskForTaskDefinition({
          scheduledTasks: scheduledTaskSettings.value,
          taskDefinition: t,
          cardLevel: { tag: 'Global' },
        })}
      />
    )
  })

  return (
    <div>
      <PersonPageTitle subtitle title="Global Tasks">
        <SuperUserOnlyGate>
          <AsyncIconButton
            buttonStyle="primary-fill"
            initialIcon="fa-plus"
            buttonSize="small"
            onClick={() => setShowGlobalTaskPopup(true)}
          >
            Add Global Task
          </AsyncIconButton>
        </SuperUserOnlyGate>
      </PersonPageTitle>
      <div className="mb-[32px]">{rows}</div>
      {showGlobalTaskPopup && (
        <TaskDefinitionModal
          name="Global"
          cardLevel={{ tag: 'Global' }}
          defaultSettingsLevel={SettingsLevel.GLOBAL_LEVEL}
          onClose={async (updated: boolean) => {
            setShowGlobalTaskPopup(false)
            if (updated) {
              return await reloadTaskSettings()
            }
          }}
        />
      )}
    </div>
  )
}
