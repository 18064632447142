import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Page, pageToPath } from '@shared/hooks/useCurrentPage'
import { Facility } from '@shared/types/facility'
import { tw, twx } from '@shared/utils/tailwind'
import SidebarContentsContext from '../SidebarContentsContext'

export default function FacilityChooser({
  onFacilityClick,
  showFacilityList,
  onClick,
}: {
  onFacilityClick: () => void
  showFacilityList: boolean
  onClick: () => void
}) {
  const { currentFacility, facilities, currentPage } = useContext(
    SidebarContentsContext
  )

  return (
    <div
      className="horizontal fixed left-0 top-0 p-[24px] xs:relative"
      onClick={(ev) => {
        ev.stopPropagation()
        if (facilities.length > 1) {
          onClick()
        }
      }}
    >
      <img
        src="/svg/icon--facility.svg"
        className={tw`h-[24px] w-[24px] rounded-[3px]`}
      />
      <div
        className={tw`w-[154px] overflow-hidden text-ellipsis whitespace-nowrap`}
      >
        {currentFacility?.name ? currentFacility.name : 'August Health'}
      </div>
      {facilities.length > 1 && (
        <span>
          <label className="visually-hidden">Open Facilities List</label>
          <i className={tw`far fa-angle-down text-[16px]`} />
        </span>
      )}
      {showFacilityList && (
        <FacilityDropdown
          currentPage={currentPage}
          facilities={facilities}
          facilityId={currentFacility?.id}
          onFacilityClick={onFacilityClick}
        />
      )}
    </div>
  )
}

function FacilityDropdown({
  currentPage,
  facilities,
  facilityId,
  onFacilityClick,
}: {
  currentPage: Page
  facilities: Facility[]
  facilityId: string | undefined
  onFacilityClick: () => void
}) {
  return (
    <div
      className={tw`contents w-0 after:absolute after:left-[48px] after:top-[55px] after:h-0 after:w-0 after:border-[10px] after:border-t-0 after:border-transparent after:border-b-white after:content-['']`}
    >
      <ul
        className={tw`absolute left-[32px] top-[64px] z-[4] m-0 max-h-[85vh] w-full list-none divide-y divide-divider overflow-y-auto rounded-[3px] bg-white p-0 text-black shadow-md`}
      >
        {facilities.map((facility) => {
          const facilityURL = urlForFacility({ facility, currentPage })
          const isSelected = facility.id === facilityId

          return (
            <li
              className={twx('transition-all hover:bg-scrim')}
              key={`fd-${facility.id}`}
            >
              <Link
                className={twx(
                  'ml-[24px] block px-[8px] py-[16px] text-black no-underline',
                  {
                    'ml-0 font-bold': isSelected,
                  }
                )}
                id={`fd-${facility.id}`}
                key={facilityURL}
                to={facilityURL}
                onClick={onFacilityClick}
              >
                {isSelected && <i className={tw`far fa-check mr-[8px]`} />}
                {facility.name}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

function urlForFacility({
  facility,
  currentPage,
}: {
  facility: Facility
  currentPage: Page
}) {
  const basePath = `/orgs/${facility.orgId}/facilities/${facility.id}`

  switch (currentPage.tag) {
    case 'Unknown':
    case 'Organization':
    case 'OrganizationList':
    case 'Person':
      return `${basePath}/residents`
    default:
      return pageToPath({
        page: {
          ...currentPage,
          orgId: facility.orgId!,
          facilityId: facility.id!,
        },
      })
  }
}
