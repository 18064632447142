import { AppraisalSettings_AppraisalCategory as AppraisalCategory } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { Snapshot } from '@shared/types/snapshot'
import { TaskStatus } from '@shared/types/task'
import { getAdmissionType } from '@shared/utils/person'
import {
  getAssessmentFromSnapshot,
  getCategoryTitle,
} from '@shared/utils/residentAssessment'
import { tw } from '@shared/utils/tailwind'
import { markInProgressByAdmin } from '@app/api/tasks'
import styles from '../styles.module.css'
import AdmissionType from '../AdmissionType'
import AssessmentReason from '../AssessmentReason'
import AssessmentType from '../AssessmentType'
import { updateAssessment } from '../helpers'
import { AssessmentPageMode } from '../types'
import useAssessmentModification from '../useAssessmentModification'
import CheckboxSections from './CheckboxSections'
import Footer from './Footer'
import Needs from './Needs'
import NeedsHeader from './NeedsHeader'
import Notes from './Notes'
import { AssessmentChange } from './types'
import WarningBanners from './WarningBanners'

export default function AssessmentPage({
  categories,
  person,
  snapshot,
  mode,
  showAdmissionTypeAndAssessmentReason,
  showAssessmentType,
}: {
  categories: AppraisalCategory[]
  person: Person
  mode: AssessmentPageMode
  snapshot: Snapshot
  showAdmissionTypeAndAssessmentReason: boolean
  showAssessmentType: boolean
}) {
  const { setError } = useContext(GlobalContext)
  const { handleSubmit, formState } = useForm()
  const { hash } = useLocation()
  const initialAssessment = getAssessmentFromSnapshot(snapshot)

  useEffect(() => {
    const id = hash.replace('#', '')
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView()
    }
  }, [hash])

  const { assessmentChangeModifier, disabledGroups, disabledDetails } =
    useAssessmentModification({
      initialAssessment,
      categories,
    })

  const saveAssessment = async (detailChange: AssessmentChange) => {
    const additionalChanges = assessmentChangeModifier(detailChange)

    try {
      const nextPromise = updateAssessment({
        assessment: initialAssessment!,
        person,
        initialPromise:
          mode.tag === 'fillOut'
            ? mode.promiseQueue
            : Promise.resolve({
                data: { augustInitialAppraisal: initialAssessment },
              }),
        changes: [detailChange, ...additionalChanges],
      })

      if (mode.tag === 'fillOut') {
        mode.setPromiseQueue(nextPromise)
        const newSnapshot = await nextPromise
        mode.onUpdate(newSnapshot)
      }

      if (
        mode.tag === 'fillOut' &&
        mode.assessmentTask.status !== TaskStatus.TASK_STATUS_ASSIGNED_TO_ADMIN
      ) {
        const updatedTask = await markInProgressByAdmin(
          person,
          mode.assessmentTask
        )
        // Get task with updated status so it will only enter this if-condition once
        mode.setAssessmentTask(updatedTask.data)
      }
    } catch (e) {
      setError(e)
    }
  }

  const missingAssessmentReason =
    (initialAssessment?.settings?.assessmentReasons?.length ?? 0) > 0 &&
    !initialAssessment?.assessmentReason?.assessmentReason

  const onSubmit = async () => {
    try {
      if (mode.tag === 'fillOut') {
        mode.goToNextStep()
      }
    } catch (e) {
      setError(e)
    }
  }

  const onFinishLater = async () => {
    try {
      mode.tag === 'fillOut' && mode.onFinishLater()
    } catch (e) {
      setError(e)
    }
  }
  const disabled = mode.tag !== 'fillOut'
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ marginBottom: '120px', maxWidth: '600px' }}
      >
        {mode.tag === 'review' && initialAssessment && (
          <WarningBanners
            categories={categories}
            assessment={initialAssessment}
          />
        )}
        {showAdmissionTypeAndAssessmentReason && initialAssessment && (
          <>
            <AdmissionType
              assessment={initialAssessment}
              disabled={disabled}
              onUpdate={mode.tag === 'fillOut' ? mode.onUpdate : undefined}
            />
            <AssessmentReason
              disabled={disabled}
              assessment={initialAssessment}
              saveAppraisal={saveAssessment}
              showRequiredError={
                mode.tag === 'review' && missingAssessmentReason
              }
            />
          </>
        )}
        {categories.map((category) => {
          const { categoryKey = '', customKey = '' } = category
          return (
            <div
              key={`${categoryKey}-${customKey}-container`}
              className={tw`print:mt-[40px] print:break-inside-avoid`}
            >
              <h2 className={styles.h2} id={categoryKey}>
                <i className={`fas fa-square ${styles.square}`} />
                {getCategoryTitle(category)}
              </h2>
              <section className={tw`print:break-inside-avoid`}>
                <NeedsHeader
                  mode={mode}
                  assessment={initialAssessment}
                  category={category}
                  person={person}
                  task={
                    (mode.tag === 'review' && mode.assessmentTask) || undefined
                  }
                />
                <Needs
                  assessment={initialAssessment}
                  category={category}
                  disabled={disabled}
                  onChange={saveAssessment}
                />
              </section>
              <CheckboxSections
                person={person}
                assessment={initialAssessment}
                category={category}
                disabled={disabled}
                onChange={saveAssessment}
                behaviorCustomization={{
                  disabledGroups,
                  disabledDetails,
                }}
                mode={mode.tag === 'fillOut' ? 'edit' : 'view'}
              />
              <Notes
                assessment={initialAssessment}
                category={category}
                disabled={disabled}
                onChange={saveAssessment}
                mode={mode.tag === 'fillOut' ? 'edit' : 'view'}
              />
            </div>
          )
        })}
        {showAssessmentType && initialAssessment && (
          <AssessmentType
            disabled={disabled}
            assessment={initialAssessment}
            saveAppraisal={saveAssessment}
          />
        )}
        {mode.tag === 'fillOut' && (
          <Footer
            disableContinue={
              showAdmissionTypeAndAssessmentReason &&
              (getAdmissionType(person) === undefined ||
                missingAssessmentReason)
            }
            formState={formState}
            onFinishLater={onFinishLater}
          />
        )}
      </form>
    </>
  )
}
