import { useForm } from 'react-hook-form'
import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import { twx } from '@shared/utils/tailwind'
import { completeTaskManually } from '@app/api/tasks'
import { MarkAsDoneProps } from '../type'

type Props = MarkAsDoneProps & {
  onClose: () => void
  onComplete: () => Promise<void>
}

type FormData = { note: string }

export default function MarkAsDoneModal({
  person: { orgId, facilityId, id: personId },
  task: { id },
  onClose,
  onComplete,
}: Props) {
  const { formState, handleSubmit, register } = useForm<FormData>({
    mode: 'onChange',
  })

  const save = async ({ note }: FormData) => {
    if (orgId && facilityId && personId && id) {
      await completeTaskManually({
        person: { orgId, facilityId, id: personId },
        task: { id },
        note,
      })
      await onComplete()
    }
  }

  return (
    <Modal
      onClose={() => onClose()}
      open={true}
      id={'mark-service-plan-done'}
      contentClassName={twx('min-w-[364px] max-w-[464px] text-center')}
    >
      <h2 className="mb-[16px] text-[18px] font-semibold leading-[24px]">
        Mark Service Plan as done
      </h2>
      <div className="mb-[24px] text-left text-[16px] leading-[24px] text-secondary-06">
        Enter the details of how you communicated the Service Plan with the
        Responsible Party to finalize this document without co-signature.
      </div>
      <form onSubmit={handleSubmit(save)}>
        <BasicTextarea
          {...register('note', { required: true })}
          placeholder="Enter a reason..."
        />
        <Button
          buttonStyle="primary-fill"
          className="mt-[24px]"
          data-testid="mark-as-done-btn"
          id="mark-as-done-btn"
          width="304px"
          disabled={!formState.isValid || formState.isSubmitting}
          isLoading={formState.isSubmitting}
          type={'submit'}
        >
          Mark As Done
        </Button>
      </form>
    </Modal>
  )
}
