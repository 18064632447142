import { useContext, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { DataType, Snapshot } from '@shared/types/snapshot'
import { getFirstAndLastName } from '@shared/utils/humanName'
import ConfirmDelete from './ConfirmDelete'
import { deleteDocument, isDeletable } from './helpers'
import { Deletable } from './index'

export default function useDeletable({
  document,
  onComplete,
  person,
}: {
  person: Person
  document: Snapshot
  onComplete: (updated: boolean) => Promise<void>
}): { deletable: Deletable } {
  const [deleting, setDeleting] = useState(false)
  const { setError } = useContext(GlobalContext)

  const handleDelete = async () => {
    setDeleting(true)

    try {
      await deleteDocument(person, document)
      await onComplete(true)
    } catch (e) {
      setError(e)
    }
  }

  const deletable: Deletable = isDeletable(document) && {
    deleting,
    onClickDelete: handleDelete,
    confirmView: (onClickDelete, onCancel) => (
      <ConfirmDelete
        title="Are you sure you want to delete this document?"
        confirmText={confirmationText(document, person)}
        onCancel={onCancel}
        onConfirm={onClickDelete}
      />
    ),
  }

  return { deletable }
}

function confirmationText(document: Snapshot, person: Person) {
  switch (document.dataType) {
    case DataType.DATA_TYPE_POLST:
      return `You will no longer have an active POLST for ${getFirstAndLastName(
        person.name
      )}.`
    default:
      return ''
  }
}
